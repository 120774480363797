import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { labels } from "../../../../../shared/translations";
import StudySharedControls from "../SharedControls";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import {
  averageInRange,
  computeInRange,
  computeZScore,
  getMomentIdFromKey,
  getValuesInRange,
} from "../../../ReportHelperFns";

const moment = extendMoment(Moment);

const dateFormat = "YYYY-MM-DD";
const RADAR_KEY = "RADAR";

const ZSCORE_SPECIFIC_KEY = "Subject reference Period";

const MIN_STUDY_DAYS = 1;
const MAX_STUDY_DAYS = 999;

const StudyZScoreSubjectsReferencePeriod = ({
  mainSubjectKey,
  study,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onStudyDataChange,
  onFetchDateDaysChange,
  zScoreActiveAndEnabled,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [daysBefore, setDaysBefore] = useState(7);

  const updateFilterDataLINEAR = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Can't z-score self
      if (subjectName != mainSubjectKey) {
        //Iterate over study parameters
        accum[subjectName] = Object.entries(subjectData).reduce(
          (accum, [studyParamKey, studyData]) => {
            //Compute value averages of main subject
            const dayAverages = averageInRange(
              dateRange,
              by,
              (range) => range.reverseBy("day", { excludeStart: true }),
              (dayStr) =>
                data[mainSubjectKey]?.[studyParamKey]?.[dayStr] ??
                (defaultZero ? 0 : null)
            );

            //Compute Z-scores with current subject values
            accum[studyParamKey] = computeInRange(
              dateRange,
              by,
              (range) =>
                moment
                  .rangeFromInterval("day", -daysBefore, range.end)
                  .reverseBy("day", { excludeStart: true }),
              (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
              (values, destDay) => computeZScore(dayAverages[destDay], values)
            );
            return accum;
          },
          {}
        );
      }
      return accum;
    }, {});
  };

  const updateFilterDataRADAR = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Can't z-score self
      if (subjectName != mainSubjectKey) {
        //Iterate over study parameters
        accum[subjectName] = Object.entries(subjectData).reduce(
          (accum, [studyParamKey, studyData]) => {
            //Find first data in date range
            let endDate = [...dateRange.reverseBy("day")].find((day) => {
              const dayStr = day.format(dateFormat);
              return studyData[dayStr] || defaultZero;
            });
            endDate = endDate ?? dateRange.end;

            //Compute average of single "by"
            const currentRange = moment.rangeFromInterval(by, -1, endDate);
            let currentValues = getValuesInRange(
              data[mainSubjectKey]?.[studyParamKey] ?? {},
              currentRange,
              defaultZero
            );
            const valAvg =
              currentValues.reduce((a, b) => a + b, 0) / currentValues.length;

            //Obtain values for days interval with current subject values
            const referenceRange = moment.rangeFromInterval(
              by,
              -daysBefore,
              endDate
            );
            const referenceValues = getValuesInRange(
              studyData,
              referenceRange,
              defaultZero
            );

            //Compute z score
            accum[studyParamKey] = computeZScore(valAvg, referenceValues);

            return accum;
          },
          {}
        );
      }
      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    let newData;
    if (active && zScoreActiveAndEnabled) {
      onFetchDateDaysChange(-daysBefore);
      newData = {
        [study.key]: {
          [ZSCORE_SPECIFIC_KEY]:
            graphType?.key === RADAR_KEY
              ? updateFilterDataRADAR(getMomentIdFromKey(graphDateView?.key))
              : updateFilterDataLINEAR(getMomentIdFromKey(graphDateView?.key)),
        },
      };
    } else {
      onFetchDateDaysChange(0);
      newData = { [study.key]: { [ZSCORE_SPECIFIC_KEY]: {} } };
    }

    onStudyDataChange(newData);
  }, [
    zScoreActiveAndEnabled,
    active,
    data,
    dateRange,
    graphDateView,
    daysBefore,
    graphType,
  ]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      setActive(config.active);
      setDaysBefore(config.daysBefore);
      setConfigDone(true);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
        daysBefore: daysBefore,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={
        labels.patient.graphReport.section.feedback.studies[
          "ZSCORE_SUBJECT_REFERENCE"
        ]
      }
      active={active}
      setActive={setActive}
      enabled={zScoreActiveAndEnabled}
    >
      <SpTextInput
        label={labels.patient.graphReport.section.feedback.filters.period}
        value={daysBefore}
        onChange={(evnt) => {
          const value = evnt.target.value;
          if (value >= MIN_STUDY_DAYS && value <= MAX_STUDY_DAYS)
            setDaysBefore(value);
        }}
        type="number"
      />
    </StudySharedControls>
  );
};

export default StudyZScoreSubjectsReferencePeriod;
