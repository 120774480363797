import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import {
  getAssessmentMonitList,
  getAssessmentMonitoringAnswers,
  getMonitoringAssessmentToRepeat,
} from "../../../models/actions/Assessment";
import AddMonitoringAssessment from "../../../components/bundles/assessment/AddMonitoringAssessment";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import NoData from "../shared/utils/NoData";

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const PatientsMonitoringStep2 = (props) => {
  const [displayAssessments, setDisplayAssessments] = useState([]);
  const [openAssessmentChoice, setOpenAssessmentChoice] = useState(false);
  const [assessmentAdd, setAssessmentAdd] = useState(false);
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCategoriesObj, setSelectedCategoriesObj] = useState(null);

  useEffect(() => {}, []);
  const { patId } = useParams();
  const history = useHistory();
  const { endDate } = props;
  const { setLoading } = props;

  const { reset, register, errors, control } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentMonitList({ id_patient: patId });
      response.reverse();
      setDisplayAssessments(response);
      setLoading(false);
    } catch (error) {
      // console.log(error.response);
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    if (!assessmentAdd) {
      fetchData();
      setAssessmentDetail(null);
      setSelectedCategoriesObj(null);
    }
  }, [assessmentAdd]);

  useEffect(() => {
    if (assessmentDetail) setAssessmentAdd(true);
  }, [assessmentDetail]);

  useEffect(() => {
    if (selectedCategoriesObj) setAssessmentAdd(true);
  }, [selectedCategoriesObj]);

  const repeatAssessment = async (monitoringAssessment) => {
    // console.info("repeatAssessment", monitoringAssessment);
    try {
      const result = await getMonitoringAssessmentToRepeat({
        id_monitoring_assessment: monitoringAssessment.id,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setSelectedCategoriesObj(result);
        // setAssessmentDetail(result);
        // setSelectedAssessment(assessment);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const closeAssessmentAdd = () => {
    setAssessmentAdd(false);
  };

  const getAssessmentDetail = async (row, monitoringAssessmentId) => {
    try {
      setLoading(true);
      const result = await getAssessmentMonitoringAnswers({
        id_assessment: row.assessment?.id,
        id_patient: patId,
        monitoring_assessment_id: monitoringAssessmentId,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setAssessmentDetail(result);
        setSelectedAssessment(
          row.assessment ?? { description: row.template_name }
        );
        setSelectedRow(row);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const getAssessmentDetailFromScrolling = async (
    row,
    monitoringAssessmentId
  ) => {
    try {
      setLoading(true);
      const result = await getAssessmentMonitoringAnswers({
        id_assessment: row.assessment?.id,
        id_patient: patId,
        monitoring_assessment_id: monitoringAssessmentId,
      });
      if (result.error) {
        setLoading(false);
        props.snackbarShowErrorMessage(result.error);
      } else {
        setAssessmentDetail(result);
        setSelectedAssessment(
          row.assessment ?? { description: row.template_name }
        );
        setSelectedRow(row);
        setLoading(false);
        return result;
      }
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };
  // Columns
  const headCellsAssessment = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.patient.monitoring.assessments.header.name,
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: labels.patient.monitoring.assessments.header.date,
      isAction: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const _onRowClick = (ass) => getAssessmentDetail(ass, ass.id);

  const AssessmentRow = ({ row, index }) => (
    <>
      <SpTableRow key={row.id} tabIndex={index}>
        <SpTableCell
          onClick={() => _onRowClick(row)}
          style={{ cursor: "pointer" }}
        >
          <SpText variant="h4ComponentLabel">
            {row.assessment?.name
              ? psTranslate(row.assessment?.name)
              : row.template_name}
          </SpText>
        </SpTableCell>
        <SpTableCell
          onClick={() => _onRowClick(row)}
          style={{ cursor: "pointer" }}
        >
          <SpText variant="h4ComponentLabel">
            {moment(row.createdAt).format("DD/MM/yyyy")}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpButton
            style={{ maxWidth: "40%" }}
            variant="column"
            onClick={() => repeatAssessment(row)}
            buttonType="accept"
            text={labels.patient.monitoring.repeatAssessment.toUpperCase()}
          />
        </SpTableCell>
      </SpTableRow>
    </>
  );

  return (
    <>
      <StyledBodyColumn>
        <Grid container>
          {!assessmentDetail ? (
            <Grid item xs={12}>
              <SpText style={{ margin: 0 }} variant="h1PageTitle">
                {labels.patient.presentation.detail.stepper.step5.title.toUpperCase()}
              </SpText>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <SpText
                style={{ margin: 0, marginLeft: 10 }}
                variant="h1PageTitle"
              >
                {selectedAssessment &&
                  selectedAssessment?.description?.toUpperCase()}
              </SpText>
            </Grid>
          )}
          {!assessmentDetail &&
            endDate &&
            (displayAssessments.length > 0 ? (
              <Grid item xs={12}>
                <SpText variant="h1PageSubtitle">
                  {
                    labels.patient.presentation.detail.stepper.step5
                      .closedPresentation
                  }
                </SpText>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <SpText variant="h1PageSubtitle">
                  {labels.patient.presentation.detail.stepper.step5.noData}
                </SpText>
              </Grid>
            ))}

          {(!endDate || assessmentDetail) && (
            <Grid item xs={4}>
              <SpButton
                style={{ width: "100%" }}
                variant="none"
                onClick={() => setAssessmentAdd(!assessmentAdd)}
                buttonType="accept"
                text={
                  assessmentAdd
                    ? labels.patient.monitoring.closeAddAssessment.toUpperCase()
                    : labels.patient.monitoring.addAssessment.toUpperCase()
                }
              />
            </Grid>
          )}
        </Grid>
        {displayAssessments.length === 0 && !assessmentAdd && (
          <NoData label={labels.patient.monitoring.assessments.noAssessments} />
        )}
        {assessmentAdd ? (
          <AddMonitoringAssessment
            selectedAssessment={selectedAssessment}
            selectedRow={selectedRow}
            monitoring={true}
            presentation={null}
            setLoading={props.setLoading}
            getAssessmentDetail={getAssessmentDetailFromScrolling}
            noTitle={true}
            detail={endDate || assessmentDetail}
            assessmentDetail={assessmentDetail}
            assessmentsDisplayList={displayAssessments}
            closeAssessmentAdd={closeAssessmentAdd}
            isRepeatAssessment={
              selectedCategoriesObj &&
              selectedCategoriesObj.categoryIds.length > 0
            }
            selectedCategoriesObj={selectedCategoriesObj}
            isRepeatFromTemplate={
              selectedCategoriesObj &&
              selectedCategoriesObj.templateAssessmentId
            }
          />
        ) : (
          displayAssessments.length > 0 && (
            <SpTable
              headCells={headCellsAssessment}
              pagination={true}
              rows={displayAssessments}
              rowKey="id"
              padding={false}
              notCheckable={false}
              tableContainerMaxHeight={
                isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
              }
            >
              <AssessmentRow />
            </SpTable>
          )
        )}
      </StyledBodyColumn>
      <SpDialog
        open={openAssessmentChoice}
        setOpen={setOpenAssessmentChoice}
        title={labels.patient.monitoring.assessments.chooseAssessmentTemplate}
      />
    </>
  );
};

export default withSnackbar(PatientsMonitoringStep2);
