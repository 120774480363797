import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { averageInRange, getMomentIdFromKey } from "../ReportHelperFns";
import { labels, psTranslate } from "../../../shared/translations";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

const PROMSData = ({
  data,
  graphDateView,
  selectedProm,
  dateRange,
  onGraphDataChanged,
  defaultZero = false,
  ...props
}) => {
  const updateData = () => {
    const parseFloatSafe = (val) => (val ? parseFloat(val) : null);

    if (graphDateView) {
      const by = getMomentIdFromKey(graphDateView.key);

      //Flatten and average data
      const averageData = {
        Proms: {},
      };
      const range = moment
        .rangeFromInterval(by, 1, dateRange.end)
        .add(dateRange, { adjacent: true });
      Object.entries(data).forEach(([promName, promLabels]) => {
        Object.entries(promLabels).forEach(([label, dataValues]) => {
          if (selectedProm?.name == promName) {
            //Timeline
            averageData["Proms"][
              `${psTranslate(promName)} - ${psTranslate(label)}`
            ] = averageInRange(
              range,
              by,
              (range) => range.by("day", { excludeEnd: true }),
              (dayStr) =>
                parseFloatSafe(dataValues[dayStr]) ?? (defaultZero ? 0 : null),
              true,
              true
            );
          }
        });
      });

      onGraphDataChanged(averageData);
    }
  };

  useEffect(() => {
    updateData();
  }, [data, graphDateView, dateRange, selectedProm]);

  return <></>;
};

export default PROMSData;
