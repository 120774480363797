import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { nanoid } from "nanoid";
import { nestedObjectAssign } from "./ReportHelperFns";
import PatientsReportsStep1Feedback from "./PatientsReportsStep1Feedback";
import PatientsReportsStep2PROMs from "./PatientsReportsStep2PROMs";
import PatientsReportsStep3Dysfunctions from "./PatientsReportsStep3Dysfunctions";
import PatientsReportsStep4Measurements from "./PatientsReportsStep4Measurements";
import {
  getAnalyticsConfigurationByPatient,
  setAnalyticsConfigurationByPatient,
} from "../../../models/actions/Patients";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations";

const TYPES = {
  feedback: { type: "feedback", value: PatientsReportsStep1Feedback },
  proms: { type: "proms", value: PatientsReportsStep2PROMs },
  disorder: { type: "disorder", value: PatientsReportsStep3Dysfunctions },
  assessment: { type: "assessment", value: PatientsReportsStep4Measurements },
};

const N_MAX_GRAPHS = 10;

const PatientsReportsConfigurable = (props) => {
  const [selectedType, setSelectedType] = useState(
    TYPES[Object.keys(TYPES)[0]]
  );
  const [currentGraphs, setCurrentGraphs] = useState({});

  const [requestSaveFlag, setRequestSaveFlag] = useState(false);

  const [prevConfigurationString, setPrevConfigurationString] = useState("{}");
  const [savedConfiguration, setSavedConfiguration] = useState({});
  const [loadConfiguration, setLoadConfiguration] = useState({});

  const [savedConfigUpdateFlag, setSavedConfigUpdateFlag] = useState(false);

  const { patId } = useParams();

  const setConfig = (data) => {
    //This is where the fun happens
    setSavedConfiguration((prev) => nestedObjectAssign(prev, data));
  };

  useEffect(async () => {
    try {
      //Fetch config
      const newConfiguration = await getAnalyticsConfigurationByPatient({
        id_patient: patId,
      });
      const jsonConfigString = newConfiguration?.config ?? "{}";
      const parsedConfig = JSON.parse(jsonConfigString);
      setPrevConfigurationString(jsonConfigString);
      setLoadConfiguration(parsedConfig);

      //Set graphs like saved
      const newCurrentGraphs = {};
      Object.entries(parsedConfig).forEach(([key, element]) => {
        newCurrentGraphs[key] = TYPES[element.type];
      });
      setCurrentGraphs(newCurrentGraphs);
    } catch (error) {
      console.error(error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  useEffect(async () => {
    setRequestSaveFlag(false);
    try {
      //Save graph data to database
      const newConfig = JSON.stringify(savedConfiguration);
      if (prevConfigurationString != newConfig) {
        setPrevConfigurationString(newConfig);
        await setAnalyticsConfigurationByPatient({
          id_patient: patId,
          config: newConfig,
        });
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  }, [savedConfigUpdateFlag]);

  useEffect(() => {
    setSavedConfigUpdateFlag(!savedConfigUpdateFlag);
  }, [savedConfiguration]);

  const MyDivider = ({ label }) => {
    const _dividerStyle = {
      padding: "1px",
      backgroundColor: "#31ccad",
      marginBottom: "5px",
      marginTop: "5px",
    };
    return (
      <Grid item xs={12} container direction="row">
        <Grid xs={12}>
          <Divider style={_dividerStyle} />
        </Grid>
        <Grid item xs={12} container direction="row">
          <SpText variant="h1PageSubtitle">{label}</SpText>
        </Grid>
        <Grid xs={12}>
          <Divider style={_dividerStyle} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      {/* Graph array */}
      <Grid xs={12} item container>
        {Object.entries(currentGraphs).map(([key, { type, value: Graph }]) => (
          <Grid container xs={12} item key={key}>
            <Grid container xs={12} item>
              <MyDivider
                label={labels.patient.graphReport.section[type].title}
              />
              <Graph
                config={loadConfiguration[key]?.value}
                setConfig={(data) =>
                  setConfig({ [key]: { type: type, value: data } })
                }
                requestSaveFlag={requestSaveFlag}
              />
            </Grid>
            <Grid
              container
              xs={12}
              item
              style={{ marginBottom: "2%", marginTop: "10px" }}
            >
              <SpButton
                text={labels.analytics.remove}
                onClick={() => {
                  const newGraphs = { ...currentGraphs };
                  delete newGraphs[key];
                  setCurrentGraphs(newGraphs);
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {/* New graph controls */}
      <Grid xs={12} item container style={{ alignItems: "self-end" }}>
        <Grid xs={3} item>
          <SpSelect
            label={""}
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={selectedType}
            onChange={(evnt) => setSelectedType(evnt.target.value)}
          >
            {Object.entries(TYPES).map(([key, value]) => (
              <SpSelectMenuItem key={key} value={value}>
                {labels.patient.graphReport.section[key].title}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
        <Grid xs={3} item style={{ marginLeft: "3px" }}>
          <SpButton
            text={labels.analytics.add}
            buttonType="accept"
            onClick={() =>
              setCurrentGraphs({ ...currentGraphs, [nanoid()]: selectedType })
            }
            disabled={Object.entries(currentGraphs).length >= N_MAX_GRAPHS}
          />
        </Grid>
        <Grid xs item style={{ textAlign: "right" }}>
          <SpButton
            text={labels.analytics.save}
            buttonType="accept"
            onClick={() => {
              setSavedConfiguration({});
              setRequestSaveFlag(true);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsConfigurable);
