import { Checkbox, Chip, Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { SpList } from "../../../components/bundles/SpList";
import {
  getAllExercises,
  getExerciseTemplateDetail,
} from "../../../models/actions/Excercise";
import RowExercise from "../../patients/shared/activitiesData/RowExercise";
import { labels, psTranslate } from "../../shared/translations";

const filterOptions = createFilterOptions({
  // limit: 20,
  stringify: (option) => option.name + option.tags,
});

const ExerciseInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  control,
  setValue,
  register,

  disabled = false,
}) => {
  const [exercisesList, setExercisesList] = useState([]);

  const {
    fields: fieldsExercise,
    append: appendExcercise,
    remove: removeExcercise,
  } = useFieldArray({
    control,
    name: "excercises",
    keyName: "id",
    defaultValues: [],
  });

  useEffect(async () => {
    const templateData = await getExerciseTemplateDetail({
      id_template: selectedTemplate,
    });
    if (templateData) {
      removeExcercise();
      setValue("quantity_excercise", templateData.excercise_quantity);
      appendExcercise(templateData.excercise_sheet_template_rows);
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      removeExcercise();
      setValue("excercises_motivation", templateData.excercises_motivation);
      setValue("quantity_excercise", templateData.excercises?.length ?? 0);
      appendExcercise(templateData.excercises);
    }
  }, [templateData]);

  useEffect(async () => {
    const results = await getAllExercises();
    setExercisesList(results);
  }, []);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid item xs={12} container style={{ justifyContent: "flex-start" }}>
        <SpTextInput
          name="excercises_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeExcercise(fieldsExercise.length - 1);
              if (fieldsExercise.length > 0)
                setValue("quantity_excercise", fieldsExercise.length - 1);
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            minValue={1}
            name="quantity_excercise"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.excerciseSheet.inputs.quantity}
            disabled={disabled}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendExcercise({ id: fieldsExercise.length });
              setValue("quantity_excercise", fieldsExercise.length + 1);
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>
      {loadParametersData && (
        <Grid
          item
          xs={12}
          container
          style={{ justifyContent: "flex-start", alignItems: "center" }}
        >
          <SpAutocomplete
            multiple
            style={{ width: "100%", height: "100%", maxHeight: "100%" }}
            formControlWidth={"100%"}
            selectPlaceholder={
              labels.patient.viewPlan.addActivity.inputForm.chooseLoadParameters
            }
            options={loadParametersData}
            getOptionLabel={(option) => psTranslate(option.name)}
            isOptionEqualToValue={(opt, value) => opt.id === value.id}
            value={loadParametersList}
            onChange={(_, newValue) => setLoadParametersList(newValue)}
            renderOption={(props, option) => (
              <li
                key={`option_${props.id}`}
                style={{ color: option.selected ? "white" : "black" }}
              >
                <Checkbox
                  name={props.id}
                  icon={<CheckBoxOutlineBlankIcon small />}
                  checkedIcon={<CheckBoxIcon small />}
                  style={{
                    marginRight: 5,
                    color: option.selected ? "white" : "black",
                  }}
                  checked={option.selected}
                />
                {psTranslate(props.name)}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const _val = loadParametersData.find(
                  (x) => x.id === (option.id ?? option)
                );
                return (
                  <Chip
                    key={_val?.id}
                    style={{ backgroundColor: "#31ccad", color: "white" }}
                    label={psTranslate(_val?.name)}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                );
              })
            }
          />
        </Grid>
      )}
      {fieldsExercise.length > 0 && (
        <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
          <SpText variant="h1PageSubtitle">
            {labels.mylab.tempAdd.excerciseSheet.list.title}
          </SpText>
        </Grid>
      )}
      {fieldsExercise.length > 0 && (
        <Grid item xs={12} container style={{ border: "1px solid #31ccad" }}>
          <Grid item xs={12}>
            <SpList
              rows={fieldsExercise}
              rowKey="id"
              checkable={false}
              deletable={false}
            >
              <RowExercise
                control={control}
                register={register}
                setValue={setValue}
                exercisesList={exercisesList}
                filterOptions={filterOptions}
                disabled={disabled}
              />
            </SpList>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ExerciseInputDetails;
