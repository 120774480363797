const compositeParams = [
  {
    id: -1,
    name: "Volume di Kg sollevati",
    um: "a.u.",
    operator: () => {},
    sourceParams: [],
  },
  {
    id: -2,
    name: "Session RPE",
    um: "a.u.",
    operator: (params) => params.reduce((a, b) => a * b, 1),
    sourceParams: [6 /*RPE*/, 11 /*Durata allenamento*/],
  },
];

export const addProxyParameters = (params) => {
  params.push(...compositeParams);
  return params;
};

export const fixForProxyParams = (currParamsIds) => {
  const requestParams = currParamsIds.filter((paramId) => paramId >= 0);
  const requestCompositeParams = currParamsIds.filter((paramId) => paramId < 0);
  if (requestCompositeParams.length > 0) {
    //Add source parameters of composite param to request
    requestCompositeParams.forEach((paramId) => {
      const elem = compositeParams.find((el) => el.id == paramId);
      requestParams.push(...elem.sourceParams);
    });
  }
  return requestParams;
};

export const fixForProxyParamsResults = (results, currParamsIds) => {
  const requestCompositeParams = currParamsIds.filter((paramId) => paramId < 0);

  if (requestCompositeParams.length > 0) {
    //Here we search for composite params in request and provide composite results
    Object.entries(results).forEach(([personKey, paramObjs]) => {
      //Iterate composite params
      requestCompositeParams.forEach((compositeParamId) => {
        const compositeParam = compositeParams.find(
          (el) => el.id == compositeParamId
        );

        if (compositeParam.sourceParams.length > 0) {
          //[{ day: value, ... }] arrays
          const paramValues = compositeParam.sourceParams.map(
            (paramId) => paramObjs[paramId] ?? {}
          );
          const dayKeys = paramValues.reduce((days, a) => {
            Object.keys(a).forEach((b) => days.add(b));
            return days;
          }, new Set());

          //Generate a { day: operatedValue } object
          results[personKey][compositeParamId] = [...dayKeys].reduce(
            (obj, day) => {
              const dayValues = paramValues.map((dayVals) => dayVals[day] ?? 0);
              obj[day] = compositeParam.operator(dayValues);
              return obj;
            },
            {}
          );
        }
      });
    });
  }
  return results;
};
