import { Checkbox, Chip, Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import React, { useEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { SpList } from "../../../components/bundles/SpList";
import { getTrainingTemplateById } from "../../../models/actions/Activity";
import RowTraining from "../../patients/shared/activitiesData/RowTraining";
import { labels, psTranslate } from "../../shared/translations";

const TrainingInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  control,
  setValue,
  register,

  disabled = false,
}) => {
  const {
    fields: fieldsTraining,
    append: appendTraining,
    remove: removeTraining,
  } = useFieldArray({
    control,
    name: "training",
    keyName: "id",
    defaultValues: [],
  });

  const RenderTrainingMinutesCount = ({ control }) => {
    const watchMinutesTotalCountTrainings = useWatch({
      control,
      name: "training",
    });
    const currentArray = watchMinutesTotalCountTrainings;
    let minutesSum = 0;
    currentArray?.forEach((t) => {
      if (typeof t.time === "string") {
        const output = parseInt(t.time, 10);
        minutesSum = minutesSum + (Number.isNaN(output) ? 0 : output);
      } else {
        minutesSum = minutesSum + t.time;
      }
    });

    return (
      <SpText
        variant="h1PageSubtitleColored"
        style={{ display: "inline-block" }}
      >
        {minutesSum} {labels.agenda.inputDetails.totalMins}
      </SpText>
    );
  };

  useEffect(async () => {
    if (selectedTemplate) {
      const templateData = await getTrainingTemplateById({
        id_template: selectedTemplate,
      });
      if (templateData) {
        removeTraining();
        setValue("quantity_training", templateData.number);
        appendTraining(templateData.row_training_templates);
      }
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      removeTraining();
      setValue("training_motivation", templateData.training_motivation);
      setValue("quantity_training", templateData.training?.length ?? 0);
      appendTraining(templateData.training);
    }
  }, [templateData]);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="training_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeTraining(fieldsTraining.length - 1);
              if (fieldsTraining.length > 0)
                setValue("quantity_training", fieldsTraining.length - 1);
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            minValue={1}
            name="quantity_training"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.training.inputs.quantity}
            disabled={disabled}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendTraining({ id: fieldsTraining.length });
              setValue("quantity_training", fieldsTraining.length + 1);
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>
      {loadParametersData && (
        <Grid
          item
          xs={12}
          container
          style={{ justifyContent: "flex-start", alignItems: "center" }}
        >
          <SpAutocomplete
            multiple
            style={{ width: "100%", height: "100%", maxHeight: "100%" }}
            formControlWidth={"100%"}
            selectPlaceholder={
              labels.patient.viewPlan.addActivity.inputForm.chooseLoadParameters
            }
            options={loadParametersData}
            getOptionLabel={(option) => psTranslate(option.name)}
            isOptionEqualToValue={(opt, value) => opt.id === value.id}
            value={loadParametersList}
            onChange={(_, newValue) => setLoadParametersList(newValue)}
            renderOption={(props, option) => (
              <li
                key={`option_${props.id}`}
                style={{ color: option.selected ? "white" : "black" }}
              >
                <Checkbox
                  name={props.id}
                  icon={<CheckBoxOutlineBlankIcon small />}
                  checkedIcon={<CheckBoxIcon small />}
                  style={{
                    marginRight: 5,
                    color: option.selected ? "white" : "black",
                  }}
                  checked={option.selected}
                />
                {psTranslate(props.name)}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const _val = loadParametersData.find(
                  (x) => x.id === (option.id ?? option)
                );
                return (
                  <Chip
                    key={_val?.id}
                    style={{ backgroundColor: "#31ccad", color: "white" }}
                    label={psTranslate(_val?.name)}
                    size="medium"
                    {...getTagProps({ index })}
                  />
                );
              })
            }
          />
        </Grid>
      )}
      {fieldsTraining.length > 0 && (
        <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
          <Grid item xs={6}>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.training.list.title}
            </SpText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <RenderTrainingMinutesCount control={control} />
          </Grid>
        </Grid>
      )}
      {fieldsTraining.length > 0 && (
        <Grid item xs={12} container style={{ border: "1px solid #31ccad" }}>
          <Grid item xs={12}>
            <SpList
              rows={fieldsTraining}
              rowKey="id"
              checkable={false}
              deletable={false}
              HeaderRow={
                <Grid container style={{ justifyContent: "flex-start" }}>
                  {labels.mylab.tempAdd.training.list.header.map((head) => (
                    <Grid item xs={4}>
                      <SpText key={head} variant="listHeaderCenter">
                        {head}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
              }
            >
              <RowTraining
                headers={labels.mylab.tempAdd.training.list.header}
                control={control}
                register={register}
                disabled={disabled}
              />
            </SpList>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TrainingInputDetails;
