import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { labels } from "../../../../shared/translations";
import StudySharedControls from "./SharedControls";
import { averageInRange, genericIsEnabled } from "../../ReportHelperFns";

const moment = extendMoment(Moment);

const MIN_STUDY_DAYS = 1;
const MAX_STUDY_DAYS = 999;

export const StudyACWRRA = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [daysBeforeAcute, setDaysBeforeAcute] = useState(7);
  const [daysBeforeCronic, setDaysBeforeCronic] = useState(28);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const accraOverPrevDays = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      const resultData = {};
      //Iterate over study parameters
      Object.entries(subjectData).forEach(([studyParamKey, studyData]) => {
        //Acute avgs
        const acuteAvgs = averageInRange(
          dateRange,
          by,
          (range) =>
            moment
              .rangeFromInterval("day", -daysBeforeAcute, range.end)
              .reverseBy("day", { excludeStart: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null)
        );

        //Cronic avgs
        const cronicAvgs = averageInRange(
          dateRange,
          by,
          (range) =>
            moment
              .rangeFromInterval("day", -daysBeforeCronic, range.end)
              .reverseBy("day", { excludeStart: true }),
          (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null)
        );

        //RA result
        resultData[studyParamKey] = Object.entries(cronicAvgs).reduce(
          (accum, [key, cronicAvg]) => {
            if (acuteAvgs[key] != null && cronicAvg != null)
              accum[key] = cronicAvg != 0 ? acuteAvgs[key] / cronicAvg : 0;
            return accum;
          },
          {}
        );
      });
      accum[subjectName] = resultData;
      return accum;
    }, {});
  };

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      setActive(config.active);
      setDaysBeforeAcute(config.daysBeforeAcute);
      setDaysBeforeCronic(config.daysBeforeCronic);
      setConfigDone(true);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
        daysBeforeAcute: daysBeforeAcute,
        daysBeforeCronic: daysBeforeCronic,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Effects
  useEffect(() => {
    let newData;
    if (active && isEnabled()) {
      onFetchDateDaysChange(-Math.max(daysBeforeAcute, daysBeforeCronic));
      newData = { [study.key]: { "": accraOverPrevDays("day") } };
    } else {
      onFetchDateDaysChange(0);
      newData = { [study.key]: { "": {} } };
    }
    onStudyDataChange(newData);
  }, [
    active,
    data,
    dateRange,
    graphType,
    graphDateView,
    daysBeforeAcute,
    daysBeforeCronic,
  ]);

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    >
      <Grid container item xs={12} alignItems={"center"} spacing={1}>
        <Grid item xs={6}>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.acute_load
            }
            value={daysBeforeAcute}
            onChange={(evnt) => {
              const value = evnt.target.value;
              if (value >= MIN_STUDY_DAYS && value <= MAX_STUDY_DAYS)
                setDaysBeforeAcute(value);
            }}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.chronic_load
            }
            value={daysBeforeCronic}
            onChange={(evnt) => {
              const value = evnt.target.value;
              if (value >= MIN_STUDY_DAYS && value <= MAX_STUDY_DAYS)
                setDaysBeforeCronic(value);
            }}
            type="number"
          />
        </Grid>
      </Grid>
    </StudySharedControls>
  );
};
