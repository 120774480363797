import React, { useEffect, useState } from "react";
import "../../App.css";
import { ArrowBack } from "@material-ui/icons";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpText from "../../components/atoms/SpText";
import { styled } from "../../components/styled";
import { labels, psTranslate } from "../shared/translations";
import { useHistory, useParams } from "react-router";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpIconButton from "../../components/atoms/SpIconButton";
import {
  getFirstTableGroupsData,
  getSecondTableGroupsData,
  getThirdTableGroupsData,
} from "../../models/actions/Groups";
import {
  getAllActivitiesTypes,
  getAllFeedbackParameters,
} from "../../models/actions/Activity";
import SpDataGrid from "../../components/atoms/SpDataGrid";

import moment from "moment";
import SpButton from "../../components/atoms/SpButton";
import { nanoid } from "nanoid";
import { rollbar } from "../../utils/common";

moment.locale("it");

const getFullName = (params) => params.row.patient.familyName;

const headCellsFirst = [
  {
    type: "string",
    field: "name_surname",
    numeric: false,
    editable: false,
    sortable: true,
    headerName: labels.groups.groupReports.firstTable.header.patient,
    flex: 1,
    valueGetter: getFullName,
    renderCell: (params) => (
      <SpText variant="tableText">
        {params.row.patient.givenName} {params.row.patient.familyName}
      </SpText>
    ),
    sortComparator: (v1, v2, _, __) => v1.localeCompare(v2),
  },
  {
    type: "string",
    field: "status",
    numeric: false,
    editable: false,
    sortable: true,
    headerName: labels.groups.groupReports.firstTable.header.status,
    flex: 1,
    valueGetter: (params) => params.row.patStatus,
    renderCell: (params) => (
      <SpIconButton variant="lightBlue" style={{ marginLeft: 24 }} small>
        <FiberManualRecordIcon
          style={{ color: params.row.patStatus === 0 ? "red" : "green" }}
        />
      </SpIconButton>
    ),
    sortComparator: (v1, v2, _, __) => (v1 >= v2 ? 1 : 0),
  },
  {
    type: "string",
    field: "reason",
    numeric: false,
    editable: false,
    sortable: true,
    headerName: labels.groups.groupReports.firstTable.header.reason,
    flex: 1,
    valueGetter: (params) => params.row.presentationReason,
    renderCell: (params) => (
      <SpText variant="tableText">
        {psTranslate(params.row.presentationReason.trim())}
      </SpText>
    ),
    sortComparator: (v1, v2, _, __) => v1.localeCompare(v2),
  },
  {
    type: "date",
    field: "start_date",
    numeric: false,
    editable: false,
    sortable: true,
    headerName: labels.groups.groupReports.firstTable.header.startDate,
    flex: 1,
    renderCell: (params) => (
      <SpText variant="tableText">
        {moment(params.row.presStartDate).isValid()
          ? moment(params.row.presStartDate).format("DD/MM/yyyy")
          : params.row.presStartDate}
      </SpText>
    ),
    sortComparator: (v1, v2, _, __) => moment(v1).isBefore(moment(v2)),
  },
  {
    type: "date",
    field: "end_date",
    numeric: false,
    editable: false,
    sortable: true,
    headerName: labels.groups.groupReports.firstTable.header.endDate,
    flex: 1,
    valueGetter: (params) =>
      moment(params.row.presEndDate).isValid()
        ? moment(params.row.presEndDate).format("DD/MM/yyyy")
        : params.row.presEndDate,
    renderCell: (params) => (
      <SpText variant="tableText">
        {moment(params.row.presEndDate).isValid()
          ? moment(params.row.presEndDate).format("DD/MM/yyyy")
          : params.row.presEndDate}
      </SpText>
    ),
    sortComparator: (v1, v2, _, __) => moment(v1).isBefore(moment(v2)),
  },
  {
    type: "string",
    field: "pres_duration",
    numeric: false,
    editable: false,
    sortable: true,
    headerName: labels.groups.groupReports.firstTable.header.presDuration,
    flex: 1,
    valueGetter: (params) =>
      params.row.presDuration
        ? ""
        : moment().diff(moment(params.row.presStartDate), "days"),
    renderCell: (params) => (
      <SpText variant="tableText">
        {params.row.presDuration
          ? ""
          : moment().diff(moment(params.row.presStartDate), "days")}
      </SpText>
    ),
    sortComparator: (v1, v2, cellParams1, cellParams2) => 1,
  },
];
const headCellsSecond = [
  {
    field: "patientNameSurname",
    headerName:
      labels.groups.groupReports.firstTable.header.patient.toUpperCase(),
    editable: false,
    width: 200,
    renderCell: (params) => (
      <SpText variant="tableText" style={{ height: 32, minWidth: "100%" }}>
        {params?.row?.patientNameSurname}
      </SpText>
    ),
    valueGetter: (params) => params?.row?.patientNameSurname,
  },
];

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const GroupReports = (props) => {
  const [firstReports, setFirstReports] = useState(null);
  const [filterStartDate1, setFilterStartDate1] = useState();
  const [filterEndDate1, setFilterEndDate1] = useState();
  const [filterStartDate2, setFilterStartDate2] = useState();
  const [filterEndDate2, setFilterEndDate2] = useState();
  const [notAvailableObj, setNotAvailableObj] = useState();
  const [feedbacksList, setFeedbacksList] = useState([]);
  const [activitiesTypes, setActivitiesTypes] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState([]);
  const [feedbacksSelected, setFeedbacksSelected] = useState([]);
  const [actFeedData, setActFeedData] = useState([]);
  const [filterActStartDate, setFilterActStartDate] = useState();
  const [filterActEndDate, setFilterActEndDate] = useState();

  const { groupId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      const firstTableData = await getFirstTableGroupsData({
        id_group: groupId,
      });
      firstTableData && setFirstReports(firstTableData);

      //get all the feedback parameters
      const feedbackParametersList = await getAllFeedbackParameters();
      feedbackParametersList && setFeedbacksList(feedbackParametersList);
      //get all activities types
      const activitiesTypesData = await getAllActivitiesTypes();
      activitiesTypesData && setActivitiesTypes(activitiesTypesData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupReports - fetchData", error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const resetFilters = () => {
    setFilterActEndDate("");
    setFilterActStartDate("");
    setFeedbacksSelected([]);
    setActivitiesSelected([]);
  };
  const fetchActivityFeedbacksData = async () => {
    const secondTableData = await getSecondTableGroupsData({
      id_group: groupId,
      activitiesSelected: activitiesSelected,
      feedbacksSelected: feedbacksSelected,
      filterActStartDate: filterActStartDate,
      filterActEndDate: filterActEndDate,
    });
    //adding id field to th array of objects
    if (secondTableData) {
      updateSecondReportsRequestRow(secondTableData);
    }
  };
  useEffect(() => {
    if (activitiesSelected.length > 0 || feedbacksSelected.length > 0)
      fetchActivityFeedbacksData();
  }, [activitiesSelected, feedbacksSelected]);

  const renderHeadCells = (activitiesSelected, feedbacksSelected) => {
    if (activitiesSelected && feedbacksSelected) {
      let formattedHeaderAct = [];
      activitiesSelected.map((act) => {
        formattedHeaderAct.push({
          id: nanoid(),
          field:
            activitiesTypes.find((a) => a.id == act).name + "SessionsCount",
          headerName: (
            labels.groups.groupReports.secondTable.sessions +
            psTranslate(activitiesTypes.find((a) => a.id == act).name)
          )
            .toString()
            .toUpperCase(),
          editable: false,
          valueGetter: (params) => {
            const row =
              params.row[
                activitiesTypes.find((a) => a.id == act).name + "SessionsCount"
              ];
            if (row) {
              return row.count;
            } else {
              return 0;
            }
          },
          width: 200,
          renderCell: (params) => {
            const row =
              params.row[
                activitiesTypes.find((a) => a.id == act).name + "SessionsCount"
              ];

            if (row) {
              let spanPercent = 0;
              if (
                !isNaN(
                  parseFloat(
                    (100 * parseFloat(row.count)) / parseFloat(row.maxCount)
                  )
                )
              ) {
                spanPercent = parseFloat(
                  (100 * parseFloat(row.count)) / parseFloat(row.maxCount)
                );
              }
              return (
                <div
                  style={{
                    border: "1px solid #31caad",
                    height: 32,
                    minWidth: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#31ccad",
                      height: 30,
                      background: `linear-gradient(to right, rgb(49, 204, 173) ${spanPercent}, black 0%)`,
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 800,
                        fontSize: "1.2rem",
                        display: "flex",
                      }}
                    >
                      {row.count}
                    </span>
                  </div>
                </div>
              );
            } else {
              return;
            }
          },
        });
        formattedHeaderAct.push({
          id: nanoid(),
          field:
            activitiesTypes.find((a) => a.id == act).name + "SessionsMinutes",
          headerName: (
            labels.groups.groupReports.secondTable.mins +
            psTranslate(activitiesTypes.find((a) => a.id == act).name)
          )
            .toString()
            .toUpperCase(),
          editable: false,
          valueGetter: (params) => {
            const row =
              params.row[
                activitiesTypes.find((a) => a.id == act).name +
                  "SessionsMinutes"
              ];

            if (row) {
              return row.count;
            } else {
              return 0;
            }
          },
          width: 200,
          renderCell: (params) => {
            const row =
              params.row[
                activitiesTypes.find((a) => a.id == act).name +
                  "SessionsMinutes"
              ];
            if (row) {
              let spanPercent = 0;
              if (
                !isNaN(
                  parseFloat(
                    (100 * parseFloat(row.count)) / parseFloat(row.maxSessions)
                  )
                )
              ) {
                spanPercent = parseFloat(
                  parseFloat(100 * row.count) / parseFloat(row.maxSessions)
                );
              }
              return (
                <div
                  style={{
                    marginLeft: "1%",
                    border: "1px solid #31caad",
                    height: 32,
                    minWidth: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#31ccad",
                      height: 30,
                      background: `linear-gradient(to right, rgb(49, 204, 173) ${spanPercent}, black 0%)`,
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 800,
                        fontSize: "1.2rem",
                        display: "flex",
                      }}
                    >
                      {row.count}
                    </span>
                  </div>
                </div>
              );
            } else {
              return;
            }
          },
        });
      });
      const formattedHeaderFeed = feedbacksSelected.map((feed) => {
        return {
          id: nanoid(),
          field: feedbacksList.find((f) => f.id == feed).name,
          headerName: psTranslate(feedbacksList.find((f) => f.id == feed).name)
            .toString()
            .toUpperCase(),
          editable: false,
          value: (params) => {
            const row =
              params.row[feedbacksList.find((f) => f.id == feed).name];
            if (row) {
              return row.answer;
            } else {
              return 0;
            }
          },
          width: 200,
          renderCell: (params) => {
            const row =
              params.row[feedbacksList.find((f) => f.id == feed).name];
            if (row) {
              let spanPercent = 0;
              if (
                !isNaN(
                  parseFloat(
                    (100 * parseFloat(row.answer)) / parseFloat(row.maxAnswer)
                  )
                )
              ) {
                spanPercent = parseFloat(
                  parseFloat(100 * row.answer) / parseFloat(row.maxAnswer)
                );
              }
              return (
                <div
                  style={{
                    minWidth: "100%",
                    border: "1px solid #31caad",
                    height: 32,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#31ccad",
                      width: `100%`,
                      height: 30,
                      background: `linear-gradient(to right, rgb(49, 204, 173) ${spanPercent}, black 0%)`,
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 800,
                        fontSize: "1.2rem",
                        display: "flex",
                      }}
                    >
                      {row.answer}
                    </span>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    minWidth: "100%",
                    width: 200,
                    border: "1px solid #31caad",
                    height: 32,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#31ccad",
                      width: `100%`,
                      height: 30,
                      background: `transparent`,
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 800,
                        fontSize: "1.2rem",
                        display: "flex",
                      }}
                    >
                      0
                    </span>
                  </div>
                </div>
              );
            }
          },
        };
      });
      return [
        ...headCellsSecond,
        ...formattedHeaderAct,
        ...formattedHeaderFeed,
      ];
    }
  };

  const handleActivitiesSelected = (event) =>
    setActivitiesSelected(event.target.value);
  const handleFeedbackSelected = (event) =>
    setFeedbacksSelected(event.target.value);

  const filterDates = async () => {
    const thirdTableData = await getThirdTableGroupsData({
      id_group: groupId,
      filterStartDate1: filterStartDate1,
      filterEndDate1: filterEndDate1,
      filterStartDate2: filterStartDate2,
      filterEndDate2: filterEndDate2,
    });

    thirdTableData && setNotAvailableObj(thirdTableData);
  };

  const updateSecondReportsRequestRow = (secondTableData) => {
    let arr = [];

    secondTableData.map((row, index) => {
      let newElem = {
        id: nanoid(),
        patientNameSurname:
          row.patient.patientData.givenName +
          " " +
          row.patient.patientData.familyName,
        patStatus: row.patient.patStatus,
      };

      if (row?.activitiesData && row?.feedbacksData) {
        let maxCount = 0,
          maxSessions = 0;
        row?.activitiesData.map((act) => {
          //setting sessions count and max sessions number
          newElem[
            activitiesTypes.find((a) => a.id == act.activityId).name +
              "SessionsCount"
          ] = {
            count: 0,
            maxCount: 0,
          };

          newElem[
            activitiesTypes.find((a) => a.id == act.activityId).name +
              "SessionsCount"
          ].count = act?.numberOfSession?.count;
          if (act?.numberOfSession?.count > maxCount) {
            maxCount = act?.numberOfSession?.count;
            newElem[
              activitiesTypes.find((a) => a.id == act.activityId).name +
                "SessionsCount"
            ].maxCount = act?.numberOfSession?.count;
          } else {
            newElem[
              activitiesTypes.find((a) => a.id == act.activityId).name +
                "SessionsCount"
            ].maxCount = maxCount;
          }
          //setting sessions minutes and max sessions number
          newElem[
            activitiesTypes.find((a) => a.id == act.activityId).name +
              "SessionsMinutes"
          ] = {
            count: 0,
            maxSessions: 0,
          };
          newElem[
            activitiesTypes.find((a) => a.id == act.activityId).name +
              "SessionsMinutes"
          ].count = act?.numberOfActivities?.sessionMinutes;
          if (act?.numberOfActivities?.sessionMinutes > maxSessions) {
            maxSessions = act?.numberOfActivities?.sessionMinutes;
            newElem[
              activitiesTypes.find((a) => a.id == act.activityId).name +
                "SessionsMinutes"
            ].maxSessions = act?.numberOfActivities?.sessionMinutes;
          } else {
            newElem[
              activitiesTypes.find((a) => a.id == act.activityId).name +
                "SessionsMinutes"
            ].maxSessions = maxSessions;
          }
        });
        let formattedCellsFeed = [],
          maxAnswer = 0;
        if (row?.feedbacksData?.length === 0 && feedbacksSelected?.length > 0) {
          feedbacksSelected?.map((feed) => {
            newElem[feed.name] = { answer: 0, maxAnswer: 0 };
            newElem[feed.name].answer = 0;
            newElem[feed.name].maxAnswer = maxAnswer;
          });
        } else {
          row?.feedbacksData?.map((feed) => {
            if (feed?.answer > maxAnswer) maxAnswer = feed?.answer;
            newElem[feed.name] = { answer: 0, maxAnswer: 0 };
            newElem[feed.name].answer = feed?.answer;
            newElem[feed.name].maxAnswer = maxAnswer;
          });
        }
        arr.push({ ...newElem, ...formattedCellsFeed });
      }
    });
    setActFeedData(arr);
  };

  useEffect(
    () => fetchActivityFeedbacksData(),
    [filterActStartDate, filterActEndDate]
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push(`/groups/edit/${groupId}`)}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">{labels.groups.toolbar.back}</SpText>
            </TitleToolbarSection>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} direction="column">
          <SpText variant="h1PageTitle">
            {labels.groups.groupReports.firstTable.title}
          </SpText>

          {firstReports && (
            <SpDataGrid
              headers={headCellsFirst}
              rows={firstReports}
              emptyText={labels.groups.groupReports.no_patients}
            />
          )}
          <SpText
            variant="h1PageTitle"
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            {labels.groups.groupReports.secondTable.title}
          </SpText>
          <Grid item xs={12} container direction="column">
            <Grid item xs={4} container direction="column">
              <Grid item>
                <SpSelect
                  label={labels.groups.groupReports.secondTable.chooseActivity}
                  selectPlaceholder={
                    labels.groups.groupReports.secondTable.selectValue
                  }
                  multiple
                  formControlStyle={{ minWidth: "100%" }}
                  value={activitiesSelected}
                  onChange={handleActivitiesSelected}
                >
                  {activitiesTypes.map((lp) => (
                    <SpSelectMenuItem key={lp.id} value={lp.id}>
                      {psTranslate(lp.name)}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              </Grid>
              <Grid item>
                <SpSelect
                  label={labels.groups.groupReports.secondTable.chooseActivity}
                  selectPlaceholder={
                    labels.groups.groupReports.secondTable.selectValue
                  }
                  multiple
                  formControlStyle={{ minWidth: "100%" }}
                  value={feedbacksSelected}
                  onChange={handleFeedbackSelected}
                >
                  {feedbacksList.map((lp) => (
                    <SpSelectMenuItem key={lp.id} value={lp.id}>
                      {psTranslate(lp.name)}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              </Grid>
              <Grid item style={{ marginTop: 8 }}>
                <SpTextInput
                  label={
                    labels.groups.groupReports.secondTable.searchInputStart
                  }
                  type="date"
                  maxValue={"2100-12-31"}
                  variant="text"
                  formControlStyle={{ minWidth: "100%" }}
                  value={filterActStartDate}
                  onChange={(e) => setFilterActStartDate(e.target.value)}
                />
              </Grid>
              <Grid item style={{ marginTop: 8 }}>
                <SpTextInput
                  label={labels.groups.groupReports.secondTable.searchInputEnd}
                  type="date"
                  maxValue={"2100-12-31"}
                  variant="text"
                  formControlStyle={{ minWidth: "100%" }}
                  value={filterActEndDate}
                  onChange={(e) => setFilterActEndDate(e.target.value)}
                />
              </Grid>
              <Grid item>
                <SpButton
                  buttonType="accept"
                  style={{ marginTop: 8 }}
                  text={labels.groups.groupReports.secondTable.searchReset}
                  onClick={() => {
                    resetFilters();
                    resetFilters();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2%", height: 400 }}>
              <SpDataGrid
                headers={renderHeadCells(activitiesSelected, feedbacksSelected)}
                rows={actFeedData}
              />
            </Grid>
          </Grid>
          <SpText
            variant="h1PageTitle"
            style={{ marginTop: "1%", marginBottom: "1%" }}
          >
            {labels.groups.groupReports.thirdTable.title}
          </SpText>
          <Grid item xs={12} container direction="row">
            <Grid item xs={4} container direction="column">
              <Grid item>
                <SpTextInput
                  type="date"
                  value={filterStartDate1}
                  style={{ width: "100%" }}
                  maxValue={"2100-12-31"}
                  onChange={(e) => setFilterStartDate1(e.target.value)}
                  label={
                    labels.groups.groupReports.thirdTable.startDateFirstPeriod
                  }
                />
              </Grid>
              <Grid item>
                <SpTextInput
                  type="date"
                  value={filterEndDate1}
                  style={{ width: "100%" }}
                  maxValue={"2100-12-31"}
                  onChange={(e) => setFilterEndDate1(e.target.value)}
                  label={
                    labels.groups.groupReports.thirdTable.endDateFirstPeriod
                  }
                />
              </Grid>
              <Grid item>
                <SpTextInput
                  type="date"
                  value={filterStartDate2}
                  style={{ width: "100%" }}
                  maxValue={"2100-12-31"}
                  onChange={(e) => setFilterStartDate2(e.target.value)}
                  label={
                    labels.groups.groupReports.thirdTable.startDateSecondPeriod
                  }
                />
              </Grid>
              <Grid item>
                <SpTextInput
                  type="date"
                  value={filterEndDate2}
                  style={{ width: "100%" }}
                  maxValue={"2100-12-31"}
                  onChange={(e) => setFilterEndDate2(e.target.value)}
                  label={
                    labels.groups.groupReports.thirdTable.endDateSecondPeriod
                  }
                />
              </Grid>
              <Grid item>
                <SpButton
                  buttonType={"accept"}
                  text={labels.groups.groupReports.thirdTable.filter}
                  onClick={() => filterDates()}
                  style={{ width: "100%", marginTop: "5px" }}
                />
              </Grid>
            </Grid>
            <Grid xs={4} style={{ marginLeft: "1%", alignSelf: "center" }}>
              <Grid
                item
                style={{ border: "1px solid #31ccad", padding: "8px" }}
                direction="column"
              >
                <SpText variant="h4regular">
                  {labels.groups.groupReports.thirdTable.unavalDaysFirstPeriod}
                </SpText>
                <SpText variant="h1PageSubtitle">
                  {notAvailableObj?.notAvailableDaysFirstPeriod ?? 0}
                </SpText>
              </Grid>
              <Grid
                item
                style={{ border: "1px solid #31ccad", padding: "8px" }}
                direction="column"
              >
                <SpText variant="h4regular">
                  {labels.groups.groupReports.thirdTable.unavalDaysSecondPeriod}
                </SpText>
                <SpText variant="h1PageSubtitle">
                  {notAvailableObj?.notAvailableDaysSecondPeriod ?? 0}
                </SpText>
              </Grid>
              <Grid
                item
                style={{ border: "1px solid #31ccad", padding: "8px" }}
                direction="column"
              >
                <SpText variant="h4regular">
                  {labels.groups.groupReports.thirdTable.diffUnavalDays}
                </SpText>
                <SpText variant="h1PageSubtitle">
                  {notAvailableObj?.notAvailableDaysFirstPeriod &&
                  notAvailableObj?.notAvailableDaysSecondPeriod
                    ? notAvailableObj?.notAvailableDaysFirstPeriod -
                      notAvailableObj?.notAvailableDaysSecondPeriod
                    : 0}
                </SpText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(GroupReports);
