import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import PROMS from "./patientsReportsPROMS/PROMS";
import { getAllPromsSurveyAnswers } from "../../../models/actions/Proms";
import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
import { calculateFinalScore } from "./patientsReportsPROMS/PROMSHelperFns";

const commonDateFormat = "YYYY-MM-DD";

const PatientsReportsStep2PROMs = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [promsData, setPromsData] = useState({});

  const { patId } = useParams();

  const fetchPromsData = async (filterRange) => {
    try {
      //get all proms parameters
      const resultPromsAnswers = await getAllPromsSurveyAnswers({
        id_patient: patId,
        starting_date: filterRange.start,
        ending_date: filterRange.end,
      });

      updateProms(resultPromsAnswers);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateProms = (resultPromsAnswers) => {
    //fetchData for proms and calculate the final score
    if (resultPromsAnswers) {
      let newPromsGraphData = {};
      resultPromsAnswers.forEach((promInstance) => {
        const answer = promInstance.proms_answers[0];
        if (answer) {
          //Obtain date
          const date = moment(promInstance.date).startOf("day");

          //Generate graph data
          const finalScores = calculateFinalScore(
            promInstance.prom,
            promInstance.proms_answers
          );

          const name = promInstance.prom.name;
          finalScores.forEach(({ label, value }) => {
            if (!isNaN(value)) {
              if (!newPromsGraphData[name]) newPromsGraphData[name] = {};
              if (!newPromsGraphData[name][label])
                newPromsGraphData[name][label] = {};
              newPromsGraphData[name][label][date.format(commonDateFormat)] =
                value;
            }
          });
        }
      });

      //Format: { proms_name: { proms_label: { day: value, ... }, ... }, ... }
      setPromsData(newPromsGraphData);
    }
  };

  return (
    <Grid item xs={12}>
      <PROMS
        data={promsData}
        onDataRequest={fetchPromsData}
        defaultZero={false}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
      />
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep2PROMs);
