import React, { useEffect, useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Grid from "@material-ui/core/Grid";
import SpButton from "../../../components/atoms/SpButton";
import { labels, psTranslate } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { styled } from "../../../components/styled";
import { useHistory, useParams } from "react-router";
import { SpList } from "../../../components/bundles/SpList";
import { ArrowBack } from "@material-ui/icons";
import { Box, Divider } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {
  createExcerciseTemplate,
  getAllExercises,
  getExerciseTemplateDetail,
  updateExerciseTemplate,
} from "../../../models/actions/Excercise";
import SpTextArea from "../../../components/atoms/SpTextArea";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import { createFilterOptions } from "@material-ui/lab";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpExerciseDialog from "../../../components/atoms/SpExerciseDialog";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import ExerciseDetailContainer from "../../../components/atoms/ExerciseDetailContainer";
import { nanoid } from "nanoid";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const StyledRowList = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "0.5rem",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

//rows for the exercise
const RowExercise = ({
  row,
  index,
  headers,
  control,
  register,
  idElement,
  exercisesList,
  filterOptions,
  setExercisesDetail,
  setDialogExerciseOpen,
}) => {
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: "#31ccad",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        />
      )}
      <StyledRowList item xs={12} container>
        <Grid xs={2}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[0]}
            </SpText>
            <Controller
              render={(props) => (
                <SpAutocomplete
                  formControlWidth={"100%"}
                  disableListWrap
                  selectPlaceholder={labels.mylab.selectExercise}
                  value={exercisesList.find((el) => el.id == props.value)}
                  onChange={(e, newValue) => props.onChange(newValue.id)}
                  options={exercisesList}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) => option.id === value?.id}
                  filterOptions={filterOptions}
                  renderOption={(option) => (
                    <Grid
                      direction="row"
                      container
                      alignItems={"center"}
                      alignSelf={"center"}
                    >
                      <Grid item xs={2}>
                        <SpIconButton
                          buttonType={"accept"}
                          small
                          onClick={(e) => {
                            setExercisesDetail(option);
                            setDialogExerciseOpen(true);
                          }}
                        >
                          <InfoIcon />
                        </SpIconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <SpText variant="text" color="black">
                          {psTranslate(option.name)}
                        </SpText>
                      </Grid>
                    </Grid>
                  )}
                />
              )}
              defaultValue={row.id_excercise ? row.id_excercise : null}
              style={{ width: "98%" }}
              name={`excercise_sheet_template_row[${index}].id_excercise`}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid xs={2}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[1]}
            </SpText>
            <SpTextInput
              disabled={idElement}
              type="number"
              minValue={0}
              style={{ width: "98%" }}
              name={`excercise_sheet_template_row[${index}].series`}
              inputRef={register}
              defaultValue={row.series}
            />
          </Grid>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[2]}
            </SpText>
            <SpTextInput
              disabled={idElement}
              type="number"
              minValue={0}
              style={{ width: "98%" }}
              name={`excercise_sheet_template_row[${index}].repetition_time`}
              inputRef={register}
              defaultValue={row.repetition_time}
            />
          </Grid>
        </Grid>

        <Grid xs={3}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[3]}
            </SpText>
            <SpTextInput
              disabled={idElement}
              type="number"
              minValue={0}
              style={{ width: "98%" }}
              name={`excercise_sheet_template_row[${index}].resting`}
              inputRef={register}
              defaultValue={row.resting}
            />
          </Grid>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[4]}
            </SpText>
            <SpTextInput
              disabled={idElement}
              type="number"
              minValue={0}
              style={{ width: "98%" }}
              name={`excercise_sheet_template_row[${index}].load`}
              inputRef={register}
              defaultValue={row.load}
            />
          </Grid>
        </Grid>

        <Grid xs={3}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[5]}
            </SpText>
            <SpTextInput
              disabled={idElement}
              name={`excercise_sheet_template_row[${index}].accessories`}
              style={{ width: "98%" }}
              inputRef={register}
              defaultValue={row.accessories}
            />
          </Grid>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[6]}
            </SpText>
            <SpTextInput
              disabled={idElement}
              name={`excercise_sheet_template_row[${index}].execution`}
              style={{ width: "98%" }}
              inputRef={register}
              defaultValue={row.execution}
            />
          </Grid>
        </Grid>
      </StyledRowList>
      <StyledRow item xs={12} container>
        <Grid item xs>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header[7]}
          </SpText>
          <SpTextArea
            disabled={idElement}
            name={`excercise_sheet_template_row[${index}].note`}
            style={{ width: "100%" }}
            inputRef={register}
            defaultValue={row.note}
          />
        </Grid>
      </StyledRow>
    </div>
  );
};

const AddExercise = (props) => {
  const [exercisesList, setExercisesList] = useState([]);
  const [exerciseDetail, setExercisesDetail] = useState(null);
  const [dialogExerciseOpen, setDialogExerciseOpen] = useState(false);
  const [showDataset, setShowDataset] = useState(false);
  const [showExerciseContainerDetail, setShowExerciseContainerDetail] =
    useState(false);

  const history = useHistory();
  const { register, control, watch, getValues, setValue, handleSubmit, reset } =
    useForm({
      shouldUnregister: false,
      defaultValues: {},
    });

  const { idElement } = useParams();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "excercise_sheet_template_row",
    keyName: "id",
    defaultValue: [],
  });

  const headersExercise = [
    {
      type: "string",
      field: "name",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.mylab.tempAdd.excerciseSheet.list.header[0],
      flex: 1,
      renderCell: (params) => (
        <SpIconAndTextButton
          isIconRight={true}
          text={`${psTranslate(params.row.name)}`}
          textVariant={"tableText"}
          onClick={() => {
            setShowExerciseContainerDetail(true);
            setExercisesDetail(params.row);
          }}
        >
          <ArrowForwardIosIcon
            style={{
              width: 10,
              color: "#31ccad",
              height: 30,
              cursor: "pointer",
            }}
          />
        </SpIconAndTextButton>
      ),
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        (v1 ?? "").localeCompare(v2),
    },
    {
      type: "string",
      field: "tags",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: "Tags",
      flex: 1,
      renderCell: (params) => (
        <SpText variant="tableText">{psTranslate(params.row.tags)}</SpText>
      ),
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        (v1 ?? "").localeCompare(v2),
    },
  ];

  const filterOptions = createFilterOptions({
    limit: 20,
    stringify: (option) => option.name + option.tags,
  });

  const watchQuant = watch("excercise_quantity");

  useEffect(() => {
    if (!idElement && !dialogExerciseOpen) {
      if (parseInt(getValues().excercise_quantity) > 20) {
        setValue("excercise_quantity", 20);
      } else if (parseInt(getValues().excercise_quantity) < 1) {
        setValue("excercise_quantity", 1);
      } else {
        const currLen = fields.length;
        for (let i = currLen; i < control.getValues().excercise_quantity; i++)
          append({ id: nanoid() }, false);
        for (
          let i = currLen - 1;
          i >= control.getValues().excercise_quantity;
          i--
        )
          remove(i);
      }
    }
  }, [watchQuant]);

  const fetchData = async () => {
    try {
      const results = await getAllExercises();
      setExercisesList(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    if (idElement) {
      try {
        const result = await getExerciseTemplateDetail({
          id_template: idElement,
        });
        //console.log(result?.excercise_sheet_template_rows);
        result?.excercise_sheet_template_rows &&
          setValue(
            "excercise_sheet_template_row",
            result?.excercise_sheet_template_rows
          );
        setValue("excercise_quantity", result.excercise_quantity);
        setValue("name", result.name);
        setExercisesDetail(result);
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const editTemplate = async (data) => {
    try {
      const result = await updateExerciseTemplate({
        ...data,
        id_exercise_template: idElement,
      });
      if (result.message) {
        props.snackbarShowMessage(result.message);
        history.push("/mylab/excercise");
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveExerciseTemplate = async (data) => {
    if (getValues().name === "") {
      props.snackbarShowErrorMessage(labels.mylab.insertTemplateName);
      return;
    }
    try {
      const result = await createExcerciseTemplate({ ...data });
      if (result.message) {
        props.snackbarShowMessage(result.message);
        history.push("/mylab/excercise");
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const LabelValue = (props) =>
    props.value ? (
      <Grid direction="column" style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    ) : null;

  const LabelValueExcerciseInfo = (props) =>
    props.value ? (
      <Grid direction="column" style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <SpIconButton
            style={{ margin: "5px !important", marginTop: "0px !important" }}
            buttonType={"accept"}
            small
            onClick={(e) => {
              setExercisesDetail({
                excercise_quantity: exerciseDetail?.excercise_quantity,
                ...props?.item?.excercise,
                file: exercisesList.find(
                  (elem) => elem?.id_file == props?.item?.excercise?.id_file
                ).file,
              });
              setDialogExerciseOpen(true);
            }}
          >
            <InfoIcon />
          </SpIconButton>
          <SpText variant="h4ComponentLabel">{props.value}</SpText>
        </div>
      </Grid>
    ) : null;

  const DetailExercises = (props) => {
    return (
      <Grid container xs={12}>
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: "#31ccad",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        />
        {props.rows.map((item) => (
          <Grid
            container
            xs={4}
            style={{ border: "1px solid #31ccad", padding: "8px" }}
            direction="column"
          >
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[0]}
              value={item.excercise?.name}
            />
            <LabelValueExcerciseInfo
              item={item}
              label={labels.mylab.tempAdd.excerciseSheet.list.guidance}
              value={item.excercise?.guidance_instructions}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[1]}
              value={item.series}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[2]}
              value={item.repetition_time}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[3]}
              value={item.resting}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[4]}
              value={item.load}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[5]}
              value={item.accessories}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[6]}
              value={item.execution}
            />
            <LabelValue
              label={labels.mylab.tempAdd.excerciseSheet.list.header[7]}
              value={item.note}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const DataSet = () => (
    <Grid item xs={12} style={{ marginTop: "2%" }}>
      <SpDataGrid
        headers={headersExercise}
        rows={exercisesList}
        hideFooterPagination={false}
        showPagination={true}
      />
    </Grid>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab/excercise")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.excerciseSheet.title}
                </span>
              </SpText>
              <SpButton
                text={
                  showExerciseContainerDetail
                    ? labels.mylab.tempAdd.excerciseSheet.back
                    : showDataset
                    ? labels.mylab.tempAdd.excerciseSheet.hideDataset
                    : labels.mylab.tempAdd.excerciseSheet.showDataset
                }
                buttonType="accept"
                variant="none"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  if (showExerciseContainerDetail) {
                    setShowExerciseContainerDetail(false);
                    setExercisesDetail(null);
                  } else {
                    setShowDataset(!showDataset);
                  }
                }}
              />
            </TitleToolbarSection>
          </Grid>
          {fields.length > 0 && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                variant="none"
                style={{ marginLeft: "2%" }}
                text={
                  idElement
                    ? labels.mylab.tempAdd.excerciseSheet.actions.edit
                    : labels.mylab.tempAdd.excerciseSheet.actions.create
                }
                buttonType="accept"
                onClick={() =>
                  handleSubmit(
                    idElement ? editTemplate : saveExerciseTemplate
                  )()
                }
              />
            </Grid>
          )}
        </Grid>
        {!showExerciseContainerDetail &&
          (showDataset ? (
            <DataSet />
          ) : (
            <form>
              <Grid
                item
                xs={12}
                container
                style={{ justifyContent: "flex-start" }}
              >
                <Grid item xs={6}>
                  <SpTextInput
                    style={{ width: "95%" }}
                    name={`name`}
                    inputRef={register}
                    label={labels.mylab.tempAdd.excerciseSheet.inputs.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  {idElement ? (
                    <Grid direction="row" container>
                      <SpText
                        variant="h4ComponentLabelColored"
                        style={{ border: "1px solid #31ccad", padding: "8px" }}
                      >
                        {labels.mylab.tempAdd.excerciseSheet.inputs.quantity}
                        <Box color={"#fff"}>
                          {exerciseDetail?.excercise_quantity}
                        </Box>
                      </SpText>
                    </Grid>
                  ) : (
                    <SpTextInput
                      style={{ maxWidth: "40%" }}
                      type="number"
                      name="excercise_quantity"
                      minValue={0}
                      inputRef={register}
                      label={
                        labels.mylab.tempAdd.excerciseSheet.inputs.quantity
                      }
                      disabled={idElement}
                    />
                  )}
                </Grid>
              </Grid>
              {getValues("quantity") > 0 && (
                <StyledRow>
                  <SpText variant="h1PageSubtitle">
                    {labels.mylab.tempAdd.excerciseSheet.list.title}
                  </SpText>
                </StyledRow>
              )}
              {fields.length > 0 &&
                (idElement ? (
                  <DetailExercises rows={fields} />
                ) : (
                  <StyledRow style={{ border: "1px solid #31ccad" }}>
                    <SpList
                      rows={fields}
                      rowKey="id"
                      checkable={false}
                      deletable={false}
                    >
                      <RowExercise
                        headers={
                          labels.mylab.tempAdd.excerciseSheet.list.header
                        }
                        idElement={idElement}
                        control={control}
                        register={register}
                        {...{
                          exercisesList,
                          filterOptions,
                          setExercisesDetail,
                          setDialogExerciseOpen,
                        }}
                      />
                    </SpList>
                  </StyledRow>
                ))}
              {fields.length > 0 && (
                <Grid
                  item
                  xs={12}
                  spacing={0}
                  style={{ paddingLeft: "0", marginTop: "1%" }}
                  container
                  justify={"flex-end"}
                >
                  {!idElement && (
                    <SpButton
                      text={labels.mylab.tempAdd.excerciseSheet.actions.create}
                      buttonType="accept"
                      variant="h1"
                      onClick={() => handleSubmit(saveExerciseTemplate)()}
                    />
                  )}
                </Grid>
              )}
            </form>
          ))}
      </Grid>
      {showExerciseContainerDetail && exerciseDetail && (
        <ExerciseDetailContainer exerciseDetail={exerciseDetail} />
      )}
      <SpExerciseDialog
        dialogExerciseOpen={dialogExerciseOpen}
        setDialogExerciseOpen={setDialogExerciseOpen}
        exerciseDetail={exerciseDetail}
      />
    </>
  );
};
export default withSnackbar(AddExercise);
