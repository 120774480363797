import { Box, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import { SpList } from "../../components/bundles/SpList";
import SpTextArea from "../../components/atoms/SpTextArea";
import Feedback from "../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../components/bundles/calendar_plan/Feedbacks";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import { setActivityFeedbackParameters } from "../../models/actions/Activity";
import {
  archiveActivityById,
  deleteCalendarActivityById,
} from "../../models/actions/CalendarActivity";
import { getAllExercises } from "../../models/actions/Excercise";
import { labels, psTranslate } from "../shared/translations";
import A1_AssessmentInputDetails from "./inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "./inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "./inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "./inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "./inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "./inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "./inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "./inputDetails/A8_RehabilitationInputDetails";
import { rollbar } from "../../utils/common";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const AgendaCalendarEventCard = ({
  event,
  setOpenDetailActivity,
  fetchData,
  updateActivity,

  createActivityObject,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  archiveEnabled = true,
  forceDisabled = false,
  allowFeedback = true,

  isGroupCard = false,

  ...props
}) => {
  const [openDeleteActivity, setOpenDeleteActivity] = useState(false);
  const [exercisesList, setExercisesList] = useState([]);
  const history = useHistory();

  const { handleSubmit, register, setValue, getValues, control } = useForm({
    shouldUnregister: false,
  });

  const isDisabled = () => {
    return (
      forceDisabled ||
      event.archiving_status === "completed_and_feedback_not_sent" ||
      event.archiving_status === "completed_and_feedback_sent"
    );
  };

  const deleteCalendarActivity = async (e) => {
    e.stopPropagation();

    try {
      if (event.is_from_template) {
        await updateActivity(event, true /*delete*/);
      } else {
        const result = await deleteCalendarActivityById({
          id_activity: event.id_activity_group ? null : event.id,
          id_activity_group: event.id_activity_group, //for groups
        });
        if (result.error) {
          props.snackbarShowErrorMessage(result.error);
        } else {
          await fetchData();
          props.snackbarShowMessage(result.message);
        }
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - deleteCalendarActivity", error);
      props.snackbarShowErrorMessage(error);
    }
    setOpenDetailActivity(false);
  };

  const updateActivityFeedback = async (data) => {
    try {
      const answers = data.feed_resp_ans.filter((a) => a);
      const result = await setActivityFeedbackParameters({
        feedback_parameters_answers: answers,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result?.message);
        await fetchData();
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - updateActivityFeedback", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateActivityContents = async (data) => {
    const updatedEvnt = {
      id: isGroupCard ? null : event.id,
      id_activity_group: isGroupCard ? event.id_activity_group : null, //for groups
      start_date: event.start_date,
      event_note: getValues("note"),
      data: createActivityObject(data, event.id_activity_type).details_data,
      feedback_parameter: loadParametersList,
    };
    await updateActivity(updatedEvnt);
    setOpenDetailActivity(false);
    await fetchData();
  };

  const updateAndArchiveActivity = async (data) => {
    const updatedEvnt = {
      id: isGroupCard ? null : event.id,
      id_activity_group: isGroupCard ? event.id_activity_group : null, //for groups
      start_date: event.start_date,
      event_note: getValues("note"),
      data: createActivityObject(data, event.id_activity_type).details_data,
      feedback_parameter: loadParametersList,
    };
    let archiveStatus;
    if (event.feedbacks.map((elem) => elem.answer).length > 0) {
      archiveStatus = "completed_and_feedback_sent";
    } else {
      archiveStatus = "completed_and_feedback_not_sent";
    }
    try {
      await updateActivity(updatedEvnt);
      const result = await archiveActivityById({
        id: isGroupCard ? null : event.id,
        id_activity_group: isGroupCard ? event.id_activity_group : null, //for groups
        archiving_status: archiveStatus,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result?.message);
        setOpenDetailActivity(false);
        await fetchData();
      }
    } catch (error) {
      rollbar.error(
        "AgendaCalendarEventCard - updateAndArchiveActivity",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  //Read only rows
  const RowTraining = ({ row, index }) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        {index > 0 && (
          <Divider
            style={{
              padding: "1px",
              width: "100%",
              backgroundColor: "#31ccad",
            }}
          />
        )}
        <Grid
          item
          xs={12}
          container
          justify="flex-start"
          style={{ marginTop: index > 0 && "1%" }}
        >
          <Grid item xs={4}>
            <SpText variant="h4ComponentLabelCenter">{row.activity}</SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText variant="h4ComponentLabelCenter">{row.time}</SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText variant="h4ComponentLabelCenter">{row.description}</SpText>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderTrainingInputDetails = () => {
    const data = event.data;
    return (
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant="h4ComponentLabel">{event.data.name}</SpText>
        </Grid>
        {data?.training?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.training.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.training?.length > 0 && (
          <Grid item xs={12} container style={{ border: "1px solid #31ccad" }}>
            <Grid item xs={12}>
              <SpList
                rows={data.training}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.training.list.header.map((head) => (
                      <Grid item xs={4}>
                        <SpText key={head} variant="listHeaderCenter">
                          {head}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <RowTraining
                  headers={labels.mylab.tempAdd.training.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}
        {allowFeedback &&
          (isGroupCard ? (
            <Feedbacks
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
            />
          ) : (
            <Feedback
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
            />
          ))}
      </Grid>
    );
  };

  const RowTreatment = ({ row, index }) => (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{ padding: "1px", width: "100%", backgroundColor: "#31ccad" }}
        />
      )}
      <Grid
        item
        xs={12}
        container
        justify="flex-start"
        style={{ marginTop: index > 0 && "1%" }}
      >
        <Grid item xs={4}>
          <SpText variant="h4ComponentLabelCenter">{row.activity}</SpText>
        </Grid>
        <Grid item xs={4}>
          <SpText variant="h4ComponentLabelCenter">{row.duration}</SpText>
        </Grid>
        <Grid item xs={4}>
          <SpText variant="h4ComponentLabelCenter">{row.description}</SpText>
        </Grid>
      </Grid>
    </div>
  );

  const renderTreatmentInputDetails = () => {
    const data = event.data;
    return (
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant="h4ComponentLabel">{event.data.name}</SpText>
        </Grid>
        {data?.treatments?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.treatment.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.treatments?.length > 0 && (
          <Grid item xs={12} container style={{ border: "1px solid #31ccad" }}>
            <Grid item xs={12}>
              <SpList
                rows={data.treatments}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.treatment.list.header.map((head) => (
                      <Grid item xs={4}>
                        <SpText key={head} variant="listHeaderCenter">
                          {head}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <RowTreatment
                  headers={labels.mylab.tempAdd.treatment.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const RowExercise = ({ row, index }) => (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{ padding: "1px", width: "100%", backgroundColor: "#31ccad" }}
        />
      )}
      <Grid item xs={12} container>
        <Grid item xs={2}>
          <SpText variant="h4ComponentLabelCenter">
            {psTranslate(
              exercisesList.find(
                (excercise) => excercise.id == row.id_excercise
              )?.name
            )}
          </SpText>
        </Grid>
        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.series}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.repetition_time}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.resting}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.load}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.accessories}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.execution}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.note}
          </SpText>
        </Grid>
      </Grid>
    </div>
  );

  const renderExerciseInputDetails = () => {
    const data = event.data;
    return (
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant="h4ComponentLabel">{data.name}</SpText>
        </Grid>
        {data?.excercises?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.excerciseSheet.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.excercises?.length > 0 && (
          <Grid item xs={12} container style={{ border: "1px solid #31ccad" }}>
            <Grid item xs={12}>
              <SpList
                rows={data?.excercises}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.excerciseSheet.list.header.map(
                      (head, idx) => (
                        <Grid
                          item
                          xs={
                            idx == 0 || idx == 7 || idx == 6 || idx == 5 ? 2 : 1
                          }
                        >
                          <SpText key={head} variant="listHeaderCenter">
                            {head}
                          </SpText>
                        </Grid>
                      )
                    )}
                  </Grid>
                }
              >
                <RowExercise
                  headers={labels.mylab.tempAdd.treatment.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}
        {allowFeedback &&
          (isGroupCard ? (
            <Feedbacks
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
            />
          ) : (
            <Feedback
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
            />
          ))}
      </Grid>
    );
  };

  const renderRaceInputDetails = () => {
    let data = event.data;
    return (
      <Grid direction="row" container style={{ marginBottom: "2%" }}>
        <SpText variant="h4ComponentLabel">
          {labels.patient.viewPlan.addActivity.inputForm.raceMinutes}{" "}
          <Box color={"#31ccad"} style={{ display: "inline-block" }}>
            {data?.race_minutes}
          </Box>
        </SpText>
      </Grid>
    );
  };

  const RowRehabilitation = ({ row, index }) => (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{ padding: "1px", width: "100%", backgroundColor: "#31ccad" }}
        />
      )}
      <Grid
        item
        xs={12}
        container
        justify="flex-start"
        style={{ marginTop: index > 0 && "1%" }}
      >
        <Grid item xs={4}>
          <SpText variant="h4ComponentLabelCenter">{row.activity}</SpText>
        </Grid>

        <Grid item xs={4}>
          <SpText variant="h4ComponentLabelCenter">{row.duration}</SpText>
        </Grid>

        <Grid item xs={4}>
          <SpText variant="h4ComponentLabelCenter">{row.description}</SpText>
        </Grid>
      </Grid>
    </div>
  );

  const renderRehabilitationInputDetails = () => {
    let data = event.data;
    return (
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant="h4ComponentLabel">{data.name}</SpText>
        </Grid>
        {data?.rehabilitations?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.treatment.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.rehabilitations?.length > 0 && (
          <Grid item xs={12} container style={{ border: "1px solid #31ccad" }}>
            <Grid item xs={12}>
              <SpList
                rows={data?.rehabilitations}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.treatment.list.header.map((head) => (
                      <Grid item xs={4}>
                        <SpText key={head} variant="listHeaderCenter">
                          {head}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <RowRehabilitation
                  headers={labels.mylab.tempAdd.treatment.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const buildSubject = () => {
    let motivation = "";
    const data = event.data;
    switch (event.id_activity_type) {
      case 1:
        motivation = data?.assessment_motivation;
        break;
      case 2:
        motivation = data?.treatments_motivation;
        break;
      case 3:
        motivation = data?.excercises_motivation;
        break;
      case 4:
        motivation = data?.training_motivation;
        break;
      case 5:
        motivation = data?.rest_motivation;
        break;
      case 6:
        motivation = data?.appointment_motivation;
        break;
      case 7:
        motivation = data?.race_motivation;
        break;
      case 8:
        motivation = data?.rehabilitation_motivation;
        break;
      default:
        break;
    }
    return (
      <>
        <SpText variant="text">{labels.agenda.card.subject}</SpText>
        <SpText variant="h4ComponentLabel">{motivation}</SpText>
      </>
    );
  };

  //Render input popup details
  const renderInputDetails = () => {
    if (isDisabled()) {
      return (
        <>
          {buildSubject()}
          {(() => {
            switch (event.id_activity_type) {
              case 2:
                //Treatment
                return renderTreatmentInputDetails();
              case 3:
                //Exercise
                return renderExerciseInputDetails();
              case 4:
                //Training
                return renderTrainingInputDetails();
              case 7:
                //Race
                return renderRaceInputDetails();
              case 8:
                //Rehab
                return renderRehabilitationInputDetails();
            }
          })()}
        </>
      );
    } else {
      //Still editable
      const Detail = INPUT_DETAILS[event.id_activity_type];
      return (
        <Grid direction="column" container style={{ marginBottom: "2%" }}>
          <Detail
            watchTemplateSelect={null}
            selectedTemplate={null}
            templateData={event.data}
            loadParametersData={loadParametersData}
            loadParametersList={loadParametersList}
            setLoadParametersList={setLoadParametersList}
            control={control}
            setValue={setValue}
            register={register}
            disabled={isDisabled()}
          />
          {(event.id_activity_type == 4 || event.id_activity_type == 3) &&
            allowFeedback &&
            (isGroupCard ? (
              <Feedbacks
                click={handleSubmit(updateActivityFeedback)}
                setValue={setValue}
                event={event}
                register={register}
              />
            ) : (
              <Feedback
                click={handleSubmit(updateActivityFeedback)}
                setValue={setValue}
                event={event}
                register={register}
              />
            ))}
        </Grid>
      );
    }
  };

  useEffect(async () => {
    const results = await getAllExercises();
    setExercisesList(results);
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          padding: 15,
          background: theme.colors.primary.grey,
          minWidth: 800,
          width: "100%",
        }}
      >
        <Grid item xs={12} direction="row" container>
          <img
            src={event.media}
            style={{ width: 60, height: 60, marginRight: "8px" }}
            alt={""}
          />
          <Grid
            item
            direction="column"
            xs
            style={{
              display: "inline-block",
              verticalAlign: "top",
              width: "100%",
            }}
          >
            <SpText variant="h1PageSubtitleColored">
              {psTranslate(event.title)}
              {(event.id_activity_type === 3 ||
                event.id_activity_type === 4) && (
                <SpButton
                  buttonType={"accept"}
                  style={{ marginLeft: "5px", height: 20 }}
                  variant="h4ComponentLabel"
                  onClick={() =>
                    history.push(
                      `/mylab/${
                        event.id_activity_type === 3 ? `excercise` : `training`
                      }/add`
                    )
                  }
                  text={labels.agenda.card.prepare}
                />
              )}
            </SpText>
          </Grid>
          <Grid container xs={12} direction="row" style={{ marginTop: "10px" }}>
            {event.start_time && event.end_time && (
              <Grid item xs={6} style={{ marginTop: "5px" }}>
                <SpText variant="text">{labels.agenda.card.time}</SpText>
                <SpText variant="h4ComponentLabel">
                  {event.start_time.substring(0, 5) +
                    " - " +
                    event.end_time.substring(0, 5)}
                </SpText>
              </Grid>
            )}
            {event.patient ? (
              <Grid
                item
                xs={6}
                container
                style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
                direction="column"
              >
                <SpText variant="h4ComponentLabel">
                  {event.patient.givenName + " " + event.patient.familyName}
                </SpText>
                <SpButton
                  variant="h4ComponentLabel"
                  onClick={() =>
                    history.push(`/patients/edit/${event.patient.id}/plan`)
                  }
                  text={labels.agenda.card.goToActivityPlan}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {event.data && renderInputDetails()}
        <Grid
          item
          xs={12}
          container
          direction="column"
          style={{ width: "100%", marginTop: "1%" }}
        >
          {!(isDisabled() && !event?.event_note) && (
            <Grid item xs={12}>
              <SpText variant="text">
                {labels.patient.patientPlan.activityDetailDialog.note + ": "}
              </SpText>
            </Grid>
          )}
          <Grid item xs={12}>
            {isDisabled() ? (
              <SpText variant="h4ComponentLabel">{event?.event_note}</SpText>
            ) : (
              <SpTextArea
                formControlStyle={{ width: "100% !important" }}
                textAlign="left"
                variant="text"
                defaultValue={event.event_note}
                inputRef={register}
                name={"note"}
              />
            )}
          </Grid>
        </Grid>
        {!isDisabled() ? (
          <Grid item xs={12} container direction="row">
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-start",
                marginTop: "2%",
              }}
              direction="row"
            >
              <SpButton
                buttonType="accept"
                style={{ flex: "auto" }}
                text={
                  labels.patient.patientPlan.activityDetailDialog
                    .updateActivityButton
                }
                onClick={handleSubmit(updateActivityContents)}
              />
              {archiveEnabled && (
                <SpButton
                  buttonType="accept"
                  style={{ flex: "auto" }}
                  text={
                    labels.patient.patientPlan.activityDetailDialog
                      .archiveActivityButton
                  }
                  onClick={handleSubmit(updateAndArchiveActivity)}
                />
              )}

              <SpButton
                buttonType="cancel"
                style={{ flex: "auto" }}
                text={
                  labels.patient.patientPlan.activityDetailDialog
                    .openDeleteButtonActivity
                }
                variant="h1PageTitle"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDeleteActivity(true);
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <SpText variant="text" style={{ marginTop: 5 }}>
            {labels.patient.patientPlan.activityDetailDialog.archivedAct}
          </SpText>
        )}
      </Grid>
      <SpDialog
        open={openDeleteActivity}
        setOpen={setOpenDeleteActivity}
        title={labels.calendar.eventDetailDialog.title}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.calendar.eventDetailDialog.deleteMessage}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                buttonType="cancel"
                text={labels.patient.patientPlan.activityDetailDialog.confirm}
                variant="h1PageTitle"
                onClick={(e) => deleteCalendarActivity(e)}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.patientPlan.activityDetailDialog.deny}
                variant="h1PageTitle"
                onClick={() => setOpenDeleteActivity(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(AgendaCalendarEventCard);
