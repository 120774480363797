import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Children, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Controller, useForm } from "react-hook-form";
import "../App.css";
import { SpAutocomplete } from "../components/atoms/SpAutocomplete";
import SpButton from "../components/atoms/SpButton";
import SpDialog from "../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../components/atoms/SpDragAndDropCalendarItems";
import { SpSelect, SpSelectMenuItem } from "../components/atoms/SpSelect";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpText from "../components/atoms/SpText";
import SpTextArea from "../components/atoms/SpTextArea";
import SpTextInput from "../components/atoms/SpTextInput";
import {
  getAllActivitiesTypes,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
} from "../models/actions/Activity";
import {
  createCalendarActivityMonitoring,
  getAllAgendaActivityByProfessional,
  updateCalendarActivityMonitoring,
} from "../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../models/actions/Excercise";
import { getPatientsByProfessionalDetails } from "../models/actions/Professionals";
import AgendaCalendarEventCard from "./agenda/AgendaCalendarEventCard";
import A1_AssessmentInputDetails from "./agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "./agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "./agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "./agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "./agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "./agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "./agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "./agenda/inputDetails/A8_RehabilitationInputDetails";
import { getLanguage, labels, psTranslate } from "./shared/translations";
import { momentDefinition, rollbar } from "../utils/common";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];

moment.locale(getLanguage(), momentDefinition);
const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const Agenda = (props) => {
  const [professionalPatients, setProfessionalPatients] = useState([]);
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [assessmentTemplateSelected, setAssessmentTemplateSelected] =
    useState(false);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [activityFormError, setActivityFormError] = useState(true);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [error, setError] = useState("");

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!draggedEvent) {
      reset({ id_activity_type: null });
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  const fetchDataInitial = async () => {
    try {
      const [
        activityTypeResponse,
        feedbackParametersList,
        professionalPatients,
      ] = await Promise.all([
        getAllActivitiesTypes(),
        getAllFeedbackParameters(),
        getPatientsByProfessionalDetails(),
      ]);

      setActivityTypeList(activityTypeResponse);
      feedbackParametersList && setLoadParametersData(feedbackParametersList);
      setProfessionalPatients(professionalPatients);
    } catch (error) {
      rollbar.error("Agenda - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const responseEvents = await getAllAgendaActivityByProfessional();

      Rollbar.configure({
        checkIgnore: () => false,
      });
      rollbar.info(" Agenda: - fetchData", JSON.stringify(responseEvents));
      Rollbar.configure({
        checkIgnore: () => true,
      });

      updateEventsList(responseEvents);
    } catch (error) {
      rollbar.error("Agenda - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const updateEventsList = (eventsListData) => {
    let newEventsList = [];
    eventsListData.map((event, eventIdx) => {
      newEventsList.push({
        id: event?.id,
        title: event?.activity_datum?.activity_type?.name,
        description: "",
        start: new Date(event?.start_date),
        end: new Date(event?.start_date),
        start_time: event?.start_time,
        end_time: event?.end_time,
        type: event?.activity_datum?.name,
        event_note: event?.event_note,
        id_presentation: event?.id_presentation,
        presentation: event?.presentation,
        id_activity_type: event?.activity_datum?.activity_type?.id,
        media: event?.activity_datum?.activity_type?.key
          ? require(`../assets/activities/${event?.activity_datum?.activity_type?.key}.png`)
          : null,
        color: event?.color,
        data: event?.activity_datum?.data,
        feedbacks: event?.activity_feedbacks[0]
          ?.activity_resp_parameter_feedbacks
          ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
          : [],
        archiving_status: event?.archiving_status,
        patient: event?.monitoring?.patient,
        is_from_group_plan: event?.is_from_group_plan,
        id_activity_group: event?.id_activity_group,
      });
    });
    setEventsList(newEventsList);
  };

  const renderCardTitle = (event, idActivityType, motivation, isArchived) => {
    return (
      <Grid direction="column">
        <SpText variant="h4ComponentLabel">
          {event.id_activity_type === idActivityType && !motivation
            ? event?.title
            : motivation}
        </SpText>
        {event.id_activity_type === idActivityType && isArchived ? (
          <SpText variant="textWhiteArchived">{labels.groups.archived}</SpText>
        ) : (
          ""
        )}
      </Grid>
    );
  };

  const ColoredEventCell = ({ event }) => {
    const data = event?.data;
    const isArchived =
      event?.archiving_status === "completed_and_feedback_not_sent" ||
      event?.archiving_status === "completed_and_feedback_sent";
    return (
      <div style={{ display: "flex", flex: 1 }}>
        <div
          className="rbc-event-label"
          style={{ display: "flex", flexDirection: "column", maxHeight: 50 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={event?.media} style={{ width: 30, height: 30 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: 5,
              }}
            >
              {renderCardTitle(
                event,
                1,
                data?.assessment_motivation,
                isArchived
              )}
              {renderCardTitle(
                event,
                2,
                data?.treatments_motivation,
                isArchived
              )}
              {renderCardTitle(
                event,
                3,
                data?.excercises_motivation,
                isArchived
              )}
              {renderCardTitle(event, 4, data?.training_motivation, isArchived)}
              {renderCardTitle(event, 5, data?.rest_motivation, isArchived)}
              {renderCardTitle(
                event,
                6,
                data?.appointment_motivation,
                isArchived
              )}
              {renderCardTitle(event, 7, data?.race_motivation, isArchived)}
              {renderCardTitle(
                event,
                8,
                data?.rehabilitation_motivation,
                isArchived
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "96%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <SpText variant="monthYearCalendar">
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </SpText>
      );
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <label style={{ justifyContent: "center" }} className={"label-date"}>
          {label()}
        </label>
        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-current"
            onClick={goToCurrent}
          >
            {labels.groups.plan.today}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };

    handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    setValue("id_activity_type", draggedEvent.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let allDay = event?.allDay;

    if (!event?.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event?.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = eventsList.map((existingEvent) => {
      return existingEvent.id === event?.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent;
    });
    event.start_date = moment(start).format("YYYY-MM-DD");
    await updateActivity(event);
    setEventsList(nextEvents);
  };

  const updateActivity = async (data) => {
    try {
      const updatedActivity = await updateCalendarActivityMonitoring({
        ...data,
        id_patient: eventDetail?.patient?.id,
      });
      if (!updatedActivity.error) {
        props.snackbarShowMessage(updatedActivity?.message);
        resetFields();
      } else {
        props.snackbarShowErrorMessage(updatedActivity?.error);
      }
    } catch (error) {
      rollbar.error("Agenda - updateActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const createActivityObject = (data, id_activity_type) => {
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: data?.assessment_motivation,
        };
        break;
      case 2:
        data.details_data = {
          name: data?.name,
          treatments: data?.treatments,
          treatments_motivation: data?.treatments_motivation,
        };
        break;
      case 3:
        data.details_data = {
          name: data?.name,
          excercises: data?.excercises,
          excercises_motivation: data?.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: data?.name,
          training: data?.training,
          training_motivation: data?.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: data?.rest_motivation };
        break;
      case 6:
        data.details_data = {
          appointment_motivation: data?.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: data?.race_motivation,
          race_minutes: data?.race_minutes,
        };
        break;
      case 8:
        data.details_data = {
          name: data?.name,
          rehabilitations: data?.rehabilitations,
          rehabilitation_motivation: data?.rehabilitation_motivation,
        };
        break;
      default:
        break;
    }
    return data;
  };

  const createActivity = async (data) => {
    try {
      if (!getValues("id_patient")) {
        setError(labels.groups.errors.selectAtLeastAPatient);
        return;
      } else {
        setError(null);
        data = createActivityObject(data, getValues("id_activity_type"));

        Rollbar.configure({
          checkIgnore: () => false,
        });
        rollbar.info(
          " Agenda: - createActivity",
          JSON.stringify({
            ...data,
            id_patient: data?.id_patient,
            is_from_agenda: 1,
          })
        );
        Rollbar.configure({
          checkIgnore: () => true,
        });

        const createdActivity = await createCalendarActivityMonitoring({
          ...data,
          id_patient: data?.id_patient,
          is_from_agenda: 1,
        });
        if (!createdActivity.error) {
          props.snackbarShowMessage(createdActivity?.message);
          resetFields();
        } else {
          props.snackbarShowErrorMessage(createdActivity?.error);
        }
      }
    } catch (error) {
      rollbar.error("Agenda - createActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const resetFields = () => {
    setValue("id_activity_type", null);
    setValue("id_template", null);
    setValue("id_activity_template", null);
    setValue("event_note", "");
    setValue("start_time", null);
    setValue("end_time", null);
    setValue("id_presentation", null);
    setValue("quantity_training", 0);
    setValue("quantity_treatment", 0);
    setValue("quantity_rehabilitation", 0);
    setValue("quantity_excercise", 0);
    setOpenAddActivity(false);
    setAssessmentTemplateSelected(false);
    setLoadParametersList([]);
    fetchData();
  };

  //method to render input details based on the type of activity selected
  const renderInputDetails = () => {
    const Detail = INPUT_DETAILS[getValues("id_activity_type")];
    return (
      Detail && (
        <Detail
          watchTemplateSelect={watchTemplateSelect}
          selectedTemplate={getValues("id_template")}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          register={register}
        />
      )
    );
  };

  const watchStartTime = watch("start_time");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      setAssessmentTemplateSelected(false);
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      rollbar.error("Agenda - fetchTemplateByActType", error);
    }
  };

  useEffect(() => {
    fetchDataInitial();
    fetchData();
  }, []);

  useEffect(() => {
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <SpText variant={"h1"}>{labels.calendar.title}</SpText>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <SpCalendarDragAndDropItems
            activitiesAvailable={activityTypeList}
            handleDragStart={handleDragStart}
          />
          <Grid item xs={11} style={{ minHeight: "750px" }}>
            <DragAndDropCalendar
              selectable
              events={eventsList}
              scrollToTime={new Date()}
              defaultDate={new Date()}
              view={"month"}
              onEventDrop={handleEventDrop}
              onDragStart={() => console.log("Drag started")}
              dragFromOutsideItem={
                displayDragItemInCell ? dragFromOutsideItem : null
              }
              onDropFromOutside={onDropFromOutside}
              handleDragStart={handleDragStart}
              onSelectEvent={handleEventDetailSelected}
              onSelectSlot={handleNewEventSelected}
              draggableAccessor={(event) =>
                !(
                  event.archiving_status ===
                    "completed_and_feedback_not_sent" ||
                  event.archiving_status === "completed_and_feedback_sent"
                )
              }
              step={60}
              eventPropGetter={(event, start, end, isSelected) => {
                const backgroundColor = event?.color;
                const style = {
                  backgroundColor: backgroundColor,
                  borderRadius: "5px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  border: "0px",
                  display: "block",
                };
                return {
                  style: style,
                };
              }}
              messages={{
                showMore: (total) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {`+${total} mostra ancora`}
                  </div>
                ),
              }}
              components={{
                toolbar: CustomToolbar,
                day: { header: MyCustomHeader },
                week: { header: MyCustomHeader },
                month: { header: MyCustomHeader },
                event: ColoredEventCell,
                eventWrapper: AgendaEventWrapper,
              }}
              localizer={localizer}
            />
          </Grid>
        </Grid>
      </Grid>
      {eventDetail && (
        <SpDialog
          variant="bigDialog"
          open={openDetailActivity}
          setOpen={setOpenDetailActivity}
          title={labels.mylab.tempAdd.activityPlan.dialog.title}
        >
          <AgendaCalendarEventCard
            event={eventDetail}
            fetchData={fetchData}
            setOpenDetailActivity={setOpenDetailActivity}
            updateActivity={updateActivity}
            openDetailActivity={openDetailActivity}
            createActivityObject={createActivityObject}
            loadParametersData={loadParametersData}
            loadParametersList={loadParametersList}
            setLoadParametersList={setLoadParametersList}
          />
        </SpDialog>
      )}
      <SpDialog
        variant="bigDialog"
        open={openAddActivity}
        setOpen={setOpenAddActivity}
        title={labels.calendar.addDialog.title}
      >
        <form onSubmit={handleSubmit(createActivity)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid item style={{ maxWidth: "100%", marginBottom: "2%" }}>
              <SpAutocomplete
                style={{ width: "100%" }}
                formControlWidth={"100%"}
                selectPlaceholder={labels.agenda.selectPatient}
                value={professionalPatients[getValues("id_patient")]}
                onChange={(e, newValue) => {
                  setValue("id_patient", newValue?.id);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <SpText
                      style={{ marginLeft: "8px" }}
                      variant="h4ComponentLabel"
                    >
                      {professionalPatients[option.id]?.givenName +
                        " " +
                        professionalPatients[option.id]?.familyName}
                    </SpText>
                  ))
                }
                options={professionalPatients}
                getOptionLabel={(option) =>
                  (option?.givenName + " " + option?.familyName).toString()
                }
                getOptionSelected={(option, value) => option?.id === value}
              />
            </Grid>
            {error && <SpText variant="h4ComponentLabelError">{error}</SpText>}
            <SpTextInput
              type="time"
              name="start_time"
              min="05:00"
              max="22:00"
              inputRef={register({ required: true })}
              label={labels.patient.viewPlan.addActivity.inputForm.start_time}
            />
            <SpTextInput
              type="time"
              name="end_time"
              min="05:00"
              max="22:00"
              inputRef={register({ required: true })}
              label={labels.patient.viewPlan.addActivity.inputForm.end_time}
            />
            <Controller
              render={(props) => (
                <SpSelect
                  label={
                    labels.patient.viewPlan.addActivity.inputForm.chooseActivity
                  }
                  style={{ marginTop: "1%" }}
                  value={props.value ?? []}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                    setValue("quantity_training", 0);
                    setValue("quantity_treatment", 0);
                    setValue("quantity_rehabilitation", 0);
                    setValue("quantity_excercise", 0);
                  }}
                >
                  {activityTypeList.map((p) => (
                    <SpSelectMenuItem key={p?.id} value={p?.id}>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={require(`../assets/activities/${p?.key}${
                            props?.value === p?.id ? "" : "_black"
                          }.png`)}
                          style={{ maxWidth: 25, maxHeight: 25 }}
                          alt={"img"}
                        />
                        <SpText
                          color={props?.value === p?.id ? "white" : "black"}
                          style={{
                            color: "black !important",
                            marginLeft: "5px",
                          }}
                        >
                          {psTranslate(p?.name)}
                        </SpText>
                      </div>
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={""}
              name={`id_activity_type`}
              control={control}
            />
            {templatesList.length > 0 && (
              <Controller
                render={(props) => (
                  <SpSelect
                    label={
                      labels.patient.viewPlan.addActivity.inputForm
                        .chooseTemplateActivity
                    }
                    style={{ marginTop: "1%" }}
                    value={props?.value}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  >
                    {templatesList.map((p) => (
                      <SpSelectMenuItem key={p?.id} value={p?.id}>
                        <SpText
                          color={props?.value === p?.id ? "white" : "black"}
                          style={{
                            color: "black !important",
                            marginLeft: "5px",
                          }}
                        >
                          {p?.name}
                        </SpText>
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`id_template`}
                control={control}
              />
            )}
            <div style={{ marginTop: "1%", marginBottom: "1%" }}>
              {renderInputDetails()}
            </div>
            <SpTextArea name="event_note" label="Note" inputRef={register} />
            <SpButton
              buttonType={"accept"}
              variant="none"
              text={labels.patient.viewPlan.addActivity.title}
              style={{ marginTop: "4%" }}
              type="submit"
            />
          </div>
        </form>
      </SpDialog>
    </>
  );
};

export default withSnackbar(Agenda);