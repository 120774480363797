import amplitude from "amplitude-js";
import axios from "axios";
import { psTranslate } from "../pages/shared/translations";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Object used in moment to define that the month and the year have to start from monday
 * @type {{week: {dow: number, doy: number}}}
 */
export const momentDefinition = {
  week: {
    dow: 1,
    doy: 1,
  },
};

/**
 * @param array list of items to sort and translate
 * @param param optional param to search in the object
 * @param sorting ASC|DESC
 * @returns {*} array sorted and translated in the "param" object parameter
 */
export const getSortedAndTranslatedArray = (
  array,
  param = "name",
  sorting = "ASC"
) => {
  let res;
  //If the database content language is the same of the client, return just the sorted list
  //To check if the language of the content is the same as that of the client, we have to take one content of the array and see if it is equal to its translation
  let sameLanguage = false; //We save in this variable if the client has the same language of the database contents (fallback language = "IT")
  if (array.length > 0) {
    const item = array[0];
    sameLanguage = param
      ? item[param] === psTranslate(item[param])
      : item === psTranslate(item);
  }

  if (sameLanguage) {
    res = [
      ...array?.sort((a, b) =>
        param ? a[param].localeCompare(b[param]) : a.localeCompare(b)
      ),
    ];
  } else {
    res = array
      ?.map((x) => {
        param ? (x[param] = psTranslate(x[param])) : (x = psTranslate(x));
        return x;
      })
      ?.sort((a, b) =>
        param ? a[param].localeCompare(b[param]) : a.localeCompare(b)
      );
  }
  //The reverse() function can be replaced directly by the opposite compare() within the sort function
  return sorting === "DESC" ? res.reverse() : res;
};

export const isBusiness = () => {
  return window.location.hostname.includes("business");
};

export const isAuth0Enabled = async () => {
  const result = await axios.get("https://api.flagsmith.com/api/v1/flags/", {
    headers: { "X-Environment-Key": process.env.REACT_APP_FLAGSMITH },
  });
  const flag = result.data.filter((el) => el?.feature.name === "auth0");
  return flag[0]?.enabled ? true : false;
};

export const isInjuryDetailEnabled = async () => {
  const result = await axios.get("https://api.flagsmith.com/api/v1/flags/", {
    headers: { "X-Environment-Key": process.env.REACT_APP_FLAGSMITH },
  });
  const flag = result.data.filter((el) => el?.feature.name === "injury-detail");
  return flag[0]?.enabled ? true : false;
};

export const isDragAndDropActivityEnabled = async () => {
  const result = await axios.get("https://api.flagsmith.com/api/v1/flags/", {
    headers: { "X-Environment-Key": process.env.REACT_APP_FLAGSMITH },
  });
  const flag = result.data.filter(
    (el) => el?.feature.name === "drag-drop-activity-patient"
  );
  return flag[0]?.enabled ? true : false;
};

//ROLLBAR UTILS
export const setRollbarUser = (rollbarUserId, rollbarUserEmail) => {
  Rollbar.configure({
    // logLevel: "info",
    payload: {
      person: {
        id: rollbarUserId, // required
        username: rollbarUserEmail,
        email: rollbarUserEmail,
      },
    },
  });
};

export const rollbar = {
  info: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.info(component + " - " + msg)
      : Rollbar.info(component + " - " + msg);
  },
  debug: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.debug(component + " - " + msg)
      : Rollbar.debug(component + " - " + msg);
  },
  warn: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.warn(component + " - " + msg)
      : Rollbar.warn(component + " - " + msg);
  },
  error: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.error("No Rollbar: " + component + " - " + msg)
      : Rollbar.error(component + " - " + msg);
  },
  critical: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.error(component + " - " + msg)
      : Rollbar.critical(component + " - " + msg);
  },
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

//AMPLITUDE UTILS
export const amplitudeUtils = {
  initAmplitude: async () => {
    //decode del token e verifica se presente l'ID utente
    // id utente
    // email ok
    const userId = 0;
    //const envName = process.env.REACT_APP_ENV_NAME;
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API, userId);
  },

  getAmplitudeUserId: () => {
    return amplitude.getInstance().getUserId();
  },

  setAmplitudeUserId: (userId) => {
    return amplitude.getInstance().setUserId(userId);
  },
  setAmplitudeUserProperties: (userProperties) => {
    return amplitude.getInstance().setUserProperties(userProperties);
  },
  setAmplitudeComponent: (componentName) => {
    return amplitude.getInstance().setComponent(componentName);
  },
  sendAmplitudeLog: async (eventType, eventProperties) => {
    const accessToken = await localStorage.getItem("accessToken");
    amplitude.getInstance().logEvent(eventType, eventProperties);
  },
};
