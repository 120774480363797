import { call } from "./utils";
import { labels } from "../../pages/shared/translations";
import React from "react";

export const sendPromsPresentationAnswer = async (data) =>
  await call({
    url: "/proms/send/presentation/answer",
    data: { data },
  });

export const sendPromsMonitoringAnswer = async (data) =>
  await call({
    url: "/proms/send/monitoring/answer",
    data: { data },
  });

export const getPromsAnswersByPresentation = async (data) =>
  await call({
    url: "/proms/list/by/presentation",
    data: { data },
  });

export const getPromsAnswersByMonitoring = async (data) =>
  await call({
    url: "/proms/list/by/monitoring",
    data: { data },
  });

export const getAllPromsSurveys = async (data) =>
  await call({ url: "/proms/get/surveys/data", data: { data } });

export const getPromsSurveyAnswersByPresentation = async (data) =>
  await call({
    url: "/proms/get/survey/answers/by/presentation",
    data: { data },
  });

export const getPromsSurveyAnswersByMonitoring = async (data) =>
  await call({
    url: "/proms/get/survey/answers/by/monitoring",
    data: { data },
  });

export const getAllPromsSurveyAnswers = async (data) =>
  await call({
    url: "/proms/get/all/survey/answers",
    data: { data },
  });

export const sendPromsToPatient = async (data) =>
  await call({ url: "/proms/send/to/patient", data: { data } });

export const getQuestionnairesAnswered = async (data) =>
  await call({
    url: "/proms/get/questionnaires/answered",
    data: { data },
  });

export const getQuestionnairesAnsweredByPresentation = async (data) =>
  await call({
    url: "/proms/get/questionnaires/answered/by/presentation",
    data: { data },
  });

export const getPromsIndexes = async (data) =>
  await call({
    url: "/proms/get/proms/indexes/by/type",
    data: { data },
  });

export const getFinalScore = (promsObj, promsAnswers) => {
  // console.info(promsObj);
  // console.info(promsAnswers);
  let result = 0;
  switch (promsObj.key) {
    case "dash":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = result / promsAnswers?.length;
        result = result - 1;
        result = result * 25;
        result = result / 100;
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
    case "vas_u":
      result = promsAnswers[0]?.proms_answer?.score;
      result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
        result
      )?.toFixed(2)}`;
      break;
    case "vas_w":
      result = promsAnswers[0]?.proms_answer?.score;
      result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
        result
      )?.toFixed(2)}`;
      break;
    case "visa_p":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = result / 100;
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
    case "koos":
      let ss = labels.patient.monitoring.proms.symptomAndStiffness;
      let ssVal = 0,
        numSSVal = 0;
      let d = labels.patient.monitoring.proms.pain;
      let dVal = 0,
        numDVal = 0;
      let aod = labels.patient.monitoring.proms.dailyActivities;
      let aodVal = 0,
        numAodVal = 0;
      let s = labels.patient.monitoring.proms.sport;
      let sVal = 0,
        numSVal = 0;
      let qol = labels.patient.monitoring.proms.qualityOfLife;
      let qolVal = 0,
        numQolVal = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          let score = parseFloat(promsAnswers[i]?.proms_answer?.score);
          switch (
            promsAnswers[i]?.proms_answer?.proms_question?.pos.toLowerCase()
          ) {
            case "s1":
            case "s2":
            case "s3":
            case "s4":
            case "s5":
            case "s6":
            case "s7":
              numSSVal++;
              ssVal += score;
              break;
            case "p1":
            case "p2":
            case "p3":
            case "p4":
            case "p5":
            case "p6":
            case "p7":
            case "p8":
            case "p9":
              numDVal++;
              dVal += score;
              break;
            case "a1":
            case "a2":
            case "a3":
            case "a4":
            case "a5":
            case "a6":
            case "a7":
            case "a8":
            case "a9":
            case "a10":
            case "a11":
            case "a12":
            case "a13":
            case "a14":
            case "a15":
            case "a16":
            case "a17":
              numAodVal++;
              aodVal += score;
              break;
            case "sp1":
            case "sp2":
            case "sp3":
            case "sp4":
            case "sp5":
              numSVal++;
              sVal += score;
              break;
            case "q1":
            case "q2":
            case "q3":
            case "q4":
              numQolVal++;
              qolVal += score;
              break;
          }
        }
        result = (
          <>
            {ss} {(100 - ((ssVal / numSSVal) * 100) / 4)?.toFixed(2)}
            <br />
            {d} {(100 - ((dVal / numDVal) * 100) / 4)?.toFixed(2)}
            <br />
            {aod} {(100 - ((aodVal / numAodVal) * 100) / 4)?.toFixed(2)}
            <br />
            {s} {(100 - ((sVal / numSVal) * 100) / 4)?.toFixed(2)}
            <br />
            {qol} {(100 - ((qolVal / numQolVal) * 100) / 4)?.toFixed(2)}
          </>
        );
      }
      break;
    case "visa_a":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = result / 100;
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
    case "faam":
      let da = labels.patient.monitoring.proms.dailyActivities;
      let daVal = 0,
        numDaVal = 0;
      let sport = labels.patient.monitoring.proms.sport;
      let sportVal = 0,
        numSportVal = 0;
      if (promsAnswers) {
        let filteredAnswers = promsAnswers?.filter(
          (a) => a?.proms_answer?.score != "N/A"
        );
        // console.info("filteredAnswers", filteredAnswers);
        for (let i = 0; i < filteredAnswers?.length; i++) {
          let score = parseFloat(filteredAnswers[i]?.proms_answer?.score);
          switch (
            filteredAnswers[i]?.proms_answer?.proms_question?.pos.toLowerCase()
          ) {
            case "faam_1":
            case "faam_2":
            case "faam_3":
            case "faam_4":
            case "faam_5":
            case "faam_6":
            case "faam_7":
            case "faam_8":
            case "faam_9":
            case "faam_10":
            case "faam_11":
            case "faam_12":
            case "faam_13":
            case "faam_14":
            case "faam_15":
            case "faam_16":
            case "faam_17":
            case "faam_18":
            case "faam_19":
            case "faam_20":
            case "faam_21":
              numDaVal++;
              daVal += score;
              break;
            case "faam_23":
            case "faam_24":
            case "faam_25":
            case "faam_26":
            case "faam_27":
            case "faam_28":
            case "faam_29":
            case "faam_30":
              numSportVal++;
              sportVal += score;
              break;
          }
        }
        result = (
          <>
            {da} {((daVal / 84) * 100)?.toFixed(2)}
            <br />
            {sport} {((sportVal / 32) * 100)?.toFixed(2)}
          </>
        );
      }
      break;
    case "hagos":
      let ss2 = labels.patient.monitoring.proms.symptomAndStiffness;
      let ss2Val = 0,
        numSS2Val = 0;
      let d2 = labels.patient.monitoring.proms.pain;
      let d2Val = 0,
        numD2Val = 0;
      let aod2 = labels.patient.monitoring.proms.physicalFunctionAndDailyLife;
      let aod2Val = 0,
        numAod2Val = 0;
      let s2 = labels.patient.monitoring.proms.sportAndFunctionActivities;
      let s2Val = 0,
        numS2Val = 0;
      let pa = labels.patient.monitoring.proms.physicalActivities;
      let paVal = 0,
        numPaVal = 0;
      let qol2 = labels.patient.monitoring.proms.qualityOfLife;
      let qol2Val = 0,
        numQol2Val = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          let score = parseFloat(promsAnswers[i]?.proms_answer?.score);
          switch (
            promsAnswers[i]?.proms_answer?.proms_question?.pos.toLowerCase()
          ) {
            case "s1":
            case "s2":
            case "s3":
            case "s4":
            case "s5":
            case "s6":
            case "s7":
              numSS2Val++;
              ss2Val += score;
              break;
            case "d1":
            case "d2":
            case "d3":
            case "d4":
            case "d5":
            case "d6":
            case "d7":
            case "d8":
            case "d9":
            case "d10":
              numD2Val++;
              d2Val += score;
              break;
            case "f1":
            case "f2":
            case "f3":
            case "f4":
            case "f5":
              numAod2Val++;
              aod2Val += score;
              break;
            case "sp1":
            case "sp2":
            case "sp3":
            case "sp4":
            case "sp5":
            case "sp6":
            case "sp7":
            case "sp8":
              numS2Val++;
              s2Val += score;
              break;
            case "af1":
            case "af2":
              numPaVal++;
              paVal += score;
              break;
            case "q1":
            case "q2":
            case "q3":
            case "q4":
            case "q5":
              numQol2Val++;
              qol2Val += score;
              break;
          }
        }
        result = (
          <>
            {ss2} {(100 - (ss2Val * 100) / 28)?.toFixed(2)}
            <br />
            {d2} {(100 - (d2Val * 100) / 40)?.toFixed(2)}
            <br />
            {aod2} {(100 - (aod2Val * 100) / 20)?.toFixed(2)}
            <br />
            {s2} {(100 - (s2Val * 100) / 32)?.toFixed(2)}
            <br />
            {pa} {(100 - (paVal * 100) / 8)?.toFixed(2)}
            <br />
            {qol2} {(100 - (qol2Val * 100) / 20)?.toFixed(2)}
          </>
        );
      }
      break;
    case "ndi":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = result * 2;
        result = result / (promsAnswers?.length === 10 ? 50 : 45);
        result = result / 100;
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
    case "quick_dash":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = result / promsAnswers?.length;
        result = result - 1;
        result = result * 25;
        result = result / 100;
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
    case "prwe":
      let pain = labels.patient.monitoring.proms.pain;
      let painVal = 0,
        numPainVal = 0;
      let fun = labels.patient.monitoring.proms.functionality;
      let funVal = 0,
        numFunVal = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          let score = parseFloat(promsAnswers[i]?.proms_answer?.score);

          switch (
            promsAnswers[i]?.proms_answer?.proms_question?.pos.toLowerCase()
          ) {
            case "prwe_1":
            case "prwe_2":
            case "prwe_3":
            case "prwe_4":
            case "prwe_5":
              numPainVal++;
              painVal += score;
              break;
            case "prwe_6":
            case "prwe_7":
            case "prwe_8":
            case "prwe_9":
            case "prwe_10":
            case "prwe_11":
            case "prwe_12":
            case "prwe_13":
            case "prwe_14":
            case "prwe_15":
              numFunVal++;
              funVal += score;
              break;
          }
        }
        result = (
          <>
            {pain} {painVal?.toFixed(2)}
            <br />
            {fun} {funVal?.toFixed(2)}
            <br />
            {labels.patient.monitoring.proms.finalPoint}{" "}
            {(painVal + funVal)?.toFixed(2)}
          </>
        );
      }
      break;
    case "odi":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = result * 2;
        result = result / (promsAnswers?.length === 10 ? 50 : 45);
        result = result / 100;
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
    case "mmodified_tqr":
      result = promsAnswers[0]?.proms_answer?.score;
      result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
        result
      )?.toFixed(2)}`;
      break;
    case "rpe":
      result = promsAnswers[0]?.proms_answer?.score;
      result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
        result
      )?.toFixed(2)}`;
      break;
    case "wellness_score":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          result += parseFloat(promsAnswers[i]?.proms_answer?.score);
        }
        result = `${labels.patient.monitoring.proms.finalPoint} ${parseFloat(
          result
        )?.toFixed(2)}`;
      }
      break;
  }
  return result;
};
