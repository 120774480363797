import React, { useEffect, useState } from "react";
import StudySharedControls from "./SharedControls";
import {
  averageInRange,
  genericIsEnabled,
  getMomentIdFromKey,
  getNDaysFromKey,
} from "../../ReportHelperFns";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { labels } from "../../../../shared/translations";

const moment = extendMoment(Moment);

export const StudyTemporalEvolution = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(true);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const averageData = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          //Compute avg (temporal is studies ahead)
          const range = moment
            .rangeFromInterval(by, 1, dateRange.end)
            .add(dateRange, { adjacent: true });
          accum[studyParamKey] = averageInRange(
            range,
            by,
            (range) => range.by("day", { excludeEnd: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            true,
            true
          );
          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    //In case of week view, average over next 7 days
    onFetchDateDaysChange(getNDaysFromKey(graphDateView?.key));
    //Update filter data
    let newData;
    if (active && isEnabled())
      newData = {
        [study.key]: {
          "": averageData(getMomentIdFromKey(graphDateView?.key)),
        },
      };
    else newData = { [study.key]: { "": {} } };

    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      setActive(config.active);
      setConfigDone(true);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    ></StudySharedControls>
  );
};
