import { labels } from "../../../shared/translations";

export const calculateFinalScore = (proms, promsAnswers) => {
  const _generateObj = ({ label = labels.patient.proms.finalScore, val }) => ({
    label: label,
    value: val ? val.toFixed(2) : 0,
  });

  let result = [];
  let value = 0;
  switch (proms.key.toLowerCase()) {
    case "dash":
    case "quick_dash":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value = value / promsAnswers?.length;
        value = value - 1;
        value = value * 25;
        value = value / 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "vas_u":
    case "vas_w":
    case "mmodified_tqr":
    case "rpe":
      for (let i = 0; i < promsAnswers?.length; i++) {
        value += parseFloat(promsAnswers[i]?.score);
      }
      result.push(_generateObj({ val: value }));
      break;
    case "visa_p":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value = value / 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "koos":
      let ss = labels.patient.proms.symptomsAndStiffness;
      let ssVal = 0,
        numSSVal = 0;
      let d = labels.patient.proms.pain;
      let dVal = 0,
        numDVal = 0;
      let aod = labels.patient.proms.dailyActivities;
      let aodVal = 0,
        numAodVal = 0;
      let s = labels.patient.proms.sport;
      let sVal = 0,
        numSVal = 0;
      let qol = labels.patient.proms.qualityOfLife;
      let qolVal = 0,
        numQolVal = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          let score = parseFloat(promsAnswers[i]?.score);

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();
          if (valueToCheck) {
            if (valueToCheck.charAt(1) == "p") {
              //"sp1" || "sp2" || "sp3" || "sp4" || "sp5"
              numSVal++;
              sVal += score;
            } else {
              switch (valueToCheck.charAt(0)) {
                case "s": //"s1" || "s2" || "s3" || "s4" || "s5" || "s6" || "s7"
                  numSSVal++;
                  ssVal += score;
                  break;
                case "p": //"p1" || "p2" || "p3" || "p4" || "p5" || "p6" || "p7" || "p8" || "p9"
                  numDVal++;
                  dVal += score;
                  break;
                case "a": //"a1" || "a2" || "a3" || "a4" || "a5" || "a6" || "a7" || "a8" || "a9" || "a10" || "a11" || "a12" || "a13" || "a14" || "a15" || "a16" || "a17"
                  numAodVal++;
                  aodVal += score;
                  break;
                case "q": //"q1" || "q2" || "q3" || "q4"
                  numQolVal++;
                  qolVal += score;
                  break;
              }
            }
          }
        }

        result.push(
          _generateObj({ label: ss, val: 100 - ((ssVal / numSSVal) * 100) / 4 })
        );
        result.push(
          _generateObj({ label: d, val: 100 - ((dVal / numDVal) * 100) / 4 })
        );
        result.push(
          _generateObj({
            label: aod,
            val: 100 - ((aodVal / numAodVal) * 100) / 4,
          })
        );
        result.push(
          _generateObj({ label: s, val: 100 - ((sVal / numSVal) * 100) / 4 })
        );
        result.push(
          _generateObj({
            label: qol,
            val: 100 - ((qolVal / numQolVal) * 100) / 4,
          })
        );
      }
      break;
    case "visa_a":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value = value / 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "faam":
      let da = labels.patient.proms.dailyActivities;
      let daVal = 0,
        numDaVal = 0;
      let sport = labels.patient.proms.sport;
      let sportVal = 0,
        numSportVal = 0;
      if (promsAnswers) {
        let filteredAnswers = promsAnswers?.filter(
          (a) => a?.proms_answer?.score != "N/A"
        );
        for (let i = 0; i < filteredAnswers?.length; i++) {
          let score = parseFloat(filteredAnswers[i]?.proms_answer?.score);

          const valueToCheck =
            filteredAnswers[i]?.proms_answer?.proms_question?.pos.toLowerCase();

          if (valueToCheck) {
            if (parseInt(valueToCheck.substring(5)) > 21) {
              //"faam_1", "faam_2", etc...
              numDaVal++;
              daVal += score;
            } else {
              numSportVal++;
              sportVal += score;
            }
          }
        }
        result.push(_generateObj({ label: da, val: (daVal / 84) * 100 }));
        result.push(_generateObj({ label: sport, val: (sportVal / 32) * 100 }));
      }
      break;
    case "hagos":
      let ss2 = labels.patient.proms.symptomsAndStiffness;
      let ss2Val = 0,
        numSS2Val = 0;
      let d2 = labels.patient.proms.pain;
      let d2Val = 0,
        numD2Val = 0;
      let aod2 = labels.patient.proms.physicalFunctionalitiesAndDailyLife;
      let aod2Val = 0,
        numAod2Val = 0;
      let s2 = labels.patient.proms.functionalAndSportActivities;
      let s2Val = 0,
        numS2Val = 0;
      let pa = labels.patient.proms.physicalActivities;
      let paVal = 0,
        numPaVal = 0;
      let qol2 = labels.patient.proms.qualityOfLife;
      let qol2Val = 0,
        numQol2Val = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          let score = parseFloat(promsAnswers[i]?.score);

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();

          if (valueToCheck) {
            if (valueToCheck.charAt(1) == "p") {
              //"sp1" || "sp2" || "sp3" || "sp4" || "sp5" || "sp6" || "sp7" || "sp8"
              numS2Val++;
              s2Val += score;
            } else {
              switch (valueToCheck.charAt(0)) {
                case "s": //"s1" || "s2" || "s3" || "s4" || "s5" || "s6" || "s7"
                  numSS2Val++;
                  ss2Val += score;
                  break;
                case "d": //"d1" || "d2" || "d3" || "d4" || "d5" || "d6" || "d7" || "d8" || "d9" || "d10"
                  numD2Val++;
                  d2Val += score;
                  break;
                case "f": //"f1" || "f2" || "f3" || "f4" || "f5"
                  numAod2Val++;
                  aod2Val += score;
                  break;
                case "a": //"af1" || "af2"
                  numPaVal++;
                  paVal += score;
                  break;
                case "q": //"q1" || "q2" || "q3" || "q4" || "q5"
                  numQol2Val++;
                  qol2Val += score;
                  break;
              }
            }
          }
        }
        result.push(
          _generateObj({ label: ss2, val: 100 - (ss2Val * 100) / 28 })
        );
        result.push(_generateObj({ label: d2, val: 100 - (d2Val * 100) / 40 }));
        result.push(
          _generateObj({ label: aod2, val: 100 - (aod2Val * 100) / 20 })
        );
        result.push(_generateObj({ label: s2, val: 100 - (s2Val * 100) / 32 }));
        result.push(_generateObj({ label: pa, val: 100 - (paVal * 100) / 8 }));
        result.push(
          _generateObj({ label: qol2, val: 100 - (qol2Val * 100) / 20 })
        );
      }
      break;
    case "ndi":
    case "odi":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value = value * 2;
        value = value / (promsAnswers?.length === 10 ? 50 : 45);
        value = value / 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "prwe":
      let pain = labels.patient.proms.pain;
      let painVal = 0,
        numPainVal = 0;
      let fun = labels.patient.proms.functionality;
      let funVal = 0,
        numFunVal = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          let score = parseFloat(promsAnswers[i]?.score);

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();
          if (valueToCheck) {
            if (parseInt(valueToCheck.substring(5)) > 5) {
              //"prwe_1", "prwe_2", etc...
              numPainVal++;
              painVal += score;
            } else {
              numFunVal++;
              funVal += score;
            }
          }
        }
        result.push(_generateObj({ label: pain, val: painVal }));
        result.push(_generateObj({ label: fun, val: funVal }));
        result.push(_generateObj({ val: painVal + funVal }));
      }
      break;
    case "wellness_score":
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
      }
      result.push(_generateObj({ val: value }));
      break;
  }

  return result;
};
