import { call } from "./utils";

export const getPathologyByArea = async (data) =>
  await call({ url: "/pathology/by/area", data: { data } });

export const getPathologyByRegion = async (data) =>
  await call({ url: "/pathology/by/region", data: { data } });

export const getPathologyByStructure = async (data) =>
  await call({ url: "/pathology/by/structure", data: { data } });

export const getInterviewByRegion = async (data) =>
  await call({
    url: "/pathology/interview/by/region",
    data: { data },
  });

export const getInterviewByArea = async (data) =>
  await call({ url: "/pathology/interview/by/area", data: { data } });

export const getAreasByRegion = async (data) =>
  await call({ url: "/pathology/areas/by/region", data: { data } });

export const getStructuresByRegion = async (data) =>
  await call({ url: "/pathology/structures/by/region", data: { data } });

export const getStructuresByArea = async (data) =>
  await call({ url: "/pathology/structures/by/area", data: { data } });

export const getRegions = async () =>
  await call({ method: "GET", url: "/pathology/regions/list" });

export const getDisorders = async () =>
  await call({ url: "/pathology/disorders" });

export const savePastHistorySymbol = async (data) =>
  await call({
    url: "/pathology/add/past/history/symbol",
    data: { data },
  });

export const savePresentationSymbol = async (data) =>
  await call({
    url: "/pathology/add/presentation/symbol",
    data: { data },
  });

export const saveSymbolToStructure = async (data) =>
  await call({
    url: "/pathology/add/symbol/to/structure",
    data: { data },
  });

export const saveSymbolToArea = async (data) =>
  await call({ url: "/pathology/add/symbol/to/area", data: { data } });

export const saveSymbolToRegion = async (data) =>
  await call({
    url: "/pathology/add/symbol/to/region",
    data: { data },
  });

export const saveSymbolToStructurePresentation = async (data) =>
  await call({
    url: "/pathology/add/symbol/to/structure/presentation",
    data: { data },
  });

export const saveSymbolToAreaPresentation = async (data) =>
  await call({
    url: "/pathology/add/symbol/to/area/presentation",
    data: { data },
  });

export const saveSymbolToRegionPresentation = async (data) =>
  await call({
    url: "/pathology/add/symbol/to/region/presentation",
    data: { data },
  });

export const updateStructureSymbolById = async (data) =>
  await call({
    url: "/pathology/update/dysfunctions/symbol/structures/by/id",
    data: { data },
  });

export const getDysfunctionsCategories = async () =>
  await call({
    method: "GET",
    url: "/pathology/get/dysfunctions/cat",
  });

export const getDysfunctionsCategoriesByDisorderTypeAndStructure = async (
  data
) =>
  await call({
    url: "/pathology/get/dysfunctions/cat/by/structure/type",
    data: { data },
  });

export const getDysfunctionsCategoriesByDisorderType = async (data) =>
  await call({
    url: "/pathology/get/dysfunctions/cat/by/type",
    data: { data },
  });

export const getStructuresSymbolDysfunctionByPresentation = async (data) =>
  await call({
    url: "/pathology/get/dysfunctions/symbol/by/structures/presentation",
    data: { data },
  });

export const getRiskFactorSymbols = async (data) =>
  await call({
    url: "/pathology/get/risk/factor/symbols",
    data: { data },
  });

export const getStructuresSymbolDysfunction = async (data) =>
  await call({
    url: "/pathology/get/dysfunctions/symbol/by/structures",
    data: { data },
  });

export const getRegionWithDysfunctions = async (data) =>
  await call({
    url: "/pathology/get/regions/with/dysfunctions",
    data: { data },
  });

export const getPathologies = async () =>
  await call({ method: "GET", url: "/pathology/get/pathologies" });

export const getMedicalSystemTypes = async () =>
  await call({
    method: "GET",
    url: "/pathology/get/medical/system/type",
  });

export const getPathologiesByMonitoring = async (data) =>
  await call({
    url: "/pathology/get/pathologies/by/monitoring",
    data: { data },
  });

export const saveMonitoringPathology = async (data) =>
  await call({
    url: "/pathology/save/monitoring/pathology",
    data: { data },
  });

export const getPathologyMonitoringDetails = async (data) =>
  await call({
    url: "/pathology/get/pathology/monitoring/details",
    data: { data },
  });

export const updatePathologyEndDate = async (data) =>
  await call({
    url: "/pathology/update/pathology/end/date",
    data: { data },
  });

export const updateDysfunctionsWeights = async (data) =>
  await call({
    url: "/pathology/update/dysfunctions/weights",
    data: { data },
  });
