import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getMomentIdFromKey } from "../ReportHelperFns";

const dateFormat = "YYYY-MM-DD";
const axisFormat = "DD-MM";

const colorArray = [
  "#30a2ff",
  "#de32a1",
  "#075cfe",
  "#8B9556",
  "#ff5024",
  "#8941ff",
  "#31cccf",
  "#070cfe",
  "#813556",
  "#aa0014",
  "#894b11",
  "#31cc12",
  "#aabb54",
  "#1041ff",
  "#31cccf",
  "#1dffae",
  "#56ffae",
  "#56ffee",
  "#232377",
  "#78ff",
  "#552377",
];

const PROMSLineGraph = ({
  data,
  graphDateView,
  dateRange,
  defaultZero,
  ...props
}) => {
  const [graphData, setGraphData] = useState(data);
  const [legendData, setLegendData] = useState([]);
  const [yAxisLabels, setYAxisLabels] = useState([]); //["axisYname"]

  const updateData = () => {
    if (graphDateView) {
      //Data format: { um: { label: { YYYY-MM-DD: val, ... }, ... }, ...}
      //Recharts format [{ xAxis: "YYYY-MM-DD", study_curve_name_subject_name_parameter_merged: datapoint }}]

      //Convert averaged data into recharts format
      const legendData = [];
      const flattenedData = {};
      const measureUnits = new Set();
      //Iterate measure units
      Object.entries(data).forEach(([units, umData]) => {
        //Iterate labels
        Object.entries(umData).forEach(([label, values]) => {
          //Flatten data
          const flattenedKey = `${label} (${units})`;
          flattenedData[flattenedKey] = values;

          //Prepare legend
          legendData.push({
            legend: flattenedKey,
            yAxisId: units,
          });
          measureUnits.add(units);
        });
      });
      setLegendData(legendData);
      setYAxisLabels([...measureUnits].sort((a, b) => b.localeCompare(a)));

      //Convert flattened data into recharts format
      const graphData = [];
      for (let day of dateRange.reverseBy(
        getMomentIdFromKey(graphDateView?.key)
      )) {
        const dayKey = day.format(dateFormat);
        const obj = { xAxis: day.format(axisFormat) };
        Object.entries(flattenedData).forEach(([key, val]) => {
          if (val[dayKey] != null) obj[key] = val[dayKey];
        });
        graphData.push(obj);
      }
      setGraphData(graphData.reverse());
    }
  };

  useEffect(() => {
    updateData();
  }, [data]);

  return (
    <Grid item xs={12} container style={{ marginTop: "5px" }} wrap="nowrap">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart style={{ color: "white !important" }} data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={"xAxis"} stroke="white" />
          {yAxisLabels.length > 0 && (
            <YAxis
              yAxisId={yAxisLabels[0]}
              stroke="white"
              width={50}
              label={{
                value: yAxisLabels[0],
                position: "insideLeft",
                stroke: "white",
                angle: yAxisLabels[0].length <= 1 ? 0 : -90,
              }}
              type="number"
            />
          )}
          {yAxisLabels.length > 1 && (
            <YAxis
              yAxisId={yAxisLabels[1]}
              stroke="white"
              width={50}
              label={{
                value: yAxisLabels[1],
                position: "insideRight",
                stroke: "white",
                angle: yAxisLabels[1].length <= 1 ? 0 : 90,
              }}
              orientation="right"
              type="number"
            />
          )}
          <Tooltip
            contentStyle={{ backgroundColor: "#FFFFFFDF" }}
            wrapperStyle={{ zIndex: 99 }}
            formatter={(value, name, props) => [value.toFixed(2), name]}
          />
          <Legend />
          {legendData.map(({ legend, yAxisId }, idx) => (
            <Line
              key={legend}
              type="monotone"
              yAxisId={yAxisId}
              strokeWidth={3}
              dataKey={legend}
              stroke={colorArray[idx % colorArray.length]}
              activeDot={{ r: 8 }}
              dot={!defaultZero}
              isAnimationActive={false}
              connectNulls={true}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default PROMSLineGraph;
