import React from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import SpText from "../../atoms/SpText";
import SpTextInput from "../../atoms/SpTextInput";
import SpButton from "../../atoms/SpButton";
import { labels, psTranslate } from "../../../pages/shared/translations";

const Feedbacks = ({ event, setValue, click, register }) => (
  <Grid direction="column" container style={{ marginTop: "2%" }} spacing={2}>
    {event.patients.map((patAct) => {
      return (
        <Grid
          key={patAct.id}
          direction="column"
          container
          style={{ marginTop: "2%" }}
          spacing={2}
        >
          <Grid item container direction="row" xs={12}>
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: "#31ccad",
                  marginTop: "1%",
                  height: 1,
                  marginBottom: "1%",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SpText variant="h1PageSubtitle">{patAct.patientName}</SpText>
            </Grid>
          </Grid>

          {patAct.patientFeedbacks && patAct.patientFeedbacks?.length > 0 && (
            <Grid item xs={12}>
              <form style={{ marginTop: "5px" }}>
                {patAct.patientFeedbacks?.map((feed) => {
                  setValue(`feed_resp_ans[${feed.id}].id`, feed.id);
                  return (
                    <Grid
                      key={`pat_feed_${feed.id}`}
                      direction="row"
                      container
                      alignItems="center"
                      style={{ marginTop: "5px" }}
                    >
                      <Grid item xs={12}>
                        {event.archiving_status ===
                          "completed_and_feedback_not_sent" ||
                        event.archiving_status ===
                          "completed_and_feedback_sent" ? (
                          <SpText variant="h4ComponentLabel">
                            {`${psTranslate(
                              feed?.feedback_parameter?.name
                            )} (in ${feed?.feedback_parameter?.um})`}
                            :{" "}
                            <Box
                              color={"#31ccad"}
                              style={{ display: "inline-block" }}
                            >
                              {feed.answer}
                            </Box>
                          </SpText>
                        ) : (
                          <SpTextInput
                            name={`feed_resp_ans[${feed.id}].answer`}
                            type="number"
                            formControlStyle={{ width: "100%" }}
                            minValue={0}
                            inputRef={register}
                            defaultValue={feed.answer}
                            label={`${psTranslate(
                              feed?.feedback_parameter?.name
                            )} (in ${feed?.feedback_parameter?.um})`}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </form>
            </Grid>
          )}
        </Grid>
      );
    })}
    {!(
      event.archiving_status === "completed_and_feedback_not_sent" ||
      event.archiving_status === "completed_and_feedback_sent"
    ) && (
      <Grid xs={12} item>
        <SpButton
          buttonType="accept"
          style={{ width: "100%" }}
          text={labels.bundles.calendarPlan.feedback.save}
          variant="h1PageTitle"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            click();
          }}
        />
      </Grid>
    )}
  </Grid>
);

export default Feedbacks;
