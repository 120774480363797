import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import { labels } from "../../../../../shared/translations";
import StudySharedControls from "../SharedControls";
import {
  averageInRange,
  computeInRange,
  computeZScore,
  computeZScoreWithAverageAndStddev,
  getMomentIdFromKey,
  getValuesInRange,
} from "../../../ReportHelperFns";

const moment = extendMoment(Moment);

const dateFormat = "YYYY-MM-DD";
const RADAR_KEY = "RADAR";

const ZSCORE_SPECIFIC_KEY = "Subject fixed Period";

const N_PERIODS = 1;

const StudyZScoreSubjectsFixedPeriods = ({
  mainSubjectKey,
  study,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onStudyDataChange,
  onFetchDateDaysChange,
  zScoreActiveAndEnabled,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [otherPeriods, setOtherPeriods] = useState(
    Array(N_PERIODS).fill(moment.rangeFromInterval("month", -1, moment()))
  );

  const updateFilterDataLINEAR = (by) => {
    return otherPeriods.reduce((accum, period, idx) => {
      const key = `${ZSCORE_SPECIFIC_KEY} ${idx + 1}`;

      //Iterate over subjects
      accum[key] = Object.entries(data).reduce(
        (accum, [subjectName, subjectData]) => {
          //Can't z-score self
          if (subjectName != mainSubjectKey) {
            //Iterate over study parameters
            accum[subjectName] = Object.entries(subjectData).reduce(
              (accum, [studyParamKey, studyData]) => {
                //Compute avg & stddev (using "by" steps)
                let dayAverages = averageInRange(
                  period,
                  by,
                  (range) => range.reverseBy("day", { excludeStart: true }),
                  (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null)
                );
                dayAverages = Object.values(dayAverages);
                const valAvg =
                  dayAverages.reduce((a, b) => a + b, 0) / dayAverages.length;
                const valStddev = Math.sqrt(
                  dayAverages.reduce(
                    (acc, val) => acc + Math.pow(val - valAvg, 2),
                    0
                  ) / dayAverages.length
                );

                //Compute Z-Score with all period reference
                accum[studyParamKey] = computeInRange(
                  dateRange,
                  by,
                  (range) => range.reverseBy("day", { excludeStart: true }), //Always use full reference range
                  (dayStr) =>
                    data[mainSubjectKey]?.[studyParamKey]?.[dayStr] ??
                    (defaultZero ? 0 : null),
                  (values) => {
                    const avg = values.reduce((a, b) => a + b) / values.length;
                    return computeZScoreWithAverageAndStddev(
                      avg,
                      valAvg,
                      valStddev
                    );
                  }
                );
                return accum;
              },
              {}
            );
          }
          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  const updateFilterDataRADAR = (by) => {
    return otherPeriods.reduce((accum, period, idx) => {
      const key = `${ZSCORE_SPECIFIC_KEY} ${idx + 1}`;
      //Iterate over subjects
      accum[key] = Object.entries(data).reduce(
        (accum, [subjectName, subjectData]) => {
          //Can't z-score self
          if (subjectName != mainSubjectKey) {
            //Iterate over study parameters
            accum[subjectName] = Object.entries(subjectData).reduce(
              (accum, [studyParamKey, studyData]) => {
                //Find first data in date range
                let endDate = [...dateRange.reverseBy("day")].find((day) => {
                  const dayStr = day.format(dateFormat);
                  return studyData[dayStr] || defaultZero;
                });
                endDate = endDate ?? dateRange.end;

                //Compute average of single "by"
                const currentRange = moment.rangeFromInterval(by, -1, endDate);
                let currentValues = getValuesInRange(
                  data[mainSubjectKey]?.[studyParamKey] ?? {},
                  currentRange,
                  defaultZero
                );
                const valAvg =
                  currentValues.reduce((a, b) => a + b, 0) /
                  currentValues.length;

                //Obtain values for days interval
                const referenceValues = getValuesInRange(
                  studyData,
                  period,
                  defaultZero
                );

                //Compute z score
                accum[studyParamKey] = computeZScore(valAvg, referenceValues);

                return accum;
              },
              {}
            );
          }
          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  const updatePeriods = (newPeriod, idx) => {
    //Set periods
    const newPeriods = [...otherPeriods];
    newPeriods[idx] = newPeriod;
    setOtherPeriods(newPeriods);

    //Request data
    let newRange = dateRange;
    newPeriods.forEach((period) => {
      if (period.start.isBefore(newRange.start))
        newRange = moment.range(period.start, newRange.end);
      if (period.end.isAfter(newRange.end))
        newRange = moment.range(newRange.start, period.end);
    });
    onFetchDateDaysChange(newRange);
  };

  //Effects
  useEffect(() => {
    let newData;
    //No ZSCORE_SPECIFIC_KEY here, will be provided by update data function
    if (active && zScoreActiveAndEnabled) {
      newData = {
        [study.key]:
          graphType?.key === RADAR_KEY
            ? updateFilterDataRADAR(getMomentIdFromKey(graphDateView?.key))
            : updateFilterDataLINEAR(getMomentIdFromKey(graphDateView?.key)),
      };
    } else {
      newData = { [study.key]: {} };
      //Zero only specific keys
      for (let idx = 0; idx < N_PERIODS; idx++) {
        const key = `${ZSCORE_SPECIFIC_KEY} ${idx + 1}`;
        newData[study.key][key] = {};
      }
    }

    onStudyDataChange(newData);
  }, [
    zScoreActiveAndEnabled,
    active,
    data,
    dateRange,
    graphDateView,
    otherPeriods,
    graphType,
  ]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      setActive(config.active);

      const newRanges = config.otherRanges.map((periodDiff) => {
        const nowStart = moment().startOf("day").add(periodDiff[0], "days");
        const nowEnd = moment().startOf("day").add(periodDiff[1], "days");
        return moment.range(nowStart, nowEnd);
      });
      //Ensure correct number
      for (let i = newRanges.length; i < N_PERIODS; i++)
        newRanges.push(moment.rangeFromInterval("month", -1, moment()));
      setOtherPeriods(newRanges);

      setConfigDone(true);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      const now = moment().startOf("day");
      const ranges = otherPeriods.map((period) => [
        period.start.diff(now, "days"),
        period.end.diff(now, "days"),
      ]);
      //Save config
      setConfig({
        active: active,
        otherRanges: ranges,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  const constPadding = { padding: "5px" };
  return (
    <StudySharedControls
      title={
        labels.patient.graphReport.section.feedback.studies[
          "ZSCORE_SUBJECT_FIXED"
        ]
      }
      active={active}
      setActive={setActive}
      enabled={zScoreActiveAndEnabled}
    >
      {otherPeriods.map((_, idx) => (
        <Grid item container xs={12} key={idx}>
          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters.start_date
              }
              value={otherPeriods[idx].start.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              onChange={(evnt) =>
                moment(evnt.target.value).isBefore(otherPeriods[idx].end) &&
                updatePeriods(
                  moment.range(evnt.target.value, otherPeriods[idx].end),
                  idx
                )
              }
            />
          </Grid>

          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters.end_date
              }
              value={otherPeriods[idx].end.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              onChange={(evnt) =>
                otherPeriods[idx].start.isBefore(moment(evnt.target.value)) &&
                updatePeriods(
                  moment.range(otherPeriods[idx].start, evnt.target.value),
                  idx
                )
              }
            />
          </Grid>
        </Grid>
      ))}
    </StudySharedControls>
  );
};

export default StudyZScoreSubjectsFixedPeriods;
