import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getPromsIndexes } from "../../../../models/actions/Proms";
import { labels, psTranslate } from "../../../shared/translations";
import SpText from "../../../../components/atoms/SpText";

const PROMSIndexes = ({ selectedProm, ...props }) => {
  const [promsIndexes, setPromsIndexes] = useState([]);

  const updatePromsIndex = async () => {
    //Show mcids
    const indexes = await getPromsIndexes({
      id_proms: selectedProm.id,
      type: "mcid",
    });
    setPromsIndexes(indexes);
  };

  useEffect(() => {
    if (selectedProm) updatePromsIndex();
  }, [selectedProm]);

  return (
    promsIndexes &&
    promsIndexes.length > 0 && (
      <Grid
        item
        xs={12}
        style={{
          border: "1px solid #31ccad",
          padding: "10px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
        wrap="nowrap"
      >
        {promsIndexes.map((promsIndex) => (
          <SpText variant="text" key={promsIndex.id}>
            {`${psTranslate(promsIndex.name)}: `}
            <SpText
              variant={"h4ComponentLabelRegular"}
              style={{ display: "inline-block" }}
            >
              {promsIndex.value}
              {promsIndex.um}
            </SpText>
          </SpText>
        ))}
      </Grid>
    )
  );
};

export default PROMSIndexes;
