import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpIconButton from "../../../components/atoms/SpIconButton";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import { getPathologies } from "../../../models/actions/Pathologies";
import {
  deletePastHistoryPathology,
  getPatientPastHistoryPathologies,
  savePastHistoryPathologies,
} from "../../../models/actions/Patients";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { DeleteForever } from "@material-ui/icons";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import { createFilterOptions } from "@material-ui/lab";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
  paddingBottom: "2%",
});

const StyledColumn = styled("div")({
  flex: "1 1 100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "3%",
  marginBottom: "1%",
  backgroundColor: "#333333",
  borderColor: "#31ccad",
  border: "1px solid",
  "&:disabled": {
    color: "#31ccad",
  },
});

const PathologiesInput = (props) => {
  const [pathologies, setPathologies] = useState([]);
  const [pathologiesPastHistory, setPathologiesPastHistory] = useState([]);

  const { patId } = useParams();
  const { setLoading } = props;

  const { reset, control, register, handleSubmit, getValues } = useForm({
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "pathologies",
    keyName: "id",
  });

  const filterOptions = createFilterOptions({
    limit: 20,
    stringify: (option) =>
      psTranslate(option.name) +
      psTranslate(option.etiology?.name) +
      psTranslate(option.medical_system_type?.name) +
      psTranslate(option.pathology_type?.name) +
      psTranslate(option.tissue_type?.name) +
      option.regions.map((e) => psTranslate(e.name))?.join(","),
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getPatientPastHistoryPathologies({
        id_patient: patId,
      });
      const formattedResults = result.map((p) => {
        //console.log(p);
        return {
          id: p.id,
          id_pathology: p.id_pathology,
          operation_type: p.operation_type,
          current_status: p.current_status,
          rehab_duration: p.rehab_duration,
          date: p.date,
        };
      });
      setPathologiesPastHistory(formattedResults);
      const resultPathologies = await getPathologies();
      setPathologies(resultPathologies);
      remove();
      append(formattedResults);
      //console.log(fields);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const addPathology = async () => {
    try {
      append({
        id: "",
        id_pathology: "",
        operation_type: "",
        current_status: "",
        rehab_duration: "",
        date: "",
      });
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const removePathology = async (pathologyId, idx) => {
    //console.log("ciao", pathologyId, idx);
    try {
      if (pathologyId) {
        const result = await deletePastHistoryPathology({
          id_pathology_past_history: pathologyId,
        });
        if (result.message) {
          remove(idx);
          props.snackbarShowMessage(result.message);
        }
      } else {
        remove(idx);
        props.snackbarShowMessage(
          labels.patient.pastHistory.stepper.pathologies.pathologyRemoved
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const updatePathology = async (data) => {
    try {
      if (
        data.pathologies.filter((p) => !p.id_pathology).length > 0 ||
        data.pathologies.length === 0
      ) {
        props.snackbarShowErrorMessage(
          labels.patient.pastHistory.stepper.pathologies.compileAllFields
        );
      } else {
        const result = await savePastHistoryPathologies({
          pathologies: data.pathologies,
          id_patient: patId,
        });
        if (result?.message) {
          props.snackbarShowMessage(result.message);
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <StyledBodyColumn>
        <StyledRow style={{ paddingBottom: 0 }}>
          <Grid container xs={12}>
            <Grid xs={10} item>
              <SpText variant="h1PageTitle">
                {labels.patient.pastHistory.stepper.pathologies.title.toUpperCase()}
              </SpText>
            </Grid>
            <Grid xs={2} item style={{ alignItems: "center" }}>
              <SpButton
                buttonType="accept"
                style={{ marginRight: "0", width: "100%" }}
                onClick={() => handleSubmit(updatePathology)()}
                text={labels.patient.pastHistory.stepper.pathologies.save}
              />
            </Grid>
          </Grid>
        </StyledRow>
        <form>
          <StyledColumn>
            {fields.map((pathology, obsIdx) => (
              <StyledGridContainer key={pathology.id}>
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid item xs style={{ paddingBottom: 20 }}>
                    <Controller
                      render={(props) => (
                        <SpAutocomplete
                          formControlWidth={"100%"}
                          disableListWrap
                          label={
                            labels.patient.pastHistory.stepper.pathologies
                              .pathology
                          }
                          selectPlaceholder={
                            labels.patient.pastHistory.stepper.pathologies
                              .pathology
                          }
                          value={pathologies.find((el) => el.id == props.value)}
                          onChange={(e, newValue) =>
                            props.onChange(newValue.id)
                          }
                          options={pathologies}
                          getOptionLabel={(option) => psTranslate(option.name)}
                          getOptionSelected={(option, value) =>
                            option.id === value?.id
                          }
                          filterOptions={filterOptions}
                        />
                      )}
                      name={`pathologies[${obsIdx}].id_pathology`}
                      control={control}
                      defaultValue={pathology?.id_pathology}
                    />
                  </Grid>
                  <Grid item xs>
                    <SpTextInput
                      label={
                        labels.patient.pastHistory.stepper.pathologies
                          .operationType
                      }
                      name={`pathologies[${obsIdx}].operation_type`}
                      defaultValue={pathology?.operation_type ?? ""}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs>
                    <SpTextInput
                      type="date"
                      maxValue={"2100-12-31"}
                      label={
                        labels.patient.pastHistory.stepper.pathologies
                          .pathologyDate
                      }
                      name={`pathologies[${obsIdx}].date`}
                      // defaultValue={pathology?.date ? moment(pathology?.date).format("yyyy-MM-DD") : moment().format("yyyy-MM-DD")}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs>
                    <SpTextInput
                      label={
                        labels.patient.pastHistory.stepper.pathologies
                          .currentStatus
                      }
                      name={`pathologies[${obsIdx}].current_status`}
                      defaultValue={pathology?.current_status ?? ""}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs>
                    <SpTextInput
                      label={
                        labels.patient.pastHistory.stepper.pathologies
                          .rehabDuration
                      }
                      type="number"
                      minValue={0}
                      name={`pathologies[${obsIdx}].rehab_duration`}
                      defaultValue={pathology?.rehab_duration}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs>
                    <SpIconButton
                      variant="lightBlueFill"
                      onClick={() => removePathology(pathology?.id, obsIdx)}
                    >
                      <DeleteForever />
                    </SpIconButton>
                  </Grid>
                </Grid>
              </StyledGridContainer>
            ))}
            <SpIconButton
              onClick={() => addPathology()}
              width={"300px"}
              additionalText={
                labels.patient.pastHistory.stepper.pathologies.addPathology
              }
            >
              <AddIcon />
            </SpIconButton>
          </StyledColumn>
        </form>
      </StyledBodyColumn>
    </>
  );
};

export default withSnackbar(PathologiesInput);
