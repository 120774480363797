import React from "react";
import "./App.css";
import Registration from "./pages/Registration.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Patients from "./pages/Patients";
import LoginAuth0 from "./pages/LoginAuth0";
import LoginUsernamePsw from "./pages/LoginUsernamePsw";
import Layout from "./pages/shared/layout/Layout";
import LayoutBase from "./pages/shared/layout/LayoutBase";
import Groups from "./pages/Groups";
import GroupsDetail from "./pages/groups/GroupsDetail";
import GroupsAdd from "./pages/groups/GroupsAdd";
import Agenda from "./pages/Agenda";
import Library from "./pages/Library";
import Account from "./pages/Account";
import AccountReports from "./pages/AccountReports";
import Faq from "./pages/Faq";
import PatientsDetail from "./pages/patients/PatientsDetail";
import PatientsLink from "./pages/patients/PatientsLink";
import PatientsAdd from "./pages/patients/PatientsAdd";
import PatientsRegistry from "./pages/patients/PatientsRegistry";
import PatientsPastHistory from "./pages/patients/PatientsPastHistory";
import PatientsMonitoring from "./pages/patients/PatientsMonitoring";
import PatientsPresentations from "./pages/patients/PatientsPresentations";
import PatientsPlan from "./pages/patients/PatientsPlan";
import PatientsReports from "./pages/patients/PatientsReports";
import PatientsControlQuestion from "./pages/patients/PatientsControlQuestion";
import PatientsResources from "./pages/patients/PatientsResources";
import PatientsTransferPatient from "./pages/patients/PatientsTransferPatient";
import PatientsPresentationAdd from "./pages/patients/PatientsPresentationAdd";
import PatientsPresentationDetail from "./pages/patients/PatientsPresentationDetail";
import MyLabTemp from "./pages/mylab/MyLabTemp";
import MyLabAddTemp from "./pages/mylab/MyLabAddTemp";
import MyLab from "./pages/MyLab";
import Agency from "./pages/Agency";
import AgencyLicenses from "./pages/agency/AgencyLicenses";
import AgencyAccount from "./pages/agency/AgencyAccount";
import LayoutAgency from "./pages/shared/layout/LayoutAgency";
import "core-js/stable";
import "regenerator-runtime/runtime";
import PatientsMonitoringAddProms from "./pages/patients/patientsMonitoring/PatientsMonitoringAddProms";
import PatientsPresentationAddProms from "./pages/patients/patientsPresentationDetail/PatientsPresentationAddProms";
import PrivateRoute from "./components/routes/PrivateRoute";
import GroupReports from "./pages/groups/GroupReports";
import GroupActivityPlan from "./pages/groups/GroupActivityPlan";
import NotFound from "./pages/NotFound";
import Error from "./pages/Error";

import LicenceExpired from "./pages/LicenceExpired";
import ErrorBoundaryClass from "./ErrorBoundaryClass";

const App = (props) => {
  return (
    <Router>
      <Switch>
        {/* TODO: Controllare se funziona ErrorBoubryClass, 
      deve essere passato il componentName */}
        {/* <Route exact path="/registration" componentName="Registration">
          <ErrorBoundaryClass>
            <LayoutBase>
              <Registration />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route> */}
        <Route exact path="/login-auth0">
          <ErrorBoundaryClass componentName="LoginAuth0">
            <LayoutBase>
              <LoginAuth0 />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route>
        <Route exact path="/login">
          <ErrorBoundaryClass componentName="LoginUsernamePsw">
            <LayoutBase>
              <LoginUsernamePsw />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route>
        <PrivateRoute exact path="/" componentName="Dashboard">
          <ErrorBoundaryClass>
            <Layout>
              <Dashboard />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/not-found" componentName="NotFound">
          <ErrorBoundaryClass>
            <Layout>
              <NotFound />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>

        <Route
          exact
          path="/error"
          render={(props) => {
            return (
              <Layout>
                <Error {...props} />
              </Layout>
            );
          }}
        />

        <PrivateRoute
          exact
          path="/licence-expired"
          componentName="LicenceExpired"
        >
          <ErrorBoundaryClass>
            <LayoutBase>
              <LicenceExpired />
            </LayoutBase>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/patients" componentName="Patients">
          <ErrorBoundaryClass>
            <Layout>
              <Patients />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId"
          componentName="PatientsDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsDetail />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/registry"
          componentName="PatientsRegistry"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsRegistry />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/past-history"
          componentName="PatientsPastHistory"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPastHistory />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/monitoring"
          componentName="PatientsMonitoring"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsMonitoring />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/monitoring/add_proms"
          componentName="PatientsMonitoringAddProms"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsMonitoringAddProms />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentation/:presentationId/add_proms"
          componentName="PatientsPresentationAddProms"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationAddProms />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentations"
          componentName="PatientsPresentations"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentations />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentation/:presentationId"
          componentName="PatientsPresentationDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationDetail />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentations/add"
          componentName="PatientsPresentationAdd"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/plan"
          componentName="PatientsPlan"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPlan />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/report"
          componentName="PatientsReports"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsReports />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/control-quest"
          componentName="PatientsControlQuestion"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsControlQuestion />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/resource"
          componentName="PatientsResources"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsResources />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/transfer"
          componentName="PatientsTransferPatient"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsTransferPatient />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/patients/create" componentName="PatientsAdd">
          <ErrorBoundaryClass>
            <Layout>
              <PatientsAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/patients/link" componentName="PatientsLink">
          <ErrorBoundaryClass>
            <Layout>
              <PatientsLink />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/groups" componentName="Groups">
          <ErrorBoundaryClass>
            <Layout>
              <Groups />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/edit/:groupId"
          componentName="GroupsDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupsDetail />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/groups/add" componentName="GroupsAdd">
          <ErrorBoundaryClass>
            <Layout>
              <GroupsAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/statistics/:groupId"
          componentName="GroupReports"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupReports />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/activity/:groupId"
          componentName="GroupActivityPlan"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupActivityPlan />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/agenda" componentName="Agenda">
          <ErrorBoundaryClass>
            <Layout>
              <Agenda />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/library" componentName="Library">
          <ErrorBoundaryClass>
            <Layout>
              <Library />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/faq" componentName="Faq">
          <ErrorBoundaryClass>
            <Layout>
              <Faq />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/account" componentName="Account">
          <ErrorBoundaryClass>
            <Layout>
              <Account />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/reports" componentName="AccountReports">
          <ErrorBoundaryClass>
            <Layout>
              <AccountReports />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/mylab" componentName="MyLab">
          <ErrorBoundaryClass>
            <Layout>
              <MyLab />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName"
          componentName="MyLabTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabTemp />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName/add"
          componentName="MyLabAddTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabAddTemp />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName/add/:idElement"
          componentName="MyLabAddTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabAddTemp />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/agency" componentName="Agency">
          <ErrorBoundaryClass>
            <Layout>
              <Agency />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/agency/licenses"
          componentName="AgencyLicenses"
        >
          <ErrorBoundaryClass>
            <LayoutAgency>
              <AgencyLicenses />
            </LayoutAgency>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/agency/account"
          componentName="AgencyAccount"
        >
          <ErrorBoundaryClass>
            <LayoutAgency>
              <AgencyAccount />
            </LayoutAgency>
          </ErrorBoundaryClass>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;