import React, { useEffect, useState } from "react";
import axios from "axios";
import { isBusiness, isAuth0Enabled, log } from "../../utils/common";
import PrivateRouteAuth0 from "./PrivateRouteAuth0";
import PrivateRouteUsernamePsw from "./PrivateRouteUsernamePsw";
// import { rollbar } from "./utils/common";
import { rollbar } from "../../utils/common";

const PrivateRoute = ({ children, ...rest }) => {
  const [enableAuth0, setEnableAuth0] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const auth0Enabled = await isAuth0Enabled();
      setEnableAuth0(auth0Enabled);
      setLoading(false);
    } catch (e) {
      rollbar.error("PrivateRoute - fetch flag", e);
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return isBusiness() || enableAuth0 ? (
      <PrivateRouteAuth0 {...rest}>{children}</PrivateRouteAuth0>
    ) : (
      <PrivateRouteUsernamePsw {...rest}>{children}</PrivateRouteUsernamePsw>
    );
  }
};

export default PrivateRoute;