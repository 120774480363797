import * as React from "react";
import { DataGrid, GridOverlay, itIT } from "@mui/x-data-grid";
import { styled } from "../styled";
import SpText from "../atoms/SpText";
import "../atoms/App.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { labels } from "../../pages/shared/translations";

const themeMUI = createMuiTheme(
  {
    root: {
      background: "transparent",
    },
  },
  itIT
);

const StyledDataGrid = styled(DataGrid)({
  height: 400,
  width: "100%",
  maxWidth: "100%",
  background: "transparent",
  color: "#FFFFFF",
  border: 0,
  "& .MuiTablePagination-root": {
    color: "#FFF",
    background: "#31caad",
  },
});

const SpDataGrid = ({
  hideFooterPagination = true,
  showPagination = false,
  rows,
  headers,
  children: Row,
  emptyText = labels.general.no_data,
}) => (
  <div style={{ width: "100%" }}>
    <StyledDataGrid
      components={{
        NoRowsOverlay: () => (
          <GridOverlay
            style={{ background: "transparent", flexDirection: "column" }}
          >
            <SpText variant="tableText">{emptyText}</SpText>
          </GridOverlay>
        ),
      }}
      theme={themeMUI}
      localeText={itIT.props.MuiDataGrid.localeText}
      headerHeight={37}
      rows={rows}
      columns={headers ?? []}
      pageSize={rows?.length > 100 ? 100 : rows?.length}
      rowsPerPageOptions={[rows.length > 100 ? 100 : rows.length]}
      disableEnforceFocus
      rowCount={rows.length}
      autoHeight={true}
      hideFooterPagination={hideFooterPagination}
      checkboxSelection={false}
      pagination={showPagination}
      disableSelectionOnClick
    />
  </div>
);

export default SpDataGrid;
