import { Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment";
import React, { Children, useEffect, useState } from "react";
/* import { Calendar, momentLocalizer, Views } from "react-big-calendar"; */
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import "../../../App.css";
import "../../../components/atoms/App.css";
import { nanoid } from "nanoid";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../../../components/atoms/SpDragAndDropCalendarItems";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextArea from "../../../components/atoms/SpTextArea";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import {
  getAllActivitiesTypes,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
} from "../../../models/actions/Activity";
import {
  createCalendarActivityTemplate,
  getAllCalendarActivityTempById,
  updateActivityPlanTemplate,
} from "../../../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../../../models/actions/Excercise";
import { getLanguage, labels, psTranslate } from "../../shared/translations";
import AgendaCalendarEventCard from "../../agenda/AgendaCalendarEventCard";
import A1_AssessmentInputDetails from "../../agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "../../agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "../../agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "../../agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "../../agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "../../agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "../../agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "../../agenda/inputDetails/A8_RehabilitationInputDetails";
import { rollbar } from "../../../utils/common";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const StyledRowItem = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const AddActivity = (props) => {
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [activityFormError, setActivityFormError] = useState(true);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [activitiesArray, setActivitiesArray] = useState([]);
  const [error, setError] = useState("");

  const { idElement } = useParams();
  const history = useHistory();

  moment.locale(getLanguage(), {
    week: {
      dow: 1,
      doy: 1,
    },
  });
  const localizer = momentLocalizer(moment);

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!draggedEvent) {
      reset({ id_activity_type: null });
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  const fetchDataInitial = async () => {
    try {
      const [activityTypeResponse, feedbackParametersList] = await Promise.all([
        getAllActivitiesTypes(),
        getAllFeedbackParameters(),
      ]);

      setActivityTypeList(activityTypeResponse);
      feedbackParametersList && setLoadParametersData(feedbackParametersList);
    } catch (error) {
      rollbar.error("AddActivity - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      if (idElement) {
        const responseEvents = await getAllCalendarActivityTempById({
          id_activity_template: idElement,
        });
        setValue("template_name", responseEvents.template_name);
        const acts0 = updateEventsList(responseEvents.activities);

        const acts = acts0.map((e) =>
          createActivityObject(e, e.id_activity_type, true)
        );
        setActivitiesArray(acts);
      }
    } catch (error) {
      rollbar.error("AddActivity - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    setLoadParametersList(event.feedback_parameter);
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const renderCardTitle = (event, idActivityType, motivation, isArchived) => (
    <Grid direction="column">
      <SpText variant="h4ComponentLabel">
        {event.id_activity_type === idActivityType && !motivation
          ? psTranslate(event.title)
          : motivation}
      </SpText>
      {event.id_activity_type === idActivityType && isArchived ? (
        <SpText variant="textWhiteArchived">{labels.groups.archived}</SpText>
      ) : (
        ""
      )}
    </Grid>
  );

  const ColoredEventCell = ({ event }) => {
    const data = event?.data;
    const isArchived =
      event.archiving_status === "completed_and_feedback_not_sent" ||
      event.archiving_status === "completed_and_feedback_sent";
    return (
      <div style={{ display: "flex", flex: 1 }}>
        <div
          className="rbc-event-label"
          style={{ display: "flex", flexDirection: "column", maxHeight: 50 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={event.media} style={{ width: 30, height: 30 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: 5,
              }}
            >
              {renderCardTitle(
                event,
                1,
                data?.assessment_motivation,
                isArchived
              )}
              {renderCardTitle(
                event,
                2,
                data?.treatments_motivation,
                isArchived
              )}
              {renderCardTitle(
                event,
                3,
                data?.excercises_motivation,
                isArchived
              )}
              {renderCardTitle(event, 4, data?.training_motivation, isArchived)}
              {renderCardTitle(event, 5, data?.rest_motivation, isArchived)}
              {renderCardTitle(
                event,
                6,
                data?.appointment_motivation,
                isArchived
              )}
              {renderCardTitle(event, 7, data?.race_motivation, isArchived)}
              {renderCardTitle(
                event,
                8,
                data?.rehabilitation_motivation,
                isArchived
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "96%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };
    handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    setValue("id_activity_type", draggedEvent.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = eventsList.map((existingEvent) => {
      return existingEvent.id === event.id
        ? {
            ...existingEvent,
            start_date: start,
            start,
            end,
            allDay,
          }
        : existingEvent;
    });
    setEventsList(nextEvents);
  };

  const updateEventsList = (data) => {
    let newEventsList = [];
    const activitiesArrayCurrent = data ? data : activitiesArray;
    activitiesArrayCurrent.map((event, eventIdx) => {
      //if we are mapping details data of an event i'll use the classic method otherwise i'll map the custom array
      if (idElement && !event.details_data) {
        newEventsList.push({
          id: event.id,
          title: event?.activity_datum.activity_type.name,
          description: "",
          start_date: event.start_date,
          start: new Date(moment.utc(event.start_date).valueOf()),
          end: new Date(moment.utc(event.start_date).valueOf()),
          start_time: event.start_time,
          end_time: event.end_time,
          type: event?.activity_datum?.name,
          event_note: event.event_note,
          id_presentation: event.id_presentation,
          presentation: event.presentation,
          id_activity_type: event?.activity_datum.activity_type.id,
          feedback_parameter: loadParametersList,
          media: require(`../../../assets/activities/${event?.activity_datum?.activity_type?.key}.png`),
          color: event.color,
          data: event.activity_datum.data,
          feedbacks: event.activity_feedbacks[0]
            ?.activity_resp_parameter_feedbacks
            ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
            : [],
          archiving_status: event.archiving_status,
          is_from_group_plan: event.is_from_group_plan,
          id_activity_group: event.id_activity_group,
          fl_template: event.fl_template,
          id_template: event.id_template,
          is_from_template: true,
        });
      } else {
        newEventsList.push({
          id: event.id,
          title: activityTypeList[event.id_activity_type - 1].name,
          description: "",
          start_date: event.start_date,
          start: new Date(moment.utc(event.start_date).valueOf()),
          end: new Date(moment.utc(event.start_date).valueOf()),
          start_time: event.start_time,
          end_time: event.end_time,
          type: activityTypeList[event.id_activity_type - 1].name,
          event_note: event.event_note,
          id_presentation: event.id_presentation,
          presentation: event.presentation,
          id_activity_type: event.id_activity_type,
          media: require(`../../../assets/activities/${
            activityTypeList[event.id_activity_type - 1]?.key
          }.png`),
          color: event.color,
          feedback_parameter: loadParametersList,
          data: event.details_data,
          feedbacks: event.feedback_parameter,
          archiving_status: event.archiving_status,
          is_from_group_plan: false,
          id_activity_group: false,
          is_from_template: true,
        });
      }
    });
    setEventsList(newEventsList);
    return newEventsList;
  };

  const createActivityObject = (
    data,
    id_activity_type,
    isGlobalFetch = false
  ) => {
    const _innerData = isGlobalFetch ? data.data : data;
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: _innerData.assessment_motivation,
        };
        break;
      case 2:
        data.details_data = {
          name: _innerData.name,
          treatments: _innerData.treatments,
          treatments_motivation: _innerData.treatments_motivation,
        };
        break;
      case 3:
        data.details_data = {
          name: _innerData.name,
          excercises: _innerData.excercises,
          excercises_motivation: _innerData.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: _innerData.name,
          training: _innerData.training,
          training_motivation: _innerData.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: _innerData.rest_motivation };
        break;
      case 6:
        data.details_data = {
          appointment_motivation: _innerData.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: _innerData.race_motivation,
          race_minutes: _innerData.race_minutes,
        };
        break;
      case 8:
        data.details_data = {
          name: _innerData.name,
          rehabilitations: _innerData.rehabilitations,
          rehabilitation_motivation: _innerData.rehabilitation_motivation,
        };
        break;
      default:
        break;
    }
    return data;
  };

  const updateActivityArray = async (data, deleteElem = false) => {
    try {
      let newArray;
      if (deleteElem) {
        newArray = activitiesArray.filter((el) => el.id != data.id);
      } else {
        newArray = [...activitiesArray];
        //New vs update
        if (!data.data) {
          data.id = nanoid(); //creating an id for the activity during creation
          data.start_time = "05:00"; //data.start_time;
          data.end_time = "22:00"; //data.start_time;
          data = createActivityObject(data, getValues("id_activity_type"));
          newArray.push(data);
        } else {
          data.details_data = data.data;
          delete data.data;
          const idx = newArray.findIndex((el) => el.id == data.id);
          newArray[idx] = Object.assign(newArray[idx], data);
        }
      }

      //update the current local array
      setActivitiesArray(newArray);
      updateEventsList(newArray);
      setLoadParametersList([]);
      setOpenAddActivity(false);
    } catch (error) {
      rollbar.error("AddActivity - updateActivityArray", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveActivityTemplate = async () => {
    try {
      const createdActivity = await createCalendarActivityTemplate({
        template_name: getValues("template_name"),
        activitiesArr: activitiesArray,
      });
      if (!createdActivity.error) {
        props.snackbarShowMessage(createdActivity.message);
        fetchData();
        setValue("id_activity_type", 0);
        setValue("event_note", "");
        setValue("feed_resp_ans", []);
        setOpenAddActivity(false);
        history.push("/mylab/activity-plan");
      } else {
        props.snackbarShowErrorMessage(createdActivity.error);
      }
    } catch (error) {
      rollbar.error("AddActivity - saveActivityTemplate", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateActivityTemplateData = async () => {
    try {
      const updateResponse = await updateActivityPlanTemplate({
        template_name: getValues("template_name"),
        id_activity_template: idElement,
        activitiesArr: eventsList,
      });
      props.snackbarShowMessage(updateResponse.message);
    } catch (error) {
      rollbar.error("AddActivity - updateActivityTemplateData", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  //method to render input details based on the type of activity selected
  const renderInputDetails = () => {
    const Detail = INPUT_DETAILS[getValues("id_activity_type")];
    return (
      Detail && (
        <Detail
          watchTemplateSelect={watchTemplateSelect}
          selectedTemplate={getValues("id_template")}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          register={register}
        />
      )
    );
  };

  const watchStartTime = watch("start_time");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      rollbar.error("AddActivity - fetchTemplateByActType", error);
    }
  };

  useEffect(async () => {
    await fetchDataInitial();
    fetchData();
  }, []);

  useEffect(() => {
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab/activity-plan")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.activityPlanList.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          <Grid
            item
            xs={4}
            alignItems={"center"}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            {!idElement ? (
              <SpButton
                buttonType={"accept"}
                type="submit"
                text={labels.mylab.tempAdd.activityPlan.actions.create}
                onClick={() => handleSubmit(saveActivityTemplate)()}
              />
            ) : (
              <SpButton
                buttonType={"accept"}
                type="submit"
                variant="none"
                style={{ marginLeft: "10px" }}
                text={labels.mylab.tempDetail.action.updateButton}
                onClick={() => handleSubmit(updateActivityTemplateData)()}
              />
            )}
          </Grid>
        </Grid>
        <StyledRow>
          <StyledRowItem>
            <SpText variant="h1PageSubtitle">
              {!idElement
                ? labels.mylab.tempAdd.activityPlan.subtitle
                : labels.mylab.tempDetail.activityPlan.subtitle}
            </SpText>
          </StyledRowItem>
        </StyledRow>
        <StyledRow>
          <StyledRowItem>
            <SpTextInput
              label={labels.mylab.templateName}
              formControlStyle={{ width: "50%" }}
              placeholder={labels.mylab.insertTemplateName}
              name="template_name"
              inputRef={register}
            />
          </StyledRowItem>
        </StyledRow>
        <Grid
          item
          xs={12}
          container
          direction={"row"}
          style={{ minHeight: "750px", marginTop: "10px" }}
          spacing={2}
        >
          <SpCalendarDragAndDropItems
            activitiesAvailable={activityTypeList}
            handleDragStart={handleDragStart}
          />
          <Grid item xs={11}>
            <DragAndDropCalendar
              selectable
              events={eventsList}
              scrollToTime={new Date(moment.utc("1900-01-01").valueOf())}
              defaultDate={new Date(moment.utc("1900-01-01").valueOf())}
              view={"month"}
              onEventDrop={handleEventDrop}
              onDragStart={() => console.log("Drag started")}
              dragFromOutsideItem={
                displayDragItemInCell ? dragFromOutsideItem : null
              }
              onDropFromOutside={onDropFromOutside}
              handleDragStart={handleDragStart}
              onSelectEvent={handleEventDetailSelected}
              onSelectSlot={handleNewEventSelected}
              draggableAccessor={(event) =>
                !(
                  event.archiving_status ===
                    "completed_and_feedback_not_sent" ||
                  event.archiving_status === "completed_and_feedback_sent"
                )
              }
              step={60}
              eventPropGetter={(event, start, end, isSelected) => {
                const backgroundColor = event.color;
                const style = {
                  backgroundColor: backgroundColor,
                  borderRadius: "5px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  border: "0px",
                  display: "block",
                };
                return {
                  style: style,
                };
              }}
              messages={{
                showMore: (total) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {`+${total} mostra ancora`}
                  </div>
                ),
              }}
              components={{
                toolbar: CustomToolbar,
                day: { header: MyCustomHeader },
                week: { header: MyCustomHeader },
                month: { header: MyCustomHeader },
                event: ColoredEventCell,
                eventWrapper: AgendaEventWrapper,
              }}
              localizer={localizer}
            />
          </Grid>
        </Grid>
      </Grid>
      {eventDetail && (
        <SpDialog
          variant="bigDialog"
          open={openDetailActivity}
          setOpen={setOpenDetailActivity}
          title={
            !eventDetail.is_from_group_plan
              ? labels.mylab.tempAdd.activityPlan.dialog.title
              : labels.mylab.tempAdd.activityPlan.dialog.groupActTitle
          }
        >
          <AgendaCalendarEventCard
            event={eventDetail}
            fetchData={() => {}}
            setOpenDetailActivity={setOpenDetailActivity}
            updateActivity={updateActivityArray}
            openDetailActivity={openDetailActivity}
            createActivityObject={createActivityObject}
            archiveEnabled={false}
            allowFeedback={false}
            loadParametersData={loadParametersData}
            loadParametersList={loadParametersList}
            setLoadParametersList={setLoadParametersList}
          />
        </SpDialog>
      )}
      <SpDialog
        variant="bigDialog"
        open={openAddActivity}
        setOpen={setOpenAddActivity}
        on
        title={labels.mylab.tempAdd.activityPlan.dialog.title}
      >
        <form onSubmit={handleSubmit((e) => updateActivityArray(e))}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {!getValues("id_activity_template") && (
              <Controller
                render={(props) => (
                  <SpSelect
                    label={labels.groups.plan.chooseActivity}
                    style={{ marginTop: "1%", marginBottom: "1%" }}
                    value={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      setValue("quantity_training", 0);
                      setValue("quantity_treatment", 0);
                      setValue("quantity_rehabilitation", 0);
                      setValue("quantity_excercise", 0);
                    }}
                  >
                    {activityTypeList.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require(`../../../assets/activities/${p.key}${
                              props.value === p.id ? "" : "_black"
                            }.png`)}
                            style={{ maxWidth: 25, maxHeight: 25 }}
                            alt={"img"}
                          />
                          <SpText
                            color={props.value === p.id ? "white" : "black"}
                            style={{
                              color: "black !important",
                              marginLeft: "5px",
                            }}
                          >
                            {psTranslate(p.name)}
                          </SpText>
                        </div>
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`id_activity_type`}
                control={control}
              />
            )}
            {templatesList.length > 0 && !getValues("id_activity_template") && (
              <Controller
                render={(props) => (
                  <SpSelect
                    label={labels.mylab.chooseActivityTemplate}
                    style={{ marginTop: "1%" }}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                  >
                    {templatesList.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        <SpText
                          color={props.value === p.id ? "white" : "black"}
                          style={{
                            color: "black !important",
                            marginLeft: "5px",
                          }}
                        >
                          {p.name}
                        </SpText>
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`id_template`}
                control={control}
              />
            )}
            {error && <SpText variant="h4ComponentLabelError">{error}</SpText>}
            {renderInputDetails()}
            <SpTextArea name="event_note" label="Note" inputRef={register} />
            <SpButton
              buttonType={"accept"}
              variant="none"
              text={labels.mylab.addActivity}
              style={{ marginTop: "4%" }}
              type="submit"
            />
          </div>
        </form>
      </SpDialog>
    </>
  );
};

export default withSnackbar(AddActivity);
