import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { styled } from "../../components/styled";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpDialog from "../../components/atoms/SpDialog";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { green, red } from "@material-ui/core/colors";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { SpStepper } from "../../components/bundles";

import { isInjuryDetailEnabled } from "../../utils/common";

import PatientsPresentationDetailStep0 from "./patientsPresentationDetail/PatientsPresentationDetailSummary";

import PatientsPresentationDetailStep1 from "./patientsPresentationDetail/PatientsPresentationDetailBodyMap";
import PatientsPresentationDetailStep2 from "./patientsPresentationDetail/PatientsPresentationDetailExitCriteria";
import PatientsPresentationDetailStep3 from "./patientsPresentationDetail/PatientsPresentationDetailMilestones";
import PatientsPresentationDetailStep4 from "./patientsPresentationDetail/PatientsPresentationDetailSins";
import PatientsPresentationDetailStep5 from "./patientsPresentationDetail/PatientsPresentationDetailAssessment";
import PatientsPresentationDetailStep6 from "./patientsPresentationDetail/PatientsPresentationDetailMainProms";
import PatientsPresentationDetailStep7 from "./patientsPresentationDetail/PatientsPresentationDetailInterview";
import {
  closePresentation,
  deletePresentationById,
  getExitCriteriaByPresentation,
  getPresentationById,
} from "../../models/actions/Presentation";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";

import moment from "moment";

const GreenRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const feedbackArray = [
  { id: 1, answer: labels.patient.presentation.feedbackArray[0] },
  { id: 2, answer: labels.patient.presentation.feedbackArray[1] },
  { id: 3, answer: labels.patient.presentation.feedbackArray[2] },
  { id: 4, answer: labels.patient.presentation.feedbackArray[3] },
  { id: 5, answer: labels.patient.presentation.feedbackArray[4] },
];

const PatientsPresentationDetail = (props) => {
  const [currentPresentation, setCurrentPresentation] = useState();
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [openExitCriteria, setOpenExitCriteria] = useState(false);
  const [openDeletePres, setOpenDeletePres] = useState(false);
  const [presentationRegion, setPresentationRegion] = useState(null);
  const [exitCriteriaList, setExitCriteriaList] = useState([]);
  const [enableInjuryDetail, setEnableInjuryDetail] = useState(false);

  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: "#31ccad",
  };
  const { patId, presentationId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { register, errors, control, handleSubmit, reset } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      const injuryDetailEnabled = await isInjuryDetailEnabled();
      setEnableInjuryDetail(injuryDetailEnabled);
      setLoading(true);
      const presentationResult = await getPresentationById({
        id_presentation: presentationId,
        id_patient: patId,
      });
      setCurrentPresentation(presentationResult);
      setPresentationRegion(presentationResult.region);
      const responseDetail = await findPatientByPk({ userId: patId });
      await fetchExitCriteria();
      setCurrentPatientDetail(responseDetail);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchExitCriteria = async () => {
    const results = await getExitCriteriaByPresentation({
      id_presentation: presentationId,
      id_patient: patId,
    });
    results?.exit_criteria_answers?.filter((elem) => !elem.satisfied);
    setExitCriteriaList(results?.exit_criteria_answers);
    reset({ exitCriteria: results?.exit_criteria_answers });
  };

  useEffect(() => {
    fetchExitCriteria();
  }, [openExitCriteria]);

  const endPresentation = async (data) => {
    await closePresentation({
      ...data,
      id_presentation: presentationId,
    }).then((elem) => {
      setOpenExitCriteria(false);
      fetchData();
    });
  };

  const interestedRegion = `${
    labels.patient.presentation.detail.harmedRegion
  } ${presentationRegion ? psTranslate(presentationRegion?.name) : ""}`;
  let bodyPartName = "";
  if (currentPresentation?.structure?.name) {
    bodyPartName = `${psTranslate(currentPresentation?.structure?.name)}`;
  } else {
    if (currentPresentation?.area?.name) {
      bodyPartName = `${psTranslate(currentPresentation?.area?.name)}`;
    } else {
      if (currentPresentation?.region?.name) {
        bodyPartName = `${psTranslate(currentPresentation?.region?.name)}`;
      }
    }
  }

  const deletePresentation = async () => {
    try {
      const result = await deletePresentationById({
        id_presentation: presentationId,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result.message);
        history.push(`/patients/edit/${patId}/presentations`);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    (errors) =>
    ({ previous, next, index, isLastStep }) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      );
  const steps = labels.patient.presentation.steps.filter(
    (step) =>
      enableInjuryDetail ||
      (!enableInjuryDetail && step != labels.patient.presentation.steps[0])
  );

  const titles = steps.map((t) => <StepTitle>{t}</StepTitle>);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item direction="column" xs={10}>
            <StyledGridContainer container spacing={1}>
              <Grid item xs={3}>
                <SpText variant="h1PageSubtitle">
                  {psTranslate(currentPresentation?.type)}
                </SpText>
              </Grid>
              <Grid item xs={4}>
                <SpText variant="h1PageSubtitle">{interestedRegion}</SpText>
              </Grid>
              {!currentPresentation?.end_date || !currentPresentation ? (
                <Grid item xs={5} container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <SpButton
                      buttonType="cancel"
                      disabled={currentPresentation?.end_date}
                      text={
                        labels.patient.presentation.detail.deletePresentation
                      }
                      variant="h1PageTitle"
                      onClick={() => setOpenDeletePres(true)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <SpButton
                      buttonType="accept"
                      disabled={currentPresentation?.end_date}
                      text={
                        labels.patient.presentation.detail.closePresentation
                      }
                      variant="h1PageTitle"
                      onClick={() => setOpenExitCriteria(true)}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={5}
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <SpButton
                    buttonType="accept"
                    disabled={currentPresentation?.end_date}
                    text={labels.patient.presentation.detail.closePresentation}
                    variant="h1PageTitle"
                    onClick={() => setOpenExitCriteria(true)}
                  />
                </Grid>
              )}
            </StyledGridContainer>
            <StyledGridContainer container spacing={1}>
              <Grid
                item
                xs={
                  currentPresentation &&
                  currentPresentation?.end_date &&
                  currentPresentation &&
                  currentPresentation?.estimated_end_date
                    ? 4
                    : currentPresentation && currentPresentation?.end_date
                    ? 6
                    : currentPresentation &&
                      currentPresentation?.estimated_end_date
                    ? 6
                    : 8
                }
              >
                <SpTextInput
                  style={{ width: "100%" }}
                  disabled={presentationId}
                  label={labels.patient.presentation.detail.reason}
                  value={
                    currentPresentation?.pathology?.name
                      ? psTranslate(currentPresentation?.pathology?.name) +
                        " " +
                        bodyPartName?.toLowerCase()
                      : psTranslate(currentPresentation?.type) +
                        " " +
                        bodyPartName?.toLowerCase()
                  }
                />
              </Grid>
              <Grid item xs={2} justify="center">
                <SpTextInput
                  style={{ width: "100%" }}
                  disabled={presentationId}
                  label={labels.patient.presentation.detail.start}
                  value={
                    currentPresentation?.start_date
                      ? moment(currentPresentation?.start_date).format(
                          "DD/MM/yyyy"
                        )
                      : moment(currentPresentation?.createdAt).format(
                          "DD/MM/yyyy"
                        )
                  }
                />
              </Grid>
              {currentPresentation && currentPresentation?.end_date && (
                <Grid item xs={2}>
                  <SpTextInput
                    style={{ width: "100%" }}
                    disabled={presentationId}
                    label={labels.patient.presentation.detail.end}
                    value={
                      currentPresentation?.end_date
                        ? moment(currentPresentation?.end_date).format(
                            "DD/MM/yyyy"
                          )
                        : ""
                    }
                  />
                </Grid>
              )}
              {currentPresentation && currentPresentation?.estimated_end_date && (
                <Grid item xs={2}>
                  <SpTextInput
                    style={{ width: "100%" }}
                    disabled={presentationId}
                    label={labels.patient.presentation.detail.estimatedEnd}
                    value={
                      currentPresentation?.estimated_end_date
                        ? moment(
                            currentPresentation?.estimated_end_date
                          ).format("DD/MM/yyyy")
                        : ""
                    }
                  />
                </Grid>
              )}
              <Grid item xs={2}>
                <SpTextInput
                  style={{ width: "100%" }}
                  disabled={presentationId}
                  label={labels.patient.presentation.detail.status}
                  value={
                    currentPresentation?.end_date
                      ? labels.patient.presentation.closed
                      : labels.patient.presentation.progress
                  }
                />
              </Grid>
            </StyledGridContainer>
            <StyledGridContainer container spacing={1}>
              <StyledBodyColumn>
                <StyledGridContainer container spacing={1}>
                  <Grid
                    container
                    item
                    direction="row"
                    xs={12}
                    spacing={2}
                    className="gridstepper"
                  >
                    {enableInjuryDetail ? (
                      <SpStepper
                        titles={titles}
                        stepperControls={StepperControls(errors)}
                      >
                        <PatientsPresentationDetailStep0
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                        />

                        <PatientsPresentationDetailStep1
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        <PatientsPresentationDetailStep7
                          register={register}
                          control={control}
                          {...props}
                          endDate={currentPresentation?.end_date}
                          presentationInterviewId={
                            currentPresentation?.id_interview
                          }
                        />
                        <PatientsPresentationDetailStep5
                          {...props}
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep6
                          register={register}
                          control={control}
                          {...props}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep4
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep3
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        <PatientsPresentationDetailStep2
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                      </SpStepper>
                    ) : (
                      <SpStepper
                        titles={titles}
                        stepperControls={StepperControls(errors)}
                      >
                        <PatientsPresentationDetailStep1
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        <PatientsPresentationDetailStep7
                          register={register}
                          control={control}
                          {...props}
                          endDate={currentPresentation?.end_date}
                          presentationInterviewId={
                            currentPresentation?.id_interview
                          }
                        />
                        <PatientsPresentationDetailStep5
                          {...props}
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep6
                          register={register}
                          control={control}
                          {...props}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep4
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                        />
                        <PatientsPresentationDetailStep3
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                        <PatientsPresentationDetailStep2
                          register={register}
                          control={control}
                          endDate={currentPresentation?.end_date}
                          setLoading={setLoading}
                        />
                      </SpStepper>
                    )}
                  </Grid>
                </StyledGridContainer>
              </StyledBodyColumn>
            </StyledGridContainer>
          </Grid>
        </Grid>
      </Grid>
      <SpDialog
        open={openExitCriteria}
        setOpen={setOpenExitCriteria}
        title={labels.patient.presentation.detail.exitCriteriaDialog.title}
      >
        <form onSubmit={handleSubmit(endPresentation)}>
          <Grid container direction="column">
            {exitCriteriaList.map((exitCriteria, idx) => (
              <Grid
                container
                direction="row"
                spacing={3}
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <SpTextInput
                    disabled
                    label={
                      labels.patient.presentation.detail.stepper.step2
                        .inputLabel
                    }
                    value={exitCriteria?.name}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={7} direction="row">
                  <Controller
                    as={
                      <RadioGroup
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          minWidth: 300,
                        }}
                      >
                        <FormControlLabel
                          style={formControlStyleCheck}
                          value="false"
                          control={<RedRadio />}
                          label={labels.patient.presentation.detail.stepper.step2.notSatisfied.toUpperCase()}
                        />
                        <FormControlLabel
                          style={formControlStyleCheck}
                          value="true"
                          control={<GreenRadio />}
                          label={labels.patient.presentation.detail.stepper.step2.satisfied.toUpperCase()}
                        />
                      </RadioGroup>
                    }
                    name={`exitCriteria[${idx}].satisfied`}
                    control={control}
                    defaultValue={exitCriteria.satistified}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} style={{ marginBottom: "4%", marginTop: "4%" }}>
              <Controller
                style={{ width: "100%", marginBottom: "2%" }}
                render={(props) => (
                  <SpSelect
                    label={labels.patient.presentation.detail.feedbackSelect}
                    value={props.value}
                    formControlStyle={{ width: "90%", maxWidth: 400 }}
                    formControlWidth={200}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  >
                    {feedbackArray.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {p.answer}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={0}
                name={`valutation`}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <SpButton
                buttonType="accept"
                type="submit"
                text={labels.patient.presentation.detail.closePresentation}
                variant="h1PageTitle"
              />
            </Grid>
          </Grid>
        </form>
      </SpDialog>
      <SpDialog
        open={openDeletePres}
        setOpen={setOpenDeletePres}
        title={labels.patient.presentation.detail.presentationDelete.title}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.patient.presentation.detail.presentationDelete.content}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                buttonType="cancel"
                disabled={currentPresentation?.end_date}
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={() => deletePresentation()}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                disabled={currentPresentation?.end_date}
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => setOpenDeletePres(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsPresentationDetail);
