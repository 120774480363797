import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "../App.css";
import {
  getPatientsByProfessional,
  getPatientToProfessionalRequest,
} from "../models/actions/Professionals";
import { getAllAgendaActivityByProfessional } from "../models/actions/CalendarActivity";
import SpText from "../components/atoms/SpText";
import Sp3DModel from "../components/atoms/Sp3DModel";
import SpButton from "../components/atoms/SpButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import { styled } from "../components/styled";
import { labels, psTranslate } from "./shared/translations";
import { useHistory } from "react-router";
import { isMobile } from "react-device-detect";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import { acceptRequest, refuseRequest } from "../models/actions/Patients";
import moment from "moment";
import { Divider } from "@material-ui/core";
import { getLogsByProf } from "../models/actions/Logs";

const DailyCalendarActivityContainer = styled(Grid)({
  paddingTop: 20,
  paddingBottom: 20,
});

const MyGroupContainer = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginTop: 5,
  paddingTop: 20,
  paddingBottom: 20,
});
const PatientRequestContainer = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginTop: 5,
  paddingTop: 20,
  paddingBottom: 20,
});

const Dashboard = (props) => {
  const [patients, setPatients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [patientRequests, setPatientRequests] = useState([]);
  const [logs, setLogs] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const history = useHistory();

  const fetchData = async () => {
    try {
      let response = await getPatientsByProfessional();
      if (response) setPatients(response);

      response = await getAllAgendaActivityByProfessional();
      let filteredAppointments = response.filter((elem) => elem.activity_datum);
      filteredAppointments = filteredAppointments.filter(
        (elem) =>
          moment(elem.start_date).format("DD/MM/yyyy") ===
          moment().format("DD/MM/yyyy")
      );
      if (response) setAppointments(filteredAppointments);

      response = await getPatientToProfessionalRequest();
      if (response) setPatientRequests(response);

      response = await getLogsByProf();
      if (response) setLogs(response);
    } catch (error) {
      //console.log(error.response);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const acceptPatRequest = async (id_request) => {
    try {
      const response = await acceptRequest({ id_request: id_request });
      if (response.error) {
        return props.snackbarShowErrorMessage(response.error);
      }
      const responseList = await getPatientToProfessionalRequest();
      if (responseList) setPatientRequests(responseList);
      const responsePatList = await getPatientsByProfessional();
      if (responsePatList) setPatients(responsePatList);
      props.snackbarShowMessage(labels.dashboard.requestAccepted);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const refusePatRequest = async (id_request) => {
    try {
      const response = await refuseRequest({ id_request: id_request });
      if (response.error) {
        return props.snackbarShowErrorMessage(response.error);
      }
      props.snackbarShowMessage(labels.dashboard.requestRefused);
      const responseList = await getPatientToProfessionalRequest();
      if (responseList) setPatientRequests(responseList);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  // Columns
  const headCellsDailyAppointments = [
    {
      id: "object",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.dailyAppointment.table.header.object,
      isAction: false,
    },
    {
      id: "patient",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.dailyAppointment.table.header.patient,
      isAction: false,
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.dailyAppointment.table.header.time,
      isAction: false,
    },
  ];

  const DailyCalendarActivityTableRow = ({ row, index }) => (
    <>
      <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
        <SpTableCell>
          <SpText variant="tableText">
            {row.activity_datum?.data?.assessment_motivation ??
              psTranslate(row.activity_datum?.activity_type?.name)}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{`${row.monitoring.patient.givenName} ${row.monitoring.patient.familyName}`}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{`${moment(
            row.start_time,
            "HH:mm:ss"
          ).format("HH:mm")} - ${moment(row.end_time, "HH:mm:ss").format(
            "HH:mm"
          )}`}</SpText>
        </SpTableCell>
      </SpTableRow>
    </>
  );

  const DailyCalendarActivity = () => {
    return (
      <DailyCalendarActivityContainer container direction="column" spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={8}>
            <SpText variant={"h1PageTitle"}>
              {labels.dashboard.dailyAppointment.title.toUpperCase()}
            </SpText>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <SpIconAndTextButton
              isIconRight={true}
              text={labels.dashboard.dailyAppointment.goToAgenda.toUpperCase()}
              style={{ backgroundColor: "#31ccad", width: "100%" }}
              isUpperCase={true}
              isIconButton={true}
              onClick={() => {
                history.push(`/agenda`);
              }}
            >
              <ArrowForwardIosIcon
                style={{
                  width: 10,
                  color: "#FFFFFF",
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {appointments.length > 0 ? (
            <SpTable
              style={{ width: "100%" }}
              headCells={headCellsDailyAppointments}
              rows={appointments}
              rowKey="id"
              padding={false}
              notCheckable={false}
              tableContainerMaxHeight={
                isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
              }
            >
              <DailyCalendarActivityTableRow />
            </SpTable>
          ) : (
            <SpText variant={"h4ComponentLabel"}>
              {labels.dashboard.dailyAppointment.noAppointments}
            </SpText>
          )}
        </Grid>
      </DailyCalendarActivityContainer>
    );
  };

  const ConnectedPatients = () => (
    <MyGroupContainer>
      <Grid item container xs={12}>
        <Grid item xs={8}>
          <SpText variant={"h1PageTitle"}>
            {labels.dashboard.patientsNumber.toUpperCase()}: {patients?.length}
          </SpText>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <SpIconAndTextButton
            isIconRight={true}
            text={labels.dashboard.patientRequests.goToPatients.toUpperCase()}
            style={{ backgroundColor: "#31ccad", width: "100%" }}
            isUpperCase={true}
            isIconButton={true}
            onClick={() => history.push(`/patients`)}
          >
            <ArrowForwardIosIcon
              style={{
                width: 10,
                color: "#FFFFFF",
                height: 30,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
        </Grid>
      </Grid>
    </MyGroupContainer>
  );

  const headCellsPatientsRequest = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.patientRequests.table.header.name,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const PatientsRequestsRow = ({ row, index }) => {
    return (
      <>
        <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
          <SpTableCell>
            <SpText variant="tableText">{`${row.patient.givenName} ${row.patient.familyName}`}</SpText>
          </SpTableCell>
          <SpTableCell align={"right"} style={{ paddingRight: "0" }}>
            <SpButton
              buttonType={"accept"}
              text={labels.dashboard.patientRequests.table.requestRow.accept.toUpperCase()}
              onClick={() => {
                acceptPatRequest(row.id);
              }}
            />
            <SpButton
              buttonType={"cancel"}
              text={labels.dashboard.patientRequests.table.requestRow.refuse.toUpperCase()}
              variant="h1PageTitle"
              onClick={() => {
                refusePatRequest(row.id);
              }}
            />
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const MyPatientsRequest = () => {
    return (
      <MyGroupContainer>
        <Grid item container xs={12}>
          <Grid item xs={8}>
            <SpText variant={"h1PageTitle"}>
              {labels.dashboard.patientRequests.title.toUpperCase()}
            </SpText>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <SpIconAndTextButton
              isIconRight={true}
              text={labels.dashboard.patientRequests.goToPatients.toUpperCase()}
              style={{ backgroundColor: "#31ccad", width: "100%" }}
              isUpperCase={true}
              isIconButton={true}
              onClick={() => history.push(`/patients`)}
            >
              <ArrowForwardIosIcon
                style={{
                  width: 10,
                  color: "#FFFFFF",
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </Grid>
        </Grid>
        <Grid item container xs={11}>
          <SpTable
            style={{ width: "100%" }}
            headCells={headCellsPatientsRequest}
            rows={patientRequests}
            rowKey="id"
            padding
            notCheckable={false}
            tableContainerMaxHeight={
              isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
            }
          >
            <PatientsRequestsRow />
          </SpTable>
        </Grid>
      </MyGroupContainer>
    );
  };

  const headCellsLogs = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.operations.table.header.title,
      isAction: false,
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: labels.dashboard.operations.table.header.description,
      isAction: false,
    },
  ];

  const OperationsTableRow = ({ row, index }) => (
    <>
      <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
        <SpTableCell>
          <SpText variant="tableText">{row.title}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{row.content}</SpText>
        </SpTableCell>
      </SpTableRow>
    </>
  );

  const OperationTable = () => {
    return (
      <PatientRequestContainer>
        <SpText variant={"h1PageTitle"}>
          {labels.dashboard.operations.title.toUpperCase()}
        </SpText>
        <Grid item xs={12}>
          <SpTable
            style={{ width: "100%", marginTop: "0px !important" }}
            headCells={headCellsLogs}
            rows={logs}
            rowKey="id"
            padding={false}
            notCheckable={false}
            tableContainerMaxHeight={
              isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
            }
          >
            <OperationsTableRow />
          </SpTable>
        </Grid>
      </PatientRequestContainer>
    );
  };

  return (
    <>
      <Grid style={{ paddingLeft: "1%" }} container spacing={2}>
        <Grid item xs={6} style={{ height: "75vh", overflow: "auto" }}>
          <SpText variant="h1" style={{ paddingBottom: 50 }}>
            {labels.dashboard.title}
          </SpText>
          <Grid item xs={12}>
            <Divider
              style={{
                backgroundColor: "#31ccad",
                marginTop: "1%",
                height: 1,
                marginBottom: "1%",
              }}
            />
          </Grid>
          <ConnectedPatients />
          <Grid item xs={12}>
            <Divider
              style={{
                backgroundColor: "#31ccad",
                marginTop: "1%",
                height: 1,
                marginBottom: "1%",
              }}
            />
          </Grid>
          <DailyCalendarActivity />
          {patientRequests.length > 0 && (
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: "#31ccad",
                  marginTop: "1%",
                  height: 1,
                  marginBottom: "1%",
                }}
              />
            </Grid>
          )}
          {patientRequests.length > 0 && (
            <MyPatientsRequest pagination={false} />
          )}
          {logs.length > 0 && (
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: "#31ccad",
                  marginTop: "1%",
                  height: 1,
                  marginBottom: "1%",
                }}
              />
            </Grid>
          )}
          {logs.length > 0 && <OperationTable />}
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Sp3DModel
            modelBehaviour={"INFORMATIVE"}
            setSelectedRegion={setSelectedRegion}
            selectedRegion={selectedRegion}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(Dashboard);