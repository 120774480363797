import React, { useEffect } from "react";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { labels } from "../../shared/translations";
import { getAssessmentTemplateDetail } from "../../../models/actions/Assessment";

const AssessmentInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,

  control,
  setValue,
  register,

  disabled = false,
}) => {
  useEffect(async () => {
    const templateData = await getAssessmentTemplateDetail({
      id_template: selectedTemplate,
    });
    if (templateData) {
      setValue("assessment_motivation", templateData.name);
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      setValue("assessment_motivation", templateData.assessment_motivation);
    }
  }, [templateData]);

  return (
    <SpTextInput
      name="assessment_motivation"
      inputRef={register}
      formControlStyle={{ width: "100%" }}
      label={labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation}
      disabled={disabled}
    />
  );
};

export default AssessmentInputDetails;
