export const labels = {
  serverMessages: {
    groupDelete: {
      error: "Could not delete the group",
      success: "Group deleted successfully",
    },
    groupExit: {
      error: "There was some problem exiting the group",
      success: "Exiting the group was successful",
    },
  },
  general: {
    loading_data: "Loading data...",
    no_selection: "No element",
    yes: "Yes",
    no: "No",
    no_data: "No data inserted",
    confirm: "Confirm",
    skip: "Skip",
    update: "Update",
    saveAndClose: "Save & Close",
    reset: "Reset filter",
    back: "Back",
    trialText: "Your account is in trial phase",
    uploadLimitSize: "Upload limit: %sMB",
  },
  analytics: {
    disturbedAreas: "Disturbed areas",
    disorders: "Disorders",
    filterByRegion: "Filter by region",
    filterByDisorder: "Filter by disorder",
    disturbedRegions: "Disturbed regions",
    remove: "Remove",
    add: "Add",
    save: "Save",
    account: {
      activity: "Activities",
      sessionNumber: "Number of performed sessions",
      startDate: "Starting date",
      endDate: "Ending date",
      helperText1: "Data updated since",
      helperText2: "to",
      updateData: "Update data",
      reset: "Reset",
      avgPresSatisfaction: "Average rehabilitation satisfaction",
      patNumber: "Number of patients",
      groupNumber: "Number of groups",
      actNumber: "Number of total activities",
      summaryPresentation: "Injury Summary",
      presTypeFreq: "Most frequent injury type",
      presLonger: "Longest injury",
      areaAffected: "Most affected Area",
      structureAffected: "Most affected anatomical part",
      summaryActivity: "Activities summary",
      activityFreq: "Most frequent activity",
      days: "days",
    },
  },
  nav: {
    dashboard: "DASHBOARD",
    patients: "PATIENTS",
    groups: "GROUPS",
    agenda: "MY AGENDA",
    support: "SUPPORT",
    library: "MEDICAL LIBRARY",
    account: "ACCOUNT",
    stats: "MY STATS",
    mylab: "MY LAB",
  },
  components: {
    picker: {
      selectDate: "Select date",
    },
    search: {
      search: "Search…",
    },
  },
  shared: {
    addSymbol: {
      selectRegion: "Select region",
      selectArea: "Select area",
      selectStructure: "Select anatomical part",
      selectDisorder: "Select disorder",
      selectDysfunctionCategory: "Select dysfunction",
      selectDysfunction: "Select type of dysfunction",
      selectSeverity: "Select severity",
    },
  },
  agenda: {
    selectPatient: "Select patient",
    inputDetails: {
      totalMins: "total minutes",
    },
    card: {
      subject: "Subject",
      prepare: "Prepare worksheet",
      time: "Time",
      goToActivityPlan: "Go to the activity plan",
    },
  },
  bundles: {
    assessment: {
      selectElements: "Select elements...",
      selectRegion: "Select region",
      selectTemplate: "Select template",
      symbols: {
        title: "Add symbol to the map",
        description:
          "Select the dysfunctions that you want to send to the body map and click on <u>Confirm</u>. Otherwise click on <u>Save</u> just to save the assessment",
      },
      renderUtils: {
        additionalNotes: "Additional notes",
        buttons: {
          prev: "Prevoius",
          next: "Next",
        },
      },
    },
    calendarPlan: {
      feedback: {
        hint: "Fill in the parameters below with your findings",
        save: "Save feedback",
      },
    },
  },
  bodymap: {
    noSymbolToFilter: "There aren't symbols to filter",
    selectDysfunctionCategory: "Select the dysfunction/symptomatology",
    resetButton: "Reset zoom",
    deleteButton: "Delete",
    updateButton: "Update",
    musclesLabel: "Muscles",
    skeletonLabel: "Skeleton",
    connectiveLabel: "Connective tissue",
    cartilageLabel: "Cartilage",
    nervousLabel: "Nervous system",
    skinOpacity: "Bodymap",
    legend: "Relative perturbation index",
    legendValues: {
      low: "Less",
      high: "More",
    },
    detailPage: {
      title: `Area Detail`,
      subtitle: `Here you will find the selected area and the anatomical parts contained in it`,
      structureList: {
        title: `Anatomical parts`,
      },
      dysfunctionsList: {
        title: `Dysfunctions`,
      },
    },
    dialogDetail: {
      title: "Disorder detail",
      titleRiskFactor: "Risk factor detail",
      structureLabel: "Anatomical part",
      regionLabel: "Region",
      areaLabel: "Area",
      disorderLabel: "Disorder",
      dysfunctionCategoryLabel: "Disorder category",
      dysfunctionLabel: "Specific dysfunction",
      severityLabel: "Severity",
      inputDateLabel: "Input",
    },
    severityLabels: {
      1: "Mild",
      2: "Moderate",
      3: "Severe",
    },
    addSymbol: {
      presentationLabel: "Injury",
      monitoringLabel: "Monitoring",
      dateLabel: "Symbol insertion date",
      pastDateLabel: "Past event",
    },
    modelBehavior: {
      informative: "INFORMATIVE",
      marker: "MARKER",
    },
  },
  login: {
    title: "Login",
    email: "Email",
    password: "Password",
    error: `Wrong username or password`,
    emailRequired: `Enter an email`,
    passwordRequired: `Enter a password`,
    passwordLost: `Did you forget your password?`,
    companyAccess: `Login as a Company`,
    buttons: {
      login: `Login`,
      registration: `Registration`,
    },
    passwordLostDialog: {
      title: "Recover password",
      subtitle: "Enter the email to which the recovery link should be sent",
      label: "Email",
      send: "Send",
    },
  },
  dashboard: {
    requestAccepted: "Request accepted!",
    requestRefused: "Request refused!",
    title: "Your Dashboard",
    patientsNumber: "Your patients",
    dailyAppointment: {
      title: "Today's Appointments",
      noAppointments: "There are no appointments",
      goToAgenda: "Agenda",
      table: {
        header: { object: "Subject", patient: "Patient", time: "Time" },
      },
    },
    patientRequests: {
      title: "Patients' connection requests",
      goToPatients: "Your patients",
      table: {
        header: { name: "Name" },
        requestRow: { Accept: "accept", refuse: "Reject" },
      },
    },
    operations: {
      title: "Notifications",
      descriptionTitle: "Description",
      actions: "",
      noOperations: "There are no notifications",
      table: {
        header: { title: "Title", description: "Description" },
        operationsRow: { accept: "Accept", refuse: "Refuse" },
      },
    },
  },
  account: {
    title: "Personal information",
    license_title: "Subscription information",
    accountForm: {
      givenName: "First name",
      familyName: "Last name",
      email: "Email",
      password: "Password",
      fiscalCode: "ID number",
      birthDate: "Year of birth",
      piva: "VAT number",
      telephone: "Mobile phone number",
      address: "Address",
      city: "City",
      cap: "Zip code",
      province: "Province",
      nation: "Nation",
      jobs: "Profession",
    },
    licenseForm: {
      parentUser: "Company",
      licenses: "Number of licenses",
      years: "Subscription duration",
      expiryDate: "Subscription expiration",
      activationDate: "Subscription starting date",
      validLicense: "Valid license",
      trial: "Trial",
    },
    toolbar: {
      addPaz: "Add a patient",
      linkPaz: "Link a patient",
      backToDash: "Back to dashboard",
      report: "Statistics",
      logout: "Log out",
      faq: "FAQ",
      modify: "Edit",
      go_to_account: "Go to account",
      myLab: "My lab",
      addGroup: "Add group",
    },

    accountReports: {
      title: "Statistics",
      inputs: {
        fromDate: "From",
        toDate: "To",
        nunmberOfTreatment: "Number of treatments",
      },
      table: {
        title: "Location of treatments",
        header: {
          region: "Affected region",
          presentationNumber: "Number of injuries",
          result: "Outcome",
          date: "Treatment date",
        },
      },
    },
  },
  groups: {
    roles: { admin: "Admin", reader: "Reader" },
    title: "Groups",
    noGroups: "No associated groups!",
    archived: "Archived",
    plan: {
      prev: "Previous",
      today: "Current",
      next: "Next",
      selectAll: "Select all",
      deselectAll: "Deselect all",
      chooseActivity: "Choose the activity to add",
    },
    errors: {
      selectAtLeastAPatient: "Select at least a patient!",
      deleteAction:
        "In order to delete a group, you must first delete all the patients and professionals within it",
    },
    toolbar: {
      add: "Add a group",
      reports: "Group report",
      back: "Groups",
      backToDetail: "Group Detail",
      statistics: "Stats",
      activityPlan: "Activity Plan",
      deleteExit: "Delete/Exit",
    },
    table: {
      header: {
        group: "Name",
        professional: "Professionals",
        patient: "Patients",
        actions: "",
      },
      actions: {
        view: "View",
      },
      deleteAction: {
        title: "Delete the group",
        content:
          "Are you sure you want to delete the group? The operation is irreversible.",
      },
      exitAction: {
        title: "Exit the group",
        content:
          "Are you sure you want to exit the group? You will no longer be able to see the data of the patients in it. The operation is irreversible.",
      },
    },
    groupAdd: {
      title: "Group Name",
      subtitle: "Enter the information related to the group",
      name: "Group name",
      description: "Description of the group",
      addPatientTable: {
        title: "Patients of the group",
        addPatientButton: "Add patients",
        serachButton: "Search",
        dialog: {
          title: "Choose patient",
          addProfessionalButton: "Add",
          usersFoundPlaceholder: "There are no patients.",
        },
        header: {
          name: "Name",
          actions: "",
        },
        add: "Add",
      },
      addProfessionalTable: {
        title: "Professionals of the group",
        addProfessionalButton: "Add a professional",
        serachButton: "Search",
        dialog: {
          title: "Choose a professional",
          addProfessionalButton: "Add",
          usersFoundPlaceholder: "No professionals found",
          searchText: "Enter the email of the professional",
        },
        header: {
          name: "Name",
          role: "Role",
          actions: "",
        },
        add: "Add",
        roleSelect: "Select role",
        searchButton: "Enter the email of the professional",
      },
      actions: {
        save: "Save",
      },
    },
    groupDetail: {
      title: "Group Detail",
      subtitle: "Display the information related to the group",
      name: "Group name",
      in_presentation: "Injured",
      searchPatient: "Search a patient",
      searchProfessional: "Search a professional",
      assignRole: "Assign a role",
      chooseRole: "Choose a role",
      in_good_health: "Healthy",
      errors: {
        professionalAlreadyExists: "Professional already existing",
        patientAlreadyExists: "Patient already existing",
        selectRole: "You must assign a role to the professional",
      },
      description: "Description of the group",
      deletePatientDialog: {
        title: "Delete Patient",
        subtitle:
          "Are you sure you want to delete the patient and all of their connections to professionals? Clicking NO will only delete the patient from the group. Clicking YES will also delete the associated relationships.",
        yes: "Yes",
        no: "No",
      },
      deleteProfessionalDialog: {
        title: "Delete professional",
        subtitle:
          "Are you sure you want to delete the professional and all of his connections with the patients of the group? Clicking NO you will only delete the professional from the group. Clicking YES you will also delete the associated relationships.",
        yes: "Yes",
        no: "No",
      },
      addPatientTable: {
        title: "Patients of the group",
        addPatientButton: "Add patient",
        serachButton: "Search",
        header: {
          name: "Name",
          actions: "",
          status: "Status",
        },
        add: "Add",
        searchButton: "Enter the email of the patient",
      },
      addProfessionalTable: {
        title: "Professionals of the group",
        searchButton: "Add a professional",
        serachButton: "Search",
        header: {
          name: "Name",
          role: "Role",
          actions: "",
        },
        add: "Add",
        roleSelect: "Select role",
        searchText: "Enter the email of the professional",
      },
      actions: {
        save: "Save",
      },
    },
    groupReports: {
      no_patients: "There are no patients in the group",
      firstTable: {
        title: "Patients situation",
        header: {
          patient: "Patient",
          status: "Status",
          reason: "Injury type",
          startDate: "Injury starting date",
          endDate: "Injury ending date",
          presDuration: "Injury duration (days)",
        },
      },
      secondTable: {
        chooseActivity: "Choose the activities to filter",
        chooseParameter: "Choose the parameters to filter",
        selectValue: "Select a value...",
        title: "Activities/Feedbacks section",
        searchInputStart: "Filter starting date",
        searchInputEnd: "Filter ending Date",
        searchReset: "Reset filter",
        sessions: "Sessions ",
        mins: "Minutes ",
      },
      thirdTable: {
        title: "Unavailability days",
        startDateFirstPeriod: "Insert the starting date of the first period",
        endDateFirstPeriod: "Insert the ending date of the first period",
        startDateSecondPeriod: "Insert the starting date of the second period",
        endDateSecondPeriod: "Insert the ending date of the second period",
        filter: "Filter",
        unavalDaysFirstPeriod: "Unavailability days of the first period",
        unavalDaysSecondPeriod: "Unavailable days of the second period",
        diffUnavalDays: "Unavailabilty difference (in days)",
      },
    },
    groupActivityPlan: {
      addActivity: {
        patientsSelect: {
          label: "Select patients from those belonging to the group",
          placeholder: "Select patients",
        },
        searchInputEnd: "Ending date of filter",
        searchReset: "Reset filter",
      },
    },
  },
  registration: {
    title: `Registration`,
    emailRequired: `Enter an email`,
    passwordRequired: `Enter a password`,
    passwordConfirm: `The passwords do not match`,
    form: {
      givenName: `First name`,
      familyName: `Surname`,
      birthDate: `Date of birth`,
      sex: `Gender`,
      email: "Email",
      username: "User name",
      password: "Password",
      confirmPassword: "Confirm password",
    },
    actions: {
      registration: "Register",
      backToLogin: "Login",
    },
  },
  patient: {
    proms: {
      errors: {
        no_answers: "No answers have been given to the questionnaire yet",
      },
      symptomsAndStiffness: "Symptoms and stiffness",
      pain: "Pain",
      dailyActivities: "Daily activities",
      sport: "Sport",
      qualityOfLife: "Quality of Life",
      physicalFunctionalitiesAndDailyLife:
        "Physical functionality And Daily Life",
      functionalAndSportActivities: "Functional and sport activities",
      physicalActivities: "Physical Activities",
      functionality: "Functionality",
      finalScore: "Final Score",
    },
    list: {
      addPaz: "Add a patient",
      linkPaz: "Link a patient",
      table: {
        patientRow: {
          status: { onPresentation: "Injured", healthy: "Healthy" },
        },
      },
    },
    status: { onPresentation: "Injured", healthy: "Healthy" },
    component: {
      backButtonLista: "Patients",
      noPatients: "There are no associated patients!",
    },
    sideBarPatEdit: {
      anagPat: "Personal details",
      pastHistory: "History",
      monit: "Monitoring",
      pres: "Injuries",
      viewPlan: "Activity Plan",
      graphReport: "Analytics",
      addSymbol: "Body-map",
      controlQuest: "Feedback",
      ris: "Resources",
      transfPat: "Transfer",
      dettPaz: "Status",
      deletePatient: "Delete",
      deletePatientDialogTitle: "Delete patient",
      deletePatientDialogContent:
        "Are you sure you want to delete the patient? The operation is irreversible!",
    },
    patientEditInput: {
      name: "Patient name",
      birthDate: "Date of birth",
      phone: "Phone",
      job: "Profession",
      from: "From",
      to: "To",
      dysfunction: "Filter by dysfunction",
      modelFiltering: "Search 3D model",
      region: "Filter by regions",
      area: "Filter by area",
      pathology: "Filter by pathology",
    },
    patientAdd: {
      givenName: "First name",
      familyName: "Last Name",
      email: "Email",
      coupon: "APP code",
      title: "Add a patient",
      addPatient: "Add a patient",
    },
    patientLink: {
      title: "Connect to a patient",
      inputSearch: {
        label: "Enter the exact email of the patient",
        label2: "Enter the email or the name of the patient",
        buttons: {
          search: "Search",
        },
      },
      patientsList: {
        placeholder: "There are no patients",
        buttons: {
          link: "Connect",
        },
      },
      cancelButton: "Cancel",
    },
    addSymbols: {
      symbolManaging: {
        title: "Manage symbols",
        add: "Add",
      },
      model3DFilter: {
        title: "Bodymap",
        add: "Reset zoom",
      },
      filter: {
        riskFactorEnabled: "Hide Risk factors",
        riskFactorDisabled: "Show Risk factors",
      },
      isAddPresentation: "Would you like to create a new presentation?",
      pathologySelect: "Select the pathology",
    },
    table: {
      header: {
        patients: "Patients",
        age: "Age",
        last_visit: "Status",
        actions: "",
      },
      actions: {
        groups: "Groups",
        report: "Report",
      },
    },
    patientRegistry: {
      steps: ["Summary", "Patient details"],
      submitButton: "Save data",
      printButton: "Print",
      title: "Patient details sheet",
      nameContact: "Name and contact details",
      registryData: "Personal details",
      antropBase: "Basic anthropometry",
      workActivity: "Work activity",
      seatPos:
        "Does your job require you to maintain a sitting position for an extended period of time?",
      standPos:
        "Does your job require prolonged maintenance of standing position? ",
      lift: "Does your job require you to continuously lift objects?",
      mental: "Is your job very physically or mentally demanding?",
      relation: "Do you think your current problem is releted with your job?",
      sportAct: "Sport or recreational activity",
      givenName: "First name",
      familyName: "Last name",
      birthDate: "Date of birth",
      job: "Profession",
      sex: "Gender",
      sexMan: "Man",
      sexWoman: "Woman",
      sexBinary: "Non-binary",
      yes: "Yes",
      no: "No",
      right: "Right",
      right_m: "Right",
      left_m: "Left",
      left: "Left",
      doctor: "Attending physician",
      address: "Address",
      phone: "Phone",
      emergencyContactName: "Emergency Contact Name",
      emergencyContactPhone: "Emergency Contact Phone",
      email: "Email",
      weight: "Weight (Kg)",
      height: "Height (cm)",
      bmi: "BMI",
      dominantHand: "Dominant hand",
      dominantFoot: "Dominant foot",
      workingActivity: "Name",
      activityType: "Sport activity",
      fitnessLevel: "Current fitness level",
      sportPracticeLevel: "Level of sport practice",
      functionalRequest:
        "Functional request or fitness level you would like to achieve.",
    },
    pastHistory: {
      steps: ["Summary", "History", "Pathologies"],
      lastEightWeeks: "REQUEST COMPILATION OF LAST 8 WEEKS MEDICAL HISTORY",
      stepper: {
        sumup: {
          additionalInfoTitle: "Additional information",
        },
        pathologies: {
          title: "Pathologies",
          pathologyRemoved: "Pathologies successfully removed!",
          compileAllFields:
            "You must complete all required fields (enter at least pathologies)",
          save: "Save",
          pathology: "Pathology",
          operationType: "Intervention type",
          pathologyDate: "Pathology date",
          currentStatus: "Current Status",
          rehabDuration: "Rehab duration (days)",
          addPathology: "Add a pathology",
        },
      },
      title: "Remote medical history",
      subtitle:
        "Please indicate whether you or any of your immediate family members (parents,siblings,children) have ever suffered from any of the following conditions:",
      allergie: "Allergies",
      angina: "Angina or chest pain",
      ansia: "Anxiety or panic attacks",
      artrite: "Rheumatoid arthritis",
      asma: "Asthma",
      cirrosi: "Cirrhosis or liver-related conditions",
      colesterolo: "Cholesterol other",
      osteoporosi: "Osteoporosis",
      revSystemsTit: "Systems review and remote surgical history",
      revSystemsSubt:
        "Please indicate if you have ever in your life had any problems with (consider that some entries may be repeated):",
      useAccessories: "Please indicate if you use: ",
      osteoPorosys: "Osteoporosis",
      endoCrinology: "Endocrinology",
      lifeStyle: "Lifestyle",
      desc: "Description",
      approxDate: "Approximate date",
      intType: "Type of intervention",
      status: "Current status",
      handicap: "Handicap or impairment",
      streetAccident: "Traffic accidents or major trauma",
      actualHealtVal: "How would you rate your health status?",
      dietQuality: "How would you rate the quality of your diet",
      sleepQuality: "How would you rate the quality of your sleep.",
      pharma: "Are you currently taking any medications.",
      supp: "Are you currently taking nutritional supplements",
      diet: "Are you following a particular dietary regimen",
      lossWeight:
        "Have you ever had any unexplained weight loss in the last month",
      tobacco: "Do you use tobacco (how many cigarettes per day)?",
      alcohol: "Do you use alcohol (how many servings per day)?",
      coffee: "Do you consume caffeine (how many coffees per day)?",
      plantars: "Insule",
      dentBite: "Dental appliance or dental splint",
      acousticApp: "Hearing aid",
      glasses: "Glasses or contact lenses",
      prothesys: "Other types of prostheses",
      additionalInfoTitle: "Current personal history (last 8 weeks)",
      additionalInfoSubtitle:
        "Please indicate if you currently suffer from, or have recently suffered from, any of the following problems:",
      yes: "Yes",
      no: "No",
      patientPathologiesTitle: "Patient's pathologies",
      patientPathologiesSubtitle: "List of the patient's past pathologies",

      patientAddPathologiesTitle: "Add new pathologies",
      patientAddPathologiesSubtitle:
        "For each pathology indicate the date and click on save",

      whereMuscPast: "Where",
      eventMuscPast: "Event",
      intTypeMuscPast: "Type of intervention",
      dateMuscPast: "Date",
      rehabDuration: "Recovery time (days)",
      actualStatusMuscPast: "Current Status",
      submitButton: "Save Data",
      pathologyDate: "Date (approximate)",
      pathologyName: "Pathology Type",
      pathologyOperationType: "Intervention Type",
    },
    monitoring: {
      noPathologies: "No pathologies have been entered yet",
      addProms: "Insert PROMs",
      addAssessment: "Insert",
      closeAddAssessment: "Close",
      steps: [
        "Bodymap",
        "Assessment",
        "PROMs",
        "Pathologies",
        "Monitoring table",
      ],
      repeatAssessment: "Repeat assessment",
      addMonitoring: "Insert monitoring",
      table: {
        in_good_health: "Healthy",
        in_presentation: "Injured",
        in_illness: "Ill",
        prevTrimester: "PREVIOUS QUARTER",
        nextTrimester: "NEXT QUARTER",
        startDate: "Starting date",
        endDate: "Ending date",
        reset: "Reset filter",
        patientSituation: "Patient situation",
        status: "Status",
        statusTable: "Status table",
        dayInPresentation: "Days injured",
        dayInGoodHealth: "Days healthy",
        dayInIllness: "Days ill",
        activityMonitoringTable: "Activity Monitoring Table",
        activity: "Activity",
        sessionNumber: "Sessions of activity",
        minutesNumber: "Minutes of activity",
      },
      assessments: {
        noAssessments: "Assessments have not yet been entered",
        chooseAssessmentTemplate: "Choose an assessment template",
        header: {
          name: "Name",
          date: "Date",
        },
        inputs: {
          selectTemplate: "Select assessment template",
          selectRegion: "Select the region of the assessment",
        },
      },
      title: "Monitoring",
      actualPatientStatusTitle: "Current Patient Status",
      addPromsLayout: {
        title: "Add PROMS",
        subtitle:
          "Fill in the patient's PROMs or delegate this task to the patient. The patient will be able to fill it out through the app.",
        buttonBack: "Close entry",
        sendButton: "Send.",
      },
      addPathology: {
        tissueType: "Select tissue type",
        etiology: "Select etiology",
        medicalSystemType: "Select medical system",
        pathologyType: "Select pathology type",
        pathology: "Select disease",
      },
      pathologiesStep: {
        title: "Diseases",
        table: {
          toDate: "To",
          fromDate: "From",
        },
        selectMedicalSystem: "Select medical system",
        selectPathology: "Select pathology",
        saveAndClose: "Save & close",
        reset: "Reset filter",
        note: "Note",
        detail: {
          pathologyName: "Pathology name",
          tissueType: "Tissue type",
          pathologyType: "Pathology type",
          etiology: "Etiology",
          medicalSystem: "Medical System",
          note: "Note",
          setAsHealed: "Set as healed",
        },
      },
      monitoringTable: {
        activities: [
          "Status",
          "Treatment",
          "Assessment",
          /* "Rehab", */ "Training",
          "Competition",
          "Rest",
        ],
      },
      proms: {
        noProms: "No PROMs have been entered yet",
        errors: {
          genericError1:
            "You must answer at least 27 of the first 30 required questions",
          genericError2: "You must answer the question",
          genericError3: "You must answer at least 1 question",
          genericError4: "You must answer at least 10% of the questions",
        },
        saveAndClose: "Save & close",
        finalPoint: "Final score:",
        symptomAndStiffness: "Symptoms and stiffness: ",
        pain: "Pain: ",
        dailyActivities: "Daily activities: ",
        sport: "Sport: ",
        qualityOfLife: "Quality of life: ",
        physicalFunctionAndDailyLife: "Physical function and daily life: ",
        sportAndFunctionActivities: "Functional and sporting activities: ",
        physicalActivities: "Physical activities: ",
        functionality: "Functionality: ",
      },
      model3DTitle: "Body-map",
      model3DSubtitle: "Select date range",
      intervalDateFrom: "From",
      intervalDateTo: "To",
      intervalDateFilterBy: "Filter by",
      assesmentTitle: "Assessment",
      promsTitle: "PROMs",
      graphTitle: "Analytics",
      tableRegion: "Region",
      tableDate: "Date",
    },
    patientPlan: {
      addProms: "Insert PROMs",
      addAssessment: "Insert assessment",
      closeAddAssessment: "Close",
      defaultSelectValue: "Monitoring",
      selectPresentation: "Select an injury",
      chooseActivityPlan: 'Choose the "Activity plan" to add',
      repeatAssessment: "Repeat assessment",
      addMonitoring: "Insert monitoring",
      title: "Monitoring",
      activityDetailDialog: {
        openDeleteButtonAppointment: "Delete appointment",
        openDeleteButtonActivity: "Delete activity",
        archiveActivityButton: "Update and archive",
        updateActivityButton: "Update activity",
        note: "Notes",
        appointment: "Other",
        excercise: "Exercise",
        training: "Training",
        treatment: "Treatment",
        presentation: "Injury",
        patient: "Patient",
        deleteButton: "Delete activity",
        confirm: "Yes",
        deny: "No",
        deleteMessage: "Do you want to delete the activity?",
        feedbackParameterInput: "Enter the feedback for the workload parameter",
        archivedAct:
          "You cannot edit the activity because it is archived, belongs to a group, or you are in the monitoring table.",
      },
    },
    presentation: {
      steps: [
        "Detail",
        "Bodymap",
        "Interview",
        "Assessment",
        "PROMs",
        "SINS",
        "Milestones",
        "Exit-criteria",
      ],
      closed: "Closed",
      no_presentations: "No injuries added",
      progress: "In progress",
      open: "Open",
      feedbackArray: [
        "Very Dissatisfied",
        "Dissatisfied",
        "Neither satisfied nor dissatisfied",
        "Satisfied",
        "Very Satisfied",
      ],
      title: "Injuries",
      newPresentation: "New injury",
      tableReason: "Type of injury",
      tableStart: "Start",
      tableEnd: "End",
      tableEstimatedEnd: "Estimated End",
      tableSatisfaction: "Satisfaction",
      tableStatus: "Status",
      intervalDateTo: "To",
      add: {
        title: "New injury",
        presentationType: "Select the symptomatology type",
        dysfunction: "Select the pathology type",
        severity: "Select the severity of the symptomatology",
        reasonOfConsultation: `Select the location and the diagnosis of the injury`,
        selectRegion: `Select region via drop-down menu`,
        selectArea: `Select area via drop-down menu`,
        selectPathology: `Select pathology via drop down menu`,
        selectStructure: `Select the anatomical part via the drop-down menu`,
        selectInterview: `Select interview`,
        selectAnswer: `Choose an answer`,
        startDate: `Date of injury`,
        estimatedEndDate: `Estimated end-date of presentation`,
        continue: `Continue and Save`,
        save: `Save`,
        stepper: {
          actions: {
            next: `Continue`,
            previous: `Back`,
            saveAndExit: `Save and exit`,

            createPresentation: "Continue and create the injury",
          },
          step0: {
            title: `Presentation detail`,
            groups: `Groups`,
            startDate: `Insert the date if the presentation is in the past`,
            endDateEstimated: `End date presentation estimated`,
            endDateEstimatedLabel: `Still in progress`,
            endDate: `End date presentation`,
            region: `Region`,
            tissueType: `Tissue type`,
            pathologyType: `Pathology type`,
            medicalDiagnose: `Diagnose`,
            osiicsCode: `OSIICS code`,
            note: `Note`,
            noteDescription: `Note`,
            detail: {
              activity: {
                name: `The injury occurred:`,
                choise: [
                  { key: 1, value: `train` },
                  { key: 2, value: `match` },
                ],
              },
              mechanism: {
                name: `Mechanism`,
                choise: [
                  { key: 1, value: `overuse` },
                  { key: 2, value: `direct trauma` },
                  { key: 3, value: `indirect trauma` },
                  { key: 4, value: `other` },
                ],
              },
              reinjury: {
                name: `Reinjury`,
                choise: [
                  { key: 1, value: `no` },
                  { key: 2, value: `yes` },
                ],
              },
              sport: {
                name: `Sport`,
              },
            },
          },
          step2: {
            title: "Interview",
            no_interview: `No interview for this region`,
            subtitle: `Fill out the patient's interview or delegate this task to the patient. The patient will be able to fill it out through the app.`,
            regionLabel: `Region: `,
            skipInterview: `Skip interview`,
            patientDelegTitle: `Delegate to patient`,
            patientDelegSubtitle: `Send fill-in request to patient.`,
            patientAlreadyDelegSubtitle: `Fill-in request sent to patient`,
            patientDelegSend: `Send`,
            medicalDiagnose: `Medical diagnosis`,
            medicalDiagnoseSubitle: `Do you have a medical diagnosis for your symptoms?`,
            localization: `Localization`,
            localizationSubitle: `Can you localize your symptoms exactly?`,
            localizationSubtitle2: `Do your symptoms radiate to other regions or is it present in other regions of the body?`,
            redFlagsTitle: `Red Flags`,
            redFlagsSubtitle: `Have you had any episodes of nausea and vomiting lately?`,
            redFlagsSubtitle2: `Have you been experiencing muscle coordination disorders, body sensitivity disorders, visual disturbances, altered mental status lately?`,
            redFlagsSubtitle3: `Have you had any jaw pain (mandibular claudication) during meals lately?`,
            painStart: `Onset`,
            painStartSubtitle: `Can you find a clear cause of onset of your symptoms or what you think triggered it?`,
            painStartSubtitle1: `How did the symptoms start?`,
            painStartSubtitle2: `When did the symptoms start (more or less relate it to a date)`,
            evolutionTitle: `Evolution`,
            evolutionSubtitle: `how are the symptoms evolving?`,
            evolutionSubtitle2: `how are the symptoms behaving throughout the day?`,
            evolutionSubtitle3: `Are you able to reproduce your symptoms in any way?`,
            evolutionSubtitle4: `Have the symptoms moved from the area where it started?`,
            descriptionTitle: `Description`,
            descriptionSubtitle: `With what word(s) would you describe your symptoms?`,
            associatedSignTitle: `Associated signs`,
            associatedSignSubtitle: `Are any of the following signs associated with the current symptoms?`,
            reliefSignTitle: `Aggravating factors`,
            reliefSignSubtitle: `Does any of the following factors relieve your pain?`,
            aggravatingFactorsTitle: `Aggravating factors`,
            aggravatingFactorsSubtitle: `Are any of the following factors aggravating your symptoms?`,
            systemicSympthomsTitle: `Systemic Symptoms`,
            systemicSympthomsSubtitle: `Do you currently experience or have experienced in the past 6-8 weeks any of the following symptoms?`,
            stressTitle: `Psychological stresses`,
            stressSubtitle: `Have you had any recent psychological stress?`,
            precedentTitle: `Previous`,
            precedentSubtitle: `Have you experienced this symptoms before or have you had other problems in this body region?`,
            precedentSubtitle2: `Have you had other treatments for your current symptoms?`,
            severityTitle: `Severity`,
            severitySubtitle: `on a scale from 0 to 10 where 0 is "no pain" and 10 is "the most pain I know of, what level of pain do you perceive right now?`,
            severitySubtitle2: `on a scale from 0 to 10 where 0 is "I have no pain at all" and 10 is "the most pain I know," what level of pain do you perceive on average over the past 7 days (or since it appeared)?`,
            severitySubtitle3: `on a scale from 0 to 10 where 0 is "not at all" and 10 is "very much", how much does your pain affect your daily life?`,
            medicinalsTItle: `Drugs`,
            medicinalsSubtitle: `Are you taking medication for this condition?`,
            medicinalsSubtitle2: `Do you normally take medication for major conditions?`,
            otherTitle: `Other`,
            otherSubtitle: `Are there other things you would like to talk about regarding your general health?`,
            otherSubtitle2: `Do you think there's a way to improve this condition?`,
          },
          step3: {
            title: `SINS`,
            subtitle: `Add SINS`,
            severityTitle: `Severity`,
            irritabilityTitle: `Irritability`,
            originTitle: `Nature`,
            statusTitle: `Stage`,
            severityRows: [
              { id: 1, name: `High` },
              { id: 2, name: "Moderate" },
              { id: 3, name: "Low" },
            ],
            irritabilityRows: [
              { id: 4, name: "High" },
              { id: 5, name: "Moderate" },
              { id: 6, name: "Low" },
            ],
            natureRows: [
              { id: 7, name: "Articular" },
              { id: 8, name: "Myofascial" },
              { id: 9, name: "Bony" },
              { id: 10, name: "Tendinous" },
              { id: 11, name: "Neuropathic" },
              { id: 12, name: "Visceral" },
            ],
            levelRows: [
              { id: 13, name: "Acute" },
              { id: 14, name: "Sub-acute" },
              { id: 15, name: "Chronic" },
            ],
          },
          step4: {
            title: `Assessment`,
            subtitle: `Select the assessment associated with the selected region`,
            skipAssesment: `Skip assessment`,
            notes: `Notes`,
            where: `Location`,
            degree: `Degree`,
            type: `Type`,
            test: `Test`,
            result: "Result",
            pos: "Positive",
            neg: "Negative",
            basicObservationTitle: "Basic observations",
            alignmentsObservation: "Alignments observation",
            exclusionTests: "Rule out tests",
            exclusionTestsSubtitle: "Irritability or cervical fractures",
            cervicalArteriaDisfunction: "Cervical artery dysfunction",
            radicCervical: "Cervical Radiculopathies",
            specialTest: "Special tests",
            specialTestSubtitle: "Vestibular dysfunction",
            monoDisf: "Monohemispheric dysfunction",
            lossROM: "Loss of C1-C2 ROM",
            clickArticolari: "Temporomandibular joint clicks",
            movementRestriction: "Temporomandibular joint movement restriction",
            assessmentDetailRequest: {
              title: "Select the region and the category of the assessment",
              categoryTitle: "Category of the assessment",
            },
          },
          step5: {
            title: `PROMs`,
            subtitle: `Fill the patient's PROMss or delegate this task to him. The patient will be able to fill it out through the app.`,
            skipProms: `Skip PROMs`,
            sendProms: `Send`,
            chooseProms: `Select PROMs`,
            patientDelegation: `Delegate to patient`,
            requestToPatient: `Send request to patient`,
          },
        },
      },
      detail: {
        closePresentation: "Close",
        deletePresentation: "Delete",
        presentationDelete: {
          content: "Are you sure you want to delete the injury",
          title: "Delete presentation",
        },
        deleteConfirm: "Yes",
        feedbackSelect: "Do you feel satisfied?",
        deleteDeny: `No`,
        presentationNumber: `Injury Number`,
        harmedRegion: `Injury Region:`,
        reason: `Injury Reason`,
        start: `Start`,
        end: `End`,
        estimatedEnd: `estimated End-date`,
        status: `Status`,
        exitCriteriaDialog: { title: "Close injury" },
        stepper: {
          step1: {
            title: "Body-map",
            subtitle: "Select a date range",
            from: "From",
            to: "To",
            filterBy: "Filter by",
            addSymbol: "Add symbol",
          },
          step2: {
            title: "Exit criteria",
            subtitle: "Enter an exit criteria",
            noData: "Closed injury. No Exit Criteria has been entered.",
            closedPresentation: "Closed injury.",
            save: "Save",
            inputLabel: "Exit criteria.",
            satisfied: "Satisfied",
            notSatisfied: "Not satisfied",
          },
          step3: {
            title: "Milestones",
            subtitle: "Enter the milestones",
            noData: "Closed injury. No Milestones have been recorded.",
            closedPresentation: "Closed injury.",
            save: "Save.",
            reachingDate: "Achievement date",
            satisfied: "Satisfied",
            notSatisfied: "Not satisfied.",
          },
          step4: {
            title: `SINS`,
            subtitle: `Add SINS`,
            severityTitle: `Severity`,
            irritabilityTitle: `Irritability`,
            noData: `Closed injury. No SINS have been recorded.`,
            closedPresentation: `Closed injury.`,
            originTitle: `Nature`,
            statusTitle: `Stage`,
          },
          step5: {
            title: `Assessment`,
            subtitle: `Assessment`,
            noData: "Closed injury. No Assessment has been recorded.",
            closedPresentation: "Closed injury.",
            add: "Insert assessment",
            table: {
              header: {
                id: "id",
                region: "region",
                date: "date",
                actions: "",
              },
            },
          },
          stepPathologies: {
            title: "Diseases",
            noData: "Closed injury. No Diseases have been entered.",
            closedPresentation: "Closed injured.",
            close: "Close",
            add: "Insert.",
            table: {
              header: {
                id: "id",
                type: "name",
                date: "date",
                actions: "",
              },
            },
          },
          step6: {
            title: "PROMs",
            subtitle: "Assign a proms",
            noData: "Closed injury. No PROMs have been registered.",
            closedPresentation: "Closed injury.",
            add: "Insert",
            close: "Close",
            table: {
              header: {
                id: "id",
                type: "type",
                date: "date",
                actions: "",
              },
            },
          },
          step7: {
            title: `Interview`,
            subtitle: `Complete the patient's interview or delegate this task to the patient. The patient will be able to fill it out through the app.`,
            regionLabel: `Region: `,
            noData: `Closed injuyr. No interviews were recorded.`,
            closedPresentation: `Closed injury`,
            denyButtLabel: `Cancel`,
            patientDelegTitle: `Patient Delegation`,
            patientDelegSubtitle: `Send fill-in request to patient`,
            patientDelegSend: `Send`,
            medicalDiagnose: `Medical diagnosis`,
            medicalDiagnoseSubitle: `Do you have a medical diagnosis for your symptoms?`,
            localization: `Localization`,
            localizationSubitle: `Can you localize your symptoms exactly?`,
            localizationSubtitle2: `Do your symptoms radiate to other regions or is it present in other regions of the body?`,
            redFlagsTitle: `Red Flags`,
            redFlagsSubtitle: `Have you had any episodes of nausea and vomiting lately?`,
            redFlagsSubtitle2: `Have you experienced muscle coordination disorders, body sensitivity disorders, visual disturbances, altered mental status lately?`,
            redFlagsSubtitle3: `Have you had any jaw pain (mandibular claudication) during meals lately?`,
            painStart: `Onset`,
            painStartSubtitle: `Can you find a clear cause of onset of your symptoms or what you think triggered it?`,
            painStartSubtitle1: `How did the symptoms start?`,
            painStartSubtitle2: `When did the symptoms start (more or less relate it to a date)`,
            evolutionTitle: `Evolution`,
            evolutionSubtitle: `How are the symptoms evolving?`,
            evolutionSubtitle2: `How are the symptoms behaving throughout the day?`,
            evolutionSubtitle3: `Are you able to reproduce your symptoms in any way?`,
            evolutionSubtitle4: `Have the symptoms moved from the area where it started?`,
            descriptionTitle: `Description`,
            descriptionSubtitle: `With what word(s) would you describe your symptoms?`,
            associatedSignTitle: `Associated signs`,
            associatedSignSubtitle: `Are any of the following signs associated with your current symptoms?`,
            reliefSignTitle: `Training factors`,
            reliefSignSubtitle: `Any of the following factors relieve your symptoms?`,
            aggravatingFactorsTitle: `Aggravating factors`,
            aggravatingFactorsSubtitle: `Are any of the following factors aggravating your symptoms?`,
            systemicSympthomsTitle: `Systemic Symptoms`,
            systemicSympthomsSubtitle: `Do you currently experience or have experienced in the past 6-8 weeks any of the following symptoms?`,
            stressTitle: `Psychological stresses`,
            stressSubtitle: `Have you had any recent psychological stresses?`,
            precedentTitle: `Previous`,
            precedentSubtitle: `Have you experienced this symptoms before or have you had other problems in this same body region?`,
            precedentSubtitle2: `Have you had other treatments for your current symptoms?`,
            severityTitle: `Severity`,
            severitySubtitle: `on a scale of 0 to 10 where 0 is "no pain" and 10 is "the most pain I know of, what level of pain do you perceive right now?`,
            severitySubtitle2: `on a scale of 0 to 10 where 0 is "I have no pain at all" and 10 is "the most pain I know," what level of pain do you perceive on average over the past 7 days (or since it appeared)?`,
            severitySubtitle3: `on a scale of 0 to 10 where 0 is "not at all" and 10 is "very much", how much does your pain affect your daily life?`,
            medicinalsTItle: `Drugs`,
            medicinalsSubtitle: `Are you taking medication for this condition?`,
            medicinalsSubtitle2: `Do you normally take medication for major conditions?`,
            otherTitle: `Other`,
            otherSubtitle: `Are there other things you would like to talk about regarding your general health?`,
            otherSubtitle2: `Do you think there is a way to improve this condition?`,
            saveButton: `Save`,
          },
        },
      },
    },
    transfer: {
      title: "Search professional",
      form: {
        title: "Search professional",
        searchButton: "Search",
        searchInputLabel:
          "Enter the email of the Powerset user who will take charge of your patient:",
        searchInputLabelTop: "Email",
        foundPatients: {
          formTitle: "Found Professionals",
          givenName: "First Name",
          familyName: "Last name",
          city: "City",
          transferButton: "Transfer",
        },
      },
    },
    graphReport: {
      title: "Analytics",
      subtitle: "View your patient analyticts",
      section: {
        feedback: {
          title: "Workload",
          filters: {
            start_date: "Starting date",
            end_date: "Ending date",
            date_view: "View type",
            graph_types: "Chart type",
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly",
            "linear graph": "Linear",
            "radar graph": "Spiderweb",
            study_parameters: "Parameters to be analyzed",
            studies: "Study mode",
            parameter_not_compatible:
              "The selected parameters are not compatible with this study",
            chronic_load: "Chronic load",
            acute_load: "Acute load",
            period: "Study period",
            unlinked_patients: "Unlinked patients",
            linked_patients: "Other patients linked in a group",
            patients_group: "Group average",
          },
          studies: {
            TEMPORAL: "Timeline",
            MONOTONY: "Monotony",
            STRAIN: "Strain",
            ACWRRA: "ACWR (RA)",
            ACWREWMA: "ACWR (EWMA)",
            COMPAREPERC: "Percentage comparison",
            COMPAREASS: "Absolute value comparison",
            ZSCORE: "Z-Score",
            SYMMETRY: "Right/left symmetry",
            ASYMMETRY: "Right/left asymmetry",
            SYMMETRYASS: "Absolute comparison right/left",

            ZSCORE_REFERENCE: "Reference period",
            ZSCORE_FIXED: "Fixed period",
            ZSCORE_SUBJECT_REFERENCE: "Subject reference period",
            ZSCORE_SUBJECT_FIXED: "Subject fixed period",
          },
        },
        proms: {
          title: "PROMs",
        },
        disorder: {
          title: "Disorders",
          filters: {
            type_select: "Select chart type",
            area_description:
              "In this section you will be able to see which are the most disturbed areas",
            region_description:
              "In this section you will be able to see which are the most present disorders",
            disorder_description:
              "In this section you can see which are the most disturbed regions",
            date: "Date",
          },
          types: {
            area: "Disrupted areas",
            region: "Disturbed regions",
            disorder: "Most frequent disorders",
          },
        },
        assessment: {
          title: "Measurements",
          filters: {
            patients: "Patients",
          },
        },
      },
    },
    controlQuestions: {
      insertAllData: "Enter all data",
      defaultSelectValue: "Monitoring questions",
      title: "Feedback",
      subtitle: "Send request for feedback to ",
      deleteDialog: {
        title: "Delete questionnaire",
        subtitle:
          "Are you sure you want to delete the questionnaire? The operation is irreversible",
      },
      responseTable: {
        title: "Feedback from",
        actions: {
          view: "View",
        },
      },
      tableHeader: {
        questionnaire: "Questionnaire",
        visibilityDate: "Visibility date",
        answerDate: "Answer Date",
      },
      listFreq: {
        oneDay: "1 day",
        twoDays: "2 days",
        threeDays: "3 days",
        fiveDays: "5 days",
        sevenDays: "One week",
      },
      sendQuestionButton: "Send",
      answerDetails: "Answer Detail",
      detailDialog: {
        title: "Questionnaire Detail",
        questTitle: "Questionnaire Name",
        answerTitle: "Details",
      },
      selectFrequency: {
        title: "How often do you want the questionnaire to be sent?",
        placeholder: "Insert frequency in days...",
      },
      selectProms: {
        title: "Select Questionnaire",
        placeholder: "Select Questionnaire...",
      },
      durationInDays: {
        title: "How many times do you want the questionnaire to be sent.",
        placeholder: "Duration questionnaire..",
      },
    },
    transferPatient: {
      title: "Transfer patient",
      subtitle:
        "Transfer this patient to another professional. Warning. It will no longer be possible to manage the patient's data, only to view the information recorded by you so far.",
      name: "Name",
      surname: "Surname",
      email: "Email",
    },
    resourcesUpload: {
      dd1: "Click here to upload a file",
      dd2: "File size limit reached",
      title: "Resources",
      subtitle: "Upload documents and other files for your patient",
      form: {
        title: "Upload your Files",
        subtitle: "Here you can upload files related to your patient.",
      },
      errors: {
        uploadLimit: "File too large",
      },
    },
    viewPlan: {
      title: "Activity Plan",
      subtitle: "View the patient's plan",
      closeButton: "Close",
      addActivity: {
        title: "Add Activity",
        backButton: "Close",
        inputForm: {
          chooseActivity: "Choose the activity to add",
          chooseTemplateActivity: "Choose the activity template to add",
          chooseLoadParameters: "Choose the load parameters to add",
          repeatActivity: "Repeat activity every",
          extendGroup: "Extend group",
          assessmentMotivation: "Subject",
          raceMinutes: "Competition minutes",
          activityReference: "Select what this new activity refers to",
          selectActivityType: "Select activity types",
          selectTime: "Select time",
          activityType: "Activity type",
          start_time: "Starting time",
          end_time: "Ending time",
          objectiveFocus: "Goals",
          description: "Description",
          addButton: "Add",
          start_date: "Enter the date on which to perform the task",
          date: "Enter the date on which to perform the task",
          end_date: "Enter the date on which to perform the activity",
        },
        inputsError: {
          activity_type: "You must select the type of activity",
          start_time: "You must select the starting time",
          end_time: "You must select the ending time",
          start_date: "You must select the starting date",
          end_date: "You must select the ending date",
          time_error: "You must enter a time between 5:00 and 22:00.",
        },
      },
      detailActivity: {
        title: "Activity",
        backButton: "Close",
        inputForm: {
          referredPresentation: "Referred to injury id: ",
          referredPresentationOf: "of ",
          referredPresentationHour: "hours",
          medicalVisit: "Medical referral",
          detail: "Details",
        },
      },
    },
  },
  mylab: {
    no_template:
      "No templates available. Add one by clicking on the top-right button!",
    templateName: "Template name",
    insertTemplateName: "Insert template name...",
    chooseActivityTemplate: "Choose the activity template to add",
    addActivity: "Add activity",
    selectExercise: "Seleziona esercizio",
    page: {
      title: "MyLab",
      subtitle: "Choose which section to display",
    },
    dialog: {
      delete: {
        title: "Delete template",
        subtitle: "Are you sure you want to delete the template?",
        yes: "Yes",
        no: "No",
      },
    },
    tempList: {
      activityPlan: "Protocols",
      excerciseSheet: "Exercise sheets",
      assesment: "Assessments",
      handbook: "Handbooks",
      training: "Workouts",
      rehab: "Rehabilitations",
      treatment: "Treatments",
      riskFactor: "Risk factors",
      dysfunctionWeights: "Weight of disorders",
      action: {
        view: "View",
        add: "Add",
        delete: "Delete",
        manage: "Manage",
      },
      toolbar: {
        riskFactors: {
          label: "Risk Factors",
        },
      },
    },
    tempDetail: {
      backButtonList: "MyLab",
      activityPlanList: { title: "Protocol Template", subtitle: "Protocols" },
      excerciseSheet: {
        title: "Exercise Template",
        subtitle: "Exercises sheets",
      },
      assesment: { title: "Assessment Template", subtitle: "Assessments" },
      handbook: {
        title: "Handbook",
        subtitle: "Handbooks",
        categoryTitle: "Selected categories",
      },
      training: { title: "Training Template", subtitle: "Training" },
      treatment: { title: "Treatment Template", subtitle: "Treatments" },
      rehab: { title: "Rehab Template", subtitle: "Rehabs" },
      riskFactor: { title: "Risk Factor", subtitle: "Risk Factors" },
      dysfunctionWeights: {
        title: "Disorder Weights",
        subtitle: "Disorder Weights",
      },
      action: {
        view: "View",
        updateButton: "Update",
      },
      toolbar: {
        add: "Add",
      },
      table: {
        name: "Name",
        actions: "",
      },
      activityPlan: {
        textLabels: {
          type: "Activity type",
          details: "Activity details",
        },
      },
    },
    riskFactor: {
      toolbar: {
        title: "Risk Factors",
        backButtonList: "Risk Factors",

        subtitle: "In this section you can manage the risk factors",
        actions: {
          add: "Add risk factor",
        },
      },
      table: {
        actions: {
          view: "View",
        },
        header: {
          name: "Name",
          actions: "",
        },
      },
      detail: {
        title: "Edit a risk factor",
        backButtonList: "Risk Factors",
        name: "Name",
        subtitle:
          "Here you can change the selected risk factor by referring to the 3D model",
        select: {
          region: "Select region",
          area: "Select area",
          structure: "Select anatomical part",
          pathology: "Select pathology",
        },
        toolbar: {
          save: "Save changes",
        },
        form: {
          region: "Region",
          area: "Area",
          structure: "Anatomical part",
          dysfunction: "Dysfunction",
        },
      },
      add: {
        title: "Add a risk factor",
        backButtonList: "Risk Factors",

        subtitle:
          "Here you can add a new risk factor by associating it with the 3d model",
        select: {
          region: "Select region",
          area: "Select area",
          structure: "Select anatomical part",
          pathology: "Select pathology",
          disorder: "Select disorder type",
          dysfunction_category: "Select disorder category",
          dysfunction: "Select dyssorder subcategory",
          note: "Note",
        },
        toolbar: {
          save: "Add Risk Factor",
        },
      },
    },
    tempAdd: {
      backButtonList: "MyLab",

      excerciseSheet: {
        back: "Back",
        hideDataset: "Hide exercise dataset",
        showDataset: "Show exercise dataset",
        dialog: {
          title: "Exercise detail",
          content: {
            name: "Name",
            guidance_instructions: "Instructions",
            tags: "Tags",
          },
        },
        title: "New exercises tab",
        subtitle: "List of exercises",
        actions: {
          create: "Create",
          edit: "Edit",
        },
        inputs: {
          name: "Name",
          quantity: "Number of exercises",
          plan: "Plan",
        },
        list: {
          title: "Exercises",
          guidance: "Instructions",
          header: [
            "Exercise name",
            "Series",
            "Repetitions or duration(sec)",
            "Recovery",
            "Load",
            "Accessories",
            "Mode of execution",
            "Notes",
          ],
        },
      },
      assesment: {
        title: "New assessment template",
        subtitle: "Enter the information to create a new template",
        actions: {
          create: "Create",
          edit: "Edit",
          createHandbook: "Create Handbook from this assessment",
        },
        inputs: {
          name: "Name",
          requiredName: "Enter the name of the template",
          section: "Sections",
          notes: "Notes",
        },
        list: {
          title: "Exercises",
          header: [
            "Exercise name",
            "Sets",
            "Reps or time(sec)",
            "Recovery",
            "Load",
            "Accessories",
            "Mode of execution",
            "Notes",
          ],
        },
      },
      handbook: {
        title: "New handbook",
        subtitle: "Enter the information to create a new handbook",
        category: "Assessment categories",
        selectElements: "Select the elements...",
        actions: {
          create: "Create",
          print: "Print",
          edit: "Edit",
        },
        inputs: {
          name: "Name",
          section: "Sections",
        },
      },
      training: {
        title: "New training template",
        subtitle: "Enter the information to create a new template",
        actions: {
          create: "Create",
          edit: "Edit",
        },
        inputs: {
          name: "Name",
          quantity: "Number of activities",
          totalMinutes: "Total minutes",
        },
        list: {
          title: "Sections",
          header: ["Activity type", "Time in minutes", "Description", ""],
        },
      },
      treatment: {
        title: "New treatment template",
        subtitle: "Enter information to create a new treatment",
        actions: {
          create: "Create",
          edit: "Edit",
        },
        inputs: {
          name: "Name",
          quantity: "Number of activities",
          totalMinutes: "Total minutes",
        },
        list: {
          title: "Sections",
          header: ["Activity type", "Time in minutes", "Description", ""],
        },
      },
      risk_factor: {
        title: "New risk factor",
        subtitle: "Enter the information to create a new risk factor",
        actions: {
          create: "Create",
          edit: "Edit",
        },
        inputs: {
          title: "Enter risk factor parameters",
          name: "Name",
        },
        list: {
          title: "Sections",
          header: ["Activity Type", "Time in Minutes", "Description"],
        },
      },
      dysfunctionWeights: {
        actions: {
          edit: "Edit",
        },
      },
      action: {
        view: "View",
      },
      toolbar: {
        add: "Add",
      },
      activityPlan: {
        title: "Protocols",
        subtitle: "To create a protocol enter name, duration and activities",
        list: {
          title: "Days",
          header: ["Activity"],
        },

        nameInput: {
          placeholder: "Insert name",
        },
        durationInput: {
          placeholder: "Insert duration",
        },
        actions: {
          create: "Create",
          edit: "Edit",
          saveButton: "Save",
        },
        inputs: {
          name: "Name",
          duration: "Protocol duration in days",
          plan: "Protocol",
        },
        dialog: {
          activity: "Choose the activity to insert",
          title: "Activity",
          groupActTitle: "Group activity",
        },
      },
    },
  },
  agency: {
    professionals: {
      title: "Professionals",
      toolbar: {
        add: "Add professionals",
        search: "Search",
      },
      table: {
        header: {
          professional: "Professional",
          specialization: "Specialization",
          coupon: "Coupon",
          license: "License",
        },
        actions: {
          remove: "Remove",
        },
      },
      addDialog: {
        title: "Add Professional",
        email: "Enter email",
      },

      detailDialog: {
        title: "Detail Professional",
        familyName: "First Name",
        givenName: "Last Name",
        birthDate: "Date of Birth",
        email: "Email",
        nation: "Nation",
        province: "Province",
        city: "City",
        address: "Street",
        job: "Profession",
      },
    },
    licenses: {
      title: "Licenses",
      toolbar: {
        search: "Search",
        buy: "Buy license",
      },
      table: {
        header: {
          coupon: "Coupon",
          subscription: "Subscription",
          professional: "Professional",
          expirationDate: "Expiration",
          usageStarted: "Starting date",
        },
      },
      buyLicenseDialog: {
        title: "Purchase License",
        duration: "Duration (in days)",
        type: "Select License Type",
        professional: "Select Professional",
      },
    },
    account: {
      title: "Account",
      toolbar: {
        logout: "Logout",
        changePassword: "Change password",
        modify: "Edit",
      },
      form: {
        title: "Account detail",
        fields: {
          name: "Name",
          email: "Email",
          prefix: "Prefix",
          telephone: "Phone",
          address: "Address",
          city: "City",
          cap: "Zip code",
          birthDate: "Year of birth",
          province: "Province",
          nation: "Nation",
          fiscalCode: "Tax code",
          piva: "VAT code",
          businessName: "Company name",
          businessAddress: "Location address",
          businessCity: "Headquarters City",
          businessCap: "Headquarters code",
          businessProvince: "Location Province",
          businessNation: "Location Nation",
        },
      },
      changePassword: {
        title: "Change password",
        password: "New password",
        confirmPassword: "Confirm new password",
        confirm: "Confirm",
      },
    },
  },
  support: {
    title: "Support",
    toolbar: {
      faq: "Faq",
    },
    form: {
      scope: "Subject",
      text: "Text",
    },
    actions: {
      send: "Send",
    },
  },
  faq: {
    title: "Faq",
    toolbar: {
      back: "Support",
    },
  },
  library: {
    title: "Library",
    no_data: "No data available",
  },
  calendar: {
    title: "Calendar",
    eventDetailDialog: {
      title: "Delete More",
      deleteMessage: "Do you really want to delete the appointment?",
    },
    addDialog: {
      title: "More",
    },
    subtitle: "View calendar with appointments",
  },
  error: {
    errorMsg: "oops... A momentary problem occurred. Please try again.",
    lnkGoBack: "Back to Dashboard",
    contactAssistenceMsg:
      "If problem persist, contact customer service at this link ",
    labelLnkToSupport: "Support",
    lnkToSupport: "https://www.mypowerset.com/supporto",
  },
};

export const translations = {
  [`"Ageberg E, Forssblad M, Herbertsson P, Roos EM. Sex differences in patient-reported outcomes after anterior cruciate ligament reconstruction: data from the Swedish knee ligament register. Am J Sports Med 2010;38:1334-42."`]: `"Ageberg E, Forssblad M, Herbertsson P, Roos EM. Sex differences in patient-reported outcomes after anterior cruciate ligament reconstruction: data from the Swedish knee ligament register. Am J Sports Med 2010;38:1334-42."`,
  [`"Frobell RB, Svensson E, Gothrick M, Roos EM. Self-reported activity level and knee function in amateur football players: the influence of age, gender, history of knee injury and level of competition. Knee Surg Sports Traumatol Arthrosc 2008;16:713-9."`]: `"Frobell RB, Svensson E, Gothrick M, Roos EM. Self-reported activity level and knee function in amateur football players: the influence of age, gender, history of knee injury and level of competition. Knee Surg Sports Traumatol Arthrosc 2008;16:713-9."`,
  [`"Frohm A, Saartok T, Edman G, Renstrom P. Psychometric properties of a Swedish translation of the VISA-P outcome score for patellar tendinopathy. BMC Musculoskelet Disord 2004;5:49."`]: `"Frohm A, Saartok T, Edman G, Renstrom P. Psychometric properties of a Swedish translation of the VISA-P outcome score for patellar tendinopathy. BMC Musculoskelet Disord 2004;5:49."`,
  [`"Hernandez-Sanchez S, Hidalgo MD, Gomez A. Cross-cultural adaptation of VISA-P score for patellar tendinopathy in Spanish population. J Orthop Sports Phys Ther . 2011 Aug;41(8):581-91."`]: `"Hernandez-Sanchez S, Hidalgo MD, Gomez A. Cross-cultural adaptation of VISA-P score for patellar tendinopathy in Spanish population. J Orthop Sports Phys Ther . 2011 Aug;41(8):581-91."`,
  [`"K Thorborg, P Hölmich, R Christensen, J Petersen, E M Roos. The Copenhagen Hip and Groin Outcome Score (HAGOS): development and validation according to the COSMIN checklist. Br J Sports Med . 2011 May;45(6):478-91."`]: `"K Thorborg, P Hölmich, R Christensen, J Petersen, E M Roos. The Copenhagen Hip and Groin Outcome Score (HAGOS): development and validation according to the COSMIN checklist. Br J Sports Med . 2011 May;45(6):478-91."`,
  [`"Kristian Thorborg, Otto Kraemer, Anne-Dorthe Madsen, Per Hölmich. Patient-Reported Outcomes Within the First Year After Hip Arthroscopy and Rehabilitation for Femoroacetabular Impingement and/or Labral Injury: The Difference Between Getting Better and ...`]: `"Kristian Thorborg, Otto Kraemer, Anne-Dorthe Madsen, Per Hölmich. Patient-Reported Outcomes Within the First Year After Hip Arthroscopy and Rehabilitation for Femoroacetabular Impingement and/or Labral Injury: The Difference Between Getting Better and ...`,
  [`"Lars G Johnsen, Christian Hellum, Oystein P Nygaard, Kjersti Storheim, Jens I Brox, Ivar Rossvoll, Gunnar Leivseth, Margreth Grotle. Comparison of the SF6D, the EQ5D, and the oswestry disability index in patients with chronic low back pain and degenerat...`]: `"Lars G Johnsen, Christian Hellum, Oystein P Nygaard, Kjersti Storheim, Jens I Brox, Ivar Rossvoll, Gunnar Leivseth, Margreth Grotle. Comparison of the SF6D, the EQ5D, and the oswestry disability index in patients with chronic low back pain and degenerat...`,
  [`"Marco Monticone, Paola Baiardi, Carla Vanti, Silvano Ferrari, Paolo Pillastrini, Raffaele Mugnai, Calogero Foti. Responsiveness of the Oswestry Disability Index and the Roland Morris Disability Questionnaire in Italian subjects with sub-acute and chroni...`]: `"Marco Monticone, Paola Baiardi, Carla Vanti, Silvano Ferrari, Paolo Pillastrini, Raffaele Mugnai, Calogero Foti. Responsiveness of the Oswestry Disability Index and the Roland Morris Disability Questionnaire in Italian subjects with sub-acute and chroni...`,
  [`"Matthew N Bourne, Morgan Williams, Jeremy Jackson, Kate L Williams, Ryan G Timmins, Tania Pizzari. Preseason Hip/Groin Strength and HAGOS Scores Are Associated With Subsequent Injury in Professional Male Soccer Players. J Orthop Sports Phys Ther . 2020 ...`]: `"Matthew N Bourne, Morgan Williams, Jeremy Jackson, Kate L Williams, Ryan G Timmins, Tania Pizzari. Preseason Hip/Groin Strength and HAGOS Scores Are Associated With Subsequent Injury in Professional Male Soccer Players. J Orthop Sports Phys Ther . 2020 ...`,
  [`"Ostenberg A, Roos E, Ekdahl C, Roos H. Physical capacity in female soccer players - does age make a difference? Advances in Physiotherapy 2000;2:39-48."`]: `"Ostenberg A, Roos E, Ekdahl C, Roos H. Physical capacity in female soccer players - does age make a difference? Advances in Physiotherapy 2000;2:39-48."`,
  [`"Paradowski PT, Bergman S, Sunden-Lundius A, Lohmander LS, Roos EM. Knee complaints vary with age and gender in the adult population. Population-based reference data for the Knee injury and Osteoarthritis Outcome Score (KOOS). BMC Musculoskelet Disord 20...`]: `"Paradowski PT, Bergman S, Sunden-Lundius A, Lohmander LS, Roos EM. Knee complaints vary with age and gender in the adult population. Population-based reference data for the Knee injury and Osteoarthritis Outcome Score (KOOS). BMC Musculoskelet Disord 20...`,
  [`"Rodriguez-Merchan EC. Knee instruments and rating scales designed to measure outcomes. Journal of orthopaedics and traumatology : official journal of the Italian Society of Orthopaedics and Traumatology 2012;13:1-6."`]: `"Rodriguez-Merchan EC. Knee instruments and rating scales designed to measure outcomes. Journal of orthopaedics and traumatology : official journal of the Italian Society of Orthopaedics and Traumatology 2012;13:1-6."`,
  [`"Roos EM, Lohmander LS. Knee injury and Osteoarthritis Outcome Score (KOOS): from joint injury to osteoarthritis. Health Qual Life Outcomes 2003;1:64."`]: `"Roos EM, Lohmander LS. Knee injury and Osteoarthritis Outcome Score (KOOS): from joint injury to osteoarthritis. Health Qual Life Outcomes 2003;1:64."`,
  [`"Visentini PJ, Khan KM, Cook JL, Kiss ZS, Harcourt PR, Wark JD. The VISA score: an index of severity of symptoms in patients with jumper’s knee (patellar tendinosis). Victorian Institute of Sport Tendon Study Group. J Sci Med Sport 1998;1:22 – 28."`]: `"Visentini PJ, Khan KM, Cook JL, Kiss ZS, Harcourt PR, Wark JD. The VISA score: an index of severity of symptoms in patients with jumper’s knee (patellar tendinosis). Victorian Institute of Sport Tendon Study Group. J Sci Med Sport 1998;1:22 – 28."`,
  [`"Wright RW. Knee injury outcomes measures. The Journal of the American Academy of Orthopaedic Surgeons 2009;17:31-9."`]: `"Wright RW. Knee injury outcomes measures. The Journal of the American Academy of Orthopaedic Surgeons 2009;17:31-9."`,
  [`(ARCATA TENDINEA DEL MUSCOLO ELEVATORE DELL'ANO)`]: `(TENDON ARCH OF LAVATOR ANI MUSCLE)`,
  [`(BORSA ACROMIALE SUBCUTANEA DESTRA)`]: `(RIGHT SUBCUTANEOUS ACROMIAL BURSA)`,
  [`(BORSA ACROMIALE SUBCUTANEA SINISTRA)`]: `(LEFT SUBCUTANEOUS ACROMIAL BURSA)`,
  [`(BORSA ILEOPETTINEA DESTRA)`]: `(RIGHT ILIOPECTINEAL BURSA)`,
  [`(BORSA ILEOPETTINEA SINISTRA)`]: `(LEFT ILIOPECTINEAL BURSA)`,
  [`(BORSA OLECRANICA INTRATENDINEA DESTRA)`]: `(RIGHT INTRATENDINOUS OLECRANON BURSA)`,
  [`(BORSA OLECRANICA INTRATENDINEA SINISTRA)`]: `(LEFT INTRATENDINOUS OLECRANON BURSA)`,
  [`(BORSA PREROTULEA SUBFASCIALE DESTRA)`]: `(RIGHT SUBFASCIAL PREPATELLAR BURSA)`,
  [`(BORSA PREROTULEA SUBFASCIALE SINISTRA)`]: `(LEFT SUBFASCIAL PREPATELLAR BURSA)`,
  [`(BORSA PREROTULEA SUBTENDINEA DESTRA)`]: `(RIGHT SUBTENDINOUS PREPATELLAR BURSA)`,
  [`(BORSA PREROTULEA SUBTENDINEA SINISTRA)`]: `(LEFT SUBTENDINOUS PREPATELLAR BURSA)`,
  [`(COSTA CERVICALE DESTRA)`]: `(RIGHT CERVICAL RIB)`,
  [`(COSTA CERVICALE SINISTRA)`]: `(LEFT CERVICAL RIB)`,
  [`(DIVERTICOLI ILIACI)`]: `(ILEAL DIVERTICULUM)`,
  [`(LEGAMENTO EPATOCOLICO)`]: `(HEPATOCOLIC LIGAMENT)`,
  [`(LEGAMENTO GASTROCOLICO)`]: `(GASTROCOLIC LIGAMENT)`,
  [`(LEGAMENTO OCCIPITOATLANTOIDEO ANTERIORE)`]: `(ANTERIOR ATLANTOOCCIPITAL LIGAMENT)`,
  [`(LEGAMENTO SCAPOLARE TRASVERSO INFERIORE DESTRO)`]: `(RIGHT INFERIOR TRANSVERSE SCAPULAR LIGAMENT)`,
  [`(LEGAMENTO SCAPOLARE TRASVERSO INFERIORE SINISTRO)`]: `(LEFT INFERIOR TRANSVERSE SCAPULAR LIGAMENT)`,
  [`(LINFONODI PERONEALI DI DESTRA)`]: `(RIGHT FIBULAR NODE)`,
  [`(LINFONODI PERONEALI DI SINISTRA)`]: `(LEFT FIBULAR NODE)`,
  [`(LINFONODI TIBIALI ANTERIORI DI DESTRA)`]: `(RIGHT ANTERIOR TIBIAL NODE)`,
  [`(LINFONODI TIBIALI ANTERIORI DI SINISTRA)`]: `(LEFT ANTERIOR TIBIAL NODE)`,
  [`(LINFONODI TIBIALI POSTERIORI DI DESTRA)`]: `(RIGHT POSTERIOR TIBIAL NODE)`,
  [`(LINFONODI TIBIALI POSTERIORI DI SINISTRA)`]: `(LEFT POSTERIOR TIBIAL NODE)`,
  [`(MESOCOLON ASCENDENTE)`]: `(ASCENDING MESOCOLON)`,
  [`(MESOCOLON DISCENDENTE)`]: `(DESCENDING MESOCOLON)`,
  [`(MUSCOLI INTERTRASVERSARI TORACICI DI DESTRA)`]: `(RIGHT THORACIC INTERTRASVERSARII MUSCLES)`,
  [`(MUSCOLI INTERTRASVERSARI TORACICI DI SINISTRA)`]: `(LEFT THORACIC INTERTRASVERSARII MUSCLES)`,
  [`(MUSCOLI ROTATORI LOMBARI DI DESTRA)`]: `(RIGHT ROTATORES LUMBORUM MUSCLES)`,
  [`(MUSCOLI ROTATORI LOMBARI DI SINISTRA)`]: `(LEFT ROTATORES LUMBORUM MUSCLES)`,
  [`(MUSCOLO ABDUTTORE DEL V METATARSO DESTRO)`]: `(RIGHT ABDUCTOR MUSCLE OF V METATARSAL)`,
  [`(MUSCOLO ABDUTTORE DEL V METATARSO SINISTRO)`]: `(LEFT ABDUCTOR MUSCLE OF V METATARSAL)`,
  [`(MUSCOLO OPPONENTE DEL V DITO DESTRO)`]: `(RIGHT FOOT OPPONENS DIGITI MINIMI MUSCLE)`,
  [`(MUSCOLO OPPONENTE DEL V DITO SINISTRO)`]: `(RIGHT FOOT OPPONENS DIGITI MINIMI MUSCLE)`,
  [`(MUSCOLO PICCOLO PSOAS DESTRO)`]: `(RIGHT PSOAS MINOR MUSCLE)`,
  [`(MUSCOLO PICCOLO PSOAS SINISTRO)`]: `(LEFT PSOAS MINOR MUSCLE)`,
  [`(MUSCOLO SCALENO MINIMO DESTRO)`]: `(RIGHT SCALENUS MINIMUS MUSCLE)`,
  [`(MUSCOLO SCALENO MINIMO SINISTRO)`]: `(LEFT SCALENUS MINIMUS MUSCLE)`,
  [`(MUSCOLO SOLEO ACCESSORIO DESTRO)`]: `(RIGHT ACCESSORY SOLEUS MUSCLE)`,
  [`(MUSCOLO SOLEO ACCESSORIO SINISTRO)`]: `(LEFT ACCESSORY SOLEUS MUSCLE)`,
  [`(MUSCOLO STERNALE DESTRO)`]: `(RIGHT STERNALIS)`,
  [`(MUSCOLO STERNALE SINISTRO)`]: `(LEFT STERNALIS)`,
  [`(MUSCOLO TRASVERSO DELLA NUCA DESTRO)`]: `(RIGHT TRANSVERSUS NUCHAE MUSCLE)`,
  [`(MUSCOLO TRASVERSO DELLA NUCA SINISTRO)`]: `(LEFT TRANSVERSUS NUCHAE MUSCLE)`,
  [`(OS TRIGONUM DESTRO)`]: `(RIGHT OS TRIGONUM)`,
  [`(OS TRIGONUM SINISTRO)`]: `(LEFT OS TRIGONUM)`,
  [`(OSSO CENTRALE DESTRO)`]: `(RIGHT OS CENTRALE)`,
  [`(OSSO CENTRALE SINISTRO)`]: `(LEFT OS CENTRALE)`,
  [`(OSSO INTERPARIETALE)`]: `(INTERPARIETAL BONE)`,
  [`(PARADIDIMO DESTRO) (M)`]: `(RIGHT PARADIDYMIS) (M)`,
  [`(PARADIDIMO SINISTRO) (M)`]: `(LEFT PARADIDYMIS) (M)`,
  [`(SINCONDROSI INTRAOCCIPITALE ANTERIORE DESTRA)`]: `(RIGHT ANTERIOR INTRAOCCIPITAL SYNCHONDROSIS)`,
  [`(SINCONDROSI INTRAOCCIPITALE ANTERIORE SINISTRA)`]: `(LEFT ANTERIOR INTRAOCCIPITAL SYNCHONDROSIS)`,
  [`(SINCONDROSI INTRAOCCIPITALE POSTERIORE DESTRA)`]: `(RIGHT POSTERIOR INTRAOCCIPITAL SYNCHONDROSIS)`,
  [`(SINCONDROSI INTRAOCCIPITALE POSTERIORE SINISTRA)`]: `(LEFT POSTERIOR INTRAOCCIPITAL SYNCHONDROSIS)`,
  [`(SINFISI MANDIBOLARE)`]: `(MANDIBULAR SYMPHYSIS)`,
  [`(SUTURA METOPICA)`]: `(METOPIC SUTURE)`,
  [`(SUTURA SQUAMOMASTOIDEA DESTRA)`]: `(RIGHT SQUAMOMASTOID SUTURE)`,
  [`(SUTURA SQUAMOMASTOIDEA SINISTRA)`]: `(LEFT SQUAMOMASTOID SUTURE)`,
  [`(TENDINE DEI MUSCOLI ROTATORI DEI LOMBI DI DESTRA) (INSERZIONE DISTALE)`]: `(TENDON OF RIGHT ROTATORES LUMBORUM MUSCLES) (DISTAL INSERTION)`,
  [`(TENDINE DEI MUSCOLI ROTATORI DEI LOMBI DI DESTRA) (INSERZIONE PROSSIMALE)`]: `(TENDON OF RIGHT ROTATORES LUMBORUM MUSCLES) (PROXIMAL INSERTION)`,
  [`(TENDINE DEI MUSCOLI ROTATORI DEI LOMBI DI SINISTRA) (INSERZIONE DISTALE)`]: `(TENDON OF LEFT ROTATORES LUMBORUM MUSCLES) (DISTAL INSERTION)`,
  [`(TENDINE DEI MUSCOLI ROTATORI DEI LOMBI DI SINISTRA) (INSERZIONE PROSSIMALE)`]: `(TENDON OF LEFT ROTATORES LUMBORUM MUSCLES) (PROXIMAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO ABDUTTORE DEL V METATARSO DESTRO) (INSERZIONE DISTALE)`]: `(TENDON OF RIGHT ABDUCTOR OF FIFTH METATARSAL MUSCLE) (DISTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO ABDUTTORE DEL V METATARSO DESTRO) (INSERZIONE PROSSIMALE)`]: `(TENDON OF RIGHT ABDUCTOR OF FIFTH METATARSAL MUSCLE) (PROXIMAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO ABDUTTORE DEL V METATARSO SINISTRO) (INSERZIONE DISTALE)`]: `(TENDON OF LEFT ABDUCTOR OF FIFTH METATARSAL MUSCLE) (DISTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO ABDUTTORE DEL V METATARSO SINISTRO) (INSERZIONE PROSSIMALE)`]: `(TENDON OF LEFT ABDUCTOR OF FIFTH METATARSAL MUSCLE) (PROXIMAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO OPPONENTE DEL V DITO DESTRO) (INSERZIONE DISTALE)`]: `(TENDON OF RIGHT FOOT OPPONENS DIGITI MINIMI MUSCLE) (DISTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO OPPONENTE DEL V DITO DESTRO) (INSERZIONE PROSSIMALE)`]: `(TENDON OF RIGHT FOOT OPPONENS DIGITI MINIMI MUSCLE) (PROXIMAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO OPPONENTE DEL V DITO SINISTRO) (INSERZIONE DISTALE)`]: `(TENDON OF LEFT FOOT OPPONENS DIGITI MINIMI MUSCLE) (DISTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO OPPONENTE DEL V DITO SINISTRO) (INSERZIONE PROSSIMALE)`]: `(TENDON OF LEFT FOOT OPPONENS DIGITI MINIMI MUSCLE) (PROXIMAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SCALENO MINIMO DESTRO) (INSERZIONE CERVICALE)`]: `(TENDON OF RIGHT SCALENUS MINIMUS MUSCLE) (CERVICAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SCALENO MINIMO DESTRO) (INSERZIONE COSTALE)`]: `(TENDON OF RIGHT SCALENUS MINIMUS MUSCLE) (COSTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SCALENO MINIMO SINISTRO) (INSERZIONE CERVICALE)`]: `(TENDON OF LEFT SCALENUS MINIMUS MUSCLE) (CERVICAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SCALENO MINIMO SINISTRO) (INSERZIONE COSTALE)`]: `(TENDON OF LEFT SCALENUS MINIMUS MUSCLE) (COSTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SOLEO ACCESSORIO DESTRO) (INSERZIONE DISTALE)`]: `(TENDON OF RIGHT ACCESSORY SOLEUS MUSCLE) (DISTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SOLEO ACCESSORIO DESTRO) (INSERZIONE PROSSIMALE)`]: `(TENDON OF RIGHT ACCESSORY SOLEUS MUSCLE) (PROXIMAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SOLEO ACCESSORIO SINISTRO) (INSERZIONE DISTALE)`]: `(TENDON OF LEFT ACCESSORY SOLEUS MUSCLE) (DISTAL INSERTION)`,
  [`(TENDINE DEL MUSCOLO SOLEO ACCESSORIO SINISTRO) (INSERZIONE PROSSIMALE)`]: `(TENDON OF LEFT ACCESSORY SOLEUS MUSCLE) (PROXIMAL INSERTION)`,
  [`(TENDINE DISTALE DEL MUSCOLO PICCOLO PSOAS DESTRO) (INSERZIONE FEMORALE)`]: `(DISTAL TENDON OF RIGHT PSOAS MINOR MUSCLE) (FEMORAL INSERTION)`,
  [`(TENDINE DISTALE DEL MUSCOLO PICCOLO PSOAS SINISTRO) (INSERZIONE FEMORALE)`]: `(DISTAL TENDON OF LEFT PSOAS MINOR MUSCLE) (FEMORAL INSERTION)`,
  [`(TENDINE PROSSIMALE DEL MUSCOLO PICCOLO PSOAS DESTRO) (INSERZIONE VERTEBRALE)`]: `(PROXIMAL TENDON OF RIGHT PSOAS MINOR MUSCLE) (VERTEBRAL INSERTION)`,
  [`(TENDINE PROSSIMALE DEL MUSCOLO PICCOLO PSOAS SINISTRO) (INSERZIONE VERTEBRALE)`]: `(PROXIMAL TENDON OF LEFT PSOAS MINOR MUSCLE) (VERTEBRAL INSERTION)`,
  [`% sessione > 85% HR`]: `% session > 85% HR`,
  [`>30 minuti`]: `>30 mins`,
  [`0`]: `0`,
  [`0 - 100 min`]: `0-100 mins`,
  [`0 - Dolore forte`]: `0- strong severe pain`,
  [`0 - Estremamente facile`]: `0- Nothing at all`,
  [`0 - Ho recuperato molto male, sono estremamente stanco`]: `0- Very bad recovery`,
  [`0 - Impossibile praticare le attività quotidiane`]: `0- cannot perform Activity of Daily Living`,
  [`0 - mai`]: `0- never`,
  [`0 - Nessun dolore`]: `0- no pain`,
  [`0 - Nessuna difficoltà`]: `0- no difficulty`,
  [`0 - Non posso farlo`]: `0- unable`,
  [`0 - Recupero molto molto scarso`]: `0- Very, very poor recovery`,
  [`0 - Totale incapacità a svolgere qualsiasi attività quotidiana`]: `0- Inability to perform daily activities`,
  [`0 -corrisponde a 100 minuti-`]: `0 - corresponds to 100 minutes`,
  [`0 minuti`]: `0 mins`,
  [`0-300 cl`]: `0-300 cl`,
  [`0-500 cl`]: `0-500 cl`,
  [`1`]: `1`,
  [`1 - Recupero molto scarso`]: `1- Very poor recovery`,
  [`1 caffè`]: `1 coffee`,
  [`1-10 minuti`]: `1-10 mins`,
  [`1-2 caffè`]: `1-2 coffees`,
  [`1-5 sigarette`]: `1-5 cigarettes`,
  [`1-RM`]: `1-MR`,
  [`1-RM Arm Curl (DX)`]: `1-RM Arm Curl (R)`,
  [`1-RM Arm Curl (SX)`]: `1-RM Arm Curl (L)`,
  [`1-RM Back Squat`]: `1-RM Back Squat`,
  [`1-RM Bench Press`]: `1-RM Bench Press`,
  [`1-RM Calf (DX)`]: `1-RM Calf (R)`,
  [`1-RM Calf (SX)`]: `1-RM Calf (L)`,
  [`1-RM Inverted Pul-Up`]: `1-RM Inverted Pul-Up`,
  [`1-RM Inverted Pull-Up`]: `1-RM Inverted Pull-Up`,
  [`1-RM Lat Pull-Down`]: `1-RM Lat Pull-Down`,
  [`1-RM Leg Curl (DX)`]: `1-RM Leg Curl (R)`,
  [`1-RM Leg Curl (SX)`]: `1-RM Leg Curl (L)`,
  [`1-RM Leg Extension (DX)`]: `1-RM Leg Extension (R)`,
  [`1-RM Leg Extension (SX)`]: `1-RM Leg Extension (L)`,
  [`1-RM Leg Press`]: `1-RM Leg Press`,
  [`1-RM Leg Press (DX)`]: `1-RM Leg Press (R)`,
  [`1-RM Leg Press (SX)`]: `1-RM Leg Press (L)`,
  [`1-RM Single Leg squat (DX)`]: `1-RM Single Leg squat (R)`,
  [`1-RM Single Leg Squat (SX)`]: `1-RM Single Leg Squat (L)`,
  [`1-RM Vertical Push-Up`]: `1-RM Vertical Push-Up`,
  [`1) Rigidità: Le seguenti domande riguardano il grado di rigidità articolare che ha provato durante la scorsa settimana. La rigidità è una sensazione di limitazione e di rallentamento nella naturalezza con cui normalmente utilizza il suo ginocchio`]: `1) Stiffness: The following questions concern the amount of joint stiffness you have
experienced during the last week in your knee. Stiffness is a sensation of
restriction or slowness in the ease with which you move your knee joint`,
  [`1) Sintomi: Risponda alle seguenti domande ripensando ai sintomi avvertiti durante la scorsa settimana`]: `1) Symptoms: these questions should be answered thinking of your knee symptoms during the last week.`,
  [`10 - 0 min`]: `10-0 mins`,
  [`10 - corrisponde a 0 minuti`]: `10 - corresponds to 0 minutes`,
  [`10 - Difficoltà massima`]: `10- Maximal difficulty`,
  [`10 - Dolore massimo`]: `10- Maximal pain`,
  [`10 - Estremamente difficile`]: `10- Very, very heavy`,
  [`10 - Ho recuperato molto bene, sono molto energico`]: `10- Very good recovery`,
  [`10 - il peggior dolore immaginabile`]: `10- Worst pain`,
  [`10 - Nessun dolore`]: `10- no pain`,
  [`10 - Nessun problema`]: `10- no problems`,
  [`10 - Nessuna limitazione alle attività quotidiane`]: `10- No limitation of Activity of Daily Living`,
  [`10 - Peggior dolore di sempre`]: `10- Worst pain`,
  [`10 - Peggior dolore immaginabile`]: `10- the worst pain imaginable`,
  [`10 - Recupero molto molto buono`]: `10- Very, very good recovery`,
  [`10 - Sempre`]: `10- constant`,
  [`10 - Talmente difficile da richiedere aiuto`]: `10- so difficult it requires help`,
  [`100 - Condizione ottimale`]: `100- No change in function`,
  [`1000+ cl`]: `1000+ cl`,
  [`11 - Recupero scarso`]: `11- Poor recovery`,
  [`11-15 sigarette`]: `11-15 cigarettes`,
  [`11-20 minuti`]: `11-20 mins`,
  [`11-20 sigarette`]: `11-20 cigarettes`,
  [`13 - Recupero ragionevole`]: `13- Reasonable recovery`,
  [`15 - Recupero buono`]: `15- Good recovery`,
  [`16-20 sigarette`]: `16-20 cigarettes`,
  [`17 - Recupero molto buono`]: `17- Very good recovery`,
  [`19 - Recupero molto molto buono`]: `19- Very, very good recovery`,
  [`2`]: `2`,
  [`2 - Facile`]: `2- Light`,
  [`2 - Non ho recuperato bene, sono abbastanza stanco`]: `2- Bad recovery`,
  [`2 caffè`]: `2 coffees`,
  [`2 Stage Treadmill Test`]: `2 Stage Treadmill Test`,
  [`2-`]: `2-`,
  [`2) Dolore`]: `2) Pain`,
  [`2+`]: `2+`,
  [`21-30 minuti`]: `21-30 mins`,
  [`21-30 sigarette`]: `21-30 cigarettes`,
  [`274-Rematore-Con-Elastico-Gomiti-Altirematore Con Elastico Gomiti Alti`]: `BAND HIGH ROW`,
  [`3`]: `3`,
  [`3 - Recupero scarso`]: `2- Poor recovery`,
  [`3 caffè`]: `3 coffees`,
  [`3 Way Hamstring Touch`]: `3 Way Hamstring Touch`,
  [`3) Funzionamento, attività quotidiane: Le seguenti domande riguardano le capacità fisiche. Con questo termine intendiamo le abilità di spostarsi e di prendersi cura della propria persona. Per cortesia, per ognuna delle seguenti attività, indichi il ...`]: `3) Function, daily living: The following questions concern your physical function. By this we mean your
ability to move around and to look after yourself. For each of the following activities please indicate the degree of difficulty you have experienced in the
last week due to your knee.`,
  [`30 seconds Sit to Stand Test`]: `30 seconds Sit to Stand Test`,
  [`301-500 cl`]: `301-500 cl`,
  [`31-50 sigarette`]: `31-50 cigarettes`,
  [`4`]: `4`,
  [`4 - Alquanto facile`]: `4- Fairly light`,
  [`4 - Ho recuperato abbastanza`]: `4- Enough recovery`,
  [`4-`]: `4-`,
  [`4-5 caffè`]: `4-5 coffees`,
  [`4) Funzionamento, sport e attività ricreative: Le domande seguenti riguardano le sue capacità fisiche durante attività più impegnative. Per cortesia, risponda alle seguenti domande ripensando al grado di difficoltà incontrato durante la scorsa setti...`]: `4) Function, sports and recreational activities:
The following questions concern your physical function when being active on a
higher level. The questions should be answered thinking of what degree of
difficulty you have experienced during the last week due to your knee.`,
  [`4+`]: `4+`,
  [`5`]: `5`,
  [`5 - Ho recuperato in maniera adeguata`]: `5- Adequate recovery`,
  [`5 - Recupero ragionevole`]: `5- Reasonable recovery`,
  [`5-10 sigarette`]: `5-10 cigarettes`,
  [`5) Qualità della vita`]: `5) Quality of life`,
  [`5+ caffè`]: `more than 5 coffees`,
  [`501-1000cl`]: `501-1000 cl`,
  [`505 Agility Test`]: `505 Agility Test`,
  [`51+ sigarette`]: `more than 50 cigarettes`,
  [`6 - Ho recuperato moderatamente`]: `6- Moderte recovery`,
  [`6 - Un po' difficile`]: `6- Heavy`,
  [`6 Minute Walking Test (6MVT)`]: `6 Minute Walking Test (6MVT)`,
  [`6-10 sigarette`]: `6-10 cigarettes`,
  [`7 - Recupero buono`]: `7- Good recovery`,
  [`7 - Recupero molto molto scarso`]: `7- Very, very poor recovery`,
  [`8 - Difficile`]: `8- Very heavy`,
  [`8 - Ho recuperato bene, sono abbastanza energico`]: `8- Good recovery`,
  [`9 - Recupero molto buono`]: `9- Very good recovery`,
  [`9 - Recupero molto scarso`]: `9- Very poor recovery`,
  [`A causa dei problemi al mio braccio, avverto dolore o altri disturbi quasi sempre`]: `Due to my arm I have pain almost all the time`,
  [`A causa dei problemi al mio braccio, cambio frequentemente posizione per cercare sollievo`]: `Due to my arm I change position frequently for comfort`,
  [`A causa dei problemi al mio braccio, chiedo agli altri di fare cose per me`]: `Due to my arm I get others to do things for me`,
  [`A causa dei problemi al mio braccio, devo riposare piu' spesso rispetto a prima`]: `Due to my arm I rest more often`,
  [`A causa dei problemi al mio braccio, dormo meno bene rispetto a prima`]: `Due to my arm I sleep less well`,
  [`A causa dei problemi al mio braccio, evito di fare lavori pesanti come le pulizie o il giardinaggio, oppure di sollevare pesi superiori a 5 kg`]: `Due to my arm I avoid heavy jobs eg. Cleaning, lifting more than 5kg or 10lbs, gardening, etc.`,
  [`A causa dei problemi al mio braccio, ho bisogno di assistenza per la cura e l'igiene personale, ad esempio lavarmi`]: `Due to my arm I need assistance with personal care eg. Washing and hygiene`,
  [`A causa dei problemi al mio braccio, ho difficolta' a scrivere o nell'uso di tastiera o mouse del computer`]: `Due to my arm I have difficulty writing or using a key board and/or "mouse"`,
  [`A causa dei problemi al mio braccio, ho difficolta' a sollevare e trasportare oggetti, ad esempio una borsa della spesa (fino a 5 kg)`]: `Due to my arm I have difficulty lifting and carrying (eg bags, shopping up to 5kg or 10lbs)`,
  [`A causa dei problemi al mio braccio, ho difficolta' con bottoni, chiavi, monete, rubinetti, contenitori o coperchi da avvitare`]: `Due to my arm I have difficulty with buttons, keys, coins, taps/faucets, containers or screw-top lids`,
  [`A causa dei problemi al mio braccio, ho difficolta' con le normali faccende domestiche o le attivita' familiari`]: `Due to my arm I have difficulty with normal home or family duties and chores`,
  [`A causa dei problemi al mio braccio, ho difficolta' nel mangiare o usare le posate`]: `Due to my arm I have difficulty eating and/or using utensils (eg knife, fork, spoon, chop sticks)`,
  [`A causa dei problemi al mio braccio, ho difficolta' nell'afferrare e spostare oggetti come lattine, boccali, tazze`]: `Due to my arm I have difficulty holding and moving dense objects (eg mugs, jars, cans)`,
  [`A causa dei problemi al mio braccio, ho difficolta' nell'infilare il braccio nelle maniche o necessito di aiuto nel vestirmi`]: `Due to my arm I have difficulty putting my arm into a shirt sleeves or need assistance dressing`,
  [`A causa dei problemi al mio braccio, ho difficota' ad aprire, tirare, spingere o premere (ad esempio porte pesanti, leve)`]: `Due to my arm I have difficulty opening, holding, pushing or pressing (eg triggers, lever, heavy doors)`,
  [`A causa dei problemi al mio braccio, il mio appetito e' cambiato`]: `Due to my arm my appetite is now different`,
  [`A causa dei problemi al mio braccio, la mia attivita' ricreativa o sportiva o il mio cammino e' alterato`]: `Due to my arm my walking or normal recreation activity is affected`,
  [`A causa dei problemi al mio braccio, la mia indipendenza negli spostamenti con mezzi proprio o pubblici e' limitata`]: `Due to my arm my transport independence is affected (driving, public transport)`,
  [`A causa dei problemi al mio braccio, le mie normali attivita' quotidiane (lavoro, contatti sociali) ora sono alterate`]: `Due to my arm my regular daily activities (work, social contact are affected`,
  [`A causa dei problemi al mio braccio, mi sento piu' debole e/o piu' rigido`]: `Due to my arm I feel weaker and/or stiffer`,
  [`A causa dei problemi al mio braccio, non riesco a fare cose all'altezza delle spalle o oltre`]: `Due to my arm I am unable to do things at or above shoulder height`,
  [`A causa dei problemi al mio braccio, rimango in casa per la maggior parte della giornata`]: `Due to my arm I stay at home most of the time`,
  [`A causa dei problemi al mio braccio, sono piu' irritabile e/o di cattivo umore`]: `Due to my arm I am more irritable and/or bad tempered`,
  [`A causa dei problemi al mio braccio, tendo a lasciar cadere le cose e/o avere piu' frequentemente piccoli infortuni domestici`]: `Due to my arm I tend to drop things and/or have minor accidents more frequently`,
  [`A causa dei problemi al mio braccio, uso l'altro braccio piu' spesso`]: `Due to my arm I use the other arm often`,
  [`A causa del dolore dormo meno di 2 ore`]: `Because of my pain I have less than 2 hours of sleep`,
  [`A causa del dolore dormo meno di 4 ore`]: `Because of my pain I have less than 4 hours of sleep`,
  [`A causa del dolore dormo meno di 6 ore`]: `Because of my pain I have less than 6 hours of sleep`,
  [`A causa del dolore le mie attività con gli altri si svolgono solo in casa mia`]: `Pain has restricted my social life to my home`,
  [`A letto (girandosi o mantenendo la stessa posizione dell'anca per lungo tempo)`]: `Lying in bed (turning over or maintaining the same hip position for a long time)`,
  [`A Mehra, D Baker, S Disney, and PB Pynsent. Oswestry Disability Index Scoring Made Easy. Ann R Coll Surg Engl. 2008 Sep; 90(6): 497–499.`]: `A Mehra, D Baker, S Disney, and PB Pynsent. Oswestry Disability Index Scoring Made Easy. Ann R Coll Surg Engl. 2008 Sep; 90(6): 497–499.`,
  [`A quale livello di attività può partecipare regolarmente?`]: `What is your highest level of physical activity you can participate in on a regular basis?`,
  [`A quale livello di attività può sottoporsi senza che il ginocchio ceda?`]: `What is the highest level of activity that you can perform without significant giving way in your knee?`,
  [`A quale livello di attività può sottoporsi senza che il ginocchio si gonfi significativamente?`]: `What is the highest level of activity that you can perform without significant swelling in your knee?`,
  [`A quale livello di attività può sottoporsi senza provare dolore significativo al ginocchio?`]: `What is the highest level of activity that you can perform without significant knee pain?`,
  [`A riposo`]: `At rest`,
  [`A volte`]: `Sometimes`,
  [`A. Se non ha dolore durante la sua attività lavorativa, per quanto tempo può lavorare?`]: `A. If you have no pain while undertaking Achilles tendon loading sports, for how long can you train/practise?`,
  [`A. Se non ha dolore praticando sport, per quanto tempo lo può praticare?`]: `A. If you have no pain while undertaking sport, for how long can you train/practise?`,
  [`Abdominal Dynamic Endurance Test`]: `Abdominal Dynamic Endurance Test`,
  [`Abdominal stage test`]: `Abdominal stage test`,
  [`Abduction-Extension-External Rotation Test (DX)`]: `Abduction-Extension-External Rotation Test (R)`,
  [`Abduction-Extension-External Rotation Test (SX)`]: `Abduction-Extension-External Rotation Test (L)`,
  [`Abduzione`]: `Abduction`,
  [`Abduzione articolazione dell'anca (da side-lying) (DX)`]: `Hip joint abduction (side lying) (R)`,
  [`Abduzione articolazione dell'anca (da side-lying) (SX)`]: `Hip joint abduction (side lying) (L)`,
  [`Abduzione articolazione dell'anca (DX)`]: `Hip joint abduction (R)`,
  [`Abduzione articolazione dell'anca (SX)`]: `Hip joint abduction (L)`,
  [`Abduzione articolazione della spalla (DX)`]: `Shoulder joint abduction (R)`,
  [`Abduzione articolazione della spalla (SX)`]: `Shoulder joint abduction (L)`,
  [`Abduzione articolazione metacarpofalangea del pollice (DX)`]: `Metacarpophalangeal joint of pollicis abduction (R)`,
  [`Abduzione articolazione metacarpofalangea del pollice (SX)`]: `Metacarpophalangeal joint of pollicis abduction (L)`,
  [`Abduzione articolazione metatarsofalangea dell'alluce (DX)`]: `Metatarsophalangeal joint of hallux abduction (R)`,
  [`Abduzione articolazione metatarsofalangea dell'alluce (SX)`]: `Metatarsophalangeal joint of hallux abduction (L)`,
  [`Abduzione articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) abduction (R)`,
  [`Abduzione articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) abduction (L)`,
  [`Abduzione articolazioni metacarpofalangee della mano dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) abduction (R)`,
  [`Abduzione articolazioni metacarpofalangee della mano dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) abduction (L)`,
  [`Abduzione dell'anca contro resistenza (DX)`]: `Resisted Hip Abduction (R)`,
  [`Abduzione dell'anca contro resistenza (SX)`]: `Resisted Hip Abduction (L)`,
  [`Abduzione della scapola`]: `Scapula abduction`,
  [`Abduzione e rotazione verso l'alto scapola (DX)`]: `Scapula abduction and upward rotation (R)`,
  [`Abduzione e rotazione verso l'alto scapola (SX)`]: `Scapula abduction and upward rotation (L)`,
  [`Abduzione orizzontale`]: `Horizontal abduction`,
  [`Abduzione orizzontale articolazione della spalla (DX)`]: `Shoulder joint horizontal abduction (R)`,
  [`Abduzione orizzontale articolazione della spalla (SX)`]: `Shoulder joint horizontal abduction (L)`,
  [`Abduzione scapola`]: `Scapula abduction`,
  [`Aborto`]: `Abortion`,
  [`Abrasione`]: `Abrasion`,
  [`Abrasione al collo`]: `Neck abrasion`,
  [`Abrasione al cuoio capelluto`]: `Scalp abrasion`,
  [`Abrasione al mento`]: `Chin abrasion`,
  [`Abrasione all'orecchio`]: `Ear abrasion`,
  [`Abrasione alla coscia`]: `Thigh abrasion`,
  [`Abrasione corneale`]: `Corneal Abrasion`,
  [`Abrasione del braccio`]: `Upper Arm Abrasion`,
  [`Abrasione del ginocchio`]: `Knee abrasion`,
  [`Abrasione del piede`]: `Foot abrasion`,
  [`Abrasione del polso`]: `Wrist abrasion`,
  [`Abrasione dell'anca e del groin`]: `Groin/hip abrasion`,
  [`Abrasione della caviglia`]: `Ankle abrasion`,
  [`Abrasione della mano`]: `Hand abrasion`,
  [`Abrasione/ferita al gomito`]: `Elbow laceration`,
  [`Abrasione/ferita alla pelvi/natiche`]: `Buttock abrasion incl pressure sores`,
  [`Abrasione/ferita alla testa/faccia`]: `Head/facial laceration`,
  [`Abuso di droghe`]: `Chemical dependency (alchool/drugs)`,
  [`AC Joint Palpation (DX)`]: `AC Joint Palpation (R)`,
  [`AC Joint Palpation (SX)`]: `AC Joint Palpation (L)`,
  [`AC Resisted Extension Test (DX)`]: `AC Resisted Extension Test (R)`,
  [`AC Resisted Extension Test (SX)`]: `AC Resisted Extension Test (L)`,
  [`Accecante`]: `Blinding`,
  [`Accentuato`]: `Extreme`,
  [`Accovacciarsi`]: `Squatting`,
  [`Acidosi metabolica`]: `Metabolic acidosis`,
  [`Acromegalia`]: `Acromegaly`,
  [`Active Compression Test (DX)`]: `Active Compression Test (R)`,
  [`Active Compression Test (SX)`]: `Active Compression Test (L)`,
  [`Active Elevation Lag Sign (DX)`]: `Active Elevation Lag Sign (R)`,
  [`Active Elevation Lag Sign (SX)`]: `Active Elevation Lag Sign (L)`,
  [`Active Knee Extension (DX)`]: `Active Knee Extension (R)`,
  [`Active Knee Extension (SX)`]: `Active Knee Extension (L)`,
  [`Active Straight Leg Raise Test (DX)`]: `Active Straight Leg Raise Test (R)`,
  [`Active Straight Leg Raise Test (SX)`]: `Active Straight Leg Raise Test (L)`,
  [`Active Supine 90/90 Position Assessment (DX)`]: `Active Supine 90/90 Position Assessment (R)`,
  [`Active Supine 90/90 Position Assessment (SX)`]: `Active Supine 90/90 Position Assessment (L)`,
  [`Acuto`]: `Acute`,
  [`Adam's Forward Bending Test`]: `Adam's Forward Bending Test`,
  [`Addome`]: `Abdomen`,
  [`ADDOME`]: `ABDOMEN`,
  [`Addominale`]: `Abdominal`,
  [`ADDUCTOR ROCKS `]: `ADDUCTOR ROCKS `,
  [`Adductor Squeeze Test (DX)`]: `Adductor Squeeze Test (R)`,
  [`Adductor Squeeze Test (SX)`]: `Adductor Squeeze Test (L)`,
  [`Adduttore A Gamba Piegata Con Elastico`]: `ISOMETRIC SINGLE LEG SQUAT WITH ADDUCTION WITH BAND`,
  [`Adduttore A Gamba Tesa Con Elastico Alto`]: `ISOMETRIC SINGLE LEG STANCE WITH WIDE RANGE ADDUCTION WITH BAND`,
  [`Adduttore A Gamba Tesa Con Elastico Alto Oltre La Mediana`]: `ISOMETRIC SINGLE LEG STANCE WITH WIDE AND COMPLETE RANGE ADDUCTION WITH BAND`,
  [`Adduttore A Gamba Tesa Con Elastico Basso`]: `ISOMETRIC SINGLE LEG STANCE WITH RESTRICT RANGE ADDUCTION WITH BAND`,
  [`Adduttore Con Affondo Laterale Con Elastico`]: `SIDE LUNGE WITH ADDUCTION WITH BAND`,
  [`Adduttore Con Mini Affondo Laterale Con Elastico`]: `MINI-SIDE LUNGE WITH ADDUCTION WITH BAND`,
  [`Adduzione`]: `Adduction`,
  [`Adduzione articolazione dell'anca (da side-lying) (DX)`]: `Hip joint adduction (side lying) (R)`,
  [`Adduzione articolazione dell'anca (da side-lying) (SX)`]: `Hip joint adduction (side lying) (L)`,
  [`Adduzione articolazione dell'anca (DX)`]: `Hip joint adduction (R)`,
  [`Adduzione articolazione dell'anca (SX)`]: `Hip joint adduction (L)`,
  [`Adduzione articolazione della spalla (DX)`]: `Shoulder joint adduction (R)`,
  [`Adduzione articolazione della spalla (SX)`]: `Shoulder joint adduction (L)`,
  [`Adduzione articolazione metacarpofalangea del pollice (DX)`]: `Metacarpophalangeal joint of pollicis adduction (R)`,
  [`Adduzione articolazione metacarpofalangea del pollice (SX)`]: `Metacarpophalangeal joint of pollicis adduction (L)`,
  [`Adduzione articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) adduction (R)`,
  [`Adduzione articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) adduction (L)`,
  [`Adduzione articolazioni metacarpofalangee della mano dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) adduction (R)`,
  [`Adduzione articolazioni metacarpofalangee della mano dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) adduction (L)`,
  [`Adduzione del femore`]: `Femoral adduction`,
  [`Adduzione dell'anca`]: `Hip adduction`,
  [`Adduzione orizzontale`]: `Horizontal adduction`,
  [`Adduzione orizzontale articolazione della spalla (DX)`]: `Shoulder joint horizontal adduction (R)`,
  [`Adduzione orizzontale articolazione della spalla (SX)`]: `Shoulder joint horizontal adduction (L)`,
  [`Adduzione orizzontale articolazone della spalla (DX)`]: `Shoulder joint horizontal adduction (R)`,
  [`Adduzione orizzontale articolazone della spalla (SX)`]: `Shoulder joint horizontal adduction (L)`,
  [`Adson's Test`]: `Adson's Test`,
  [`Advice on equipment/other aids e.g. appropriate footwear.`]: `Advice on equipment/other aids e.g. appropriate footwear.`,
  [`Affettivo-valutativo-sensoriale`]: `Affective-evaluative-sensory:miscellaneous`,
  [`Affettivo-valutativo: varie`]: `affective-evaluative:miscellaneous`,
  [`Affondi Ampi In Avanzamento Braccia Coordinate`]: `WIDE FORWARD WALKING LUNGE`,
  [`Affondi Ampi In Avanzamento Mani Ai Fianchi`]: `WIDE FORWARD WALKING LUNGE WITH LOCKED ARMS`,
  [`Affondi Con Saltello E Fase Eccentrica Veloce`]: `WALKING DROP LUNGE`,
  [`Affondi Dietro Braccia Coordinate`]: `BACK WALKING LUNGE`,
  [`Affondi Dietro Con Rotazione`]: `BACK WALKING LUNGE WITH ROTATION`,
  [`Affondi Dietro Incrociati`]: `BACK CURTSY LUNGE`,
  [`Affondi Dietro Larghi E Ampi`]: `FORWARD LUNGE WIDE AND LARGE`,
  [`Affondi In Avanzamento Braccia Coordinate`]: `FORWARD LUNGE`,
  [`Affondi In Avanzamento Braccia Sollevate`]: `OVERHEAD FORWARD LUNGE`,
  [`Affondi In Avanzamento Con Rotazione Sulla Gamba Che Avanza`]: `FORWARD WALKING LUNGE WITH ROTATION`,
  [`Affondi In Avanzamento Con Sforbiciata`]: `SHUFFLE  FORWARD DROP LUNGE`,
  [`Affondi In Avanzamento Mani Ai Fianchi`]: `FORWARD LUNGE WITH LOCKED ARMS`,
  [`Affondi Incrociati In Avanzamento Mani Ai Fianchi`]: `FORWARD CROSSOVER LUNGE WITH LOCKED ARMS`,
  [`Affondi Larghi E Ampi In Avanzamento Braccia Coordinate`]: `FORWARD CROSSOVER LUNGE WITH LOCKED ARMS`,
  [`Affondi Larghi E Ampi In Avanzamento Mani Ai Fianchi`]: `FORWARD LUNGE WIDE AND LARGE WITH LOCKED ARMS`,
  [`Affondi Laterali`]: `SIDE LUNGE`,
  [`Affondi Laterali E Laterali Incrociati`]: `SIDE AND CURTSY LUNGE`,
  [`Affondi Laterali Incrociati`]: `SIDE CURTSY LUNGE`,
  [`Affondi Scavalcamento In Avanzamento Braccia Coordinate`]: `FORWARD LUNGE WITH KNEE DRIVE`,
  [`Affondi Stretti In Avanzamento Mani Ai Fianchi`]: `CLOSE FORWARD LUNGE  WITH LOCKED ARMS`,
  [`Affondi Stretti In Avanzamento Mani Bloccate`]: `CLOSE FORWARD PRISONER  LUNGE`,
  [`Affondi Stretti In Avanzmaneto Braccia Coordinate`]: `CLOSE FORWARD LUNGE`,
  [`Affondo Avanti Con Elastico`]: `FORWARD DECELERATION LUNGE`,
  [`Affondo Decelerativo Con Antirotazione Con Elastico`]: `DROP LUNGE PALLOF PRESS`,
  [`Affondo Dietro Con Sollevamento Ginocchio Con Elastico`]: `BACK LUNGE WITH RESISTANCE BAND`,
  [`Affondo Laterale Con Elastico`]: `SIDE DECELERATIONE LUNGE`,
  [`Agonizzante`]: `Agonizing`,
  [`ai avuto recenti stress psicologici?`]: `Have you had psychological stress recently?`,
  [`AIDS`]: `AIDS`,
  [`Aiuta a confermare a presenza di un alluce rigido. Considerare la presenza di una sindrome da stress mediale della tibia`]: `Help to confirm hallux rigidus. Consider medial tibial stress syndrome`,
  [`Aiuta a confermare il gomito come regione causativa di dolore`]: `Help to confirm the elbow as a pain generator`,
  [`Aiuta a confermare la preenza di una CAD o Cervical Artery Dysfunction`]: `Help to confirm a CAD (Cervical Artery Dysfunction)`,
  [`Aiuta a confermare la presenza di dolore femoro-rotuleo (PFPS)`]: `Help to confirm PFPS (patello femoral pain syndrome)`,
  [`Aiuta a confermare la presenza di frattura omerale o lussazione di spalla`]: `Help to confirm a fracture of humerus, clavicle or a shoulder joint dislocation`,
  [`Aiuta a confermare la presenza di Impingement Sub-Acromiale`]: `Help to confirm sub-acromial impingement`,
  [`Aiuta a confermare la presenza di Impingement Sub-Acromiale o una patologia a carico dell'articolaione Acromion-Clavicolare`]: `Help to confirm a sub-acromial impingement or acromion-clavicular joint pathology`,
  [`Aiuta a confermare la presenza di instabilita' lombare`]: `Help to confirm lumbar instability`,
  [`Aiuta a confermare la presenza di PRPPP`]: `Help to confirm PRPPP (pregnancy-related posterior pelvic pain)`,
  [`Aiuta a confermare la presenza di un impingement della caviglia`]: `Help to confirm ankle joint impingement`,
  [`Aiuta a confermare la presenza di un Impingement Sub-Acromiale`]: `Help to confirm a sub-acromial impingement`,
  [`Aiuta a confermare la presenza di una CAD o Cervical Artery Dysfunction`]: `Help to confirm a CAD (Cervical Artery Dysfunction)`,
  [`Aiuta a confermare la presenza di una disfunzione sacro-iliaca`]: `Help to confirm sacroiliac joint dysfunction`,
  [`Aiuta a confermare la presenza di una frattura`]: `Help to confirm a fracture  `,
  [`Aiuta a confermare la presenza di una frattura relativa all'anca`]: `Help to confirm hip fracture`,
  [`Aiuta a confermare la presenza di una frattura vertebrale toracica`]: `Help to confirm a thoracic vertebral fracture`,
  [`Aiuta a confermare la presenza di una Instabilita' Anteriore`]: `Help to rule out an anterior instability of shoulder`,
  [`Aiuta a confermare la presenza di una instabilita' dell'articolazione tibio-fibulare prossimale`]: `Help to confirm proximal tibio-fibular joint instability`,
  [`Aiuta a confermare la presenza di una instabilita' tra atlante e epistrofeo`]: `Help to confirm an instability of atlas and axis`,
  [`Aiuta a confermare la presenza di una Instabilita'Anteriore`]: `Help to confirm an anterior instability of shoulder`,
  [`Aiuta a confermare la presenza di una lesione del labbro`]: `Help to confirm a labial tear `,
  [`Aiuta a confermare la presenza di una lesione del LCL (Legamento Collaterale Laterale)`]: `Help to confirm LCL injury`,
  [`Aiuta a confermare la presenza di una lesione del legamento rotondo dell'anca`]: `Help to confirm teres ligament of the hip injury`,
  [`Aiuta a confermare la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear)`]: `Help to confirm a rotator cuff injury (Rotator Cuff Tear)`,
  [`Aiuta a confermare la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear): lesione del SubScapularis`]: `Help to confirm a rotator cuff injury (Rotator Cuff Tear): Subscapularis injury`,
  [`Aiuta a confermare la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear): Lesione di Infraspinatus e Teres Minor`]: `Help to confirm a rotator cuff injury (Rotator Cuff Tear): Infraspinatus and Teres minor injury`,
  [`Aiuta a confermare la presenza di una MTSS o Medial Tibial Stress Syndrome`]: `Help to confirm medial tibial stress syndrome`,
  [`Aiuta a confermare la presenza di una problematica Sacro/Iliaca`]: `Help to confirm a sacroiliac joint dysfunction`,
  [`Aiuta a confermare la presenza di una radicolopatia cervicale o di una patologia relativa al disco cervicale`]: `Help to confirm a cervical radiculopaty or an intervertebral disc pathology`,
  [`Aiuta a confermare la presenza di una radicolopatia o di una patologia relativa al disco`]: `Help to confirm a cervical radiculopaty or an intervertebral disc pathology`,
  [`Aiuta a confermare la presenza di una Sindrome del Piriforme`]: `Help to confirm piriformis syndrome`,
  [`Aiuta a confermare la presenza di una Sindrome del Tunnel Carpale`]: `Help to confirm a carpal tunnel syndrome`,
  [`Aiuta a confermare la presenza di una sindrome del tunnel tarsale`]: `Help to confirm tarsal tunnel syndrome`,
  [`Aiuta a confermare la presenza di una sindrome dello stretto toracico`]: `Help to confirm a thoracic outlet syndrome`,
  [`Aiuta a confermare la presenza di una sindrome femoro-rotulea (PFPS)`]: `Help to confirm PFPS (patello femoral pain syndrome)`,
  [`Aiuta a confermare la presenza di una stenosi spinale lombare`]: `Help to confirm out a lumbar spine stenosis`,
  [`Aiuta a confermare la presenza di una tendinopatia Achillea`]: `Help to confirm achilles tendinopathy`,
  [`Aiuta a confermare la presenza di una tendinopatia del bicipite brachiale o di una lesione del bicipite brachiale`]: `Help to confirm a biceps brachii tendinopathy or a biceps brachii muscle injury`,
  [`Aiuta a confermare la presenza di una tendinopatia rotulea`]: `Help to confirm patellar tendinopathy`,
  [`Aiuta a confermare la presenza di una WAD`]: `Help to confirm a WAD (Whiplash Associated Disorder)`,
  [`Aiuta a confermare la presenza una Hamstring Tendinopathy o un Hamstring Injury`]: `Help to confirm hamstring tendinopathy`,
  [`Aiuta a confermare la rottura del tendine d'Achille`]: `Help to confirm achilles tendon rupture`,
  [`Aiuta a confermare una Adductor Related Groin Pain`]: `Help to confirm adductor related groin pain`,
  [`Aiuta a confermare una atologia a carico dell'articolazione Acromion-Clavicolare`]: `Help to confirm acromionclavicluar joint pathology`,
  [`Aiuta a confermare una disfunzione meccanica in flessione`]: `Help to confirm a mechanical dysfunction in flexion`,
  [`Aiuta a confermare una lesione del tendine bicipite brachiale`]: `Help to confirm a biceps brachii tendon injury`,
  [`Aiuta a confermare una lesione della sindesmosi`]: `Help to confirm syndesmosis injury`,
  [`Aiuta a confermare una piriformis syndrome`]: `Help to confirm pyriformis syndrome`,
  [`Aiuta a considerare la presenza di impingement`]: `Help to consider an impingement`,
  [`Aiuta a considerare la presenza di un Impingement Sub-Acromiale (SAIS Sub-Acromial Impingement Syndrome)`]: `Help to consider a sub-acromial impingement`,
  [`Aiuta a considerare la presenza di una Capsulite Adesiva`]: `Help to confirm an adesive capsulitis`,
  [`Aiuta a considerare la presenza di una disfunzione del Sacro`]: `Help to consider sacrum dysfunction`,
  [`Aiuta a considerare la presenza di una disfunzione dell'Ileo`]: `Help to consider ilium dysfunction`,
  [`Aiuta a considerare la presenza di una disfunzione vestibolare`]: `Help to consider a vestibular dysfunction`,
  [`Aiuta a considerare la presenza di una frattura`]: `Help to consider a fracture`,
  [`Aiuta a considerare la presenza di una lesione del legamento luno-piramidale`]: `Help to consider a luno-triquetral ligament injury`,
  [`Aiuta a considerare la presenza di una radicolopatia cervicale`]: `Help to consider a cervical radiculopaty `,
  [`Aiuta a considerare la regione della spalla come possibile area generatrice del dolore`]: `Help to consider the shoulder as the pain generator`,
  [`Aiuta a considerare una disfunzione sacro-iliaca`]: `Help to consider sacroiliac joint dysfunction`,
  [`Aiuta a considerare una Epicondilopatia Laterale`]: `Help to consider a lateral epicondilitis`,
  [`Aiuta a considerare una frattura del gomito`]: `Help to consider an elbow fracture`,
  [`Aiuta a considerare una frattura distale del Radio`]: `Help to consider a distal fracture of radius`,
  [`Aiuta a considerare una instabilita' del gomito o una lassita' legamentosa`]: `Help to consider an elbow instability or a elbow ligaments laxity`,
  [`Aiuta a considerare una instabilita' dell'articolazione radio-unare distale`]: `Help to consider a distal radio-ulnar joint instability`,
  [`Aiuta a considerare una lesione del legamento collaterale laterale (LCL)`]: `Help to consider an injury of the lateral collateral ligament `,
  [`Aiuta a considerare una lesione del legamento collaterale mediale`]: `Help to consider MCL injury`,
  [`Aiuta a considerare una lesione del legamento collaterale mediale (MCL)`]: `Help to consider an injury of the medial collateral ligament `,
  [`Aiuta a considerare una lesione del tendine distale del bicipite brachiale`]: `Help to consider an injury of the biceps brachii distal tendon`,
  [`Aiuta a considerare una radicolopatia cervicale o una patologia relativa al disco cervicale`]: `Help to consider a cervical radiculopaty or an intervertebral disc pathology`,
  [`Aiuta a considerare una sospetta anormalita' nel SNC`]: `Help to consider a CNS anormality`,
  [`Aiuta a considerare una Tendinopatia prossimale degli hamstring`]: `Help to consider proximal hamstring tendinopathy`,
  [`Aiuta a escludere la possibile presenza di una frattura vertebrale`]: `Help to rule out a vertebral fracture`,
  [`Aiuta a escludere la presenza di condizioni non muscolo-scheletriche`]: `Help to rule out non-musculoskeletal conditions`,
  [`Aiuta a escludere la presenza di dolore femoro-rotuleo (PFPS)`]: `Help to rule out PFPS (patello femoral pain syndrome)`,
  [`Aiuta a escludere la presenza di fratture pelviche`]: `Rule out pelvic fracture`,
  [`Aiuta a escludere la presenza di PRPPP`]: `Help to rule out PRPPP (pregnancy-related posterior pelvic pain)`,
  [`Aiuta a escludere la presenza di un impingement della caviglia`]: `Help to rule out ankle joint impingement`,
  [`Aiuta a escludere la presenza di un Impingement Sub-Acromiale`]: `Help to rule out a sub-acromial impingement`,
  [`Aiuta a escludere la presenza di una Capsulite Adesiva`]: `Help to rule out adesive capsulitis`,
  [`Aiuta a escludere la presenza di una Discinesia Scapolare`]: `Help to rule out scapular dyskinesis`,
  [`Aiuta a escludere la presenza di una disfunzione sacro-iliaca`]: `Help to rule out sacroiliac joint dysfunction`,
  [`Aiuta a escludere la presenza di una disfunzione vestibolare`]: `Help to rule out a vestibular dysfunction`,
  [`Aiuta a escludere la presenza di una effusione intra-articolare`]: `Help to rule out intra-articular swelling`,
  [`Aiuta a escludere la presenza di una fasciopatia plantare`]: `Help to rule out plantar fasciopathy`,
  [`Aiuta a escludere la presenza di una frattura`]: `Help to rule out a fracture  `,
  [`Aiuta a escludere la presenza di una frattura omerale`]: `Help to rule out humeral fracture`,
  [`Aiuta a escludere la presenza di una GTPS`]: `Help to rule out GTPS (greater throcanter pain syndrome)`,
  [`Aiuta a escludere la presenza di una Hamstring Tendinopathy o un Hamstring Injury`]: `Help to rule out hamstring tendinopathy`,
  [`Aiuta a escludere la presenza di una Instabilita' Anteriore`]: `Help to rule out an anterior instability of shoulder`,
  [`Aiuta a escludere la presenza di una instabilita' del piramidale`]: `Help to rule out a triquetral instability`,
  [`Aiuta a escludere la presenza di una instabilita' dell'articolazione tibio-fibulare prossimale`]: `Help to rule out proximal tibio-fibular joint instability`,
  [`Aiuta a escludere la presenza di una instabilita' dello scafoide`]: `Help to rule out a scaphoid instability`,
  [`Aiuta a escludere la presenza di una instabilita' tra atlante e epistrofeo`]: `Help to rule out an instability of atlas and axis`,
  [`Aiuta a escludere la presenza di una lassita' capsulare dell'anca o uno stiramento del legamento ileofemorale`]: `Help to rule out laxity of hip joint capsule or iliofemoral ligament`,
  [`Aiuta a escludere la presenza di una lesione acuta del gomito`]: `Help to rule out elbow acute injury`,
  [`Aiuta a escludere la presenza di una lesione del labbro`]: `Help to rule out a labial tear`,
  [`Aiuta a escludere la presenza di una lesione del labbro postero-inferiore`]: `Help to rule out a postero-inferior labial tear`,
  [`Aiuta a escludere la presenza di una lesione del LCL (Legamento Collaterale Laterale)`]: `Help to rule out LCL injury`,
  [`Aiuta a escludere la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear)`]: `Help to rule out a rotator cuff injury (Rotator Cuff Tear)`,
  [`Aiuta a escludere la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear): Lesione del Subscapularis; aiuta a escludere la presenza di una lesione posteriore delabbro`]: `Help to rule out a Rotator Cuff Tear: Subscapularis injury; help to rule out a posterior labial tear`,
  [`Aiuta a escludere la presenza di una patologia a carico dell'articolazione Acromion-Clavicolare`]: `Help to rule out acromionclavicluar joint pathology`,
  [`Aiuta a escludere la presenza di una patologia a carico dell'articolazione Acromion-Clavicolare; basso significato clinico per l'Impingement Sub-Acromiale`]: `Help to rule out acromionclavicluar pathology; low clinical meaning for sub-acromial impingement`,
  [`Aiuta a escludere la presenza di una radicolopatia cervicale`]: `Help to rule out a cervical radiculopathy  `,
  [`Aiuta a escludere la presenza di una Sindrome del Piriforme`]: `Help to rule out piriformis syndrome`,
  [`Aiuta a escludere la presenza di una Sindrome del Tunnel Carpale`]: `Help to rule out a carpal tunnel syndrome`,
  [`Aiuta a escludere la presenza di una sindrome dello stretto toracico`]: `Help to rule out a thoracic outlet syndrome`,
  [`Aiuta a escludere la presenza di una sindrome femoro-rotulea (PFPS)`]: `Help to rule out PFPS (patello femoral pain syndrome)`,
  [`Aiuta a escludere la presenza di una tendinopatia Achillea`]: `Help to rule out achilles tendinopathy`,
  [`Aiuta a escludere la presenza di una tendinopatia del bicipite brachiale o di una lesione del bicipite brachiale`]: `Help to rule out a biceps brachii tendinopathy or a biceps brachii muscle injury`,
  [`Aiuta a escludere patologie intraarticolari dell'anca`]: `Help to rule out hip joint intraarticular pathologies`,
  [`Aiuta a escludere una disfunzione sacro-iliaca`]: `Help to rule out sacroiliac joint dysfunction`,
  [`Aiuta a escludere una Epicondilopatia Laterale`]: `Help to rule out a lateral epicondilitis`,
  [`Aiuta a escludere una frattura dello scafoide`]: `Help to rule out a scaphoid fracture`,
  [`Aiuta a escludere una frattura distale del Radio`]: `Help to rule out a distal fracture of radius`,
  [`Aiuta a escludere una instabilita' dell'articolazione radio-ulnare distale`]: `Help to rule out a distal radio-ulnar joint instability`,
  [`Aiuta a escludere una lesione del legamento collaterale lateale (LCL)`]: `Help to rule out an injury of the lateral collateral ligament `,
  [`Aiuta a escludere una lesione del legamento collaterale mediale (MCL)`]: `Help to rule out an injury of the medial collateral ligament `,
  [`Aiuta a escludere una lesione del legamento crociato anteriore`]: `Help to rule out ACL injury`,
  [`Aiuta a escludere una lesione del legamento crociato posteriore`]: `Help to rule out PCL injury`,
  [`Aiuta a escludere una lesione del legamento luno-piramidale`]: `Help to rule out a luno-triquetral ligament injury`,
  [`Aiuta a escludere una lesione meniscale`]: `Help to rule out meniscal injury `,
  [`Aiuta a escludere una lesione meniscale mediale`]: `Help to rule out meniscal injury (medial)`,
  [`Aiuta a escludere una patologia a carico dell'articolazione Acromion-Clavicolare`]: `Help to rule out acromionclavicluar joint pathology`,
  [`Aiuta a escludere una radicolopatia cervicale o una patologia relativa al disco cervicale`]: `Help to rule out a cervical radiculopaty or an intervertebral disc pathology`,
  [`Aiuta a escludere una radicolopatia lombare o una patologia relativa al disco lombare`]: `Help to rule out lumbar radiculopathy or disc pathology`,
  [`Aiuta a escludere una SAIS o Impingement Sub-Acromiale`]: `Help to rule out a sub-acromial impingement`,
  [`Aiuta a escludere una sospetta anormalita' nel SNC`]: `Help to rule out CNS anormality`,
  [`Aiuta a escludere una tendinopatia prossimale degli hamstring`]: `Help to rule out proximal hamstring tendinopathy`,
  [`Aiuta a escludere una Tenosinovite di De Quervain`]: `Help to rule out a De Quervain tenosinovitis`,
  [`Aiuta a rule out la presenza di artrosi dell'anca o altre patologie intra-articolari`]: `Help to rule out hip joint osteoarhtritis or intraarticular pathologies`,
  [`Aiuta ad escludere il coinvolgimento dell'articolazione Sacro-Iliaca`]: `Help to rule out sacroiliac joint problems`,
  [`Aiuta ad escludere la possibile presenza di una frattura dell'anello (arco anteriore e arco posteriore) dell'atlante`]: `Help to rule out a fracture of the atlas (anterior or posterior arch)`,
  [`Aiuta ad escludere la possibile presenza di una spondilolistesi`]: `Help to rule out spondylolistesis`,
  [`Aiuta ad escludere la presena di una tendinopatia rotulea`]: `Help to rule out patellar tendinopathy`,
  [`Aiuta ad escludere la presenza di artrosi dell'anca`]: `Help to rule out hip joint osteoarthritis`,
  [`Aiuta ad escludere la presenza di impingement`]: `Help to rule out an impingement`,
  [`Aiuta ad escludere la presenza di instabilita' lombare`]: `Help to rule out lumbar instability`,
  [`Aiuta ad escludere la presenza di lassita' del legamento ileo-femorale`]: `Help to rule out laxity of iliofemoral ligament`,
  [`Aiuta ad escludere la presenza di un alluce rigido`]: `Help to rule out hallux rigidus`,
  [`Aiuta ad escludere la presenza di un Neuroma di Morton`]: `Help to rule out Morton neuroma`,
  [`Aiuta ad escludere la presenza di una frattura`]: `Rule out a fracture`,
  [`Aiuta ad escludere la presenza di una frattura vertebrale toracica`]: `Hel to rule out a thoracic vertebral fracture`,
  [`Aiuta ad escludere la presenza di una gluteal tendinopathy`]: `Help to rule out gluteal tendinopathy`,
  [`Aiuta ad escludere la presenza di una instabilita' del legamento trasverso`]: `Help to rule out an instability of cervical transverse ligament`,
  [`Aiuta ad escludere la presenza di una ipomobilita' C1-C2 (possibile causa di Cervicogenic Headache (CH)`]: `Help to rule out hypomobility of C1-C2 (CH Cervicogenic Headache)`,
  [`Aiuta ad escludere la presenza di una lesione del labbro`]: `Help to rule out a labial tear`,
  [`Aiuta ad escludere la presenza di una lesione della sindesmosi`]: `Help to rule out syndesmosis injury`,
  [`Aiuta ad escludere la presenza di una piriformis syndrome`]: `Help to rule out pyriformis syndrome`,
  [`Aiuta ad escludere la presenza di una radicolopatia cervicale o di una patologia relativa al disco cervicale`]: `Help to rule out a cervical radiculopaty or an intervertebral disc pathology`,
  [`Aiuta ad escludere la presenza di una radicolopatia o di una patologia relativa al disco`]: `Help to rule out a cervical radiculopaty or an intervertebral disc pathology`,
  [`Aiuta ad escludere la presenza di una radicolopatia o di una patologia relativa al disco lombare`]: `Help to rule out a lumbar radiculopathy or disc pathology`,
  [`Aiuta ad escludere la presenza di una retrazione dell'Ileopsoas`]: `Help to rule out iliopsoas flexibility restriction`,
  [`Aiuta ad escludere la presenza di una sindrome del Tunnel Cubitale`]: `Help to rule out an injury of the biceps brachii distal tendon`,
  [`Aiuta ad escludere la presenza di una sindrome del tunnel tarsale`]: `Help to rule out tarsal tunnel syndrome`,
  [`Aiuta ad escludere la presenza di una stenosi spinale lombare`]: `Help to rule out a lumbar spine stenosis`,
  [`Aiuta ad escludere la presenza di una tendinopatia Achillea`]: `Help to rule out achilles tendinopathy`,
  [`Aiuta ad escludere la presenza di una tendinopatia del tibiale posteriore`]: `Help to rule out tibialis posterior tendinopathy`,
  [`Aiuta ad escludere la presenza di una WAD or Whiplash Associated Disorder`]: `Help to rule out a WAD (Whiplash Associated Disorder)`,
  [`Aiuta ad escludere la rottura del tendine d'Achille`]: `Help to rule out achilles tendon rupture`,
  [`Aiuta ad escludere un coinvolgimento dell'anca nella generazione del dolore`]: `Help to rule out the hip as the pain generator`,
  [`Aiuta ad escludere una lesione del bicipite brachiale`]: `Help to rule out a biceps brachii tendon injury`,
  [`Aiuta ad escludere una lesione della sindesmosi`]: `Help to rule out syndesmosis injury`,
  [`Aiuta ad escsclude la presenza di una problematica Sacro/Iliaca`]: `Help to rule out a sacroiliac joint dysfunction`,
  [`Al momento della massima intensità`]: `How sever is your pain at its worst?`,
  [`Al momento il dolore e' abbastanza forte`]: `The pain is fairly severe at the moment`,
  [`Al momento il dolore e' di media intensita'`]: `The pain is moderate at the moment`,
  [`Al momento il dolore e' molto forte`]: `The pain is severe at the moment`,
  [`Al momento il dolore e' molto lieve`]: `The pain is very mild at the moment`,
  [`Al momento il dolore non potrebbe essere peggiore`]: `The pain is the worst imaginable at the moment`,
  [`Al momento non ho dolore`]: `I have no pain at the moment`,
  [`Alata`]: `Winged`,
  [`Alcalosi metabolica`]: `Metabolic alkalosis`,
  [`Alcune volte`]: `Sometimes`,
  [`Alcuni farmaci possono provocare una medication overuse headache`]: `Some drugs could potentially cause a medication overuse headache`,
  [`All'inizio della mattina`]: `In the morning upon taking your first step?`,
  [`Alla fine della giornata`]: `How is your pain at the end of the day?`,
  [`Allacciare i bottoni della camicia`]: `Fasten buttons on my shirt`,
  [`Allenamento`]: `Training`,
  [`Allenamento completo ma le gare non sono più allo stesso livello di prima dell'esordio dei sintomi`]: `Full training / competition but not at same level as when symptoms began`,
  [`Allenamento completo ma le gare non sono piu' allo stesso livello di prima dell'esordio dei sintomi`]: `Full training / competition but not at same level as when symptoms began`,
  [`Allergia`]: `Allergic `,
  [`Allergia stagionale alla polvere`]: `Respiratory symptoms from air pollution`,
  [`Allergica`]: `Allergic`,
  [`Allergie`]: `Allergies`,
  [`Allineamento ideale`]: `Ideal alignement`,
  [`ALLUCE DESTRO`]: `RIGHT HALLUX`,
  [`alluce rigido`]: `hallux rigidus`,
  [`ALLUCE SINISTRO`]: `LEFT HALLUX`,
  [`alluce valgo`]: `hallux valgus`,
  [`Alluce valgo`]: `Hallux valgus`,
  [`Alpinismo`]: `Mountain climb`,
  [`Alta`]: `High`,
  [`Alta difficoltà`]: `Quite a bit difficulty`,
  [`Alternate Pull-Up Test`]: `Alternate Pull-Up Test`,
  [`Alternating Med Ball Push Ups`]: `Alternating Med Ball Push Ups`,
  [`Alternating Superman`]: `Alternating Superman`,
  [`Alto`]: `High`,
  [`Altra amenorrea`]: `Other amenorrhoea`,
  [`Altra cardiomiopatia`]: `Cardiomyopathy, other`,
  [`Altra diagnosi al pavimento pelvico`]: `Pelvic floor other diagnosis `,
  [`Altra diagnosi urologica/vescicale (inclusa nocturia)`]: `Other urological/bladder diagnosis (incl nocturia)`,
  [`Altra ecchimosi/ematoma ad anca/inguine`]: `Other hip/groin bruising/haematoma`,
  [`Altra ecchimosi/ematoma dei tessuti molli della coscia non altrimenti specificata`]: `Other thigh soft tissue bruising/haematoma not otherwise specified`,
  [`Altra lesione del quadricipite`]: `Other quadricep strain`,
  [`Altra lesione neurologica/intrappolamento al gomito`]: `Other nerve entrapment, elbow`,
  [`Altra neoformazione`]: `Other tumour not otherwise mentioned`,
  [`Altra tenosinovite/tendinopatia del pollice`]: `Other tenosynovitis/tendinopathy thumb`,
  [`Altre apofisiti/fratture da avulsione all'anca/inguine`]: `Other apophysitis/avulsion fracture groin/hip`,
  [`Altre complicanze di distorsione del polso`]: `Other complication of wrist sprain`,
  [`Altre complicanze di ferita/abrasione della gamba`]: `Other complication of lower leg laceration/abrasion`,
  [`Altre distorsioni del gomito non altrimenti specificate`]: `Other elbow strain not otherwise specified`,
  [`Altre ecchimosi/ematomi dei tessuti molli del ginocchio`]: `Other soft tissue bruising/haematoma knee`,
  [`Altre ecchimosi/ematomi dei tessuti molli del piede non altrimenti specificati`]: `Other foot soft tissue bruising/haematoma not elsewhere specified`,
  [`Altre fratture da stress del bacino`]: `Other stress fracture pelvis`,
  [`Altre fratture da stress della colonna lombare`]: `Other lumbar spine stress fracture`,
  [`Altre fratture da stress metatarsali`]: `Other metatarsal stress fracture`,
  [`Altre fratture dell'orbita non altrimenti specificate`]: `Other orbital fracture not otherwise specified`,
  [`Altre fratture della colonna lombare`]: `Other lumbar spine fracture`,
  [`Altre infezioni`]: `Infection, other`,
  [`Altre infezioni cutanee non specificatamente menzionate`]: `Other skin infection not specifically mentioned`,
  [`Altre infezioni del tratto respiratorio inferiore`]: `Other lower respiratory tract infection`,
  [`Altre infezioni del tratto respiratorio superiore`]: `Other upper respiratory tract infection`,
  [`Altre infezioni fungine`]: `Other fungal infection`,
  [`Altre infezioni gastrointestinali`]: `Other gastrointestinal infection`,
  [`Altre infezioni genitourinarie`]: `Other genitourinary infection`,
  [`Altre instabilita' di ginocchio`]: `Other instability`,
  [`Altre lesioni del tendine del sottoscapolare non altrimenti specificate`]: `Other Subscapularis tendon injury not otherwise specified`,
  [`Altre lesioni da sovraccarico del braccio`]: `Other Upper Arm Overuse Injury`,
  [`Altre lesioni da sovraccarico della gamba`]: `Other Leg Overuse Injury`,
  [`Altre lesioni da stress/sovraccarico dell'avambraccio`]: `Other Stress/Overuse injuries to Forearm`,
  [`Altre lesioni da stress/sovraccarico della coscia`]: `Other stress/Overuse Injuries to Thigh`,
  [`Altre lesioni da stress/sovraccarico di anca/inguine`]: `Other Stress/Overuse Injury Hip and Groin`,
  [`Altre lesioni da stress/sovraccarico non altrimenti specificate`]: `Other bony/overuse injuries not elsewhere classified`,
  [`Altre lesioni dei nervi lombosacrali`]: `Other lumbosacral nerve injury`,
  [`Altre lesioni del polso non altrimenti specificate`]: `Other Wrist Injury not otherwise specified`,
  [`Altre lesioni del tendine del bicipite brachiale non altrimenti specificate`]: `Other biceps tendon injury not otherwise specified`,
  [`Altre lesioni del tendine del grande pettorale non altrimenti specificate`]: `Other pec major tendon injury not otherwise specified`,
  [`Altre lesioni legamentose del carpo`]: `Other carpal ligament injury`,
  [`Altre lesioni legamentose della mano o delle dita`]: `Other hand or finger ligament tear`,
  [`Altre lesioni muscolari della spalla non altrimenti specificate`]: `Other shoulder muscle injury not elsewhere specified`,
  [`Altre lesioni tendinee alla spalla non altrimenti specificate`]: `Other tendon injury NOS`,
  [`Altre lesioni tendinee del sottospinato non altrimenti specificate`]: `Other Infraspinatus tendon injury Not otherwise specified`,
  [`Altre lesioni vascolari della gamba`]: `Other vascular injury to lower leg`,
  [`Altre malattie cardiovascolari`]: `Other cardiovascular disease`,
  [`Altre malattie mediche`]: `Other medical illness`,
  [`Altre malattie respiratorie non altrimenti specificate`]: `Other respiratory illness not otherwise specified`,
  [`Altre patologie venose, fovea, origine sconosciuta`]: `Other venous disease incl calf/ankle oedema, cause unknown`,
  [`Altre sindromi compartimentali acute della gamba`]: `Other acute compartment syndrome to lower leg`,
  [`Altre verruche`]: `Other warts`,
  [`Altre/i ecchimosi/ematomi non altrimenti specificati`]: `Other bruising/haematoma not otherwise specified`,
  [`Altri disordini endocrini`]: `Other endocrine disorder`,
  [`Altri disturbi ginecologici`]: `Other gynaecological illness`,
  [`Altri ematomi/ecchimosi dei tessuti molli del braccio`]: `Other Upper arm soft tissue bruising/haematoma`,
  [`Altri problemi neurologici`]: `Other neurological problem`,
  [`Altri rash cutanei non altrimenti menzionati o non diagnosticati`]: `Other rash not otherwise mentioned or undiagnosed`,
  [`Altri tipi di ortesi`]: `Other orthesis`,
  [`Altrie ecchimosi/ematomi della gamba non altrimenti specificati`]: `Other shin soft tissue bruising/haematoma not otherwise specified`,
  [`Altro`]: `Other`,
  [`altro (lasciare possibilita' di aggiungere)`]: `Other`,
  [`Altro dolore al pollice non altrimenti specificato`]: `Other thumb pain NOS`,
  [`Altro dolore al polso non altrimenti specificato`]: `Other wrist pain not otherwise specified`,
  [`Altro dolore alla mano non altrimenti specificato`]: `Other hand pain NOS`,
  [`Altro dolore alle dita non altrimenti specificato`]: `Other finger pain NOS`,
  [`Altro dolore/lesione della gamba non altrimenti specificato`]: `Other Lower Leg Pain/Injury not otherwise specified`,
  [`Altro infortunio/dolore alla caviglia non altrimenti specificato`]: `Other Ankle Pain/Injury not otherwise specified`,
  [`Altro trauma organico non altrimenti specificato`]: `Other organ trauma not otherwise specified`,
  [`altro, lasciare possibilita' di scrivere`]: `Other`,
  [`Alzarsi da seduto`]: `Raising from sitting`,
  [`Alzarsi da seguto`]: `Rising from sitting`,
  [`Alzarsi dal letto`]: `Rising from beds`,
  [`Alzarsi dalla sedia`]: `Rise from a chair`,
  [`Alzarsi/sedersi sul WC`]: `Getting on/off toilet`,
  [`Ambientale- correlata all'esercizio`]: `Environmental- exercise-related `,
  [`Ambientale-non correlata ad esercizio`]: `Environmental-non exercise`,
  [`Ambientale-non relazionata ad esercizio`]: `Environmental - non exercise`,
  [`Ambientale-relazionata ad esercizio`]: `Environmental - exercise-related`,
  [`Ambientale– correlata all'esercizio`]: `Environmental- exercise-related `,
  [`Amenorrea associata a dieta ed esercizio`]: `Diet and Exercise associated amenorrhoea`,
  [`Amenorrea ipotalamica funzionale`]: `Functional hypothalamic amenorrhoea `,
  [`AMPOLLA RETTALE`]: `RECTAL AMPULLA`,
  [`Amputazioni`]: `Amputations`,
  [`Analisi e statistiche`]: `Analysis`,
  [`Anamnesi del dolore`]: `Pain History`,
  [`Anamnesi medica attuale`]: `Current Medical History`,
  [`Anamnesi medica familiare`]: `Family Medical History`,
  [`Anamnesi medica personale`]: `Personal Medical History`,
  [`Anatomical Snuffbox Tenderness (DX)`]: `Anatomical Snuffbox Tenderness (R)`,
  [`Anatomical SnuffBox Tenderness (SX)`]: `Anatomical SnuffBox Tenderness (L)`,
  [`Anca`]: `Hip`,
  [`Anca (DX)`]: `Hip (R)`,
  [`Anca (SX)`]: `Hip (L)`,
  [`Anca a scatto (escluso scatto da lesione del labbro o da tendine dell'ileopsoas)`]: `Clicking hip`,
  [`Anca a scatto da ileopsoas`]: `Snapping psoas tendon`,
  [`Andare a fare spese`]: `Going shopping`,
  [`Andare al lavoro`]: `going to work`,
  [`Anemia`]: `Anemia`,
  [`Anemia, deficit di ferro`]: `Iron deficiency anaemia`,
  [`Angina`]: `Angina or chest pain`,
  [`Angina o dolore toracico`]: `Angina or chest pain`,
  [`ANGOLO POSTEROLATERALE DEL GINOCCHIO DESTRO`]: `POSTEROLATERAL CORNER OF RIGHT KNEE`,
  [`ANGOLO POSTEROLATERALE DEL GINOCCHIO SINISTRO`]: `POSTEROLATERAL CORNER OF LEFT KNEE`,
  [`Angst, F., Schwyzer, H. K., Aeschlimann, A., Simmen, B. R., & Goldhahn, J. (2011). Measures of adult shoulder function: disabilities of the arm, shoulder, and hand questionnaire (DASH) and its short version (QuickDASH), shoulder pain and disability index...`]: `Angst, F., Schwyzer, H. K., Aeschlimann, A., Simmen, B. R., & Goldhahn, J. (2011). Measures of adult shoulder function: disabilities of the arm, shoulder, and hand questionnaire (DASH) and its short version (QuickDASH), shoulder pain and disability index...`,
  [`Ankle Anterior Drawer Test (DX)`]: `Ankle Anterior Drawer Test (R)`,
  [`Ankle Anterior Drawer Test (SX)`]: `Ankle Anterior Drawer Test (L)`,
  [`ANO`]: `ANUS`,
  [`Anomalia al pap test cervicale`]: `Abnormal cervical smear `,
  [`Anomalia cardiaca congenita`]: `Cardiovasculardevelopmental anomaly`,
  [`Anomalia di sviluppo`]: `Development anomaly`,
  [`Anomalia radioulnare`]: `Radioulnar variance`,
  [`Anoressia nervosa`]: `Anorexia nervosa`,
  [`Anormalita' congenita dell'articolazione dell'anca`]: `Congenital abnormality of hip joint`,
  [`Anormalita' congenita della colonna lombare`]: `Congenital abnormality Lumbar Spine`,
  [`Anormalita' congenita dellacartilagine del ginocchio`]: `Congenital cartilage abnormality of knee`,
  [`Anormalita' muscolari della gamba`]: `Muscle abnormality of lower leg`,
  [`Anormalita' strutturale della gabbia toracica`]: `Chest Structural Abnormaility`,
  [`Anormalita' strutturali del ginocchio`]: `Structural Abnormality of knee`,
  [`Anormalita' strutturali del piede`]: `Structural Abnormality of Foot`,
  [`Anormalita' strutturali dell'anca/inguine`]: `Structural Abnormaility of Hip/Groin`,
  [`Anormalita' strutturali della colonna cervicale`]: `Structural Abnormality Cervical Spine`,
  [`Anormalita' strutturali della colonna lombare`]: `Lumbosacral Spine Structural Abnormality`,
  [`Anormalita' strutturali della colonna toracica`]: `Thoracic Spine Structural Abnormality`,
  [`Anormalita' strutturali della gamba`]: `Structural Abnormality of Lower leg`,
  [`Anormalita' strutturali della spalla`]: `Shoulder Structural Abnormality`,
  [`Ansia`]: `Anxiety`,
  [`Ansia o attacchi di panico`]: `Anxiety/panic attacks`,
  [`Ansia/attacchi di panico`]: `Anxiety/panic disorder`,
  [`Anterior Apprehension Test (DX)`]: `Anterior Apprehension Test (R)`,
  [`Anterior Apprehension Test (SX)`]: `Anterior Apprehension Test (L)`,
  [`Anterior Slide Test (DX)`]: `Anterior Slide Test (R)`,
  [`Anterior Slide Test (SX)`]: `Anterior Slide Test (L)`,
  [`Anteriorita'`]: `Anterior glide`,
  [`Antero-Posterior and Lateral Compression Test (DX)`]: `Antero-Posterior and Lateral Compression Test (R)`,
  [`Antero-Posterior and Lateral Compression Test (SX)`]: `Antero-Posterior and Lateral Compression Test (L)`,
  [`Anti Rotazione Con Elastico Da In Ginocchio Anche Estese`]: `KNEELING PALLOF RPESS`,
  [`Anti Rotazione Con Elastico Da In Ginocchio Con Estensione Anche`]: `KNEELING PALLOF PRESS HIP EXTENSION`,
  [`Antirotazione Con Elastico Da In Ginocchio`]: `JAPANESE SITTING PALLOF PRESS`,
  [`Antirotazione Con Elastico Da In Ginocchio Pede Esterno`]: `HALF KNEELING PALLOF PRESS`,
  [`Antirotazione Con Elastico Da In Ginocchio Piede Interno`]: `HALF KNEELING PALLOF PRESS 2`,
  [`Antirotazione Con Elastico Da Split Piede Interno`]: `SPLIT STANCE PALLOF PRESS`,
  [`Antirotazione Con Elastico Da Supino`]: `SUPINE LYING PALLOF PRESS`,
  [`Antirotazione Con Elastico Da Supino Gambe Sollevate`]: `DEAD BUG PALLOF PRESS`,
  [`Antirotazione Con Elastico Dead Bug Estensione Gamba`]: `1 LEG DEAD BUG PALLOF PRESS`,
  [`Antirotazione Da 1/4 Squat`]: `PALLOF PRESS`,
  [`Antirotazione Da Seduto Gambe Piegate`]: `V-SIT PALLOF PRESS`,
  [`Antirotazione Da Seduto Gambe Tese`]: `STRAIGHT LEG SEATED PALLOF PRESS`,
  [`Antiversione femorale`]: `Femoral antiversion`,
  [`Apertura`]: `Opening`,
  [`Apley's Test (DX)`]: `Apley's Test (R)`,
  [`Apley's Test (SX)`]: `Apley's Test (L)`,
  [`Apofisite`]: `Apophisytis`,
  [`Apofisite da trazione/osteocondrite non altrimenti specificata`]: `Traction Apophysitis/Osteochondrosis not elsewhere specified`,
  [`apofisite del gomito`]: `apophysitis elbow`,
  [`Apofisite dell'epicondilo mediale del gomito`]: `Apophysitis medial epicondyle elbow`,
  [`apofisite della sinfisi pubica`]: `Pubic symphysis apophysitis`,
  [`apofisite della spina iliaca antero inferiore`]: `Anterior inferior iliac spine apophysitis`,
  [`apofisite della spina iliaca antero superiore`]: `Anterior superior iliac spine apophysitis`,
  [`apofisite della tuberosita' ischiatica`]: `ischial tuberosity apophysitis`,
  [`Apofisite/ frattura da avulsione della base del V metatarso`]: `Apophysitis/avulsion fracture base 5th metatarsal`,
  [`Apofisite/frattura da avulsione al polo inferiore della rotula`]: `Apophysitis/avulsion fracture distal pole patella (SLJ)`,
  [`Apofisite/frattura da avulsione alla cresta iliaca`]: `Apophysitis/avulsion fracture iliac crest`,
  [`Apofisite/frattura da avulsione alla tuberosita' ischiatica`]: `Apophysitis/avulsion fracture ischial tuberosity`,
  [`Apofisite/frattura da avulsione spina iliaca anteriore inferiore`]: `Apophysitis/avulsion fracture AIIS`,
  [`Apofisite/frattura da avulsione spina iliaca anteriore superiore`]: `Apophysitis/avulsion fracture ASIS`,
  [`Apofisite/frattura da avulsione su apofisi al calcagno (morbo di Sever`]: `Apophysitis/avulsion fracture to calcaneus (Severs Dx)`,
  [`APONEUROSI BICIPITALE DESTRA`]: `RIGHT BICIPITAL APONEUROSIS`,
  [`APONEUROSI BICIPITALE SINISTRA`]: `LEFT BICIPITAL APONEUROSIS`,
  [`APONEUROSI DEL MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO`]: `APONEUROSIS OF RIGHT EXTERNAL OBLIQUE MUSCLE`,
  [`APONEUROSI DEL MUSCOLO OBLIQUO ESTERNO DELL'ADDOME SINISTRO`]: `APONEUROSIS OF LEFT EXTERNAL OBLIQUE MUSCLE`,
  [`APONEUROSI DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME DESTRO`]: `APONEUROSIS OF RIGHT INTERNAL OBLIQUE MUSCLE`,
  [`APONEUROSI DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME SINISTRO`]: `APONEUROSIS OF LEFT INTERNAL OBLIQUE MUSCLE`,
  [`APONEUROSI DEL MUSCOLO TRASVERSO DELL'ADDOME DESTRO`]: `APONEUROSIS OF RIGHT TRANSVERSE ABDOMEN MUSCLE`,
  [`APONEUROSI DEL MUSCOLO TRASVERSO DELL'ADDOME SINISTRO`]: `APONEUROSIS OF LEFT TRANSVERSE ABDOMEN MUSCLE`,
  [`Aponeurosi glutea (DX)`]: `Gluteal aponeurosis (R)`,
  [`Aponeurosi glutea (SX)`]: `Gluteal aponeurosis (L)`,
  [`APONEUROSI GLUTEA DESTRA`]: `RIGHT GLUTEAL APONEUROSIS`,
  [`APONEUROSI GLUTEA SINISTRA`]: `LEFT GLUTEAL APONEUROSIS`,
  [`APONEUROSI INFERIORE DEL MUSCOLO TRAPEZIO DESTRO`]: `INFERIOR APONEUROSIS OF RIGHT TRAPEZIUS MUSCLE`,
  [`APONEUROSI INFERIORE DEL MUSCOLO TRAPEZIO SINISTRO`]: `INFERIOR APONEUROSIS OF LEFT TRAPEZIUS MUSCLE`,
  [`APONEUROSI MEDIA DEL MUSCOLO TRAPEZIO DESTRO`]: `INTERMEDIATE APONEUROSIS OF RIGHT TRAPEZIUS MUSCLE`,
  [`APONEUROSI MEDIA DEL MUSCOLO TRAPEZIO SINISTRO`]: `INTERMEDIATE APONEUROSIS OF LEFT TRAPEZIUS MUSCLE`,
  [`APONEUROSI POSTERIORE DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME DESTRO`]: `POSTERIOR APONEUROSIS OF RIGHT INTERNAL OBLIQUE MUSCLE`,
  [`APONEUROSI POSTERIORE DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME SINISTRO`]: `POSTERIOR APONEUROSIS OF LEFT INTERNAL OBLIQUE MUSCLE`,
  [`APONEUROSI POSTERIORE DEL MUSCOLO TRASVERSO DELL'ADDOME DESTRO`]: `POSTERIOR APONEUROSIS OF RIGHT TRANSVERSE ABDOMINAL MUSCLE`,
  [`APONEUROSI POSTERIORE DEL MUSCOLO TRASVERSO DELL'ADDOME SINISTRO`]: `POSTERIOR APONEUROSIS OF LEFT TRANSVERSE ABDOMINAL MUSCLE`,
  [`APONEUROSI SUPERIORE DEL MUSCOLO TRAPEZIO DESTRO`]: `RIGHT TRAPEZIUS SUPERIOR APONEUROSIS`,
  [`APONEUROSI SUPERIORE DEL MUSCOLO TRAPEZIO SINISTRO`]: `LEFT TRAPEZIUS SUPERIOR APONEUROSIS`,
  [`Apparecchio acustico`]: `Hearing aid`,
  [`Apparecchio dentale o bite dentale`]: `Dental orthodontic`,
  [`Apparent Leg Length Discrepancy (DX)`]: `Apparent Leg Length Discrepancy (R)`,
  [`Apparent Leg Length Discrepancy (SX)`]: `Apparent Leg Length Discrepancy (L)`,
  [`Appena visibile`]: `Barely visible`,
  [`Appendice`]: `Appendix`,
  [`APPENDICE`]: `VERMIFORM APPENDIX`,
  [`Appendicite`]: `Appendicitis`,
  [`Appiattito`]: `Flattened`,
  [`Aprire una porta pesante`]: `Push open a heavy door`,
  [`Arc Sign (DX)`]: `Arc Sign (R)`,
  [`Arc Sign (SX)`]: `Arc Sign (L)`,
  [`ARCATA DENTALE MANDIBOLARE`]: `MANDIBULAR DENTAL ARCADE`,
  [`ARCATA DENTALE MASCELLARE`]: `MAXILLARY DENTAL ARCADE`,
  [`ARCATA SOPRACILIARE DESTRA`]: `RIGHT SUPERCILIARY ARCH`,
  [`ARCATA SOPRACILIARE SINISTRA`]: `LEFT SUPERCILIARY ARCH`,
  [`Arcata tendinea del muscolo soleo (DX)`]: `Tendineous arch of soleus (R)`,
  [`Arcata tendinea del muscolo soleo (SX)`]: `Tendineous arch of soleus (L)`,
  [`ARCATA TENDINEA DEL MUSCOLO SOLEO DESTRO`]: `TENDINOUS ARCH OF RIGHT SOLEUS`,
  [`ARCATA TENDINEA DEL MUSCOLO SOLEO SINISTRO`]: `TENDINOUS ARCH OF LEFT SOLEUS`,
  [`ARCATA ZIGOMATICA DESTRA`]: `RIGHT ZYGOMATIC ARCH`,
  [`ARCATA ZIGOMATICA SINISTRA`]: `LEFT ZYGOMATIC ARCH`,
  [`Area Achillea Della Gamba Destra`]: `Right Achilles Area`,
  [`Area Achillea Della Gamba Sinistra`]: `Left Achilles Area`,
  [`Area anteriore del braccio`]: `Anterior area of upper arm`,
  [`Area Anteriore Del Braccio Destro`]: `Anterior Area Of Right Upper Arm`,
  [`Area Anteriore Del Braccio Sinistro`]: `Anterior Area Of Left Upper Arm`,
  [`Area anteriore del ginocchio`]: `Anterior area of knee`,
  [`Area Anteriore Del Ginocchio Destro`]: `Anterior Area Of Right Knee`,
  [`Area Anteriore Del Ginocchio Sinistro`]: `Anterior Area Of Left Knee`,
  [`Area anteriore del gomito`]: `Anterior area of elbow`,
  [`Area Anteriore Del Gomito Destro`]: `Anterior Area Of Right Elbow`,
  [`Area Anteriore Del Gomito Sinistro`]: `Anterior Area Of Left Elbow`,
  [`Area anteriore dell'avambraccio`]: `Anterior area of forearm`,
  [`Area Anteriore Dell'Avambraccio Destro`]: `Anterior Area Of Right Forearm`,
  [`Area Anteriore Dell'Avambraccio Sinistro`]: `Anterior Area Of Left Forearm`,
  [`Area anteriore della caviglia`]: `Anterior area of ankle`,
  [`Area Anteriore Della Caviglia Destra`]: `Anterior Area Of Right Ankle`,
  [`Area Anteriore Della Caviglia Sinistra`]: `Anterior Area Of Left Ankle`,
  [`Area anteriore della coscia`]: `Anterior area of thigh`,
  [`Area Anteriore Della Coscia Destra`]: `Anterior Area Of Right Thigh`,
  [`Area Anteriore Della Coscia Sinistra`]: `Anterior Area Of Left Thigh`,
  [`Area anteriore dellavanbraccio`]: `Anterior area of forearm`,
  [`Area anteriore destra`]: `Anterior right area`,
  [`Area anteriore sinistra`]: `Anterior left area`,
  [`Area antero-laterale della spalla`]: `Deltoid area`,
  [`Area antero-mediale dell'anca`]: `Groin area`,
  [`Area antero-mediale della gamba`]: `Anterior area of leg`,
  [`Area Anterolaterale Dell'Anca Destra`]: `Right Lateral Hip Area`,
  [`Area Anterolaterale Dell'Anca Sinistra`]: `Left Lateral Hip Area`,
  [`Area Anteromediale Dell'Anca Destra`]: `Right Groin Area`,
  [`Area Anteromediale Dell'Anca Sinistra`]: `Left Groin Area`,
  [`Area Anteromediale Della Gamba Destra`]: `Anterior Area Of Right Leg`,
  [`Area Anteromediale Della Gamba Sinistra`]: `Anterior Area Of Left Leg`,
  [`Area Auricolare Destra`]: `Right Auricular Area`,
  [`Area Auricolare Sinistra`]: `Left Auricular Area`,
  [`Area Centrale Dorsale Del Polso Sinistro`]: `Central Dorsal Area Of Left Wrist`,
  [`Area Centrale Dorsale Del Polso Destro`]: `Central Dorsal Area Of Right Wrist`,
  [`Area Centrale Volare Del Polso Destro`]: `Central Volar Area Of Right Wrist`,
  [`Area Centrale Volare Del Polso Sinistro`]: `Central Volar Area Of Left Wrist`,
  [`Area Cervicale Paravertebrale Destra`]: `Right Paravertebral Cervical Area`,
  [`Area Cervicale Paravertebrale Sinistra`]: `Left Paravertebral Cervical Area`,
  [`Area Cervicale Vertebrale Inferiore`]: `Inferior Vertebral Cervical Area`,
  [`Area Cervicale Vertebrale Superiore`]: `Superior Vertebral Cervical Area`,
  [`Area Costale Destra`]: `Right Costal Area`,
  [`Area Costale Sinistra`]: `Left Costal Area`,
  [`Area Del Fianco Destro`]: `Area Of Right Flank`,
  [`Area Del Fianco Sinistro`]: `Area Of Left Flank`,
  [`AREA DEL NASO`]: `Nasal area`,
  [`Area del pollice`]: `Thumb area`,
  [`Area Del Pollice Destro`]: `Right Thumb Area`,
  [`Area Del Pollice Sinistro`]: `Left Thumb Area`,
  [`Area del retropiede`]: `Rearfoot area`,
  [`Area del sacro`]: `Sacral area`,
  [`Area Dell'Ipocondrio Destro`]: `Right Hypochondrium Area`,
  [`Area Dell'Ipocondrio Sinistro`]: `Left Hypochondrium Area`,
  [`AREA DELL'ORECCHIO DESTRO`]: `Right auricular area`,
  [`AREA DELL'ORECCHIO SINISTRO`]: `Left auricular area`,
  [`AREA DELLA BOCCA E DEL MENTO`]: `Oral and mentolabial area`,
  [`Area Deltoidea Della Spalla Destra`]: `Deltoid Area Of Right Shoulder`,
  [`Area Deltoidea Della Spalla Sinistra`]: `Deltoid Area Of Left Shoulder`,
  [`Area dorsale centrale del polso`]: `Central dorsal area of wrist`,
  [`Area dorsale del mesopiede`]: `Dorsal area of midfoot`,
  [`Area Dorsale Del Mesopiede Destro`]: `Dorsal Area Of Right Midfoot`,
  [`Area Dorsale Del Mesopiede Sinistro`]: `Dorsal Area Of Left Midfoot`,
  [`Area dorsale dell'alluce`]: `Dorsal area of hallux`,
  [`Area dorsale dell'avampiede`]: `Dorsal area of forefoot`,
  [`Area dorsale della mano`]: `Dorsal area of hand`,
  [`Area Dorsale Della Mano Destra`]: `Dorsal Area Of Right Hand`,
  [`Area Dorsale Della Mano Sinistra`]: `Dorsal Area Of Left Hand`,
  [`Area dorsale radiale del polso`]: `Radial dorsal area of wrist`,
  [`Area dorsale ulnare del polso`]: `Ulnar dorsal area of wrist`,
  [`Area Epigastrica`]: `Epigastric Area`,
  [`Area Frontale Destra`]: `Right Frontal Area`,
  [`AREA FRONTALE MEDIALE`]: `Frontal area`,
  [`Area Frontale Mediana`]: `Median Frontal Area`,
  [`Area Frontale Sinistra`]: `Left Frontal Area`,
  [`Area Genitale`]: `Urogenital Triangle`,
  [`Area glutea`]: `Gluteal area`,
  [`Area Glutea Destra`]: `Right Glute Area`,
  [`Area Glutea Sinistra`]: `Left Glute Area`,
  [`Area iliaca`]: `Iliac area`,
  [`Area Iliaca Destra`]: `Right Inguinal Area`,
  [`Area Iliaca Sinistra`]: `Left Inguinal Area`,
  [`Area inferiore destra`]: `Inferior right area`,
  [`Area inferiore sinistra`]: `Inferior left area`,
  [`Area Infraioidea`]: `Infrahyoid Area`,
  [`Area Ipogastrica`]: `Hypogastric Area`,
  [`Area laterale`]: `Lateral area of forearm`,
  [`Area Laterale Del Collo Destra`]: `Right Sternocleidomastoid Area`,
  [`Area Laterale Del Collo Sinistra`]: `Left Sternocleidomastoid Area`,
  [`Area laterale del ginocchio`]: `Lateral area of knee`,
  [`Area Laterale Del Ginocchio Destro`]: `Lateral Area Of Right Knee`,
  [`Area Laterale Del Ginocchio Sinistro`]: `Lateral Area Of Left Knee`,
  [`Area laterale del gomito`]: `Lateral area of elbow`,
  [`Area Laterale Del Gomito Destro`]: `Lateral Area Of Right Elbow`,
  [`Area Laterale Del Gomito Sinistro`]: `Lateral Area Of Left Elbow`,
  [`Area laterale dell'avambraccio`]: `Lateral area of forearm`,
  [`Area Laterale Dell'Avambraccio Destro`]: `Lateral Area Of Right Forearm`,
  [`Area Laterale Dell'Avambraccio Sinistro`]: `Lateral Area Of Left Forearm`,
  [`Area laterale della caviglia`]: `Lateral area of ankle`,
  [`Area Laterale Della Caviglia Destra`]: `Lateral Area Of Right Ankle`,
  [`Area Laterale Della Caviglia Sinistra`]: `Lateral Area Of Left Ankle`,
  [`Area laterale della coscia`]: `Lateral area of thigh`,
  [`Area Laterale Della Coscia Destra`]: `Lateral Area Of Right Thigh`,
  [`Area Laterale Della Coscia Sinistra`]: `Lateral Area Of Left Thigh`,
  [`Area laterale della gamba`]: `Lateral area of leg`,
  [`Area Laterale Della Gamba Destra`]: `Lateral Area Of Right Leg`,
  [`Area Laterale Della Gamba Sinistra`]: `Lateral Area Of Left Leg`,
  [`Area Laterale Dorsale Dell'Avampiede Destro`]: `Dorsal Lateral Area Of Right Forefoot`,
  [`Area Laterale Dorsale Dell'Avampiede Sinistro`]: `Dorsal Lateral Area Of Left Forefoot`,
  [`Area Laterale Plantare Dell'Avampiede Destro`]: `Plantar Lateral Area Of Right Forefoot`,
  [`Area Laterale Plantare Dell'Avampiede Sinistro`]: `Plantar Lateral Area Of Left Forefoot`,
  [`Area lombare laterale`]: `Lateral lumbar area`,
  [`Area Lombare Paravertebrale Destra`]: `Right Paravertebral Lumbar Area`,
  [`Area Lombare Paravertebrale Sinistra`]: `Left Paravertebral Lumbar Area`,
  [`Area Lombare Vertebrale`]: `Vertebral Lumbar Area`,
  [`AREA LOMBARE VERTEBRALE`]: `VERTEBRAL LUMBAR AREA`,
  [`Area mediale del ginocchio`]: `Medial area of knee`,
  [`Area Mediale Del Ginocchio Destro`]: `Medial Area Of Right Knee`,
  [`Area Mediale Del Ginocchio Sinistro`]: `Medial Area Of Left Knee`,
  [`Area mediale del gomito`]: `Medial area of elbow`,
  [`Area Mediale Del Gomito Destro`]: `Medial Area Of Right Elbow`,
  [`Area Mediale Del Gomito Sinistro`]: `Medial Area Of Left Elbow`,
  [`Area mediale della caviglia`]: `Medial area of ankle`,
  [`Area Mediale Della Caviglia Destra`]: `Medial Area Of Right Ankle`,
  [`Area Mediale Della Caviglia Sinistra`]: `Medial Area Of Left Ankle`,
  [`Area mediale della coscia`]: `Medial area of thigh`,
  [`Area Mediale Della Coscia Destra`]: `Medial Area Of Right Thigh`,
  [`Area Mediale Della Coscia Sinistra`]: `Medial Area Of Left Thigh`,
  [`Area Mediale Dorsale Dell'Avampiede Destro`]: `Dorsal Medial Area Of Right Forefoot`,
  [`Area Mediale Dorsale Dell'Avampiede Sinistro`]: `Dorsal Medial Area Of Left Forefoot`,
  [`Area Mediale Plantare Dell'Avampiede Destro`]: `Plantar Medial Area Of Rigth Forefoot`,
  [`Area Mediale Plantare Dell'Avampiede Sinistro`]: `Plantar Medial Area Of Left Forefoot`,
  [`Area Nasale`]: `Nasal Area`,
  [`Area Nucale Destra`]: `Right Nuchal Area`,
  [`Area Nucale Sinistra`]: `Left Nuchal Area`,
  [`Area Occipitale`]: `Occipital Area`,
  [`AREA OCCIPITO-MASTOIDEA DESTRA`]: `Right mastoid area`,
  [`AREA OCCIPITO-MASTOIDEA SINISTRA`]: `Left mastoid area`,
  [`Area Occipitomastoidea Destra`]: `Right Mastoid Area`,
  [`Area Occipitomastoidea Sinistra`]: `Left Mastoid Area`,
  [`Area Ombelicale`]: `Umbilical Area`,
  [`Area Orale E Mentolabiale`]: `Mentolabial And Oral Area`,
  [`Area Orbitale Destra`]: `Right Orbital Area`,
  [`Area Orbitale Sinistra`]: `Left Orbital Area`,
  [`Area palmare centrale del polso`]: `Central palmar area of wrist`,
  [`Area palmare della mano`]: `Palmar area of hand`,
  [`Area Palmare Della Mano Destra`]: `Palmar Area Of Right Hand`,
  [`Area Palmare Della Mano Sinistra`]: `Palmar Area Of Left Hand`,
  [`Area palmare radiale del polso`]: `Radial palmar area of wrist`,
  [`Area palmare ulnare del polso`]: `Ulnar palmar area of wrist`,
  [`Area Perineale`]: `Perineal Triangle`,
  [`Area Pettorale Destra`]: `Right Pectoral Area`,
  [`Area Pettorale Sinistra`]: `Left Pectoral Area`,
  [`Area plantare del mesopiede`]: `Plantar area of midfoot`,
  [`Area Plantare Del Mesopiede Destro`]: `Plantar Area Of Right Midfoot`,
  [`Area Plantare Del Mesopiede Sinistro`]: `Plantar Area Of Left Midfoot`,
  [`Area Plantare Del Retropiede Destro`]: `Plantar Area Of Right Rearfoot`,
  [`Area Plantare Del Retropiede Sinistro`]: `Plantar Area Of Left Rearfoot`,
  [`Area plantare dell'alluce`]: `Plantar area of hallux`,
  [`Area plantare dell'avampiede`]: `Plantar area of forefoot`,
  [`Area posteriore del braccio`]: `Posterior area of upper arm`,
  [`Area Posteriore Del Braccio Destro`]: `Posterior Area Of Right Upper Arm`,
  [`Area Posteriore Del Braccio Sinistro`]: `Posterior Area Of Left Upper Arm`,
  [`Area posteriore del ginocchio`]: `Posterior area of knee`,
  [`Area Posteriore Del Ginocchio Destro`]: `Posterior Area Of Right Knee`,
  [`Area Posteriore Del Ginocchio Sinistro`]: `Posterior Area Of Left Knee`,
  [`Area posteriore del gomito`]: `Posterior area of elbow`,
  [`Area Posteriore Del Gomito Destro`]: `Posterior Area Of Right Elbow`,
  [`Area Posteriore Del Gomito Sinistro`]: `Posterior Area Of Left Elbow`,
  [`Area posteriore dell'avambraccio`]: `Posterior area of forearm`,
  [`Area Posteriore Dell'Avambraccio Destro`]: `Posterior Area Of Right Forearm`,
  [`Area Posteriore Dell'Avambraccio Sinistro`]: `Posterior Area Of Left Forearm`,
  [`Area posteriore della caviglia`]: `Posterior area of ankle`,
  [`Area Posteriore Della Caviglia Destra`]: `Posterior Area Of Right Ankle`,
  [`Area Posteriore Della Caviglia Sinistra`]: `Posterior Area Of Left Ankle`,
  [`Area posteriore della coscia`]: `Posterior thigh area`,
  [`Area Posteriore Della Coscia Destra`]: `Posterior Area Of Right Thigh`,
  [`Area Posteriore Della Coscia Sinistra`]: `Posterior Area Of Left Thigh`,
  [`Area posteriore della gamba`]: `Posterior area of leg`,
  [`Area Posteriore Della Gamba Destra`]: `Posterior Area Of Right Leg`,
  [`Area Posteriore Della Gamba Sinistra`]: `Posterior Area Of Left Leg`,
  [`Area posteriore della spalla`]: `Scapular area`,
  [`Area Radiale Dorsale Del Polso Destro`]: `Radial Dorsal Area Of Right Wrist`,
  [`Area Radiale Dorsale Del Polso Sinistro`]: `Radial Dorsal Area Of Left Wrist`,
  [`Area Radiale Volare Del Polso Destro`]: `Radial Volar Area Of Right Wrist`,
  [`Area Radiale Volare Del Polso Sinistro`]: `Radial Volar Area Of Left Wrist`,
  [`Area Sacrale`]: `Sacral Area`,
  [`AREA SACRALE`]: `SACRAL AREA`,
  [`Area sacro-iliaca`]: `Sacroiliac area`,
  [`Area Sacroiliaca Destra`]: `Right Sacroiliac Area`,
  [`Area Sacroiliaca Sinistra`]: `Left Sacroiliac Area`,
  [`Area Sagittale`]: `Sagittal Area`,
  [`Area Scapolare Della Spalla Destra`]: `Scapular Area Of Right Shoulder`,
  [`Area Scapolare Della Spalla Sinistra`]: `Scapular Area Of Left Shoulder`,
  [`Area sopraioidea`]: `Suprahyoid area`,
  [`Area Sopraioidea Destra`]: `Right Suprahyoid Area`,
  [`Area Sopraioidea Sinistra`]: `Left Suprahyoid Area`,
  [`Area Sternale`]: `Sternal Area`,
  [`Area superiore destra`]: `Superior right area`,
  [`Area superiore sinistra`]: `Superior left area`,
  [`AREA TEMPORO-MANDIBOLARE DESTRA`]: `Right temporomandibular area`,
  [`AREA TEMPORO-MANDIBOLARE SINISTRA`]: `Left temporomandibular area`,
  [`AREA TEMPORO-PARIETALE DESTRA`]: `Right temporal area`,
  [`AREA TEMPORO-PARIETALE SINISTRA`]: `Left temporal area`,
  [`Area Temporomandibolare Destra`]: `Right Parotid Area`,
  [`Area Temporomandibolare Sinistra`]: `Left Parotid Area`,
  [`Area Temporoparietale Destra`]: `Right Temporal Area`,
  [`Area Temporoparietale Sinistra`]: `Left Temporal Area`,
  [`Area toracca laterale`]: `Lateral thoracic area`,
  [`Area toracica inferiore destra`]: `Right inferior paravertebral thoracic area`,
  [`Area toracica inferiore sinistra`]: `Left inferior paravertebral thoracic area`,
  [`Area toracica media destra`]: `Right intermediate paravertebral thoracic area`,
  [`Area toracica media sinistra`]: `Left intermediate paravertebral thoracic area`,
  [`Area Toracica Paravertebrale Inferiore Destra`]: `Right Inferior Paravertebral Thoracic Area`,
  [`Area Toracica Paravertebrale Inferiore Sinistra`]: `Left Inferior Paravertebral Thoracic Area`,
  [`Area Toracica Paravertebrale Media Destra`]: `Right Intermediate Paravertebral Thoracic Area`,
  [`Area Toracica Paravertebrale Media Sinistra`]: `Left Intermediate Paravertebral Thoracic Area`,
  [`Area Toracica Paravertebrale Superiore Destra`]: `Right Superior Paravertbral Thoracic Area`,
  [`Area Toracica Paravertebrale Superiore Sinistra`]: `Left Superior Paravertebral Thoracic Area`,
  [`Area toracica superiore destra`]: `Right superior paravertebral thoracic area`,
  [`Area toracica superiore sinistra`]: `Left superior paravertebral thoracic area`,
  [`Area Toracica Vertebrale Inferiore`]: `Inferior Vertebral Thoracic Area`,
  [`Area Toracica Vertebrale Media`]: `Intermediate Vertebral Thoracic Area`,
  [`Area Toracica Vertebrale Superiore`]: `Superior Vertebral Thoracic Area`,
  [`Area Ulnare Dorsale Del Polso Destro`]: `Ulnar Dorsal Area Of Right Wrist`,
  [`Area Ulnare Dorsale Del Polso Sinistro`]: `Ulnar Dorsal Area Of Left Wrist`,
  [`Area Ulnare Volare Del Polso Destro`]: `Ulnar Volar Area Of Right Wrist`,
  [`Area Ulnare Volare Del Polso Sinistro`]: `Ulnar Volar Area Of Left Wrist`,
  [`AREA ZIGOMATICO-MASCELLARE DESTRA`]: `Right zygomatic area`,
  [`AREA ZIGOMATICO-MASCELLARE SINISTRA`]: `Left zygomatic area`,
  [`Area Zigomaticomascellare Destra`]: `Right Zygomatic Area`,
  [`Area Zigomaticomascellare Sinistra`]: `Left Zygomatic Area`,
  [`Aritmia cardiaca/anomalia della conduzione`]: `Heart arrhythmia/conduction abnormality`,
  [`Aritmie`]: `Arrhythmia`,
  [`ARM CIRCLES `]: `ARM CIRCLES`,
  [`Arm Curl Test (DX)`]: `Arm Curl Test (R)`,
  [`Arm Curl Test (SX)`]: `Arm Curl Test (L)`,
  [`Arm Squeeze Test (DX)`]: `Arm Squeeze Test (R)`,
  [`Arm Squeeze Test (SX)`]: `Arm Squeeze Test (L)`,
  [`Arnold Press`]: `Arnold Press`,
  [`ARTERIA ALVEOLARE INFERIORE DESTRA`]: `RIGHT INFERIOR ALVEOLAR ARTERY`,
  [`ARTERIA ALVEOLARE INFERIORE SINISTRA`]: `LEFT INFERIOR ALVEOLAR ARTERY`,
  [`ARTERIA ALVEOLARE SUPERIORE POSTERIORE DESTRA`]: `RIGHT POSTERIOR SUPERIOR ALVEOLAR ARTERY`,
  [`ARTERIA ALVEOLARE SUPERIORE POSTERIORE SINISTRA`]: `LEFT POSTERIOR SUPERIOR ALVEOLAR ARTERY`,
  [`ARTERIA ANGOLARE DESTRA`]: `RIGHT ANGULAR ARTERY`,
  [`ARTERIA ANGOLARE SINISTRA`]: `LEFT ANGULAR ARTERY`,
  [`ARTERIA AORTA`]: `AORTA ARTERY`,
  [`ARTERIA AORTA ADDOMINALE`]: `ABDOMINAL AORTA ARTERY`,
  [`ARTERIA AORTA ASCENDENTE`]: `ASCENDING AORTA ARTERY`,
  [`ARTERIA AORTA DISCENDENTE`]: `DESCENDING AORTA ARTERY`,
  [`ARTERIA AORTA TORACICA`]: `THORACIC AORTA`,
  [`ARTERIA ASCELLARE DESTRA`]: `RIGHT AXILLARY ARTERY`,
  [`ARTERIA ASCELLARE SINISTRA`]: `LEFT AXILLARY ARTERY`,
  [`ARTERIA AURICOLARE POSTERIORE DESTRA`]: `RIGHT POSTERIOR AURICULAR ARTERY`,
  [`ARTERIA AURICOLARE POSTERIORE SINISTRA`]: `LEFT POSTERIOR AURICULAR ARTERY`,
  [`ARTERIA BASILARE DESTRA`]: `RIGHT BASILAR ARTERY`,
  [`ARTERIA BRACHIALE DESTRA`]: `RIGHT BRACHIAL ARTERY`,
  [`ARTERIA BRACHIALE PROFONDA DESTRA`]: `RIGHT PROFUNDA BRACHII ARTERY`,
  [`ARTERIA BRACHIALE PROFONDA SINISTRA`]: `LEFT PROFUNDA BRACHII ARTERY`,
  [`ARTERIA BRACHIALE SINISTRA`]: `LEFT BRACHIAL ARTERY`,
  [`ARTERIA CAROTIDE COMUNE DESTRA`]: `RIGHT COMMON CAROTID ARTERY`,
  [`ARTERIA CAROTIDE COMUNE SINISTRA`]: `LEFT COMMON CAROTID ARTERY`,
  [`ARTERIA CAROTIDE ESTERNA DESTRA`]: `RIGHT EXTERNAL CAROTID ARTERY`,
  [`ARTERIA CAROTIDE ESTERNA SINISTRA`]: `LEFT EXTERNAL CAROTID ARTERY`,
  [`ARTERIA CAROTIDE INTERNA DESTRA`]: `RIGHT INTERNAL CAROTID ARTERY`,
  [`ARTERIA CAROTIDE INTERNA SINISTRA`]: `LEFT INTERNAL CAROTID ARTERY`,
  [`ARTERIA CEREBRALE ANTERIORE DESTRA`]: `RIGHT ANTERIOR CEREBRAL ARTERY`,
  [`ARTERIA CEREBRALE ANTERIORE SINISTRA`]: `LEFT ANTERIOR CEREBRAL ARTERY`,
  [`ARTERIA CEREBRALE MEDIA DESTRA`]: `RIGHT MIDDLE CEREBRAL ARTERY`,
  [`ARTERIA CEREBRALE MEDIA SINISTRA`]: `LEFT MIDDLE CEREBRAL ARTERY`,
  [`ARTERIA CEREBRALE POSTERIORE DESTRA`]: `RIGHT POSTERIOR CEREBRAL ARTERY`,
  [`ARTERIA CEREBRALE POSTERIORE SINISTRA`]: `LEFT POSTERIOR CEREBRAL ARTERY`,
  [`ARTERIA CERVICALE ASCENDENTE DESTRA`]: `RIGHT ASCENDING CERVICAL ARTERY`,
  [`ARTERIA CERVICALE ASCENDENTE SINISTRA`]: `LEFT ASCENDING CERVICAL ARTERY`,
  [`ARTERIA CERVICALE PROFONDA DESTRA`]: `RIGHT DEEP CERVICAL ARTERY`,
  [`ARTERIA CERVICALE PROFONDA SINISTRA`]: `LEFT DEEP CERVICAL ARTERY`,
  [`ARTERIA CIRCONFLESSA ANTERIORE DELL'OMERO DESTRA`]: `RIGHT ANTERIOR CIRCUMFLEX HUMERAL ARTERY`,
  [`ARTERIA CIRCONFLESSA ANTERIORE DELL'OMERO SINISTRA`]: `LEFT ANTERIOR CIRCUMFLEX HUMERAL ARTERY`,
  [`ARTERIA CIRCONFLESSA ILIACA PROFONDA DESTRA`]: `RIGHT DEEP CIRCUMFLEX ILIAC ARTERY`,
  [`ARTERIA CIRCONFLESSA ILIACA PROFONDA SINISTRA`]: `LEFT DEEP CIRCUMFLEX ILIAC ARTERY`,
  [`ARTERIA CIRCONFLESSA POSTERIORE DELL'OMERO DESTRA`]: `RIGHT POSTERIOR CIRCUMFLEX HUMERAL ARTERY`,
  [`ARTERIA CIRCONFLESSA POSTERIORE DELL'OMERO SINISTRA`]: `LEFT POSTERIOR CIRCUMFLEX HUMERAL ARTERY`,
  [`ARTERIA COLICA DESTRA`]: `RIGHT COLIC ARTERY`,
  [`ARTERIA COLICA MEDIA`]: `MIDDLE COLIC ARTERY`,
  [`ARTERIA COLICA SINISTRA`]: `LEFT COLIC ARTERY`,
  [`ARTERIA COLLATERALE ULNARE INFERIORE DESTRA`]: `RIGHT INFERIOR ULNAR COLLATERAL ARTERY`,
  [`ARTERIA COLLATERALE ULNARE INFERIORE SINISTRA`]: `LEFT INFERIOR ULNAR COLLATERAL ARTERY`,
  [`ARTERIA COLLATERALE ULNARE SUPERIORE DESTRA`]: `RIGHT SUPERIOR ULNAR COLLATERAL ARTERY`,
  [`ARTERIA COLLATERALE ULNARE SUPERIORE SINISTRA`]: `LEFT SUPERIOR ULNAR COLLATERAL ARTERY`,
  [`ARTERIA COMUNICANTE ANTERIORE`]: `ANTERIOR COMMUNICATING ARTERY`,
  [`ARTERIA COMUNICANTE POSTERIORE DESTRA`]: `RIGHT POSTERIOR COMMUNICATING ARTERY`,
  [`ARTERIA COMUNICANTE POSTERIORE SINISTRA`]: `LEFT POSTERIOR COMMUNICATING ARTERY`,
  [`ARTERIA CORONARIA DESTRA`]: `RIGHT CORONARY ARTERY`,
  [`ARTERIA CORONARIA SINISTRA`]: `LEFT CORONARY ARTERY`,
  [`ARTERIA CREMASTERICA DESTRA (M)`]: `RIGHT CREMASTERIC ARTERY (M)`,
  [`ARTERIA CREMASTERICA SINISTRA (M)`]: `LEFT CREMASTERIC ARTERY (M)`,
  [`ARTERIA DEL CANALE PTERIGOIDEO DESTRO`]: `RIGHT ARTERY OF PTERYGOID CANAL`,
  [`ARTERIA DEL CANALE PTERIGOIDEO SINISTRO`]: `LEFT ARTERY OF PTERYGOID CANAL`,
  [`ARTERIA DEL LEGAMENTO ROTONDO DELL'UTERO DESTRA (F)`]: `ARTERY OF RIGHT ROUND LIGAMENT OF UTERUS (F)`,
  [`ARTERIA DEL LEGAMENTO ROTONDO DELL'UTERO SINISTRA (F)`]: `ARTERY OF LEFT ROUND LIGAMENT OF UTERUS (F)`,
  [`ARTERIA DISCENDENTE DEL GINOCCHIO DESTRA`]: `RIGHT DESCENDING GENICULAR ARTERY`,
  [`ARTERIA DISCENDENTE DEL GINOCCHIO SINISTRA`]: `LEFT DESCENDING GENICULAR ARTERY`,
  [`ARTERIA DORSALE DEL CLITORIDE DESTRA (F)`]: `RIGHT DORSAL ARTERY OF CLITORIS (F)`,
  [`ARTERIA DORSALE DEL CLITORIDE SINISTRA (F)`]: `LEFT DORSAL ARTERY OF CLITORIS (F)`,
  [`ARTERIA DORSALE DEL NASO DESTRA`]: `RIGHT DORSAL NASAL ARTERY`,
  [`ARTERIA DORSALE DEL NASO SINISTRA`]: `LEFT DORSAL NASAL ARTERY`,
  [`ARTERIA DORSALE DEL PENE DESTRA (M)`]: `RIGHT DORSAL ARTERY OF PENIS (M)`,
  [`ARTERIA DORSALE DEL PENE SINISTRA (F)`]: `LEFT DORSAL ARTERY OF PENIS (M)`,
  [`ARTERIA DORSALE DEL PIEDE DESTRA`]: `DORSAL ARTERY OF RIGHT FOOT`,
  [`ARTERIA DORSALE DEL PIEDE SINISTRA`]: `DORSAL ARTERY OF LEFT FOOT`,
  [`ARTERIA EPATICA COMUNE`]: `COMMON HEPATIC ARTERY`,
  [`ARTERIA EPATICA PROPRIA`]: `HEPATIC ARTERY PROPER`,
  [`ARTERIA EPIGASTRICA INFERIORE DESTRA`]: `RIGHT INFERIOR EPIGASTRIC ARTERY`,
  [`ARTERIA EPIGASTRICA INFERIORE SINISTRA`]: `LEFT INFERIOR EPIGASTRIC ARTERY`,
  [`ARTERIA EPIGASTRICA SUPERFICIALE DESTRA`]: `RIGHT SUPERFICIAL EPIGASTRIC ARTERY`,
  [`ARTERIA EPIGASTRICA SUPERFICIALE SINISTRA`]: `LEFT SUPERFICIAL EPIGASTRIC ARTERY`,
  [`ARTERIA ETMOIDALE ANTERIORE DESTRA`]: `RIGHT ANTERIOR ETHMOIDAL ARTERY`,
  [`ARTERIA ETMOIDALE ANTERIORE SINISTRA`]: `LEFT ANTERIOR ETHMOIDAL ARTERY`,
  [`ARTERIA ETMOIDALE POSTERIORE DESTRA`]: `RIGHT POSTERIOR ETHMOIDAL ARTERY`,
  [`ARTERIA ETMOIDALE POSTERIORE SINISTRA`]: `LEFT POSTERIOR ETHMOIDAL ARTERY`,
  [`ARTERIA FACIALE DESTRA`]: `RIGHT FACIAL ARTERY`,
  [`ARTERIA FACIALE SINISTRA`]: `LEFT FACIAL ARTERY`,
  [`ARTERIA FACIALE TRASVERSA DESTRA`]: `RIGHT TRANSVERSE FACIAL ARTERY`,
  [`ARTERIA FACIALE TRASVERSA SINISTRA`]: `LEFT TRANSVERSE FACIAL ARTERY`,
  [`ARTERIA FEMORALE CIRCONFLESSA LATERALE DESTRA`]: `RIGHT LATERAL CIRCUMFLEX FEMORAL ARTERY`,
  [`ARTERIA FEMORALE CIRCONFLESSA LATERALE SINISTRA`]: `LEFT LATERAL CIRCUMFLEX FEMORAL ARTERY`,
  [`ARTERIA FEMORALE CIRCONFLESSA MEDIALE DESTRA`]: `RIGHT MEDIAL CIRCUMFLEX FEMORAL ARTERY`,
  [`ARTERIA FEMORALE CIRCONFLESSA MEDIALE SINISTRA`]: `LEFT MEDIAL CIRCUMFLEX FEMORAL ARTERY`,
  [`ARTERIA FEMORALE DESTRA`]: `RIGHT FEMORAL ARTERY`,
  [`ARTERIA FEMORALE PROFONDA DESTRA`]: `DEEP ARTERY OF RIGHT TIGHT`,
  [`ARTERIA FEMORALE PROFONDA SINISTRA`]: `DEEP ARTERY OF LEFT TIGHT`,
  [`ARTERIA FEMORALE SINISTRA`]: `LEFT FEMORAL ARTERY`,
  [`ARTERIA FRENICA INFERIORE DESTRA`]: `RIGHT PHRENIC ARTERY`,
  [`ARTERIA FRENICA INFERIORE SINISTRA`]: `LEFT PHRENIC ARTERY`,
  [`ARTERIA GASTRICA DESTRA`]: `RIGHT GASTRIC ARTERY`,
  [`ARTERIA GASTRICA POSTERIORE`]: `POSTERIOR GASTRIC ARTERY`,
  [`ARTERIA GASTRICA SINISTRA`]: `LEFT GASTRIC ARTERY`,
  [`ARTERIA GASTRODUODENALE`]: `GASTRODUODENAL ARTERY`,
  [`ARTERIA GASTROOMENTALE DESTRA`]: `RIGHT GASTROOMENTAL ARTERY`,
  [`ARTERIA GASTROOMENTALE SINISTRA`]: `LEFT GASTROOMENTAL ARTERY`,
  [`ARTERIA GLUTEA INFERIORE DESTRA`]: `RIGHT INFERIOR GLUTEAL ARTERY`,
  [`ARTERIA GLUTEA INFERIORE SINISTRA`]: `LEFT INFERIOR GLUTEAL ARTERY`,
  [`ARTERIA GLUTEA SUPERIORE DESTRA`]: `RIGHT SUPERIOR GLUTEAL ARTERY`,
  [`ARTERIA GLUTEA SUPERIORE SINISTRA`]: `LEFT SUPERIOR GLUTEAL ARTERY`,
  [`ARTERIA ILEOCOLICA`]: `ILEOCOLIC ARTERY`,
  [`ARTERIA ILEOLOMBARE DESTRA`]: `RIGHT ILIOLUMBAR ARTERY`,
  [`ARTERIA ILEOLOMBARE SINISTRA`]: `LEFT ILIOLUMBAR ARTERY`,
  [`ARTERIA ILIACA CIRCONFELSSA SUPERFICIALE DESTRA`]: `RIGHT SUPERFICIAL CIRCUMFLEX ILIAC ARTERY`,
  [`ARTERIA ILIACA CIRCONFELSSA SUPERFICIALE SINISTRA`]: `LEFT SUPERFICIAL CIRCUMFLEX ILIAC ARTERY`,
  [`ARTERIA ILIACA COMUNE DESTRA`]: `RIGHT COMMON ILIAC ARTERY`,
  [`ARTERIA ILIACA COMUNE SINISTRA`]: `LEFT COMMON ILIAC ARTERY`,
  [`ARTERIA ILIACA ESTERNA DESTRA`]: `RIGHT EXTERNAL ILIAC ARTERY`,
  [`ARTERIA ILIACA ESTERNA SINISTRA`]: `LEFT EXTERNAL ILIAC ARTERY`,
  [`ARTERIA ILIACA INTERNA DESTRA`]: `RIGHT INTERNAL ILIAC ARTERY`,
  [`ARTERIA ILIACA INTERNA SINISTRA`]: `LEFT INTERNAL ILIAC ARTERY`,
  [`ARTERIA INFRAORBITALE DESTRA`]: `RIGHT INFRAORBITAL ARTERY`,
  [`ARTERIA INFRAORBITALE SINISTRA`]: `LEFT INFRAORBITAL ARTERY`,
  [`ARTERIA INTERCOSTALE SUPREMA DESTRA`]: `RIGHT SUPREME INTERCOSTAL ARTERY`,
  [`ARTERIA INTERCOSTALE SUPREMA SINISTRA`]: `LEFT SUPREME INTERCOSTAL ARTERY`,
  [`ARTERIA INTEROSSEA ANTERIORE DESTRA`]: `RIGHT ANTERIOR INTEROSSEOUS ARTERY`,
  [`ARTERIA INTEROSSEA ANTERIORE SINISTRA`]: `LEFT ANTERIOR INTEROSSEOUS ARTERY`,
  [`ARTERIA INTEROSSEA COMUNE DESTRA`]: `RIGHT COMMON INTEROSSEOUS ARTERY`,
  [`ARTERIA INTEROSSEA COMUNE SINISTRA`]: `LEFT COMMON INTEROSSEOUS ARTERY`,
  [`ARTERIA INTEROSSEA POSTERIORE DESTRA`]: `RIGHT POSTERIOR INTEROSSEOUS ARTERY`,
  [`ARTERIA INTEROSSEA POSTERIORE SINISTRA`]: `LEFT POSTERIOR INTEROSSEOUS ARTERY`,
  [`ARTERIA LABIALE INFERIORE DESTRA`]: `RIGHT INFERIOR LABIAL ARTERY`,
  [`ARTERIA LABIALE INFERIORE SINISTRA`]: `LEFT INFERIOR LABIAL ARTERY`,
  [`ARTERIA LABIALE SUPERIORE DESTRA`]: `RIGHT SUPFERIOR LABIAL ARTERY`,
  [`ARTERIA LABIALE SUPERIORE SINISTRA`]: `LEFT SUPFERIOR LABIAL ARTERY`,
  [`ARTERIA LACRIMALE DESTRA`]: `RIGHT LACRIMAL ARTERY`,
  [`ARTERIA LACRIMALE SINISTRA`]: `LEFT LACRIMAL ARTERY`,
  [`ARTERIA LARINGEA SUPERIORE DESTRA`]: `RIGHT SUPERIOR LARYNGEAL ARTERY`,
  [`ARTERIA LARINGEA SUPERIORE SINISTRA`]: `LEFT SUPERIOR LARYNGEAL ARTERY`,
  [`ARTERIA LINGUALE DESTRA`]: `RIGHT LINGUAL ARTERY`,
  [`ARTERIA LINGUALE SINISTRA`]: `LEFT LINGUAL ARTERY`,
  [`ARTERIA MALLEOLARE LATERALE ANTERIORE DESTRA`]: `RIGHT ANTERIOR LATERAL MALLEOLAR ARTERY`,
  [`ARTERIA MALLEOLARE LATERALE ANTERIORE SINISTRA`]: `LEFT ANTERIOR LATERAL MALLEOLAR ARTERY`,
  [`ARTERIA MALLEOLARE MEDIALE ANTERIORE DESTRA`]: `RIGHT ANTERIOR MEDIAL MALLEOLAR ARTERY`,
  [`ARTERIA MALLEOLARE MEDIALE ANTERIORE SINISTRA`]: `LEFT ANTERIOR MEDIAL MALLEOLAR ARTERY`,
  [`ARTERIA MASCELLARE DESTRA`]: `RIGHT MAXILLARY ARTERY`,
  [`ARTERIA MASCELLARE SINISTRA`]: `LEFT MAXILLARY ARTERY`,
  [`ARTERIA MASSETERICA DESTRA`]: `RIGHT MASSETERIC ARTERY`,
  [`ARTERIA MASSETERICA SINISTRA`]: `LEFT MASSETERIC ARTERY`,
  [`ARTERIA MENINGEA MEDIA DESTRA`]: `RIGHT MIDDLE MENINGEAL ARTERY`,
  [`ARTERIA MENINGEA MEDIA SINISTRA`]: `LEFT MIDDLE MENINGEAL ARTERY`,
  [`ARTERIA MESENTERICA INFERIORE`]: `RIGHT INFERIOR MESENTERIC ARTERY`,
  [`ARTERIA MESENTERICA SUPERIORE`]: `LEFT INFERIOR MESENTERIC ARTERY`,
  [`ARTERIA OCCIPITALE DESTRA`]: `RIGHT OCCIPITAL ARTERY`,
  [`ARTERIA OCCIPITALE SINISTRA`]: `LEFT OCCIPITAL ARTERY`,
  [`ARTERIA OFTALMICA DESTRA`]: `RIGHT OPHTALMIC ARTERY`,
  [`ARTERIA OFTALMICA SINISTRA`]: `LEFT OPHTALMIC ARTERY`,
  [`ARTERIA OMBELICALE DESTRA`]: `RIGHT UMBILICAL ARTERY`,
  [`ARTERIA OMBELICALE SINISTRA`]: `LEFT UMBILICAL ARTERY`,
  [`ARTERIA OTTURATORIA DESTRA`]: `RIGHT OBTURATOR ARTERY`,
  [`ARTERIA OTTURATORIA SINISTRA`]: `LEFT OBTURATOR ARTERY`,
  [`ARTERIA OVARICA DESTRA (F)`]: `RIGHT OVARIAN ARTERY (F)`,
  [`ARTERIA OVARICA SINISTRA (F)`]: `LEFT OVARIAN ARTERY (F)`,
  [`ARTERIA PALATINA ASCENDENTE DESTRA`]: `RIGHT ASCENDING PALATINE ARTERY`,
  [`ARTERIA PALATINA ASCENDENTE SINISTRA`]: `LEFT ASCENDING PALATINE ARTERY`,
  [`ARTERIA PALATINA DISCENDENTE DESTRA`]: `RIGHT DESCENDING PALATINE ARTERY`,
  [`ARTERIA PALATINA DISCENDENTE SINISTRA`]: `LEFT DESCENDING PALATINE ARTERY`,
  [`ARTERIA PANCREATICODUODENALE INFERIORE`]: `INFERIOR PANCREATICODUODENAL ARTERY`,
  [`ARTERIA PANCREATICODUODENALE SUPERIORE ANTERIORE`]: `RIGHT ANTERIOR SUPERIOR PANCREATICODUODENAL ARTERY`,
  [`ARTERIA PANCREATICODUODENALE SUPERIORE POSTERIORE`]: `LEFT ANTERIOR SUPERIOR PANCREATICODUODENAL ARTERY`,
  [`ARTERIA PERINEALE DESTRA`]: `RIGHT PERINEAL ARTERY`,
  [`ARTERIA PERINEALE SINISTRA`]: `LEFT PERINEAL ARTERY`,
  [`ARTERIA PERONEA DESTRA`]: `RIGHT FIBULAR ARTERY`,
  [`ARTERIA PERONEA SINISTRA`]: `LEFT FIBULAR ARTERY`,
  [`ARTERIA PLANTARE LATERALE DESTRA`]: `RIGHT LATERAL PLANTAR ARTERY`,
  [`ARTERIA PLANTARE LATERALE SINISTRA`]: `LEFT LATERAL PLANTAR ARTERY`,
  [`ARTERIA PLANTARE MEDIALE DESTRA`]: `RIGHT MEDIAL PLANTAR ARTERY`,
  [`ARTERIA PLANTARE MEDIALE SINISTRA`]: `LEFT MEDIAL PLANTAR ARTERY`,
  [`ARTERIA PLANTARE PROFONDA DESTRA`]: `RIGHT DEEP PLANTAR ARTERY`,
  [`ARTERIA PLANTARE PROFONDA SINISTRA`]: `LEFT DEEP PLANTAR ARTERY`,
  [`ARTERIA POLMONARE DESTRA`]: `RIGHT PULMUNARY ARTERY`,
  [`ARTERIA POLMONARE SINISTRA`]: `LEFT PULMUNARY ARTERY`,
  [`ARTERIA POPLITEA DESTRA`]: `RIGHT POPLITEAL ARTERY`,
  [`ARTERIA POPLITEA SINISTRA`]: `LEFT POPLITEAL ARTERY`,
  [`ARTERIA PRINCIPALE DEL POLLICE DESTRA`]: `RIGHT PRINCEPS POLLICIS ARTERY`,
  [`ARTERIA PRINCIPALE DEL POLLICE SINISTRA`]: `LEFT PRINCEPS POLLICIS ARTERY`,
  [`ARTERIA PUDENDA ESTERNA PROFONDA DESTRA`]: `RIGHT DEEP EXTERNAL PUDENDAL ARTERY`,
  [`ARTERIA PUDENDA ESTERNA PROFONDA SINISTRA`]: `LEFT DEEP EXTERNAL PUDENDAL ARTERY`,
  [`ARTERIA PUDENDA ESTERNA SUPERFICIALE DESTRA`]: `RIGHT SUPERFICIAL EXTERNAL PUDENDAL ARTERY`,
  [`ARTERIA PUDENDA ESTERNA SUPERFICIALE SINISTRA`]: `LEFT SUPERFICIAL EXTERNAL PUDENDAL ARTERY`,
  [`ARTERIA PUDENDA INTERNA DESTRA`]: `RIGHT INTERNAL PUDENDAL ARTERY`,
  [`ARTERIA PUDENDA INTERNA SINISTRA`]: `LEFT INTERNAL PUDENDAL ARTERY`,
  [`ARTERIA RADIALE DESTRA`]: `RIGHT RADIAL ARTERY`,
  [`ARTERIA RADIALE SINISTRA`]: `LEFT RADIAL ARTERY`,
  [`ARTERIA RENALE DESTRA`]: `RIGHT RENAL ARTERY`,
  [`ARTERIA RENALE SINISTRA`]: `LEFT RENAL ARTERY`,
  [`ARTERIA RETINICA CENTRALE DESTRA`]: `RIGHT CENTRAL RETINAL ARTERY`,
  [`ARTERIA RETINICA CENTRALE SINISTRA`]: `LEFT CENTRAL RETINAL ARTERY`,
  [`ARTERIA RETTALE INFERIORE DESTRA`]: `RIGHT INFERIOR RECTAL ARTERY`,
  [`ARTERIA RETTALE INFERIORE SINISTRA`]: `LEFT INFERIOR RECTAL ARTERY`,
  [`ARTERIA RETTALE MEDIA DESTRA`]: `RIGHT MIDDLE RECTAL ARTERY`,
  [`ARTERIA RETTALE MEDIA SINISTRA`]: `LEFT MIDDLE RECTAL ARTERY`,
  [`ARTERIA RETTALE SUPERIORE`]: `SUPERIOR RECTAL ARTERY`,
  [`ARTERIA RICORRENTE RADIALE DESTRA`]: `RIGHT RADIAL RECURRENT ARTERY`,
  [`ARTERIA RICORRENTE RADIALE SINISTRA`]: `LEFT RADIAL RECURRENT ARTERY`,
  [`ARTERIA RICORRENTE TIBIALE ANTERIORE DESTRA`]: `RIGHT ANTERIOR TIBIAL RECURRENT ARTERY`,
  [`ARTERIA RICORRENTE TIBIALE ANTERIORE SINISTRA`]: `LEFT ANTERIOR TIBIAL RECURRENT ARTERY`,
  [`ARTERIA SACRALE MEDIANA`]: `MEDIAN SACRAL ARTERY`,
  [`ARTERIA SFENOPALATINA DESTRA`]: `RIGHT SPHENOPALATINE ARTERY`,
  [`ARTERIA SFENOPALATINA SINISTRA`]: `LEFT SPHENOPALATINE ARTERY`,
  [`ARTERIA SOPRAORBITALE DESTRA`]: `RIGHT SUPRAORBITAL ARTERY`,
  [`ARTERIA SOPRAORBITALE SINISTRA`]: `LEFT SUPRAORBITAL ARTERY`,
  [`ARTERIA SOPRASCAPOLARE DESTRA`]: `RIGHT SUPRASCAPULAR ARTERY`,
  [`ARTERIA SOPRASCAPOLARE SINISTRA`]: `LEFT SUPRASCAPULAR ARTERY`,
  [`ARTERIA SOPRATROCLEARE DESTRA`]: `RIGHT SUPRATROCHLEAR ARTERY`,
  [`ARTERIA SOPRATROCLEARE SINISTRA`]: `LEFT SUPRATROCHLEAR ARTERY`,
  [`ARTERIA SOTTOSCAPOLARE DESTRA`]: `RIGHT SUBSCAPULAR ARTERY`,
  [`ARTERIA SOTTOSCAPOLARE SINISTRA`]: `LEFT SUBSCAPULAR ARTERY`,
  [`ARTERIA SPLENICA`]: `SPLENIC ARTERY`,
  [`ARTERIA STILOMASTOIDEA DESTRA`]: `RIGHT STYLOMASTOID ARTERY`,
  [`ARTERIA STILOMASTOIDEA SINISTRA`]: `LEFT STYLOMASTOID ARTERY`,
  [`ARTERIA SUBMENTALE DESTRA`]: `RIGHT SUBMENTAL ARTERY`,
  [`ARTERIA SUBMENTALE SINISTRA`]: `LEFT SUBMENTAL ARTERY`,
  [`ARTERIA SUCCLAVIA DESTRA`]: `RIGHT SUBCLAVIAN ARTERY`,
  [`ARTERIA SUCCLAVIA SINISTRA`]: `LEFT SUBCLAVIAN ARTERY`,
  [`ARTERIA SURRENALE INFERIORE DESTRA`]: `RIGHT INFERIOR SUPRARENAL ARTERY`,
  [`ARTERIA SURRENALE INFERIORE SINISTRA`]: `LEFT INFERIOR SUPRARENAL ARTERY`,
  [`ARTERIA SURRENALE MEDIA DESTRA`]: `RIGHT MIDDLE SUPRARENAL ARTERY`,
  [`ARTERIA SURRENALE MEDIA SINISTRA`]: `LEFT MIDDLE SUPRARENAL ARTERY`,
  [`ARTERIA TARSALE LATERALE DESTRA`]: `RIGHT LATERAL TARSAL ARTERY`,
  [`ARTERIA TARSALE LATERALE SINISTRA`]: `LEFT LATERAL TARSAL ARTERY`,
  [`ARTERIA TARSALE MEDIALE DESTRA`]: `RIGHT MEDIAL TARSAL ARTERY`,
  [`ARTERIA TARSALE MEDIALE SINISTRA`]: `LEFT MEDIAL TARSAL ARTERY`,
  [`ARTERIA TEMPORALE MEDIA DESTRA`]: `RIGHT MIDDLE TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE MEDIA SINISTRA`]: `LEFT MIDDLE TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE PROFONDA ANTERIORE DESTRA`]: `RIGHT ANTERIOR DEEP TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE PROFONDA ANTERIORE SINISTRA`]: `RIGHT ANTERIOR DEEP TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE PROFONDA POSTERIORE DESTRA`]: `LEFT POSTERIOR DEEP TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE PROFONDA POSTERIORE SINISTRA`]: `LEFT POSTERIOR DEEP TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE SUPERFICIALE DESTRA`]: `LEFT SUPERFICIAL TEMPORAL ARTERY`,
  [`ARTERIA TEMPORALE SUPERFICIALE SINISTRA`]: `LEFT SUPERFICIAL TEMPORAL ARTERY`,
  [`ARTERIA TESTICOLARE SINISTRA (M)`]: `LEFT TESTICULAR ARTERY (M)`,
  [`ARTERIA TIBIALE ANTERIORE DESTRA`]: `RIGHT ANTERIOR TIBIAL ARTERY`,
  [`ARTERIA TIBIALE ANTERIORE SINISTRA`]: `LEFT ANTERIOR TIBIAL ARTERY`,
  [`ARTERIA TIBIALE POSTERIORE DESTRA`]: `RIGHT POSTERIOR TIBIAL ARTERY`,
  [`ARTERIA TIBIALE POSTERIORE SINISTRA`]: `LEFT POSTERIOR TIBIAL ARTERY`,
  [`ARTERIA TIMPANICA ANTERIORE DESTRA`]: `RIGHT ANTERIOR TYMPANIC ARTERY`,
  [`ARTERIA TIMPANICA ANTERIORE SINISTRA`]: `LEFT ANTERIOR TYMPANIC ARTERY`,
  [`ARTERIA TIMPANICA POSTERIORE DESTRA`]: `RIGHT POSTERIOR TYMPANIC ARTERY`,
  [`ARTERIA TIMPANICA POSTERIORE SINISTRA`]: `LEFT POSTERIOR TYMPANIC ARTERY`,
  [`ARTERIA TIROIDEA INFERIORE DESTRA`]: `RIGHT INFERIOR THYROID ARTERY`,
  [`ARTERIA TIROIDEA INFERIORE SINISTRA`]: `LEFT INFERIOR THYROID ARTERY`,
  [`ARTERIA TIROIDEA SUPERIORE DESTRA`]: `RIGHT SUPERIOR THYROID ARTERY`,
  [`ARTERIA TIROIDEA SUPERIORE SINISTRA`]: `LEFT SUPERIOR THYROID ARTERY`,
  [`ARTERIA TORACICA INTERNA DESTRA`]: `RIGHT INTERNAL THORACIC ARTERY`,
  [`ARTERIA TORACICA INTERNA SINISTRA`]: `LEFT INTERNAL THORACIC ARTERY`,
  [`ARTERIA TORACICA LATERALE DESTRA`]: `RIGHT LATERAL THORACIC ARTERY`,
  [`ARTERIA TORACICA LATERALE SINISTRA`]: `LEFT LATERAL THORACIC ARTERY`,
  [`ARTERIA TORACICA SUPERIORE DESTRA`]: `RIGHT SUPERIOR THORACIC ARTERY`,
  [`ARTERIA TORACICA SUPERIORE SINISTRA`]: `LEFT SUPERIOR THORACIC ARTERY`,
  [`ARTERIA TORACOACROMIALE DESTRA`]: `RIGHT THORACOACROMIAL ARTERY`,
  [`ARTERIA TORACOACROMIALE SINISTRA`]: `LEFT THORACOACROMIAL ARTERY`,
  [`ARTERIA TRASVERSA DEL COLLO DESTRA`]: `RIGHT TRANSVERSE CERVICAL ARTERY`,
  [`ARTERIA TRASVERSA DEL COLLO SINISTRA`]: `LEFT TRANSVERSE CERVICAL ARTERY`,
  [`ARTERIA ULNARE DESTRA`]: `RIGHT ULNAR ARTERY`,
  [`ARTERIA ULNARE RICORRENTE DESTRA`]: `RIGHT ULNAR RECURRENT ARTERY`,
  [`ARTERIA ULNARE RICORRENTE SINISTRA`]: `LEFT ULNAR RECURRENT ARTERY`,
  [`ARTERIA ULNARE SINISTRA`]: `LEFT ULNAR ARTERY`,
  [`ARTERIA URETRALE DESTRA`]: `RIGHT URETERIC ARTERY`,
  [`ARTERIA URETRALE SINISTRA`]: `LEFT URETERIC ARTERY`,
  [`ARTERIA UTERINA DESTRA (F)`]: `RIGHT UTERINE ARTERY (F)`,
  [`ARTERIA UTERINA SINISTRA (F)`]: `LEFT UTERINE ARTERY (F)`,
  [`ARTERIA VAGINALE DESTRA (F)`]: `RIGHT VAGINAL ARTERY (F)`,
  [`ARTERIA VAGINALE SINISTRA (F)`]: `LEFT VAGINAL ARTERY (F)`,
  [`ARTERIA VERTEBRALE DESTRA`]: `RIGHT VERTEBRAL ARTERY`,
  [`ARTERIA VERTEBRALE SINISTRA`]: `LEFT VERTEBRAL ARTERY`,
  [`ARTERIA VESCICALE INFERIORE DESTRA`]: `RIGHT INFERIOR VESICAL ARTERY`,
  [`ARTERIA VESCICALE INFERIORE SINISTRA`]: `LEFT INFERIOR VESICAL ARTERY`,
  [`ARTERIA VESCICALE SUPERIORE DI DESTRA`]: `RIGHT SUPERIOR VESICAL ARTERY`,
  [`ARTERIA ZIGOMATICOORBITALE DESTRA`]: `RIGHT ZYGOMATICORBITAL ARTERY`,
  [`ARTERIA ZIGOMATICOORBITALE SINISTRA`]: `LEFT ZYGOMATICOORBITAL ARTERY`,
  [`ARTERIE BRONCHIALI DI DESTRA`]: `RIGHT BRONCHIAL ARTERIES`,
  [`ARTERIE BRONCHIALI DI SINISTRA`]: `LEFT BRONCHIAL ARTERIES`,
  [`ARTERIE CAROTICOTIMPANICHE DI DESTRA`]: `RIGHT CAROTICOTYMPANIC ARTERIES`,
  [`ARTERIE CAROTICOTIMPANICHE DI SINISTRA`]: `LEFT CAROTICOTYMPANIC ARTERIES`,
  [`ARTERIE CEREBRALI`]: `CEREBRAL ARTERIES`,
  [`ARTERIE CILIARI ANTERIORI DI DESTRA`]: `RIGHT ANTERIOR CILIARY ARTERIES`,
  [`ARTERIE CILIARI ANTERIORI DI SINISTRA`]: `LEFT ANTERIOR CILIARY ARTERIES`,
  [`ARTERIE CILIARI POSTERIORI BREVI DI DESTRA`]: `RIGHT SHORT POSTERIOR CILIARY ARTERIES`,
  [`ARTERIE CILIARI POSTERIORI BREVI DI SINISTRA`]: `LEFT SHORT POSTERIOR CILIARY ARTERIES`,
  [`ARTERIE CILIARI POSTERIORI LUNGHE DI DESTRA`]: `RIGHT LONG POSTERIOR CILIARY ARTERIES`,
  [`ARTERIE CILIARI POSTERIORI LUNGHE DI SINISTRA`]: `LEFT LONG POSTERIOR CILIARY ARTERIES`,
  [`ARTERIE CONGIUNTIVALI ANTERIORI DI DESTRA`]: `RIGHT ANTERIOR CONJUNCTIVAL ARTERIES`,
  [`ARTERIE CONGIUNTIVALI ANTERIORI DI SINISTRA`]: `LEFT ANTERIOR CONJUNCTIVAL ARTERIES`,
  [`ARTERIE CONGIUNTIVALI POSTERIORI DI DESTRA`]: `RIGHT POSTERIOR CONJUNCTIVAL ARTERIES`,
  [`ARTERIE CONGIUNTIVALI POSTERIORI DI SINISTRA`]: `LEFT POSTERIOR CONJUNCTIVAL ARTERIES`,
  [`ARTERIE DEL LOBO INFERIORE DESTRO`]: `RIGHT INFERIOR LOBAR ARTERIES`,
  [`ARTERIE DEL LOBO INFERIORE SINISTRO`]: `LEFT INFERIOR LOBAR ARTERIES`,
  [`ARTERIE DEL LOBO MEDIO DESTRO`]: `RIGHT MIDDLE LOBAR ARTERIES`,
  [`ARTERIE DEL LOBO SUPERIORE DESTRO`]: `RIGHT SUPERIOR LOBAR ARTERIES`,
  [`ARTERIE DEL LOBO SUPERIORE SINISTRO`]: `LEFT SUPERIOR LOBAR ARTERIES`,
  [`ARTERIE DIGITALI DORSALI DI DESTRA`]: `RIGHT DORSAL DIGITAL ARTERIES`,
  [`ARTERIE DIGITALI DORSALI DI SINISTRA`]: `LEFT DORSAL DIGITAL ARTERIES`,
  [`ARTERIE DIGITALI PALMARI COMUNI DI DESTRA`]: `RIGHT COMMON PALMAR DIGITAL ARTERIES`,
  [`ARTERIE DIGITALI PALMARI COMUNI DI SINISTRA`]: `RIGHT COMMON PALMAR DIGITAL ARTERIES`,
  [`ARTERIE DIGITALI PLANTARI COMUNI DI DESTRA`]: `RIGHT COMMON PLANTAR DIGITAL ARTERIES`,
  [`ARTERIE DIGITALI PLANTARI COMUNI DI SINISTRA`]: `RIGHT COMMON PLANTAR DIGITAL ARTERIES`,
  [`ARTERIE DIGIUNALI`]: `JEJUNAL ARTERIES`,
  [`ARTERIE EPISCLERALI DI DESTRA`]: `RIGHT EPISCLERAL ARTERIES`,
  [`ARTERIE EPISCLERALI DI SINISTRA`]: `LEFT EPISCLERAL ARTERIES`,
  [`ARTERIE ESOFAGEE`]: `OESOPHAGEAL ARTERIES`,
  [`ARTERIE FRENICHE SUPERIORI`]: `SUPERIOR PHRENIC ARTERIES`,
  [`ARTERIE ILEALI`]: `ILEAL ARTERIES`,
  [`ARTERIE INTERCOSTALI POSTERIORI DI DESTRA`]: `RIGHT POSTERIOR INTERCOSTAL ARTERIES`,
  [`ARTERIE INTERCOSTALI POSTERIORI DI SINISTRA`]: `LEFT POSTERIOR INTERCOSTAL ARTERIES`,
  [`ARTERIE INTRARENALI DI DESTRA`]: `RIGHT INTRARENAL ARTERIES`,
  [`ARTERIE INTRARENALI DI SINISTRA`]: `LEFT INTRARENAL ARTERIES`,
  [`ARTERIE LOMBARI DI DESTRA`]: `RIGHT LUMBAR ARTERIES`,
  [`ARTERIE LOMBARI DI SINISTRA`]: `LEFT LUMBAR ARTERIES`,
  [`ARTERIE METATARSALI DORSALI DI DESTRA`]: `RIGHT DORSAL METATARSAL ARTERIES`,
  [`ARTERIE METATARSALI DORSALI DI SINISTRA`]: `LEFT DORSAL METATARSAL ARTERIES`,
  [`ARTERIE METATARSALI PLANTARI DI DESTRA`]: `RIGHT PLANTAR METATARSAL ARTERIES`,
  [`ARTERIE METATARSALI PLANTARI DI SINISTRA`]: `LEFT PLANTAR METATARSAL ARTERIES`,
  [`ARTERIE NASALI POSTERIORI LATERALI DI DESTRA`]: `RIGHT LATERAL POSTERIOR NASAL ARTERIES`,
  [`ARTERIE NASALI POSTERIORI LATERALI DI SINISTRA`]: `LEFT LATERAL POSTERIOR NASAL ARTERIES`,
  [`ARTERIE PALATINE MINORI DI DESTRA`]: `RIGHT LESSER PALATINE ARTERIES`,
  [`ARTERIE PALATINE MINORI DI SINISTRA`]: `LEFT LESSER PALATINE ARTERIES`,
  [`ARTERIE PALPEBRALI LATERALI DI DESTRA`]: `RIGHT LATERAL PALPEBRAL ARTERIES`,
  [`ARTERIE PALPEBRALI LATERALI DI SINISTRA`]: `LEFT LATERAL PALPEBRAL ARTERIES`,
  [`ARTERIE PALPEBRALI MEDIALI DI DESTRA`]: `RIGHT MEDIAL PALPEBRAL ARTERIES`,
  [`ARTERIE PALPEBRALI MEDIALI DI SINISTRA`]: `LEFT MEDIAL PALPEBRAL ARTERIES`,
  [`ARTERIE PANCREATICHE`]: `PANCREATIC ARTERIES`,
  [`ARTERIE SACRALI LATERALI DI DESTRA`]: `RIGHT LATERAL SACRAL ARTERIES`,
  [`ARTERIE SACRALI LATERALI DI SINISTRA`]: `LEFT LATERAL SACRAL ARTERIES`,
  [`ARTERIE SIGMOIDEE`]: `SIGMOID ARTERIES`,
  [`ARTERIE SURALI DI DESTRA`]: `RIGHT SURAL ARTERIES`,
  [`ARTERIE SURALI DI SINISTRA`]: `LEFT SURAL ARTERIES`,
  [`Arthritis`]: `Osteoarthritis`,
  [`Articolare`]: `Articular`,
  [`ARTICOLAZIONE ACROMIOCLAVICOLARE DESTRA`]: `RIGHT ACROMIOCLAVICULAR JOINT`,
  [`ARTICOLAZIONE ACROMIOCLAVICOLARE SINISTRA`]: `LEFT ACROMIOCLAVICULAR JOINT`,
  [`ARTICOLAZIONE ATLANTOEPISTROFEA MEDIANA`]: `MEDIAN ATLANTOAXIAL JOINT`,
  [`ARTICOLAZIONE ATLOASSIALE LATERALE DESTRA`]: `RIGHT LATERAL ATLANTOAXIAL JOINT`,
  [`ARTICOLAZIONE ATLOASSIALE LATERALE SINISTRA`]: `RIGHT LATERAL ATLANTOAXIAL JOINT`,
  [`ARTICOLAZIONE CALCANEOCUBOIDEA DESTRA`]: `RIGHT CALCANEOCUBOID JOINT`,
  [`ARTICOLAZIONE CALCANEOCUBOIDEA SINISTRA`]: `LEFT CALCANEOCUBOID JOINT`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL I METACARPO DESTRO`]: `CARPOMETACARPAL JOINT OF RIGHT I METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL I METACARPO SINISTRO`]: `CARPOMETACARPAL JOINT OF LEFT I METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL II METCARPO DESTRO`]: `CARPOMETACARPAL JOINT OF RIGHT II METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL II METCARPO SINISTRO`]: `CARPOMETACARPAL JOINT OF LEFT II METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL III METACARPO DESTRO`]: `CARPOMETACARPAL JOINT OF RIGHT III METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL III METACARPO SINISTRO`]: `CARPOMETACARPAL JOINT OF LEFT III METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL IV METACARPO DESTRO`]: `CARPOMETACARPAL JOINT OF RIGHT IV METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL IV METACARPO SINISTRO`]: `CARPOMETACARPAL JOINT OF LEFT IV METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL V METACARPO DESTRO`]: `CARPOMETACARPAL JOINT OF RIGHT V METATARSAL`,
  [`ARTICOLAZIONE CARPOMETACARPALE DEL V METACARPO SINISTRO`]: `CARPOMETACARPAL JOINT OF LEFT V METATARSAL`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T1 A DESTRA`]: `RIGHT I COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T1 A SINISTRA`]: `LEFT I COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T10 DI DESTRA`]: `RIGHT X COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T10 DI SINISTRA`]: `LEFT X COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T11 DI DESTRA`]: `RIGHT XI COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T11 DI SINISTRA`]: `LEFT XI COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T12 DI DESTRA`]: `RIGHT XII COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T12 DI SINISTRA`]: `LEFT XII COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T2 A DESTRA`]: `RIGHT II COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T2 A SINISTRA`]: `LEFT II COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T3 A DESTRA`]: `RIGHT III COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T3 A SINISTRA`]: `LEFT III COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T4 A DESTRA`]: `RIGHT IV COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T4 A SINISTRA`]: `LEFT IV COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T5 DI DESTRA`]: `RIGHT V COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T5 DI SINISTRA`]: `LEFT V COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T6 DI DESTRA`]: `RIGHT VI COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T6 DI SINISTRA`]: `LEFT VI COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T7 DI DESTRA`]: `RIGHT VII COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T7 DI SINISTRA`]: `LEFT VII COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T8 DI DESTRA`]: `RIGHT VIII COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T8 DI SINISTRA`]: `LEFT VIII COSTOVERTEBRAL JOINT  `,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T9 DI DESTRA`]: `RIGHT IX COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE COSTOVERTEBRALE DI T9 DI SINISTRA`]: `LEFT IX COSTOVERTEBRAL JOINT`,
  [`ARTICOLAZIONE CRICOARITENOIDEA`]: `CRICOARYTENOID JOINT`,
  [`ARTICOLAZIONE CRICOTIROIDEA`]: `CRICOTHYROID JOINT`,
  [`ARTICOLAZIONE CUBOCUNEIFORME DESTRA`]: `RIGHT CUNEOCUBOID JOINT`,
  [`ARTICOLAZIONE CUBOCUNEIFORME SINISTRA`]: `LEFT CUNEOCUBOID JOINT`,
  [`ARTICOLAZIONE CUBONAVICOLARE DESTRA`]: `RIGHT CUBOIDEOONAVICULAR JOINT`,
  [`ARTICOLAZIONE CUBONAVICOLARE SINISTRA`]: `LEFT CUBOIDEONAVICULAR JOINT`,
  [`ARTICOLAZIONE CUNEONAVICOLARE DESTRA`]: `RIGHT CUNEONAVICULAR JOINT`,
  [`ARTICOLAZIONE CUNEONAVICOLARE SINISTRA`]: `LEFT CUNEONAVICULAR JOINT`,
  [`ARTICOLAZIONE DEL GINOCCHIO DESTRO`]: `RIGHT KNEE JOINT`,
  [`ARTICOLAZIONE DEL GINOCCHIO SINISTRO`]: `LEFT KNEE JOINT`,
  [`ARTICOLAZIONE DEL GOMITO DESTRO`]: `RIGHT ELBOW JOINT`,
  [`ARTICOLAZIONE DEL GOMITO SINISTRO`]: `LEFT ELBOW JOINT`,
  [`ARTICOLAZIONE DEL POLSO DESTRO`]: `RIGHT WRIST JOINT`,
  [`ARTICOLAZIONE DEL POLSO SINISTRO`]: `LEFT WRIST JOINT`,
  [`ARTICOLAZIONE DELL'ANCA DESTRA`]: `RIGHT HIP JOINT`,
  [`ARTICOLAZIONE DELL'ANCA SINISTRA`]: `LEFT HIP JOINT`,
  [`ARTICOLAZIONE DELL'OSSO PISIFORME DESTRA`]: `RIGHT PISIFORM JOINT`,
  [`ARTICOLAZIONE DELL'OSSO PISIFORME SINISTRA`]: `LEFT PISIFORM JOINT`,
  [`ARTICOLAZIONE DELLA CAVIGLIA DESTRA`]: `RIGHT ANKLE JOINT`,
  [`ARTICOLAZIONE DELLA CAVIGLIA SINISTRA`]: `LEFT ANKLE JOINT`,
  [`ARTICOLAZIONE DELLA SPALLA DESTRA`]: `RIGHT SHOULDER JOINT`,
  [`ARTICOLAZIONE DELLA SPALLA SINISTRA`]: `LEFT SHOULDER JOINT`,
  [`ARTICOLAZIONE FEMOROROTULEA DESTRA`]: `RIGHT PATELLOFEMORAL JOINT`,
  [`ARTICOLAZIONE FEMOROROTULEA SINISTRA`]: `LEFT PATELLOFEMORAL JOINT`,
  [`Articolazione interfalangea del pollice (DX)`]: `Interphalangeal joint of thumb (R)`,
  [`Articolazione interfalangea del pollice (SX)`]: `Interphalangeal joint of thumb (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DEL POLLICE DESTRO`]: `INTERPHALANGEAL JOINT OF RIGHT THUMB`,
  [`ARTICOLAZIONE INTERFALANGEA DEL POLLICE SINISTRO`]: `INTERPHALANGEAL JOINT OF LEFT THUMB`,
  [`Articolazione interfalangea dell'alluce (DX)`]: `Interphalangeal joint of hallux (R)`,
  [`Articolazione interfalangea dell'alluce (SX)`]: `Interphalangeal joint of hallux (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DELL'ALLUCE DESTRO`]: `INTERPHALANGEAL JOINT OF RIGHT HALLUX`,
  [`ARTICOLAZIONE INTERFALANGEA DELL'ALLUCE SINISTRO`]: `INTERPHALANGEAL JOINT OF LEFT HALLUX`,
  [`Articolazione interfalangea distale del II dito del piede (DX)`]: `Distal interphalangeal joint of II toe (R)`,
  [`Articolazione interfalangea distale del II dito del piede (SX)`]: `Distal interphalangeal joint of II toe (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL II DITO DEL PIEDE DESTRO`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT II TOE`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL II DITO DEL PIEDE SINISTRO`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT II TOE`,
  [`Articolazione interfalangea distale del II dito della mano (DX)`]: `Distal interphalangeal joint of index finger (R)`,
  [`Articolazione interfalangea distale del II dito della mano (SX)`]: `Distal interphalangeal joint of index finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL II DITO DELLA MANO DESTRA`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT INDEX FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL II DITO DELLA MANO SINISTRA`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT INDEX FINGER`,
  [`Articolazione interfalangea distale del III dito del piede (DX)`]: `Distal interphalangeal joint of III toe (R)`,
  [`Articolazione interfalangea distale del III dito del piede (SX)`]: `Distal interphalangeal joint of III toe (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL III DITO DEL PIEDE DESTRO`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT III TOE`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL III DITO DEL PIEDE SINISTRO`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT III TOE`,
  [`Articolazione interfalangea distale del III dito della mano (DX)`]: `Distal interphalangeal joint of middle finger (R)`,
  [`Articolazione interfalangea distale del III dito della mano (SX)`]: `Distal interphalangeal joint of middle finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL III DITO DELLA MANO DESTRA`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT MIDDLE FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL III DITO DELLA MANO SINISTRA`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT MIDDLE FINGER`,
  [`Articolazione interfalangea distale del IV dito del piede (DX)`]: `Distal interphalangeal joint of IV toe (R)`,
  [`Articolazione interfalangea distale del IV dito del piede (SX)`]: `Distal interphalangeal joint of IV toe (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DEL PIEDE DESTRO`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT IV TOE`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DEL PIEDE SINISTRO`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT IV TOE`,
  [`Articolazione interfalangea distale del IV dito della mano (DX)`]: `Distal interphalangeal joint of ring finger (R)`,
  [`Articolazione interfalangea distale del IV dito della mano (SX)`]: `Distal interphalangeal joint of ring finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO DESTRA`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT RING FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO SINISTRA`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT RING FINGER`,
  [`Articolazione interfalangea distale del V dito del piede (DX)`]: `Distal interphalangeal joint of V toe (R)`,
  [`Articolazione interfalangea distale del V dito del piede (SX)`]: `Distal interphalangeal joint of V toe (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL V DITO DEL PIEDE DESTRO`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT V TOE`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL V DITO DEL PIEDE SINISTRO`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT V TOE`,
  [`Articolazione interfalangea distale del V dito della mano (DX)`]: `Distal interphalangeal joint of little finger (R)`,
  [`Articolazione interfalangea distale del V dito della mano (SX)`]: `Distal interphalangeal joint of little finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL V DITO DELLA MANO DESTRA`]: `DISTAL INTERPHALANGEAL JOINT OF RIGHT LITTLE FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA DISTALE DEL V DITO DELLA MANO SINISTRA`]: `DISTAL INTERPHALANGEAL JOINT OF LEFT LITTLE FINGER`,
  [`Articolazione interfalangea prossimale del II dito del piede (DX)`]: `Proximal interphalangeal joint of II toe (R)`,
  [`Articolazione interfalangea prossimale del II dito del piede (SX)`]: `Proximal interphalangeal joint of II toe  (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL II DITO DEL PIEDE DESTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT II TOE`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL II DITO DEL PIEDE SINISTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT II TOE`,
  [`Articolazione interfalangea prossimale del II dito della mano (DX)`]: `Proximal interphalangeal joint of index finger (R)`,
  [`Articolazione interfalangea prossimale del II dito della mano (SX)`]: `Proximal interphalangeal joint of index finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL II DITO DELLA MANO DESTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT INDEX FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL II DITO DELLA MANO SINISTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT INDEX FINGER`,
  [`Articolazione interfalangea prossimale del III dito del piede (DX)`]: `Proximal interphalangeal joint of III toe (R)`,
  [`Articolazione interfalangea prossimale del III dito del piede (SX)`]: `Proximal interphalangeal joint of III toe  (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL III DITO DEL PIEDE DESTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT III TOE`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL III DITO DEL PIEDE SINISTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT III TOE`,
  [`Articolazione interfalangea prossimale del III dito della mano (DX)`]: `Proximal interphalangeal joint of middle finger (R)`,
  [`Articolazione interfalangea prossimale del III dito della mano (SX)`]: `Proximal interphalangeal joint of middle finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL III DITO DELLA MANO DESTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT MIDDLE FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL III DITO DELLA MANO SINISTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT MIDDLE FINGER`,
  [`Articolazione interfalangea prossimale del IV dito del piede (DX)`]: `Proximal interphalangeal joint of IV toe (R)`,
  [`Articolazione interfalangea prossimale del IV dito del piede (SX)`]: `Proximal interphalangeal joint of IV toe  (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL IV DITO DEL PIEDE DESTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT IV TOE`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL IV DITO DEL PIEDE SINISTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT IV TOE`,
  [`Articolazione interfalangea prossimale del IV dito della mano (DX)`]: `Proximal interphalangeal joint of ring finger (R)`,
  [`Articolazione interfalangea prossimale del IV dito della mano (SX)`]: `Proximal interphalangeal joint of ring finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL IV DITO DELLA MANO DESTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT RING FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL IV DITO DELLA MANO SINISTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT RING FINGER`,
  [`Articolazione interfalangea prossimale del V dito del piede (DX)`]: `Proximal interphalangeal joint of V toe (R)`,
  [`Articolazione interfalangea prossimale del V dito del piede (SX)`]: `Proximal interphalangeal joint of V toe  (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL V DITO DEL PIEDE DESTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT V TOE`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL V DITO DEL PIEDE SINISTRO`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT V TOE`,
  [`Articolazione interfalangea prossimale del V dito della mano (DX)`]: `Proximal interphalangeal joint of little finger (R)`,
  [`Articolazione interfalangea prossimale del V dito della mano (SX)`]: `Proximal interphalangeal joint of little finger (L)`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL V DITO DELLA MANO DESTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF RIGHT LITTLE FINGER`,
  [`ARTICOLAZIONE INTERFALANGEA PROSSIMALE DEL V DITO DELLA MANO SINISTRA`]: `PROXIMAL INTERPHALANGEAL JOINT OF LEFT LITTLE FINGER`,
  [`ARTICOLAZIONE LOMBOSACRALE`]: `LUMBOSACRAL JOINT`,
  [`ARTICOLAZIONE MEDIOCARPICA DESTRA`]: `RIGHT MIDCARPAL JOINT`,
  [`ARTICOLAZIONE MEDIOCARPICA SINISTRA`]: `LEFT MIDCARPAL JOINT`,
  [`articolazione metacarpofalangea del II dito della mano (DX)`]: `II metacarpophalangeal joint (R)`,
  [`Articolazione metacarpofalangea del II dito della mano (SX)`]: `II metacarpophalangeal joint (L)`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL II DITO DELLA MANO DESTRA`]: `II METACARPOPHALANGEAL JOINT OF RIGHT HAND`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL II DITO DELLA MANO SINISTRA`]: `II METACARPOPHALANGEAL JOINT OF LEFT HAND`,
  [`articolazione metacarpofalangea del III dito della mano (DX)`]: `III metacarpophalangeal joint (R)`,
  [`Articolazione metacarpofalangea del III dito della mano (SX)`]: `III metacarpophalangeal joint (L)`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL III DITO DELLA MANO DESTRA`]: `III METACARPOPHALANGEAL JOINT OF RIGHT HAND`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL III DITO DELLA MANO SINISTRA`]: `III METACARPOPHALANGEAL JOINT OF LEFT HAND`,
  [`articolazione metacarpofalangea del IV dito della mano (DX)`]: `IV metacarpophalangeal joint (R)`,
  [`Articolazione metacarpofalangea del IV dito della mano (SX)`]: `IV metacarpophalangeal joint (L)`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL IV DITO DELLA MANO DESTRA`]: `IV METACARPOPHALANGEAL JOINT OF RIGHT HAND`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL IV DITO DELLA MANO SINISTRA`]: `IV METACARPOPHALANGEAL JOINT OF LEFT HAND`,
  [`Articolazione metacarpofalangea del pollice (DX)`]: `I metacarpophalangeal joint (R)`,
  [`Articolazione metacarpofalangea del pollice (SX)`]: `I metacarpophalangeal joint (L)`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL POLLICE DESTRO`]: `METACARPOPHALANGEAL JOINT OF RIGHT THUMB`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL POLLICE SINISTRO`]: `METACARPOPHALANGEAL JOINT OF LEFT THUMB`,
  [`Articolazione metacarpofalangea del V dito della mano (DX)`]: `V metacarpophalangeal joint (R)`,
  [`Articolazione metacarpofalangea del V dito della mano (SX)`]: `V metacarpophalangeal joint (L)`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL V DITO DELLA MANO DESTRA`]: `V METACARPOPHALANGEAL JOINT OF RIGHT HAND`,
  [`ARTICOLAZIONE METACARPOFALANGEA DEL V DITO DELLA MANO SINISTRA`]: `V METACARPOPHALANGEAL JOINT OF LEFT HAND`,
  [`Articolazione metatarsofalangea del II dito del piede (DX)`]: `II metatarsophalangeal joint (R)`,
  [`Articolazione metatarsofalangea del II dito del piede (SX)`]: `II metatarsophalangeal joint (L)`,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL II DITO DEL PIEDE DESTRO`]: `II METATARSOPHALANGEAL JOINT OF RIGHT FOOT `,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL II DITO DEL PIEDE SINISTRO`]: `II METATARSOPHALANGEAL JOINT OF LEFT FOOT `,
  [`Articolazione metatarsofalangea del III dito del piede (DX)`]: `III metatarsophalangeal joint (R)`,
  [`Articolazione metatarsofalangea del III dito del piede (SX)`]: `III metatarsophalangeal joint (L)`,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL III DITO DEL PIEDE DESTRO`]: `III METATARSOPHALANGEAL JOINT OF RIGHT FOOT `,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL III DITO DEL PIEDE SINISTRO`]: `III METATARSOPHALANGEAL JOINT OF LEFT FOOT `,
  [`Articolazione metatarsofalangea del IV dito del piede (DX)`]: `IV metatarsophalangeal joint (R)`,
  [`Articolazione metatarsofalangea del IV dito del piede (SX)`]: `IV metatarsophalangeal joint (L)`,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL IV DITO DEL PIEDE DESTRO`]: `IV METATARSOPHALANGEAL JOINT OF RIGHT FOOT `,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL IV DITO DEL PIEDE SINISTRO`]: `IV METATARSOPHALANGEAL JOINT OF LEFT FOOT `,
  [`Articolazione metatarsofalangea del V dito del piede (DX)`]: `V metatarsophalangeal joint (R)`,
  [`Articolazione metatarsofalangea del V dito del piede (SX)`]: `V metatarsophalangeal joint (L)`,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL V DITO DEL PIEDE DESTRO`]: `V METATARSOPHALANGEAL JOINT OF RIGHT FOOT `,
  [`ARTICOLAZIONE METATARSOFALANGEA DEL V DITO DEL PIEDE SINISTRO`]: `V METATARSOPHALANGEAL JOINT OF LEFT FOOT `,
  [`Articolazione metatarsofalangea dell'alluce (DX)`]: `I metatarsophalangeal joint (R)`,
  [`Articolazione metatarsofalangea dell'alluce (SX)`]: `I metatarsophalangeal joint (L)`,
  [`ARTICOLAZIONE METATARSOFALANGEA DELL'ALLUCE DESTRO`]: `I METATARSOPHALANGEAL JOINT OF RIGHT FOOT`,
  [`ARTICOLAZIONE METATARSOFALANGEA DELL'ALLUCE SINISTRO`]: `I METATARSOPHALANGEAL JOINT OF LEFT FOOT`,
  [`ARTICOLAZIONE OCCIPITOATLANTOIDEA DESTRA`]: `RIGHT ATLANTOOCCIPITAL JOINT`,
  [`ARTICOLAZIONE OCCIPITOATLANTOIDEA SINISTRA`]: `LEFT ATLANTOOCCIPITAL JOINT`,
  [`ARTICOLAZIONE OMERORADIALE DESTRA`]: `RIGHT HUMERORADIAL JOINT`,
  [`ARTICOLAZIONE OMERORADIALE SINISTRA`]: `LEFT HUMERORADIAL JOINT`,
  [`ARTICOLAZIONE OMEROULNARE DESTRA`]: `RIGHT HUMEROULNAR JOINT`,
  [`ARTICOLAZIONE OMEROULNARE SINISTRA`]: `LEFT HUMEROULNAR JOINT`,
  [`ARTICOLAZIONE RADIOCARPICA DESTRA`]: `RIGHT RADIOCARPAL JOINT`,
  [`ARTICOLAZIONE RADIOCARPICA SINISTRA`]: `LEFT RADIOCARPAL JOINT`,
  [`ARTICOLAZIONE RADIOULNARE DISTALE DESTRA`]: `RIGHT DISTAL RADIOULNAR JOINT`,
  [`ARTICOLAZIONE RADIOULNARE DISTALE SINISTRA`]: `LEFT DISTAL RADIOULNAR JOINT`,
  [`ARTICOLAZIONE RADIOULNARE PROSSIMALE DESTRA`]: `RIGHT PROXIMAL RADIOULNAR JOINT`,
  [`ARTICOLAZIONE RADIOULNARE PROSSIMALE SINISTRA`]: `LEFT PROXIMAL RADIOULNAR JOINT`,
  [`ARTICOLAZIONE SACROCOCCIGEA`]: `SACROCOCCYGEAL JOINT`,
  [`Articolazione sacroiliaca (DX)`]: `Sacroiliac joint (R)`,
  [`Articolazione sacroiliaca (SX)`]: `Sacroiliac joint (L)`,
  [`ARTICOLAZIONE SACROILIACA DESTRA`]: `RIGHT SACROILIAC JOINT`,
  [`ARTICOLAZIONE SACROILIACA SINISTRA`]: `LEFT SACROILIAC JOINT`,
  [`ARTICOLAZIONE SCAPOLOTORACICA DESTRA`]: `RIGHT SCAPULOTHORACIC JOINT`,
  [`ARTICOLAZIONE SCAPOLOTORACICA SINISTRA`]: `LEFT SCAPULOTHORACIC JOINT`,
  [`ARTICOLAZIONE SOTTOASTRAGALICA DESTRA`]: `RIGHT SUBTALAR JOINT`,
  [`ARTICOLAZIONE SOTTOASTRAGALICA SINISTRA`]: `LEFT SUBTALAR JOINT`,
  [`ARTICOLAZIONE SOTTODELTOIDEA DESTRA`]: `RIGHT SUBDELTOID JOINT`,
  [`ARTICOLAZIONE SOTTODELTOIDEA SINISTRA`]: `LEFT SUBDELTOID JOINT`,
  [`ARTICOLAZIONE STERNOCLAVICOLARE DESTRA`]: `RIGHT STERNOCLAVICULAR JOINT`,
  [`ARTICOLAZIONE STERNOCLAVICOLARE SINISTRA`]: `LEFT STERNOCLAVICULAR JOINT`,
  [`ARTICOLAZIONE TALOCALCANEONAVICOLARE DESTRA`]: `RIGHT TALOCALCANEONAVICULAR JOINT`,
  [`ARTICOLAZIONE TALOCALCANEONAVICOLARE SINISTRA`]: `LEFT TALOCALCANEONAVICULAR JOINT`,
  [`Articolazione temporomandibolare (DX)`]: `Temporomandibular joint (R)`,
  [`Articolazione temporomandibolare (SX)`]: `Temporomandibular joint (L)`,
  [`ARTICOLAZIONE TEMPOROMANDIBOLARE DESTRA`]: `RIGHT TEMPOROMANDIBULAR JOINT`,
  [`ARTICOLAZIONE TEMPOROMANDIBOLARE SINISTRA`]: `LEFT TEMPOROMANDIBULAR JOINT`,
  [`Articolazione tibioperoneale distale (DX)`]: `Distal tibiofibular joint (R)`,
  [`Articolazione tibioperoneale distale (SX)`]: `Distal tibiofibular joint (L)`,
  [`ARTICOLAZIONE TIBIOPERONEALE PROSSIMALE DESTRA`]: `RIGHT SUPERIOR TIBIOFIBULAR JOINT`,
  [`ARTICOLAZIONE TIBIOPERONEALE PROSSIMALE SINISTRA`]: `LEFT SUPERIOR TIBIOFIBULAR JOINT`,
  [`ARTICOLAZIONE TIBIOTARSICA DESTRA`]: `RIGHT TIBIOTALAR JOINT`,
  [`ARTICOLAZIONE TIBIOTARSICA SINISTRA`]: `LEFT TIBIOTALAR JOINT`,
  [`ARTICOLAZIONE TRAPEZIOMETACARPALE DESTRA`]: `RIGHT TRAPEZIUMMETACARPAL JOINT`,
  [`ARTICOLAZIONE TRAPEZIOMETACARPALE SINISTRA`]: `LEFT TRAPEZIUMMETACARPAL JOINT`,
  [`ARTICOLAZIONE TRAPEZIOSCAFOIDEA DESTRA`]: `RIGHT TRAPEZIUMSCAPHOID JOINT`,
  [`ARTICOLAZIONE TRAPEZIOSCAFOIDEA SINISTRA`]: `LEFT TRAPEZIUMSCAPHOID JOINT`,
  [`ARTICOLAZIONE TRASVERSA DEL TARSO DESTRA`]: `RIGHT TRANSVERSE TARSAL JOINT`,
  [`ARTICOLAZIONE TRASVERSA DEL TARSO SINISTRA`]: `LEFT TRANSVERSE TARSAL JOINT`,
  [`ARTICOLAZIONI CARPALI DI DESTRA`]: `RIGHT INTERCARPAL JOINTS`,
  [`ARTICOLAZIONI CARPALI DI SINISTRA`]: `LEFT INTERCARPAL JOINTS`,
  [`ARTICOLAZIONI CARPOMETACARPALI DALLA II ALLA V A DESTRA`]: `RIGHT CARPOMETACARPAL JOINTS [II-V]`,
  [`ARTICOLAZIONI CARPOMETACARPALI DALLA II ALLA V A SINISTRA`]: `LEFT CARPOMETACARPAL JOINTS [II-V]`,
  [`ARTICOLAZIONI CARPOMETACARPALI DI DESTRA`]: `RIGHT CARPOMETACARPAL JOINTS  `,
  [`ARTICOLAZIONI CARPOMETACARPALI DI SINISTRA`]: `LEFT CARPOMETACARPAL JOINTS  `,
  [`ARTICOLAZIONI CONDROSTERNALI DI DESTRA`]: `RIGHT STERNOCOSTAL JOINTS`,
  [`ARTICOLAZIONI CONDROSTERNALI DI SINISTRA`]: `LEFT STERNOCOSTAL JOINTS`,
  [`ARTICOLAZIONI COSTOCONDRALI DI DESTRA`]: `RIGHT COSTOCHONDRAL JOINTS`,
  [`ARTICOLAZIONI COSTOCONDRALI DI SINISTRA`]: `LEFT COSTOCHONDRAL JOINTS`,
  [`ARTICOLAZIONI COSTOTRASVERSARIE DI DESTRA`]: `RIGHT COSTOTRANSVERSE JOINTS`,
  [`ARTICOLAZIONI COSTOTRASVERSARIE DI SINISTRA`]: `LEFT COSTOTRANSVERSE JOINTS`,
  [`ARTICOLAZIONI COSTOVERTEBRALI DI DESTRA`]: `RIGHT COSTOVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI COSTOVERTEBRALI DI SINISTRA`]: `LEFT COSTOVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERAPOFISARIE CERVICALI INFERIORI`]: `INFERIOR CERVICAL INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERAPOFISARIE CERVICALI SUPERIORI`]: `SUPERIOR CERVICAL INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERAPOFISARIE TORACICHE INFERIORI`]: `INFERIOR THORACIC INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERAPOFISARIE TORACICHE INTERMEDIE`]: `INTERMEDIATE THORACIC INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERAPOFISARIE TORACICHE SUPERIORI`]: `SUPERIOR THORACIC INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERCUNEIFORMI DI DESTRA`]: `RIGHT INTERCUNEIFORM JOINTS`,
  [`ARTICOLAZIONI INTERCUNEIFORMI DI SINISTRA`]: `LEFT INTERCUNEIFORM JOINTS`,
  [`Articolazioni interfaccettarie lombari (DX)`]: `Lumbar facet joints (R)`,
  [`Articolazioni interfaccettarie lombari (SX)`]: `Lumbar facet joints (L)`,
  [`ARTICOLAZIONI INTERFALANGEE DISTALI DEL PIEDE DALLA II ALLA V A DESTRA`]: `DISTAL INTERPHALANGEAL JOINTS OF RIGHT FOOT [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE DISTALI DEL PIEDE DALLA II ALLA V A SINISTRA`]: `DISTAL INTERPHALANGEAL JOINTS OF LEFT FOOT [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE DISTALI DELLA MANO DALLA II ALLA V A DESTRA`]: `DISTAL INTERPHALANGEAL JOINTS OF RIGHT HAND [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE DISTALI DELLA MANO DALLA II ALLA V A SINISTRA`]: `DISTAL INTERPHALANGEAL JOINTS OF LEFT HAND [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE PROSSIMALI DEL PIEDE DALLA II ALLA V A DESTRA`]: `PROXIMAL INTERPHALANGEAL JOINTS OF RIGHT FOOT [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE PROSSIMALI DEL PIEDE DALLA II ALLA V A SINISTRA`]: `PROXIMAL INTERPHALANGEAL JOINTS OF LEFT FOOT [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE PROSSIMALI DELLA MANO DALLA II ALLA V A DESTRA`]: `PROXIMAL INTERPHALANGEAL JOINTS OF RIGHT HAND [II-V]`,
  [`ARTICOLAZIONI INTERFALANGEE PROSSIMALI DELLA MANO DALLA II ALLA V A SINISTRA`]: `PROXIMAL INTERPHALANGEAL JOINTS OF LEFT HAND [II-V]`,
  [`ARTICOLAZIONI INTERMETACARPALI DI DESTRA`]: `RIGHT INTERMETACARPAL JOINTS`,
  [`ARTICOLAZIONI INTERMETACARPALI DI SINISTRA`]: `LEFT INTERMETACARPAL JOINTS`,
  [`ARTICOLAZIONI INTERMETATARSALI DI DESTRA`]: `RIGHT INTERMETATARSAL JOINTS`,
  [`ARTICOLAZIONI INTERMETATARSALI DI SINISTRA`]: `LEFT INTERMETATARSAL JOINTS`,
  [`ARTICOLAZIONI INTERVERTEBRALI CERVICALI`]: `CERVICAL INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERVERTEBRALI LOMBARI`]: `LUMBAR INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI INTERVERTEBRALI TORACICHE`]: `THORACIC INTERVERTEBRAL JOINTS`,
  [`ARTICOLAZIONI METACARPOFALANGEE DELLA MANO DALLA II ALLA V A DESTRA`]: `METACARPOPHALANGEAL JOINTS OF RIGHT HAND [II-V]`,
  [`ARTICOLAZIONI METACARPOFALANGEE DELLA MANO DALLA II ALLA V A SINISTRA`]: `METACARPOPHALANGEAL JOINTS OF LEFT HAND [II-V]`,
  [`ARTICOLAZIONI METATARSOFALANGEE DEL PIEDE DALLA II ALLA V A DESTRA`]: `METATARSOPHALANGEAL JOINTS OF RIGHT FOOT [II-V]`,
  [`ARTICOLAZIONI METATARSOFALANGEE DEL PIEDE DALLA II ALLA V A SINISTRA`]: `METATARSOPHALANGEAL JOINTS OF LEFT FOOT [II-V]`,
  [`ARTICOLAZIONI STERNOCOSTALI DI DESTRA`]: `RIGHT STERNOCOSTAL JOINTS`,
  [`ARTICOLAZIONI STERNOCOSTALI DI SINISTRA`]: `LEFT STERNOCOSTAL JOINTS`,
  [`ARTICOLAZIONI TARSOMETATARSALI DI DESTRA`]: `RIGHT TARSOMETATARSAL JOINTS`,
  [`ARTICOLAZIONI TARSOMETATARSALI DI SINISTRA`]: `LEFT TARSOMETATARSAL JOINTS`,
  [`ARTICOLAZIONI TRA GLI OSSICINI DELL'ORECCHIO DI DESTRA`]: `ARTICULATIONS OF RIGHT AUDITORY OSSICLES`,
  [`ARTICOLAZIONI TRA GLI OSSICINI DELL'ORECCHIO DI SINISTRA`]: `ARTICULATIONS OF LEFT AUDITORY OSSICLES`,
  [`Artrite`]: `Arthritis`,
  [`Artrite infiammatoria del ginocchio`]: `Inflammatory arthritis of knee`,
  [`Artrite psoriasica`]: `Psoriatic arthritis`,
  [`Artrite reattiva`]: `Reactive Arthritis`,
  [`Artrite reumatoide`]: `Rheumatoid arthritis`,
  [`Artrite Reumatoide`]: `Arthritis`,
  [`Artrite reumatoide interessante <4 articolazioni`]: `Rheumatoid arthritis affecting <4 joints`,
  [`Artrite reumatoide interessante molte articolazioni`]: `Rheumatoid arthritis affecting many joints`,
  [`Artrite sieronegativa`]: `Seronegative arthritis`,
  [`Artrite sieronegativa non specifica all'articolazione sacroiliaca`]: `Non specific seronegative arthritis affecting SIJ`,
  [`Artrite sieronegativa non specifica su meno di 4 articolazioni`]: `Non specific seronegative arthritis affecting <4 joints`,
  [`Artrite sieronegativa non specifica su più articolazioni`]: `Non specific seronegative arthritis affecting many joints`,
  [`Artrosi`]: `Osteoarthritis`,
  [`Artrosi a localizzazione non specificata`]: `Osteoarthritis location unspecified`,
  [`Artrosi al piede`]: `Foot Osteoarthritis`,
  [`Artrosi all'arto superiore`]: `Upper limb osteoarthritis`,
  [`Artrosi bi o tri-compartimentale del ginocchio`]: `Bi- or tri-compartmental osteoarthritis`,
  [`artrosi carpo-metacarpale`]: `Carpo-metacarpal osteoarthritis`,
  [`Artrosi che attraversa I confini anatomici (esclusa l'artrosi generalizzata)`]: `Osteoarthritis crossing anatomical boundaries`,
  [`artrosi del compartimento laterale del ginocchio`]: `Osteoarthritis of lateral compartment of knee`,
  [`Artrosi del compartimento laterale del ginocchio`]: `Lateral compartment osteoarthritis knee`,
  [`artrosi del compartimento mediale del ginocchio`]: `osteoarthritis of medial compartment of knee`,
  [`Artrosi del compartimento mediale del ginocchio`]: `Medial compartment osteoarthritis knee`,
  [`Artrosi del ginocchio`]: `Knee osteoarthritis`,
  [`Artrosi del gomito`]: `Elbow osteoarthritis`,
  [`Artrosi del mesopiede`]: `Arthritis of midfoot`,
  [`Artrosi del pollice`]: `Osteoarthritis of thumb`,
  [`Artrosi del polso`]: `Wrist Osteoarthritis`,
  [`Artrosi del polso (radio-carpica)`]: `Wrist (radiocarpal) osteoarthritis`,
  [`artrosi dell'anca`]: `hip osteoarthritis`,
  [`Artrosi dell'anca`]: `Hip joint osteoarthritis`,
  [`Artrosi dell'articolazione acromioclavicolare`]: `A/C joint arthritis`,
  [`Artrosi dell'articolazione inter-falangea del pollice`]: `Interphalangeal joint OA`,
  [`Artrosi dell'articolazione interfalangea distale delle dita della mano`]: `DIP joint OA fingers`,
  [`Artrosi dell'articolazione interfalangea prossimale delle dita della mano`]: `PIP joint OA fingers`,
  [`Artrosi dell'articolazione metacarpofalangea del pollice`]: `MCP joint OA`,
  [`Artrosi dell'articolazione metatarsofalangea dell'alluce`]: `Arthritis MTP joint great toe/Hallux Rigidus`,
  [`Artrosi dell'articolazione sottoastragalica`]: `Subtalar joint arthritis`,
  [`Artrosi della caviglia`]: `Ankle joint osteoarthritis`,
  [`Artrosi della colonna lombare`]: `Osteoarthritis Lumbosacral spine`,
  [`Artrosi della colonna toracica`]: `Thoracic spine Osteoarthritis`,
  [`Artrosi della prima articolazione carpometacarpale`]: `CMC joint OA`,
  [`Artrosi delle altre dita`]: `Arthritis of lesser toes`,
  [`Artrosi delle dita della mano`]: `Osteoarthritis of fingers`,
  [`Artrosi delle faccette articolari della colonna cervicale`]: `Cervical facet joint arthritis`,
  [`Artrosi delle faccette articolari della colonna lombare`]: `Facet joint OA lumbosacral spine`,
  [`Artrosi delle faccette articolari della colonna toracica`]: `Facet joint OA thoracic spine`,
  [`Artrosi di anca/inguine`]: `Hip/Groin Arthritis`,
  [`Artrosi di spalla`]: `Shoulder Osteoarthritis`,
  [`Artrosi femororotulea`]: `Patellofemoral osteoarthritis`,
  [`Artrosi gleno-omerale`]: `Glenohumeral osteoarthritis`,
  [`Artrosi non traumatica`]: `Osteoarthritis - non-traumatic`,
  [`Artrosi post-traumatica`]: `Post-traumatic osteoarthritis`,
  [`Askling's hamstring apprehension test (DX)`]: `Askling's hamstring apprehension test (R)`,
  [`Askling's hamstring apprehension test (SX)`]: `Askling's hamstring apprehension test (L)`,
  [`Asma`]: `Asthma `,
  [`Asma - allergica`]: `Asthma - allergic`,
  [`Asma - da esercizio fisico`]: `Asthma - exercise induced only`,
  [`Asma cronica`]: `Chronic airflow limitation`,
  [`Asma/allergia/febbre da fieno`]: `Allergy - rhinitis/sinusitis/hayfever`,
  [`Asma/allergia/hay fever/respiratory`]: `Asthma/allergy/hay fever/respiratory`,
  [`Assente`]: `Absent`,
  [`Assenza di lassita' capsulare dell'anca`]: `Rule out laxity of hip joint capsule`,
  [`Astenia`]: `Asthenia`,
  [`ASTENIA`]: `Asthenia`,
  [`Asterion (DX)`]: `Asterion (R)`,
  [`Asterion (SX)`]: `Asterion (L)`,
  [`ASTERION DESTRO`]: `RIGHT ASTERION`,
  [`ASTERION SINISTRO`]: `LEFT ASTERION`,
  [`Astigmatismo`]: `Astigmatism`,
  [`Astragalo (DX)`]: `Talus (R)`,
  [`Astragalo (SX)`]: `Talus (L)`,
  [`ASTRAGALO DESTRO`]: `RIGHT TALUS`,
  [`ASTRAGALO DESTRO (COLLO)`]: `RIGHT TALUS (NECK)`,
  [`ASTRAGALO DESTRO (PROCESSO POSTERIORE)`]: `RIGHT TALUS (POSTERIOR PROCESS)`,
  [`ASTRAGALO DESTRO (TESTA)`]: `RIGHT TALUS (HEAD)`,
  [`ASTRAGALO DESTRO (TROCLEA)`]: `RIGHT TALUS (TROCHLEA)`,
  [`ASTRAGALO DESTRO (TUBERCOLO LATERALE)`]: `RIGHT TALUS (LATERAL TUBERCLE)`,
  [`ASTRAGALO DESTRO (TUBERCOLO MEDIALE)`]: `RIGHT TALUS (MEDIAL TUBERCLE)`,
  [`ASTRAGALO SINISTRO`]: `LEFT TALUS`,
  [`ASTRAGALO SINISTRO (COLLO)`]: `LEFT TALUS (NECK)`,
  [`ASTRAGALO SINISTRO (PROCESSO POSTERIORE)`]: `LEFT TALUS (POSTERIOR PROCESS)`,
  [`ASTRAGALO SINISTRO (TESTA)`]: `LEFT TALUS (HEAD)`,
  [`ASTRAGALO SINISTRO (TROCLEA)`]: `LEFT TALUS (TROCHLEA)`,
  [`ASTRAGALO SINISTRO (TUBERCOLO LATERALE)`]: `LEFT TALUS (LATERAL TUBERCLE)`,
  [`ASTRAGALO SINISTRO (TUBERCOLO MEDIALE)`]: `LEFT TALUS (MEDIAL TUBERCLE)`,
  [`Aterosclerosi`]: `Atherosclerosis`,
  [`Athletic Shoulder Test (ASH) (I-test) (DX)`]: `Athletic Shoulder Test (ASH) (I-test) (R)`,
  [`Athletic Shoulder Test (ASH) (I-test) (SX)`]: `Athletic Shoulder Test (ASH) (I-test) (L)`,
  [`Athletic Shoulder Test (ASH) (T-test) (DX)`]: `Athletic Shoulder Test (ASH) (T-test) (R)`,
  [`Athletic Shoulder Test (ASH) (T-test) (SX)`]: `Athletic Shoulder Test (ASH) (T-test) (L)`,
  [`Athletic Shoulder Test (ASH) (Y-test) (DX)`]: `Athletic Shoulder Test (ASH) (Y-test) (R)`,
  [`Athletic Shoulder Test (ASH) (Y-test) (SX)`]: `Athletic Shoulder Test (ASH) (Y-test) (L)`,
  [`Atrofia del quadricipite (esclusa quella in cui e' stata fatta diagnosi clinica di dolore femororotuleo)`]: `Quadriceps wasting`,
  [`Atrofia muscolare`]: `Muscle wasting`,
  [`Atrofia muscolare della coscia`]: `Thigh muscle wasting`,
  [`Attacchi di panico`]: `Panic Attacks`,
  [`Attacchi ischemici transitori`]: `Transitory Ischemic Attack`,
  [`Atterraggio Avanti Con Elastico`]: `SINGLE LEG FORWARD LANDING WITH BAND RESISTANCE`,
  [`Atterrare da un salto`]: `Landing`,
  [`Attivazione Adduttore Leva Corta`]: `SIDE LYING BENT KNEE ADDUCTION`,
  [`Attivazione Adduttore Leva Lunga`]: `SIDE LYIING ADDUCTION`,
  [`Attività a basso impatto`]: `Low impact activities`,
  [`Attività della vita Quotidiana`]: `Activities of daily living`,
  [`Attività di cura della propria persona (vestirsi, lavarsi, etc)`]: `Personal care activities (dressing, washing)`,
  [`Attività di moderata intensità, come lavoro fisico moderato, corsa o jogging`]: `Moderate activities, like moderate physical work, running or jogging`,
  [`Attività funzionali ed attività sportive e ricreative: Le seguenti domande riguardano la funzionalità fisica nel compiere attività di impegno elevato. Risponda ad ogni domanda barrando l'apposita casella. Se la domanda non riguarda un caso da lei vis...`]: `Function, sports and recreational activities: The following questions concern your physical function when participating in higher-level activities. Answer every question by ticking the appropriate box. If a question does not pertain to you or you have not experienced it in the past week please make your “best guess” as to which response would be the most accurate. The questions should be answered considering what degree of difficulty you have experienced during the following activities in the past week due to problems with your hip and/or groin`,
  [`Attività funzionali ed attività sportive e ricreative: Le seguenti domande riguardano la funzionalità fisica nel compiere attività di impegno elevato. Risponda ad ogni domanda barrando l'apposita casella. Se la domanda non riguarda un caso da lei vissuto nella scorsa settimana, la preghiamo di scegliere la risposta che più si avvicina alla sua sensazione. Le risposte dovrebbero considerare il grado di difficoltà da lei riscontrato durante lo svolgimento delle seguenti attività, svolte nella scorsa settimana, in rapporto ai suoi problemi a livello dell'anca e/o dell'inguine.`]: `Function, sports and recreational activities: The following questions concern your physical function when participating in higher-level activities. Answer every question by ticking the appropriate box. If a question does not pertain to you or you have not experienced it in the past week please make your “best guess” as to which response would be the most accurate. The questions should be answered considering what degree of difficulty you have experienced during the following activities in the past week due to problems with your hip and/or groin`,
  [`Attività lavorativa`]: `Job`,
  [`Attività lavorative leggere o moderatamente intense (stare in piedi, camminare)`]: `Light to moderate work (standing, walking)`,
  [`Attività lavorative pesanti (spingere/tirare, trasportare)`]: `Heavy work (push/pulling, climbing, carrying)`,
  [`Attività leggere, come passeggiare, faccende domestiche o giardinaggio`]: `Light activities like walking, housework or yard work`,
  [`Attività molto vigorose, per esempio saltare o girare su un solo piede, come quando si dribbla nel basket o nel calcio`]: `Very strenuous activities like jumping or pivoting as in basketball or soccer`,
  [`Attività normale`]: `Function: Normal activities`,
  [`Attività ricreative che richiedono una certa forza o che prevedono dei colpi sul braccio, la spalla o la mano (usare il martello, tennis)`]: `Recreational activities in which you take some force or impact through your arm, shoulder or hand (eg golf, hammering, tennis, etc)`,
  [`Attività ricreative che richiedono uno sforzo modesto (giocare a carte, lavorare a maglia)`]: `Recreational activities which require little efford (eg cardplaying, knitting, etc)`,
  [`Attività ricreative e del tempo libero`]: `Recreational activities`,
  [`Attività ricreative nelle quali si fa forza o si prendono colpi sul braccio, sulla spalla o sulla mano (usare il martello, giocare a tennis o a golf, etc.)`]: `Recreational activities in which you take some force or impact through your arm, shoulder or hand (eg golf, hammering, tennis, etc)`,
  [`Attività ricreazionali che richiedono un movimento libero del braccio (lanciare una palla, giocare a ping-pong)`]: `Recreational activities in which you move your arm freely (eg playing frisbee, badminton, etc)`,
  [`Attività sportiva o ricreativa`]: `Sport/hobbies`,
  [`Attività vigorose, come lavoro fisico pesante, sci o tennis`]: `Strenuous activities like heavy physical work, skiing or tennis`,
  [`Attivita' della vita quotidiana`]: `Activity of daily living`,
  [`Attivita' di moderato impegno fisico, come spostare un tavolo, usare l'aspirapolvere, giocare a bocce o fare un giretto in bicicletta`]: `Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf`,
  [`Attivita' fisicamente impegnative, come correre, sollevare oggetti pesanti, praticare sport faticosi`]: `Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports`,
  [`Attivita' ricreative`]: `Recreational activities`,
  [`Attivita' sessuale`]: `Sexual activities`,
  [`ATTIVITA' SPORTIVE`]: `Sports Activities`,
  [`Attualmente non mi dà più problemi`]: `I don't have problems at the moment`,
  [`Aumentano`]: `Increase`,
  [`Auscultazione per crepitii dell'articolazione temporomandibolare`]: `Auscultation for TMJ crepitius`,
  [`Automobilismo`]: `Motoring`,
  [`Autonomo`]: `Autonomic`,
  [`Avambraccio`]: `Forearm`,
  [`Avambraccio (DX)`]: `Forearm (R)`,
  [`Avambraccio (SX)`]: `Forearm (L)`,
  [`AVAMBRACCIO DESTRO`]: `RIGHT FOREARM`,
  [`AVAMBRACCIO SINISTRO`]: `LEFT FOREARM`,
  [`Avampiede valgo`]: `Forefoot valgus`,
  [`Avampiede varo`]: `Forefoot varus`,
  [`avulsione del tendine del muscolo flessore profondo delle dita`]: `Avulsion of flexor digitorum profundus tendon`,
  [`avulsione della spina iliaca antero inferiore`]: `Anterior inferior iliac spine avulsion`,
  [`avulsione della spina iliaca antero superiore`]: `Anterior superior iliac spine avulsion`,
  [`avulsione della tuberosita' ischiatica`]: `ischial tuberosity avulsion`,
  [`Avulsione dentale`]: `Avulsed Tooth`,
  [`Avulsione/frattura dentale`]: `Avulsed/fractured tooth`,
  [`Avulsioni/fratture della colonna cervicale (es. rottura del processo spinoso)`]: `Avulsion fracture/s cervical spine (e.g. spinous process fracture)`,
  [`Avverte crepitii, schiocchi o altri rumori quando muove il ginocchio?`]: `Do you feel grinding, hear clicking or any other type of nois when your knee moves?`,
  [`Avverte delle improvvise fitte od un forte dolore all'anca o all'inguine?`]: `Do you experience sudden twinging/stabbing sensations in your hip and/or groin?`,
  [`Avverto molta difficolta' a concentrarmi quando lo desidero`]: `I have a lot of difficulty in concentrating when I want to `,
  [`Avverto moltissima difficolta' a concentrarmi quando lo desidero`]: `I have a great deal of difficulty in concentrating when I want to `,
  [`Avverto una difficolta' intermedia a concentrarmi quando lo desidero`]: `I have a fair degree of difficulty in concentrating when I want to `,
  [`Avvicinamento angolo costale/cresta iliaca a destra`]: `Right infrasternal angle closure`,
  [`Avvicinamento angolo costale/cresta iliaca a sinistra`]: `Left infrasternal angle closure`,
  [`Axial Pivot-Shift Test (DX)`]: `Axial Pivot-Shift Test (R)`,
  [`Axial Pivot-Shift Test (SX)`]: `Axial Pivot-Shift Test (L)`,
  [`B. Se ha dolore durante la sua attività lavorativa, ma il dolore non è tale da impedirle di portarla avanti, per quanto tempo può lavorare?`]: `B. If you have some pain while undertaking Achilles tendon loading sport, but it does not stop you from completing your trainig/practice for how long can you train/practise?`,
  [`B. Se ha dolore praticando sport, ma il dolore non e' tale da impedirle di portare a termine l'attivita' sportiva, per quanto tempo lo può praticare?`]: `B. If you have some pain while undertaking sport, but it does not stop you from completing your trainig/practice for how long can you train/practise?`,
  [`Baby Crawling Band Stage 2`]: `Baby Crawling Band Stage 2`,
  [`Baby Crawling Band Stage 3`]: `Baby Crawling Band Stage 3`,
  [`Baby Crawling Stage One`]: `Baby Crawling Stage One`,
  [`Baby Crawling Stage Three`]: `Baby Crawling Stage Three`,
  [`Back Extension`]: `Back Extension`,
  [`Backward Movement Agility Test`]: `Backward Movement Agility Test`,
  [`Backward Overhead Medicine Ball Throw Test`]: `Backward Overhead Medicine Ball Throw Test`,
  [`Balance Error Scoring System (BESS) (DX)`]: `Balance Error Scoring System (BESS) (R)`,
  [`Balance Error Scoring System (BESS) (SX)`]: `Balance Error Scoring System (BESS) (L)`,
  [`ballo`]: `dance`,
  [`Barotrauma`]: `Barotrauma`,
  [`Baseball`]: `Baeball`,
  [`BASION`]: `BASION`,
  [`Bassa`]: `Low`,
  [`Bassa difficoltà`]: `A little bit of difficulty`,
  [`Basso`]: `Low`,
  [`basso significato clinico`]: `Low clinical meaning`,
  [`Basso significato clinico per lesioni acute del legamento crociato anteriore; escludere lesioni croniche del legamento crociato anteriore`]: `Low clinical meaning for acute ACL injury; rule out chronic ACL injury`,
  [`Basso significato diagnostico`]: `Low clinical meaning`,
  [`Battente`]: `Beating`,
  [`Bear Crawl Allontano Le Gambe`]: `BEAR CRAWL TO PLANK`,
  [`Bear Crawl Allontano Le Mani`]: `BEAR CRAWL HANDS OUT`,
  [`Bear Crawl Calciata Gamba Piegata`]: `BEAR CRAWL 1 LEG KICKING`,
  [`Bear Crawl Ginocchio Gomito`]: `BEAR CRAWL KNEE TO ELBOW`,
  [`Bear Hug Test (DX)`]: `Bear Hug Test (R)`,
  [`Bear Hug Test (SX)`]: `Bear Hug Test (L)`,
  [`Beatty Test or Active Side-lying Piriformis Test (DX)`]: `Beatty Test or Active Side-lying Piriformis Test (R)`,
  [`Beatty Test or Active Side-lying Piriformis Test (SX)`]: `Beatty Test or Active Side-lying Piriformis Test (L)`,
  [`Belly Press Test (DX)`]: `Belly Press Test (R)`,
  [`Belly Press Test (SX)`]: `Belly Press Test (L)`,
  [`Bench Pres Endurance Test`]: `Bench Press Endurance Test`,
  [`Bench Press Endurance Test`]: `Bench Press Endurance Test`,
  [`Bent Knee Fall Out Test (BKFO) (DX)`]: `Bent Knee Fall Out Test (BKFO) (R)`,
  [`Bent Knee Fall Out Test (BKFO) (SX)`]: `Bent Knee Fall Out Test (BKFO) (L)`,
  [`Bent Knee Stretch Test (DX)`]: `Bent Knee Stretch Test (R)`,
  [`Bent Knee Stretch Test (SX)`]: `Bent Knee Stretch Test (L)`,
  [`Bernese Ankle Rules (DX)`]: `Bernese Ankle Rules (R)`,
  [`Bernese Ankle Rules (SX)`]: `Bernese Ankle Rules (L)`,
  [`Biceps Crease Interval (DX)`]: `Biceps Crease Interval (R)`,
  [`Biceps Crease Interval (SX)`]: `Biceps Crease Interval (L)`,
  [`Biceps Load I (DX)`]: `Biceps Load I (R)`,
  [`Biceps Load I (SX)`]: `Biceps Load I (L)`,
  [`Biceps Load II (DX)`]: `Biceps Load II (R)`,
  [`Biceps Load II (SX)`]: `Biceps Load II (L)`,
  [`Biceps Squeeze Test (DX)`]: `Biceps Squeeze Test (R)`,
  [`Biceps Squeeze Test (SX)`]: `Biceps Squeeze Test (L)`,
  [`Biceps Tendon Palpation (DX)`]: `Biceps Tendon Palpation (R)`,
  [`Biceps Tendon Palpation (SX)`]: `Biceps Tendon Palpation (L)`,
  [`Biering-Sorenson Test`]: `Biering-Sorenson Test`,
  [`Bilateral Adductor Test (DX)`]: `Bilateral Adductor Test (R)`,
  [`Bilateral Adductor Test (SX)`]: `Bilateral Adductor Test (L)`,
  [`BIRD DOG`]: `BIRD DOG `,
  [`blocchi articolari`]: `Joint lock`,
  [`Blocco/compressione`]: `Compression`,
  [`BLOCCO/COMPRESSIONE`]: `Compression/Impingement`,
  [`Bob`]: `Bob`,
  [`Bony Apprehension Test (DX)`]: `Bony Apprehension Test (R)`,
  [`Bony Apprehension Test (SX)`]: `Bony Apprehension Test (L)`,
  [`BORSA ANSERINA DESTRA`]: `RIGHT ANSERINE BURSA`,
  [`BORSA ANSERINA SINISTRA`]: `LEFT ANSERINE BURSA`,
  [`BORSA BICIPITORADIALE DESTRA`]: `RIGHT BICIPITORADIAL BURSA`,
  [`BORSA BICIPITORADIALE SINISTRA`]: `LEFT BICIPITORADIAL BURSA`,
  [`BORSA DEL MUSCOLO PIRIFORME DESTRO`]: `BURSA OF RIGHT PIRIFORMIS MUSCLE`,
  [`BORSA DEL MUSCOLO PIRIFORME SINISTRO`]: `BURSA OF LEFT PIRIFORMIS MUSCLE`,
  [`BORSA DEL MUSCOLO SEMIMEMBRANOSO DESTRO`]: `RIGHT SEMIMEMBRANOSUS BURSA`,
  [`BORSA DEL MUSCOLO SEMIMEMBRANOSO SINISTRO`]: `LEFT SEMIMEMBRANOSUS BURSA`,
  [`BORSA GLUTEA INTERMUSCOLARE DI DESTRA`]: `RIGHT INTERMUSCULAR GLUTEAL BURSAE`,
  [`BORSA GLUTEA INTERMUSCOLARE DI SINISTRA`]: `LEFT INTERMUSCULAR GLUTEAL BURSAE`,
  [`BORSA INFRAIOIDEA DESTRA`]: `RIGHT INFRAHYOID BURSA`,
  [`BORSA INFRAIOIDEA SINISTRA`]: `LEFT INFRAHYOID BURSA`,
  [`BORSA INFRAROTULEA PROFONDA DESTRA`]: `RIGHT DEEP INFRAPATELLAR BURSA`,
  [`BORSA INFRAROTULEA PROFONDA SINISTRA`]: `LEFT DEEP INFRAPATELLAR BURSA`,
  [`BORSA INFRAROTULEA SUBCUTANEA DESTRA`]: `RIGHT SUBCUTANEOUS INFRAPATELLAR BURSA`,
  [`BORSA INFRAROTULEA SUBCUTANEA SINISTRA`]: `LEFT SUBCUTANEOUS INFRAPATELLAR BURSA`,
  [`BORSA ISCHIATICA DEL MUSCOLO GRANDE GLUTEO DESTRO`]: `SCIATIC BURSA OF RIGHT GLUTEUS MAXIMUS`,
  [`BORSA ISCHIATICA DEL MUSCOLO GRANDE GLUTEO SINISTRO`]: `SCIATIC BURSA OF LEFT GLUTEUS MAXIMUS`,
  [`BORSA ISCHIATICA DEL MUSCOLO OTTURATORE INTERNO DESTRO`]: `RIGHT SCIATIC BURSA OF OBTURATOR INTERNUS`,
  [`BORSA ISCHIATICA DEL MUSCOLO OTTURATORE INTERNO SINISTRO`]: `LEFT SCIATIC BURSA OF OBTURATOR INTERNUS`,
  [`Borsa olecranica (DX)`]: `Olecranon bursa (R)`,
  [`Borsa olecranica (SX)`]: `Olecranon bursa (L)`,
  [`BORSA OLECRANICA SUBCUTANEA DESTRA`]: `RIGHT SUBCUTANEOUS OLECRANON BURSA`,
  [`BORSA OLECRANICA SUBCUTANEA SINISTRA`]: `LEFT SUBCUTANEOUS OLECRANON BURSA`,
  [`BORSA PREROTULEA SUBCUTANEA DESTRA`]: `RIGHT SUBCUTANEOUS PREPATELLAR BURSA`,
  [`BORSA PREROTULEA SUBCUTANEA SINISTRA`]: `LEFT SUBCUTANEOUS PREPATELLAR BURSA`,
  [`BORSA RETROCALCANEALE DESTRA`]: `RIGHT RETROCALCANEAL BURSA`,
  [`BORSA RETROCALCANEALE SINISTRA`]: `LEFT RETROCALCANEAL BURSA`,
  [`BORSA RETROIOIDEA DESTRA`]: `RIGHT RETROHYOID BURSA`,
  [`BORSA RETROIOIDEA SINISTRA`]: `LEFT RETROHYOID BURSA`,
  [`BORSA SOPRAROTULEA DESTRA`]: `RIGHT SUPRAPATELLAR BURSA`,
  [`BORSA SOPRAROTULEA SINISTRA`]: `LEFT SUPRAPATELLAR BURSA`,
  [`BORSA SUBACROMIALE DESTRA`]: `RIGHT SUBACROMIAL BURSA`,
  [`BORSA SUBACROMIALE SINISTRA`]: `LEFT SUBACROMIAL BURSA`,
  [`BORSA SUBCUTANEA CALCANEALE DESTRA`]: `RIGHT SUBCUTANEOUS CALCANEAL BURSA`,
  [`BORSA SUBCUTANEA CALCANEALE SINISTRA`]: `LEFT SUBCUTANEOUS CALCANEAL BURSA`,
  [`BORSA SUBCUTANEA DEL MALLEOLO LATERALE DESTRO`]: `SUBCUTANEOUS BURSA OF RIGHT LATERAL MALLEOLUS`,
  [`BORSA SUBCUTANEA DEL MALLEOLO LATERALE SINISTRO`]: `SUBCUTANEOUS BURSA OF LEFT LATERAL MALLEOLUS`,
  [`BORSA SUBCUTANEA DEL MALLEOLO MEDIALE DESTRO`]: `SUBCUTANEOUS BURSA OF RIGHT MEDIAL MALLEOLUS`,
  [`BORSA SUBCUTANEA DEL MALLEOLO MEDIALE SINISTRO`]: `SUBCUTANEOUS BURSA OF LEFT MEDIAL MALLEOLUS`,
  [`BORSA SUBCUTANEA DELLA TUBEROSITA' TIBIALE DESTRA`]: `SUBCUTANEOUS BURSA OF RIGHT TUBEROSITY OF TIBIA`,
  [`BORSA SUBCUTANEA DELLA TUBEROSITA' TIBIALE SINISTRA`]: `SUBCUTANEOUS BURSA OF LEFT TUBEROSITY OF TIBIA`,
  [`BORSA SUBCUTANEA PROMINENTE DELLA LARINGE DESTRA`]: `RIGHT SUBCUTANEOUS BURSA OF LARYNGEAL PROMINENCE`,
  [`BORSA SUBCUTANEA PROMINENTE DELLA LARINGE SINISTRA`]: `LEFT SUBCUTANEOUS BURSA OF LARYNGEAL PROMINENCE`,
  [`BORSA SUBDELTOIDEA DESTRA`]: `RIGHT SUBDELTOID BURSA`,
  [`BORSA SUBDELTOIDEA SINISTRA`]: `LEFT SUBDELTOID BURSA`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GASTROCNEMIO LATERALE DESTRO DESTRO`]: `LATERAL SUBTENDINOUS BURSA OF RIGHT GASTROCNEMIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GASTROCNEMIO LATERALE DESTRO SINISTRO`]: `LATERAL SUBTENDINOUS BURSA OF LEFT GASTROCNEMIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GASTROCNEMIO MEDIALE DESTRO DESTRO`]: `MEDIAL SUBTENDINOUS BURSA OF RIGHT GASTROCNEMIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GASTROCNEMIO MEDIALE DESTRO SINISTRO`]: `MEDIAL SUBTENDINOUS BURSA OF LEFT GASTROCNEMIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GRAN DORSALE DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT LATISSIMUS DORSI`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GRAN DORSALE SINISTRA`]: `SUBTENDINEOUS BURSA OF LEFT LATISSIMUS DORSI`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GRANDE ROTONDO DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT TERES MAJOR`,
  [`BORSA SUBTENDINEA DEL MUSCOLO GRANDE ROTONDO SINISTRA`]: `SUBTENDINEOUS BURSA OF LEFT TERES MAJOR`,
  [`BORSA SUBTENDINEA DEL MUSCOLO ILEOPSOAS DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT ILIOPSOAS MUSCLE`,
  [`BORSA SUBTENDINEA DEL MUSCOLO ILEOPSOAS SINISTRO`]: `SUBTENDINEOUS BURSA OF LEFT ILIOPSOAS MUSCLE`,
  [`BORSA SUBTENDINEA DEL MUSCOLO OTTURATORE INTERNO DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT OBTURATOR INTERNUS MUSCLE`,
  [`BORSA SUBTENDINEA DEL MUSCOLO OTTURATORE INTERNO SINISTRO`]: `SUBTENDINEOUS BURSA OF LEFT OBTURATOR INTERNUS MUSCLE`,
  [`BORSA SUBTENDINEA DEL MUSCOLO SARTORIO DESTRO`]: `SUBTENDINOUS BURSA OF RIGHT SARTORIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO SARTORIO SINISTRO`]: `SUBTENDINOUS BURSA OF LEFT SARTORIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO SOTTOSCAPOLARE DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT SUBSCAPULARIS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO SOTTOSCAPOLARE SINISTRA`]: `SUBTENDINEOUS BURSA OF LEFT SUBSCAPULARIS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO SOTTOSPINATO DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT INFRASPINATUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO SOTTOSPINATO SINISTRA`]: `SUBTENDINEOUS BURSA OF LEFT INFRASPINATUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO TRAPEZIO DESTRO`]: `SUBTENDINOUS BURSA OF RIGHT TRAPEZIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO TRAPEZIO SINISTRO`]: `SUBTENDINOUS BURSA OF LEFT TRAPEZIUS`,
  [`BORSA SUBTENDINEA DEL MUSCOLO TRICIPITE BRACHIALE DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT TRICEPS BRACHII`,
  [`BORSA SUBTENDINEA DEL MUSCOLO TRICIPITE BRACHIALE SINISTRO`]: `SUBTENDINEOUS BURSA OF LEFT TRICEPS BRACHII`,
  [`BORSA SUBTENDINEA DEL TIBIALE ANTERIORE DESTRO`]: `SUBTENDINEOUS BURSA OF RIGHT TIBIALIS ANTERIOR`,
  [`BORSA SUBTENDINEA DEL TIBIALE ANTERIORE SINISTRO`]: `TENDON OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE`,
  [`BORSA SUBTENDINEA INFERIORE DEL MUSCOLO BICIPITE FEMORALE DESTRO`]: `INFERIOR SUBTENDINOUS BURSA OF RIGHT BICEPS FEMORIS`,
  [`BORSA SUBTENDINEA INFERIORE DEL MUSCOLO BICIPITE FEMORALE SINISTRO`]: `INFERIOR SUBTENDINOUS BURSA OF LEFT BICEPS FEMORIS`,
  [`BORSA SUBTENDINEA SUPERIORE DEL MUSCOLO BICIPITE FEMORALE DESTRO`]: `RIGHT SUPERIOR BURSA OF BICEPS FEMORIS`,
  [`BORSA SUBTENDINEA SUPERIORE DEL MUSCOLO BICIPITE FEMORALE SINISTRO`]: `LEFT SUPERIOR BURSA OF BICEPS FEMORIS`,
  [`BORSA TROCANTERICA DEL GRANDE GLUTEO DESTRO`]: `TROCHANTERIC BURSA OF RIGHT GLUTEUS MAXIMUS`,
  [`BORSA TROCANTERICA DEL GRANDE GLUTEO SINISTRO`]: `TROCHANTERIC BURSA OF LEFT GLUTEUS MAXIMUS`,
  [`BORSA TROCANTERICA DEL MEDIO GLUTEO DESTRO`]: `TROCHANTERIC BURSA OF RIGHT GLUTEUS MEDIUS`,
  [`BORSA TROCANTERICA DEL MEDIO GLUTEO SINISTRO`]: `TROCHANTERIC BURSA OF LEFT GLUTEUS MEDIUS`,
  [`BORSA TROCANTERICA DEL PICCOLO GLUTEO DESTRO`]: `TROCHANTERIC BURSA OF RIGHT GLUTEUS MINIMUS`,
  [`BORSA TROCANTERICA DEL PICCOLO GLUTEO SINISTRO`]: `TROCHANTERIC BURSA OF LEFT GLUTEUS MINIMUS`,
  [`BORSA TROCANTERICA SUBCUTANEA DESTRA`]: `RIGHT SUBCUTANEOUS TROCHANTERIC BURSA`,
  [`BORSA TROCANTERICA SUBCUTANEA SINISTRA`]: `LEFT SUBCUTANEOUS TROCHANTERIC BURSA`,
  [`Borsite`]: `Bursitis`,
  [`Borsite calcaneale (pump bump)`]: `Calcaneal bursitis (pump bump)`,
  [`borsite della zampa d'oca`]: `pes anserinus bursitis`,
  [`borsite infrarotulea`]: `infrapatellar bursitis`,
  [`borsite ischioglutea`]: `ischiogluteal bursitis`,
  [`borsite olecranica`]: `Olecranon bursitis`,
  [`Borsite olecranica`]: `Elbow olecranon bursitis`,
  [`borsite prerotulea`]: `prepatellar bursitis`,
  [`Borsite prerotulea`]: `Prepatellar bursitis`,
  [`borsite radio-omerale`]: `radio-humeral borsitis`,
  [`borsite retrocalcaneale`]: `retrocalcaneal bursitis`,
  [`Borsite traumatica del ginocchio`]: `Traumatic knee bursitis`,
  [`borsite trocanterica`]: `trochanter bursitis`,
  [`Borsite trocanterica (senza tendinopatia del gluteo)`]: `Trochanteric bursitis`,
  [`Borsiti non altrimenti specificate`]: `Bursitis not otherwise specified`,
  [`Bowling`]: `Bowling`,
  [`bozzi o rigonfiamenti`]: `Bumps`,
  [`Braccio`]: `Upper arm`,
  [`BRACCIO DESTRO`]: `RIGHT ARM`,
  [`BRACCIO SINISTRO`]: `LEFT ARM`,
  [`Bradicardia`]: `Bradycardia`,
  [`BREGMA`]: `BREGMA`,
  [`Bridge Andgolo Aperto Monopodalico`]: `SINGLE LEG LONG LEVER GLUTES BRIDGE ON HEEL`,
  [`Bridge Andolo Aperto Monopodalico Iso Piedi Elevati`]: `ISOMETRIC SINGLE LEG GLUTES BRIDGE ON BOX`,
  [`Bridge Angolo Aperto Bipodalico`]: `GLUTE BRIDGE ON HEELS`,
  [`Bridge Angolo Aperto Bipodalico Iso`]: `ISOMETRIC GLUTE BRIDGE ON HEELS`,
  [`Bridge Angolo Aperto Bipodalico Iso Muro Soleo`]: `ISOMETRIC WALL CALF BRIDGE`,
  [`Bridge Angolo Aperto Bipodalico Iso Piedi Elevati`]: `ISOMETRIC GLUTES BRIDGE ON BOX`,
  [`Bridge Angolo Aperto Bipodalico Muro Soleo`]: `WALL CALF BRIDGE`,
  [`Bridge Angolo Aperto Bipodalico Piedi Elevati`]: `LONG LEVER GLUTES BRIDGE ON BOX`,
  [`Bridge Angolo Aperto Monopodalico Iso`]: `ISOMETRIC SINGLE LEG LONG LEVER GLUTES BRIDGE ON HEEL`,
  [`Bridge Angolo Aperto Monopodalico Piedi Elevati`]: `SINGLE LEG GLUTES BRIDGE ON BOX`,
  [`Bridge Bipodalico`]: `GLUTES BRIDGE`,
  [`Bridge Bipodalico Con Elastico`]: `GLUTE BRIDGE WITH BAND`,
  [`Bridge Bipodalico Con Reclutamento Adduttore Con Fitbal`]: `GLUTE BRIDGE WITH ADDUCTOR SQUEEZE WITH FITBALL`,
  [`Bridge Bipodalico Iso`]: `ISOMETRIC GLUTES BRIDGE`,
  [`Bridge Bipodalico Iso Con Elastico`]: `ISOMETRIC GLUTE BRIDGE WITH BAND`,
  [`Bridge Bipodalico Iso Con Reclutamento Adduttore Con Fitbal`]: `ISOMETRIC GLUTES BRIDGE WITH ADDUCTOR SQUEEZE WITH FITBALL`,
  [`Bridge Bipodalico Iso Lungo`]: `ISOMETRIC LONG LEVER GLUTES BRIDGE`,
  [`Bridge Bipodalico Iso Mani Al Petto`]: `ISOMETRIC GLUTES BRIDGE HAND ON CHEST`,
  [`Bridge Bipodalico Iso Piedi Elevati`]: `ISOMETRIC GLUTE BRIDGE ON HEELS ON BOX`,
  [`Bridge Bipodalico Iso Piedi Elevati Su Fitbal`]: `ISOMETRIC GLUTE BRIDGE ON FITBALL`,
  [`Bridge Bipodalico Piedi Elevati`]: `GLUTES BRIDGE ON HEELS ON BOX`,
  [`Bridge Bipodalico Piedi Elevati Soleo`]: `GLUTES BRIDGE ON BOX`,
  [`Bridge Bipodalico Piedi Elevati Su Fitbal`]: `GLUTES BRIDGE ON FITBALL`,
  [`Bridge Bipodalico Piedi Elevati Su Fitbal + Eccentrica`]: `ECCENTRIC HAMSTRING CURL WITH FITBALL`,
  [`Bridge Bipodalico Piedi Elevati Su Fitbal + Eccentrica Concentrica`]: `GLUTES BRIDGE HAMSTRING CURL WITH FITBAL`,
  [`Bridge Bipodalico Piedi Elevati Su Fitbal + Eccentrica Monopodalica`]: `GLUTES BRIDGE SINGLE LEG ECCENTRIC HAMSTRING CURL WITH FITBAL`,
  [`Bridge Con Shift Laterale`]: `GLUTE BRIDGE WITH LATERAL SHIFT`,
  [`Bridge Con Shift Laterale Leva Lunga`]: `GLUTE BRIDGE WITH LATERAL SHIFT - LONG LEVER`,
  [`Bridge Gambe Tese Bipodalico Con Fitbal`]: `GLUTES BRIDGE STRAIGHT LEG WITH FITBALL`,
  [`Bridge Gambe Tese Bipodalico Con Fitbal + Concentrico Bipo + Eccentrica Mono`]: `HAMSTRING CURL WITH SINGL LEG ECCENTRIC CURL WITH FITBALL`,
  [`Bridge Gambe Tese Bipodalico Iso Con Fitbal`]: `ISOMETRIC GLUTES BRIDGE STRAIGHT LEG WITH FITBALL`,
  [`Bridge Gambe Tese Elevate Bipodalico`]: `GLUTES BRIDGE STRAIGHT LEG ON BOX`,
  [`Bridge Gambe Tese Elevate Iso Bipodalico`]: `ISOMETRIC GLUTES BRIDGE STRAIGHT LEG ON BOX`,
  [`Bridge Gambe Tese Elevate Iso Monopodalico`]: `ISOMETRIC SINGLE LEG GLUTE BRIDGE STRAIGHT LEG ON BOX`,
  [`Bridge Gambe Tese Elevate Monopodalico`]: `SINGLE LEG GLUTE BRIDGE STRAIGHT LEG ON BOX`,
  [`Bridge Iso Con Elastico`]: `ISOMETRIC BAND GLUTES BRIDGE`,
  [`Bridge Iso Con Shift Laterale Leva Lunga`]: `ISOMETRIC LONG LEVER BAND GLUTES BRIDGE (LATERAL ATTACHED)`,
  [`Bridge Iso Monopodalico Con Shift Laterale Leva Lunga`]: `ISOMETRIC SINGLE LEG GLUTE BRIDGE LONG LEVER LATERAL SHIFT`,
  [`Bridge Lungo Bipodalico`]: `LONG LEVER GLUTES BRIDGE`,
  [`Bridge Monopdalico Piedi Elevati`]: `SINGLE LEG GLUTE BRIDGE ON HEEL ON BOX`,
  [`Bridge Monopodalico`]: `SINGLE LEG GLUTE BRIDGE`,
  [`Bridge Monopodalico Con Attivazione Adduttore Con Elastico`]: `SINGLE GLUTE BRIDGE WITH BANDED ADDUCTOR ACTION`,
  [`Bridge Monopodalico Con Shift Laterale`]: `SINGLE LEG BAND GLUTES BRIDGE (LATERAL ATTACHED)`,
  [`Bridge Monopodalico Iso`]: `ISOMETRIC SINGLE LEG GLUTE BRIDGE`,
  [`Bridge Monopodalico Iso Controllo Eccentrico Adduttore`]: `ISOMETRIG GLUTE BRIDGE WITH BENDED LEG ABDUCTION`,
  [`Bridge Monopodalico Iso Controllo Eccentrico Adduttore Leva Lunga`]: `ISOMETRIG GLUTE BRIDGE WITH STRAIGHT LEG ABDUCTION`,
  [`Bridge Monopodalico Iso Piedi Elevati`]: `ISOMETRIC SINGLE LEG GLUTE BRIDGE ON HEEL ON BOX`,
  [`Bridge Monopodalico Piedi Elevati Soleo`]: `SINGLE LEG GLUTE BRIDGE ON BOX`,
  [`Bridge Monopodalico Su Fitbal + Eccentrica Concentrica`]: `SINGLE LEG HAMSTRING CURL WITH FITBALL`,
  [`Bridge Monopodalico Su Fitbal + Eccentrica Mono`]: `SINGLE LEG GLUTE BRIDGE WITH ECCENTRIC SINLGE LEG CURL WITH FITBALL`,
  [`BRONCHI`]: `BRONCHI`,
  [`BRONCHI (BRONCO PRINCIPALE DESTRO)`]: `BRONCHI (RIGHT MAIN BRONCHUS)`,
  [`BRONCHI (BRONCO PRINCIPALE SINISTRO)`]: `BRONCHI (LEFT MAIN BRONCHUS)`,
  [`BRONCHI (LOBO BRONCHIALE INFERIORE DESTRO)`]: `BRONCHI (RIGHT INFERIOR LOBAR BRONCHUS) `,
  [`BRONCHI (LOBO BRONCHIALE INFERIORE SINISTRO)`]: `BRONCHI (LEFT INFERIOR LOBAR BRONCHUS) `,
  [`BRONCHI (LOBO BRONCHIALE MEDIO)`]: `BRONCHI (MIDDLE LOBAR BRONCHUS) `,
  [`BRONCHI (LOBO BRONCHIALE SUPERIORE DESTRO)`]: `BRONCHI (RIGHT SUPERIOR LOBAR BRONCHUS) `,
  [`BRONCHI (LOBO BRONCHIALE SUPERIORE SINISTRO)`]: `BRONCHI (LEFT SUPERIOR LOBAR BRONCHUS) `,
  [`Bronchiectasia`]: `Bronchiectasis`,
  [`Bronchite`]: `Bronchitis`,
  [`Bronchite cronica o enfisema`]: `Chronic bronchitis`,
  [`Bronchite cronica ostruttiva`]: `Obstructive chronic bronchitis`,
  [`Bruciante`]: `Searing`,
  [`Brusco`]: `Abrupt`,
  [`Bulimia nervosa`]: `Bulimia nervosa`,
  [`Buona`]: `Good`,
  [`Buono`]: `Good`,
  [`C. Se ha dolore durante la sua attività, tale da impedirle di portarla avanti, per quanto tempo può lavorare?`]: `C. If you have pain that stops you from completing your training/practice in Achilles tendon loading sport, for how long can you train/practise?`,
  [`C. Se ha dolore praticando sport tale da impedirle di portare a termine l'attivita', per quanto tempo lo può praticare?`]: `C. If you have pain which stops you from completing your training/practice for how long can you train/practise?`,
  [`C. Se ha dolore praticando sport tale da impedirle di portare a termine l'attivita', per quanto tempo lo può praticare?`]: `C. If you have pain which stops you from completing your training/practice for how long can you train/practise?`,
  [`C1`]: `C1`,
  [`C1 (ARCO POSTERIORE)`]: `C1 (POSTERIOR ARCH)`,
  [`C1 (MASSA LATERALE DESTRA)`]: `C1 (RIGHT LATERAL MASS)`,
  [`C1 (MASSA LATERALE SINISTRA)`]: `C1 (LEFT LATERAL MASS)`,
  [`C2`]: `C2`,
  [`C2 (DENTE DELL'EPISTROFEO)`]: `C2 (DENS)`,
  [`C2 (PROCESSO SPINOSO)`]: `C2 (SPINOUS PROCESS)`,
  [`C2 (PROCESSO TRASVERSO DESTRO)`]: `C2 (RIGHT TRANSVERSE PROCESS)`,
  [`C2 (PROCESSO TRASVERSO SINISTRO)`]: `C2 (LEFT TRANSVERSE PROCESS)`,
  [`C3`]: `C3`,
  [`C3 (PROCESSO SPINOSO)`]: `C3 (SPINOUS PROCESS)`,
  [`C3 (PROCESSO TRASVERSO DESTRO)`]: `C3 (RIGHT TRANSVERSE PROCESS)`,
  [`C3 (PROCESSO TRASVERSO SINISTRO)`]: `C3 (LEFT TRANSVERSE PROCESS)`,
  [`C4`]: `C4`,
  [`C4 (PROCESSO SPINOSO)`]: `C4 (SPINOUS PROCESS)`,
  [`C4 (PROCESSO TRASVERSO DESTRO)`]: `C4 (RIGHT TRANSVERSE PROCESS)`,
  [`C4 (PROCESSO TRASVERSO SINISTRO)`]: `C4 (LEFT TRANSVERSE PROCESS)`,
  [`C5`]: `C5`,
  [`C5 (PROCESSO SPINOSO)`]: `C5 (SPINOUS PROCESS)`,
  [`C5 (PROCESSO TRASVERSO DESTRO)`]: `C5 (RIGHT TRANSVERSE PROCESS)`,
  [`C5 (PROCESSO TRASVERSO SINISTRO)`]: `C5 (LEFT TRANSVERSE PROCESS)`,
  [`C6`]: `C6`,
  [`C6 (PROCESSO SPINOSO)`]: `C6 (SPINOUS PROCESS)`,
  [`C6 (PROCESSO TRASVERSO DESTRO)`]: `C6 (RIGHT TRANSVERSE PROCESS)`,
  [`C6 (PROCESSO TRASVERSO SINISTRO)`]: `C6 (LEFT TRANSVERSE PROCESS)`,
  [`C7`]: `C7`,
  [`C7 (PROCESSO SPINOSO)`]: `C7 (SPINOUS PROCESS)`,
  [`C7 (PROCESSO TRASVERSO DESTRO)`]: `C7 (RIGHT TRANSVERSE PROCESS)`,
  [`C7 (PROCESSO TRASVERSO SINISTRO)`]: `C7 (LEFT TRANSVERSE PROCESS)`,
  [`Cadenza al minuto`]: `Frame rate per minute`,
  [`caduta`]: `Fall`,
  [`Calcagno (DX)`]: `Calcaneus (R)`,
  [`Calcagno (SX)`]: `Calcaneus (L)`,
  [`CALCAGNO DESTRO`]: `RIGHT CALCANEUS `,
  [`CALCAGNO DESTRO (SUSTENTACULUM TALI)`]: `RIGHT CALCANEUS (SUSTENTACULUM TALI)`,
  [`CALCAGNO DESTRO (TROCLEA PERONEALE)`]: `RIGHT CALCANEUS (PERONEAL TROCHLEA)`,
  [`CALCAGNO DESTRO (TUBEROSITA' CALCANEALE)`]: `RIGHT CALCANEUS (CALCANEAL TUBEROSITY)`,
  [`CALCAGNO SINISTRO`]: `LEFT CALCANEUS `,
  [`CALCAGNO SINISTRO (SUSTENTACULUM TALI)`]: `LEFT CALCANEUS (SUSTENTACULUM TALI)`,
  [`CALCAGNO SINISTRO (TROCLEA PERONEALE)`]: `LEFT CALCANEUS (PERONEAL TROCHLEA)`,
  [`CALCAGNO SINISTRO (TUBEROSITA' CALCANEALE)`]: `LEFT CALCANEUS (CALCANEAL TUBEROSITY)`,
  [`Calcio a 5`]: `Five a side football`,
  [`Calcoli o condizioni relative ai reni`]: `Kidney disease/stones`,
  [`Calcolosi renale`]: `Kidney stones`,
  [`Calcolosi uretrale`]: `Uretral stones`,
  [`Caldo`]: `Hot`,
  [`Callo (ipercheratosi) al polso/mano (comprese le dita e il pollice)`]: `Callous of Wrist/hand (incl fingers/thumb)`,
  [`Callo al piede`]: `Callous on foot`,
  [`Calmo e sereno`]: `Have you felt calm and peaceful?`,
  [`calore`]: `Heat`,
  [`Calorie`]: `Calories`,
  [`Calpestante`]: `Pounding`,
  [`Cambiamenti di tempo`]: `weather changes`,
  [`cambiamenti nelle funzioni intestinali o urinarie`]: `Changes in urinary or intestinal functions`,
  [`Cambiamenti nelle funzioni intestinali o vescicali`]: `Changes in bowel or bladder`,
  [`Cambiamento della trama tessutale`]: `Tissue Texture Change`,
  [`Cambiare una lampadina sul soffitto`]: `Change a lightbulb overhead`,
  [`Camminando su una superficie irregolare`]: `Walking on an uneven surface`,
  [`Camminando su una superficie rigida (asfalto, cemento, ecc.)`]: `Walking on a hard surfaice (asphalt, concrete, etc.)`,
  [`Camminare da una stanza all'altra`]: `Walking between rooms`,
  [`Camminare in discesa`]: `Walking down hills`,
  [`Camminare in salita`]: `Walking up hills`,
  [`Camminare per 1 Km`]: `Walking a mile`,
  [`Camminare per 10 minuti`]: `Walking 2 blocks`,
  [`Camminare per 5 minuti o meno`]: `Walking 5 minutes or less`,
  [`Camminare per circa 10 minuti`]: `Walking approximately 10 minutes`,
  [`Camminare per circa cento metri`]: `Walking one block`,
  [`Camminare per più di 15 minuti`]: `Walking 15 minutes or greater`,
  [`Camminare per qualche centinaia di metri`]: `Walking several blocks`,
  [`Camminare per un chilometro`]: `Walking more than a mile`,
  [`Camminare scalzo su una superficie piana`]: `Walking on even ground without shoes`,
  [`Camminare su superfici piace`]: `Walking on flat surface`,
  [`Camminare su superfici piane`]: `Walking on flat surface`,
  [`Camminare su un terreno sconnesso`]: `Walking on uneven ground`,
  [`Camminare su una superficie irregolare`]: `Walking on an uneven surface`,
  [`Camminare su una superficie piana`]: `Walking on even ground`,
  [`Camminare su una superficie rigida (asfalto, cemento, ecc.)`]: `Walking on a hard surface (asphalt, concrete, etc.)`,
  [`camminata su terreno scosceso`]: `walking on uneven surface`,
  [`Cancro`]: `Cancer`,
  [`Cancro al seno`]: `Breast cancer`,
  [`Cancro alla pelle`]: `Skin cancer`,
  [`Cancro dei reni`]: `Kidney Cancer`,
  [`Cancro del fegato`]: `Liver Cancer`,
  [`Cancro della cistifellea`]: `Gallbladder Cancer`,
  [`Cancro della prostata`]: `Prostate Cancer`,
  [`Cancro della vescica`]: `Bladder Cancer`,
  [`Cancro testiolare`]: `Testicles Cancer`,
  [`Canoa`]: `Canoe`,
  [`Canottaggio`]: `Boating`,
  [`Capitato (DX)`]: `Capitate (R)`,
  [`Capitato (SX)`]: `Capitate (L)`,
  [`CAPITATO DESTRO`]: `RIGHT CAPITATE`,
  [`CAPITATO SINISTRO`]: `LEFT CAPITATE`,
  [`CAPSULA ARTICOLARE ACROMIOCLAVICOLARE DESTRA`]: `RIGHT ACROMIOCLAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE ACROMIOCLAVICOLARE SINISTRA`]: `LEFT ACROMIOCLAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE ATLOASSIALE LATERALE DESTRA`]: `RIGHT LATERAL ATLANTOAXIAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE ATLOASSIALE LATERALE SINISTRA`]: `LEFT LATERAL ATLANTOAXIAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CALCANEOCUBOIDEA DESTRA`]: `RIGHT CALCANEOCUBOID JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CALCANEOCUBOIDEA SINISTRA`]: `LEFT CALCANEOCUBOID JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CUBOCUNEIFORME DESTRA`]: `RIGHT CUNEOCUBOID JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CUBOCUNEIFORME SINISTRA`]: `LEFT CUNEOCUBOID JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CUBONAVICOLARE DESTRA`]: `RIGHT CUBOIDEONAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CUBONAVICOLARE SINISTRA`]: `LEFT CUBOIDEONAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CUNEONAVICOLARE DESTRA`]: `RIGHT CUNEONAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE CUNEONAVICOLARE SINISTRA`]: `LEFT CUNEONAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE DEL GINOCCHIO DESTRO`]: `JOINT CAPSULE OF RIGHT KNEE`,
  [`CAPSULA ARTICOLARE DEL GINOCCHIO SINISTRO`]: `JOINT CAPSULE OF LEFT KNEE`,
  [`CAPSULA ARTICOLARE DEL GOMITO DESTRO`]: `JOINT CAPSULE OF RIGHT ELBOW`,
  [`CAPSULA ARTICOLARE DEL GOMITO SINISTRO`]: `JOINT CAPSULE OF LEFT ELBOW`,
  [`CAPSULA ARTICOLARE DEL POLSO DESTRO`]: `RIGHT WRIST JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE DEL POLSO SINISTRO`]: `LEFT WRIST JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE DELL'ANCA DESTRA`]: `JOINT CAPSULE OF RIGHT HIP `,
  [`CAPSULA ARTICOLARE DELL'ANCA SINISTRA`]: `JOINT CAPSULE OF LEFT HIP `,
  [`CAPSULA ARTICOLARE DELLA CAVIGLIA DESTRA`]: `JOINT CAPSULE OF RIGHT ANKLE`,
  [`CAPSULA ARTICOLARE DELLA CAVIGLIA SINISTRA`]: `JOINT CAPSULE OF LEFT ANKLE`,
  [`CAPSULA ARTICOLARE DELLA SINFISI PUBICA`]: `PUBIC SYMPHYSIS JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE DELLA SPALLA DESTRA`]: `JOINT CAPSULE OF RIGHT SHOULDER`,
  [`CAPSULA ARTICOLARE DELLA SPALLA SINISTRA`]: `LEFT CAPSULE OF RIGHT SHOULDER`,
  [`CAPSULA ARTICOLARE I CARPOMETACARPALE DESTRA`]: `RIGHT I CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE I CARPOMETACARPALE SINISTRA`]: `LEFT I CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE I COSTOTRASVERSARIA DESTRA`]: `RIGHT I COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE I COSTOTRASVERSARIA SINISTRA`]: `LEFT I COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE I COSTOVERTEBRALE DESTRA`]: `RIGHT I COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE I COSTOVERTEBRALE SINISTRA`]: `LEFT I COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE II CARPOMETACARPALE DESTRA`]: `RIGHT II CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE II CARPOMETACARPALE SINISTRA`]: `LEFT II CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE II COSTOTRASVERSARIA DESTRA`]: `RIGHT II COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE II COSTOTRASVERSARIA SINISTRA`]: `LEFT II COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE II COSTOVERTEBRALE DESTRA`]: `RIGHT II COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE II COSTOVERTEBRALE SINISTRA`]: `LEFT II COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE III CARPOMETACARPALE DESTRA`]: `RIGHT III CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE III CARPOMETACARPALE SINISTRA`]: `LEFT III CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE III COSTOTRASVERSARIA DESTRA`]: `RIGHT II COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE III COSTOTRASVERSARIA SINISTRA`]: `LEFT II COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE III COSTOVERTEBRALE DESTRA`]: `RIGHT II COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE III COSTOVERTEBRALE SINISTRA`]: `LEFT II COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C2-C3 DESTRA`]: `JOINT CAPSULE OF RIGHT C2-C3 INTERVERTEBRAL JOINT`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C2-C3 SINISTRA`]: `JOINT CAPSULE OF LEFT C2-C3 INTERVERTEBRAL JOINT`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C3-C4 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE C3-C4`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C3-C4 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE C3-C4`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C4-C5 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE C4-C5`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C4-C5 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE C4-C5`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C5-C6 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE C5-C6`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C5-C6 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE C5-C6`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C6-C7 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE C6-C7`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C6-C7 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE C6-C7`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C7-D1 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE C7-D1`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA C7-D1 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE C7-D1`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D1-D2 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D1-D2`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D1-D2 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D1-D2`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D10-D11 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D10-D11`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D10-D11 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D10-D11`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D11-D12 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D11-D12`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D11-D12 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D11-D12`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D12-L1 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D12-L1`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D12-L1 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D12-L1`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D2-D3 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D2-D3`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D2-D3 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D2-D3`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D3-D4 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D3-D4`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D3-D4 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D3-D4`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D4-D5 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D4-D5`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D4-D5 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D4-D5`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D5-D6 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D5-D6`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D5-D6 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D5-D6`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D6-D7 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D6-D7`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D6-D7 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D6-D7`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D7-D8 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D7-D8`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D7-D8 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D7-D8`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D8-D9 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D8-D9`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D8-D9 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D8-D9`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D9-D10 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE D9-D10`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA D9-D10 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE D9-D10`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L1-L2 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE L1-L2`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L1-L2 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE L1-L2`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L2-L3 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE L2-L3`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L2-L3 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE L2-L3`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L3-L4 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE L3-L4`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L3-L4 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE L3-L4`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L4-L5 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE L4-L5`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L4-L5 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE L4-L5`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L5-S1 DESTRA`]: `RIGHT INTERVERTEBRAL JOINT CAPSULE L5-S1`,
  [`CAPSULA ARTICOLARE INTERAPOFISARIA L5-S1 SINISTRA`]: `LEFT INTERVERTEBRAL JOINT CAPSULE L5-S1`,
  [`CAPSULA ARTICOLARE INTERCUNEIFORME LATERALE DESTRA`]: `RIGHT LATERAL INTERCUNEIFORM JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERCUNEIFORME LATERALE SINISTRA`]: `LEFT LATERAL INTERCUNEIFORM JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERCUNEIFORME MEDIALE DESTRA`]: `RIGHT MEDIAL INTERCUNEIFORM JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERCUNEIFORME MEDIALE SINISTRA`]: `LEFT MEDIAL INTERCUNEIFORM JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DEL POLLICE DESTRO`]: `JOINT CAPSULE OF INTERPHALANGEAL JOINT OF RIGHT THUMB`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DEL POLLICE SINISTRO`]: `JOINT CAPSULE OF INTERPHALANGEAL JOINT OF LEFT THUMB`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DELL'ALLUCE DESTRO`]: `JOINT CAPSULE OF INTERPHALANGEAL JOINT OF RIGHT HALLUX`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DELL'ALLUCE SINISTRO`]: `JOINT CAPSULE OF INTERPHALANGEAL JOINT OF LEFT HALLUX`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL II DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF II RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL II DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF II LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL II DITO DELLA MANO DESTRA`]: `II RIGHT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL II DITO DELLA MANO SINISTRA`]: `II LEFT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL III DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF III RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL III DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF III LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL III DITO DELLA MANO DESTRA`]: `III RIGHT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL III DITO DELLA MANO SINISTRA`]: `III LEFT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL IV DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF IV RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL IV DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF IV LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO DESTRA`]: `IV RIGHT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL IV DITO DELLA MANO SINISTRA`]: `IV LEFT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL V DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF V RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL V DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF DISTAL INTERPHALANGEAL JOINT OF V LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL V DITO DELLA MANO DESTRA`]: `V RIGHT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA DISTALE DEL V DITO DELLA MANO SINISTRA`]: `V LEFT DISTAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL II DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF II RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL II DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF II LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL II DITO DELLA MANO DESTRA`]: `II RIGHT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL II DITO DELLA MANO SINISTRA`]: `II LEFT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL III DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF III RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL III DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF III LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL III DITO DELLA MANO DESTRA`]: `III RIGHT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL III DITO DELLA MANO SINISTRA`]: `III LEFT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL IV DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF IV RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL IV DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF IV LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL IV DITO DELLA MANO DESTRA`]: `IV RIGHT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL IV DITO DELLA MANO SINISTRA`]: `IV LEFT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL V DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF V RIGHT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL V DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF PROXIMAL INTERPHALANGEAL JOINT OF V LEFT TOE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL V DITO DELLA MANO DESTRA`]: `V RIGHT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE INTERFALANGEA PROSSIMALE DEL V DITO DELLA MANO SINISTRA`]: `V LEFT PROXIMAL INTERPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IV CARPOMETACARPALE DESTRA`]: `RIGHT IV CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IV CARPOMETACARPALE SINISTRA`]: `LEFT IV CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IV COSTOTRASVERSARIA DESTRA`]: `RIGHT IV COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IV COSTOTRASVERSARIA SINISTRA`]: `LEFT IV COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IV COSTOVERTEBRALE DESTRA`]: `RIGHT IV COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IV COSTOVERTEBRALE SINISTRA`]: `LEFT IV COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IX COSTOTRASVERSARIA DESTRA`]: `LEFT IX CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IX COSTOTRASVERSARIA SINISTRA`]: `RIGHT IX COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IX COSTOVERTEBRALE DESTRA`]: `LEFT IX COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE IX COSTOVERTEBRALE SINISTRA`]: `RIGHT IX COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE MEDIOCARPICA DESTRA`]: `RIGHT MIDCARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE MEDIOCARPICA SINISTRA`]: `LEFT MIDCARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL II DITO DELLA MANO DESTRA`]: `II RIGHT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL II DITO DELLA MANO SINISTRA`]: `II LEFT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL III DITO DELLA MANO DESTRA`]: `III RIGHT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL III DITO DELLA MANO SINISTRA`]: `III LEFT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL IV DITO DELLA MANO DESTRA`]: `IV RIGHT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL IV DITO DELLA MANO SINISTRA`]: `IV LEFT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL POLLICE DESTRO`]: `JOINT CAPSULE OF METACARPOPHALANGEAL JOINT OF RIGHT THUMB`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL POLLICE SINISTRO`]: `JOINT CAPSULE OF METACARPOPHALANGEAL JOINT OF LEFT THUMB`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL V DITO DELLA MANO DESTRA`]: `V RIGHT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METACARPOFALANGEA DEL V DITO DELLA MANO SINISTRA`]: `V LEFT METACARPOPHALANGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METATARSOCUBOIDEA DESTRA`]: `RIGHT METATARSALCUBOID JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METATARSOCUBOIDEA SINISTRA`]: `LEFT METATARSALCUBOID JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL II DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF II METATARSOPHALANGEAL JOINT OF RIGHT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL II DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF II METATARSOPHALANGEAL JOINT OF LEFT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL III DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF III METATARSOPHALANGEAL JOINT OF RIGHT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL III DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF III METATARSOPHALANGEAL JOINT OF LEFT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL IV DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF IV METATARSOPHALANGEAL JOINT OF RIGHT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL IV DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF IV METATARSOPHALANGEAL JOINT OF LEFT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL V DITO DEL PIEDE DESTRO`]: `JOINT CAPSULE OF V METATARSOPHALANGEAL JOINT OF RIGHT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DEL V DITO DEL PIEDE SINISTRO`]: `JOINT CAPSULE OF V METATARSOPHALANGEAL JOINT OF LEFT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DELL'ALLUCE DESTRO`]: `JOINT CAPSULE OF I METATARSOPHALANGEAL JOINT OF RIGHT FOOT`,
  [`CAPSULA ARTICOLARE METATARSOFALANGEA DELL'ALLUCE SINISTRO`]: `JOINT CAPSULE OF I METATARSOPHALANGEAL JOINT OF LEFT FOOT`,
  [`CAPSULA ARTICOLARE OCCIPITOATLANTOIDEA DESTRA`]: `RIGHT ATLANTOOCCIPITAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE OCCIPITOATLANTOIDEA SINISTRA`]: `LEFT ATLANTOOCCIPITAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE PISOPIRAMIDALE DESTRA`]: `RIGHT PISOTRIQUETRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE PISOPIRAMIDALE SINISTRA`]: `LEFT PISOTRIQUETRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE RADIOULNARE DISTALE DESTRA`]: `RIGHT DISTAL RADIOULNAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE RADIOULNARE DISTALE SINISTRA`]: `LEFT DISTAL RADIOULNAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE RADIOULNARE PROSSIMALE DESTRA`]: `JOINT CAPSULE OF RIGHT PROXIMAL RADIOULNAR JOINT`,
  [`CAPSULA ARTICOLARE RADIOULNARE PROSSIMALE SINISTRA`]: `JOINT CAPSULE OF LEFT PROXIMAL RADIOULNAR JOINT`,
  [`CAPSULA ARTICOLARE SACROCOCCIGEA`]: `SACROCOCCYGEAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE SACROILIACA DESTRA`]: `RIGHT SACROILIAC JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE SACROILIACA SINISTRA`]: `LEFT SACROILIAC JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE SOTTOASTRAGALICA DESTRA`]: `RIGHT SUBTALAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE SOTTOASTRAGALICA SINISTRA`]: `LEFT SUBTALAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE STERNOCLAVICOLARE DESTRA`]: `RIGHT STERNOCLAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE STERNOCLAVICOLARE SINISTRA`]: `LEFT STERNOCLAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TALOCALCANEONAVICOLARE DESTRA`]: `RIGHT TALOCALCANEONAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TALOCALCANEONAVICOLARE SINISTRA`]: `LEFT TALOCALCANEONAVICULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TARSOMETATARSALE INTERMEDIA DESTRA`]: `RIGHT INTERMEDIATE TARSOMETATARSAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TARSOMETATARSALE INTERMEDIA SINISTRA`]: `LEFT INTERMEDIATE TARSOMETATARSAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TARSOMETATARSALE LATERALE DESTRA`]: `RIGHT LATERAL TARSOMETATARSAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TARSOMETATARSALE LATERALE SINISTRA`]: `LEFT LATERAL TARSOMETATARSAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TARSOMETATARSALE MEDIALE DESTRA`]: `RIGHT MEDIAL TARSOMETATARSAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TARSOMETATARSALE MEDIALE SINISTRA`]: `LEFT MEDIAL TARSOMETATARSAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TEMPOROMANDIBOLARE DESTRA`]: `JOINT CAPSULE OF RIGHT TEMPOROMANDIBULAR JOINT`,
  [`CAPSULA ARTICOLARE TEMPOROMANDIBOLARE SINISTRA`]: `JOINT CAPSULE OF LEFT TEMPOROMANDIBULAR JOINT`,
  [`CAPSULA ARTICOLARE TIBIOPERONEALE PROSSIMALE DESTRA`]: `RIGHT SUPERIOR TIBIOFIBULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE TIBIOPERONEALE PROSSIMALE SINISTRA`]: `LEFT SUPERIOR TIBIOFIBULAR JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE V CARPOMETACARPALE DESTRA`]: `RIGHT V CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE V CARPOMETACARPALE SINISTRA`]: `LEFT V CARPOMETACARPAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE V COSTOTRASVERSARIA DESTRA`]: `RIGHT V COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE V COSTOTRASVERSARIA SINISTRA`]: `LEFT V COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE V COSTOVERTEBRALE DESTRA`]: `RIGHT V COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE V COSTOVERTEBRALE SINISTRA`]: `LEFT V COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VI COSTOTRASVERSARIA DESTRA`]: `RIGHT VI COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VI COSTOTRASVERSARIA SINISTRA`]: `LEFT VI COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VI COSTOVERTEBRALE DESTRA`]: `RIGHT VI COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VI COSTOVERTEBRALE SINISTRA`]: `LEFT VI COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VII COSTOTRASVERSARIA DESTRA`]: `RIGHT VII COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VII COSTOTRASVERSARIA SINISTRA`]: `LEFT VII COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VII COSTOVERTEBRALE DESTRA`]: `RIGHT VII COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VII COSTOVERTEBRALE SINISTRA`]: `LEFT VII COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VIII COSTOTRASVERSARIA DESTRA`]: `RIGHT VIII COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VIII COSTOTRASVERSARIA SINISTRA`]: `LEFT VIII COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VIII COSTOVERTEBRALE DESTRA`]: `RIGHT VIII COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE VIII COSTOVERTEBRALE SINISTRA`]: `LEFT VIII COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE X COSTOTRASVERSARIA DESTRA`]: `RIGHT X COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE X COSTOTRASVERSARIA SINISTRA`]: `LEFT X COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE X COSTOVERTEBRALE DESTRA`]: `RIGHT X COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE X COSTOVERTEBRALE SINISTRA`]: `LEFT X COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XI COSTOTRASVERSARIA DESTRA`]: `RIGHT XI COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XI COSTOTRASVERSARIA SINISTRA`]: `LEFT XI COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XI COSTOVERTEBRALE DESTRA`]: `RIGHT XI COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XI COSTOVERTEBRALE SINISTRA`]: `LEFT XI COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XII COSTOTRASVERSARIA DESTRA`]: `RIGHT XII COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XII COSTOTRASVERSARIA SINISTRA`]: `LEFT XII COSTOTRANSVERSE JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XII COSTOVERTEBRALE DESTRA`]: `RIGHT XII COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA ARTICOLARE XII COSTOVERTEBRALE SINISTRA`]: `LEFT XII COSTOVERTEBRAL JOINT CAPSULE`,
  [`CAPSULA GLISSONIANA DEL FEGATO`]: `GLISSON'S CAPSULE`,
  [`Capsulare`]: `Capsular`,
  [`CAPSULE ARTICOLARI INTERMETACARPALI DI DESTRA`]: `RIGHT INTERMETACARPAL JOINTS CAPSULES`,
  [`CAPSULE ARTICOLARI INTERMETACARPALI DI SINISTRA`]: `LEFT INTERMETACARPAL JOINTS CAPSULES`,
  [`capsulite adesiva`]: `adhesive capsulitis`,
  [`Capsulite adesiva/spalla congelata`]: `Adhesive capsulitis/frozen shoulder`,
  [`Carcinoma colon-rettale`]: `Colon-rectum Cancer`,
  [`Carcinoma del pancreas`]: `Pancreas Cancer`,
  [`Carcinoma delle cellule basali`]: `Basal cell carcinoma (BCC)`,
  [`Carcinoma delle cellule squamose`]: `Squamous cell carcinoma SCC`,
  [`Carcinoma polmonare`]: `Lung Cancer`,
  [`Carcinoma tiroideo`]: `Thyroid Cancer`,
  [`CARDIAS`]: `CARDIA`,
  [`Cardio-chirurgia`]: `Heart surgery`,
  [`Cardiomiopatia ipertrofica`]: `Hypertrophic Cardiomyopathy`,
  [`Cardiovascolare`]: `Cardiovascular`,
  [`Cardiovascolari`]: `Cardiovascular`,
  [`Carico esterno`]: `external load`,
  [`Carico interno`]: `internal load`,
  [`Carie dentale`]: `Dental caries`,
  [`Cartella clinica`]: `Paperwork`,
  [`Cartilage/synovium/bursa`]: `Cartilage/synovium/bursa`,
  [`Cartilagine`]: `Cartilage  `,
  [`CARTILAGINE AURICOLARE DESTRA`]: `RIGHT AURICULAR CARTILAGE`,
  [`CARTILAGINE AURICOLARE SINISTRA`]: `LEFT AURICULAR CARTILAGE`,
  [`Cartilagine tiroidea`]: `Thyroid cartilage`,
  [`Cartilagine/sinovia/borsa`]: `Cartilage/synovium/bursa`,
  [`CAT CAMEL `]: `CAT CAMEL `,
  [`Cat Cow`]: `Cat Cow`,
  [`Cat Cow Shoulder Stretch`]: `Cat Cow Shoulder Stretch`,
  [`Caviglia`]: `Ankle`,
  [`Caviglia (DX)`]: `Ankle (R)`,
  [`Caviglia (SX)`]: `Ankle (L)`,
  [`Caviglia e piede`]: `Ankle & foot`,
  [`Cecità`]: `Blindness`,
  [`Cefalea`]: `Headaches`,
  [`Cefalea a grappolo`]: `Cluster headaches`,
  [`Cefalea cervicogenica`]: `Cervicogenic headache`,
  [`Cefalea da esercizio`]: `Exercise related headache`,
  [`Cefalea funzionale`]: `Functional head pain`,
  [`Cefalea non altrimenti specificata`]: `Headache not otherwise specified`,
  [`Cefalea non diagnosticata`]: `Headache/pain undiagnosed`,
  [`Cefalea riferibile ad un trigger point muscolare`]: `Muscular trigger point referred headache`,
  [`Cefalea sinusale`]: `Sinus headache`,
  [`Centralization and Peripheralization Test (5-20 reps)`]: `Centralization and Peripheralization Test (5-20 reps)`,
  [`Certamente falso`]: `Definitely false`,
  [`Certamente vero`]: `Definitely true`,
  [`Certificato medico`]: `Medical certificate`,
  [`Cervello`]: `Brain`,
  [`Cervical Anterior Quadrant Test (DX)`]: `Cervical Anterior Quadrant Test (R)`,
  [`Cervical Anterior Quadrant Test (SX)`]: `Cervical Anterior Quadrant Test (L)`,
  [`Cervical Distraction Test`]: `Cervical Distraction Test`,
  [`Cervical Joint Position Error Test (estensione)`]: `Cervical Joint Position Error Test (extension)`,
  [`Cervical Joint Position Error Test (flessione)`]: `Cervical Joint Position Error Test (flexion)`,
  [`Cervical Joint Position Error Test (rotazione) (DX)`]: `Cervical Joint Position Error Test (rotation) (R)`,
  [`Cervical Joint Position Error Test (rotazione) (SX)`]: `Cervical Joint Position Error Test (rotation) (L)`,
  [`Cervical Joint Position Error Test (side-bending) (DX)`]: `Cervical Joint Position Error Test (side-bending) (R)`,
  [`Cervical Joint Position Error Test (side-bending) (SX)`]: `Cervical Joint Position Error Test (side-bending) (L)`,
  [`Cervical Posterior Quadrant Test (DX)`]: `Cervical Posterior Quadrant Test (R)`,
  [`Cervical Posterior Quadrant Test (SX)`]: `Cervical Posterior Quadrant Test (L)`,
  [`Cervical Radiculopathy Test Item Cluster`]: `Cervical Radiculopathy Test Item Cluster`,
  [`Cervical Rotation Test`]: `Cervical Rotation Test`,
  [`Cervicale`]: `Cervical spine`,
  [`Cervice uterina`]: `Uterine Cervix`,
  [`Chatman AB, Hyams SP, Neel JM, Binkley JM, Stratford PW, Schomberg A, Stabler M. The Patient-Specific Functional Scale: measurement properties in patients with knee dysfunction. Physical therapy. 1997 Aug 1;77(8):820-9.`]: `Chatman AB, Hyams SP, Neel JM, Binkley JM, Stratford PW, Schomberg A, Stabler M. The Patient-Specific Functional Scale: measurement properties in patients with knee dysfunction. Physical therapy. 1997 Aug 1;77(8):820-9.`,
  [`Che livello di rigidità avverte al momento del risveglio al mattino all'anca e/o all'inguine?`]: `How severe is your hip and/or groin stiffness after first awakening in the morning?`,
  [`Che livello di rigidità avverte all'anca e/o all'inguine dopo aver assunto una posizione seduta, sdraiata o di riposo nel corso della giornata?`]: `How severe is your hip and/or groin stiffness after sitting, lying or resting later in the day?`,
  [`Che tipo di attività o sport di squadra pratichi?`]: `Which team sport activity do you practise?`,
  [`Che tipo di attività o sport individuale pratichi?`]: `Which individual sport activity do you practise?`,
  [`Chest Expansion Test`]: `Chest Expansion Test`,
  [`Childs JD, Piva SR, Fritz JM. Responsiveness of the numeric pain rating scale in patients with low back pain. Spine 2005;30:1331–4.`]: `Childs JD, Piva SR, Fritz JM. Responsiveness of the numeric pain rating scale in patients with low back pain. Spine 2005;30:1331–4.`,
  [`Chin Up`]: `Chin Up`,
  [`Chinandosi, ad esempio per raccogliere qualcosa da terra`]: `Bending down, e.g. to pick something up from the floor`,
  [`Chirurgia vascolare`]: `Vascular surgery`,
  [`Chiusura`]: `Closure`,
  [`Chiusura angolo infrasternale destro`]: `Right infrasternal angle closure`,
  [`Chiusura angolo infrasternale sinistro`]: `Left infrasternal angle closure`,
  [`Chiusura angolo infrasternale snistro`]: `Left infrasternal angle closure`,
  [`Chiusura angolo infrastrernale destro`]: `Right infrasternal angle closure`,
  [`Chiusura angolo infrastrernale sinistro`]: `Left infrasternal angle closure`,
  [`Christopher R. Carcia et al. Validity of the Foot and Ankle Ability Measure in Athletes With Chronic Ankle Instability. Journal of Athletic Training 2008;43(2):179–183.`]: `Christopher R. Carcia et al. Validity of the Foot and Ankle Ability Measure in Athletes With Chronic Ankle Instability. Journal of Athletic Training 2008;43(2):179–183.`,
  [`Ci sono altre cose di cui vorresti parlare riguardo al tuo stato di salute in generale?`]: `Do you wish to tell me anything else about your injury, your health or your present symptoms that we have not discussed yet?`,
  [`Ci sono fattori che aggravano il tuo dolore?`]: `What kinds of things makes the symptoms worse?`,
  [`Cicatrice Cutanea`]: `Scar`,
  [`Ciclismo`]: `Cycling`,
  [`CIECO`]: `CAECUM`,
  [`Cifo-lordotico`]: `Kipholordotic`,
  [`Cifosi toracica`]: `Thoracic kyphosis`,
  [`Circonferenza dei fianchi`]: `Hip circumference `,
  [`Circonferenza del braccio flesso (DX)`]: `Contracted arm circumference (R)`,
  [`Circonferenza del braccio flesso (SX)`]: `Contracted arm circumference (L)`,
  [`Circonferenza del braccio rilassato (DX)`]: `Relaxed arm circumference (R)`,
  [`Circonferenza del braccio rilassato (SX)`]: `Relaxed arm circumference (L)`,
  [`Circonferenza del collo`]: `Neck circumference`,
  [`Circonferenza del polso (DX)`]: `Wrist circumference (R)`,
  [`Circonferenza del polso (SX)`]: `Wrist circumference (L)`,
  [`Circonferenza del torace`]: `Thorax circumference`,
  [`Circonferenza dell'addome`]: `Abdominal circumference`,
  [`Circonferenza dell'avambraccio (DX)`]: `Forearm circumference (R)`,
  [`Circonferenza dell'avambraccio (SX)`]: `Forearm circumference (L)`,
  [`Circonferenza della caviglia (DX)`]: `Ankle joint circumference (R)`,
  [`Circonferenza della caviglia (SX)`]: `Ankle joint circumference (L)`,
  [`Circonferenza della gamba (DX)`]: `Leg circumference (R)`,
  [`Circonferenza della gamba (SX)`]: `Leg circumference (L)`,
  [`Circonferenza della testa`]: `Head circumference`,
  [`Circonferenza della vita`]: `Waist circumference`,
  [`Circonferenza delle spalle`]: `Shoulder circumference`,
  [`Circonferenza distale della coscia (DX)`]: `Distal thigh circumference (R)`,
  [`Circonferenza distale della coscia (SX)`]: `Distal thigh circumference (L)`,
  [`Circonferenza mediana della coscia (DX)`]: `Middle thigh circumference (R)`,
  [`Circonferenza mediana della coscia (SX)`]: `Middle thigh circumference (L)`,
  [`Circonferenza prossimale della coscia (DX)`]: `Proximal thigh circumference (R)`,
  [`Circonferenza prossimale della coscia (SX)`]: `Proximal thigh circumference (L)`,
  [`Cirrosi epatica`]: `Cirrhosis  `,
  [`Cirrosi o condizioni relative al fegato`]: `Cirrhosis/liver desease`,
  [`Cisti del polso`]: `Wrist ganglion`,
  [`cisti di Baker`]: `Baker's cyst`,
  [`Cisti di Baker`]: `Baker's cyst`,
  [`Cisti dorsale del carpo`]: `Carpal boss`,
  [`cisti gangliare`]: `Ganglion Cyst`,
  [`Cisti meniscale laterale`]: `Lateral meniscal cyst`,
  [`Cisti ovariche`]: `Ovarian cyst`,
  [`Cistite`]: `Cystitis`,
  [`Cistocele`]: `Cystocele`,
  [`Clarke's Sign (Patellar Grind Test) (DX)`]: `Clarke's Sign (Patellar Grind Test) (R)`,
  [`Clarke's Sign (Patellar Grind Test) (SX)`]: `Clarke's Sign (Patellar Grind Test) (L)`,
  [`Classe occlusale di tipi III`]: `Occlusal class 3`,
  [`Classe occlusale di tipo I`]: `Occlusal class 1`,
  [`Classe occlusale di tipo II`]: `Occlusal class 2`,
  [`Classe occlusale I`]: `Occlusal class 1`,
  [`Classe occlusale II`]: `Occlusal class 2`,
  [`Classe occlusale III`]: `Occlusal class 3`,
  [`Classifichi i sintomi in base al più vigoroso livello di attività che pensa di poter compiere senza provare sintomi significativi, anche se non svolge attività a quel livello.`]: `Grade symptoms at the highest activity level at which you think you could function without significant symptoms, even if
    you are not actually performing activities at this level`,
  [`Clavicola (DX)`]: `Clavicle (R)`,
  [`Clavicola (SX)`]: `Clavicle (L)`,
  [`CLAVICOLA DESTRA`]: `RIGHT CLAVICLE`,
  [`CLAVICOLA DESTRA (ESTREMITA' ACROMIALE)`]: `RIGHT CLAVICLE (ACROMIAL END)`,
  [`CLAVICOLA DESTRA (ESTREMITA' STERNALE)`]: `RIGHT CLAVICLE (STERNAL END)`,
  [`CLAVICOLA SINISTRA`]: `LEFT CLAVICLE`,
  [`CLAVICOLA SINISTRA (ESTREMITA' ACROMIALE)`]: `LEFT CLAVICLE (ACROMIAL END)`,
  [`CLAVICOLA SINISTRA (ESTREMITA' STERNALE)`]: `LEFT CLAVICLE (STERNAL END)`,
  [`Clean`]: `Clean`,
  [`Cleland JA, Childs JD, Whitman JM.. Psychometric Properties of the Neck Disability Index and Numeric Pain Rating Scale in patients With Mechanical Neck Pain, Arch Phys Med Rehabil. 2008; 89(1):69-74`]: `Cleland JA, Childs JD, Whitman JM.. Psychometric Properties of the Neck Disability Index and Numeric Pain Rating Scale in patients With Mechanical Neck Pain, Arch Phys Med Rehabil. 2008; 89(1):69-74`,
  [`Cleland JA, Fritz JM, Whitman JM, Palmer JA. The reliability and construct validity of the Neck Disability Index and patient specific functional scale in patients with cervical radiculopathy. Spine. 2006 Mar 1;31(5):598-602.`]: `Cleland JA, Fritz JM, Whitman JM, Palmer JA. The reliability and construct validity of the Neck Disability Index and patient specific functional scale in patients with cervical radiculopathy. Spine. 2006 Mar 1;31(5):598-602.`,
  [`click articolari`]: `Joint click`,
  [`Click reciproco dell'articolazione temporomandibolare`]: `TMJ Reciprocal Clicking`,
  [`Clinical Prediction Rule of Impingement (DX)`]: `Clinical Prediction Rule of Impingement (R)`,
  [`Clinical Prediction Rule of Impingement (SX)`]: `Clinical Prediction Rule of Impingement (L)`,
  [`Clinical Stress Test (DX)`]: `Clinical Stress Test (R)`,
  [`Clinical Stress Test (SX)`]: `Clinical Stress Test (L)`,
  [`CLITORIDE (F)`]: `CLITORIS (F)`,
  [`Closed Kinetic Chain Upper Extremity Stability Test (DX)`]: `Closed Kinetic Chain Upper Extremity Stability Test (R)`,
  [`Closed Kinetic Chain Upper Extremity Stability Test (SX)`]: `Closed Kinetic Chain Upper Extremity Stability Test (L)`,
  [`Closed-Fist Percussion Sign`]: `Closed-Fist Percussion Sign`,
  [`Cluster di Test for temporomandibular joint: palpazione, ROM attivo, apertura`]: `Cluster di Test for temporomandibular joint: palpazione, ROM attivo, apertura`,
  [`Cluster of Laslett: Distraction Test + Thigh Thrust Test+ Gaenslen's Test + Sacral Thrust Test + Compression Test (DX)`]: `Cluster of Laslett: Distraction Test + Thigh Thrust Test+ Gaenslen's Test + Sacral Thrust Test + Compression Test (R)`,
  [`Cluster of Laslett: Distraction Test + Thigh Thrust Test+ Gaenslen's Test + Sacral Thrust Test + Compression Test (SX)`]: `Cluster of Laslett: Distraction Test + Thigh Thrust Test+ Gaenslen's Test + Sacral Thrust Test + Compression Test (L)`,
  [`Cluster of tests for AC Pathology: Cross-Body Adduction Test + AC Resisted Extension Test + Active Compression Test (DX)`]: `Cluster of tests for AC Pathology: Cross-Body Adduction Test + AC Resisted Extension Test + Active Compression Test (R)`,
  [`Cluster of tests for AC Pathology: Cross-Body Adduction Test + AC Resisted Extension Test + Active Compression Test (SX)`]: `Cluster of tests for AC Pathology: Cross-Body Adduction Test + AC Resisted Extension Test + Active Compression Test (L)`,
  [`Cluster of tests for AC Pathology: Cross-Body Adduction Test + Active Compression Test + Hawkins-Kennedy Test + AC joint Tenderness to Palpation (DX)`]: `Cluster of tests for AC Pathology: Cross-Body Adduction Test + Active Compression Test + Hawkins-Kennedy Test + AC joint Tenderness to Palpation (R)`,
  [`Cluster of tests for AC Pathology: Cross-Body Adduction Test + Active Compression Test + Hawkins-Kennedy Test + AC joint Tenderness to Palpation (SX)`]: `Cluster of tests for AC Pathology: Cross-Body Adduction Test + Active Compression Test + Hawkins-Kennedy Test + AC joint Tenderness to Palpation (L)`,
  [`Cluster of tests for Biceps Tendinopathy: Speed's Test + Biceps Tendon Palpation (DX)`]: `Cluster of tests for Biceps Tendinopathy: Speed's Test + Biceps Tendon Palpation (R)`,
  [`Cluster of tests for Biceps Tendinopathy: Speed's Test + Biceps Tendon Palpation (SX)`]: `Cluster of tests for Biceps Tendinopathy: Speed's Test + Biceps Tendon Palpation (L)`,
  [`Cluster of Tests for Cervical Mielopathy: Gait Deviation, Hoffmann's Sign, Inverted Supinator Sign, Babinski Test, Age>45`]: `Cluster of Tests for Cervical Mielopathy: Gait Deviation, Hoffmann's Sign, Inverted Supinator Sign, Babinski Test, Age>45`,
  [`Cluster of tests for Complete Tear of Biceps Brachii: Hook Test + Passive Forearm Pronation Test + Biceps Crease Interval (DX)`]: `Cluster of tests for Complete Tear of Biceps Brachii: Hook Test + Passive Forearm Pronation Test + Biceps Crease Interval (R)`,
  [`Cluster of tests for Complete Tear of Biceps Brachii: Hook Test + Passive Forearm Pronation Test + Biceps Crease Interval (SX)`]: `Cluster of tests for Complete Tear of Biceps Brachii: Hook Test + Passive Forearm Pronation Test + Biceps Crease Interval (L)`,
  [`Cluster of tests for Labral Tears: Apprehension Test + Relocation Test (DX)`]: `Cluster of tests for Labral Tears: Apprehension Test + Relocation Test (R)`,
  [`Cluster of tests for Labral Tears: Apprehension Test + Relocation Test (SX)`]: `Cluster of tests for Labral Tears: Apprehension Test + Relocation Test (L)`,
  [`Cluster of tests for Labral Tears: Crank Test + Anterior Slide Test (DX)`]: `Cluster of tests for Labral Tears: Crank Test + Anterior Slide Test (R)`,
  [`Cluster of tests for Labral Tears: Crank Test + Anterior Slide Test (SX)`]: `Cluster of tests for Labral Tears: Crank Test + Anterior Slide Test (L)`,
  [`Cluster of tests for Monoemispheric Dysfunction: Pronator Drift, Finger tap, Riflessi tendinei profondi`]: `Cluster of tests for Monoemispheric Dysfunction: Pronator Drift, Finger tap, Riflessi tendinei profondi`,
  [`Cluster of tests for Subacromial Impingement (SAIS): Hawkins -Kennedy Test, Painful Arc, Neer's Sign, External Rotation Resistance Test, Empty Can Test (DX)`]: `Cluster of tests for Subacromial Impingement (SAIS): Hawkins -Kennedy Test, Painful Arc, Neer's Sign, External Rotation Resistance Test, Empty Can Test (R)`,
  [`Cluster of tests for Subacromial Impingement (SAIS): Hawkins -Kennedy Test, Painful Arc, Neer's Sign, External Rotation Resistance Test, Empty Can Test (SX)`]: `Cluster of tests for Subacromial Impingement (SAIS): Hawkins -Kennedy Test, Painful Arc, Neer's Sign, External Rotation Resistance Test, Empty Can Test (L)`,
  [`Cluster of Tests for Subacromial Impingement: Hawkins-Kennedy Test, Painful Arc, Infraspinatus Test (DX)`]: `Cluster of Tests for Subacromial Impingement: Hawkins-Kennedy Test, Painful Arc, Infraspinatus Test (R)`,
  [`Cluster of Tests for Subacromial Impingement: Hawkins-Kennedy Test, Painful Arc, Infraspinatus Test (SX)`]: `Cluster of Tests for Subacromial Impingement: Hawkins-Kennedy Test, Painful Arc, Infraspinatus Test (L)`,
  [`Cluster of Tests for type II SLAP Lesion: Apprehension Test + Compression Rotation Test + Speed's Test (DX)`]: `Cluster of Tests for type II SLAP Lesion: Apprehension Test + Compression Rotation Test + Speed's Test (R)`,
  [`Cluster of Tests for type II SLAP Lesion: Apprehension Test + Compression Rotation Test + Speed's Test (SX)`]: `Cluster of Tests for type II SLAP Lesion: Apprehension Test + Compression Rotation Test + Speed's Test (L)`,
  [`Coalizione calcaneo-cuboidea`]: `Calcaneocuboid coalition`,
  [`Coalizione calcaneo-navicolare`]: `Calcaneonavicular coalition`,
  [`Coalizione talo-navicolare`]: `Talonavicular Coalition`,
  [`coalizione tarsale`]: `tarsal coalition of foot`,
  [`Cobra`]: `Cobra`,
  [`Coccige`]: `Coccyx`,
  [`COCCIGE`]: `COCCYX`,
  [`Colecistite`]: `Cholecystitis`,
  [`Colesterolo alto`]: `High colesterol`,
  [`Colite ulcerante`]: `Ulcerative colitis`,
  [`Collasso avanzato scafo-lunato (post lesione scafo-lunato)`]: `SLAC Wrist (post S-L tear)`,
  [`Collo`]: `Neck`,
  [`COLLO`]: `NECK`,
  [`COLON`]: `COLON`,
  [`COLON (ANGOLO EPATICO)`]: `COLON (HEPATIC FLEXURE)`,
  [`COLON (ANGOLO SPLENICO)`]: `COLON (SPLENIC FLEXURE)`,
  [`COLON (COLON ASCENDENTE)`]: `COLON (ASCENDING COLON)`,
  [`COLON (COLON DISCENDENTE)`]: `COLON (DESCENDING COLON)`,
  [`COLON (COLON SIGMOIDEO)`]: `COLON (ASCESIGMOIDNDING COLON)`,
  [`COLON (COLON TRASVERSO)`]: `COLON (TRANSVERSE COLON)`,
  [`Colonna cervicale`]: `Cervical spine`,
  [`Colonna lombare`]: `Lumbar spine`,
  [`Colonna toracica`]: `Thoracic spine`,
  [`Com'è la sua situazione di dolore attuale?`]: `What is your pain now?`,
  [`Combined Cervical Extension and Rotation Test`]: `Combined Cervical Extension and Rotation Test`,
  [`Come definiresti al tuo attuale stato di funzione?`]: `How would you rate your current level of function?`,
  [`Come definiresti il tuo attuale stato di funzione?`]: `How would you rate your current level of function?`,
  [`Come e' esordito il dolore?`]: `Hw did the pain start?`,
  [`Come e' iniziato il tuo dolore ?`]: `How did the pain start?`,
  [`Come giudicherebbe la funzionalità del suo ginocchio in base ad una scala da 0 a 10, dove 10 è la normalità e 0 è l'incapacità di eseguire una o più delle sue abituali attività quotidiane, eventuali attività sportive comprese?`]: `How would you rate the function of your knee on a scale of 0 to 10 with 10 being normal, excellent function and 0 being the inability to perform any of your usual daily activities which may include sports?`,
  [`Come giudicherebbe la funzionalità del suo ginocchio su una scala da 0 a 10 (dove 10 è la normalità e 0 è l'incapacità di eseguire una o più delle sue abituali attività quotidiane, eventuali attività sportive comprese)?`]: `How would you rate the function of your knee on a scale of 0 to 10 with 10 being normal, excellent function and 0 being the inability to perform any of your usual daily activities which may include sports?`,
  [`Come percepisce lo sforzo?`]: `Rate your effort`,
  [`Come si comporta il dolore durante la giornata?`]: `How does the pain behave within a day?`,
  [`Come sta evolvendo il dolore da quando è comparso?`]: `How is the pain is evolving?`,
  [`Come valuteresti il tuo stato di salute?`]: `How do you rate your health status`,
  [`Come valuteresti la qualita' del tuo sonno`]: `How do you rate the quality of your sleep?`,
  [`Come valuteresti la qualita' della tua dieta`]: `How do you rate the quality of your diet?`,
  [`COMPARTIMENTO ANTERIORE DEL BRACCIO DESTRO`]: `ANTERIOR COMPARTMENT OF RIGHT ARM`,
  [`COMPARTIMENTO ANTERIORE DEL BRACCIO SINISTRO`]: `ANTERIOR COMPARTMENT OF LEFT ARM`,
  [`COMPARTIMENTO ANTERIORE DELL'AVAMBRACCIO DESTRO`]: `ANTERIOR COMPARTMENT OF RIGHT FOREARM`,
  [`COMPARTIMENTO ANTERIORE DELL'AVAMBRACCIO SINISTRO`]: `ANTERIOR COMPARTMENT OF LEFT FOREARM`,
  [`COMPARTIMENTO ANTERIORE DELLA COSCIA DESTRA`]: `ANTERIOR COMPARTMENT OF RIGHT THIGH`,
  [`COMPARTIMENTO ANTERIORE DELLA COSCIA SINISTRA`]: `ANTERIOR COMPARTMENT OF LEFT THIGH`,
  [`COMPARTIMENTO ANTERIORE DELLA GAMBA DESTRA`]: `ANTERIOR COMPARTMENT OF RIGHT LEG`,
  [`COMPARTIMENTO ANTERIORE DELLA GAMBA SINISTRA`]: `ANTERIOR COMPARTMENT OF LEFT LEG`,
  [`COMPARTIMENTO LATERALE DELLA GAMBA DESTRA`]: `LATERAL COMPARTMENT OF RIGHT LEG`,
  [`COMPARTIMENTO LATERALE DELLA GAMBA SINISTRA`]: `LATERAL COMPARTMENT OF LEFT LEG`,
  [`COMPARTIMENTO MEDIALE DELLA COSCIA DESTRA`]: `MEDIAL COMPARTMENT OF RIGHT THIGH`,
  [`COMPARTIMENTO MEDIALE DELLA COSCIA SINISTRA`]: `MEDIAL COMPARTMENT OF LEFT THIGH`,
  [`COMPARTIMENTO POSTERIORE DEL BRACCIO DESTRO`]: `POSTERIOR COMPARTMENT OF RIGHT ARM`,
  [`COMPARTIMENTO POSTERIORE DEL BRACCIO SINISTRO`]: `POSTERIOR COMPARTMENT OF LEFT ARM`,
  [`COMPARTIMENTO POSTERIORE DELL'AVAMBRACCIO DESTRO`]: `POSTERIOR COMPARTMENT OF RIGHT FOREARM`,
  [`COMPARTIMENTO POSTERIORE DELL'AVAMBRACCIO SINISTRO`]: `POSTERIOR COMPARTMENT OF LEFT FOREARM`,
  [`COMPARTIMENTO POSTERIORE DELLA COSCIA DESTRA`]: `POSTERIOR COMPARTMENT OF RIGHT THIGH`,
  [`COMPARTIMENTO POSTERIORE DELLA COSCIA SINISTRA`]: `POSTERIOR COMPARTMENT OF LEFT THIGH`,
  [`COMPARTIMENTO POSTERIORE PROFONDO DELLA GAMBA DESTRA`]: `POSTERIOR COMPARTMENT OF RIGHT LEG (DEEP PART)`,
  [`COMPARTIMENTO POSTERIORE PROFONDO DELLA GAMBA SINISTRA`]: `POSTERIOR COMPARTMENT OF LEFT LEG (DEEP PART)`,
  [`COMPARTIMENTO POSTERIORE SUPERFICIALE DELLA GAMBA DESTRA`]: `SUPERFICIAL POSTERIOR COMPARTMENT OF RIGHT LEG`,
  [`COMPARTIMENTO POSTERIORE SUPERFICIALE DELLA GAMBA SINISTRA`]: `SUPERFICIAL POSTERIOR COMPARTMENT OF LEFT LEG`,
  [`Compili il questionario`]: `Tick the questionnaire`,
  [`Compili le seguenti sezioni`]: `Tick the next sections`,
  [`Complicanza di abrasione/ferita della parete toracica compresa infezione e perforazione della cavita' toracica`]: `Chest wall abrasion (incl complications)`,
  [`Complicanza di abrasione/ferita di anca/inguine compresa l'infezione`]: `Complication of laceration including infection`,
  [`Complicanza di distorsione all'articolazione matacarpofalangea esclusa l'instabilita' cronica`]: `Complication of MCP joint sprain excl chronic instability`,
  [`Complicanza di ferita al gomito compresa l'infezione`]: `Complication of elbow laceration including infection`,
  [`Complicanza di ferita/abrasione al tronco compresa l'infezione`]: `Complication of abrasion to trunk - including infection`,
  [`Complicanza di ferita/abrasione lombare compresa l'infezione`]: `Complication of lumbosacral laceration incl infection`,
  [`Complicanza di ferita/abrasione toracica compresa l'infezione`]: `Thoracic abrasion (including infection)`,
  [`Complicanza di ferite/abrasioni della spalla compresa l'infezione`]: `Complication of shoulder laceration including infection`,
  [`Complicanza di lussazione del pollice esclusa l'instabilita'`]: `Complication of thumb joint dislocation`,
  [`Complicanza di una ferita alla caviglia compresa l'infezione`]: `Complication of ankle laceration incl infection`,
  [`Complicanza di una ferita alla coscia compresa l'infezione`]: `Complication of laceration incl. infection`,
  [`Complicanza di una frattura alle dita della mano (inclusa pseudoartrosi)`]: `Complication from finger fracture (incl malunion)`,
  [`Complicanza post stiramento/rottura del LCM, compresa la lesione di Pellegrini Steida`]: `Complication post MCL strain/rupture incl Pellegrini Stieda lesion`,
  [`Complicanze delle fratture del piede compresa la pseudoartrosi`]: `Complication of fractured foot including non union`,
  [`Complicanze di abrasione/ferita all'avambraccio compresa l'infezione`]: `Complication of forearm laceration including infection`,
  [`Complicanze di una ferita/abrasione al piede compresa l'infezione`]: `Complication of foot laceration incl infection`,
  [`Complicanze di una frattura della colonna lombare (compresa la pseudoartrosi - esclusa la lesione spinale)`]: `Complication of lumbar fracture`,
  [`Complicazione di ferita/abrasione al collo compresa l'infezione`]: `Complication of neck laceration including infection`,
  [`Complicazione di ferite/abrasioni alla testa comprese le infezioni`]: `Complication of head laceration including infection`,
  [`Complicazione di una lussazione dell'articolazione interfalangea prossimale o distale`]: `Complication of PIP/DIP joint dislocation`,
  [`Complicazione di una lussazione metacarpofalangea delle dita`]: `Complication of finger MCP joint sprain`,
  [`Composite physical examination for ACL injury: Lachman test, Anterior Drawer Test, Pivot Shift Test (DX)`]: `Composite physical examination for ACL injury: Lachman test, Anterior Drawer Test, Pivot Shift Test (R)`,
  [`Composite physical examination for ACL injury: Lachman test, Anterior Drawer Test, Pivot Shift Test (SX)`]: `Composite physical examination for ACL injury: Lachman test, Anterior Drawer Test, Pivot Shift Test (L)`,
  [`Composite physical examination for meniscal tear: Knee Joint Line Tenderness, McMurray, Steinmann, modified Apley's Test (DX)`]: `Composite physical examination for meniscal tear: Knee Joint Line Tenderness, McMurray, Steinmann, modified Apley's Test (R)`,
  [`Composite physical examination for meniscal tear: Knee Joint Line Tenderness, McMurray, Steinmann, Modified Apley's test (SX)`]: `Composite physical examination for meniscal tear: Knee Joint Line Tenderness, McMurray, Steinmann, Modified Apley's Test (L)`,
  [`Composite physical examination for PCL injury: Reverse Lachman, Reverse Lachman Endpoint, Reverse Pivot Shift , Posterior Drawer, Godfrey's Sign, Quadriceps Active Drawer, External Rotation Recurvatum (DX)`]: `Composite physical examination for PCL injury: Reverse Lachman, Reverse Lachman Endpoint, Reverse Pivot Shift , Posterior Drawer, Godfrey's Sign, Quadriceps Active Drawer, External Rotation Recurvatum (L)`,
  [`Composite physical examination for PCL injury: Reverse Lachman, Reverse Lachman Endpoint, Reverse Pivot Shift , Posterior Drawer, Godfrey's Sign, Quadriceps Active Drawer, External Rotation Recurvatum (SX)`]: `Composite physical examination for PCL injury: Reverse Lachman, Reverse Lachman Endpoint, Reverse Pivot Shift , Posterior Drawer, Godfrey's Sign, Quadriceps Active Drawer, External Rotation Recurvatum (R)`,
  [`Compression Rotation Test (DX)`]: `Compression Rotation Test (R)`,
  [`Compression Rotation Test (SX)`]: `Compression Rotation Test (L)`,
  [`Compression Test`]: `Compression Test`,
  [`Compressione`]: `Compression`,
  [`compressione del nervo sensitivo radiale`]: `Radial sensory nerve compression`,
  [`Compressione/stiramento radice nervosa a livello cervicale (sensazione di bruciore, puntura prossimale)`]: `Cervical nerve root compression/stretch`,
  [`Con il braccio del lato interessato riesce a lavarsi la spalla opposta?`]: `11. Can you wash the back of your opposite shoulder with the affected extremity?`,
  [`Con quale frequenza si rende conto del suo problema all'anca?`]: `How often are you aware of your hip problem?`,
  [`Con quale/i parola/e descriveresti il tuo dolore?`]: `What does the pain feel like?`,
  [`Con quanta frequenza ha dolore al ginocchio?`]: `How often do you experience knee pain?`,
  [`CONCA NASALE INFERIORE DI DESTRA`]: `RIGHT INFERIOR NASAL CONCHA`,
  [`CONCA NASALE INFERIORE DI SINISTRA`]: `LEFT INFERIOR NASAL CONCHA`,
  [`Concussione`]: `Concussion`,
  [`Concussione acuta con sintomi visivi`]: `Acute Concussion with visual symptoms`,
  [`Concussione del midollo spinale a livello cervicale`]: `Cervical spinal cord concussion`,
  [`Concussione/trauma cerebrale`]: `Concussion/Brain Injury`,
  [`Condizione cronica o degenerativa`]: `Degenerative or chronic condition`,
  [`Condizione relazionata a dismorfismo sessuale`]: `Condition related to Sexual Dimorphism`,
  [`Condizione relazionata a stato di transgender`]: `Condition related to Transgender status`,
  [`condropatia dell'anca`]: `Hip joint chondropathy`,
  [`Conferma il gomito come regione causativa del dolore`]: `Confirm the elbow as a pain generator`,
  [`Conferma la possibile presenza di una frattura vertebrale`]: `Confirm a vertebral fracture`,
  [`Conferma la possibile presenza di una GTPS o Greater Trochanteric Pain Syndrome`]: `Confirm GTPS (greater throcanter pain syndrome)`,
  [`Conferma la presenza di dolore femoro-rotuleo (PFPS)`]: `Confirm PFPS (patello femoral pain syndrome)`,
  [`Conferma la presenza di frattura omerale, clavicolare o lussazione della spalla`]: `Confirm a fracture of humerus, clavicle or a shoulder joint dislocation`,
  [`Conferma la presenza di instabilita' lombare`]: `Confirm lumbar instability`,
  [`Conferma la presenza di un Impingement Sub-Acromiale`]: `Confirm a sub-acromial impingement`,
  [`Conferma la presenza di una frattura della caviglia`]: `Confirm an ankle fracture`,
  [`Conferma la presenza di una frattura omerale`]: `Confirm humeral fracture`,
  [`Conferma la presenza di una Instabilita' Anteriore`]: `Confirm an anterior instability of shoulder`,
  [`Conferma la presenza di una ipomobilita' C1-C2 (possibile causa di Cervicogenic Headache (CH)`]: `Confirm hypomobility of C1-C2 (CH Cervicogenic Headache)`,
  [`Conferma la presenza di una lesiojne del labbro`]: `Confirm a labial tear`,
  [`Conferma la presenza di una lesione acuta del gomito`]: `Confirm elbow acute injury`,
  [`Conferma la presenza di una lesione del labbro`]: `Confirm a labial tear`,
  [`Conferma la presenza di una lesione del labbro postero-inferiore`]: `Confirm a postero-inferior labial tear`,
  [`Conferma la presenza di una lesione del legamento collaterale mediale (MCL)`]: `Confirm an injury of the medial collateral ligament `,
  [`Conferma la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear)`]: `Confirm a rotator cuff injury (Rotator Cuff Tear)`,
  [`Conferma la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear); lesione di Supraspinatu e Infraspinatus`]: `Confirm a rotator cuff injury (Rotator Cuff Tear): Supraspinatus and infraspinatus injury`,
  [`Conferma la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear): lesione del Subscapularis`]: `Confirm a rotator cuff injury (Rotator Cuff Tear): Subscapularis injury`,
  [`Conferma la presenza di una lesione intra-articolare`]: `Confirm an intraarticular injury`,
  [`Conferma la presenza di una MTSS o Medial Tibial Stress Syndrome`]: `Confirm medial tibial stress syndrome`,
  [`Conferma la presenza di una patologia a carico dell'articolazione Acromion-Clavicolare`]: `Confirm acromionclavicluar joint pathology`,
  [`Conferma la presenza di una radicolopatia cervicale`]: `Confirm a cervical radiculopathy`,
  [`Conferma la presenza di una radicolopatia o di dolore relativo al disco`]: `Confirm a radiculopathy or a intervertebral disc problem`,
  [`Conferma la presenza di una retrazione dell'Ileopsoas`]: `Confirm iliopsoas flexibility restriction`,
  [`Conferma la presenza di una retrazione della IT band`]: `Confirm IT band flexibility restriction`,
  [`Conferma la presenza di una Sindrome del Tunnel Carpale`]: `Confirm a carpal tunnel syndrome`,
  [`Conferma la presenza di una sindrome femoro-rotulea (PFPS)`]: `Confirm PFPS (patello femoral pain syndrome)`,
  [`Conferma la presenza di una spondilite anchilosante`]: `Confirm ankylosing spondylitis`,
  [`Conferma la presenza di una tendinopatia Achillea`]: `Confirm achilles tendinopathy`,
  [`Conferma la presenza di una tendinopatia del tibiale posteriore. Considerare la presenza di una sindrome da stress mediale della tibia`]: `Confirm a tibialis posterior tendinopathy. Consider medial tibial stress syndrome`,
  [`Conferma la presenza di una Type II Slap Lesion`]: `Confirm a type II slap lesion`,
  [`Conferma una lesione del tendine distale del bicipite brachiale`]: `Confirm an injury of the biceps brachii distal tendon`,
  [`Conferma una tendinopatia glutea o Greater Trochanteric Pain Syndrome (GTPS)`]: `Confirm a gluteal tendinopathy or a GTPS (greater throcanter pain syndrome)`,
  [`Confusione`]: `Confusion`,
  [`Congiuntivite allergica`]: `Allergic conjunctivitis`,
  [`Congiuntivite infettiva`]: `Infective conjunctivitis`,
  [`Considerare cause meccaniche`]: `Consider mechanical problems  `,
  [`Considerare cause meccaniche o condizioni da overuse del polso`]: `Consider mechanical problems of the wrist`,
  [`Considerare cause muscolo-scheletriche`]: `Consider non-musculoskeletal conditions`,
  [`Considerare cause non muscolo-scheletriche`]: `Consider non-musculoskeletal conditions`,
  [`Considerare cause non muscolo-scheletriche (Insufficienza Vertebro-Basilare)`]: `Consider non-musculoskeletal conditions (vertebrobasilar insufficiency)`,
  [`Considerare cause non muscolo-scheletriche o dolore riferito`]: `Consider non-musculoskeletal conditions or a referred pain`,
  [`Considerare cause non muscolo-scheletriche( Dissezione Arteria Cervicale)`]: `Consider non-musculoskeletal conditions ( Cervical Artery Dissection)`,
  [`Considerare condizioni da overuse del polso`]: `Consider overuse injuries of wrist`,
  [`Considerare condizioni extra-articolari`]: `consider extra-articular problems`,
  [`Considerare condizioni infiammatorie, morbo di Sheuermann`]: `Consider inflammatory conditions; consider Sheuermann disease`,
  [`considerare condizioni non muscolo-scheletriche`]: `Consider non-musculoskeletal conditions`,
  [`Considerare condizioni non muscolo-scheletriche`]: `Consider non-musculoskeletal conditions`,
  [`Considerare condizioni non muscolo-scheletriche (Dissezione Arteria Cervicale)`]: `Consider non-musculoskeletal conditions (Cervical Artery Dissection)`,
  [`Considerare condizioni non-muscolo-scheletriche`]: `Consider non-musculoskeletal conditions`,
  [`Considerare condizioni non-muscolo-scheletriche; considerare frattura vertebrale in donne anziane`]: `Consider non musculoskeletal conditions; consider vertebral fractures in old women`,
  [`Considerare condizioni non-muscoloscheletriche`]: `Consider non-musculoskeletal conditions`,
  [`Considerare condizioni traumatiche del polso`]: `Consider acute injuries of wrist`,
  [`Considerare costocondrite`]: `Consider costochondritis`,
  [`Considerare disfunzione costo-vertebrale`]: `Consider costovertebral dysfunction`,
  [`Considerare fratture da stress`]: `Consider a stress fracture`,
  [`Considerare Herpes Zoster`]: `Consider Herpes Zoster`,
  [`Considerare il coinvolgimento dei nervi cranici`]: `consider involvment of cranial nerves`,
  [`Considerare il coinvolgimento del disco articolare`]: `consider involvment of articular disc`,
  [`Considerare il coinvolgimento della regione dell'anca nel dolore al ginocchio`]: `Consider hip joint as pain generator`,
  [`Considerare il neuroma di Morton`]: `Consider Morton neuroma`,
  [`Considerare impingement`]: `Consider an impingment`,
  [`Considerare l'articolazione sacro-iliaca o la regione lombare`]: `Consider sacroiliac joint; consider lumbar region`,
  [`Considerare l'articolazione sacro-iliaca; considerare la sindrome del piriform`]: `Consider sacroiliac joint; consider piriformis syndrome`,
  [`Considerare la possibile presenza di frattura di caviglia`]: `Consider an ankle fracture`,
  [`Considerare la possibile presenza di scoliosi`]: `Consider scoliosis`,
  [`Considerare la possibile presenza di una Capsulite Adesiva o di Artrosi Gleno-Omerale`]: `Consider adesive capsulitis or gleno-humeral osteoarthritis`,
  [`Considerare la possibile presenza di una frattura`]: `Consider a fracture  `,
  [`Considerare la possibile presenza di una frattura al piede`]: `Consider a foot fracture`,
  [`Considerare la possibile presenza di una frattura da stress`]: `Consider stress fracture`,
  [`Considerare la possibile presenza di una frattura del ginocchio`]: `Consider knee fractures`,
  [`Considerare la possibile presenza di una frattura dell'anello (arco anteriore e arco posteriore) dell'atlante`]: `Consider a fracture of the atlas (anterior or posterior arch)`,
  [`Considerare la possibile presenza di una possibile frattura del femore`]: `Consider femoral fracture`,
  [`Considerare la possibile presenza di una pProblematica intraarticolare`]: `Consider intraarticular pathologies`,
  [`Considerare la possibile presenza di una radicolopatia lombare o di una patologia relativa al disco`]: `Consider lumbar radiculopathy or disc pathology`,
  [`Considerare la possibile presenza di una radicolopatia o di una patologia relativa al disco lombare`]: `Consider a lumbar radiculopathy or disc pathology`,
  [`Considerare la possibile presenza di una spondilolistesi`]: `Consider spondylolistesis`,
  [`Considerare la possibile presenza di una Tenosinovite di De Quervain`]: `Consider a De Quervain tenosinovitis`,
  [`Considerare la potenziale presenza di fratture pelviche`]: `Consider pelvic fracture`,
  [`Considerare la presena e il grado di versamento articolare`]: `Consider intra-articular swelling`,
  [`Considerare la presenza di disfunzione delle vertebre cervicali superiori (se il dolore si irradia alla testa); in presenza di dolore ad insorgenza acuta che si irradia alla testa non escludere una CAD (Dissezione Arteria Cervicale)`]: `If pain radiates to the head, consider dysfunction of superior cervical vertebrae; in case of sudden onset of the pain, do not exclude CAD (Cervical Artery Dissection)`,
  [`Considerare la presenza di edema`]: `Consider edema`,
  [`Considerare la presenza di instabilita' della articolazione sottoastragalica`]: `Consider subtalar joint instability`,
  [`Considerare la presenza di instabilita' della caviglia per lassita' legamentosa del compartimento laterale della caviglia`]: `Consider ankle joint instability due to lateral ligament laxity`,
  [`Considerare la presenza di instabilita' della caviglia per lassita' legamentosa del compartimento mediale della caviglia`]: `Consider ankle joint instability due to medial ligament laxity`,
  [`Considerare la presenza di osteo-artrosi`]: `Consider Osteoarthritis`,
  [`Considerare la presenza di radicolopatia se il dolore si irradia sopra al gomito`]: `consider radiculopathy if pain radiates above the elbow`,
  [`Considerare la presenza di un cedimento vertebrale`]: `Consider a vertebral collapse`,
  [`Considerare la presenza di un impingement della caviglia`]: `Consider ankle joint impingement`,
  [`Considerare la presenza di una effusione intra-articolare`]: `Consider intra-articular swelling`,
  [`Considerare la presenza di una frattura vertebrale da compressione`]: `Consider a compression vertebral fracture`,
  [`Considerare la presenza di una GTPS`]: `Consider GTPS (greater throcanter pain syndrome)`,
  [`Considerare la presenza di una instabilita' del legamento trasverso`]: `Consider an instability of cervical transverse ligament`,
  [`Considerare la presenza di una instabilita' del piramidale`]: `Consider a triquetral instability`,
  [`Considerare la presenza di una instabilita' dello scafoide`]: `Consider a scaphoid instability`,
  [`Considerare la presenza di una instabilita' tra occipite e atlante`]: `Consider an instability of the atlas`,
  [`Considerare la presenza di una lesione del legamento collaterale mediale (MCL)`]: `Consider an injury of the medial collateral ligament`,
  [`Considerare la presenza di una lesione del tendine del bicipite brachiale`]: `Confirm a biceps brachii tendon injury`,
  [`Considerare la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear): Lesione del Subscapularis; considerare la presenza di una Lesione posteriore del labbro`]: `Consider a Rotator Cuff Tear: Subscapularis injury; consider posterior labial tear`,
  [`Considerare la presenza di una lesione della sindesmosi`]: `Consider syndesmosis injury`,
  [`Considerare la presenza di una radicolopatia lombare`]: `Consider a lumbar radiculopathy`,
  [`Considerare la presenza di una Sindrome del Tunnel Cubitale`]: `Consider cubital tunnel syndrome`,
  [`Considerare la presenza di una sindrome del tunnel tarsale`]: `Confirm tarsal tunnel syndrome`,
  [`Considerare la regione dell'anca come potenziale causa del dolore`]: `Consider hip joint as pain generator`,
  [`Considerare la regione dell'anca come potenziale causa di dolore alla gamba`]: `Consider the hip joint as pain generator`,
  [`Considerare potenziali fratture pelviche`]: `Consider pelvic fracture`,
  [`Considerare potenziali problemi renali`]: `Consider kidneys problems`,
  [`Considerare sindrome coronarica acuta`]: `Consider coronary acute syndrome`,
  [`considerare T1-T2`]: `Consider T1-T2`,
  [`Considerare tendinopatia achillea inserzionale`]: `Consider insertional Achilles tendinopathy`,
  [`Considerare tendinopatia prossimale degli hamstring o`]: `Consider proximal hamstring tendinopathy`,
  [`Considerare tumore di Pancoast in caso di dolore sul bordo scapolare e progressione del dolore nel territorio di distribuzione del nervo ulnare`]: `Consider a Pancoast cancer with pain on the scapular medial margin with radiation to the unar nerve territory`,
  [`Considerare un coinvolgimento della regione del polso nella generazione del dolore`]: `Consider the wrist as the pain generator`,
  [`Considerare un dolore di origine miofasciale o un DOMS; Considerare una tendinopatia prossimale degli hamstring`]: `Consider myofascial pain or DOMS; consider an hamstring proximal tendinopathy o`,
  [`Considerare un dolore riferito da altre regioni`]: `Consider a referred pain from other body regions`,
  [`Considerare un dolore riferito dalla regione lombare o dall'area sacro-iliaca`]: `Consider a referred pain from the lumbar region or sacroiliac joint`,
  [`Considerare un dolore riferito dalle articolazioni interfaccettarie se il dolore e' riferito infero-lateralmente alla regione cervicale`]: `consider referred pain from intervertebral joints `,
  [`Considerare un dolore riferito viscerale`]: `Consider a visceral referred pain`,
  [`Considerare un DOMS: Considerare una frattura da stress della corticale posteriore della tibia`]: `Consider DOMS; Consider stress fracture of Tibia`,
  [`Considerare un problema vertebrale toracico`]: `Consider a thoracic vertebral problem`,
  [`Considerare un trauma cranico (Traumatic Brain Injury TBI o mildTBI)`]: `Consider Traumatic Brain Injury (TBI or mildTBI)`,
  [`Considerare una contusione`]: `Consider a contusion`,
  [`Considerare una contusione muscolare degli hamstring`]: `Consider hamstring muscle contusion`,
  [`Considerare una Discinesia Scapolare`]: `Consider scapular dyskinesis`,
  [`Considerare una disfunzione dell'articolazione sacro-iliaca`]: `Consider sacroiliac joint dysfunction`,
  [`Considerare una disfunzione meccanica vertebrale in flessione`]: `Consider a vertebral somatic dysfunction in flexion `,
  [`Considerare una dissociazione scafo-lunata`]: `Consider scapho-lunate dissociation`,
  [`Considerare una fasciopatia plantare`]: `Consider plantar fasciopathy`,
  [`Considerare una fasciopatia plantare o una tendinopatia`]: `Consider a plantar fasciopathy`,
  [`Considerare una frattura`]: `Consider a fracture`,
  [`Considerare una frattura da stress`]: `Consider a stress fracture`,
  [`Considerare una frattura da stress; considerare una contusione del cuscinetto adiposo; considerare una fasciopatia o una metatarsalgia`]: `Consider a stress fracture, a fat pad contusion, a fasciopathy or metatarsalgya`,
  [`Considerare una instabilita' del Capitato`]: `Consider capitate instability`,
  [`Considerare una instabilita' femoro-rotulea`]: `Consider patello-femoral instability`,
  [`Considerare una lesione del legamento crociato anteriore`]: `Consider ACL injury`,
  [`Considerare una lesione del legamento crociato posteriore`]: `Consider a PCL injury`,
  [`Considerare una lesione del tendine distale del bicipite brachiale`]: `Consider an injury of the biceps brachii distal tendon`,
  [`Considerare una lesione meniscale`]: `Consider meniscal injury`,
  [`Considerare una lesione meniscale mediale`]: `Consider meniscal injury (medial)`,
  [`Considerare una lesione muscolare degli hamstring o una problematica miofasciale degli hamstring; considerare una tendinopatia prossimale degli hamstring`]: `Consider hamstring muscle injury or hamstring myofascial pain; consider an hamstring proximal tendinopathy`,
  [`Considerare una lesione muscolare degli hamstring: Tipo I, Tipo II`]: `Consider hamstring muscle injury: type I or type II`,
  [`Considerare una lesione muscolare dei Gastrocnemi o del Soleo`]: `Consider muscle injury of the calf`,
  [`Considerare una mielopatia cervicale`]: `Consider Cervical Mielopathy`,
  [`Considerare una possibile frattura costale`]: `Consider rib fracture`,
  [`Considerare una possibile frattura di Maissoneuve`]: `Consider Maissoneuve fracture`,
  [`Considerare una possibile frattura vertebrale`]: `Consider a vertebral fracture`,
  [`Considerare una possibile lesione della sindesmosi`]: `Consider syndesmosis injury`,
  [`Considerare una possibile paralisi del Axillary Nerve`]: `Consider axillary nerve palsy`,
  [`Considerare una possibile paralisi dello Spinal Accessory Nerve (Nervo Accessorio Spinale)`]: `Consider spinal accessory nerve palsy`,
  [`Considerare una possibile patologia a carico dell'articolazione Acromion-Clavicolare`]: `Consider acromion-clavicular joint pathology`,
  [`Considerare una possibile problematica Sacro-Iliaca`]: `Consider sacroiliac joint problem`,
  [`Considerare una possibile sindrome delle faccette articolari lombari`]: `Consider lumbar facet joints syndrome`,
  [`Considerare una primary exercise headache (mal di testa da esercizio)`]: `Consider a primary exercise headache`,
  [`considerare una prognosi piu' lunga per fattori psicologici`]: `Consider longer time (psychological factors)`,
  [`Considerare una radicolopatia lombare o una patologia relativa al dico lombare`]: `Consider lumbar radiculopathy or disc pathology`,
  [`Considerare una rottura del tendine d'achille`]: `Consider Achilles tendon rupture`,
  [`Considerare una sindrome del piriforme`]: `Consider Piriformis syndrome`,
  [`Considerare una sindrome del tunnel tarsale, intrappolamento dei nervi plantari`]: `Consider tarsal tunnel syndrome or impingment of plantar nerves`,
  [`Considerare WAD (Wiplash-associated disorder) se il dolore e' unilaterale`]: `Consider Wiplash-associated disorder in case of unilateral pain`,
  [`Consiglio per cura del seno`]: `Advice on breast care`,
  [`Consiglio su legalita' di farmaci/supplementi`]: `Advice on legality of drug/supplement`,
  [`Consiglio/Consultazione fertilita'`]: `Fertility advice consultation`,
  [`Consiglio/Consultazione postnatale`]: `Postnatal advice / consult `,
  [`Consiglio/Consultazione prenatale`]: `Antenatal advice / consult `,
  [`Consiglio/Rieducazione pavimento pelvico`]: `Pelvic floor advice/education`,
  [`Constocondrite`]: `Costochondritis`,
  [`Consultazione`]: `Consultation`,
  [`Consultazione ginecologica`]: `Gynaecological consultation`,
  [`Consumi caffeina (quanti caffe' al giorno)`]: `Do you use caffeine? (how many coffees a day)`,
  [`Contrattura di Dupytren`]: `Dupytren's Contracture`,
  [`Contusione al legamento collaterale mediale (LCM)`]: `Knee MCL contusion`,
  [`Contusione all'articolazione acromioclavicolare`]: `A/C Joint contusion`,
  [`Contusione alla regione della spalla`]: `Shoulder region contusion`,
  [`contusione costale`]: `Rib contusion`,
  [`contusione del cuscinetto adiposo`]: `calcaneus fat pad contusion`,
  [`Contusione del cuscinetto adiposo del calcagno`]: `Fat pad contusion heel`,
  [`contusione del gastrocnemio`]: `Gstrocnemius muscle contusion`,
  [`contusione del muscolo quadricipite`]: `Quadriceps muscle contusion`,
  [`Contusione muscolare`]: `Muscle contusion`,
  [`Contusione ossea`]: `Bone contusion`,
  [`Contusione ossea al ginocchio`]: `Knee bone contusion`,
  [`Contusione ossea al gomito`]: `Elbow bone bruise`,
  [`Contusione ossea alla colonna lombare/natiche`]: `Lumbar spine/buttock bone contusion`,
  [`Contusione ossea alla gamba`]: `Lower leg bone contusion`,
  [`Contusione ossea del femore`]: `Femur bone contusion`,
  [`Contusione ossea dell'anca/groin`]: `Groin/hip bone contusion`,
  [`Contusione ossea dell'avambraccio`]: `Forearm bone contusion`,
  [`Contusione ossea dell'omero`]: `Humerus bone contusion`,
  [`Contusione ossea della caviglia`]: `Ankle bone contusion`,
  [`Contusione ossea della mano`]: `Hand bone contusion`,
  [`Contusione ossea vertebrale toracica`]: `Thoracic spine bone contusion`,
  [`contusione periostale del perone`]: `periostal contusion of fibula`,
  [`contusione periostale della tibia`]: `periostal contusion of tibia`,
  [`Contusione superficiale`]: `Contusion (superficial)`,
  [`Contusione vascolare`]: `Vascular Trauma`,
  [`Contusione/ematoma al polpaccio`]: `Calf contusion/haematoma`,
  [`Contusione/ematoma del piede`]: `Foot contusion/haematoma`,
  [`Contusione/vascolare`]: `Vascular trauma`,
  [`Copenhagen Adduttore Leva Corta Iso`]: `BENT KNEE COPENHAGEN`,
  [`Coracoid Pain Test (DX)`]: `Coracoid Pain Test (R)`,
  [`Coracoid Pain Test (SX)`]: `Coracoid Pain Test (L)`,
  [`CORPO ADIPOSO CALCANEALE DESTRO`]: `RIGHT CALCANEAL FAT PAD`,
  [`CORPO ADIPOSO CALCANEALE SINISTRO`]: `LEFT CALCANEAL FAT PAD`,
  [`Corpo adiposo di Hoffa (DX)`]: `Patellar fat pad (R)`,
  [`Corpo adiposo di Hoffa (SX)`]: `Patellar fat pad (L)`,
  [`CORPO ADIPOSO DI HOFFA DESTRO`]: `RIGHT INFRAPATELLAR FAT PAD`,
  [`CORPO ADIPOSO DI HOFFA SINISTRO`]: `LEFT INFRAPATELLAR FAT PAD`,
  [`Corpo esterno in laringe`]: `Foreign body in larynx`,
  [`Corpo estraneo nell'occhio - cornea`]: `Eye foreign body - Corneal`,
  [`Corpo estraneo nell'occhio - non altrimenti specificato`]: `Eye foreign body - not otherwise specified`,
  [`Corpo libero nel gomito`]: `Loose Body in Elbow`,
  [`Correndo`]: `Running`,
  [`Correndo il più velocemente possibile`]: `Running as fast as you can`,
  [`Correre`]: `Running`,
  [`Correre in linea retta`]: `Run straight ahead`,
  [`Correre su un terreno piatto (regolare)`]: `Running on even ground`,
  [`Correre su un terreno scosceso (irregolare)`]: `Running on uneven ground`,
  [`Correre velocemente a zig-zag`]: `Making sharp turns while running fast`,
  [`corsa`]: `Running`,
  [`Corsa`]: `Running`,
  [`Coscia anteriore`]: `Anterior thigh`,
  [`COSCIA DESTRA`]: `RIGHT THIGH`,
  [`Coscia posteriore`]: `Posterior thigh`,
  [`Coscia poteriore`]: `Posterior thigh`,
  [`COSCIA SINISTRA`]: `LEFT THIGH`,
  [`Cosi giu' di morale che niente avrebbe potuto tirarla su`]: `Have you felt so down in the dumps that nothing could cheer you up?`,
  [`Costa cervicale`]: `Cervical rib`,
  [`Costantemente`]: `Constantly`,
  [`Costoclavicular Test`]: `Costoclavicular Test`,
  [`costocondrite`]: `Costochondritis`,
  [`Counter Movement Jump Test (CMJ)`]: `Counter Movement Jump Test (CMJ)`,
  [`Cozen's Test (DX)`]: `Cozen's Test (R)`,
  [`Cozen's Test (SX)`]: `Cozen's Test (L)`,
  [`crampi muscolari del polpaccio`]: `Calf muscle cramps`,
  [`Crampiforme`]: `Cramping`,
  [`Crampo agli adduttori durante l'esercizio`]: `Adductor muscle cramping during exercise`,
  [`Crampo ai muscoli della coscia durante l'esercizio`]: `Thigh muscle cramping during exercise`,
  [`Crampo al quadricipite durante l'esercizio`]: `Quadricep cramping during exercise`,
  [`Crampo muscolare addominale`]: `Abdominal muscle cramps`,
  [`Crampo muscolare degli ischiocrurali (hamstring) durante l'esercizio`]: `Hamstring cramping during exercise`,
  [`Cranio`]: `Head`,
  [`Cranio-Cervical Flexion Test (CCFT)`]: `Cranio-Cervical Flexion Test (CCFT)`,
  [`Crank Test (DX)`]: `Crank Test (R)`,
  [`Crank Test (SX)`]: `Crank Test (L)`,
  [`Credi ci sia un modo per migliorare questa condizione?`]: `Do you think there is a way to improve your condition?`,
  [`Cricket`]: `Cricket`,
  [`Cronico`]: `Chronic`,
  [`Cross-Body Adduction Test (DX)`]: `Cross-Body Adduction Test (R)`,
  [`Cross-Body Adduction Test (SX)`]: `Cross-Body Adduction Test (L)`,
  [`Crudele`]: `Cruel`,
  [`Cuban Press`]: `Cuban Press`,
  [`Cuboide (DX)`]: `Cuboid (R)`,
  [`Cuboide (SX)`]: `Cuboid (L)`,
  [`CUBOIDE DESTRO`]: `RIGHT CUBOID`,
  [`CUBOIDE SINISTRO`]: `LEFT CUBOID`,
  [`Cucinare un pasto`]: `Prepare a meal`,
  [`Culturismo`]: `Weight lifting`,
  [`Cuneiforme intermedio (DX)`]: `Intermediate cuneiform (R)`,
  [`Cuneiforme intermedio (SX)`]: `Intermediate cuneiform (L)`,
  [`CUNEIFORME INTERMEDIO DESTRO`]: `RIGHT INTERMEDIATE CUNEIFORM`,
  [`CUNEIFORME INTERMEDIO SINISTRO`]: `LEFT INTERMEDIATE CUNEIFORM`,
  [`Cuneiforme laterale (DX)`]: `Lateral cuneiform (R)`,
  [`Cuneiforme laterale (SX)`]: `Lateral cuneiform (L)`,
  [`CUNEIFORME LATERALE DESTRO`]: `RIGHT LATERAL CUNEIFORM`,
  [`CUNEIFORME LATERALE SINISTRO`]: `LEFT LATERAL CUNEIFORM`,
  [`Cuneiforme mediale (DX)`]: `Medial cuneiform (R)`,
  [`Cuneiforme mediale (SX)`]: `Medial cuneiform (L)`,
  [`CUNEIFORME MEDIALE DESTRO`]: `RIGHT MEDIAL CUNEIFORM`,
  [`CUNEIFORME MEDIALE SINISTRO`]: `LEFT MEDIAL CUNEIFORM`,
  [`Cuore`]: `Heart  `,
  [`CUORE`]: `HEART  `,
  [`CUORE (BASE)`]: `HEART (BASE)`,
  [`Cuore di atleta`]: `Athlete's heart`,
  [`Cura di sé`]: `Personal care`,
  [`Curl And Press Seated`]: `Curl and Press Seated`,
  [`CURL UP `]: `CURL UP `,
  [`Curling`]: `Curling`,
  [`Cuscinetto adiposo calcaneale (DX)`]: `Calcaneus fat pad (R)`,
  [`Cuscinetto adiposo calcaneale (SX)`]: `Calcaneus fat pad (L)`,
  [`CUTE DELL'AREA MEDIALE DELLA CAVIGLIA DESTRA`]: `SKIN OF MEDIAL AREA OF RIGHT ANKLE`,
  [`CUTE DELL'AREA MEDIALE DELLA CAVIGLIA SINISTRA`]: `SKIN OF MEDIAL AREA OF LEFT ANKLE`,
  [`CUTE DELL'AREA ACHILLEA DESTRA`]: `SKIN OF RIGHT ACHILLES AREA`,
  [`CUTE DELL'AREA ACHILLEA SINISTRA`]: `SKIN OF LEFT ACHILLES AREA`,
  [`CUTE DELL'AREA ANTERIORE DEL BRACCIO DESTRO`]: `SKIN OF ANTERIOR AREA OF RIGHT UPPER ARM`,
  [`CUTE DELL'AREA ANTERIORE DEL BRACCIO SINISTRO`]: `SKIN OF ANTERIOR AREA OF LEFT UPPER ARM`,
  [`CUTE DELL'AREA ANTERIORE DEL COLLO DESTRA`]: `SKIN OF RIGHT STERNOCLEIDOMASTOID AREA`,
  [`CUTE DELL'AREA ANTERIORE DEL COLLO SINISTRA`]: `SKIN OF LEFT STERNOCLEIDOMASTOID AREA`,
  [`CUTE DELL'AREA ANTERIORE DELL'AVAMBRACCIO DESTRO`]: `SKIN OF ANTERIOR AREA OF RIGHT FOREARM`,
  [`CUTE DELL'AREA ANTERIORE DELL'AVAMBRACCIO SINISTRO`]: `SKIN OF ANTERIOR AREA OF LEFT FOREARM`,
  [`CUTE DELL'AREA ANTERIORE DELLA COSCIA DESTRA`]: `SKIN OF ANTERIOR AREA OF RIGHT THIGH`,
  [`CUTE DELL'AREA ANTERIORE DELLA COSCIA SINISTRA`]: `SKIN OF ANTERIOR AREA OF LEFT THIGH`,
  [`CUTE DELL'AREA ANTERIOROMEDIALE DELLA GAMBA DESTRA`]: `SKIN OF ANTERIOR AREA OF RIGHT LEG`,
  [`CUTE DELL'AREA ANTERIOROMEDIALE DELLA GAMBA SINISTRA`]: `SKIN OF ANTERIOR AREA OF LEFT LEG`,
  [`CUTE DELL'AREA CERVICALE VERTEBRALE INFERIORE`]: `SKIN OF INFERIOR VERTEBRAL CERVICAL AREA`,
  [`CUTE DELL'AREA CERVICALE VERTEBRALE SUPERIORE`]: `SKIN OF SUPERIOR VERTEBRAL CERVICAL AREA`,
  [`CUTE DELL'AREA COSTALE DESTRA`]: `SKIN OF RIGHT COSTAL AREA`,
  [`CUTE DELL'AREA COSTALE SINISTRA`]: `SKIN OF LEFT COSTAL AREA`,
  [`CUTE DELL'AREA DEL FIANCO DESTRO`]: `SKIN OF RIGHT FLANK AREA`,
  [`CUTE DELL'AREA DEL FIANCO SINISTRO`]: `SKIN OF LEFT FLANK AREA`,
  [`CUTE DELL'AREA DEL POLLICE DELLA MANO DESTRA`]: `SKIN OF AREA OF RIGHT THUMB`,
  [`CUTE DELL'AREA DEL POLLICE DELLA MANO SINISTRA`]: `SKIN OF AREA OF LEFT THUMB`,
  [`CUTE DELL'AREA DELL'EPIGASTRIO`]: `SKIN OF EPIGASTRIC AREA`,
  [`CUTE DELL'AREA DELL'IPOCONDRIO DESTRO`]: `SKIN OF RIGHT HYPOCHONDRIUM`,
  [`CUTE DELL'AREA DELL'IPOCONDRIO SINISTRO`]: `SKIN OF LEFT HYPOCHONDRIUM`,
  [`CUTE DELL'AREA DELL'ORECCHIO DESTRO`]: `SKIN OF RIGHT AURICULAR AREA`,
  [`CUTE DELL'AREA DELL'ORECCHIO SINISTRO`]: `SKIN OF LEFT AURICULAR AREA`,
  [`CUTE DELL'AREA DELLA BOCCA E DEL MENTO`]: `SKIN OF ORAL AND MENTOLABIAL AREA`,
  [`CUTE DELL'AREA DELLO STERNO`]: `SKIN OF STERNAL AREA`,
  [`CUTE DELL'AREA DORSALE CENTRALE DEL POLSO DESTRO`]: `SKIN OF CENTRAL DORSAL AREA OF RIGHT WRIST`,
  [`CUTE DELL'AREA DORSALE CENTRALE DEL POLSO SINISTRO`]: `SKIN OF CENTRAL DORSAL AREA OF LEFT WRIST`,
  [`CUTE DELL'AREA DORSALE DEL MESOPIEDE DESTRO`]: `SKIN OF DORSAL AREA OF RIGHT MIDFOOT`,
  [`CUTE DELL'AREA DORSALE DEL MESOPIEDE SINISTRO`]: `SKIN OF DORSAL AREA OF LEFT MIDFOOT`,
  [`CUTE DELL'AREA DORSALE DELL'AVAMPIEDE E DELL'ALLUCE DESTRO`]: `SKIN OF DORSAL MEDIAL AREA OF RIGHT FOREFOOT`,
  [`CUTE DELL'AREA DORSALE DELL'AVAMPIEDE E DELL'ALLUCE SINISTRO`]: `CUTIS OF DORSAL MEDIAL AREA OF LEFT FOREFOOT`,
  [`CUTE DELL'AREA DORSALE LATERALE DELL'AVAMPIEDE DESTRO`]: `CUTIS OF DORSAL LATERAL AREA OF RIGHT FOREFOOT`,
  [`CUTE DELL'AREA DORSALE LATERALE DELL'AVAMPIEDE SINISTRO`]: `CUTIS OF DORSAL LATERAL AREA OF LEFT FOREFOOT`,
  [`CUTE DELL'AREA DORSALE LATERALE DELLA MANO DESTRA`]: `SKIN OF DORSAL AREA OF RIGHT HAND`,
  [`CUTE DELL'AREA DORSALE LATERALE DELLA MANO SINISTRA`]: `SKIN OF DORSAL AREA OF LEFT HAND`,
  [`CUTE DELL'AREA DORSALE RADIALE DEL POLSO DESTRO`]: `SKIN OF RADIAL DORSAL AREA OF RIGHT WRIST`,
  [`CUTE DELL'AREA DORSALE RADIALE DEL POLSO SINISTRO`]: `SKIN OF RADIAL DORSAL AREA OF LEFT WRIST`,
  [`CUTE DELL'AREA DORSALE ULNARE DESTRA`]: `SKIN OF ULNAR DORSAL AREA OF RIGHT WRIST`,
  [`CUTE DELL'AREA DORSALE ULNARE SINISTRA`]: `SKIN OF ULNAR DORSAL AREA OF LEFT WRIST`,
  [`CUTE DELL'AREA FRONTALE DESTRA`]: `SKIN OF RIGHT FRONTAL AREA`,
  [`CUTE DELL'AREA FRONTALE MEDIANA`]: `SKIN OF MEDIAN FRONTAL AREA`,
  [`CUTE DELL'AREA FRONTALE SINISTRA`]: `SKIN OF LEFT FRONTAL AREA`,
  [`CUTE DELL'AREA ILIACA DESTRA`]: `SKIN OF RIGHT ILIAC AREA`,
  [`CUTE DELL'AREA ILIACA SINISTRA`]: `RIGHT ILIOPASOAS MUSCLE`,
  [`CUTE DELL'AREA INFRAIOIDEA`]: `SKIN OF INFRAHYOID AREA`,
  [`CUTE DELL'AREA INTERPARIETALE`]: `SKIN OF SAGITTAL AREA`,
  [`CUTE DELL'AREA IPOGASTRICA`]: `SKIN OF HYPOGASTRIC AREA`,
  [`CUTE DELL'AREA LATERALE DEL GINOCCHIO DESTRO`]: `CUTIS OF LATERAL AREA OF RIGHT KNEE`,
  [`CUTE DELL'AREA LATERALE DEL GINOCCHIO SINISTRO`]: `CUTIS OF LATERAL AREA OF LEFT KNEE`,
  [`CUTE DELL'AREA LATERALE DELL'AVAMBRACCIO DESTRO`]: `SKIN OF LATERAL AREA OF RIGHT FOREARM`,
  [`CUTE DELL'AREA LATERALE DELL'AVAMBRACCIO SINISTRO`]: `SKIN OF LATERAL AREA OF LEFT FOREARM`,
  [`CUTE DELL'AREA LATERALE DELLA CAVIGLIA DESTRA`]: `SKIN OF LATERAL AREA OF RIGHT ANKLE`,
  [`CUTE DELL'AREA LATERALE DELLA CAVIGLIA SINISTRA`]: `SKIN OF LATERAL AREA OF LEFT ANKLE`,
  [`CUTE DELL'AREA LATERALE DELLA GAMBA DESTRA`]: `SKIN OF LATERAL AREA OF RIGHT LEG`,
  [`CUTE DELL'AREA LATERALE DELLA GAMBA SINISTRA`]: `SKIN OF LATERAL AREA OF LEFT LEG`,
  [`CUTE DELL'AREA LOMBARE PARAVERTEBRALE DESTRA`]: `SKIN OF RIGHT PARAVERTEBRAL LUMBAR AREA`,
  [`CUTE DELL'AREA LOMBARE PARAVERTEBRALE SINISTRA`]: `SKIN OF LEFT PARAVERTEBRAL LUMBAR AREA`,
  [`CUTE DELL'AREA LOMBARE VERTEBRALE`]: `SKIN OF VERTEBRAL LUMBAR AREA`,
  [`CUTE DELL'AREA MASCELLAREZIGOMATICA DESTRA`]: `SKIN OF RIGHT ZYGOMATIC AREA`,
  [`CUTE DELL'AREA MASCELLAREZIGOMATICA SINISTRA`]: `SKIN OF LEFT ZYGOMATIC AREA`,
  [`CUTE DELL'AREA MEDIALE DEL GOMITO DESTRO`]: `SKIN OF MEDIAL AREA OF RIGHT ELBOW`,
  [`CUTE DELL'AREA MEDIALE DEL GOMITO SINISTRO`]: `SKIN OF MEDIAL AREA OF LEFT ELBOW`,
  [`CUTE DELL'AREA NASALE`]: `SKIN OF NASAL AREA`,
  [`CUTE DELL'AREA NUCALE DESTRA`]: `SKIN OF RIGHT NUCHAL AREA`,
  [`CUTE DELL'AREA NUCALE SINISTRA`]: `SKIN OF LEFT NUCHAL AREA`,
  [`CUTE DELL'AREA OCCIPITALE`]: `SKIN OF OCCIPITAL AREA`,
  [`CUTE DELL'AREA OCCIPITOMASTOIDEA DESTRA`]: `SKIN OF RIGHT MASTOID AREA`,
  [`CUTE DELL'AREA OCCIPITOMASTOIDEA SINISTRA`]: `SKIN OF LEFT MASTOID AREA`,
  [`CUTE DELL'AREA OMBELICALE`]: `SKIN OF UMBILICAL AREA`,
  [`CUTE DELL'AREA PALMARE CENTRALE DEL POLSO DESTRO`]: `SKIN OF CENTRA PALMAR AREA OF RIGHT WRIST`,
  [`CUTE DELL'AREA PALMARE CENTRALE DEL POLSO SINISTRO`]: `SKIN OF CENTRA PALMAR AREA OF LEFT WRIST`,
  [`CUTE DELL'AREA PALMARE DELLA MANO DESTRA`]: `SKIN OF PALMAR AREA OF RIGHT HAND`,
  [`CUTE DELL'AREA PALMARE DELLA MANO SINISTRA`]: `SKIN OF PALMAR AREA OF LEFT HAND`,
  [`CUTE DELL'AREA PALMARE RADIALE DEL POLSO DESTRO`]: `SKIN OF RADIAL PALMAR AREA OF RIGHT WRIST`,
  [`CUTE DELL'AREA PALMARE RADIALE DEL POLSO SINISTRO`]: `SKIN OF RADIAL PALMAR AREA OF LEFT WRIST`,
  [`CUTE DELL'AREA PALMARE ULNARE DEL POLSO DESTRO`]: `SKIN OF ULNAR PALMAR AREA OF RIGHT WRIST`,
  [`CUTE DELL'AREA PALMARE ULNARE DEL POLSO SINISTRO`]: `SKIN OF ULNAR PALMAR AREA OF LEFT WRIST`,
  [`CUTE DELL'AREA PERINEALE`]: `SKIN OF PERINEAL TRIANGLE`,
  [`CUTE DELL'AREA PETTORALE DESTRA`]: `SKIN OF RIGHT PECTORAL AREA`,
  [`CUTE DELL'AREA PETTORALE SINISTRA`]: `SKIN OF LEFT PECTORAL AREA`,
  [`CUTE DELL'AREA PLANTARE DEL MESOPIEDE DESTRO`]: `SKIN OF PLANTAR AREA OF RIGHT MIDFOOT`,
  [`CUTE DELL'AREA PLANTARE DEL MESOPIEDE SINISTRO`]: `SKIN OF PLANTAR AREA OF LEFT MIDFOOT`,
  [`CUTE DELL'AREA PLANTARE DEL RETROPIEDE DESTRO`]: `SKIN OF PLANTAR AREA OF RIGHT REARFOOT`,
  [`CUTE DELL'AREA PLANTARE DEL RETROPIEDE SINISTRO`]: `SKIN OF PLANTAR AREA OF LEFT REARFOOT`,
  [`CUTE DELL'AREA PLANTARE LATERALE DELL'AVAMPIEDE DESTRO`]: `SKIN OF PLANTAR LATERAL AREA OF RIGHT FOOT`,
  [`CUTE DELL'AREA PLANTARE LATERALE DELL'AVAMPIEDE SINISTRO`]: `SKIN OF PLANTAR LATERAL AREA OF LEFT FOOT`,
  [`CUTE DELL'AREA PLANTARE MEDIALE DELL'AVAMPIEDE DESTRO`]: `SKIN OF PLANTAR MEDIAL AREA OF RIGHT FOREFOOT`,
  [`CUTE DELL'AREA PLANTARE MEDIALE DELL'AVAMPIEDE SINISTRO`]: `SKIN OF PLANTAR MEDIAL AREA OF LEFT FOREFOOT`,
  [`CUTE DELL'AREA POSTERIORE DEL BRACCIO DESTRO`]: `SKIN OF POSTERIOR AREA OF RIGHT ARM`,
  [`CUTE DELL'AREA POSTERIORE DEL BRACCIO SINISTRO`]: `SKIN OF POSTERIOR AREA OF LEFT ARM`,
  [`CUTE DELL'AREA POSTERIORE DEL COLLO DESTRA`]: `SKIN OF RIGHT PARAVERTEBRAL CERVICAL AREA`,
  [`CUTE DELL'AREA POSTERIORE DEL COLLO SINISTRA`]: `SKIN OF LEFT PARAVERTEBRAL CERVICAL AREA`,
  [`CUTE DELL'AREA POSTERIORE DEL GINOCCHIO DESTRO`]: `SKIN OF POSTERIOR AREA OF RIGHT KNEE`,
  [`CUTE DELL'AREA POSTERIORE DEL GINOCCHIO SINISTRO`]: `SKIN OF POSTERIOR AREA OF LEFT KNEE`,
  [`CUTE DELL'AREA POSTERIORE DEL GOMITO DESTRO`]: `SKIN OF POSTERIOR AREA OF RIGHT ELBOW`,
  [`CUTE DELL'AREA POSTERIORE DEL GOMITO SINISTRO`]: `SKIN OF POSTERIOR AREA OF LEFT ELBOW`,
  [`CUTE DELL'AREA POSTERIORE DELL'AVAMBRACCIO DESTRO`]: `SKIN OF POSTERIOR AREA OF RIGHT FOREARM`,
  [`CUTE DELL'AREA POSTERIORE DELL'AVAMBRACCIO SINISTRO`]: `SKIN OF POSTERIOR AREA OF LEFT FOREARM`,
  [`CUTE DELL'AREA POSTERIORE DELLA CAVIGLIA DESTRA`]: `SKIN OF POSTERIOR AREA OF RIGHT ANKLE`,
  [`CUTE DELL'AREA POSTERIORE DELLA CAVIGLIA SINISTRA`]: `SKIN OF POSTERIOR AREA OF LEFT ANKLE`,
  [`CUTE DELL'AREA POSTERIORE DELLA GAMBA DESTRA`]: `SKIN OF POSTERIOR AREA OF RIGHT LEG`,
  [`CUTE DELL'AREA POSTERIORE DELLA GAMBA SINISTRA`]: `SKIN OF POSTERIOR AREA OF LEFT LEG`,
  [`CUTE DELL'AREA POSTERIORE DELLA SPALLA SINISTRA`]: `SKIN OF LEFT SCAPULAR AREA`,
  [`CUTE DELL'AREA POTERIORE DELLA SPALLA DESTRA`]: `SKIN OF RIGHT SCAPULAR AREA`,
  [`CUTE DELL'AREA SACROCOCCIGEA`]: `SKIN OF SACRAL AREA`,
  [`CUTE DELL'AREA SACROILIACA DESTRA`]: `SKIN OF RIGHT SACROILIAC AREA`,
  [`CUTE DELL'AREA SACROILIACA SINISTRA`]: `SKIN OF LEFT SACROILIAC AREA`,
  [`CUTE DELL'AREA SOPRAIOIDEA DESTRA`]: `SKIN OF RIGHT SUPRAHYOID AREA`,
  [`CUTE DELL'AREA SOPRAIOIDEA SINISTRA`]: `SKIN OF LEFT SUPRAHYOID AREA`,
  [`CUTE DELL'AREA TEMPORALE DESTRA`]: `SKIN OF RIGHT TEMPORAL AREA`,
  [`CUTE DELL'AREA TEMPORALE SINISTRA`]: `SKIN OF LEFT TEMPORAL AREA`,
  [`CUTE DELL'AREA TEMPOROMANDIBOLARE DESTRA`]: `SKIN OF RIGHT TEMPOROMANDIBULAR AREA`,
  [`CUTE DELL'AREA TEMPOROMANDIBOLARE SINISTRA`]: `SKIN OF LEFT TEMPOROMANDIBULAR AREA`,
  [`CUTE DELL'AREA TORACICA INFERIORE DESTRA`]: `SKIN OF LEFT PARAVERTEBRAL INFERIOR THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA INFERIORE VERTEBRALE`]: `SKIN OF VERTEBRAL INFERIOR THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA MEDIA DI DESTRA`]: `CUTIS OF RIGHT PARAVERTEBRAL INTERMEDIATE THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA MEDIA DI SINISTRA`]: `CUTIS OF LEFT PARAVERTEBRAL INTERMEDIATE THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA MEDIA VERTEBRALE`]: `CUTIS OF VERTEBRAL INTERMEDIATE THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA SUPERIORE DI DESTRA`]: `SKIN OF RIGHT PRAVERTEBRAL SUPERIOR THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA SUPERIORE DI SINISTRA`]: `CUTIS OF LEFT PRAVERTEBRAL SUPERIOR THORACIC AREA`,
  [`CUTE DELL'AREA TORACICA SUPERIORE VERTEBRALE`]: `SKIN OF VERTEBRAL SUPERIOR THORACIC AREA`,
  [`CUTE DELL'AREA UROGENITALE`]: `SKIN OF UROGENITAL TRIANGLE`,
  [`CUTE DELLA REGIONE ANTERIORE DEL GINOCCHIO DESTRO`]: `SKIN OF ANTERIOR AREA OF RIGHT KNEE`,
  [`CUTE DELLA REGIONE ANTERIORE DEL GINOCCHIO SINISTRO`]: `SKIN OF ANTERIOR AREA OF LEFT KNEE`,
  [`CUTE DELLA REGIONE ANTERIORE DEL GOMITO DESTRO`]: `SKIN OF ANTERIOR AREA OF RIGHT ELBOW`,
  [`CUTE DELLA REGIONE ANTERIORE DEL GOMITO SINISTRO`]: `SKIN OF ANTERIOR AREA OF LEFT ELBOW`,
  [`CUTE DELLA REGIONE ANTERIORE DELLA CAVIGLIA DESTRA`]: `SKIN OF ANTERIOR AREA OF RIGHT ANKLE`,
  [`CUTE DELLA REGIONE ANTERIORE DELLA CAVIGLIA SINISTRA`]: `SKIN OF ANTERIOR AREA OF LEFT ANKLE`,
  [`CUTE DELLA REGIONE ANTEROMEDIALE DELL'ANCA DESTRA`]: `CUTIS OF RIGHT HIP AREA`,
  [`CUTE DELLA REGIONE ANTEROMEDIALE DELL'ANCA SINISTRA`]: `SKIN OF LEFT HIP AREA`,
  [`CUTE DELLA REGIONE DELL'OCCHIO DESTRO`]: `SKIN OF RIGHT ORBITAL AREA`,
  [`CUTE DELLA REGIONE DELL'OCCHIO SINISTRO`]: `SKIN OF LEFT ORBITAL AREA`,
  [`CUTE DELLA REGIONE LATERALE DEL GOMITO DESTRO`]: `SKIN OF LATERAL AREA OF RIGHT ELBOW`,
  [`CUTE DELLA REGIONE LATERALE DEL GOMITO SINISTRO`]: `SKIN OF LATERAL AREA OF LEFT ELBOW`,
  [`CUTE DELLA REGIONE LATERALE DELLA COSCIA DESTRA`]: `SKIN OF LATERAL AREA OF RIGHT THIGH`,
  [`CUTE DELLA REGIONE LATERALE DELLA COSCIA SINISTRA`]: `SKIN OF LATERAL AREA OF LEFT THIGH`,
  [`CUTE DELLA REGIONE LATERALE DELLA SPALLA DESTRA`]: `SKIN OF RIGHT DELTOID AREA`,
  [`CUTE DELLA REGIONE LATERALE DELLA SPALLA SINISTRA`]: `SKIN OF LEFT DELTOID AREA`,
  [`CUTE DELLA REGIONE MEDIALE DEL GINOCCHIO DESTRO`]: `SKIN OF MEDIAL AREA OF LEFT KNEE`,
  [`CUTE DELLA REGIONE MEDIALE DELLA COSCIA DESTRA`]: `SKIN OF MEDIAL AREA OF RIGHT THIGH`,
  [`CUTE DELLA REGIONE MEDIALE DELLA COSCIA SINISTRA`]: `SKIN OF MEDIAL AREA OF LEFT THIGH`,
  [`CUTE DELLA REGIONE POSTERIORE DELL'ANCA DESTRA`]: `SKIN OF RIGHT GLUTEAL AREA`,
  [`CUTE DELLA REGIONE POSTERIORE DELL'ANCA SINISTRA`]: `CUTIS OF LEFT GLUTEAL AREA`,
  [`CUTE DELLA REGIONE POSTERIORE DELLA COSCIA DESTRA`]: `SKIN OF POSTERIOR AREA OF RIGHT THIGH`,
  [`CUTE DELLA REGIONE POSTERIORE DELLA COSCIA SINISTRA`]: `SKIN OF POSTERIOR AREA OF LEFT THIGH`,
  [`da due settimane`]: `Since 2 weeks`,
  [`da due settimane,`]: `Since 2 weeks`,
  [`Da Plank 3 Appoggi A Side Plank Con Rotazione`]: `1 LEG SIDE PLANK ROTATION`,
  [`Da Plank Gomiti A Plank Alto`]: `ELBOW PLANK TO PLANK`,
  [`Da Plank Gomiti A Side Plank`]: `ELBOW PLANK TO SIDE PLANK`,
  [`da pochi giorni`]: `Since few days`,
  [`Da quanto tempo`]: `How long?`,
  [`da una settimana`]: `Since a week`,
  [`Danneggiamento della cartilagine articolare del ginocchio`]: `Knee articular cartilage damage`,
  [`Danno a un organo della testa (comprese le lesioni a occhio, orecchio, bocca; esclusi: ematomi, ferite)`]: `Head Organ Damage `,
  [`Danno agli organi del collo`]: `Neck Organ Damage`,
  [`DASH (QUESTIONARIO PER BRACCIO, SPALLA, MANO)`]: `DASH (DISABILITIES OF THE ARM SHULDER AND HAND SCORE)`,
  [`Data approssimativa`]: `Approximate date`,
  [`Dead Bu Estensione 1 Braccio`]: `SINGLE ARM DEAD BUG`,
  [`Dead Bug Estenione Gamba Braccio Stesso Lato`]: `DEAD BUG SAME SIDE`,
  [`Dead Bug Estensione 1 Gamba`]: `SINGLE LEG DEAD BUG`,
  [`Dead Bug Estensione 2 Braccia`]: `2 ARMS DEAD BUG`,
  [`Dead Bug Estensione 2 Gambe`]: `2 LEGS DEAD BUG`,
  [`Dead Bug Estensione Braccia Gambe Opposte Alternate`]: `ALTERNATING DEAD BUG`,
  [`Dead Bug Estensione Gamba Braccio Lato Opposto`]: `1 SIDE DEAD BUG`,
  [`Dead Bug Iso`]: `ISOMETRIC DEAD BUG`,
  [`Dead Bug Iso Estensione 1 Gamba`]: `ISOMETRIC 1 LEG DEAD BUG`,
  [`Dead Bug Iso Estensione Braccia Alternate`]: `DEAD BUG ALTERNATING ARMS`,
  [`Dead Bug Iso Estensione Braccia Gambe`]: `FULL DEAD BUG`,
  [`Dead Bug Iso Estensione Gambe Alternate`]: `ALTERNATING LEGS DEAD BUG`,
  [`Deadlift`]: `Deadlift`,
  [`Deadlift Hex Bar`]: `Deadlift Hex Bar`,
  [`Debolezza al braccio, alla spalla o alla mano`]: `Weakness in your arm, shoulder or hand`,
  [`Debolezza improvvisa`]: `Sudden weakness`,
  [`Debolezza muscolare`]: `Muscle weakness`,
  [`Decisamente migliore adesso rispetto a un anno fa`]: `Much better than one year ago`,
  [`Decisamente peggiore adesso rispetto a un anno fa`]: `Much worse than one year ago`,
  [`Decline Push Up`]: `Decline Push Up`,
  [`Decompression sickness`]: `Decompression sickness`,
  [`Decompressione articolazione del ginocchio (DX)`]: `Knee joint decompression (R)`,
  [`Decompressione articolazione del ginocchio (SX)`]: `Knee joint decompression (L)`,
  [`Decompressione articolazione dell'anca (DX)`]: `Hip joint decompression (R)`,
  [`Decompressione articolazione dell'anca (SX)`]: `Hip joint decompression (L)`,
  [`Decompressione articolazione della caviglia (DX)`]: `Ankle joint decompression (R)`,
  [`Decompressione articolazione della caviglia (SX)`]: `Ankle joint decompression (L)`,
  [`Decompressione articolazione omeroradiale (DX)`]: `Humeroradial joint decompression (R)`,
  [`Decompressione articolazione omeroradiale (SX)`]: `Humeroradial joint decompression (L)`,
  [`Decompressione articolazione omeroulnare (DX)`]: `Humeroulnar joint decompression (R)`,
  [`Decompressione articolazione omeroulnare (SX)`]: `Humeroulnar joint decompression (L)`,
  [`Decompressione articolazione sottoastragalica (DX)`]: `Subtalar joint decompression (R)`,
  [`Decompressione articolazione sottoastragalica (SX)`]: `Subtalar joint decompression (L)`,
  [`Decompressione scapola (DX)`]: `Scapula decompression (R)`,
  [`Decompressione scapola (SX)`]: `Scapula decompression (L)`,
  [`Defcit di ROM attivo`]: `Varus`,
  [`defecare`]: `Defecating`,
  [`Deficienza di ferro relazionata con esercizio`]: `Exercise-related iron deficiency`,
  [`Deficit di flessibilità`]: `Flexibility restriction`,
  [`Deficit di flessione dorsale`]: `Dorsal flexion restriction`,
  [`Deficit di ROM attivo`]: `aROM restriction`,
  [`Deficit di ROM attivo non specificato`]: `aROM restriction`,
  [`Deficit di ROM contro resistenza`]: `rROM restriction`,
  [`Deficit di ROM contro resistenza non specificato`]: `rROM restriction`,
  [`Deficit di ROM passivo`]: `pROM restriction`,
  [`Deficit di ROM passivo non specificato`]: `pROM restriction`,
  [`Definisca, per ogni domanda, qual è la risposta che definisce meglio lo stato attuale`]: `For each question, which answer better define your actual status?`,
  [`Definisca, per ogni gruppo, qual è la parola che indica al meglio il dolore attuale`]: `For each group, which word better define your actual status?`,
  [`Definito`]: `Sharp`,
  [`Definizione della pressione`]: `Incisive pressure`,
  [`DeformitÃ a boutonniere`]: `Boutonniere deformity`,
  [`Deformità a boutonniere`]: `Boutonniere deformity`,
  [`Deformita' a boutonniere`]: `Boutonniere deformity`,
  [`Degenerazione del disco della colonna cervicale`]: `Cervical disc degeneration`,
  [`Degenerazione del disco L4/L5`]: `Degenerative L4/L5 disc disease`,
  [`Degenerazione del disco L5/S1`]: `Degenerative L5/S1 disc disease`,
  [`Degenerazione del disco lombare a vari livelli`]: `Degenerative disc disease multiple levels lumbar spine`,
  [`degenerazione dell'articolazione piso-piramidale`]: `piso-triquetral joint degeneration`,
  [`Degenerazione discale`]: `Disc degeneration`,
  [`Del tutto`]: `Totally`,
  [`Deltoid Extension Lag Sign (DX)`]: `Deltoid Extension Lag Sign (R)`,
  [`Deltoid Extension Lag Sign (SX)`]: `Deltoid Extension Lag Sign (L)`,
  [`Demenza`]: `Dementia`,
  [`Dentale`]: `Dental  `,
  [`DENTE CANINO INFERIORE DESTRO`]: `RIGHT INFERIOR CANINE TOOTH`,
  [`DENTE CANINO INFERIORE SINISTRO`]: `LEFT INFERIOR CANINE TOOTH`,
  [`DENTE CANINO SUPERIORE DESTRO`]: `RIGHT SUPERIOR CANINE TOOTH`,
  [`DENTE CANINO SUPERIORE SINISTRO`]: `LEFT SUPERIOR CANINE TOOTH`,
  [`Dente del giudizio`]: `Wisdom tooth`,
  [`DENTE INCISIVO CENTRALE INFERIORE DESTRO`]: `RIGHT CENTRAL INFERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO CENTRALE INFERIORE SINISTRO`]: `LEFT CENTRAL INFERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO CENTRALE SUPERIORE DESTRO`]: `RIGHT CENTRAL SUPERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO CENTRALE SUPERIORE SINISTRO`]: `LEFT CENTRAL SUPERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO LATERALE INFERIORE DESTRO`]: `RIGHT LATERAL INFERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO LATERALE INFERIORE SINISTRO`]: `LEFT LATERAL INFERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO LATERALE SUPERIORE DESTRO`]: `RIGHT LATERAL SUPERIOR INCISOR TOOTH`,
  [`DENTE INCISIVO LATERALE SUPERIORE SINISTRO`]: `LEFT LATERAL SUPERIOR INCISOR TOOTH`,
  [`DENTE PRIMO MOLARE INFERIORE DESTRO`]: `RIGHT FIRST INFERIOR MOLAR TOOTH`,
  [`DENTE PRIMO MOLARE INFERIORE SINISTRO`]: `LEFT FIRST INFERIOR MOLAR TOOTH`,
  [`DENTE PRIMO MOLARE SUPERIORE DESTRO`]: `RIGHT FIRST SUPERIOR MOLAR TOOTH`,
  [`DENTE PRIMO MOLARE SUPERIORE SINISTRO`]: `LEFT FIRST SUPERIOR MOLAR TOOTH`,
  [`DENTE PRIMO PREMOLARE INFERIORE DESTRO`]: `RIGHT FIRST INFERIOR PREMOLAR TOOTH`,
  [`DENTE PRIMO PREMOLARE INFERIORE SINISTRO`]: `LEFT FIRST INFERIOR PREMOLAR TOOTH`,
  [`DENTE PRIMO PREMOLARE SUPERIORE DESTRO`]: `RIGHT FIRST SUPERIOR PREMOLAR TOOTH`,
  [`DENTE PRIMO PREMOLARE SUPERIORE SINISTRO`]: `LEFT FIRST SUPERIOR PREMOLAR TOOTH`,
  [`DENTE SECONDO MOLARE INFERIORE DESTRO`]: `RIGHT SECOND INFERIOR MOLAR TOOTH`,
  [`DENTE SECONDO MOLARE INFERIORE SINISTRO`]: `LEFT SECOND INFERIOR MOLAR TOOTH`,
  [`DENTE SECONDO MOLARE SUPERIORE DESTRO`]: `RIGHT SECOND SUPERIOR MOLAR TOOTH`,
  [`DENTE SECONDO MOLARE SUPERIORE SINISTRO`]: `LEFT SECOND SUPERIOR MOLAR TOOTH`,
  [`DENTE SECONDO PREMOLARE INFERIORE DESTRO`]: `RIGHT SECOND INFERIOR PREMOLAR TOOTH`,
  [`DENTE SECONDO PREMOLARE INFERIORE SINISTRO`]: `LEFT SECOND INFERIOR PREMOLAR TOOTH`,
  [`DENTE SECONDO PREMOLARE SUPERIORE DESTRO`]: `RIGHT SECOND SUPERIOR PREMOLAR TOOTH`,
  [`DENTE SECONDO PREMOLARE SUPERIORE SINISTRO`]: `LEFT SECOND SUPERIOR PREMOLAR TOOTH`,
  [`DENTE TERZO MOLARE INFERIORE DESTRO`]: `RIGHT THIRD INFERIOR MOLAR TOOTH`,
  [`DENTE TERZO MOLARE INFERIORE SINISTRO`]: `LEFT THIRD INFERIOR MOLAR TOOTH`,
  [`DENTE TERZO MOLARE SUPERIORE DESTRO`]: `RIGHT THIRD SUPERIOR MOLAR TOOTH`,
  [`DENTE TERZO MOLARE SUPERIORE SINISTRO`]: `LEFT THIRD SUPERIOR MOLAR TOOTH`,
  [`Depressione`]: `Depression`,
  [`Depressione della scapola`]: `Scapula depression`,
  [`Depressione mandibola`]: `Mandible depression`,
  [`Depressione scapola`]: `Scapula depression`,
  [`Dermatite`]: `Dermatitis`,
  [`Dermatite atopica`]: `Atopic dermatitis`,
  [`Dermatite/cellulite/ascesso`]: `Skin Infection/Cellulitis/abscess`,
  [`Dermatologico`]: `Dermatological `,
  [`Descrizione`]: `Pain description`,
  [`Deviazione laterale sinistra`]: `Left lateral deviation`,
  [`Deviazione articolazione radiale del polso (DX)`]: `Wrist joint radial deviation (R)`,
  [`Deviazione articolazione radiale del polso (SX)`]: `Wrist joint radial deviation (L)`,
  [`Deviazione laterale`]: `Lateral deviation`,
  [`Deviazione laterale della mandibola durante l'apertura`]: `Lateral deviation of mandible during mouth opening`,
  [`Deviazione laterale destra`]: `Right lateral deviation`,
  [`Deviazione laterale destra mandibola`]: `Mandible right lateral deviation`,
  [`Deviazione laterale sinistra`]: `Left lateral deviation`,
  [`Deviazione laterale sinistra mandibola`]: `Mandible left lateral deviation`,
  [`Deviazione mediale`]: `Medial deviation`,
  [`Deviazione radiale`]: `Radial deviation`,
  [`Deviazione radiale articolazione del polso (DX)`]: `Wrist joint radial deviation (R)`,
  [`Deviazione radiale articolazione del polso (SX)`]: `Wrist joint radial deviation (L)`,
  [`Deviazione radiale delle interfalangee`]: `Interphalangeal joints radial deviation`,
  [`Deviazione ulnare`]: `Ulnar deviation`,
  [`Deviazione ulnare articolazione del polso (DX)`]: `Wrist joint ulnar deviation (R)`,
  [`Deviazione ulnare articolazione del polso (SX)`]: `Wrist joint ulnar deviation (L)`,
  [`Deviazione ulnare delle interfalangee`]: `Interphalangeal joints ulnar deviation`,
  [`Deviazone radiale delle interfalangee`]: `Interphalangeal joints radial deviation`,
  [`Di media`]: `Moderate`,
  [`Di media intensità`]: `Moderate`,
  [`Di notte, a letto`]: `At night while in bed  `,
  [`Di notte, mentre riposa a letto (dolore che disturba o impedisce il sonno)`]: `At night while in bed (pain that disturb your sleep)`,
  [`Di seguito vi è un elenco di frasi riguardanti sintomi o difficoltà di utilizzo dell'arto superiore. Per ciascuna di esse, scelga la risposta che più rappresenta la Sua situazione, facendo riferimento agli ultimi giorni`]: `Your upper limb (arm) may make it difficult to do some of the things you normally do. This list contains sentences people often use to describe themselves when they have such problems. Think of yourself over the last few days. If an item describes you, mark the box. If not, leave the box blank. DUE TO MY ARM:`,
  [`Di seguito vi è un elenco di frasi riguardanti sintomi o difficoltà di utilizzo dell'arto superiore. Per ciascuna di esse, scelga la risposta che più rappresenta la Sua situazione, facendo riferimento agli ultimi giorni.`]: `Your upper limb (arm) may make it difficult to do some of the things you normally do. This list contains sentences people often use to describe themselves when they have such problems. Think of yourself over the last few days. If an item describes you, mark the box. If not, leave the box blank. DUE TO MY ARM: `,
  [`Diabete`]: `Diabetes`,
  [`Diabete di tipo I`]: `Type 1 diabetes`,
  [`Diabete di tipo II`]: `Type 2 diabetes`,
  [`DIAFRAMMA (CENTRO FRENICO)`]: `DIAPHRAGM (CENTRAL TENDON)`,
  [`DIAFRAMMA DELLA SELLA`]: `SELLAR DIAPHRAGM`,
  [`Diagnosi incerta/sconosciuta`]: `Diagnosis uncertain/unrecorded`,
  [`Diagnosi medica`]: `Diagnosis`,
  [`Dial Test (DX)`]: `Dial Test (R)`,
  [`Dial Test (SX)`]: `Dial Test (L)`,
  [`Diametro del ginocchio (DX)`]: `Knee diameter (R)`,
  [`Diametro del ginocchio (SX)`]: `Knee diameter (L)`,
  [`Diametro del gomito (DX)`]: `Elbow diameter (R)`,
  [`Diametro del gomito (SX)`]: `Elbow diameter (L)`,
  [`Diapason (Tuning Fork) Assessment for wrist (DX)`]: `Diapason (Tuning Fork) Assessment for wrist (R)`,
  [`Diapason (Tuning Fork) Assessment for wrist (SX)`]: `Diapason (Tuning Fork) Assessment for wrist (L)`,
  [`Diapason and Stethoscope for Fractures (DX)`]: `Diapason and Stethoscope for Fractures (R)`,
  [`Diapason and Stethoscope for Fractures (SX)`]: `Diapason and Stethoscope for Fractures (L)`,
  [`Diapason and Stethoscope for Humeral Fractures (DX)`]: `Diapason and Stethoscope for Humeral Fractures (R)`,
  [`Diapason and Stethoscope for Humeral Fractures (SX)`]: `Diapason and Stethoscope for Humeral Fractures (L)`,
  [`Diarrea`]: `Diarrhoea`,
  [`Diarrea del corridore`]: `Runner's diarrhoea`,
  [`Diastasi dei retti addominali`]: `Divarication of rectus abdominis`,
  [`Differenze sensoriali`]: `Sensory differencies`,
  [`Difficoltà a fare passi lunghi mentre cammina`]: `Difficulties to stride out when walking`,
  [`Difficoltà moderata`]: `Moderate difficulty`,
  [`Difficoltà nel divaricare al massimo le gambe`]: `Difficulties spreading legs wide apart`,
  [`Difficolta' nel dormire`]: `Trouble sleeping`,
  [`Difficolta' nell'ingoiare o nel parlare`]: `Difficulty swallowing/speaking`,
  [`Diffuso`]: `Spreading`,
  [`Dilettantistico`]: `Amateur`,
  [`Diminuiscono`]: `Decrease`,
  [`Dipendenza da esercizio fisico`]: `Exercise addiction`,
  [`Disallineamento`]: `Misalignement`,
  [`Disallineamento non specificato`]: `Misalignement`,
  [`discinesia scapolare`]: `Scapular diskynesis`,
  [`DISCO ARTICOLARE DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE DESTRA`]: `ARTICULAR DISC OF RIGHT TEMPOROMANDIBULAR JOINT`,
  [`DISCO ARTICOLARE DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE SINISTRA`]: `ARTICULAR DISC OF LEFT TEMPOROMANDIBULAR JOINT`,
  [`DISCO C1-C2`]: `INTERVERTEBRAL DISC C1-C2`,
  [`DISCO C2-C3`]: `INTERVERTEBRAL DISC C2-C3`,
  [`DISCO C3-C4`]: `INTERVERTEBRAL DISC C3-C4`,
  [`DISCO C4-C5`]: `INTERVERTEBRAL DISC C4-C5`,
  [`DISCO C5-C6`]: `INTERVERTEBRAL DISC C5-C6`,
  [`DISCO C6-C7`]: `INTERVERTEBRAL DISC C6-C7`,
  [`DISCO C7-T1`]: `INTERVERTEBRAL DISC C7-T1`,
  [`DISCO L1-L2`]: `INTERVERTEBRAL DISC L1-L2`,
  [`DISCO L2-L3`]: `INTERVERTEBRAL DISC L2-L3`,
  [`DISCO L3-L4`]: `INTERVERTEBRAL DISC L3-L4`,
  [`DISCO L4--L5`]: `INTERVERTEBRAL DISC L4--L5`,
  [`DISCO L5-S1`]: `INTERVERTEBRAL DISC L5-S1`,
  [`DISCO T1-T2`]: `T1-T2 INTERVERTEBRAL DISC`,
  [`DISCO T10-T11`]: `INTERVERTEBRAL DISC T10-T11`,
  [`DISCO T11-T12`]: `INTERVERTEBRAL DISC T11-T12`,
  [`DISCO T12-L1`]: `INTERVERTEBRAL DISC T12-L1`,
  [`DISCO T2-T3`]: `T2-T3 INTERVERTEBRAL DISC`,
  [`DISCO T3-T4`]: `T3-T4 INTERVERTEBRAL DISC`,
  [`DISCO T4-T5`]: `T4-T5 INTERVERTEBRAL DISC`,
  [`DISCO T5-T6`]: `T5-T6 INTERVERTEBRAL DISC`,
  [`DISCO T6-T7`]: `T6-T7 INTERVERTEBRAL DISC`,
  [`DISCO T7-T8`]: `T7-T8 INTERVERTEBRAL DISC`,
  [`DISCO T8-T9`]: `T8-T9 INTERVERTEBRAL DISC`,
  [`DISCO T9-T10`]: `INTERVERTEBRAL DISC T9-T10`,
  [`Discreta difficoltà`]: `Moderate difficulty`,
  [`Discreto`]: `Moderate`,
  [`Disfunzione`]: `Dysfunction`,
  [`disfunzione articolazione costo-trasversaria`]: `Costo-transverse joint dysfunction`,
  [`disfunzione articolazione costovertebrale`]: `Costo-vertebral joint dysfunction`,
  [`Disfunzione di movimento`]: `Movement dysfunction`,
  [`Disfunzione di movimento non specificata`]: `Movement dysfunction`,
  [`Disfunzione erettile non relazionata con esercizio`]: `Erectile dysfunction non-exercise related`,
  [`Disfunzione erettile relazionata con cycling/rowing`]: `Erectile dysfunction related to cycling/rowing`,
  [`disfunzione sacro-iliaca`]: `sacroiliac dysfunction`,
  [`Disfunzione somatica`]: `Somatic dysfunction`,
  [`Disfunzione somatica non specificata`]: `Somatic dysfunction`,
  [`Dislocazione della rotula con frattura da avulsione della rotula`]: `Patellar dislocation with avulsion fracture patella`,
  [`Dislocazione della testa del radio`]: `Dislocated radial head`,
  [`Dismenorrea`]: `Dysmenorrhoea`,
  [`Disordine comportamentale (alcool, droga)`]: `Behavioural disorder (alcohol, drugs)`,
  [`Disordine comportamentale (eg gambling/other addiction)`]: `Behavioural disorder (eg gambling/other addiction)`,
  [`Disordine di adattamento (incluso esaurimento)`]: `Adjustment disorder (incl burrnout)`,
  [`Disordine strutturale, deficit di flessibilita' del…(nome del muscolo nel test)`]: `Muscle flexibility restriction`,
  [`Disordine strutturale, deficit di flessibilita' delâ€¦(nome del muscolo nel test)`]: `Muscle flexibility restriction`,
  [`Disordini alimentari`]: `Alimentary disorders`,
  [`disordini delle articolazioni zigoapofisarie`]: `intervertebral joints disorder`,
  [`Disordini tiroidei`]: `Thyroid disorder`,
  [`Disordini vascolari periferici o trombosi venosa profonda`]: `Peripheral vascular disease`,
  [`Dispnea da trauma addominale`]: `Winded`,
  [`Disreflessia autonoma`]: `Autonomic dysreflexia`,
  [`Dissenteria amebica`]: `Amoebic dysentery`,
  [`dissociazione scafo-lunata`]: `scapho-lunate dissociation`,
  [`Distacco di retina`]: `Retinal detachment`,
  [`Distacco epifisario`]: `Physis injury`,
  [`Distanza ad alta velocità`]: `High speed distance`,
  [`Distanza in Sprint`]: `Sprint distance`,
  [`Distanza totale`]: `Total distance`,
  [`Distorsione a localizzazione multipla`]: `Sprain multiple locations`,
  [`Distorsione a localizzazione non specificata`]: `Sprain location unspecified`,
  [`Distorsione al piede`]: `Foot Dislocation`,
  [`Distorsione anteriore dell'articolazione sternoclavicolare`]: `Anterior sternoclavicular (S/C) joint sprain`,
  [`Distorsione articolare`]: `Joint sprain`,
  [`Distorsione articolare (lesione legamentosa o instabilita' acuta)`]: `Joint sprain (ligament tear or acute instability episode)`,
  [`Distorsione articolare del polso`]: `Sprained/jarred wrist joint`,
  [`Distorsione articolare della colonna lombare`]: `Lumbar region ligament sprain`,
  [`Distorsione articolare/legamentosa dell'articolazione tarsale trasversa (Lisfranc)`]: `Midfoot joint/ligament sprain (incl Lisfranc)`,
  [`Distorsione articolazione facciale`]: `Facial Joint sprain/injury`,
  [`Distorsione del disco cervicale`]: `Cervical Disc sprain`,
  [`Distorsione del ginocchio con complicanza neurologica o vascolare`]: `Knee dislocation with neural or vascular complication`,
  [`Distorsione del ginocchio/lesione dei legamenti`]: `Knee Sprains/Ligament Injuries`,
  [`Distorsione del gomito`]: `Sprained/jarred elbow`,
  [`Distorsione del legamento collaterale laterale della caviglia`]: `Sprain lateral collateral ligament ankle`,
  [`Distorsione del legamento collaterale mediale (deltoideo) della caviglia`]: `Sprain medial collateral (deltoid) ligament ankle`,
  [`Distorsione del mesopiede delle articolazioni tarsometatarsali`]: `Dislocation of midfoot through TMT joints`,
  [`Distorsione del pollice`]: `Thumb sprain`,
  [`Distorsione dell'alluce`]: `Sprain of great toe`,
  [`Distorsione dell'alluce/'turf toe'`]: `Sprained toe/'turf toe'`,
  [`Distorsione dell'articolazione acromion-clavicolare`]: `Acromioclavicular (A/C) joint sprain`,
  [`Distorsione dell'articolazione carpometacarpale del pollice`]: `Thumb CMC joint sprain`,
  [`Distorsione dell'articolazione dell'anca`]: `Hip joint sprain/jar`,
  [`Distorsione dell'articolazione glenoomerale`]: `Glenohumeral joint sprains`,
  [`Distorsione dell'articolazione interfalangea del pollice`]: `Thumb IP joint sprain`,
  [`distorsione dell'articolazione medio-tarsica (Chopart)`]: `midtarsal joint sprain`,
  [`Distorsione dell'articolazione metacarpofalangea`]: `Metacarpophalangeal joint sprain`,
  [`distorsione dell'articolazione metatarso-falangea dell'alluce (turf toe)`]: `hallux metatarso-phalangeal joint sprain (turf toe)`,
  [`Distorsione dell'articolazione sternoclavicolare`]: `Sternoclavicular joint sprains`,
  [`distorsione dell'articolazione tarso-metatarsale (Lisfranc)`]: `tarsometatarsal joint sprain`,
  [`distorsione dell'articolazione tibio-peroneale superiore`]: `superior tibio-fibular joint sprain`,
  [`Distorsione dell'articolazione tibioperoneale superiore`]: `Sprained superior tibiofibular joint`,
  [`Distorsione della faccetta articolare lombare`]: `Lumbar facet joint sprain`,
  [`Distorsione della faccetta articolare toracica`]: `Thoracic facet joint sprain`,
  [`Distorsione della faccetta articolare/legamento cervicale`]: `Whiplash/neck sprain`,
  [`distorsione della mandibola`]: `Ankle sprain`,
  [`Distorsione della prima articolazione metatarsofalangea con rottura della piastra plantare`]: `Sprain 1st MTP joint with volar plate rupture`,
  [`Distorsione della sindesmosi tibioperoneale inferiore`]: `Inferior tibiofibular syndesmosis sprain`,
  [`Distorsione di articolazione della colonna`]: `Spinal joint sprain`,
  [`Distorsione di articolazione toracica`]: `Chest Joint Sprains`,
  [`distorsione di caviglia`]: `Ankle sprain`,
  [`Distorsione di caviglia`]: `Ankle sprains`,
  [`Distorsione di I grado dell'articolazione acromioclavicolare`]: `Grade 1 A/C joint sprain`,
  [`Distorsione di II grado dell'articolazione acromioclavicolare`]: `Grade 2 A/C joint sprain`,
  [`Distorsione di III grado dell'articolazione acromioclavicolare`]: `Grade 3 A/C joint dislocation`,
  [`Distorsione di IV-VI grado dell'articolazione acromioclavicolare`]: `Grade 4-6 A/C joint dislocation`,
  [`Distorsione gleno-omerale con lesione condrale/labiale (compresa la SLAP)`]: `Glenohumeral joint sprain with chondral/labral damage (incl SLAP tear)`,
  [`Distorsione mandibola/sintomi temporo-mandibolari`]: `Jaw sprain/TMJ symptoms`,
  [`Distorsione posteriore dell'articolazione sternoclavicolare`]: `Posterior sternoclavicular joint sprain`,
  [`Distorsione/sublussazione acuta della spalla`]: `Acute shoulder subluxation`,
  [`Distraction Test`]: `Distraction Test`,
  [`Distrazioni (TV, leggere, telefono, ecc)`]: `distraction (TV, reading, etc.)`,
  [`Disturbi psicologici/psichiatrici non altrimenti specificati`]: `Other psychological/psychiatric disorder not otherwise specified`,
  [`Disturbo ematologico e deficit nutrizionale`]: `Haematological Illness and Nutritional Deficiencies`,
  [`Disturbo ematologico maligno (leucemia)`]: `Haematological Malignancy`,
  [`Disturbo genito urinario`]: `Genitourinary Illness`,
  [`Disturbo oftalmico`]: `Ophthalmological Illness (excl trauma)`,
  [`Disturbo Psicolocico/psichiatrico`]: `Psychological/psychiatric`,
  [`Disturbo urinario`]: `Urinary Illness`,
  [`dita a collo di cigno`]: `Chronic swan neck deformity`,
  [`Dita a collo di cigno`]: `Swan neck deformity`,
  [`dita a martello`]: `Mallet finger`,
  [`Dita a martello`]: `Hammer toes`,
  [`Dita ad artiglio`]: `Claw toes`,
  [`dita ad asola`]: `Boutonniere deformity`,
  [`Dito a scatto`]: `Trigger Finger`,
  [`Diverticolite`]: `Diverticulitis`,
  [`divorzio`]: `Divorce`,
  [`Dix-Hallpike Test`]: `Dix-Hallpike Test`,
  [`Dizziness Test`]: `Dizziness Test`,
  [`Dolente`]: `Aching`,
  [`Dolenzia muscolare`]: `Muscle soreness`,
  [`Dolore`]: `Pain`,
  [`Dolore a salire e scendere le scale (DX)`]: `Pain going upstairs or downstairs (R)`,
  [`Dolore a salire e scendere le scale (SX)`]: `Pain going upstairs or downstairs (L)`,
  [`dolore a supinare l'avambraccio come per svitare un barattolo`]: `Pain supinating the forearm as to unscrew a jar`,
  [`dolore acuto al collo (torcicollo)`]: `acute neck pain (stiff neck)`,
  [`dolore ad ogni movimento che fai`]: `Pain at every movement`,
  [`Dolore addominale non altrimenti specificato (escluse cause mediche di dolore addominale)`]: `Abdominal pain not otherwise specified`,
  [`Dolore addominale non diagnosticato`]: `Abdominal pain undiagnosed`,
  [`Dolore al braccio, alla spalla o alla mano`]: `Arm, shoulder or hand pain`,
  [`Dolore al braccio, alla spalla o alla mano durante qualsiasi attivita'`]: `Arm, shoulder or hand pain when you performed any specific activity`,
  [`Dolore al collo non diagnosticato`]: `Neck pain undiagnosed`,
  [`Dolore al ginocchio non diagnosticato`]: `Knee pain undiagnosed`,
  [`Dolore al gluteo non altrimenti specificato`]: `Buttock Pain not otherwise specified`,
  [`Dolore al gomito non diagnosticato`]: `Elbow pain undiagnosed`,
  [`Dolore al gomito senza specifica del tipo di tessuto`]: `Elbow pain without tissue type specified`,
  [`Dolore al legamento ileolombare`]: `Iliolumbar Ligament pain`,
  [`Dolore al movimento attivo`]: `Pain at active movement`,
  [`Dolore al piede non diagnosticato`]: `Foot pain undiagnosed`,
  [`Dolore al polso o alla mano non diagnosticato`]: `Wrist or hand pain undiagnosed`,
  [`Dolore al test`]: `Pain at test`,
  [`Dolore all'anca/inguine non altrimenti specificato`]: `Hip/Groin Pain Not otherwise specified`,
  [`Dolore all'apertura massima della bocca`]: `Pain at maximal mouth opening`,
  [`Dolore all'inginocchiarsi (DX)`]: `Pain at kneeling (R)`,
  [`Dolore all'inginocchiarsi (SX)`]: `Pain at kneeling (L)`,
  [`Dolore alla coscia non altrimenti specificato`]: `Thigh Pain Not otherwise specified`,
  [`Dolore alla coscia non diagnosticato`]: `Thigh pain undiagnosed`,
  [`Dolore alla gamba non diagnosticato`]: `Lower leg pain undiagnosed`,
  [`dolore alla palpazione del polso`]: `pain at the palpation of wrist`,
  [`Dolore alla palpazione posterolaterale dell'articolazione temporomandibolare`]: `Pain at posterior lateral palpation of TMJ`,
  [`Dolore alla spalla non diagnosticato`]: `Shoulder pain undiagnosed`,
  [`Dolore alla spalla senza specifica del tipo di tessuto interessato`]: `Shoulder pain without tissue type specified`,
  [`Dolore alle natiche non diagnosticato`]: `Buttock pain undiagnosed`,
  [`Dolore allo squat (DX)`]: `Pain at squat (R)`,
  [`Dolore allo squat (SX)`]: `Pain at squat (L)`,
  [`Dolore allo stretching`]: `Pain at stretching`,
  [`dolore allo stringere la mano`]: `Pain shaking hands`,
  [`Dolore assente o lieve a riposo, presente durante attività leggere. Frequente utilizzo di analgesici`]: `Pain: None or little at rest' present during light activities; salicylates used frequently`,
  [`Dolore cervicale con segni di patologie importanti`]: `Cervical pain with signs of important pathologies`,
  [`Dolore cervicale con segni neurologici di compressione nervosa`]: `Cervical pain with nerve compression neurological signs`,
  [`Dolore cervicale funzionale`]: `Cervical functional pain`,
  [`dolore cervicale meccanico`]: `Mechanical neck pain`,
  [`Dolore cervicale senza segni di patologie maggiori e con bassa interferenza con le attivita' quotidiane`]: `Cervical pain without signs of major pathologies and with low impact on daily activities`,
  [`Dolore cervicale senza segni di patologie maggiori ma con interferenza con le attivita' quotidiane`]: `Cervical pain without signs of major pathologies but with impact on daily activities`,
  [`Dolore condrale dell'articolazione femororotulea`]: `Patellofemoral joint chondral pain`,
  [`Dolore cronico all'articolazione acromion-clavicolare`]: `A/C joint chronic pain`,
  [`Dolore cronico non specificato o funzionale all'inguine`]: `Chronic non specific /functional groin pain`,
  [`Dolore di anca/inguine non diagnosticato`]: `Groin pain undiagnosed`,
  [`Dolore femorotuleo con tendinopatia rotulea`]: `Patellofemoral pain with patellar tendinopathy`,
  [`Dolore funzionale alla caviglia`]: `Functional pain ankle`,
  [`Dolore funzionale toracico`]: `Thoracic Functional Pain`,
  [`dolore improvviso durante un movimento o un'attivita' specifica`]: `Sudden onset performing a specific movement `,
  [`Dolore lombare funzionale cronico`]: `Chronic lumbar functional pain`,
  [`Dolore lombare irradiato agli ischiocrurali`]: `Lumbar pain with hamstring referral`,
  [`Dolore lombare non specifico`]: `Lumbar pain non-specific`,
  [`dolore miofasciale, frattura da stress dell'omero, lesione muscolare del muscolo bicipite brachiale, lesione muscolare del muscolo tricipite brachiale, lesione muscolare del muscolo brachiale`]: `Myofascial pain, humerus stress fracture, biceps brachii muscle injury, triceps brachii muscle injury, brachialis muscle injury`,
  [`Dolore muscolare ritardato (DOMS) alla gamba`]: `Delayed onset muscle soreness`,
  [`Dolore occasionale e lieve`]: `Pain: Occasional and slight`,
  [`Dolore pelvico non specifico`]: `Pelvic Pain non-specific`,
  [`Dolore pelvico/alla natica non altrimenti specificato`]: `Pelvic/Buttock Pain not otherwise specified`,
  [`Dolore plantare al tallone (fasciopatia/stiramento/spina calcaneale)`]: `Plantar heel pain (fasciitis/strain/calcaneal spur)`,
  [`Dolore presente solo durante attività pesanti e/o attività particolari. Occasionale utilizzo di FANS`]: `Pain: Present during heavy or particular activities only; salicylates used occasionally`,
  [`dolore se muovi il polso con l'altra mano`]: `Pain moving the wrist with the other hand`,
  [`Dolore sempre presente ma sopportabile. Occasionale utilizzo di analgesici potenti`]: `Pain: Present always but bearable' strong medication occasionally`,
  [`Dolore sempre presente, insopportabile. Frequente utilizzo di analgesici potenti`]: `Pain: Present always and unbearable; strong medication frequently`,
  [`Dolore senza specifica del tipo di tessuto`]: `Pain without specified tissue type `,
  [`Dolore senza specificazione del tipo di tessuto`]: `Injury without tissue type specified`,
  [`Dolore si irradia in altre regioni o e' presente in altre regioni del corpo?`]: `Does the pain radiate in other areas or do you feel pain in other body areas?`,
  [`dolore toracico`]: `Thoracic pain`,
  [`Dolore toracico funzionale cronico`]: `Chronic thoracic functional pain`,
  [`Dolore toracico non diagnosticato`]: `Thoracic pain undiagnosed`,
  [`Dolore: le seguenti domande riguardano il livello di dolore percepito a livello dell'anca e/o dell'inguine che lei generalmente prova.`]: `Pain`,
  [`Dolore: misuri sulla scala da 0 a 10 l'intensità di dolore che quantifica la sintomatologia del polso/mano nell'ultima settimana. Metta una crocetta sul numero che descrive l'intensità del suo dolore nell'ultima settimana. Zero significa nessun dolore ...`]: `Rate the average amount of pain in your wrist over the past week by circling the
number that best describes your pain on a scale from 0-10. A zero (0) means that you did
not have any pain and a ten (10) means that you had the worst pain you have ever
experienced or that you could not do the activity because of pain`,
  [`Dolore: misuri sulla scala da 0 a 10 l'intensità di dolore che quantifica la sintomatologia del polso/mano nell'ultima settimana. Metta una crocetta sul numero che descrive l'intensità del suo dolore nell'ultima settimana. Zero significa nessun dolore e 10 il massimo dolore percepibile`]: `Rate the average amount of pain in your wrist over the past week by circling the
number that best describes your pain on a scale from 0-10. A zero (0) means that you did
not have any pain and a ten (10) means that you had the worst pain you have ever
experienced or that you could not do the activity because of pain`,
  [`Dolore/infortunio all'articolazione sacroiliaca`]: `Sacroiliac Joint Injury/Pain`,
  [`Dolore/infortunio alla caviglia non diagnosticato`]: `Ankle Pain/Injury undiagnosed`,
  [`Dolore/lesione al braccio non altrimenti specificata`]: `Upper Arm Pain/Injury not otherwise specified`,
  [`Dolore/lesione al ginocchio non altrimenti specificata`]: `Knee Pain/Injury Not otherwise specified`,
  [`Dolore/lesione all'avambraccio non altrimenti specificato`]: `Forearm Pain/Injury not otherwise specified`,
  [`Dolore/lesione all'avambraccio non diagnosticato`]: `Forearm Pain/Injury undiagnosed`,
  [`Dolore/lesione alla coscia non altrimenti specificata`]: `Thigh pain/Injury Not otherwise specified`,
  [`Dolore/lesione alla coscia non altrimenti specificato`]: `Thigh pain without tissue type specified`,
  [`Dolore/lesione del piede non altrimenti specificata`]: `Foot Pain/Injury Not otherwise specified`,
  [`Dolore/lesione lombare non altrimenti specificata`]: `Lumbar pain/injury not otherwise specified`,
  [`Dolore/lesione toracica non altrimenti specificato`]: `Thoracic Pain/Injury not otherwise specified`,
  [`Dolori/lesioni al collo non altrimenti specificati`]: `Neck Pain/Injury Not Otherwise Specified`,
  [`Dolori/lesioni alla spalla non altrimenti specificate`]: `Shoulder Pain/Injury not otherwise specified`,
  [`Doloroso`]: `Painful`,
  [`DOMS al braccio`]: `Upper arm DOMS`,
  [`DOMS dei muscoli del tricipite surale`]: `Calf DOMS`,
  [`Donkey Kick`]: `DONKEY KICK`,
  [`Dopo aver camminato per 30 minuti in pianura, ha dolore nelle due ore successive? (Se non e' possibile camminare per 30 minuti in pianura a causa del dolore al tendine di Achille, in questa domanda il punteggio sara' 0)`]: `After walking on flat ground for 30 minutes, do you have pain within the next 2 hours? (If unable to walk on flat ground for 30 minutes because of pain, score 0 for this question)`,
  [`Dormire o riposare`]: `sleep or rest`,
  [`Dorsal Capitate Displacement Apprehension Test (DX)`]: `Dorsal Capitate Displacement Apprehension Test (R)`,
  [`Dorsal Capitate Displacement Apprehension Test (SX)`]: `Dorsal Capitate Displacement Apprehension Test (L)`,
  [`Dorsiflexion Lunge Test (DX)`]: `Dorsiflexion Lunge Test (R)`,
  [`Dorsiflexion Lunge Test (SX)`]: `Dorsiflexion Lunge Test (L)`,
  [`Dorsiflexion-Eversion Test (DX)`]: `Dorsiflexion-Eversion Test (R)`,
  [`Dorsiflexion-Eversion Test (SX)`]: `Dorsiflexion-Eversion Test (L)`,
  [`Dorso`]: `Back`,
  [`Dorso piatto`]: `Flat back`,
  [`DOTTO TORACICO (PARTE TORACICA)`]: `THORACIC DUCT (THORACIC PART)`,
  [`Double Leg Lowering Test`]: `Double Leg Lowering Test`,
  [`Double-Leg Lowering Test`]: `Double-Leg Lowering Test`,
  [`Dove (parte del corpo)`]: `Where (body area)`,
  [`DOWNDOG TO UP DOG`]: `DOWNDOG TO UP DOG `,
  [`Drop Arm Test (DX)`]: `Drop Arm Test (R)`,
  [`Drop Arm Test (SX)`]: `Drop Arm Test (L)`,
  [`Drop Sign (DX)`]: `Drop Sign (R)`,
  [`Drop Sign (SX)`]: `Drop Sign (L)`,
  [`Drowning`]: `Drowning`,
  [`Drug-related/poisoning`]: `Drug-related/poisoning`,
  [`Dumbbell Bench Press`]: `Dumbbell Bench Press`,
  [`Dumbbell Curl To Press`]: `Dumbbell Curl To Press`,
  [`DUODENO`]: `DUODENUM`,
  [`DUODENO (PARTE ASCENDENTE)`]: `DUODENUM (ASCENDING PART)`,
  [`DUODENO (PARTE DISCENDENTE)`]: `DUODENUM (DESCENDING PART)`,
  [`DUODENO (PARTE INFERIORE)`]: `DUODENUM (INFERIOR PART)`,
  [`DUODENO (PARTE SUPERIORE)`]: `DUODENUM (SUPERIOR PART)`,
  [`DURA MADRE CRANIALE`]: `CRANIAL DURA MATER`,
  [`Durante l'ultima settimana quanta difficoltà ha incontrato nel dormire a causa del dolore al braccio, alla spalla o alla mano?`]: `During the past week, how much difficulty have you had sleeping because of the pain in your arm, shoulder or hand?`,
  [`Durante la settimana passata è stato limitato nel suo lavoro o in altre attività quotidiane abituali a causa del suo problema al braccio, alla spalla o alla mano?`]: `During the past week, were you limited in your work or other regular daily activities as a result of your arm, shoulder or hand problem?`,
  [`Durante la settimana passata e' stato limitato/a nel suo lavoro o in altre attivita' quotidiane abituali a causa del suo problema al braccio, alla spalla o alla mano?`]: `During the past week, were you limited in your work or other regular daily activities as a result of your arm, shoulder or hand problem?`,
  [`Durante la settimana passata in che misura il suo problema al braccio, alla spalla o alla mano ha influito nelle normali attivita' sociali con la (famiglia, amici, i vicini di casa, i gruppi di cui fa parte?`]: `During the past week, to what extent has your arm, shoulder or hand problem interferred with your normal social activities with family, friends, neighbours or groups?`,
  [`Durante la settimana passata in che misura il suo problema al braccio, alla spalla o alla mano ha influito nelle normali attivita' sociali con la famiglia, amici, i vicini di casa, i gruppi di cui fa parte?`]: `During the past week, to what extent has your arm, shoulder or hand problem interferred with your normal social activities with family, friends, neighbours or groups?`,
  [`Durante la settimana passata, in che misura il suo problema al braccio, alla spalla o alla mano ha interferito con le normali attività sociali con la famiglia, gli amici, i vicini di casa o i gruppi di cui fa parte?`]: `During the past week, to what extent has your arm, shoulder or hand problem interfered with your normal social activities with family, friends, neighbours or groups?`,
  [`Durata allenamento`]: `Session duration`,
  [`Durezza`]: `Dullness`,
  [`Duro`]: `Hard`,
  [`Dworkin RH, Turk DC, Farrar JT, Haythornthwaite JA, Jensen MP, Katz NP, et al. Core outcome measures for chronic pain clinical trials: IMMPACT recommendations. Pain 2005;113:9–19.`]: `Dworkin RH, Turk DC, Farrar JT, Haythornthwaite JA, Jensen MP, Katz NP, et al. Core outcome measures for chronic pain clinical trials: IMMPACT recommendations. Pain 2005;113:9–19.`,
  [`Dynamic Gait Index`]: `Dynamic Gait Index`,
  [`Dynamic Knee Test (DX)`]: `Dynamic Knee Test (R)`,
  [`Dynamic Knee Test (SX)`]: `Dynamic Knee Test (L)`,
  [`e' altalenante, va e viene`]: `It is fluctuating`,
  [`e' costante`]: `It is constant`,
  [`e' esordito in seguito ad una attivita' specifica`]: `It started after performing a specific activity`,
  [`E' in grado di eseguire le sue attività fisiche preferite per tutto il tempo da lei desiderato?`]: `Are you able to partecipate in your preferred physical activities for as long as you would like?`,
  [`E' in grado di lavorare allo stato attuale?`]: `Are you currently undertaking sport or other physical activity?`,
  [`E' in grado di svolgere le sue attività fisiche preferite ad un livello di prestazione/esecuzione normale?`]: `Are you able to partecipate in your preferred physical activities at your normal performance level?`,
  [`E' incominciato in seguito ad un periodo di intensa attivita' fisica`]: `It started after performing an excessive activity`,
  [`e' iniziato in seguito ad uno svolgimento eccessivo di un'attivita' specifica`]: `It started after performing an excessive activity`,
  [`e' iniziato in seguito al sollevamento di un oggetto`]: `The pain started after lifting something`,
  [`e' iniziato in seguito allo svolgimento di una eccessiva attivita sportiva o lavorativa'`]: `It started after performin an excessive activity`,
  [`e' iniziato in seguito allo svolgimento di una eccessiva attivita'`]: `It started after performing an excessive activity`,
  [`E' presente dolore all'addome superiore?`]: `Have you got superior abdominal pain?`,
  [`E' presente dolore alla palpazione del navicolare (lato interno del piede)?`]: `Is there pain at palpation of navicular bone (internal side of the foot)?`,
  [`E' presente dolore alla palpazione del V metatarso (lato esterno del piede)?`]: `Is there pain at palpation of the V metatarsal (external side of the foot)?`,
  [`E' presente dolore alla respirazione normale?`]: `Do you have pain with regular breathing?`,
  [`E' presente dolore alla respirazione profonda?`]: `Do you have pain with deep breathing?`,
  [`E' presente dolore intenso e accentuato di notte?`]: `Have you got a very strong pain increasing at night?`,
  [`E' presente dolore nel prendere un oggetto disposto in alto?`]: `Do you have pain taking something positioned on a high shelf?`,
  [`E' presente dolore notturno?`]: `Do you have pain at night?`,
  [`E' presente dolore toracico, dolore addominale superiore, respiro breve o vertigini?`]: `Do you have thoracic pain, superior abdominal pain, short breath or dizziness?`,
  [`E' presente dolore toracico?`]: `Have you got thoracic pain?`,
  [`E' presente gonfiore al piede?`]: `Is your foot swollen?`,
  [`E' presente incapacita' di caricare il peso corporeo per piu' di 4 passi?`]: `Is there inability to weightbearing for more than four steps?`,
  [`E' presente qualcuno dei seguenti segni associati al dolore?`]: `What other symptoms have you had that you can associate with this problem?`,
  [`E' presente respiro corto?`]: `Have you got short breath?`,
  [`e' presente sempre, anche ora`]: `I've always pain, even now`,
  [`e' presente solo al risveglio`]: `I only feel pain upon awakening`,
  [`e' presente solo durante lo svolgimento di attivita'`]: `I only feel pain when performing activity`,
  [`e' presente solo quando muovo la mandibola `]: `I only feel pain moving the mandible`,
  [`E' presente una ovvia massa ai lati del tendine d'achille?`]: `Could you feel an obvious bumps beside your achilles tendon?`,
  [`e' rimasto uguale`]: `It stayed the same`,
  [`Eccellente`]: `Excellent`,
  [`eccessiva sete`]: `Excessive thirst`,
  [`eccessiva sudorazione`]: `Excessive sweating`,
  [`eccesso di attivita': specificare l'attivita'`]: `Excess of activity `,
  [`Ecchimosi`]: `Bruise`,
  [`Ecchimosi /ematoma dei tessuti molli della pelvi/natiche`]: `Buttock Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi dei tessuti molli all'arto superiore`]: `Soft tissue bruising upper limb`,
  [`Ecchimosi dei tessuti molli dell'arto inferiore`]: `Soft tissue bruising lower limb`,
  [`Ecchimosi in regione sternale`]: `Bruised sternum`,
  [`Ecchimosi periorbitale/ematoma`]: `Neck muscle trigger pts/spasm/torticollis`,
  [`Ecchimosi/ematoma a livello sacroiliaco`]: `SIJ bruising/haematoma`,
  [`Ecchimosi/ematoma ai tessuti molli del collo`]: `Neck contusion/haematoma`,
  [`Ecchimosi/ematoma ai tessuti molli dell'avambraccio`]: `Forearm contusion/haematoma`,
  [`Ecchimosi/ematoma al cuoio capelluto`]: `Cervical disc Injury`,
  [`Ecchimosi/ematoma al naso`]: `Nose contusion/haematoma`,
  [`Ecchimosi/ematoma al/le dito/a`]: `Finger bruising/haematoma`,
  [`Ecchimosi/ematoma all'occhio`]: `Whiplash/neck sprain`,
  [`Ecchimosi/ematoma all'orecchio`]: `Ear bruising/haematoma`,
  [`Ecchimosi/ematoma alla bocca`]: `Mouth bruising/haematoma`,
  [`Ecchimosi/ematoma alla mascella`]: `Jaw bruising/haematoma`,
  [`Ecchimosi/ematoma alla testa/faccia`]: `Head/facial contusion/haematoma`,
  [`Ecchimosi/ematoma alle natiche`]: `Pelvic/Buttock Laceration`,
  [`Ecchimosi/ematoma dei tessuti molli addominopelvici (esclusa contusione organi interni)`]: `Abdominopelvic Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli del braccio`]: `Upper Arm Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli del ginocchio`]: `Knee contusion/haematoma (extraarticular)`,
  [`Ecchimosi/ematoma dei tessuti molli del gomito`]: `Elbow contusion/haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli della caviglia`]: `Ankle contusion/haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli della colonna lombare`]: `Lumbar contusion/haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli della coscia`]: `Contusion/haematoma of thigh`,
  [`Ecchimosi/ematoma dei tessuti molli della gamba`]: `Leg Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli della parete toracica`]: `Chest Wall Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli della spalla`]: `Shoulder contusion/haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli di anca/inguine`]: `contusion/haematoma, hip region`,
  [`Ecchimosi/ematoma dei tessuti molli di polso`]: `Wrist Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi/ematoma dei tessuti molli toracici`]: `Thoracic Soft Tissue Bruising/Haematoma`,
  [`Ecchimosi/ematoma del labbro acetabolare`]: `Labial bruising/haematoma`,
  [`Ecchimosi/ematoma del pollice`]: `Thumb bruising/haematoma`,
  [`Ecchimosi/ematoma del polso`]: `Wrist bruising/haematoma`,
  [`Ecchimosi/ematoma del tallone, compresa la contusione del cuscinetto adiposo (fat pad)`]: `Heel bruising/haematoma incl fat pad contusion`,
  [`Ecchimosi/ematoma della cresta iliaca/medio gluteo`]: `Bruising/haematoma iliac crest/gluteus medius`,
  [`Ecchimosi/ematoma della mano`]: `Hand muscle bruising/haematoma`,
  [`Ecchimosi/ematoma periostale pretibiale`]: `Pretibial periosteal bruising/haematoma`,
  [`Ecchimosi/ematoma scrotale/testicolare`]: `Testicular/scrotal contusion/haematoma`,
  [`Edema osseo`]: `Bone edema`,
  [`Edema osseo al piede`]: `Foot bone bruise`,
  [`Edgren Side Step Test`]: `Edgren Side Step Test`,
  [`Educazione`]: `Education`,
  [`Eechaute et al. The clinimetric qualities of patient-assessed instruments for measuring chronic ankle instability: A systematic review. BMC Musculoskeletal Disorders 2007`]: `Eechaute et al. The clinimetric qualities of patient-assessed instruments for measuring chronic ankle instability: A systematic review. BMC Musculoskeletal Disorders 2007`,
  [`Effettuando movimenti durante i quali si distende la gamba in una posizione abdotta (come ad esempio quando si posiziona la gamba il più lontano possibile dal corpo)`]: `Situations where the leg is streched into an outer position (such as when the leg is placed as far away from the body as possible)`,
  [`Effettuando un piegamento sulle gambe`]: `Squatting`,
  [`Effettuando violenti movimenti improvvisi che coinvolgono un rapido gioco di gambe, come ad esempio effettuando accelerazioni, decelerazioni o cambi di direzione`]: `Sudden explosive movements that involve quick footwork, such as accelerations, decelerations, change of directions, etc.`,
  [`Ege's Test (DX)`]: `Ege's Test (R)`,
  [`Ege's Test (SX)`]: `Ege's Test (L)`,
  [`Elastco`]: `Elastic`,
  [`Elastico`]: `Elastic`,
  [`Elbow Extension Test (DX)`]: `Elbow Extension Test (R)`,
  [`Elbow Extension Test (SX)`]: `Elbow Extension Test (L)`,
  [`Elbow Flexion Test (DX)`]: `Elbow Flexion Test (R)`,
  [`Elbow Flexion Test (SX)`]: `Elbow Flexion Test (L)`,
  [`Elbow Flexion Test for Cubital Tunnel (DX)`]: `Elbow Flexion Test for Cubital Tunnel (R)`,
  [`Elbow Flexion Test for Cubital Tunnel (SX)`]: `Elbow Flexion Test for Cubital Tunnel (L)`,
  [`Elbow Flexion Test for Neuropathy (DX)`]: `Elbow Flexion Test for Neuropathy (R)`,
  [`Elbow Flexion Test for Neuropathy (SX)`]: `Elbow Flexion Test for Neuropathy (L)`,
  [`Elbow Pronation Test (DX)`]: `Elbow Pronation Test (R)`,
  [`Elbow Pronation Test (SX)`]: `Elbow Pronation Test (L)`,
  [`Elbow Supination Test (DX)`]: `Elbow Supination Test (R)`,
  [`Elbow Supination Test (SX)`]: `Elbow Supination Test (L)`,
  [`Elbow Valgus Stress Test (DX)`]: `Elbow Valgus Stress Test (R)`,
  [`Elbow Valgus Stress Test (SX)`]: `Elbow Valgus Stress Test (L)`,
  [`Elbow Varus Stress Test (DX)`]: `Elbow Varus Stress Test (R)`,
  [`Elbow Varus Stress Test (SX)`]: `Elbow Varus Stress Test (L)`,
  [`Elevata`]: `Severe`,
  [`Elevazione`]: `Elevation`,
  [`Elevazione mandibola`]: `Mandible elevation`,
  [`Elevazione scapola (DX)`]: `Scapula elevation (R)`,
  [`Elevazione scapola (SX)`]: `Scapula elevation (L)`,
  [`Emartrosi del ginocchio da causa non diagnosticabile`]: `Knee haemarthrosis cause undiagnosed`,
  [`Ematemesi/melena/emorragia gastrointestinale`]: `Haematemesis/melaena/GI bleeding`,
  [`Ematologici`]: `Hematologic`,
  [`Ematologico`]: `Hematologic`,
  [`Ematoma congiuntiva`]: `Cervical functional pain`,
  [`Ematoma dei peronieri`]: `Peroneal Haematoma`,
  [`Ematoma del bicipite brachiale`]: `Biceps haematoma`,
  [`Ematoma del deltoide`]: `Deltoid haematoma`,
  [`Ematoma del tibiale anteriore`]: `Tib anterior haematoma`,
  [`Ematoma del tratto ileo-tibiale`]: `Iliotibial band haematoma`,
  [`Ematoma del tricipite brachiale`]: `Triceps Haematoma`,
  [`Ematoma dell'alluce`]: `Haematoma great toe`,
  [`Ematoma delle altre dita del piede`]: `Haematoma lesser toes`,
  [`Ematoma distale del quadricipite`]: `Distal quadricep haematoma`,
  [`Ematoma muscolare alla coscia`]: `Thigh muscle haematoma`,
  [`Ematoma muscolare alla spalla`]: `Shoulder muscle haematoma`,
  [`Ematoma muscolare degli adduttori`]: `Adductor muscle haematoma`,
  [`Ematoma muscolare degli ischiocrurali (hamstring)`]: `Hamstring muscle haematoma`,
  [`Ematoma muscolare del braccio`]: `Upper arm muscle bruising/haematoma`,
  [`Ematoma muscolare del quadricipite`]: `Quadriceps muscle haematoma`,
  [`Ematoma muscolare della gamba`]: `Lower leg muscle haematoma`,
  [`Ematoma settale`]: `Neck pain undiagnosed`,
  [`Ematoma sottoungueale delle altre dita del piede`]: `Nail bed haematoma lesser toes`,
  [`Ematoma subungueale`]: `Fingernail haematoma`,
  [`Ematoma subungueale del pollice`]: `Thumbnail haematoma`,
  [`Ematoma/borsite del corpo adiposo di Hoffa`]: `Infrapatella fat pad contusion/haematoma +/- bursitis`,
  [`Ematoma/Trauma al seno`]: `Breast haematoma/trauma`,
  [`Ematuria`]: `Haematuria`,
  [`Embolia polmonare`]: `Pulmunary embolism`,
  [`emicrania`]: `migraine`,
  [`Emicrania da esercizio`]: `Exercise related migraine`,
  [`Emocromatosi`]: `Hemochromatosis`,
  [`Emofilia`]: `Haemophilia`,
  [`Emorragia arteriosa acuta alla coscia`]: `Acute arterial bleed thigh`,
  [`Emorragia intracranica`]: `Intracranial Bleed`,
  [`Emotorace`]: `Haemothorax`,
  [`Empty Can Test (DX)`]: `Empty Can Test (R)`,
  [`Empty Can Test (SX)`]: `Empty Can Test (L)`,
  [`End-Feel`]: `End-feel`,
  [`endinopatia distale del bicipite femorale`]: `distal tendinopathy of biceps femoris`,
  [`Endocardite`]: `Endocarditis`,
  [`Endocrinological`]: `Endocrinological`,
  [`Endocrinologici`]: `Endocrine and metabolic`,
  [`Endocrinologico`]: `Endocrinological`,
  [`Endocrinopatia`]: `Endocrine Illness`,
  [`Endometriosi`]: `Endometriosis`,
  [`Enfisema polmonare`]: `Pulmunary Emphysema`,
  [`Entesopatia`]: `Entesopathy`,
  [`Entesopatia Achillea con borsite retrocalcaneale`]: `Achilles enthesopathy with retrocalcaneal bursitis`,
  [`Entrando ed uscendo dall'auto`]: `Getting in/out of car`,
  [`Entrare e/o uscire dalla vasca da bagno/doccia`]: `Getting in/out of bath`,
  [`Entrare o uscire dall'auto`]: `Getting into or out the car`,
  [`Entrare o uscire dalla vasca da bagno`]: `Getting into or out the bath`,
  [`Entrare/uscire dalla vasca da bagno`]: `Getting in/out of bath`,
  [`Environmental – exercise-related`]: `Environmental- exercise-related`,
  [`Environmental – non-exercise`]: `Environmental-non exercise`,
  [`Environmental Illness`]: `Environmental Illness`,
  [`Epatite`]: `Hepatitis/jaundice`,
  [`Epatite non virale`]: `Non-Viral Hepatitis`,
  [`Epatite ronica`]: `Chronic Hepatitis`,
  [`Epatite virale`]: `Viral Hepatitis`,
  [`Epatite virale (A, B o C)`]: `Viral hepatitis (A, B, or C)`,
  [`Epato-biliari`]: `Hepatic and Biliary`,
  [`Epididimite`]: `Epididymitis`,
  [`EPIFISI`]: `PINEAL GLAND`,
  [`Epifisiolisi`]: `Slipped capital femoral epiphysis`,
  [`Epifisite del piatto tibiale mediale (morbo di Blount)`]: `Epiphysitis of medial tibial plateau (Blount's Disease)`,
  [`Epifisite del radio distale`]: `Epiphysitis of distal radius`,
  [`Epilessia`]: `Epilepsy  `,
  [`Epistassi`]: `Cervical nerve root compression/stretch`,
  [`Equitazione`]: `Equestrian`,
  [`Eritema da farmaco`]: `Drug-related skin rash`,
  [`Eritrocitosi`]: `Erythrocytosis`,
  [`Ernia addominale`]: `Abdominal hernia`,
  [`Ernia del disco e lesioni dei nervi`]: `Nerve injuries`,
  [`Ernia dello sportivo (Sportsman's hernia)`]: `Sportsman's hernia`,
  [`Ernia femorale`]: `Femoral hernia`,
  [`Ernia iatale`]: `Hiatal hernia`,
  [`Ernia inguinale`]: `Inguinal hernia`,
  [`Ernia inguinale diretta`]: `Direct inguinal hernia`,
  [`Ernia inguinale indiretta`]: `Indirect inguinal hernia`,
  [`Ernia ombelicare`]: `Umbilical Hernia`,
  [`Esclude il gomito come regione causativa del dolore`]: `Rule out the elbow as a pain generator`,
  [`Esclude il gomito come regione causativa di dolore`]: `Rule out the elbow as a pain generator`,
  [`Esclude la presena di una frattura al piede`]: `Rule out a foot fracture`,
  [`Esclude la presena di una frattura della caviglia`]: `Rule out ankle fractures`,
  [`Esclude la presena di una frattura dellacaviglia`]: `Rule out an ankle fracture`,
  [`Esclude la presenza di lesioni del labbro acetabolare o Impingement femoro-acetabolare`]: `Rule out acetabular labial injury or femoroacetabular impingement`,
  [`Esclude la presenza di un dolore di origine discogenica`]: `Rule out a discogenic pain`,
  [`Esclude la presenza di un dolore relativo alle articolazioni zigo-apofisarie`]: `Rule out a facet joints pain`,
  [`Esclude la presenza di un Impingement Sub-Acromiale`]: `Rule out a sub-acromial impingement`,
  [`Esclude la presenza di una Capsulite Adesiva e di Artrosi Gleno-Omerale`]: `Rule out gleno-humeral osteoarthritis and adesive capsulitis`,
  [`Esclude la presenza di una Discinesia Scapolare`]: `Rule out scapular dyskinesis`,
  [`Esclude la presenza di una disfunzione vestibolare`]: `Rule out a vestibular dysfunction`,
  [`Esclude la presenza di una frattura della caviglia`]: `Rule out an ankle fracture`,
  [`Esclude la presenza di una frattura relativa all'articolazione dell'anca`]: `Rule out to confirm hip fracture`,
  [`Esclude la presenza di una Instabilita' Anteriore`]: `Rule out an anterior instability of shoulder`,
  [`Esclude la presenza di una lesione acuta del gomito`]: `Rule out elbow acute injury`,
  [`Esclude la presenza di una lesione del labbro`]: `Rule out a labial tear`,
  [`Esclude la presenza di una lesione del legamento collaterale mediale`]: `Rule out MCL injury`,
  [`Esclude la presenza di una lesione del legamento collaterale mediale (MCL)`]: `Rule out an injury of the medial collateral ligament `,
  [`Esclude la presenza di una lesione del legamento rotondo dell'anca`]: `Rule out to confirm teres ligament of the hip injury`,
  [`Esclude la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear)`]: `Rule out a rotator cuff injury (Rotator Cuff Tear)`,
  [`Esclude la presenza di una lesione della cuffia dei rotatori (RCT Rotator Cuff Tear): Lesione del Subscapularis`]: `Rule out a rotator cuff injury (Rotator Cuff Tear): Subscapularis injury`,
  [`Esclude la presenza di una MTSS o Medial Tibial Stress Syndrome`]: `Rule out medial tibial stress syndrome`,
  [`Esclude la presenza di una patologia a carico dell'articolazione Acromion-Clavicolare`]: `Rule out acromionclavicluar joint pathology`,
  [`Esclude la presenza di una problematica Sacro/Iliaca`]: `Rule out a sacroiliac joint dysfunction`,
  [`Esclude la presenza di una radicolopatia o di una patologia relativa al disco`]: `Rule out a cervical radiculopaty or an intervertebral disc pathology`,
  [`Esclude la presenza di una radicolopatia o di una patologia relativa al disco lombare`]: `Rule out a lumbar radiculopathy or disc pathology`,
  [`Esclude la presenza di una sindrome femoro-rotulea (PFPS)`]: `Rule out PFPS (patello femoral pain syndrome)`,
  [`Esclude la regione della spalla come area generatrice di dolore`]: `Rule out the shoulder as the pain generator`,
  [`Esclude la Sindrome del Tunnel Carpale`]: `Rule out a carpal tunnel syndrome`,
  [`Esclude un coinvolgimento delle articolazioni faccettarie lombari`]: `Rule out lumbar facet joint as pain generator`,
  [`Esclude una disfunzione sacro-iliaca`]: `Rule out sacroiliac joint dysfunction`,
  [`Esclude una frattura del gomito`]: `Rule out an elbow fracture`,
  [`Esclude una lesione del tendine distale del bicipite brachiale`]: `Rule out an injury of the biceps brachii distal tendon`,
  [`Escludere fratture clavicolari, fratture omerali o lussazione gleno-omerale`]: `Rule out a fracture of humerus, clavicle or a shoulder joint dislocation`,
  [`Escludere fratture omerali o lussazione gleno-omerale`]: `Rule out a fracture of humerus or a shoulder joint dislocation`,
  [`Escludere il coinvolgimento dell'articolazione dell'anca nel dolore al ginocchio`]: `Rule out hip joint as pain generator`,
  [`Escludere la paralisi dell'Axillary Nerve`]: `Rule out axillary nerve palsy`,
  [`Escludere la paralisi dello Spinal Accessory Nerve (Nervo Accessorio Spinale)`]: `Rule out spinal accessory nerve palsy`,
  [`Escludere la possibile presenza di instabilita' della caviglia per lassita' legamentosa del compartimento laterale della caviglia`]: `Rule out ankle joint instability due to lateral ligament laxity`,
  [`Escludere la possibile presenza di instabilita' della caviglia per lassita' legamentosa del compartimento mediale della caviglia`]: `Rule out ankle joint instability due to medial ligament laxity`,
  [`Escludere la possibile presenza di una sindrome del tunnel tarsale`]: `Rule out tarsal tunnel syndrome`,
  [`Escludere la possibile presenza di una tendinopatia Achillea`]: `Rule out achilles tendinopathy`,
  [`Escludere la preenza di versamento articolare`]: `Rule out intra-articular swelling`,
  [`Escludere la presenza di edema`]: `Rule out edema`,
  [`Escludere la presenza di instabilita' della articolazione sottoastragalica`]: `Rule out subtalar joint instability`,
  [`Escludere la presenza di patologie intra-articolari dell'anca`]: `Rule out intraarticular pathology of the hip joint`,
  [`Escludere la presenza di scoliosi`]: `Rule out scoliosis`,
  [`Escludere la presenza di un cedimento vertebrale`]: `Rule out a vertebral collapse`,
  [`Escludere la presenza di un impingement della caviglia`]: `Rule out ankle joint impingement`,
  [`Escludere la presenza di una dissociazione scafo-lunata`]: `Rule out scapho-lunate dissociation`,
  [`Escludere la presenza di una frattura`]: `Rule out a fracture`,
  [`escludere la presenza di una frattura del ginocchio`]: `Rule out knee fracture`,
  [`Escludere la presenza di una instabilita' del Capitato`]: `Rule out capitate instability`,
  [`Escludere la presenza di una instabilita' tra occipite e atlante`]: `Rule out an instability of the atlas`,
  [`Escludere la presenza di una radicolopatia lombare`]: `Rule out a lumbar radiculopathy`,
  [`Escludere la regione del polso`]: `Rule out the wrist as the pain generator`,
  [`Escludere la regione dell'anca come potenziale causa del dolore`]: `Rule out hip joint as pain generator`,
  [`Escludere la regione dell'anca come potenziale causa di dolore alla gamba`]: `Rule out the hip joint as pain generator`,
  [`Escludere la regione lombare come possibile causa di dolore`]: `Rule out lumbar spine as pain generator`,
  [`Escludere patologie intra-articolari dell'anca`]: `Rule out intraarticular pathologies of the hip joint`,
  [`Escludere una disfunzione dell'articolazione sacro-iliaca`]: `Rule out sacroiliac joint dysfunction`,
  [`Escludere una disfunzione delle articolazioni faccettarie lombari`]: `Rule out lumbar facet joints as pain generator`,
  [`Escludere una frattura vertebrale da compressione`]: `Rule out a compression vertebral fracture`,
  [`Escludere una lesione del legamento crociato anteriore`]: `Rule out ACL injury`,
  [`Escludere una lesione del legamento crociato posteriore`]: `Rule out PCL injury`,
  [`Escludere una lesione del tendine distale del bicipite brachiale`]: `Rule out an injury of the biceps brachii distal tendon`,
  [`Escludere una lesione meniscale`]: `Rule out meniscal injury `,
  [`Escludere una mielopatia cervicale`]: `Rule out Cervical Mielopathy`,
  [`Escludere una possibile lesione della sindesmosi`]: `Rule out syndesmosis injury`,
  [`Escludere una radicolopatia lombare o una patologia relativa al disco lombare`]: `Rule out lumbar radiculopathy or disc pathology`,
  [`Eseguendo movimenti ripetuti del polso/mano`]: `When doing a task with a repeated wrist movement`,
  [`Esercizio`]: `Exercise`,
  [`Esercizio leggero`]: `mild exercise`,
  [`Esiti artroplastica di ginocchio`]: `Post arthroplasty knee`,
  [`Esiti chirurgia acromion claveter`]: `Post A/C joint surgery`,
  [`Esiti chirurgia arto inferiore`]: `Post lower leg surgery`,
  [`Esiti chirurgia del collo`]: `Post neck surgery`,
  [`Esiti chirurgia del tendine d'Achille`]: `Post Achilles tendon surgery`,
  [`Esiti chirurgia di anca/inguine`]: `Post hip/groin Surgery`,
  [`Esiti chirurgia di ginocchio`]: `Post knee reconstruction`,
  [`Esiti chirurgia di gomito`]: `Post elbow surgery`,
  [`Esiti chirurgia di mano`]: `Post hand surgery`,
  [`Esiti chirurgia di polso`]: `Post surgery on wrist joint`,
  [`Esiti fasciotomia`]: `Post compartment release surgery`,
  [`Esiti ricostruzione di spalla`]: `Post shoulder reconstruction`,
  [`Esofago`]: `Esophagus`,
  [`ESOFAGO`]: `OESOPHAGUS`,
  [`ESOFAGO (PARTE ADDOMINALE)`]: `OESOPHAGUS (ABDOMINAL PART)`,
  [`ESOFAGO (PARTE CERVICALE)`]: `OESOPHAGUS (CERVICAL PART)`,
  [`ESOFAGO (PARTE TORACICA)`]: `OESOPHAGUS (THORACIC PART)`,
  [`Esordio del dolore`]: `Onset of pain`,
  [`Esostosi (bunion) dell'articolazione metatarsofalangea del V dito`]: `Bunion 5th MTP joint`,
  [`Esostosi (bunion) dell'articolazione metatarsofalangea dell'alluce`]: `Bunion of great toe MTP joint`,
  [`Esostosi dell'avambraccio/indrome da stress mediale ulnare`]: `Forearm splints/medial ulnar stress syndrome`,
  [`Espirazione profonda`]: `Deep expiration `,
  [`Estendendo l'anca completamente`]: `Straightening your hip fully`,
  [`Estendere completamente il ginocchio`]: `Straightening knee fully`,
  [`Estendere completamente l'anca`]: `Straightening your hip fully`,
  [`Estensione`]: `Extension`,
  [`Estensione (dorso piatto)`]: `Extension (flat back)`,
  [`Estensione (iperlordosi)`]: `Extension (hyperlordosis)`,
  [`Estensione Anche Da In Ginocchio`]: `BAND KNEELING HIP THRUST`,
  [`Estensione Anche Da In Piedi`]: `BAND STANDING HIP THRUST`,
  [`Estensione Anche Da Split Squat`]: `BAND BACK LUNGE`,
  [`Estensione articolazione del ginocchio (da seduto) (Sitting Active Knee Extension) (DX)`]: `Knee joint extension (sitting) (Sitting Active Knee Extension) (R)`,
  [`Estensione articolazione del ginocchio (da seduto) (Sitting Active Knee Extension) (SX)`]: `Knee joint extension (sitting) (Sitting Active Knee Extension) (L)`,
  [`Estensione articolazione del ginocchio (DX)`]: `Knee joint extension (R)`,
  [`Estensione articolazione del ginocchio (SX)`]: `Knee joint extension (L)`,
  [`Estensione articolazione del gomito (DX)`]: `Elbow joint extension (R)`,
  [`Estensione articolazione del gomito (SX)`]: `Elbow joint extension (L)`,
  [`Estensione articolazione del polso (DX)`]: `Wrist joint extension (R)`,
  [`Estensione articolazione del polso (SX)`]: `Wrist joint extension (L)`,
  [`Estensione articolazione dell'anca (con flessione articolazione del ginocchio) (DX)`]: `Hip joint extension (with bended knee) (R)`,
  [`Estensione articolazione dell'anca (con flessione articolazione del ginocchio) (SX)`]: `Hip joint extension (with bended knee) (L)`,
  [`Estensione articolazione dell'anca (con ginocchio flesso) (DX)`]: `Hip joint extension (with bended knee) (R)`,
  [`Estensione articolazione dell'anca (con ginocchio flesso) (SX)`]: `Hip joint extension (with bended knee) (L)`,
  [`Estensione articolazione dell'anca (da prono con ginocchio esteso) (dominanza hamstring Janda) (Active Hip Extension) (DX)`]: `Hip joint extension (with extended knee) (Active Hip Extension) (R)`,
  [`Estensione articolazione dell'anca (da prono con ginocchio esteso) (dominanza hamstring Janda) (Active Hip Extension) (SX)`]: `Hip joint extension (with extended knee) (Active Hip Extension) (L)`,
  [`Estensione articolazione dell'anca (DX)`]: `Hip joint extension (R)`,
  [`Estensione articolazione dell'anca (SX)`]: `Hip joint extension (L)`,
  [`Estensione articolazione della spalla (con gomito esteso) (DX)`]: `Shoulder joint extension (with extended elbow) (R)`,
  [`Estensione articolazione della spalla (con gomito esteso) (SX)`]: `Shoulder joint extension (with extended elbow) (L)`,
  [`Estensione articolazione della spalla (DX)`]: `Shoulder joint extension (R)`,
  [`Estensione articolazione della spalla (SX)`]: `Shoulder joint extension (L)`,
  [`Estensione articolazione interfalangea del pollice (DX)`]: `Interphalangeal joint of pollicis extension (R)`,
  [`Estensione articolazione interfalangea del pollice (SX)`]: `Interphalangeal joint of pollicis extension (L)`,
  [`Estensione articolazione interfalangea dell'alluce (DX)`]: `Interphalangeal joint of hallux extension (R)`,
  [`Estensione articolazione interfalangea dell'alluce (SX)`]: `Interphalangeal joint of hallux extension (L)`,
  [`Estensione articolazione metacarpofalangea del pollice (DX)`]: `Metacarpophalangeal joint of pollicis extension (R)`,
  [`Estensione articolazione metacarpofalangea del pollice (SX)`]: `Metacarpophalangeal joint of pollicis extension (L)`,
  [`Estensione articolazione metatarsofalangea dell'alluce (DX)`]: `Metatarsophalangeal joint of hallux extension (R)`,
  [`Estensione articolazione metatarsofalangea dell'alluce (SX)`]: `Metatarsophalangeal joint of hallux extension (L)`,
  [`Estensione articolazioni interfalangee distali del piede dalla II alla V (DX)`]: `Distal interphalangeal joints of foot (II to V) extension (R)`,
  [`Estensione articolazioni interfalangee distali del piede dalla II alla V (SX)`]: `Distal interphalangeal joints of foot (II to V) extension (L)`,
  [`Estensione articolazioni interfalangee distali della mano dalla II alla V (DX)`]: `Distal interphalangeal joints (II-V) extension (R)`,
  [`Estensione articolazioni interfalangee distali della mano dalla II alla V (SX)`]: `Distal interphalangeal joints (II-V) extension (L)`,
  [`Estensione articolazioni interfalangee prossimali del piede dalla II alla V (DX)`]: `Proximal interphalangeal joints of foot (II to V) extension (R)`,
  [`Estensione articolazioni interfalangee prossimali del piede dalla II alla V (SX)`]: `Proximal interphalangeal joints of foot (II to V) extension (L)`,
  [`Estensione articolazioni interfalangee prossimali della mano dalla II alla V (DX)`]: `Proximal interphalangeal joints (II-V) extension (R)`,
  [`Estensione articolazioni interfalangee prossimali della mano dalla II alla V (SX)`]: `Proximal interphalangeal joints (II-V) extension (L)`,
  [`Estensione articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) extension (R)`,
  [`Estensione articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) extension (L)`,
  [`Estensione articolazioni metacarpofalangee della mano dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) extension (R)`,
  [`Estensione articolazioni metacarpofalangee della mano dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) extension (L)`,
  [`Estensione articolazioni metatarsofalangee dalla II alla V (DX)`]: `Matatarsophalangeal joints (II-V) extension (R)`,
  [`Estensione articolazioni metatarsofalangee dalla II alla V (SX)`]: `Matatarsophalangeal joints (II-V) extension (L)`,
  [`Estensione articolazioni metatarsofalangee del piede dalla II alla V (DX)`]: `Matatarsophalangeal joints (II-V) extension (R)`,
  [`Estensione articolazioni metatarsofalangee del piede dalla II alla V (SX)`]: `Matatarsophalangeal joints (II-V) extension (L)`,
  [`Estensione bilaterale`]: `Bilateral extension`,
  [`Estensione collo`]: `Neck extension`,
  [`Estensione collo in posizione quadrupedica`]: `Neck extension (quadrupedal position)`,
  [`Estensione del ginocchio contro resistenza (DX)`]: `Knee joint resisted extension (R)`,
  [`Estensione del ginocchio contro resistenza (SX)`]: `Knee joint resisted extension (L)`,
  [`Estensione dell'anca`]: `Hip extension`,
  [`Estensione e rotazione`]: `Extension/rotation`,
  [`Estensione e rotazione destra`]: `Right extension/rotation`,
  [`Estensione e rotazione sinistra`]: `Left extension/rotation`,
  [`Estensione lombare`]: `Lumbar extension`,
  [`Estensione testa`]: `Head extension`,
  [`Estensione tronco`]: `Trunk extension`,
  [`Estensione unilaterale destra`]: `Right unilateral extension`,
  [`Estensione unilaterale sinistra`]: `Left unilateral extension`,
  [`Estensione vertebre cervicali`]: `Cervical vertebrae extension`,
  [`Estensione vertebre lombari in appoggio monopodalico sulla gamba (DX)`]: `Lumbar extension in single leg stance (R)`,
  [`Estensione vertebre lombari in appoggio monopodalico sulla gamba (SX)`]: `Lumbar extension in single leg stance (L)`,
  [`Estensione vertebre toraciche`]: `Thoracic vertebrae extension`,
  [`Estenuante`]: `Gruelling`,
  [`Estrema`]: `Extreme`,
  [`Estrema difficoltà`]: `Extreme difficulty`,
  [`Estrema difficoltà o incapacità di svolgere l'attività`]: `Extrem difficulty or unable to perform activity`,
  [`Estremo`]: `Extreme `,
  [`ETMOIDE`]: `ETHMOID`,
  [`ETMOIDE (CONCA NASALE MEDIA DESTRA)`]: `ETHMOID (RIGHT MIDDLE NASAL CONCHA)`,
  [`ETMOIDE (CONCA NASALE MEDIA SINISTRA)`]: `ETHMOID (LEFT MIDDLE NASAL CONCHA)`,
  [`ETMOIDE (CONCA NASALE SUPERIORE DESTRA)`]: `ETHMOID (RIGHT SUPERIOR NASAL CONCHA)`,
  [`ETMOIDE (CONCA NASALE SUPERIORE SINISTRA)`]: `ETHMOID (LEFT SUPERIOR NASAL CONCHA)`,
  [`ETMOIDE (CONCA NASALE SUPREMA DESTRA)`]: `ETHMOID (RIGHT SUPREME NASAL CONCHA)`,
  [`ETMOIDE (CONCA NASALE SUPREMA SINISTRA)`]: `ETHMOID (LEFT SUPREME NASAL CONCHA)`,
  [`ETMOIDE (LAMINA CRIBROSA)`]: `ETHMOID (CRIBIFORM PLATE)`,
  [`ETMOIDE (LAMINA PERPENDICOLARE)`]: `ETHMOID (PERPENDICULAR PLATE)`,
  [`Eversione`]: `Eversion`,
  [`Eversione articolazione della caviglia (DX)`]: `Ankle joint eversion (R)`,
  [`Eversione articolazione della caviglia (SX)`]: `Ankle joint eversion (L)`,
  [`Eversione articolazione sottoastragalica (DX)`]: `Subtalar joint eversion (R)`,
  [`Eversione articolazione sottoastragalica (SX)`]: `Subtalar joint eversion (L)`,
  [`Evidente`]: `Evident`,
  [`Evoluzione del dolore`]: `Pain pattern`,
  [`Extension Loss`]: `Extension Loss`,
  [`Extension Quadrant test`]: `Extension Quadrant test`,
  [`Extensor Dynamic Endurance Test`]: `Extensor Dynamic Endurance Test`,
  [`External Rotation and Dorsiflexion Test (DX)`]: `External Rotation and Dorsiflexion Test (R)`,
  [`External Rotation and Dorsiflexion Test (SX)`]: `External Rotation and Dorsiflexion Test (L)`,
  [`External Rotation Lag Sign (DX)`]: `External Rotation Lag Sign (R)`,
  [`External Rotation Lag Sign (SX)`]: `External Rotation Lag Sign (L)`,
  [`External Rotators of the Hip Assessment (DX)`]: `External Rotators of the Hip Assessment (R)`,
  [`External Rotators of the Hip Assessment (SX)`]: `External Rotators of the Hip Assessment (L)`,
  [`Ez Tricep Extension`]: `EZ Tricep Extension`,
  [`FAAM (CAVIGLIA E PIEDE)`]: `FAAM (FOOT AND ANKLE ABILITY MEASURE)`,
  [`FABER Test (DX)`]: `FABER Test (R)`,
  [`FABER Test (SX)`]: `FABER Test (L)`,
  [`Faccia`]: `Face`,
  [`Facendo dei lavori domestici (lavare i pavimenti, passare l'aspirapolvere, spostare oggetti pesanti)`]: `Heavy domestic duties (scrubbing floors, vacuuming, moving heavy boxes, etc.)`,
  [`FADIR Test (DX)`]: `FADIR Test (R)`,
  [`FADIR Test (SX)`]: `FADIR Test (L)`,
  [`Fai uso di alcol (quante porzioni al giorno)`]: `Do you use alchool? (how many portions a day)`,
  [`Fai uso di tabacco (quante sigarette al giorno)`]: `Do you use tobacco? (how many cigarettes a day)`,
  [`Fairbank J, Pynsent P. The Oswestry disablility index. Spine 2000;25:2490-53.`]: `Fairbank J, Pynsent P. The Oswestry disablility index. Spine 2000;25:2490-53.`,
  [`Falange distale del II dito del piede (DX)`]: `Distal phalanx of II toe (R)`,
  [`Falange distale del II dito del piede (SX)`]: `Distal phalanx of II toe (L)`,
  [`FALANGE DISTALE DEL II DITO DEL PIEDE DESTRO`]: `DISTAL PHALANX OF RIGHT II TOE`,
  [`FALANGE DISTALE DEL II DITO DEL PIEDE SINISTRO`]: `DISTAL PHALANX OF LEFT II TOE`,
  [`Falange distale del II dito della mano (DX)`]: `Distal phalanx of index finger (R)`,
  [`Falange distale del II dito della mano (SX)`]: `Distal phalanx of index finger (L)`,
  [`FALANGE DISTALE DEL II DITO DELLA MANO DESTRA`]: `DISTAL PHALANX OF RIGHT INDEX FINGER`,
  [`FALANGE DISTALE DEL II DITO DELLA MANO SINISTRA`]: `DISTAL PHALANX OF LEFT INDEX FINGER`,
  [`Falange distale del III dito del piede (DX)`]: `Distal phalanx of III toe (R)`,
  [`Falange distale del III dito del piede (SX)`]: `Distal phalanx of III toe (L)`,
  [`FALANGE DISTALE DEL III DITO DEL PIEDE DESTRO`]: `DISTAL PHALANX OF RIGHT III TOE`,
  [`FALANGE DISTALE DEL III DITO DEL PIEDE SINISTRO`]: `DISTAL PHALANX OF LEFT III TOE`,
  [`Falange distale del III dito della mano (DX)`]: `Distal phalanx of middle finger (R)`,
  [`Falange distale del III dito della mano (SX)`]: `Distal phalanx of middle finger (L)`,
  [`FALANGE DISTALE DEL III DITO DELLA MANO DESTRA`]: `DISTAL PHALANX OF RIGHT MIDDLE FINGER`,
  [`FALANGE DISTALE DEL III DITO DELLA MANO SINISTRA`]: `DISTAL PHALANX OF LEFT MIDDLE FINGER`,
  [`Falange distale del IV dito del piede (DX)`]: `Distal phalanx of IV toe (R)`,
  [`Falange distale del IV dito del piede (SX)`]: `Distal phalanx of IV toe (L)`,
  [`FALANGE DISTALE DEL IV DITO DEL PIEDE DESTRO`]: `DISTAL PHALANX OF RIGHT IV TOE`,
  [`FALANGE DISTALE DEL IV DITO DEL PIEDE SINISTRO`]: `DISTAL PHALANX OF LEFT IV TOE`,
  [`Falange distale del IV dito della mano (DX)`]: `Distal phalanx of ring finger (R)`,
  [`Falange distale del IV dito della mano (SX)`]: `Distal phalanx of ring finger (L)`,
  [`FALANGE DISTALE DEL IV DITO DELLA MANO DESTRA`]: `DISTAL PHALANX OF RIGHT RING FINGER`,
  [`FALANGE DISTALE DEL IV DITO DELLA MANO SINISTRA`]: `DISTAL PHALANX OF LEFT RING FINGER`,
  [`Falange distale del pollice (DX)`]: `Thumb distal phalanx (R)`,
  [`Falange distale del pollice (SX)`]: `Thumb distal phalanx (L)`,
  [`FALANGE DISTALE DEL POLLICE DESTRO`]: `DISTAL PHALANX OF RIGHT THUMB`,
  [`FALANGE DISTALE DEL POLLICE SINISTRO`]: `DISTAL PHALANX OF LEFT THUMB`,
  [`Falange distale del V dito del piede (DX)`]: `Distal phalanx of V toe (R)`,
  [`Falange distale del V dito del piede (SX)`]: `Distal phalanx of V toe (L)`,
  [`FALANGE DISTALE DEL V DITO DEL PIEDE DESTRO`]: `DISTAL PHALANX OF RIGHT V TOE`,
  [`FALANGE DISTALE DEL V DITO DEL PIEDE SINISTRO`]: `DISTAL PHALANX OF LEFT V TOE`,
  [`Falange distale del V dito della mano (DX)`]: `Distal phalanx of little finger (R)`,
  [`Falange distale del V dito della mano (SX)`]: `Distal phalanx of little finger (L)`,
  [`FALANGE DISTALE DEL V DITO DELLA MANO DESTRA`]: `DISTAL PHALANX OF RIGHT LITTLE FINGER`,
  [`FALANGE DISTALE DEL V DITO DELLA MANO SINISTRA`]: `DISTAL PHALANX OF LEFT LITTLE FINGER`,
  [`Falange distale dell'alluce (DX)`]: `Distal phalanx of hallux (R)`,
  [`Falange distale dell'alluce (SX)`]: `Distal phalanx of hallux (L)`,
  [`FALANGE DISTALE DELL'ALLUCE DESTRO`]: `DISTAL PHALANX OF RIGHT HALLUX`,
  [`FALANGE DISTALE DELL'ALLUCE SINISTRO`]: `SKIN PHALANX OF LEFT HALLUX`,
  [`Falange media del II dito del piede (DX)`]: `Middle phalanx of II toe (R)`,
  [`Falange media del II dito del piede (SX)`]: `Middle phalanx of II toe (L)`,
  [`FALANGE MEDIA DEL II DITO DEL PIEDE DESTRO`]: `MIDDLE PHALANX OF RIGHT II TOE`,
  [`FALANGE MEDIA DEL II DITO DEL PIEDE SINISTRO`]: `MIDDLE PHALANX OF LEFT II TOE`,
  [`Falange media del II dito della mano (DX)`]: `Middle phalanx of index finger (R)`,
  [`Falange media del II dito della mano (SX)`]: `Middle phalanx of index finger (L)`,
  [`FALANGE MEDIA DEL II DITO DELLA MANO DESTRA`]: `MIDDLE PHALANX OF RIGHT INDEX FINGER`,
  [`FALANGE MEDIA DEL II DITO DELLA MANO SINISTRA`]: `MIDDLE PHALANX OF LEFT INDEX FINGER`,
  [`Falange media del III dito del piede (DX)`]: `Middle phalanx of III toe (R)`,
  [`Falange media del III dito del piede (SX)`]: `Middle phalanx of III toe (L)`,
  [`FALANGE MEDIA DEL III DITO DEL PIEDE DESTRO`]: `MIDDLE PHALANX OF RIGHT III TOE`,
  [`FALANGE MEDIA DEL III DITO DEL PIEDE SINISTRO`]: `MIDDLE PHALANX OF LEFT III TOE`,
  [`Falange media del III dito della mano (DX)`]: `Middle phalanx of middle finger (R)`,
  [`Falange media del III dito della mano (SX)`]: `Middle phalanx of middle finger (L)`,
  [`FALANGE MEDIA DEL III DITO DELLA MANO DESTRA`]: `MIDDLE PHALANX OF RIGHT MIDDLE FINGER`,
  [`FALANGE MEDIA DEL III DITO DELLA MANO SINISTRA`]: `MIDDLE PHALANX OF LEFT MIDDLE FINGER`,
  [`Falange media del IV dito del piede (DX)`]: `Middle phalanx of IV toe (R)`,
  [`Falange media del IV dito del piede (SX)`]: `Middle phalanx of IV toe (L)`,
  [`FALANGE MEDIA DEL IV DITO DEL PIEDE DESTRO`]: `MIDDLE PHALANX OF RIGHT IV TOE`,
  [`FALANGE MEDIA DEL IV DITO DEL PIEDE SINISTRO`]: `MIDDLE PHALANX OF LEFT IV TOE`,
  [`Falange media del IV dito della mano (DX)`]: `Middle phalanx of ring finger (R)`,
  [`Falange media del IV dito della mano (SX)`]: `Middle phalanx of ring finger (L)`,
  [`FALANGE MEDIA DEL IV DITO DELLA MANO DESTRA`]: `MIDDLE PHALANX OF RIGHT RING FINGER`,
  [`FALANGE MEDIA DEL IV DITO DELLA MANO SINISTRA`]: `MIDDLE PHALANX OF LEFT RING FINGER`,
  [`Falange media del V dito del piede (DX)`]: `Middle phalanx of V toe (R)`,
  [`Falange media del V dito del piede (SX)`]: `Middle phalanx of V toe (L)`,
  [`FALANGE MEDIA DEL V DITO DEL PIEDE DESTRO`]: `MIDDLE PHALANX OF RIGHT V TOE`,
  [`FALANGE MEDIA DEL V DITO DEL PIEDE SINISTRO`]: `MIDDLE PHALANX OF LEFT V TOE`,
  [`Falange media del V dito della mano (DX)`]: `Middle phalanx of little finger (R)`,
  [`Falange media del V dito della mano (SX)`]: `Middle phalanx of little finger (L)`,
  [`FALANGE MEDIA DEL V DITO DELLA MANO DESTRA`]: `MIDDLE PHALANX OF RIGHT LITTLE FINGER`,
  [`FALANGE MEDIA DEL V DITO DELLA MANO SINISTRA`]: `MIDDLE PHALANX OF LEFT LITTLE FINGER`,
  [`Falange prossimale del II dito del piede (DX)`]: `Proximal phalanx of II toe (R)`,
  [`Falange prossimale del II dito del piede (SX)`]: `Proximal phalanx of II toe (L)`,
  [`FALANGE PROSSIMALE DEL II DITO DEL PIEDE DESTRO`]: `PROXIMAL PHALANX OF RIGHT II TOE`,
  [`FALANGE PROSSIMALE DEL II DITO DEL PIEDE SINISTRO`]: `PROXIMAL PHALANX OF LEFT II TOE`,
  [`Falange prossimale del II dito della mano (DX)`]: `Proximal phalanx of index finger (R)`,
  [`Falange prossimale del II dito della mano (SX)`]: `Proximal phalanx of index finger (L)`,
  [`FALANGE PROSSIMALE DEL II DITO DELLA MANO DESTRA`]: `PROXIMAL PHALANX OF RIGHT INDEX FINGER`,
  [`FALANGE PROSSIMALE DEL II DITO DELLA MANO SINISTRA`]: `PROXIMAL PHALANX OF LEFT INDEX FINGER`,
  [`Falange prossimale del III dito del piede (DX)`]: `Proximal phalanx of III toe (R)`,
  [`Falange prossimale del III dito del piede (SX)`]: `Proximal phalanx of III toe (L)`,
  [`FALANGE PROSSIMALE DEL III DITO DEL PIEDE DESTRO`]: `PROXIMAL PHALANX OF RIGHT III TOE`,
  [`FALANGE PROSSIMALE DEL III DITO DEL PIEDE SINISTRO`]: `PROXIMAL PHALANX OF LEFT III TOE`,
  [`Falange prossimale del III dito della mano (DX)`]: `Proximal phalanx of middle finger (R)`,
  [`Falange prossimale del III dito della mano (SX)`]: `Proximal phalanx of middle finger (L)`,
  [`FALANGE PROSSIMALE DEL III DITO DELLA MANO DESTRA`]: `PROXIMAL PHALANX OF RIGHT MIDDLE FINGER`,
  [`FALANGE PROSSIMALE DEL III DITO DELLA MANO SINISTRA`]: `PROXIMAL PHALANX OF LEFT MIDDLE FINGER`,
  [`Falange prossimale del IV dito del piede (DX)`]: `Proximal phalanx of IV toe (R)`,
  [`Falange prossimale del IV dito del piede (SX)`]: `Proximal phalanx of IV toe (L)`,
  [`FALANGE PROSSIMALE DEL IV DITO DEL PIEDE DESTRO`]: `PROXIMAL PHALANX OF RIGHT IV TOE`,
  [`FALANGE PROSSIMALE DEL IV DITO DEL PIEDE SINISTRO`]: `PROXIMAL PHALANX OF LEFT IV TOE`,
  [`Falange prossimale del IV dito della mano (DX)`]: `Proximal phalanx of ring finger (R)`,
  [`Falange prossimale del IV dito della mano (SX)`]: `Proximal phalanx of ring finger (L)`,
  [`FALANGE PROSSIMALE DEL IV DITO DELLA MANO DESTRA`]: `PROXIMAL PHALANX OF RIGHT RING FINGER`,
  [`FALANGE PROSSIMALE DEL IV DITO DELLA MANO SINISTRA`]: `PROXIMAL PHALANX OF LEFT RING FINGER`,
  [`Falange prossimale del pollice (DX)`]: `Thumb proximal phalanx (R)`,
  [`Falange prossimale del pollice (SX)`]: `Thumb proximal phalanx (L)`,
  [`FALANGE PROSSIMALE DEL POLLICE DESTRO`]: `PROXIMAL PHALANX OF RIGHT THUMB`,
  [`FALANGE PROSSIMALE DEL POLLICE SINISTRO`]: `PROXIMAL PHALANX OF LEFT THUMB`,
  [`Falange prossimale del V dito del piede (DX)`]: `Proximal phalanx of V toe (R)`,
  [`Falange prossimale del V dito del piede (SX)`]: `Proximal phalanx of V toe (L)`,
  [`FALANGE PROSSIMALE DEL V DITO DEL PIEDE DESTRO`]: `PROXIMAL PHALANX OF RIGHT V TOE`,
  [`FALANGE PROSSIMALE DEL V DITO DEL PIEDE SINISTRO`]: `PROXIMAL PHALANX OF LEFT V TOE`,
  [`Falange prossimale del V dito della mano (DX)`]: `Proximal phalanx of little finger (R)`,
  [`Falange prossimale del V dito della mano (SX)`]: `Proximal phalanx of little finger (L)`,
  [`FALANGE PROSSIMALE DEL V DITO DELLA MANO DESTRA`]: `PROXIMAL PHALANX OF RIGHT LITTLE FINGER`,
  [`FALANGE PROSSIMALE DEL V DITO DELLA MANO SINISTRA`]: `PROXIMAL PHALANX OF LEFT LITTLE FINGER`,
  [`Falange prossimale dell'alluce (DX)`]: `Proximal phalanx of hallux (R)`,
  [`Falange prossimale dell'alluce (SX)`]: `Proximal phalanx of hallux (L)`,
  [`FALANGE PROSSIMALE DELL'ALLUCE DESTRO`]: `PROXIMAL PHALANX OF RIGHT HALLUX`,
  [`FALANGE PROSSIMALE DELL'ALLUCE SINISTRO`]: `PROXIMAL PHALANX OF LEFT HALLUX`,
  [`FALCE DEL CERVELLETTO`]: `CEREBELLAR FALX`,
  [`FALCE DEL CERVELLO`]: `FALX CEREBRI`,
  [`Fare il bagno o vestirsi da soli`]: `Bathing or dressing yourself`,
  [`Fare lavori di giardinaggio`]: `Garden or do yard work`,
  [`Fare lavori domestici leggeri (cucinare, spolverare, ecc.)`]: `Light domestic duties (cooking, dusting, etc.)`,
  [`Fare lavori domestici pesanti (lavare il pavimento o I vetri)`]: `Do heavy household chores (eg wash walls, wash floors)`,
  [`Fare lavori domestici pesanti (spostare scatole pesanti, lavare pavimenti, ecc.)`]: `Heavy domestic duties (moving heavy boxes, scrubbing floors, etc.)`,
  [`Fare spese o compere`]: `Going shopping`,
  [`Faringite`]: `Pharyngitis`,
  [`Farmaci`]: `Drugs`,
  [`Farrar JT, Young JP Jr, LaMoreaux L, Werth JL, Poole RM. Clinical importance of changes in chronic pain intensity measured on an 11-point numerical pain rating scale. Pain 2001;94:149–58.`]: `Farrar JT, Young JP Jr, LaMoreaux L, Werth JL, Poole RM. Clinical importance of changes in chronic pain intensity measured on an 11-point numerical pain rating scale. Pain 2001;94:149–58.`,
  [`FASCIA ADDOMINALE PARIETALE`]: `ABDOMINAL PARIETAL FASCIA`,
  [`FASCIA ADDOMINALE PARIETALE PARTE DESTRA`]: `PARIETAL ABDOMINAL FASCIA (RIGHT SIDE)`,
  [`FASCIA ADDOMINALE VISCERALE`]: `ABDOMINAL VISCERAL FASCIA`,
  [`Fascia antibrachiale (DX)`]: `Antibrachial fascia (R)`,
  [`Fascia antibrachiale (SX)`]: `Antibrachial fascia (L)`,
  [`FASCIA ANTIBRACHIALE DESTRA`]: `RIGHT ANTEBRACHIAL FASCIA`,
  [`FASCIA ANTIBRACHIALE SINISTRA`]: `LEFT ANTEBRACHIAL FASCIA`,
  [`FASCIA ASCELLARE DESTRA`]: `RIGHT AXILLARY FASCIA`,
  [`FASCIA ASCELLARE SINISTRA`]: `LEFT AXILLARY FASCIA`,
  [`Fascia brachiale (DX)`]: `Brachial fascia (R)`,
  [`Fascia brachiale (SX)`]: `Brachial fascia (L)`,
  [`FASCIA BRACHIALE DESTRA`]: `RIGHT BRACHIAL FASCIA`,
  [`FASCIA BRACHIALE SINISTRA`]: `LEFT BRACHIAL FASCIA`,
  [`FASCIA BUCCOFARINGEA DESTRA`]: `BUCCOPHARYMGEAL FASCIA (RIGHT SIDE)`,
  [`FASCIA BUCCOFARINGEA SINISTRA`]: `BUCCOPHARYMGEAL FASCIA (LEFT SIDE)`,
  [`FASCIA CAROTIDEA DESTRA`]: `RIGHT CAROTID FASCIA`,
  [`FASCIA CAROTIDEA SINISTRA`]: `LEFT CAROTID FASCIA`,
  [`FASCIA CERVICALE`]: `CERVICAL FASCIA`,
  [`FASCIA CERVICALE MEDIA (LATO DESTRO)`]: `INTERMEDIATE CERVICAL FASCIA (RIGHT SIDE)`,
  [`FASCIA CERVICALE MEDIA (LATO SINISTRO)`]: `INTERMEDIATE CERVICAL FASCIA (LEFT SIDE)`,
  [`FASCIA CERVICALE PREVERTEBRALE (LATO DESTRO)`]: `DEEP CERVICAL FASCIA (RIGHT SIDE)`,
  [`FASCIA CERVICALE PREVERTEBRALE (LATO SINISTRO)`]: `DEEP CERVICAL FASCIA (LEFT SIDE)`,
  [`FASCIA CERVICALE PROFONDA (LATO DESTRO)`]: `DEEP CERVICAL FASCIA (RIGHT SIDE)`,
  [`FASCIA CERVICALE PROFONDA (LATO SINISTRO)`]: `DEEP CERVICAL FASCIA (LEFT SIDE)`,
  [`Fascia cervicale superficiale`]: `Superficial cervical fascia`,
  [`FASCIA CERVICALE SUPERFICIALE (LATO DESTRO)`]: `SUPERFICIAL CERVICAL FASCIA (RIGHT SIDE)`,
  [`FASCIA CERVICALE SUPERFICIALE (LATO SINISTRO)`]: `SUPERFICIAL CERVICAL FASCIA (LEFT SIDE)`,
  [`FASCIA CERVICALE SUPERFICIALE ANTERIORE (LATO DESTRO)`]: `SUPERFICIAL CERVICAL FASCIA (RIGHT SIDE)`,
  [`FASCIA CERVICALE SUPERFICIALE ANTERIORE (LATO SINISTRO)`]: `SUPERFICIAL CERVICAL FASCIA (LEFT SIDE)`,
  [`FASCIA CLAVIPETTORALE DESTRA`]: `RIGHT CLAVIPECTORAL FASCIA`,
  [`FASCIA CLAVIPETTORALE SINISTRA`]: `LEFT CLAVIPECTORAL FASCIA`,
  [`FASCIA CRIBROSA DESTRA`]: `RIGHT CRIBIFORM FASCIA`,
  [`FASCIA CRIBROSA SINISTRA`]: `LEFT CRIBIFORM FASCIA`,
  [`Fascia crurale (DX)`]: `Crural fascia (R)`,
  [`Fascia crurale (SX)`]: `Crural fascia (L)`,
  [`FASCIA CRURALE DESTRA`]: `RIGHT CRURAL FASCIA`,
  [`FASCIA CRURALE SINISTRA`]: `LEFT CRURAL FASCIA`,
  [`FASCIA DEL MUSCOLO GRAN DORSALE DESTRO`]: `FASCIA OF RIGHT LATISSIMUS DORSI MUSCLE`,
  [`FASCIA DEL MUSCOLO GRAN DORSALE SINISTRO`]: `FASCIA OF LEFT LATISSIMUS DORSI MUSCLE`,
  [`FASCIA DEL MUSCOLO PEDIDIO DESTRO`]: `FASCIA OF RIGHT PEDIDIUS MUSCLE`,
  [`FASCIA DEL MUSCOLO PEDIDIO SINISTRO`]: `SUBTENDINEOUS BURSA OF LEFT TIBIALIS ANTERIOR`,
  [`FASCIA DEL MUSCOLO PIRIFORME DESTRO`]: `FASCIA OF RIGHT PIRIFORMIS MUSCLE`,
  [`FASCIA DEL MUSCOLO PIRIFORME SINISTRO`]: `FASCIA OF LEFT PIRIFORMIS MUSCLE`,
  [`FASCIA DEL MUSCOLO SERRATO ANTERIORE DESTRO`]: `FASCIA OF RIGHT SERRATU ANTERIOR MUSCLE`,
  [`FASCIA DEL MUSCOLO SERRATO ANTERIORE SINISTRO`]: `FASCIA OF LEFT SERRATU ANTERIOR MUSCLE`,
  [`FASCIA DEL MUSCOLO SUCCLAVIO DESTRO`]: `FASCIA OF RIGHT SUBCLAVIUS MUCLE`,
  [`FASCIA DEL MUSCOLO SUCCLAVIO SINISTRO`]: `FASCIA OF LEFT SUBCLAVIUS MUCLE`,
  [`FASCIA DELTOIDEA DESTRA`]: `RIGHT DELTOID FASCIA`,
  [`FASCIA DELTOIDEA SINISTRA`]: `LEFT DELTOID FASCIA`,
  [`Fascia dorsale del piede (DX)`]: `Dorsal fascia of foot (R)`,
  [`Fascia dorsale del piede (SX)`]: `Dorsal fascia of foot (L)`,
  [`FASCIA DORSALE DEL PIEDE DESTRO`]: `DORSAL FASCIA OF RIGHT FOOT`,
  [`FASCIA DORSALE DEL PIEDE SINISTRO`]: `DORSAL FASCIA OF LEFT FOOT`,
  [`FASCIA DORSALE DELLA MANO DESTRA`]: `DORSAL FASCIA OF RIGHT HAND`,
  [`FASCIA DORSALE DELLA MANO SINISTRA`]: `DORSAL FASCIA OF LEFT HAND`,
  [`FASCIA DORSALE PROFONDA DEL PIEDE DESTRO`]: `DORSAL FASCIA OF RIGHT FOOT (DEEP LAYER)`,
  [`FASCIA DORSALE PROFONDA DEL PIEDE SINISTRO`]: `DORSAL FASCIA OF LEFT FOOT (DEEP LAYER)`,
  [`FASCIA DORSALE PROFONDA DELLA MANO DESTRA`]: `DEEP DORSAL FASCIA OF RIGHT HAND `,
  [`FASCIA DORSALE PROFONDA DELLA MANO SINISTRA`]: `DEEP DORSAL FASCIA OF LEFT HAND `,
  [`FASCIA DORSALE SUPERFICIALE DEL PIEDE DESTRO`]: `DORSAL FASCIA OF RIGHT FOOT (SUPERFICIAL LAYER)`,
  [`FASCIA DORSALE SUPERFICIALE DEL PIEDE SINISTRO`]: `DORSAL FASCIA OF LEFT FOOT (SUPERFICIAL LAYER)`,
  [`FASCIA DORSALE SUPERFICIALE DELLA MANO DESTRA`]: `SUPERFICIAL DORSAL FASCIA OF RIGHT HAND `,
  [`FASCIA DORSALE SUPERFICIALE DELLA MANO SINISTRA`]: `SUPERFICIAL DORSAL FASCIA OF LEFT HAND `,
  [`Fascia femorale (DX)`]: `Femoral fascia (R)`,
  [`Fascia femorale (SX)`]: `Femoral fascia (L)`,
  [`FASCIA FEMORALE DESTRA`]: `RIGHT FEMORAL FASCIA`,
  [`FASCIA FEMORALE SINISTRA`]: `LEFT FEMORAL FASCIA`,
  [`Fascia iliaca (DX)`]: `Iliac fascia (R)`,
  [`Fascia iliaca (SX)`]: `Iliac fascia (L)`,
  [`FASCIA ILIACA DESTRA`]: `RIGHT ILIAC FASCIA`,
  [`FASCIA ILIACA SINISTRA`]: `LEFT ILIAC FASCIA`,
  [`FASCIA MASSETERINA DESTRA`]: `RIGHT MASSETERIC FASCIA`,
  [`FASCIA MASSETERINA SINISTRA`]: `LEFT MASSETERIC FASCIA`,
  [`Fascia nucale`]: `Nuchal fascia`,
  [`FASCIA NUCALE A DESTRA`]: `NUCHAL FASCIA (RIGHT SIDE)`,
  [`FASCIA NUCALE A SINISTRA`]: `NUCHAL FASCIA (LEFT SIDE)`,
  [`FASCIA OTTURATORIA DESTRA`]: `RIGHT OBTURATOR FASCIA`,
  [`FASCIA OTTURATORIA SINISTRA`]: `LEFT OBTURATOR FASCIA`,
  [`FASCIA PALMARE DESTRA`]: `RIGHT PALMAR APONEUROSIS`,
  [`FASCIA PALMARE PROFONDA DELLA MANO DESTRA`]: `RIGHT DEEP PALMAR FASCIA`,
  [`FASCIA PALMARE PROFONDA DELLA MANO SINISTRA`]: `LEFT DEEP PALMAR FASCIA`,
  [`FASCIA PALMARE SINISTRA`]: `LEFT PALMAR APONEUROSIS`,
  [`FASCIA PALMARE SUPERFICIALE DELLA MANO DESTRA`]: `RIGHT SUPERFICIAL PALMAR FASCIA`,
  [`FASCIA PALMARE SUPERFICIALE DELLA MANO SINISTRA`]: `LEFT SUPERFICIAL PALMAR FASCIA`,
  [`FASCIA PAROTIDEA DESTRA`]: `RIGHT PAROTID FASCIA`,
  [`FASCIA PAROTIDEA SINISTRA`]: `LEFT PAROTID FASCIA`,
  [`FASCIA PELVICA`]: `PELVIC FASCIA`,
  [`FASCIA PELVICA PARIETALE`]: `PARIETAL PELVIC FASCIA`,
  [`FASCIA PELVICA VISCERALE`]: `VISCERAL PELVIC FASCIA`,
  [`FASCIA PERINEALE`]: `PERINEAL FASCIA`,
  [`FASCIA PERINEALE PROFONDA`]: `DEEP PERINEAL FASCIA`,
  [`FASCIA PETTINEA DESTRA`]: `RIGHT PECTINEAL FASCIA`,
  [`FASCIA PETTINEA SINISTRA`]: `LEFT PECTINEAL FASCIA`,
  [`Fascia pettorale (DX)`]: `Pectoral fascia (R)`,
  [`Fascia pettorale (SX)`]: `Pectoral fascia (L)`,
  [`FASCIA PETTORALE DESTRA`]: `RIGHT PECTORAL FASCIA`,
  [`FASCIA PETTORALE SINISTRA`]: `LEFT PECTORAL FASCIA`,
  [`Fascia plantare (DX)`]: `Plantar fascia (R)`,
  [`Fascia plantare (SX)`]: `Plantar fascia (L)`,
  [`Fascia Plantare Attivazione Flessione Dita`]: `TOWEL TOE CURLS`,
  [`FASCIA PLANTARE DESTRA (FASCIO LATERALE)`]: `RIGHT PLANTAR APONEUROSI (LATERAL BAND)`,
  [`FASCIA PLANTARE SINISTRA (FASCIO LATERALE)`]: `LEFT PLANTAR APONEUROSI (LATERAL BAND)`,
  [`FASCIA PLANTARE SUPERFICIALE DESTRA`]: `RIGHT PLANTAR APONEUROSIS (SUPERFICIAL LAYER)`,
  [`FASCIA PLANTARE SUPERFICIALE SINISTRA`]: `LEFT PLANTAR APONEUROSIS (SUPERFICIAL LAYER)`,
  [`Fascia pretracheale`]: `Pretracheal fascia`,
  [`FASCIA PRETRACHEALE`]: `CERVICAL FASCIA (PRETRACHEAL LAYER)`,
  [`Fascia pretracreale`]: `Pretracheal fascia`,
  [`FASCIA RETROPROSTATICA (M)`]: `RETROPROSTATIC FASCIA (M)`,
  [`FASCIA RETROVAGINALE (F)`]: `RETROVAGINAL FASCIA (F)`,
  [`FASCIA SOPRASPINATA DESTRA`]: `RIGHT SUPRASPINOUS FASCIA`,
  [`FASCIA SOPRASPINATA SINISTRA`]: `LEFT SUPRASPINOUS FASCIA`,
  [`FASCIA SOTTOSCAPOLARE DESTRA`]: `RIGHT SUBSCAPULARIS FASCIA`,
  [`FASCIA SOTTOSCAPOLARE SINISTRA`]: `LEFT SUBSCAPULARIS FASCIA`,
  [`FASCIA SOTTOSPINATA DESTRA`]: `RIGHT INFRASPINATUS FASCIA`,
  [`FASCIA SOTTOSPINATA SINISTRA`]: `LEFT INFRASPINATUS FASCIA`,
  [`Fascia temporale (DX)`]: `Temporal fascia (R)`,
  [`Fascia temporale (SX)`]: `Temporal fascia (L)`,
  [`FASCIA TEMPORALE DESTRA`]: `RIGHT TEMPORAL FASCIA`,
  [`FASCIA TEMPORALE SINISTRA`]: `LEFT TEMPORAL FASCIA`,
  [`FASCIA TORACOLOMBARE`]: `THORACOLUMBAR FASCIA`,
  [`Fascia toracolombare (DX)`]: `Thoracolumbar fascia (R)`,
  [`FASCIA TORACOLOMBARE (STRATO ANTERIORE A DESTRA)`]: `THORACOLUMBAR FASCIA (ANTERIOR LAYER ON RIGHT SIDE)`,
  [`FASCIA TORACOLOMBARE (STRATO ANTERIORE A SINISTRA)`]: `THORACOLUMBAR FASCIA (ANTERIOR LAYER ON LEFT SIDE)`,
  [`FASCIA TORACOLOMBARE (STRATO INTERMEDIO A DESTRA)`]: `THORACOLUMBAR FASCIA (MIDDLE LAYER ON RIGHT SIDE)`,
  [`FASCIA TORACOLOMBARE (STRATO INTERMEDIO A SINISTRA)`]: `THORACOLUMBAR FASCIA (MIDDLE LAYER ON LEFT SIDE)`,
  [`FASCIA TORACOLOMBARE (STRATO POSTERIORE A DESTRA)`]: `THORACOLUMBAR FASCIA (POSTERIOR LAYER ON RIGHT SIDE)`,
  [`FASCIA TORACOLOMBARE (STRATO POSTERIORE A SINISTRA)`]: `THORACOLUMBAR FASCIA (POSTERIOR LAYER ON LEFT SIDE)`,
  [`Fascia toracolombare (SX)`]: `Thoracolumbar fascia (L)`,
  [`FASCIA TRASVERSALE DESTRA`]: `FASCIA TRASVERSALIS (RIGHT SIDE)`,
  [`FASCIA TRASVERSALE SINISTRA`]: `FASCIA TRASVERSALIS (LEFT SIDE)`,
  [`Fasciopatia`]: `Fasciopathy`,
  [`fasciopatia plantare`]: `plantar fascia fasciopathy`,
  [`Fasciopatia plantare media/distale`]: `Mid/distal plantar fasciitis`,
  [`Fastidioso`]: `Nagging`,
  [`Fatica`]: `Fatigue`,
  [`FATICA`]: `Fatigue`,
  [`fatica inusuale`]: `Unusual fatigue`,
  [`Fatica inusuale`]: `Unusual fatigue, drowsiness`,
  [`Fatica localizzata`]: `Localised fatigue`,
  [`Faticoso`]: `Tiring`,
  [`Fattori Aggravanti`]: `Aggravating factors`,
  [`Fattori Allevianti`]: `Relieving factors`,
  [`Febbre, brividi, sudore spontaneo o notturno`]: `Fever, chills, sweats (day or night)`,
  [`Fegato`]: `Liver`,
  [`FEGATO`]: `LIVER`,
  [`FEGATO (FACCIA DIAFRAMMATICA)`]: `LIVER (DIAPHRAGMATIC SURFACE)`,
  [`FEGATO (LOBO CAUDATO)`]: `LIVER (CAUDATE LOBE)`,
  [`FEGATO (LOBO DESTRO)`]: `LIVER (RIGHT LOBE)`,
  [`FEGATO (LOBO QUADRATO)`]: `LIVER (QUADRATE LOBE)`,
  [`FEGATO (LOBO SINISTRO)`]: `LIVER (LEFT LOBE)`,
  [`Felice`]: `Have you been a happy person?`,
  [`Femoral Nerve Test`]: `Femoral Nerve Test`,
  [`Femoral Nerve Test (DX)`]: `Femoral Nerve Test (R)`,
  [`Femoral Nerve Test (SX)`]: `Femoral Nerve Test (L)`,
  [`Femore (DX)`]: `Femur (R)`,
  [`Femore (SX)`]: `Femur (L)`,
  [`FEMORE DESTRO`]: `RIGHT FEMUR`,
  [`FEMORE DESTRO (COLLO ANATOMICO)`]: `RIGHT FEMUR (ANATOMICAL NECK)`,
  [`FEMORE DESTRO (COLLO CHIRURGICO)`]: `RIGHT FEMUR (SURGICAL NECK)`,
  [`FEMORE DESTRO (DIAFISI)`]: `RIGHT FEMUR (SHAFT)`,
  [`FEMORE DESTRO (GRANDE TROCANTERE)`]: `RIGHT FEMUR (GREATER TROCHANTER)`,
  [`FEMORE DESTRO (PICCOLO TROCANTERE)`]: `RIGHT FEMUR (LESSER TROCHANTER)`,
  [`FEMORE DESTRO (TESTA DEL FEMORE)`]: `RIGHT FEMUR (HEAD OF FEMUR)`,
  [`FEMORE DESTRO (TUBERCOLO ADDUTTORIO)`]: `RIGHT FEMUR (ADDUCTOR TUBERCLE)`,
  [`FEMORE SINISTRO`]: `LEFT FEMUR`,
  [`FEMORE SINISTRO (COLLO ANATOMICO)`]: `LEFT FEMUR (ANATOMICAL NECK)`,
  [`FEMORE SINISTRO (COLLO CHIRURGICO)`]: `LEFT FEMUR (SURGICAL NECK)`,
  [`FEMORE SINISTRO (DIAFISI)`]: `LEFT FEMUR (SHAFT)`,
  [`FEMORE SINISTRO (GRANDE TROCANTERE)`]: `LEFT FEMUR (GREATER TROCHANTER)`,
  [`FEMORE SINISTRO (PICCOLO TROCANTERE)`]: `LEFT FEMUR (LESSER TROCHANTER)`,
  [`FEMORE SINISTRO (TESTA DEL FEMORE)`]: `LEFT FEMUR (HEAD OF FEMUR)`,
  [`FEMORE SINISTRO (TUBERCOLO ADDUTTORIO)`]: `LEFT FEMUR (ADDUCTOR TUBERCLE)`,
  [`Fendente`]: `Splitting`,
  [`Ferita`]: `Laceration`,
  [`Ferita al collo`]: `Neck Laceration`,
  [`Ferita al collo che necessita di sutura`]: `Neck laceration requiring suturing`,
  [`Ferita al collo che non necessita di sutura`]: `Neck laceration not requiring suturing`,
  [`Ferita al cuoio capelluto`]: `Scalp laceration`,
  [`Ferita al labbro che necessita di sutura`]: `Lip laceration requiring suturing`,
  [`Ferita al labbro che non necessita di sutura`]: `Lip abrasion not requiring suturing`,
  [`Ferita al mento`]: `Chin laceration`,
  [`Ferita al naso che necessita di sutura`]: `Nose laceration requiring suturing`,
  [`Ferita al naso che non necessita di sutura`]: `Nose laceration not requiring suturing`,
  [`Ferita al sopracciglio che necessita di sutura`]: `Eyebrow laceration requiring suturing`,
  [`Ferita al sopracciglio che non necessita di sutura`]: `Eyebrow laceration not requiring suturing`,
  [`Ferita al tronco che necessita di sutura`]: `Truncal laceration requiring suturing`,
  [`Ferita all'orecchio`]: `Ear laceration`,
  [`Ferita all'orecchio con necessita' di suturazione`]: `Ear laceration requiring suturing`,
  [`Ferita alla caviglia`]: `Ankle laceration`,
  [`Ferita alla coscia`]: `Thigh Laceration/Abrasion`,
  [`Ferita alla guancia che necessita di sutura`]: `Cheek laceration requiring suturing`,
  [`Ferita alla guancia che non necessita di sutura`]: `Cheek laceration not requiring suturing`,
  [`Ferita alla lingua`]: `Tongue laceration`,
  [`Ferita alla lingua che necessita di sutura`]: `Tongue laceration requiring suturing`,
  [`Ferita alla lingua che non necessita di sutura`]: `Tongue laceration not requiring suturing`,
  [`Ferita alla mucosa buccale che necessita di sutura`]: `Mucosal laceration requiring suturing`,
  [`Ferita alla mucosa buccale che non necessita di sutura`]: `Mucosal laceration not requiring suturing`,
  [`Ferita alla palpebra che necessita di sutura`]: `Eyelid laceration requiring suturing`,
  [`Ferita alla palpebra che non necessita di sutura`]: `Eyelid laceration not requiring suturing`,
  [`Ferita alla testa con localizzazione aspecifica o ferite multiple che necessitano di sutura`]: `Head laceration location unspecified/or multiple requiring suturing`,
  [`Ferita alla testa con localizzazione aspecifica o ferite multiple che non necessitano di sutura`]: `Head laceration location unspecified/or multiple not requiring suturing`,
  [`Ferita del braccio`]: `Upper arm laceration`,
  [`Ferita del ginocchio`]: `Lacerated knee`,
  [`Ferita dell'unghia/letto ungueale del pollice`]: `Laceration of thumb nail/nailbed`,
  [`Ferita dell'unghia/letto ungueale del/le dito/a`]: `Laceration of fingernail/nailbed`,
  [`Ferita della gamba`]: `Lower Leg Laceration`,
  [`Ferita di anca/inguine`]: `Hip and Groin Laceration`,
  [`Ferita perforante alla bocca`]: `Perforating mouth laceration`,
  [`Ferita perforante alla bocca che necessita di sutura`]: `Perforating mouth laceration requiring suturing`,
  [`Ferita profonda (intra-articolare) del gomito`]: `Elbow laceration deep`,
  [`Ferita profonda (intraarticolare) della caviglia`]: `Deep (intraarticular) laceration ankle`,
  [`Ferita profonda del ginocchio (intraarticolare)`]: `Deep knee laceration - intraarticular`,
  [`Ferita/abrasione a localizzazione non specificata`]: `Laceration/Abrasion location unspecified`,
  [`Ferita/abrasione ai tessuti molli della spalla`]: `Shoulder Soft Tissue Abrasion`,
  [`Ferita/abrasione al labbro`]: `Lip laceration`,
  [`Ferita/abrasione al naso`]: `Nose abrasion`,
  [`Ferita/abrasione al pollice`]: `Thumb laceration`,
  [`Ferita/abrasione al polpaccio`]: `Calf laceration/abrasion`,
  [`Ferita/abrasione al polso`]: `Wrist laceration/abrasion`,
  [`Ferita/abrasione al polso e alla mano`]: `Wrist and Hand Laceration/Abrasion`,
  [`Ferita/abrasione al sopracciglio`]: `Eyebrow abrasion`,
  [`Ferita/abrasione al tronco`]: `Truncal Laceration/Abrasion`,
  [`Ferita/abrasione al tronco che non necessita di sutura`]: `Truncal laceration/abrasion not requiring suturing`,
  [`Ferita/abrasione al/le dito/a`]: `Finger laceration`,
  [`Ferita/abrasione alla faccia anteriore della gamba`]: `Shin abrasion`,
  [`Ferita/abrasione alla faccia non altrimenti specificata`]: `Facial abrasion not otherwise specified`,
  [`Ferita/abrasione alla faccia non altrimenti specificata che necessita di sutura`]: `Facial laceration requiring suturing`,
  [`Ferita/abrasione alla faccia non altrimenti specificata che non necessita di sutura`]: `Facial laceration NOS not requiring suturing`,
  [`Ferita/abrasione alla fronte`]: `Forehead abrasion`,
  [`Ferita/abrasione alla fronte che necessita di sutura`]: `Forehead laceration requiring suturing`,
  [`Ferita/abrasione alla fronte che non necessita di sutura`]: `Forehead laceration not requiring suturing`,
  [`Ferita/abrasione alla guancia`]: `Cheek abrasion`,
  [`Ferita/abrasione alla mano`]: `Hand laceration`,
  [`Ferita/abrasione alla mucosa della bocca`]: `Mouth abrasion`,
  [`Ferita/abrasione alla palpebra`]: `Eyelid laceration`,
  [`Ferita/abrasione alla spalla che necessita di sutura`]: `Shoulder laceration requiring suturing`,
  [`Ferita/abrasione alla spalla che non necessita di sutura`]: `Shoulder laceration not requiring suturing`,
  [`Ferita/abrasione che attraversa I confini anatomici di una regione`]: `Laceration/Abrasion crossing anatomical boundaries`,
  [`Ferita/abrasione dell'arto inferiore`]: `Laceration/abrasion lower limb`,
  [`Ferita/abrasione dell'arto superiore`]: `Laceration/abrasion upper limb`,
  [`Ferita/abrasione dell'avambraccio`]: `Forearm Abrasion`,
  [`Ferita/abrasione della colonna lombare`]: `Lumbar Laceration`,
  [`Ferita/abrasione della parete toracica`]: `Chest Wall laceration/Abrasion`,
  [`Ferita/abrasione dorsale del polso`]: `Dorsal wrist laceration/abrasion`,
  [`Ferita/abrasione dorsale della mano`]: `Dorsal hand laceration`,
  [`Ferita/abrasione palmare del polso`]: `Volar wrist laceration/abrasion`,
  [`Ferita/abrasione palmare della mano`]: `Palmar hand laceration`,
  [`Ferita/abrasione superficiale del ginocchio`]: `Superficial knee laceration/abrasion`,
  [`Ferita/abrasione superficiale del gomito`]: `Elbow abrasion superficial`,
  [`Ferita/abrasione superficiale della caviglia`]: `Superficial ankle laceration/abrasion`,
  [`Ferita/abrasione toracica`]: `Thoracic Laceration`,
  [`Fermarsi e ripartire immediatamente`]: `Stop and start quickly`,
  [`Feroce`]: `Vicious`,
  [`Fibrillazione atriale`]: `Atrial fibrillation`,
  [`Fibrillazioni`]: `Heart fibrillations`,
  [`FIBROCARTILAGINE TRIANGOLARE DEL POLSO DESTRO`]: `RIGHT TRIANGULAR FIBROCARTILAGE COMPLEX [TFCC]`,
  [`FIBROCARTILAGINE TRIANGOLARE DEL POLSO SINISTRO`]: `LEFT TRIANGULAR FIBROCARTILAGE COMPLEX [TFCC]`,
  [`Fibromialgia`]: `Fibromyalgia `,
  [`Fibromialgia / sofferenza muscolare multipla`]: `Fibromyalgia/multiple sore muscle areas`,
  [`Fibrosi cistica`]: `Cystic Fibrosis`,
  [`Fibular Head Translation Test (DX)`]: `Fibular Head Translation Test (R)`,
  [`Fibular Head Translation Test (SX)`]: `Fibular Head Translation Test (L)`,
  [`Figure 8 Hop Test (DX)`]: `Figure 8 Hop Test (R)`,
  [`Figure 8 Hop Test (SX)`]: `Figure 8 Hop Test (L)`,
  [`Figure 8 Test for Edema (DX)`]: `Figure 8 Test for Edema (R)`,
  [`Figure 8 Test for Edema (SX)`]: `Figure 8 Test for Edema (L)`,
  [`Figure Four Test (DX)`]: `Figure Four Test (R)`,
  [`Figure Four Test (SX)`]: `Figure Four Test (L)`,
  [`Finger Rolling Test`]: `Finger Rolling Test`,
  [`Finger TapTest`]: `Finger TapTest`,
  [`Finkelstein's Test (DX)`]: `Finkelstein's Test (R)`,
  [`Finkelstein's Test (SX)`]: `Finkelstein's Test (L)`,
  [`First Ray Mobility (DX)`]: `First Ray Mobility (R)`,
  [`First Ray Mobility (SX)`]: `First Ray Mobility (L)`,
  [`Fissazione`]: `Fixation`,
  [`Fistball`]: `Fistball`,
  [`Fitta`]: `Stabbing`,
  [`Flebopatia`]: `Venous disease`,
  [`Flessione`]: `Flexion`,
  [`Flessione (ipercifosi)`]: `Flexion (hyperkyphosis)`,
  [`Flessione (verticalizzazione)`]: `Flexion (verticalization)`,
  [`Flessione articolazione del ginocchio (da prono) (DX)`]: `Knee joint flexion (prone) (R)`,
  [`Flessione articolazione del ginocchio (da prono) (SX)`]: `Knee joint flexion (prone) (L)`,
  [`Flessione articolazione del ginocchio (DX)`]: `Knee joint flexion (R)`,
  [`Flessione articolazione del ginocchio (SX)`]: `Knee joint flexion (L)`,
  [`Flessione articolazione del gomito (DX)`]: `Elbow joint flexion (R)`,
  [`Flessione articolazione del gomito (SX)`]: `Elbow joint flexion (L)`,
  [`Flessione articolazione del polso (DX)`]: `Wrist joint flexion (R)`,
  [`Flessione articolazione del polso (SX)`]: `Wrist joint flexion (L)`,
  [`Flessione articolazione dell'anca (a ginocchio esteso da supino) (Straight Leg Raise Test) (DX)`]: `Hip joint flexion (Straight Leg Raise Test) (R)`,
  [`Flessione articolazione dell'anca (a ginocchio esteso da supino) (Straight Leg Raise Test) (SX)`]: `Hip joint flexion (Straight Leg Raise Test) (L)`,
  [`Flessione articolazione dell'anca (con ginocchio flesso da supino) (DX)`]: `Hip joint flexion (with bended knee) (R)`,
  [`Flessione articolazione dell'anca (con ginocchio flesso da supino) (SX)`]: `Hip joint flexion (with bended knee) (L)`,
  [`Flessione articolazione dell'anca (DX)`]: `Hip joint flexion (R)`,
  [`Flessione articolazione dell'anca (SX)`]: `Hip joint flexion (L)`,
  [`Flessione articolazione della spalla (con gomito flesso) (DX)`]: `Shoulder joint flexion (with bended elbow) (R)`,
  [`Flessione articolazione della spalla (con gomito flesso) (SX)`]: `Shoulder joint flexion (with bended elbow) (L)`,
  [`Flessione articolazione della spalla (DX)`]: `Shoulder joint flexion (R)`,
  [`Flessione articolazione della spalla (SX)`]: `Shoulder joint flexion (L)`,
  [`Flessione articolazione interfalangea del pollice (DX)`]: `Interphalangeal joint of pollicis flexion (R)`,
  [`Flessione articolazione interfalangea del pollice (SX)`]: `Interphalangeal joint of pollicis flexion (L)`,
  [`Flessione articolazione interfalangea dell'alluce (DX)`]: `Interphalangeal joint of hallux flexion (R)`,
  [`Flessione articolazione interfalangea dell'alluce (SX)`]: `Interphalangeal joint of hallux flexion (L)`,
  [`Flessione articolazione metacarpofalangea del pollice (DX)`]: `Metacarpophalangeal joint of pollicis flexion (R)`,
  [`Flessione articolazione metacarpofalangea del pollice (SX)`]: `Metacarpophalangeal joint of pollicis flexion (L)`,
  [`Flessione articolazione metatarsofalangea dell'alluce (DX)`]: `Metatarsophalangeal joint of hallux flexion (R)`,
  [`Flessione articolazione metatarsofalangea dell'alluce (SX)`]: `Metatarsophalangeal joint of hallux flexion (L)`,
  [`Flessione articolazioni interfalangee distali del piede dalla II alla V (DX)`]: `Distal interphalangeal joints of foot (II to V) flexion (R)`,
  [`Flessione articolazioni interfalangee distali del piede dalla II alla V (SX)`]: `Distal interphalangeal joints of foot (II to V) flexion (L)`,
  [`Flessione articolazioni interfalangee distali della mano dalla II alla V (DX)`]: `Distal interphalangeal joints (II-V) flexion (R)`,
  [`Flessione articolazioni interfalangee distali della mano dalla II alla V (SX)`]: `Distal interphalangeal joints (II-V) flexion (L)`,
  [`Flessione articolazioni interfalangee prossimali del piede dalla II alla V (DX)`]: `Proximal interphalangeal joints of foot (II to V) flexion (R)`,
  [`Flessione articolazioni interfalangee prossimali del piede dalla II alla V (SX)`]: `Proximal interphalangeal joints of foot (II to V) flexion (L)`,
  [`Flessione articolazioni interfalangee prossimali della mano dalla II alla V (DX)`]: `Proximal interphalangeal joints (II-V) flexion (R)`,
  [`Flessione articolazioni interfalangee prossimali della mano dalla II alla V (SX)`]: `Proximal interphalangeal joints (II-V) flexion (L)`,
  [`Flessione articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) flexion (R)`,
  [`Flessione articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) flexion (L)`,
  [`Flessione articolazioni metacarpofalangee della mano dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) flexion (R)`,
  [`Flessione articolazioni metacarpofalangee della mano dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) flexion (L)`,
  [`Flessione articolazioni metatarsofalangee dalla II alla V (DX)`]: `Matatarsophalangeal joints (II-V) flexion (R)`,
  [`Flessione articolazioni metatarsofalangee dalla II alla V (SX)`]: `Matatarsophalangeal joints (II-V) flexion (L)`,
  [`Flessione articolazioni metatarsofalangee del piede dalla II alla V (DX)`]: `Matatarsophalangeal joints (II-V) flexion (R)`,
  [`Flessione articolazioni metatarsofalangee del piede dalla II alla V (SX)`]: `Matatarsophalangeal joints (II-V) flexion (L)`,
  [`Flessione bilaterale`]: `Bilateral flexion`,
  [`Flessione bilaterale articolazioni delle spalle (da seduto)`]: `Bilateral shoulder joint flexion (sitting)`,
  [`Flessione bilaterale articolazioni delle spalle a 180 gradi`]: `Bilateral shoulder joint flexion `,
  [`Flessione bilaterale di anca e ginocchio a 90 gradi (da supino)`]: `Bilateral hip joint flexion (with bended knee) (at 90 degrees in supine position) `,
  [`Flessione collo`]: `Neck flexion`,
  [`Flessione collo (da supino)`]: `Neck flexion (supine position)`,
  [`Flessione di anca e ginocchio (a 90 gradi in piedi) (DX)`]: `Hip joint flexion (with bended knee) (at 90 degrees standing) (R)`,
  [`Flessione di anca e ginocchio (a 90 gradi in piedi) (SX)`]: `Hip joint flexion (with bended knee) (at 90 degrees standing) (L)`,
  [`Flessione dorsale`]: `Dorsal flexion`,
  [`Flessione dorsale articolazione della caviglia (DX)`]: `Ankle joint dorsal flexion (R)`,
  [`Flessione dorsale articolazione della caviglia (SX)`]: `Ankle joint dorsal flexion (L)`,
  [`Flessione e rotazione`]: `Flexion/rotation`,
  [`Flessione e rotazione destra`]: `Right flexion/rotation`,
  [`Flessione e rotazione sinistra`]: `Left flexion/rotation`,
  [`Flessione lombare`]: `Lumbar flexion`,
  [`Flessione plantare`]: `Plantar flexion`,
  [`Flessione plantare articolazione della caviglia (DX)`]: `Ankle joint plantar flexion (R)`,
  [`Flessione plantare articolazione della caviglia (SX)`]: `Ankle joint plantar flexion (L)`,
  [`Flessione testa`]: `Head flexion`,
  [`Flessione tronco`]: `Trunk flexion`,
  [`Flessione unilaterale destra`]: `Right unilateral flexion`,
  [`Flessione unilaterale di anca e ginocchio (a 90 gradi da supino) (DX)`]: `Hip joint flexion (with bended knee) (at 90 degrees in supine position) (R)`,
  [`Flessione unilaterale di anca e ginocchio (a 90 gradi da supino) (SX)`]: `Hip joint flexion (with bended knee) (at 90 degrees in supine position) (L)`,
  [`Flessione unilaterale sinistra`]: `Left unilateral flexion`,
  [`Flessione vertebre cervicali`]: `Cervical vertebrae flexion`,
  [`Flessione vertebre toraciche`]: `Thoracic vertebrae flexion`,
  [`Flesso`]: `Flexum`,
  [`Flessori Dell'Anca Con Elastico`]: `HIP FLEXION WITH BAND RESISTANCE`,
  [`Flettendo completamente l'anca`]: `Bending your hip fully`,
  [`Flettere completamente il ginocchio`]: `Bending knee fully`,
  [`Flettere completamente l'anca`]: `Bending your hip fully`,
  [`Flettersi verso il pavimento/raccogliere un oggetto da terra`]: `Bending to floor/pick up an object`,
  [`Flexed Arm Hang (DX)`]: `Flexed Arm Hang (R)`,
  [`Flexed Arm Hang (SX)`]: `Flexed Arm Hang (L)`,
  [`Flexion Quadrant Test`]: `Flexion Quadrant Test`,
  [`Flexion-Internal Rotation Test (DX)`]: `Flexion-Internal Rotation Test (R)`,
  [`Flexion-Internal Rotation Test (SX)`]: `Flexion-Internal Rotation Test (L)`,
  [`Flick Sign (DX)`]: `Flick Sign (R)`,
  [`Flick Sign (SX)`]: `Flick Sign (L)`,
  [`Foam Roll Gluteo 1`]: `SELF MYOFASCIAL RELEASE - GLUTES`,
  [`Foam Roll Hamstring 1`]: `SELF MYOFASCIAL RELEASE - HAMSTRING`,
  [`Foam Roll Ileo-Tibiale 1`]: `SELF MYOFASCIAL RELEASE - ANTERIOR TIBIALIS`,
  [`Foam Roll Polpaccio 1`]: `SELF MYOFASCIAL RELEASE - GASTROCNEMIUS`,
  [`Foam Roll Retto 1`]: `SELF MYOFASCIAL RELEASE - RECTUS FEMORIS`,
  [`Foam Roll Retto 2`]: `SELF MYOFASCIAL RELEASE - RECTUS FEMORIS 2`,
  [`Foam Roll Soleo 2`]: `SELF MYOFASCIAL RELEASE - SOLEUS`,
  [`Foam Roll Soleo 3`]: `SELF MYOFASCIAL RELEASE - SOLEUS 2`,
  [`Foam Roll Tfl 1`]: `SELF MYOFASCIAL RELEASE - TLF`,
  [`Fonti di luce`]: `bright lights`,
  [`Foot cellulitis/infected ulcer`]: `Foot cellulitis/infected ulcer`,
  [`Foot Squeeze Test (DX)`]: `Foot Squeeze Test (R)`,
  [`Foot Squeeze Test (SX)`]: `Foot Squeeze Test (L)`,
  [`Football americano`]: `American football`,
  [`Forced Dorsiflexion Test (DX)`]: `Forced Dorsiflexion Test (R)`,
  [`Forced Dorsiflexion Test (SX)`]: `Forced Dorsiflexion Test (L)`,
  [`Forearm Rolling Test`]: `Forearm Rolling Test`,
  [`Formicolio`]: `Tingling`,
  [`Formicolio (sensazione di punture di spillo) al braccio, alla spalla o alla mano`]: `Tingling (pins and needles) in your arm, shoulder or hand`,
  [`Forte`]: `Severe`,
  [`Franchignoni F, Vercelli S, Giordano A, Sartorio F, Bravini E, Ferriero G. Minimal clinically important difference of the disabilities of the arm, shoulder and hand outcome measure (DASH) and its shortened version (QuickDASH). journal of orthopaedic & sp...`]: `Franchignoni F, Vercelli S, Giordano A, Sartorio F, Bravini E, Ferriero G. Minimal clinically important difference of the disabilities of the arm, shoulder and hand outcome measure (DASH) and its shortened version (QuickDASH). journal of orthopaedic & sp...`,
  [`Frattura`]: `Fracture`,
  [`Frattura`]: `Fracture`,
  [`Frattura acuta della pars interarticularis`]: `Lumbar pars interarticularis acute fracture`,
  [`Frattura al braccio`]: `Upper Arm Fracture`,
  [`Frattura al polso con complicazioni (es. Rottura dell'estensore lungo del pollice)`]: `Wrist fracture with complication (e.g. EPL rupture)`,
  [`Frattura alla coscia`]: `Thigh Fractures`,
  [`Frattura alla testa/faccia`]: `Head/Facial fracture`,
  [`Frattura bimalleolare`]: `Bimalleolar fracture`,
  [`Frattura cervicale instabile`]: `Unstable cervical fracture/s`,
  [`Frattura cervicale stabile`]: `Stable cervical fracture/s`,
  [`Frattura composta della mandibola`]: `Compound fractured mandible`,
  [`frattura costale`]: `Rib fracture`,
  [`Frattura costale multipla`]: `Fracture multiple ribs`,
  [`frattura cranica`]: `Cranial fracture`,
  [`Frattura da avulsione`]: `Avulsion fracture`,
  [`Frattura da avulsione del gomito con localizzazione multipla o non specificata`]: `Avulsion fracture elbow multiple locations or location unspecified`,
  [`Frattura da avulsione della base del V metatarso`]: `Avulsion fracture 5th metatarsal base`,
  [`Frattura da avulsione della falange`]: `Avulsion fracture phalanx`,
  [`Frattura da avulsione distale dell'omero`]: `Avulsion fracture distal humerus`,
  [`Frattura da avulsione prossimale del radio`]: `Avulsion fracture proximal radius`,
  [`Frattura da stress`]: `Bone stress fracture`,
  [`Frattura da stress attraverso il collo del femore (pertrocanterica)`]: `Stress fracture through femoral neck (both cortices)`,
  [`Frattura da stress cervicale`]: `Cervical stress fracture`,
  [`frattura da stress costale`]: `Rib stress fracture`,
  [`Frattura da stress costale`]: `Stress reaction rib(s)`,
  [`Frattura da stress del bacino`]: `Pelvic stress fracture`,
  [`frattura da stress del calcagno`]: `calcaneus stress fracture`,
  [`Frattura da stress del calcagno`]: `Stress fracture calcaneus`,
  [`Frattura da stress del carpo`]: `Carpal stress fracture`,
  [`Frattura da stress del coccige`]: `Stress fracture coccyx`,
  [`frattura da stress del collo del femore`]: `stress fracture of neck of femur`,
  [`Frattura da stress del collo del femore`]: `Femoral neck stress fracture`,
  [`frattura da stress del collo del IV metatarso`]: `stress fracture of neck of IV metatarsal`,
  [`frattura da stress del cuboide`]: `Cuboid stress fracture`,
  [`Frattura da stress del cuboide`]: `Cuboid stress fracture`,
  [`frattura da stress del cuneiforme`]: `Cuneiform stress fracture`,
  [`frattura da stress del femore`]: `Femural stress fracture`,
  [`Frattura da stress del ginocchio`]: `Knee Stress Fracture`,
  [`Frattura da stress del I metatarso`]: `First metatarsal stress fracture`,
  [`Frattura da stress del II metatarso`]: `Second metatarsal stress fracture`,
  [`Frattura da stress del III metatarso`]: `Third metatarsal stress fracture`,
  [`Frattura da stress del IV metatarso`]: `Fourth metatarsal stress fracture`,
  [`Frattura da stress del malleolo laterale`]: `Stress fracture lateral malleolus`,
  [`frattura da stress del malleolo mediale`]: `Medial malleolus stress fracture`,
  [`Frattura da stress del malleolo mediale`]: `Stress fracture medial malleolus`,
  [`Frattura da stress del navicolare`]: `Navicular stress fracture`,
  [`frattura da stress del navicolare (scafoide)`]: `navicular stress fracture`,
  [`Frattura da stress del peduncolo lombare`]: `Lumbar pedicle stress fracture`,
  [`frattura da stress del perone`]: `Stress fracture of fibula`,
  [`Frattura da stress del perone`]: `Stress fracture fibula`,
  [`frattura da stress del processo coracoideo`]: `Choracoid process Stress fracture `,
  [`Frattura da stress del processo coracoideo`]: `Stress fracture coracoid process`,
  [`frattura da stress del pube`]: `stress fractur of pubis`,
  [`Frattura da stress del radio e/o dell'ulna`]: `Stress fracture radius and/or ulna`,
  [`Frattura da stress del ramo pubico inferiore`]: `Stress fracture inferior pubic ramus`,
  [`Frattura da stress del sacro`]: `Stress fracture sacrum`,
  [`Frattura da stress del V metatarso`]: `Fifth metatarsal stress fracture`,
  [`Frattura da stress del/I cuneiforme/i`]: `Cuneiform stress fracture`,
  [`frattura da stress dell'alluce`]: `hallux stress fracture`,
  [`frattura da stress dell'astragalo`]: `talus stress fracture`,
  [`Frattura da stress dell'astragalo`]: `Stress fracture talus`,
  [`Frattura da stress dell'ileo`]: `Stress fracture ilium`,
  [`Frattura da stress dell'ischio`]: `Stress fracture ischium`,
  [`frattura da stress dell'omero`]: `Humeral stress fracture`,
  [`Frattura da stress dell'omero`]: `Stress fracture humerus`,
  [`frattura da stress dell'ulna`]: `ulna stress fracture`,
  [`Frattura da stress della base del II metatarso`]: `Base second metatarsal stress fracture`,
  [`Frattura da stress della caviglia`]: `Ankle stress fracture`,
  [`Frattura da stress della colonna lombare`]: `Stress fracture lumbar spine`,
  [`Frattura da stress della corticale anteriore della tibia`]: `Stress fracture anterior cortex tibia`,
  [`Frattura da stress della coscia`]: `Thigh Stress Fractures`,
  [`Frattura da stress della diafisi del femore`]: `Femoral shaft stress fracture`,
  [`frattura da stress della I costa`]: `Stress fracture of I rib`,
  [`frattura da stress della II base metatarsale`]: `II metatarsal base stress fracture`,
  [`Frattura da stress della mano (comprese quelle del pollice e delle dita)`]: `Hand stress fracture (incl thumb and fingers)`,
  [`Frattura da stress della pars interarticularis`]: `Pars interarticularis stress fracture`,
  [`Frattura da stress della pars interarticularis da L1 a L3`]: `Pars stress fracture L1 - L3`,
  [`Frattura da stress della pars interarticularis di L4`]: `Pars stress fracture L4`,
  [`Frattura da stress della pars interarticularis di L5`]: `Pars stress fracture L5`,
  [`Frattura da stress della pars interarticularis multipla (comprese le fratture bilaterali)`]: `Multiple (incl bilateral) pars stress fractures`,
  [`Frattura da stress della porzione inferiore del collo del femore (basicervicale)`]: `Stress fracture inferior cortex femoral neck`,
  [`Frattura da stress della porzione superiore del collo del femore (sottocapitata)`]: `Stress fracture superior cortex femoral neck`,
  [`frattura da stress della rotula`]: `patella stress fracture`,
  [`Frattura da stress della rotula`]: `Patellar stress fracture`,
  [`frattura da stress della tibia`]: `Tibia stress fracture`,
  [`Frattura da stress della tibia`]: `Stress fracture tibia`,
  [`frattura da stress delle ossa sesamoidi`]: `sesamoid stress fracture`,
  [`Frattura da stress di anca/inguine`]: `Hip/Groin Stress Fracture`,
  [`Frattura da stress di sesamoide`]: `Sesamoid stress fracture`,
  [`Frattura da stress distale del femore`]: `Distal femoral stress fracture`,
  [`Frattura da stress distale del perone alla caviglia`]: `Stress fracture fibula at ankle`,
  [`Frattura da stress metatarsale`]: `Stress fracture metatarsal`,
  [`Frattura da stress o lesione da sovraccarico del braccio`]: `Upper arm stress fracture`,
  [`Frattura da stress pelvica`]: `Stress fracture pelvic bone`,
  [`Frattura da stress posteromediale della tibia`]: `Stress fracture posteromedial tibia`,
  [`Frattura da stress prossimale della tibia`]: `Proximal tibial stress fracture`,
  [`Frattura da stress/compressione vertebrale toracica`]: `Thoracic spine stress/compression fracture`,
  [`Frattura da stress/sesamoidite`]: `Sesamoiditis/stress fracture`,
  [`Frattura dei metacarpi (II-V)`]: `Fracture metacarpals 2- 5`,
  [`Frattura deiafisaria di tibia e fibula`]: `Fractured midshaft tibia and fibula`,
  [`Frattura del calcagno`]: `Fractured calcaneus`,
  [`frattura del capitello radiale`]: `Radial capitellum fracture`,
  [`Frattura del coccige`]: `Fractured coccyx`,
  [`Frattura del collo`]: `Neck Fracture`,
  [`frattura del collo del femore`]: `fracture of neck of femur`,
  [`Frattura del collo del femore`]: `Fractured neck of femur`,
  [`Frattura del collo dell'astragalo`]: `Fractured talar neck`,
  [`Frattura del collo dell'omero`]: `Fracture neck of humerus`,
  [`Frattura del corpo vertebrale lombare`]: `Lumbar spine vertebral body fracture`,
  [`Frattura del corpo vertebrale toracico`]: `Fracture thoracic vertebral body`,
  [`Frattura del cranio`]: `Skull/cranial fracture`,
  [`Frattura del cuboide`]: `Fracture cuboid`,
  [`Frattura del femore`]: `Femoral fracture`,
  [`Frattura del I metatarso`]: `Fracture 1st Metatarsal`,
  [`Frattura del II metacarpo`]: `Fracture 2nd metacarpal`,
  [`Frattura del II metatarso`]: `Fracture 2nd Metatarsal`,
  [`Frattura del III metacarpo`]: `Fracture 3rd metacarpal`,
  [`Frattura del III metatarso`]: `Fracture 3rd metatarsal`,
  [`Frattura del IV metacarpo`]: `Fracture 4th metacarpal`,
  [`Frattura del IV metatarso`]: `Fracture 4th metatarsal`,
  [`Frattura del malleolo laterale`]: `Lateral malleolus fracture`,
  [`Frattura del malleolo mediale`]: `Medial malleolus fracture`,
  [`Frattura del malleolo posteriore`]: `Fracture posterior malleolus`,
  [`Frattura del margine costocondrale`]: `Fracture of costochondral margin`,
  [`Frattura del navicolare`]: `Fracture navicular`,
  [`Frattura del pavimento orbitale`]: `Orbital floor fracture`,
  [`Frattura del peduncolo lombare`]: `Lumbar pedicle fracture`,
  [`frattura del perone`]: `Fracture of fibula`,
  [`Frattura del perone`]: `Fractured fibula`,
  [`frattura del piatto tibiale`]: `Fracture of tibial plate`,
  [`frattura del piramidale`]: `triquetral fracture`,
  [`Frattura del pollice`]: `Fractured thumb`,
  [`Frattura del polo distale dello scafoide`]: `Fracture distal pole scaphoid`,
  [`Frattura del polo prossimale dello scafoide`]: `Fracture proximal pole scaphoid`,
  [`Frattura del processo anteriore del calcagno`]: `Fractured anterior process calcaneus`,
  [`Frattura del processo laterale dell'astragalo`]: `Fractured lateral process talus`,
  [`Frattura del processo posteriore dell'astragalo`]: `Fractured posterior process talus`,
  [`Frattura del processo spinoso della vertebra lombare`]: `Lumbar spinous process fracture`,
  [`Frattura del processo trasverso della vertebra lombare`]: `Fracture transverse process(es)`,
  [`Frattura del processo trasverso o spinoso della colonna toracica`]: `Fracture transverse or posterior process thoracic spine`,
  [`frattura del radio`]: `Radial facture`,
  [`Frattura del ramo pubico`]: `Fracture pubic ramus`,
  [`Frattura del ramo pubico inferiore`]: `Fracture inferior pubic ramus`,
  [`Frattura del ramo pubico superiore`]: `Fracture superior pubic ramus`,
  [`Frattura del sacro`]: `Fractured sacrum`,
  [`Frattura del terzo distale del perone`]: `Fractured distal shaft fibula`,
  [`Frattura del terzo esterno della clavicola`]: `Fracture outer third clavicle`,
  [`Frattura del terzo interno della clavicola`]: `Fracture inner third clavicle`,
  [`Frattura del terzo medio della clavicola`]: `Fracture middle third clavicle`,
  [`frattura del trapezio`]: `trapezium fracture`,
  [`Frattura del trapezio`]: `Fractured trapezium`,
  [`Frattura del V metacarpo`]: `Fifth metacarpal fracture`,
  [`frattura del V metatarso`]: `V metatarsal frcture`,
  [`Frattura del/i condilo/i omerale/i`]: `Fractured humeral condyle(s)`,
  [`Frattura del/i cuneiforme/i`]: `Fracture cuneiform`,
  [`Frattura dell'acetabolo`]: `Acetabular fracture`,
  [`Frattura dell'alluce`]: `Fracture great toe`,
  [`Frattura dell'apofisi uncinata dell'uncinato`]: `Fractured hook of hamate`,
  [`Frattura dell'astragalo`]: `Fractured talus`,
  [`frattura dell'epifisi radiale`]: `Radial epyphyseal fracture`,
  [`Frattura dell'ileo`]: `Fractured Ilium`,
  [`Frattura dell'ischio`]: `Fractured ischium`,
  [`Frattura dell'istmo dello scafoide`]: `Fracture waist scaphoid`,
  [`Frattura dell'olecrano`]: `Fractured olecranon`,
  [`frattura dell'olecrano dell'ulna`]: `fracture of ulna olecranon`,
  [`Frattura dell'omero`]: `Humerus fracture`,
  [`Frattura dell'os trigonum`]: `Fractured os trigonum`,
  [`Frattura dell'osso frontale`]: `Fractured frontal bone`,
  [`frattura dell'ulna`]: `Ulna fracture`,
  [`frattura dell'ulna distale`]: `fracture of distal ulna`,
  [`Frattura dell'uncinato`]: `Fractured hamate`,
  [`frattura dell'uncino dell'uncinato`]: `Hook of hamate fracture`,
  [`frattura della base del I metacarpo`]: `Fracture of the base of the first metacarpal`,
  [`frattura della clavicola`]: `Clavicle fracture`,
  [`Frattura della clavicola`]: `Clavicular fracture`,
  [`Frattura della colonna lombare`]: `Lumbar Spine Fracture`,
  [`Frattura della colonna lombare associata a lesione nervosa`]: `Lumbar spinal fracture with associated neurological injury`,
  [`Frattura della colonna lombare con lesione di midollo/cauda equina`]: `Lumbar spinal fracture with spinal cord/cauda equina injury`,
  [`frattura della coronoide ulnare`]: `Coronoid fracture`,
  [`Frattura della diafisi del femore`]: `Fractured femoral shaft`,
  [`Frattura della diafisi del I metacarpo`]: `Fracture shaft 1st metacarpal`,
  [`Frattura della diafisi della tibia +/- perone`]: `Fractured tibia +/- fibula`,
  [`Frattura della falange`]: `Fractured phalanx`,
  [`frattura della falange distale`]: `Distal phalanx fractures`,
  [`Frattura della falange distale`]: `Finger extensor tendon injury (incl mallet finger +/- avulsion fracture distal phalanx)`,
  [`Frattura della falange distale del II dito della mano`]: `Distal phalanx fracture index finger`,
  [`Frattura della falange distale del III dito della mano`]: `Distal phalanx fracture middle finger`,
  [`Frattura della falange distale del IV dito della mano`]: `Distal phalanx fracture ring finger`,
  [`Frattura della falange distale del pollice`]: `Fracture distal phalanx thumb`,
  [`Frattura della falange distale del V dito della mano`]: `Distal phalanx fracture little finger`,
  [`Frattura della falange distale dell'alluce`]: `Fracture great toe distal phalanx`,
  [`frattura della falange media`]: `Middle phalanx fractures`,
  [`Frattura della falange media del II dito della mano`]: `Middle phalanx fracture index finger`,
  [`Frattura della falange media del III dito della mano`]: `Middle phalanx fracture middle finger`,
  [`Frattura della falange media del IV dito della mano`]: `Middle phalanx fracture ring finger`,
  [`Frattura della falange media del V dito della mano`]: `Middle phalanx fracture little finger`,
  [`frattura della falange prossimale`]: `Proximl phalanx fractures`,
  [`Frattura della falange prossimale del II dito della mano`]: `Proximal phalanx fracture index finger`,
  [`Frattura della falange prossimale del III dito della mano`]: `Proximal phalanx fracture middle finger`,
  [`Frattura della falange prossimale del IV dito della mano`]: `Proximal phalanx fracture ring finger`,
  [`Frattura della falange prossimale del pollice`]: `Fracture proximal phalanx of thumb`,
  [`Frattura della falange prossimale del V dito della mano`]: `Proximal phalanx fracture little finger`,
  [`Frattura della falange prossimale dell'alluce`]: `Fracture great toe proximal phalanx`,
  [`Frattura della glenoide, lesione ossea di Bankart`]: `Fractured glenoid /bony Bankart lesion`,
  [`Frattura della grande tuberosita' dell'omero`]: `Fracture greater tuberosity humerus`,
  [`frattura della mandibola`]: `Jaw fracture`,
  [`Frattura della parete mediale dell'orbita`]: `Medial Wall orbit fracture`,
  [`frattura della rotula`]: `patella fracture`,
  [`Frattura della rotula`]: `Patella fracture`,
  [`frattura della scapola`]: `Scapular fracture`,
  [`Frattura della stiloide radiale`]: `Fracture radial styloid`,
  [`frattura della stiloide ulnare`]: `ulnar styloid fracture`,
  [`Frattura della stiloide ulnare`]: `Fracture of ulna styloid`,
  [`Frattura della testa del radio`]: `Fractured radial head`,
  [`frattura della tibia`]: `Fracture of tibia`,
  [`Frattura della troclea (domo astragalico)`]: `Fractured talar dome`,
  [`Frattura delle coste inferiori (10-12)`]: `Fracture lower rib (10 - 12`,
  [`Frattura delle coste medie (5-9)`]: `Fracture Middle rib (5 - 9)`,
  [`Frattura delle coste superiori (1-4)`]: `Fracture upper rib (1- 4)`,
  [`frattura dello scafoide`]: `scaphoid fracture`,
  [`Frattura dello scafoide`]: `Fractured scaphoid`,
  [`Frattura dello sterno`]: `Sternal fracture`,
  [`Frattura dello zigomo`]: `Zygoma fracture`,
  [`Frattura dentale`]: `Dental fracture`,
  [`Frattura di altre ossa del carpo`]: `Fracture other carpal bone`,
  [`Frattura di anca/inguine`]: `Hip/Groin Fractures`,
  [`Frattura di caviglia`]: `Fracture ankle`,
  [`Frattura di caviglia con diastasi della sindesmosi`]: `Ankle fracture with diastasis of syndesmosis`,
  [`Frattura di Colles del radio`]: `Colles fracture radius`,
  [`Frattura di due o piu' metatarsi`]: `Fracture two or more metatarsals`,
  [`Frattura di Gaeazzi- frattura diafisaria di radio, lussazione distale dell'articolazione radiocubitale`]: `Galeazzi fracture - midshaft radius fracture, dislocation DRUJ`,
  [`Frattura di Hill Sachs`]: `Hill Sachs compression fracture`,
  [`Frattura di Monteggia- frattura diafisaria di ulna, e lussazione della testa del radio a livello del gomito`]: `Monteggia Fracture - midshaft ulna fracture and dislocation radial head at elbow`,
  [`Frattura di perone associata a lesione del nervo peroneale`]: `Fractured fibula with associated peroneal nerve injury`,
  [`Frattura di perone associata a lesione della sindesmosi della caviglia`]: `Fractured mid-fibula with associated syndesmosis injury ankle`,
  [`frattura di radio e ulna`]: `Fracture of radius and ulna`,
  [`Frattura di Rolando (frattura comminuta della base del primo metacarpo)`]: `Rolando fracture (comminuted fracture base 1st MC)`,
  [`Frattura di scapola`]: `Scapula fracture`,
  [`Frattura di spalla`]: `Shoulder Fractures`,
  [`Frattura di tibia +/- perone con altra complicaziona (es. sindrome compartimentale)`]: `Fractured tibia +/- fibula  with other complication (e.g. compartment syndrome)`,
  [`frattura di tibia e perone`]: `Fracture of tibia and fibula`,
  [`Frattura diafisaria composta di tibia +- perone`]: `Compound fractured tibia +/- fibula`,
  [`Frattura diafisaria del perone`]: `Fractured midshaft fibula`,
  [`Frattura diafisaria del V metatarso`]: `Fracture 5th metatarsal shaft`,
  [`Frattura diafisaria dell'omero`]: `Midshaft humerus fracture`,
  [`Frattura diafisaria di radio`]: `Fracture radius midshaft`,
  [`Frattura diafisaria di tibia`]: `Fractured midshaft tibia  `,
  [`Frattura diafisaria di ulna`]: `Fractured ulna midshaft`,
  [`Frattura diafisaria di ulna e radio`]: `Fracture radius and ulna midshaft`,
  [`Frattura distale del femore`]: `Distal femoral fracture`,
  [`frattura distale del radio`]: `fracture of distal radius`,
  [`Frattura distale del radio`]: `Fractured distal radius`,
  [`Frattura distale del radio +/- ulna`]: `Fracture of distal radius +/- ulna`,
  [`Frattura distale del radio di Smith`]: `Smiths fracture distal radius`,
  [`Frattura distale dell'ulna`]: `Fracture of distal ulna`,
  [`Frattura falangea al piede`]: `Fractured phalanx (foot)`,
  [`Frattura intraarticolare del femore al ginocchio`]: `Intraarticular femoral fracture`,
  [`Frattura intraarticolare della tibia al ginocchio`]: `Intraarticular tibial fracture`,
  [`Frattura laringea`]: `Laryngeal fracture`,
  [`Frattura mandibolare`]: `Maxillary fracture`,
  [`Frattura mascellare`]: `Mandible fracture`,
  [`frattura metacarpale`]: `metacarpal fracture`,
  [`Frattura multipla delle ossa del carpo`]: `Fracture multiple carpal bones`,
  [`Frattura nasale`]: `Nose fracture`,
  [`Frattura orbitale`]: `Orbital fracture`,
  [`Frattura osso del tarso`]: `Fracture tarsal bone`,
  [`frattura osteo-condrale del calcagno`]: `calcaneus osteo-chondral lesion`,
  [`Frattura prossimale del perone`]: `Fractured proximal fibula`,
  [`frattura prossimale dell'omero`]: `Humerus proximal fracture`,
  [`Frattura prossimale della tibia`]: `Proximal tibial fracture`,
  [`frattura sopracondilare dell'omero`]: `Supracondylar fracture of humerus`,
  [`Frattura sopracondiloidea del gomito`]: `Supracondylar humeral fracture`,
  [`Frattura trimalleolare`]: `Trimalleolar fracture`,
  [`frattura vertebrale lombare`]: `Lumbar vertebra fracture`,
  [`Frattura vertebrale toracica`]: `Thoracic Spine Fracture`,
  [`Frattura-lussazione dell'articolazione acromioclavicolare`]: `Fracture dislocation A/C joint`,
  [`Frattura/e costale/i`]: `Chest Fracture(s)`,
  [`Frattura/e del bacino (escluse le fratture della cartilagine di accrescimento)`]: `Pelvic fracture(s)`,
  [`Frattura/e dell'avambraccio`]: `Fractured forearm bone(s)`,
  [`Frattura/e metatarsale/i`]: `Fractured metatarsal(s)`,
  [`Frattura/lussazione di Bennett's del pollice`]: `Bennett's fracture/dislocation thumb`,
  [`Frattura/reazione da stress al piede`]: `Stress reactions/fractures in foot`,
  [`Fratture da stress multiple del bacino`]: `Multiple stress fractures pelvis`,
  [`Fratture del ginocchio`]: `Knee Fractures`,
  [`Fratture del gomito (escluse fratture-avulsioni associate con dislocazioni del gomito e fratture specifiche dell'eta' pediatrica)`]: `Elbow Fractures`,
  [`Fratture della gamba`]: `Lower Leg Fractures`,
  [`Fratture metacarpali multiple`]: `Multiple metacarpal fractures`,
  [`Fratture multiple della colonna lombare`]: `Multiple lumbar spine fractures`,
  [`Fratture multiple delle falangi delle dita della mano`]: `Multiple phalangeal fractures fingers`,
  [`Fratture o fratture da stress`]: `Fractures (included stress fractures)`,
  [`freddo`]: `cold`,
  [`Freddo`]: `Cold`,
  [`freddo,`]: `Cold`,
  [`Fremente`]: `Quivering`,
  [`Frequenza cardiaca a riposo`]: `rest heart rate`,
  [`Frequenza cardiaca massima`]: `maximal heart rate`,
  [`Frequenza cardiaca media`]: `mean heart rate`,
  [`Fresco`]: `Cool`,
  [`Frizzante`]: `Smarting`,
  [`FROG STRETCH `]: `FROG STRETCH `,
  [`Front Squat Con Elastico`]: `FRONT SQUAT WITH RESISTANCE BAND`,
  [`Front Step Up`]: `Front Step Up`,
  [`Frontal Lunge (DX)`]: `Frontal Lunge (R)`,
  [`Frontal Lunge (SX)`]: `Frontal Lunge (L)`,
  [`Frontal Lunge Test (DX)`]: `Frontal Lunge Test (R)`,
  [`Frontal Lunge Test (SX)`]: `Frontal Lunge Test (L)`,
  [`Frttura/e cervicale/i`]: `Cervical Fracture/s`,
  [`Full Bridge`]: `FULL BRIDGE`,
  [`Full Bridge Reach`]: `FULL BRIDGE REACH`,
  [`Full Can Test (DX)`]: `Full Can Test (R)`,
  [`Full Can Test (SX)`]: `Full Can Test (L)`,
  [`Fulmineo`]: `Flashing`,
  [`Functional Reach Test`]: `Functional Reach Test`,
  [`Funzionalità attuale`]: `Current function of your knee?`,
  [`Funzionalità fisica e vita quotidiana: Le seguenti domande riguardano la sua funzionalità fisica. Per ciascuna delle seguenti attività la preghiamo di indicare il grado di difficoltà, da imputarsi ai suoi problemi all'inguine od all'anca, che lei ha ...`]: `Physical function, daily living: The following questions concern your physical function. For each of the following activities please indicate the degree of difficulty you have experienced in the past`,
  [`Funzionalità fisica e vita quotidiana: Le seguenti domande riguardano la sua funzionalità fisica. Per ciascuna delle seguenti attività la preghiamo di indicare il grado di difficoltà, da imputarsi ai suoi problemi all'inguine od all'anca, che lei ha avvertito nella scorsa settimana.`]: `Physical function, daily living: The following questions concern your physical function. For each of the following activities please indicate the degree of difficulty you have experienced in the past`,
  [`Funzionalità prima dell'infortunio al ginocchio (non conta nello score finale)`]: `Function prior to knee injury: (not scored, for purpose of benchmarking)`,
  [`Funzione - Attività specifiche: stimi sulla scala da 0 a 10 la difficoltà avvertita nell'ultima settimana eseguendo una delle seguenti azioni specifiche. Metta una crocetta sul numero che descrive la difficoltà ad eseguire l'azione nell'ultima settima...`]: `FUNCTION: SPECIFIC ACTIVITIES
Rate the amount of difficulty you experienced performing each of the items listed
below - over the past week, by circling the number that describes your difficulty on a scale of
0-10. A zero (0) means you did not experience any difficulty and a ten (10) means it was so
difficult you were unable to do it at all.`,
  [`Funzione - Attività specifiche: stimi sulla scala da 0 a 10 la difficoltà avvertita nell'ultima settimana eseguendo una delle seguenti azioni specifiche. Metta una crocetta sul numero che descrive la difficoltà ad eseguire l'azione nell'ultima settimana`]: `FUNCTION: SPECIFIC ACTIVITIES
 Rate the amount of difficulty you experienced performing each of the items listed
below - over the past week, by circling the number that describes your difficulty on a scale of
0-10. A zero (0) means you did not experience any difficulty and a ten (10) means it was so
difficult you were unable to do it at all.`,
  [`Funzione - Attività usuale: misuri sulla scala da 0 a 10 la difficoltà avvertita nell'ultima settimana eseguendo le attività della vita quotidiana.`]: `FUNCTION:USUAL ACTIVITIES
 Rate the amount of difficulty you experienced performing your usual activities in each
of the areas listed below, over the past week, by circling the number that best describes
your difficulty on a scale of 0-10. By “usual activities”, we mean the activities you performed
before you started having a problem with your wrist. A zero (0) means that you did not
experience any difficulty and a ten (10) means it was so difficult you were unable to do any
of your usual activities.`,
  [`Funzione - Attività usuale: misuri sulla scala da 0 a 10 la difficoltà avvertita nell'ultima settimana eseguendo le attività della vita quotidiana. Attività ""usuale"" significa attività della vita quotidiana svolta prima di avere un problema con il p...`]: `FUNCTION:USUAL ACTIVITIES
Rate the amount of difficulty you experienced performing your usual activities in each
of the areas listed below, over the past week, by circling the number that best describes
your difficulty on a scale of 0-10. By “usual activities”, we mean the activities you performed
before you started having a problem with your wrist. A zero (0) means that you did not
experience any difficulty and a ten (10) means it was so difficult you were unable to do any
    of your usual activities.`,
  [`Funzione fisica, attività sportive e del tempo libero`]: `Function, sports and recreational activities - The following questions concern your physical function when being active on a higher level. The questions should be answered thinking of what degree of difficulty you have experienced during the last week due to your hip`,
  [`Funzione, attività della vita quotidiana: Le domande che seguono riguardano le sue capacità funzionali, in particolare la sua abilità nel muoversi e prendersi cura di sé. Per ciascuna delle seguenti attività indichi per favore il grado di difficolt...`]: `Function, daily living : The following questions concern your physical function. By this we mean your ability to move around and to look after yourself. For each of the following activities please indicate the degree of difficulty you have experienced in the last week due to your hip.`,
  [`Funzione, attività della vita quotidiana: Le domande che seguono riguardano le sue capacità funzionali, in particolare la sua abilità nel muoversi e prendersi cura di sé. Per ciascuna delle seguenti attività indichi per favore il grado di difficoltà provato nell'ultima settimana a causa dei problemi all'anca`]: `Function, daily living : The following questions concern your physical function. By this we mean your ability to move around and to look after yourself. For each of the following activities please indicate the degree of difficulty you have experienced in the last week due to your hip.`,
  [`Gabbia toracica`]: `Rib cage`,
  [`GABBIA TORACICA`]: `CHEST`,
  [`Gaenslen's Test`]: `Gaenslen's Test`,
  [`Gaenslen's Test (DX)`]: `Gaenslen's Test (R)`,
  [`Gaenslen's Test (SX)`]: `Gaenslen's Test (L)`,
  [`Gait`]: `Gait`,
  [`Gait Velocity Test`]: `Gait Velocity Test`,
  [`GALEA APONEUROTICA`]: `EPICRANIAL APONEUROSIS`,
  [`Gamba`]: `Leg`,
  [`Gamba anteriore`]: `Leg`,
  [`GAMBA DESTRA`]: `RIGHT LEG`,
  [`Gamba posteriore`]: `Calf`,
  [`GAMBA SINISTRA`]: `LEFT LEG`,
  [`Gara`]: `Competition`,
  [`Gare allo stesso livello o livello più alto di quando sono iniziati I sintomi`]: `Competing at the same or higher level as when symptoms began`,
  [`Gare allo stesso livello o livello più alto rispetto a quando sono iniziati i sintomi`]: `Competing at the same or higher level as when symptoms began`,
  [`Gastrite`]: `Gastritis`,
  [`Gastrite associata a FANS/ulcera peptica`]: `NSAID associated gastritis/peptic ulceration`,
  [`Gastrite/reflusso associata ad esercizio`]: `Exercise associated gastritis/reflux`,
  [`Gastrite/ulcera peptica - non correlata ad esercizio/anti infiammatori`]: `Gastritis/peptic ulceration - non exercise/NSAID related`,
  [`Gastrocnemius Assessment (DX)`]: `Gastrocnemius Assessment (R)`,
  [`Gastrocnemius Assessment (SX)`]: `Gastrocnemius Assessment (L)`,
  [`Gastroenterite`]: `Gastrointestinal Illness`,
  [`Gastroenterite batterica (inclusa intossicazione alimentare)`]: `Bacterial gastroenteritis (incl food poisoning)`,
  [`Gastroenterite virale`]: `Viral gastroenteritis`,
  [`Gastrointestinalneoplasm`]: `Gastrointestinalneoplasm`,
  [`Gastrointestinale`]: `Gastrointestinal`,
  [`Gastrointestinale`]: `Gastrointestinal`,
  [`Gastrointestinali`]: `Gastrointestinal`,
  [`Generalità e obiettivi`]: `Goals`,
  [`Genitourinari`]: `Urogenital`,
  [`Genitourinario`]: `Genitourinary  `,
  [`Gentile Signora, Egregio Signore, Le seguenti domande ci aiutano a comprendere il grado di difficoltà che lei ha avuto a causa dei disturbi al polso o alla mano nell'ultima settimana. Quantifichi sulla scala da 0 a 10 la sintomatologia MEDIA del polso o della mano nell'ultima settimana. Per favore risponda a TUTTE le domande. Se nell'ultima settimana non ha eseguito una certa attività, per favore, stimi il dolore o la difficoltà che si aspetta avrebbe potuto percepire. Se non ha MAI eseguito una certa attività con la mano malata, non risponda a quella domanda.`]: `The questions below will help us understand how much difficulty you have had with your wrist/hand in the past week. You will be describing your average wrist symptoms over the past week on a scale of 0-10. Please provide an answer for all questions. If you did not perform an activity, please estimate the pain or difficulty you would expect. If you have never performed the activity, you may leave it blank.`,
  [`Gentile Signora, Egregio Signore, Le seguenti domande ci aiutano a comprendere il grado di difficoltà che lei ha avuto a causa dei disturbi al polso o alla mano nell'ultima settimana. Quantifichi sulla scala da 0 a 10 la sintomatologia MEDIA del polso o...`]: `The questions below will help us understand how much difficulty you have had with your wrist/hand in the past week. You will be describing your average wrist symptoms over the past week on a scale of 0-10. Please provide an answer for all questions. If you did not perform an activity, please estimate the pain or difficulty you would expect. If you have never performed the activity, you may leave it blank.`,
  [`Gentile Signore/Signora, siamo interessati a sapere se Lei sta avendo qualche difficolta' nello svolgere le varie attivita' di seguito elencate a causa del suo problema agli arti inferiori per il quale ha richiesto di essere visitato/a. Le chiediamo cortesemente di  Le chiediamo cortesemente di indicare una risposta per ogni attività. Oggi, Lei ha o potrebbe avere qualche difficoltà in attività come:`]: `We are interested in knowing whether you are having any difficulty at all with the activities listed below because of your lower limb problem for which you are currently seeking attention. Please provide an answer for each activity.
Today, do you or would you have any difficulty at all with:`,
  [`Gentile SignoreSignora, siamo interessati a sapere se Lei sta avendo qualche difficolta' nello svolgere le varie attivita' di seguito elencate a causa del suo problema agli arti inferiori per il quale ha richiesto di essere visitato/a. Le chiediamo corte...`]: `We are interested in knowing whether you are having any difficulty at all with the activities listed below because of your lower limb problem for which you are
currently seeking attention. Please provide an answer for each activity.
    Today, do you or would you have any difficulty at all with:`,
  [`Get Up`]: `GET UP`,
  [`Ghiacciante`]: `Freexing`,
  [`ghiaccio`]: `Ice`,
  [`GHIANDOLA LACRIMALE DESTRA`]: `RIGHT LACRIMAL GLAND`,
  [`GHIANDOLA LACRIMALE SINISTRA`]: `LEFT LACRIMAL GLAND`,
  [`GHIANDOLA MAMMARIA DESTRA (F)`]: `RIGHT MAMMARY GLAND (F)`,
  [`GHIANDOLA MAMMARIA SINISTRA (F)`]: `LEFT MAMMARY GLAND (F)`,
  [`GHIANDOLA PARATIROIDEA ACCESSORIA`]: `ACCESSORY PARATHYROID GLAND`,
  [`GHIANDOLA PARATIROIDEA INFERIORE DESTRA`]: `RIGHT INFERIOR PARATHYROID GLAND`,
  [`GHIANDOLA PARATIROIDEA INFERIORE SINISTRA`]: `LEFT INFERIOR PARATHYROID GLAND`,
  [`GHIANDOLA PARATIROIDEA SUPERIORE DESTRA`]: `RIGHT SUPERIOR PARATHYROID GLAND`,
  [`GHIANDOLA PARATIROIDEA SUPERIORE SINISTRA`]: `LEFT SUPERIOR PARATHYROID GLAND`,
  [`GHIANDOLA PAROTIDE DESTRA`]: `RIGHT PAROTID GLAND`,
  [`GHIANDOLA PAROTIDE SINISTRA`]: `LEFT PAROTID GLAND`,
  [`GHIANDOLA SEMINALE DESTRA (M)`]: `RIGHT SEMINAL GLAND (M)`,
  [`GHIANDOLA SEMINALE SINISTRA (M)`]: `LEFT SEMINAL GLAND (M)`,
  [`GHIANDOLA SOTTOLINGUALE DESTRA`]: `RIGHT SUBLINGUAL GLAND`,
  [`GHIANDOLA SOTTOLINGUALE SINISTRA`]: `LEFT SUBLINGUAL GLAND`,
  [`GHIANDOLA SOTTOMANDIBOLARE DESTRA`]: `RIGHT SUBMANDIBULAR GLAND`,
  [`GHIANDOLA SOTTOMANDIBOLARE SINISTRA`]: `LEFT SUBMANDIBULAR GLAND`,
  [`GHIANDOLA SURRENALE DESTRA`]: `RIGHT ADRENAL GLAND`,
  [`GHIANDOLA SURRENALE DESTRA (PARTE CORTICALE)`]: `RIGHT SUPRARENAL GLAND (CORTEX)`,
  [`GHIANDOLA SURRENALE DESTRA (PARTE MIDOLLARE)`]: `RIGHT SUPRARENAL GLAND (MEDULLA)`,
  [`GHIANDOLA SURRENALE SINISTRA`]: `LEFT ADRENAL GLAND`,
  [`GHIANDOLA SURRENALE SINISTRA (PARTE CORTICALE)`]: `LEFT SUPRARENAL GLAND (CORTEX)`,
  [`GHIANDOLA SURRENALE SINISTRA (PARTE MIDOLLARE)`]: `LEFT SUPRARENAL GLAND (MEDULLA)`,
  [`GHIANDOLE OLFATTIVE`]: `OLFACTORY GLANDS`,
  [`GHIANDOLE PARATIROIDEE`]: `PARATHYROID GLANDS`,
  [`GHIANDOLE SURRENALI`]: `ADRENALS GLANDS`,
  [`Ginecologici`]: `Gynecological`,
  [`Ginnastica artistica`]: `Artistic gymnastic`,
  [`Ginocchio`]: `Knee`,
  [`Ginocchio (DX)`]: `Knee (R)`,
  [`Ginocchio (SX)`]: `Knee (L)`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 1`]: `BAND TERMINAL KNEE ESTENSION 1`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 2`]: `BAND TERMINAL KNEE ESTENSION 2`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 3`]: `BAND TERMINAL KNEE ESTENSION 3`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 4`]: `BAND TERMINAL KNEE ESTENSION 4`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 5`]: `BAND TERMINAL KNEE ESTENSION 5`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 6`]: `BAND TERMINAL KNEE ESTENSION 6`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 7`]: `BAND TERMINAL KNEE ESTENSION 7`,
  [`Ginocchio Estensione Terminale Con Elastico Frontale Liv 8`]: `BAND TERMINAL KNEE ESTENSION 8`,
  [`Ginocchio Estensione Terminale Con Elastico Mediale Liv 1`]: `MEDIAL BAND TERMINAL KNEE ESTENSION 1`,
  [`Ginocchio Estensione Terminale Con Elastico Mediale Liv 2`]: `MEDIAL BAND TERMINAL KNEE ESTENSION 2`,
  [`Ginocchio Estensione Terminale Con Elastico Mediale Liv 3`]: `MEDIAL BAND TERMINAL KNEE ESTENSION 3`,
  [`Ginocchio flesso`]: `Genu flexum`,
  [`Ginocchio posteriore`]: `Posterior knee`,
  [`Ginocchio recurvato`]: `Genu recurvatum`,
  [`Giornalmente`]: `Daily`,
  [`Girare la maniglia di una porta usando la mano malata`]: `Turn a door knob using my affected hand`,
  [`Girare una chiave`]: `Turn a key`,
  [`Girarsi facendo perno sulla gamba`]: `Twisting/pivoting on loaded leg`,
  [`Girrsi nel letto`]: `Rolling over in bed`,
  [`GLABELLA`]: `GLABELLA`,
  [`Glandular Fever`]: `Glandular Fever`,
  [`Glutea`]: `Glutes`,
  [`Glutea/Sacro-iliaca`]: `Gluteal/sacroiliac`,
  [`Glutei Abduzione Passi Laterale A Gamba Piegata`]: `MONSTER WALK`,
  [`Glutei Abduzione Passi Laterale A Gamba Tesa`]: `MONSTER WALK STRAIGHT LEG`,
  [`Glutei Abduzioni Con Elastico In Equilibrio`]: `STANDING CLAMSHELL`,
  [`Glutei Passo Diagonale Dietro Gamba Piegata`]: `BACK DIAGONAL MONSTER WALK`,
  [`Glutei Passo Diagonaleavanti Gamba Piegata`]: `FRONT DIAGONAL MONSTER WALK`,
  [`Gluteo Addondo Dietro Sollevamento Ginocchio`]: `BAND BACK LUNGE KNEE DRIVE`,
  [`Goblet Split Squat Con Elastico`]: `GOBLET SPLIT SQUAT WITH RESISTANCE BAND`,
  [`Goblet Split Squat Con Elastico Con Busto Flesso`]: `GOBLET SPLIT SQUAT FORWARD TORSO WITH RESISTANCE BAND`,
  [`Goblet Squat Con Elastico`]: `GOBLET SQUAT WITH RESISTANCE BAND`,
  [`Godfrey's Test or Posterior Sag Sign (DX)`]: `Godfrey's Test or Posterior Sag Sign (R)`,
  [`Godfrey's Test or Posterior Sag Sign (SX)`]: `Godfrey's Test or Posterior Sag Sign (L)`,
  [`Godo di ottima salute`]: `My health is excellent`,
  [`Golf`]: `Golf`,
  [`Gomito`]: `Elbow`,
  [`Gomito (DX)`]: `Elbow (R)`,
  [`Gomito (SX)`]: `Elbow (L)`,
  [`gonfiore`]: `swelling`,
  [`Gonfiore`]: `Swelling`,
  [`Gonfiori o emergenza di bozzi`]: `Swelling or lumps anywhere`,
  [`GONION DESTRO`]: `RIGHT GONION`,
  [`GONION SINISTRO`]: `LEFT GONION`,
  [`Gotta`]: `Gout`,
  [`Gotta al ginocchio`]: `Gout in knee`,
  [`Gotta al gomito`]: `Gout in elbow`,
  [`Gotta alla caviglia/piede (incluso alluce)`]: `Gout in ankle/foot (incl big toe)`,
  [`Gotta alla mano/dita`]: `Gout in hands/fingers`,
  [`Gotta in altre localizzazioni non altrimenti specificate`]: `Gout in other location not otherwise specified`,
  [`Gozzo`]: `Goitre`,
  [`Grado`]: `Degree`,
  [`Grado di deficit di forza`]: `Strength deficit degree`,
  [`Grado disallineamento`]: `Misalignement degree`,
  [`Grado disfunzione`]: `Dysfunction degree`,
  [`Grado I`]: `I degree`,
  [`Grado II`]: `II degree`,
  [`Grado III`]: `III degree`,
  [`Grado IV`]: `IV degree`,
  [`Grado limitazione`]: `Limitation degree`,
  [`GRANDE OMENTO`]: `GREATER OMENTUM`,
  [`Grave`]: `Extreme`,
  [`Gravemente`]: `Severely`,
  [`Gravidanza`]: `Pregnancy`,
  [`Gravoso`]: `Tender`,
  [`Grip Endurance Test (DX)`]: `Grip Endurance Test (R)`,
  [`Grip Endurance Test (SX)`]: `Grip Endurance Test (L)`,
  [`Grip Strength (DX)`]: `Grip Strength (R)`,
  [`Grip Strength (SX)`]: `Grip Strength (L)`,
  [`groin pain syndrome (pubalgia)`]: `groin pain syndrome`,
  [`Gronblad M, Hupli M et al (1989) Intercorrelation and test-retest reliability of the pain disability index and the Oswestry disability questionnaire and their correlation with pain intensity in low back pain patients The Clinical Journal of Pain 9, 189-1...`]: `Gronblad M, Hupli M et al (1989) Intercorrelation and test-retest reliability of the pain disability index and the Oswestry disability questionnaire and their correlation with pain intensity in low back pain patients The Clinical Journal of Pain 9, 189-1...`,
  [`GUAINA TENDINEA COMUNE DEI MUSCOLI PERONIERI DI DESTRA`]: `COMMON TENDINOUS SHEATH OF RIGHT PERONEI MUSCLES`,
  [`GUAINA TENDINEA COMUNE DEI MUSCOLI PERONIERI DI SINISTRA`]: `COMMON TENDINOUS SHEATH OF LEFT PERONEI MUSCLES`,
  [`GUAINA TENDINEA DEI MUSCOLI ADDUTTORE LUNGO ED ESTENSORE BREVE DEL POLLICE DI DESTRA`]: `TENDINOUS SHEATH OF RIGHT ADDUCTOR LONGUS AND EXTENSOR POLLICIS BREVIS`,
  [`GUAINA TENDINEA DEI MUSCOLI ADDUTTORE LUNGO ED ESTENSORE BREVE DEL POLLICE DI SINISTRA`]: `TENDINOUS SHEATH OF LEFT ADDUCTOR LONGUS AND EXTENSOR POLLICIS BREVIS`,
  [`GUAINA TENDINEA DEI MUSCOLI ESTENSORE DELLE DITA E ESTENSORE DELL'INDICE DI DESTRA`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR DIGITORUM AND EXTENSOR INDICIS MUSCLES`,
  [`GUAINA TENDINEA DEI MUSCOLI ESTENSORE DELLE DITA E ESTENSORE DELL'INDICE DI SINISTRA`]: `TENDINOUS SHEATH OF LEFT EXTENSOR DIGITORUM AND EXTENSOR INDICIS MUSCLES`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE BREVE DEL MIGNOLO DESTRO`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR DIGITI MINIMI BREVIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE BREVE DEL MIGNOLO SINISTRO`]: `TENDINOUS SHEATH OF LEFT EXTENSOR DIGITI MINIMI BREVIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE LUNGO DEL POLLICE DESTRO`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR POLLICIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE LUNGO DEL POLLICE SINISTRO`]: `TENDINOUS SHEATH OF LEFT EXTENSOR POLLICIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE DESTRO`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR HALLUCIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE SINISTRO`]: `TENDINOUS SHEATH OF LEFT EXTENSOR HALLUCIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO`]: `TENDINOUS SHEATH OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE RADIALE DEL CARPO DESTRO`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR CARPI RADIALIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE RADIALE DEL CARPO SINISTRO`]: `TENDINOUS SHEATH OF LEFT EXTENSOR CARPI RADIALIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO`]: `TENDINOUS SHEATH OF RIGHT EXTENSOR CARPI ULNARIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO`]: `TENDINOUS SHEATH OF LEFT EXTENSOR CARPI ULNARIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE LUNGO DEL POLLICE DESTRO`]: `TENDINOUS SHEATH OF RIGHT FLEXOR POLLICIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE LUNGO DEL POLLICE SINISTRO`]: `TENDINOUS SHEATH OF LEFT FLEXOR POLLICIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE LUNGO DELL'ALLUCE DESTRO`]: `TENDINOUS SHEATH OF RIGHT FLEXOR HALLUCIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE LUNGO DELL'ALLUCE SINISTRO`]: `TENDINOUS SHEATH OF LEFT FLEXOR HALLUCIS LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO`]: `TENDINOUS SHEATH OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO`]: `TENDINOUS SHEATH OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE RADIALE DEL CARPO DESTRO`]: `TENDINOUS SHEATH OF RIGHT FLEXOR CARPI ULNARIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO FLESSORE RADIALE DEL CARPO SINISTRO`]: `TENDINOUS SHEATH OF LEFT FLEXOR CARPI ULNARIS MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO TIBIALE ANTERIORE DESTRO`]: `TENDINOUS SHEATH OF RIGHT TIBIALIS ANTERIOR MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO TIBIALE ANTERIORE SINISTRO`]: `TENDINOUS SHEATH OF LEFT TIBIALIS ANTERIOR MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO TIBIALE POSTERIORE DESTRO`]: `TENDINOUS SHEATH OF RIGHT TIBIALIS POSTERIOR MUSCLE`,
  [`GUAINA TENDINEA DEL MUSCOLO TIBIALE POSTERIORE SINISTRO`]: `TENDINOUS SHEATH OF LEFT TIBIALIS POSTERIOR MUSCLE`,
  [`GUAINA TENDINEA PLANTARE DEL MUSCOLO PERONIERO LUNGO DESTRO`]: `TENDINOUS SHEATH OF RIGHT PERONEUS LONGUS MUSCLE`,
  [`GUAINA TENDINEA PLANTARE DEL MUSCOLO PERONIERO LUNGO SINISTRO`]: `TENDINOUS SHEATH OF LEFT PERONEUS LONGUS MUSCLE`,
  [`Ha avuto difficoltà a dedicare al suo lavoro la consueta quantità di tempo?`]: `Did you have any difficulty spending your usual amount of time doing your work?`,
  [`Ha avuto difficoltà a dedicare al suo strumento o al suo sport la consueta quantità di tempo?`]: `Did you have any difficulty spending your usual amount of time practising or playing your instrument or sport?`,
  [`Ha avuto difficoltà a fare il lavoro bene come vorrebbe?`]: `Did you have any difficulty doing your work as well as you would like?`,
  [`Ha avuto difficoltà a suonare il suo strumento musicale o a praticare il suo sport a causa del dolore al braccio, alla spalla o alla mano?`]: `Did you have any difficulty playing your musical instrument or sport because of your arm, shoulder or hand pain?`,
  [`Ha avuto difficoltà a suonare il suo strumento o praticare il suo sport bene come vorrebbe?`]: `Did you have any difficulty playing your instrument or sport as well as you would like?`,
  [`Ha avuto difficoltà a svolgere il suo lavoro abituale a causa del dolore al braccio, alla spalla o alla mano?`]: `Did you have any difficulty doing your usual work because of arm, shoulder or hand pain?`,
  [`Ha avuto difficoltà a utilizzare la sua tecnica abituale per lavorare?`]: `Did you have any difficulty doing your work in your usual way?`,
  [`Ha avuto difficoltà a utilizzare la sua tecnica abituale per suonare il suo strumento o praticare il suo sport?`]: `Did you have any difficulty playing your instrument or sport in your usual way?`,
  [`Ha avuto difficolta' nell'eseguire il lavoro o altre attivita' (ad esempio ha fatto piu' fatica)`]: `Had difficulty performing the work or other activities (for example, it took extra effort)`,
  [`Ha avuto un calo di concentrazione sul lavoro o in altre attivita'`]: `Didn't do work or other activities as carefully as usual`,
  [`Ha difficoltà a camminare con passi ben allungati?`]: `Do you have difficulties taking full strides when you walk?`,
  [`Ha difficoltà nell'estendere la gamba lateralmente?`]: `Do you have difficulties stretching your legs far out to the side?`,
  [`Ha dolore a scendere le scale ad un'andatura normale?`]: `Do you have pain walking downstairs with a normal gait cycle?`,
  [`Ha dolore durante o immediatamente dopo aver fatto 10 saltelli con una sola gamba?`]: `Do you have pain during or immediately after doing 10 single leg hops?`,
  [`Ha dolore durante o immediatamente dopo essere andato sulla punta dei piedi per 10 volte su una superﬁcie piana sulla gamba il cui tendine di Achille fa male?`]: `Do you have pain during or immediately after doing 10 (single leg) heel raises from a flat surface?`,
  [`Ha dolore nel fare un affondo?`]: `Do you have pain when doing a full weight bearing lunge?`,
  [`Ha dolore nell'estendere al massimo il ginocchio senza resistenza?`]: `Do you have pain at the knee with full active non-weightbearing knee extension?`,
  [`Ha dolore scendendo le scale ad un'andatura normale?`]: `Do you have pain walking downstairs with a normal gait cycle?`,
  [`Ha dovuto limitare alcuni tipi di lavoro o di altre attivita'`]: `Were limited in the kind of work or other activities`,
  [`Ha modificato il suo stile di vita al fine di evitare attività potenzialmente dannose per il suo ginocchio?`]: `Have you modified your life style to avoid potentially damaging activities to your knee?`,
  [`Ha modificato il suo stile di vita per evitare attività potenzialmente dannose per la sua anca e/o inguine?`]: `Have you modified your life style to avoid activities potentially damaging to your hip and/or groin?`,
  [`Ha modificato il suo stile di vita per evitare attività potenzialmente dannose per la sua anca?`]: `Have you modified your life style to avoid activities potentially damaging to your hip?`,
  [`Ha problemi ad accovacciarsi?`]: `Do you have problems squatting?`,
  [`Ha reso meno di quanto avrebbe voluto`]: `Accomplished less than you would like`,
  [`Ha ridotto il tempo dedicato al lavoro o ad altre attivita'`]: `Cut down the amount of time you spent on work or other activities`,
  [`HAGOS (ANCA E INGUINE)`]: `HAGOS (HIP AND GROIN OUTCOME SCORE)`,
  [`Hai applicato ghiaccio e compresso la caviglia immediatamente dopo l'infortunio?`]: `Did you apply ice and compress the ankle straight after the injury?`,
  [`Hai avuto dolore alla mandibola (claudicatio mandibolare) durante I pasti ultimamente?`]: `Have you recently had pain at mandibula during meals?`,
  [`Hai avuto episodi di nausea e vomito ultimamente?`]: `Have you experienced nausea, vomiting or loss of appetite recently?`,
  [`Hai avuto perdite di peso inspiegabili nell'ultimo mese`]: `Have you inexplicably lost weight in the past month?`,
  [`Hai avuto precedenti infortuni alla caviglia o al piede?`]: `Did you have previous injuries at the ankle or at the foot?`,
  [`Hai avuto recenti stress psicologici?`]: `What major life changes or stresses have you encountered that you would associate with your injury/illness?`,
  [`Hai già sperimentato questo dolore in passato o hai avuto altri problemi in questa stessa regione?`]: `Have you ever experienced anything like this before?`,
  [`Hai la sensazione che la rotula si muova verso l'esterno?`]: `Have you got the feeling your patella move outwards?`,
  [`Hai la sensazione che la tua caviglia si blocchi?`]: `Have you got the feeling your ankle lock?`,
  [`Hai mai avuto problemi alla prostata (se uomo) o al seno (se donna)`]: `Have you ever had postate disorder (if man) or breast disorder (if woman)`,
  [`Hai manifestato disturbi della coordinazione muscolare, disturbi della sensibilita' corporea, disturbi visivi, alterato stato mentale ultimamente?`]: `Have you had motor symptoms, sensory symptoms, visual problems in the last few weeks?`,
  [`Hai manifestato problemi intestinali o vescicali(ritenzione urinaria) o disordini sessuali esorditi in concomitanza con il tuo dolore?`]: `Have you had any intestinal, urinary, gynecological or sexual disorder starting together with your pain?`,
  [`Hai manifestato problemi intestinali o vescicali(ritenzione urinaria), ginecologici o disordini sessuali esorditi in concomitanza con il tuo dolore?`]: `Have you had any intestinal, urinary, gynecological or sexual disorder starting together with your pain?`,
  [`Hai manifestato problemi intestinali, vescicali (ritenzione urinaria) o disordini sessuali esorditi in concomitanza con il dolore lombare?`]: `Have you had bowel problems, urinary retention or sexual disorders onset together with your pain?`,
  [`Hai manifestato ultimamente almeno uno dei seguenti sintomi: febbre, inspiegabile perdita di peso, bruciore durante la minzione?`]: `Have you recently had any of the following symptoms? Fever, unexplained weight loss, burning when urinating `,
  [`Hai manifestato zoppia o la manifesti attualmente`]: `Have you had any lameness?`,
  [`Hai meno di 14 anni?`]: `Are you younger than 14 years old?`,
  [`Hai meno di 18 anni?`]: `Are you younger than 18 years old?`,
  [`Hai mostrato ultimamente presenza di vertigini, blakouts o cadute improvvise?`]: `Have you recently had dizziness, blackouts or sudden fall?`,
  [`Hai notato l'emergenza di un rash cutaneo sul tuo torace?`]: `Have you noticed a cutaneous rush on your thoracic area?`,
  [`Hai notato la comparsa di bozzi o protuberanze sul gluteo in assenza di un trauma contusivo o di una caduta?`]: `Have you noticed the growth of bumps on your glutes without an obvious cause?`,
  [`Hai notato la crescita di bozzi o masse muscolari nella regione del braccio?`]: `Have you noticed the growth of bumps on the arm area?`,
  [`Hai notato la crescita di bozzi o masse muscolari nella regione del gomito?`]: `Have you noticed the growth of bumps on the elbow region?`,
  [`Hai notato la crescita di bozzi o masse muscolari nella regione del polso?`]: `Have you noticed the growth of bumps on the wrist area?`,
  [`Hai notato la crescita di bozzi o masse muscolari nella regione dell'avambraccio?`]: `Have you noticed the growth of bumps on the forearm region?`,
  [`Hai notato la crescita di bozzi o masse muscolari nella regione della mano?`]: `Have you noticed the growth of bumps on your hand?`,
  [`Hai notato la crescita di bozzi o masse muscolari nella regione della spalla?`]: `Have you noticed the growth of bumps on the shoulder area?`,
  [`Hai notato la crescita di bozzi o protuberanze nella regione toracica?`]: `Have you noticed the growth of bumps in the thoracic area?`,
  [`Hai notato la crescita di bozzi o protuberanze sull'area del polpaccio?`]: `Have you noticed the growth of bumps on your calf?`,
  [`Hai notato la crescita di bozzi o protuberanze sulla regione della coscia?`]: `Have you noticed the growth of bumps on your thigh?`,
  [`Hai notato un gonfiore importante del polpaccio o di tutta la gamba, o la comparsa di un livido sulla gamba in assenza di trauma?`]: `Can you see an important swelling of the calf or the whole leg or have you noticed a bruise on your leg (without an obvious cause)?`,
  [`Hai paura o apprensione a muovere la spalla?`]: `Are you scared or apprehensive to move your shoulder?`,
  [`Hai percepito (al momento del trauma) o puoi percepire (nei movimenti quotidiani) un "pop" o un "click" nella spalla durante I movimenti?`]: `Did you feel a pop or a click inside the shoulder at the moment of the injury?`,
  [`Hai percepito un "pop" al momento del trauma?`]: `Have you heard a "pop" at the moment of the injury?`,
  [`Hai perso peso inspiegabilmente nell'ultimo mese?`]: `Have you inexplicably lost pain in the last month`,
  [`Hai problemi nel camminare o nello scavalcare piccoli ostacoli, o rischi spesso di cadere?`]: `Do you have problems walking or are you frequently close to fall?`,
  [`Hai sintomi neurologici alle braccia o alle gambe?`]: `Have you got neurological symptoms on arms or legs?`,
  [`Hai sofferto precedentemente di Trombosi Venosa Profonda o di altri problemi vascolari?`]: `Have you suffered of Deep Venous Thrombosis or other vascular problems?`,
  [`Hai svolto altri trattamenti per il tuo attuale problema e se si che effetti hanno avuto e per quanto tempo li hai svolti?`]: `Have you had previous treatment for this condition and for how long?`,
  [`Hai una diagnosi medica per il tuo dolore o infortunio?`]: `Have you got a medical diagnosis for your pain or injury?`,
  [`Hai una diagnosi medica per il tuo dolore?`]: `Do you have a diagnosis for your problem?`,
  [`Hai una storia di artrite reumatoide o altri tipi di artriti infiammatorie o trattamenti con farmaci steroidei sistemici?`]: `Have you got an history of rheumatoid arthritis, inflammatory arthritis or systemic steroids treatments?`,
  [`HAMSTRING KICK `]: `HAMSTRING KICK`,
  [`Hand Elevation Test (DX)`]: `Hand Elevation Test (R)`,
  [`Hand Elevation Test (SX)`]: `Hand Elevation Test (L)`,
  [`Handicap o menomazioni`]: `Disabilities`,
  [`HAWKER GA. Measures of Adult Pain. Arthritis Care & Research 2011; 63,S240–S252`]: `HAWKER GA. Measures of Adult Pain. Arthritis Care & Research 2011; 63,S240–S252`,
  [`Hawkins-Kennedy Test (DX)`]: `Hawkins-Kennedy Test (R)`,
  [`Hawkins-Kennedy Test (SX)`]: `Hawkins-Kennedy Test (L)`,
  [`Heel Strike Test (DX)`]: `Heel Strike Test (R)`,
  [`Heel Strike Test (SX)`]: `Heel Strike Test (L)`,
  [`Hefford C, Abbott JH, Arnold R, Baxter GD. The patient-specific functional scale: validity, reliability, and responsiveness in patients with upper extremity musculoskeletal problems. journal of orthopaedic & sports physical therapy. 2012 Feb;42(2):56-65.`]: `Hefford C, Abbott JH, Arnold R, Baxter GD. The patient-specific functional scale: validity, reliability, and responsiveness in patients with upper extremity musculoskeletal problems. journal of orthopaedic & sports physical therapy. 2012 Feb;42(2):56-65.`,
  [`Herpes simplex`]: `Herpes simplex`,
  [`Herpes Zoster`]: `Herpes Zoster`,
  [`Hexagon Test (DX)`]: `Hexagon Test (R)`,
  [`Hexagon Test (SX)`]: `Hexagon Test (L)`,
  [`High Knee`]: `HIGH KNEE`,
  [`Hip AROM (DX)`]: `Hip AROM (R)`,
  [`Hip AROM (SX)`]: `Hip AROM (L)`,
  [`HIP DROPS `]: `HIP DROPS`,
  [`Hip Extensor Assessment (DX)`]: `Hip Extensor Assessment (R)`,
  [`Hip Extensor Assessment (SX)`]: `Hip Extensor Assessment (L)`,
  [`Hip Flexion Test (DX)`]: `Hip Flexion Test (R)`,
  [`Hip Flexion Test (SX)`]: `Hip Flexion Test (L)`,
  [`Hip Flexors Assessment (DX)`]: `Hip Flexors Assessment (R)`,
  [`Hip Flexors Assessment (SX)`]: `Hip Flexors Assessment (L)`,
  [`Hip ROM (active, passive and with overpressure)`]: `Hip ROM (active, passive and with overpressure)`,
  [`Hip Scour Test (DX)`]: `Hip Scour Test (R)`,
  [`Hip Scour Test (SX)`]: `Hip Scour Test (L)`,
  [`Ho bisogno di aiuto ogni giorno in quasi tutti gli aspetti della cura di me stesso/a`]: `I need help every day in most aspects of self care`,
  [`Ho bisogno di un po di aiuto ma riesco per lo piu' a prendermi cura di me stesso/a`]: `I need some help but I can manage most of my personal care`,
  [`ho cambiato scarpe`]: `I changed footwear`,
  [`ho dolore al risveglio`]: `I feel pain when I awake`,
  [`ho dolore all'inizio dell'attivita' ma migliora quando l'area e' calda`]: `I feel pain at the start of the activity and it improves when I'm warm`,
  [`ho dolore durante l'attivita' e dura per tutto il tempo dello svolgimento`]: `I have pain during the whole time performing activity `,
  [`ho dolore la sera`]: `I feel pain in the evening`,
  [`ho dolore sempre, anche in questo momento`]: `I've always pain, even now`,
  [`ho dolore solo durante l'attivita'`]: `I just feel pain during the activity`,
  [`Ho modificato la mia attività lavorativa (meno attività fisica)`]: `I have modified my work activity (less physical activity)`,
  [`Ho ripreso la mia attività lavorativa, ma non allo stesso livello di prima della insorgenza dei sintomi`]: `Full work activity, but not at same level as when symptoms began`,
  [`Hockey su ghiaccio`]: `Ice hockey`,
  [`Hockey su prato`]: `Field hockey`,
  [`Hook Test (DX)`]: `Hook Test (R)`,
  [`Hook Test (SX)`]: `Hook Test (L)`,
  [`Hop (DX)`]: `Hop (R)`,
  [`Hop (SX)`]: `Hop (L)`,
  [`Hop Test (DX)`]: `Hop Test (R)`,
  [`Hop Test (SX)`]: `Hop Test (L)`,
  [`Horn KK, Jennings S, Richardson G, Van Vliet D, Hefford C, Abbott JH. The patient-specific functional scale: psychometrics, clinimetrics, and application as a clinical outcome measure. journal of orthopaedic & sports physical therapy. 2012 Jan;42(1):30-D...`]: `Horn KK, Jennings S, Richardson G, Van Vliet D, Hefford C, Abbott JH. The patient-specific functional scale: psychometrics, clinimetrics, and application as a clinical outcome measure. journal of orthopaedic & sports physical therapy. 2012 Jan;42(1):30-D...`,
  [`Hornblower's Test (DX)`]: `Hornblower's Test (R)`,
  [`Hornblower's Test (SX)`]: `Hornblower's Test (L)`,
  [`Hush JM, Refshauge KM, Sullivan G, De Souza L, McAuley JH. Do numerical rating scales and the Roland-Morris Disability Question-naire capture changes that are meaningful to patients with persistent back pain? Clin Rehabil 2010;24:648–57.`]: `Hush JM, Refshauge KM, Sullivan G, De Souza L, McAuley JH. Do numerical rating scales and the Roland-Morris Disability Question-naire capture changes that are meaningful to patients with persistent back pain? Clin Rehabil 2010;24:648–57.`,
  [`Hyperabduction Test`]: `Hyperabduction Test`,
  [`Hyperabduction Test (DX)`]: `Hyperabduction Test (R)`,
  [`Hyperabduction Test (SX)`]: `Hyperabduction Test (L)`,
  [`I ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT I STERNOCOSTAL JOINT`,
  [`I ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT I STERNOCOSTAL JOINT`,
  [`I ARTICOLAZIONE COSTOCONDRALE DESTRA`]: `RIGHT I COSTOCHONDRAL JOINT`,
  [`I ARTICOLAZIONE COSTOCONDRALE SINISTRA`]: `LEFT I COSTOCHONDRAL JOINT`,
  [`I ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT I COSTOTRANSVERSE JOINT`,
  [`I ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT I COSTOTRANSVERSE JOINT`,
  [`I ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT I COSTOVERTEBRAL JOINT`,
  [`I ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT I COSTOVERTEBRAL JOINT`,
  [`I costa (DX)`]: `I rib (R)`,
  [`I costa (SX)`]: `I rib (L)`,
  [`I COSTA DESTRA`]: `RIGHT I RIB`,
  [`I COSTA SINISTRA`]: `LEFT I RIB`,
  [`I metacarpo (DX)`]: `I metacarpal (R)`,
  [`I metacarpo (SX)`]: `I metacarpal (L)`,
  [`I METACARPO DESTRO`]: `RIGHT I METACARPAL`,
  [`I METACARPO DESTRO (BASE)`]: `RIGHT I METACARPAL (BASE)`,
  [`I METACARPO DESTRO (TESTA)`]: `RIGHT I METACARPAL (HEAD)`,
  [`I METACARPO SINISTRO`]: `LEFT I METACARPAL`,
  [`I METACARPO SINISTRO (BASE)`]: `LEFT I METACARPAL (BASE)`,
  [`I METACARPO SINISTRO (TESTA)`]: `LEFT I METACARPAL (HEAD)`,
  [`I metatarso (DX)`]: `I metatarsal (R)`,
  [`I metatarso (SX)`]: `I metatarsal (L)`,
  [`I METATARSO DESTRO`]: `RIGHT I METATARSAL `,
  [`I METATARSO DESTRO (BASE)`]: `RIGHT I METATARSAL (BASE)`,
  [`I METATARSO DESTRO (TESTA)`]: `RIGHT I METATARSAL (HEAD)`,
  [`I METATARSO SINISTRO`]: `LEFT I METATARSAL `,
  [`I METATARSO SINISTRO (BASE)`]: `LEFT I METATARSAL (BASE)`,
  [`I METATARSO SINISTRO (TESTA)`]: `LEFT I METATARSAL (HEAD)`,
  [`I Rigidità: Le seguenti domande riguardano il grado di rigidità articolare che ha provato durante la scorsa settimana. La rigidità è una sensazione di limitazione e di rallentamento nella naturalezza con cui normalmente utilizza il suo ginocchio`]: `Stiffness: The following questions concern the amount of joint stiffness you have
experienced during the last week in your knee. Stiffness is a sensation of
restriction or slowness in the ease with which you move your knee joint`,
  [`I Sintomi: Risponda alle seguenti domande ripensando ai sintomi avvertiti durante la scorsa settimana`]: `Symptoms: these questions should be answered thinking of your knee symptoms during the last week.`,
  [`I sostantivi sotto elencati aumentano o diminuiscono il suo dolore?`]: `Do the following items increase or decrease your pain?`,
  [`I suoi hobby, le sue attivita' ricreative o sportive`]: `Usual hobbies, recreational or sporting activities`,
  [`Ictus`]: `Stroke`,
  [`ieri`]: `Yesterday`,
  [`Ifema`]: `Hyphema`,
  [`II ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT II STERNOCOSTAL JOINT`,
  [`II ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT II STERNOCOSTAL JOINT`,
  [`II ARTICOLAZIONE COSTOCONDRALE DESTRA`]: `RIGHT II COSTOCHONDRAL JOINT`,
  [`II ARTICOLAZIONE COSTOCONDRALE SINISTRA`]: `LEFT II COSTOCHONDRAL JOINT`,
  [`II ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT II COSTOTRANSVERSE JOINT`,
  [`II ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT II COSTOTRANSVERSE JOINT`,
  [`II ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT II COSTOVERTEBRAL JOINT`,
  [`II ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT II COSTOVERTEBRAL JOINT`,
  [`II costa (DX)`]: `II rib (R)`,
  [`II costa (SX)`]: `II rib (L)`,
  [`II COSTA DESTRA`]: `RIGHT II RIB`,
  [`II COSTA SINISTRA`]: `LEFT II RIB`,
  [`II Dolore`]: `II Pain`,
  [`II metacarpo (DX)`]: `II metacarpal (R)`,
  [`II metacarpo (SX)`]: `II metacarpal (L)`,
  [`II METACARPO DESTRO`]: `RIGHT II METACARPAL`,
  [`II METACARPO DESTRO (BASE)`]: `RIGHT II METACARPAL (BASE)`,
  [`II METACARPO DESTRO (TESTA)`]: `RIGHT II METACARPAL (HEAD)`,
  [`II METACARPO SINISTRO`]: `LEFT II METACARPAL`,
  [`II METACARPO SINISTRO (BASE)`]: `LEFT II METACARPAL (BASE)`,
  [`II METACARPO SINISTRO (TESTA)`]: `LEFT II METACARPAL (HEAD)`,
  [`II metatarso (DX)`]: `II metatarsal (R)`,
  [`II metatarso (SX)`]: `II metatarsal (L)`,
  [`II METATARSO DESTRO`]: `RIGHT II METATARSAL`,
  [`II METATARSO DESTRO (BASE)`]: `RIGHT II METATARSAL (BASE)`,
  [`II METATARSO DESTRO (TESTA)`]: `RIGHT II METATARSAL (HEAD)`,
  [`II METATARSO SINISTRO`]: `LEFT II METATARSAL`,
  [`II METATARSO SINISTRO (BASE)`]: `LEFT II METATARSAL (BASE)`,
  [`II METATARSO SINISTRO (TESTA)`]: `LEFT II METATARSAL (HEAD)`,
  [`III ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT III STERNOCOSTAL JOINT`,
  [`III ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT III STERNOCOSTAL JOINT`,
  [`III ARTICOLAZIONE COSTOCONDRALE DESTRA`]: `RIGHT III COSTOCHONDRAL JOINT`,
  [`III ARTICOLAZIONE COSTOCONDRALE SINISTRA`]: `LEFT III COSTOCHONDRAL JOINT`,
  [`III ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT III COSTOTRANSVERSE JOINT`,
  [`III ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT III COSTOTRANSVERSE JOINT`,
  [`III ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT III COSTOVERTEBRAL JOINT`,
  [`III ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT III COSTOVERTEBRAL JOINT`,
  [`III costa (DX)`]: `III rib (R)`,
  [`III costa (SX)`]: `III rib (L)`,
  [`III COSTA DESTRA`]: `RIGHT III RIB`,
  [`III COSTA SINISTRA`]: `LEFT III RIB`,
  [`III Funzionamento, attività quotidiane: Le seguenti domande riguardano le capacità fisiche. Con questo termine intendiamo le abilità di spostarsi e di prendersi cura della propria persona. Per cortesia, per ognuna delle seguenti attività, indichi il ...`]: `Function, daily living: The following questions concern your physical function. By this we mean your
ability to move around and to look after yourself. For each of the following activities please indicate the degree of difficulty you have experienced in the
last week due to your knee. `,
  [`III metacarpo (DX)`]: `III metacarpal (R)`,
  [`III metacarpo (SX)`]: `III metacarpal (L)`,
  [`III METACARPO DESTRO`]: `RIGHT III METACARPAL`,
  [`III METACARPO DESTRO (BASE)`]: `RIGHT III METACARPAL (BASE)`,
  [`III METACARPO DESTRO (PROCESSO STILOIDEO)`]: `RIGHT III METACARPAL (STYLOID PROCESS)`,
  [`III METACARPO DESTRO (TESTA)`]: `RIGHT III METACARPAL (HEAD)`,
  [`III METACARPO SINISTRO`]: `LEFT III METACARPAL`,
  [`III METACARPO SINISTRO (BASE)`]: `LEFT III METACARPAL (BASE)`,
  [`III METACARPO SINISTRO (PROCESSO STILOIDEO)`]: `LEFT III METACARPAL (STYLOID PROCESS)`,
  [`III METACARPO SINISTRO (TESTA)`]: `LEFT III METACARPAL (HEAD)`,
  [`III metatarso (DX)`]: `III metatarsal (R)`,
  [`III metatarso (SX)`]: `III metatarsal (L)`,
  [`III METATARSO DESTRO`]: `RIGHT III METATARSAL`,
  [`III METATARSO DESTRO (BASE)`]: `RIGHT III METATARSAL (BASE)`,
  [`III METATARSO DESTRO (TESTA)`]: `RIGHT III METATARSAL (HEAD)`,
  [`III METATARSO SINISTRO`]: `LEFT III METATARSAL`,
  [`III METATARSO SINISTRO (BASE)`]: `LEFT III METATARSAL (BASE)`,
  [`III METATARSO SINISTRO (TESTA)`]: `LEFT III METATARSAL (HEAD)`,
  [`il dolore e' cominciato all'improvviso mentre mangiavo`]: `The symptoms started suddenly while eating`,
  [`il dolore e' cominciato in seguito ad un trauma`]: `The symptoms started after a contusion`,
  [`il dolore e' cominciato in seguito ad un trauma alla testa`]: `My symptoms started after a head trauma`,
  [`il dolore e' cominciato in seguito al mantenimento di una posizione non adeguata`]: `My symptoms started after mantaining a position for a long time`,
  [`il dolore e' cominciato in seguito allo svolgimento di una prolungata attivita'`]: `My symptoms started after performing an excessive activity`,
  [`Il dolore e' cominciato?`]: `How did the pain start?`,
  [`il dolore e' iniziato in seguito al mantenimento di una posizione non adeguata per un lungo periodo di tempo`]: `The pain started after maintaning a position for a long time`,
  [`il dolore e' iniziato in seguito allo svolgimento di un'eccessiva attivita'`]: `The pain started after performing an excessive activity`,
  [`il dolore e' iniziato in seguito allo svolgimento di una eccessiva attivita sportiva o lavorativa'`]: `It onsets after performing an excessive activity`,
  [`il dolore e' iniziato in seguito allo svolgimento di una eccessiva attivita'`]: `The pain started after performing an excessive activity`,
  [`il dolore e' presente al risveglio`]: `I feel pain in the morning upon awakening`,
  [`il dolore e' presente al risveglio o dopo un periodo di riposo`]: `I feel pain when I awake or after a rest period`,
  [`il dolore e' presente al risveglio o dopo un periodo di riposo e migliora con il movimento`]: `I feel pain upon awakening or after a rest period and it gets better with the movement`,
  [`il dolore e' presente in qualsiasi movimento faccia`]: `I feel pain in any movement I do`,
  [`il dolore e' presente in qualsiasi movimento faccia o attivita' svolga`]: `I feel pain in any movement I do`,
  [`Il dolore e' presente in qualsiasi movimento tu faccia?`]: `Do you have pain with any movement you do?`,
  [`il dolore e' presente qualunque movimento faccia o qualsiasi attivita' svolga`]: `pain is always present, whatever movement or activity I do`,
  [`il dolore e' presente sempre, anche adesso`]: `I always feel pain, even now`,
  [`il dolore e' presente sempre, anche ora`]: `I always feel pain, even now`,
  [`il dolore e' presente solo al risveglio`]: `I just feel pain upon awakening`,
  [`il dolore e' presente solo al risveglio e migliora con il movimento`]: `I only feel pain upon awakening and it gets better with the movement`,
  [`Il dolore e' presente solo all'inizio dell'attivita' e migliora dopo che l'articolazione e' calda""""""""`]: `I feel pain at the start of the activity and it improves when I'm warm`,
  [`il dolore e' presente solo durante lo svolgimento di attivita'`]: `I just feel pain during the activity`,
  [`il dolore e' presente solo durante lo svolgimento di certe attivita' o durante il mantenimento di certe posizioni`]: `I only feel pain when performing activity or when maintaining specific positions`,
  [`il dolore e' presente solo durante lo svolgimento di certi movimenti o attivita'`]: `I feel pain only when performing specific activities or movements`,
  [`il dolore e' presente solo durante lo svolgimento di determinate attivita'; specificare l'attivita'`]: `I only feel pain when performing a specific activity or movement`,
  [`il dolore e' presente solo durante lo svolgimento di una determinata attivita'attivita'`]: `I only feel pain performing a specific activity`,
  [`il dolore e' presente solo quando indosso determinate calzature`]: `Pain is present just when I wear certain footwear`,
  [`Il dolore e' riprodotto da un colpo di tosse o da uno stranuto?`]: `Do you have pain when coughing or sneezing?`,
  [`Il dolore e'accompagnato da claudicazione (zoppia) (claudicatio intermittens)?`]: `Have you got claudication pain?`,
  [`Il dolore limita le mie attività con gli altri e non esco cosi spesso come al solito`]: `Pain has restricted my social life and I do not go out as often`,
  [`Il dolore mi impedisce del tutto di avere una vita sessuale.`]: `Pain prevents any sex life at all`,
  [`Il dolore mi impedisce del tutto di dormire`]: `Pain prevents me from sleeping at all`,
  [`Il dolore mi impedisce del tutto di stare in piedi`]: `Pain prevents me from standing at all`,
  [`Il dolore mi impedisce del tutto di stare seduto/a`]: `Pain prevents me from sitting at all`,
  [`Il dolore mi impedisce di camminare per più di 100 metri`]: `Pain prevents me from walking more than 100 yards`,
  [`Il dolore mi impedisce di camminare per più di 500 metri`]: `Pain prevents me from walking more than 1/2 mile`,
  [`Il dolore mi impedisce di camminare per più di un chilometro`]: `Pain prevents me from walking more than 1 mile`,
  [`Il dolore mi impedisce di sollevare oggetti pesanti , ma riesco a sollevare oggetti leggeri o di medio peso se sono posizionati in maniera opportuna`]: `Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned`,
  [`Il dolore mi impedisce di sollevare oggetti pesanti da terra, ma ci riesco se sono posizionati in maniera opportuna, per esempio su un tavolo`]: `Pain prevents me lifting heavy weights off the floor, but I can manage if they are conveniently placed, for example, on a table`,
  [`Il dolore mi impedisce di stare in piedi per più di 1 ora`]: `Pain prevents me from standing for more than 1 hour`,
  [`Il dolore mi impedisce di stare in piedi per più di 10 minuti`]: `Pain prevents me from standing for more than 10 minutes`,
  [`Il dolore mi impedisce di stare in piedi per più di mezzora`]: `Pain prevents me from standing for more than 30 minutes`,
  [`Il dolore mi impedisce di stare seduto/a per più di 1 ora`]: `Pain prevents me from sitting more than one hour`,
  [`Il dolore mi impedisce di stare seduto/a per più di 10 minuti`]: `Pain prevents me from sitting more than 10 minutes`,
  [`Il dolore mi impedisce di stare seduto/a per più di mezzora`]: `Pain prevents me from sitting more than 30 minutes`,
  [`Il dolore mi impedisce di viaggiare e spostarmi, tranne che per fare le mie cure`]: `Pain prevents me from travelling except to receive treatment`,
  [`Il dolore mi limita a spostamenti brevi e necessari che durino meno di 30 minuti`]: `Pain restricts me to short necessary journeys under 30 minutes`,
  [`Il dolore mi limita a spostamenti che durino meno di un'ora`]: `Pain restrict me to journeys of less than one hour`,
  [`il dolore mi risveglia di notte`]: `The pain awakes me at night`,
  [`Il dolore migliora se ti inclini con il tronco di lato?`]: `Does your symptoms improve bending your trunk to the side?`,
  [`Il dolore migliora se ti inclini con il tronco in avanti?`]: `Does your symptoms improve bending forward your trunk?`,
  [`Il dolore migliora se ti inclini con il tronco lateralmente?`]: `Does the pain improve if you bend your trunk on the side?`,
  [`il dolore non e' legato ad alcun movimento`]: `The pain isn't link with any movement`,
  [`Il dolore non ha un vero effetto sulle mie attività con gli altri, a parte il fatto di limitare alcuni dei miei interessi che richiedono più energia (ad esempio sport, ecc.).`]: `Pain has no significant effect on my social life apart from limiting my more energetic interests eg sport`,
  [`Il dolore non mi impedisce di percorrere qualsiasi distanza a piedi`]: `Pain does not prevent me walking any distance`,
  [`il dolore peggiora alla sera`]: `The pain gets worse in the evening`,
  [`Il dolore scompare con l'assunzione di un'aspirina?`]: `Does the pain disappear with the use of Aspirin?`,
  [`Il dolore si e' spostato da quando e' comparso?`]: `Did the pain move since it started?`,
  [`Il dolore si e' spostato dalla zona in cui e' cominciato?`]: `Has the pain moved from the starting spot`,
  [`Il dolore si e' spostato o modificato da quando e' cominciato?`]: `Did the pain move or change since it started?`,
  [`Il dolore si e' spostato o modificato da quando e' comparso?`]: `Did the pain move or change since it started?`,
  [`Il dolore si e' spostato o modificato in qualche modo da quando e' cominciato?`]: `Did the pain move or change since it started?`,
  [`Il dolore si e' spostato o modificato in qualche modo da quando e' comparso?`]: `Did the pain move or change since it started?`,
  [`Il dolore si e' spostato o si e' modificato in qualche modo da quando e' cominciato?`]: `Did the pain move or change since it started?`,
  [`Il dolore si e' spostato o si e' modificato in qualche modo da quando e' comparso?`]: `Did the pain move since it started?`,
  [`Il dolore si irradia in altre aree o regioni del corpo?`]: `Does the pain radiate in other areas or do you feel pain in other body areas?`,
  [`Il dolore si irradia in altre regioni o e' presente in altre regioni del corpo?`]: `Does the pain radiate in other areas or do you feel pain in other body areas?`,
  [`Il dolore si presenta con uno specifici movimenti oppure si manifesta in ogni tipo di movimento?`]: `Do you feel pain in a specific movement or in any movement you do?`,
  [`Il dolore si presenta in specifici movimenti o si manifesta in qualsiasi movimento?`]: `Do you feel pain in a specific movement or in any movement you do?`,
  [`Il dolore si presenta in uno specifico movimento o in tutti I movimenti?`]: `Do you feel pain in a specific movement or in any movement you do?`,
  [`Il ginocchio ti sembra tumefatto (ha perso la sua forma fisiologica) o sono presenti deformita'?`]: `Is your knee swollen (did it loose its physiological shape) or is it deformed?`,
  [`il gonfiore nell'area posteriore del ginocchio potrebbe indicare una problematica intra-articolare; non escludere una trombosi venosa profonda`]: `Consider intraarticular pathologies; not to rule out deep venous thrombosis`,
  [`Il mio riposo e' completamente disturbato dal dolore al collo (5-7 ore di insonnia)`]: `My sleep is completely disturbed (5-7 hrs sleepless)`,
  [`Il mio riposo e' leggermente disturbato dal dolore al collo (1-2 ore di insonnia)`]: `My sleep is midly disturbed (1-2 hrs sleepless)`,
  [`Il mio riposo e' modestamente disturbato dal dolore al collo (2-3 ore di insonnia)`]: `My sleep is moderately disturbed (2-3 hrs sleepless)`,
  [`Il mio riposo e' molto disturbato dal dolore al collo (3-5 ore di insonnia)`]: `My sleep is greatly disturbed (3-5 hrs sleepless)`,
  [`Il mio riposo e' scarsamente disturbato dal dolore al collo(meno di un'ora di insonnia)`]: `My sleep is slightly disturbed (less than 1 hr sleepless)`,
  [`Il mio sonno non viene mai disturbato dal dolore`]: `My sleep is never disturbed by pain`,
  [`Il mio sonno viene disturbato ogni tanto dal dolore`]: `My sleep is occasionally disturbed by pain`,
  [`Il presente questionario è stato predisposto per conoscere il suo parere sullo stato della sua anca. Queste informazioni possono aiutarci a comprendere quanto lei sia in grado di svolgere differenti attività. Risponda a ciascuna domanda, mettendo una c...`]: `This survey asks for your view about your hip. This information will help us keep track of how you feel about your hip and how well you are able to do your usual activities. Answer every question by ticking the appropriate box. If you are unsure about how to answer a question, please give the best answer you can.`,
  [`Il presente questionario ha lo scopo di raccogliere informazioni in merito al suo punto di vista circa i problemi del suo ginocchio. Queste informazioni ci aiuteranno a conoscere la salute del suo ginocchio e il livello con cui è in grado di svolgere le normali attivita' quotidiane. Per cortesia, risponda ad ogni domanda barrando la casella piu' appropriata (una sola per ciascuna domanda). Se e' indeciso sulla risposta da scegliere, fornisca la miglior risposta possibile`]: `This survey asks for your view about your knee. This information will help us keep track of how you feel about your knee and how well you are able to perform your usual activities. Answer every question by ticking the appropriate box, only one box for each question. If you are unsure about how to answer a question, please give the best answer you can.`,
  [`Il presente questionario ha lo scopo di raccogliere informazioni in merito al suo punto di vista circa i problemi del suo ginocchio. Queste informazioni ci aiuteranno a conoscere la salute del suo ginocchio e il livello con cui è in grado di svolgere le...`]: `This survey asks for your view about your knee. This information will help us keep track of how you feel about your knee and how well you are able to perform your usual activities. Answer every question by ticking the appropriate box, only one box for each question. If you are unsure about how to answer a question, please give the best answer you can.`,
  [`Il presente questionario ha lo scopo di raccogliere informazioni in merito al suo punto di vista circa i problemi del suo ginocchio. Queste informazioni ci aiuteranno a registrare il livello con cui è in grado di svolgere diverse attività. Risponda ad ...`]: `This survey asks for your view about your knee. This information will help us keep track of how you feel about your knee and how well you are able to do your usual activities. Answer every question by ticking the appropriate box. If you are unsure about how to answer a question, please give the best answer you can`,
  [`Il presente questionario ha lo scopo di raccogliere informazioni in merito al suo punto di vista circa i problemi del suo ginocchio. Queste informazioni ci aiuteranno a registrare il livello con cui è in grado di svolgere diverse attività. Risponda ad ogni domanda barrando la casella appropriata, una sola casella per ciascuna domanda. Se è indeciso sulla risposta da scegliere, fornisca per cortesia la migliore risposta possibile in modo da rispondere a tutte le domande. Le seguenti domande riguardano le sue capacità fisiche durante lo svolgimento di attività della vita quotidiana, comuni e più impegnative. Per ognuna delle seguenti attività, indichi per cortesia il grado di difficolta' che ha incontraro durante la scorsa settimana a causa del suo problema al ginocchio`]: `This survey asks for your view about your knee. This information will help us keep track of how you feel about your knee and how well you are able to do your usual activities. Answer every question by ticking the appropriate box. If you are unsure about how to answer a question, please give the best answer you can`,
  [`Il presente questionario riguarda i suoi sintomi e la sua capacità di compiere alcune azioni. Risponda ad ogni domanda facendo riferimento al suo stato durante l'ultima settimana. Se non ha avuto l'opportunità di eseguire una delle azioni durante l'ult...`]: `This questionnaire asks about your symptoms as well as your ability to perform certain activities. Please answer every question , based on your condition in the last week. If you did not have the opportunity to perform an activity in the past week, please make your best estimate on which response would be the most accurate. It doesn't matter which hand or arm you use to perform the activity; please answer based on you ability regardless of how you perform the task`,
  [`Il presente questionario riguarda i suoi sintomi e la sua capacità di compiere alcune azioni. Risponda ad ogni domanda facendo riferimento al suo stato durante l'ultima settimana. Se non ha avuto l'opportunità di eseguire una delle azioni durante l'ultima settimana, risponda alla domanda provando a immaginare come avrebbe potuto eseguirla. Non importa con quale mano o braccio lei esegue l'azione; risponda in base alla sua capacità di compierla e senza tenere conto del modo in cui la compie`]: `This questionnaire asks about your symptoms as well as your ability to perform certain activities. Please answer every question , based on your condition in the last week. If you did not have the opportunity to perform an activity in the past week, please make your best estimate on which response would be the most accurate. It doesn't matter which hand or arm you use to perform the activity; please answer based on you ability regardless of how you perform the task`,
  [`Il presente questionario riguarda i suoi sintomi e la sua capacità di compiere alcune azioni. Risponda ad ogni domanda facendo riferimento al suo stato durante l'ultima settimana. Se non ha avuto l'opportunità di eseguire una delle azioni durante l'ultima settimana, risponda alla domanda provando a immaginare come avrebbe potuto eseguirla. Non importa con quale mano o braccio lei esegue l'azione; risponda in base alla sua capacita' di compierla e senza tenere conto del modo in cui la compie`]: `This questionnaire asks about your symptoms as well as your ability to perform certain activities. Please answer every question , based on your condition in the last week. If you did not have the opportunity to perform an activity in the past week, please make your best estimate on which response would be the most accurate. It doesn't matter which hand or arm you use to perform the activity; please answer based on you ability regardless of how you perform the task`,
  [`Il problema all'anca e/o all'inguine influisce in modo negativo sul suo umore?`]: `Does your hip and/or groin problem affect your mood in a negative way?`,
  [`Il suo ginocchio quanto influisce sulla sua capacità di…`]: `How does your knee affect your ability to:`,
  [`Il suo ginocchio si blocca o si arresta quando si muove?`]: `Does your knee catch or hang up when moving?`,
  [`Il suo ginocchio tende a gonfiarsi?`]: `Do you have swelling in your knee?`,
  [`Il suo lavoro, le faccende domestiche o le attivita' scolastiche`]: `Any of usual work, housework or school activities`,
  [`Il tuo ginocchio e' gonfio?`]: `Is your knee swollen?`,
  [`Il tuo lavoro e' molto stressante fisicamente o mentalmente`]: `Is your job physically and mentally stressful?`,
  [`Il tuo lavoro richiede il continuo sollevamento di oggetti?`]: `Does your job require frequent lifting of heavy items?`,
  [`Il tuo lavoro richiede il mantenimento prolungato della posizione in piedi?`]: `Does your job require standing for a long time? `,
  [`Il tuo lavoro richiede il mantenimento prolungato della posizione seduta?`]: `Does your job require sitting for a long time? `,
  [`ILEO`]: `ILEUM `,
  [`Ileo (DX)`]: `Ileum (R)`,
  [`ILEO (ILEO TERMINALE)`]: `ILEUM (TERMINAL ILEUM)`,
  [`Ileo (SX)`]: `Ileum (L)`,
  [`ILEO DESTRO`]: `RIGHT ILIUM`,
  [`ILEO DESTRO (ALA ILIACA)`]: `RIGHT ILIUM (WING)`,
  [`ILEO DESTRO (CRESTA ILIACA)`]: `RIGHT ILIUM (ILIAC CREST)`,
  [`ILEO DESTRO (SPINA ILIACA ANTERO INFERIORE)`]: `RIGHT ILIUM (ANTERIOR INFERIOR ILIAC SPINE)`,
  [`ILEO DESTRO (SPINA ILIACA ANTERO SUPERIORE)`]: `RIGHT ILIUM (ANTERIOR SUPERIOR ILIAC SPINE)`,
  [`ILEO DESTRO (SPINA ILIACA POSTERO INFERIORE)`]: `RIGHT ILIUM (POSTERIOR INFERIOR ILIAC SPINE)`,
  [`ILEO DESTRO (SPINA ILIACA POSTERO SUPERIORE)`]: `RIGHT ILIUM (POSTERIOR SUPERIOR ILIAC SPINE)`,
  [`ILEO SINISTRO`]: `LEFT ILIUM`,
  [`ILEO SINISTRO (ALA ILIACA)`]: `LEFT ILIUM (WING)`,
  [`ILEO SINISTRO (CRESTA ILIACA)`]: `LEFT ILIUM (ILIAC CREST)`,
  [`ILEO SINISTRO (SPINA ILIACA ANTERO INFERIORE)`]: `LEFT ILIUM (ANTERIOR INFERIOR ILIAC SPINE)`,
  [`ILEO SINISTRO (SPINA ILIACA ANTERO SUPERIORE)`]: `LEFT ILIUM (ANTERIOR SUPERIOR ILIAC SPINE)`,
  [`ILEO SINISTRO (SPINA ILIACA POSTERO INFERIORE)`]: `LEFT ILIUM (POSTERIOR INFERIOR ILIAC SPINE)`,
  [`ILEO SINISTRO (SPINA ILIACA POSTERO SUPERIORE)`]: `LEFT ILIUM (POSTERIOR SUPERIOR ILIAC SPINE)`,
  [`Illinois Agility Test`]: `Illinois Agility Test`,
  [`immobilizzazione`]: `Immobilisation`,
  [`Immunologica/infiammatoria`]: `Immunological/inflammatory`,
  [`Immunological/inflammatory`]: `Immunological/inflammatory`,
  [`Immunologici`]: `Immunologic`,
  [`impingement`]: `Impingement`,
  [`Impingement`]: `Impingement`,
  [`Impingement (conflitto) scapolo-omerale`]: `Internal impingement of the shoulder`,
  [`Impingement (conflitto) subacromiale`]: `Subacromial impingement`,
  [`Impingement anteriore della caviglia`]: `Ankle anterior impingement`,
  [`impingement antero-laterale di caviglia`]: `Anterolateral impingement of the ankle`,
  [`Impingement costoiliaco`]: `Costolliac impingement`,
  [`impingement del capitato`]: `capitate impingement`,
  [`Impingement del corpo adiposo di Hoffa`]: `Hoffa's fat pad impingement`,
  [`impingement del cuscinetto adiposo`]: `Fat pad impingement`,
  [`impingement del nervo ascellare`]: `Axillary nerve impingement`,
  [`impingement del nervo calcaneale mediale`]: `medial calcaneal nerve impingment`,
  [`impingement del nervo interosseo posteriore (sindrome del tunnel radiale)`]: `Posterior interosseous nerve entrapment (radial tunnel syndrome)`,
  [`impingement del nervo mediano (sindrome del pronatore rotondo),`]: `Median nerve entrapment (pronator teres syndrome)`,
  [`impingement del nervo soprascapolare`]: `Suprascapular nerve impingement `,
  [`impingement del nervo toracico lungo`]: `Long thoracic nerve impingement`,
  [`impingement del nervo ulnare`]: `Ulnar nerve impingement`,
  [`impingement del piramidale`]: `triquetral impingement`,
  [`impingement del semilunare`]: `lunate impingement`,
  [`impingement dell'estensore lungo del pollice`]: `Impingement of extensor pollicis longus`,
  [`impingement della stiloide ulnare`]: `impingement of ulnar styloid `,
  [`Impingement di radice nervosa lombosacrale da stenosi foraminale (ossea e discale)`]: `Lumbosacral Nerve root impingement due to foraminal stenosis bony and disc`,
  [`impingement femoro-acetabolare`]: `Femoroacetabular impingement`,
  [`impingement ischiofemorale`]: `ischiofemoral impingment`,
  [`impingement posteriore della caviglia`]: `posterior impingment of ankle`,
  [`Impingement posteriore della caviglia`]: `Posterior impingement ankle`,
  [`Impingement scapolo-omerale anteriore acuto`]: `Acute anterior internal impingement`,
  [`Impingement scapolo-omerale cronico`]: `Chronic internal impingement`,
  [`Impingement scapolo-omerale posteriore acuto`]: `Acute posterior internal impingement`,
  [`Impingement subacromiale acuto`]: `Acute subacromial impingement`,
  [`Impingement subacromiale cronico`]: `Other chronic subacromial impingement`,
  [`Impingement/sinovite della spalla`]: `Shoulder impingement/Synovitis`,
  [`Impingement/sinovite di gomito`]: `Elbow atraumatic synovitis`,
  [`Impingement/sinovite posteriore del gomito`]: `Elbow posterior impingement/synovitis`,
  [`Impingement/sinovite/lesione biomeccanica del ginocchio non associata ad altre patologie`]: `Knee Impingement/Synovitis/Biomechanical Lesion not associated with other conditions`,
  [`Impossibile praticare le attività sopracitate, a causa del dolore al ginocchio`]: `Unable to perform any of the above activities due to knee pain`,
  [`improvviso`]: `sudden`,
  [`In abduzione`]: `Abduction`,
  [`In abduzione orizzontale`]: `Horizontal abduction`,
  [`In adduzione`]: `Adduction`,
  [`In adduzione orizzontale`]: `Horizontal adduction`,
  [`in alcuni movimenti`]: `I feel pain in some movements`,
  [`in anteriorità`]: `Anterior glide`,
  [`In caso di comparsa di dolore nel quadrante addominale inferiore considerare una possibile appendicite o infiammazione peritoneale o dell'ileo-psoas; in caso di comparsa didolore articolare atnteriore o clicking potrebbe indicare lesione del labbro`]: `In presence of inferior abdominal quadrant pain consider appendicitis, peritoneal inflammation, iliopsoas inflammation; in presence of anterior articular pain or clicking consider labial lesion`,
  [`In che modo e' cominciato il dolore`]: `How did the pain begin?`,
  [`In che modo e' cominciato il dolore?`]: `How did the pain start?`,
  [`In che modo e' esordito il dolore`]: `How did the pain begin?`,
  [`In che modo e' esordito il dolore?`]: `How did the pain start?`,
  [`In compressione`]: `Compression`,
  [`In espiro`]: `Expiration`,
  [`In estensione`]: `Extension`,
  [`In estensione e rotazione`]: `Extension/rotation`,
  [`In estensione e rotazione destra`]: `Extension/right rotation`,
  [`In estensione e rotazione sinistra`]: `Extension/left rotation`,
  [`In eversione`]: `Eversion`,
  [`In fissazione`]: `Fixation`,
  [`In flessione`]: `Flexion`,
  [`In flessione dorsale`]: `Dorsal flexion`,
  [`In flessione e rotazione`]: `Flexion/rotation`,
  [`In flessione e rotazione destra`]: `Flexion/right rotation`,
  [`In flessione e rotazione sinistra`]: `Flexion/left rotation`,
  [`In flessione plantare`]: `Plantar flexion`,
  [`In generale direbbe che la sua salute e'…`]: `In general, would you say your health is:`,
  [`In generale, i problemi al suo ginocchio quanta difficoltà creano?`]: `In general, how much difficulty do you have with your knee?`,
  [`In generale, quanta difficolta' riscontra con il suo problema all'anca e/o all'inguine?`]: `In general, how much difficulty do you have with your hip and/or groin?`,
  [`In generale, quanto è in difficoltà a causa della sua anca?`]: `In general, how much difficulty do you have with your hip?`,
  [`In gran parte falso`]: `Mostly false`,
  [`In gran parte vero`]: `Mostly true`,
  [`In inspiro`]: `Inspiration`,
  [`In inversione`]: `Inversion`,
  [`In ipomobilità`]: `Hypomobility`,
  [`In ipomobilitÃ`]: `Hypomobility`,
  [`in modo improvviso`]: `Suddenly`,
  [`in modo improvviso `]: `Suddenly`,
  [`in modo improvviso in assenza di trauma`]: `Suddenly without an obvious cause`,
  [`in modo improvviso in assenza di un meccanismo traumatico`]: `Suddenly without an obvious cause`,
  [`in modo improvviso in assenza di un trauma`]: `suddenly, without any obvious cause`,
  [`in modo improvviso in assenza di un trauma specifico o una attivita' specifica`]: `Sudden onset, without a clear cause`,
  [`in modo improvviso in assenza di una causa specifica`]: `Suddenly without an obvious cause`,
  [`in modo improvviso in seguito ad un movimento specifico`]: `Suddenly after doing a specific movement`,
  [`in modo improvviso in seguito ad un movimento specifico di flessione assiale e rotazione`]: `with a sudden onset after a bending and/or rotation trunk movement `,
  [`in modo improvviso in seguito ad un movimento specifico durante lo svolgimento di un'attivita' sportiva`]: `Sudden onset performing a specific movement `,
  [`in modo lento e insidioso`]: `Slowly and insidiously`,
  [`in modo lento e progressivo`]: `Slowly and progressively`,
  [`in ogni movimento`]: `In every movement`,
  [`In parte`]: `Moderately`,
  [`In passato sì`]: `In the past `,
  [`In piedi`]: `Standing upright`,
  [`in posteriorità`]: `Posterior glide`,
  [`In presenza di dolore isolato in un area cervicale senza irradiazione considerare un problema meccanico`]: `Consider a mechanical pain `,
  [`In presenza di rash cutaneo considerare Herpes Zoster`]: `If cutaneous rash is present, consider Herpes Zoster`,
  [`In pronazione`]: `Pronation`,
  [`In riferimento soltanto a te stesso, hai mai sofferto o soffri di:`]: `Referred only to you, have you ever had:`,
  [`In rotazione`]: `Rotation`,
  [`In rotazione destra`]: `Right rotation`,
  [`In rotazione esterna`]: `External rotation`,
  [`In rotazione interna`]: `Internal rotation`,
  [`In rotazione sinistra`]: `Left rotation`,
  [`in scivolamento anteriore`]: `Anterior glide`,
  [`in Scivolamento dorsale`]: `Dorsal glide`,
  [`in scivolamento inferiore`]: `Inferior glide`,
  [`in scivolamento laterale`]: `Lateral glide`,
  [`in scivolamento mediale`]: `Medial glide`,
  [`in Scivolamento plantare`]: `Plantar glide `,
  [`in scivolamento posteriore`]: `Posterior glide`,
  [`in scivolamento superiore`]: `Superior glide`,
  [`In supinazione`]: `Supination`,
  [`in uno specifico movimento`]: `I feel pain doing specific movements`,
  [`In valgismo`]: `Valgus`,
  [`In-flare`]: `In-flare`,
  [`Inarcamento posteriore`]: `Posterior buckling`,
  [`Incidenti stradali o traumi maggiori`]: `Major trauma or accident`,
  [`Inclinato a destra`]: `Right side-bending`,
  [`Inclinato a sinistra`]: `Left side-bending`,
  [`Inclinazione destra`]: `Right side-bending`,
  [`Inclinazione sinistra`]: `Left side-bending`,
  [`Incline Hammer Curl`]: `Incline Hammer Curl`,
  [`Incline Press`]: `Incline Press`,
  [`Incontinenza urinaria`]: `Urinary incontinence`,
  [`INCUDINE DESTRA`]: `RIGHT INCUS`,
  [`INCUDINE SINISTRA`]: `LEFT INCUS`,
  [`Indica se hai dolore in una delle seguenti attivita'`]: `Have you got pain in any of the following activities?`,
  [`Indolenzito`]: `Heavy`,
  [`Indossare le calze`]: `Putting on socks/stockings`,
  [`Indossare una camicia abbottonata sul davanti?`]: `Putting on a shirt that buttons down the front?`,
  [`Indossare una maglietta oppure un pullover?`]: `Putting on a undershirt or jumper?`,
  [`Indossarei pantaloni?`]: `Putting on your pants?`,
  [`Infarto del miocardio`]: `Myocardial infarction`,
  [`Infelice`]: `Wretched`,
  [`Inferiorita'`]: `Inferior glide`,
  [`Infezione`]: `Infection`,
  [`Infezione al moncone di avambraccio`]: `Infection of stump forearm`,
  [`Infezione al moncone di braccio`]: `Infection of stump upper arm`,
  [`Infezione al moncone di coscia`]: `Infection of stump thigh`,
  [`Infezione al moncone di gamba`]: `Infection of stump lower leg`,
  [`Infezione al moncone di ginocchio`]: `Infection of stump knee`,
  [`Infezione al moncone di gomito`]: `Infection of stump elbow`,
  [`Infezione all'orecchio +/- seni/gola`]: `Ear +/- sinuses/throat infection`,
  [`Infezione alla sinfisi pubica`]: `Infected pubic symphysis`,
  [`Infezione articolare - artrite settica (escluse complicazioni post-chirurgiche o lacerazioni perforanti)`]: `Joint infection - septic arthritis (excl complications of surgery or perforating lacerations)`,
  [`Infezione articolare del gomito`]: `Infected elbow joint`,
  [`Infezione articolare del piede`]: `Infected foot joint`,
  [`Infezione articolare dell'anca`]: `Infected hip joint`,
  [`Infezione articolare della caviglia`]: `Infected ankle joint`,
  [`Infezione articolare della spalla`]: `Infected shoulder joint`,
  [`Infezione articolare di polso, mano, dita, pollice`]: `Infected wrist, hand, finger, thumb joint`,
  [`Infezione come complicanza di ferita/abrasione della gamba`]: `Infection as complication of lower leg laceration/abrasion`,
  [`Infezione cutanea al gomito`]: `Skin infection elbow`,
  [`Infezione cutanea al polso/mano`]: `Skin infection wrist/hand`,
  [`Infezione cutanea alla pelvi/natiche, compreso ascesso ischiale`]: `Skin infection pelvis/buttock - incl ischial abscess`,
  [`Infezione cutanea alla testa/faccia/collo`]: `Skin infection head/face/neck`,
  [`Infezione cutanea virale (comprese le verruche)`]: `Skin infection - viral (incl warts)`,
  [`Infezione cutanea-fungina`]: `Skin Infection - fungal`,
  [`Infezione da COVID-19`]: `COVID-19 infection`,
  [`Infezione dei tessuti molli della gamba`]: `Lower leg soft tissue infection`,
  [`Infezione del sistema respiratorio`]: `Respiratory system infection`,
  [`Infezione del sistema respiratorio, sospetto di COVID-19`]: `Respiratory infection suspected of being COVID-19`,
  [`Infezione del tratto urinario`]: `Urinary Tract Infection`,
  [`Infezione dell'articolazione del ginocchio`]: `Infected knee joint`,
  [`Infezione dell'orecchio`]: `Ear infection`,
  [`Infezione dell'orecchio medio`]: `Middle ear infection`,
  [`Infezione funginea all'inguine`]: `Fungal infection groin`,
  [`Infezione gastrointestinale`]: `Gastrointestinal infection`,
  [`Infezione ginecologica`]: `Gynaecological infection`,
  [`Infezione orale`]: `Dental condition`,
  [`Infezione ossea - osteomielite`]: `Infection of bone - osteomyelitis`,
  [`Infezione ossea - osteomielite alla coscia`]: `Infection of bone - osteomyelitis thigh`,
  [`Infezione virale sieropositiva`]: `Virus proven by serology`,
  [`Infezioni del tratto respiratorio o urinario`]: `Recurrent urinary or respiratory infections`,
  [`Infezioni del tratto urinario`]: `Urinary tract infection`,
  [`Infiammazione dell'artic olazione sacroiliaca (esclusa artrite infiammatoria della sacroiliaca)`]: `Sacroiliac Joint Inflammation`,
  [`Infiammazione/rigidita' delle articolazioni costovertebrali`]: `Inflammation/stiffness of costovertebral joints`,
  [`Infiammazione/sinovite/altra lesione biomeccanica dell'articolazione dell'anca`]: `Hip Joint Inflammation/Synovitis/Other Biomechanical Lesion`,
  [`Infilarsi le scarpe o i calzini`]: `Putting on your shoes or socks`,
  [`Infilarsi un maglione`]: `Put on a pullover sweater`,
  [`Infortunio`]: `Injury`,
  [`Infortunio al collo`]: `Neck Injuries`,
  [`Infortunio al gomito`]: `Elbow Injuries`,
  [`Infortunio alla colonna lombare`]: `Lumbar spinal injury`,
  [`Infortunio alla fisi`]: `Physis injury`,
  [`Infortunio alla testa`]: `Neck contusion/haematoma`,
  [`Infortunio da trazione al piede`]: `Traction injury to foot`,
  [`Infortunio muscoloscheletrico associato a gravidanza`]: `Pregnancy associated musculoskeletal injury`,
  [`Infortunio traumatico ai polmoni/cuore`]: `Lung/Heart Traumatic Injury`,
  [`Infraspinatus or External Rotation Resistance Test (DX)`]: `Infraspinatus or External Rotation Resistance Test (R)`,
  [`Infraspinatus or External Rotation Resistance Test (SX)`]: `Infraspinatus or External Rotation Resistance Test (L)`,
  [`Inginocchiarsi`]: `Kneeling`,
  [`Inginocchiarsi appoggiandosi sulla parte anteriore del ginocchio`]: `Kneel on the front of your knee`,
  [`Inibito`]: `Inhibited`,
  [`Inibito (tutti gli altri)`]: `Inhibited`,
  [`Inibizione`]: `Inhibition`,
  [`Inibizione muscolare`]: `Muscle inhibition`,
  [`INION`]: `INION`,
  [`Iniziare a camminare (al risveglio mattutino, o dopo una posizione seduta o distesa prolungata)`]: `Walking initially`,
  [`innervazione`]: `innervation`,
  [`inserire data precisa o approssimativa`]: `Enter date`,
  [`Inserisci il tuo peso e la tua altezza in metri`]: `Please, insert your body weight and your height (in meters)`,
  [`Inserzione`]: `Insertion`,
  [`insicurezza al lavoro`]: `Job insecurity`,
  [`Insopportabile`]: `Extreme`,
  [`inspiegabile perdita di peso`]: `Inexplicable weight lost`,
  [`Inspirazione profonda`]: `Deep inspiration`,
  [`instabilita`]: `Instability`,
  [`instabilita'`]: `Instability`,
  [`Instabilita'`]: `Instability`,
  [`INSTABILITA'`]: `Instability`,
  [`Instabilita' anteroinferiore di spalla`]: `Anterior shoulder instability`,
  [`instabilita' articolare dell'anca`]: `hip joint instability`,
  [`Instabilita' associata all'impingement subacromiale`]: `Instability associated subacromial impingement`,
  [`instabilita' carpale`]: `carpal instability`,
  [`Instabilita' carpale`]: `Carpal instability`,
  [`Instabilita' carpale VISI (instabilita' volare del segmento intermedio)`]: `VISI wrist instability`,
  [`Instabilita' cronica`]: `Chronic instability`,
  [`instabilita' cronica del gomito`]: `Chronic elbow instability`,
  [`Instabilita' cronica del polso`]: `Chronic Wrist Instability`,
  [`Instabilita' cronica di articolazione del piede`]: `Foot joint chronic instability`,
  [`Instabilita' cronica di caviglia`]: `Chronic Ankle Instability`,
  [`Instabilita' cronica di spalla`]: `Chronic Shoulder instability`,
  [`Instabilita' cronica laterale di caviglia`]: `Chronic lateral instability`,
  [`Instabilita' cronica mediale di caviglia`]: `Chronic medial instability`,
  [`Instabilita' del ginocchio (sublussazioni croniche o ricorrenti)`]: `Knee Instability (chronic or recurrent subluxations)`,
  [`Instabilita' del pollice`]: `Thumb Instability`,
  [`Instabilita' dell' articolazione metacarpo-falangea del pollice`]: `Instability 1st MCP joint`,
  [`Instabilita' dell'articolazione costocondrale`]: `Costochondral joint instability`,
  [`Instabilita' dell'articolazione costovertebrale`]: `Costovertebral joint instability`,
  [`Instabilita' dell'articolazione dell'anca`]: `Instability of Hip joint`,
  [`Instabilita' dell'articolazione inter-falangea del pollice`]: `Interphalangeal joint instability of thumb`,
  [`Instabilita' dell'articolazione interfalangea prossimale o distale del dito della mano (escluso il pollice)`]: `Finger PIP or DIP joint instability`,
  [`Instabilita' dell'articolazione metacarpofalangea del dito della mano (escluso il pollice)`]: `Finger MCP joint instability`,
  [`Instabilita' dell'articolazione radio-carpica`]: `Radiocarpal joint instability`,
  [`Instabilita' dell'articolazione radio-ulnare distale`]: `Distal radioulnar joint instability`,
  [`Instabilita' dell'articolazione sacroiliaca`]: `Sacroiliac Joint Instability`,
  [`Instabilita' dell'articolazione sternoclavicolare`]: `Sternoclavicular joint instability`,
  [`Instabilita' della I articolazione carpo-metacarpale`]: `Instability 1st CMC joint`,
  [`Instabilita' della sinfisi pubica`]: `Pubic symphysis instability`,
  [`Instabilita' di gomito`]: `Elbow Instability`,
  [`Instabilita' di rotula`]: `Patellar instability`,
  [`instabilita' femoro-rotulea`]: `patello-femoral instability`,
  [`instabilita' gleno-omerale AIOS`]: `Glenohumeral instability (AIOS)`,
  [`instabilita' gleno-omerale AMBRI`]: `Glenohumeral instability (AMBRI)`,
  [`instabilita' gleno-omerale Tubs`]: `Glenohumeral instability (TUBS)`,
  [`Instabilita' in valgo del gomito`]: `Elbow valgus instability`,
  [`instabilita' lombare`]: `Lumbar Instability`,
  [`Instabilita' lombare`]: `Lumbar Instability`,
  [`Instabilita' lombosacrale`]: `Lumbosacral instability`,
  [`Instabilita' posteriore di spalla`]: `Posterior shoulder instability`,
  [`Instabilita' posterolaterale di gomito`]: `Elbow posterolateral instability`,
  [`instabilita' radio-ulnare distale`]: `distal radio-ulnar joint instability`,
  [`Instabilita' scafolunata (DISI instabilita' dorsale del segmento intermedio)`]: `Scapholunate (DISI) instability`,
  [`Instabilita' vertebrale cervicale`]: `Cervical Spine Instability`,
  [`Instabilita'/lussazione recidivante dell'articolazione acromioclavicolare`]: `A/C Joint instability/recurrent sprains`,
  [`Instability Catch Sign`]: `Instability Catch Sign`,
  [`Insufficienza cardio vascolare`]: `Cardiovascular Illness`,
  [`Insufficienza cronica del legamento collaterale mediale (LCM)`]: `Chronic MCL insufficiency`,
  [`Insufficienza cronica del legamento crociato anteriore (LCA)`]: `Chronic ACL insufficiency`,
  [`Insufficienza cronica del legamento crociato posteriore (LCP)`]: `Chronic PCL insufficiency`,
  [`Insufficienza renale cronica`]: `Chronic Kidney Disease (CKD)`,
  [`Insufficienza surrenale,sindrome di Cushing`]: `Cushing syndrome`,
  [`Intensa`]: `Severe`,
  [`Intenso`]: `Severe`,
  [`Interlinea articolare mediale del ginocchio (DX)`]: `Medial joint line of knee (R)`,
  [`Interlinea articolare mediale del ginocchio (SX)`]: `Medial joint line of knee (L)`,
  [`Internal Rotation Lag Sign (DX)`]: `Internal Rotation Lag Sign (R)`,
  [`Internal Rotation Lag Sign (SX)`]: `Internal Rotation Lag Sign (L)`,
  [`Internal Rotation Resisted Strength Test (DX)`]: `Internal Rotation Resisted Strength Test (R)`,
  [`Internal Rotation Resisted Strength Test (SX)`]: `Internal Rotation Resisted Strength Test (L)`,
  [`Internal Rotators of the Hip Assessment (DX)`]: `Internal Rotators of the Hip Assessment (R)`,
  [`Internal Rotators of the Hip Assessment (SX)`]: `Internal Rotators of the Hip Assessment (L)`,
  [`Interventi chirurgici`]: `Surgeries`,
  [`Interventi chirurgici al cuore`]: `Heart Surgery`,
  [`Interventi chirurgici gastro-intestinali`]: `Gastrointestinal Surgery`,
  [`Intervento chirurgico`]: `Surgery`,
  [`Intervista anca`]: `Hip region interview`,
  [`Intervista area achille e area posteriore caviglia`]: `Achille's area interview`,
  [`Intervista avambraccio`]: `Forearm region interview`,
  [`Intervista braccio`]: `Upper arm region interview`,
  [`Intervista caviglia`]: `Ankle region interview`,
  [`Intervista cervicale`]: `Neck region interview`,
  [`Intervista coscia area anteriore`]: `Anterior thigh area interview`,
  [`Intervista coscia area anteriore mediale`]: `Medial thigh area interview`,
  [`Intervista coscia area posteriore`]: `Posterior thigh area interview`,
  [`Intervista costale`]: `Chest region interview`,
  [`Intervista gamba area anteromediale e laterale`]: `Leg region interview`,
  [`Intervista ginocchio area anteriore`]: `Anterior knee area interview`,
  [`Intervista ginocchio area laterale`]: `Lateral knee area interview`,
  [`Intervista ginocchio area mediale`]: `Medial knee area interview`,
  [`Intervista ginocchio area poplitea`]: `Posterior knee area interview`,
  [`Intervista gluteo`]: `Gluteal area interview`,
  [`Intervista gomito`]: `Elbow region interview`,
  [`Intervista lombare`]: `Lumbar region interview`,
  [`Intervista mandibolare`]: `TMJ area interview`,
  [`Intervista mano`]: `Hand region interview`,
  [`Intervista piede`]: `Foot region interview`,
  [`Intervista polpaccio`]: `Calf area interview`,
  [`Intervista polso`]: `Wrist region interview`,
  [`Intervista spalla`]: `Shoulder region interview`,
  [`Intervista testa`]: `Head region interview`,
  [`Intervista toracica`]: `Thoracic region interview`,
  [`Intestino`]: `Instestine`,
  [`INTESTINO CRASSO`]: `LARGE INTESTINE`,
  [`INTESTINO TENUE`]: `SMALL INTESTINE`,
  [`Intorpidimenti`]: `Numbness or tingling`,
  [`Intorpidito`]: `Numb`,
  [`Intossicazione da farmaci/cibo/overdose`]: `Drug use/overdose/poisoning`,
  [`Intrappolamento del nervo calcaneale`]: `Calcaneal nerve entrapment`,
  [`intrappolamento del nervo calcaneale mediale`]: `Medial calcaneal nerve entrapment`,
  [`Intrappolamento del nervo calcaneale mediale`]: `Medial calcaneal nerve entrapment`,
  [`Intrappolamento del nervo cutaneo laterale della coscia`]: `Lateral cutaneous nerve of thigh entrapment`,
  [`Intrappolamento del nervo genitofemorale`]: `Genitofemoral nerve entrapment`,
  [`Intrappolamento del nervo ileoinguinale`]: `Ilioinguinal nerve entrapment`,
  [`Intrappolamento del nervo otturatorio`]: `Obturator nerve entrapment`,
  [`intrappolamento del nervo radiale interosseo posteriore`]: `Posterior interosseous nerve-radial nerve entrapment`,
  [`intrappolamento del nervo sciatico`]: `sciatic nerve impingement`,
  [`intrappolamento dell'arteria poplitea`]: `Popliteal artery entrapment`,
  [`Intrappolamento dell'arteria poplitea`]: `Popliteal artery entrapment`,
  [`Intrappolamento nervoso all'inguine`]: `Nerve Entrapment Groin`,
  [`Intrappolamento nervoso alla coscia`]: `Nerve entrapment in thigh`,
  [`Intrappolamento o paralisi del nervo soprascapolare`]: `Suprascapular nerve entrapment or palsy`,
  [`Inversione`]: `Inversion`,
  [`Inversione articolazione della caviglia (DX)`]: `Ankle joint inversion (R)`,
  [`Inversione articolazione della caviglia (SX)`]: `Ankle joint inversion (L)`,
  [`Inversione articolazione sottoastragalica (DX)`]: `Subtalar joint inversion (R)`,
  [`Inversione articolazione sottoastragalica (SX)`]: `Subtalar joint inversion (L)`,
  [`Investigazione per massa al seno`]: `Breast lump for investigation`,
  [`Ipercifosi`]: `Hyperkyphosis`,
  [`Iperestensione`]: `Hyperextenion`,
  [`Iperestensione `]: `Hyperextension`,
  [`Iperestensione del gomito +- stiramento delle strutture anteriori del gomito`]: `Elbow hyperextension +/- strain anterior elbow structures`,
  [`Iperlordosi`]: `Hyperlordosis`,
  [`Ipermobilità`]: `Hypomobility`,
  [`IpermobilitÃ`]: `Hyperextension`,
  [`Ipermobilita' articolare generalizzata`]: `Generalised hypermobility of joints`,
  [`Iperparatiroidismo`]: `Hyperparathyroidism`,
  [`Iperplasia prostatica benigna`]: `Benign prostatic hyperplasia (BPH)`,
  [`Ipertensione`]: `Hypertension or high blood pressure`,
  [`Ipertermia/colpo di calore`]: `Hyperthermia/heat stroke`,
  [`Ipertiroidismo`]: `Hyperthyroidism`,
  [`Ipo o iper-mobilita' articolare`]: `Hypo or hyper - mobility of joints`,
  [`IPOFISI`]: `PITUITARY GLAND`,
  [`IPOFISI (ADENOIPOFISI)`]: `PITUITARY GLAND (ADENOHYPOPHYSIS)`,
  [`IPOFISI (NEUROIPOFISI)`]: `PITUITARY GLAND (NEUROHYPOPHYSIS)`,
  [`Ipoglicemia`]: `Hypoglycemia`,
  [`Ipomobilità`]: `Hypomobility`,
  [`IpomobilitÃ`]: `Hypomobility`,
  [`IpomobilitÃ gleno-omerale`]: `Glenohumeral hypomobility`,
  [`Ipomobilità del femore`]: `Femoral hypomobility`,
  [`Ipomobilità gleno-omerale`]: `Glenohumeral hypomobility`,
  [`Ipomobilita'`]: `Hypomobility`,
  [`Ipomobilita' articolare generalizzata`]: `Generalised hypomobility of joints`,
  [`Ipomobilita' gleno-omerale`]: `Glenohumeral hypomobility`,
  [`IpomobiltÃ gleno-omerale`]: `Glenohumeral hypomobility`,
  [`Ipomobiltà gleno-omerale`]: `Glenohumeral hypomobility`,
  [`Ipoparatiroidismo`]: `Hypoparathyroidism`,
  [`Ipotermia`]: `Hypothermia`,
  [`Ipotiroidismo`]: `Hypothyroidism`,
  [`Irritabilità`]: `Irritability`,
  [`Ischio (DX)`]: `Ischium (R)`,
  [`Ischio (SX)`]: `Ischium (L)`,
  [`Ischio (tuberosita' ischiatica) (DX)`]: `Ischium (ischial tuberosity) (R)`,
  [`Ischio (tuberosita' ischiatica) (SX)`]: `Ischium (ischial tuberosity) (L)`,
  [`ISCHIO DESTRO`]: `RIGHT ISCHIUM`,
  [`ISCHIO DESTRO (SPINA ISCHIATICA)`]: `RIGHT ISCHIUM (ISCHIATIC SPINE)`,
  [`ISCHIO DESTRO (TUBEROSITA' ISCHIATICA)`]: `RIGHT ISCHIUM (ISCHIAL TUBEROSITY)`,
  [`ISCHIO SINISTRO`]: `LEFT ISCHIUM`,
  [`ISCHIO SINISTRO (SPINA ISCHIATICA)`]: `LEFT ISCHIUM (ISCHIATIC SPINE)`,
  [`ISCHIO SINISTRO (TUBEROSITA' ISCHIATICA)`]: `LEFT ISCHIUM (ISCHIAL TUBEROSITY)`,
  [`Iso Bear Crawl`]: `ISOMETRIC BEAR CRAWL`,
  [`IV ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT IV STERNOCOSTAL JOINT`,
  [`IV ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT IV STERNOCOSTAL JOINT`,
  [`IV ARTICOLAZIONE COSTOCONDRALE DESTRA`]: `RIGHT IV COSTOCHONDRAL JOINT`,
  [`IV ARTICOLAZIONE COSTOCONDRALE SINISTRA`]: `LEFT IV COSTOCHONDRAL JOINT`,
  [`IV ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT IV COSTOTRANSVERSE JOINT`,
  [`IV ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT IV COSTOTRANSVERSE JOINT`,
  [`IV ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT IV COSTOVERTEBRAL JOINT`,
  [`IV ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT IV COSTOVERTEBRAL JOINT`,
  [`IV costa (DX)`]: `IV rib (R)`,
  [`IV costa (SX)`]: `IV rib (L)`,
  [`IV COSTA DESTRA`]: `RIGHT IV RIB `,
  [`IV COSTA SINISTRA`]: `LEFT IV RIB `,
  [`IV Funzionamento, sport e attività ricreative: Le domande seguenti riguardano le sue capacità fisiche durante attività più impegnative. Per cortesia, risponda alle seguenti domande ripensando al grado di difficoltà incontrato durante la scorsa setti...`]: `Function, sports and recreational activities:
    The following questions concern your physical function when being active on a
higher level. The questions should be answered thinking of what degree of
difficulty you have experienced during the last week due to your knee. `,
  [`IV metacarpo (DX)`]: `IV metacarpal (R)`,
  [`IV metacarpo (SX)`]: `IV metacarpal (L)`,
  [`IV METACARPO DESTRO`]: `RIGHT IV METACARPAL`,
  [`IV METACARPO DESTRO (BASE)`]: `RIGHT IV METACARPAL (BASE)`,
  [`IV METACARPO DESTRO (TESTA)`]: `RIGHT IV METACARPAL (HEAD)`,
  [`IV METACARPO SINISTRO`]: `LEFT IV METACARPAL`,
  [`IV METACARPO SINISTRO (BASE)`]: `LEFT IV METACARPAL (BASE)`,
  [`IV METACARPO SINISTRO (TESTA)`]: `LEFT IV METACARPAL (HEAD)`,
  [`IV metatarso (DX)`]: `IV metatarsal (R)`,
  [`IV metatarso (SX)`]: `IV metatarsal (L)`,
  [`IV METATARSO DESTRO`]: `RIGHT IV METATARSAL`,
  [`IV METATARSO DESTRO (BASE)`]: `RIGHT IV METATARSAL (BASE)`,
  [`IV METATARSO DESTRO (TESTA)`]: `RIGHT IV METATARSAL (HEAD)`,
  [`IV METATARSO SINISTRO`]: `LEFT IV METATARSAL`,
  [`IV METATARSO SINISTRO (BASE)`]: `LEFT IV METATARSAL (BASE)`,
  [`IV METATARSO SINISTRO (TESTA)`]: `LEFT IV METATARSAL (HEAD)`,
  [`IX ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT IX COSTOTRANSVERSE JOINT`,
  [`IX ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT IX COSTOTRANSVERSE JOINT`,
  [`IX ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT IX COSTOVERTEBRAL JOINT`,
  [`IX ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT IX COSTOVERTEBRAL JOINT`,
  [`IX costa (DX)`]: `IX rib (R)`,
  [`IX costa (SX)`]: `IX rib (L)`,
  [`IX COSTA DESTRA`]: `RIGHT IX RIB`,
  [`IX COSTA SINISTRA`]: `LEFT IX RIB`,
  [`J M Robinson, J L Cook, C Purdam, P J Visentini, J Ross, N Maffulli, J E Taunton, K M Khan, for the Victorian Institute of Sport Tendon Study Group. The VISA-A questionnaire: a valid and reliable index of the clinical severity of Achilles tendinopathy. B...`]: `J M Robinson, J L Cook, C Purdam, P J Visentini, J Ross, N Maffulli, J E Taunton, K M Khan, for the Victorian Institute of Sport Tendon Study Group. The VISA-A questionnaire: a valid and reliable index of the clinical severity of Achilles tendinopathy. B...`,
  [`Jan lucas hoving, Elizabeth F o’leary, ken r niere, sally green, Rachelle buchbinder, Validity of the neck disability index, Northwick park neck pain questionnaire, and problem elicitation technique for measuring disability associated with whiplash-ass...`]: `Jan lucas hoving, Elizabeth F o’leary, ken r niere, sally green, Rachelle buchbinder, Validity of the neck disability index, Northwick park neck pain questionnaire, and problem elicitation technique for measuring disability associated with whiplash-ass...`,
  [`Jerk Test (DX)`]: `Jerk Test (R)`,
  [`Jerk Test (SX)`]: `Jerk Test (L)`,
  [`Judo`]: `Judo`,
  [`Karin Grävare Silbernagel, Roland ThomeéfckLRBengt I. Eriksson, and Jon Karlsson. Continued Sports Activity, Using a Pain-Monitoring Model, During Rehabilitation in Patients With Achilles Tendinopathy. Am J Sports Med. 2007 Jun;35(6):897-906.`]: `Karin Grävare Silbernagel, Roland ThomeéfckLRBengt I. Eriksson, and Jon Karlsson. Continued Sports Activity, Using a Pain-Monitoring Model, During Rehabilitation in Patients With Achilles Tendinopathy. Am J Sports Med. 2007 Jun;35(6):897-906.`,
  [`Katz Hand Diagram (DX)`]: `Katz Hand Diagram (R)`,
  [`Katz Hand Diagram (SX)`]: `Katz Hand Diagram (L)`,
  [`Kemp Test`]: `Kemp Test`,
  [`Kettlebell Renegade Row`]: `Kettlebell Renegade Row`,
  [`Kettlebell Swing`]: `Kettlebell Swing`,
  [`Kettlebell Turkish Get Up`]: `Kettlebell Turkish Get Up`,
  [`Key Pinch Strength Test (DX)`]: `Key Pinch Strength Test (R)`,
  [`Key Pinch Strength Test (SX)`]: `Key Pinch Strength Test (L)`,
  [`Kim Test (DX)`]: `Kim Test (R)`,
  [`Kim Test (SX)`]: `Kim Test (L)`,
  [`Kivlan, B. R., Martin, R. L., & Wukich, D. K. (2011). Responsiveness of the foot and ankle ability measure (FAAM) in individuals with diabetes. The Foot, 21(2), 84-87.`]: `Kivlan, B. R., Martin, R. L., & Wukich, D. K. (2011). Responsiveness of the foot and ankle ability measure (FAAM) in individuals with diabetes. The Foot, 21(2), 84-87.`,
  [`Km percorsi`]: `Total distance`,
  [`Knee Anterior Drawer Test (DX)`]: `Knee Anterior Drawer Test (R)`,
  [`Knee Anterior Drawer Test (SX)`]: `Knee Anterior Drawer Test (L)`,
  [`Knee Bending in 30 seconds`]: `Knee Bending in 30 seconds`,
  [`Knee Joint Line Tenderness (DX)`]: `Knee Joint Line Tenderness (R)`,
  [`Knee Joint Line Tenderness (SX)`]: `Knee Joint Line Tenderness (L)`,
  [`Knee Valgus Stress (DX)`]: `Knee Valgus Stress (R)`,
  [`Knee Valgus Stress (SX)`]: `Knee Valgus Stress (L)`,
  [`Knee Varus Stress (DX)`]: `Knee Varus Stress (R)`,
  [`Knee Varus Stress (SX)`]: `Knee Varus Stress (L)`,
  [`KOOS (ARTICOLAZIONE DEL GINOCCHIO)`]: `KOOS (KNEE OSTEOARHTRITIS OUTCOME SCORE)`,
  [`Kyphosis angle`]: `Kyphosis angle`,
  [`l dolore peggiora alla sera`]: `The pain gets worse in the evening`,
  [`L'allenamento e la partecipazione sono stati modificati`]: `Modified training / modified competition`,
  [`L'esordio del dolore e' stato?`]: `How did the pain onset?`,
  [`L1`]: `L1`,
  [`L1 (PROCESSO SPINOSO)`]: `L1 (SPINOUS PROCESS)`,
  [`L1 (PROCESSO TRASVERSO DESTRO)`]: `L1 (RIGHT TRANSVERSE PROCESS)`,
  [`L1 (PROCESSO TRASVERSO SINISTRO)`]: `L1 (LEFT TRANSVERSE PROCESS)`,
  [`L2`]: `L2`,
  [`L2 (PROCESSO SPINOSO)`]: `L2 (SPINOUS PROCESS)`,
  [`L2 (PROCESSO TRASVERSO DESTRO)`]: `L2 (RIGHT TRANSVERSE PROCESS)`,
  [`L2 (PROCESSO TRASVERSO SINISTRO)`]: `L2 (LEFT TRANSVERSE PROCESS)`,
  [`L3`]: `L3`,
  [`L3 (PROCESSO SPINOSO)`]: `L3 (SPINOUS PROCESS)`,
  [`L3 (PROCESSO TRASVERSO DESTRO)`]: `L3 (RIGHT TRANSVERSE PROCESS)`,
  [`L3 (PROCESSO TRASVERSO SINISTRO)`]: `L3 (LEFT TRANSVERSE PROCESS)`,
  [`L4`]: `L4`,
  [`L4 (PROCESSO SPINOSO)`]: `L4 (SPINOUS PROCESS)`,
  [`L4 (PROCESSO TRASVERSO DESTRO)`]: `L4 (RIGHT TRANSVERSE PROCESS)`,
  [`L4 (PROCESSO TRASVERSO SINISTRO)`]: `L4 (LEFT TRANSVERSE PROCESS)`,
  [`L5`]: `L5`,
  [`L5 (PROCESSO SPINOSO)`]: `L5 (SPINOUS PROCESS)`,
  [`L5 (PROCESSO TRASVERSO DESTRO)`]: `L5 (RIGHT TRANSVERSE PROCESS)`,
  [`L5 (PROCESSO TRASVERSO SINISTRO)`]: `L5 (LEFT TRANSVERSE PROCESS)`,
  [`La mano e' gonfia?`]: `Is the hand swollen?`,
  [`La mano ha perso il suo profilo fisiologico e presenta deformita'?`]: `Has the hand lost its normal shape?`,
  [`La mia salute e' come quella degli altri`]: `I am as healthy as anybody I know`,
  [`La mia vita sessuale è fortemente limitata dal dolore.`]: `My sex life is severely restricted by pain`,
  [`La mia vita sessuale è normale e non mi provoca più dolore del solito`]: `My sex life is normal and causes no extra pain`,
  [`La mia vita sessuale è normale, ma mi provoca più dolore del solito`]: `My sex life is normal but causes some extra pain`,
  [`La mia vita sessuale è quasi inesistente a causa del dolore.`]: `My sex life is nearly absent because of my pain`,
  [`La mia vita sessuale è quasi normale, ma mi provoca molto dolore.`]: `My sex life is nearly normal but is very painful`,
  [`La notte stando a letto`]: `At night while in bed`,
  [`La presenza di un Clicking suggerisce la possibile presenza di una lesione del labbro; la presenza di un eccessivo ROM in ER potrebbe indicare la presenza di lassita' del legamento ileofemorale`]: `A clicking suggest a labial injury; an increased ROM in external rotation suggest laxity of iliofemoral ligament`,
  [`La spalla la lascia dormire serenamente?`]: `2. Does your shoulder allow you to sleep comfortably?`,
  [`La spalla Le permette di svolgere il suo lavoro a tempo pieno?`]: `12. Would your shoulder allow you to work full-time at your usual job?`,
  [`La tua caviglia e' gonfia?`]: `Is your ankle swollen?`,
  [`La tua caviglia ha perso la sua fisiologica forma?`]: `Has your ankle lost its physiologic shape?`,
  [`LABBRO ACETABOLARE DESTRO`]: `RIGHT ACETABULAR LABRUM`,
  [`LABBRO ACETABOLARE SINISTRO`]: `LEFT ACETABULAR LABRUM`,
  [`LABBRO GLENOIDEO DESTRO`]: `RIGHT GLENOID LABRUM`,
  [`LABBRO GLENOIDEO SINISTRO`]: `LEFT GLENOID LABRUM`,
  [`LABBRO INFERIORE`]: `LOWER LIP`,
  [`LABBRO SUPERIORE`]: `UPPER LIP`,
  [`Lacerante`]: `Lacerating`,
  [`Lacerazione`]: `Laceration`,
  [`Lacerazione`]: `Laceration`,
  [`Lachman Test (DX)`]: `Lachman Test (R)`,
  [`Lachman Test (SX)`]: `Lachman Test (L)`,
  [`Lacrosse`]: `Lacrosse`,
  [`LAMBDA`]: `LAMBDA`,
  [`Lancinante`]: `Lancinating`,
  [`Landing Error Scoring System (LESS) (DX)`]: `Landing Error Scoring System (LESS) (R)`,
  [`Landing Error Scoring System (LESS) (SX)`]: `Landing Error Scoring System (LESS) (L)`,
  [`Landmine Rotation`]: `Landmine Rotation`,
  [`Landmine Rotation Eccentric`]: `Landmine Rotation Eccentric`,
  [`LARINGE`]: `LARYNX`,
  [`Lassita'`]: `Laxity`,
  [`Lat Pull Down`]: `Lat Pull Down`,
  [`Lateral Jobe Test (DX)`]: `Lateral Jobe Test (R)`,
  [`Lateral Jobe Test (SX)`]: `Lateral Jobe Test (L)`,
  [`Lateral Lunge Test (DX)`]: `Lateral Lunge Test (R)`,
  [`Lateral Lunge Test (SX)`]: `Lateral Lunge Test (L)`,
  [`Lateral Pivot Shift Test (DX)`]: `Lateral Pivot Shift Test (R)`,
  [`Lateral Pivot Shift Test (SX)`]: `Lateral Pivot Shift Test (L)`,
  [`Lateral Pull Test (DX)`]: `Lateral Pull Test (R)`,
  [`Lateral Pull Test (SX)`]: `Lateral Pull Test (L)`,
  [`Lateral Scapular Slide Test (DX)`]: `Lateral Scapular Slide Test (R)`,
  [`Lateral Scapular Slide Test (SX)`]: `Lateral Scapular Slide Test (L)`,
  [`Lavarsi i capelli?`]: `Whashing your hair?`,
  [`Lavarsi la schiena`]: `Wash your back`,
  [`Lavarsi la schiena?`]: `Whashing your back?`,
  [`Lavarsi o asciugarsi I capelli`]: `Wash or blow dry your air`,
  [`Lavori domestici (pulire, sbrigare le faccende)`]: `Household work (cleaning, maintenance)`,
  [`Lavoro (occupazione o lavoro giornaliero)`]: `Work (your job or usual everyday work)`,
  [`Le mie attività con gli altri sono normali e non mi provocano più dolore del solito`]: `My social life is normal and gives me no extra pain`,
  [`Le mie attività con gli altri sono normali, ma aumentano il livello di dolore`]: `My social life is normal but increases the degree of pain`,
  [`Le seguenti domande riguardano alcune attivita' che potrebbe svolgere nel corso di una qualsiasi giornata. Ci dica, scegliendo una risposta per ogni riga, se attualmnete la sua salute la limita nello svolgimento di queste attivita'`]: `The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?`,
  [`Le seguenti domande riguardano alcune attivita' che potrebbe svolgere nel corso di una qualsiasi giornata. Ci dica, scegliendo una risposta per ogni riga, se attualmnete la sua salute la limita nello svolgimento di queste attivita'.`]: `The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?`,
  [`Le seguenti domande riguardano il livello di dolore percepito a livello della sua anca e/o dell'inguine che ha provato durante la scorsa settimana. Che livello di dolore all'anca e/o all'inguine ha provato durante le seguenti attività?`]: `The following questions concern the amount of pain you have experienced during the past week in your hip and/or groin. What amount of hip and/or groin pain have you experienced during the following activities?`,
  [`Le seguenti domande si riferiscono a come si e' sentito nelle ultime quattro settimane. Risponda a ciascuna domanda scegliendo la risposta che piu' si avvicina al suo caso; Per quanto tempo nelle ultime quattro settimane si e' sentito….`]: `These questions are about how you feel and how things have been with you during the past 4 weeks. For each question, please give the one answer that comes closest to the way you have been feeling.
How much of the time during the past 4 weeks…`,
  [`Le seguenti domande si riferiscono all'impatto del suo problema al braccio, alla spalla o alla mano sul suo lavoro (compreso il lavoro in casa se questa è la sua attività principale)`]: `The following questions ask about the impact of your arm, shoulder or hand problem on your ability to work (including homemaking
if that is your main work role)`,
  [`Le seguenti domande si riferiscono all'impatto del suo problema al braccio, alla spalla o alla mano sul suo lavoro (compreso il lavoro in casa se questa è la sua attività principale). Indichi su ogni riga il numero che meglio descrive la sua capacità ...`]: `The following questions ask about the impact of your arm, shoulder or hand problem on your ability to work (including homemaking
if that is your main work role). Please circle the number that best describes your physical ability in the past week. Did you have any difficulty:`,
  [`Le seguenti domande si riferiscono all'impatto del suo problema al braccio, alla spalla o alla mano sul suo lavoro (compreso il lavoro in casa se questa è la sua attività principale). Indichi su ogni riga il numero che meglio descrive la sua capacità fisica durante l'ultima settimana`]: `The following questions ask about the impact of your arm, shoulder or hand problem on your ability to work (including homemaking if that is your main work role). Please circle the number that best describes your physical ability in the past week. Did you have any difficulty:`,
  [`Le seguenti domande si riferiscono all'impatto del suo problema al braccio, alla spalla o alla mano sulla sua capacità di suonare il suo strumento musicale o praticare il suo sport, o su entrambe le attività.`]: `The following questions relate to the impact of your arm, shoulder or hand problem on playing your musical instrument or sport or both. If you play more than one sport or instrument (or play both), please answer with respect to that activity which is most important to you. Please circle the number that best describes your physical ability in the past week. Did you have any difficulty:`,
  [`Le seguenti domande si riferiscono all'impatto del suo problema al braccio, alla spalla o alla mano sulla sua capacità di suonare il suo strumento musicale o praticare il suo sport, o su entrambe le attività. Se pratica più di uno sport o suona più d...`]: `The following questions relate to the impact of your arm, shoulder or hand problem on playing your musical instrument or sport or both. If you play more than one sport or instrument (or play both), please answer with respect to that activity which is most important to you. Please circle the number that best describes your physical ability in the past week. Did you have any difficulty:`,
  [`Le seguenti domande si riferiscono all'impatto del suo problema al braccio, alla spalla o alla mano sulla sua capacità di suonare il suo strumento musicale o praticare il suo sport, o su entrambe le attività. Se pratica più di uno sport o suona più di uno strumento (o fa entrambe le cose) risponda facendo riferimento all'attività che è più importante per lei. Indichi su ogni riga il numero che meglio descrive la sua capacità fisica durante l'ultima settimana`]: `The following questions relate to the impact of your arm, shoulder or hand problem on playing your musical instrument or sport or both. If you play more than one sport or instrument (or play both), please answer with respect to that activity which is most important to you. Please circle the number that best describes your physical ability in the past week. Did you have any difficulty:`,
  [`Leg Extension`]: `LEG EXTENSION`,
  [`Leg Press`]: `Leg Press`,
  [`Leg Press Calf Raise`]: `Leg Press Calf Raise`,
  [`Leg Swing`]: `Leg Swing`,
  [`LEGAMENTI ALARI DEL DENTE`]: `ALAR LIGAMENTS`,
  [`LEGAMENTI CARPOMETACARPALI DORSALI DI DESTRA`]: `RIGHT DORSAL CARPOMETACARPAL LIGAMENTS`,
  [`LEGAMENTI CARPOMETACARPALI DORSALI DI SINISTRA`]: `LEFT DORSAL CARPOMETACARPAL LIGAMENTS`,
  [`LEGAMENTI CARPOMETACARPALI PALMARI DI DESTRA`]: `RIGHT PALMAR CARPOMETACARPAL LIGAMENTS`,
  [`LEGAMENTI CARPOMETACARPALI PALMARI DI SINISTRA`]: `LEFT PALMAR CARPOMETACARPAL LIGAMENTS`,
  [`LEGAMENTI COLLATERALI INTERFALANGEI DISTALI DELLA MANO DESTRA`]: `COLLATERAL DISTAL INTERPHALANGEAL LIGAMENT OF RIGHT HAND `,
  [`LEGAMENTI COLLATERALI INTERFALANGEI DISTALI DELLA MANO SINISTRA`]: `COLLATERAL DISTAL INTERPHALANGEAL LIGAMENT OF LEFT HAND `,
  [`LEGAMENTI COLLATERALI INTERFALANGEI PROSSIMALI DELLA MANO DESTRA`]: `COLLATERAL PROXIMAL INTERPHALANGEAL LIGAMENT OF RIGHT HAND `,
  [`LEGAMENTI COLLATERALI INTERFALANGEI PROSSIMALI DELLA MANO SINISTRA`]: `COLLATERAL PROXIMAL INTERPHALANGEAL LIGAMENT OF LEFT HAND `,
  [`LEGAMENTI COLLATERALI METACARPOFALANGEI DI DESTRA`]: `RIGHT COLLATERAL METACARPOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI COLLATERALI METACARPOFALANGEI DI SINISTRA`]: `LEFT COLLATERAL METACARPOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI COLLATERALI METATARSOFALANGEI DI DESTRA`]: `RIGHT COLLATERAL METATARSOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI COLLATERALI METATARSOFALANGEI DI SINISTRA`]: `LEFT COLLATERAL METATARSOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI COSTOTRASVERSARI INTEROSSEI DI DESTRA`]: `RIGHT INTEROSSEOUS COSTOTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI COSTOTRASVERSARI INTEROSSEI DI SINISTRA`]: `RIGHT INTEROSSEOUS COSTOTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI COSTOTRASVERSARI LATERALI DI DESTRA`]: `RIGHT LATERAL INTEROSSEOUS COSTOTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI COSTOTRASVERSARI LATERALI DI SINISTRA`]: `RIGHT LATERAL INTEROSSEOUS COSTOTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI COSTOTRASVERSARI SUPERIORI DI DESTRA`]: `RIGHT SUPERIOR INTEROSSEOUS COSTOTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI COSTOTRASVERSARI SUPERIORI DI SINISTRA`]: `RIGHT SUPERIOR INTEROSSEOUS COSTOTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI CUNEOMETATARSALI INTEROSSEI DI DESTRA`]: `RIGHT CUNEOMETATARSAL INTEROSSEOUS LIGAMENTS`,
  [`LEGAMENTI CUNEOMETATARSALI INTEROSSEI DI SINISTRA`]: `LEFT CUNEOMETATARSAL INTEROSSEOUS LIGAMENTS`,
  [`LEGAMENTI CUNEONAVICOLARI DORSALI DI DESTRA`]: `RIGHT DORSAL CUNEONAVICULAR LIGAMENTS`,
  [`LEGAMENTI CUNEONAVICOLARI DORSALI DI SINISTRA`]: `LEFT DORSAL CUNEONAVICULAR LIGAMENTS`,
  [`LEGAMENTI CUNEONAVICOLARI PLANTARI DI DESTRA`]: `RIGHT PLANTAR CUNEONAVICULAR LIGAMENTS`,
  [`LEGAMENTI CUNEONAVICOLARI PLANTARI DI SINISTRA`]: `LEFT PLANTAR CUNEONAVICULAR LIGAMENTS`,
  [`LEGAMENTI DEL TARSO DI DESTRA`]: `RIGHT TARSAL LIGAMENTS`,
  [`LEGAMENTI DEL TARSO DI SINISTRA`]: `LEFT TARSAL LIGAMENTS`,
  [`LEGAMENTI GIALLI`]: `LIGAMENTA FLAVA`,
  [`Legamenti ileolombari (DX)`]: `Ileolumbar ligaments (R)`,
  [`Legamenti ileolombari (SX)`]: `Ileolumbar ligaments (L)`,
  [`LEGAMENTI ILEOLOMBARI DI DESTRA`]: `RIGHT ILIOLUMBAR LIGAMENTS`,
  [`LEGAMENTI ILEOLOMBARI DI SINISTRA`]: `LEFT ILIOLUMBAR LIGAMENTS`,
  [`LEGAMENTI INTERCUNEIFORMI DORSALI DI DESTRA`]: `RIGHT DORSAL INTERCUNEIFORM LIGAMENTS`,
  [`LEGAMENTI INTERCUNEIFORMI DORSALI DI SINISTRA`]: `LEFT DORSAL INTERCUNEIFORM LIGAMENTS`,
  [`LEGAMENTI INTERCUNEIFORMI INTEROSSEI DI DESTRA`]: `RIGHT INTEROSSEOSU INTERCUNEIFORM LIGAMENTS`,
  [`LEGAMENTI INTERCUNEIFORMI INTEROSSEI DI SINISTRA`]: `LEFT INTEROSSEOUS INTERCUNEIFORM LIGAMENTS`,
  [`LEGAMENTI INTERCUNEIFORMI PLANTARI DI DESTRA`]: `RIGHT PLANTAR INTERCUNEIFORM LIGAMENTS`,
  [`LEGAMENTI INTERCUNEIFORMI PLANTARI DI SINISTRA`]: `LEFT PLANTAR INTERCUNEIFORM LIGAMENTS`,
  [`LEGAMENTI INTERMETACARPALI DORSALI DI DESTRA`]: `RIGHT DORSAL INTERMETACARPAL LIGAMENTS`,
  [`LEGAMENTI INTERMETACARPALI DORSALI DI SINISTRA`]: `LEFT DORSAL INTERMETACARPAL LIGAMENTS`,
  [`LEGAMENTI INTERMETACARPALI INTEROSSEI DI DESTRA`]: `RIGHT INTEROSSEOUS INTERMETACARPAL LIGAMENTS`,
  [`LEGAMENTI INTERMETACARPALI INTEROSSEI DI SINISTRA`]: `LEFT INTEROSSEOUS INTERMETACARPAL LIGAMENTS`,
  [`LEGAMENTI INTERMETACARPALI PALMARI DI DESTRA`]: `RIGHT PALMAR INTERMETACARPAL LIGAMENTS`,
  [`LEGAMENTI INTERMETACARPALI PALMARI DI SINISTRA`]: `LEFT PALMAR INTERMETACARPAL LIGAMENTS`,
  [`LEGAMENTI INTERSPINOSI CERVICALI`]: `CERVICAL INTERSPINOUS LIGAMENTS`,
  [`LEGAMENTI INTERSPINOSI LOMBARI`]: `LUMBAR INTERSPINOUS LIGAMENTS`,
  [`LEGAMENTI INTERSPINOSI TORACICI INFERIORI`]: `INFERIOR THORACIC INTERSPINOUS LIGAMENTS`,
  [`LEGAMENTI INTERSPINOSI TORACICI INTERMEDI`]: `INTERMEDIATE THORACIC INTERSPINOUS LIGAMENTS`,
  [`LEGAMENTI INTERSPINOSI TORACICI SUPERIORI`]: `SUPERIOR THORACIC INTERSPINOUS LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI CERVICALI DI DESTRA`]: `RIGHT CERVICAL INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI CERVICALI DI SINISTRA`]: `LEFT CERVICAL INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI LOMBARI DI DESTRA`]: `RIGHT LUMBAR INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI LOMBARI DI SINISTRA`]: `LEFT LUMBAR INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI TORACICI INFERIORI DI DESTRA`]: `RIGHT INFERIOR THORACIC INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI TORACICI INFERIORI DI SINISTRA`]: `LEFT INFERIOR THORACIC INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI TORACICI INTERMEDI DI DESTRA`]: `RIGHT INTERMEDIATE THORACIC INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI TORACICI INTERMEDI DI SINISTRA`]: `LEFT INTERMEDIATE THORACIC INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI TORACICI SUPERIORI DI DESTRA`]: `RIGHT SUPERIOR THORACIC INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTERTRASVERSARI TORACICI SUPERIORI DI SINISTRA`]: `LEFT SUPERIOR THORACIC INTERTRANSVERSE LIGAMENTS`,
  [`LEGAMENTI INTRAARTICOLARI DELLE TESTE DELLE COSTE DI DESTRA`]: `RIGHT INTRAARTICULAR LIGAMENTS OF HEAD OF RIBS`,
  [`LEGAMENTI INTRAARTICOLARI DELLE TESTE DELLE COSTE DI SINISTRA`]: `LEFT INTRAARTICULAR LIGAMENTS OF HEAD OF RIBS`,
  [`LEGAMENTI MEDIOCARPALI DI DESTRA`]: `RIGHT MIDCARPAL LIGAMENTS`,
  [`LEGAMENTI MEDIOCARPALI DI SINISTRA`]: `LEFT MIDCARPAL LIGAMENTS`,
  [`LEGAMENTI METACARPALI TRASVERSI PROFONDI DELLA MANO DESTRA`]: `RIGHT DEEP TRANSVERSE METACARPAL LIGAMENT`,
  [`LEGAMENTI METACARPALI TRASVERSI PROFONDI DELLA MANO SINISTRA`]: `LEFT DEEP TRANSVERSE METACARPAL LIGAMENT`,
  [`LEGAMENTI METACARPALI TRASVERSI SUPERFICIALI DELLA MANO DESTRA`]: `RIGHT SUPERFICIAL TRANSVERSE METACARPAL LIGAMENT`,
  [`LEGAMENTI METACARPALI TRASVERSI SUPERFICIALI DELLA MANO SINISTRA`]: `LEFT SUPERFICIAL TRANSVERSE METACARPAL LIGAMENT`,
  [`LEGAMENTI METATARSALI DORSALI DI DESTRA`]: `RIGHT DORSAL METATARSAL LIGAMENTS`,
  [`LEGAMENTI METATARSALI DORSALI DI SINISTRA`]: `LEFT DORSAL METATARSAL LIGAMENTS`,
  [`LEGAMENTI METATARSALI INTEROSSEI DI DESTRA`]: `RIGHT INTEROSSEOUS METATARSAL LIGAMENTS`,
  [`LEGAMENTI METATARSALI INTEROSSEI DI SINISTRA`]: `LEFT INTEROSSEOUS METATARSAL LIGAMENTS`,
  [`LEGAMENTI METATARSALI PLANTARI DI DESTRA`]: `RIGHT PLANTAR METATARSAL LIGAMENTS`,
  [`LEGAMENTI METATARSALI PLANTARI DI SINISTRA`]: `LEFT PLANTAR METATARSAL LIGAMENTS`,
  [`LEGAMENTI OCCIPITOASSIALI`]: `OCCIPITOAXIAL LIGAMENTS`,
  [`LEGAMENTI OCCIPITOASSIALI LATERALI`]: `LATERAL OCCIPITOAXIAL LIGAMENTS`,
  [`LEGAMENTI OCCIPITOODONTOIDEI`]: `OCCIPITO ODONTAL LIGAMENTS`,
  [`LEGAMENTI PALMARI INTERFALANGEI DISTALI DELLA MANO DESTRA`]: `PALMAR DISTAL INTERPHALANGEAL LIGAMENTS OF RIGHT HAND`,
  [`LEGAMENTI PALMARI INTERFALANGEI DISTALI DELLA MANO SINISTRA`]: `PALMAR DISTAL INTERPHALANGEAL LIGAMENTS OF LEFT HAND`,
  [`LEGAMENTI PALMARI INTERFALANGEI PROSSIMALI DELLA MANO DESTRA`]: `PALMAR PROXIMAL INTERPHALANGEAL LIGAMENTS OF RIGHT HAND`,
  [`LEGAMENTI PALMARI INTERFALANGEI PROSSIMALI DELLA MANO SINISTRA`]: `PALMAR PROXIMAL INTERPHALANGEAL LIGAMENTS OF LEFT HAND`,
  [`LEGAMENTI PALMARI METACARPOFALANGEI DI DESTRA`]: `RIGHT PALMAR METACARPOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI PALMARI METACARPOFALANGEI DI SINISTRA`]: `LEFT PALMAR METACARPOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI PLANTARI METATARSOFALANGEI DI DESTRA`]: `RIGHT PLANTAR METATARSOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI PLANTARI METATARSOFALANGEI DI SINISTRA`]: `LEFT PLANTAR METATARSOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI TARSOMETATARSALI DORSALI DI DESTRA`]: `RIGHT DORSAL METATARSOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI TARSOMETATARSALI DORSALI DI SINISTRA`]: `LEFT DORSAL METATARSOPHALANGEAL LIGAMENTS`,
  [`LEGAMENTI TARSOMETATARSALI PLANTARI DI DESTRA`]: `RIGHT PLANTAR TARSOMETATARSAL LIGAMENTS`,
  [`LEGAMENTI TARSOMETATARSALI PLANTARI DI SINISTRA`]: `LEFT PLANTAR TARSOMETATARSAL LIGAMENTS`,
  [`LEGAMENTI TIROIOIDEI LATERALI`]: `LATERAL THYROID LIGAMENTS`,
  [`LEGAMENTO ACROMIOCLAVICOLARE DESTRO`]: `RIGHT ACROMIOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO ACROMIOCLAVICOLARE SINISTRO`]: `LEFT ACROMIOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO ANOCOCCIGEO`]: `ANOCOCCYGEAL LIGAMENT`,
  [`LEGAMENTO ANTERIORE DELLA TESTA DEL PERONE DESTRO`]: `ANTERIOR LIGAMENT OF RIGHT FIBULAR HEAD`,
  [`LEGAMENTO ANTERIORE DELLA TESTA DEL PERONE SINISTRO`]: `ANTERIOR LIGAMENT OF LEFT FIBULAR HEAD`,
  [`LEGAMENTO ANULARE DEL RADIO DESTRO`]: `ANULAR LIGAMENT OF RIGHT RADIUS`,
  [`LEGAMENTO ANULARE DEL RADIO SINISTRO`]: `ANULAR LIGAMENT OF LEFT RADIUS`,
  [`LEGAMENTO APICALE DEL DENTE`]: `APICAL LIGAMENT OF DENS`,
  [`LEGAMENTO ARCUATO LATERALE DESTRO`]: `RIGHT LATERAL ARCUATE LIGAMENT`,
  [`LEGAMENTO ARCUATO LATERALE SINISTRO`]: `LEFT LATERAL ARCUATE LIGAMENT`,
  [`LEGAMENTO ARCUATO MEDIALE DESTRO`]: `RIGHT MEDIAL ARCUATE LIGAMENT`,
  [`LEGAMENTO ARCUATO MEDIALE SINISTRO`]: `LEFT MEDIAL ARCUATE LIGAMENT`,
  [`LEGAMENTO ARCUATO MEDIANO`]: `MEDIANE ARCUATE LIGAMENT`,
  [`LEGAMENTO BIFORCATO DESTRO`]: `RIGHT BIFURCATE LIGAMENT`,
  [`LEGAMENTO BIFORCATO DESTRO (FASCIO CALCANEOCUBOIDEO)`]: `RIGHT BIFURCATE LIGAMENT (CALCANEOCUBOID LIGAMENT)`,
  [`LEGAMENTO BIFORCATO DESTRO (FASCIO CALCANEONAVICOLARE)`]: `RIGHT BIFURCATE LIGAMENT (CALCANEONAVICULAR LIGAMENT)`,
  [`LEGAMENTO BIFORCATO SINISTRO`]: `LEFT BIFURCATE LIGAMENT`,
  [`LEGAMENTO BIFORCATO SINISTRO (FASCIO CALCANEOCUBOIDEO)`]: `LEFT BIFURCATE LIGAMENT (CALCANEOCUBOID LIGAMENT)`,
  [`LEGAMENTO BIFORCATO SINISTRO (FASCIO CALCANEONAVICOLARE)`]: `LEFT BIFURCATE LIGAMENT (CALCANEONAVICULAR LIGAMENT)`,
  [`LEGAMENTO CALCANEOCUBOIDEO DORSALE DESTRO`]: `RIGHT DORSAL CALCANEOCUBOID LIGAMENT `,
  [`LEGAMENTO CALCANEOCUBOIDEO DORSALE SINISTRO`]: `LEFT DORSAL CALCANEOCUBOID LIGAMENT `,
  [`LEGAMENTO CALCANEOCUBOIDEO PLANTARE DESTRO`]: `RIGHT PLANTAR CALCANEOCUBOID LIGAMENT`,
  [`LEGAMENTO CALCANEOCUBOIDEO PLANTARE SINISTRO`]: `LEFT PLANTAR CALCANEOCUBOID LIGAMENT`,
  [`LEGAMENTO CALCANEONAVICOLARE PLANTARE DESTRO`]: `RIGHT PLANTAR CALCANEONEVICULAR LIGAMENT`,
  [`LEGAMENTO CALCANEONAVICOLARE PLANTARE SINISTRO`]: `LEFT PLANTAR CALCANEONEVICULAR LIGAMENT`,
  [`LEGAMENTO CALCANEONAVICOLARE SINISTRO`]: `LEFT CALCANEONEVICULAR LIGAMENT`,
  [`LEGAMENTO CAPITATOTRAPEZOIDEO DESTRO`]: `RIGHT TRAPEZOIDECAPITATE LIGAMENT`,
  [`LEGAMENTO CAPITATOTRAPEZOIDEO SINISTRO`]: `LEFT TRAPEZOIDECAPITATE LIGAMENT`,
  [`LEGAMENTO CAPITATOUNCINATO DESTRO`]: `RIGHT CAPITATEHAMATE LIGAMENT`,
  [`LEGAMENTO CAPITATOUNCINATO SINISTRO`]: `LEFT CAPITATEHAMATE LIGAMENT`,
  [`LEGAMENTO CARPALE TRASVERSO SUPERFICIALE DESTRO`]: `RIGHT SUPERFICIAL CARPAL TRANSVERSE LIGAMENT`,
  [`LEGAMENTO CARPALE TRASVERSO SUPERFICIALE SINISTRO`]: `LEFT SUPERFICIAL CARPAL TRANSVERSE LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL I METACARPO DESTRO`]: `DORSAL CARPOMETACARPAL LIGAMENT OF RIGHT I METATARSAL`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL I METACARPO SINISTRO`]: `DORSAL CARPOMETACARPAL LIGAMENT OF LEFT I METATARSAL`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL II METACARPO DESTRO`]: `DORSAL CARPOMETACARPAL LIGAMENT OF RIGHT II METATARSAL`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL II METACARPO SINISTRO`]: `DORSAL CARPOMETACARPAL LIGAMENT OF LEFT II METATARSAL`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL III METACARPO DESTRO`]: `RIGHT III DORSAL CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL III METACARPO SINISTRO`]: `LEFT III DORSAL CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL IV METACARPO DESTRO`]: `RIGHT IV DORSAL CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL IV METACARPO SINISTRO`]: `LEFT IV DORSAL CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL V METACARPO DESTRO`]: `RIGHT V DORSAL CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE DORSALE DEL V METACARPO SINISTRO`]: `LEFT V DORSAL CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL I METACARPO DESTRO`]: `PALMAR CARPOMETACARPAL LIGAMENT OF RIGHT I METACARPAL`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL I METACARPO SINISTRO`]: `PALMAR CARPOMETACARPAL LIGAMENT OF LEFT I METACARPAL`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL II METACARPO DESTRO`]: `PALMAR CARPOMETACARPAL LIGAMENT OF RIGHT II METACARPAL`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL II METACARPO SINISTRO`]: `PALMAR CARPOMETACARPAL LIGAMENT OF LEFT II METACARPAL`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL III METACARPO DESTRO`]: `RIGHT III PALMAR CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL III METACARPO SINISTRO`]: `LEFT III PALMAR CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL IV METACARPO DESTRO`]: `RIGHT IV PALMAR CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL IV METACARPO SINISTRO`]: `LEFT IV PALMAR CARPOMETACARPAL LIGAMENT`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL V METACARPO DESTRO`]: `CARPOMETACARPAL LIGAMENT OF RIGHT V METACARPAL`,
  [`LEGAMENTO CARPOMETACARPALE PALMARE DEL V METACARPO SINISTRO`]: `CARPOMETACARPAL LIGAMENT OF LEFT V METACARPAL`,
  [`LEGAMENTO CERATOCRICOIDEO`]: `CERATOCRICOID LIGAMENT`,
  [`LEGAMENTO CERVICALE TRASVERSO (F)`]: `TRANSVERSE CERVICAL LIGAMENT OF UTERUS (F)`,
  [`Legamento collaterale laterale del ginocchio (DX)`]: `Lateral collateral ligament of knee (LCL) (R)`,
  [`Legamento collaterale laterale del ginocchio (SX)`]: `Lateral collateral ligament of knee (LCL) (L)`,
  [`LEGAMENTO COLLATERALE LATERALE DEL GINOCCHIO DESTRO`]: `LATERAL COLLATERAL LIGAMENT OF RIGHT KNEE`,
  [`LEGAMENTO COLLATERALE LATERALE DEL GINOCCHIO SINISTRO`]: `LATERAL COLLATERAL LIGAMENT OF LEFT KNEE`,
  [`LEGAMENTO COLLATERALE LATERALE DELLA CAVIGLIA DESTRA`]: `LATERAL COLLATERAL LIGAMENT OF RIGHT ANKLE`,
  [`LEGAMENTO COLLATERALE LATERALE DELLA CAVIGLIA SINISTRA`]: `LATERAL COLLATERAL LIGAMENT OF LEFT ANKLE`,
  [`Legamento collaterale mediale del ginocchio (DX)`]: `Medial collateral ligament of knee (MCL) (R)`,
  [`Legamento collaterale mediale del ginocchio (SX)`]: `Medial collateral ligament of knee (MCL) (L)`,
  [`LEGAMENTO COLLATERALE MEDIALE DEL GINOCCHIO DESTRO`]: `MEDIAL COLLATERAL LIGAMENT OF RIGHT KNEE`,
  [`LEGAMENTO COLLATERALE MEDIALE DEL GINOCCHIO SINISTRO`]: `MEDIAL COLLATERAL LIGAMENT OF LEFT KNEE`,
  [`LEGAMENTO COLLATERALE MEDIALE DELLA CAVIGLIA DESTRA`]: `MEDIAL COLLATERAL LIGAMENT OF RIGHT ANKLE`,
  [`LEGAMENTO COLLATERALE MEDIALE DELLA CAVIGLIA SINISTRA`]: `MEDIAL COLLATERAL LIGAMENT OF LEFT ANKLE`,
  [`LEGAMENTO COLLATERALE RADIALE DEL GOMITO DESTRO`]: `RIGHT RADIAL COLLATERAL LIGAMENT OF ELBOW`,
  [`LEGAMENTO COLLATERALE RADIALE DEL GOMITO SINISTRO`]: `LEFT RADIAL COLLATERAL LIGAMENT OF ELBOW`,
  [`LEGAMENTO COLLATERALE RADIALE DEL POLSO DESTRO`]: `RADIAL COLLATERAL LIGAMENT OF RIGHT WRIST`,
  [`LEGAMENTO COLLATERALE RADIALE DEL POLSO SINISTRO`]: `RADIAL COLLATERAL LIGAMENT OF LEFT WRIST`,
  [`LEGAMENTO COLLATERALE ULNARE DEL GOMITO DESTRO`]: `RIGHT ULNAR COLLATERAL LIGAMENT OF ELBOW`,
  [`LEGAMENTO COLLATERALE ULNARE DEL GOMITO SINISTRO`]: `LEFT ULNAR COLLATERAL LIGAMENT OF ELBOW`,
  [`LEGAMENTO COLLATERALE ULNARE DEL POLSO DESTRO`]: `ULNAR COLLATERAL LIGAMENT OF RIGHT WRIST`,
  [`LEGAMENTO COLLATERALE ULNARE DEL POLSO SINISTRO`]: `ULNAR COLLATERAL LIGAMENT OF LEFT WRIST`,
  [`LEGAMENTO CONOIDE DESTRO`]: `RIGHT CONOID LIGAMENT`,
  [`LEGAMENTO CONOIDE SINISTRO`]: `LEFT CONOID LIGAMENT`,
  [`LEGAMENTO CORACOACROMIALE DESTRO`]: `RIGHT CORACOACROMIAL LIGAMENT`,
  [`LEGAMENTO CORACOACROMIALE SINISTRO`]: `LEFT CORACOACROMIAL LIGAMENT`,
  [`LEGAMENTO CORACOCLAVICOLARE DESTRO`]: `RIGHT CORACOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO CORACOCLAVICOLARE SINISTRO`]: `LEFT CORACOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO CORACOOMERALE DESTRO`]: `RIGHT CORACOHUMERAL LIGAMENT`,
  [`LEGAMENTO CORACOOMERALE SINISTRO`]: `LEFT CORACOHUMERAL LIGAMENT`,
  [`LEGAMENTO CORONARIO`]: `CORONARY LIGAMENT`,
  [`LEGAMENTO COSTOCLAVICOLARE DESTRO`]: `RIGHT COSTOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO COSTOCLAVICOLARE SINISTRO`]: `LEFT COSTOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO CRICOARITENOIDEO`]: `CRICOARYTENOID LIGAMENT`,
  [`LEGAMENTO CRICOFARINGEO`]: `CRICOPHARYNGEAL LIGAMENT`,
  [`LEGAMENTO CRICOTIROIDEO MEDIANO`]: `MEDIAN CRICOTHYROID LIGAMENT`,
  [`LEGAMENTO CRICOTRACHEALE`]: `CRICOTRACHEAL LIGAMENT`,
  [`LEGAMENTO CROCIATO ANTERIORE DEL GINOCCHIO DESTRO`]: `ANTERIOR CRUCIATE LIGAMENT OF RIGHT KNEE`,
  [`LEGAMENTO CROCIATO ANTERIORE DEL GINOCCHIO SINISTRO`]: `ANTERIOR CRUCIATE LIGAMENT OF LEFT KNEE`,
  [`LEGAMENTO CROCIATO ANTERIORE DESTRO (FASCIO ANTEROMEDIALE)`]: `RIGHT ANTERIOR CRUCIAT LIGAMENT (ANTERIOR MEDIAL BAND)`,
  [`LEGAMENTO CROCIATO ANTERIORE DESTRO (FASCIO POSTEROLATERALE)`]: `RIGHT ANTERIOR CRUCIAT LIGAMENT (POSTERIOR LATERAL BAND)`,
  [`LEGAMENTO CROCIATO ANTERIORE SINISTRO (FASCIO ANTEROMEDIALE)`]: `LEFT ANTERIOR CRUCIAT LIGAMENT (ANTERIOR MEDIAL BAND)`,
  [`LEGAMENTO CROCIATO ANTERIORE SINISTRO (FASCIO POSTEROLATERALE)`]: `LEFT ANTERIOR CRUCIAT LIGAMENT (POSTERIOR LATERAL BAND)`,
  [`LEGAMENTO CROCIATO DELL'ATLANTE`]: `CRUCIATE LIGAMENT OF ATLAS`,
  [`LEGAMENTO CROCIATO DELL'ATLANTE (FASCIO LONGITUDINALE)`]: `CRUCIATE LIGAMENT OF ATLAS`,
  [`LEGAMENTO CROCIATO POSTERIORE DEL GINOCCHIO DESTRO`]: `POSTERIOR CRUCIATE LIGAMENT OF RIGHT KNEE`,
  [`LEGAMENTO CROCIATO POSTERIORE DEL GINOCCHIO SINISTRO`]: `POSTERIOR CRUCIATE LIGAMENT OF LEFT KNEE`,
  [`LEGAMENTO CUBOIDEONAVICOLARE DORSALE DESTRO`]: `RIGHT DORSAL CUBOIDEONAVICUAL LIGAMENT`,
  [`LEGAMENTO CUBOIDEONAVICOLARE DORSALE SINISTRO`]: `LEFT DORSAL CUBOIDEONAVICUAL LIGAMENT`,
  [`LEGAMENTO CUBOIDEONAVICOLARE PLANTARE DESTRO`]: `RIGHT PLANTAR CUBOIDEONAVICUAL LIGAMENT`,
  [`LEGAMENTO CUBOIDEONAVICOLARE PLANTARE SINISTRO`]: `LEFT PLANTAR CUBOIDEONAVICUAL LIGAMENT`,
  [`LEGAMENTO CUNEOCUBOIDEO DORSALE DESTRO`]: `RIGHT DORSAL CUNEOCUBOID LIGAMENT`,
  [`LEGAMENTO CUNEOCUBOIDEO DORSALE SINISTRO`]: `LEFT DORSAL CUNEOCUBOID LIGAMENT`,
  [`LEGAMENTO CUNEOCUBOIDEO INTEROSSEO DESTRO`]: `RIGHT INTEROSSEOUS CUNEOCUBOID LIGAMENT`,
  [`LEGAMENTO CUNEOCUBOIDEO INTEROSSEO SINISTRO`]: `LEFT INTEROSSEOUS CUNEOCUBOID LIGAMENT`,
  [`LEGAMENTO CUNEOCUBOIDEO PLANTARE DESTRO`]: `RIGHT PLANTAR CUNEOCUBOID LIGAMENT`,
  [`LEGAMENTO CUNEOCUBOIDEO PLANTARE SINISTRO`]: `LEFT PLANTAR CUNEOCUBOID LIGAMENT`,
  [`LEGAMENTO DELL'OVAIO DESTRO (F)`]: `LIGAMENT OF RIGHT OVARY (F)`,
  [`LEGAMENTO DELL'OVAIO SINISTRO (F)`]: `LIGAMENT OF LEFT OVARY (F)`,
  [`LEGAMENTO DELLA TESTA DEL FEMORE DESTRO`]: `RIGHT LIGAMENT OF HEAD OF FEMUR`,
  [`LEGAMENTO DELLA TESTA DEL FEMORE SINISTRO`]: `LEFT LIGAMENT OF HEAD OF FEMUR`,
  [`Legamento deltoideo (DX)`]: `Deltoid ligament (R)`,
  [`Legamento deltoideo (SX)`]: `Deltoid ligament (L)`,
  [`LEGAMENTO DELTOIDEO DESTRO`]: `RIGHT DELTOID LIGAMENT`,
  [`LEGAMENTO DELTOIDEO SINISTRO`]: `LEFT DELTOID LIGAMENT`,
  [`LEGAMENTO DI STRUTHERS DESTRO`]: `RIGHT STRHUTERS LIGAMENT`,
  [`LEGAMENTO DI STRUTHERS SINISTRO`]: `LEFT STRHUTERS LIGAMENT`,
  [`LEGAMENTO EPATODUODENALE`]: `HEPATODUODENAL LIGAMENT`,
  [`LEGAMENTO EPATOESOFAGEO`]: `HEPATOOESOPHAGEAL LIGAMENT`,
  [`LEGAMENTO EPATOFRENICO`]: `HEPATOPHRENIC LIGAMENT`,
  [`LEGAMENTO EPATOGASTRICO`]: `HEPATOGASTRIC LIGAMENT`,
  [`LEGAMENTO EPATORENALE`]: `HEPATORENAL LIGAMENT`,
  [`LEGAMENTO FALCIFORME`]: `FALCIFORM LIGAMENT`,
  [`LEGAMENTO FRENICOCOLICO`]: `PHRENICOCOLIC LIGAMENT`,
  [`LEGAMENTO FRENICOESOFAGEO`]: `PHRENICOOESOPHAGEAL LIGAMENT`,
  [`LEGAMENTO FRENICOSPLENICO`]: `PHRENICOSPLENIC LIGAMENT`,
  [`LEGAMENTO FUNDIFORME DEL CLITORIDE (F)`]: `FUNDIFORM LIGAMENT OF CLITORIS (F)`,
  [`LEGAMENTO GASTROFRENICO`]: `GASTROPHRENIC LIGAMENT`,
  [`LEGAMENTO GASTROSPLENICO`]: `GASTROSPLENIC LIGAMENT`,
  [`LEGAMENTO GLENOOMERALE INFERIORE DESTRO`]: `RIGHT INFERIOR GLENOHUMERAL LIGAMENT`,
  [`LEGAMENTO GLENOOMERALE INFERIORE SINISTRO`]: `LEFT INFERIOR GLENOHUMERAL LIGAMENT`,
  [`LEGAMENTO GLENOOMERALE MEDIO DESTRO`]: `RIGHT INTERMEDIATE GLENOHUMERAL LIGAMENT`,
  [`LEGAMENTO GLENOOMERALE MEDIO SINISTRO`]: `LEFT INTERMEDIATE GLENOHUMERAL LIGAMENT`,
  [`LEGAMENTO GLENOOMERALE SUPERIORE DESTRO`]: `RIGHT SUPERIOR GLENOHUMERAL LIGAMENT`,
  [`LEGAMENTO GLENOOMERALE SUPERIORE SINISTRO`]: `LEFT SUPERIOR GLENOHUMERAL LIGAMENT`,
  [`LEGAMENTO ILEOFEMORALE DESTRO`]: `RIGHT ILIOFEMORAL LIGAMENT `,
  [`LEGAMENTO ILEOFEMORALE SINISTRO`]: `LEFT ILIOFEMORAL LIGAMENT `,
  [`LEGAMENTO ILEOPETTINEO DI COOPER DESTRO`]: `RIGHT ILEOPECTINEAL LIGAMENT (COOPER LIGAMENT)`,
  [`LEGAMENTO ILEOPETTINEO DI COOPER SINISTRO`]: `LEFT ILEOPECTINEAL LIGAMENT (COOPER LIGAMENT)`,
  [`Legamento Inguinale (DX)`]: `Inguinal ligament (R)`,
  [`Legamento inguinale (SX)`]: `Inguinal ligament (L)`,
  [`LEGAMENTO INGUINALE DESTRO`]: `RIGHT INGUINAL LIGAMENT`,
  [`LEGAMENTO INGUINALE RIFLESSO DESTRO`]: `RIGHT INGUINAL REFLECTED LIGAMENT`,
  [`LEGAMENTO INGUINALE RIFLESSO SINISTRO`]: `LEFT INGUINAL REFLECTED LIGAMENT`,
  [`LEGAMENTO INGUINALE SINISTRO`]: `LEFT INGUINAL LIGAMENT`,
  [`LEGAMENTO INTERCARPALE DORSALE DESTRO`]: `RIGHT DORSAL INTERCARPAL LIGAMENTS`,
  [`LEGAMENTO INTERCARPALE DORSALE SINISTRO`]: `LEFT DORSAL INTERCARPAL LIGAMENTS`,
  [`LEGAMENTO INTERCARPALE INTEROSSEO DESTRO`]: `RIGHT INTEROSSEOUS INTERCARPAL LIGAMENT`,
  [`LEGAMENTO INTERCARPALE INTEROSSEO SINISTRO`]: `LEFT INTEROSSEOUS INTERCARPAL LIGAMENT`,
  [`LEGAMENTO INTERCARPALE PALMARE DESTRO`]: `RIGHT PALMAR INTERCARPAL LIGAMENT`,
  [`LEGAMENTO INTERCARPALE PALMARE SINISTRO`]: `LEFT PALMAR INTERCARPAL LIGAMENT`,
  [`LEGAMENTO INTERCLAVICOLARE`]: `INTERCLAVICULAR LIGAMENT`,
  [`LEGAMENTO IOEPIGLOTTICO`]: `HYOEPIGLOTTIC LIGAMENT`,
  [`LEGAMENTO ISCHIOFEMORALE DESTRO`]: `RIGHT ISCHIOFEMORAL LIGAMENT`,
  [`LEGAMENTO ISCHIOFEMORALE SINISTRO`]: `LEFT ISCHIOFEMORAL LIGAMENT`,
  [`LEGAMENTO LACUNARE DESTRO`]: `RIGHT LACUNAR LIGAMENT`,
  [`LEGAMENTO LACUNARE SINISTRO`]: `LEFT LACUNAR LIGAMENT`,
  [`LEGAMENTO LARGO DELL'UTERO (F)`]: `BROAD LIGAMENT OF UTERUS (F)`,
  [`LEGAMENTO LATERALE DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE DESTRA`]: `LATERAL LIGAMENT OF RIGHT TEMPOROMANDIBULAR JOINT`,
  [`LEGAMENTO LATERALE DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE SINISTRA`]: `LATERAL LIGAMENT OF LEFT TEMPOROMANDIBULAR JOINT`,
  [`LEGAMENTO LOMBOCOSTALE DI HENLE DESTRO`]: `RIGHT LUMBOCOSTAL LIGAMENT (HENLE LIGAMENT)`,
  [`LEGAMENTO LOMBOCOSTALE DI HENLE SINISTRO`]: `LEFT LUMBOCOSTAL LIGAMENT (HENLE LIGAMENT)`,
  [`LEGAMENTO LONGITUDINALE ANTERIORE`]: `ANTERIOR LONGITUDINAL LIGAMENT`,
  [`LEGAMENTO LONGITUDINALE POSTERIORE`]: `POSTERIOR LONGITUDINAL LIGAMENT`,
  [`LEGAMENTO LUNOPIRAMIDALE DORSALE DESTRO`]: `RIGHT DORSAL LUNOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO LUNOPIRAMIDALE DORSALE SINISTRO`]: `LEFT DORSAL LUNOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO LUNOPIRAMIDALE INTEROSSEO DESTRO`]: `RIGHT INTEROSSEOUS LUNOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO LUNOPIRAMIDALE INTEROSSEO SINISTRO`]: `LEFT INTEROSSEOUS LUNOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO LUNOPIRAMIDALE PALMARE DESTRO`]: `RIGHT PALMAR LUNOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO LUNOPIRAMIDALE PALMARE SINISTRO`]: `LEFT PALMAR LUNOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO MEDIALE DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE DESTRA`]: `MEDIAL LIGAMENT OF RIGHT TEMPOROMANDIBULAR JOINT`,
  [`LEGAMENTO MEDIALE DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE SINISTRA`]: `MEDIAL LIGAMENT OF LEFT TEMPOROMANDIBULAR JOINT`,
  [`LEGAMENTO MENISCOFEMORALE ANTERIORE DESTRO`]: `RIGHT ANTERIOR MENISCOFEMORAL LIGAMENT`,
  [`LEGAMENTO MENISCOFEMORALE ANTERIORE SINISTRO`]: `LEFT ANTERIOR MENISCOFEMORAL LIGAMENT`,
  [`LEGAMENTO MENISCOFEMORALE POSTERIORE DESTRO`]: `RIGHT POSTERIOR MENISCOFEMORAL LIGAMENT`,
  [`LEGAMENTO MENISCOFEMORALE POSTERIORE SINISTRO`]: `LEFT POSTERIOR MENISCOFEMORAL LIGAMENT`,
  [`LEGAMENTO METACARPALE TRASVERSO PROFONDO DESTRO`]: `RIGHT DEEP TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METACARPALE TRASVERSO PROFONDO SINISTRO`]: `LEFT DEEP TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METACARPALE TRASVERSO SUPERFICIALE DESTRO`]: `RIGHT SUPERFICIAL TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METACARPALE TRASVERSO SUPERFICIALE SINISTRO`]: `LEFT SUPERFICIAL TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METATARSALE TRASVERSO PROFONDO DESTRO`]: `RIGHT DEEP TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METATARSALE TRASVERSO PROFONDO SINISTRO`]: `LEFT DEEP TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METATARSALE TRASVERSO SUPERFICIALE DESTRO`]: `RIGHT SUPERFICIAL TRANSVERSE METATARSAL LIGAMENT`,
  [`LEGAMENTO METATARSALE TRASVERSO SUPERFICIALE SINISTRO`]: `LEFT SUPERFICIAL TRANSVERSE METATARSAL LIGAMENT`,
  [`Legamento nucale`]: `Nuchal ligament`,
  [`LEGAMENTO NUCALE`]: `NUCHAL LIGAMENT`,
  [`LEGAMENTO OCCIPITOATLANTOIDEO LATERALE DESTRO`]: `RIGHT LATERAL ATLANTOOCCIPITAL LIGAMENT`,
  [`LEGAMENTO OCCIPITOATLANTOIDEO LATERALE SINISTRO`]: `LEFT LATERAL ATLANTOOCCIPITAL LIGAMENT`,
  [`LEGAMENTO OMBELICALE MEDIANO`]: `MEDIANE UMBILICAL LIGAMENT`,
  [`LEGAMENTO OMERALE TRASVERSO DESTRO`]: `RIGHT TRANSVERSE HUMERAL LIGAMENT`,
  [`LEGAMENTO OMERALE TRASVERSO SINISTRO`]: `LEFT TRANSVERSE HUMERAL LIGAMENT`,
  [`LEGAMENTO PANCREATICOCOLICO`]: `PANCREATICOCOLIC LIGAMENT`,
  [`LEGAMENTO PANCREATICOSPLENICO`]: `PANCREATICOSPLENIC LIGAMENT`,
  [`Legamento peroneoastragalico anteriore (DX)`]: `Anterior TaloFibular Ligament (ATFL) (R)`,
  [`Legamento peroneoastragalico anteriore (SX)`]: `Anterior TaloFibular Ligament (ATFL) (L)`,
  [`LEGAMENTO PERONEOASTRAGALICO ANTERIORE DESTRO`]: `RIGHT ANTERIOR TALOFIBULAR LIGAMENT`,
  [`LEGAMENTO PERONEOASTRAGALICO ANTERIORE SINISTRO`]: `LEFT ANTERIOR TALOFIBULAR LIGAMENT`,
  [`Legamento peroneoastragalico posteriore (DX)`]: `Posterior TaloFibular Ligament (PTFL) (R)`,
  [`Legamento peroneoastragalico posteriore (SX)`]: `Posterior TaloFibular Ligament (PTFL) (L)`,
  [`LEGAMENTO PERONEOASTRAGALICO POSTERIORE DESTRO`]: `RIGHT POSTERIOR TALOFIBULAR LIGAMENT`,
  [`LEGAMENTO PERONEOASTRAGALICO POSTERIORE SINISTRO`]: `LEFT POSTERIOR TALOFIBULAR LIGAMENT`,
  [`Legamento peroneocalcaneale (DX)`]: `Calcaneofibular ligament (R)`,
  [`Legamento peroneocalcaneale (SX)`]: `Calcaneofibular ligament (L)`,
  [`LEGAMENTO PERONEOCALCANEALE DESTRO`]: `RIGHT CALCANEOFIBULAR LIGAMENT`,
  [`LEGAMENTO PERONEOCALCANEALE SINISTRO`]: `LEFT CALCANEOFIBULAR LIGAMENT`,
  [`LEGAMENTO PIRAMIDALEUNCINATO DESTRO`]: `RIGHT TRIQUETROHAMATE LIGAMENT`,
  [`LEGAMENTO PIRAMIDALEUNCINATO SINISTRO`]: `LEFT TRIQUETROHAMATE LIGAMENT`,
  [`LEGAMENTO PISOMETACARPALE DESTRO`]: `RIGHT PISOMETACARPAL LIGAMENT`,
  [`LEGAMENTO PISOMETACARPALE SINISTRO`]: `LEFT PISOMETACARPAL LIGAMENT`,
  [`LEGAMENTO PISOPIRAMIDALE DESTRO`]: `RIGHT PISOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO PISOPIRAMIDALE SINISTRO`]: `LEFT PISOTRIQUETRAL LIGAMENT`,
  [`LEGAMENTO PISOUNCINATO DESTRO`]: `RIGHT PISOHAMATE LIGAMENT`,
  [`LEGAMENTO PISOUNCINATO SINISTRO`]: `LEFT PISOHAMATE LIGAMENT`,
  [`LEGAMENTO PLANTARE LUNGO DESTRO`]: `RIGHT LONG PLANTAR LIGAMENT`,
  [`LEGAMENTO PLANTARE LUNGO SINISTRO`]: `LEFT LONG PLANTAR LIGAMENT`,
  [`LEGAMENTO POLMONARE DESTRO`]: `RIGHT PULMONARY LIGAMENT`,
  [`LEGAMENTO POLMONARE SINISTRO`]: `LEFT PULMUNARY LIGAMENT`,
  [`LEGAMENTO POPLITEO ARCUATO DESTRO`]: `RIGHT ARCUATE POPLITEAL LIGAMENT`,
  [`LEGAMENTO POPLITEO ARCUATO SINISTRO`]: `LEFT ARCUATE POPLITEAL LIGAMENT`,
  [`LEGAMENTO POPLITEO OBLIQUO DESTRO`]: `RIGHT OBLIQUE POPLITEAL LIGAMENT`,
  [`LEGAMENTO POPLITEO OBLIQUO SINISTRO`]: `LEFT OBLIQUE POPLITEAL LIGAMENT`,
  [`LEGAMENTO POSTERIORE DELLA TESTA DEL PERONE DESTRO`]: `POSTERIOR LIGAMENT OF RIGHT FIBULAR HEAD`,
  [`LEGAMENTO POSTERIORE DELLA TESTA DEL PERONE SINISTRO`]: `POSTERIOR LIGAMENT OF LEFT FIBULAR HEAD`,
  [`LEGAMENTO PTERIGOSPINOSO DESTRO`]: `RIGHT PTERYGOSPINOUS LIGAMENT`,
  [`LEGAMENTO PTERIGOSPINOSO SINISTRO`]: `LEFT PTERYGOSPINOUS LIGAMENT`,
  [`LEGAMENTO PUBICO ANTERIORE`]: `ANTERIOR PUBIC LIGAMENT`,
  [`LEGAMENTO PUBICO INFERIORE`]: `INFERIOR PUBIC LIGAMENT`,
  [`LEGAMENTO PUBICO POSTERIORE`]: `POSTERIOR PUBIC LIGAMENT`,
  [`LEGAMENTO PUBICO SUPERIORE`]: `SUPERIOR PUBIC LIGAMENT`,
  [`LEGAMENTO PUBOCERVICALE (F)`]: `PUBOCERVICAL LIGAMENT (F)`,
  [`LEGAMENTO PUBOFEMORALE DESTRO`]: `RIGHT PUBOFEMORAL LIGAMENT`,
  [`LEGAMENTO PUBOFEMORALE SINISTRO`]: `LEFT PUBOFEMORAL LIGAMENT`,
  [`LEGAMENTO PUBOPROSTATICO DESTRO (M)`]: `RIGHT PUBOPROSTATIC LIGAMENT (M)`,
  [`LEGAMENTO PUBOPROSTATICO SINISTRO (M)`]: `LEFT PUBOPROSTATIC LIGAMENT (M)`,
  [`LEGAMENTO PUBOVESCICALE DESTRO`]: `RIGHT PUBOVESICAL LIGAMENT`,
  [`LEGAMENTO PUBOVESCICALE LATERALE DESTRO (F)`]: `RIGHT LATERAL PUBOVESICAL LIGAMENT (F)`,
  [`LEGAMENTO PUBOVESCICALE LATERALE SINISTRO (F)`]: `LEFT LATERAL PUBOVESICAL LIGAMENT (F)`,
  [`LEGAMENTO PUBOVESCICALE SINISTRO`]: `LEFT PUBOVESICAL LIGAMENT`,
  [`LEGAMENTO QUADRATO DESTRO`]: `RIGHT QUADRATE LIGAMENT`,
  [`LEGAMENTO QUADRATO SINISTRO`]: `LEFT QUADRATE LIGAMENT`,
  [`LEGAMENTO RADIOCARPALE DORSALE DESTRO`]: `RIGHT DORSAL RADIOCARPAL LIGAMENT`,
  [`LEGAMENTO RADIOCARPALE DORSALE SINISTRO`]: `LEFT DORSAL RADIOCARPAL LIGAMENT`,
  [`LEGAMENTO RADIOCARPALE PALMARE DESTRO`]: `RIGHT PALMAR RADIOCARPAL LIGAMENT`,
  [`LEGAMENTO RADIOCARPALE PALMARE SINISTRO`]: `LEFT PALMAR RADIOCARPAL LIGAMENT`,
  [`LEGAMENTO RAGGIATO DEL CARPO DESTRO`]: `RIGHT RADIATE CARPAL LIGAMENT`,
  [`LEGAMENTO RAGGIATO DEL CARPO SINISTRO`]: `LEFT RADIATE CARPAL LIGAMENT`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA I COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT I RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA I COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT I RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA II COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT II RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA II COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT II RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA III COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT III RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA III COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT III RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA IV COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT IV RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA IV COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT IV RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA IX COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT IX RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA IX COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT IX RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA V COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT V RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA V COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT V RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA VI COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT VI RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA VI COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT VI RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA VII COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT VII RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA VII COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT VII RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA VIII COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT VIII RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA VIII COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT VIII RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA X COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT X RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA X COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT X RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA XI COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT XI RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA XI COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT XI RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA XII COSTA DESTRA`]: `RADIATE LIGAMENT OF HEAD OF RIGHT XII RIB`,
  [`LEGAMENTO RAGGIATO DELLA TESTA DELLA XII COSTA SINISTRA`]: `RADIATE LIGAMENT OF HEAD OF LEFT XII RIB`,
  [`LEGAMENTO RETTOUTERINO (F)`]: `RECTOUTERINE LIGAMENT (F)`,
  [`LEGAMENTO ROTONDO DEL FEGATO`]: `ROUND LIGAMENT OF THE LIVER`,
  [`LEGAMENTO ROTONDO DELL'UTERO (F)`]: `ROUND LIGAMENT OF UTERUS (F)`,
  [`LEGAMENTO SACROCOCCIGEO ANTERIORE`]: `ANTERIOR SACROCOCCYGEAL LIGAMENT`,
  [`LEGAMENTO SACROCOCCIGEO LATERALE DESTRO`]: `RIGHT LATERAL SACROCOCCYGEAL LIGAMENT`,
  [`LEGAMENTO SACROCOCCIGEO LATERALE SINISTRO`]: `LEFT LATERAL SACROCOCCYGEAL LIGAMENT`,
  [`LEGAMENTO SACROCOCCIGEO POSTERIORE PROFONDO`]: `DEEP POSTERIOR SACROCOCCYGEAL LIGAMENT`,
  [`LEGAMENTO SACROCOCCIGEO POSTERIORE SUPERFICIALE`]: `SUPERFICIAL POSTERIOR SACROCOCCYGEAL LIGAMENT`,
  [`LEGAMENTO SACROILIACO ANTERIORE DESTRO`]: `RIGHT ANTERIOR SACROILIAC LIGAMENT`,
  [`LEGAMENTO SACROILIACO ANTERIORE SINISTRO`]: `LEFT ANTERIOR SACROILIAC LIGAMENT`,
  [`LEGAMENTO SACROILIACO INTEROSSEO DESTRO`]: `RIGHT INTEROSSEOUS SACROILIAC LIGAMENT`,
  [`LEGAMENTO SACROILIACO INTEROSSEO SINISTRO`]: `LEFT INTEROSSEOUS SACROILIAC LIGAMENT`,
  [`LEGAMENTO SACROILIACO POSTERIORE DESTRO`]: `RIGHT POSTERIOR SACROILIAC LIGAMENTS`,
  [`LEGAMENTO SACROILIACO POSTERIORE SINISTRO`]: `LEFT POSTERIOR SACROILIAC LIGAMENTS`,
  [`Legamento sacrospinoso (DX)`]: `Sacrospinous ligament (R)`,
  [`Legamento sacrospinoso (SX)`]: `Sacrospinous ligament (L)`,
  [`LEGAMENTO SACROSPINOSO DESTRO`]: `RIGHT SACROSPINOUS LIGAMENT`,
  [`LEGAMENTO SACROSPINOSO SINISTRO`]: `LEFT SACROSPINOUS LIGAMENT`,
  [`Legamento sacrotuberoso (DX)`]: `Sacrotuberous ligament (R)`,
  [`Legamento sacrotuberoso (SX)`]: `Sacrotuberous ligament (L)`,
  [`LEGAMENTO SACROTUBEROSO DESTRO`]: `RIGHT SACROTUBEROUS LIGAMENT`,
  [`LEGAMENTO SACROTUBEROSO SINISTRO`]: `LEFT SACROTUBEROUS LIGAMENT`,
  [`LEGAMENTO SCAFOCAPITATO DESTRO`]: `RIGHT SCAPHOCAPITATE LIGAMENT`,
  [`LEGAMENTO SCAFOCAPITATO SINISTRO`]: `LEFT SCAPHOCAPITATE LIGAMENT`,
  [`LEGAMENTO SCAFOLUNATO DORSALE DESTRO`]: `RIGHT DORSAL SCAPHOLUNATE LIGAMENT`,
  [`LEGAMENTO SCAFOLUNATO DORSALE SINISTRO`]: `LEFT DORSAL SCAPHOLUNATE LIGAMENT`,
  [`LEGAMENTO SCAFOLUNATO INTEROSSEO DESTRO`]: `RIGHT INTEROSSEOUS SCAPHOLUNATE LIGAMENT`,
  [`LEGAMENTO SCAFOLUNATO INTEROSSEO SINISTRO`]: `LEFT INTEROSSEOUS SCAPHOLUNATE LIGAMENT`,
  [`LEGAMENTO SCAFOLUNATO PALMARE DESTRO`]: `RIGHT PALMAR SCAPHOLUNATE LIGAMENT`,
  [`LEGAMENTO SCAFOLUNATO PALMARE SINISTRO`]: `LEFT PALMAR SCAPHOLUNATE LIGAMENT`,
  [`LEGAMENTO SCAFOTRAPEZIOTRAPEZOIDEO DESTRO`]: `RIGHT SCAPHOTRAPEZIOTRAPEZOID LIGAMENT`,
  [`LEGAMENTO SCAFOTRAPEZIOTRAPEZOIDEO SINISTRO`]: `LEFT SCAPHOTRAPEZIOTRAPEZOID LIGAMENT`,
  [`LEGAMENTO SCAPOLARE TRASVERSO SUPERIORE DESTRO`]: `RIGHT SUPERIOR TRANSVERSE SCAPULAR LIGAMENT`,
  [`LEGAMENTO SCAPOLARE TRASVERSO SUPERIORE SINISTRO`]: `LEFT SUPERIOR TRANSVERSE SCAPULAR LIGAMENT`,
  [`LEGAMENTO SFENOMANDIBOLARE DESTRO`]: `RIGHT SPHENOMANDIBULAR LIGAMENT`,
  [`LEGAMENTO SFENOMANDIBOLARE SINISTRO`]: `LEFT SPHENOMANDIBULAR LIGAMENT`,
  [`LEGAMENTO SOSPENSORE DEL CLITORIDE (F)`]: `SUSPENSORY LIGAMENT OF CLITORIS (F)`,
  [`LEGAMENTO SOSPENSORE DEL PENE (M)`]: `SUSPENSORY LIGAMENT OF PENIS (M)`,
  [`LEGAMENTO SOSPENSORE DELL'ASCELLA DESTRO`]: `RIGHT SUSPENSORY LIGAMENT OF AXILLA`,
  [`LEGAMENTO SOSPENSORE DELL'ASCELLA SINISTRO`]: `LEFT SUSPENSORY LIGAMENT OF AXILLA`,
  [`LEGAMENTO SOSPENSORE DELL'OVAIO DESTRO (F)`]: `SUSPENSORY LIGAMENT OF RIGHT OVARY (F)`,
  [`LEGAMENTO SOSPENSORE DELL'OVAIO SINISTRO (F)`]: `SUSPENSORY LIGAMENT OF LEFT OVARY (F)`,
  [`LEGAMENTO SOSPENSORE DELLA GHIANDOLA TIROIDEA`]: `SUSPENSORY LIGAMENT OF THYROID GLAND`,
  [`LEGAMENTO SPLENOCOLICO`]: `SPLENOCOLIC LIGAMENT`,
  [`LEGAMENTO SPLENORENALE`]: `SPLENORENAL LIGAMENT`,
  [`LEGAMENTO STERNOCLAVICOLARE ANTERIORE DESTRO`]: `RIGHT ANTERIOR STERNOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO STERNOCLAVICOLARE ANTERIORE SINISTRO`]: `LEFT ANTERIOR STERNOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO STERNOCLAVICOLARE POSTERIORE DESTRO`]: `RIGHT POSTERIOR STERNOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO STERNOCLAVICOLARE POSTERIORE SINISTRO`]: `LEFT POSTERIOR STERNOCLAVICULAR LIGAMENT`,
  [`LEGAMENTO STERNOPERICARDICO`]: `STERNOPERICARDIAL LIGAMENT`,
  [`LEGAMENTO STILOIOIDEO DESTRO`]: `RIGHT STYLOID LIGAMENT`,
  [`LEGAMENTO STILOIOIDEO SINISTRO`]: `LEFT STYLOID LIGAMENT`,
  [`LEGAMENTO STILOMANDIBOLARE DESTRO`]: `RIGHT STYLOMANDIBULAR LIGAMENT`,
  [`LEGAMENTO STILOMANDIBOLARE SINISTRO`]: `LEFT STYLOMANDIBULAR LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE INTEROSSEO DESTRO`]: `RIGHT INTEROSSEOUS TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE INTEROSSEO SINISTRO`]: `LEFT INTEROSSEOUS TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE LATERALE DESTRO`]: `RIGHT LATERAL TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE LATERALE SINISTRO`]: `LEFT LATERAL TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE MEDIALE DESTRO`]: `RIGHT MEDIAL TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE MEDIALE SINISTRO`]: `LEFT MEDIAL TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE POSTERIORE DESTRO`]: `RIGHT POSTERIOR TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALOCALCANEALE POSTERIORE SINISTRO`]: `LEFT POSTERIOR TALOCALCANEAL LIGAMENT`,
  [`LEGAMENTO TALONAVICOLARE DORSALE DESTRO`]: `RIGHT DORSAL TALONAVICULAR LIGAMENT`,
  [`LEGAMENTO TALONAVICOLARE DORSALE SINISTRO`]: `LEFT DORSAL TALONAVICULAR LIGAMENT`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL I METATARSO DESTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF RIGHT I METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL I METATARSO SINISTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF LEFT I METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL II METATARSO DESTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF RIGHT II METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL II METATARSO SINISTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF LEFT II METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL III METATARSO DESTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF RIGHT III METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL III METATARSO SINISTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF LEFT III METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL IV METATARSO DESTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF RIGHT IV METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL IV METATARSO SINISTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF LEFT IV METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL V METATARSO DESTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF RIGHT V METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE DORSALE DEL V METATARSO SINISTRO`]: `DORSAL TARSOMETATARSAL LIGAMENT OF LEFT V METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL I METATARSO DESTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF RIGHT I METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL I METATARSO SINISTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF LEFT I METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL II METATARSO DESTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF RIGHT II METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL II METATARSO SINISTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF LEFT II METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL III METATARSO DESTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF RIGHT III METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL III METATARSO SINISTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF LEFT III METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL IV METATARSO DESTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF RIGHT IV METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL IV METATARSO SINISTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF LEFT IV METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL V METATARSO DESTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF RIGHT V METATARSAL`,
  [`LEGAMENTO TARSOMETATARSALE PLANTARE DEL V METATARSO SINISTRO`]: `PLANTAR TARSOMETATARSAL LIGAMENT OF LEFT V METATARSAL`,
  [`LEGAMENTO TIBIOASTRAGALICO ANTERIORE DESTRO`]: `RIGHT ANTERIOR TIBIOTALAR LIGAMENT`,
  [`LEGAMENTO TIBIOASTRAGALICO ANTERIORE SINISTRO`]: `LEFT ANTERIOR TIBIOTALAR LIGAMENT`,
  [`LEGAMENTO TIBIOASTRAGALICO POSTERIORE DESTRO`]: `RIGHT POSTERIOR TIBIOTALAR LIGAMENT`,
  [`LEGAMENTO TIBIOASTRAGALICO POSTERIORE SINISTRO`]: `LEFT POSTERIOR TIBIOTALAR LIGAMENT`,
  [`LEGAMENTO TIBIOCALCANEALE DESTRO`]: `RIGHT DELTOID LIGAMENT (TIBIOCALCANEAL PART)`,
  [`LEGAMENTO TIBIOCALCANEALE SINISTRO`]: `LEFT DELTOID LIGAMENT (TIBIOCALCANEAL PART)`,
  [`LEGAMENTO TIBIONAVICOLARE DESTRO`]: `RIGHT DELTOID LIGAMENT (TIBIONAVICULAR PART)`,
  [`LEGAMENTO TIBIONAVICOLARE SINISTRO`]: `LEFT DELTOID LIGAMENT (TIBIONAVICULAR PART)`,
  [`LEGAMENTO TIBIOPERONEALE ANTERIORE DESTRO`]: `RIGHT ANTERIOR TIBIOFIBULAR LIGAMENT`,
  [`LEGAMENTO TIBIOPERONEALE ANTERIORE SINISTRO`]: `LEFT ANTERIOR TIBIOFIBULAR LIGAMENT`,
  [`LEGAMENTO TIBIOPERONEALE POSTERIORE DESTRO`]: `RIGHT POSTERIOR TIBIOFIBULAR LIGAMENT`,
  [`LEGAMENTO TIBIOPERONEALE POSTERIORE SINISTRO`]: `LEFT POSTERIOR TIBIOFIBULAR LIGAMENT`,
  [`LEGAMENTO TIROEPIGLOTTICO`]: `THYROEPIGLOTTIC LIGAMENT`,
  [`LEGAMENTO TIROIOIDEO MEDIANO`]: `MEDIANE THYROID LIGAMENT`,
  [`LEGAMENTO TRAPEZOIDE DESTRO`]: `RIGHT TRAPEZOID LIGAMENT`,
  [`LEGAMENTO TRAPEZOIDE SINISTRO`]: `LEFT TRAPEZOID LIGAMENT`,
  [`LEGAMENTO TRASVERSO DEL GINOCCHIO DESTRO`]: `TRANSVERSE LIGAMENT OF RIGHT KNEE`,
  [`LEGAMENTO TRASVERSO DEL GINOCCHIO SINISTRO`]: `TRANSVERSE LIGAMENT OF LEFT KNEE`,
  [`LEGAMENTO TRASVERSO DEL PERINEO (M)`]: `TRANSVERSE LIGAMENT OF PERINEUM (M)`,
  [`LEGAMENTO TRASVERSO DELL'ACETABOLO DESTRO`]: `RIGHT TRANSVERSE ACETABULAR LIGAMENT`,
  [`LEGAMENTO TRASVERSO DELL'ACETABOLO SINISTRO`]: `LEFT TRANSVERSE ACETABULAR LIGAMENT`,
  [`LEGAMENTO TRASVERSO DELL'ATLANTE`]: `TRANSVERSE LIGAMENT OF ATLAS`,
  [`LEGAMENTO TRIANGOLARE DESTRO`]: `RIGHT TRIANGULAR LIGAMENT`,
  [`LEGAMENTO TRIANGOLARE SINISTRO`]: `LEFT TRIANGULAR LIGAMENT`,
  [`LEGAMENTO ULNOCARPALE DORSALE DESTRO`]: `RIGHT DORSAL ULNOCARPAL LIGAMENT`,
  [`LEGAMENTO ULNOCARPALE DORSALE SINISTRO`]: `LEFT DORSAL ULNOCARPAL LIGAMENT`,
  [`LEGAMENTO ULNOCARPALE PALMARE DESTRO`]: `RIGHT PALMAR ULNOCARPAL LIGAMENT`,
  [`LEGAMENTO ULNOCARPALE PALMARE SINISTRO`]: `LEFT PALMAR ULNOCARPAL LIGAMENT`,
  [`LEGAMENTO UNCINATOPIRAMIDALE DESTRO`]: `RIGHT TRIQUETRUMHAMATE LIGAMENT`,
  [`LEGAMENTO UNCINATOPIRAMIDALE SINISTRO`]: `LEFT TRIQUETRUMHAMATE LIGAMENT`,
  [`Legamento/capsula articolare`]: `Ligament/joint capsule`,
  [`Legamentosa`]: `Ligament`,
  [`Leggera difficoltà`]: `Light difficulty`,
  [`Leggermente`]: `Slightly`,
  [`Leggero`]: `Light`,
  [`lento e insidioso`]: `Slowly and insidiously`,
  [`Lesiona cartilaginea`]: `Cartilage injury`,
  [`Lesione`]: `Injury`,
  [`Lesione a tendine di anca/inguine (compresa ernia, esclusa avulsione in eta' pediatrica)`]: `Hip and Groin Tendon Injuries`,
  [`Lesione acuta del legamento crociato anteriore (LCA)`]: `Anterior cruciate ligament injury`,
  [`Lesione acuta del legamento crociato posteriore (LCP)`]: `Posterior cruciate ligament (PCL) injury`,
  [`Lesione al moncone`]: `Stump injury`,
  [`Lesione all'addome/tronco`]: `Trunk and Abdominal Injury`,
  [`Lesione alla gabbia toracica senza specifica del tessuto interessato`]: `Chest injury without tissue type specified`,
  [`Lesione arteriosa al polso (compreso aneurisma)`]: `Wrist arterial injury (incl aneurysm)`,
  [`Lesione articolare della colonna toracica`]: `Thoracic spine Joint Injury`,
  [`Lesione articolare vertebrale lombare`]: `Lumbar Spine Joint Injury`,
  [`Lesione articolazione radioulnare distale`]: `Distal radioulnar joint injury`,
  [`Lesione cartilagine d\'accrescimento`]: `Physis injury`,
  [`Lesione cartilaginea`]: `Cartilage injury`,
  [`lesione cartilaginea del ginocchio`]: `cartilage lesion of knee`,
  [`Lesione cartilaginea del ginocchio (non specificata)`]: `Knee joint cartilage injury (unspecified)`,
  [`Lesione cartilaginea del ginocchio con corpi liberi intraarticolari`]: `Knee cartilage injury with loose bodies`,
  [`Lesione cartilaginea/osteocondrale all'articolazione dell'anca`]: `Hip Joint Chondral/Osteochondral Injury`,
  [`Lesione cartilaginea/osteocondrale dell'articolazione dell'anca`]: `Hip joint chondral lesion`,
  [`Lesione cerebrale cronica`]: `Chronic Brain Injury`,
  [`Lesione cerebrale o midollare`]: `Brain & spinal cord injury`,
  [`Lesione combinata dei legamenti del ginocchio con lesione condrale/meniscale associata`]: `Combined ligament injury with chondral/meniscal injury`,
  [`Lesione condrale/osteocondrale del piede`]: `Foot Chondral/Osteochondral Lesion`,
  [`Lesione condrale/osteocondrale di polso e mano`]: `Wrist Osteochondral/Chondral Injury`,
  [`Lesione cutanea benigna`]: `Benign skin lesion`,
  [`Lesione da sovraccarico dei tessuti molli del braccio (es. periostite)`]: `Upper arm soft tissue overuse injury (e.g. periostitis)`,
  [`Lesione da sovraccarico/stress osseo al braccio`]: `Upper Arm Bony Stress /Overuse Injury`,
  [`Lesione da stress di sesamoide`]: `Sesamoid stress injury`,
  [`Lesione da stress osseo`]: `Bone Stress Injury`,
  [`Lesione da stress/sovraccarico del gomito compresa la frattura da stress`]: `Elbow stress fracture`,
  [`Lesione da stress/sovraccarico dell'articolazione acromioclavicolare`]: `A/C joint stress/overuse injury`,
  [`Lesione da trazione su apofisi al ginocchio`]: `Traction injury to apophysis at knee`,
  [`Lesione da trazione su apofisi all'anca`]: `Traction injury to apophysis at groin/hip joint`,
  [`Lesione da trazione su apofisi alla caviglia`]: `Traction injury to apophysis ankle`,
  [`Lesione da trazione su apofisi alle natiche o al bacino`]: `Traction injury to apophysis at buttock and pelvis`,
  [`Lesione da trazione su apofisi del gomito`]: `Traction injury to apophysis at elbow`,
  [`Lesione da trazione su apofisi del polso e mano`]: `Traction injury to apophysis at wrist/hand`,
  [`Lesione da trazione su apofisi della spalla`]: `Traction injury to apophysis at shoulder`,
  [`Lesione da trazione/dolore pungente/bruciore del plesso brachiale`]: `Brachial plexus traction injury/burner/stinger`,
  [`Lesione degenerativa del disco/artrosi della colonna cervicale`]: `Cervical spinal column degenerative disc disease/arthritis`,
  [`Lesione degli ischiocrurali (hamstring)`]: `Hamstring strain/tear`,
  [`Lesione degli ischiocrurali (hamstring) di grado III`]: `Grade 3 hamstring strain`,
  [`Lesione dei muscoli estensori dell'avambraccio`]: `Forearm extensor muscle strain`,
  [`Lesione dei muscoli flessori dell'avambraccio`]: `Forearm flexor muscle strain`,
  [`Lesione dei tendini estensori della caviglia`]: `Extensor tendon injuries at ankle`,
  [`Lesione del bicipite femorale di grado I-II`]: `Biceps femoris strain grade 1 - 2`,
  [`lesione del complesso fibrocartilagineo triangolare`]: `Triangular fibrocartilage complex tear`,
  [`Lesione del disco cervicale`]: `Cervical disc Injury`,
  [`Lesione del disco lombare (esclusa la patologia discale degenerativa)`]: `Disc prolapse/disruption`,
  [`Lesione del disco toracico`]: `Thoracic disc prolapse`,
  [`Lesione del grande adduttore`]: `Adductor magnus strain`,
  [`Lesione del labbro (SLAP lesion)`]: `SLAP labral tears`,
  [`lesione del labbro acetabolare`]: `Acetabular lip injury`,
  [`Lesione del labbro acetabolare`]: `Labral tear, hip joint`,
  [`lesione del legamento collaterale laterale`]: `lateral collateral ligament injury`,
  [`Lesione del legamento collaterale laterale (LCL) e dell'angolo posterolaterale del ginocchio`]: `Posterolateral corner and LCL ligament injuries knee`,
  [`lesione del legamento collaterale laterale della caviglia (peroneo-astragalico e peroneo-calcaneale)`]: `Ankle lateral ligament sprain`,
  [`lesione del legamento collaterale mediale`]: `Lesion of medial collateral ligament of elbow`,
  [`Lesione del legamento collaterale mediale (LCM)`]: `Knee medial collateral ligament injury`,
  [`Lesione del legamento collaterale mediale del gomito`]: `Elbow medial collateral ligament strain or tear`,
  [`Lesione del legamento collaterale mediale del gomito con rottura del flessore comune`]: `Elbow medial ligament injury and CFO tear`,
  [`lesione del legamento collaterale mediale della caviglia (deltoideo)`]: `Ankle medial ligament injury (deltoideus)`,
  [`lesione del legamento collaterale ulnare del I metacarpo`]: `Injury of ulnar collateral ligament of I metacarpal`,
  [`Lesione del legamento collaterale ulnare del pollice (Skier's)`]: `Sprained ulnar collateral ligament (Skier's) thumb`,
  [`lesione del legamento crociato anteriore`]: `anterior cruciate ligament injury`,
  [`lesione del legamento crociato posteriore`]: `injury of posterior cruciate ligament`,
  [`Lesione del legamento crociato posteriore (LCP) con lesione cartilaginea/meniscale`]: `PLC injury with chondral /meniscal injury`,
  [`Lesione del legamento glenoomerale`]: `Glenohumeral ligament tear`,
  [`Lesione del legamento peroneoastragalico anteriore`]: `Anterior talofibular ligament sprain`,
  [`Lesione del legamento peroneocalcaneale`]: `Calcaneofibular ligament sprain`,
  [`lesione del legamento rotondo`]: `Ligament teres tear`,
  [`lesione del legamento scafo-lunato`]: `Scapholunate ligament sprain`,
  [`Lesione del legamento scafolunato`]: `Scapholunate ligament sprain`,
  [`lesione del legamento tibio-peroneale antero-inferiore`]: `Anterior inferior tibiofibular ligament injury`,
  [`Lesione del menisco degenerativa`]: `Degenerative meniscal tear`,
  [`lesione del menisco laterale`]: `lateral meniscus tear`,
  [`Lesione del menisco laterale`]: `Lateral meniscal tear`,
  [`lesione del menisco mediale`]: `medial meniscus tear`,
  [`Lesione del menisco mediale`]: `Medial meniscal tear`,
  [`Lesione del menisco mediale e laterale`]: `Medial and lateral meniscal tears`,
  [`Lesione del midollo spinale cervicale`]: `Cervical spinal cord injury`,
  [`lesione del nervo femorale`]: `femoral nerve injury`,
  [`Lesione del nervo mediano al braccio`]: `Median nerve injury upper arm`,
  [`Lesione del nervo muscolocutaneo al braccio`]: `Musculocutaneous nerve injury upper arm`,
  [`lesione del nervo peroneo comune`]: `common peroneal nerve injury`,
  [`Lesione del nervo pudendo`]: `Pudendal Nerve Injury`,
  [`Lesione del nervo radiale al braccio`]: `Radial nerve injury upper arm`,
  [`Lesione del nervo ulnare al braccio`]: `Ulnar nerve injury upper arm`,
  [`Lesione del nervo ulnare al gomito`]: `Ulnar neuropathy, elbow`,
  [`Lesione del nervo ulnare al polso`]: `Wrist ulnar nerve injury`,
  [`Lesione del pene`]: `Penile injury`,
  [`lesione del plesso brachiale`]: `Brachial plexus injury`,
  [`Lesione del quadricipite`]: `Quadriceps strain/tear`,
  [`Lesione del retto femorale`]: `Rectus femoris strain`,
  [`Lesione del semimembranoso/semitendinoso di grado I-II`]: `Semimembranosis/tendinosis strain (grade 1 - 2)`,
  [`Lesione del tendine d'achille`]: `Achilles tendon injury`,
  [`Lesione del tendine degli estensori nel piede`]: `Extensor tendon injury in foot`,
  [`Lesione del tendine del grande adduttore`]: `Adductor magnus tendon injury`,
  [`Lesione del tendine del grande pettorale`]: `Pectoralis major tendon injury`,
  [`Lesione del tendine del popliteo`]: `Popliteus tendinopathy/strain`,
  [`Lesione del tendine del retto femorale`]: `Rectus femoris tendon injury`,
  [`Lesione del tendine del sartorio`]: `Sartorius tendon injury`,
  [`Lesione del tendine del sottoscapolare`]: `Subscapularis Tendon Injury`,
  [`Lesione del tendine del sottospinato`]: `Infraspinatus tendon injury`,
  [`lesione del tendine del tibiale posteriore`]: `Tibialis posterior tendon rupture`,
  [`lesione del tendine dell'adduttore lungo`]: `injury of adductor longus tendon`,
  [`Lesione del tendine dell'adduttore lungo`]: `Adductor longus tendon injury`,
  [`lesione del tendine della cuffia dei rotatori`]: `Rottor Cuff tendon injury`,
  [`lesione del tendine distale del bicipite brachiale`]: `distal biceps brachii tendon injury`,
  [`lesione del tendine distale del tricipite brachiale`]: `lesion of triceps brachii distal tendon`,
  [`Lesione del tendine inserzionale del retto dell'addome`]: `Abdominal tendon insertion injury`,
  [`Lesione del tendine quadricipitale`]: `Quadriceps tendon injury`,
  [`lesione del tendine rotuleo`]: `injury of patellar tendon`,
  [`Lesione del tendine rotuleo`]: `Patellar Tendon Injury`,
  [`Lesione del tibiale posteriore`]: `Tibialis posterior strain`,
  [`lesione dell'abduttore dell'alluce`]: `abductor hallucis muscle injury`,
  [`Lesione dell'adduttore lungo`]: `Adductor longus strain`,
  [`Lesione dell'anulus del disco lombare`]: `Lumbar disc annular tear`,
  [`Lesione dell'articolazione sacroiliaca`]: `Sacroiliac joint sprain`,
  [`Lesione dell'origine del flessore comune`]: `Common flexor origin tear`,
  [`Lesione dell'origine dell'estensore comune`]: `Common extensor origin tear`,
  [`lesione della capsula della I articolazione metacarpo-falangea`]: `Capsular sprain of the first metacarpalphalangeal joint`,
  [`Lesione della cartilagine di accrescimento`]: `Physis injury`,
  [`lesione della fascia plantare`]: `plantar fascia injury`,
  [`Lesione della piastra plantare (articolazione metatarso-falangea)`]: `Plantar plate disruption (MTP jt)`,
  [`lesione della sindesmosi tibio-peroneale distale`]: `Syndesmosis injury`,
  [`Lesione della troclea del flessore delle dita della mano`]: `Flexor pully Injury fingers`,
  [`Lesione delle faccette articolari della colonna cervicale`]: `Facet Joint/Neck Ligament sprain`,
  [`Lesione di I grado del legamento collaterale mediale (LCM)`]: `Grade 1 MCL tear knee`,
  [`Lesione di II grado del legamento collaterale mediale (LCM)`]: `Grade 2 MCL tear knee`,
  [`Lesione di nervo cranico`]: `Cranial Nerve injury`,
  [`Lesione di organi dell'inguine`]: `Groin Organ Damage`,
  [`Lesione di un organo addominale`]: `Abdominal organ Injury`,
  [`Lesione distale degli adduttori`]: `Distal adductor strain`,
  [`Lesione epifisaria`]: `Physis injury`,
  [`Lesione legamentosa`]: `Ligament injury`,
  [`lesione legamentosa parziale`]: `Partial ligament rupture`,
  [`lesione legamentosa totale`]: `Total ligament rupture`,
  [`Lesione mista osteocondrale e meniscale`]: `Mixed osteochondral and meniscal injury`,
  [`Lesione muscolare`]: `Muscle injury`,
  [`Lesione muscolare al compartimento anteriore della gamba`]: `Anterior compartment muscle injury`,
  [`Lesione muscolare al compartimento laterale della gamba`]: `Lateral compartment muscle injury`,
  [`Lesione muscolare all'arto inferiore`]: `Muscle strain lower limb`,
  [`Lesione muscolare all'arto superiore`]: `Muscle strain upper limb`,
  [`Lesione muscolare alla gamba`]: `Lower leg muscle Injury`,
  [`lesione muscolare degli adduttori`]: `adductors muscle injury`,
  [`Lesione muscolare del bicipite brachiale`]: `Biceps muscle strain`,
  [`lesione muscolare del bicipite femorale`]: `biceps femoris muscle injury`,
  [`lesione muscolare del capo lungo del bicipite brachiale`]: `Biceps brachii (long head) muscle injury`,
  [`Lesione muscolare del deltoide`]: `Deltoid muscle injury`,
  [`lesione muscolare del gastrocnemio (leg tennis)`]: `gastrocnemius muscle injury`,
  [`Lesione muscolare del gastrocnemio laterale`]: `Lateral gastroc strain`,
  [`Lesione muscolare del gastrocnemio mediale`]: `Medial gastroc strain`,
  [`lesione muscolare del grande adduttore`]: `adductor magnus muscle injury`,
  [`Lesione muscolare del grande dorsale`]: `Latissimus dorsi muscle strain`,
  [`lesione muscolare del grande pettorale`]: `Pectoral major muscle injury`,
  [`Lesione muscolare del grande pettorale`]: `Pectoralis major muscle strain`,
  [`lesione muscolare del muscolo bicipite brachiale`]: `Biceps brachii muscle injury`,
  [`lesione muscolare del muscolo brachiale`]: `brachialis muscle injury`,
  [`lesione muscolare del muscolo tricipite brachiale`]: `triceps brachii muscle injury`,
  [`Lesione muscolare del popliteo`]: `Popliteus muscle strain`,
  [`lesione muscolare del semimembranoso`]: `semimembranosus muscle injury`,
  [`lesione muscolare del semitendinoso`]: `Semitendinosus muscle injury`,
  [`Lesione muscolare del soleo`]: `Soleus muscle injury`,
  [`Lesione muscolare del soleo e/o soleo accessorio`]: `Soleus strain a/w accessory soleus`,
  [`lesione muscolare del sottoscapolare`]: `Subscapularis muscle injury`,
  [`Lesione muscolare del tricipite brachiale`]: `Triceps muscle strain`,
  [`Lesione muscolare dell'avambraccio`]: `Forearm Muscle Injury`,
  [`Lesione muscolare della cuffia dei rotatori`]: `Rotator Cuff muscle injury`,
  [`Lesione muscolare di polso e mano`]: `Wrist and Hand Muscle Injury`,
  [`lesione muscolare distale del retto femorale`]: `distal muscle injury of rectus femoris`,
  [`lesione muscolare distale del retto femorale,`]: `rectus femoris distal muscle injury`,
  [`lesione muscolare prossimale del retto femorale`]: `proximal muscle injury of the rectus femoris`,
  [`Lesione muscolare toracica`]: `Thoracic extensor muscle strain`,
  [`Lesione muscolare vertebrale`]: `Muscle strain spine`,
  [`Lesione muscolare/spasmo/punto trigger del tronco`]: `Abdominal muscle trigger pts or spasm`,
  [`Lesione muscolare/spasmo/trigger point al braccio`]: `Upper Arm Muscle Strain/Spasm/Trigger points`,
  [`Lesione muscolare/spasmo/trigger point al gomito`]: `Elbow muscle strain/spasm/trigger points`,
  [`Lesione muscolare/spasmo/trigger point alla spalla`]: `Muscle strain, shoulder region`,
  [`Lesione muscolare/spasmo/trigger point toracico`]: `Thoracic Muscle Strain/Spasm/Trigger Points`,
  [`Lesione nervosa`]: `Nerve injury`,
  [`Lesione nervosa alla caviglia`]: `Nerve Injury at Ankle`,
  [`Lesione nervosa di bacino/natiche`]: `Buttock/Pudendal Nerve Injury`,
  [`Lesione nervosa lombosacrale da stiramento/trazione`]: `Lumbosacral nerve stretch/traction injury`,
  [`Lesione nervosa periferica`]: `Peripheral nerve Injury`,
  [`Lesione neurologica`]: `Peripheral nerve injury`,
  [`Lesione neurologica a localizzazione non specificata`]: `Spinal injury location unspecified`,
  [`Lesione neurologica al braccio`]: `Upper Arm neurological injury`,
  [`Lesione neurologica al piede`]: `Foot Neurological Injury`,
  [`Lesione neurologica all'arto inferiore`]: `Lower limb neurological injury`,
  [`Lesione neurologica all'arto superiore`]: `Upper limb neurological injury`,
  [`Lesione neurologica che attraversa I confini anatomici`]: `Spinal injury crossing anatomical boundaries`,
  [`Lesione neurologica del collo`]: `Neurological Neck Injury`,
  [`Lesione neurologica dell'avambraccio`]: `Forearm Neurological Injury`,
  [`Lesione neurologica della colonna lombare`]: `Lumbar Spine Neurological Injury`,
  [`Lesione neurologica della gamba`]: `Neurological Injury of Lower Leg`,
  [`Lesione neurologica di polso e mano`]: `Wrist Neurological Injury`,
  [`Lesione non diagnosticata`]: `No specific tissue pathology diagnosed`,
  [`Lesione non specificata`]: `Injury without tissue type specified`,
  [`Lesione organica pelvica`]: `Pelvic Organ Injury`,
  [`Lesione ossea da stress`]: `Bone stress injury`,
  [`Lesione ossea da stress/sovraccarico alla spalla`]: `Shoulder bony stress/over use injury`,
  [`Lesione osteocondrale`]: `Osteochondral injury`,
  [`Lesione osteocondrale del condilo femorale laterale`]: `Lateral femoral condyle osteochondral injury`,
  [`Lesione osteocondrale del condilo femorale mediale`]: `Medial femoral condyle osteochondral injury`,
  [`Lesione osteocondrale del gomito (escluse diagnosi pediatriche)`]: `Chondral lesion elbow (+/- loose bodies)`,
  [`Lesione osteocondrale della spalla`]: `Shoulder chondral lesion`,
  [`Lesione osteocondrale della tibia`]: `Tibial osteochondral injury`,
  [`Lesione osteocondrale femororotulea`]: `Patellofemoral osteochondral injury`,
  [`Lesione parziale del legamento crociato anteriore (LCA)`]: `Partial ACL tear`,
  [`lesione parziale del legamento crociato posteriore`]: `partial rupture of posterior cruciate ligament`,
  [`Lesione parziale del legamento crociato posteriore (LCP)`]: `Partial PCL tear`,
  [`Lesione parziale del tendine del sopraspinato`]: `Supraspinatus tendon tear partial thickness`,
  [`Lesione prossimale del tendine del bicipite brachiale`]: `Proximal biceps tendon injury`,
  [`Lesione senza tipo di tessuto specificato`]: `Injury without tissue type specified`,
  [`Lesione tendinea al braccio`]: `Upper Arm Tendon Injury`,
  [`Lesione tendinea al ginocchio`]: `Knee Tendon Injury`,
  [`Lesione tendinea al piede`]: `Foot Tendon Injuries`,
  [`Lesione tendinea al pollice`]: `Thumb tendon injury`,
  [`Lesione tendinea alla coscia(vedi localizzazione a anca/inguine o ginocchio, in dipendenza dalla localizzazione del tendine)`]: `Thigh Tendon Injuries`,
  [`Lesione tendinea combinata al gomito`]: `Elbow Tendon Injury Combined`,
  [`Lesione tendinea degli adduttori multipla o non specificata`]: `Unspecified or multiple adductor tendon injury`,
  [`Lesione tendinea degli estensori del polso`]: `Wrist extensor tendon injury`,
  [`Lesione tendinea degli estensori dell'avambraccio`]: `Forearm extensor tendon injury`,
  [`Lesione tendinea degli ischiocrurali (hamstring)`]: `Hamstring tendon rupture`,
  [`Lesione tendinea dei flessori dell'avambraccio`]: `Forearm flexor tendon injury`,
  [`lesione tendinea dei muscoli peronieri`]: `Peroneal tendons rupture (longitudinal rupture)`,
  [`Lesione tendinea del gastrocnemio`]: `Gastrocnemius tendon injury`,
  [`Lesione tendinea del retto dell'addome`]: `Abdominal tendon Injury`,
  [`Lesione tendinea dell'avambraccio`]: `Forearm Tendon Injury`,
  [`Lesione tendinea dell'ileopsoas`]: `Iliopsoas tendon injury`,
  [`Lesione tendinea di caviglia`]: `Ankle tendon injury`,
  [`Lesione tendinea di polso e mano`]: `Wrist Tendon Injury`,
  [`Lesione tendinea parziale`]: `Partial tendon injury`,
  [`Lesione tendinea totale`]: `Total tendon injury`,
  [`Lesione totale del tendine del sopraspinato`]: `Supraspinatus tendon rupture full thickness`,
  [`Lesione vascolare a localizzazione non specificata`]: `Vascular Injury location unspecified`,
  [`Lesione vascolare al piede`]: `Foot Vascular Injuries`,
  [`Lesione vascolare che attraversa I confini anatomici`]: `Vascular Injury Crossing Anatomical Boundaries`,
  [`Lesione vascolare dell'anca`]: `Vascular Injury Hip Region`,
  [`Lesione vascolare della gamba`]: `Vascular Injury Lower Leg`,
  [`Lesione vascolare di polso e mano`]: `Wrist Vascular Injury`,
  [`LESIONE-PATOLOGIA`]: `Injury`,
  [`Lesione-patologia non specificata`]: `Injury`,
  [`Lesione/dolore al gomito non altrimenti specificato`]: `Elbow Pain/Injury not otherwise specified`,
  [`Lesione/dolore all'articolazione sacrococcigea`]: `Sacrococcygeal joint injury/pain`,
  [`Lesione/spasmo muscolare a localizzazione non specificata`]: `Muscle injury/spasm location unspecified`,
  [`Lesione/spasmo/trigger point dei muscoli del ginocchio`]: `Knee Muscle Strain/Spasm/Trigger Points`,
  [`Lesione/spasmo/trigger point dei muscoli della coscia`]: `Thigh Muscle strain/Spasm/Trigger Points`,
  [`Lesione/stiramento del gastrocnemio`]: `Gastrocnemius muscle injury/strain`,
  [`Lesione/stiramento del soleo`]: `Soleus muscle strain`,
  [`Lesione/stiramento di muscolo flessore dell'anca`]: `Hip flexor muscle strain`,
  [`Lesione/trauma oculare`]: `Eye injury/trauma`,
  [`Lesioni articolari del piede`]: `Foot Joint Sprain`,
  [`Lesioni cartilagini, menischi, cercini`]: `Cartilage, menisc, labrum injuries`,
  [`Lesioni combinate dei legamenti del ginocchio`]: `Combined ligament injuries knee`,
  [`Lesioni da sovraccarico/rotture dei tendini della spalla`]: `Rotator cuff tendon pathology`,
  [`Lesioni da stress del calcagno`]: `Stress injury calcaneus`,
  [`Lesioni da stress/sovraccarico del polso e della mano comprese le fratture da stress`]: `Wrist and Hand Stress/Overuse Injuries`,
  [`Lesioni da stress/sovraccarico dell'avambraccio, compresa la frattura da stress`]: `Forearm Stress Fracture`,
  [`Lesioni da stress/sovraccarico della spalla, comprese le fratture da stress`]: `Shoulder stress/Overuse injuries incl stress fractures`,
  [`Lesioni legamentose`]: `Ligament injuries`,
  [`Lesioni legamentose multiple di caviglia`]: `Ankle multiple ligaments sprain`,
  [`Lesioni muscolari`]: `Muscle injuries`,
  [`Lesioni neurologiche alla coscia`]: `Thigh Neurological Injury`,
  [`Lesioni neurologiche/vascolari di spalla (esclusi I problemi di origine non meccanica)`]: `Shoulder Neurological injury`,
  [`Lesioni neurovascolari dell'inguine`]: `Groin Neurovascular Injuries`,
  [`Lesioni osteocondrali di caviglia`]: `Ankle osteochondral Injuries`,
  [`Lesioni tendinee`]: `Tendon injuries`,
  [`Lesioni tendinee multiple alla spalla`]: `Multiple tendon injury`,
  [`Leucemia`]: `Leukemia`,
  [`Leucocitosi`]: `Leukocytosis`,
  [`Lieve`]: `Mild`,
  [`Lieve difficoltà`]: `Mild difficulty`,
  [`Lievemente`]: `Mildly`,
  [`Lift-Off Test (DX)`]: `Lift-Off Test (R)`,
  [`Lift-Off Test (SX)`]: `Lift-Off Test (L)`,
  [`Lifting (DX)`]: `Lifting (R)`,
  [`Lifting (SX)`]: `Lifting (L)`,
  [`Ligamentus Teres Test (DX)`]: `Ligamentus Teres Test (R)`,
  [`Ligamentus Teres Test (SX)`]: `Ligamentus Teres Test (L)`,
  [`Limitato la sua attività`]: `Limit physical activity?`,
  [`Limitazione all'apertura attiva della bocca`]: `Restriction of active mouth opening`,
  [`Limitazione all'apertura passiva della bocca`]: `Restriction of passive mouth opening`,
  [`Limitazione alla protrusione attiva della bocca`]: `Restriction of active TMJ protrusion`,
  [`Limitazione alla traslazione laterale del condilo mandibolare`]: `Restriction of lateral glide of mandible condile`,
  [`Limitazione del flusso dell'arteria iliaca relazionata ad esercizio`]: `Exercise-related iliac artery flow limitation`,
  [`LINEA OMBELICALE LATERALE DESTRA`]: `LEFT MEDIAL UMBILICAL FOLD`,
  [`LINEA OMBELICALE LATERALE SINISTRA`]: `LEFT LATERAL UMBILICAL FOLD`,
  [`LINEA OMBELICALE MEDIALE DESTRA`]: `RIGHT MEDIAL UMBILICAL FOLD`,
  [`LINEA OMBELICALE MEDIALE SINISTRA`]: `LEFT MEDIAL UMBILICAL FOLD`,
  [`LINEA OMBELICALE MEDIANA`]: `MEDIAN UMBILICAL FOLD`,
  [`Linfoadenopatia secondaria a infezione cutanea`]: `Lymphadenopathy secondary to skin infection`,
  [`Linfoma di Hodgkin`]: `Hodgkin lymphoma`,
  [`Linfoma non-Hodgkin`]: `Non-Hodgkin lymphoma`,
  [`LINFONODI ADDOMINALI`]: `ABDOMINAL LYMPH NODES`,
  [`LINFONODI ASCELLARI DI DESTRA`]: `RIGHT AXILLARY LYMPH NODES`,
  [`LINFONODI ASCELLARI DI SINISTRA`]: `LEFT AXILLARY LYMPH NODES`,
  [`LINFONODI BRACHIALI DI DESTRA`]: `RIGHT BRACHIAL NODES`,
  [`LINFONODI BRACHIALI DI SINISTRA`]: `LEFT BRACHIAL NODES`,
  [`LINFONODI CERVICALI ANTERIORI DI DESTRA`]: `RIGHT ANTERIOR CERVICAL NODES`,
  [`LINFONODI CERVICALI ANTERIORI DI SINISTRA`]: `LEFT ANTERIOR CERVICAL NODES`,
  [`LINFONODI CERVICALI LATERALI DI DESTRA`]: `RIGHT LATERAL CERVICAL NODES`,
  [`LINFONODI CERVICALI LATERALI DI SINISTRA`]: `LEFT LATERAL CERVICAL NODES`,
  [`LINFONODI CUBITALI DI DESTRA`]: `RIGHT CUBITAL NODES`,
  [`LINFONODI CUBITALI DI SINISTRA`]: `LEFT CUBITAL NODES`,
  [`LINFONODI DELTOPETTORALI DI DESTRA`]: `RIGHT DELTOPECTORAL NODES`,
  [`LINFONODI DELTOPETTORALI DI SINISTRA`]: `LEFT DELTOPECTORAL NODES`,
  [`LINFONODI DIAFRAMMATICI`]: `DIAPHRAGMATIC NODES`,
  [`LINFONODI FACIALI DI DESTRA`]: `RIGHT FACIAL NODES`,
  [`LINFONODI FACIALI DI SINISTRA`]: `LEFT FACIAL NODES`,
  [`LINFONODI ILIACI COMUNI`]: `COMMON ILIAC NODES`,
  [`LINFONODI ILIACI ESTERNI DI DESTRA`]: `RIGHT EXTERNAL ILIAC NODES`,
  [`LINFONODI ILIACI ESTERNI DI SINISTRA`]: `LEFT EXTERNAL ILIAC NODES`,
  [`LINFONODI INGUINALI DI DESTRA`]: `RIGHT INGUINAL LYMPH NODES`,
  [`LINFONODI INGUINALI DI SINISTRA`]: `LEFT INGUINAL LYMPH NODES`,
  [`LINFONODI INTERCOSTALI DI DESTRA`]: `RIGHT INTERCOSTAL NODES`,
  [`LINFONODI INTERCOSTALI DI SINISTRA`]: `LEFT INTERCOSTAL NODES`,
  [`LINFONODI IPOGASTRICI`]: `HYPOGASTRIC NODES`,
  [`LINFONODI LINGUALI DI DESTRA`]: `RIGHT LINGUAL NODES`,
  [`LINFONODI LINGUALI DI SINISTRA`]: `LEFT LINGUAL NODES`,
  [`LINFONODI LOMBOAORTICI`]: `LUMBOAORTIC NODES`,
  [`LINFONODI MASTOIDEI DI DESTRA`]: `RIGHT MASTOID NODES`,
  [`LINFONODI MASTOIDEI DI SINISTRA`]: `LEFT MASTOID NODES`,
  [`LINFONODI OCCIPITALI DI DESTRA`]: `RIGHT OCCIPITAL NODES`,
  [`LINFONODI OCCIPITALI DI SINISTRA`]: `LEFT OCCIPITAL NODES`,
  [`LINFONODI PARAMAMMARI DI DESTRA`]: `RIGHT PARAMAMMARY NODES`,
  [`LINFONODI PARAMAMMARI DI SINISTRA`]: `LEFT PARAMAMMARY NODES`,
  [`LINFONODI PAROTIDEI PROFONDI DI DESTRA`]: `RIGHT DEEP PAROTID NODES`,
  [`LINFONODI PAROTIDEI PROFONDI DI SINISTRA`]: `LEFT DEEP PAROTID NODES`,
  [`LINFONODI PAROTIDEI SUPERFICIALI DI DESTRA`]: `RIGHT SUPERFICIAL PAROTID NODES`,
  [`LINFONODI PAROTIDEI SUPERFICIALI DI SINISTRA`]: `LEFT SUPERFICIAL PAROTID NODES`,
  [`LINFONODI POPLITEI DI DESTRA`]: `RIGHT POPLITEAL NODES`,
  [`LINFONODI POPLITEI DI SINISTRA`]: `LEFT POPLITEAL NODES`,
  [`LINFONODI SACRALI`]: `SACRAL NODES`,
  [`LINFONODI SOPRACLAVICOLARI DI DESTRA`]: `RIGHT SUPRACLAVICULAR NODES`,
  [`LINFONODI SOPRACLAVICOLARI DI SINISTRA`]: `LEFT SUPRACLAVICULAR NODES`,
  [`LINFONODI SUBMANDIBOLARI DI DESTRA`]: `RIGHT SUBMANDIBULAR NODES`,
  [`LINFONODI SUBMANDIBOLARI DI SINISTRA`]: `LEFT SUBMANDIBULAR NODES`,
  [`LINFONODI SUBMENTALI DI DESTRA`]: `RIGHT SUBMENTAL NODES`,
  [`LINFONODI SUBMENTALI DI SINISTRA`]: `LEFT SUBMENTAL NODES`,
  [`LINFONODI TORACICI INTERNI DI DESTRA`]: `RIGHT INTERNAL THORACIC NODES`,
  [`LINFONODI TORACICI INTERNI DI SINISTRA`]: `LEFT INTERNAL THORACIC NODES`,
  [`LINFONODI VISCERALI`]: `VISCERAL LYMPH NODES`,
  [`LINGUA`]: `TONGUE`,
  [`Liquori`]: `liquor`,
  [`Little leaguers' shoulder`]: `Little leaguers' shoulder`,
  [`Livello competenza movimento`]: `Movement competency level`,
  [`Livello di fatica`]: `Fatigue level`,
  [`Livello di stress`]: `Stress level`,
  [`Livello forza`]: `Strength level`,
  [`Loaded Forward Reach Test`]: `Loaded Forward Reach Test`,
  [`Localizzazione`]: `Location`,
  [`Localizzazione del dolore`]: `Location of pain`,
  [`Log Roll Test (DX)`]: `Log Roll Test (R)`,
  [`Log Roll Test (SX)`]: `Log Roll Test (L)`,
  [`lombalgia`]: `Low back pain`,
  [`lombalgia acuta`]: `Acute low back pain`,
  [`lombalgia cronica`]: `Chronic low back pain`,
  [`Lombare`]: `Lumbar spine  `,
  [`Lombarizzazione di S1`]: `Lumbarisation of S1`,
  [`Long Jump Test`]: `Long Jump Test`,
  [`Long-Axis Femoral Distraction Test (DX)`]: `Long-Axis Femoral Distraction Test (R)`,
  [`Long-Axis Femoral Distraction Test (SX)`]: `Long-Axis Femoral Distraction Test (L)`,
  [`Lower Quarter Y Balance Test (anteriore) (DX)`]: `Lower Quarter Y Balance Test (anterior) (R)`,
  [`Lower Quarter Y Balance Test (anteriore) (SX)`]: `Lower Quarter Y Balance Test (anterior) (L)`,
  [`Lower Quarter Y Balance Test (posterolaterale) (DX)`]: `Lower Quarter Y Balance Test (posterolateral) (R)`,
  [`Lower Quarter Y Balance Test (posterolaterale) (SX)`]: `Lower Quarter Y Balance Test (posterolateral) (L)`,
  [`Lower quarter Y Balance Test (posterolaterlae) (SX)`]: `Lower Quarter Y Balance Test (posterolateral) (L)`,
  [`Lower Quarter Y Balance Test (posteromediale) (DX)`]: `Lower Quarter Y Balance Test (posteromedial) (R)`,
  [`Lower Quarter Y Balance Test (posteromediale) (SX)`]: `Lower Quarter Y Balance Test (posteromedial) (L)`,
  [`Lower Trunk Dynamic Stability Test`]: `Lower Trunk Dynamic Stability Test`,
  [`Lumbar Anterior Quadrant Test (DX)`]: `Lumbar Anterior Quadrant Test (R)`,
  [`Lumbar Anterior Quadrant Test (SX)`]: `Lumbar Anterior Quadrant Test (L)`,
  [`Lumbar Apprehension Sign`]: `Lumbar Apprehension Sign`,
  [`Lumbar nerve root impingement due to foraminal stenosis bony and disc`]: `Lumbar nerve root impingement due to foraminal stenosis bony and disc`,
  [`Lumbar Posterior Quadrant Test (DX)`]: `Lumbar Posterior Quadrant Test (R)`,
  [`Lumbar Posterior Quadrant Test (SX)`]: `Lumbar Posterior Quadrant Test (L)`,
  [`Lumbar spine repeated motion ROM (5-20 times)`]: `Lumbar spine repeated motion ROM (5-20 times)`,
  [`Lunge (DX)`]: `Lunge (R)`,
  [`Lunge (SX)`]: `Lunge (L)`,
  [`Lupus eritematoso sistemico`]: `Systemic lupus erythematosus (SLE)`,
  [`Lussazione`]: `Dislocation`,
  [`Lussazione acromion-clavicolare`]: `Acromion-clavicular dislocation`,
  [`Lussazione acuta di spalla`]: `Dislocated shoulder`,
  [`Lussazione alla faccia`]: `Facial Dislocation`,
  [`Lussazione anteriore del gomito`]: `Anterior elbow dislocation`,
  [`lussazione anteriore del semilunare`]: `anterior dislocation of lunate`,
  [`Lussazione antero-inferiore di spalla`]: `Shoulder dislocation (anterior)`,
  [`Lussazione articolare alle dita con infortunio della piastra palmare`]: `Finger joint dislocation with volar plate injury`,
  [`lussazione articolazione carpometacarpale`]: `Carpometacarpal joint dislocation`,
  [`lussazione articolazione interfalangea distale`]: `Distal inter-phalangeal joint dislocation`,
  [`lussazione articolazione interfalangea prossimale`]: `Proximal inter-phalangeal joint dislocation`,
  [`lussazione articolazione metacarpo-falangea`]: `Metacarpo-phalangeal joint dislocation`,
  [`Lussazione congenita dell'anca`]: `Congenital dislocation of hip`,
  [`Lussazione del carpo`]: `Dislocation through carpus`,
  [`Lussazione del ginocchio`]: `Knee dislocation  `,
  [`Lussazione del gomito`]: `Dislocated elbow`,
  [`lussazione del tendine del tibiale posteriore`]: `tibialis posterior tendon subluxation`,
  [`Lussazione dell'articolazione carpometacarpale`]: `Dislocation of CMC joint of fingers`,
  [`Lussazione dell'articolazione carpometacarpale del pollice`]: `Dislocation CMC joint thumb`,
  [`Lussazione dell'articolazione costocondrale`]: `Costochondral joint dislocation`,
  [`Lussazione dell'articolazione del pollice`]: `Dislocation of thumb joint`,
  [`Lussazione dell'articolazione dell'anca`]: `Hip Joint Dislocation`,
  [`Lussazione dell'articolazione interfalangea del pollice`]: `Dislocation of IP joint thumb`,
  [`Lussazione dell'articolazione interfalangea delle altre dita del piede`]: `Dislocation of IP joint of lesser toe`,
  [`Lussazione dell'articolazione interfalangea distale del dito anulare`]: `DIP joint dislocation ring finger`,
  [`Lussazione dell'articolazione interfalangea distale del dito indice`]: `DIP joint dislocation index finger`,
  [`Lussazione dell'articolazione interfalangea distale del dito medio`]: `DIP joint dislocation middle finger`,
  [`Lussazione dell'articolazione interfalangea distale del dito mignolo`]: `DIP joint dislocation little finger`,
  [`Lussazione dell'articolazione interfalangea distale dito non specificato`]: `DIP joint dislocation finger unknown`,
  [`Lussazione dell'articolazione interfalangea prossimale del dito anulare`]: `PIP joint dislocation ring finger`,
  [`Lussazione dell'articolazione interfalangea prossimale del dito indice`]: `PIP joint dislocation index finger`,
  [`Lussazione dell'articolazione interfalangea prossimale del dito medio`]: `PIP joint dislocation middle finger`,
  [`Lussazione dell'articolazione interfalangea prossimale del dito mignolo`]: `PIP joint dislocation little finger`,
  [`Lussazione dell'articolazione interfalangea prossimale dito non specificato`]: `PIP joint dislocation finger unknown`,
  [`Lussazione dell'articolazione metacarpofalangea del dito anulare`]: `MCP joint dislocation ring finger`,
  [`Lussazione dell'articolazione metacarpofalangea del dito indice`]: `MCP joint dislocation index finger`,
  [`Lussazione dell'articolazione metacarpofalangea del dito medio`]: `MCP joint dislocation middle finger`,
  [`Lussazione dell'articolazione metacarpofalangea del dito mignolo`]: `MCP joint dislocation little finger`,
  [`Lussazione dell'articolazione metacarpofalangea del dito/a`]: `Dislocation of MCP joint finger(s)`,
  [`Lussazione dell'articolazione metacarpofalangea del pollice`]: `Dislocation of MCP joint thumb`,
  [`Lussazione dell'articolazione metacarpofalangea o interfalangea`]: `Dislocated metacarpophalangeal or interphalangeal joint`,
  [`Lussazione dell'articolazione metatarsofalangea dell'alluce`]: `Dislocation of great toe MTP joint`,
  [`Lussazione dell'articolazione metatarsofalangea delle altre dita del piede`]: `Dislocation of lesser toe MTP joint`,
  [`Lussazione dell'articolazione radiocarpica`]: `Radiocarpal joint dislocation`,
  [`Lussazione dell'articolazione radioulnare distale`]: `Distal R/U joint dislocation`,
  [`Lussazione dell'articolazione sternoclavicolare`]: `Sternoclavicular joint dislocation`,
  [`Lussazione dell'articolazione tibioperoneale superiore`]: `Superior tib fib joint dislocation`,
  [`lussazione della mandibola`]: `Jaw dislocation`,
  [`Lussazione della mandibola`]: `Jaw dislocation`,
  [`lussazione della rotula`]: `patellar displacement`,
  [`Lussazione della rotula`]: `Dislocated patella`,
  [`Lussazione della spalla con altre o non specificate lesioni neurologiche`]: `Shoulder dislocation with other or unspecified neurological injury`,
  [`Lussazione della spalla con lesione del nervo ascellare`]: `Shoulder dislocation with axillary nerve injury`,
  [`Lussazione della spalla con lesione HAGL`]: `Shoulder dislocation with HAGL lesion`,
  [`Lussazione della spalla con lesione labiale di Bankart`]: `Shoulder dislocation with labral Bankart lesion`,
  [`Lussazione delle articolazioni metatarsofalangee di due o piu' dita`]: `MCP joint dislocation of two or more fingers`,
  [`Lussazione di caviglia`]: `Ankle dislocation`,
  [`Lussazione di polso e mano (compresa frattura da avulsione minore)`]: `Wrist and Hand Dislocations`,
  [`Lussazione di spalla con lesione SLAP`]: `SLAP lesion shoulder`,
  [`Lussazione inferiore di spalla`]: `Inferior shoulder dislocation`,
  [`Lussazione multipla della/e articolazione/I interfalangea prossimale/i e distale`]: `Multiple PIP and/or DIP joint dislocations`,
  [`lussazione perilunare del carpo`]: `carpal dislocation`,
  [`lussazione posteriore del gomito`]: `Posterior dislocation of elbow`,
  [`Lussazione posteriore del gomito`]: `Posterior elbow dislocation`,
  [`Lussazione posteriore dell'articolazione sternoclavicolare`]: `Posterior sternoclavicular joint dislocation`,
  [`Lussazione posteriore della spalla con lesione labiale posteriore`]: `Posterior shoulder dislocation with posterior labral lesion`,
  [`Lussazione posteriore di spalla`]: `Posterior shoulder dislocation`,
  [`Lussazione tendinea`]: `Tendon dislocation`,
  [`Lussazione toracica`]: `Chest Dislocations`,
  [`Lussazioni articolari`]: `Joint injuries`,
  [`Lutto familiare`]: `Family mourning`,
  [`Macdermid JC, Walton DM, Avery S, Blanchard A, Etruw E, McAlpine C, Goldsmith CH. Measurement properties of the neck disability index a sustematic review Journal of Orthopedic and Sports Physical Therapy. 2009 May;39(5):400-17.`]: `Macdermid JC, Walton DM, Avery S, Blanchard A, Etruw E, McAlpine C, Goldsmith CH. Measurement properties of the neck disability index a sustematic review Journal of Orthopedic and Sports Physical Therapy. 2009 May;39(5):400-17.`,
  [`Maffulli N, Longo UG, Testa V, Oliva F, Capasso G, Denaro V. Italian translation of the VISA-A score for tendinopathy of the main body of the Achilles tendon. Disabil Rehabil. 2008;30(20-22):1635-9.`]: `Maffulli N, Longo UG, Testa V, Oliva F, Capasso G, Denaro V. Italian translation of the VISA-A score for tendinopathy of the main body of the Achilles tendon. Disabil Rehabil. 2008;30(20-22):1635-9.`,
  [`Maffulli N, Longo UG, Testa V, Oliva F, Capasso G, Denaro V. VISA-P score for patellar tendinopathy in males: adaptation to Italian. Disabil Rehabil. 2008;30(20-22):1621-4.`]: `Maffulli N, Longo UG, Testa V, Oliva F, Capasso G, Denaro V. VISA-P score for patellar tendinopathy in males: adaptation to Italian. Disabil Rehabil. 2008;30(20-22):1621-4.`,
  [`Mai`]: `Never`,
  [`mal d testa cervicogenico`]: `Cervicogenic headache`,
  [`Mal d testa da esercizio`]: `Exercise headache`,
  [`mal d testa post-traumatco`]: `post-traumatic headache`,
  [`Mal di testa`]: `Headaches`,
  [`mal di testa da compressione`]: `compression headache`,
  [`Malattia a trasmissione sessuale`]: `Sexually transmitted disease`,
  [`malattia celiaca`]: `Coeliac disease`,
  [`Malattia dermatologica`]: `Dermatological Illness`,
  [`Malattia respiratoria`]: `Respiratory Disease`,
  [`Malattia reumatica`]: `Rheumatological Illness`,
  [`Malattia reumatica aspecifica/non diagnosticata`]: `Rheumatological disease other/undiagnosed`,
  [`Malattia vascolare periferica`]: `Peripheral vascular disease`,
  [`malessere generale`]: `general ill-being`,
  [`Mandibola`]: `Mandible`,
  [`MANDIBOLA`]: `MANDIBULA`,
  [`MANDIBOLA (COLLO DESTRO)`]: `MANDIBULA (RIGHT NECK)`,
  [`MANDIBOLA (COLLO SINISTRO)`]: `MANDIBULA (LEFT NECK)`,
  [`MANDIBOLA (PROCESSO CONDILARE DESTRO)`]: `MANDIBULA (RIGHT CONDYLAR PROCESS)`,
  [`MANDIBOLA (PROCESSO CONDILARE SINISTRO)`]: `MANDIBULA (LEFT CONDYLAR PROCESS)`,
  [`MANDIBOLA (PROCESSO CORONOIDEO DESTRO)`]: `MANDIBULA (RIGHT CORONOID PROCESS)`,
  [`MANDIBOLA (PROCESSO CORONOIDEO SINISTRO)`]: `MANDIBULA (LEFT CORONOID PROCESS)`,
  [`MANDIBOLA (PROTUBERANZA MENTALE)`]: `MANDIBULA (MENTAL PROTUBERANCE)`,
  [`MANDIBOLA (RAMO DESTRO)`]: `MANDIBULA (RIGHT RAMUS)`,
  [`MANDIBOLA (RAMO SINISTRO)`]: `MANDIBULA (LEFT RAMUS)`,
  [`MANDIBOLA (TESTA DESTRA)`]: `MANDIBULA (RIGHT HEAD)`,
  [`MANDIBOLA (TESTA SINISTRA)`]: `MANDIBULA (LEFT HEAD)`,
  [`mangiare`]: `Eating`,
  [`Mangiare`]: `eating`,
  [`Manifesti attuamente o hai manifestato nelle ultime 6 settimane qualcuno tra i seguenti sintomi?`]: `Have you had anyone of the next symptoms in the last 6 weeks?`,
  [`Manifesti dei Click articolari?`]: `Do you have joint click or joint lock during daily activities?`,
  [`Mano`]: `Hand`,
  [`Mano (DX)`]: `Hand (R)`,
  [`Mano (SX)`]: `Hand (L)`,
  [`Mano ad artiglio`]: `Claw hand deformity`,
  [`MANO DESTRA`]: `RIGHT HAND`,
  [`Mano rigida`]: `Stiff hand`,
  [`MANO SINISTRA`]: `LEFT HAND`,
  [`Mansioni domestiche`]: `Home responsabilities`,
  [`mantenimento di una posizione non adeguata per lungo tempo`]: `Maintaining a position for a long time`,
  [`mantenimento di una posizione non adeguata per lungotempo`]: `Maintaining a position for a long time`,
  [`mantenimento di una posizione non adeguata per un lungo periodo`]: `Maintaining a position for a long time`,
  [`mantenimento di una posizione non adeguata per un lungo periodo di tempo`]: `Maintaning a specific position for a long time`,
  [`mantenimento di una posizione per lungo tempo`]: `Maintaining a position for a long time`,
  [`mantenimento di una specifica posizione`]: `Maintaning a specific position`,
  [`mantenimento per lungo tempo di una posizione non adeguata`]: `Maintaining a position for a long time`,
  [`MARTELLO DESTRO`]: `RIGHT MALLEUS`,
  [`MARTELLO SINISTRO`]: `LEFT MALLEUS`,
  [`Martin RL, Hutt DM, Wukich DK. Validity of the Foot and Ankle Ability Measure (FAAM) in Diabetes Mellitus. Foot Ankle Int. 2009 Apr;30(4):297-302`]: `Martin RL, Hutt DM, Wukich DK. Validity of the Foot and Ankle Ability Measure (FAAM) in Diabetes Mellitus. Foot Ankle Int. 2009 Apr;30(4):297-302`,
  [`Massaggio o uso di massaggi vibratori`]: `massage or use of a vibrator`,
  [`Mastite/Infezione al seno`]: `Mastitis/breast infection`,
  [`Matles Test (DX)`]: `Matles Test (R)`,
  [`Matles Test (SX)`]: `Matles Test (L)`,
  [`Maudsley's Test (DX)`]: `Maudsley's Test (R)`,
  [`Maudsley's Test (SX)`]: `Maudsley's Test (L)`,
  [`Maughan EF, Lewis JS. Outcome measures in chronic low back pain. European Spine Journal. 2010 Sep 1;19(9):1484-94.`]: `Maughan EF, Lewis JS. Outcome measures in chronic low back pain. European Spine Journal. 2010 Sep 1;19(9):1484-94.`,
  [`McKenzie mechanical assessment`]: `McKenzie mechanical assessment`,
  [`McMurray's Test (DX)`]: `McMurray's Test (R)`,
  [`McMurray's Test (SX)`]: `McMurray's Test (L)`,
  [`Me lo impedisce completamente`]: `Unable to do `,
  [`Meccanismo traumatico`]: `Injury mechanism`,
  [`Media`]: `Moderate`,
  [`Medial Elbow Instability Test (DX)`]: `Medial Elbow Instability Test (R)`,
  [`Medial Elbow Instability Test (SX)`]: `Medial Elbow Instability Test (L)`,
  [`Medial Subtalar Glide Test (DX)`]: `Medial Subtalar Glide Test (R)`,
  [`Medial Subtalar Glide Test (SX)`]: `Medial Subtalar Glide Test (L)`,
  [`Median Nerve Upper Limb Neurodynamic Test`]: `Median Nerve Upper Limb Neurodynamic Test`,
  [`Median Nerve Upper Limb Neurodynamic Test (DX)`]: `Median Nerve Upper Limb Neurodynamic Test (R)`,
  [`Median Nerve Upper Limb Neurodynamic Test (SX)`]: `Median Nerve Upper Limb Neurodynamic Test (L)`,
  [`Mediastino`]: `Mediastinum`,
  [`Medio`]: `Moderate`,
  [`Melanoma`]: `Melanoma`,
  [`Melanoma multiplo`]: `Multiple skin cancers`,
  [`MEMBRANA INTERCOSTALE ESTERNA DI DESTRA`]: `RIGHT EXTERNAL INTERCOSTAL MEMBRANE`,
  [`MEMBRANA INTERCOSTALE ESTERNA DI SINISTRA`]: `LEFT EXTERNAL INTERCOSTAL MEMBRANE`,
  [`MEMBRANA INTERCOSTALE INTERNA DI DESTRA`]: `RIGHT INTERNAL INTERCOSTAL MEMBRANE`,
  [`MEMBRANA INTERCOSTALE INTERNA DI SINISTRA`]: `LEFT INTERNAL INTERCOSTAL MEMBRANE`,
  [`MEMBRANA INTEROSSEA ANTIBRACHIALE DESTRA`]: `INTEROSSEOUS MEMBRANE OF RIGHT FOREARM`,
  [`MEMBRANA INTEROSSEA ANTIBRACHIALE SINISTRA`]: `INTEROSSEOUS MEMBRANE OF LEFT FOREARM`,
  [`Membrana interossea brachiale (DX)`]: `Brachial interosseous membrane (R)`,
  [`Membrana interossea brachiale (SX)`]: `Brachial interosseous membrane (L)`,
  [`Membrana interossea crurale (DX)`]: `Crural interosseous membrane (R)`,
  [`Membrana interossea crurale (SX)`]: `Crural interosseous membrane (L)`,
  [`MEMBRANA INTEROSSEA CRURALE DESTRA`]: `INTEROSSEOUS MEMBRANE OF RIGHT LEG`,
  [`MEMBRANA INTEROSSEA CRURALE SINISTRA`]: `INTEROSSEOUS MEMBRANE OF LEFT LEG`,
  [`MEMBRANA OCCIPITOATLANTOIDEA ANTERIORE`]: `ANTERIOR ATLANTOOCCIPITAL MEMBRANE`,
  [`MEMBRANA OCCIPITOATLANTOIDEA POSTERIORE`]: `POSTERIOR ATLANTOOCCIPITAL MEMBRANE`,
  [`MEMBRANA OTTURATORIA DESTRA`]: `RIGHT OBTURATOR MEMBRANE`,
  [`MEMBRANA OTTURATORIA SINISTRA`]: `LEFT OBTURATOR MEMBRANE`,
  [`MEMBRANA PERINEALE`]: `PERINEAL MEMBRANE`,
  [`MEMBRANA TECTORIA`]: `TECTORIAL MEMBRANE`,
  [`MEMBRANA TIMPANICA DESTRA`]: `RIGHT TYMPANIC MEMBRANE`,
  [`MEMBRANA TIMPANICA SINISTRA`]: `LEFT TYMPANIC MEMBRANE`,
  [`MEMBRANA TIROIOIDEA`]: `THYROHYOID MEMBRANE`,
  [`Menisco discoide laterale`]: `Discoid lateral meniscus`,
  [`MENISCO LATERALE DEL GINOCCHIO DESTRO`]: `LATERAL MENISCUS OF RIGHT KNEE`,
  [`MENISCO LATERALE DEL GINOCCHIO SINISTRO`]: `LATERAL MENISCUS OF LEFT KNEE`,
  [`MENISCO MEDIALE DEL GINOCCHIO DESTRO`]: `MEDIAL MENISCUS OF RIGHT KNEE`,
  [`MENISCO MEDIALE DEL GINOCCHIO SINISTRO`]: `MEDIAL MENISCUS OF LEFT KNEE`,
  [`meno di 5 sigarette`]: `less than 5 cigarettes`,
  [`meno di due settimane`]: `Less than two weeks`,
  [`meno di un mese`]: `Less than a month`,
  [`Menorragia`]: `Menorrhagia `,
  [`Mensilmente`]: `Monthly`,
  [`meralgia parestesica`]: `Meralgia paresthetica`,
  [`MESOAPPENDICE`]: `MESOAPPENDIX`,
  [`MESOCOLON SIGMOIDEO`]: `SIGMOID MESOCOLON`,
  [`MESOCOLON TRASVERSO`]: `TRANSVERSE MESOCOLON`,
  [`MESOMETRIO (F)`]: `MESOMETRIUM (F)`,
  [`MESOOVARIO (F)`]: `MESOVARIUM (F)`,
  [`MESOSALPINGE (F)`]: `MESOSALPINX (F)`,
  [`Metabolica/nutrizionale`]: `Metabolic/nutritional`,
  [`Metabolico/nutrizionale`]: `Metabolic/nutritional`,
  [`Mettere un oggetto su uno scaffale alto?`]: `Placing an object on a high shelf?`,
  [`Mettersi i calzini o le calze`]: `Putting on socks/stockings`,
  [`Mi aspetto che la mia salute andra' peggiorando`]: `I expect my health to get worse`,
  [`Mi dà problemi molto raramente`]: `I have problems rarely`,
  [`Mi dà problemi molto spesso`]: `I have problems often`,
  [`Mi dà problemi ogni tanto`]: `I have problems sometimes`,
  [`Mi da problemi raramente`]: `It rarely gives me problems`,
  [`Mi da problemi sempre`]: `It always gives me problems`,
  [`Mi da problemi spesso`]: `It frequently gives me problems`,
  [`Mi fa male prendermi cura di me stesso/a e sono lento/a e prudente`]: `It is painful to look after myself and I am slow and careful`,
  [`Mi fa male, ma riesco a viaggiare e spostarmi per più di due ore`]: `Pain is bad but I manage journeys over two hours`,
  [`Mi ha dato problemi in passato`]: `I have had problems in the past but now never`,
  [`Mi ha limitato discretamente`]: `Moderately limited`,
  [`Mi ha limitato leggermente`]: `Slightly limited`,
  [`Mi ha limitato molto`]: `Very limited`,
  [`Mi pare di ammalarmi un po' piu' facilmente degli altri`]: `I seem to get sick a little easier than other people`,
  [`mi risveglia alla notte`]: `It awakes me at night`,
  [`mi risveglia di notte`]: `It awakes me at night`,
  [`Mi sento meno capace, meno fiducioso o meno utile a causa del mio problema al braccio, alla spalla o alla mano`]: `I feel less capable, less confident or less useful because of my arm, shoulder or hand problem`,
  [`Mialgia/trigger point all'avambraccio`]: `Forearm muscle trigger pts`,
  [`Miastenia Gravis`]: `Miastenia Gravis`,
  [`Michener LA, Snyder AR, Leggin BG. Responsiveness of the numeric pain rating scale in patients with shoulder pain and the effect of surgical status. Journal of sport rehabilitation. 2011 Feb 1;20(1):115.`]: `Michener LA, Snyder AR, Leggin BG. Responsiveness of the numeric pain rating scale in patients with shoulder pain and the effect of surgical status. Journal of sport rehabilitation. 2011 Feb 1;20(1):115.`,
  [`Micidiale`]: `Killing`,
  [`Midollo spinale`]: `Spinal cord`,
  [`Mieloma multiplo`]: `Multiple myeloma`,
  [`MILZA`]: `SPLEEN`,
  [`MIOCARDIO`]: `MYOCARDIUM`,
  [`Miocardite`]: `Myocarditis`,
  [`Miofasciale`]: `Myofascial`,
  [`MIOMETRIO (F)`]: `MYOMETRIUM (F)`,
  [`Miopia`]: `Myopia`,
  [`miosite ossificante`]: `Myositis ossificans`,
  [`Miosite ossificante alla coscia`]: `Myositis ossificans thigh`,
  [`Miosite ossificante del braccio`]: `Upper arm myositis ossificans`,
  [`Misero`]: `Miserable`,
  [`Misurazione antropometrica`]: `Anthropometry measurement`,
  [`Misurazione articolarità`]: `ROM measurment`,
  [`Misurazione flessibilità`]: `Flexibility measurment`,
  [`Misurazione flessibilita' muscolare`]: `Myofascial flexibility Measurement`,
  [`Misurazione forza`]: `Strength measurment`,
  [`Misurazione performance`]: `Physical performance measures`,
  [`Misurazione ROM`]: `ROM Measurement`,
  [`Misurazione ROM contro resistenza`]: `Resisted ROM Measurement`,
  [`Misure di performance fisica`]: `Physical Performance Measures`,
  [`Moderata`]: `Moderate`,
  [`Moderatamente`]: `Moderately  `,
  [`Moderato`]: `Moderate`,
  [`Modified Bent Knee Stretch Test (DX)`]: `Modified Bent Knee Stretch Test (R)`,
  [`Modified Bent Knee Stretch Test (SX)`]: `Modified Bent Knee Stretch Test (L)`,
  [`Modified Biering-Sorensen Test`]: `Modified Biering-Sorensen Test`,
  [`Modified Hexagon Hop Test (DX)`]: `Modified Hexagon Hop Test (R)`,
  [`Modified Hexagon Hop Test (SX)`]: `Modified Hexagon Hop Test (L)`,
  [`Modified Sharp-Purser Test`]: `Modified Sharp-Purser Test`,
  [`Modified Stroke Test (DX)`]: `Modified Stroke Test (R)`,
  [`Modified Stroke Test (SX)`]: `Modified Stroke Test (L)`,
  [`Modified Thomas Test (DX)`]: `Modified Thomas Test (R)`,
  [`Modified Thomas Test (SX)`]: `Modified Thomas Test (L)`,
  [`MODIFIED TQR (TOTAL QUALITY RECOVERY)`]: `MODIFIED TQR (TOTAL QUALITY RECOVERY)`,
  [`Moltissimo`]: `Extremely`,
  [`Molto`]: `Severely`,
  [`Molto affaticato`]: `Really tired`,
  [`Molto agitato`]: `Have you been a very nervous person?`,
  [`Molto alta`]: `Very high`,
  [`Molto alto`]: `Very high`,
  [`Molto basso`]: `Really low`,
  [`Molto buona`]: `Very good`,
  [`Molto dolorante`]: `Really sore`,
  [`Molto forte`]: `Very severe`,
  [`Molto intenso`]: `Extreme`,
  [`Molto lieve`]: `Very mild`,
  [`Molto poca`]: `Really low`,
  [`Molto poco`]: `Slightly`,
  [`Molto scarsa`]: `Very poor`,
  [`Molto spesso`]: `Very often`,
  [`Molto stressato`]: `A lot stressed`,
  [`Molto tempo`]: `A good bit of the time`,
  [`Moncone`]: `Stump`,
  [`Mononucleosi`]: `Glandular Fever`,
  [`Morbido`]: `Soft `,
  [`Morbo di Crohn`]: `Chron's disease`,
  [`Morbo di Freiberg - osteocondrosi della testa metatarsale`]: `Freiberg's  disease - osteochondrosis of MT head`,
  [`morbo di kienbock`]: `Kienbock's disease`,
  [`morbo di Kohler`]: `Kohler disease`,
  [`Morbo di Kohler - osteocondrosi del navicolare`]: `Kohler's disease - navicular osteochondrosis`,
  [`Morbo di Paget`]: `Paget disease`,
  [`Morbo di Panner (necrosi avascolare del gomito)`]: `Panner disease (elbow avascular necrosis)`,
  [`morbo di Perthes`]: `Perthes disease`,
  [`Morbo di Perthes`]: `Perthes disease`,
  [`morbo di Scheuermann`]: `Sheuermann's disease`,
  [`Morbo di Scheuermanns`]: `Scheuermanns disease`,
  [`morbo di Sever`]: `Sever desease`,
  [`Motocross`]: `Motocross`,
  [`Mountain bike`]: `Mountain bike`,
  [`Movimento`]: `movement`,
  [`Movimento costale in espiro (DX)`]: `Ribs movement in exhale (R)`,
  [`Movimento costale in espiro (SX)`]: `Ribs movement in exhale (L)`,
  [`Movimento costale in inspiro (DX)`]: `Ribs movement in inhale (R)`,
  [`Movimento costale in inspiro (SX)`]: `Ribs movement in inhale (L)`,
  [`Movimento incontrollato`]: `Uncontrolled movement`,
  [`movimento o attivita'`]: `Movement or activity`,
  [`movimento o attivita'o`]: `Movement or activity`,
  [`Moving Valgus Stress Test (DX)`]: `Moving Valgus Stress Test (R)`,
  [`Moving Valgus Stress Test (SX)`]: `Moving Valgus Stress Test (L)`,
  [`mpingement del nervo plantare laterale (di Baxter)`]: `lateral calcaneal nerve impingment (Baxter nerve)`,
  [`Multipla`]: `Multiple `,
  [`Multiple Single Leg Hop Stabilization Test (DX)`]: `Multiple Single Leg Hop Stabilization Test (R)`,
  [`Multiple Single Leg Hop Stabilization Test (SX)`]: `Multiple Single Leg Hop Stabilization Test (L)`,
  [`Muoversi con I mezzi di trasporto senza difficolta'`]: `Manage transportation needs (getting from one place to another)`,
  [`Murmure/insufficienza valvola`]: `Murmurs/Valvular disease`,
  [`MUSCOLI ABDUTTORI DELL'ANCA DESTRA`]: `RIGHT HIP ABDUCTOR MUSCLES`,
  [`MUSCOLI ABDUTTORI DELL'ANCA SINISTRA`]: `LEFT HIP ABDUCTOR MUSCLES`,
  [`MUSCOLI ADDOMINALI`]: `ABDOMINAL MUSCLES`,
  [`Muscoli adduttori (DX)`]: `Adductors muscles (R)`,
  [`Muscoli adduttori (SX)`]: `Adductors muscles (L)`,
  [`MUSCOLI ADDUTTORI DELL'ANCA DESTRA`]: `RIGHT HIP ADDUCTOR MUSCLES`,
  [`MUSCOLI ADDUTTORI DELL'ANCA SINISTRA`]: `LEFT HIP ADDUCTOR MUSCLES`,
  [`Muscoli antagonisti`]: `Antagonistic Muscles`,
  [`MUSCOLI DEL PALATO MOLLE`]: `MUSCLES OF SOFT PALATE AND FAUCES`,
  [`MUSCOLI DELLA CUFFIA DEI ROTATORI DI DESTRA`]: `RIGHT ROTATOR CUFF MUSCLES`,
  [`MUSCOLI DELLA CUFFIA DEI ROTATORI DI SINISTRA`]: `LEFT ROTATOR CUFF MUSCLES`,
  [`MUSCOLI DELLA LARINGE`]: `LARYNGEAL MUSCLES`,
  [`MUSCOLI DELLE OSSA DELL'ORECCHIO DESTRO`]: `MUSCLES OF RIGHT AUDITORY OSSICLES`,
  [`MUSCOLI DELLE OSSA DELL'ORECCHIO SINISTRO`]: `MUSCLES OF LEFT AUDITORY OSSICLES`,
  [`MUSCOLI DORSALI`]: `MUSCES OF THORACX`,
  [`MUSCOLI ELEVATORI DELLE COSTE DI DESTRA`]: `RIGHT LEVATORES COSTARUM MUSCLES`,
  [`MUSCOLI ELEVATORI DELLE COSTE DI SINISTRA`]: `LEFT LEVATORES COSTARUM MUSCLES`,
  [`Muscoli erettori della colonna`]: `Erector spinae muscles`,
  [`Muscoli erettori della colonna (DX)`]: `Erector spinae muscles (R)`,
  [`Muscoli erettori della colonna (SX)`]: `Erector spinae muscles (L)`,
  [`MUSCOLI ERETTORI DELLA COLONNA DI DESTRA`]: `RIGHT ERECTOR SPINAE MUSCLE`,
  [`MUSCOLI ERETTORI DELLA COLONNA DI SINISTRA`]: `LEFT ERECTOR SPINAE MUSCLE`,
  [`Muscoli estensori breve e lungo del pollice (DX)`]: `Extensor pollicis longus and brevis muscles (R)`,
  [`Muscoli estensori breve e lungo del pollice (SX)`]: `Extensor pollicis longus and brevis muscles (L)`,
  [`Muscoli estensori del carpo (DX)`]: `Extensor carpi muscles (R)`,
  [`Muscoli estensori del carpo (SX)`]: `Extensor carpi muscles (L)`,
  [`MUSCOLI ESTENSORI DEL CARPO DI DESTRA`]: `RIGHT EXTENSOR CARPI MUSCLES`,
  [`MUSCOLI ESTENSORI DEL CARPO DI SINISTRA`]: `LEFT EXTENSOR CARPI MUSCLES`,
  [`Muscoli estensori del collo`]: `Neck extensor muscles`,
  [`MUSCOLI ESTENSORI DEL COLLO`]: `NECK EXTENSOR MUSCLES`,
  [`MUSCOLI ESTENSORI DEL COLLO DI DESTRA`]: `RIGHT NECK EXTENSORS MUSCLES`,
  [`MUSCOLI ESTENSORI DEL COLLO DI SINISTRA`]: `LEFT NECK EXTENSORS MUSCLES`,
  [`Muscoli estensori dell'anca (DX)`]: `Hip extensors muscles (R)`,
  [`Muscoli estensori dell'anca (SX)`]: `Hip extensors muscles (L)`,
  [`MUSCOLI ESTENSORI DELL'ANCA DESTRA`]: `RIGHT HIP EXTENSOR MUSCLES`,
  [`MUSCOLI ESTENSORI DELL'ANCA SINISTRA`]: `LEFT HIP EXTENSOR MUSCLES`,
  [`Muscoli estensori radiali del carpo (lungo e breve) (DX)`]: `Extensor carpi radialis longus and brevis muscles (R)`,
  [`Muscoli estensori radiali del carpo (lungo e breve) (SX)`]: `Extensor carpi radialis longus and brevis muscles (L)`,
  [`MUSCOLI ESTRINSECI DELL'OCCHIO DESTRO`]: `EXTRINSIC MUSCLES OF RIGHT EYEBALL`,
  [`MUSCOLI ESTRINSECI DELL'OCCHIO SINISTRO`]: `EXTRINSIC MUSCLES OF LEFT EYEBALL`,
  [`Muscoli flessori del carpo (DX)`]: `Flexor carpi muscles (R)`,
  [`Muscoli flessori del carpo (SX)`]: `Flexor carpi muscles (L)`,
  [`MUSCOLI FLESSORI DEL CARPO DI DESTRA`]: `RIGHT FLEXOR CARPI MUSCLES`,
  [`MUSCOLI FLESSORI DEL CARPO DI SINISTRA`]: `LEFT FLEXOR CARPI MUSCLES`,
  [`Muscoli flessori del collo`]: `Neck flexors muscles`,
  [`MUSCOLI FLESSORI DEL COLLO`]: `NECK FLEXOR MUSCLES`,
  [`MUSCOLI FLESSORI DEL COLLO DI DESTRA`]: `RIGHT NECK FLEXORS MUSCLES`,
  [`MUSCOLI FLESSORI DEL COLLO DI SINISTRA`]: `LEFT NECK FLEXORS MUSCLES`,
  [`Muscoli flessori dell'anca (DX)`]: `Hip flexors muscles (R)`,
  [`Muscoli flessori dell'anca (SX)`]: `Hip flexors muscles (L)`,
  [`MUSCOLI FLESSORI DELL'ANCA DESTRA`]: `RIGHT HIP FLEXORS MUSCLES`,
  [`MUSCOLI FLESSORI DELL'ANCA SINISTRA`]: `LEFT HIP FLEXORS MUSCLES`,
  [`MUSCOLI FLESSORI PROFONDI DEL COLLO DI DESTRA`]: `RIGHT DEEP NECK FLEXORS MUSCLES`,
  [`MUSCOLI FLESSORI PROFONDI DEL COLLO DI SINISTRA`]: `LEFT DEEP NECK FLEXORS MUSCLES`,
  [`Muscoli gastrocnemi (DX)`]: `Gastrocnemius muscles (R)`,
  [`Muscoli gastrocnemi (SX)`]: `Gastrocnemius muscles (L)`,
  [`MUSCOLI GASTROCNEMI DI DESTRA`]: `RIGHT GASTROCNEMIUS MUSCLES`,
  [`MUSCOLI GASTROCNEMI DI SINISTRA`]: `LEFT GASTROCNEMIUS MUSCLES`,
  [`MUSCOLI GLUTEI DI DESTRA`]: `RIGHT GLUTES MUSCLES`,
  [`MUSCOLI GLUTEI DI SINISTRA`]: `LEFT GLUTES MUSCLES`,
  [`MUSCOLI INCLINATORI DEL TRONCO DI DESTRA`]: `RIGHT TRUNK INCLINATOR MUSCLES`,
  [`MUSCOLI INCLINATORI DEL TRONCO DI SINISTRA`]: `LEFT TRUNK INCLINATOR MUSCLES`,
  [`MUSCOLI INFRAIOIDEI`]: `INFRAHYOID MUSCLES`,
  [`Muscoli intercostali esterni (DX)`]: `External intercostal muscles (R)`,
  [`Muscoli intercostali esterni (SX)`]: `External intercostal muscles (L)`,
  [`MUSCOLI INTERCOSTALI ESTERNI DI DESTRA`]: `RIGHT EXTERNAL INTERCOSTAL MUSCLES`,
  [`MUSCOLI INTERCOSTALI ESTERNI DI SINISTRA`]: `LEFT EXTERNAL INTERCOSTAL MUSCLES`,
  [`Muscoli intercostali interni (DX)`]: `Internal intercostal muscles (R)`,
  [`Muscoli intercostali interni (SX)`]: `Internal intercostal muscles (L)`,
  [`MUSCOLI INTERCOSTALI INTERNI DI DESTRA`]: `RIGHT INTERNAL INTERCOSTAL MUSCLES`,
  [`MUSCOLI INTERCOSTALI INTERNI DI SINISTRA`]: `LEFT INTERNAL INTERCOSTAL MUSCLES`,
  [`MUSCOLI INTERCOSTALI INTIMI DI DESTRA`]: `RIGHT INNERMOST INTERCOSTAL MUSCLES`,
  [`MUSCOLI INTERCOSTALI INTIMI DI SINISTRA`]: `LEFT INNERMOST INTERCOSTAL MUSCLES`,
  [`MUSCOLI INTEROSSEI DORSALI DEL PIEDE DESTRO`]: `DORSAL INTEROSSEI MUSCLES OF RIGHT FOOT`,
  [`MUSCOLI INTEROSSEI DORSALI DEL PIEDE SINISTRO`]: `DORSAL INTEROSSEI MUSCLES OF LEFT FOOT`,
  [`Muscoli interossei dorsali della mano (DX)`]: `Hand dorsal interossei muscles (R)`,
  [`Muscoli interossei dorsali della mano (SX)`]: `Hand dorsal interossei muscles (L)`,
  [`MUSCOLI INTEROSSEI DORSALI DELLA MANO DESTRA`]: `RIGHT DORSAL INTEROSSEI MUSCLES`,
  [`MUSCOLI INTEROSSEI DORSALI DELLA MANO SINISTRA`]: `LEFT DORSAL INTEROSSEI MUSCLES`,
  [`Muscoli interossei palmari della mano (DX)`]: `Hand palmar interossei muscles (R)`,
  [`Muscoli interossei palmari della mano (SX)`]: `Hand palmar interossei muscles (L)`,
  [`MUSCOLI INTEROSSEI PALMARI DELLA MANO DESTRA`]: `RIGHT PALMAR INTEROSSEI MUSCLES `,
  [`MUSCOLI INTEROSSEI PALMARI DELLA MANO SINISTRA`]: `LEFT PALMAR INTEROSSEI MUSCLES `,
  [`MUSCOLI INTEROSSEI PLANTARI DEL PIEDE DESTRO`]: `DORSAL INTEROSSEI MUSCLES OF RIGHT FOOT`,
  [`MUSCOLI INTEROSSEI PLANTARI DEL PIEDE SINISTRO`]: `DORSAL INTEROSSEI MUSCLES OF LEFT FOOT`,
  [`MUSCOLI INTERSPINOSI CERVICALI`]: `INTERSPINALES CERVICIS MUSCLES`,
  [`MUSCOLI INTERSPINOSI LOMBARI`]: `INTERSPINALES LUMBORUM MUSCLES`,
  [`MUSCOLI INTERSPINOSI TORACICI`]: `INTERSPINALES THORACIS MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI CERVICALI ANTERIORI DI DESTRA`]: `RIGHT MEDIAL ANTERIOR CERVICAL INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI CERVICALI ANTERIORI DI SINISTRA`]: `RIGHT MEDIAL ANTERIOR CERVICAL INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI CERVICALI POSTERIORI DI DESTRA`]: `RIGHT MEDIAL POSTERIOR CERVICAL INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI CERVICALI POSTERIORI DI SINISTRA`]: `LEFT MEDIAL POSTERIOR CERVICAL INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI LOMBARI LATERALI DI DESTRA`]: `RIGHT LATERAL LUMBAR INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI LOMBARI LATERALI DI SINISTRA`]: `LEFT LATERAL LUMBAR INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI LOMBARI MEDIALI DI DESTRA`]: `RIGHT MEDIAL LUMBAR INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTERTRASVERSARI LOMBARI MEDIALI DI SINISTRA`]: `LEFT MEDIAL LUMBAR INTERTRASVERSARII MUSCLES`,
  [`MUSCOLI INTRINSECI DELL'OCCHIO DESTRO`]: `INTRINSIC MUSCLES OF RIGHT EYEBALL`,
  [`MUSCOLI INTRINSECI DELL'OCCHIO SINISTRO`]: `INTRINSIC MUSCLES OF LEFT EYEBALL`,
  [`MUSCOLI INTRINSECI DELLA LINGUA`]: `INTRINSIC MUSCLES OF TONGUE`,
  [`Muscoli ischiocrurali (DX)`]: `Hamstring muscles (R)`,
  [`Muscoli ischiocrurali (SX)`]: `Hamstring muscles (L)`,
  [`MUSCOLI ISCHIOCRURALI DI DESTRA`]: `RIGHT HAMSTRING MUSCLES`,
  [`MUSCOLI ISCHIOCRURALI DI SINISTRA`]: `LEFT HAMSTRING MUSCLES`,
  [`Muscoli ischiocrurali laterali (DX)`]: `Lateral hamstring muscles (R)`,
  [`Muscoli ischiocrurali laterali (SX)`]: `Lateral hamstring muscles (L)`,
  [`MUSCOLI ISCHIOCRURALI LATERALI DI DESTRA`]: `RIGHT LATERAL HAMSTRING MUSCLES`,
  [`MUSCOLI ISCHIOCRURALI LATERALI DI SINISTRA`]: `LEFT LATERAL HAMSTRING MUSCLES`,
  [`Muscoli ischiocrurali mediali (DX)`]: `Medial hamstring muscles (R)`,
  [`Muscoli ischiocrurali mediali (SX)`]: `Medial hamstring muscles (L)`,
  [`MUSCOLI ISCHIOCRURALI MEDIALI DI DESTRA`]: `RIGHT MEDIAL HAMSTRING MUSCLES`,
  [`MUSCOLI ISCHIOCRURALI MEDIALI DI SINISTRA`]: `LEFT MEDIAL HAMSTRING MUSCLES`,
  [`MUSCOLI LOMBRICALI DEL PIEDE DESTRO`]: `LUMBRICALS MUSCLES OF RIGHT FOOT`,
  [`MUSCOLI LOMBRICALI DEL PIEDE SINISTRO`]: `LUMBRICALS MUSCLES OF LEFT FOOT`,
  [`Muscoli lombricali della mano (DX)`]: `Lumbrical muscles of hand `,
  [`Muscoli lombricali della mano (SX)`]: `Opponens digiti minimi muscle (L)`,
  [`MUSCOLI LOMBRICALI DELLA MANO DESTRA`]: `LUMBRICALS MUSCLES OF RIGHT HAND`,
  [`MUSCOLI LOMBRICALI DELLA MANO SINISTRA`]: `LUMBRICALS MUSCLES OF LEFT HAND`,
  [`MUSCOLI MASTICATORI DI DESTRA`]: `RIGHT MASTICATORY MUSCLES`,
  [`MUSCOLI MASTICATORI DI SINISTRA`]: `LEFT MASTICATORY MUSCLES`,
  [`Muscoli medio e piccolo gluteo (DX)`]: `Gluteus medium & minimus muscles (R)`,
  [`Muscoli medio e piccolo gluteo (SX)`]: `Gluteus medium & minimus muscle (L)`,
  [`MUSCOLI PERINEALI`]: `PERINEAL MUSCLES`,
  [`MUSCOLI PERONIERI DI DESTRA`]: `RIGHT PERONEUS MUSCLES`,
  [`MUSCOLI PERONIERI DI SINISTRA`]: `LEFT PERONEUS MUSCLES`,
  [`MUSCOLI PETTORALI DI DESTRA`]: `RIGHT PECTORAL MUSCLES`,
  [`MUSCOLI PETTORALI DI SINISTRA`]: `LEFT PECTORAL MUSCLES`,
  [`Muscoli pronatori dell'avambraccio (DX)`]: `Forearm pronator muscles (R)`,
  [`Muscoli pronatori dell'avambraccio (SX)`]: `Forearm pronator muscles (L)`,
  [`MUSCOLI PRONATORI DELL'AVAMBRACCIO DESTRO`]: `PRONATOR MUSCLES OF LEFT FOREARM`,
  [`MUSCOLI PRONATORI DELL'AVAMBRACCIO SINISTRO`]: `PRONATOR MUSCLES OF LEFT FOREARM`,
  [`MUSCOLI RETTOUTERINI (F)`]: `RECTOUTERINUS MUSCLE`,
  [`MUSCOLI RETTOVESCICALI`]: `RECTOVESICALIS MUSCLE`,
  [`Muscoli romboidei (DX)`]: `Rhomboid muscles`,
  [`Muscoli romboidei (SX)`]: `Rhomboid muscles`,
  [`MUSCOLI ROMBOIDEI DI DESTRA`]: `RIGHT RHOMBOID MUSCLES`,
  [`MUSCOLI ROMBOIDEI DI SINISTRA`]: `LEFT RHOMBOID MUSCLES`,
  [`MUSCOLI ROTATORI DEL COLLO DI DESTRA`]: `RIGHT ROTATORES CERVICIS MUSCLES`,
  [`MUSCOLI ROTATORI DEL COLLO DI SINISTRA`]: `LEFT ROTATORES CERVICIS MUSCLES`,
  [`MUSCOLI ROTATORI DEL TRONCO DI DESTRA`]: `RIGHT TRUNK ROTATOR MUSCLES`,
  [`MUSCOLI ROTATORI DEL TRONCO DI SINISTRA`]: `LEFT TRUNK ROTATOR MUSCLES`,
  [`Muscoli rotatori esterni dell'anca (DX)`]: `Hip external rotators muscles (R)`,
  [`Muscoli rotatori esterni dell'anca (SX)`]: `Hip external rotators muscles (L)`,
  [`MUSCOLI ROTATORI ESTERNI DELL'ANCA DESTRA`]: `RIGHT HIP EXTERNAL ROTATOR MUSCLES`,
  [`MUSCOLI ROTATORI ESTERNI DELL'ANCA SINISTRA`]: `LEFT HIP EXTERNAL ROTATOR MUSCLES`,
  [`Muscoli rotatori esterni della spalla (DX)`]: `Shoulder external rotators muscles (R)`,
  [`Muscoli rotatori esterni della spalla (SX)`]: `Shoulder external rotators muscles (L)`,
  [`Muscoli rotatori interni della spalla (DX)`]: `Shoulder internal rotators muscles (R)`,
  [`Muscoli rotatori interni della spalla (SX)`]: `Shoulder internal rotators muscles (L)`,
  [`MUSCOLI ROTATORI TORACICI DI DESTRA`]: `RIGHT ROTATORES THORACIS MUSCLES`,
  [`MUSCOLI ROTATORI TORACICI DI SINISTRA`]: `LEFT ROTATORES THORACIS MUSCLES`,
  [`Muscoli sacrospinali`]: `Sacrospinalis muscles`,
  [`Muscoli sacrospinali (DX)`]: `Sacrospinalis muscles (R)`,
  [`Muscoli sacrospinali (SX)`]: `Sacrospinalis muscles (L)`,
  [`MUSCOLI SACROSPINALI DI DESTRA`]: `RIGHT SACROSPINALIS MUSCLE`,
  [`MUSCOLI SACROSPINALI DI SINISTRA`]: `LEFT SACROSPINALIS MUSCLE`,
  [`Muscoli scaleni (DX)`]: `Scalenus muscles (R)`,
  [`Muscoli scaleni (SX)`]: `Scalenus muscles (L)`,
  [`MUSCOLI SCALENI DI DESTRA`]: `RIGHT SCALENUS MUSCLES`,
  [`MUSCOLI SCALENI DI SINISTRA`]: `LEFT SCALENUS MUSCLES`,
  [`Muscoli sinergici`]: `Synergistic Muscles`,
  [`Muscoli sopraioidei`]: `Suprahyoid muscles`,
  [`MUSCOLI SOPRAIOIDEI DI DESTRA`]: `RIGHT SUPRAHYOID MUSCLES`,
  [`MUSCOLI SOPRAIOIDEI DI SINISTRA`]: `LEFT SUPRAHYOID MUSCLES`,
  [`Muscoli sottoioidei`]: `Infrahyoid muscles`,
  [`MUSCOLI SPINOTRASVERSARI DI DESTRA`]: `RIGHT SPINOTRANSVERSALES MUSCLE`,
  [`MUSCOLI SPINOTRASVERSARI DI SINISTRA`]: `LEFT SPINOTRANSVERSALES MUSCLE`,
  [`Muscoli suboccipitali`]: `Suboccipital muscles`,
  [`MUSCOLI SUBOCCIPITALI`]: `SUBOCCIPITAL MUSCLES`,
  [`Muscoli suboccipitali (DX)`]: `Suboccipital muscles (R)`,
  [`Muscoli suboccipitali (SX)`]: `Suboccipital muscles (L)`,
  [`MUSCOLI SUBOCCIPITALI DI DESTRA`]: `RIGHT SUBOCCIPITAL MUSCLES`,
  [`MUSCOLI SUBOCCIPITALI DI SINISTRA`]: `LEFT SUBOCCIPITAL MUSCLES`,
  [`Muscoli trasversospinali (DX)`]: `Trasversusspinalis muscles (R)`,
  [`Muscoli trasversospinali (SX)`]: `Trasversusspinalis muscles (L)`,
  [`MUSCOLI TRASVERSOSPINALI DI DESTRA`]: `RIGHT TRANSVERSOSPINALES MUSCLE`,
  [`MUSCOLI TRASVERSOSPINALI DI SINISTRA`]: `LEFT TRANSVERSOSPINALES MUSCLE`,
  [`Muscolo abduttore breve del pollice (DX)`]: `Abductor pollicis brevis muscle (R)`,
  [`Muscolo abduttore breve del pollice (SX)`]: `Abductor pollicis brevis muscle (L)`,
  [`MUSCOLO ABDUTTORE BREVE DEL POLLICE DESTRO`]: `RIGHT ABDUCTOR POLLICIS BREVIS MUSCLE`,
  [`MUSCOLO ABDUTTORE BREVE DEL POLLICE SINISTRO`]: `LEFT ABDUCTOR POLLICIS BREVIS MUSCLE`,
  [`Muscolo abduttore del mignolo (DX)`]: `Abductor digiti minimi muscle (R)`,
  [`Muscolo abduttore del mignolo (SX)`]: `Abductor digiti minimi muscle (L)`,
  [`MUSCOLO ABDUTTORE DEL MIGNOLO DESTRO`]: `RIGHT HAND ABDUCTOR DIGITI MINIMI MUSCLE`,
  [`MUSCOLO ABDUTTORE DEL MIGNOLO SINISTRO`]: `LEFT HAND ABDUCTOR DIGITI MINIMI MUSCLE`,
  [`Muscolo abduttore del V dito (DX)`]: `Abductor digiti minimi muscle of foot (R)`,
  [`Muscolo abduttore del V dito (SX)`]: `Abductor digiti minimi muscle of foot (L)`,
  [`MUSCOLO ABDUTTORE DEL V DITO DESTRO`]: `RIGHT ABDUCTOR DIGITI MINIMI MUSCLE`,
  [`MUSCOLO ABDUTTORE DEL V DITO SINISTRO`]: `LEFT ABDUCTOR DIGITI MINIMI MUSCLE`,
  [`Muscolo abduttore dell'alluce (DX)`]: `Abductor hallucis muscle (R)`,
  [`Muscolo abduttore dell'alluce (SX)`]: `Abductor hallucis muscle (L)`,
  [`MUSCOLO ABDUTTORE DELL'ALLUCE DESTRO`]: `RIGHT ABDUTTOR HALLUCIS MUSCLE`,
  [`MUSCOLO ABDUTTORE DELL'ALLUCE SINISTRO`]: `LEFT ABDUTTOR HALLUCIS MUSCLE`,
  [`Muscolo abduttore lungo del pollice (DX)`]: `Abductor pollicis longus muscle (R)`,
  [`Muscolo abduttore lungo del pollice (SX)`]: `Abductor pollicis longus muscle (L)`,
  [`MUSCOLO ABDUTTORE LUNGO DEL POLLICE DESTRO`]: `RIGHT ABDUCTOR POLLICIS LONGUS MUSCLE`,
  [`MUSCOLO ABDUTTORE LUNGO DEL POLLICE SINISTRO`]: `LEFT ABDUCTOR POLLICIS LONGUS MUSCLE`,
  [`MUSCOLO ADDUTTORE BREVE DESTRO`]: `RIGHT ADDUCTOR BREVIS MUSCLE`,
  [`MUSCOLO ADDUTTORE BREVE SINISTRO`]: `LEFT ADDUCTOR BREVIS MUSCLE`,
  [`MUSCOLO ADDUTTORE DEL POLLICE DESTRO`]: `RIGHT ADDUCTOR POLLICIS MUSCLE`,
  [`MUSCOLO ADDUTTORE DEL POLLICE DESTRO (CAPO OBLIQUO)`]: `RIGHT ADDUCTOR POLLICIS MUSCLE (OBLIQUE HEAD)`,
  [`MUSCOLO ADDUTTORE DEL POLLICE DESTRO (CAPO TRASVERSO)`]: `RIGHT ADDUCTOR POLLICIS MUSCLE (TRANSVERSE HEAD)`,
  [`MUSCOLO ADDUTTORE DEL POLLICE SINISTRO`]: `LEFT ADDUCTOR POLLICIS MUSCLE`,
  [`MUSCOLO ADDUTTORE DEL POLLICE SINISTRO (CAPO OBLIQUO)`]: `LEFT ADDUCTOR POLLICIS MUSCLE (OBLIQUE HEAD)`,
  [`MUSCOLO ADDUTTORE DEL POLLICE SINISTRO (CAPO TRASVERSO)`]: `LEFT ADDUCTOR POLLICIS MUSCLE (TRANSVERSE HEAD)`,
  [`Muscolo adduttore dell'alluce (DX)`]: `Adductor hallucis muscle (R)`,
  [`Muscolo adduttore dell'alluce (SX)`]: `Adductor hallucis muscle (L)`,
  [`MUSCOLO ADDUTTORE DELL'ALLUCE DESTRO`]: `RIGHT ADDUCTOR HALLUCIS MUSCLE`,
  [`MUSCOLO ADDUTTORE DELL'ALLUCE DESTRO (CAPO OBLIQUO)`]: `RIGHT ADDUCTOR HALLUCIS MUSCLE (OBLIQUE HEAD)`,
  [`MUSCOLO ADDUTTORE DELL'ALLUCE DESTRO (CAPO TRASVERSO)`]: `RIGHT ADDUCTOR HALLUCIS MUSCLE (TRANSVERSE HEAD)`,
  [`MUSCOLO ADDUTTORE DELL'ALLUCE SINISTRO`]: `LEFT ADDUCTOR HALLUCIS MUSCLE`,
  [`MUSCOLO ADDUTTORE DELL'ALLUCE SINISTRO (CAPO OBLIQUO)`]: `LEFT ADDUCTOR HALLUCIS MUSCLE (OBLIQUE HEAD)`,
  [`MUSCOLO ADDUTTORE DELL'ALLUCE SINISTRO (CAPO TRASVERSO)`]: `LEFT ADDUCTOR HALLUCIS MUSCLE (TRANSVERSE HEAD)`,
  [`Muscolo adduttore lungo (DX)`]: `Adductor longus muscle (R)`,
  [`Muscolo adduttore lungo (SX)`]: `Adductor longus muscle (L)`,
  [`MUSCOLO ADDUTTORE LUNGO DESTRO`]: `RIGHT ADDUCTOR LONGUS MUSCLE`,
  [`MUSCOLO ADDUTTORE LUNGO SINISTRO`]: `LEFT ADDUCTOR LONGUS MUSCLE`,
  [`MUSCOLO ADDUTTORE MINIMO DESTRO`]: `RIGHT ADDUCTOR MINIMUS MUSCLE`,
  [`MUSCOLO ADDUTTORE MINIMO SINISTRO`]: `LEFT ADDUCTOR MINIMUS MUSCLE`,
  [`Muscolo anconeo (DX)`]: `Anconeus muscle (R)`,
  [`Muscolo anconeo (SX)`]: `Anconeus muscle (L)`,
  [`MUSCOLO ANCONEO DESTRO`]: `RIGHT ANCONEUS MUSCLE`,
  [`MUSCOLO ANCONEO SINISTRO`]: `LEFT ANCONEUS MUSCLE`,
  [`MUSCOLO ARITENOIDEO OBLIQUO DESTRO`]: `RIGHT OBLIQUE ARYTENOID MUSCLE`,
  [`MUSCOLO ARITENOIDEO OBLIQUO SINISTRO`]: `LEFT OBLIQUE ARYTENOID MUSCLE`,
  [`MUSCOLO ARITENOIDEO TRASVERSO`]: `TRANSVERSE ARYTENOID MUSCLE`,
  [`MUSCOLO ARTICOLARE DEL GINOCCHIO DESTRO`]: `ARTICULAR MUSCLE OF RIGHT KNEE`,
  [`MUSCOLO ARTICOLARE DEL GINOCCHIO SINISTRO`]: `ARTICULAR MUSCLE OF LEFT KNEE`,
  [`MUSCOLO AURICOLARE ANTERIORE DESTRO`]: `RIGHT AURICULARIS ANTERIOR MUSCLE`,
  [`MUSCOLO AURICOLARE ANTERIORE SINISTRO`]: `LEFT AURICULARIS ANTERIOR MUSCLE`,
  [`MUSCOLO AURICOLARE POSTERIORE DESTRO`]: `RIGHT AURICULARIS POSERIOR MUSCLE`,
  [`MUSCOLO AURICOLARE POSTERIORE SINISTRO`]: `LEFT AURICULARIS POSTERIOR MUSCLE`,
  [`MUSCOLO AURICOLARE SUPERIORE DESTRO`]: `RIGHT AURICULARIS SUPERIOR MUSCLE`,
  [`MUSCOLO AURICOLARE SUPERIORE SINISTRO`]: `LEFT AURICULARIS SUPERIOR MUSCLE`,
  [`Muscolo bicipite brachiale (capo breve) (DX)`]: `Biceps brachii muscle (short head) (R)`,
  [`Muscolo bicipite brachiale (capo breve) (SX)`]: `Biceps brachii muscle (short head) (L)`,
  [`Muscolo bicipite brachiale (capo lungo) (DX)`]: `Biceps brachii muscle (long head) (R)`,
  [`Muscolo bicipite brachiale (capo lungo) (SX)`]: `Biceps brachii muscle (long head) (L)`,
  [`Muscolo bicipite brachiale (DX)`]: `Biceps brachii muscle (R)`,
  [`Muscolo bicipite brachiale (SX)`]: `Biceps brachii muscle (L)`,
  [`MUSCOLO BICIPITE BRACHIALE DESTRO`]: `RIGHT BICEPS BRACHII MUSCLE`,
  [`MUSCOLO BICIPITE BRACHIALE DESTRO (CAPO BREVE)`]: `RIGHT BICEPS BRACHII MUSCLE (SHORT HEAD)`,
  [`MUSCOLO BICIPITE BRACHIALE DESTRO (CAPO LUNGO)`]: `RIGHT BICEPS BRACHII MUSCLE (LONG HEAD)`,
  [`MUSCOLO BICIPITE BRACHIALE SINISTRO`]: `LEFT BICEPS BRACHII MUSCLE`,
  [`MUSCOLO BICIPITE BRACHIALE SINISTRO (CAPO BREVE)`]: `LEFT BICEPS BRACHII MUSCLE (SHORT HEAD)`,
  [`MUSCOLO BICIPITE BRACHIALE SINISTRO (CAPO LUNGO)`]: `LEFT BICEPS BRACHII MUSCLE (LONG HEAD)`,
  [`Muscolo bicipite femorale (capo breve) (DX)`]: `Biceps femoris muscle (short head) (R)`,
  [`Muscolo bicipite femorale (capo breve) (SX)`]: `Biceps femoris muscle (short head) (L)`,
  [`Muscolo bicipite femorale (capo lungo) (DX)`]: `Biceps femoris muscle (long head) (R)`,
  [`Muscolo bicipite femorale (capo lungo) (SX)`]: `Biceps femoris muscle (long head) (L)`,
  [`Muscolo bicipite femorale (DX)`]: `Biceps femoris muscle (R)`,
  [`Muscolo bicipite femorale (SX)`]: `Biceps femoris muscle (L)`,
  [`MUSCOLO BICIPITE FEMORALE DESTRO`]: `RIGHT BICEPS FEMORIS MUSCLE`,
  [`MUSCOLO BICIPITE FEMORALE DESTRO (CAPO BREVE)`]: `RIGHT BICEPS FEMORIS MUSCLE (SHORT HEAD)`,
  [`MUSCOLO BICIPITE FEMORALE DESTRO (CAPO LUNGO)`]: `RIGHT BICEPS FEMORIS MUSCLE (LONG HEAD)`,
  [`MUSCOLO BICIPITE FEMORALE SINISTRO`]: `LEFT BICEPS FEMORIS MUSCLE`,
  [`MUSCOLO BICIPITE FEMORALE SINISTRO (CAPO BREVE)`]: `LEFT BICEPS FEMORIS MUSCLE (SHORT HEAD)`,
  [`MUSCOLO BICIPITE FEMORALE SINISTRO (CAPO LUNGO)`]: `LEFT BICEPS FEMORIS MUSCLE (LONG HEAD)`,
  [`Muscolo brachiale (DX)`]: `Brachialis muscle (R)`,
  [`Muscolo brachiale (SX)`]: `Brachialis muscle (L)`,
  [`MUSCOLO BRACHIALE DESTRO`]: `RIGHT BRACHIALIS MUSCLE`,
  [`MUSCOLO BRACHIALE SINISTRO`]: `LEFT BRACHIALIS MUSCLE`,
  [`Muscolo brachioradiale (DX)`]: `Brachioradialis muscle (R)`,
  [`Muscolo brachioradiale (SX)`]: `Brachioradialis muscle (L)`,
  [`MUSCOLO BRACHIORADIALE DESTRO`]: `RIGHT BRACHIORADIALIS MUSCLE`,
  [`MUSCOLO BRACHIORADIALE SINISTRO`]: `LEFT BRACHIORADIALIS MUSCLE`,
  [`MUSCOLO BUCCINATORE DESTRO`]: `RIGHT BUCCINATOR MUSCLE`,
  [`MUSCOLO BUCCINATORE SINISTRO`]: `LEFT BUCCINATOR MUSCLE`,
  [`MUSCOLO BULBOSPONGIOSO`]: `BULBOSPONGIOSUS MUSCLE`,
  [`MUSCOLO COMPRESSORE DELL'URETRA (F)`]: `COMPRESSOR URETHRAA MUSCLE (F)`,
  [`Muscolo coracobrachiale (DX)`]: `Coracobrachialis muscle (R)`,
  [`Muscolo coracobrachiale (SX)`]: `Coracobrachialis muscle (L)`,
  [`MUSCOLO CORACOBRACHIALE DESTRO`]: `RIGHT CORACOBRACHIALIS MUSCLE`,
  [`MUSCOLO CORACOBRACHIALE SINISTRO`]: `LEFT CORACOBRACHIALIS MUSCLE`,
  [`MUSCOLO CORRUGATORE DEL SOPRACCIGLIO DESTRO`]: `RIGHT CORRUGATOR SUPERCILII MUSCLE`,
  [`MUSCOLO CORRUGATORE DEL SOPRACCIGLIO SINISTRO`]: `LEFT CORRUGATOR SUPERCILII MUSCLE`,
  [`MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE DESTRO`]: `RIGHT INFERIOR CONSTRICTOR MUSCLE`,
  [`MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE DESTRO (PARTE CRICOFARINGEA)`]: `RIGHT PHARYNX INFERIOR CONSTICTOR MUSCLE (CRICOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE DESTRO (PARTE TIROFARINGEA)`]: `RIGHT PHARYNX INFERIOR CONSTICTOR MUSCLE (THYROPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE SINISTRO`]: `LEFT INFERIOR CONSTRICTOR MUSCLE`,
  [`MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE SINISTRO (PARTE CRICOFARINGEA)`]: `LEFT PHARYNX INFERIOR CONSTICTOR MUSCLE (CRICOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE SINISTRO (PARTE TIROFARINGEA)`]: `LEFT PHARYNX INFERIOR CONSTICTOR MUSCLE (THYROPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE MEDIO DELLA FARINGE DESTRO`]: `RIGHT MIDDLE CONSTRICTOR MUSCLE`,
  [`MUSCOLO COSTRITTORE MEDIO DELLA FARINGE DESTRO (PARTE CONDROFARINGEA)`]: `RIGHT PHARYNX MIDDLE CONSTICTOR MUSCLE (CONDROPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE MEDIO DELLA FARINGE SINISTRO`]: `LEFT MIDDLE CONSTRICTOR MUSCLE`,
  [`MUSCOLO COSTRITTORE MEDIO DELLA FARINGE SINISTRO (PARTE CONDROFARINGEA)`]: `LEFT PHARYNX MIDDLE CONSTICTOR MUSCLE (CONDROPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE DESTRO`]: `RIGHT SUPERIOR CONSTRICTOR MUSCLE`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE DESTRO (PARTE BUCCOFARINGEA)`]: `RIGHT PHARYNX SUPERIOR CONSTICTOR MUSCLE (BUCCOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE DESTRO (PARTE GLOSSOFARINGEA)`]: `RIGHT PHARYNX SUPERIOR CONSTICTOR MUSCLE (GLOSSOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE DESTRO (PARTE MILOFARINGEA)`]: `RIGHT PHARYNX SUPERIOR CONSTICTOR MUSCLE (MYLOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE SINISTRO`]: `LEFT SUPERIOR CONSTRICTOR MUSCLE`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE SINISTRO (PARTE BUCCOFARINGEA)`]: `LEFT PHARYNX SUPERIOR CONSTICTOR MUSCLE (BUCCOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE SINISTRO (PARTE GLOSSOFARINGEA)`]: `LEFT PHARYNX SUPERIOR CONSTICTOR MUSCLE (GLOSSOPHARYNGEAL INSERTION)`,
  [`MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE SINISTRO (PARTE MILOFARINGEA)`]: `LEFT PHARYNX SUPERIOR CONSTICTOR MUSCLE (MYLOPHARYNGEAL INSERTION)`,
  [`MUSCOLO CREMASTERE DESTRO (M)`]: `RIGHT CREMASTER MUSCLE (M)`,
  [`MUSCOLO CREMASTERE SINISTRO (M)`]: `LEFT CREMASTER MUSCLE (M)`,
  [`MUSCOLO CRICOARITENOIDEO LATERALE DESTRO`]: `RIGHT LATERAL CRICOARYTENOID MUSCLE`,
  [`MUSCOLO CRICOARITENOIDEO LATERALE SINISTRO`]: `LEFT LATERAL CRICOARYTENOID MUSCLE`,
  [`MUSCOLO CRICOARITENOIDEO POSTERIORE DESTRO`]: `RIGHT POSTERIOR CRICOARYTENOID MUSCLE`,
  [`MUSCOLO CRICOARITENOIDEO POSTERIORE SINISTRO`]: `LEFT POSTERIOR CRICOARYTENOID MUSCLE`,
  [`MUSCOLO CRICOTIROIDEO DESTRO`]: `RIGHT CRICOTHYROID MUSCLE`,
  [`MUSCOLO CRICOTIROIDEO SINISTRO`]: `LEFT CRICOTHYROID MUSCLE`,
  [`MUSCOLO DELL'UGOLA`]: `MUSCULUS UVULAE`,
  [`Muscolo deltoide (parte acromiale) (DX)`]: `Deltoid muscle (acromial part) (R)`,
  [`Muscolo deltoide (parte acromiale) (SX)`]: `Deltoid muscle (acromial part) (L)`,
  [`Muscolo deltoide (parte clavicolare) (DX)`]: `Deltoid muscle (clavicular part) (R)`,
  [`Muscolo deltoide (parte clavicolare) (SX)`]: `Deltoid muscle (clavicular part) (L)`,
  [`Muscolo deltoide (parte spinale) (DX)`]: `Deltoid muscle (spinal part) (R)`,
  [`Muscolo deltoide (parte spinale) (SX)`]: `Deltoid muscle (spinal part) (L)`,
  [`MUSCOLO DELTOIDE DESTRO`]: `RIGHT DELTOID MUSCLE`,
  [`MUSCOLO DELTOIDE DESTRO (PARTE ACROMIALE)`]: `RIGHT DELTOID MUSCLE (ACROMIAL PART)`,
  [`MUSCOLO DELTOIDE DESTRO (PARTE CLAVICOLARE)`]: `RIGHT DELTOID MUSCLE (CLAVICULAR PART)`,
  [`MUSCOLO DELTOIDE DESTRO (PARTE SPINALE)`]: `RIGHT DELTOID MUSCLE (SPINAL PART)`,
  [`MUSCOLO DELTOIDE SINISTRO`]: `LEFT DELTOID MUSCLE`,
  [`MUSCOLO DELTOIDE SINISTRO (PARTE ACROMIALE)`]: `LEFT DELTOID MUSCLE (ACROMIAL PART)`,
  [`MUSCOLO DELTOIDE SINISTRO (PARTE CLAVICOLARE)`]: `LEFT DELTOID MUSCLE (CLAVICULAR PART)`,
  [`MUSCOLO DELTOIDE SINISTRO (PARTE SPINALE)`]: `LEFT DELTOID MUSCLE (SPINAL PART)`,
  [`Muscolo dentato posteriore inferiore (DX)`]: `Serratus posterior inferior muscle (R)`,
  [`Muscolo dentato posteriore inferiore (SX)`]: `Serratus posterior inferior muscle (L)`,
  [`MUSCOLO DENTATO POSTERIORE INFERIORE DESTRO`]: `RIGHT SERRATUS POSTERIOR INFERIOR MUSCLE`,
  [`MUSCOLO DENTATO POSTERIORE INFERIORE SINISTRO`]: `LEFT SERRATUS POSTERIOR INFERIOR MUSCLE`,
  [`Muscolo dentato posteriore superiore (DX)`]: `Serratus posterior superior muscle (R)`,
  [`Muscolo dentato posteriore superiore (SX)`]: `Serratus posterior superior muscle (L)`,
  [`MUSCOLO DENTATO POSTERIORE SUPERIORE DESTRO`]: `RIGHT SERRATUS POSTERIOR SUPERIOR MUSCLE`,
  [`MUSCOLO DENTATO POSTERIORE SUPERIORE SINISTRO`]: `LEFT SERRATUS POSTERIOR SUPERIOR MUSCLE`,
  [`MUSCOLO DEPRESSORE DEL LABBRO INFERIORE DESTRO`]: `RIGHT DEPRESSOR LABII INFERIORIS MUSCLE`,
  [`MUSCOLO DEPRESSORE DEL LABBRO INFERIORE SINISTRO`]: `LEFT DEPRESSOR LABII INFERIORIS MUSCLE`,
  [`MUSCOLO DEPRESSORE DEL SETTO NASALE DESTRO`]: `RIGHT DEPRESSOR SEPTI NASI MUSCLE`,
  [`MUSCOLO DEPRESSORE DEL SETTO NASALE SINISTRO`]: `LEFT DEPRESSOR SEPTI NASI MUSCLE`,
  [`MUSCOLO DEPRESSORE DEL SOPRACCIGLIO DESTRO`]: `RIGHT DEPRESSOR SUPERCILII MUSCLE`,
  [`MUSCOLO DEPRESSORE DEL SOPRACCIGLIO SINISTRO`]: `LEFT DEPRESSOR SUPERCILII MUSCLE`,
  [`MUSCOLO DEPRESSORE DELL'ANGOLO DELLA BOCCA DESTRO`]: `RIGHT DEPRESSOR ANGULI ORIS MUSCLE`,
  [`MUSCOLO DEPRESSORE DELL'ANGOLO DELLA BOCCA SINISTRO`]: `LEFT DEPRESSOR ANGULI ORIS MUSCLE`,
  [`MUSCOLO DETRUSORE`]: `DETRUSOR MUSCLE`,
  [`Muscolo diaframma`]: `Diaphragm muscle`,
  [`MUSCOLO DIAFRAMMA`]: `DIAPHRAGM`,
  [`MUSCOLO DIAFRAMMA (PARTE COSTALE A DESTRA)`]: `DIAPHRAGM (RIGHT COSTAL PART)`,
  [`MUSCOLO DIAFRAMMA (PARTE COSTALE A SINISTRA)`]: `DIAPHRAGM (LEFT COSTAL PART)`,
  [`MUSCOLO DIAFRAMMA (PARTE LOMBARE)`]: `DIAPHRAGM MUSCLE (LUMBAR PART)`,
  [`MUSCOLO DIAFRAMMA (PARTE STERNALE)`]: `DIAPHRAGM (STERNAL PART)`,
  [`Muscolo digastrico (ventre anteriore) (DX)`]: `Digastric muscle (anterior belly) (R)`,
  [`Muscolo digastrico (ventre anteriore) (SX)`]: `Digastric muscle (anterior belly) (L)`,
  [`Muscolo digastrico (ventre posteriore) (DX)`]: `Digastric muscle (posterior belly) (R)`,
  [`Muscolo digastrico (ventre posteriore) (SX)`]: `Digastric muscle (posterior belly) (L)`,
  [`MUSCOLO DIGASTRICO DESTRO`]: `RIGHT DIGASTRIC MUSCLE`,
  [`MUSCOLO DIGASTRICO DESTRO (VENTRE ANTERIORE)`]: `RIGHT DIGASTRIC MUSCLE (ANTERIOR BELLY)`,
  [`MUSCOLO DIGASTRICO DESTRO (VENTRE POSTERIORE)`]: `RIGHT DIGASTRIC MUSCLE (POSTERIOR BELLY)`,
  [`MUSCOLO DIGASTRICO SINISTRO`]: `LEFT DIGASTRIC MUSCLE`,
  [`MUSCOLO DIGASTRICO SINISTRO (VENTRE ANTERIORE)`]: `LEFT DIGASTRIC MUSCLE (ANTERIOR BELLY)`,
  [`MUSCOLO DIGASTRICO SINISTRO (VENTRE POSTERIORE)`]: `LEFT DIGASTRIC MUSCLE (POSTERIOR BELLY)`,
  [`MUSCOLO ELEVATORE BREVE DELLA I COSTA DESTRA`]: `RIGHT I LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA I COSTA SINISTRA`]: `LEFT I LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA II COSTA DESTRA`]: `RIGHT II LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA II COSTA SINISTRA`]: `LEFT I LIEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA III COSTA DESTRA`]: `RIGHT III LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA III COSTA SINISTRA`]: `LEFT I LIIEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA IV COSTA DESTRA`]: `RIGHT IV LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA IV COSTA SINISTRA`]: `LEFT IV LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA IX COSTA DESTRA`]: `RIGHT V LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA IX COSTA SINISTRA`]: `LEFT V LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA V COSTA DESTRA`]: `RIGHT VI LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA V COSTA SINISTRA`]: `LEFT VI LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA VI COSTA DESTRA`]: `RIGHT VII LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA VI COSTA SINISTRA`]: `LEFT VII LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA VII COSTA DESTRA`]: `RIGHT VIII LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA VII COSTA SINISTRA`]: `LEFT VIII LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA VIII COSTA DESTRA`]: `RIGHT IX LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA VIII COSTA SINISTRA`]: `LEFT IX LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA X COSTA DESTRA`]: `RIGHT X LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA X COSTA SINISTRA`]: `LEFT X LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA XI COSTA DESTRA`]: `RIGHT XI LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA XI COSTA SINISTRA`]: `LEFT XI LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA XII COSTA DESTRA`]: `RIGHT XII LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE BREVE DELLA XII COSTA SINISTRA`]: `LEFT XII LEVATORES COSTARUM BREVES MUSCLE`,
  [`MUSCOLO ELEVATORE DEL LABBRO SUPERIORE DESTRO`]: `RIGHT LEVATOR LABII SUPERIORIS MUSCLE`,
  [`MUSCOLO ELEVATORE DEL LABBRO SUPERIORE E DELL'ALA DEL NASO DESTRO`]: `RIGHT LEVATOR LABII SUPERIORIS ALAEQUE NASI MUSCLE`,
  [`MUSCOLO ELEVATORE DEL LABBRO SUPERIORE E DELL'ALA DEL NASO SINISTRO`]: `LEFT LEVATOR LABII SUPERIORIS ALAEQUE NASI MUSCLE`,
  [`MUSCOLO ELEVATORE DEL LABBRO SUPERIORE SINISTRO`]: `LEFT LEVATOR LABII SUPERIORIS MUSCLE`,
  [`MUSCOLO ELEVATORE DEL VELO PALATINO DESTRO`]: `RIGHT LEVATOR VELI PALATINI MUSCLE`,
  [`MUSCOLO ELEVATORE DEL VELO PALATINO SINISTRO`]: `LEFT LEVATOR VELI PALATINI MUSCLE`,
  [`MUSCOLO ELEVATORE DELL'ANGOLO DELLA BOCCA DESTRO`]: `RIGHT LEVATOR ANGULI ORIS MUSCLE`,
  [`MUSCOLO ELEVATORE DELL'ANGOLO DELLA BOCCA SINISTRO`]: `LEFT LEVATOR ANGULI ORIS MUSCLE`,
  [`MUSCOLO ELEVATORE DELLA PALPEBRA SUPERIORE DESTRO`]: `RIGHT LEVATOR PALPEBRAE SUPERIORIS MUSCLE`,
  [`MUSCOLO ELEVATORE DELLA PALPEBRA SUPERIORE SINISTRO`]: `LEFT LEVATOR PALPEBRAE SUPERIORIS MUSCLE`,
  [`Muscolo elevatore della scapola (DX)`]: `Levator scapula muscle (R)`,
  [`Muscolo elevatore della scapola (SX)`]: `Levator scapula muscle (L)`,
  [`MUSCOLO ELEVATORE DELLA SCAPOLA DESTRO`]: `RIGHT LEVATOR SCAPULAE MUSCLE`,
  [`MUSCOLO ELEVATORE DELLA SCAPOLA SINISTRO`]: `LEFT LEVATOR SCAPULAE MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA I COSTA DESTRA`]: `RIGHT I LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA I COSTA SINISTRA`]: `LEFT I LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA II COSTA DESTRA`]: `RIGHT II LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA II COSTA SINISTRA`]: `LEFT I LIEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA III COSTA DESTRA`]: `RIGHT III LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA III COSTA SINISTRA`]: `LEFT I LIIEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA IV COSTA DESTRA`]: `RIGHT IV LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA IV COSTA SINISTRA`]: `LEFT IV LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA IX COSTA DESTRA`]: `RIGHT V LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA IX COSTA SINISTRA`]: `LEFT V LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA V COSTA DESTRA`]: `RIGHT VI LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA V COSTA SINISTRA`]: `LEFT VI LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA VI COSTA DESTRA`]: `RIGHT VII LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA VI COSTA SINISTRA`]: `LEFT VII LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA VII COSTA DESTRA`]: `RIGHT VIII LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA VII COSTA SINISTRA`]: `LEFT VIII LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA VIII COSTA DESTRA`]: `RIGHT IX LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA VIII COSTA SINISTRA`]: `LEFT IX LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA X COSTA DESTRA`]: `RIGHT X LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA X COSTA SINISTRA`]: `LEFT X LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA XI COSTA DESTRA`]: `RIGHT XI LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA XI COSTA SINISTRA`]: `LEFT XI LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA XII COSTA DESTRA`]: `RIGHT XII LEVATORES COSTARUM LONGI MUSCLE`,
  [`MUSCOLO ELEVATORE LUNGO DELLA XII COSTA SINISTRA`]: `LEFT XII LEVATORES COSTARUM LONGI MUSCLE`,
  [`Muscolo estensore breve del pollice (DX)`]: `Extensor pollicis brevis muscle (R)`,
  [`Muscolo estensore breve del pollice (SX)`]: `Extensor pollicis brevis muscle (L)`,
  [`MUSCOLO ESTENSORE BREVE DEL POLLICE DESTRO`]: `RIGHT EXTENSOR POLLICIS BREVIS MUSCLE`,
  [`MUSCOLO ESTENSORE BREVE DEL POLLICE SINISTRO`]: `LEFT EXTENSOR POLLICIS BREVIS MUSCLE`,
  [`Muscolo estensore breve dell'alluce (DX)`]: `Extensor hallucis brevis muscle (R)`,
  [`Muscolo estensore breve dell'alluce (SX)`]: `Extensor hallucis brevis muscle (L)`,
  [`MUSCOLO ESTENSORE BREVE DELL'ALLUCE DESTRO`]: `RIGHT EXTENSOR HALLUCIS BREVIS MUSCLE`,
  [`MUSCOLO ESTENSORE BREVE DELL'ALLUCE SINISTRO`]: `LEFT EXTENSOR HALLUCIS BREVIS MUSCLE`,
  [`Muscolo estensore breve delle dita (DX)`]: `Extensor digitorum brevis muscle (R)`,
  [`Muscolo estensore breve delle dita (SX)`]: `Extensor digitorum brevis muscle (L)`,
  [`MUSCOLO ESTENSORE BREVE DELLE DITA DESTRO`]: `RIGHT EXTENSOR DIGITORUM BREVIS MUSCLE`,
  [`MUSCOLO ESTENSORE BREVE DELLE DITA SINISTRO`]: `LEFT EXTENSOR DIGITORUM BREVIS MUSCLE`,
  [`MUSCOLO ESTENSORE COMUNE DELLE DITA DESTRO`]: `RIGHT EXTENSOR DIGITORUM MUSCLE`,
  [`MUSCOLO ESTENSORE COMUNE DELLE DITA SINISTRO`]: `LEFT EXTENSOR DIGITORUM MUSCLE`,
  [`MUSCOLO ESTENSORE DEL MIGNOLO DESTRO`]: `RIGHT EXTENSOR DIGITI MINIMI MUSCLE`,
  [`MUSCOLO ESTENSORE DEL MIGNOLO SINISTRO`]: `LEFT EXTENSOR DIGITI MINIMI MUSCLE`,
  [`MUSCOLO ESTENSORE DELL'INDICE DESTRO`]: `RIGHT EXTENSOR INDICIS MUSCLE`,
  [`MUSCOLO ESTENSORE DELL'INDICE SINISTRO`]: `LEFT EXTENSOR INDICIS MUSCLE`,
  [`Muscolo estensore delle dita (DX)`]: `Extensor digitorum muscle (R)`,
  [`Muscolo estensore delle dita (SX)`]: `Extensor digitorum muscle (L)`,
  [`Muscolo estensore lungo del pollice (DX)`]: `Extensor pollicis longus muscle (R)`,
  [`Muscolo estensore lungo del pollice (SX)`]: `Extensor pollicis longus muscle (L)`,
  [`MUSCOLO ESTENSORE LUNGO DEL POLLICE DESTRO`]: `RIGHT EXTENSOR POLLICIS LONGUS MUSCLE`,
  [`MUSCOLO ESTENSORE LUNGO DEL POLLICE SINISTRO`]: `LEFT EXTENSOR POLLICIS LONGUS MUSCLE`,
  [`Muscolo estensore lungo dell'alluce (DX)`]: `Extensor hallucis longus muscle (R)`,
  [`Muscolo estensore lungo dell'alluce (SX)`]: `Extensor hallucis longus muscle (L)`,
  [`MUSCOLO ESTENSORE LUNGO DELL'ALLUCE DESTRO`]: `RIGHT EXTENSOR HALLUCIS LONGUS MUSCLE`,
  [`MUSCOLO ESTENSORE LUNGO DELL'ALLUCE SINISTRO`]: `LEFT EXTENSOR HALLUCIS LONGUS MUSCLE`,
  [`Muscolo estensore lungo delle dita (DX)`]: `Extensor digitorum longus muscle (R)`,
  [`Muscolo estensore lungo delle dita (SX)`]: `Extensor digitorum longus muscle (L)`,
  [`MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO`]: `RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE`,
  [`MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO`]: `LEFT EXTENSOR DIGITORUM LONGUS MUSCLE`,
  [`Muscolo estensore radiale breve del carpo (DX)`]: `Extensor carpi radialis brevis muscle (R)`,
  [`Muscolo estensore radiale breve del carpo (SX)`]: `Extensor carpi radialis brevis muscle (L)`,
  [`MUSCOLO ESTENSORE RADIALE BREVE DEL CARPO DESTRO`]: `RIGHT EXTENSOR CARPI RADIALIS BREVIS MUSCLE`,
  [`MUSCOLO ESTENSORE RADIALE BREVE DEL CARPO SINISTRO`]: `LEFT EXTENSOR CARPI RADIALIS BREVIS MUSCLE`,
  [`Muscolo estensore radiale lungo del carpo (DX)`]: `Extensor carpi radialis longus muscle (R)`,
  [`Muscolo Estensore radiale lungo del carpo (SX)`]: `Extensor carpi radialis longus muscle (L)`,
  [`MUSCOLO ESTENSORE RADIALE LUNGO DEL CARPO DESTRO`]: `RIGHT EXTENSOR CARPI RADIALIS LONGUS MUSCLE`,
  [`MUSCOLO ESTENSORE RADIALE LUNGO DEL CARPO SINISTRO`]: `LEFT EXTENSOR CARPI RADIALIS LONGUS MUSCLE`,
  [`Muscolo estensore ulnare del carpo (DX)`]: `Extensor carpi ulnaris muscle (R)`,
  [`Muscolo estensore ulnare del carpo (SX)`]: `Extensor carpi ulnaris muscle (L)`,
  [`MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO`]: `RIGHT EXTENSOR CARPI ULNARIS MUSCLE`,
  [`MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO (CAPO OMERALE)`]: `RIGHT EXTENSOR CARPI ULNARIS MUSCLE (HUMERAL HEAD)`,
  [`MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO (CAPO UNARE)`]: `RIGHT EXTENSOR CARPI ULNARIS MUSCLE (ULNAR HEAD)`,
  [`MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO`]: `LEFT EXTENSOR CARPI ULNARIS MUSCLE`,
  [`MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO (CAPO OMERALE)`]: `LEFT EXTENSOR CARPI ULNARIS MUSCLE (HUMERAL HEAD)`,
  [`MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO (CAPO UNARE)`]: `LEFT EXTENSOR CARPI ULNARIS MUSCLE (ULNAR HEAD)`,
  [`MUSCOLO FLESSORE BREVE DEL MIGNOLO DESTRO`]: `RIGHT HAND FLEXOR DIGITI MINIMI MUSCLE`,
  [`MUSCOLO FLESSORE BREVE DEL MIGNOLO SINISTRO`]: `LEFT HAND FLEXOR DIGITI MINIMI MUSCLE`,
  [`Muscolo flessore breve del pollice (DX)`]: `Flexor hallucis brevis muscle (R)`,
  [`Muscolo flessore breve del pollice (SX)`]: `Flexor hallucis brevis muscle (L)`,
  [`MUSCOLO FLESSORE BREVE DEL POLLICE DESTRO`]: `RIGHT FLEXOR POLLICIS BREVIS MUSCLE`,
  [`MUSCOLO FLESSORE BREVE DEL POLLICE DESTRO (CAPO PROFONDO)`]: `RIGHT FLEXOR POLLICIS BREVIS MUSCLE (DEEP HEAD)`,
  [`MUSCOLO FLESSORE BREVE DEL POLLICE DESTRO (CAPO SUPERFICIALE)`]: `RIGHT FLEXOR POLLICIS BREVIS MUSCLE (SUPERFICIAL HEAD)`,
  [`MUSCOLO FLESSORE BREVE DEL POLLICE SINISTRO`]: `LEFT FLEXOR POLLICIS BREVIS MUSCLE`,
  [`MUSCOLO FLESSORE BREVE DEL POLLICE SINISTRO (CAPO PROFONDO)`]: `LEFT FLEXOR POLLICIS BREVIS MUSCLE (DEEP HEAD)`,
  [`MUSCOLO FLESSORE BREVE DEL POLLICE SINISTRO (CAPO SUPERFICIALE)`]: `LEFT FLEXOR POLLICIS BREVIS MUSCLE (SUPERFICIAL HEAD)`,
  [`MUSCOLO FLESSORE BREVE DEL V DITO DESTRO`]: `RIGHT FLEXOR DIGITI MINIMI BREVIS MUSCLE `,
  [`MUSCOLO FLESSORE BREVE DEL V DITO SINISTRO`]: `LEFT FLEXOR DIGITI MINIMI BREVIS MUSCLE `,
  [`Muscolo flessore breve dell'alluce (DX)`]: `Flexor hallucis brevis muscle (R)`,
  [`Muscolo flessore breve dell'alluce (SX)`]: `Flexor hallucis brevis muscle (L)`,
  [`MUSCOLO FLESSORE BREVE DELL'ALLUCE DESTRO`]: `RIGHT FLEXUS HALLUCIS BREVIS MUSCLE `,
  [`MUSCOLO FLESSORE BREVE DELL'ALLUCE SINISTRO`]: `LEFT FLEXUS HALLUCIS BREVIS MUSCLE `,
  [`Muscolo flessore breve delle dita (DX)`]: `Flexor digitorum brevis muscle (R)`,
  [`Muscolo flessore breve delle dita (SX)`]: `Flexor digitorum brevis muscle (L)`,
  [`MUSCOLO FLESSORE BREVE DELLE DITA DESTRO`]: `RIGHT FLEXOR DIGITORUM BREVIS MUSCLE`,
  [`MUSCOLO FLESSORE BREVE DELLE DITA SINISTRO`]: `LEFT FLEXOR DIGITORUM BREVIS MUSCLE`,
  [`Muscolo flessore del mignolo (DX)`]: `Flexor digiti minimi muscle (R)`,
  [`Muscolo flessore del mignolo (SX)`]: `Flexor digiti minimi muscle (L)`,
  [`Muscolo flessore lungo del pollice (DX)`]: `Flexor pollicis longus muscle (R)`,
  [`Muscolo flessore lungo del pollice (SX)`]: `Flexor pollicis longus muscle (L)`,
  [`MUSCOLO FLESSORE LUNGO DEL POLLICE DESTRO`]: `RIGHT FLEXOR POLLICIS LONGUS MUSCLE`,
  [`MUSCOLO FLESSORE LUNGO DEL POLLICE SINISTRO`]: `LEFT FLEXOR POLLICIS LONGUS MUSCLE`,
  [`Muscolo flessore lungo dell'alluce (DX)`]: `Flexor hallucis longus muscle (R)`,
  [`Muscolo flessore lungo dell'alluce (SX)`]: `Flexor hallucis longus muscle (L)`,
  [`MUSCOLO FLESSORE LUNGO DELL'ALLUCE DESTRO`]: `RIGHT FLEXOR HALLUCIS LONGUS MUSCLE`,
  [`MUSCOLO FLESSORE LUNGO DELL'ALLUCE SINISTRO`]: `LEFT FLEXOR HALLUCIS LONGUS MUSCLE`,
  [`Muscolo flessore lungo delle dita (DX)`]: `Flexor digitorum longus muscle (R)`,
  [`Muscolo flessore lungo delle dita (SX)`]: `Flexor digitorum longus muscle (L)`,
  [`MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO`]: `RIGHT FLEXOR DIGITORUM LONGUS MUSCLE`,
  [`MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO`]: `LEFT FLEXOR DIGITORUM LONGUS MUSCLE`,
  [`Muscolo flessore profondo delle dita (DX)`]: `Flexor digitorum profundus muscle (R)`,
  [`Muscolo flessore profondo delle dita (SX)`]: `Flexor digitorum profundus muscle (L)`,
  [`MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO`]: `RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE`,
  [`MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO`]: `LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE`,
  [`Muscolo flessore radiale del carpo (DX)`]: `Flexor carpi radialis muscle (R)`,
  [`Muscolo flessore radiale del carpo (SX)`]: `Flexor carpi radialis muscle (L)`,
  [`MUSCOLO FLESSORE RADIALE DEL CARPO DESTRO`]: `RIGHT FLEXOR CARPI RADIALIS MUSCLE`,
  [`MUSCOLO FLESSORE RADIALE DEL CARPO SINISTRO`]: `LEFT FLEXOR CARPI RADIALIS MUSCLE`,
  [`Muscolo flessore superficiale delle dita (DX)`]: `Flexor digitorum superficialis muscle (R)`,
  [`Muscolo flessore superficiale delle dita (SX)`]: `Flexor digitorum superficialis muscle (L)`,
  [`MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO`]: `RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE`,
  [`MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (CAPO OMEROULNARE)`]: `RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (HUMERALULNAR HEAD)`,
  [`MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (CAPO RADIALE)`]: `RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (RADIAL HEAD)`,
  [`MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO`]: `LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE`,
  [`MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (CAPO OMEROULNARE)`]: `LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (HUMERALULNAR HEAD)`,
  [`MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (CAPO RADIALE)`]: `LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (HUMERALULNAR HEAD)`,
  [`Muscolo flessore ulnare del carpo (DX)`]: `Flexor carpi ulnaris muscle (R)`,
  [`Muscolo flessore ulnare del carpo (SX)`]: `Flexor carpi ulnaris muscle (L)`,
  [`MUSCOLO FLESSORE ULNARE DEL CARPO DESTRO`]: `RIGHT FLEXOR CARPI ULNARIS MUSCLE`,
  [`MUSCOLO FLESSORE ULNARE DEL CARPO DESTRO (CAPO OMERALE)`]: `RIGHT FLEXOR CARPI ULNARIS MUSCLE (HUMERAL HEAD)`,
  [`MUSCOLO FLESSORE ULNARE DEL CARPO DESTRO (CAPO ULNARE)`]: `RIGHT FLEXOR CARPI ULNARIS MUSCLE (ULNAR HEAD)`,
  [`MUSCOLO FLESSORE ULNARE DEL CARPO SINISTRO`]: `LEFT FLEXOR CARPI ULNARIS MUSCLE`,
  [`MUSCOLO FLESSORE ULNARE DEL CARPO SINISTRO (CAPO OMERALE)`]: `LEFT FLEXOR CARPI ULNARIS MUSCLE (HUMERAL HEAD)`,
  [`MUSCOLO FLESSORE ULNARE DEL CARPO SINISTRO (CAPO ULNARE)`]: `LEFT FLEXOR CARPI ULNARIS MUSCLE (ULNAR HEAD)`,
  [`Muscolo gastrocnemio laterale (DX)`]: `Lateral gastrocnemius muscle (R)`,
  [`Muscolo gastrocnemio laterale (SX)`]: `Lateral gastrocnemius muscle (L)`,
  [`MUSCOLO GASTROCNEMIO LATERALE DESTRO`]: `RIGHT LATERAL GASTROCNEMIUS MUSCLE`,
  [`MUSCOLO GASTROCNEMIO LATERALE SINISTRO`]: `LEFT LATERAL GASTROCNEMIUS MUSCLE`,
  [`Muscolo gastrocnemio mediale (DX)`]: `Medial gastrocnemius muscle (R)`,
  [`Muscolo gastrocnemio mediale (SX)`]: `Medial gastrocnemius muscle (L)`,
  [`MUSCOLO GASTROCNEMIO MEDIALE DESTRO`]: `RIGHT MEDIAL GASTROCNEMIUS MUSCLE`,
  [`MUSCOLO GASTROCNEMIO MEDIALE SINISTRO`]: `LEFT MEDIAL GASTROCNEMIUS MUSCLE`,
  [`MUSCOLO GEMELLO INFERIORE DESTRO`]: `RIGHT GEMELLUS INFERIOR MUSCLE`,
  [`MUSCOLO GEMELLO INFERIORE SINISTRO`]: `LEFT GEMELLUS INFERIOR MUSCLE`,
  [`MUSCOLO GEMELLO SUPERIORE DESTRO`]: `RIGHT GEMELLUS SUPERIOR MUSCLE`,
  [`MUSCOLO GEMELLO SUPERIORE SINISTRO`]: `LEFT GEMELLUS SUPERIOR MUSCLE`,
  [`MUSCOLO GENIOGLOSSO DESTRO`]: `RIGHT GENIOGLOSSUS MUSCLE`,
  [`MUSCOLO GENIOGLOSSO SINISTRO`]: `LEFT GENIOGLOSSUS MUSCLE`,
  [`MUSCOLO GENIOIOIDEO DESTRO`]: `RIGHT GENIOHYOID MUSCLE`,
  [`MUSCOLO GENIOIOIDEO SINISTRO`]: `LEFT GENIOHYOID MUSCLE`,
  [`Muscolo gracile (DX)`]: `Gracilis muscle (R)`,
  [`Muscolo gracile (SX)`]: `Gracilis muscle (L)`,
  [`MUSCOLO GRACILE DESTRO`]: `RIGHT GRACILIS MUSCLE`,
  [`MUSCOLO GRACILE SINISTRO`]: `LEFT GRACILIS MUSCLE`,
  [`Muscolo gran dorsale (DX)`]: `Latissimus dorsi muscle (R)`,
  [`Muscolo gran dorsale (SX)`]: `Latissimus dorsi muscle (L)`,
  [`MUSCOLO GRAN DORSALE DESTRO`]: `RIGHT LATISSIMUS DORSI MUSCLE`,
  [`MUSCOLO GRAN DORSALE SINISTRO`]: `LEFT LATISSIMUS DORSI MUSCLE`,
  [`Muscolo grande adduttore (DX)`]: `Adductor magnus muscle (R)`,
  [`Muscolo grande adduttore (SX)`]: `Adductor magnus muscle (L)`,
  [`MUSCOLO GRANDE ADDUTTORE DESTRO`]: `RIGHT ADDUCTOR MAGNUS MUSCLE`,
  [`MUSCOLO GRANDE ADDUTTORE SINISTRO`]: `LEFT ADDUCTOR MAGNUS MUSCLE`,
  [`Muscolo grande gluteo (DX)`]: `Gluteus maximus muscle (R)`,
  [`Muscolo grande gluteo (SX)`]: `Gluteus maximus muscle (L)`,
  [`MUSCOLO GRANDE GLUTEO DESTRO`]: `RIGHT GLUTEUS MAXIMUS MUSCLE`,
  [`MUSCOLO GRANDE GLUTEO SINISTRO`]: `LEFT GLUTEUS MAXIMUS MUSCLE`,
  [`Muscolo grande pettorale (DX)`]: `Pectoralis major muscle (R)`,
  [`Muscolo grande pettorale (parte clavicolare) (DX)`]: `Pectoralis major muscle (clavicular part) (R)`,
  [`Muscolo grande pettorale (parte clavicolare) (SX)`]: `Pectoralis major muscle (clavicular part) (L)`,
  [`Muscolo grande pettorale (parte sternocostale) (DX)`]: `Pectoralis major muscle (sternocostal part) (R)`,
  [`Muscolo grande pettorale (parte sternocostale) (SX)`]: `Pectoralis major muscle (sternocostal part) (L)`,
  [`Muscolo grande pettorale (SX)`]: `Pectoralis major muscle (L)`,
  [`MUSCOLO GRANDE PETTORALE DESTRO`]: `RIGHT PECTORALIS MAJOR MUSCLE`,
  [`MUSCOLO GRANDE PETTORALE DESTRO (PARTE CLAVICOLARE)`]: `RIGHT PECTORALIS MAJOR MUSCLE (CLAVICULAR HEAD)`,
  [`MUSCOLO GRANDE PETTORALE DESTRO (PARTE STERNOCOSTALE)`]: `RIGHT PECTORALIS MAJOR MUSCLE (STERNOCOSTAL HEAD)`,
  [`MUSCOLO GRANDE PETTORALE SINISTRO`]: `LEFT PECTORALIS MAJOR MUSCLE`,
  [`MUSCOLO GRANDE PETTORALE SINISTRO (PARTE CLAVICOLARE)`]: `LEFT PECTORALIS MAJOR MUSCLE (CLAVICULAR HEAD)`,
  [`MUSCOLO GRANDE PETTORALE SINISTRO (PARTE STERNOCOSTALE)`]: `LEFT PECTORALIS MAJOR MUSCLE (STERNOCOSTAL HEAD)`,
  [`Muscolo grande psoas (DX)`]: `Psoas major muscle (R)`,
  [`Muscolo grande psoas (SX)`]: `Psoas major muscle (L)`,
  [`MUSCOLO GRANDE PSOAS DESTRO`]: `RIGHT PSOAS MAJOR MUSCLE`,
  [`MUSCOLO GRANDE PSOAS SINISTRO`]: `LEFT PSOAS MAJOR MUSCLE`,
  [`MUSCOLO GRANDE RETTO POSTERIORE DELLA TESTA DESTRO`]: `RIGHT RECTUS CAPITIS POSTERIOR MAJOR MUSCLE`,
  [`MUSCOLO GRANDE RETTO POSTERIORE DELLA TESTA SINISTRO`]: `LEFT RECTUS CAPITIS POSTERIOR MAJOR MUSCLE`,
  [`Muscolo grande rotondo (DX)`]: `Teres major muscle (R)`,
  [`Muscolo grande rotondo (SX)`]: `Teres major muscle (L)`,
  [`MUSCOLO GRANDE ROTONDO DESTRO`]: `RIGHT TERES MAJOR MUSCLE`,
  [`MUSCOLO GRANDE ROTONDO SINISTRO`]: `LEFT TERES MAJOR MUSCLE`,
  [`MUSCOLO I INTEROSSEO DORSALE DEL PIEDE DESTRO`]: `I DORSAL INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO I INTEROSSEO DORSALE DEL PIEDE SINISTRO`]: `I DORSAL INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO I INTEROSSEO DORSALE DELLA MANO DESTRA`]: `RIGHT I DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO I INTEROSSEO DORSALE DELLA MANO DESTRO`]: `I DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND`,
  [`MUSCOLO I INTEROSSEO DORSALE DELLA MANO SINISTRA`]: `LEFT I DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO I INTEROSSEO DORSALE DELLA MANO SINISTRO`]: `I DORSAL INTEROSSEUS MUSCLE OF LEFT HAND`,
  [`MUSCOLO I INTEROSSEO PALMARE DELLA MANO DESTRA`]: `RIGHT I PALMAR INTEROSSEUS MUSCLE`,
  [`MUSCOLO I INTEROSSEO PALMARE DELLA MANO SINISTRA`]: `LEFT I PALMAR INTEROSSEUS MUSCLE`,
  [`MUSCOLO I INTEROSSEO PLANTARE DEL PIEDE DESTRO`]: `I PLANTAR INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO I INTEROSSEO PLANTARE DEL PIEDE SINISTRO`]: `I PLANTAR INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO I LOMBRICALE DEL PIEDE DESTRO`]: `I LUMBRICAL MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO I LOMBRICALE DEL PIEDE SINISTRO`]: `I LUMBRICAL MUSCLE OF LEFT FOOT`,
  [`MUSCOLO I LOMBRICALE DELLA MANO DESTRA`]: `I LUMBRICAL MUSCLE OF RIGHT HAND`,
  [`MUSCOLO I LOMBRICALE DELLA MANO SINISTRA`]: `I LUMBRICAL MUSCLE OF LEFT HAND`,
  [`MUSCOLO II INTEROSSEO DORSALE DEL PIEDE DESTRO`]: `II DORSAL INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO II INTEROSSEO DORSALE DEL PIEDE SINISTRO`]: `II DORSAL INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO II INTEROSSEO DORSALE DELLA MANO DESTRA`]: `RIGHT II DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO II INTEROSSEO DORSALE DELLA MANO SINISTRA`]: `LEFT II DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO II INTEROSSEO PALMARE DELLA MANO DESTRA`]: `RIGHT II PALMAR INTEROSSEUS MUSCLE`,
  [`MUSCOLO II INTEROSSEO PALMARE DELLA MANO SINISTRA`]: `LEFT II PALMAR INTEROSSEUS MUSCLE`,
  [`MUSCOLO II INTEROSSEO PLANTARE DEL PIEDE DESTRO`]: `II PLANTAR INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO II INTEROSSEO PLANTARE DEL PIEDE SINISTRO`]: `II PLANTAR INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO II LOMBRICALE DEL PIEDE DESTRO`]: `II LUMBRICAL MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO II LOMBRICALE DEL PIEDE SINISTRO`]: `II LUMBRICAL MUSCLE OF LEFT FOOT`,
  [`MUSCOLO II LOMBRICALE DELLA MANO DESTRA`]: `II LUMBRICAL MUSCLE OF RIGHT HAND`,
  [`MUSCOLO II LOMBRICALE DELLA MANO SINISTRA`]: `II LUMBRICAL MUSCLE OF LEFT HAND`,
  [`MUSCOLO III INTEROSSEO DORSALE DEL PIEDE DESTRO`]: `III DORSAL INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO III INTEROSSEO DORSALE DEL PIEDE SINISTRO`]: `III DORSAL INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO III INTEROSSEO DORSALE DELLA MANO DESTRA`]: `RIGHT III DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO III INTEROSSEO DORSALE DELLA MANO SINISTRA`]: `LEFT III DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO III INTEROSSEO PALMARE DELLA MANO DESTRA`]: `RIGHT III PALMAR INTEROSSEUS MUSCLE`,
  [`MUSCOLO III INTEROSSEO PALMARE DELLA MANO SINISTRA`]: `LEFT III PALMAR INTEROSSEUS MUSCLE`,
  [`MUSCOLO III INTEROSSEO PLANTARE DEL PIEDE DESTRO`]: `III PLANTAR INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO III INTEROSSEO PLANTARE DEL PIEDE SINISTRO`]: `III PLANTAR INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO III LOMBRICALE DEL PIEDE DESTRO`]: `III LUMBRICAL MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO III LOMBRICALE DEL PIEDE SINISTRO`]: `III LUMBRICAL MUSCLE OF LEFT FOOT`,
  [`MUSCOLO III LOMBRICALE DELLA MANO DESTRA`]: `III LUMBRICAL MUSCLE OF RIGHT HAND`,
  [`MUSCOLO III LOMBRICALE DELLA MANO SINISTRA`]: `III LUMBRICAL MUSCLE OF LEFT HAND`,
  [`MUSCOLO ILEOCOCCIGEO DESTRO`]: `RIGHT ILIOCOCCYGEAL MUSCLE`,
  [`MUSCOLO ILEOCOCCIGEO SINISTRO`]: `LEFT ILIOCOCCYGEAL MUSCLE`,
  [`Muscolo ileocostale dei lombi (DX)`]: `Iliocostalis lumborum muscle (R)`,
  [`Muscolo ileocostale dei lombi (SX)`]: `Iliocostalis lumborum muscle (L)`,
  [`MUSCOLO ILEOCOSTALE DEI LOMBI DESTRO (PARTE LOMBARE)`]: `RIGHT ILIOCOSTALIS LUMBORUM MUSCLE (LUMBAR PART)`,
  [`MUSCOLO ILEOCOSTALE DEI LOMBI DESTRO (PARTE TORACICA)`]: `RIGHT ILIOCOSTALIS LUMBORUM MUSCLE (THORACIC PART)`,
  [`MUSCOLO ILEOCOSTALE DEI LOMBI SINISTRO (PARTE LOMBARE)`]: `LEFT ILIOCOSTALIS LUMBORUM MUSCLE (LUMBAR PART)`,
  [`MUSCOLO ILEOCOSTALE DEI LOMBI SINISTRO (PARTE TORACICA)`]: `LEFT ILIOCOSTALIS LUMBORUM MUSCLE (THORACIC PART)`,
  [`MUSCOLO ILEOCOSTALE DEL COLLO DESTRO`]: `RIGHT ILIOCOSTALIS CERVICIS MUSCLE`,
  [`MUSCOLO ILEOCOSTALE DEL COLLO SINISTRO`]: `LEFT ILIOCOSTALIS CERVICIS MUSCLE`,
  [`Muscolo ileocostale del torace (DX)`]: `Iliocostalis thoracis muscle (R)`,
  [`Muscolo ileocostale del torace (SX)`]: `Iliocostalis thoracis muscle (L)`,
  [`MUSCOLO ILEOPSOAS DESTRO`]: `RIGHT ILIOPASOAS MUSCLE`,
  [`MUSCOLO ILEOPSOAS SINISTRO`]: `SKIN OF RIGHT ILIAC AREA`,
  [`Muscolo iliaco (DX)`]: `Iliacus muscle (R)`,
  [`Muscolo iliaco (SX)`]: `Iliacus muscle (L)`,
  [`MUSCOLO ILIACO DESTRO`]: `RIGHT ILIACUS MUSCLE`,
  [`MUSCOLO ILIACO SINISTRO`]: `LEFT ILIACUS MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA I COSTA DESTRA`]: `RIGHT I EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA I COSTA SINISTRA`]: `LEFT I EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA II COSTA DESTRA`]: `RIGHT II EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA II COSTA SINISTRA`]: `LEFT II EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA III COSTA DESTRA`]: `RIGHT III EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA III COSTA SINISTRA`]: `LEFT III EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA IV COSTA DESTRA`]: `RIGHT IV EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA IV COSTA SINISTRA`]: `LEFT IV EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA IX COSTA DESTRA`]: `RIGHT IX EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA IX COSTA SINISTRA`]: `LEFT IX EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA V COSTA DESTRA`]: `RIGHT V EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA V COSTA SINISTRA`]: `LEFT V EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA VI COSTA DESTRA`]: `RIGHT VI EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA VI COSTA SINISTRA`]: `LEFT VI EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA VII COSTA DESTRA`]: `RIGHT VII EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA VII COSTA SINISTRA`]: `LEFT VII EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA VIII COSTA DESTRA`]: `RIGHT VIII EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA VIII COSTA SINISTRA`]: `LEFT VIII EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA X COSTA DESTRA`]: `RIGHT X EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA X COSTA SINISTRA`]: `LEFT X EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA XI COSTA DESTRA`]: `RIGHT XI EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA XI COSTA SINISTRA`]: `LEFT XI EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA XII COSTA DESTRA`]: `RIGHT XII EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE ESTERNO DELLA XII COSTA SINISTRA`]: `LEFT XII EXTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA I COSTA DESTRA`]: `RIGHT I INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA I COSTA SINISTRA`]: `LEFT I INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA II COSTA DESTRA`]: `RIGHT II INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA II COSTA SINISTRA`]: `LEFT II INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA III COSTA SINISTRA`]: `RIGHT III INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA IIII COSTA DESTRA`]: `LEFT III INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA IV COSTA DESTRA`]: `RIGHT IV INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA IV COSTA SINISTRA`]: `LEFT IV INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA IX COSTA DESTRA`]: `RIGHT IX INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA IX COSTA SINISTRA`]: `LEFT IX INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA V COSTA DESTRA`]: `RIGHT V INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA V COSTA SINISTRA`]: `LEFT V INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA VI COSTA DESTRA`]: `RIGHT VI INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA VI COSTA SINISTRA`]: `LEFT VI INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA VII COSTA DESTRA`]: `RIGHT VII INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA VII COSTA SINISTRA`]: `LEFT VII INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA VIII COSTA DESTRA`]: `RIGHT VIII INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA VIII COSTA SINISTRA`]: `LEFT VIII INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA X COSTA DESTRA`]: `RIGHT X INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA X COSTA SINISTRA`]: `LEFT X INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA XI COSTA DESTRA`]: `RIGHT XI INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA XI COSTA SINISTRA`]: `LEFT XI INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA XII COSTA DESTRA`]: `RIGHT XII INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO INTERCOSTALE INTERNO DELLA XII COSTA SINISTRA`]: `LEFT XII INTERNAL INTERCOSTAL MUSCLE`,
  [`MUSCOLO IOGLOSSO DESTRO`]: `RIGHT HYOGLOSSUS MUSCLE`,
  [`MUSCOLO IOGLOSSO SINISTRO`]: `LEFT HYOGLOSSUS MUSCLE`,
  [`MUSCOLO ISCHIOCAVERNOSO DESTRO`]: `RIGHT ISCHIOCAVERNOSUS MUSCLE`,
  [`MUSCOLO ISCHIOCAVERNOSO SINISTRO`]: `LEFT ISCHIOCAVERNOSUS MUSCLE`,
  [`MUSCOLO ISCHIOCOCCIGEO DESTRO`]: `RIGHT ISCHIOCOCCYGEAL MUSCLE`,
  [`MUSCOLO ISCHIOOCCIGEO SINISTRO`]: `LEFT ISCHIOCOCCYGEAL MUSCLE`,
  [`MUSCOLO IV INTEROSSEO DORSALE DEL PIEDE DESTRO`]: `IV DORSAL INTEROSSEOUS MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO IV INTEROSSEO DORSALE DEL PIEDE SINISTRO`]: `IV DORSAL INTEROSSEOUS MUSCLE OF LEFT FOOT`,
  [`MUSCOLO IV INTEROSSEO DORSALE DELLA MANO DESTRA`]: `RIGHT IV DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO IV INTEROSSEO DORSALE DELLA MANO SINISTRA`]: `LEFT IV DORSAL INTEROSSEUS MUSCLE`,
  [`MUSCOLO IV LOMBRICALE DEL PIEDE DESTRO`]: `IV LUMBRICAL MUSCLE OF RIGHT FOOT`,
  [`MUSCOLO IV LOMBRICALE DEL PIEDE SINISTRO`]: `IV LUMBRICAL MUSCLE OF LEFT FOOT`,
  [`MUSCOLO IV LOMBRICALE DELLA MANO DESTRA`]: `IV LUMBRICAL MUSCLE OF RIGHT HAND`,
  [`MUSCOLO IV LOMBRICALE DELLA MANO SINISTRA`]: `IV LUMBRICAL MUSCLE OF LEFT HAND`,
  [`MUSCOLO LONGITUDINALE INFERIORE DESTRO`]: `RIGHT INFERIOR LONGITUDINAL MUSCLE`,
  [`MUSCOLO LONGITUDINALE INFERIORE SINISTRO`]: `LEFT INFERIOR LONGITUDINAL MUSCLE`,
  [`MUSCOLO LONGITUDINALE SUPERIORE`]: `SUPERIOR LONGITUDINAL MUSCLE`,
  [`Muscolo lunghissimo dei lombi (DX)`]: `Longissimus lumborum muscle (R)`,
  [`Muscolo lunghissimo dei lombi (SX)`]: `Longissimus lumborum muscle (L)`,
  [`MUSCOLO LUNGHISSIMO DEL COLLO DESTRO`]: `RIGHT LONGISSIMUS CERVICIS MUSCLE`,
  [`MUSCOLO LUNGHISSIMO DEL COLLO SINISTRO`]: `LEFT LONGISSIMUS CERVICIS MUSCLE`,
  [`Muscolo lunghissimo del torace (DX)`]: `Longissimus thoracis muscle (R)`,
  [`Muscolo lunghissimo del torace (SX)`]: `Longissimus thoracis muscle (L)`,
  [`MUSCOLO LUNGHISSIMO DEL TORACE DESTRO`]: `RIGHT LONGISSIMUS THORACIS MUSCLE`,
  [`MUSCOLO LUNGHISSIMO DEL TORACE SINISTRO`]: `LEFT LONGISSIMUS THORACIS MUSCLE`,
  [`MUSCOLO LUNGHISSIMO DELLA TESTA DESTRO`]: `RIGHT LONGISSIMUS CAPITIS MUSCLE`,
  [`MUSCOLO LUNGHISSIMO DELLA TESTA SINISTRO`]: `LEFT LONGISSIMUS CAPITIS MUSCLE`,
  [`MUSCOLO LUNGO DEL COLLO DESTRO`]: `RIGHT LONGUS COLLI MUSCLE`,
  [`MUSCOLO LUNGO DEL COLLO SINISTRO`]: `LEFT LONGUS COLLI MUSCLE`,
  [`MUSCOLO LUNGO DELLA TESTA DESTRO`]: `RIGHT LONGUS CAPITIS MUSCLE`,
  [`MUSCOLO LUNGO DELLA TESTA SINISTRO`]: `LEFT LONGUS CAPITIS MUSCLE`,
  [`Muscolo massetere (DX)`]: `Masseter muscle (R)`,
  [`Muscolo massetere (SX)`]: `Masseter muscle (L)`,
  [`MUSCOLO MASSETERE DESTRO`]: `RIGHT MASSETER MUSCLE`,
  [`MUSCOLO MASSETERE DESTRO (PARTE PROFONDA)`]: `RIGHT MASSETER MUSCLE (DEEP PART)`,
  [`MUSCOLO MASSETERE DESTRO (PARTE SUPERFICIALE)`]: `RIGHT MASSETER MUSCLE (SUPERFICIAL PART)`,
  [`MUSCOLO MASSETERE SINISTRO`]: `LEFT MASSETER MUSCLE`,
  [`MUSCOLO MASSETERE SINISTRO (PARTE PROFONDA)`]: `LEFT MASSETER MUSCLE (DEEP PART)`,
  [`MUSCOLO MASSETERE SINISTRO (PARTE SUPERFICIALE)`]: `LEFT MASSETER MUSCLE (SUPERFICIAL PART)`,
  [`Muscolo medio gluteo (DX)`]: `Gluteus medium muscle (R)`,
  [`Muscolo medio gluteo (SX)`]: `Gluteus medium muscle (L)`,
  [`MUSCOLO MEDIO GLUTEO DESTRO`]: `RIGHT GLUTEUS MEDIUS MUSCLE`,
  [`MUSCOLO MEDIO GLUTEO SINISTRO`]: `LEFT GLUTEUS MEDIUS MUSCLE`,
  [`MUSCOLO MENTALE DESTRO`]: `RIGHT MENTAL MUSCLE`,
  [`MUSCOLO MENTALE SINISTRO`]: `LEFT MENTAL MUSCLE`,
  [`Muscolo miloioideo (DX)`]: `Milohyoid muscle (R)`,
  [`Muscolo miloioideo (SX)`]: `Milohyoid muscle (L)`,
  [`MUSCOLO MILOIOIDEO DESTRO`]: `RIGHT MYLOHYOID MUSCLE`,
  [`MUSCOLO MILOIOIDEO SINISTRO`]: `LEFT MYLOHYOID MUSCLE`,
  [`MUSCOLO MODIOLO DESTRO`]: `RIGHT MODIOLUS MUSCLE`,
  [`MUSCOLO MODIOLO SINISTRO`]: `LEFT MODIOLUS MUSCLE`,
  [`MUSCOLO MULTIFIDO CERVICALE DESTRO`]: `RIGHT MULTIFIDUS CERVICIS MUSCLE`,
  [`MUSCOLO MULTIFIDO CERVICALE SINISTRO`]: `LEFT MULTIFIDUS CERVICIS MUSCLE`,
  [`Muscolo multifido lombare (DX)`]: `Lumbar multifidus muscle (R)`,
  [`Muscolo multifido lombare (SX)`]: `Lumbar multifidus muscle (L)`,
  [`MUSCOLO MULTIFIDO LOMBARE DESTRO`]: `RIGHT MULTIFIDUS LUMBORUM MUSCLE`,
  [`MUSCOLO MULTIFIDO LOMBARE SINISTRO`]: `LEFT MULTIFIDUS LUMBORUM MUSCLE`,
  [`MUSCOLO MULTIFIDO SACRALE DESTRO`]: `RIGHT MULTIFIDUS SACRALIS MUSCLE`,
  [`MUSCOLO MULTIFIDO SACRALE SINISTRO`]: `LEFT MULTIFIDUS SACRALIS MUSCLE`,
  [`MUSCOLO MULTIFIDO TORACICO DESTRO`]: `RIGHT MULTIFIDUS THORACIS MUSCLE`,
  [`MUSCOLO MULTIFIDO TORACICO SINISTRO`]: `LEFT MULTIFIDUS THORACIS MUSCLE`,
  [`MUSCOLO NASALE DESTRO`]: `RIGHT NASAL MUSCLE`,
  [`MUSCOLO NASALE SINISTRO`]: `LEFT NASAL MUSCLE`,
  [`Muscolo obliquo esterno dell'addome (DX)`]: `Obliquus externus abdominis muscle (R)`,
  [`Muscolo obliquo esterno dell'addome (SX)`]: `Obliquus externus abdominis muscle (L)`,
  [`MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO`]: `RIGHT EXTERNAL OBLIQUE MUSCLE`,
  [`MUSCOLO OBLIQUO ESTERNO DELL'ADDOME SINISTRO`]: `LEFT EXTERNAL OBLIQUE MUSCLE`,
  [`MUSCOLO OBLIQUO INFERIORE DELL'OCCHIO DESTRO`]: `INFERIOR OBLIQUE MUSCLE OF RIGHT EYE`,
  [`MUSCOLO OBLIQUO INFERIORE DELL'OCCHIO SINISTRO`]: `INFERIOR OBLIQUE MUSCLE OF LEFT EYE`,
  [`MUSCOLO OBLIQUO INFERIORE DELLA TESTA DESTRO`]: `RIGHT OBLIQUUS CAPITIS INFERIOR MUSCLE`,
  [`MUSCOLO OBLIQUO INFERIORE DELLA TESTA SINISTRO`]: `LEFT OBLIQUUS CAPITIS INFERIOR MUSCLE`,
  [`Muscolo obliquo interno dell'addome (DX)`]: `Obliquus internus abdominis muscle (R)`,
  [`Muscolo obliquo interno dell'addome (SX)`]: `Obliquus internus abdominis muscle (L)`,
  [`MUSCOLO OBLIQUO INTERNO DELL'ADDOME DESTRO`]: `RIGHT INTERNAL OBLIQUE MUSCLE`,
  [`MUSCOLO OBLIQUO INTERNO DELL'ADDOME SINISTRO`]: `LEFT INTERNAL OBLIQUE MUSCLE`,
  [`MUSCOLO OBLIQUO SUPERIORE DELL'OCCHIO DESTRO`]: `SUPERIOR OBLIQUE MUSCLE OF RIGHT EYE`,
  [`MUSCOLO OBLIQUO SUPERIORE DELL'OCCHIO SINISTRO`]: `SUPERIOR OBLIQUE MUSCLE OF LEFT EYE`,
  [`MUSCOLO OBLIQUO SUPERIORE DELLA TESTA DESTRO`]: `RIGHT OBLIQUUS CAPITIS SUPERIOR MUSCLE`,
  [`MUSCOLO OBLIQUO SUPERIORE DELLA TESTA SINISTRO`]: `LEFT OBLIQUUS CAPITIS SUPERIOR MUSCLE`,
  [`MUSCOLO OCCIPITOFRONTALE DESTRO (VENTRE FRONTALE)`]: `RIGHT OCCIPITOFRONTALIS MUSCLE (FRONTAL BELLY)`,
  [`MUSCOLO OCCIPITOFRONTALE DESTRO (VENTRE OCCIPITALE)`]: `RIGHT OCCIPITOFRONTALIS MUSCLE (OCCIPITAL BELLY)`,
  [`MUSCOLO OCCIPITOFRONTALE SINISTRO (VENTRE FRONTALE)`]: `LEFT OCCIPITOFRONTALIS MUSCLE (FRONTAL BELLY)`,
  [`MUSCOLO OCCIPITOFRONTALE SINISTRO (VENTRE OCCIPITALE)`]: `LEFT OCCIPITOFRONTALIS MUSCLE (OCCIPITAL BELLY)`,
  [`Muscolo omoioideo (DX)`]: `Omohyoid muscle (R)`,
  [`Muscolo omoioideo (SX)`]: `Omohyoid muscle (L)`,
  [`MUSCOLO OMOIOIDEO DESTRO`]: `RIGHT OMOHYOID MUSCLE`,
  [`MUSCOLO OMOIOIDEO DESTRO (VENTRE INFERIORE)`]: `RIGHT OMOHYOID MUSCLE (INFERIOR BELLY)`,
  [`MUSCOLO OMOIOIDEO DESTRO (VENTRE SUPERIORE)`]: `RIGHT OMOHYOID MUSCLE (SUPERIOR BELLY)`,
  [`MUSCOLO OMOIOIDEO SINISTRO`]: `LEFT OMOHYOID MUSCLE`,
  [`MUSCOLO OMOIOIDEO SINISTRO (VENTRE INFERIORE)`]: `LEFT OMOHYOID MUSCLE (INFERIOR BELLY)`,
  [`MUSCOLO OMOIOIDEO SINISTRO (VENTRE SUPERIORE)`]: `LEFT OMOHYOID MUSCLE (SUPERIOR BELLY)`,
  [`Muscolo opponente del mignolo (DX)`]: `Opponens digiti minimi muscle (R)`,
  [`Muscolo opponente del mignolo (SX)`]: `Opponens digiti minimi muscle (L)`,
  [`MUSCOLO OPPONENTE DEL MIGNOLO DESTRO`]: `RIGHT HAND OPPONENS DIGITI MINIMI MUSCLE`,
  [`MUSCOLO OPPONENTE DEL MIGNOLO SINISTRO`]: `LEFT HAND OPPONENS DIGITI MINIMI MUSCLE`,
  [`Muscolo opponente del pollice (DX)`]: `Opponens pollicis muscle (R)`,
  [`Muscolo opponente del pollice (SX)`]: `Opponens pollicis muscle (L)`,
  [`MUSCOLO OPPONENTE DEL POLLICE DESTRO`]: `RIGHT OPPONENS POLLICIS MUSCLE`,
  [`MUSCOLO OPPONENTE DEL POLLICE SINISTRO`]: `LEFT OPPONENS POLLICIS MUSCLE`,
  [`MUSCOLO ORBICOLARE DELL'OCCHIO DESTRO`]: `RIGHT ORBICULARIS OCULI MUSCLE`,
  [`MUSCOLO ORBICOLARE DELL'OCCHIO DESTRO (PARTE ORBITALE)`]: `RIGHT ORBICULARIS OCULI MUSCLE (ORBITAL PART)`,
  [`MUSCOLO ORBICOLARE DELL'OCCHIO DESTRO (PARTE PALPEBRALE)`]: `RIGHT ORBICULARIS OCULI MUSCLE (PALPEBRAL PART)`,
  [`MUSCOLO ORBICOLARE DELL'OCCHIO SINISTRO`]: `LEFT ORBICULARIS OCULI MUSCLE`,
  [`MUSCOLO ORBICOLARE DELL'OCCHIO SINISTRO (PARTE ORBITALE)`]: `LEFT ORBICULARIS OCULI MUSCLE (ORBITAL PART)`,
  [`MUSCOLO ORBICOLARE DELL'OCCHIO SINISTRO (PARTE PALPEBRALE)`]: `LEFT ORBICULARIS OCULI MUSCLE (PALPEBRAL PART)`,
  [`MUSCOLO ORBICOLARE DELLA BOCCA`]: `ORBICULARIS ORIS MUSCLE`,
  [`MUSCOLO ORBICOLARE DELLA BOCCA (PARTE LABIALE)`]: `ORBICULARIS ORIS MUSCLE (LABIAL PART)`,
  [`MUSCOLO ORBICOLARE DELLA BOCCA (PARTE MARGINALE)`]: `ORBICULARIS ORIS MUSCLE (MARGINAL PART)`,
  [`MUSCOLO OTTURATORE ESTERNO DESTRO`]: `RIGHT OBTURATOR EXTERNUS MUSCLE`,
  [`MUSCOLO OTTURATORE ESTERNO SINISTRO`]: `LEFT OBTURATOR EXTERNUS MUSCLE`,
  [`Muscolo otturatore interno (DX)`]: `Obturator internus muscle (R)`,
  [`Muscolo otturatore interno (SX)`]: `Obturator internus muscle (L)`,
  [`MUSCOLO OTTURATORE INTERNO DESTRO`]: `RIGHT OBTURATOR INTERNUS MUSCLE`,
  [`MUSCOLO OTTURATORE INTERNO SINISTRO`]: `LEFT OBTURATOR INTERNUS MUSCLE`,
  [`MUSCOLO PALATOFARINGEO DESTRO`]: `RIGHT PALATOPHARYNGEUS MUSCLE`,
  [`MUSCOLO PALATOFARINGEO SINISTRO`]: `LEFT PALATOPHARYNGEUS MUSCLE`,
  [`MUSCOLO PALATOGLOSSO DESTRO`]: `RIGHT PALATOGLOSSUS MUSCLE`,
  [`MUSCOLO PALATOGLOSSO SINISTRO`]: `LEFT PALATOGLOSSUS MUSCLE`,
  [`MUSCOLO PALMARE BREVE DESTRO`]: `RIGHT PALMARIS BREVIS MUSCLE`,
  [`MUSCOLO PALMARE BREVE SINISTRO`]: `LEFT PALMARIS BREVIS MUSCLE`,
  [`Muscolo palmare lungo (DX)`]: `Palmaris longus muscle (R)`,
  [`Muscolo palmare lungo (SX)`]: `Palmaris longus muscle (L)`,
  [`MUSCOLO PALMARE LUNGO DESTRO`]: `RIGHT PALMARIS LONGUS MUSCLE`,
  [`MUSCOLO PALMARE LUNGO SINISTRO`]: `LEFT PALMARIS LONGUS MUSCLE`,
  [`MUSCOLO PEDIDIO DESTRO`]: `RIGHT PEDIDIUM MUSCLE`,
  [`MUSCOLO PEDIDIO SINISTRO`]: `LEFT PEDIDIUM MUSCLE`,
  [`Muscolo peroniero breve (DX)`]: `Peroneus brevis muscle (R)`,
  [`Muscolo peroniero breve (SX)`]: `Peroneus brevis mucle (L)`,
  [`MUSCOLO PERONIERO BREVE DESTRO`]: `RIGHT PERONEUS BREVIS MUSCLE`,
  [`MUSCOLO PERONIERO BREVE SINISTRO`]: `LEFT PERONEUS BREVIS MUSCLE`,
  [`Muscolo peroniero lungo (DX)`]: `Peroneus longus muscle (R)`,
  [`Muscolo peroniero lungo (SX)`]: `Peroneus longus mucle (L)`,
  [`MUSCOLO PERONIERO LUNGO DESTRO`]: `RIGHT PERONEUS LONGUS MUSCLE`,
  [`MUSCOLO PERONIERO LUNGO SINISTRO`]: `LEFT PERONEUS LONGUS MUSCLE`,
  [`Muscolo peroniero terzo (DX)`]: `Peroneus tertius muscle (R)`,
  [`Muscolo peroniero terzo (SX)`]: `Peroneus tertius mucle (L)`,
  [`MUSCOLO PERONIERO TERZO DESTRO`]: `RIGHT PERONEUS TERTIUS MUSCLE`,
  [`MUSCOLO PERONIERO TERZO SINISTRO`]: `LEFT PERONEUS TERTIUS MUSCLE`,
  [`Muscolo pettineo (DX)`]: `Pectineus muscle (R)`,
  [`Muscolo pettineo (SX)`]: `Pectineus muscle (L)`,
  [`MUSCOLO PETTINEO DESTRO`]: `RIGHT PECTINEUS MUSCLE`,
  [`MUSCOLO PETTINEO SINISTRO`]: `LEFT PECTINEUS MUSCLE`,
  [`Muscolo piccolo gluteo (DX)`]: `Gluteus minimus muscle (R)`,
  [`Muscolo piccolo gluteo (SX)`]: `Gluteus minimus muscle (L)`,
  [`MUSCOLO PICCOLO GLUTEO DESTRO`]: `RIGHT GLUTEUS MINIMUS MUSCLE`,
  [`MUSCOLO PICCOLO GLUTEO SINISTRO`]: `LEFT GLUTEUS MINIMUS MUSCLE`,
  [`Muscolo piccolo pettorale (Borstad method) (DX)`]: `Pectoralis minor muscle (Borstad method) (R)`,
  [`Muscolo piccolo pettorale (Borstad method) (SX)`]: `Pectoralis minor muscle (Borstad method) (L)`,
  [`Muscolo piccolo pettorale (DX)`]: `Pectoralis minor muscle (R)`,
  [`Muscolo piccolo pettorale (SX)`]: `Pectoralis minor muscle (L)`,
  [`MUSCOLO PICCOLO PETTORALE DESTRO`]: `RIGHT PECTORALIS MINOR MUSCLE`,
  [`MUSCOLO PICCOLO PETTORALE SINISTRO`]: `LEFT PECTORALIS MINOR MUSCLE`,
  [`MUSCOLO PICCOLO RETTO POSTERIORE DELLA TESTA DESTRO`]: `RIGHT RECTUS CAPITIS POSTERIOR MINOR MUSCLE`,
  [`MUSCOLO PICCOLO RETTO POSTERIORE DELLA TESTA SINISTRO`]: `LEFT RECTUS CAPITIS POSTERIOR MINOR MUSCLE`,
  [`Muscolo piccolo rotondo (DX)`]: `Teres minor muscle (R)`,
  [`Muscolo piccolo rotondo (SX)`]: `Teres minor muscle (L)`,
  [`MUSCOLO PICCOLO ROTONDO DESTRO`]: `RIGHT TERES MINOR MUSCLE`,
  [`MUSCOLO PICCOLO ROTONDO SINISTRO`]: `LEFT TERES MINOR MUSCLE`,
  [`MUSCOLO PIRAMIDALE DELL'ADDOME DESTRO`]: `RIGHT PYRAMIDALIS MUSCLE`,
  [`MUSCOLO PIRAMIDALE DELL'ADDOME SINISTRO`]: `LEFT PYRAMIDALIS MUSCLE`,
  [`Muscolo piriforme (DX)`]: `Piriformis muscle (R)`,
  [`Muscolo piriforme (SX)`]: `Piriformis muscle (L)`,
  [`MUSCOLO PIRIFORME DESTRO`]: `RIGHT PIRIFORMIS MUSCLE`,
  [`MUSCOLO PIRIFORME SINISTRO`]: `LEFT PIRIFORMIS MUSCLE`,
  [`Muscolo plantare (DX)`]: `Plantaris muscle (R)`,
  [`Muscolo plantare (SX)`]: `Plantaris muscle (L)`,
  [`MUSCOLO PLANTARE DESTRO`]: `RIGHT PLANTARIS MUSCLE`,
  [`MUSCOLO PLANTARE SINISTRO`]: `LEFT PLANTARIS MUSCLE`,
  [`MUSCOLO PLATISMA DESTRO`]: `RIGHT PLATYSMA MUSCLE`,
  [`MUSCOLO PLATISMA SINISTRO`]: `LEFT PLATYSMA MUSCLE`,
  [`Muscolo popliteo (DX)`]: `Popliteus muscle (R)`,
  [`Muscolo popliteo (SX)`]: `Popliteus muscle (L)`,
  [`MUSCOLO POPLITEO DESTRO`]: `RIGHT POPLITEUS MUSCLE`,
  [`MUSCOLO POPLITEO SINISTRO`]: `LEFT POPLITEUS MUSCLE`,
  [`MUSCOLO PROCERO DESTRO`]: `RIGHT PROCERUS MUSCLE`,
  [`MUSCOLO PROCERO SINISTRO`]: `LEFT PROCERUS MUSCLE`,
  [`Muscolo pronatore quadrato (DX)`]: `Pronator quadratus muscle (R)`,
  [`Muscolo pronatore quadrato (SX)`]: `Pronator quadratus muscle (L)`,
  [`MUSCOLO PRONATORE QUADRATO DESTRO`]: `RIGHT PRONATOR QUADRATUS MUSCLE`,
  [`MUSCOLO PRONATORE QUADRATO SINISTRO`]: `LEFT PRONATOR QUADRATUS MUSCLE`,
  [`Muscolo pronatore rotondo (DX)`]: `Pronator teres muscle (R)`,
  [`Muscolo pronatore rotondo (SX)`]: `Pronator teres muscle (L)`,
  [`MUSCOLO PRONATORE ROTONDO DESTRO`]: `RIGHT PRONATOR TERES MUSCLE`,
  [`MUSCOLO PRONATORE ROTONDO DESTRO (CAPO OMERALE)`]: `RIGHT PRONATOR TERES MUSCLE (HUMERAL HEAD)`,
  [`MUSCOLO PRONATORE ROTONDO DESTRO (CAPO ULNARE)`]: `RIGHT PRONATOR TERES MUSCLE (ULNAR HEAD)`,
  [`MUSCOLO PRONATORE ROTONDO SINISTRO`]: `LEFT PRONATOR TERES MUSCLE`,
  [`MUSCOLO PRONATORE ROTONDO SINISTRO (CAPO OMERALE)`]: `LEFT PRONATOR TERES MUSCLE (HUMERAL HEAD)`,
  [`MUSCOLO PRONATORE ROTONDO SINISTRO (CAPO ULNARE)`]: `LEFT PRONATOR TERES MUSCLE (ULNAR HEAD)`,
  [`Muscolo pterigoideo laterale (DX)`]: `Lateral pterygoideus muscle (R)`,
  [`Muscolo pterigoideo laterale (SX)`]: `Lateral pterygoideus muscle (L)`,
  [`MUSCOLO PTERIGOIDEO LATERALE DESTRO`]: `RIGHT LATERAL PTERYGOID MUSCLE`,
  [`MUSCOLO PTERIGOIDEO LATERALE DESTRO (CAPO INFERIORE)`]: `RIGHT LATERAL PTERYGOID MUSCLE (INFERIOR HEAD)`,
  [`MUSCOLO PTERIGOIDEO LATERALE DESTRO (CAPO SUPERIORE)`]: `RIGHT LATERAL PTERYGOID MUSCLE (SUPERIOR HEAD)`,
  [`MUSCOLO PTERIGOIDEO LATERALE SINISTRO`]: `LEFT LATERAL PTERYGOID MUSCLE`,
  [`MUSCOLO PTERIGOIDEO LATERALE SINISTRO (CAPO INFERIORE)`]: `LEFT LATERAL PTERYGOID MUSCLE (INFERIOR HEAD)`,
  [`MUSCOLO PTERIGOIDEO LATERALE SINISTRO (CAPO SUPERIORE)`]: `LEFT LATERAL PTERYGOID MUSCLE (SUPERIOR HEAD)`,
  [`Muscolo pterigoideo mediale (DX)`]: `Medial pterygoideus muscle (R)`,
  [`Muscolo pterigoideo mediale (SX)`]: `Medial pterygoideus muscle (L)`,
  [`MUSCOLO PTERIGOIDEO MEDIALE DESTRO`]: `RIGHT MEDIAL PTERYGOID MUSCLE`,
  [`MUSCOLO PTERIGOIDEO MEDIALE SINISTRO`]: `LEFT MEDIAL PTERYGOID MUSCLE`,
  [`MUSCOLO PUBOANALE DESTRO`]: `RIGHT PUBOANAL MUSCLE`,
  [`MUSCOLO PUBOANALE SINISTRO`]: `LEFT PUBOANAL MUSCLE`,
  [`MUSCOLO PUBOCOCCIGEO DESTRO`]: `RIGHT PUBOCOCCYGEAL MUSCLE`,
  [`MUSCOLO PUBOCOCCIGEO SINISTRO`]: `LEFT PUBOCOCCYGEAL MUSCLE`,
  [`MUSCOLO PUBOPERINEALE DESTRO`]: `RIGHT PUBOPERINEAL MUSCLE`,
  [`MUSCOLO PUBOPERINEALE SINISTRO`]: `LEFT PUBOPERINEAL MUSCLE`,
  [`MUSCOLO PUBOPROSTATICO (M)`]: `PUBOPROSTATIC MUSCLE (M)`,
  [`MUSCOLO PUBOPROSTATICO DESTRO (M)`]: `RIGHT PUBOPROSTATIC MUSCLE (M)`,
  [`MUSCOLO PUBOPROSTATICO SINISTRO (M)`]: `LEFT PUBOPROSTATIC MUSCLE (M)`,
  [`MUSCOLO PUBORETTALE DESTRO`]: `RIGHT PUBORECTALIS MUSCLE`,
  [`MUSCOLO PUBORETTALE SINISTRO`]: `LEFT PUBORECTALIS MUSCLE`,
  [`MUSCOLO PUBOVAGINALE DESTRO (F)`]: `RIGHT PUBOVAGINALIS MUSCLE (F)`,
  [`MUSCOLO PUBOVAGINALE SINISTRO (F)`]: `LEFT PUBOVAGINALIS MUSCLE (F)`,
  [`MUSCOLO PUBOVESCICALE`]: `PUBOVESICALIS MUSCLE`,
  [`Muscolo quadrato dei lombi (DX)`]: `Quadratus lumborum muscle (R)`,
  [`Muscolo quadrato dei lombi (SX)`]: `Quadratus lumborum muscle (L)`,
  [`MUSCOLO QUADRATO DEI LOMBI DESTRO`]: `RIGHT QUADRATUS LUMBORUM MUSCLE`,
  [`MUSCOLO QUADRATO DEI LOMBI SINISTRO`]: `LEFT QUADRATUS LUMBORUM MUSCLE`,
  [`Muscolo quadrato del femore (DX)`]: `Quadratus femoris muscle (R)`,
  [`Muscolo quadrato del femore (SX)`]: `Quadratus femoris muscle (L)`,
  [`MUSCOLO QUADRATO DEL FEMORE DESTRO`]: `RIGHT QUADRATUS FEMORIS MUSCLE`,
  [`MUSCOLO QUADRATO DEL FEMORE SINISTRO`]: `LEFT QUADRATUS FEMORIS MUSCLE`,
  [`Muscolo quadrato della pianta (DX)`]: `Quadratus plantae muscle (R)`,
  [`Muscolo quadrato della pianta (SX)`]: `Quadratus plantae muscle (L)`,
  [`MUSCOLO QUADRATO DELLA PIANTA DESTRO`]: `RIGHT QUADRATUS PLANTAE MUSCLE`,
  [`MUSCOLO QUADRATO DELLA PIANTA SINISTRO`]: `LEFT QUADRATUS PLANTAE MUSCLE`,
  [`Muscolo quadricipite (DX)`]: `Quadriceps muscle (R)`,
  [`Muscolo quadricipite (SX)`]: `Quadriceps muscle (L)`,
  [`MUSCOLO QUADRICIPITE FEMORALE DESTRO`]: `RIGHT QUADRICEPS FEMORIS MUSCLE`,
  [`MUSCOLO QUADRICIPITE FEMORALE SINISTRO`]: `LEFT QUADRICEPS FEMORIS MUSCLE`,
  [`MUSCOLO RETTO ANTERIORE DELLA TESTA DESTRO`]: `RIGHT RECTUS CAPITIS ANTERIOR MUSCLE`,
  [`MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO`]: `LEFT RECTUS CAPITIS ANTERIOR MUSCLE`,
  [`Muscolo retto dell'addome`]: `Rectus abdominis muscle`,
  [`Muscolo retto dell'addome (DX)`]: `Rectus abdominis muscle (R)`,
  [`Muscolo retto dell'addome (SX)`]: `Rectus abdominis muscle (L)`,
  [`MUSCOLO RETTO DELL'ADDOME DESTRO`]: `RIGHT RECTUS ABDOMINIS MUSCLE`,
  [`MUSCOLO RETTO DELL'ADDOME SINISTRO`]: `LEFT RECTUS ABDOMINIS MUSCLE`,
  [`Muscolo retto femorale (DX)`]: `Rectus femoris muscle (R)`,
  [`Muscolo retto femorale (SX)`]: `Rectus femoris muscle (L)`,
  [`MUSCOLO RETTO FEMORALE DESTRO`]: `RIGHT RECTUS FEMORIS MUSCLE`,
  [`MUSCOLO RETTO FEMORALE SINISTRO`]: `LEFT RECTUS FEMORIS MUSCLE`,
  [`MUSCOLO RETTO INFERIORE DELL'OCCHIO DESTRO`]: `INFERIOR RECTUS MUSCLE OF RIGHT EYE`,
  [`MUSCOLO RETTO INFERIORE DELL'OCCHIO SINISTRO`]: `INFERIOR RECTUS MUSCLE OF LEFT EYE`,
  [`MUSCOLO RETTO LATERALE DELL'OCCHIO DESTRO`]: `LATERAL RECTUS MUSCLE OF RIGHT EYE`,
  [`MUSCOLO RETTO LATERALE DELL'OCCHIO SINISTRO`]: `LATERAL RECTUS MUSCLE OF LEFT EYE`,
  [`MUSCOLO RETTO LATERALE DELLA TESTA DESTRO`]: `RIGHT RECTUS CAPITIS LATERALIS MUSCLE`,
  [`MUSCOLO RETTO LATERALE DELLA TESTA SINISTRO`]: `LEFT RECTUS CAPITIS LATERALIS MUSCLE`,
  [`MUSCOLO RETTO MEDIALE DELL'OCCHIO DESTRO`]: `MEDIAL RECTUS MUSCLE OF RIGHT EYE`,
  [`MUSCOLO RETTO MEDIALE DELL'OCCHIO SINISTRO`]: `MEDIAL RECTUS MUSCLE OF LEFT EYE`,
  [`MUSCOLO RETTO SUPERIORE DELL'OCCHIO DESTRO`]: `SUPERIOR RECTUS MUSCLE OF RIGHT EYE`,
  [`MUSCOLO RETTO SUPERIORE DELL'OCCHIO SINISTRO`]: `SUPERIOR RECTUS MUSCLE OF LEFT EYE`,
  [`MUSCOLO RISORIO DESTRO`]: `RIGHT RISORIUS MUSCLE`,
  [`MUSCOLO RISORIO SINISTRO`]: `LEFT RISORIUS MUSCLE`,
  [`Muscolo romboideo maggiore (DX)`]: `Rhomboid major muscle (R)`,
  [`Muscolo romboideo maggiore (SX)`]: `Rhomboid major muscle (L)`,
  [`MUSCOLO ROMBOIDEO MAGGIORE DESTRO`]: `RIGHT RHOMBOID MAJOR MUSCLE`,
  [`MUSCOLO ROMBOIDEO MAGGIORE SINISTRO`]: `LEFT RHOMBOID MAJOR MUSCLE`,
  [`Muscolo romboideo minore (DX)`]: `Rhomboid minor muscle (R)`,
  [`Muscolo romboideo minore (SX)`]: `Rhomboid minor muscle (L)`,
  [`MUSCOLO ROMBOIDEO MINORE DESTRO`]: `RIGHT RHOMBOID MINOR MUSCLE`,
  [`MUSCOLO ROMBOIDEO MINORE SINISTRO`]: `LEFT RHOMBOID MINOR MUSCLE`,
  [`MUSCOLO SALPINGOFARINGEO DESTRO`]: `RIGHT SALPINGOPHARYNGEAL MUSCLE`,
  [`MUSCOLO SALPINGOFARINGEO SINISTRO`]: `LEFT SALPINGOPHARYNGEAL MUSCLE`,
  [`Muscolo sartorio (DX)`]: `Sartorius muscle (R)`,
  [`Muscolo sartorio (SX)`]: `Sartorius muscle (L)`,
  [`MUSCOLO SARTORIO DESTRO`]: `RIGHT SARTORIUS MUSCLE`,
  [`MUSCOLO SARTORIO SINISTRO`]: `LEFT SARTORIUS MUSCLE`,
  [`Muscolo scaleno anteriore (DX)`]: `Scalenus anterior muscle (R)`,
  [`Muscolo scaleno anteriore (SX)`]: `Scalenus anterior muscle (L)`,
  [`MUSCOLO SCALENO ANTERIORE DESTRO`]: `RIGHT SCALENUS ANTERIOR MUSCLE`,
  [`MUSCOLO SCALENO ANTERIORE SINISTRO`]: `LEFT SCALENUS ANTERIOR MUSCLE`,
  [`Muscolo scaleno medio (DX)`]: `Scalenus medius muscle (R)`,
  [`Muscolo scaleno medio (SX)`]: `Scalenus medius muscle (L)`,
  [`MUSCOLO SCALENO MEDIO DESTRO`]: `RIGHT SCALENUS MEDIUS MUSCLE`,
  [`MUSCOLO SCALENO MEDIO SINISTRO`]: `LEFT SCALENUS MEDIUS MUSCLE`,
  [`MUSCOLO SCALENO POSTERIORE DESTRO`]: `RIGHT SCALENUS POSTERIOR MUSCLE`,
  [`MUSCOLO SCALENO POSTERIORE SINISTRO`]: `LEFT SCALENUS POSTERIOR MUSCLE`,
  [`Muscolo semimembranoso (DX)`]: `Semimembranosus muscle (R)`,
  [`Muscolo semimembranoso (SX)`]: `Semimembranosus muscle (L)`,
  [`MUSCOLO SEMIMEMBRANOSO DESTRO`]: `RIGHT SEMIMEMBRANOSUS MUSCLE`,
  [`MUSCOLO SEMIMEMBRANOSO SINISTRO`]: `LEFT SEMIMEMBRANOSUS MUSCLE`,
  [`MUSCOLO SEMISPINALE DEL COLLO DESTRO`]: `RIGHT SEMISPINALIS CERVICIS MUSCLE`,
  [`MUSCOLO SEMISPINALE DEL COLLO SINISTRO`]: `LEFT SEMISPINALIS CERVICIS MUSCLE`,
  [`MUSCOLO SEMISPINALE DEL TORACE DESTRO`]: `RIGHT SEMISPINALIS THORACIS MUSCLE`,
  [`MUSCOLO SEMISPINALE DEL TORACE SINISTRO`]: `LEFT SEMISPINALIS THORACIS MUSCLE`,
  [`MUSCOLO SEMISPINALE DELLA TESTA DESTRO`]: `RIGHT SEMISPINALIS CAPITIS MUSCLE`,
  [`MUSCOLO SEMISPINALE DELLA TESTA SINISTRO`]: `LEFT SEMISPINALIS CAPITIS MUSCLE`,
  [`Muscolo semitendinoso (DX)`]: `Semitendinosus muscle (R)`,
  [`Muscolo semitendinoso (SX)`]: `Semitendinosus muscle (L)`,
  [`MUSCOLO SEMITENDINOSO DESTRO`]: `RIGHT SEMITENDINOSUS MUSCLE`,
  [`MUSCOLO SEMITENDINOSO SINISTRO`]: `LEFT SEMITENDINOSUS MUSCLE`,
  [`Muscolo serrato anteriore (DX)`]: `Serratus anterior muscle (R)`,
  [`Muscolo serrato anteriore (SX)`]: `Serratus anterior muscle (L)`,
  [`MUSCOLO SERRATO ANTERIORE DESTRO`]: `RIGHT SERRATUS ANTERIOR MUSCLE`,
  [`MUSCOLO SERRATO ANTERIORE SINISTRO`]: `LEFT SERRATUS ANTERIOR MUSCLE`,
  [`MUSCOLO SFINTERE ESTERNO DELL'ANO`]: `EXTERNAL ANAL SPHINCTER`,
  [`MUSCOLO SFINTERE ESTERNO DELL'URETRA (F)`]: `EXTERNAL URETHRAL SPHINCTER (F)`,
  [`MUSCOLO SFINTERE ESTERNO DELL'URETRA (M)`]: `EXTERNAL URETHRAL SPHINCTER (M)`,
  [`MUSCOLO SFINTERE INTERNO DELL'ANO`]: `INTERNAL ANAL SPHINCTER`,
  [`MUSCOLO SFINTERE URETROVAGINALE (F)`]: `URETHRALVAGINAL SPHINCTER (F)`,
  [`Muscolo soleo (DX)`]: `Soleus muscle (R)`,
  [`Muscolo soleo (SX)`]: `Soleus muscle (L)`,
  [`Muscolo soleo accessorio (excl inj to that muscle)`]: `Accessory soleus muscle (excl inj to that muscle)`,
  [`MUSCOLO SOLEO DESTRO`]: `RIGHT SOLEUS MUSCLE`,
  [`MUSCOLO SOLEO SINISTRO`]: `LEFT SOLEUS MUSCLE`,
  [`Muscolo sopraspinato (DX)`]: `Supraspinatus muscle (R)`,
  [`Muscolo sopraspinato (SX)`]: `Supraspinatus muscle (L)`,
  [`MUSCOLO SOPRASPINATO DESTRO`]: `RIGHT SUPRASPINATUS MUSCLE`,
  [`MUSCOLO SOPRASPINATO SINISTRO`]: `LEFT SUPRASPINATUS MUSCLE`,
  [`MUSCOLO SOSPENSORE DEL DUODENO`]: `SUSPENSOR DUODENUM MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA I COSTA DESTRA`]: `RIGHT I SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA I COSTA SINISTRA`]: `LEFT I SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA II COSTA DESTRA`]: `RIGHT II SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA II COSTA SINISTRA`]: `LEFT II SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA III COSTA SINISTRA`]: `RIGHT III SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA IIII COSTA DESTRA`]: `LEFT III SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA IV COSTA DESTRA`]: `RIGHT IV SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA IV COSTA SINISTRA`]: `LEFT IV SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA IX COSTA DESTRA`]: `RIGHT IX SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA IX COSTA SINISTRA`]: `LEFT IX SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA V COSTA DESTRA`]: `RIGHT V SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA V COSTA SINISTRA`]: `LEFT V SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA VI COSTA DESTRA`]: `RIGHT VI SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA VI COSTA SINISTRA`]: `LEFT VI SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA VII COSTA DESTRA`]: `RIGHT VII SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA VII COSTA SINISTRA`]: `LEFT VII SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA VIII COSTA DESTRA`]: `RIGHT VIII SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA VIII COSTA SINISTRA`]: `LEFT VIII SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA X COSTA DESTRA`]: `RIGHT X SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA X COSTA SINISTRA`]: `LEFT X SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA XI COSTA DESTRA`]: `RIGHT XI SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA XI COSTA SINISTRA`]: `LEFT XI SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE DELLA XII COSTA DESTRA`]: `RIGHT XII SUBCOSTALES MUSCLE`,
  [`MUSCOLO SOTTOCOSTALE ESTERNO DELLA XII COSTA SINISTRA`]: `LEFT XII SUBCOSTALES MUSCLE`,
  [`Muscolo sottoscapolare (DX)`]: `Subscapularis muscle (R)`,
  [`Muscolo sottoscapolare (SX)`]: `Subscapularis muscle (L)`,
  [`MUSCOLO SOTTOSCAPOLARE DESTRO`]: `RIGHT SUBSCAPULARIS MUSCLE`,
  [`MUSCOLO SOTTOSCAPOLARE SINISTRO`]: `LEFT SUBSCAPULARIS MUSCLE`,
  [`Muscolo sottospinato (DX)`]: `Infraspinatus muscle (R)`,
  [`Muscolo sottospinato (SX)`]: `Infraspinatus muscle (L)`,
  [`MUSCOLO SOTTOSPINATO DESTRO`]: `RIGHT INFRASPINATUS MUSCLE`,
  [`MUSCOLO SOTTOSPINATO SINISTRO`]: `LEFT INFRASPINATUS MUSCLE`,
  [`MUSCOLO SPINALE DEL COLLO DESTRO`]: `RIGHT SPINALIS CERVICIS MUSCLE`,
  [`MUSCOLO SPINALE DEL COLLO SINISTRO`]: `LEFT SPINALIS CERVICIS MUSCLE`,
  [`MUSCOLO SPINALE DEL TORACE DESTRO`]: `RIGHT SPINALIS THORACIS MUSCLE`,
  [`MUSCOLO SPINALE DEL TORACE SINISTRO`]: `LEFT SPINALIS THORACIS MUSCLE`,
  [`MUSCOLO SPINALE DELLA TESTA DESTRO`]: `RIGHT SPINALIS CAPITIS MUSCLE`,
  [`MUSCOLO SPINALE DELLA TESTA SINISTRO`]: `LEFT SPINALIS CAPITIS MUSCLE`,
  [`Muscolo splenio del collo (DX)`]: `Splenius cervicis muscle (R)`,
  [`Muscolo splenio del collo (SX)`]: `Splenius cervicis muscle (L)`,
  [`MUSCOLO SPLENIO DEL COLLO DESTRO`]: `RIGHT SPLENIUS CERVICIS MUSCLE`,
  [`MUSCOLO SPLENIO DEL COLLO SINISTRO`]: `LEFT SPLENIUS CERVICIS MUSCLE`,
  [`MUSCOLO SPLENIO DELLA TESTA DESTRO`]: `RIGHT SPLENIUS CAPITIS MUSCLE`,
  [`MUSCOLO SPLENIO DELLA TESTA SINISTRO`]: `LEFT SPLENIUS CAPITIS MUSCLE`,
  [`MUSCOLO STAPEDIO DESTRO`]: `RIGHT STAPEDIUS MUSCLE`,
  [`MUSCOLO STAPEDIO SINISTRO`]: `LEFT STAPEDIUS MUSCLE`,
  [`Muscolo sternale (DX)`]: `Sternalis muscle (R)`,
  [`Muscolo sternale (SX)`]: `Sternalis muscle (L)`,
  [`Muscolo sternocleidomastoideo (DX)`]: `Sternocleidomastoid muscle (R)`,
  [`Muscolo sternocleidomastoideo (SX)`]: `Sternocleidomastoid muscle (L)`,
  [`MUSCOLO STERNOCLEIDOMASTOIDEO DESTRO`]: `RIGHT STERNOCLEIDOMASTOID MUSCLE`,
  [`MUSCOLO STERNOCLEIDOMASTOIDEO SINISTRO`]: `LEFT STERNOCLEIDOMASTOID MUSCLE`,
  [`Muscolo sternocleidomatoideo (SX)`]: `Sternocleidomastoid muscle (L)`,
  [`Muscolo sternoioideo (DX)`]: `Sternohyoid muscle (R)`,
  [`Muscolo sternoioideo (SX)`]: `Sternohyoid muscle (L)`,
  [`MUSCOLO STERNOIOIDEO DESTRO`]: `RIGHT STERNOHYOID MUSCLE`,
  [`MUSCOLO STERNOIOIDEO SINISTRO`]: `LEFT STERNOHYOID MUSCLE`,
  [`MUSCOLO STERNOTIROIDEO DESTRO`]: `RIGHT STERNOTHYROID MUSCLE`,
  [`MUSCOLO STERNOTIROIDEO SINISTRO`]: `LEFT STERNOTHYROID MUSCLE`,
  [`MUSCOLO STILOFARINGEO DESTRO`]: `RIGHT STYLOPHARYNGEUS MUSCLE`,
  [`MUSCOLO STILOFARINGEO SINISTRO`]: `LEFT STYLOPHARYNGEUS MUSCLE`,
  [`MUSCOLO STILOGLOSSO DESTRO`]: `RIGHT STYLOGLOSSUS MUSCLE`,
  [`MUSCOLO STILOGLOSSO SINISTRO`]: `LEFT STYLOGLOSSUS MUSCLE`,
  [`Muscolo stiloioideo (DX)`]: `Stilohyoid muscle (R)`,
  [`Muscolo stiloioideo (SX)`]: `Stilohyoid muscle (L)`,
  [`MUSCOLO STILOIOIDEO DESTRO`]: `RIGHT STYLOHYOID MUSCLE`,
  [`MUSCOLO STILOIOIDEO SINISTRO`]: `LEFT STYLOHYOID MUSCLE`,
  [`Muscolo succlavio (DX)`]: `Subclavius muscle (R)`,
  [`Muscolo succlavio (SX)`]: `Subclavius muscle (L)`,
  [`MUSCOLO SUCCLAVIO DESTRO`]: `RIGHT SUBCLAVIUS MUSCLE`,
  [`MUSCOLO SUCCLAVIO SINISTRO`]: `LEFT SUBCLAVIUS MUSCLE`,
  [`Muscolo supinatore (DX)`]: `Supinator muscle (R)`,
  [`Muscolo supinatore (SX)`]: `Supinator muscle (L)`,
  [`MUSCOLO SUPINATORE DESTRO`]: `RIGHT SUPINATOR MUSCLE`,
  [`MUSCOLO SUPINATORE SINISTRO`]: `LEFT SUPINATOR MUSCLE`,
  [`Muscolo temporale (DX)`]: `Temporal muscle (R)`,
  [`Muscolo temporale (SX)`]: `Temporal muscle (L)`,
  [`MUSCOLO TEMPORALE DESTRO`]: `RIGHT TEMPORAL MUSCLE`,
  [`MUSCOLO TEMPORALE SINISTRO`]: `LEFT TEMPORAL MUSCLE`,
  [`MUSCOLO TEMPOROPARIETALE DESTRO`]: `RIGHT TEMPOROPARIETAL MUSCLE`,
  [`MUSCOLO TEMPOROPARIETALE SINISTRO`]: `LEFT TEMPOROPARIETAL MUSCLE`,
  [`MUSCOLO TENSORE DEL TIMPANO DESTRO`]: `RIGHT TENSOR TYMPANI MUSCLE`,
  [`MUSCOLO TENSORE DEL TIMPANO SINISTRO`]: `LEFT TENSOR TYMPANI MUSCLE`,
  [`MUSCOLO TENSORE DEL VELO PALATINO DESTRO`]: `RIGHT TENSOR VELI PALATINI MUSCLE`,
  [`MUSCOLO TENSORE DEL VELO PALATINO SINISTRO`]: `LEFT TENSOR VELI PALATINI MUSCLE`,
  [`Muscolo tensore della fascia lata (DX)`]: `Tensor fascia lata muscle (R)`,
  [`Muscolo tensore della fascia lata (SX)`]: `Tensor fascia lata muscle (L)`,
  [`MUSCOLO TENSORE DELLA FASCIA LATA DESTRO`]: `RIGHT TENSOR FASCIAE LATAE MUSCLE`,
  [`MUSCOLO TENSORE DELLA FASCIA LATA SINISTRO`]: `LEFT TENSOR FASCIAE LATAE MUSCLE`,
  [`Muscolo tibiale anteriore (DX)`]: `Tibialis anterior muscle (R)`,
  [`Muscolo tibiale anteriore (SX)`]: `Tibialis anterior muscle (L)`,
  [`MUSCOLO TIBIALE ANTERIORE DESTRO`]: `RIGHT TIBIALIS ANTERIOR MUSCLE`,
  [`MUSCOLO TIBIALE ANTERIORE SINISTRO`]: `LEFT TIBIALIS ANTERIOR MUSCLE`,
  [`Muscolo tibiale posteriore (DX)`]: `Tibialis posterior muscle (R)`,
  [`Muscolo tibiale posteriore (SX)`]: `Tibialis posterior muscle (L)`,
  [`MUSCOLO TIBIALE POSTERIORE DESTRO`]: `RIGHT TIBIALIS POSTERIOR MUSCLE`,
  [`MUSCOLO TIBIALE POSTERIORE SINISTRO`]: `LEFT TIBIALIS POSTERIOR MUSCLE`,
  [`MUSCOLO TIROARITENOIDEO DESTRO`]: `RIGHT THYROARYTENOID MUSCLE`,
  [`MUSCOLO TIROARITENOIDEO SINISTRO`]: `LEFT THYROARYTENOID MUSCLE`,
  [`Muscolo tiroioideo (DX)`]: `Thyrohyoid muscle (R)`,
  [`Muscolo tiroioideo (SX)`]: `Thyrohyoid muscle (L)`,
  [`MUSCOLO TIROIOIDEO DESTRO`]: `RIGHT THYROHYOID MUSCLE`,
  [`MUSCOLO TIROIOIDEO SINISTRO`]: `LEFT THYROHYOID MUSCLE`,
  [`Muscolo trapezio (parte ascendente) (DX)`]: `Trapezius muscle (inferior part) (R)`,
  [`Muscolo trapezio (parte ascendente) (SX)`]: `Trapezius muscle (inferior part) (L)`,
  [`Muscolo trapezio (parte discendente) (DX)`]: `Trapezius muscle (superior part) (R)`,
  [`Muscolo trapezio (parte discendente) (SX)`]: `Trapezius muscle (superior part) (L)`,
  [`Muscolo trapezio (parte trasversa) (DX)`]: `Trapezius muscle (middle part) (R)`,
  [`Muscolo trapezio (parte trasversa) (SX)`]: `Trapezius muscle (middle part) (L)`,
  [`MUSCOLO TRAPEZIO DESTRO`]: `RIGHT TRAPEZIUS MUSCLE`,
  [`MUSCOLO TRAPEZIO DESTRO (PARTE ASCENDENTE)`]: `RIGHT TRAPEZIUS MUSCLE (INFERIOR PART)`,
  [`MUSCOLO TRAPEZIO DESTRO (PARTE DISCENDENTE)`]: `RIGHT TRAPEZIUS MUSCLE (SUPERIOR PART)`,
  [`MUSCOLO TRAPEZIO DESTRO (PARTE TRASVERSA)`]: `RIGHT TRAPEZIUS MUSCLE (MIDDLE PART)`,
  [`Muscolo trapezio parte superiore (DX)`]: `Trapezius muscle (superior part) (R)`,
  [`Muscolo trapezio parte superiore (SX)`]: `Trapezius muscle (superior part) (L)`,
  [`MUSCOLO TRAPEZIO SINISTRO`]: `LEFT TRAPEZIUS MUSCLE`,
  [`MUSCOLO TRAPEZIO SINISTRO (PARTE ASCENDENTE)`]: `LEFT TRAPEZIUS MUSCLE (INFERIOR PART)`,
  [`MUSCOLO TRAPEZIO SINISTRO (PARTE DISCENDENTE)`]: `LEFT TRAPEZIUS MUSCLE (SUPERIOR PART)`,
  [`MUSCOLO TRAPEZIO SINISTRO (PARTE TRASVERSA)`]: `LEFT TRAPEZIUS MUSCLE (MIDDLE PART)`,
  [`MUSCOLO TRASVERSO DEL TORACE DESTRO`]: `RIGHT TRANSVERSUS THORACIS MUSCLE`,
  [`MUSCOLO TRASVERSO DEL TORACE SINISTRO`]: `LEFT TRANSVERSUS THORACIS MUSCLE`,
  [`MUSCOLO TRASVERSO DELL'ADDOME DESTRO`]: `RIGHT TRANSVERSE ABDOMINAL MUSCLE`,
  [`MUSCOLO TRASVERSO DELL'ADDOME SINISTRO`]: `LEFT TRANSVERSE ABDOMINAL MUSCLE`,
  [`MUSCOLO TRASVERSO DELLA LINGUA`]: `TRANSVERSE MUSCLE OF TONGUE`,
  [`MUSCOLO TRASVERSO PROFONDO DEL PERINEO (M)`]: `SUPERFICIAL TRANSVERSE PERINEAL MUSCLE`,
  [`MUSCOLO TRASVERSO SUPERFICIALE DEL PERINEO DESTRO`]: `RIGHT SUPERFICIAL TRANSVERSE PERINEAL MUSCLE`,
  [`MUSCOLO TRASVERSO SUPERFICIALE DEL PERINEO SINISTRO`]: `LEFT SUPERFICIAL TRANSVERSE PERINEAL MUSCLE`,
  [`MUSCOLO TRIANGOLARE DELLA VESCICA`]: `TRIGONAL MUSCLE`,
  [`Muscolo tricipite brachiale (capo laterale) (DX)`]: `Triceps brachii muscle (lateral head) (R)`,
  [`Muscolo tricipite brachiale (capo laterale) (SX)`]: `Triceps brachii muscle (lateral head) (L)`,
  [`Muscolo tricipite brachiale (capo lungo) (DX)`]: `Triceps brachii muscle (long head) (R)`,
  [`Muscolo tricipite brachiale (capo lungo) (SX)`]: `Triceps brachii muscle (long head) (L)`,
  [`Muscolo tricipite brachiale (capo mediale) (DX)`]: `Triceps brachii muscle (medial head) (R)`,
  [`Muscolo tricipite brachiale (capo mediale) (SX)`]: `Triceps brachii muscle (medial head) (L)`,
  [`Muscolo tricipite brachiale (DX)`]: `Triceps brachii muscle (R)`,
  [`Muscolo tricipite brachiale (SX)`]: `Triceps brachii muscle (L)`,
  [`MUSCOLO TRICIPITE BRACHIALE DESTRO`]: `RIGHT TRICEPS BRACHII MUSCLE`,
  [`MUSCOLO TRICIPITE BRACHIALE DESTRO (CAPO LATERALE)`]: `RIGHT TRICEPS BRACHII MUSCLE (LATERAL HEAD)`,
  [`MUSCOLO TRICIPITE BRACHIALE DESTRO (CAPO LUNGO)`]: `RIGHT TRICEPS BRACHII MUSCLE (LONG HEAD)`,
  [`MUSCOLO TRICIPITE BRACHIALE DESTRO (CAPO MEDIALE)`]: `RIGHT TRICEPS BRACHII MUSCLE (MEDIAL HEAD)`,
  [`MUSCOLO TRICIPITE BRACHIALE SINISTRO`]: `LEFT TRICEPS BRACHII MUSCLE`,
  [`MUSCOLO TRICIPITE BRACHIALE SINISTRO (CAPO LATERALE)`]: `LEFT TRICEPS BRACHII MUSCLE (LATERAL HEAD)`,
  [`MUSCOLO TRICIPITE BRACHIALE SINISTRO (CAPO LUNGO)`]: `LEFT TRICEPS BRACHII MUSCLE (LONG HEAD)`,
  [`MUSCOLO TRICIPITE BRACHIALE SINISTRO (CAPO MEDIALE)`]: `LEFT TRICEPS BRACHII MUSCLE (MEDIAL HEAD)`,
  [`MUSCOLO TRICIPITE SURALE DESTRO`]: `RIGHT TRICEPS SURAE MUSCLE`,
  [`MUSCOLO TRICIPITE SURALE SINISTRO`]: `LEFT TRICEPS SURAE MUSCLE`,
  [`MUSCOLO VASTO INTERMEDIO DESTRO`]: `RIGHT VASTUS INTERMEDIUS MUSCLE`,
  [`MUSCOLO VASTO INTERMEDIO SINISTRO`]: `LEFT VASTUS INTERMEDIUS MUSCLE`,
  [`Muscolo vasto laterale (DX)`]: `Vastus lateralis muscle (R)`,
  [`Muscolo vasto laterale (SX)`]: `Vastus lateralis muscle (L)`,
  [`MUSCOLO VASTO LATERALE DESTRO`]: `RIGHT VASTUS LATERALIS MUSCLE`,
  [`MUSCOLO VASTO LATERALE SINISTRO`]: `LEFT VASTUS LATERALIS MUSCLE`,
  [`Muscolo vasto mediale (DX)`]: `Vastus medialis muscle (R)`,
  [`Muscolo vasto mediale (SX)`]: `Vastus medialis muscle (L)`,
  [`MUSCOLO VASTO MEDIALE DESTRO`]: `RIGHT VASTUS MEDIALIS MUSCLE`,
  [`MUSCOLO VASTO MEDIALE SINISTRO`]: `LEFT VASTUS MEDIALIS MUSCLE`,
  [`MUSCOLO VERTICALE DELLA LINGUA`]: `VERTICAL MUSCLE OF TONGUE`,
  [`MUSCOLO VESCICOPROSTATICO (M)`]: `VESCICOPROSTATICUS MUSCLE (M)`,
  [`MUSCOLO VESCICOVAGINALE (F)`]: `VESCICOVAGINALIS MUSCLE (F)`,
  [`MUSCOLO ZIGOMATICO MAGGIORE DESTRO`]: `RIGHT ZYGOMATICUS MAJOR MUSCLE`,
  [`MUSCOLO ZIGOMATICO MAGGIORE SINISTRO`]: `LEFT ZYGOMATICUS MAJOR MUSCLE`,
  [`MUSCOLO ZIGOMATICO MINORE DESTRO`]: `RIGHT ZYGOMATICUS MINOR MUSCLE`,
  [`MUSCOLO ZIGOMATICO MINORE SINISTRO`]: `LEFT ZYGOMATICUS MINOR MUSCLE`,
  [`Muscolo-scheletrici`]: `Musculoskeletal`,
  [`Muscolo/tendine`]: `Muscle/tendon`,
  [`Muscoloscheletrico`]: `Musculoskeletal`,
  [`n che modo e' cominciato il dolore?`]: `How did the pain start?`,
  [`NASION`]: `NASION`,
  [`NASO`]: `NOSE`,
  [`NASO (NARICE DESTRA)`]: `NOSE (RIGHT NOSTRIL)`,
  [`NASO (NARICE SINISTRA)`]: `NOSE (LEFT NOSTRIL)`,
  [`Naso faringite`]: `Nose /throat illness /condition`,
  [`NASOFARINGE`]: `NASOPHARYNX`,
  [`Natura`]: `Nature`,
  [`nausea`]: `Nausea`,
  [`Nausea, vomito, perdita di appetito`]: `Nausea, vomiting, loss of appetite`,
  [`Nauseabondo`]: `Sickening`,
  [`Navicolare (DX)`]: `Navicular (R)`,
  [`Navicolare (SX)`]: `Navicular (L)`,
  [`NAVICOLARE DESTRO`]: `RIGHT NAVICULAR`,
  [`NAVICOLARE SINISTRO`]: `LEFT NAVICULAR`,
  [`NDI (CERVICALE)`]: `NDI (NECK DISABILITY INDEX)`,
  [`Neck Extensors Endurance Test`]: `Neck Extensors Endurance Test`,
  [`Neck Flexors Endurance Test`]: `Neck Flexors Endurance Test`,
  [`Neck Pain Task Force`]: `Neck Pain Task Force`,
  [`Necrosi avascolare`]: `Avascular necrosis`,
  [`Necrosi avascolare al polso/mano`]: `Avascular necrosis in wrist/hand`,
  [`Necrosi avascolare alla caviglia`]: `Ankle avascular necrosis`,
  [`Necrosi avascolare del semilunare`]: `Avascular necrosis lunate`,
  [`Necrosi avascolare dell'anca`]: `Hip avascular necrosis`,
  [`necrosi avascolare della testa del femore`]: `Avascular necrosis of the head of femur`,
  [`Necrosi avascolare della testa del femore`]: `Avascular necrosis femoral head`,
  [`Necrosi avascolare dello scafoide`]: `Avascular necrosis scaphoid`,
  [`Necrosi avascolare di sesamoide`]: `Avascular necrosis sesamoid Great Toe`,
  [`Neer's Sign (DX)`]: `Neer's Sign (R)`,
  [`Neer's Sign (SX)`]: `Neer's Sign (L)`,
  [`Negativo`]: `Negative`,
  [`Nel momento di peggior dolore?`]: `At its worst?`,
  [`Nella media`]: `Average`,
  [`Nelle ultime 4 settimane o dal giorno del suo infortunio, che livello di gonfiore o di rigidità ha provato nel ginocchio?`]: `During the past 4 weeks, or since your injury, how stiff or swollen was your knee?`,
  [`Nelle ultime 4 settimane o dal giorno del suo infortunio, con che frequenza ha accusato dolore?`]: `During the past 4 weeks, or since your injury, how often have you had pain?`,
  [`Nelle ultime 4 settimane o dal giorno del suo infortunio, le è capitato che il ginocchio si sia bloccato oppure che sia "scattato"?`]: `During the past 4 weeks, or since your injury, did you knee lock or catch?`,
  [`Nelle ultime 4 settimane, quanto spesso hai avuto la sensazione di non poterne piu' o ti sei sentito/a irritato/a a causa del mal di testa?`]: `In the past 4 weeks, how often have you felt fed up or irritated because of your headaches?`,
  [`Nelle ultime 4 settimane, quanto spesso il mal di testa ha limitato la tua capacita' di concentrarti sul lavoro o sulle attivita' quotidiane?`]: `In the past 4 weeks, how often did headaches limit your ability to concentrate on work or
daily activities?`,
  [`Nelle ultime 4 settimane, quanto spesso ti sei sentito/a troppo stanco/a per lavorare o per svolgere le tue attivita' quotidiane, a causa del mal di testa?`]: `In the past 4 weeks, how often have you felt too tired to do work or daily activities because
of your headaches?`,
  [`Nelle ultime quattro settimane, ha riscontrato I seguenti problemi sul lavoro o nelle altre attivita' quotidiane, a causa del suo stato emotivo (quale il sentirsi depresso o ansioso)? Risponda Si o No a ciascuna domanda`]: `During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?`,
  [`Nelle ultime quattro settimane, ha riscontrato I seguenti problemi sul lavoro o nelle altre attivita' quotidiane, a causa della sua salute fisica? Risponda si o no a ciascuna domanda`]: `During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?`,
  [`Nelle ultime quattro settimane, ha riscontrato I seguenti problemi sul lavoro o nelle altre attivita' quotidiane, a causa della sua salute fisica? Risponda si o no a ciascuna domanda.`]: `During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?`,
  [`Nelle ultime quattro settimane, in che misura il dolore l'ha ostacolata nel lavoro che svolge abitualmente, sia in casa che fuori?`]: `During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the
home and housework)?`,
  [`Nelle ultime quattro settimane, in che misura la sua salute fisica o il suo stato emotivo hanno interferito con le normali attivita' sociali con la famiglia, gli amici, I vicini di casa, I gruppi di cui fa parte?`]: `Emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?`,
  [`Nelle ultime quattro settimane, per quanto tempo la sua salute fisica o il suo stato emotivo hanno interferito nelle sue attivita' sociali, in famiglia, con gli amici?`]: `During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with
your social activities (like visiting with friends, relatives, etc.)?`,
  [`Neoformazione cutanea`]: `Skin Lesion/Tumour`,
  [`Neoformazione cutanea del torace`]: `Skin Lesion/Tumour thoracic`,
  [`Neoformazione cutanea della testa`]: `Skin Lesion/Tumour head`,
  [`Neoplasia`]: `Neoplasm`,
  [`NERVI ALVEOLARI INFERIORI DI DESTRA`]: `RIGHT INFERIOR ALVEOLAR NERVES`,
  [`NERVI ALVEOLARI INFERIORI DI SINISTRA`]: `LEFT INFERIOR ALVEOLAR NERVES`,
  [`NERVI ALVEOLARI SUPERIORI DI DESTRA`]: `RIGHT SUPERIOR ALVEOLAR NERVES`,
  [`NERVI ALVEOLARI SUPERIORI DI SINISTRA`]: `LEFT SUPERIOR ALVEOLAR NERVES`,
  [`NERVI ANALI INFERIORI DI DESTRA`]: `RIGHT INFERIOR ANAL NERVES`,
  [`NERVI ANALI INFERIORI DI SINISTRA`]: `LEFT INFERIOR ANAL NERVES`,
  [`NERVI CAROTIDEI ESTERNI DESTRO`]: `RIGHT EXTERNAL CAROTIC NERVES`,
  [`NERVI CAROTIDEI ESTERNI SINISTRO`]: `LEFT EXTERNAL CAROTIC NERVES`,
  [`NERVI CERVICALI [C1-C8]`]: `CERVICAL NERVES [C1-C8]`,
  [`NERVI CILIARI BREVI DI DESTRA`]: `RIGHT SHORT CILIARY NERVES`,
  [`NERVI CILIARI BREVI DI SINISTRA`]: `LEFT SHORT CILIARY NERVES`,
  [`NERVI DIGITALI DORSALI DEL PIEDE DESTRO`]: `DORSAL DIGITAL NERVES OF RIGHT FOOT`,
  [`NERVI DIGITALI DORSALI DEL PIEDE SINISTRO`]: `INTERMEDIATE DORSAL CUTANEOUS NERVE OF LEFT FOOT`,
  [`NERVI DIGITALI DORSALI DELLA MANO DESTRA`]: `DORSAL DIGITAL NERVES OF RIGHT HAND`,
  [`NERVI DIGITALI DORSALI DELLA MANO SINISTRA`]: `DORSAL DIGITAL NERVES OF LEFT HAND`,
  [`NERVI DIGITALI PALMARI COMUNI DI DESTRA`]: `RIGHT COMMON PALMAR DIGITAL NERVES`,
  [`NERVI DIGITALI PALMARI COMUNI DI SINISTRA`]: `LEFT COMMON PALMAR DIGITAL NERVES`,
  [`NERVI DIGITALI PALMARI PROPRI DI DESTRA`]: `RIGHT PROPER PALMAR DIGITAL NERVES`,
  [`NERVI DIGITALI PALMARI PROPRI DI SINISTRA`]: `LEFT PROPER PALMAR DIGITAL NERVES`,
  [`NERVI DIGITALI PLANTARI COMUNI DI DESTRA`]: `RIGHT COMMON PLANTAR DIGITAL NERVES`,
  [`NERVI DIGITALI PLANTARI COMUNI DI SINISTRA`]: `LEFT COMMON PLANTAR DIGITAL NERVES`,
  [`NERVI DIGITALI PLANTARI PROPRI DI DESTRA`]: `RIGHT PROPER PLANTAR DIGITAL NERVES`,
  [`NERVI DIGITALI PLANTARI PROPRI DI SINISTRA`]: `LEFT PROPER PLANTAR DIGITAL NERVES`,
  [`NERVI INTERCOSTALI DI DESTRA`]: `RIGHT INTERCOSTAL NERVES `,
  [`NERVI INTERCOSTALI DI SINISTRA`]: `LEFT INTERCOSTAL NERVES `,
  [`NERVI LABIALI ANTERIORI DI DESTRA (F)`]: `RIGHT ANTERIOR LABIAL NERVES (F)`,
  [`NERVI LABIALI ANTERIORI DI SINISTRA (F)`]: `LEFT ANTERIOR LABIAL NERVES (F)`,
  [`NERVI LABIALI POSTERIORI DI DESTRA (F)`]: `RIGHT POSTERIOR LABIAL NERVES (F)`,
  [`NERVI LABIALI POSTERIORI DI SINISTRA (F)`]: `LEFT POSTERIOR LABIAL NERVES (F)`,
  [`NERVI LOMBARI [L1-L5]`]: `LUMBAR NERVES [L1-L5]`,
  [`NERVI PALATINI MINORI DI DESTRA`]: `RIGHT LESSER PALATINE NERVES`,
  [`NERVI PALATINI MINORI DI SINISTRA`]: `LEFT LESSER PALATINE NERVES`,
  [`NERVI PERINEALI DI DESTRA`]: `RIGHT PERINEAL NERVES`,
  [`NERVI PERINEALI DI SINISTRA`]: `LEFT PERINEAL NERVES`,
  [`NERVI SACRALI E COCCIGEI [S1-S5, Co]`]: `SACRAL NERVES AND COCCYGEAL NERVE [S1-S5, Co]`,
  [`NERVI SCROTALI ANTERIORI DI DESTRA (M)`]: `RIGHT ANTERIOR SCROTAL NERVES (M)`,
  [`NERVI SCROTALI ANTERIORI DI SINISTRA (M)`]: `LEFT ANTERIOR SCROTAL NERVES (M)`,
  [`NERVI SCROTALI POSTERIORI DI DESTRA (M)`]: `RIGHT POSTERIOR SCROTAL NERVES (M)`,
  [`NERVI SCROTALI POSTERIORI DI SINISTRA (M)`]: `LEFT POSTERIOR SCROTAL NERVES (M)`,
  [`NERVI SOPRACLAVICOLARI DI DESTRA`]: `RIGHT SUPRACLAVICULAR NERVES`,
  [`NERVI SOPRACLAVICOLARI DI SINISTRA`]: `LEFT SUPRACLAVICUAR NERVES`,
  [`NERVI TEMPORALI PROFONDI DI DESTRA`]: `RIGHT DEEP TEMPORAL NERVES`,
  [`NERVI TEMPORALI PROFONDI DI SINISTRA`]: `LEFT DEEP TEMPORAL NERVES`,
  [`NERVI TORACICI [T1-T12]`]: `THORACIC NERVES [T1-T12]`,
  [`NERVI VAGINALI (F)`]: `VAGINAL NERVES (F)`,
  [`NERVO ABDUCENTE [VI] DESTRO`]: `RIGHT ABDUCENT NERVE [VI]`,
  [`NERVO ABDUCENTE [VI] SINISTRO`]: `LEFT ABDUCENT NERVE [VI]`,
  [`NERVO ACCESSORIO [XI] DESTRO`]: `RIGHT ACCESSORY NERVE [XI]`,
  [`NERVO ACCESSORIO [XI] SINISTRO`]: `LEFT ACCESSORY NERVE [XI]`,
  [`NERVO ANOCOCCIGEO DESTRO`]: `RIGHT ANOCOCCYGEAL NERVE`,
  [`NERVO ANOCOCCIGEO SINISTRO`]: `LEFT ANOCOCCYGEAL NERVE`,
  [`NERVO ASCELLARE DESTRO`]: `RIGHT AXILLARY NERVE`,
  [`NERVO ASCELLARE SINISTRO`]: `LEFT AXILLARY NERVE`,
  [`NERVO AURICOLARE POSTERIORE DESTRO`]: `RIGHT POSTERIOR AURICULAR NERVE`,
  [`NERVO AURICOLARE POSTERIORE SINISTRO`]: `LEFT POSTERIOR AURICULAR NERVE`,
  [`NERVO AURICOLOTEMPORALE DESTRO`]: `RIGHT AURICULOTEMPORAL NERVE`,
  [`NERVO AURICOLOTEMPORALE SINISTRO`]: `LEFT AURICULOTEMPORAL NERVE`,
  [`NERVO BUCCALE DESTRO`]: `RIGHT BUCCAL NERVE`,
  [`NERVO BUCCALE SINISTRO`]: `LEFT BUCCAL NERVE`,
  [`NERVO CARDIACO CERVICALE INFERIORE DESTRO`]: `RIGHT INFERIOR CERVICAL CARDIAC NERVE`,
  [`NERVO CARDIACO CERVICALE INFERIORE SINISTRO`]: `LEFT INFERIOR CERVICAL CARDIAC NERVE`,
  [`NERVO CARDIACO CERVICALE MEDIO DESTRO`]: `RIGHT MIDDLE CERVICAL CARDIAC NERVE`,
  [`NERVO CARDIACO CERVICALE MEDIO SINISTRO`]: `LEFT MIDDLE CERVICAL CARDIAC NERVE`,
  [`NERVO CARDIACO CERVICALE SUPERIORE DESTRO`]: `RIGHT SUPERIOR CERVICAL CARDIAC NERVE`,
  [`NERVO CARDIACO CERVICALE SUPERIORE SINISTRO`]: `LEFT SUPERIOR CERVICAL CARDIAC NERVE`,
  [`NERVO CAROTIDEO INTERNO DESTRO`]: `RIGHT INTERNAL CAROTIC NERVE`,
  [`NERVO CAROTIDEO INTERNO SINISTRO`]: `LEFT INTERNAL CAROTIC NERVE`,
  [`NERVO CAVERNOSO DEL CLITORIDE (F)`]: `CAVERNOUS NERVE OF CLITORIS (F)`,
  [`NERVO CAVERNOSO DEL PENE (M)`]: `CAVERNOUS NERVE OF PENIS (M)`,
  [`NERVO COCCIGEO DESTRO`]: `RIGHT COCCYGEAL NERVE`,
  [`NERVO COCCIGEO SINISTRO`]: `LEFT COCCYGEAL NERVE`,
  [`NERVO COCLEARE DESTRO`]: `RIGHT COCHLEAR NERVE`,
  [`NERVO COCLEARE SINISTRO`]: `LEFT COCHLEAR NERVE`,
  [`NERVO CUTANEO DORSALE LATERALE DEL PIEDE DESTRO`]: `RIGHT LATERAL DORSAL CUTANEOUS NERVE`,
  [`NERVO CUTANEO DORSALE LATERALE DEL PIEDE SINISTRO`]: `LEFT LATERAL DORSAL CUTANEOUS NERVE`,
  [`NERVO CUTANEO LATERALE DELL'AVAMBRACCIO DESTRO`]: `LATERAL CUTANEOUS NERVE OF RIGHT FOREARM`,
  [`NERVO CUTANEO LATERALE DELL'AVAMBRACCIO SINISTRO`]: `LATERAL CUTANEOUS NERVE OF LEFT FOREARM`,
  [`NERVO CUTANEO LATERALE DELLA COSCIA DESTRO`]: `RIGHT LATERAL CUTANEOUS NERVE OF THIGH`,
  [`NERVO CUTANEO LATERALE DELLA COSCIA SINISTRO`]: `LEFT LATERAL CUTANEOUS NERVE OF THIGH`,
  [`NERVO CUTANEO LATERALE DELLA GAMBA DESTRO`]: `LATERAL CUTANEOUS NERVE OF RIGHT LEG`,
  [`NERVO CUTANEO LATERALE DELLA GAMBA SINISTRO`]: `LATERAL CUTANEOUS NERVE OF LEFT LEG`,
  [`NERVO CUTANEO LATERALE INFERIORE DEL BRACCIO DESTRO`]: `INFERIOR LATERAL CUTANEOUS NERVE OF RIGHT ARM`,
  [`NERVO CUTANEO LATERALE INFERIORE DEL BRACCIO SINISTRO`]: `INFERIOR LATERAL CUTANEOUS NERVE OF LEFT ARM`,
  [`NERVO CUTANEO LATERALE SUPERIORE DEL BRACCIO DESTRO`]: `SUPERIOR CUTANEOUS NERVE OF RIGHT ARM`,
  [`NERVO CUTANEO LATERALE SUPERIORE DEL BRACCIO SINISTRO`]: `SUPERIOR CUTANEOUS NERVE OF LEFT ARM`,
  [`NERVO CUTANEO MEDIALE DEL BRACCIO DESTRO`]: `MEDIAL CUTANEOUS NERVE OF RIGHT ARM`,
  [`NERVO CUTANEO MEDIALE DEL BRACCIO SINISTRO`]: `MEDIAL CUTANEOUS NERVE OF LEFT ARM`,
  [`NERVO CUTANEO MEDIALE DELL'AVAMBRACCIO DESTRO`]: `CUTANEOUS NERVE OF RIGHT FOREARM`,
  [`NERVO CUTANEO MEDIALE DELL'AVAMBRACCIO SINISTRO`]: `CUTANEOUS NERVE OF LEFT FOREARM`,
  [`NERVO CUTANEO MEDIALE DELLA GAMBA DESTRO`]: `MEDIAL CUTANEOUS NERVE OF RIGHT LEG`,
  [`NERVO CUTANEO MEDIALE DELLA GAMBA SINISTRO`]: `MEDIAL CUTANEOUS NERVE OF LEFT LEG`,
  [`NERVO CUTANEO POSTERIORE DEL BRACCIO DESTRO`]: `POSTERIOR CUTANEOUS NERVE OF RIGHT ARM`,
  [`NERVO CUTANEO POSTERIORE DEL BRACCIO SINISTRO`]: `POSTERIOR CUTANEOUS NERVE OF LEFT ARM`,
  [`NERVO CUTANEO POSTERIORE DELL'AVAMBRACCIO DESTRO`]: `POSTERIOR CUTANEOUS NERVE OF RIGHT FOREARM`,
  [`NERVO CUTANEO POSTERIORE DELL'AVAMBRACCIO SINISTRO`]: `POSTERIOR CUTANEOUS NERVE OF LEFT FOREARM`,
  [`NERVO CUTANEO POSTERIORE DELLA COSCIA DESTRO`]: `RIGHT POSTERIOR CUTANEOUS NERVE OF THIGH`,
  [`NERVO CUTANEO POSTERIORE DELLA COSCIA SINISTRO`]: `LEFT POSTERIOR CUTANEOUS NERVE OF THIGH`,
  [`NERVO CUTANEO SURALE MEDIALE DESTRO`]: `RIGHT MEDIAL SURAL CUTANEOUS NERVE`,
  [`NERVO CUTANEO SURALE MEDIALE SINISTRO`]: `LEFT MEDIAL SURAL CUTANEOUS NERVE`,
  [`NERVO DEL CANALE PTERIGOIDEO DESTRO`]: `NERVE OF RIGHT PTERYGOID CANAL`,
  [`NERVO DEL CANALE PTERIGOIDEO SINISTRO`]: `NERVE OF LEFT PTERYGOID CANAL`,
  [`NERVO DORSALE DEL CLITORIDE (F) DESTRO`]: `RIGHT DORSAL NERVE OF CLITORIS (F)`,
  [`NERVO DORSALE DEL CLITORIDE (F) SINISTRO`]: `LEFT DORSAL NERVE OF CLITORIS (F)`,
  [`NERVO DORSALE DEL PENE (M) DESTRO`]: `RIGHT DORSAL NERVE OF PENIS (F)`,
  [`NERVO DORSALE DEL PENE (M) SINISTRO`]: `LEFT DORSAL NERVE OF PENIS (F)`,
  [`NERVO DORSALE DELLA SCAPOLA DESTRO`]: `RIGHT DORSAL SCAPULAR NERVE`,
  [`NERVO DORSALE DELLA SCAPOLA SINISTRO`]: `LEFT DORSAL SCAPULAR NERVE`,
  [`NERVO ETMOIDALE ANTERIORE DESTRO`]: `RIGHT ANTERIOR ETHMOIDAL NERVE`,
  [`NERVO ETMOIDALE ANTERIORE SINISTRO`]: `LEFT ANTERIOR ETHMOIDAL NERVE`,
  [`NERVO ETMOIDALE POSTERIORE DESTRO`]: `RIGHT POSTERIOR ETHMOIDAL NERVE`,
  [`NERVO ETMOIDALE POSTERIORE SINISTRO`]: `LEFT POSTERIOR ETHMOIDAL NERVE`,
  [`NERVO FACIALE [VII] DESTRO`]: `RIGHT FACIAL NERVE [VII]`,
  [`NERVO FACIALE [VII] SINISTRO`]: `LEFT FACIAL NERVE [VII]`,
  [`NERVO FARINGEO DESTRO`]: `RIGHT PHARYNGEAL NERVE`,
  [`NERVO FARINGEO SINISTRO`]: `LEFT PHARYNGEAL NERVE`,
  [`Nervo femorale (DX)`]: `Femoral nerve (R)`,
  [`Nervo femorale (SX)`]: `Femoral nerve (L)`,
  [`NERVO FEMORALE DESTRO`]: `RIGHT FEMORAL NERVE`,
  [`NERVO FEMORALE SINISTRO`]: `LEFT FEMORAL NERVE`,
  [`NERVO FRENICO DESTRO`]: `RIGHT PHRENIC NERVE`,
  [`NERVO FRENICO SINISTRO`]: `LEFT PHRENIC NERVE`,
  [`NERVO FRONTALE DESTRO`]: `RIGHT FRONTAL NERVE`,
  [`NERVO FRONTALE SINISTRO`]: `LEFT FRONTAL NERVE`,
  [`NERVO GENITOFEMORALE DESTRO`]: `RIGHT GENITOFEMORAL NERVE`,
  [`NERVO GENITOFEMORALE SINISTRO`]: `LEFT GENITOFEMORAL NERVE`,
  [`NERVO GIUGULARE DESTRO`]: `RIGHT JUGULAR NERVE`,
  [`NERVO GIUGULARE SINISTRO`]: `LEFT JUGULAR NERVE`,
  [`NERVO GLOSSOFARINGEO [IX] DESTRO`]: `RIGHT GLOSSOPHARYNGEAL NERVE [IX]`,
  [`NERVO GLOSSOFARINGEO [IX] SINISTRO`]: `LEFT GLOSSOPHARYNGEAL NERVE [IX]`,
  [`NERVO GLUTEO INFERIORE DESTRO`]: `RIGHT INFERIOR GLUTEAL NERVE`,
  [`NERVO GLUTEO INFERIORE SINISTRO`]: `LEFT INFERIOR GLUTEAL NERVE`,
  [`NERVO GLUTEO SUPERIORE DESTRO`]: `RIGHT SUPERIOR GLUTEAL NERVE`,
  [`NERVO GLUTEO SUPERIORE SINISTRO`]: `LEFT SUPERIOR GLUTEAL NERVE`,
  [`NERVO GRANDE AURICOLARE DESTRO`]: `RIGHT GREATER AURICULAR NERVE`,
  [`NERVO GRANDE AURICOLARE SINISTRO`]: `LEFT GREATER AURICULAR NERVE`,
  [`NERVO GRANDE OCCIPITALE DESTRO`]: `RIGHT GREATER OCCIPITAL NERVE`,
  [`NERVO GRANDE OCCIPITALE SINISTRO`]: `LEFT GREATER OCCIPITAL NERVE`,
  [`NERVO GRANDE PETROSO DESTRO`]: `RIGHT GREATER PETROSAL NERVE`,
  [`NERVO GRANDE PETROSO SINISTRO`]: `EFT GREATER PETROSAL NERVE`,
  [`NERVO ILEOINGUINALE DESTRO`]: `RIGHT ILEOINGUINAL NERVE`,
  [`NERVO ILEOINGUINALE SINISTRO`]: `LEFT ILEOINGUINAL NERVE`,
  [`NERVO ILEOIPOGASTRICO DESTRO`]: `RIGHT ILIOHYPOGASTRIC NERVE`,
  [`NERVO ILEOIPOGASTRICO SINISTRO`]: `LEFT ILIOHYPOGASTRIC NERVE`,
  [`NERVO INFRAORBITALE DESTRO`]: `RIGHT INFRAORBITAL NERVE`,
  [`NERVO INFRAORBITALE SINISTRO`]: `LEFT INFRAORBITAL NERVE`,
  [`NERVO INTERMEDIO CUTANEO DORSALE DEL PIEDE DESTRO`]: `INTERMEDIATE DORSAL CUTANEOUS NERVE OF RIGHT FOOT`,
  [`NERVO INTEROSSEO ANTERIORE DELL' AVAMBRACCIO SINISTRO`]: `LEFT ANTERIOR INTEROSSEOUS NERVE`,
  [`NERVO INTEROSSEO ANTERIORE DELL'AVAMBRACCIO DESTRO`]: `RIGHT ANTERIOR INTEROSSEOUS NERVE`,
  [`NERVO INTEROSSEO CRURALE DESTRO`]: `RIGHT CRURAL INTEROSSEOUS NERVE`,
  [`NERVO INTEROSSEO CRURALE SINISTRO`]: `LEFT CRURAL INTEROSSEOUS NERVE`,
  [`NERVO INTEROSSEO POSTERIORE DESTRO`]: `RIGHT POSTERIOR INTEROSSEOUS NERVE`,
  [`NERVO INTEROSSEO POSTERIORE SINISTRO`]: `LEFT POSTERIOR INTEROSSEOUS NERVE`,
  [`NERVO IPOGLOSSO [XII] DESTRO`]: `RIGHT HYPOGLOSSAL NERVE [XII]`,
  [`NERVO IPOGLOSSO [XII] SINISTRO`]: `LEFT HYPOGLOSSAL NERVE [XII]`,
  [`NERVO LACRIMALE DESTRO`]: `RIGHT LACRIMAL NERVE`,
  [`NERVO LACRIMALE SINISTRO`]: `LEFT LACRIMAL NERVE`,
  [`NERVO LARINGEO RICORRENTE DESTRO`]: `RIGHT RECURRENT LARYNGEAL NERVE`,
  [`NERVO LARINGEO RICORRENTE SINISTRO`]: `LEFT RECURRENT LARYNGEAL NERVE`,
  [`NERVO LARINGEO SUPERIORE DESTRO`]: `RIGHT SUPERIOR LARYNGEAL NERVE`,
  [`NERVO LARINGEO SUPERIORE SINISTRO`]: `LEFT SUPERIOR LARYNGEAL NERVE`,
  [`NERVO LINGUALE DESTRO`]: `RIGHT LINGUAL NERVE`,
  [`NERVO LINGUALE SINISTRO`]: `LEFT LINGUAL NERVE`,
  [`NERVO MANDIBOLARE [Vc] DESTRO`]: `RIGHT MANDIBULAR NERVE [Vc]`,
  [`NERVO MANDIBOLARE [Vc] SINISTRO`]: `LEFT MANDIBULAR NERVE [Vc]`,
  [`NERVO MASCELLARE [Vb] DESTRO`]: `RIGHT MAXILLARY NERVE [Vb]`,
  [`NERVO MASCELLARE [Vb] SINISTRO`]: `LEFT MAXILLARY NERVE [Vb]`,
  [`NERVO MASSETERICO DESTRO`]: `RIGHT MASSETRIC NERVE`,
  [`NERVO MASSETERICO SINISTRO`]: `LEFT MASSETERIC NERVE`,
  [`Nervo mediano (DX)`]: `Median nerve (R)`,
  [`Nervo mediano (SX)`]: `Median nerve (L)`,
  [`NERVO MEDIANO DESTRO`]: `RIGHT MEDIAN NERVE`,
  [`NERVO MEDIANO SINISTRO`]: `LEFT MEDIAN NERVE`,
  [`NERVO MENTALE DESTRO`]: `RIGHT MENTAL NERVE`,
  [`NERVO MENTALE SINISTRO`]: `LEFT MENTAL NERVE`,
  [`NERVO MUSCOLOCUTANEO DESTRO`]: `RIGHT MUSCULOCUTANEOUS NERVE`,
  [`NERVO MUSCOLOCUTANEO SINISTRO`]: `LEFT MUSCULOCUTANEOUS NERVE`,
  [`NERVO NASOCILIARE DESTRO`]: `RIGHT NASOCILIARY NERVE`,
  [`NERVO NASOCILIARE SINISTRO`]: `LEFT NASOCILIARY NERVE`,
  [`NERVO NASOPALATINO DESTRO`]: `RIGHT NASOPALATINE NERVE`,
  [`NERVO NASOPALATINO SINISTRO`]: `LEFT NASOPALATINE NERVE`,
  [`NERVO OCULOMOTORIO [III] DESTRO`]: `RIGHT OCULOMOTOR NERVE [III]`,
  [`NERVO OCULOMOTORIO [III] SINISTRO`]: `LEFT OCULOMOTOR NERVE [III]`,
  [`NERVO OFTALMICO [Va] DESTRO`]: `RIGHT OPHTALMIC NERVE [Va]`,
  [`NERVO OFTALMICO [Va] SINISTRO`]: `LEFT OPHTALMIC NERVE [Va]`,
  [`NERVO OLFATTIVO [I] DESTRO`]: `RIGHT OLFACTORY NERVE [I]`,
  [`NERVO OLFATTIVO [I] SINISTRO`]: `LEFT OLFACTORY NERVE [I]`,
  [`NERVO OTTICO [II] DESTRO`]: `RIGHT OPTIC NERVE [II]`,
  [`NERVO OTTICO [II] SINISTRO`]: `LEFT OPTIC NERVE [II]`,
  [`NERVO OTTURATORIO DESTRO`]: `RIGHT OBTURATOR NERVE`,
  [`NERVO OTTURATORIO SINISTRO`]: `LEFT OBTURATOR NERVE`,
  [`NERVO PALATINO MAGGIORE DESTRO`]: `RIGHT GREATER PALATINE NERVE`,
  [`NERVO PALATINO MAGGIORE SINISTRO`]: `LEFT GREATER PALATINE NERVE`,
  [`NERVO PER I MUSCOLI OTTURATORE INTERNO E GEMELLO SUPERIORE DI DESTRA`]: `NERVE TO RIGHT OBTURATOR INTERNUS AND GEMELLUS SUPERIOR MUSCLES`,
  [`NERVO PER I MUSCOLI OTTURATORE INTERNO E GEMELLO SUPERIORE DI SINISTRA`]: `NERVE TO LEFT OBTURATOR INTERNUS AND GEMELLUS SUPERIOR MUSCLES`,
  [`NERVO PER IL MUSCOLO ELEVATORE DELLA SCAPOLA DESTRO`]: `RIGHT NERVE TO LEVATOR SCAPULA MUSCLE`,
  [`NERVO PER IL MUSCOLO ELEVATORE DELLA SCAPOLA SINISTRO`]: `LEFT NERVE TO LEVATOR SCAPULA MUSCLE`,
  [`NERVO PER IL MUSCOLO PIRIFORME DESTRO`]: `NERVE TO RIGHT PIRIFORMIS MUSCLE`,
  [`NERVO PER IL MUSCOLO PIRIFORME SINISTRO`]: `NERVE TO LEFT PIRIFORMIS MUSCLE`,
  [`NERVO PER IL MUSCOLO PTERIGOIDEO LATERALE DESTRO`]: `NERVO TO RIGHT LATERAL PTERYGOID MUSCLE`,
  [`NERVO PER IL MUSCOLO PTERIGOIDEO LATERALE SINISTRO`]: `NERVO TO LEFT LATERAL PTERYGOID MUSCLE`,
  [`NERVO PER IL MUSCOLO PTERIGOIDEO MEDIALE DESTRO`]: `NERVO TO RIGHT MEDIAL PTERYGOID MUSCLE`,
  [`NERVO PER IL MUSCOLO PTERIGOIDEO MEDIALE SINISTRO`]: `NERVO TO LEFT MEDIAL PTERYGOID MUSCLE`,
  [`NERVO PER IL MUSCOLO QUADRATO DEL FEMORE E GEMELLO INFERIORE DI DESTRA`]: `NERVO TO RIGHT QUADRATUS FEMORIS AND GEMELLUS INFERIOR MUSCLES`,
  [`NERVO PER IL MUSCOLO QUADRATO DEL FEMORE E GEMELLO INFERIORE DI SINISTRA`]: `NERVO TO LEFT QUADRATUS FEMORIS AND GEMELLUS INFERIOR MUSCLES`,
  [`NERVO PER IL MUSCOLO STAPEDIO DESTRO`]: `NERVE TO RIGHT STAPEDIUS MUSCLE`,
  [`NERVO PER IL MUSCOLO STAPEDIO SINISTRO`]: `NERVE TO LEFT STAPEDIUS MUSCLE`,
  [`NERVO PER IL MUSCOLO SUCCLAVIO DESTRO`]: `NERVE TO RIGHT SUBCLAVIUS MUSCLE`,
  [`NERVO PER IL MUSCOLO SUCCLAVIO SINISTRO`]: `NERVE TO LEFT SUBCLAVIUS MUSCLE`,
  [`NERVO PER IL MUSCOLO TENSORE DEL TIMPANO DESTRO`]: `NERVE TO RIGHT TENSOR TYMPANI MUSCLE`,
  [`NERVO PER IL MUSCOLO TENSORE DEL TIMPANO SINISTRO`]: `NERVE TO LEFT TENSOR TYMPANI MUSCLE`,
  [`NERVO PER IL MUSCOLO TENSORE DEL VELO PALATINO DESTRO`]: `NERVE TO RIGHT TENSOR VELI PALATINI MUSCLE`,
  [`NERVO PER IL MUSCOLO TENSORE DEL VELO PALATINO SINISTRO`]: `NERVE TO LEFT TENSOR VELI PALATINI MUSCLE`,
  [`Nervo peroneo comune (DX)`]: `Common peroneus nerve (R)`,
  [`Nervo peroneo comune (SX)`]: `Common peroneus nerve (L)`,
  [`NERVO PERONEO COMUNE DESTRO`]: `RIGHT COMMON FIBULAR NERVE`,
  [`NERVO PERONEO COMUNE SINISTRO`]: `LEFT COMMON FIBULAR NERVE`,
  [`NERVO PERONEO PROFONDO DESTRO`]: `RIGHT DEEP FIBULAR NERVE`,
  [`NERVO PERONEO PROFONDO SINISTRO`]: `LEFT DEEP FIBULAR NERVE`,
  [`NERVO PERONEO SUPERFICIALE DESTRO`]: `RIGHT SUPERFICIAL FIBULAR NERVE`,
  [`NERVO PERONEO SUPERFICIALE SINISTRO`]: `LEFT SUPERFICIAL FIBULAR NERVE`,
  [`NERVO PETROSO MAGGIORE DESTRO`]: `RIGHT MAJOR PETROSAL NERVE`,
  [`NERVO PETROSO MAGGIORE SINISTRO`]: `LEFT MAJOR PETROSAL NERVE`,
  [`NERVO PETROSO MINORE DESTRO`]: `RIGHT MINOR PETROSAL NERVE`,
  [`NERVO PETROSO MINORE SINISTRO`]: `LEFT MINOR PETROSAL NERVE`,
  [`NERVO PETROSO PROFONDO DESTRO`]: `RIGHT DEEP PETROSAL NERVE`,
  [`NERVO PETROSO PROFONDO SINISTRO`]: `LEFT DEEP PETROSAL NERVE`,
  [`NERVO PETTORALE LATERALE DESTRO`]: `RIGHT LATERAL PECTORAL NERVE`,
  [`NERVO PETTORALE LATERALE SINISTRO`]: `LEFT LATERAL PECTORAL NERVE`,
  [`NERVO PETTORALE MEDIALE DESTRO`]: `RIGHT MEDIAL PECTORAL NERVE`,
  [`NERVO PETTORALE MEDIALE SINISTRO`]: `LEFT MEDIAL PECTORAL NERVE`,
  [`NERVO PICCOLO OCCIPITALE DESTRO`]: `RIGHT LESSER OCCIPITAL NERVE`,
  [`NERVO PICCOLO OCCIPITALE SINISTRO`]: `LEFT LESSER OCCIPITAL NERVE`,
  [`NERVO PICCOLO PETROSO DESTRO`]: `RIGHT LESSER PETROSAL NERVE`,
  [`NERVO PICCOLO PETROSO SINISTRO`]: `LEFT LESSER PETROSAL NERVE`,
  [`NERVO PLANTARE LATERALE DESTRO`]: `RIGHT LATERAL PLANTAR NERVE`,
  [`NERVO PLANTARE LATERALE SINISTRO`]: `LEFT LATERAL PLANTAR NERVE`,
  [`NERVO PLANTARE MEDIALE DESTRO`]: `RIGHT MEDIAL PLANTAR NERVE`,
  [`NERVO PLANTARE MEDIALE SINISTRO`]: `LEFT MEDIAL PLANTAR NERVE`,
  [`NERVO PUDENDO DESTRO`]: `RIGHT PUDENDAL NERVE`,
  [`NERVO PUDENDO SINISTRO`]: `LEFT PUDENDAL NERVE`,
  [`NERVO RADIALE DESTRO`]: `RIGHT RADIAL NERVE`,
  [`NERVO RADIALE SINISTRO`]: `LEFT RADIAL NERVE`,
  [`NERVO SAFENO DESTRO`]: `RIGHT SAPHENOUS NERVE`,
  [`NERVO SAFENO SINISTRO`]: `LEFT SAPHENOUS NERVE`,
  [`Nervo sciatico (DX)`]: `Sciatic nerve (R)`,
  [`Nervo sciatico (SX)`]: `Sciatic nerve (L)`,
  [`NERVO SCIATICO ALL'AREA GLUTEA DESTRA`]: `RIGHT SCIATIC NERVE (GLUTEAL AREA)`,
  [`NERVO SCIATICO ALL'AREA GLUTEA SINISTRA`]: `LEFT SCIATIC NERVE (GLUTEAL AREA)`,
  [`NERVO SCIATICO ALL'AREA POSTERIORE DELLA COSCIA DESTRA`]: `RIGHT SCIATIC NERVE (POSTERIOR AREA OF THIGH)`,
  [`NERVO SCIATICO ALL'AREA POSTERIORE DELLA COSCIA SINISTRA`]: `LEFT SCIATIC NERVE (POSTERIOR AREA OF THIGH)`,
  [`NERVO SICATICO DESTRO`]: `RIGHT SCIATIC NERVE`,
  [`NERVO SICATICO SINISTRO`]: `LEFT SCIATIC NERVE`,
  [`NERVO SOPRAORBITALE DESTRO`]: `RIGHT SUPRAORBITAL NERVE`,
  [`NERVO SOPRAORBITALE SINISTRO`]: `LEFT SUPRAORBITAL NERVE`,
  [`NERVO SOPRASCAPOLARE DESTRO`]: `RIGHT SUPRASCAPULAR NERVE`,
  [`NERVO SOPRASCAPOLARE SINISTRO`]: `LEFT SUPRASCAPULAR NERVE`,
  [`NERVO SOTTOSCAPOLARE INFERIORE DESTRO`]: `RIGHT INFERIOR SUBSCAPULAR NERVE`,
  [`NERVO SOTTOSCAPOLARE INFERIORE SINISTRO`]: `LEFT INFERIOR SUBSCAPULAR NERVE`,
  [`NERVO SOTTOSCAPOLARE SUPERIORE DESTRO`]: `RIGHT SUPERIOR SUBSCAPULAR NERVE`,
  [`NERVO SOTTOSCAPOLARE SUPERIORE SINISTRO`]: `LEFT SUPERIOR SUBSCAPULAR NERVE`,
  [`NERVO SUBOCCIPITALE DESTRO`]: `RIGHT SUBOCCIPITAL NERVE`,
  [`NERVO SUBOCCIPITALE SINISTRO`]: `LEFT SUBOCCIPITAL NERVE`,
  [`NERVO SURALE DESTRO`]: `RIGHT SURAL NERVE`,
  [`NERVO SURALE SINISTRO`]: `LEFT SURAL NERVE`,
  [`NERVO TERMINALE DESTRO [0]`]: `RIGHT TERMINAL NERVE [0]`,
  [`NERVO TERMINALE SINISTRO [0]`]: `LEFT TERMINAL NERVE [0]`,
  [`NERVO TIBIALE DESTRO`]: `RIGHT TIBIAL NERVE`,
  [`NERVO TIBIALE SINISTRO`]: `LEFT TIBIAL NERVE`,
  [`NERVO TIMPANICO DESTRO`]: `RIGHT TYMPANIC NERVE`,
  [`NERVO TIMPANICO SINISTRO`]: `LEFT TYMPANIC NERVE`,
  [`NERVO TORACICO LUNGO DESTRO`]: `RIGHT LONG THORACIC NERVE`,
  [`NERVO TORACICO LUNGO SINISTRO`]: `LEFT LONG THORACIC NERVE`,
  [`NERVO TORACODORSALE DESTRO`]: `RIGHT THORACODORSAL NERVE`,
  [`NERVO TORACODORSALE SINISTRO`]: `LEFT THORACODORSAL NERVE`,
  [`NERVO TRASVERSO DEL COLLO DESTRO`]: `RIGHT TRANSVERSE CERVICAL NERVE`,
  [`NERVO TRASVERSO DEL COLLO SINISTRO`]: `LEFT TRANSVERSE CERVICAL NERVE`,
  [`NERVO TRIGEMINO [V] DESTRO`]: `RIGHT TRIGEMINAL NERVE [V]`,
  [`NERVO TRIGEMINO [V] SINISTRO`]: `LEFT TRIGEMINAL NERVE [V]`,
  [`NERVO TROCLEARE [IV] DESTRO`]: `RIGHT TROCHLEAR NERVE [IV]`,
  [`NERVO TROCLEARE [IV] SINISTRO`]: `LEFT TROCHLEAR NERVE [IV]`,
  [`Nervo ulnare (DX)`]: `Ulnar nerve (R)`,
  [`Nervo ulnare (SX)`]: `Ulnar nerve (L)`,
  [`NERVO ULNARE DESTRO`]: `RIGHT ULNAR NERVE`,
  [`NERVO ULNARE SINISTRO`]: `LEFT ULNAR NERVE`,
  [`NERVO VAGO [X] DESTRO`]: `RIGHT VAGUS NERVE [X]`,
  [`NERVO VAGO [X] SINISTRO`]: `LEFT VAGUS NERVE [X]`,
  [`NERVO VESTIBOLARE DESTRO`]: `RIGHT VESTIBULAR NERVE`,
  [`NERVO VESTIBOLARE SINISTRO`]: `LEFT VESTIBULAR NERVE`,
  [`NERVO VESTIBOLOCOCLEARE [VIII] DESTRO`]: `RIGHT VESTIBULOCOCHLEAR NERVE [VIII]`,
  [`NERVO VESTIBOLOCOCLEARE [VIII] SINISTRO`]: `LEFT VESTIBULOCOCHLEAR NERVE [VIII]`,
  [`NERVO ZIGOMATICO DESTRO`]: `RIGHT ZYGOMATIC NERVE`,
  [`NERVO ZIGOMATICO SINISTRO`]: `LEFT ZYGOMATIC NERVE`,
  [`Nessun dolore`]: `Pain: None`,
  [`Nessuna`]: `None`,
  [`Nessuna difficoltà`]: `No difficulty`,
  [`Nessuno`]: `None`,
  [`Netball`]: `Netball`,
  [`neurite di Joplin`]: `Joplin neuritis`,
  [`Neurite plesso brachiale`]: `Brachial neuritis`,
  [`Neuro-chirurgia`]: `Neuro ssurgery`,
  [`Neurological Illness`]: `Neurological Illness`,
  [`Neurological lesion Location Unspecified or Crossing Anatomical Boundaries`]: `Neurological lesion  Location Unspecified or Crossing Anatomical Boundaries`,
  [`Neurologici`]: `Neurologic`,
  [`Neurologico`]: `Neurological`,
  [`neuroma di morton`]: `Morton neuroma`,
  [`Neuroma di Morton`]: `Morton's neuroma or Joplin's neuritis`,
  [`Neuromuscolari`]: `Neuromuscular`,
  [`neuropatia del pudendo`]: `pudendal neuropathy`,
  [`neuropatia dell'otturatore`]: `obturator neuropathy`,
  [`Neuropatico`]: `Neuropatic`,
  [`nevralgia del nervo grande occipitale`]: `Greater occipital nerve neuralgia`,
  [`nevralgia del trigemino`]: `trigeminal neuralgia`,
  [`Nicholas P, Hefford C, Tumilty S. The use of the Patient-Specific Functional Scale to measure rehabilitative progress in a physiotherapy setting. Journal of Manual & Manipulative Therapy. 2012 Aug 1;20(3):147-52.`]: `Nicholas P, Hefford C, Tumilty S. The use of the Patient-Specific Functional Scale to measure rehabilitative progress in a physiotherapy setting. Journal of Manual & Manipulative Therapy. 2012 Aug 1;20(3):147-52.`,
  [`no`]: `No `,
  [`No`]: `Never`,
  [`no il dolore e' costante`]: `No, the pain is constant`,
  [`No per niente`]: `Not at all`,
  [`no, e' localizzato alla regione del polpaccio`]: `no, it is localised on the calf area`,
  [`no, e' rimasto uguale`]: `No, it is not changed`,
  [`no, esordio insidioso non connesso con una specifica attivita'`]: `No, sudden onset not related to a specific activity`,
  [`no, esordio insidioso non connesso con unaspecifica attivita'`]: `No, onset not linked with a specific activity`,
  [`no, esordio non connesso con una specifica attivita'`]: `No, my symptoms are not linked with a specific activity`,
  [`no, il dolore e' ben localizzato`]: `no, the pain is well localized`,
  [`no, il dolore e' diffuso`]: `No, the pain is diffuse`,
  [`no, il dolore e' diffuso a tutta l'area glutea`]: `no but it is diffuse on the gluteal area`,
  [`no, il dolore e' diffuso a tutta la mano`]: `No, the pain is diffuse `,
  [`no, il dolore e' diffuso a tutta la regione del collo e non saprei localizzarlo`]: `No, my symptoms are diffuse on the whole neck and I'm not able to localise it`,
  [`no, il dolore e' diffuso a tutta la regione dell'anca`]: `No, the pain is diffuse on the hip`,
  [`no, il dolore e' diffuso al ginocchio e non saprei localizzarlo bene`]: `No, the pain is diffuse on the knee`,
  [`no, il dolore e' diffuso alla parte posteriore della coscia`]: `No, the pain is diffuse on the posterior thigh`,
  [`no, il dolore e' diffuso e non saprei localizzarlo`]: `No, my pain is diffuse and I'm not able to localise it`,
  [`no, il dolore e' diffuso e non saprei localizzarlo bene`]: `No, the pain is diffuse and I'm not able to well localise it`,
  [`no, il dolore e' diffuso ed e' difficile localizzarlo`]: `No, the pain is diffuse and it is difficult to localise`,
  [`no, il dolore e' diffuso su tutto l'avambraccio`]: `No, the pain is diffuse on the whole forearm`,
  [`no, il dolore e' diffuso sulla coscia`]: `no, the pain is diffuse on the thigh`,
  [`no, il dolore e' localizzato`]: `No, the pain doesn't radiate in other areas`,
  [`no, il dolore e' localizzato al ginocchio`]: `No, the pain is localised on the knee`,
  [`no, il dolore e' localizzato al tendine d'achille`]: `No, the pain is localised on the Achilles tendon`,
  [`no, il dolore e' localizzato all'area glutea`]: `no, the pain is localised on the gluteal area`,
  [`no, il dolore e' localizzato alla caviglia`]: `No, it is localised on the ankle`,
  [`no, il dolore e' localizzato alla parte posteriore del ginocchio`]: `No, the pain is localised on the posterior knee`,
  [`no, il dolore e' localizzato alla parte postriore della coscia`]: `No, the pain is localised on the posterior thigh`,
  [`no, il dolore e' localizzato alla/e costa/e`]: `No, the pain is localized on the rib`,
  [`no, il dolore e' localizzato e non si irradia`]: `no, it doesn't radiate`,
  [`no, il dolore e' localizzato solo sul gomito`]: `No, it is well localized on the elbow`,
  [`no, il dolore e' localizzato sulla spalla`]: `No, the pain is localized on the shoulder`,
  [`no, il dolore e' presente solo sul braccio`]: `No, I feel pain only on my arm`,
  [`no, il dolore e' presente solo sull'avambraccio`]: `No, the pain is localised on the forearm`,
  [`no, il dolore e' presente solo sulla coscia`]: `no, the pain is only on the thigh`,
  [`no, il dolore e' unilaterale ma localizzato al collo`]: `No, the pain is localised unilaterally on the neck`,
  [`no, il dolore e' vago e diffuso`]: `No, the pain is diffuse`,
  [`no, il dolore non e' connesso con movimenti o attivita' specifiche`]: `No, the pain is not related with specific movements or activities`,
  [`no, il dolore non e' relazionato a qualche movimento o attivita' specifica`]: `No, the pain is not related with specific movements or activities`,
  [`no, il dolore non si irradia in altre regioni`]: `No, the pain doesn't radiate in other areas`,
  [`no, il problema e' generale ma localizzato al collo`]: `No, the pain is interesting the whole neck`,
  [`no, l'esordio del dolore non e' connesso con una specifica attivita'`]: `no, the onset of the pain is not linked with a specific activity`,
  [`no, l'esordio non e' connesso con una specifica attivita'`]: `no, the onset of the pain is not linked with an obvious cause`,
  [`no, non ho dolore in nessuna di esse`]: `No, I don't feel pain in any of the previous movements`,
  [`No, non mi limita per nulla`]: `No, not limited at all`,
  [`no, non posso riprodurlo con dei movimenti, inoltre il dolore e' presente pressoche' in tutte le attivita' e I movimenti`]: `No, I can't reproduce the pain and I feel pain at every movement or activity`,
  [`no, non posso riprodurlo con specifici movimenti; il dolore e' presente in qualsiasi movimento io faccia`]: `No, I'm not able to reproduce my pain with specific movement or activities; I feel pain in any movement I do`,
  [`no, non posso riprodurre il dolore con dei movimenti delle attivita'`]: `no, I'm not able to reproduce the pain with a specific movement`,
  [`no, non riesco a riprodurlo`]: `No, I'm not able to reproduce the pain`,
  [`no, non riesco a riprodurre il dolore`]: `No, I'm not able to reproduce the pain`,
  [`no, non riesco a riprodurre il dolore con dei movimenti`]: `No, I'm not able to reproduce the pain with any movement`,
  [`no, non riesco a trovare un motivo per il mio dolore`]: `no, I'm not able to find an obvious cause for my pain`,
  [`no, non riesco a trovare una causa per il mio dolore`]: `no, I'm not able to find an obvious cause for my pain`,
  [`no, non riesco a trovare una causa per il mio dolore al gomito`]: `no, I'm not able to find an obvious cause for my pain`,
  [`no, non riesco a trovare una spiegazione al mio dolore`]: `No, I can't find an obvious cause to my pain`,
  [`no, non saprei descrivere il meccanismo traumatico`]: `No, I can't describe the injury mechanism`,
  [`no, non saprei descriverla`]: `no, I'm not able to describe it`,
  [`no, non saprei descriverlo`]: `no, I'm not able to describe it`,
  [`no, non saprei localizzare esattamente il dolore`]: `No, I can't localise the pain`,
  [`no, non saprei riprodurre il mio dolore`]: `No, I'm not able to reproduce my pain with specific movement or activities; the pain is continous`,
  [`no, non saprei riprodurre il mio dolore anche perche' e' presente in qualsiasi movimento faccia`]: `No, I'm not able to reproduce the pain; it is constant`,
  [`no, non saprei riprodurre il mio dolore con specifici movimenti o attivita', inoltre il dolore e' presente qualunque cosa faccia`]: `No, I'm not able to reproduce my pain with specific movement or activities; the pain is continous`,
  [`no, non so riprodurre il mio dolore`]: `No, I'm not able to reproduce the pain`,
  [`no, non sono in grado di riprodurre il dolore`]: `no, I'm not able to reproduce the pain`,
  [`no, non sono in grado di riprodurre il dolore con dei movimenti o delle attivita' specifiche; il dolore e' presente qualunque cosa faccia`]: `no, I'm not able to reproduce the pain but it is present whatever I do`,
  [`no, non sono in grado di riprodurre il dolore, il dolore e' praticamente sempre presente`]: `No, I'm not able to reproduce the pain; it is constant`,
  [`no, non svolgo alcun tipo di attivita'`]: `No, I don't practise any activity`,
  [`No, per niente`]: `Not at all`,
  [`No, per nulla`]: `Not at all`,
  [`no, solo in specifici movimenti`]: `no, I feel pain only during specific movement`,
  [`Noioso`]: `Annoying`,
  [`Non applicabile (N/A)`]: `Not Apllicable (N/A)`,
  [`non c'e' stata una chiara causa di esordio`]: `There wasn't an obvious onset cause`,
  [`Non ci sono riuscito`]: `Unable`,
  [`Non esclude la presenza di una Adductor Related Groin Pain`]: `t doesn't rule out adductor related groin pain`,
  [`Non esclude la presenza di una retrazione della IT Band`]: `Help to rule out IT band flexibility restriction`,
  [`Non faccio alcuna attività con gli altri a causa del dolore`]: `I have no social life because of pain`,
  [`Non ho modificato la mia attività lavorativa da quando sono incominciati i sintomi`]: `I have not modified my work activity since symptoms began`,
  [`Non ho problemi per dormire`]: `I have no trouble sleeping`,
  [`non ho svolto un'attivita' eccessiva`]: `I didn't perform any activity`,
  [`Non mi è possibile utilizzare il braccio`]: `Function: Unable to use limb`,
  [`Non mi ha limitato per nulla`]: `Not limited at all`,
  [`Non mi vesto da solo/a, mi lavo con difficolta' e sto a letto`]: `I do not get dressed, I wash with difficulty, and stay in bed`,
  [`Non mi vesto, mi lavo con difficolta' e sto a letto`]: `I do not get dressed, I wash with difficulty, and stay in bed`,
  [`Non movimento`]: `no movement`,
  [`Non ne consumo`]: `I don't drink coffee`,
  [`Non ne faccio uso`]: `I don't use it`,
  [`Non normale affatto`]: `Severely abnormal`,
  [`Non proprio normale`]: `Abnormal`,
  [`Non provo mal di testa per nulla`]: `I have no headaches at all`,
  [`Non riesco a concentrarmi del tutto`]: `I cannot concentrate at all`,
  [`Non riesco a guidare la macchina fin quando voglio a causa di un dolore al collo di media intensita'`]: `I cannot drive my car as long as I want because of moderate pain in my neck`,
  [`Non riesco a leggere del tutto`]: `I cannot read at all`,
  [`Non riesco a leggere quanto voglio a causa di un dolore al collo di media intensita'`]: `I can't read as much as I want to because of moderate pain in my neck`,
  [`Non riesco a leggere quanto voglio a causa di un dolore al collo molto forte`]: `I can't read as much as I want to because of severe pain in my neck`,
  [`Non riesco a sollevare o trasportare assolutamente niente`]: `I cannot lift or carry anything`,
  [`Non riesco a svolgere il mio lavoro abituale`]: `I cannot do my usual work`,
  [`Non riesco piu' a dedicarmi ad alcun passatempo`]: `I can't do any recreation activities at all`,
  [`Non riesco piu' a guidare la macchina a causa del dolore cervicale`]: `I cannot drive my car at all`,
  [`Non riesco piu' a svolgere alcun lavoro`]: `I can't do any work at all`,
  [`Non saprei`]: `Neither agree nor disagree`,
  [`non saprei descriverlo`]: `I can't describe it`,
  [`non so`]: `I don't know`,
  [`Non so`]: `Don't know`,
  [`Non sono assolutamente d'accordo`]: `Strongly disagree`,
  [`Non sono d'accordo`]: `Disagree`,
  [`Non sono in grado di farlo`]: `Unable to do `,
  [`Non sono riuscito a dormire`]: `So much I can't sleep`,
  [`Non specifico`]: `Unknown or not specified`,
  [`Non valutabile`]: `NA `,
  [`Non-specific`]: `Non-specific`,
  [`Non-specifico`]: `Non-specific`,
  [`Non-specifico`]: `Non-specific`,
  [`Nordic Hamstring Exercise Peak Force (DX)`]: `Nordic Hamstring Exercise Peak Force (R)`,
  [`Nordic Hamstring Exercise Peak Force (SX)`]: `Nordic Hamstring Exercise Peak Force (L)`,
  [`Normale`]: `Normal`,
  [`Normoreattivo`]: `Normoreactive`,
  [`Normoreattivo (5)`]: `Normoreactive`,
  [`Not specifico`]: `Unknown or not specified`,
  [`Notevole difficoltà`]: `Severe difficulty`,
  [`Numero accelerazioni`]: `Number of accelerations`,
  [`Numero decelerazioni`]: `Number of deceleration`,
  [`Numero di sprint`]: `Number of sprints`,
  [`Numero RPE: 10 - (55% sforzo)`]: `10- (55% effort)`,
  [`Numero RPE: 11 - leggero (60% sforzo)`]: `11- Light (60% effort)`,
  [`Numero RPE: 12 - moderato (65% sforzo)`]: `12- (65% effort)`,
  [`Numero RPE: 13 - abbastanza duro (70% sforzo)`]: `13- Somewhat hard (70% effort)`,
  [`Numero RPE: 14 - (75% sforzo)`]: `14- (75% effort)`,
  [`Numero RPE: 15 - duro (80% sforzo)`]: `15- Hard (80% effort)`,
  [`Numero RPE: 16 - (85% sforzo)`]: `16- (85% effort)`,
  [`Numero RPE: 17 - molto duro (90% sforzo)`]: `17- Very hard (90% effort)`,
  [`Numero RPE: 18 - (95% sforzo)`]: `18- (95% effort)`,
  [`Numero RPE: 19 - estremamente duro (100% sforzo)`]: `19- Extremely hard (100% effort)`,
  [`Numero RPE: 20 - sforzo massimale (esaurimento)`]: `20- Maximal exertion (exaustion)`,
  [`Numero RPE: 6 - nessuno sforzo (20% sforzo)`]: `6- no exertion at all (20% effort)`,
  [`Numero RPE: 7 - estermamente leggero (30% sforzo)`]: `7- extremely light (30% effort)`,
  [`Numero RPE: 8 - (40% sforzo)`]: `8- (40% effort)`,
  [`Numero RPE: 9 - molto leggero (50% sforzo)`]: `9- Very light (50% effort)`,
  [`Nuoto`]: `Swimming`,
  [`Ober Test (DX)`]: `Ober Test (R)`,
  [`Ober Test (SX)`]: `Ober Test (L)`,
  [`Obesita'`]: `Obesity`,
  [`Occhiali o lenti a contatto`]: `Eyeglasses or contact lenses`,
  [`OCCHIO (IRIDE DESTRA)`]: `RIGHT EYE (IRIDIS)`,
  [`OCCHIO (IRIDE SINISTRA)`]: `LEFT EYE (IRIDIS)`,
  [`OCCHIO (RETINA DESTRA)`]: `RIGHT EYE (RETINA)`,
  [`OCCHIO (RETINA SINISTRA)`]: `LEFT EYE (RETINA)`,
  [`OCCHIO DESTRO`]: `RIGHT EYE`,
  [`OCCHIO SINISTRO`]: `LEFT EYE`,
  [`OCCIPUT`]: `OCCIPUT`,
  [`OCP Advice`]: `OCP Advice`,
  [`Oculari`]: `Oftalmic`,
  [`ODI (LOMBOSACRALE)`]: `ODI (OSWESTRY DISABILITY INDEX)`,
  [`Oftalmologico`]: `Ophtalmological`,
  [`oggi`]: `Today`,
  [`Ogni giorno`]: `Daily`,
  [`Olecranon-Manubrium Percussion test (DX)`]: `Olecranon-Manubrium Percussion Test (R)`,
  [`Olecranon-Manubrium Percussion test (SX)`]: `Olecranon-Manubrium Percussion Test (L)`,
  [`oltre due settimane fa`]: `More than 2 weeks ago`,
  [`Omero (DX)`]: `Humerus (R)`,
  [`Omero (SX)`]: `Humerus (L)`,
  [`Omero (testa dell'omero) (DX)`]: `Humerus (head of humerus) (R)`,
  [`Omero (testa dell'omero) (SX)`]: `Humerus (head of humerus) (L)`,
  [`OMERO DESTRO`]: `RIGHT HUMERUS`,
  [`OMERO DESTRO (COLLO ANATOMICO)`]: `RIGHT HUMERUS (ANATOMICAL NECK)`,
  [`OMERO DESTRO (COLLO CHIRURGICO)`]: `RIGHT HUMERUS (SURGICAL NECK)`,
  [`OMERO DESTRO (DIAFISI)`]: `RIGHT HUMERUS (SHAFT)`,
  [`OMERO DESTRO (EPICONDILO)`]: `RIGHT HUMERUS (EPICONDYLE)`,
  [`OMERO DESTRO (EPITROCLEA)`]: `RIGHT HUMERUS (EPITROCHLEA)`,
  [`OMERO DESTRO (TESTA DELL'OMERO)`]: `RIGHT HUMERUS (HEAD OF HUMERUS)`,
  [`OMERO DESTRO (TROCLEA)`]: `RIGHT HUMERUS (TROCHLEA)`,
  [`OMERO DESTRO (TUBERCOLO MAGGIORE)`]: `RIGHT HUMERUS (GREATER TUBERCLE)`,
  [`OMERO DESTRO (TUBERCOLO MINORE)`]: `RIGHT HUMERUS (LESSER TUBERCLE)`,
  [`OMERO SINISTRO`]: `LEFT HUMERUS`,
  [`OMERO SINISTRO (COLLO ANATOMICO)`]: `LEFT HUMERUS (ANATOMICAL NECK)`,
  [`OMERO SINISTRO (COLLO CHIRURGICO)`]: `LEFT HUMERUS (SURGICAL NECK)`,
  [`OMERO SINISTRO (DIAFISI)`]: `LEFT HUMERUS (SHAFT)`,
  [`OMERO SINISTRO (EPICONDILO)`]: `LEFT HUMERUS (EPICONDYLE)`,
  [`OMERO SINISTRO (EPITROCLEA)`]: `LEFT HUMERUS (EPITROCHLEA)`,
  [`OMERO SINISTRO (TESTA DELL'OMERO)`]: `LEFT HUMERUS (HEAD OF HUMERUS)`,
  [`OMERO SINISTRO (TROCLEA)`]: `LEFT HUMERUS (TROCHLEA)`,
  [`OMERO SINISTRO (TUBERCOLO MAGGIORE)`]: `LEFT HUMERUS (GREATER TUBERCLE)`,
  [`OMERO SINISTRO (TUBERCOLO MINORE)`]: `LEFT HUMERUS (LESSER TUBERCLE)`,
  [`OPISTION`]: `OPISTION`,
  [`Opposizione pollice (DX)`]: `Thumb opposition (R)`,
  [`Opposizione pollice (SX)`]: `Thumb opposition (L)`,
  [`ORBITA DESTRA`]: `RIGHT ORBIT`,
  [`ORBITA SINISTRA`]: `LEFT ORBIT`,
  [`Orecchio a cavolfiore (cronico)`]: `Cauliflower Ear (chronic)`,
  [`Orecchio del nuotatore`]: `Swimmer's ear`,
  [`ORECCHIO DESTRO`]: `RIGHT EAR`,
  [`ORECCHIO SINISTRO`]: `LEFT EAR`,
  [`ORGANI GENITALI FEMMINILI ESTERNI (F)`]: `FEMALE EXTERNAL GENITALIA (F)`,
  [`ORGANI GENITALI FEMMINILI INTERNI (F)`]: `FEMALE INTERNAL GENITALIA (F)`,
  [`ORGANI GENITALI MASCHILI ESTERNI (M)`]: `MALE EXTERNAL GENITALIA (M)`,
  [`ORGANI GENITALI MASCHILI INTERNI (M)`]: `MALE INTERNAL GENITALIA (M)`,
  [`Organi interni`]: `Internal organs`,
  [`Organo interno`]: `Internal Organ`,
  [`ORGANO OLFATTIVO`]: `OLFACTORY ORGAN`,
  [`ORGANO VESTIBOLOCOCLEARE DESTRO`]: `RIGHT VESTIBULARCOCHLEAR ORGAN`,
  [`ORGANO VESTIBOLOCOCLEARE SINISTRO`]: `LEFT VESTIBULARCOCHLEAR ORGAN`,
  [`Origine`]: `Origin`,
  [`OROFARINGE`]: `OROPHARYNX`,
  [`Orribile`]: `Horrible`,
  [`Orticaria`]: `Urticaria`,
  [`Orzaiolo`]: `Sty (eyelash)`,
  [`Osgood-Schlatter`]: `Osgood-Schlatter syndrome`,
  [`Ossa`]: `Bones`,
  [`OSSA DEL CARPO DI DESTRA`]: `RIGHT CARPAL BONES`,
  [`OSSA DEL CARPO DI SINISTRA`]: `LEFT CARPAL BONES`,
  [`OSSA DEL TARSO DI DESTRA`]: `RIGHT TARSAL BONES`,
  [`OSSA DEL TARSO DI SINISTRA`]: `LEFT TARSAL BONES`,
  [`OSSA METACARPALI DI DESTRA`]: `RIGHT METACARPAL BONES`,
  [`OSSA METACARPALI DI SINISTRA`]: `LEFT METACARPAL BONES`,
  [`OSSA METATARSALI DI DESTRA`]: `RIGHT METATARSAL BONES`,
  [`OSSA METATARSALI DI SINISTRA`]: `RIGHT METATARSAL BONES`,
  [`Osseo`]: `Bony`,
  [`Osservazione allineamenti`]: `Alignement observation`,
  [`Osservazione di base`]: `Observation`,
  [`Osso`]: `Bone`,
  [`Osso accessorio al piede`]: `Accessory bone foot`,
  [`Osso frontale`]: `Frontal bone`,
  [`OSSO FRONTALE`]: `FRONTAL BONE`,
  [`OSSO FRONTALE (EMINENZA FRONTALE DESTRA)`]: `FRONTAL BONE (RIGHT FRONTAL EMINENCE)`,
  [`OSSO FRONTALE (EMINENZA FRONTALE SINISTRA)`]: `FRONTAL BONE (LEFT FRONTAL EMINENCE)`,
  [`OSSO FRONTALE (SENO FRONTALE DESTRO)`]: `FRONTAL BONE (RIGHT FRONTAL SINUS)`,
  [`OSSO FRONTALE (SENO FRONTALE SINISTRO)`]: `FRONTAL BONE (LEFT FRONTAL SINUS)`,
  [`OSSO FRONTALE (SETTO DEI SENI FRONTALI)`]: `FRONTAL BONE (SEPTUM OF FRONTAL SINUSES)`,
  [`Osso ioide`]: `Hyoid bone`,
  [`OSSO IOIDE`]: `HYOID BONE`,
  [`Osso lacrimale (DX)`]: `Lacrimal bone (R) `,
  [`Osso lacrimale (SX)`]: `Lacrimal bone (L)`,
  [`OSSO LACRIMALE DESTRO`]: `RIGHT LACRIMAL BONE`,
  [`OSSO LACRIMALE SINISTRO`]: `LEFT LACRIMAL BONE`,
  [`Osso mascellare (DX)`]: `Maxillary bone (R)`,
  [`OSSO MASCELLARE (SENO MASCELLARE DESTRO)`]: `RIGHT MAXILLA (MAXILLARY SINUS)`,
  [`OSSO MASCELLARE (SENO MASCELLARE SINISTRO)`]: `LEFT MAXILLA (MAXILLARY SINUS)`,
  [`Osso mascellare (SX)`]: `Maxillary bone (L)`,
  [`OSSO MASCELLARE DESTRO`]: `RIGHT MAXILLA`,
  [`OSSO MASCELLARE DESTRO (PROCESSO ALVEOLARE)`]: `RIGHT MAXILLA (ALVEOLAR PROCESS)`,
  [`OSSO MASCELLARE DESTRO (PROCESSO FRONTALE)`]: `RIGHT MAXILLA (FRONTAL PROCESS)`,
  [`OSSO MASCELLARE DESTRO (PROCESSO PALATINO)`]: `RIGHT MAXILLA (PALATINE PROCESS)`,
  [`OSSO MASCELLARE DESTRO (SENI MASCELLARI)`]: `RIGHT MAXILLA (MAXILLARY SINUS)`,
  [`OSSO MASCELLARE SINISTRO`]: `LEFT MAXILLA`,
  [`OSSO MASCELLARE SINISTRO (PROCESSO ALVEOLARE)`]: `LEFT MAXILLA (ALVEOLAR PROCESS)`,
  [`OSSO MASCELLARE SINISTRO (PROCESSO FRONTALE)`]: `LEFT MAXILLA (FRONTAL PROCESS)`,
  [`OSSO MASCELLARE SINISTRO (PROCESSO PALATINO)`]: `LEFT MAXILLA (PALATINE PROCESS)`,
  [`OSSO MASCELLARE SINISTRO (SENI MASCELLARI)`]: `LEFT MAXILLA (MAXILLARY SINUS)`,
  [`Osso nasale (DX)`]: `Nasal bone (R)`,
  [`Osso nasale (SX)`]: `Nasal bone (L)`,
  [`OSSO NASALE DESTRO`]: `RIGHT NASAL BONE`,
  [`OSSO NASALE SINISTRO`]: `LEFT NASAL BONE`,
  [`Osso occipitale`]: `Occipital bone`,
  [`OSSO OCCIPITALE`]: `OCCIPITAL BONE`,
  [`OSSO OCCIPITALE (PARTE BASILARE)`]: `OCCIPITAL BONE (BASILAR PART)`,
  [`OSSO OCCIPITALE (PARTE SQUAMOSA A DESTRA)`]: `OCCIPITAL BONE (RIGHT SQUAMOUS PART)`,
  [`OSSO OCCIPITALE (PARTE SQUAMOSA A SINISTRA)`]: `OCCIPITAL BONE (LEFT SQUAMOUS PART)`,
  [`OSSO OCCIPITALE (PROCESSO GIUGULARE DESTRO)`]: `OCCIPITAL BONE (RIGHT JUGULAR PROCESS)`,
  [`OSSO OCCIPITALE (PROCESSO GIUGULARE SINISTRO)`]: `OCCIPITAL BONE (LEFT JUGULAR PROCESS)`,
  [`Osso palatino (DX)`]: `Palatine bone (R)`,
  [`Osso palatino (SX)`]: `Palatine bone (L)`,
  [`OSSO PALATINO DESTRO`]: `RIGHT PALATINE BONE`,
  [`OSSO PALATINO DESTRO (LAMINA ORIZZONTALE)`]: `RIGHT PALATINE BONE (HORIZONTAL PLATE)`,
  [`OSSO PALATINO DESTRO (LAMINA PERPENDICOLARE)`]: `RIGHT PALATINE BONE (PERPENDICULAR PLATE)`,
  [`OSSO PALATINO SINISTRO`]: `LEFT PALATINE BONE`,
  [`OSSO PALATINO SINISTRO (LAMINA ORIZZONTALE)`]: `LEFT PALATINE BONE (HORIZONTAL PLATE)`,
  [`OSSO PALATINO SINISTRO (LAMINA PERPENDICOLARE)`]: `LEFT PALATINE BONE (PERPENDICULAR PLATE)`,
  [`Osso parietale (DX)`]: `Parietal bone (R)`,
  [`Osso parietale (SX)`]: `Parietal bone (L)`,
  [`OSSO PARIETALE DESTRO`]: `RIGHT PARIETAL BONE`,
  [`OSSO PARIETALE DESTRO (EMINENZA PARIETALE)`]: `RIGHT PARIETAL BONE BONE (FRONTAL EMINENCE)`,
  [`OSSO PARIETALE SINISTRO`]: `LEFT PARIETAL BONE`,
  [`OSSO PARIETALE SINISTRO (EMINENZA PARIETALE)`]: `LEFT PARIETAL BONE BONE (FRONTAL EMINENCE)`,
  [`Osso sacro`]: `Sacrum bone`,
  [`OSSO SACRO`]: `SACRUM`,
  [`OSSO SACRO (ALA SACRALE DESTRA)`]: `SACRUM (RIGHT WING)`,
  [`OSSO SACRO (ALA SACRALE SINISTRA)`]: `SACRUM (LEFT WING)`,
  [`OSSO SACRO (APICE)`]: `SACRUM (APEX)`,
  [`OSSO SACRO (BASE)`]: `SACRUM (BASE)`,
  [`OSSO SACRO (PROMONTORIO)`]: `SACRUM (PROMONTORY)`,
  [`Osso scafoide (navicolare) accessorio`]: `Accessory navicular bone`,
  [`Osso sesamoide peroneale (DX)`]: `Fibular sesamoid bone (R)`,
  [`Osso sesamoide peroneale (SX)`]: `Fibular sesamoid bone (L)`,
  [`Osso sesamoide tibiale (DX)`]: `Tibial sesamoid bone (R)`,
  [`Osso sesamoide tibiale (SX)`]: `Tibial sesamoid bone (L)`,
  [`OSSO SESAMOIDEO PERONEALE DESTRO`]: `RIGHT PERONEAL SESAMOID BONE`,
  [`OSSO SESAMOIDEO PERONEALE SINISTRO`]: `LEFT PERONEAL SESAMOID BONE`,
  [`OSSO SESAMOIDEO RADIALE DESTRO`]: `RIGHT RADIAL SESAMOID BONE`,
  [`OSSO SESAMOIDEO RADIALE SINISTRO`]: `LEFT RADIAL SESAMOID BONE`,
  [`OSSO SESAMOIDEO TIBIALE DESTRO`]: `RIGHT TIBIAL SESAMOID BONE`,
  [`OSSO SESAMOIDEO TIBIALE SINISTRO`]: `LEFT TIBIAL SESAMOID BONE`,
  [`OSSO SESAMOIDEO ULNARE DESTRO`]: `RIGHT ULNAR SESAMOID BONE`,
  [`OSSO SESAMOIDEO ULNARE SINISTRO`]: `LEFT ULNAR SESAMOID BONE`,
  [`Osso temporale (DX)`]: `Temporal bone (R)`,
  [`Osso temporale (SX)`]: `Temporal bone (L)`,
  [`OSSO TEMPORALE DESTRO`]: `RIGHT TEMPORAL BONE`,
  [`OSSO TEMPORALE DESTRO (MEATO ACUSTICO INTERNO)`]: `LEFT TEMPORAL BONE (EXTERNAL ACOUSTIC MEATUS)`,
  [`OSSO TEMPORALE DESTRO (MEATO ACUTSICO ESTERNO)`]: `RIGHT TEMPORAL BONE (INTERNAL ACOUSTIC MEATUS)`,
  [`OSSO TEMPORALE DESTRO (PARTE PETROSA)`]: `RIGHT TEMPORAL BONE (PETROUS PART)`,
  [`OSSO TEMPORALE DESTRO (PARTE SQUAMOSA)`]: `RIGHT TEMPORAL BONE (SQUAMOUS PART)`,
  [`OSSO TEMPORALE DESTRO (PARTE TIMPANICA)`]: `RIGHT TEMPORAL BONE (TYMPANIC PART)`,
  [`OSSO TEMPORALE DESTRO (PROCESSO MASTOIDEO)`]: `RIGHT TEMPORAL BONE (MASTOID PROCESS)`,
  [`OSSO TEMPORALE DESTRO (PROCESSO STILOIDEO)`]: `RIGHT TEMPORAL BONE (STYLOID PROCESS)`,
  [`OSSO TEMPORALE DESTRO (PROCESSO ZIGOMATICO)`]: `RIGHT TEMPORAL BONE (ZYGOMATIC PROCESS)`,
  [`OSSO TEMPORALE SINISTRO`]: `LEFT TEMPORAL BONE`,
  [`OSSO TEMPORALE SINISTRO (MEATO ACUTSICO ESTERNO)`]: `LEFT TEMPORAL BONE (INTERNAL ACOUSTIC MEATUS)`,
  [`OSSO TEMPORALE SINISTRO (PARTE PETROSA)`]: `LEFT TEMPORAL BONE (PETROUS PART)`,
  [`OSSO TEMPORALE SINISTRO (PARTE SQUAMOSA)`]: `LEFT TEMPORAL BONE (SQUAMOUS PART)`,
  [`OSSO TEMPORALE SINISTRO (PARTE TIMPANICA)`]: `LEFT TEMPORAL BONE (TYMPANIC PART)`,
  [`OSSO TEMPORALE SINISTRO (PROCESSO MASTOIDEO)`]: `LEFT TEMPORAL BONE (MASTOID PROCESS)`,
  [`OSSO TEMPORALE SINISTRO (PROCESSO STILOIDEO)`]: `LEFT TEMPORAL BONE (STYLOID PROCESS)`,
  [`OSSO TEMPORALE SINISTRO (PROCESSO ZIGOMATICO)`]: `LEFT TEMPORAL BONE (ZYGOMATIC PROCESS)`,
  [`Osso zigomatico (DX)`]: `Zygomatic bone (R)`,
  [`Osso zigomatico (SX)`]: `Zygomatic bone (L)`,
  [`OSSO ZIGOMATICO DESTRO`]: `RIGHT ZYGOMATIC BONE`,
  [`OSSO ZIGOMATICO SINISTRO`]: `LEFT ZYGOMATIC BONE`,
  [`Osteite pubica`]: `Osteitis pubis`,
  [`osteocondrite di Freiberg`]: `Freiberg osteochondritis`,
  [`osteocondrite dissecante`]: `Dissecans osteochondritis`,
  [`Osteocondrosi`]: `Osteochondritis`,
  [`Osteocondrosi che attraversa I confini anatomici`]: `Osteochondrosis crossing anatomical boundaries`,
  [`Osteocondrosi del capitello radiale`]: `Capitellar osteochondrosis`,
  [`Osteocondrosi del condilo femorale mediale o laterale`]: `OCD Medial or lateral femoral condyle`,
  [`Osteocondrosi del ginocchio`]: `Osteochondrosis of knee`,
  [`Osteocondrosi del gomito`]: `Osteochondrosis elbow`,
  [`Osteocondrosi del piede`]: `Osteochondrosis of foot`,
  [`Osteocondrosi del polso e della mano`]: `Osteochondrosis of wrist and hand`,
  [`Osteocondrosi dell'anca`]: `Osteochondroses of hip joint`,
  [`Osteocondrosi della caviglia`]: `Osteochondrosis of ankle`,
  [`Osteocondrosi della rotula`]: `OCD Patella`,
  [`Osteocondrosi della spalla`]: `Osteochondrosis shoulder`,
  [`Osteocondrosi vertebrale`]: `Osteochondrosis Spine`,
  [`Osteolisi distale della clavicola`]: `Osteolysis of distal clavicle`,
  [`Osteoporosi`]: `Osteoporosis`,
  [`Osteosarcoma`]: `Osteosarcoma`,
  [`Ostruzione acuta del colon`]: `Intestinal Obstruction`,
  [`Ostruzione della vena succlavia`]: `Subclavian vein obstruction`,
  [`Otite esterna`]: `Otitis externa`,
  [`Otite media ricorrente`]: `Recurrent middle ear infections`,
  [`Otorino`]: `Otological`,
  [`Otorino-laringoiatrici`]: `ENT`,
  [`Otorinolaringoiatrici`]: `Otological`,
  [`Otorinolaringoiatrico`]: `Otological`,
  [`Ottawa Ankle Rules (DX)`]: `Ottawa Ankle Rules (R)`,
  [`Ottawa Ankle Rules (SX)`]: `Ottawa Ankle Rules (L)`,
  [`Ottawa Foot Rules (DX)`]: `Ottawa Foot Rules (R)`,
  [`Ottawa Foot Rules (SX)`]: `Ottawa Foot Rules (L)`,
  [`Ottawa Knee Rules (DX)`]: `Ottawa Knee Rules (R)`,
  [`Ottawa Knee Rules (SX)`]: `Ottawa Knee Rules (L)`,
  [`Ottawa knee rules: tenderness isolata sulla rotula, tenderness sulla testa del perone, incapacita' di flettere il ginocchio a 90 gradi (DX)`]: `Ottawa knee rules: isolated tenderness of patella, tenderness of fibular head, inability to bend the knee to 90 degrees (R)`,
  [`Ottawa knee rules: tenderness isolata sulla rotula, tenderness sulla testa del perone, incapacita' di flettere il ginocchio a 90 gradi (SX)`]: `Ottawa knee rules: isolated tenderness of patella, tenderness of fibular head, inability to bend the knee to 90 degrees (L)`,
  [`Out-flare`]: `Out-flare`,
  [`Ovaie`]: `Ovaries`,
  [`OVAIE (F)`]: `OVARIES (F)`,
  [`OVAIO DESTRO (F)`]: `RIGHT OVARY (F)`,
  [`OVAIO SINISTRO (F)`]: `LEFT OVARY (F)`,
  [`Overall AROM articolazione del gomito (DX)`]: `Elbow joint overall AROM (R)`,
  [`Overall AROM articolazione del gomito (SX)`]: `Elbow joint overall AROM (L)`,
  [`Overall AROM articolazione del gomito e Tenderness alla Palpazione (DX)`]: `Elbow joint overall AROM and Tenderness at palpation (R)`,
  [`Overall AROM articolazione del gomito e Tenderness alla Palpazione (SX)`]: `Elbow joint overall AROM and Tenderness at palpation (L)`,
  [`Overall AROM articolazione del polso (DX)`]: `Wrist joint overall AROM (R)`,
  [`Overall AROM articolazione del polso (SX)`]: `Wrist joint overall AROM (L)`,
  [`Overexercising disorder`]: `Overexercising disorder`,
  [`Overhead Barbell Step Up`]: `Overhead Barbell Step Up`,
  [`Overhead Squat`]: `OVERHEAD SQUAT WITH RESISTANCE BAND`,
  [`Overpressure Active ROM delle vertebre toraciche`]: `Overpressure Active ROM of thoracic vertebrae`,
  [`Overpressure AROM articolazione dell'anca (DX)`]: `Hip Joint overpressure AROM (R)`,
  [`Overpressure AROM articolazione dell'anca (SX)`]: `Hip Joint overpressure AROM (L)`,
  [`PACE Test (Passive Seated Piriformis Test) (DX)`]: `PACE Test (Passive Seated Piriformis Test) (R)`,
  [`PACE Test (Passive Seated Piriformis Test) (SX)`]: `PACE Test (Passive Seated Piriformis Test) (L)`,
  [`Padel`]: `Padel`,
  [`Pain Provocation Cluster Tests: Thigh Thrust Test, Sacral Thrust Test, Compression Test, Distraction Test (DX)`]: `Pain Provocation Cluster Tests: Thigh Thrust Test, Sacral Thrust Test, Compression Test, Distraction Test (R)`,
  [`Pain Provocation Cluster Tests: Thigh Thrust Test, Sacral Thrust Test, Compression Test, Distraction Test (SX)`]: `Pain Provocation Cluster Tests: Thigh Thrust Test, Sacral Thrust Test, Compression Test, Distraction Test (L)`,
  [`Painful Arc (DX)`]: `Painful Arc (R)`,
  [`Painful Arc (SX)`]: `Painful Arc (L)`,
  [`Painful Catch Sign`]: `Painful Catch Sign`,
  [`PALATO`]: `PALATE`,
  [`PALATO DURO`]: `HARD PALATE`,
  [`PALATO MOLLE`]: `SOFT PALATE`,
  [`Pallacanestro`]: `Basket`,
  [`Pallamano`]: `Handball`,
  [`Pallanuoto`]: `Waterpolo`,
  [`Pallapugno`]: `Punchball`,
  [`Pallavolo`]: `Volley`,
  [`Palpation for Achilles Tendon Tenderness (DX)`]: `Palpation for Achilles Tendon Tenderness (R)`,
  [`Palpation for Achilles Tendon Tenderness (SX)`]: `Palpation for Achilles Tendon Tenderness (L)`,
  [`Palpation for Spinous Process Step-Off`]: `Palpation for Spinous Process Step-Off`,
  [`Palpation of Gap in Achilles Tendon (DX)`]: `Palpation of Gap in Achilles Tendon (R)`,
  [`Palpation of Gap in Achilles Tendon (SX)`]: `Palpation of Gap in Achilles Tendon (L)`,
  [`Palpation Side-Glide Test (DX)`]: `Palpation Side-Glide Test (R)`,
  [`Palpation Side-Glide Test (SX)`]: `Palpation Side-Glide Test (L)`,
  [`Palpazione per la tendinopatia rotulea (DX)`]: `Palpation for Patellar Tendinopathy (R)`,
  [`Palpazione per la tendinopatia rotulea (SX)`]: `Palpation for Patellar Tendinopathy (L)`,
  [`PALPEBRA DESTRA`]: `RIGHT EYELID`,
  [`PALPEBRA SINISTRA`]: `LEFT EYELID`,
  [`Palpitante`]: `Throbbing`,
  [`Palpitazioni`]: `Heart palpitations or fluttering`,
  [`Palpitazioni cardiache`]: `Heart palpitations or fluttering`,
  [`Pancreas`]: `Pancreas`,
  [`PANCREAS`]: `PANCREAS`,
  [`PANCREAS (CODA)`]: `PANCREAS (TAIL)`,
  [`PANCREAS (COLLO)`]: `PANCREAS (NECK)`,
  [`PANCREAS (CORPO)`]: `PANCREAS (BODY)`,
  [`PANCREAS (TESTA)`]: `PANCREAS (HEAD)`,
  [`Pancreatite`]: `Pancreatitis`,
  [`Paralisi cerebrale`]: `Cerebral palsy`,
  [`Paralisi del nervo peroneale (con piede cadente)`]: `Peroneal nerve palsy (with foot drop)`,
  [`Paralisi isolata del nervo ascellare (escluse quelle causate da lussazione di spalla)`]: `Isolated axillary nerve palsy`,
  [`parestesie o formicolii`]: `Paresthesia and numbness`,
  [`Parete addominale`]: `Abdominal wall`,
  [`PAROOFORON (F)`]: `PAROOPHORON (F)`,
  [`Parte I: Per ogni domanda indichi quanto dolore ha provato durante la scorsa settimana`]: `How severe is your pain?`,
  [`Parte II: Per ogni domanda indichi quanta difficoltà ha avuto nello svolgere le attività riportate nell'ultima settimana`]: `How much difficulty do you have?`,
  [`Parte laterale`]: `Lateral part`,
  [`Parte mediale`]: `Medial part`,
  [`Partire e fermarsi rapidamente nella corsa`]: `Starting and stopping quickly`,
  [`Parzialmente`]: `Moderately`,
  [`Passabile`]: `Fair`,
  [`Passi Laterali Con Elastico`]: `BAND SIDE WALK`,
  [`Passive Accessory InterVertebral Motion in estensione vertebre cervicali`]: `Passive Accessory InterVertebral Motion in estensione vertebre cervicali`,
  [`Passive Accessory Intervertebral Motion in scivolamento postero-anteriore centrale vertebre lombari`]: `Passive Accessory Intervertebral Motion lumbar vertebrae posterior-anterior central glide`,
  [`Passive Accessory Intervertebral Motion in scivolamento postero-anteriore unilaterale vertebre lombari (DX)`]: `Passive Accessory Intervertebral Motion lumbar vertebrae posterior-anterior unilateral glide (R)`,
  [`Passive Accessory Intervertebral Motion in Scivolamento postero-anteriore unilaterale vertebre lombari (SX)`]: `Passive Accessory Intervertebral Motion lumbar vertebrae posterior-anterior unilateral glide (L)`,
  [`Passive Accessory Intervertebral Motion postero-anteriore (eccesso di rimbalzo) vertebre lombari`]: `Passive Acceyssor Intervertebral Motion lumbar vertebrae posterior-anterior central (excess of rebound) `,
  [`Passive Accessory Intervertebral Motion postero-anteriore centrale (eccesso di rimbalzo) vertebre cervicali`]: `Passive Accessory Intervertebral Motion cervical vertebrae posterior-anterior central (excess of rebound)`,
  [`Passive Accessory Intervertebral Motion postero-anteriore centrale (eccesso di rimbalzo) vertebre toraciche`]: `Passive Accessory Intervertebral Motion central posterior-anterior (excess of rebound) thoracic vertebrae`,
  [`Passive Accessory Intervertebral Motion postero-anteriore centrale (mancanza di rimbalzo) vertebre cervicali`]: `Passive Accessory Intervertebral Motion cervical vertebrae posterior-anterior central (absence of rebound)`,
  [`Passive Accessory Intervertebral Motion postero-anteriore centrale (mancanza di rimbalzo) vertebre toraciche`]: `Passive Accessory Intervertebral Motion central posterior-anterior (absence of rebound) thoracic vertebrae`,
  [`Passive Accessory Intervertebral Motion postero-anteriore unilaterale (eccesso di rimbalzo) vertebre cervicali (DX)`]: `Passive Accessory Intervertebral Motion posterior-anterior unilateral (excess of rebound) (R)`,
  [`Passive Accessory Intervertebral Motion postero-anteriore unilaterale (eccesso di rimbalzo) vertebre cervicali (SX)`]: `Passive Accessory Intervertebral Motion posterior-anterior unilateral (excess of rebound) (L)`,
  [`Passive Accessory Intervertebral Motion Postero-Anteriore unilaterale (eccesso di rimbalzo) vertebre toraciche (DX)`]: `Passive Accessory Intervertebral Motion unilateral posterior-anterior (excess of rebound) thoracic vertebrae (R)`,
  [`Passive Accessory Intervertebral Motion Postero-Anteriore unilaterale (eccesso di rimbalzo) vertebre toraciche (SX)`]: `Passive Accessory Intervertebral Motion unilateral posterior-anterior (excess of rebound) thoracic vertebrae (L)`,
  [`Passive Accessory Intervertebral Motion rotazione (eccesso di rimbalzo) vertebre cervicali (DX)`]: `Passive Accessory Intervertebral Motion cervical vertebrae rotation (excess of rebound) (R)`,
  [`Passive Accessory Intervertebral Motion rotazione (eccesso di rimbalzo) vertebre cervicali (SX)`]: `Passive Accessory Intervertebral Motion cervical vertebrae rotation (excess of rebound) (L)`,
  [`Passive Accessory Intervertebral Motion rotazione destra (eccesso di rimbalzo) vertebre cervicali`]: `Passive Accessory Intervertebral Motion rotation (excessive rebound) cervical vertebrae (R)`,
  [`Passive Accessory Intervertebral Motion rotazione sinistra (eccesso di rimbalzo) vertebre cervicali`]: `Passive Accessory Intervertebral Motion rotation (excessive rebound) cervical vertebrae (L)`,
  [`Passive Accessory Intervertebral Motion side-glide (eccesso di rimbalzo) vertebre cervicali (DX)`]: `Passive Accessory Intervertebral Motion cervical vertebrae side-glide (excess of rebound) (R)`,
  [`Passive Accessory Intervertebral Motion side-glide (eccesso di rimbalzo) vertebre cervicali (SX)`]: `Passive Accessory Intervertebral Motion cervical vertebrae side-glide (excess of rebound) (L)`,
  [`Passive Accessory Intervertebral Motion side-glide destro (eccesso di rimbalzo) vertebre cervicali`]: `Passive Accessory Intervertebral Motion side-glide (excessive rebound) cervical vertebrae (R)`,
  [`Passive Accessory Intervertebral Motion side-glide sinistro (eccesso di rimbalzo) vertebre cervicali`]: `Passive Accessory Intervertebral Motion side-glide (excessive rebound) cervical vertebrae (L)`,
  [`Passive Acceyssor Intervertebral Motion postero-anteriore centrale (mancanza di rimbalzo) vertebre lombari`]: `Passive Acceyssor Intervertebral Motion lumbar vertebrae posterior-anterior central (absence of rebound) `,
  [`Passive Compression Test (DX)`]: `Passive Compression Test (R)`,
  [`Passive Compression Test (SX)`]: `Passive Compression Test (L)`,
  [`Passive Distraction Test (DX)`]: `Passive Distraction Test (R)`,
  [`Passive Distraction Test (SX)`]: `Passive Distraction Test (L)`,
  [`Passive Forearm Pronation (DX)`]: `Passive Forearm Pronation (R)`,
  [`Passive Forearm Pronation (SX)`]: `Passive Forearm Pronation (L)`,
  [`Passive Hip Motion (DX)`]: `Passive Hip Motion (R)`,
  [`Passive Hip Motion (SX)`]: `Passive Hip Motion (L)`,
  [`Passive Lumbar Extension Test`]: `Passive Lumbar Extension Test`,
  [`Passive Physiological InterVertebral Motion in estensione C0-C1`]: `Passive Physiological InterVertebral Motion in extension C0-C1`,
  [`Passive Physiological Intervertebral Motion in estensione vertebre lombari`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in extension`,
  [`Passive Physiological Intervertebral Motion in estensione vertebre lombari (DX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in extension `,
  [`Passive Physiological Intervertebral Motion in estensione vertebre lombari (SX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in extension `,
  [`Passive Physiological InterVertebral Motion in flessione C0-C1`]: `Passive Physiological InterVertebral Motion in flexion C0-C1`,
  [`Passive Physiological Intervertebral Motion in flessione vertebre lombari`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in flexion`,
  [`Passive Physiological Intervertebral Motion in flessione vertebre lombari (DX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in flexion `,
  [`Passive Physiological Intervertebral Motion in flessione vertebre lombari (SX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in flexion `,
  [`Passive Physiological InterVertebral Motion in flessione-rotazione C1-C2 (DX)`]: `Passive Physiological InterVertebral Motion in flexion-rotation C1-C2 (R)`,
  [`Passive Physiological InterVertebral Motion in flessione-rotazione C1-C2 (SX)`]: `Passive Physiological InterVertebral Motion in flexion-rotation C1-C2 (L)`,
  [`Passive Physiological Intervertebral Motion in rotazione vertebre lombari (DX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in rotation (R)`,
  [`Passive Physiological Intervertebral Motion in rotazione vertebre lombari (SX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in rotation (L)`,
  [`Passive Physiological InterVertebral Motion in side-bending C0-C1 (DX)`]: `Passive Physiological InterVertebral Motion in side-bending C0-C1 (R)`,
  [`Passive Physiological InterVertebral Motion in side-bending C0-C1 (SX)`]: `Passive Physiological InterVertebral Motion in side-bending C0-C1 (L)`,
  [`Passive Physiological Intervertebral Motion in side-bending vertebre lombari (DX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in side-bending (R)`,
  [`Passive Physiological Intervertebral Motion in side-bending vertebre lombari (SX)`]: `Passive Physiological Intervertebral Motion lumbar vertebrae in side-bending (L)`,
  [`Passive Supine 90/90 Position Assessment (DX)`]: `Passive Supine 90/90 Position Assessment (R)`,
  [`Passive Supine 90/90 Position Assessment (SX)`]: `Passive Supine 90/90 Position Assessment (L)`,
  [`Passo medio al Km`]: `Pace (min/km)`,
  [`Patellar Apprehension Test (DX)`]: `Patellar Apprehension Test (R)`,
  [`Patellar Apprehension Test (SX)`]: `Patellar Apprehension Test (L)`,
  [`Patellar Compression Test (DX)`]: `Patellar Compression Test (R)`,
  [`Patellar Compression Test (SX)`]: `Patellar Compression Test (L)`,
  [`Patellar Tilt Test (DX)`]: `Patellar Tilt Test (R)`,
  [`Patellar Tilt Test (SX)`]: `Patellar Tilt Test (L)`,
  [`Patellar-Pubic Percussion Test (DX)`]: `Patellar-Pubic Percussion Test (R)`,
  [`Patellar-Pubic Percussion Test (SX)`]: `Patellar-Pubic Percussion Test (L)`,
  [`patologia acromion-clavicolare cronica`]: `Chronic acromion-clavicular pathology`,
  [`Patologia inserzionale del tendine rotuleo, compreso ossicolo intratendineo (escluso Osgood-Schlatters)`]: `Insertional Patellar tendon pathology, incl intratendon ossicle`,
  [`patologia sterno-clavicolare`]: `Sternoclavicular pathology`,
  [`Pattern capsulare`]: `Capsular pattern`,
  [`Pattinaggio`]: `Skating`,
  [`Paura`]: `Fear`,
  [`Pauroso`]: `Fearful`,
  [`Paxinos Sign (DX)`]: `Paxinos Sign (R)`,
  [`Paxinos Sign (SX)`]: `Paxinos Sign (L)`,
  [`Pectoralis Major Assessment (DX)`]: `Pectoralis Major Assessment (R)`,
  [`Pectoralis Major Assessment (SX)`]: `Pectoralis Major Assessment (L)`,
  [`Pedalate o colpi al minuto medi`]: `Cadence RPM`,
  [`peggiora alla sera`]: `It gets worse in the evening`,
  [`Pelvi (DX)`]: `Pelvis (R)`,
  [`Pelvi (SX)`]: `Pelvis (L)`,
  [`PELVI DESTRA`]: `RIGHT PELVIC BONE`,
  [`PELVI DESTRA (ACETABOLO)`]: `RIGHT PELVIC BONE (ACETABOLUM)`,
  [`PELVI DESTRA (RAMO ISCHIOPUBICO)`]: `RIGHT PELVIC BONE (ISCHIOPUBIC RAMUS)`,
  [`PELVI SINISTRA`]: `LEFT PELVIC BONE`,
  [`PELVI SINISTRA (ACETABOLO)`]: `LEFT PELVIC BONE (ACETABOLUM)`,
  [`PELVI SINISTRA (RAMO ISCHIOPUBICO)`]: `LEFT PELVIC BONE (ISCHIOPUBIC RAMUS)`,
  [`PENE (CORPO) (M)`]: `PENIS (BODY) (M)`,
  [`PENE (GLANDE) (M)`]: `PENIS (GLANS) (M)`,
  [`PENE (M)`]: `PENIS (M)`,
  [`Penetrante`]: `Penetrating`,
  [`Pensa di poter lanciare una palla da tennis ad una distanza di 18 metri?`]: `10. Do you think you can throw a softball overhand 20 yards with the affected extremity?`,
  [`Pensa di poter lanciare una palla da tennis ad una distanza di 9 metri?`]: `9. Do you think you can toss a softball underhand 10 yards with the affected extremity?`,
  [`Pensi che il tuo problema attuale sia relazionato al tuo lavoro?`]: `Do you think your current pain/injury is related with your job?`,
  [`Per favore indica se utilizzi:`]: `Please, tell us if you use:`,
  [`Per favore indica tra I seguenti infortuni, quelli che hai subito nella tua vita:`]: `Which injuries have you had?`,
  [`Per favore, indica se nella tua vita hai mai avuto problemi (considera che alcune cose potrebbero essere ripetute):`]: `Have you ever had one or more of the next:`,
  [`Per favore, indica se soffri attualmente, o hai sofferto recentemente, di uno dei seguenti problemi`]: `Please, tell us if you recently have had one of the next symptoms:`,
  [`Per favore, indica se tu o uno dei tuoi familiari piu' vicini (genitori, fratelli, figli) avete mai soffeto di una delle seguenti condizioni:`]: `Have you or any immediate family member (parent, sibling, child) ever been told that you have:`,
  [`Per favore, indica se tu o uno dei tuoi familiari piu' vicini (genitori, fratelli, figli) avete mai soffeto o soffrite di una delle seguenti condizioni:`]: `Have you or any of your family members ever suffered of:`,
  [`Per favore, indica tra le seguenti aree del corpo, quale/i nel corso della tua vita ti ha/hanno dato piu' problemi o ti da/danno problemi in maniera ricorrente`]: `Please, tell us wich body areas create more problems `,
  [`Per favore, inserisci il tuo peso e la tua altezza in metri`]: `Please, insert your body weight and your height (in meters)`,
  [`Per favore, rispondi alle seguenti domande:`]: `Please answer to the next questions:`,
  [`Per favore, segnali sulla seguente scala quale è la sua condizione di recupero in questo momento`]: `Please, rate your recovery`,
  [`Per favore, segnali sulla seguente scala quale è la sua condizione di recupero in questo momento.`]: `Please, rate your recovery`,
  [`Per le prossime domande indichi quanto dolore hai avuto la scorsa settimana durante le seguenti attività?`]: `What amount of knee pain have you experienced the last week during the
following activities? `,
  [`Per niente`]: `None at all`,
  [`Per nulla`]: `Not at all`,
  [`Per poter calcolare il DASH è necessario rispondere almeno a 27 delle prime 30 domande obbligatorie; più e' basso il risultato, migliore è la condizione del paziente`]: `At least 27 of the 30 items must be completed for a score to be calculated. The assigned values for all completed responses are simply summed and averaged, producing a score out of five. This value is then transformed to a score out of 100 by subtracting one and multiplying by 25. This transformation is done to make the score easier to compare to other measures scaled on a 0-100 scale. A higher score indicates greater disability.`,
  [`Per quanti minuti ha dolore nella regione del tendine di Achille al risveglio?`]: `For how many minutes do you have stiffness in the Achilles region on first getting up?`,
  [`Per quanti minuti può stare seduto senza avere dolore?`]: `For how many minutes can you sit pain free?`,
  [`Per quanto tempo lei ha:`]: `Answer all of the following questions related to your pain and activities over the past WEEK. How much of the time did you:`,
  [`Percepisci dolore o debolezza allo stringere la mano`]: `Do you feel pain or weakness shaking hands?`,
  [`Perde Salita Sui Talloni`]: `TOE RAISES`,
  [`Perdita di urine`]: `Dribbling or leaking urine`,
  [`Perdite di memoria`]: `Memory loss`,
  [`Perforante`]: `Drilling`,
  [`Perforazione del timpano`]: `Perforated ear drum`,
  [`PERICARDIO`]: `PERICARDIUM`,
  [`Pericardite`]: `Pericarditis`,
  [`PERIMETRIO (F)`]: `PERIMETRIUM (F)`,
  [`Periodontite`]: `Periodontitis`,
  [`Periostite`]: `Periostitis`,
  [`Periostite tibiale anteriore/sindrome da stress/shin splints`]: `Anterior shin splints`,
  [`Periostite tibiale posteromediale/sindrome da stress/shin splints`]: `Medial Tibial Stress Syndrome ('shin splints')`,
  [`PERITONEO`]: `PERITONEUM`,
  [`PERITONEO PARIETALE`]: `PARIETAL PERITONEUM`,
  [`PERITONEO UROGENITALE`]: `UROGENITAL PERITONEUM`,
  [`PERITONEO VISCERALE`]: `VISCERAL PERITONEUM`,
  [`Perone (DX)`]: `Fibula (R)`,
  [`Perone (SX)`]: `Fibula (L)`,
  [`PERONE DESTRO`]: `RIGHT FIBULA  `,
  [`PERONE DESTRO (COLLO)`]: `RIGHT FIBULA (NECK)`,
  [`PERONE DESTRO (DIAFISI)`]: `RIGHT FIBULA (SHAFT)`,
  [`PERONE DESTRO (MALLEOLO LATERALE)`]: `RIGHT FIBULA (LATERAL MALLEOLUS)`,
  [`PERONE DESTRO (TESTA DEL PERONE)`]: `RIGHT FIBULA (HEAD OF FIBULA)`,
  [`PERONE SINISTRO`]: `LEFT FIBULA  `,
  [`PERONE SINISTRO (COLLO)`]: `LEFT FIBULA (NECK)`,
  [`PERONE SINISTRO (DIAFISI)`]: `LEFT FIBULA (SHAFT)`,
  [`PERONE SINISTRO (MALLEOLO LATERALE)`]: `LEFT FIBULA (LATERAL MALLEOLUS)`,
  [`PERONE SINISTRO (TESTA DEL PERONE)`]: `LEFT FIBULA (HEAD OF FIBULA)`,
  [`Persecutorio`]: `Pinishment`,
  [`Pesca sportiva`]: `Sport fishing`,
  [`Petto carenato`]: `Pectum carinatus`,
  [`Petto escavato`]: `Pectus excavatum`,
  [`Petto scavato`]: `Pectum escavatum`,
  [`Phalene's Test (DX)`]: `Phalene's Test (R)`,
  [`Phalene's Test (SX)`]: `Phalene's Test (L)`,
  [`Piaghe da sella`]: `Saddle sores`,
  [`Piano Key Test (DX)`]: `Piano Key Test (R)`,
  [`Piano Key Test (SX)`]: `Piano Key Test (L)`,
  [`PICCOLO OMENTO`]: `LESSER OMENTUM`,
  [`Piede`]: `Foot`,
  [`Piede (DX)`]: `Foot (R)`,
  [`Piede (SX)`]: `Foot (L)`,
  [`Piede Abduzione Con Elastico`]: `ANKLE ABDUCTION WITH RESISTANCE BAND`,
  [`Piede Abduzione Eversione`]: `ANKLE ABDUCTION + EVERSION`,
  [`Piede Adduzione Con Elastico`]: `ANKLE ADDUCTION WITH RESISTANCE BAND`,
  [`Piede Adduzione Flessione Plantare`]: `ANKLE ADDUCTION + INVERSION + PLANTAR FLEXION`,
  [`Piede Adduzione Inversione`]: `ANKLE ADDUCTION + INVERSION`,
  [`Piede Carico Interno Piede Esterno Piede`]: `PLANTAR FASCIA ACTIVATION`,
  [`PIEDE DESTRO`]: `RIGHT FOOT`,
  [`Piede Equilibrio Monopodalico Con Attivazione Flessore Alluce`]: `SINGLE LEG STANCE WITH BAND UNDER BIG TOE`,
  [`Piede Flessione Dorsale`]: `ANKLE DORSIFLEXION`,
  [`Piede Flessione Dorsale Con Elastico`]: `BANDED ANKLE DORSIFLEXION`,
  [`Piede Flessione Plantare`]: `ANKLE PLANTAR FLEXION`,
  [`Piede Flessione Plantare Con Elastico`]: `BANDED ANKLE PLANTAR FLEXION`,
  [`Piede Salita Sul Tallone Monopodalico`]: `SINGLE LEG TOE RAISES`,
  [`Piede Salita Sulla Punta Monopodalico`]: `SINGLE LEG HEEL RAISES`,
  [`Piede Salita Sulle Punte`]: `HEELS RAISES`,
  [`PIEDE SINISTRO`]: `LEFT FOOT`,
  [`Piegarsi verso il pavimento/raccogliere un oggetto`]: `Bending to the floor/pick up an object`,
  [`Piegarsi, inginocchiarsi o chinarsi`]: `Bending, kneeling, or stooping`,
  [`Pielonefrite`]: `Pyelonephritis`,
  [`Pieno di energia`]: `Did you have a lot of energy?`,
  [`Pike Bench Push Ups`]: `Pike Bench Push Ups`,
  [`Pike Swiss Ball Abs`]: `Pike Swiss Ball Abs`,
  [`Piramidale (DX)`]: `Triquetral (R)`,
  [`Piramidale (SX)`]: `Triquetral (L)`,
  [`PIRAMIDALE DESTRO`]: `RIGHT TRIQUETRUM`,
  [`PIRAMIDALE SINISTRO`]: `LEFT TRIQUETRUM`,
  [`Pisiforme (DX)`]: `Pisiform (R)`,
  [`Pisiforme (SX)`]: `Pisiform (L)`,
  [`PISIFORME DESTRO`]: `RIGHT PISIFORM`,
  [`PISIFORME SINISTRO`]: `LEFT PISIFORM`,
  [`Piston Squat`]: `Piston Squat`,
  [`più di 30 minuti`]: `>30 mins`,
  [`Più o meno uguale rispetto a un anno fa`]: `About the same`,
  [`piu' di un mese`]: `More than a month`,
  [`Pivot Shift Test (DX)`]: `Pivot Shift Test (R)`,
  [`Pivot Shift Test (SX)`]: `Pivot Shift Test (L)`,
  [`Pizzicante`]: `Pricking`,
  [`Plank 2 Appoggi Dinamico`]: `2 POINTS PLANK KNEE TO ELBOW`,
  [`Plank 2 Appoggi So`]: `2 POINTS PLANK`,
  [`Plank 3 App`]: `3 POINTS PLANK`,
  [`Plank 3 App Ginocchio Gomito`]: `3 POINTS PLANK KNEE TO ELBOW`,
  [`Plank 3 App Ginocchio Gomito Laterale`]: `3 POINTS PLANK LATERAL KNEE TO ELBOW`,
  [`Plank 3 App Ginocchio Polso`]: `3 POINTS PLANK KNEE TO WRIST`,
  [`Plank 3 App Ginocchio Polso Alternato`]: `3 POINTS PLAN ALTERNATING KNEE TO WRIST`,
  [`Plank 3 App Ginocchio Polso Opposto`]: `3 POINTS PLANK KNEE TO OPPOSIT WRIST`,
  [`Plank 3 App Ginocchio Polso Opposto Alternato`]: `3 POINTS PLANK ALTERNATING KNEE TO OPPOSIT WRIST`,
  [`Plank 3 App Ginocchio Tra Le Braccia`]: `3 POINTS PLANK KNEE BETWEEN SHOULDER`,
  [`Plank 3 App Iso Ginocchio Gomito Laterale`]: `ISOMETRIC 3 POINTS PLANK LATERAL KNEE TO ELBOW`,
  [`Plank 3 App Iso Ginocchio Polso Opposto`]: `ISOMETRIC 3 POINTS PLANK KNEE TO OPPOSIT WRIST`,
  [`Plank 3 App Iso Ginocchio Tra Le Braccia`]: `ISOMETRIC 3 POINTS PLANK KNEE BETWEEN SHOULDER`,
  [`Plank 3 App Iso Mano Piede Esterno`]: `ISOMETRIC 3 POINTS PLANK HAND TO SAME SIDE FOOT`,
  [`Plank 3 App Iso Mano Piede Interno`]: `ISOMETRIC 3 POINTS PLANK HAND TO SAME INSIDE FOOT`,
  [`Plank 3 App Iso Mano Piede Interno Opposto`]: `ISOMETRIC 3 POINTS PLANK HAND TO OPPOSITE INSIDE FOOT`,
  [`Plank 3 App Mano Piede Esterno`]: `3 POINTS PLANK HAND TO SAME SIDE FOOT`,
  [`Plank 3 App Mano Piede Interno`]: `3 POINTS PLANK HAND TO INSIDE FOOT`,
  [`Plank 3 App Mano Piede Interno Opposto`]: `3 POINTS PLANK HAND TO OPPOSITE INSIDE FOOT`,
  [`Plank 3 App Mano Piede Interno Opposto Da Dietro`]: `3 POINTS PLANK HAND TO OPPOSITE FOOT WITH ROTATION`,
  [`Plank 3 Appoggi Ginocchio Gomito Alternato`]: `3 POINTS PLANK ALTERNATING KNEE TO ELBOW`,
  [`Plank Alto Jumping Jack`]: `PLANK JUMPING JACK`,
  [`Plank Alto Mountain Climber`]: `PLANK MOUNTAIN CLIMBER`,
  [`Plank Alto Walk In`]: `PLANK WALK IN`,
  [`Plank Alto Walk Out`]: `PLANK WALK OUT`,
  [`Plank Gomiti 2 Appoggi`]: `ISOMETRIC 2 POINTS ELBOW PLANK`,
  [`Plank Gomiti 2 Appoggi Alternato`]: `2 POINTS ELBOW PLANK ALTERNATING`,
  [`Plank Gomiti 2 Appoggi Gomito Ginocchio`]: `2 POINTS ELBOW PLANK KNEE TO ELBOW`,
  [`Plank Gomiti 3 Appoggi Ginocchio Gomito Laterale`]: `3 POINTS ELBOW PLANK LATERAL KNEE TO ELBOW`,
  [`Plank Gomiti 3 Appoggi Ombra`]: `3 POINTS ELBOW PLANK BODY SAW`,
  [`Plank Gomiti 4 Appoggi Abduzione Gamba`]: `ELBOW PLANK WITH ABDUCTION`,
  [`Plank Gomiti 4 Appoggi Lungo Ombra`]: `LONG LEVER ELBOW BODY SAW`,
  [`Plank Gomiti 4 Appoggi Marciato Braccia`]: `ELBOW PLANK ARM MARCH`,
  [`Plank Gomiti 4 Appoggi Marciato Gambe`]: `ELBOW PLANK LEG MARCH`,
  [`Plank Gomiti 4 Appoggi Mountain Climber`]: `ELBOW PLANK MOUNTAIN CLIMBER`,
  [`Plank Gomiti 4 Appoggi Ombra`]: `ELBOW PLANK BODY SAW`,
  [`Plank Gomiti 4 Appoggi Reach`]: `ELBOW PLANK REACH`,
  [`Plank Gomiti 4 Appoggi Reach Laterale`]: `ELBOW PLANK LATERAL REACH`,
  [`Plank Gomiti 4 Appoggi Rotazione`]: `ELBOW PLANK ROTATION`,
  [`Plank Gomiti 4 Appoggi Sollevamento Anche`]: `ELBOW PLANK PIKE`,
  [`Plank Gomiti 4 Appoggi Tocco Fianco Opposto`]: `ELBOW PLANK HIP TOUCH`,
  [`Plank Gomiti 4 Appoggi Tricipiti`]: `PLANK TRICEPS EXTENSION`,
  [`Plank Gomiti Facilitato`]: `ELBOW KNEE PLANK`,
  [`Plank Gomiti Facilitato Reach`]: `ELBOW KNEE PLANK REACH`,
  [`Plank Gomiti Iso 2 Appoggi`]: `ISOMETRIC 2 POINTS ELBOW PLANK`,
  [`Plank Gomiti Iso 3 Appoggi`]: `ISOMETRIC 3 POINTS ELBOW PLANK OVERHEAD ARM`,
  [`Plank Gomiti Iso 3 Appoggi - Gamba Tesa`]: `ISOMETRIC 3 POINTS ELBOW PLANK WITHOUT LEG`,
  [`Plank Gomiti Iso 3 Appoggi - Ginocchio Gomito`]: `ISOMETRIC 3 POINTS ELBOW PLANK KNEE TO ELBOW`,
  [`Plank Gomiti Iso 3 Appoggi - Ginocchio Gomito Opposto`]: `ISOMETRIC 3 POINTS ELBOW PLANK KNEE TO OPPOSITE ELBOW`,
  [`Plank Gomiti Iso 3 Appoggi -Braccio Laterale`]: `ISOMETRIC 3 POINTS ELBOW PLANK WITH ARM ABDUCTION`,
  [`Plank Gomiti Iso 3 Appoggi -Braccio Lungo Il Corpo`]: `ISOMETRIC 3 POINTS ELBOW PLANK ARM ALONG THE BODY`,
  [`Plank Gomiti Iso 4 Appoggi`]: `ISOMETRIC ELBOW PLANK`,
  [`Plank Gomiti Iso 4 Appoggi Gambe Larghe`]: `ISOMETRIC ELBOW PLANK WIDE STANCE`,
  [`Plank Gomiti Iso 4 Appoggi Lungo`]: `ISOMETRIC LONG LEVER ELBOW PLANK`,
  [`Plank Gomiti Iso 4 Appoggi Piedi Uniti`]: `ISOMETRIC ELBOW PLANK UNITED FEET`,
  [`Plank Iso Ginocchio Polso`]: `ISOMETRIC PLANK KNEE TO WRIST`,
  [`Plantar Percussion Test (DX)`]: `Plantar Percussion Test (R)`,
  [`Plantar Percussion Test (SX)`]: `Plantar Percussion Test (L)`,
  [`Plantari`]: `Insule`,
  [`Plate Circle Overhead`]: `Plate Circle overhead`,
  [`Plate Half Moon`]: `Plate Half Moon`,
  [`Plate Oh Rotation Chop`]: `Plate OH Rotation Chop`,
  [`PLESSO BRACHIALE DESTRO`]: `RIGHT BRACHIAL PLEXUS`,
  [`PLESSO BRACHIALE SINISTRO`]: `LEFT BRACHIAL PLEXUS`,
  [`PLESSO CERVICALE DESTRO`]: `RIGHT CERVICAL PLEXUS`,
  [`PLESSO CERVICALE POSTERIORE DESTRO`]: `RIGHT POSTERIOR CERVICAL PLEXUS`,
  [`PLESSO CERVICALE POSTERIORE SINISTRO`]: `LEFT POSTERIOR CERVICAL PLEXUS`,
  [`PLESSO CERVICALE SINISTRO`]: `LEFT CERVICAL PLEXUS`,
  [`PLESSO LOMBARE DESTRO`]: `RIGHT LUMBAR PLEXUS`,
  [`PLESSO LOMBARE SINISTRO`]: `LEFT LUMBAR PLEXUS`,
  [`PLESSO PUDENDO DESTRO`]: `RIGHT PUDENDUS PLEXUS`,
  [`PLESSO PUDENDO SINISTRO`]: `LEFT PUDENDUS PLEXUS`,
  [`PLESSO SACRALE DESTRO`]: `RIGHT SACRAL PLEXUS`,
  [`PLESSO SACRALE SINISTRO`]: `LEFT SACRAL PLEXUS`,
  [`PLEURA PARIETALE DESTRA`]: `RIGHT PARIETAL PLEURA`,
  [`PLEURA PARIETALE DESTRA (PARTE CERVICALE)`]: `RIGHT PARIETAL PLEURA (CERVICAL PART)`,
  [`PLEURA PARIETALE DESTRA (PARTE COSTALE)`]: `RIGHT PARIETAL PLEURA (COSTAL PART)`,
  [`PLEURA PARIETALE DESTRA (PARTE DIAFRAMMATICA)`]: `RIGHT PARIETAL PLEURA (DIAPHRAGMATIC PART)`,
  [`PLEURA PARIETALE DESTRA (PARTE MEDIASTINICA)`]: `RIGHT PARIETAL PLEURA (MEDIASTINAL PART)`,
  [`PLEURA PARIETALE SINISTRA`]: `LEFT PARIETAL PLEURA`,
  [`PLEURA PARIETALE SINISTRA (PARTE CERVICALE)`]: `LEFT PARIETAL PLEURA (CERVICAL PART)`,
  [`PLEURA PARIETALE SINISTRA (PARTE COSTALE)`]: `LEFT PARIETAL PLEURA (COSTAL PART)`,
  [`PLEURA PARIETALE SINISTRA (PARTE DIAFRAMMATICA)`]: `LEFT PARIETAL PLEURA (DIAPHRAGMATIC PART)`,
  [`PLEURA PARIETALE SINISTRA (PARTE MEDIASTINICA)`]: `LEFT PARIETAL PLEURA (MEDIASTINAL PART)`,
  [`PLEURA VISCERALE DESTRA`]: `RIGHT VISCERAL PLEURA`,
  [`PLEURA VISCERALE SINISTRA`]: `LEFT VISCERAL PLEURA`,
  [`Pleurite`]: `Pleuritis`,
  [`Plica addominale`]: `Abdominal skinfold`,
  [`Plica bicipitale (DX)`]: `Bicipital skinfold (R)`,
  [`Plica bicipitale (SX)`]: `Bicipital skinfold (L)`,
  [`Plica cresta iliaca (DX)`]: `Iliac crest skinfold (R)`,
  [`Plica cresta iliaca (SX)`]: `Iliac crest skinfold (L)`,
  [`Plica del polpaccio (DX)`]: `Calf skinfold (R)`,
  [`Plica del polpaccio (SX)`]: `Calf Skinfold (L)`,
  [`Plica della coscia (DX)`]: `Thigh skinfold (R)`,
  [`Plica della coscia (SX)`]: `Thigh skinfold (L)`,
  [`PLICA RETTOUTERINA (F)`]: `RECTOUTERINE FOLD (F)`,
  [`plica sinoviale`]: `Synovial plica`,
  [`Plica sinoviale del ginocchio`]: `Synovial plica of knee`,
  [`Plica sopraspinale (DX)`]: `Supraspinal skinfold (R)`,
  [`Plica sopraspinale (SX)`]: `Supraspinal skinfold (L)`,
  [`Plica sottoscapolare (DX)`]: `Subscapular skinfold (R)`,
  [`Plica sottoscapolare (SX)`]: `Subscapular skinfold (L)`,
  [`Plica tricipitale (DX)`]: `Tricipital skinfold (R)`,
  [`Plica tricipitale (SX)`]: `Tricipital skinfold (L)`,
  [`Pneumo-torace`]: `Pneumothorax`,
  [`Pneumotorace`]: `Pneumothorax`,
  [`Poca`]: `Low`,
  [`Poco`]: `Mildly`,
  [`Polimialgia reumatica`]: `Rheumatoid polymyalgia`,
  [`Pollice a scatto`]: `Trigger thumb`,
  [`Polmonari`]: `Pulmonary`,
  [`POLMONE DESTRO`]: `RIGHT LUNG `,
  [`POLMONE DESTRO (BASE)`]: `RIGHT LUNG (BASE)`,
  [`POLMONE DESTRO (LOBO INFERIORE)`]: `RIGHT LUNG (INFERIOR LOBE)`,
  [`POLMONE DESTRO (LOBO MEDIO)`]: `RIGHT LUNG (MIDDLE LOBE)`,
  [`POLMONE DESTRO (LOBO SUPERIORE)`]: `RIGHT LUNG (SUPERIOR LOBE)`,
  [`POLMONE SINISTRO`]: `LEFT LUNG  `,
  [`POLMONE SINISTRO (BASE)`]: `LEFT LUNG (BASE)`,
  [`POLMONE SINISTRO (LOBO INFERIORE)`]: `LEFT LUNG (INFERIOR LOBE)`,
  [`POLMONE SINISTRO (LOBO SUPERIORE)`]: `LEFT LUNG (SUPERIOR LOBE)`,
  [`Polmoni`]: `Lungs`,
  [`Polmonite`]: `Pneumonia`,
  [`Polmonite/bronchite/infezione del tratto respiratorio inferiore`]: `Pneumonia/bronchitis/lower RTI`,
  [`Polo`]: `Polo`,
  [`POLPACCIO DESTRO`]: `RIGHT CALF`,
  [`Polpaccio Estensione Sulle Punte Bipodalico`]: `ISOMETRIC CALF RAISES`,
  [`Polpaccio Estensione Sulle Punte Bipodalico Concentrico Eccentrico`]: `CALF RAISES`,
  [`Polpaccio Estensione Sulle Punte Bipodalico Concentrico Monopodalico Eccentrico`]: `CALF RAISES + SINGLE LEG ECCENTRIC`,
  [`Polpaccio Estensione Sulle Punte Monopodalico`]: `ISOMETRIC SINGLE LEG CALF RAISE`,
  [`POLPACCIO SINISTRO`]: `LEFT CALF`,
  [`Polso`]: `Wrist`,
  [`Polso (DX)`]: `Wrist (R)`,
  [`Polso (SX)`]: `Wrist (L)`,
  [`Polso e mano`]: `Wrist & hand`,
  [`Portando la gamba con forza in avanti e/o di lato`]: `Bringing the leg forcefully forward and/or out the side, such as in kicking, skating, etc.`,
  [`Portare la busta della spesa o una ventiquattrore`]: `Carry a shopping bag or briefcase`,
  [`Portare un oggetto del peso di circa 5 Kg con la mano malata`]: `Carry a 10lb object in my affected hand`,
  [`Portare un oggetto del peso uguale o superiore a 5Kg?`]: `Carrying a heavy object of 10 pounds (4.5 kilograms)?`,
  [`Portare un oggetto pesante (oltre 5 kg)`]: `Carry a heavy object (over 10 lbs)`,
  [`Portatore di bypass cardiaco`]: `Heart bypass`,
  [`Posare un oggetto su uno scaffale posto al di sopra della sua testa`]: `Place an object on a shelf above your head`,
  [`Positivo`]: `Positive`,
  [`Possibile presenza di una lassita' capsulare dell'anca`]: `Consider laxity of hip joint capsule`,
  [`Possibile presenza di una lassita' capsulare dell'anca o di stiramento del legamento ileofemorale`]: `Consider laxity of hip joint capsule or iliofemoral ligament`,
  [`Posso dedicarmi a molti, ma non a tutti I miei passatempi a causa del dolore al collo`]: `I am able to engage in most, but not all of my usual recreation activities because of pain in my neck`,
  [`Posso dedicarmi a tutti I miei passatempi con un po di dolore al collo`]: `I am able to engage in all my recreation activities with some pain in my neck `,
  [`Posso dedicarmi a tutti I miei passatempi senza alcun dolore al collo`]: `I am able to engage in all my recreation activities with no neck pain at all`,
  [`Posso dedicarmi con difficolta' ai miei passatempi a causa del dolore al collo`]: `I can hardly do any recreation activities because of pain in my neck`,
  [`Posso dedicarmi solo ad alcuni dei miei passatempi a causa del dolore al collo`]: `I am able to engage in few of my usual recreation activities because of pain in my neck`,
  [`Post-chirurgia di spalla`]: `Post shoulder surgery`,
  [`Post-chirurgico al ginocchio`]: `Post knee Surgery`,
  [`Post-chirurgico al piede`]: `Post foot surgery`,
  [`Post-chirurgico di caviglia`]: `Post ankle surgery`,
  [`Posterior Chain Band Stretch`]: `Posterior Chain Band Stretch`,
  [`Posterior Drawer Test (DX)`]: `Posterior Drawer Test (R)`,
  [`Posterior Drawer Test (SX)`]: `Posterior Drawer Test (L)`,
  [`Posterior Impingement Sign (DX)`]: `Posterior Impingement Sign (R)`,
  [`Posterior Impingement Sign (SX)`]: `Posterior Impingement Sign (L)`,
  [`Posterior Pelvic Palpation (DX)`]: `Posterior Pelvic Palpation (R)`,
  [`Posterior Pelvic Palpation (SX)`]: `Posterior Pelvic Palpation (L)`,
  [`Posterior Tibial Edema Sign (DX)`]: `Posterior Tibial Edema Sign (R)`,
  [`Posterior Tibial Edema Sign (SX)`]: `Posterior Tibial Edema Sign (L)`,
  [`Posteriorita'`]: `Posterior glide`,
  [`PosteroLateral Rotatory Drawer Test (DX)`]: `PosteroLateral Rotatory Drawer Test (R)`,
  [`PosteroLateral Rotatory Drawer Test (SX)`]: `PosteroLateral Rotatory Drawer Test (L)`,
  [`Potrebbe indicare la presenza di un dolore di origine discogenica`]: `Could suggest a discogenic pain`,
  [`Potrebbe indicare la presenza di un problema sacro-iliaco`]: `Could suggest a sacroiliac joint problem`,
  [`Potrebbe indicare la presenza di una radicolopatia o di una patologia relativa al disco`]: `Could suggest radiculopaty or disc pathology`,
  [`Power Clean`]: `Power Clean`,
  [`Power Clean And Jerk`]: `Power Clean and Jerk`,
  [`Power Clean From Blocks Above Knee`]: `Power Clean From Blocks Above Knee`,
  [`Power Jerk`]: `Power Jerk`,
  [`praticando sport con cambi di direzione frequenti`]: `practising sport involving frequent change of directions`,
  [`Praticare il Suo sport come da abitudine`]: `Ability to perform activity with your normal technique`,
  [`Praticare il Suo sport per quanto tempo desidera`]: `Ability to participate in your desired sport as long as you would like`,
  [`Precedenti`]: `Previous `,
  [`Prendere qualcosa dalla tasca posteriore dei pantaloni?`]: `Removing something from your back pocket?`,
  [`Prendi normalmente farmaci per condizioni maggiori?`]: `Do you usually use medications for major problems?`,
  [`Prendi normalmente farmaci per condizioni mediche maggiori?`]: `Do you take medications for medical conditions?`,
  [`Preparation for overseas travel - advice immunisations`]: `Preparation for overseas travel - advice immunisations`,
  [`Prescrizione di esercizi`]: `Exercise prescription`,
  [`Prescrizione esercizi per pazienti con artrosi`]: `Exercise prescription for patient with arthritis`,
  [`Prescrizione esercizi per pazienti con obesita'`]: `Exercise prescription for patient with obesity`,
  [`Presenti click o blocchi della spalla durante le attivita' quotidiane?`]: `Do you have joint click or joint lock during daily activities?`,
  [`Presenti deficit di forza muscolare o di sensibilita' alla parte posteriore della coscia?`]: `Do you have strength deficit or sensory symptoms on the posterior thigh?`,
  [`Presenti deficit di forza muscolare o di sensibilita' alla regione della coscia?`]: `Do you have strength deficit or sensory symptoms on the thigh?`,
  [`Presenti deficit di forza muscolare o di sensibilita' alla regione glutea o alle gambe?`]: `Have you got motor or sensory symptoms at the glutes or legs?`,
  [`Presenti deficit di sensibilita' alla caviglia o al piede?`]: `Have you got sensory symptoms on your ankle?`,
  [`Presenti defict di sensibilita' all'area mandibolare?`]: `Have you got sensory symptoms on the mandibula?`,
  [`Presenti gonfiore localizzato sul gomito?`]: `Have you got a localised swelling on the elbow?`,
  [`Presenti parestesie, formicolii o intorpidimenti sulla regione del gomito e dell'avambraccio?`]: `Have you got paraesthesia, tingling, numbness on your elbow and/or forearm region?`,
  [`Presenti segni di deficit di sensibilita' cutanea o parestesia sulla regione del polpaccio?`]: `Have you got sensory symptoms or paresthesia on your calf?`,
  [`Presenti un deficit di forza evidente al gomito durante le attivita' quotidiane?`]: `Do you have an obvious strength deficit on the elbow during daily activities?`,
  [`Presenti un deficit di forza evidente al polso durante le attivita' quotidiane?`]: `Do you have an evident strength deficit on the wrist during the daily activities?`,
  [`Presenti un deficit di forza evidente alla mano durante le attivita' quotidiane?`]: `Do you have an evident strength deficit on the hand during the daily activities?`,
  [`Presenti un deficit di forza evidente alla spalla durante le attivita' quotidiane?`]: `Do you have an obvious strength deficit on the shoulder during daily activities?`,
  [`Presenti un gonfiore evidente sulla regione anteriore della coscia?`]: `Do you have a clear swelling on your thigh?`,
  [`Pressante`]: `Pressing`,
  [`Pressione`]: `pressure`,
  [`Pressione costrittiva`]: `Constrictive pressure`,
  [`Pressione di trazione`]: `Traction pressure`,
  [`Pressione in un punto`]: `Punctate pressure`,
  [`Pressure Provocation Test (DX)`]: `Pressure Provocation Test (R)`,
  [`Pressure Provocation Test (SX)`]: `Pressure Provocation Test (L)`,
  [`Pro Agility Test`]: `Pro Agility Test`,
  [`Problema medico non diagnosticato/altro`]: `Medical Illness Undiagnosed/Other`,
  [`Problema nutrizionale (incluse deficenze vitaminiche)`]: `Nutritional (including vitamin deficiency)`,
  [`Problema/contusione/ematoma all'unghia/e`]: `Toenail problem/contusion/haematoma`,
  [`Problematico`]: `Troublesome`,
  [`Problemi alla prostata`]: `Prostate problems`,
  [`Problemi di vista o di udito`]: `Problems seeing or hearing`,
  [`problemi digestivi`]: `Digestive disorders`,
  [`problemi finanziari`]: `Financial problems`,
  [`problemi ginecologici`]: `Gynecological disorders`,
  [`problemi relazionali`]: `Relationship problems`,
  [`problemi sociali`]: `Social life problems`,
  [`Problemi tiroidei`]: `Thyroid problems`,
  [`Problemi valvole cardiache`]: `Heart valve disease`,
  [`Professionistico/agonistico`]: `Professional`,
  [`Progressive Isoinertial Lifting Evaluation (PILE)`]: `Progressive Isoinertial Lifting Evaluation (PILE)`,
  [`Prolasso del disco lombare`]: `Lumbar disc prolapse`,
  [`prolasso discale toracico,`]: `Thoracic disc prolapse`,
  [`Prolasso uterino`]: `Uterine prolapse`,
  [`Prolasso/protrusione/ernia del disco a livello cervicale`]: `Cervical Disc Prolapse`,
  [`Pronated Tricep Extension`]: `Pronated Tricep Extension`,
  [`Pronato`]: `Pronated`,
  [`Pronator Drift Test`]: `Pronator Drift Test`,
  [`Pronazione`]: `Pronation`,
  [`Pronazione avambraccio (DX)`]: `Forearm pronation (R)`,
  [`Pronazione avambraccio (SX)`]: `Forearm pronation (L)`,
  [`Prone Bridge Test (Plank)`]: `Prone Bridge Test (Plank)`,
  [`Prone Knee Bend Test (PKB) (DX)`]: `Prone Knee Bend Test (PKB) (R)`,
  [`Prone Knee Bend Test (PKB) (SX)`]: `Prone Knee Bend Test (PKB) (L)`,
  [`Prone Scap Retraction`]: `Prone Scap Retraction`,
  [`Prostata`]: `Prostate  `,
  [`PROSTATA`]: `PROSTATE`,
  [`Prostatite`]: `Prostatitis`,
  [`Protesi articolari`]: `Joint replacement`,
  [`Protrazione`]: `Protraction`,
  [`Protrusione`]: `Protrusion`,
  [`Protrusione mandibola`]: `Mandible protrusion`,
  [`provo dolore in ogni movimento`]: `I feel pain in any movement I do`,
  [`Provo quasi sempre mal di testa`]: `I have headaches almost all the time`,
  [`Provo un mal di testa di media intensita' che insorge frequentemente`]: `I have moderate headaches, which come frequently`,
  [`Provo un mal di testa di media intensita' che insorge raramente`]: `I have moderate headaches, which come infrequently`,
  [`Provo un mal di testa lieve che insorge raramente`]: `I have slight headaches, which come infrequently`,
  [`Provo un mal di testa molto forte che insorge frequentemente`]: `I have severe headaches, which come frequently`,
  [`Pruriginoso`]: `Itchy`,
  [`PRWE (POLSO)`]: `PRWE (PATIENT RATED WRIST EVALUATION)`,
  [`Pseudoartrosi da frattura da stress del navicolare`]: `Non union navicular stress fracture`,
  [`Pseudoartrosi dello scafoide`]: `Non union fractured scaphoid`,
  [`Pseudogotta`]: `Pseudogout`,
  [`Pseudogotta al ginocchio`]: `Pseudogout in knee`,
  [`Pseudogotta in altre articolazioni/localita'`]: `Pseudogout in other joint/location`,
  [`Psichiatrico/psicologico`]: `Psychiatric/psychological`,
  [`Psicologici`]: `Psychologic`,
  [`Psioriasi`]: `Psoriasis`,
  [`Psoas Ball Kb Press`]: `Psoas Ball KB Press`,
  [`Psoriasi`]: `Psoriasis`,
  [`Pterion (DX)`]: `Pterion (R)`,
  [`Pterion (SX)`]: `Pterion (L)`,
  [`PTERION DESTRO`]: `RIGHT PTERION`,
  [`PTERION SINISTRO`]: `LEFT PTERION`,
  [`Pube (DX)`]: `Pubis (R)`,
  [`Pube (SX)`]: `Pubis (L)`,
  [`PUBE DESTRO`]: `RIGHT PUBIS`,
  [`PUBE DESTRO (RAMO PUBICO INFERIORE)`]: `RIGHT PUBIS (INFERIOR PUBIC RAMUS)`,
  [`PUBE DESTRO (RAMO PUBICO SUPERIORE)`]: `RIGHT PUBIS (SUPERIOR PUBIC RAMUS)`,
  [`PUBE DESTRO (TUBERCOLO PUBICO)`]: `RIGHT PUBIS (PUBIC TUBERCLE)`,
  [`PUBE SINISTRO`]: `LEFT PUBIS`,
  [`PUBE SINISTRO (RAMO PUBICO INFERIORE)`]: `LEFT PUBIS (INFERIOR PUBIC RAMUS)`,
  [`PUBE SINISTRO (RAMO PUBICO SUPERIORE)`]: `LEFT PUBIS (SUPERIOR PUBIC RAMUS)`,
  [`PUBE SINISTRO (TUBERCOLO PUBICO)`]: `LEFT PUBIS (PUBIC TUBERCLE)`,
  [`Pubic Compression Test (DX)`]: `Pubic Compression Test (R)`,
  [`Pubic Compression Test (SX)`]: `Pubic Compression Test (L)`,
  [`Pull Up`]: `Pull up`,
  [`Pull Up X`]: `Pull Up X`,
  [`Pull-Up Calisthenic Type Strength Test`]: `Pull-Up Calisthenic Type Strength Test`,
  [`Pulling (DX)`]: `Pulling (R)`,
  [`Pulling (SX)`]: `Pulling (L)`,
  [`Pulsante`]: `Pulsing`,
  [`Pulsazioni dolorose addominali, al collo o ovunque nel corpo`]: `Throbbing sensation/pain in belly or anywhere else`,
  [`Pungente`]: `Stinging`,
  [`Punitivo`]: `Punishing`,
  [`Punteggio`]: `Score`,
  [`Puoi descrivere il meccanismo traumatico ?`]: `Could you describe the injury mechanism?`,
  [`Puoi descrivere il meccanismo traumatico?`]: `Could you describe the injury mechanism?`,
  [`Puoi descrivere il tipo di attivita' svolta in eccesso?`]: `Can you describe the excessive activity performed?`,
  [`Puoi descrivere l'attivita' che pensi abbia provocato il dolore`]: `Could you describe the activity you think caused your pain?`,
  [`Puoi localizzare esattamente il tu dolore?`]: `Can you exactly point your pain?`,
  [`Puoi localizzare esattamente il tuo dolore e indicarlo con il dito o con la mano?`]: `Could you localise your pain and point it with your fingers or your hand?`,
  [`Puoi localizzare esattamente il tuo dolore o il dolore e' diffuso?`]: `Could you exactly localise your pain or is it diffuse?`,
  [`Puoi localizzare esattamente il tuo dolore?`]: `Can you point your pain exactly?`,
  [`Puoi localizzare il tuo dolore in un punto o in un area ben definita?`]: `Could you exactly localise your pain on a specific spot?`,
  [`Puoi percepire dei click articolari alla mandibola?`]: `Do you feel clicking on the temporo-mandibular joint?`,
  [`Puoi trovare una causa chiara di esordio del tuo dolore ovvero cosa pensi lo abbia scaturito?`]: `Can you find an obvious onset cause for your pain?`,
  [`Puoi trovare una causa chiara di esordio di dolore?`]: `Can you find an obvious onset cause for your pain?`,
  [`Puoi trovare una chiara causa di esordio del tuo dolore ovvero cosa pensi lo abbia scaturito?`]: `Can you find an obvious onset cause for your pain?`,
  [`Puoi trovare una chiara causa di esordio per il tuo dolore al piede?`]: `Could you find an obvious cause for your foot pain?`,
  [`Puranen-Orava Test (DX)`]: `Puranen-Orava Test (R)`,
  [`Puranen-Orava Test (SX)`]: `Puranen-Orava Test (L)`,
  [`Puraven-Ovara Test (DX)`]: `Puraven-Ovara Test (R)`,
  [`Puraven-Ovara Test (SX)`]: `Puraven-Ovara Test (L)`,
  [`Push Press`]: `Push Press`,
  [`Push Up`]: `PUSH UP`,
  [`Push Up Alternato Con Elastico`]: `ALTERNATING PAUSE BAND PUNCH`,
  [`Push Up Alternato Con Elastico Marciato`]: `MARCHING ALTERNATING BAND PUNCH`,
  [`Push Up Alternato Continuo Con Elastico`]: `ALTERNATING BAND PUNCH`,
  [`Push Up Close Grip`]: `PUSH UP CLOSE GRIP`,
  [`Push Up Con Affondo Simultaneo Con Elastico`]: `LUNGE BILATERAL BAND PUNC`,
  [`Push Up Drop`]: `Push Up Drop`,
  [`Push Up Plank Sl Raise`]: `Push Up Plank SL Raise`,
  [`Push Up Scapula Shrug With Bands`]: `Push Up Scapula Shrug With Bands`,
  [`Push Up Simultaneo Con Elastico`]: `PUSH SIMULTANEO ELASTICO ELASTICO`,
  [`Push Up Simultaneo Con Elastico Monopodalico`]: `SINGLE LEG BILATERAL BAND PUNCH KNEE DRIVE`,
  [`Push Up Simultaneo Con Elastico Passo Aperto`]: `SPLIT STANCE BILATERAL BAND PUNCH`,
  [`Push Up Stretch`]: `Push Up Stretch`,
  [`Push Up Stretch With Twist`]: `Push Up Stretch With Twist`,
  [`Push Up Twist`]: `Push Up Twist`,
  [`Push Up Wide Grip`]: `PUSH UP WIDE GRIP`,
  [`Push-Up Calisthenic Type Strength Test`]: `Push-Up Calisthenic Type Strength Test`,
  [`Pushing (DX)`]: `Pushing (R)`,
  [`Pushing (SX)`]: `Pushing (L)`,
  [`Q-Angle Measurement (DX)`]: `Q-Angle Measurement (R)`,
  [`Q-Angle Measurement (SX)`]: `Q-Angle Measurement (L)`,
  [`Qual è il livello di fitness che vorresti raggiungere?`]: `What level of fitness would you like to achieve?`,
  [`Qual è il recupero?`]: `Rate your recovery`,
  [`Qual è il tuo attuale livello di fitness?`]: `What is your current fitness level?`,
  [`Qual è il tuo livello di pratica sportiva?`]: `What is your level of sports practice?`,
  [`Qual è la condizione?`]: `What is your actual condition?`,
  [`Qual è la richiesta funzionale, ovvero il livello di fitness che vorresti raggiungere?`]: `What is the level of fitness you would like to achieve?`,
  [`Qual è la rigidità del suo ginocchio, appena svegliato la mattina?`]: `How severe is your knee joint stiffness after first wakening in the morning?`,
  [`Qual è la sua condizione di recupero?`]: `How would you rate your recovery?`,
  [`Qual è la sua situazione?`]: `What is your actual status`,
  [`Qual è stata la rigidità del suo ginocchio quando è seduto, sdraiato o a riposo, nel corso dell'ultima giornata?`]: `How severe is your knee stiffness after sitting, lying or resting later in the day?`,
  [`Qualche volta`]: `Sometimes`,
  [`Qualcuno dei seguenti fattori allevia il tuo dolore?`]: `What kinds of things makes the symptoms better?`,
  [`Quale è stata l'intensità del dolore che ha provato all'anca nell'ultima settimana durante le seguenti attività?`]: `What amount of hip pain have you experienced the last week during the following activities?`,
  [`Quale e' la percezione dello sforzo su una scala da 0 a 10 dove 0 rappresenta "nessuno sforzo" e 10 "maggior sforzo sostenibile"?`]: `How would you rate the effort on a scale from 0 to 10 where 0 means (no effort" and 10 means "the maximum effort"?`,
  [`Quale e' la sua condizione di recupero?`]: `How would you rate your recovery?`,
  [`Quale e' stato il livello di dolore medio percepito in riferimento a ieri?`]: `What was the average perceived pain (in reference to yesterday)?`,
  [`Quale e' stato il livello di peggior dolore percepito in riferimento a ieri?`]: `What was the worst perceived pain (in reference to yesterday)?`,
  [`Quale parola descrive il peggior mal di denti che ha avuto?`]: `Which word describes the worst toothache
you ever had?`,
  [`Quale parola descrive il peggior mal di stomaco che ha avuto?`]: `Which word describes the worst stomachache you ever had?`,
  [`Quale parola descrive il peggior mal di testa che ha avuto?`]: `Which word describes the worst headache yo
ever had? `,
  [`Quale parola descrive il tuo dolore ora?`]: `Which word describes your pain right now?`,
  [`Quale parola lo descrive al suo massimo?`]: `Which word describes it at its worst?`,
  [`Quale parola lo descrive al suo minimo?`]: `Which word describes it when it is least?`,
  [`Qualità del sonno`]: `Sleep quality`,
  [`Qualità della vita`]: `Quality of life`,
  [`Quando camminava`]: `When walking?`,
  [`Quando camminava all'aperto`]: `When walking outside?`,
  [`Quando camminava in casa`]: `When walking in the house?`,
  [`Quando camminava per 500 metri`]: `When walking four blocks?`,
  [`Quando correva o camminava velocemente`]: `When running or fast walking?`,
  [`Quando è disteso sul lato interessato è a suo agio per quanto riguarda la spalla ed il braccio?`]: `1. Is your shoulder comfortable with your arm at rest by your side?`,
  [`Quando è disteso sul lato interessato?`]: `When lying on the involved side?`,
  [`Quando e' esordito il tuo dolore?`]: `When did your pain onset?`,
  [`Quando e' iniziato il dolore (piu' o meno riconducilo ad una data)`]: `When did the pain begin?`,
  [`Quando fa più male`]: `When it is at its worst`,
  [`Quando hai mal di testa, quanto spesso il dolore e' forte?`]: `When you have headaches, how often is the pain severe?`,
  [`Quando hai mal di testa, quanto spesso vorresti poterti sdraiare?`]: `When you have a headache, how often do you wish you could lie down?`,
  [`Quando prova a raggiungere qualcosa su uno scaffale alto?`]: `Reaching for something on a high shelf?`,
  [`Quando saliva le scale`]: `When climbing stairs?`,
  [`Quando scendeva le scale`]: `When descending stairs?`,
  [`Quando si rialzava da una sedia`]: `When getting up from a chair?`,
  [`Quando si tocca la nuca?`]: `Touching the back of your neck?`,
  [`Quando spinge con il braccio interessato?`]: `Pushing with the involved arm?`,
  [`Quando stava in piedi`]: `When standing?`,
  [`Quando superava un ostacolo di 20cm`]: `When climbing curbs?`,
  [`Quanta difficoltà ha avuto:`]: `Answer all of the following questions related to your pain and activities over the past WEEK. How much difficulty did you have?`,
  [`Quante difficoltà riscontra nell'eseguire le seguenti attività:`]: `Activities of daily living subscale: Because of your foot and ankle, how much difficulty do you have with:`,
  [`Quante volte prova dolore all'anca e/o all'inguine?`]: `How often is your hip and/or groin painful?`,
  [`Quanti saltelli su una gamba può fare senza aver dolore?`]: `How many single leg hops can you do without pain?`,
  [`Quanto dolore fisico ha provato nelle ultime quattro settimane?`]: `How much bodily pain have you had during the past 4 weeks?`,
  [`Quanto è preoccupato a causa della mancanza di sicurezza del suo ginocchio?`]: `How much are you troubled with lack of confidence in your knee?`,
  [`Quanto è preoccupato dalla mancanza di sicurezza della sua anca?`]: `How much are you troubled with lack of confidence in your hip?`,
  [`Quanto è stato severo il dolore al piede:`]: `For the following questions, we would like you to score each question on a scale from 0 (no pain) to 10 (worst pain imaginable) that best describes your foot over the past WEEK`,
  [`Quanto frequentemente fa male l'anca?`]: `How often is your hip painful?`,
  [`Quanto intensa è la rigidità della sua anca dopo essere stato seduto, disteso o aver riposato nel corso della giornata?`]: `How severe is your hip stiffness after sitting, lying or resting later in the day?`,
  [`Quanto intensa è la rigidità della sua anca subito dopo il risveglio al mattino?`]: `How severe is your hip joint stiffness after first wakening in the morning?`,
  [`Quanto spesso avverte dolore in aree del corpo diverse dall'anca e/o dall'inguine che ritiene comunque possano essere una conseguenza dei suoi problemi all'anca e/o all'inguine?`]: `How often do you have pain in areas other than your hip and/or groin that you think may be related to your hip and/or groin problem?`,
  [`Quanto spesso avverte dolore?`]: `How often do you have pain?`,
  [`Quanto spesso il mal di testa limita la tua capacita' di svolgere le attivita' quotidiane abituali, compresi I lavori di casa, il lavoro, lo studio o le attivita' con gli altri?`]: `How often do headaches limit your ability to do usual daily activities including household
work, work, school, or social activities?`,
  [`Quanto spesso lei è consapevole del suo problema all'anca e/o all'inguine?`]: `How often are you aware of your hip and/or groin problem?`,
  [`Quanto spesso ti rendi conto di avere problemi al ginocchio?`]: `How often are you aware of your knee problem?`,
  [`Quarantena per viaggio o contatto`]: `Quarantine due to overseas travel or contact tracing`,
  [`Quarantine due to overseas travel or contact tracing Quarantena`]: `Quarantine due to overseas travel or contact tracing`,
  [`Quasi mai`]: `A little of the time`,
  [`Quasi normale`]: `Nearly normal`,
  [`Quasi sempre`]: `Most of the time`,
  [`Questo questionario ci sarà utile per effettuare una valutazione del suo problema a livello della sua anca e/o della sua regione inguinale. Le risposte debbono essere inerenti alla funzionalità della sua anca e/o della zona inguinale durante la settima...`]: `This questionnaire asks for your view about your hip and/or groin problem. The questions should be answered considering your hip and/or groin function during the past week. This information will help us keep track of how you feel, and how well you are able to do your usual activities.
    Answer every question by checking the appropriate box. Check only one box for each question. If a question does not pertain to you or you have not experienced it in the past week please make your “best guess” as to which response would be the most accurate.`,
  [`Questo questionario ci sarà utile per effettuare una valutazione del suo problema a livello della sua anca e/o della sua regione inguinale. Le risposte debbono essere inerenti alla funzionalità della sua anca e/o della zona inguinale durante la settimana precedente alla formulazione del suddetto questionario. Queste informazioni ci aiuteranno a valutare il suo stato di salute e ci permetteranno di capire come lei sia in grado di svolgere le sue attività di vita quotidiana. La preghiamo pertanto di rispondere ad ogni domanda barrando l'apposita casella. Occorre barrare una sola casella per domanda. Se una domanda non riguarda una situazione che la coinvolge personalmente, oppure ciò che è stato richiesto non si è verificato nella scorsa settimana, la preghiamo di esprimere la sua "ipotesi migliore", in modo da avere comunque una valutazione la più accurata possibile in rapporto alla domanda formulata.`]: `This questionnaire asks for your view about your hip and/or groin problem. The questions should be answered considering your hip and/or groin function during the past week. This information will help us keep track of how you feel, and how well you are able to do your usual activities.
Answer every question by checking the appropriate box. Check only one box for each question. If a question does not pertain to you or you have not experienced it in the past week please make your “best guess” as to which response would be the most accurate.`,
  [`Questo questionario è stato predisposto per conoscere il suo parere sullo stato della sua anca. Queste informazioni possono aiutarci a comprendere cosa lei pensa del suo disturbo e quanto lei sia in grado di compiere le comuni attività della vita quoti...`]: `This survey asks for your view about your hip. This information will help us keep track of how you feel about your hip and how well you are able to do your usual activities. Answer every question by ticking the appropriate box. If you are unsure about how`,
  [`Questo questionario è stato predisposto per conoscere il suo parere sullo stato della sua anca. Queste informazioni possono aiutarci a comprendere cosa lei pensa del suo disturbo e quanto lei sia in grado di compiere le comuni attività della vita quotidiana. Risponda a ciascuna domanda, mettendo una crocetta nella casella corrispondente (solo una risposta per ciascuna domanda). Se nessuna delle risposte dovesse sembrarle appropriata, scelga quella che più si avvicina alla sua situazione`]: `This survey asks for your view about your hip. This information will help us keep track of how you feel about your hip and how well you are able to do your usual activities. Answer every question by ticking the appropriate box. If you are unsure about how`,
  [`Questo questionario e' stato creato per darci delle informazioni su quanto i suoi problemi alla schiena (o alla gamba) influenzino la sua capacità di cavarsela nella vita di tutti i giorni. La preghiamo di rispondere a tutte le domande del questionario. Faccia una crocetta su una sola casella per ciascuna domanda, scegliendo quella che più si avvicina a come si sente oggi`]: `This questionnaire has been designed to give us information as to how your back or leg pain is affecting your ability to manage in everyday life. Please answer by placing a () mark in each section for the statement which best applies to you. We realize you may consider that two or more statements in any one section apply but please just check the box that indicates that statement which most clearly describes your problem`,
  [`Questo questionario e' stato creato per darci delle informazioni su quanto i suoi problemi alla schiena (o alla gamba) influenzino la sua capacità di cavarsela nella vita di tutti i giorni. La preghiamo di rispondere a tutte le domande del questionario....`]: `This questionnaire has been designed to give us information as to how your back or leg pain is affecting your ability to manage in everyday life. Please answer by placing a () mark in each section for the statement which best applies to you. We realize you may consider that two or more statements in any one section apply but please just check the box that indicates that statement which most clearly describes your problem`,
  [`Questo questionario e' stato creato per farti capire in che modo il dolore che provi al collo condizioni le tue capacita' di gestire le attivita' della vita quotidiana. Rispondi ad ogni sezione cercando LA RISPOSTA che giudichi piu' pertinente. CERCHIA S...`]: `This questionnaire has been designed to give us information as to how your neck pain is affecting your ability to manage in everyday life. Please answer by placing a check mark in each section for the statement which best applies to you. We realize you may consider that two or more statements in any one section apply but please just check the box that indicates the statement which most clearly describes your problem`,
  [`Questo questionario e' stato creato per farti capire in che modo il dolore che provi al collo condizioni le tue capacita' di gestire le attivita' della vita quotidiana. Rispondi ad ogni sezione cercando LA RISPOSTA che giudichi piu' pertinente. CERCHIA SOLO LA RISPOSTA CHE DESCRIVE MAGGIORMENTE IL TUO PROBLEMA IN QUESTO MOMENTO`]: `This questionnaire has been designed to give us information as to how your neck pain is affecting your ability to manage in everyday life. Please answer by placing a check mark in each section for the statement which best applies to you. We realize you may consider that two or more statements in any one section apply but please just check the box that indicates the statement which most clearly describes your problem`,
  [`Questo test e' stato ideato per fornire al suo curante informazioni riguardo il modo in cui il dolore al piede ha influenzato la sua capacita' di gestire le attivita' di vita quotidiana. La preghiamo di rispondere ad ogni ad ogni domanda, assegnando a ci...`]: `This questionnaire has been designed to give information as to how your foot pain has affected your ability to manage in every day life. Please read each question and choose a number from 0-10 on the corresponding scale.`,
  [`Questo test e' stato ideato per fornire al suo curante informazioni riguardo il modo in cui il dolore al piede ha influenzato la sua capacita' di gestire le attivita' di vita quotidiana. La preghiamo di rispondere ad ogni ad ogni domanda, assegnando a ciascuna di esse un punteggio da 0 a 10. Il punteggio 0 corrisponde a "nessun dolore" o "nessuna difficolta'" o "mai". Il punteggio 10 corrisponde al "peggior dolore immaginabile" o "una difficolta' tale da dover chiedere aiuto" o "sempre". Il punteggio totalizzato descrive lo stato di salute del suo piede durante la scorsa settimana (ultimi 7 giorni). Si prega di leggere ogni domanda e di inserire un segno lungo la linea`]: `This questionnaire has been designed to give information as to how your foot pain has affected your ability to manage in every day life. Please read each question and choose a number from 0-10 on the corresponding scale.`,
  [`QUICK-DASH (BRACCIO, SPALLA, MANO)`]: `QUICK-DASH (QUICK-DISABILITIES OF THE ARM SHULDER AND HAND SCORE)`,
  [`Quotidianamente`]: `Daily`,
  [`R.L. Martin, J.J. Irrgang, R.G. Burdett, S.F. Conti, J.M. Van Swearingen. Evidence of validity for the Foot and Ankle Ability Measure (FAAM). Foot Ankle Int, 26 (11) (2005), pp. 968–983`]: `R.L. Martin, J.J. Irrgang, R.G. Burdett, S.F. Conti, J.M. Van Swearingen. Evidence of validity for the Foot and Ankle Ability Measure (FAAM). Foot Ankle Int, 26 (11) (2005), pp. 968–983`,
  [`Rabdomiolisi`]: `Rhabdomyolysis`,
  [`Radiante`]: `Radiating`,
  [`RADICE DEL MESENTERE`]: `ROOT OF MESENTERY`,
  [`RADICE LATERALE DEL NERVO MEDIANO DESTRO`]: `LATERAL ROOT OF RIGHT MEDIAN NERVE`,
  [`RADICE LATERALE DEL NERVO MEDIANO SINISTRO`]: `LATERAL ROOT OF LEFT MEDIAN NERVE`,
  [`RADICE MEDIALE DEL NERVO MEDIANO DESTRO`]: `MEDIAL ROOT OF RIGHT MEDIAN NERVE`,
  [`RADICE MEDIALE DEL NERVO MEDIANO SINISTRO`]: `MEDIAL ROOT OF LEFT MEDIAN NERVE`,
  [`RADICE SPINALE DEL NERVO ACCESSORIO DESTRO`]: `SPINAL ROOT OF RIGHT ACCESSORY NERVE [XI]`,
  [`RADICE SPINALE DEL NERVO ACCESSORIO SINISTRO`]: `SPINAL ROOT OF LEFT ACCESSORY NERVE [XI]`,
  [`RADICI DEL PLESSO BRACHIALE DESTRO`]: `ROOTS OF RIGHT BRACHIAL PLEXUS`,
  [`RADICI DEL PLESSO BRACHIALE SINISTRO`]: `ROOTS OF LEFT BRACHIAL PLEXUS`,
  [`radicolopatia cervicale`]: `Cervical radiculopathy`,
  [`radicolopatia lombare`]: `Lumbar radiculopathy`,
  [`Radio (DX)`]: `Radius (R)`,
  [`Radio (SX)`]: `Radius (L)`,
  [`Radio (testa del radio) (DX)`]: `Radius (head of radius) (R)`,
  [`Radio (testa del radio) (SX)`]: `Radius (head of radius) (L)`,
  [`RADIO DESTRO`]: `RIGHT RADIUS`,
  [`RADIO DESTRO (COLLO)`]: `RIGHT RADIUS (NECK)`,
  [`RADIO DESTRO (DIAFISI)`]: `RIGHT RADIUS (SHAFT)`,
  [`RADIO DESTRO (PROCESSO STILOIDEO)`]: `RIGHT RADIUS (STYLOID PROCESS)`,
  [`RADIO DESTRO (TESTA DEL RADIO)`]: `RIGHT RADIUS (HEAD OF RADIUS)`,
  [`RADIO SINISTRO`]: `LEFT RADIUS`,
  [`RADIO SINISTRO (COLLO)`]: `LEFT RADIUS (NECK)`,
  [`RADIO SINISTRO (DIAFISI)`]: `LEFT RADIUS (SHAFT)`,
  [`RADIO SINISTRO (PROCESSO STILOIDEO)`]: `LEFT RADIUS (STYLOID PROCESS)`,
  [`RADIO SINISTRO (TESTA DEL RADIO)`]: `LEFT RADIUS (HEAD OF RADIUS)`,
  [`Radulescu Sign (DX)`]: `Radulescu Sign (R)`,
  [`Radulescu Sign (SX)`]: `Radulescu Sign (L)`,
  [`Raffreddore o influenza`]: `Recurrent colds or flu`,
  [`RAMI CALCANEALI LATERALI DI DESTRA`]: `RIGHT LATERAL CALCANEAL BRANCHES`,
  [`RAMI CALCANEALI LATERALI DI SINISTRA`]: `LEFT LATERAL CALCANEAL BRANCHES`,
  [`RAMI CALCANEALI MEDIALI DI DESTRA`]: `RIGHT MEDIAL CALCANEAL BRANCHES`,
  [`RAMI CALCANEALI MEDIALI DI SINISTRA`]: `LEFT MEDIAL CALCANEAL BRANCHES`,
  [`RAMO ANTERIORE DEL I NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT I CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL I NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF RIGHT I CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL I NERVO COCCIGEO DESTRO`]: `ANTERIOR RAMI OF RIGHT I COCCYGEAL NERVE`,
  [`RAMO ANTERIORE DEL I NERVO COCCIGEO SINISTRO`]: `ANTERIOR RAMI OF LEFT I COCCYGEAL NERVE`,
  [`RAMO ANTERIORE DEL I NERVO LOMBARE DESTRO`]: `ANTERIOR RAMI OF RIGHT I LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL I NERVO LOMBARE SINISTRO`]: `ANTERIOR RAMI OF LEFT I LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL I NERVO SACRALE DESTRO`]: `ANTERIOR RAMI OF RIGHT I SACRAL NERVE`,
  [`RAMO ANTERIORE DEL I NERVO SACRALE SINISTRO`]: `ANTERIOR RAMI OF LEFT I SACRAL NERVE`,
  [`RAMO ANTERIORE DEL II NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT II CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL II NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF LEFT II CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL II NERVO COCCIGEO DESTRO`]: `ANTERIOR RAMI OF RIGHT II COCCYGEAL NERVE`,
  [`RAMO ANTERIORE DEL II NERVO COCCIGEO SINISTRO`]: `ANTERIOR RAMI OF LEFT II COCCYGEAL NERVE`,
  [`RAMO ANTERIORE DEL II NERVO LOMBARE DESTRO`]: `ANTERIOR RAMI OF RIGHT II LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL II NERVO LOMBARE SINISTRO`]: `ANTERIOR RAMI OF LEFT II LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL II NERVO SACRALE DESTRO`]: `ANTERIOR RAMI OF RIGHT II SACRAL NERVE`,
  [`RAMO ANTERIORE DEL II NERVO SACRALE SINISTRO`]: `ANTERIOR RAMI OF LEFT II SACRAL NERVE`,
  [`RAMO ANTERIORE DEL III NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT III CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL III NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF LEFT III CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL III NERVO COCCIGEO DESTRO`]: `ANTERIOR RAMI OF RIGHT III COCCYGEAL NERVE`,
  [`RAMO ANTERIORE DEL III NERVO COCCIGEO SINISTRO`]: `ANTERIOR RAMI OF LEFT III COCCYGEAL NERVE`,
  [`RAMO ANTERIORE DEL III NERVO LOMBARE DESTRO`]: `ANTERIOR RAMI OF RIGHT III LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL III NERVO LOMBARE SINISTRO`]: `ANTERIOR RAMI OF LEFT III LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL III NERVO SACRALE DESTRO`]: `ANTERIOR RAMI OF RIGHT III SACRAL NERVE`,
  [`RAMO ANTERIORE DEL III NERVO SACRALE SINISTRO`]: `ANTERIOR RAMI OF LEFT III SACRAL NERVE`,
  [`RAMO ANTERIORE DEL IV NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT IV CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL IV NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF LEFT IV CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL IV NERVO LOMBARE DESTRO`]: `ANTERIOR RAMI OF RIGHT IV LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL IV NERVO LOMBARE SINISTRO`]: `ANTERIOR RAMI OF LEFT IV LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL IV NERVO SACRALE DESTRO`]: `ANTERIOR RAMI OF RIGHT IV SACRAL NERVE`,
  [`RAMO ANTERIORE DEL IV NERVO SACRALE SINISTRO`]: `ANTERIOR RAMI OF LEFT IV SACRAL NERVE`,
  [`RAMO ANTERIORE DEL NERVO CUTANEO MEDIALE DELL'AVAMBRACCIO DESTRO`]: `ANTERIOR BRANCH OF MEDIAL CUTANEOUS NERVE OF RIGHT FOREARM`,
  [`RAMO ANTERIORE DEL NERVO CUTANEO MEDIALE DELL'AVAMBRACCIO SINISTRO`]: `ANTERIOR BRANCH OF MEDIAL CUTANEOUS NERVE OF LEFT FOREARM`,
  [`RAMO ANTERIORE DEL NERVO OTTURATORIO DESTRO`]: `ANTERIOR BRANCH OF RIGHT OBTURATOR NERVE`,
  [`RAMO ANTERIORE DEL NERVO OTTURATORIO SINISTRO`]: `ANTERIOR BRANCH OF LEFT OBTURATOR NERVE`,
  [`RAMO ANTERIORE DEL V NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT V CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL V NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF LEFT V CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL V NERVO LOMBARE DESTRO`]: `ANTERIOR RAMI OF RIGHT V LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL V NERVO LOMBARE SINISTRO`]: `ANTERIOR RAMI OF LEFT V LUMBAR NERVE`,
  [`RAMO ANTERIORE DEL V NERVO SACRALE DESTRO`]: `ANTERIOR RAMI OF RIGHT V SACRAL NERVE`,
  [`RAMO ANTERIORE DEL V NERVO SACRALE SINISTRO`]: `ANTERIOR RAMI OF LEFT V SACRAL NERVE`,
  [`RAMO ANTERIORE DEL VI NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT VI CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL VI NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF LEFT VI CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL VII NERVO CERVICALE DESTRO`]: `ANTERIOR RAMI OF RIGHT VII CERVICAL NERVE`,
  [`RAMO ANTERIORE DEL VII NERVO CERVICALE SINISTRO`]: `ANTERIOR RAMI OF LEFT VII CERVICAL NERVE`,
  [`RAMO CUTANEO ANTERIORE DEL NERVO ILEOIPOGASTRICO DESTRO`]: `ANTERIOR CUTANEOUS BRANCH OF RIGHT ILIOHYPOGASTRIC NERVE`,
  [`RAMO CUTANEO ANTERIORE DEL NERVO ILEOIPOGASTRICO SINISTRO`]: `ANTERIOR CUTANEOUS BRANCH OF LEFT ILIOHYPOGASTRIC NERVE`,
  [`RAMO CUTANEO LATERALE DEL NERVO ILEOIPOGASTRICO DESTRO`]: `LATERAL CUTANEOUS BRANCH OF RIGHT ILIOHYPOGASTRIC NERVE`,
  [`RAMO CUTANEO LATERALE DEL NERVO ILEOIPOGASTRICO SINISTRO`]: `LATERAL CUTANEOUS BRANCH OF LEFT ILIOHYPOGASTRIC NERVE`,
  [`RAMO DORSALE DEL NERVO ULNARE DESTRO`]: `POSTERIOR BRANCH OF RIGHT ULNAR NERVE`,
  [`RAMO DORSALE DEL NERVO ULNARE SINISTRO`]: `POSTERIOR BRANCH OF LEFT ULNAR NERVE`,
  [`RAMO FEMORALE DEL NERVO GENITOFEMORALE DESTRO`]: `FEMORAL BRANCH OF RIGHT GENITOFEMORAL NERVE`,
  [`RAMO FEMORALE DEL NERVO GENITOFEMORALE SINISTRO`]: `FEMORAL BRANCH OF LEFT GENITOFEMORAL NERVE`,
  [`RAMO GENITALE DEL NERVO GENITOFEMORALE DESTRO`]: `GENITAL BRANCH OF RIGHT GENITOFEMORAL NERVE`,
  [`RAMO GENITALE DEL NERVO GENITOFEMORALE SINISTRO`]: `GENITAL BRANCH OF LEFT GENITOFEMORAL NERVE`,
  [`RAMO INFRAROTULEO DEL NERVO SAFENO DESTRO`]: `INFRAPATELLAR BRANCH OF RIGHT SAPHENOUS NERVE`,
  [`RAMO INFRAROTULEO DEL NERVO SAFENO SINISTRO`]: `INFRAPATELLAR BRANCH OF LEFT SAPHENOUS NERVE`,
  [`RAMO PALMARE DEL NERVO MEDIANO DESTRO`]: `PALMAR BRANCH OF RIGHT MEDIAN NERVE`,
  [`RAMO PALMARE DEL NERVO MEDIANO SINISTRO`]: `PALMAR BRANCH OF LEFT MEDIAN NERVE`,
  [`RAMO PALMARE DEL NERVO ULNARE DESTRO`]: `PALMAR BRANCH OF RIGHT ULNAR NERVE`,
  [`RAMO PALMARE DEL NERVO ULNARE SINISTRO`]: `PALMAR BRANCH OF LEFT ULNAR NERVE`,
  [`RAMO POSTERIORE DEL I NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT I CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL I NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT I CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL I NERVO COCCIGEO DESTRO`]: `POSTERIOR RAMI OF RIGHT I COCCYGEAL NERVE`,
  [`RAMO POSTERIORE DEL I NERVO COCCIGEO SINISTRO`]: `POSTERIOR RAMI OF LEFT I COCCYGEAL NERVE`,
  [`RAMO POSTERIORE DEL I NERVO LOMBARE DESTRO`]: `POSTERIOR RAMI OF RIGHT I LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL I NERVO LOMBARE SINISTRO`]: `POSTERIOR RAMI OF LEFT I LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL I NERVO SACRALE DESTRO`]: `POSTERIOR RAMI OF RIGHT I SACRAL NERVE`,
  [`RAMO POSTERIORE DEL I NERVO SACRALE SINISTRO`]: `POSTERIOR RAMI OF LEFT I SACRAL NERVE`,
  [`RAMO POSTERIORE DEL II NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT II CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL II NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT I ICERVICAL NERVE`,
  [`RAMO POSTERIORE DEL II NERVO COCCIGEO DESTRO`]: `POSTERIOR RAMI OF RIGHT II COCCYGEAL NERVE`,
  [`RAMO POSTERIORE DEL II NERVO COCCIGEO SINISTRO`]: `POSTERIOR RAMI OF LEFT II COCCYGEAL NERVE`,
  [`RAMO POSTERIORE DEL II NERVO LOMBARE DESTRO`]: `POSTERIOR RAMI OF RIGHT II LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL II NERVO LOMBARE SINISTRO`]: `POSTERIOR RAMI OF LEFT II LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL II NERVO SACRALE DESTRO`]: `POSTERIOR RAMI OF RIGHT II SACRAL NERVE`,
  [`RAMO POSTERIORE DEL II NERVO SACRALE SINISTRO`]: `POSTERIOR RAMI OF LEFT II SACRAL NERVE`,
  [`RAMO POSTERIORE DEL III NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT III CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL III NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT III CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL III NERVO COCCIGEO DESTRO`]: `POSTERIOR RAMI OF RIGHT III COCCYGEAL NERVE`,
  [`RAMO POSTERIORE DEL III NERVO COCCIGEO SINISTRO`]: `POSTERIOR RAMI OF LEFT III COCCYGEAL NERVE`,
  [`RAMO POSTERIORE DEL III NERVO LOMBARE DESTRO`]: `POSTERIOR RAMI OF RIGHT III LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL III NERVO LOMBARE SINISTRO`]: `POSTERIOR RAMI OF LEFT III LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL III NERVO SACRALE DESTRO`]: `POSTERIOR RAMI OF RIGHT III SACRAL NERVE`,
  [`RAMO POSTERIORE DEL III NERVO SACRALE SINISTRO`]: `POSTERIOR RAMI OF LEFT III SACRAL NERVE`,
  [`RAMO POSTERIORE DEL IV NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT IV CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL IV NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT IV CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL IV NERVO LOMBARE DESTRO`]: `POSTERIOR RAMI OF RIGHT IV LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL IV NERVO LOMBARE SINISTRO`]: `POSTERIOR RAMI OF LEFT IV LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL IV NERVO SACRALE DESTRO`]: `POSTERIOR RAMI OF RIGHT IV SACRAL NERVE`,
  [`RAMO POSTERIORE DEL IV NERVO SACRALE SINISTRO`]: `POSTERIOR RAMI OF LEFT IV SACRAL NERVE`,
  [`RAMO POSTERIORE DEL NERVO CUTANEO MEDIALE DELL'AVAMBRACCIO DESTRO`]: `POSTERIOR BRANCH OF MEDIAL CUTENEOUS NERVE OF RIGHT FOREARM`,
  [`RAMO POSTERIORE DEL NERVO CUTANEO MEDIALE DELL'AVAMBRACCIO SINISTRO`]: `POSTERIOR BRANCH OF MEDIAL CUTENEOUS NERVE OF LEFT FOREARM`,
  [`RAMO POSTERIORE DEL NERVO OTTURATORIO DESTRO`]: `POSTERIOR BRANCH OF RIGHT OBTURATOR NERVE`,
  [`RAMO POSTERIORE DEL NERVO OTTURATORIO SINISTRO`]: `POSTERIOR BRANCH OF LEFT OBTURATOR NERVE`,
  [`RAMO POSTERIORE DEL V NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT V CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL V NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT V CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL V NERVO LOMBARE DESTRO`]: `POSTERIOR RAMI OF RIGHT V LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL V NERVO LOMBARE SINISTRO`]: `POSTERIOR RAMI OF LEFT V LUMBAR NERVE`,
  [`RAMO POSTERIORE DEL V NERVO SACRALE DESTRO`]: `POSTERIOR RAMI OF RIGHT V SACRAL NERVE`,
  [`RAMO POSTERIORE DEL V NERVO SACRALE SINISTRO`]: `POSTERIOR RAMI OF LEFT V SACRAL NERVE`,
  [`RAMO POSTERIORE DEL VI NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT VI CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL VI NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT VI CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL VII NERVO CERVICALE DESTRO`]: `POSTERIOR RAMI OF RIGHT VII CERVICAL NERVE`,
  [`RAMO POSTERIORE DEL VII NERVO CERVICALE SINISTRO`]: `POSTERIOR RAMI OF LEFT VII CERVICAL NERVE`,
  [`RAMO POSTERIORE I NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT I THORACIC NERVE`,
  [`RAMO POSTERIORE I NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT I THORACIC NERVE`,
  [`RAMO POSTERIORE II NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT II THORACIC NERVE`,
  [`RAMO POSTERIORE II NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT II THORACIC NERVE`,
  [`RAMO POSTERIORE III NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT III THORACIC NERVE`,
  [`RAMO POSTERIORE III NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT III THORACIC NERVE`,
  [`RAMO POSTERIORE IV NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT IV THORACIC NERVE`,
  [`RAMO POSTERIORE IV NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT IV THORACIC NERVE`,
  [`RAMO POSTERIORE IX NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT IX THORACIC NERVE`,
  [`RAMO POSTERIORE IX NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT IX THORACIC NERVE`,
  [`RAMO POSTERIORE V NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT V THORACIC NERVE`,
  [`RAMO POSTERIORE V NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT V THORACIC NERVE`,
  [`RAMO POSTERIORE VI NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT VI THORACIC NERVE`,
  [`RAMO POSTERIORE VI NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT VI THORACIC NERVE`,
  [`RAMO POSTERIORE VII NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT VII THORACIC NERVE`,
  [`RAMO POSTERIORE VII NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT VII THORACIC NERVE`,
  [`RAMO POSTERIORE VIII NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT VIII THORACIC NERVE`,
  [`RAMO POSTERIORE VIII NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT VIII THORACIC NERVE`,
  [`RAMO POSTERIORE X NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT X THORACIC NERVE`,
  [`RAMO POSTERIORE X NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT X THORACIC NERVE`,
  [`RAMO POSTERIORE XI NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT XI THORACIC NERVE`,
  [`RAMO POSTERIORE XI NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT XI THORACIC NERVE`,
  [`RAMO POSTERIORE XII NERVO TORACICO DESTRO`]: `POSTERIOR RAMI OF RIGHT XII THORACIC NERVE`,
  [`RAMO POSTERIORE XII NERVO TORACICO SINISTRO`]: `POSTERIOR RAMI OF LEFT XII THORACIC NERVE`,
  [`RAMO PROFONDO DEL NERVO RADIALE DESTRO`]: `DEEP BRANCH OF RIGHT RADIAL NERVE`,
  [`RAMO PROFONDO DEL NERVO RADIALE SINISTRO`]: `DEEP BRANCH OF LEFT RADIAL NERVE`,
  [`RAMO SUPERFICIALE DEL NERVO RADIALE DESTRO`]: `SUPERFICIAL BRANCH OF RIGHT RADIAL NERVE`,
  [`RAMO SUPERFICIALE DEL NERVO RADIALE SINISTRO`]: `SUPERFICIAL BRANCH OF LEFT RADIAL NERVE`,
  [`Rapidi spostamenti laterali`]: `Cutting/lateral movements`,
  [`Rapporto sessuale`]: `intercourse`,
  [`Rappresenta un criterio per il ritorno all'attivita' dopo un inortunio agli hamstring`]: `Exit criteria after hamstring injury`,
  [`Raramente`]: `Rarely`,
  [`Rash cutanei`]: `Skin rash or other skin changes`,
  [`rash cutaneo`]: `Skin rash`,
  [`Rash/altre condizioni dermatologiche`]: `Rash /other dermatological condition`,
  [`Rdl Dumbbell`]: `RDL Dumbbell`,
  [`Rdl Romanian Dead Lift`]: `RDL romanian dead lift`,
  [`Reaching (DX)`]: `Reaching (R)`,
  [`Reaching (SX)`]: `Reaching (L)`,
  [`Rear Deltoid`]: `Rear Deltoid`,
  [`Reazione da stress del metatarso/metatarsalgia`]: `Stress reaction metatarsal/metatarsalgia`,
  [`Reazione da stress della colonna lombare`]: `Lumbar stress reaction`,
  [`RECESSO SUBPOPLITEO DESTRO`]: `RIGHT SUBPOPLITEAL RECESS`,
  [`RECESSO SUBPOPLITEO SINISTRO`]: `LEFT SUBPOPLITEAL RECESS`,
  [`Rectocele`]: `Rectocele`,
  [`Recurvato`]: `Recurvatum`,
  [`Red Flags`]: `Red Flags`,
  [`RED-S (Relative Energy Deficiency in Sport)`]: `RED-S (Relative Energy Deficiency in Sport)`,
  [`Referenze`]: `References`,
  [`Referral`]: `Referral`,
  [`Reflusso gastro-esofageo o ernia iatale`]: `GERD`,
  [`Reflusso gastroesofageo`]: `Gastroesophageal reflux disease (GERD)`,
  [`Regione Addominale`]: `Abdominal Region`,
  [`Regione Costale`]: `Chest Region`,
  [`Regione Del Braccio Destro`]: `Right Upper Arm Region`,
  [`Regione Del Braccio Sinistro`]: `Left Upper Arm Region`,
  [`Regione Del Collo`]: `Neck Region`,
  [`Regione Del Ginocchio Destro`]: `Right Knee Region`,
  [`Regione Del Ginocchio Sinistro`]: `Left Knee Region`,
  [`Regione Del Gomito Destro`]: `Right Elbow Region`,
  [`Regione Del Gomito Sinistro`]: `Left Elbow Region`,
  [`Regione Del Piede Destro`]: `Right Foot Region`,
  [`Regione Del Piede Sinistro`]: `Left Foot Region`,
  [`Regione Del Polso Destro`]: `Right Wrist Region`,
  [`Regione Del Polso Sinistro`]: `Right Hand Region`,
  [`Regione Dell'Anca Destra`]: `Right Hip Region`,
  [`Regione Dell'Anca Sinistra`]: `Left Hip Region`,
  [`Regione Dell'Avambraccio Destro`]: `Right Forearm Region`,
  [`Regione Dell'Avambraccio Sinistro`]: `Left Forearm Region`,
  [`Regione Della Caviglia Destra`]: `Right Ankle Region`,
  [`Regione Della Caviglia Sinistra`]: `Left Ankle Region`,
  [`Regione Della Coscia Destra`]: `Right Thigh Region`,
  [`Regione Della Coscia Sinistra`]: `Left Thigh Region`,
  [`Regione Della Gamba Destra`]: `Right Leg Region`,
  [`Regione Della Gamba Sinistra`]: `Left Leg Region`,
  [`Regione Della Mano Destra`]: `Left Wrist Region`,
  [`Regione Della Mano Sinistra`]: `Left Hand Region`,
  [`Regione Della Spalla Destra`]: `Right Shoulder Region`,
  [`Regione Della Spalla Sinistra`]: `Left Shoulder Region`,
  [`Regione Della Testa`]: `Head Region`,
  [`REGIONE LOMBO-SACRALE`]: `LUMBOSACRAL REGION`,
  [`Regione Lombosacrale`]: `Lumbosacral Region`,
  [`Regione Perineale`]: `Perineal Region`,
  [`Regione Toracica`]: `Thoracic Region`,
  [`Relativa a droghe/avvelenamento`]: `Drug-related/poisoning`,
  [`Relocation Test (DX)`]: `Relocation Test (R)`,
  [`Relocation Test (SX)`]: `Relocation Test (L)`,
  [`Rematore Con Elastico Gomiti Alti Alternato`]: `ALTERNATING BAND ROW`,
  [`Rematore Con Elastico Gomiti Alti Mono`]: `ALTERNATING PAUSE  BAND HIGH ROW`,
  [`Rematore Con Elastico Gomiti Stretti`]: `BAND CLOSE ROW`,
  [`Rematore Con Elastico Gomito Alto Presa Pronata`]: `SINGLE ARM PRONE GRIP HIGH ROW`,
  [`Rematore Con Elastico Monolaterale`]: `SINGLE ARM ROW`,
  [`Rematore Con Elastico Monolaterale Supinazione`]: `SINGLE ARM PRONE TO SUPINATED ROW`,
  [`Rematore Con Elastico Monopodalico`]: `SINGLE LEG ROW`,
  [`Rematore Orizzontale Con Elastico Bilaterale`]: `BENT OVER OH ROW`,
  [`Rematore Orizzontale Con Elastico Gomito Alto Monolaterale`]: `SINGLE ARM BENT OVER OH ROW`,
  [`Rematore Supinato Con Elastico Monolaterale`]: `SINGLE ARM SUPINATED ROW`,
  [`RENE DESTRO`]: `RIGHT KIDNEY`,
  [`RENE SINISTRO`]: `LEFT KIDNEY`,
  [`Reni`]: `Kidneys`,
  [`Rent Test (DX)`]: `Rent Test (R)`,
  [`Rent Test (SX)`]: `Rent Test (L)`,
  [`Repetitive Box-Lifting Task (RBLT)`]: `Repetitive Box-Lifting Task (RBLT)`,
  [`Resisted External Derotation Test (DX)`]: `Resisted External Derotation Test (R)`,
  [`Resisted External Derotation Test (SX)`]: `Resisted External Derotation Test (L)`,
  [`Resisted Straight Leg Raise Test (DX)`]: `Resisted Straight Leg Raise Test (R)`,
  [`Resisted Straight Leg Raise Test (SX)`]: `Resisted Straight Leg Raise Test (L)`,
  [`Respiratorio`]: `Respiratory`,
  [`Respiratory neoplasm`]: `Respiratory neoplasm`,
  [`Respiro Corto`]: `Shortness of breath`,
  [`RETINACOLO DEI MUSCOLI ESTENSORI DEL CARPO DI DESTRA`]: `RIGHT EXTENSOR CARPI RETINACULUM`,
  [`RETINACOLO DEI MUSCOLI ESTENSORI DEL CARPO DI SINISTRA`]: `LEFT EXTENSOR CARPI RETINACULUM`,
  [`RETINACOLO DEI MUSCOLI FLESSORI DEL CARPO DI DESTRA`]: `RIGHT FLEXOR CARPI RETINACULUM`,
  [`RETINACOLO DEI MUSCOLI FLESSORI DEL CARPO DI SINISTRA`]: `LEFT FLEXOR CARPI RETINACULUM`,
  [`RETINACOLO DEI MUSCOLI FLESSORI DEL TARSO DI DESTRA`]: `RIGHT FLEXOR RETINACULUM`,
  [`RETINACOLO DEI MUSCOLI FLESSORI DEL TARSO DI SINISTRA`]: `LEFT FLEXOR RETINACULUM`,
  [`RETINACOLO INFERIORE DEI MUSCOLI ESTENSORI DEL TARSO DI DESTRA`]: `LEFT INFERIOR EXTENSOR RETINACULUM`,
  [`RETINACOLO INFERIORE DEI MUSCOLI PERONIERI DI DESTRA`]: `RIGHT INFERIOR FIBULAR RETINACULUM`,
  [`RETINACOLO INFERIORE DEI MUSCOLI PERONIERI DI SINISTRA`]: `LEFT INFERIOR FIBULAR RETINACULUM`,
  [`Retinacolo rotuleo laterale (DX)`]: `Lateral patellar retinaculum (R)`,
  [`Retinacolo rotuleo laterale (SX)`]: `Lateral patellar retinaculum (L)`,
  [`RETINACOLO ROTULEO LATERALE DESTRO`]: `RIGHT LATERAL PATELLAR RETINACULUM`,
  [`RETINACOLO ROTULEO LATERALE SINISTRO`]: `LEFT LATERAL PATELLAR RETINACULUM`,
  [`Retinacolo rotuleo mediale (DX)`]: `Medial patellar retinaculum (R)`,
  [`Retinacolo rotuleo mediale (SX)`]: `Medial patellar retinaculum (L)`,
  [`RETINACOLO ROTULEO MEDIALE DESTRO`]: `RIGHT MEDIAL PATELLAR RETINACULUM`,
  [`RETINACOLO ROTULEO MEDIALE SINISTRO`]: `LEFT MEDIAL PATELLAR RETINACULUM`,
  [`RETINACOLO SUPERIORE DEI MUSCOLI ESTENSORI DEL TARSO DI DESTRA`]: `RIGHT SUPERIOR EXTENSOR RETINACULUM`,
  [`RETINACOLO SUPERIORE DEI MUSCOLI ESTENSORI DEL TARSO DI SINISTRA`]: `LEFT SUPERIOR EXTENSOR RETINACULUM`,
  [`RETINACOLO SUPERIORE DEI MUSCOLI PERONIERI DI DESTRA`]: `RIGHT SUPERIOR FIBULAR RETINACULUM`,
  [`RETINACOLO SUPERIORE DEI MUSCOLI PERONIERI DI SINISTRA`]: `LEFT SUPERIOR FIBULAR RETINACULUM`,
  [`Retrazione`]: `Retraction`,
  [`Retrazione della spalla (DX)`]: `Shoulder joint retraction (R)`,
  [`Retrazione della spalla (SX)`]: `Shoulder joint retraction (L)`,
  [`Retrolistesi della colonna lombare`]: `Retrolisthesis lumbar spine`,
  [`Retropiede valgo`]: `Rearfoot valgus`,
  [`Retropiede varo`]: `Rearfoot varus`,
  [`Retroversione femorale`]: `Femoral retroversion`,
  [`Retrusione`]: `Retrusion`,
  [`Retrusione mandibola`]: `Mandible retrusion`,
  [`RETTO`]: `RECTUM`,
  [`Retto e ano`]: `Rectum and anus`,
  [`Reumatologici`]: `Rheumatological`,
  [`Rev Grip Bent Over Row`]: `Rev Grip Bent Over Row`,
  [`Reverse Curl`]: `Reverse Curl`,
  [`Reverse Lachman Test (DX)`]: `Reverse Lachman Test (R)`,
  [`Reverse Lachman Test (SX)`]: `Reverse Lachman Test (L)`,
  [`Reverse Lung With Contralateral Shoulder Press`]: `Reverse Lung with Contralateral Shoulder Press`,
  [`Reverse Lunge With Contralateral One Arm Band Row`]: `Reverse Lunge with Contralateral One Arm Band Row`,
  [`Reverse Lunge With Contralateral One Arm Chest Press`]: `Reverse Lunge with Contralateral One Arm Chest Press`,
  [`Reverse Pivot-Shift Test (DX)`]: `Reverse Pivot-Shift Test (R)`,
  [`Reverse Pivot-Shift Test (SX)`]: `Reverse Pivot-Shift Test (L)`,
  [`Reverse Plank Kick`]: `REVERSE PLANK KICK`,
  [`Reverse Plank With Leg Raise`]: `Reverse Plank with leg raise`,
  [`Reverse Walking Toe Touch`]: `Reverse Walking Toe Touch`,
  [`Revisione dei sistemi e storia remota chirurgica`]: `Review of Systems`,
  [`Ri-rottura del legamento crociato anteriore (LCA) (rottura del neolegamento)`]: `ACL graft rupture`,
  [`Riabilitazione`]: `Rehabilitation`,
  [`Ricreazionale`]: `Recreational`,
  [`Riesce a mettere le mani dietro la testa con il gomito retto verso l'esterno?`]: `4. Can you place your hand behind your head with the elbow straight out to the side?`,
  [`Riesce a piegare il ginocchio completamente?`]: `Can you bend your knee fully?`,
  [`Riesce a posizionare una moneta su di uno scaffale situato a livello delle spalle senza piegare il gomito?`]: `5. Can you place a coin on a shelf at the level of your shoulder without bending your elbow?`,
  [`Riesce a trasportare con il braccio del lato interessato un peso pari a 9 chilogrammi?`]: `8. Can you carry 20 pounds at your side with the affected extremity?`,
  [`Riesce ad alzare un peso di 3,5 chilogrammi a livello delle spalle senza piegare il gomito?`]: `7. Can you lift eight pounds (a full gallon container) to the level of the top of your head without bending your elbow?`,
  [`Riesce ad alzare un peso di mezzo chilogrammo a livello delle spalle senza piegare il gomito?`]: `6. Can you lift one pound (a full pint container) to the level of your shoulder without bending your elbow?`,
  [`Riesce ad estendere il ginocchio completamente?`]: `Can you straighten your knee fully?`,
  [`Riesce ad infilare la camicia nei pantaloni con le proprie mani?`]: `3. Can you reach the small of your back to tuck in your shirt with your hand?`,
  [`Riesco a guidare la macchina fin quando voglio provando un lieve dolore al collo`]: `I can drive my car as long as I want with slight pain in my neck`,
  [`Riesco a camminare solo con un bastone o delle stampelle`]: `I can only walk using a stick or crutches`,
  [`Riesco a concentrarmi perfettamente quando lo desidero e senza difficolta'`]: `I can concentrate fully when I want to with no difficulty`,
  [`Riesco a concentrarmi perfettamente quando lo desidero ma con leggera difficolta'`]: `I can concentrate fully when I want to with slight difficulty`,
  [`Riesco a guidare con molta difficolta' a causa di un forte dolore al collo`]: `I can hardly drive at all because of severe pain in my neck`,
  [`Riesco a guidare la macchina fin quando voglio provando un dolore al collo di media intensita'`]: `I can drive my car as long as I want with moderate pain in my neck`,
  [`Riesco a guidare la macchina senza alcun dolore al collo`]: `I can drive my car without any neck pain`,
  [`Riesco a leggere quanto voglio avvertendo un dolore al collo di media intensita'`]: `I can read as much as I want to with moderate pain in my neck`,
  [`Riesco a leggere quanto voglio avvertendo un dolore lieve al collo`]: `I can read as much as I want to with slight pain in my neck`,
  [`Riesco a leggere quanto voglio senza provare alcun dolore al collo`]: `I can read as much as I want to with no pain in my neck`,
  [`Riesco a prendermi cura di me stesso/a normalmente ma mi fa molto male`]: `I can look after myself normally but it causes extra pain`,
  [`Riesco a prendermi cura di me stesso/a normalmente senza sentire piu' dolore del solito`]: `I can look after myself normally without causing extra pain`,
  [`Riesco a sollevare oggetti pesanti ma sentendo piu' dolore del solito`]: `I can lift heavy weights but it gives extra pain`,
  [`Riesco a sollevare solo oggetti molto leggeri`]: `I can lift very light weights`,
  [`Riesco a stare in piedi per tutto il tempo che mi va senza sentire più dolore del solito`]: `I can stand as long as I want without extra pain`,
  [`Riesco a stare in piedi per tutto il tempo che mi va, ma sentendo più dolore del solito`]: `I can stand as long as I want but it gives me extra pain`,
  [`Riesco a stare seduto/a su qualsiasi sedia per tutto il tempo che mi va`]: `I can sit in any chair as long as I like`,
  [`Riesco a stare seduto/a sulla mia sedia preferita per tutto il tempo che mi va`]: `I can only sit in my favorite chair as long as I like`,
  [`Riesco a svolgere parte del mio lavoro abituale, ma nulla di piu'`]: `I can do most of my usual work, but no more`,
  [`Riesco a svolgere solo il mio lavoro abituale, ma nulla di piu'`]: `I can only do my usual work, but no more`,
  [`Riesco a svolgere tutto il lavoro che voglio`]: `I can do as much work as I want to`,
  [`Riesco a viaggiare e spostarmi in ogni luogo senza sentire dolore`]: `I can travel anywhere without pain`,
  [`Riesco a viaggiare e spostarmi in ogni luogo, ma sentendo più dolore del solito`]: `I can travel anywhere but it gives me extra pain`,
  [`Riesco ad effettuare le faccende domestiche leggere oppure la maggior parte delle attività giornaliere`]: `Function: Able to do light housework or most activities of daily living`,
  [`Riesco ad eseguire la maggior parte delle faccende domestiche, guidare l'auto, fare shopping, pettinarmi, vestirmi e svestirmi (compreso allacciare il reggiseno)`]: `Function: Most housework, shopping, and driving possible; able to do hair and to dress and undress, including fastening bra`,
  [`Riesco ad eseguire unicamente attività leggere`]: `Function: Only light activities possible`,
  [`Riesco sollevare oggetti pesanti senza provare alcun dolore al collo`]: `I can lift heavy weights without extra pain`,
  [`Riesco sollevare oggetti pesanti senza sentire più dolore del solito`]: `I can lift heavy weights without extra pain`,
  [`Rifare il letto`]: `Make a bed`,
  [`Rifrattura della clavicola sul callo osseo`]: `Refracture clavicle through callus`,
  [`Rigidità: le domande che seguono riguardano il grado di rigidità che ha provato all'anca nel corso dell'ultima settimana. La rigidità è una sensazione di limitazione o minore facilità nel muovere l'anca.`]: `Stiffness : The following questions concern the amount of joint stiffness you have experienced during the last week in your hip. Stiffness is a sensation of restriction or slowness in the ease with which you move your hip joint`,
  [`Rigidità: le seguenti domande riguardano la sensazione di rigidità che ha avvertito durante la scorsa settimana a livello dell'anca e/o dell'inguine. La rigidità e' una sensazione di limitazione o di lentezza nell'eseguire un semplice movimento a live...`]: `Stiffness: The following questions concern the amount of stiffness you have experienced during the past week in your hip and/or groin. Stiffness is a sensation of restriction or slowness in the ease with which you move your hip and/or groin`,
  [`Rigidità: le seguenti domande riguardano la sensazione di rigidità che ha avvertito durante la scorsa settimana a livello dell'anca e/o dell'inguine. La rigidità e' una sensazione di limitazione o di lentezza nell'eseguire un semplice movimento a livello dell'anca e/o dell'inguine.`]: `Stiffness: The following questions concern the amount of stiffness you have experienced during the past week in your hip and/or groin. Stiffness is a sensation of restriction or slowness in the ease with which you move your hip and/or groin`,
  [`Rigidita'`]: `Stiffness`,
  [`RIGIDITA'`]: `Stiffness`,
  [`Rigidita' al braccio, alla spalla o alla mano`]: `Stiffness in your arm, shoulder or hand`,
  [`Rigidita' degli ischiocrurali (hamstring) riferita dalla schiena`]: `Back referred hamstring tightness`,
  [`Rigidita' muscolare riferita dalla schiena`]: `Back referred muscle tightness`,
  [`RIMA ARTICOLARE LATERALE DEL GINOCCHIO DESTRO`]: `LATERAL JOINT LINE OF RIGHT KNEE`,
  [`RIMA ARTICOLARE LATERALE DEL GINOCCHIO SINISTRO`]: `LATERAL JOINT LINE OF LEFT KNEE`,
  [`RIMA ARTICOLARE MEDIALE DEL GINOCCHIO DESTRO`]: `MEDIAL JOINT LINE OF RIGHT KNEE`,
  [`RIMA ARTICOLARE MEDIALE DEL GINOCCHIO SINISTRO`]: `MEDIAL JOINT LINE OF LEFT KNEE`,
  [`Rimanere in posizione eretta (in piedi)`]: `Standing upright`,
  [`Rimanere seduto o sdraiato`]: `Sitting or lying`,
  [`Rimbalzante`]: `Bouncy`,
  [`Rinite`]: `Rhinitis`,
  [`Rinite allergica, sinusite`]: `Allergy - rhinitis/sinusitis/hayfever`,
  [`Ripetizione di prescrizione terapeutica`]: `Prescription repeat`,
  [`riposo`]: `Rest`,
  [`Riposo`]: `Rest`,
  [`riposo,`]: `rest`,
  [`Rischiesta per test di gravidanza`]: `Request for pregnancy test`,
  [`Rispetto a un anno fa, come giudicherebbe ora la sua salute in generale?`]: `Compared to one year ago, how would you rate your health in general now?`,
  [`Risponda alle seguenti domande`]: `Answer the next questions`,
  [`Risponda sì o no alle seguenti domande in base alle attività che Lei riesce a svolgere oppure che pensa di poter svolgere`]: `Answer yes or no at the next questions based on the activities you think you can do or not do`,
  [`Risultato`]: `Result`,
  [`RobRoy L. Martin. A Survey of Self-reported Outcome Instruments for the Foot and Ankle. Journal of orthopaedic sports physical therapy. 2007.`]: `RobRoy L. Martin. A Survey of Self-reported Outcome Instruments for the Foot and Ankle. Journal of orthopaedic sports physical therapy. 2007.`,
  [`Rodente`]: `Gnawing`,
  [`Roller Calf Press`]: `Roller Calf Press`,
  [`Roos Test`]: `Roos Test`,
  [`Rotazione`]: `Rotation`,
  [`Rotazione anteriore`]: `Anterior rotation`,
  [`Rotazione anteriore Ileo (DX)`]: `Ileum anterior rotation (R)`,
  [`Rotazione anteriore Ileo (SX)`]: `Ileum anterior rotation (L)`,
  [`Rotazione collo (DX)`]: `Neck rotation (R)`,
  [`Rotazione collo (SX)`]: `Neck rotation (L)`,
  [`Rotazione delle dita`]: `Finger rotation`,
  [`Rotazione destra`]: `Right rotation`,
  [`Rotazione e estensione lombare`]: `Lumbar extension/rotation`,
  [`Rotazione e flessione lombare`]: `Lumbar flexion/rotation`,
  [`Rotazione esterna`]: `External rotation`,
  [`Rotazione esterna articolazione del ginocchio (DX)`]: `Knee joint external rotation (R)`,
  [`Rotazione esterna articolazione del ginocchio (SX)`]: `Knee joint external rotation (L)`,
  [`Rotazione esterna articolazione dell'anca (a 0 gradi di flessione) (DX)`]: `Hip joint external rotation (0 degrees of flexion) (R)`,
  [`Rotazione esterna articolazione dell'anca (a 0 gradi di flessione) (SX)`]: `Hip joint external rotation (0 degrees of flexion) (L)`,
  [`Rotazione esterna articolazione dell'anca (a 90 gradi di flessione) (DX)`]: `Hip joint external rotation (90 degrees of flexion) (R)`,
  [`Rotazione esterna articolazione dell'anca (a 90 gradi di flessione) (SX)`]: `Hip joint external rotation (90 degrees of flexion) (L)`,
  [`Rotazione esterna articolazione dell'anca (da side-lying) (DX)`]: `Hip joint external rotation (side lying) (R)`,
  [`Rotazione esterna articolazione dell'anca (da side-lying) (SX)`]: `Hip joint external rotation (side lying) (L)`,
  [`Rotazione esterna articolazione dell'anca (DX)`]: `Hip joint external rotation (R)`,
  [`Rotazione esterna articolazione dell'anca (SX)`]: `Hip joint external rotation (L)`,
  [`Rotazione esterna articolazione della spalla (DX)`]: `Shoulder joint external rotation (R)`,
  [`Rotazione esterna articolazione della spalla (SX)`]: `Shoulder joint external rotation (L)`,
  [`Rotazione esterna dell'anca`]: `Hip external rotation`,
  [`Rotazione interna`]: `Internal rotation`,
  [`Rotazione interna articolazione del ginocchio (DX)`]: `Knee joint internal rotation (R)`,
  [`Rotazione interna articolazione del ginocchio (SX)`]: `Knee joint internal rotation (L)`,
  [`Rotazione interna articolazione dell'anca (a 0 gradi di flessione) (Craig's Test) (DX)`]: `Hip joint internal rotation (0 degrees of flexion) (Craig's Test) (R)`,
  [`Rotazione interna articolazione dell'anca (a 0 gradi di flessione) (Craig's Test) (SX)`]: `Hip joint internal rotation (0 degrees of flexion) (Craig's Test) (L)`,
  [`Rotazione interna articolazione dell'anca (a 0 gradi di flessione) (DX)`]: `Hip joint internal rotation (0 degrees of flexion) (Craig's Test) (R)`,
  [`Rotazione interna articolazione dell'anca (a 90 gradi di flessione) (DX)`]: `Hip joint internal rotation (90 degrees of flexion) (R)`,
  [`Rotazione interna articolazione dell'anca (a 90 gradi di flessione) (SX)`]: `Hip joint internal rotation (90 degrees of flexion) (L)`,
  [`Rotazione interna articolazione dell'anca (DX)`]: `Hip joint internal rotation (R)`,
  [`Rotazione interna articolazione dell'anca (SX)`]: `Hip joint internal rotation (L)`,
  [`Rotazione interna articolazione della spalla (DX)`]: `Shoulder joint internal rotation (R)`,
  [`Rotazione interna articolazione della spalla (SX)`]: `Shoulder joint internal rotation (L)`,
  [`Rotazione interna dell'anca`]: `Hip internal rotation`,
  [`Rotazione interna della spalla`]: `Shoulder internal rotation`,
  [`Rotazione laterale`]: `Lateral rotation`,
  [`Rotazione laterale dell'anca`]: `Hip external rotation`,
  [`Rotazione lombare`]: `Lumbar rotation`,
  [`Rotazione mediale`]: `Medial rotation`,
  [`Rotazione pelvica anteriore`]: `Pelvic anterior rotation`,
  [`Rotazione pelvica posteriore`]: `Pelvic posterior rotation`,
  [`Rotazione posteriore`]: `Posterior rotation`,
  [`Rotazione posteriore ileo (DX)`]: `Ileum posterior rotation (R)`,
  [`Rotazione posteriore ileo (SX)`]: `Ileum posterior rotation (L)`,
  [`Rotazione scapola verso il basso`]: `Scapula downward rotation`,
  [`Rotazione sinistra`]: `Left rotation`,
  [`Rotazione tronco (DX)`]: `Trunk rotation (R)`,
  [`Rotazione tronco (SX)`]: `Trunk rotation (L)`,
  [`Rotazione verso il basso`]: `Downward rotation`,
  [`Rotazione verso il basso della scapola`]: `Scapula downward rotation`,
  [`Rotazione verso l'alto`]: `Upward rotation`,
  [`Rotazione vertebre cervicali (DX)`]: `Cervical vertebrae rotation (R)`,
  [`Rotazione vertebre cervicali (SX)`]: `Cervical vertebrae rotation (L)`,
  [`Rotazione vertebre toraciche (DX)`]: `Thoracic vertebrae rotation (R)`,
  [`Rotazione vertebre toraciche (SX)`]: `Thoracic vertebrae rotation (L)`,
  [`Rottura completa del retto femorale`]: `Rectus femoris rupture`,
  [`Rottura dei legamenti laterali della caviglia (lesione di grado III)`]: `Lateral ligaments rupture (grade 3 injury)`,
  [`Rottura dei tendini degli ischiocrurali mediali`]: `Medial hamstring tendon rupture`,
  [`Rottura del capo lungo del bicipite`]: `Rupture of long head of biceps tendon`,
  [`Rottura del complesso fibrocartilagineo triangolare del carpo`]: `Wrist fibrocartilage tear`,
  [`Rottura del legamento collaterale mediale (LCM)`]: `MCL rupture knee`,
  [`Rottura del legamento collaterale radiale del pollice all'articolazione metacarpofalangea`]: `Thumb RCL lig rupture at MCP joint`,
  [`Rottura del legamento collaterale ulnare del pollice all'articolazione metacarpofalangea (pollice dello sciatore)`]: `Thumb UCL lig rupture at MCP joint (skier's thumb)`,
  [`Rottura del legamento crociato anteriore (LCA)`]: `ACL rupture`,
  [`Rottura del legamento crociato posteriore (LCP)`]: `PCL rupture`,
  [`Rottura del legamento scafolunato`]: `Scapholunate ligament rupture`,
  [`Rottura del tendine d'Achille`]: `Achilles tendon rupture`,
  [`Rottura del tendine d'Achille all'inserzione calcaneale`]: `Insertional Achilles tendon rupture`,
  [`Rottura del tendine d'Achille alla porzione media`]: `Midsubstance Achilles tendon rupture`,
  [`Rottura del tendine degli estensori del polso`]: `Rupture wrist extensor tendon`,
  [`Rottura del tendine dei flessori del polso`]: `Rupture wrist flexor tendon`,
  [`Rottura del tendine dei peronieri`]: `Peroneal tendon rupture`,
  [`Rottura del tendine del flessore del dito medio`]: `Middle finger flexor tendon rupture`,
  [`Rottura del tendine del flessore del mignolo`]: `Little finger flexor tendon rupture`,
  [`Rottura del tendine del flessore del pollice`]: `Rupture thumb flexor tendon`,
  [`Rottura del tendine del flessore dell'anulare`]: `Ring finger flexor tendon rupture`,
  [`Rottura del tendine del flessore dell'indice`]: `Index finger flexor tendon rupture`,
  [`Rottura del tendine del flessore lungo dell'alluce`]: `Flexor Hallucis Longus rupture`,
  [`Rottura del tendine del grande adduttore`]: `Adductor magnus tendon rupture`,
  [`Rottura del tendine del Grande Pettorale`]: `Pec Major tendon rupture`,
  [`Rottura del tendine del muscolo adduttore lungo`]: `Adductor longus tendon rupture`,
  [`Rottura del tendine del muscolo sottoscapolare`]: `Subscapularis tendon rupture`,
  [`Rottura del tendine del sottospinato`]: `Infraspinatus tendon rupture`,
  [`Rottura del tendine del tibiale posteriore`]: `Tibialis posterior tendon rupture`,
  [`Rottura del tendine dell'estensore del dito medio`]: `Middle finger extensor tendon rupture`,
  [`Rottura del tendine dell'estensore del mignolo`]: `Little finger extensor tendon rupture`,
  [`Rottura del tendine dell'estensore del pollice (esclusa se complicanza di frattura del polso- vedi frattura specifica)`]: `Rupture thumb extensor tendon (excl if complication of wrist fracture - see specific fracture)`,
  [`Rottura del tendine dell'estensore dell'anulare`]: `Ring finger extensor tendon rupture`,
  [`Rottura del tendine dell'estensore dell'indice`]: `Index finger extensor tendon rupture`,
  [`Rottura del tendine dell'Ileopsoas`]: `Iliopsoas tendon rupture`,
  [`Rottura del tendine di origine del retto femorale`]: `Rectus femoris origin tendon rupture`,
  [`Rottura del tendine distale del bicipite brachiale`]: `Distal biceps tendon rupture`,
  [`Rottura del tendine distale del bicipite femorale`]: `Lateral hamstring tendon rupture at knee`,
  [`Rottura del tendine distale del tricipite brachiale`]: `Distal triceps tendon rupture`,
  [`Rottura del tendine flessore del/le dito/a`]: `Flexor tendon rupture finger(s)`,
  [`Rottura del tendine inserzionale del retto dell'addome`]: `Abdominal tendon insertion rupture`,
  [`Rottura del tendine prossimale del sartorio`]: `Sartorius tendon rupture`,
  [`Rottura del tendine quadricipitale`]: `Quadriceps tendon rupture`,
  [`Rottura del tendine rotuleo`]: `Patellar tendon rupture`,
  [`Rottura del testicolo`]: `Testicular rupture`,
  [`Rottura dell'inserzione prossimale degli ischiocrurali (esclusa la frattura della cartilagine di accrescimento)`]: `Hamstring origin tendon rupture`,
  [`Rottura della Cisti di Baker`]: `Ruptured Baker's Cyst`,
  [`Rottura della fascia plantare`]: `Plantar fascia rupture`,
  [`Rottura di pene/uretra`]: `Ruptured penis/urethra`,
  [`Rottura tendinea`]: `Tendon rupture`,
  [`Rottura tendinea del medio/piccolo gluteo`]: `Gluteus med/min tendon rupture`,
  [`Rottura/lesione di III grado degli adduttori`]: `Adductor muscle rupture/grade 3 strain`,
  [`Rottura/lesione di III grado del legamento collaterale mediale del gomito`]: `Elbow medial ligament rupture/grade 3 tear`,
  [`Rottura/stiramento del legamento crociato anteriore (LCA) con lesione condrale/meniscale associata`]: `ACL strain/rupture with chondral/meniscal injury`,
  [`Rottura/stiramento del legamento crociato posteriore (PCL) con lesione condrale/meniscale associata`]: `PCL strain/rupture with associated chondral/meniscal injury`,
  [`Rotula (DX)`]: `Patella (R)`,
  [`Rotula (SX)`]: `Patella (L)`,
  [`Rotula alta`]: `Patella alta`,
  [`Rotula bassa`]: `Low patella`,
  [`Rotula bi o multi-partita`]: `Bi or multipartite patella`,
  [`ROTULA DESTRA`]: `RIGHT PATELLA `,
  [`ROTULA DESTRA (APICE)`]: `RIGHT PATELLA (APEX)`,
  [`ROTULA DESTRA (BASE)`]: `RIGHT PATELLA (BASE)`,
  [`Rotula in scivolamento laterale`]: `Patella lateral glide`,
  [`ROTULA SINISTRA`]: `LEFT PATELLA `,
  [`ROTULA SINISTRA (APICE)`]: `LEFT PATELLA (APEX)`,
  [`ROTULA SINISTRA (BASE)`]: `LEFT PATELLA (BASE)`,
  [`Royal London Hospital Test (DX)`]: `Royal London Hospital Test (R)`,
  [`Royal London Hospital Test (SX)`]: `Royal London Hospital Test (L)`,
  [`RPE`]: `RPE`,
  [`RPE SCALE (PERCEZIONE DELLO SFORZO)`]: `RPE (RATED OF PERCEIVED EXERTION)`,
  [`Rugby a 15`]: `Rugby 7`,
  [`Rugby a 7`]: `Rugby 7`,
  [`Rule out frattura del femore`]: `Rule out femoral fracture`,
  [`Rule out fratture da stress`]: `Rule out stress fracture`,
  [`Rule Out Impingement femoro-acetabolare o una lesione del labbro`]: `Rule out femoro-acetabular impingement or labial lesion`,
  [`rule out problematiche intra-articolari`]: `Rule out intraarticular pathologies`,
  [`rule out problematiche intra-articolari e del quadrante addominale inferiore`]: `Rule out intraarticular pathologies and pathologis of inferior abdominal quadrant`,
  [`Rule out una problematica Sacro-Iliaca`]: `Rule out sacroiliac joint problem`,
  [`Rule out una radicolopatia lombare o una patologia relativa al disco`]: `Rule out lumbar radiculopathy or disc pathology`,
  [`Rule out una sindrome delle faccette articolari lombari`]: `Rule out lumbar facet joints syndrome`,
  [`Rumori forti`]: `loud noises`,
  [`Ruotando e spostando il peso su una sola gamba`]: `Twisting/pivoting on a weight bearing leg`,
  [`Sacral Thrust Test`]: `Sacral Thrust Test`,
  [`Sacralizzazione di L5`]: `Sacralisation of L5`,
  [`Safety Bar Split Squat`]: `Safety Bar Split Squat`,
  [`Salendo le scale`]: `Walking up stairs`,
  [`Salendo o scendendo le scale`]: `Walking up or down stairs`,
  [`Salire e scendere dai marciapiedi`]: `Standing up and down curbs`,
  [`Salire e/o scendere da un'automobile`]: `Getting in/out of car`,
  [`Salire le scale`]: `Ascending stairs`,
  [`Salire o scendere 10 gradini (all'incirca una rampa di scale)`]: `Going up or down 10 stairs`,
  [`Salire o scendere le scale`]: `Going up or down stairs`,
  [`Salire qualche piano di scale`]: `Climbing several flights of stairs`,
  [`Salire un piano di scale`]: `Climbing one flight of stairs`,
  [`Salire/scendere dalla macchina`]: `Getting in/out of car`,
  [`Saltare`]: `Jumping`,
  [`Saltare atterrando sulla gamba offesa`]: `Jump and land on involved leg`,
  [`Saltellante`]: `Jumping`,
  [`salti`]: `jumpes`,
  [`Sangue nelle urine, nelle feci, nel vomito o nel muco`]: `Blood in urine, stool, vomit, mucus`,
  [`Sarcoidosi polmonare`]: `Pulmonary sarcoidosis`,
  [`Scadente`]: `Poor`,
  [`Scafoide (DX)`]: `Scaphoid (R)`,
  [`Scafoide (SX)`]: `Scaphoid (L)`,
  [`SCAFOIDE DESTRO`]: `RIGHT SCAPHOID `,
  [`SCAFOIDE DESTRO (TUBERCOLO)`]: `RIGHT SCAPHOID (TUBERCLE)`,
  [`SCAFOIDE SINISTRO`]: `LEFT SCAPHOID `,
  [`SCAFOIDE SINISTRO (TUBERCOLO)`]: `LEFT SCAPHOID (TUBERCLE)`,
  [`Scaphoid Compression Test (DX)`]: `Scaphoid Compression Test (R)`,
  [`Scaphoid Compression Test (SX)`]: `Scaphoid Compression Test (L)`,
  [`Scaphoid Tubercle Tenderness (DX)`]: `Scaphoid Tubercle Tenderness (R)`,
  [`Scaphoid Tubercle Tenderness (SX)`]: `Scaphoid Tubercle Tenderness (L)`,
  [`Scapola (acromion) (DX)`]: `Scapula (acromion) (R)`,
  [`Scapola (acromion) (SX)`]: `Scapula (acromion) (L)`,
  [`Scapola (DX)`]: `Scapula (R)`,
  [`Scapola (processo coracoideo) (DX)`]: `Scapula (coracoid process) (R)`,
  [`Scapola (processo coracoideo) (SX)`]: `Scapula (coracoid process) (L)`,
  [`Scapola (spina della scapola) (DX)`]: `Scapula (spine) (R)`,
  [`Scapola (spina della scapola) (SX)`]: `Scapula (spine) (L)`,
  [`Scapola (SX)`]: `Scapula (L)`,
  [`scapola a scatto`]: `Snapping scapula`,
  [`Scapola alata`]: `Winged scapula`,
  [`SCAPOLA DESTRA`]: `RIGHT SCAPULA`,
  [`SCAPOLA DESTRA (ACROMON)`]: `RIGHT SCAPULA (ACROMION)`,
  [`SCAPOLA DESTRA (ANGOLO INFERIORE)`]: `RIGHT SCAPULA (INFERIOR ANGLE)`,
  [`SCAPOLA DESTRA (MARGINE LATERALE)`]: `RIGHT SCAPULA (LATERAL MARGIN)`,
  [`SCAPOLA DESTRA (MARGINE MEDIALE)`]: `RIGHT SCAPULA (MEDIAL MARGIN)`,
  [`SCAPOLA DESTRA (PROCESSO CORACOIDEO)`]: `RIGHT SCAPULA (CORACOID PROCESS)`,
  [`SCAPOLA DESTRA (SPINA DELLA SCAPOLA)`]: `RIGHT SCAPULA (SPINE OF SCAPULA)`,
  [`SCAPOLA SINISTRA`]: `LEFT SCAPULA`,
  [`SCAPOLA SINISTRA (ACROMION)`]: `LEFT SCAPULA (ACROMION)`,
  [`SCAPOLA SINISTRA (ANGOLO INFERIORE)`]: `LEFT SCAPULA (INFERIOR ANGLE)`,
  [`SCAPOLA SINISTRA (MARGINE LATERALE)`]: `LEFT SCAPULA (LATERAL MARGIN)`,
  [`SCAPOLA SINISTRA (MARGINE MEDIALE)`]: `LEFT SCAPULA (MEDIAL MARGIN)`,
  [`SCAPOLA SINISTRA (PROCESSO CORACOIDEO)`]: `LEFT SCAPULA (CORACOID PROCESS)`,
  [`SCAPOLA SINISTRA (SPINA DELLA SCAPOLA)`]: `LEFT SCAPULA (SPINE OF SCAPULA)`,
  [`Scapula Pull Isometric Hold`]: `Scapula Pull Isometric Hold`,
  [`Scapular Assistance Test (DX)`]: `Scapular Assistance Test (R)`,
  [`Scapular Assistance Test (SX)`]: `Scapular Assistance Test (L)`,
  [`Scapular Dyskinesis Test (DX)`]: `Scapular Dyskinesis Test (R)`,
  [`Scapular Dyskinesis Test (SX)`]: `Scapular Dyskinesis Test (L)`,
  [`Scapular Muscles Endurance Test (DX)`]: `Scapular Muscles Endurance Test (R)`,
  [`Scapular Muscles Endurance Test (SX)`]: `Scapular Muscles Endurance Test (L)`,
  [`Scapular Retraction Test (DX)`]: `Scapular Retraction Test (R)`,
  [`Scapular Retraction Test (SX)`]: `Scapular Retraction Test (L)`,
  [`Scarecrow`]: `Scarecrow`,
  [`Scaricare`]: `damp`,
  [`Scarsa`]: `Poor`,
  [`Scarso`]: `Not so good`,
  [`Scelga, per ogni domanda, la risposta che meglio descrive quanto siano Vere o False le seguenti affermazioni.`]: `How TRUE or FALSE is each of the following statements for you`,
  [`Scendere le scale`]: `Descending stairs`,
  [`Scivolamento anteriore`]: `Anterior glide`,
  [`Scivolamento anteriore articolazione acromionclavicolare (DX)`]: `Acromionclavicular joint anterior glide (R)`,
  [`Scivolamento anteriore articolazione acromionclavicolare (SX)`]: `Acromionclavicular joint anterior glide (L)`,
  [`Scivolamento anteriore articolazione del polso (DX)`]: `Wrist joint anterior glide (R)`,
  [`Scivolamento anteriore articolazione del polso (SX)`]: `Wrist joint anterior glide (L)`,
  [`Scivolamento anteriore articolazione omeroradiale (DX)`]: `Humeroradial joint anterior glide (R)`,
  [`Scivolamento anteriore articolazione omeroradiale (SX)`]: `Humeroradial joint anterior glide (L)`,
  [`Scivolamento anteriore articolazione radioulnare distale (DX)`]: `Distal radioulnar joint anterior glide (R)`,
  [`Scivolamento anteriore articolazione radioulnare distale (SX)`]: `Distal radioulnar joint anterior glide (L)`,
  [`Scivolamento anteriore articolazione radioulnare prossimale (DX)`]: `Proximal radioulnar joint anterior glide (R)`,
  [`Scivolamento anteriore articolazione radioulnare prossimale (SX)`]: `Proximal radioulnar joint ansterior glide (L)`,
  [`Scivolamento anteriore articolazione sternoclavicolare (DX)`]: `Sternoclavicular joint anterior glide (R)`,
  [`Scivolamento anteriore articolazione sternoclavicolare (SX)`]: `Sternoclavicular joint anterior glide (L)`,
  [`Scivolamento anteriore astragalo sulla tibia (DX)`]: `Talus anterior glide (R)`,
  [`Scivolamento anteriore astragalo sulla tibia (SX)`]: `Talus anterior glide (L)`,
  [`Scivolamento anteriore del femore`]: `Femoral anterior glide`,
  [`Scivolamento anteriore della testa omerale`]: `Humeral head anterior glide`,
  [`Scivolamento anteriore malleolo peroneale (DX)`]: `Fibular malleolus anterior glide (R)`,
  [`Scivolamento anteriore malleolo peroneale (SX)`]: `Fibular melleolus anterior glide (L)`,
  [`Scivolamento anteriore mandibola all'articolazione temporomandibolare (DX)`]: `Mandible anterior glide (at the temporomandibular joint) (R)`,
  [`Scivolamento anteriore mandibola all'articolazione temporomandibolare (SX)`]: `Mandible anterior glide (at the temporomandibular joint) (L)`,
  [`Scivolamento anteriore testa del femore (DX)`]: `Femoral head anterior glide (R)`,
  [`Scivolamento anteriore testa del femore (SX)`]: `Femoral head anterior glide (L)`,
  [`Scivolamento anteriore testa del perone (DX)`]: `Fibular head anterior glide (R)`,
  [`Scivolamento anteriore testa del perone (SX)`]: `Fibular head anterior glide (L)`,
  [`Scivolamento anteriore testa dell'omero (DX)`]: `humeral head anterior glide (R)`,
  [`Scivolamento anteriore testa dell'omero (SX)`]: `Humeral head anterior glide (L)`,
  [`Scivolamento anteriore testa omero`]: `Humeral head anterior glide`,
  [`Scivolamento anteriore tibia`]: `Tibial anterior glide`,
  [`Scivolamento anteriore tibia sul femore (DX)`]: `Tibial anterior glide (at the knee) (R) `,
  [`Scivolamento anteriore tibia sul femore (SX)`]: `Tibial anterior glide (at the knee) (L) `,
  [`Scivolamento dell'epifisi prossimale del femore`]: `Slipped capital femoral epiphysis`,
  [`Scivolamento dorsale`]: `Dorsal glide`,
  [`Scivolamento dorsale articolazione del polso (DX)`]: `Wrist joint dorsal glide (R)`,
  [`Scivolamento dorsale articolazione del polso (SX)`]: `Wrist joint dorsal glide (L)`,
  [`Scivolamento dorsale articolazione interfalangea del pollice (DX)`]: `Interphalangeal joint of thumb dorsal glide (R)`,
  [`Scivolamento dorsale articolazione interfalangea del pollice (SX)`]: `Interphalangeal joint of thumb dorsal glide (L)`,
  [`Scivolamento dorsale articolazione metacarpofalangea del pollice (DX)`]: `Matacarpophalangeal joint of pollicis dorsal glide (R)`,
  [`Scivolamento dorsale articolazione metacarpofalangea del pollice (SX)`]: `Matacarpophalangeal joint of pollicis dorsal glide (L)`,
  [`Scivolamento dorsale articolazioni carpometacarpali dalla II alla V (DX)`]: `Carpometacarpal joint (II-V) dorsal glide (R)`,
  [`Scivolamento dorsale articolazioni carpometacarpali dalla II alla V (SX)`]: `Carpometacarpal joint (II-V) dorsal glide (L)`,
  [`Scivolamento dorsale articolazioni interfalangee distali della mano dalla II alla V (DX)`]: `Distal interphalangeal joints (II-V) dorsal glide (R)`,
  [`Scivolamento dorsale articolazioni interfalangee distali della mano dalla II alla V (SX)`]: `Distal interphalangeal joints (II-V) dorsal glide (L)`,
  [`Scivolamento dorsale articolazioni interfalangee prossimali della mano dalla II alla V (DX)`]: `Proximal interphalangeal joints (II-V) dorsal glide (R)`,
  [`Scivolamento dorsale articolazioni interfalangee prossimali della mano dalla II alla V (SX)`]: `Proximal interphalangeal joints (II-V) dorsal glide (L)`,
  [`Scivolamento dorsale articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) dorsal glide (R)`,
  [`Scivolamento dorsale articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) dorsal glide (L)`,
  [`Scivolamento dorsale capitato sul semilunare (DX)`]: `Capitate dorsal glide (on lunate) (R)`,
  [`Scivolamento dorsale capitato sul semilunare (SX)`]: `Capitate dorsal glide (on lunate) (L)`,
  [`Scivolamento dorsale cuboide sul calcagno (DX)`]: `Cuboid dorsal glide (R)`,
  [`Scivolamento dorsale cuboide sul calcagno (SX)`]: `Cuboid dorsal glide (L)`,
  [`Scivolamento dorsale falange prossimale dell'alluce sul I metatarso (DX)`]: `Proximal phalanx of hallux dorsal glide (on I metatarsal) (R)`,
  [`Scivolamento dorsale falange prossimale dell'alluce sul I metatarso (SX)`]: `Proximal phalanx of hallux dorsal glide (on I metatarsal) (L)`,
  [`Scivolamento dorsale falangi prossimali del piede sui metatarsi dalla II al V (DX)`]: `Proximal phalanges (II-V) dorsal glide (on metatarsals) (R)`,
  [`Scivolamento dorsale falangi prossimali del piede sui metatarsi dalla II al V (SX)`]: `Proximal phalanges (II-V) dorsal glide (on metatarsals) (L)`,
  [`Scivolamento dorsale I metacarpo sul trapezio (DX)`]: `I metacarpal dorsal glide (on trapezius) (R)`,
  [`Scivolamento dorsale I metacarpo sul Trapezio (SX)`]: `I metacarpal dorsal glide (on trapezius) (L)`,
  [`Scivolamento dorsale metatarsi nell'articolazione di Lisfranc (DX)`]: `Metatarsals dorsal glide (R)`,
  [`Scivolamento dorsale metatarsi nell'articolazione di Lisfranc (SX)`]: `Metatarsals dorsal glide (L)`,
  [`Scivolamento dorsale navicolare sull'astragalo (DX)`]: `Navicular dorsal glide (R)`,
  [`Scivolamento dorsale navicolare sull'astragalo (SX)`]: `Navicular dorsal glide (L)`,
  [`Scivolamento dorsale piramidale sul disco (DX)`]: `Triquetral dorsal glide (on disc) (R)`,
  [`Scivolamento dorsale piramidale sul disco (SX)`]: `Triquetral dorsal glide (on disc) (L)`,
  [`Scivolamento dorsale scafoide sul radio (DX)`]: `Scaphoid dorsal glide (on radius) (R)`,
  [`Scivolamento dorsale scafoide sul radio (SX)`]: `Scaphoid dorsal glide (on radius) (L)`,
  [`Scivolamento dorsale semilunare sul radio (DX)`]: `Lunate dorsal glide (on radius) (R)`,
  [`Scivolamento dorsale semilunare sul radio (SX)`]: `Lunate dorsal glide (on radius) (L)`,
  [`Scivolamento dorsale trapezio e trapezoide sullo scafoide (DX)`]: `Trapezius and trapezoid dorsal glide (on scaphoid) (R)`,
  [`Scivolamento dorsale trapezio e trapezoide sullo scafoide (SX)`]: `Trapezius and trapezoid dorsal glide (on scaphoid) (L)`,
  [`Scivolamento dorsale uncinato sul piramidale (DX)`]: `Hamate dorsal glide (on triquetral) (R)`,
  [`Scivolamento dorsale uncinato sul piramidale (SX)`]: `Hamate dorsal glide (on triquetral) (L)`,
  [`Scivolamento inferiore`]: `Inferior glide`,
  [`Scivolamento inferiore articolazione sternoclavicolare (DX)`]: `Sternoclavicular joint inferior glide (R)`,
  [`Scivolamento inferiore articolazione sternoclavicolare (SX)`]: `Sternoclavicular joint inferior glide (L)`,
  [`Scivolamento inferiore mandibola all'articolazione temporomandibolare (DX)`]: `Mandible inferior glide (at the temporomandibular joint) (R)`,
  [`Scivolamento inferiore mandibola all'articolazione temporomandibolare (SX)`]: `Mandible inferior glide (at the temporomandibular joint) (L)`,
  [`Scivolamento inferiore rotula (DX)`]: `Patella inferior glide (R)`,
  [`Scivolamento inferiore rotula (SX)`]: `Patella inferior glide (L)`,
  [`Scivolamento inferiore scapola (DX)`]: `Scapula inferior glide (R)`,
  [`Scivolamento inferiore scapola (SX)`]: `Scapula inferior glide (L)`,
  [`Scivolamento inferiore testa dell'omero (DX)`]: `humeral head inferior glide (R)`,
  [`Scivolamento inferiore testa dell'omero (SX)`]: `Humeral head inferior glide (L)`,
  [`Scivolamento laterale`]: `Lateral glide`,
  [`Scivolamento laterale articolazione omeroulnare (DX)`]: `Humeroulnar joint lateral glide (R)`,
  [`Scivolamento laterale articolazione omeroulnare (SX)`]: `Humeroulnar joint lateral glide (L)`,
  [`Scivolamento laterale calcagno sull'astragalo (DX)`]: `Calcaneus lateral glide (R)`,
  [`Scivolamento laterale calcagno sull'astragalo (SX)`]: `Calcaneus lateral glide (L)`,
  [`Scivolamento laterale del femore`]: `Femoral lateral glide`,
  [`Scivolamento laterale della rotula`]: `Patella lateral glide`,
  [`Scivolamento laterale destro`]: `Right lateral glide`,
  [`Scivolamento laterale falange prossimale dell'alluce sul I metatarso (DX)`]: `Proximal phalanx of hallux lateral glide (on I metatarsal) (R)`,
  [`Scivolamento laterale falange prossimale dell'alluce sul I metatarso (SX)`]: `Proximal phalanx of hallux lateral glide (on I metatarsal) (L)`,
  [`Scivolamento laterale falangi prossimali del piede sui metatarsi dalla II al V (DX)`]: `Proximal phalanges (II-V) lateral glide (on metatarsals) (R)`,
  [`Scivolamento laterale falangi prossimali sui metatarsi dalla II al V (SX)`]: `Proximal phalanges (II-V) lateral glide (on metatarsals) (L)`,
  [`Scivolamento laterale mandibola all'articolazione temporomandibolare (DX)`]: `Mandible lateral glide (at the temporomandibular joint) (R)`,
  [`Scivolamento laterale mandibola all'articolazione temporomandibolare (SX)`]: `Mandible lateral glide (at the temporomandibular joint) (L)`,
  [`Scivolamento laterale rotula (DX)`]: `Patella lateral glide (R)`,
  [`Scivolamento laterale rotula (SX)`]: `Patella lateral glide (L)`,
  [`Scivolamento laterale scapola (DX)`]: `Scapula lateral glide (R)`,
  [`Scivolamento laterale scapola (SX)`]: `Scapula lateral glide (L)`,
  [`Scivolamento laterale sinistro`]: `Left lateral glide`,
  [`Scivolamento laterale tibia`]: `Tibial lateral glide`,
  [`Scivolamento laterale tibia sul femore (DX)`]: `Tibial lateral glide (at the knee) (R) `,
  [`Scivolamento laterale tibia sul femore (SX)`]: `Tibial lateral glide (at the knee) (L) `,
  [`Scivolamento mediale`]: `Medial glide`,
  [`Scivolamento mediale articolazione omeroulnare (DX)`]: `Humeroulnar joint medial glide (R)`,
  [`Scivolamento mediale articolazione omeroulnare (SX)`]: `Humeroulnar joint medial glide (L)`,
  [`Scivolamento mediale calcagno sull'astragalo (DX)`]: `Calcaneus medial glide (R)`,
  [`Scivolamento mediale calcagno sull'astragalo (SX)`]: `Calcaneus medial glide (L)`,
  [`Scivolamento mediale del femore`]: `Femoral medial glide`,
  [`Scivolamento mediale falange prossimale dell'alluce sul I metatarso (DX)`]: `Proximal phalanx of hallux medial glide (on I metatarsal) (R)`,
  [`Scivolamento mediale falange prossimale dell'alluce sul I metatarso (SX)`]: `Proximal phalanx of hallux medial glide (on I metatarsal) (L)`,
  [`Scivolamento mediale falangi prossimali del piede sui metatarsi dalla II al V (DX)`]: `Proximal phalanges (II-V) medial glide (on metatarsals) (R)`,
  [`Scivolamento mediale falangi prossimali del piede sui metatarsi dalla II al V (SX)`]: `Proximal phalanges (II-V) medial glide (on metatarsals) (L)`,
  [`Scivolamento mediale mandibola all'articolazione temporomandibolare (DX)`]: `Mandible medial glide (at the temporomandibular joint) (R)`,
  [`Scivolamento mediale mandibola all'articolazione temporomandibolare (SX)`]: `Mandible medial glide (at the temporomandibular joint) (L)`,
  [`Scivolamento mediale rotula (DX)`]: `Patella medial glide (R)`,
  [`Scivolamento mediale rotula (SX)`]: `Patella medial glide (L)`,
  [`Scivolamento mediale scapola (DX)`]: `Scapula medial glide (R)`,
  [`Scivolamento mediale scapola (SX)`]: `Scapula medial glide (L)`,
  [`Scivolamento mediale tibia`]: `Tibial medial glide`,
  [`Scivolamento mediale tibia sul femore (DX)`]: `Tibial medial glide (at the knee) (R) `,
  [`Scivolamento mediale tibia sul femore (SX)`]: `Tibial medial glide (at the knee) (L) `,
  [`Scivolamento palmare`]: `Palmar glide`,
  [`Scivolamento palmare articolazione del polso (DX)`]: `Wrist joint palmar glide (R)`,
  [`Scivolamento palmare articolazione del polso (SX)`]: `Wrist joint palmar glide (L)`,
  [`Scivolamento palmare articolazione interfalangea del pollice (DX)`]: `Interphalangeal joint of thumb palmar glide (R)`,
  [`Scivolamento palmare articolazione interfalangea del pollice (SX)`]: `Interphalangeal joint of thumb palmar glide (L)`,
  [`Scivolamento palmare articolazione metacarpofalangea del pollice (DX)`]: `Matacarpophalangeal joint of pollicis palmar glide (R)`,
  [`Scivolamento palmare articolazione metacarpofalangea del pollice (SX)`]: `Matacarpophalangeal joint of pollicis palmar glide (L)`,
  [`Scivolamento palmare articolazioni carpometacarpali dalla II alla V (DX)`]: `Carpometacarpal joint (II-V) palmar glide (R)`,
  [`Scivolamento palmare articolazioni carpometacarpali dalla II alla V (SX)`]: `Carpometacarpal joint (II-V) palmar glide (L)`,
  [`Scivolamento palmare articolazioni interfalangee distali della mano dalla II alla V (DX)`]: `Distal interphalangeal joints (II-V) palmar glide (R)`,
  [`Scivolamento palmare articolazioni interfalangee distali della mano dalla II alla V (SX)`]: `Distal interphalangeal joints (II-V) palmar glide (L)`,
  [`Scivolamento palmare articolazioni interfalangee prossimali della mano dalla II alla V (DX)`]: `Proximal interphalangeal joints (II-V) palmar glide (R)`,
  [`Scivolamento palmare articolazioni interfalangee prossimali della mano dalla II alla V (SX)`]: `Proximal interphalangeal joints (II-V) palmar glide (L)`,
  [`Scivolamento palmare articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) palmar glide (R)`,
  [`Scivolamento palmare articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) palmar glide (L)`,
  [`Scivolamento palmare capitato sul semilunare (DX)`]: `Capitate palmar glide (on lunate) (R)`,
  [`Scivolamento palmare capitato sul semilunare (SX)`]: `Capitate palmar glide (on lunate) (L)`,
  [`Scivolamento palmare I metacarpo sul trapezio (DX)`]: `I metacarpal palmar glide (on trapezius) (R)`,
  [`Scivolamento palmare I metacarpo sul trapezio (SX)`]: `I metacarpal palmar glide (on trapezius) (L)`,
  [`Scivolamento palmare piramidale sul disco (DX)`]: `Triquetral palmar glide (on disc) (R)`,
  [`Scivolamento palmare piramidale sul disco (SX)`]: `Triquetral palmar glide (on disc) (L)`,
  [`Scivolamento palmare scafoide sul radio (DX)`]: `Scaphoid palmar glide (on radius) (R)`,
  [`Scivolamento palmare scafoide sul radio (SX)`]: `Scaphoid palmar glide (on radius) (L)`,
  [`Scivolamento palmare semilunare sul radio (DX)`]: `Lunate palmar glide (on radius) (R)`,
  [`Scivolamento palmare semilunare sul radio (SX)`]: `Lunate palmar glide (on radius) (L)`,
  [`Scivolamento palmare trapezio e trapezoide sullo scafoide (DX)`]: `Trapezius and trapezoid palmar glide (on scaphoid) (R)`,
  [`Scivolamento palmare trapezio e trapezoide sullo scafoide (SX)`]: `Trapezius and trapezoid palmar glide (on scaphoid) (L)`,
  [`Scivolamento palmare uncinato sul piramidale (DX)`]: `Hamate palmar glide (on triquetral) (R)`,
  [`Scivolamento palmare uncinato sul piramidale (SX)`]: `Hamate palmar glide (on triquetral) (L)`,
  [`Scivolamento plantare`]: `Plantar glide`,
  [`Scivolamento plantare cuboide sul calcagno (DX)`]: `Cuboid plantar glide (R)`,
  [`Scivolamento plantare cuboide sul calcagno (SX)`]: `Cuboid plantar glide (L)`,
  [`Scivolamento plantare falange prossimale dell'alluce sul I metatarso (DX)`]: `Proximal phalanx of hallux plantar glide (on I metatarsal) (R)`,
  [`Scivolamento plantare falange prossimale dell'alluce sul I metatarso (SX)`]: `Proximal phalanx of hallux plantar glide (on I metatarsal) (L)`,
  [`Scivolamento plantare falangi prossimali del piede sui metatarsi dal II al V (DX)`]: `Proximal phalanges (II-V) plantar glide (on metatarsals) (R)`,
  [`Scivolamento plantare falangi prossimali del piede sui metatarsi dal II al V (SX)`]: `Proximal phalanges (II-V) plantar glide (on metatarsals) (L)`,
  [`Scivolamento plantare metatarsi nell'articolazione di Lisfranc (DX)`]: `Metatarsals plantar glide (R)`,
  [`Scivolamento plantare metatarsi nell'articolazione di Lisfranc (SX)`]: `Metatarsals plantar glide (L)`,
  [`Scivolamento plantare navicolare sull'astragalo (DX)`]: `Navicular plantar glide (R)`,
  [`Scivolamento plantare navicolare sull'astragalo (SX)`]: `Navicular plantar glide (L)`,
  [`Scivolamento posteriore`]: `Posterior glide`,
  [`Scivolamento posteriore articolazione acromionclavicolare (DX)`]: `Acromionclavicular joint posterior glide (R)`,
  [`Scivolamento posteriore articolazione acromionclavicolare (SX)`]: `Acromionclavicular joint posterior glide (L)`,
  [`Scivolamento posteriore articolazione del polso (DX)`]: `Wrist joint posterior glide (R)`,
  [`Scivolamento posteriore articolazione del polso (SX)`]: `Wrist joint posterior glide (L)`,
  [`Scivolamento posteriore articolazione omeroradiale (DX)`]: `Humeroradial joint posterior glide (R)`,
  [`Scivolamento posteriore articolazione omeroradiale (SX)`]: `Humeroradial joint posterior glide (L)`,
  [`Scivolamento posteriore articolazione radioulnare distale (DX)`]: `Distal radioulnar joint posterior glide (R)`,
  [`Scivolamento posteriore articolazione radioulnare distale (SX)`]: `Distal radioulnar joint posterior glide (L)`,
  [`Scivolamento posteriore articolazione radioulnare prossimale (DX)`]: `Proximal radioulnar joint posterior glide (R)`,
  [`Scivolamento posteriore articolazione radioulnare prossimale (SX)`]: `Proximal radioulnar joint posterior glide (L)`,
  [`Scivolamento posteriore articolazione sternoclavicolare (DX)`]: `Sternoclavicular joint posterior glide (R)`,
  [`Scivolamento posteriore articolazione sternoclavicolare (SX)`]: `Sternoclavicular joint posterior glide (L)`,
  [`Scivolamento posteriore astragalo sulla tibia (DX)`]: `Talus posterior glide (R)`,
  [`Scivolamento posteriore astragalo sulla tibia (SX)`]: `Talus posterior glide (L)`,
  [`Scivolamento posteriore malleolo peroneale (DX)`]: `Fibular malleolus posterior glide (R)`,
  [`Scivolamento posteriore malleolo peroneale (SX)`]: `Fibular melleolus posterior glide (L)`,
  [`Scivolamento posteriore mandibola all'articolazione temporomandibolare (DX)`]: `Mandible posterior glide (at the temporomandibular joint) (R)`,
  [`Scivolamento posteriore mandibola all'articolazione temporomandibolare (SX)`]: `Mandible posterior glide (at the temporomandibular joint) (L)`,
  [`Scivolamento posteriore tbia`]: `Tibial posterior glide`,
  [`Scivolamento posteriore testa del femore (DX)`]: `Femoral head posterior glide (R)`,
  [`Scivolamento posteriore testa del femore (SX)`]: `Femoral head posterior glide (L)`,
  [`Scivolamento posteriore testa del perone (DX)`]: `Fibular head posterior glide (R)`,
  [`Scivolamento posteriore testa del perone (SX)`]: `Fibular head posterior glide (L)`,
  [`Scivolamento posteriore testa dell'omero (DX)`]: `humeral head posterior glide (R)`,
  [`Scivolamento posteriore testa dell'omero (SX)`]: `Humeral head posterior glide (L)`,
  [`Scivolamento posteriore tibia`]: `Tibial posterior glide`,
  [`Scivolamento posteriore tibia sul femore (DX)`]: `Tibial posterior glide (at the knee) (R) `,
  [`Scivolamento posteriore tibia sul femore (SX)`]: `Tibial posterior glide (at the knee) (L) `,
  [`Scivolamento postero-anteriore sacro (in contro-nutazione)`]: `Sacrum posterior-anterior glide (counter-nutation)`,
  [`Scivolamento postero-anteriore sacro (in nutazione)`]: `Sacrum posterior-anterior glide (nutation)`,
  [`Scivolamento radiale`]: `Radial glide`,
  [`Scivolamento radiale articolazione del polso (DX)`]: `Wrist joint radial glide (R)`,
  [`Scivolamento radiale articolazione del polso (SX)`]: `Wrist joint radial glide (L)`,
  [`Scivolamento radiale articolazione metacarpofalangea del pollice (DX)`]: `Matacarpophalangeal joint of pollicis radial glide (R)`,
  [`Scivolamento radiale articolazione metacarpofalangea del pollice (SX)`]: `Matacarpophalangeal joint of pollicis radial glide (L)`,
  [`Scivolamento radiale articolazione omeroulnare (DX)`]: `Homeroulnar joint radial glide (R)`,
  [`Scivolamento radiale articolazione omeroulnare (SX)`]: `Homeroulnar joint radial glide (L)`,
  [`Scivolamento radiale articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) radial glide (R)`,
  [`Scivolamento radiale articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) radial glide (L)`,
  [`Scivolamento radiale I metacarpo sul trapezio (DX)`]: `I metacarpal radial glide (on trapezius) (R)`,
  [`Scivolamento radiale I metacarpo sul trapezio (SX)`]: `I metacarpal radial glide (on trapezius) (L)`,
  [`Scivolamento rotuleo laterale`]: `Patella lateral glide`,
  [`Scivolamento superiore`]: `Superior glide`,
  [`Scivolamento superiore articolazione sternoclavicolare (DX)`]: `Sternoclavicular joint superior glide (R)`,
  [`Scivolamento superiore articolazione sternoclavicolare (SX)`]: `Sternoclavicular joint superior glide (L)`,
  [`Scivolamento superiore della testa omerale`]: `Humeral head superior glide`,
  [`Scivolamento superiore malleolo peroneale (DX)`]: `Fibular malleolus superior glide (R)`,
  [`Scivolamento superiore malleolo peroneale (SX)`]: `Fibular melleolus superior glide (L)`,
  [`Scivolamento superiore mandibola all'articolazione temporomandibolare (DX)`]: `Mandible superior glide (at the temporomandibular joint) (R)`,
  [`Scivolamento superiore mandibola all'articolazione temporomandibolare (SX)`]: `Mandible superior glide (at the temporomandibular joint) (L)`,
  [`Scivolamento superiore rotula (DX)`]: `Patella superior glide (R)`,
  [`Scivolamento superiore rotula (SX)`]: `Patella superior glide (L)`,
  [`Scivolamento superiore scapola (DX)`]: `Scapula superior glide (R)`,
  [`Scivolamento superiore scapola (SX)`]: `Scapula superior glide (L)`,
  [`Scivolamento superiore testa omero`]: `Humeral head superior glide`,
  [`Scivolamento ulnare`]: `Ulnar glide`,
  [`Scivolamento ulnare articolazione del polso (DX)`]: `Wrist joint ulnar glide (R)`,
  [`Scivolamento ulnare articolazione del polso (SX)`]: `Wrist joint ulnar glide (L)`,
  [`Scivolamento ulnare articolazione metacarpofalangea del pollice (DX)`]: `Matacarpophalangeal joint of pollicis ulnar glide (R)`,
  [`Scivolamento ulnare articolazione metacarpofalangea del pollice (SX)`]: `Matacarpophalangeal joint of pollicis ulnar glide (L)`,
  [`Scivolamento ulnare articolazione omeroulnare (DX)`]: `Homeroulnar joint ulnar glide (R)`,
  [`Scivolamento ulnare articolazione omeroulnare (SX)`]: `Homeroulnar joint ulnar glide (L)`,
  [`Scivolamento ulnare articolazioni metacarpofalangee dalla II alla V (DX)`]: `Metacarpophalangeal joints (II-V) ulnar glide (R)`,
  [`Scivolamento ulnare articolazioni metacarpofalangee dalla II alla V (SX)`]: `Metacarpophalangeal joints (II-V) ulnar glide (L)`,
  [`Scivolamento ulnare I metacarpo sul trapezio (DX)`]: `I metacarpal ulnar glide (on trapezius) (R)`,
  [`Scivolamento ulnare I metacarpo sul trapezio (SX)`]: `I metacarpal ulnar glide (on trapezius) (L)`,
  [`Sclerodermia`]: `Sclerodermia`,
  [`Sclerosi multipla`]: `Multiple sclerosis`,
  [`Sclerosi Multipla`]: `Multiple Sclorosis`,
  [`Scoliosi lombare`]: `Lumbar Scoliosis`,
  [`Scoliosi toracica`]: `Thoracic scoliosis`,
  [`Scompenso cardiaco`]: `Heart failure`,
  [`Sconfortante`]: `Disheartening`,
  [`Sconosciuta o non specificata`]: `Unknown or not specified`,
  [`Sconosciuto`]: `Unknown`,
  [`Scoraggiato e triste`]: `Have you felt downhearted and blue?`,
  [`Scorpion`]: `Scorpion`,
  [`Scottante`]: `Scalding`,
  [`Screening`]: `Screening`,
  [`Scrivere`]: `Write`,
  [`Scrosci, scatti o altri tipi di rumore`]: `do you feel grinding, hear clicking or any other type of noise from your hip?`,
  [`SCROTO (M)`]: `SCROTUM (M)`,
  [`Sdraiarsi`]: `lying down`,
  [`Se non ha dolore praticando sport, completi solo la domanda A. Se ha dolore praticando sport, ma il dolore non e' tale da impedirle di portare a termine l'attivita' sportiva, completi solo la domanda B. Se ha dolore praticando sport tale da impedirle di ...`]: `If you have no pain while undertaking sport please complete question 8a only. If you have pain while undertaking sport but it does not stop you from completing th activity, please complete question 8b only. If you have pain that stops you from completing sporting activities, please complete question 8c only.`,
  [`Se non ha dolore praticando sport, completi solo la domanda A. Se ha dolore praticando sport, ma il dolore non e' tale da impedirle di portare a termine l'attivita' sportiva, completi solo la domanda B. Se ha dolore praticando sport tale da impedirle di portare a termine l'attivita' sportiva, completi solo la domanda C`]: `If you have no pain while undertaking sport please complete question 8a only. If you have pain while undertaking sport but it does not stop you from completing th activity, please complete question 8b only. If you have pain that stops you from completing sporting activities, please complete question 8c only.`,
  [`Se prova dolore, quanto è forte?`]: `If you have pain, how severe is it?`,
  [`Sèalle Alzate Frontali A 90° Con Elastico`]: `FRONT RAISES WITH RESISTANCE BAND`,
  [`Seated Chest Pass`]: `Seated Chest Pass`,
  [`Seated Extension-Rotation Test (DX)`]: `Seated Extension-Rotation Test (R)`,
  [`Seated Extension-Rotation Test (SX)`]: `Seated Extension-Rotation Test (L)`,
  [`Seated Shot-Put Throw`]: `Seated Shot-Put Throw`,
  [`Secco`]: `Boring`,
  [`Sedentario`]: `Sedentary`,
  [`Sedersi`]: `Sitting`,
  [`Sedersi e/o alzarsi dal water`]: `Getting on/off toilet`,
  [`Sedersi normalmente, con il ginocchio flesso`]: `Sit with your knee bent`,
  [`Seduto o sdraiato`]: `Sitting or lying`,
  [`SEGMENTI CERVICALI DEL MIDOLLO SPINALE [1-8]`]: `CERVICAL SEGMENTS [1-8]`,
  [`SEGMENTI COCCIGEI DEL MIDOLLO SPINALE [1]`]: `COCCYGEAL SEGMENTS [1-3]`,
  [`SEGMENTI LOMBARI DEL MIDOLLO SPINALE [1-5]`]: `LUMBAR SEGMENTS [1-5]`,
  [`SEGMENTI SACRALI DEL MIDOLLO SPINALE [1-5]`]: `SACRAL SEGMENTS [1-5]`,
  [`SEGMENTI TORACICI DEL MIDOLLO SPINALE [1-12]`]: `THORACIC SEGMENTS [1-12]`,
  [`Segni Associati`]: `Associated symptoms`,
  [`Segno di Trendelemburg (DX)`]: `Segno di Trendelemburg (R)`,
  [`Segno di Trendelemburg (SX)`]: `Segno di Trendelemburg (L)`,
  [`Sei in grado di estendere completamente il gomito?`]: `Are you able to fully extend your elbow?`,
  [`Sei in grado di piegare il gomito almeno fino a 90 gradi?`]: `Are you able to bend your elbow at least at 90 degrees?`,
  [`Sei in grado di riprodurre il dolore con qualche movimento o attivita' specifica?`]: `Are you able to reproduce your symptoms with a specific movement or activity?`,
  [`Sei in grado di riprodurre il dolore con un movimento o puoi descrivere un'attivita' che lo riproduce?`]: `Are you able to reproduce the pain?`,
  [`Sei in grado di riprodurre il dolore con un movimento o sei in grado di descrivere un'attivita' che ti provoca dolore?`]: `Are you able to reproduce your pain?`,
  [`Sei in grado di riprodurre il dolore in qualche modo o puoi descrivere un attivita' che lo provoca?`]: `Are you able to reproduce your pain or could you describe an activity provoking it?`,
  [`Sei in grado di riprodurre il dolore in qualche modo o puoi descrivere un'attivita' che lo riproduca?`]: `Are you able to reproduce your pain?`,
  [`Sei in grado di riprodurre il dolore in qualche modo o puoi descrivere un'attivita' che lo riproduce?`]: `Are you able to reproduce the pain?`,
  [`Sei in grado di riprodurre il dolore in qualche modo?`]: `Are you able to reproduce the pain?`,
  [`Sei portatore di pace-maker`]: `Do you have a pacemaker `,
  [`Sei stato allettato per un periodo maggiore di 3 giorni ultimamente o hai subito un intervento chirurgico importante negli ultimi 3 mesi?`]: `Have you recently been forced on the bed for more than 3 days and/or have you had a surgery within the last 3 months?`,
  [`Semilunare (DX)`]: `Lunate (R)`,
  [`Semilunare (SX)`]: `Lunate (L)`,
  [`SEMILUNARE DESTRO`]: `RIGHT LUNATE`,
  [`SEMILUNARE SINISTRO`]: `LEFT LUNATE`,
  [`Sempre`]: `Always`,
  [`SENI FRONTALI`]: `FRONTAL SINUS`,
  [`Seno`]: `Breast`,
  [`SENO CAROTICO DESTRO`]: `RIGHT CAROTID SINUS`,
  [`SENO CAROTICO SINISTRO`]: `LEFT CAROTID SINUS`,
  [`SENO CAVERNOSO DESTRO`]: `RIGHT CAVERNOUS SINUS`,
  [`SENO CAVERNOSO SINISTRO`]: `LEFT CAVERNOUS SINUS`,
  [`SENO CORONARICO`]: `CORONARY SINUS`,
  [`SENO DEL TARSO DESTRO`]: `RIGHT SINUS TARSI`,
  [`SENO DEL TARSO SINISTRO`]: `LEFT SINUS TARSI`,
  [`SENO DESTRO (F)`]: `RIGHT MAMMA (F)`,
  [`SENO INTERCAVERNOSO ANTERIORE`]: `ANTERIOR INTERCAVERNOUS SINUS`,
  [`SENO INTERCAVERNOSO POSTERIORE`]: `POSTERIOR INTERCAVERNOUS SINUS`,
  [`SENO OCCIPITALE DESTRO`]: `RIGHT OCCIPITAL SINUS`,
  [`SENO OCCIPITALE SINISTRO`]: `LEFT OCCIPITAL SINUS`,
  [`SENO PETROSO INFERIORE DESTRO`]: `RIGHT INFERIOR PETROSAL SINUS`,
  [`SENO PETROSO INFERIORE SINISTRO`]: `LEFT INFERIOR PETROSAL SINUS`,
  [`SENO PETROSO SUPERIORE DESTRO`]: `RIGHT SUPERIOR PETROSAL SINUS`,
  [`SENO PETROSO SUPERIORE SINISTRO`]: `LEFT SUPERIOR PETROSAL SINUS`,
  [`SENO RETTO`]: `STRAIGHT SINUS`,
  [`SENO SAGITTALE INFERIORE`]: `INFERIOR SAGITTAL SINUS`,
  [`SENO SAGITTALE SUPERIORE`]: `SUPERIOR SAGITTAL SINUS`,
  [`SENO SFENOPARIETALE DESTRO`]: `RIGHT SPHENOPARIETAL SINUS`,
  [`SENO SFENOPARIETALE SINISTRO`]: `LEFT SPHENOPARIETAL SINUS`,
  [`SENO SIGMOIDEO DESTRO`]: `RIGHT SIGMOID SINUS`,
  [`SENO SIGMOIDEO SINISTRO`]: `LEFT SIGMOID SINUS`,
  [`SENO SINISTRO (F)`]: `LEFT MAMMA (F)`,
  [`SENO TRASVERSO DESTRO`]: `RIGHT TRANSVERSE SINUS`,
  [`SENO TRASVERSO SINISTRO`]: `LEFT TRANSVERSE SINUS`,
  [`Sensazione di blocco`]: `Locking`,
  [`sensazione di calore o di freddo nella zona`]: `cold or heat feeling on the area`,
  [`Sensazione di debolezza`]: `Localised Weakness`,
  [`Sensazione di instabilita'`]: `Instability`,
  [`Sensazione di rigidita'`]: `Stiffness`,
  [`Sensoriale: varie`]: `Sensory: miscellaneous`,
  [`Sente fastidio all'anca e/o all'inguine?`]: `Do tou feel discomfort in your hip and/or groin?`,
  [`Sente un click o qualsiasi altro tipo di rumore a livello dell'anca e/o all'inguine?`]: `Do you hear clicking or any other type of noise from your hip and/or groin? `,
  [`sento dolore in qualsiasi movimento coinvolga la regione lombare`]: `I feel pain in every movement involving the low back`,
  [`sento dolore in specifici movimenti`]: `I feel pain doing specific movements`,
  [`Septic discitis - osteomyelitis della colonna`]: `Septic discitis - osteomyelitis of the spine`,
  [`Sesamoidite del pollice`]: `Sesamoiditis of thumb`,
  [`Settimanalmente`]: `Weekly`,
  [`SETTO DI HUNTER DESTRO`]: `RIGHT ANTEROMEDIAL INTERMUSCULAR SEPTUM`,
  [`SETTO DI HUNTER SINISTRO`]: `LEFT ANTEROMEDIAL INTERMUSCULAR SEPTUM`,
  [`SETTO INTERMUSCOLARE ANTERIORE DELLA GAMBA DESTRA`]: `ANTERIOR INTERMUSCULAR SEPTUM OF RIGHT LEG`,
  [`SETTO INTERMUSCOLARE ANTERIORE DELLA GAMBA SINISTRA`]: `ANTERIOR INTERMUSCULAR SEPTUM OF LEFT LEG`,
  [`SETTO INTERMUSCOLARE LATERALE DEL BRACCIO DESTRO`]: `LATERAL INTERMUSCULAR SEPTUM OF LEFT ARM`,
  [`SETTO INTERMUSCOLARE LATERALE DELLA COSCIA DESTRA`]: `RIGHT LATERAL FEMORAL INTERMUSCULAR SEPTUM`,
  [`SETTO INTERMUSCOLARE LATERALE DELLA COSCIA SINISTRA`]: `LEFT LATERAL FEMORAL INTERMUSCULAR SEPTUM`,
  [`SETTO INTERMUSCOLARE MEDIALE DEL BRACCIO DESTRO`]: `MEDIAL INTERMUSCULAR SEPTUM OF LEFT ARM`,
  [`SETTO INTERMUSCOLARE MEDIALE DELLA COSCIA DESTRA`]: `RIGHT MEDIAL FEMORAL INTERMUSCULAR SEPTUM`,
  [`SETTO INTERMUSCOLARE MEDIALE DELLA COSCIA SINISTRA`]: `LEFT MEDIAL FEMORAL INTERMUSCULAR SEPTUM`,
  [`SETTO INTERMUSCOLARE POSTERIORE DELLA GAMBA DESTRA`]: `POSTERIOR INTERMUSCULAR SEPTUM OF RIGHT LEG`,
  [`SETTO INTERMUSCOLARE POSTERIORE DELLA GAMBA SINISTRA`]: `POSTERIOR INTERMUSCULAR SEPTUM OF LEFT LEG`,
  [`SETTO NASALE (PARTE MEMBRANOSA)`]: `NASAL SEPTUM (MEMBRANOUS PART)`,
  [`Severa`]: `Severe`,
  [`Severità`]: `Severity`,
  [`Severità`]: `Severity`,
  [`Severo`]: `Severe`,
  [`Sezione 1 - Intensità del dolore`]: `Section 1: Pain intensity`,
  [`Sezione 10 - Svagarsi`]: `Section 10: Recreation`,
  [`Sezione 10 - Viaggi e spostamenti`]: `Section 10: Travelling`,
  [`Sezione 2 - Cura personale (lavarsi, vestirsi, etc.)`]: `Section 2: Personal care (washing, dressing, etc.)`,
  [`Sezione 3 - Alzare pesi`]: `Section 3: Lifting`,
  [`Sezione 4 - Camminare`]: `Section 4: Walking`,
  [`Sezione 4 - Leggere`]: `Section 4: Reading`,
  [`Sezione 5 - Mal di testa`]: `Section 5: Headaches`,
  [`Sezione 5 - Stare seduto/a`]: `Section 5: Sitting`,
  [`Sezione 6 - Concentrarsi`]: `Section 6: Concentration`,
  [`Sezione 6 - Stare in piedi`]: `Section 6: Standing`,
  [`Sezione 7 - Lavorare`]: `Section 7: Work`,
  [`Sezione 7 -Dormire`]: `Section 7: Sleeping`,
  [`Sezione 8 - Guidare`]: `Section 8: Driving`,
  [`Sezione 8 - Vita sessuale (se pertinente)`]: `Section 8: Sex life (if applicable)`,
  [`Sezione 9 - Attività con gli altri`]: `Section 9: Social life`,
  [`Sezione 9 - Dormire`]: `Section 9: Sleeping`,
  [`SFENOIDE`]: `SPHENOID`,
  [`SFENOIDE (GRANDE ALA DESTRA)`]: `SPHENOID (RIGHT GREATER WING)`,
  [`SFENOIDE (GRANDE ALA SINISTRA)`]: `SPHENOID (LEFT GREATER WING)`,
  [`Sfenoide (grande ala) (DX)`]: `Sphenoid (greater wing) (R)`,
  [`Sfenoide (grande ala) (SX)`]: `Sphenoid (greater wing) (L)`,
  [`SFENOIDE (PICCOLA ALA DESTRA)`]: `SPHENOID (RIGHT LESSER WING)`,
  [`SFENOIDE (PICCOLA ALA SINISTRA)`]: `SPHENOID (LEFT LESSER WING)`,
  [`SFENOIDE (PROCESSO PTERIGOIDEO LATERALE DESTRO)`]: `SPHENOID (RIGHT LATERAL PTERYGOID PROCESS)`,
  [`SFENOIDE (PROCESSO PTERIGOIDEO LATERALE SINISTRO)`]: `SPHENOID (LEFT LATERAL PTERYGOID PROCESS)`,
  [`SFENOIDE (PROCESSO PTERIGOIDEO MEDIALE DESTRO)`]: `SPHENOID (RIGHT MEDIAL PTERYGOID PROCESS)`,
  [`SFENOIDE (PROCESSO PTERIGOIDEO MEDIALE SINISTRO)`]: `SPHENOID (LEFT MEDIAL PTERYGOID PROCESS)`,
  [`SFENOIDE (PROCESSO PTERIGOSPINOSO DESTRO)`]: `SPHENOID (RIGHT PTERYGOSPINOUS PROCESS)`,
  [`SFENOIDE (PROCESSO PTERIGOSPINOSO SINISTRO)`]: `SPHENOID (LEFT PTERYGOSPINOUS PROCESS)`,
  [`SFENOIDE (SENO SFENOIDALE DESTRO)`]: `SPHENOID (RIGHT SPHENOIDAL SINUS)`,
  [`SFENOIDE (SENO SFENOIDALE SINISTRO)`]: `SPHENOID (LEFT SPHENOIDAL SINUS)`,
  [`SFENOIDE (SETTO DEI SENI SFENOIDALI)`]: `SPHENOID (SEPTUM OF SPHENOIDAL SINUSES)`,
  [`Sfilare le calze`]: `Taking off socks/stockings`,
  [`Sfinito`]: `Did you feel worn out?`,
  [`Shift anteriore`]: `Anterior shift`,
  [`Shift laterale destro`]: `Right lateral shift`,
  [`Shift laterale sinistro`]: `Left lateral shift`,
  [`Shin Edema Test (DX)`]: `Shin Edema Test (R)`,
  [`Shin Edema Test (SX)`]: `Shin Edema Test (L)`,
  [`Shin Palpation Test (DX)`]: `Shin Palpation Test (R)`,
  [`Shin Palpation Test (SX)`]: `Shin Palpation Test (L)`,
  [`Shoulder Abduction Test (Bakody's Sign)`]: `Shoulder Abduction Test (Bakody's Sign)`,
  [`Shoulder Endurance Test (SET) (DX)`]: `Shoulder Endurance Test (SET) (R)`,
  [`Shoulder Endurance Test (SET) (SX)`]: `Shoulder Endurance Test (SET) (L)`,
  [`Shoulder Shrug Sign (DX)`]: `Shoulder Shrug Sign (R)`,
  [`Shoulder Shrug Sign (SX)`]: `Shoulder Shrug Sign (L)`,
  [`si`]: `Yes`,
  [`Sì`]: `Yes`,
  [`si il dolore e' localizzato all'area del pollice`]: `Yes, the pain is localised on the thumb area`,
  [`si ma difficilmente`]: `Yes but hardly`,
  [`si posso localizzarlo suldorso del piede`]: `yes, I can localize the pain on the dorsal area of the foot`,
  [`Si sente limitato a causa del suo problema all'anca e/o all'inguine?`]: `Do you feel restricted due to your hip and/or groin problem?`,
  [`Sì, 2 mesi fa`]: `Yes, 2 months ago`,
  [`Sì, 2-3 settimane fa`]: `Yes, 2-3 weeks ago`,
  [`si, alla regione toracia`]: `Yes, the pain radiates to the thoracic region`,
  [`si, almeno una volta all'anno si presenta questo dolore`]: `Yes, I have this symptoms once a year`,
  [`si, altre sedi di irradiazione`]: `Yes, it radiates in other areas`,
  [`si, dando un pugno`]: `Yes, punching`,
  [`si, descrivi brevemente; lasciare possibilita' di scrivere`]: `Yes`,
  [`si, durante un movimento veloce del polso come per girarlo`]: `Yes, doing a fast movement with the wrist, as to turn it`,
  [`si, e' da molto tempo che ho questo dolore`]: `Yes, I've had this symptoms for a long time`,
  [`si, e' localizzato esattamente in un punto dietro al ginocchio`]: `Yes, it is localised in a specific spot on the posterior knee`,
  [`si, facilmente`]: `Yes, easily`,
  [`si, gia' ho sofferto in questa regione in passato`]: `Yes, I aready had problems on that area in the past`,
  [`si, giocando a golf o durante attivita' di lancio`]: `Yes, playing golf or after throwing activity`,
  [`si, giocando a tennis o svolgendo attivita' di carpenteria o taglio e cucito`]: `Yes, playing tennis, in carpentry activities or cutting and sewing`,
  [`si, ho dolore in qualunque movimento`]: `yes, I feel pain in every movement`,
  [`si, ho dolore quando apro la bocca`]: `Yes, I feel pain opening the mouth`,
  [`si, ho dolore quando chiudo la bocca`]: `Yes, I feel pain closing the mouth`,
  [`si, ho dolore quando sto molto tempo seduto`]: `Yes, I feel pain when sitting for a long time`,
  [`si, il braccio e' stato stirato all'indietro`]: `Yes, I pulled my arm posteriorly`,
  [`si, il dolore e' iniziato in seguito allo svolgimento eccessivo di attivita'`]: `Yes, it started during performing an excessive activity`,
  [`si, il dolore e' localizzato al centro dell'area glutea`]: `yes, the pain is localised in the middle of the glute`,
  [`si, il dolore e' localizzato al centro delle vertebre dorsali`]: `Yes, the pain is localised on the thoracic vertebrae`,
  [`si, il dolore e' localizzato al lato delle vertebre dorsali`]: `Yes, the pain is localised beside the thoracic vertebrae`,
  [`si, il dolore e' localizzato al lato delle vertebre lombari`]: `Yes, the pain is localized on the side of the lumbar vertebrae`,
  [`si, il dolore e' localizzato al lato esterno della caviglia`]: `Yes, the pain is localised on the external area of the ankle`,
  [`si, il dolore e' localizzato al lato interno della caviglia`]: `Yes, the pain is localised on the internal area of the ankle`,
  [`si, il dolore e' localizzato al tendine d'achille alla sua inserzione sul tallone`]: `Yes, the pain is localised on the insertion of Achilles tendon on the heel`,
  [`si, il dolore e' localizzato alla linea glutea vicino alla coscia`]: `yes, the pain is localised on the glute line`,
  [`si, il dolore e' localizzato alla parte occipitale`]: `Yes, my symptoms are localised on the occipital area`,
  [`si, il dolore e' localizzato alla parte orbitale`]: `Yes, my symptoms are localised on the orbital area`,
  [`si, il dolore e' localizzato alla regione inguinale`]: `Yes, the pain is localised on the inguinal region`,
  [`si, il dolore e' localizzato in un punto preciso della mano`]: `Yes, the pain is localised on a specific spot on the hand`,
  [`si, il dolore e' localizzato in un punto preciso o in una piccola area della parte posteriore della coscia`]: `Yes, the pain is localised in a specific spot of the posterior thigh`,
  [`si, il dolore e' localizzato in un punto specifico del bordo mediale della tibia`]: `Yes, the pain is localised on a specific spot on the medial margin of tibia`,
  [`si, il dolore e' localizzato in un punto specifico di una costa`]: `Yes, the pain is well localized on a apot on a rib`,
  [`si, il dolore e' localizzato in un punto specifico sulla parte anteriore della coscia`]: `yes, the pain is localised in a specific spot on the anterior thigh`,
  [`si, il dolore e' localizzato in un'area specifica`]: `Yes, the pain is localized on a specific spot`,
  [`si, il dolore e' localizzato medialmente al tendine d'achille alla sua inserzione sul tallone`]: `Yes, the pain is localised medially to the Achilles tendon`,
  [`si, il dolore e' localizzato su meta' della testa`]: `Yes, my symptoms are interesting half of my head`,
  [`si, il dolore e' localizzato su un punto specifico dell'area laterale della coscia`]: `yes, the pain is localised on a specific point on the lateral thigh`,
  [`si, il dolore e' localizzato su un punto specifico della parte anteriore della gamba`]: `Yes, the pain is localised on a specific point of the anterior leg`,
  [`si, il dolore e' localizzato su un punto specifico della parte mediale della coscia`]: `yes, the pain is localised on a specific point on the medial thigh`,
  [`si, il dolore e' localizzato su un punto specifico sulla parte esterna della gamba`]: `Yes, the pain is localised on a specific spot on the lateral leg`,
  [`si, il dolore e' localizzato sul tendine d'achille leggermente piu' in alto rispetto alla sua inserzione sul tallone`]: `Yes, the pain is localised on the Achilles tendon, above its insertion on the heel`,
  [`si, il dolore e' localizzato sulla parte alta del collo, vicino alla testa`]: `Yes, my symptoms are localised on the top of the cervical area, close to the head`,
  [`si, il dolore e' localizzato sulla parte anteriore del collo`]: `Yes, my symptoms are localised on the anterior area of the neck`,
  [`si, il dolore e' localizzato sulla parte anteriore dell'avambraccio (dal lato del palmo della mano)`]: `Yes, the pain is localised on the anterior area of the forearm `,
  [`si, il dolore e' localizzato sulla parte anteriore della caviglia`]: `Yes, the pain is localised on the anterior area of the ankle`,
  [`si, il dolore e' localizzato sulla parte esterna del ginocchio`]: `Yes, the pain is localised on the external area of the knee`,
  [`si, il dolore e' localizzato sulla parte interna del ginocchio`]: `Yes, the pain is localised on the internal area of the knee`,
  [`si, il dolore e' localizzato sulla parte laterale del collo a destra`]: `Yes, my symptoms are localised on the right lateral side of the neck`,
  [`si, il dolore e' localizzato sulla parte laterale del collo a sinistra`]: `Yes, my symptoms are localised on the left lateral side of the neck`,
  [`si, il dolore e' localizzato sulla parte laterale dell'anca`]: `Yes, the pain is localised on the lateral side of the hip`,
  [`si, il dolore e' localizzato sulla parte laterale dell'avambraccio (sul lato del pollice)`]: `Yes, the pain is localised on the lateral area of the forearm `,
  [`si, il dolore e' localizzato sulla parte posteriore del ginocchio`]: `Yes, the pain is localised on the posterior area of the knee`,
  [`si, il dolore e' localizzato sulla parte posteriore dell'avambraccio (dal lato del dorso della mano)`]: `Yes, the pain is localised on the posterior area of the forearm `,
  [`si, il dolore e' localizzato sulla rotula e sulla parte anteriore del ginocchio`]: `Yes, the pain is localised in a specific spot of the anterior knee`,
  [`si, il dolore e' localizzato sulle dita della mano`]: `Yes, the pain is localised on the fingers`,
  [`si, il dolore e' localizzato sulle vertebre della regione lombare`]: `Yes, the pain is localized on a lumbar vertebra `,
  [`si, il dolore e' presente in tutti I movimenti`]: `yes, I feel pain in every movement`,
  [`si, il dolore e' presente su un'amplia area del bordo mediale della tibia`]: `Yes, I feel pain on a wide area of the medial margin of tibia`,
  [`si, il dolore si irradia a tutto il torace`]: `Yes, the pain interests the whole thorax`,
  [`si, il dolore si irradia a tutto l'arto inferiore`]: `Yes, the pain interests the whole lower limb`,
  [`si, il dolore si irradia al braccio e al gomito`]: `Yes, the pain radiates to the upper arm and elbow`,
  [`si, il dolore si irradia al gluteo e alla schiena`]: `Yes, the pain radiates on the glutes and/or low back`,
  [`si, il dolore si irradia al petto e all'addome`]: `Yes, the pain radiates to the chest and superior abdomen`,
  [`si, il dolore si irradia al piede`]: `Yes, it radiates on the foot`,
  [`si, il dolore si irradia all'area glutea e alla schiena`]: `Yes, the pain radiates on the glutes and/or low back`,
  [`si, il dolore si irradia all'articolazione dell'anca`]: `yes, the pain radiates on the hip region`,
  [`si, il dolore si irradia alla coscia`]: `Yes, the pain radiates on the thigh`,
  [`si, il dolore si irradia alla parte anteriore della caviglia e del piede`]: `Yes, the pain is localised on the anterior part of ankle and foot`,
  [`si, il dolore si irradia alla parte mediale del ginocchio e della coscia`]: `Yes, the pain radiates on the medial side of knee and thigh`,
  [`si, il dolore si irradia alla regione cervicale`]: `Yes, the pain radiates to the cervical area`,
  [`si, il dolore si irradia alla regione del collo e al bordo mediale della scapola:`]: `Yes, the pain radiates to the neck and to the medial margin of the scapula`,
  [`si, il dolore si irradia alla regione del polpaccio`]: `Yes, the pain radiates on the calf`,
  [`si, il dolore si irradia alla regione dell'anca`]: `yes, the pain radiates on the hip region`,
  [`si, il dolore si irradia alla regione lombare`]: `Yes, the pain radiates to the lumbar region`,
  [`si, il dolore si irradia alla scapola e a tutto il braccio`]: `Yes, the pain radiates to the scapula and the whole upper limb`,
  [`si, il dolore si irradia alla schiena, alla gamba e al piede`]: `Yes, the pain radiates on the low back, on the leg and on the foot`,
  [`si, il dolore si irradia alla spalla`]: `Yes, the pain radiates to the shoulder`,
  [`si, il dolore si irradia alla testa`]: `Yes, the pain radiates to the head`,
  [`si, il dolore si irradia di lato lungo le coste`]: `Yes, the pain radiates along the ribs`,
  [`si, Il dolore si irradia lungo una costa`]: `Yes, the pain radiates along a rib`,
  [`si, il dolore si irradia o e' presente anche sulla regione lombare`]: `yes, the pain radiates/is present on the lumbar spine`,
  [`si, il dolore si presenta durante lo svolgimento di attivita' fisica sempre allo stesso momento (minutaggio o kilometri)`]: `Yes, I feel pain at the same moment (kilometers or minutes) performing physical activity`,
  [`si, il dolore si presenta durante lo svolgimento di attivita' fisica, sempre allo stesso momento (minutaggio, kilometri)`]: `Yes, I feel pain at the same moment (kilometers or minutes) performing physical activity`,
  [`si, il mio dolore e' localizzato esattamente sulla mandibola`]: `Yes, my symptoms are localised exactly on the mandible`,
  [`si, il piede e' gonfio`]: `yes, my foot is swollen`,
  [`si, il problema e' rimasto uguale`]: `Yes, but my symptoms stayed the same`,
  [`si, in un impatto sulle dita della mano`]: `Yes, an impact on the fingers`,
  [`Sì, io`]: `yes, me`,
  [`si, ma il problema e' peggiorato`]: `Yes, but my symptoms got worse`,
  [`si, mi hanno migliorato il problema`]: `Yes, my symptoms got better`,
  [`Sì, mi limita parecchio`]: `Yes, limited a lot`,
  [`Sì, mi limita parzialmente`]: `Yes, limited a little`,
  [`Sì, più di 2 mesi fa`]: `Yes, more than 2 months ago`,
  [`si, piu' volte all'anno si presenta questo dolore`]: `Yes, I have this symptoms few times during the year`,
  [`si, posso localizarlo all'alluce del piede`]: `yes, I can localize the pain on the hallux`,
  [`si, posso localizzarlo abbastanza bene sulla parte dorsale del polso, al centro del polso`]: `Yes, I can localise the pain on the dorsal area of the wrist, on the center`,
  [`si, posso localizzarlo abbastanza bene sulla parte dorsale del polso, dal lato del mignolo`]: `Yes, I can localise the pain on the dorsal area of the wrist on the little finger side`,
  [`si, posso localizzarlo abbastanza bene sulla parte dorsale del polso, dal lato del pollice`]: `Yes, I can localise the pain on the dorsal area of the wrist on the thumb side`,
  [`si, posso localizzarlo abbastanza bene sulla parte palmare del polso dal lato del mignolo`]: `Yes, I can localise the pain on the palmar area of the wrist on the little finger side`,
  [`si, posso localizzarlo abbastanza bene sulla parte palmare del polso dal lato del pollice`]: `Yes, I can localise the pain on the palmar area of the wrist on the thumb side`,
  [`si, posso localizzarlo abbastanza bene sulla parte palmare del polso, al centro del polso`]: `Yes, I can localise the pain on the palmar area of the wrist, on the center`,
  [`si, posso localizzarlo con un dito su un area specifica del lato laterale (lato del pollice) del gomito`]: `Yes, I can localize my pain with a finger on a specific spot on the lateral area of elbow`,
  [`si, posso localizzarlo con un dito su un area specifica del lato mediale (lato del mignolo) del gomito`]: `Yes, I can localize my pain with a finger on a specific spot on the medial area of elbow`,
  [`si, posso localizzarlo con un dito su un area specifica sulla parte posteriore del gomito`]: `Yes, I can localize my pain with a finger on a specific spot on the posterior area of elbow`,
  [`si, posso localizzarlo esattamente sulla parte anteriore del gomito`]: `Yes, I can localize my pain with a finger on a specific spot on the anterior area of elbow`,
  [`si, posso localizzarlo in un'area precisa del polpaccio`]: `Yes, I can localise the pain in a specific spot of the calf`,
  [`si, posso localizzarlo sull'area del tallone`]: `yes, I can localize the pain on the heel area`,
  [`si, posso localizzarlo sull'area della fascia plantare`]: `yes, I can localize the pain on the plantar fascia area`,
  [`si, posso localizzarlo sulle dita dei piedi`]: `yes, I can localize the pain on the toes`,
  [`si, posso riprodurlo con il respiro profondo o con determinati movimenti`]: `Yes, I'm able to reproduce it breathing deep or doing specific movements`,
  [`si, posso riprodurlo con specifici movimenti o attivita'`]: `Yes, I can reproduce my pain with specific movements or activities`,
  [`si, posso riprodurlo con un movimento specifico o svolgendo una specifica attivita'`]: `yes, I'm able to reproduce the pain with a specific movement or performing a specific activity`,
  [`si, posso riprodurlo estendendo al massimo il gomito`]: `Yes, I can reproduce the pain fully extending the elbow`,
  [`si, posso riprodurlo estendendo il polso ad esempio scrivendo al computer`]: `Yes, I can reproduce the pain extending the wrist (for example typing)`,
  [`si, posso riprodurlo stringendo la mano, aprendo la porta o usando un cacciavite`]: `Yes, I can reproduce my symptoms shaking hands, opening a door or using a screwdriver`,
  [`si, posso riprodurre il dolore alla flessione del ginocchio contro resistenza`]: `Yes, I can reproduce the pain bending the knee versus a resistance`,
  [`si, posso riprodurre il dolore allo stretching dei muscoli della parte posteriore della coscia`]: `Yes, I can reproduce the pain stretching my hamstring`,
  [`si, posso riprodurre il dolore con attivita' di carico (corsa, camminata, sport)`]: `Yes, I can reproduce the pain with weight-bearing activities (running, walking)`,
  [`si, posso riprodurre il dolore con dei movimenti specifici`]: `Yes, I'm able to reproduce my symptoms with specific movements`,
  [`si, posso riprodurre il dolore con specifici movimenti`]: `yes, I'm able to reproduce the pain with a specific movement or performing a specific activity`,
  [`si, posso riprodurre il dolore con specifici movimenti o attivita'`]: `Yes, I can reproduce the pain with specific movements or activities`,
  [`si, posso riprodurre il dolore con un movimento specifico di allungamento del polpaccio`]: `Yes, I can reproduce the pain stretching the calf`,
  [`si, posso riprodurre il dolore con uno specifico movimento`]: `yes, I can reproduce the pain with a specific movement`,
  [`si, posso riprodurre il dolore quando faccio forza con il polpaccio`]: `Yes, I can reproduce the pain when activate the calf muscles`,
  [`si, posso riprodurre il dolore salendo o scendendo le scale`]: `Yes, I can reproduce the pain going upstairs or downstairs`,
  [`si, posso riprodurre il dolore se cammino o corro`]: `yes, I can reproduce the pain walking or running`,
  [`si, posso riprodurre il dolore se faccio forza con I muscoli della parte posteriore della coscia`]: `Yes, I can reproduce the pain when I activate my hamstring`,
  [`si, posso riprodurre il dolore se tiro il piede verso l'alto`]: `Yes, I can reproduce the pain when I flex my ankle`,
  [`si, posso riprodurre il dolore stringendo il piede con la mano`]: `yes, I'm able to reproduce the pain squeezing the foot with my hand`,
  [`Sì, questa settimana`]: `Yes, this week`,
  [`si, si e' modificato`]: `Yes, it changed`,
  [`si, si irradia a tutta la parte posteriore dell'arto inferiore fino al piede`]: `yes, the pain radiates on the whole posterior side of the lower limb`,
  [`si, si irradia a tutta la regione della spalla`]: `Yes, the pain radiates to the whole shoulder`,
  [`si, si irradia al braccio subito sopra al gomito`]: `Yes, it radiates to the arm just above the elbow`,
  [`si, si irradia al collo`]: `Yes, it radiates to the neck`,
  [`si, si irradia al gluteo e alla parte posteriore di coscia, ginocchio e gamba`]: `Yes, the pain radiates to the glutes, posterior thigh, posterior knee and leg`,
  [`si, si irradia al gomito`]: `Yes, the pain radiates to the elbow`,
  [`si, si irradia al petto e all'addome`]: `Yes, it radiates to the chest and/or abdomen`,
  [`si, si irradia al polso e alla mano`]: `Yes, the pain radiates to the wrist and the hand`,
  [`si, si irradia al torace o all'area addominale alta`]: `Yes, the pain radiates to the thorax and superior abdomen`,
  [`si, si irradia all'area del polpaccio`]: `Yes, the pain radiates on the calf`,
  [`si, si irradia all'articolazione del ginocchio`]: `Yes, the pain radiates on the knee joint`,
  [`si, si irradia all'avambraccio sul lato mediale, alla mano e al mignolo`]: `Yes, it radiates to the medial forearm, the hand and the little finger`,
  [`si, si irradia alla caviglia`]: `yes, it radiates on the ankle`,
  [`si, si irradia alla caviglia e al piede`]: `yes, it radiates to the ankle and foot`,
  [`si, si irradia alla coscia e alla schiena`]: `yes, it radiates to the thigh and back`,
  [`si, si irradia alla faccia`]: `Yes, it radiates to the face`,
  [`si, si irradia alla parte palmare del polso`]: `Yes, it radiates to the palmar area of the wrist`,
  [`si, si irradia alla parte posteriore della coscia`]: `yes, the pain radiates on the hamstring area`,
  [`si, si irradia alla regione anteriore del braccio`]: `Yes, the pain radiates to the anterior arm`,
  [`si, si irradia alla regione cervicale`]: `Yes, the pain radiates to the cervical spine`,
  [`si, si irradia alla regione del gluteo`]: `Yes, the pain radiates to the glutes`,
  [`si, si irradia alla regione dell'anca e/o della coscia anteriore`]: `Yes, the pain radiates to the hip and/or anterior thigh`,
  [`si, si irradia alla regione della gamba`]: `Yes, it radiates on the leg`,
  [`si, si irradia alla regione della mano`]: `Yes, it radiates to the hand`,
  [`si, si irradia alla regione glutea`]: `yes, it radiates on the gluteal area`,
  [`si, si irradia alla regione glutea e lombare`]: `Yes, the pain radiates on the glutes and/or low back`,
  [`si, si irradia alla regione lombare`]: `yes, it radiates on the lumbar region`,
  [`si, si irradia alla regione posteriore o laterale della coscia`]: `Yes, the pain radiates to the posterior and/or lateral thigh`,
  [`si, si irradia alla spalla`]: `Yes, it radiates to the shoulder`,
  [`si, si irradia alla spalla e al collo`]: `Yes, it radiates to the shoulder and/or the neck`,
  [`si, si irradia alla testa`]: `Yes, it radiates to the head`,
  [`si, si irradia alle dita della mano`]: `Yes, it radiates to the fingers`,
  [`si, si irradia su tutto il braccio fino alla regione cervicale`]: `Yes, it radiates to the upper arm, shoulder and cervical spine`,
  [`si, si irradia verso l'avambraccio e il gomito`]: `Yes, it radiates to the forearm and the elbow`,
  [`si, sono caduto sulla mano`]: `Yes, I fell on my hand`,
  [`si, sono caduto sulla spalla`]: `Yes, I fell on my shoulder`,
  [`si, sono in grado di percepire il dolore con un movimento o un'attivita' specifica`]: `Yes, I'm able to reproduce the pain during a specific movement or activity`,
  [`si, un atterraggio forte sulla caviglia, come un insaccamento`]: `Yes, landing heavy on the ankle`,
  [`Sì, un mese fa`]: `Yes, a month ago`,
  [`Sì, un mio familiare`]: `yes, a family member`,
  [`si, una caduta con la mano aperta`]: `Yes, I fell on my open hand`,
  [`si, una caduta sulla mano`]: `Yes, falling on the hand`,
  [`si, una distorsione della caviglia con il piede puntato a terra`]: `Yes, an ankle sprain with the foot locked on the floor`,
  [`si, una distorsione della caviglia con il piede verso l'esterno`]: `yes, ankle sprain in lateral direction`,
  [`si, una distorsione della caviglia con il piede verso l'interno`]: `Yes, ankle sprain in medial direction`,
  [`Sì, una settimana fa`]: `Yes, the last week`,
  [`si: specificare in che modo`]: `Yes`,
  [`Side Bridge Leg Circle`]: `Side Bridge Leg Circle`,
  [`Side Bridge Test (DX)`]: `Side Bridge Test (R)`,
  [`Side Bridge Test (SX)`]: `Side Bridge Test (L)`,
  [`Side Deltoid Raise`]: `Side Deltoid Raise`,
  [`Side Lat Raise Seated`]: `Side Lat Raise Seated`,
  [`Side Plank Abduzione`]: `SIDE PLANK HIP ABDUCTION`,
  [`Side Plank Alto 2 Appoggi Stella Dinamico`]: `ELBOW SIDE STAR PLANK WITH HIP ABDUCTION`,
  [`Side Plank Alto Iso 2 Appoggi Stella`]: `ELBOW SIDE STAR PLANK`,
  [`Side Plank Alto Passo Aperto`]: `SIDE HAND PLANK OPEN STANCE`,
  [`Side Plank Alto Piedi Uniti`]: `ISOMETRIC SIDE PLANK`,
  [`Side Plank Alto Piedi Uniti Sali E Scendi`]: `DYNAMIC SIDE PLANK`,
  [`Side Plank Avanti Dietro`]: `SIDE PLANK TOUCH FORWARD AND BACKWARD`,
  [`Side Plank Azione Corsa Gamba`]: `CYCLE SIDE PLANK`,
  [`Side Plank Con Rematore + Abduzione Con Elastico`]: `SIDE PLANK WITH ROW AND ABDUCTION`,
  [`Side Plank Con Rematore Con Elastico`]: `BAND SIDE PLANK WITH ROW`,
  [`Side Plank Con Rotazione`]: `ELBOW SIDE PLANK WITH ROTATION`,
  [`Side Plank Con Rotazione Con Elastico`]: `BAND SIDE PLANK WITH ROTATION`,
  [`Side Plank Dinamico`]: `DYNAMIC SIDE PLANK OPEN STANCE`,
  [`Side Plank Facilitato`]: `ISOMETRIC SHORT LEVER SIDE PLANK`,
  [`Side Plank Facilitato Dinamico`]: `SHORT LEVER DYNAMIC SIDE PLANK`,
  [`Side Plank Facilitato Stella`]: `ISOMETRIC SHORT LEVER ELBOW SIDE PLANK HIP ABDUCTION`,
  [`Side Plank Facilitato Stella Dinamico`]: `SHORT LEVER ELBOW SIDE PLANK HIP ABDUCTION`,
  [`Side Plank Flessione Estensione`]: `SIDE PLANK HIP FLEXION`,
  [`Side Plank Interno Piede`]: `SIDE PLANK OPEN STANCE TO COPENHAGEN`,
  [`Side Plank Interno Piede Dinamico`]: `SIDE PLANK COPENHAGEN`,
  [`Side Plank Interno Piede Estensione Gamba`]: `SIDE PLANK COPENHAGEN LEG EXTENSION`,
  [`Side Plank Iso`]: `ISOMETRIC SIDE PLANK OPEN STANCE`,
  [`Side Plank Marciato`]: `MARCHING SIDE ELBOW PLANK`,
  [`Side Plank Piedi Uniti`]: `ISOMETRIC SIDE ELBOW PLANK`,
  [`Side Plank Piedi Uniti Dinamico`]: `DYNAMIC SIDE ELBOW PLANK`,
  [`Side Plank Stella`]: `ISOMETRIC STAR SIDE ELBOW PLANK`,
  [`Side Plank Stella Dinamico`]: `DYNAMIC STAR SIDE ELBOW PLANK`,
  [`side strain`]: `Side strain`,
  [`Side To Side Supine Row`]: `Side To Side Supine Row`,
  [`Side-bending collo (DX)`]: `Neck side-bending (R)`,
  [`Side-bending collo (SX)`]: `Neck side-bending (L)`,
  [`Side-bending destro`]: `Right side-bending`,
  [`Side-bending sinistro`]: `Left side-bending`,
  [`Side-bending tronco (DX)`]: `Trunk side-bending (R)`,
  [`Side-bending tronco (SX)`]: `Trunk side-bending (L)`,
  [`Side-bending vertebre cervicali (DX)`]: `Cervical vertebrae side-bending (R)`,
  [`Side-bending vertebre cervicali (SX)`]: `Cervical vertebrae side-bending (L)`,
  [`Side-bending/rotation destro`]: `Righ side-bending/rotation`,
  [`Side-bending/rotation sinistro`]: `Left side-bending/rotation`,
  [`Side-Lying Slump Test (DX)`]: `Side-Lying Slump Test (R)`,
  [`Side-Lying Slump Test (SX)`]: `Side-Lying Slump Test (L)`,
  [`Side-Step test`]: `Side-Step test`,
  [`Sidearm Medicine Ball Throw Test (DX)`]: `Sidearm Medicine Ball Throw Test (R)`,
  [`Sidearm Medicine Ball Throw Test (SX)`]: `Sidearm Medicine Ball Throw Test (L)`,
  [`Sign of the Buttock Test`]: `Sign of the Buttock Test`,
  [`Silbernagel KG, Thomeé R, Karlsson J. Cross-cultural adaptation of the VISA-A questionnaire, an index of clinical severity for patients with Achilles tendinopathy, with reliability, validity and structure evaluations. BMC Musculoskelet Disord. 2005 Mar ...`]: `Silbernagel KG, Thomeé R, Karlsson J. Cross-cultural adaptation of the VISA-A questionnaire, an index of clinical severity for patients with Achilles tendinopathy, with reliability, validity and structure evaluations. BMC Musculoskelet Disord. 2005 Mar ...`,
  [`SINCONDROSI PETROOCCIPITALE DESTRA`]: `RIGHT PETROOCCIPITAL SYNCHONDROSIS`,
  [`SINCONDROSI PETROOCCIPITALE SINISTRA`]: `LEFT PETROOCCIPITAL SYNCHONDROSIS`,
  [`SINCONDROSI SFENOETMOIDALE`]: `SPHENOETHMOIDAL SYNCHONDROSIS`,
  [`SINCONDROSI SFENOOCCIPITALE`]: `SPHENOOCCIPITAL SYNCHONDROSIS`,
  [`SINCONDROSI SFENOPETROSA DESTRA`]: `RIGHT SPHENOPETROSAL SYNCHONDROSIS`,
  [`SINCONDROSI SFENOPETROSA SINISTRA`]: `LEFT SPHENOPETROSAL SYNCHONDROSIS`,
  [`SINDESMOSI RADIOULNARE DESTRA`]: `RIGHT RADIOULNAR SYNDESMOSIS`,
  [`SINDESMOSI RADIOULNARE SINISTRA`]: `LEFT RADIOULNAR SYNDESMOSIS`,
  [`SINDESMOSI TIBIOPERONEALE DISTALE DESTRA`]: `RIGHT TIBIOFIBULAR SYNDESMOSIS`,
  [`SINDESMOSI TIBIOPERONEALE DISTALE SINISTRA`]: `LEFT TIBIOFIBULAR SYNDESMOSIS`,
  [`Sinding-Larsen-Johansson`]: `Sinding-Larsen-Johansson syndrome`,
  [`Sindrome compartimentale`]: `Compartimental syndrome`,
  [`Sindrome compartimentale a localizzazione multipla della gamba`]: `Compartment syndrome multiple sites lower leg`,
  [`sindrome compartimentale anteriore acuta della coscia`]: `acute compartment syndrome of anterior thigh`,
  [`Sindrome compartimentale anteriore acuta della gamba (escluse quelle da frattura di tibia)`]: `Acute anterior compartment syndrome`,
  [`Sindrome compartimentale anteriore della gamba`]: `Anterior compartment syndrome `,
  [`sindrome compartimentale cronica anteriore,`]: `Anterior leg chronic compartment syndrome`,
  [`sindrome compartimentale cronica posteriore profonda`]: `chronic compartment syndrome of deep posterior compartment of leg`,
  [`sindrome compartimentale cronica posteriore superficiale`]: `chronic compartment syndrome of superficial posterior compartment of leg`,
  [`Sindrome compartimentale del piede`]: `Foot muscle compartment syndrome`,
  [`Sindrome compartimentale dell'avambraccio`]: `Forearm compartment syndrome`,
  [`sindrome compartimentale dell'avambraccio,`]: `compatimental syndrome of forearm`,
  [`Sindrome compartimentale della coscia`]: `Compartment Syndrome of Thigh`,
  [`Sindrome compartimentale laterale della gamba`]: `Lateral (peroneal) compartment syndrome`,
  [`Sindrome compartimentale muscolare`]: `Muscle compartment syndrome`,
  [`Sindrome compartimentale posteriore della gamba`]: `Posterior compartment syndrome`,
  [`Sindrome compartimentale posteriore profonda della gamba`]: `Deep posterior compartment syndrome`,
  [`sindrome da eccessiva pressione laterae`]: `Excessive lateral pressure syndrome`,
  [`Sindrome da fatica cronica`]: `Chronic Fatigue Syndrome`,
  [`sindrome da frizione della bendelletta ileo-tibiale`]: `Iliotibial band friction syndrome`,
  [`Sindrome da impatto ulnare`]: `Ulnar abutment syndrome`,
  [`Sindrome da inappropriata secrezione di ormone antidiuretico`]: `Syndrome of inappropriate antidiuretic hormone (ADH) secretion (SIADH)`,
  [`sindrome da intersezione`]: `intersection syndrome`,
  [`sindrome da stress mediale della tibia,`]: `Medial tibia stress syndrome`,
  [`sindrome del canale di Guyon`]: `Guyon's canal syndrome`,
  [`Sindrome del colon irritabile`]: `Irritable colon syndrome`,
  [`sindrome del cuboide`]: `cuboid syndrome`,
  [`sindrome del piriforme`]: `piriformis syndrome`,
  [`Sindrome del piriforme (con impingement del nervo sciatico)`]: `Piriformis syndrome (with sciatic nerve impingement)`,
  [`Sindrome del QT lungo`]: `Long QT syndrome`,
  [`sindrome del seno del tarso`]: `Sinus tarsi syndrome`,
  [`Sindrome del seno del tarso (sinovite dell'articolazione sottoastragalica)`]: `Sinus tarsi syndrome (subtalar joint synovitis)`,
  [`sindrome del tunnel carpale`]: `Carpal tunnel syndrome`,
  [`Sindrome del Tunnel Carpale`]: `Carpal Tunnel Syndrome`,
  [`sindrome del tunnel tarsale`]: `Tarsal tunnel syndrome`,
  [`Sindrome del tunnel tarsale`]: `Tarsal tunnel syndrome`,
  [`Sindrome della bandelletta ileotibiale`]: `Iliotibial band syndrome`,
  [`sindrome dello stretto toracico`]: `Thoracic outlet syndrome`,
  [`Sindrome dello stretto toracico (neurologica)`]: `Thoracic Outlet Syndrome (neural)`,
  [`Sindrome dello stretto toracico (vascolare)`]: `Shoulder vascular thoracic outlet injury`,
  [`Sindrome di Guillan-Barrè`]: `Guillian-Barre' syndrome`,
  [`Sindrome di Guillan-Barre'`]: `Guillain-Barre' syndrome`,
  [`Sindrome di Osgood-Schlatter`]: `Osgood-Schlatter syndrome`,
  [`Sindrome di Reiter`]: `Reiter's syndrome`,
  [`sindrome di T4`]: `T4 syndrome`,
  [`Sindrome di Wolff-Parkinson-White`]: `Wolff-Parkinson-White syndrome`,
  [`Sindrome dolorosa regionale complessa del piede`]: `Chronic regional pain syndrome foot`,
  [`sindrome dolorosa regionale complessa di tipo 1`]: `Complex regional pain syndrome type 1`,
  [`sindrome femoro-rotulea`]: `Patello-femoral syndrome`,
  [`Sindrome inserzionale`]: `Intersection syndrome`,
  [`Sindrome metabolica`]: `Metabolic syndrome`,
  [`Sindrome ovarica policistica`]: `PCOS (Polycystic ovary syndrome) `,
  [`Sindrome regionale dolorosa complessa alla mano`]: `Chronic regional pain syndrome`,
  [`SINFISI MANUBRIOSTERNALE`]: `MANUBRIOSTERNAL JOINT`,
  [`SINFISI PUBICA`]: `PUBIC SYMPHYSIS`,
  [`SINFISI XIFOSTERNALE`]: `XIPHISTERNAL JOINT`,
  [`Single Adductor Test (DX)`]: `Single Adductor Test (R)`,
  [`Single Adductor Test (SX)`]: `Single Adductor Test (L)`,
  [`Single Arm Db Farmers Carry`]: `Single Arm DB Farmers Carry`,
  [`Single Arm Dumbbell Bench`]: `Single Arm Dumbbell Bench`,
  [`Single Arm Dumbbell Row`]: `Single Arm Dumbbell Row`,
  [`Single Arm Dumbbell Row Elbow Wide`]: `Single Arm Dumbbell Row Elbow Wide`,
  [`Single Arm Med Ball Toss`]: `Single Arm Med Ball Toss`,
  [`Single Leg Back Squat`]: `Single Leg Back Squat`,
  [`Single Leg Box Eccentric Squat`]: `Single Leg Box Eccentric Squat`,
  [`Single Leg Calf Raise (DX)`]: `Single Leg Calf Raise (R)`,
  [`Single Leg Calf Raise (SX)`]: `Single Leg Calf Raise (L)`,
  [`Single Leg Crossover Hop for distance (DX)`]: `Single Leg Crossover Hop for distance (R)`,
  [`Single Leg Crossover Hop for distance (SX)`]: `Single Leg Crossover Hop for distance (L)`,
  [`Single Leg Dumbbell Front Squat`]: `Single Leg Dumbbell Front Squat`,
  [`Single Leg Eccentric Leg Press`]: `Single Leg Eccentric Leg Press`,
  [`Single Leg Good Morning`]: `Single Leg Good Morning`,
  [`Single Leg Heel Raise Test (DX)`]: `Single Leg Heel Raise Test (R)`,
  [`Single Leg Heel Raise Test (SX)`]: `Single Leg Heel Raise Test (L)`,
  [`Single Leg Hop (DX)`]: `Single Leg Hop (R)`,
  [`Single Leg Hop (SX)`]: `Single Leg Hop (L)`,
  [`Single Leg Hop Test (DX)`]: `Single Leg Hop Test (R)`,
  [`Single Leg Hop Test (SX)`]: `Single Leg Hop Test (L)`,
  [`Single Leg Hyperextension Test`]: `Single Leg Hyperextension Test`,
  [`Single Leg Press`]: `Single Leg Press`,
  [`Single Leg Romain Deadlift`]: `Single Leg Romain Deadlift`,
  [`Single Leg Split Dumbbell Squat`]: `Single Leg Split Dumbbell Squat`,
  [`Single Leg Squat`]: `Single Leg Squat`,
  [`Single Leg Squat (DX)`]: `Single Leg Squat (R)`,
  [`Single Leg Squat (SX)`]: `Single Leg Squat (L)`,
  [`Single Leg Squat Test for repetition (DX)`]: `Single Leg Squat Test for repetition (R)`,
  [`Single Leg Squat Test for repetition (SX)`]: `Single Leg Squat Test for repetition (L)`,
  [`Single Leg Stance (DX)`]: `Single Leg Stance (R)`,
  [`Single Leg Stance (SX)`]: `Single Leg Stance (L)`,
  [`Single Leg Stance Test (DX)`]: `Single Leg Stance Test (R)`,
  [`Single Leg Stance Test (SX)`]: `Single Leg Stance Test (L)`,
  [`Single Leg T Rdl`]: `Single Leg T RDL`,
  [`Single Straight Leg Deadlift`]: `Single Straight Leg Deadlift`,
  [`Sinovite cronica del pollice`]: `Chronic synovitis of thumb`,
  [`Sinovite cronica del polso`]: `Chronic synovitis of wrist`,
  [`Sinovite cronica dell'articolazione inter-falangea del pollice`]: `Chronic Synovitis of IP joint thumb`,
  [`Sinovite cronica dell'articolazione metacarpo-falangea del pollice`]: `Chronic synovitis of 1st MCP joint`,
  [`Sinovite cronica dell'articolazione/I interfalangea/e distale/i`]: `Chronic synovitis of DIP joint(s)`,
  [`Sinovite cronica dell'articolazione/I interfalangea/e prossimale/i`]: `Chronic synovitis of PIP joint(s)`,
  [`Sinovite cronica dell'articolazione/I metacarpofalangea/e`]: `Chronic synovitis of MCP joint(s)`,
  [`Sinovite cronica della I articolazione carpo-metacarpale`]: `Chronic synovitis of 1st CMC joint `,
  [`Sinovite cronica delle dita`]: `Chronic synovitis of fingers`,
  [`sinovite del ginocchio`]: `knee joint synovitis`,
  [`sinovite dell'anca`]: `hip joint synovitis`,
  [`Sinovite dell'articolazione del ginocchio`]: `Knee Joint synovitis`,
  [`Sinovite dell'articolazione dell'anca`]: `Synovitis of hip joint`,
  [`Sinovite dell'articolazione della caviglia`]: `Ankle joint synovitis`,
  [`sinovite dell'articolazione metatarso-falangea (metatarsalgia)`]: `metatarso-phalangeal joint synovitis (metatarsalgya)`,
  [`Sinovite dell'articolazione sternoclavicolare`]: `Synovitis of Sternoclavicular joint`,
  [`Sinovite dell'articolazione/I metatarso-falangea/e`]: `Synovitis of MTP joint(s)`,
  [`Sinovite di articolazione del mesopiede`]: `Synovitis of midfoot joints`,
  [`Sinovite di articolazione del torace`]: `Synovitis of Chest Joint`,
  [`sinovite radio-omerale`]: `radio-humeral sinovitis`,
  [`Sinovite/capsulite`]: `Synovitis/capsulitis`,
  [`Sinovite/impingement al polso/mano`]: `Wrist and Hand Impingement/Synovitis`,
  [`Sinovite/intrappolamento/borsite di caviglia non altrimenti specificate`]: `Ankle synovitis/Impingement/Bursitis`,
  [`Sinovite/intrappolamento/lesione biomeccanica del piede`]: `Synovitis/Impingement/Biomechanical Lesion of Foot`,
  [`Sintomatologia`]: `Symptomatology`,
  [`Sintomi motori`]: `Motor Symptoms`,
  [`Sintomi Motori`]: `Motor symptoms`,
  [`Sintomi sensitivi`]: `Sensory Symptoms`,
  [`Sintomi Sensitivi`]: `Sensory symptoms`,
  [`Sintomi Sistemici`]: `Systemic symptoms`,
  [`Sintomi: le domande che seguono riguardano i sintomi e le difficoltà che ha provato all'anca nel corso dell'ultima settimana`]: `Symptoms : These questions should be answered thinking of your hip symptoms during the last week`,
  [`Sintomi: risponda a queste domande considerando I sintomi e le difficoltà incontrate a carico della sua anca e/o dell'inguine durante le attività fisiche svolte la scorsa settimana`]: `Symptoms: These questions should be answered considering your hip and/or groin symptoms and difficulties during the past week`,
  [`Sintomo`]: `Symptom`,
  [`Sinusite`]: `Sinusitis`,
  [`Siramento del tendine prossimale del sartorio`]: `Sartorius tendon strain`,
  [`Sistema Nervoso`]: `Nervous system`,
  [`Sistemi multipli o non specificati`]: `Multiple systems`,
  [`Sit-Outs`]: `SIT-OUTS`,
  [`Sit-Up Endurance Test`]: `Sit-Up Endurance Test`,
  [`Sitting Extension-Rotation Test`]: `Sitting Extension-Rotation Test`,
  [`Sitting Forward Bending Test`]: `Sitting Forward Bending Test`,
  [`Six Meter Timed Hop Test (DX)`]: `Six Meter Timed Hop Test (R)`,
  [`Six Meter Timed Hop Test (SX)`]: `Six Meter Timed Hop Test (L)`,
  [`Skin infection toenail - incl infected ingrown toenail`]: `Skin infection toenail - incl infected ingrown toenail`,
  [`Slalom Test`]: `Slalom Test`,
  [`Slump Test (DX)`]: `Slump Test (R)`,
  [`Slump Test (SX)`]: `Slump Test (L)`,
  [`Smith, M. V., Calfee, R. P., Baumgarten, K. M., Brophy, R. H., & Wright, R. W. (2012). Upper Extremity-Specific Measures of Disability and Outcomes in Orthopaedic Surgery. The Journal of Bone and Joint Surgery. American Volume., 94(3), 277–285.`]: `Smith, M. V., Calfee, R. P., Baumgarten, K. M., Brophy, R. H., & Wright, R. W. (2012). Upper Extremity-Specific Measures of Disability and Outcomes in Orthopaedic Surgery. The Journal of Bone and Joint Surgery. American Volume., 94(3), 277–285.`,
  [`Soffocante`]: `Soffocating`,
  [`Softball`]: `Softball`,
  [`Soleo Estensione Sulle Punte Bipodalico Concentrico Eccentrico`]: `CALF RAISES BENT KNEES`,
  [`Soleo Estensione Sulle Punte Bipodalico Concentrico Monopodalico Eccentrico`]: `CALF RAISES BENT KNEES + ECCENTRIC`,
  [`Soleo Estensione Sulle Punte Bipodalico Ginocchio Piegato`]: `ISOMETRIC CALF RAISES BENT KNEES`,
  [`Sollevando un oggetto pesante`]: `When lifting a heavy object`,
  [`Sollevare o portare le borse della spesa`]: `Lifting or carrying groceries`,
  [`Sollevare un oggetto, come una borsa della spesa dal pavimento`]: `Lifting an object from the floor`,
  [`Sollevarsi da una sedia, spingendosi sulla mano malata`]: `Use my affected hand to push up from a chair`,
  [`Solo modesta restrizione della mia attività. Riesco ad utilizzare le braccia al di sopra del livello delle spalle`]: `Function: Slight restriction only; able to work above shoulder level`,
  [`Sono assolutamente d'accordo`]: `Strongly agree`,
  [`Sono d'accordo`]: `Agree`,
  [`Sono presenti "click" nell'articolazione o hai avuto episodi di blocco del ginocchio?`]: `Do you feel clicking or have you had locking episodes on your knee?`,
  [`Sono presenti crepitii nel gomito?`]: `Do you feel crepitus on the elbow?`,
  [`Sono presenti crepitii nel tuo ginocchio?`]: `Do you feel crepitus on the knee?`,
  [`Sono presenti deformita' del piede o delle dita del piede?`]: `Are there deformities on your foot, hallux or toes?`,
  [`Sono presenti evidenti atrofie muscolari sulla regione? (DX)`]: `Can you observe muscle wasting? (R)`,
  [`Sono presenti evidenti atrofie muscolari?`]: `Can you observe muscle wasting?`,
  [`Sono presenti evidenti atrofie muscolari? (DX)`]: `Can you observe muscle wasting?`,
  [`Sono presenti evidenti atrofie muscolari? (SX)`]: `Can you observe muscle wasting? (L)`,
  [`Sono presenti evidenti deformita' o protuberanze ?`]: `Can you observe deformities of bumps?`,
  [`Sono presenti evidenti deformita' o protuberanze?`]: `Can you observe deformities of bumps?`,
  [`Sono presenti evidenti deformita' o protuberanze? (DX)`]: `Can you observe deformities of bumps? (R)`,
  [`Sono presenti evidenti deformita' o protuberanze? (SX)`]: `Can you observe deformities of bumps? (L)`,
  [`Sono presenti evidenti ecchimosi cutanee?`]: `Can you observe bruise?`,
  [`Sono presenti evidenti ecchimosi cutanee? (DX)`]: `Can you observe bruise? (R)`,
  [`Sono presenti evidenti ecchimosi cutanee? (SX)`]: `Can you observe bruise? (L)`,
  [`Sono presenti evidenti gonfiori?`]: `Can you observe swelling?`,
  [`Sono presenti evidenti gonfiori? (DX)`]: `Can you observe swelling? (R)`,
  [`Sono presenti evidenti gonfiori? (SX)`]: `Can you observe swelling? (L)`,
  [`Sono presenti evidenti posizioni antalgiche o movimenti compensatori?`]: `Can you observe obvious compensations?`,
  [`Sono presenti evidenti posizioni antalgiche o movimenti compensatori? (DX)`]: `Can you observe obvious compensations? (R)`,
  [`Sono presenti evidenti posizioni antalgiche o movimenti compensatori? (SX)`]: `Can you observe obvious compensations? (L)`,
  [`Sono presenti parestesie sul ginocchio`]: `Have you got paraesthesia, tingling, numbness on your knee region?`,
  [`Sono presenti parestesie sull'area del ginocchio?`]: `Have you got paresthesia on your knee?`,
  [`Sono presenti parestesie, formicolii, sensazione di caldo o di freddo alla gamba?`]: `Have you got paresthesia, tingling or hot/cold feeling on your leg?`,
  [`Sono presenti parestesie, formicolii, sensazione di freddo o di calore sul polpaccio?`]: `Have you got paresthesia, tingling or hot/cold feeling on your calf?`,
  [`Sono presenti parestesiee o formicolii o intorpidimenti sul piede?`]: `Have you got paresthesia, tingling or numbness on your foot?`,
  [`Sono presenti rush cutanei?`]: `Can you observe cutaneous rash?`,
  [`Sono presenti rush cutanei? (DX)`]: `Can you observe cutaneous rash? (R)`,
  [`Sono presenti rush cutanei? (SX)`]: `Can you observe cutaneous rash? (L)`,
  [`Sono presenti vertigini?`]: `Have you got dizziness?`,
  [`SOPRACCIGLIO DESTRO`]: `RIGHT EYEBROW`,
  [`SOPRACCIGLIO SINISTRO`]: `LEFT EYEBROW`,
  [`Sordità`]: `Deafness`,
  [`Spalla`]: `Shoulder`,
  [`Spalla (DX)`]: `Shoulder (R)`,
  [`Spalla (SX)`]: `Shoulder (L)`,
  [`Spalla Abduzione Con Elastico Monolaterale`]: `LATERAL RAISE WITH RESISTANCE BAND`,
  [`Spalla Adduzione Con Elastico`]: `ARM ADDUCTION WITH RESISTANCE BAND`,
  [`Spalla Distensione Sopra La Testa Monolaterale`]: `SHOULDER PRESS WITH RESISTANCE BAND`,
  [`Spalla Extrarotazione Gomito A 90°`]: `EXTRAROTATION 90° WITH RESISTANCE BAND`,
  [`Spalla Flessione Con Elastico Con Braccia A I`]: `SHOULDER FLEXION "I" WITH RESISTANCE BAND`,
  [`Spalla Flessione Con Elastico Con Braccia A Y`]: `SHOULDER FLEXION "Y" WITH RESISTANCE BAND`,
  [`Spalla Intrarotazione Con Elastico`]: `INTRAROTATION WITH RESISTANCE BAND`,
  [`Spalla Intrarotazione Con Elastico 2`]: `INTRAROTATION WITH RESISTANCE BAND (HANDLE)`,
  [`Spalle Abduzioni Con Elastico Braccia Tese`]: `PULL APART WITH RESITANCE BAND`,
  [`Spalle Abduzioni Con Elastico Braccia Tese Busto Flesso`]: `PULL APART PARALLEL TORSO WITH RESITANCE BAND`,
  [`Spalle Distensione Sopra La Testa Anti Estensione`]: `SHOULDER PRESS WITH RESISTANCE BAND`,
  [`Spalle Distensione Sopra La Testa Anti Estensione Con Affondo`]: `LUNGE SHOULDER PRESS WITH RESISTANCE BAND`,
  [`Spalle Distensione Sopra La Testa Anti Estensione Con Passo Avanti`]: `STEP FORWARD SHOULDER PRESS WITH RESISTANCE BAND`,
  [`Spalle Distensione Sopra La Testa Anti Estensione Con Sollevamento Ginocchio`]: `SHOULDER PRESS KNEE DRIVE WITH RESISTANCE BAND`,
  [`Spalle Distensione Sopra La Testa Anti Estensione Passo Aperto`]: `SPLIT STANCE SHOULDER PRESS WITH RESISTANCE BAND`,
  [`Spasmo muscolare o trigger point al collo, compreso il torcicollo`]: `Neck muscle trigger pts/spasm/torticollis`,
  [`Spasmo/trigger point dei muscoli del polpaccio`]: `Calf muscle cramps/spasm`,
  [`Spasmo/trigger point muscolare di anca/inguine`]: `Groin soreness or trigger points`,
  [`Spasticità generalizzata`]: `Generalised tight muscles/spasticity`,
  [`Spaventevole`]: `Frightful`,
  [`Spaziale`]: `Spatial`,
  [`specifici movimenti`]: `Specific movements`,
  [`Specifico per dolore nella regione del tendine rotuleo (NB: alcune persone conoscono questo tendine come tendine patellare), nella parte anteriore del ginocchio`]: `Specific for pain on the patellar tendon area`,
  [`Speed's Test (DX)`]: `Speed's Test (R)`,
  [`Speed's Test (SX)`]: `Speed's Test (L)`,
  [`Spesso`]: `Often`,
  [`Spina Bifida`]: `Spina Bifida`,
  [`Splalla Flessione A Braccio Teso`]: `FRONT RAISE WITH RESISTANCE BAND`,
  [`Split Squat Con Anti Rotazione Con Elastico`]: `PALLOF PRESS SPLIT SQUAT`,
  [`Split Squat Decelerativo`]: `DECELERATION SPLIT SQUAT`,
  [`Spondilite anchilosante`]: `Ankylosing spondylitis`,
  [`Spondiloartropatie`]: `Spondyloarthropathy`,
  [`spondilolisi`]: `Spondylosis`,
  [`spondilolistesi`]: `Spondylolisthesis`,
  [`Spondilolistesi`]: `Spondylolisthesis lumbar`,
  [`Spondilolistesi ad ogni livello`]: `Spondylolisthesis any Level`,
  [`Spondilolistesi lombare di II-III grado`]: `Grade 2-3 Spondylolisthesis lumbar spine`,
  [`Spondilolistesi lombare di IV grado`]: `Grade 4 Spondylolisthesis lumbar spine`,
  [`Sporgente`]: `Bulkling`,
  [`Sport: quanta difficoltà riscontra nell'eseguire le seguenti attività sportive:`]: `Sport Subscale: Because of your foot and ankle, how much difficulty do you have with:`,
  [`Sports hernia/tendinopatia addominale`]: `Sports hernia/abdominal tendinopathy`,
  [`Spossante`]: `Exhausting`,
  [`Spostamento della lente a contatto`]: `Contact lens displacement`,
  [`Spurling's Test`]: `Spurling's Test`,
  [`Spurling's Test (DX)`]: `Spurling's Test (R)`,
  [`Spurling's Test (SX)`]: `Spurling's Test (L)`,
  [`Squat`]: `Squat`,
  [`Squat Al Muro Dinamico Con Fitbal`]: `FITBALL WALL SQUAT`,
  [`Squat Al Muro Iso Angolo 90° Con Fitbal + Soleo Dinamico`]: `FITBALL WALL SQUAT CALF RAISE`,
  [`Squat Al Muro Iso Angolo Aperto`]: `ISOMETRIC WALL 1/2 SQUAT`,
  [`Squat Al Muro Iso Angolo Aperto + Soleo Iso`]: `ISOMETRIC WALL 1/2 SQUAT CALF RAISE`,
  [`Squat Al Muro Iso Parallelo`]: `ISOMETRIC WALL PARALLEL SQUAT`,
  [`Squat Al Muro Iso Parallelo + Soleo Dinamico`]: `ISOMETRIC WALL SQUAT DINAMIC CALF RAISE`,
  [`Squat Al Muro Iso Parallelo + Soleo Iso`]: `ISOMETRIC WALL SQUAT CALF RAISE`,
  [`Squat Basso Affondo Dietro`]: `AIR SQUAT TO REVERSE LUNGE`,
  [`Squat Con Antirotazione`]: `PALLOF PRESS SQUAT`,
  [`Squat Con Elastico`]: `SQUAT WITH BANDED KNEE`,
  [`Squat Con Elastico Al Collo`]: `BACK SQUAT WITH RESISTANCE BAND`,
  [`Squat Con Elastico Avanti`]: `DECELERATION SQUAT`,
  [`Squat Con Estensione Delle Anche`]: `SQUAT HIP EXTENSION WITH RESISTANCE BAND`,
  [`Squat Con Tirante`]: `SPANISH SQUAT`,
  [`Squat Decelerativo Con Anti Rotazione Con Elastico`]: `PALLOF PRESS DROP SQUAT`,
  [`Squat Eccentrico Veloce`]: `DROP SQUAT`,
  [`Squat Iso`]: `ISOMETRIC SQUAT WITH BANDED KNEE`,
  [`Squat Iso Con Tirante`]: `ISOMETRIC SPANISH SQUAT`,
  [`Squat Iso Monopodalico Con Tirante`]: `ISOMETRIC SINGLE LEG SPANISH SQUAT`,
  [`Squat Jmp In Avanzamento Arresto Monopdalico`]: `BROAD JUMP TO SINGLE LEG STICK`,
  [`Squat Jump`]: `SQUAT JUMP`,
  [`Squat Jump 180°`]: `180° ROTATION SQUAT JUMP`,
  [`Squat Jump 90°`]: `90° ROTATION SQUAT JUMP`,
  [`Squat Jump Arresto Monopodalico`]: `SQUAT JUMP TO SINGLE LEG STICK`,
  [`Squat Jump Con Arresto Monopodalico E Rotazione 90°`]: `SQUAT JUMP TO SINGLE LEG 90° STICK`,
  [`Squat Jump Monopodalico Con Arresto Bipodalico`]: `SINGLE LEG SQUAT JUMP TO STICK`,
  [`Squat Jump Monopodalico In Avanzamento Con Arresto Monopodalico Gamba Opposta`]: `SINGLE LEG BROAD JUMP TO OPPOSITE LEG STICK`,
  [`Squat Jump Monopodalico In Avanzamento Con Arresto Monopodalico Stessa Gamba`]: `SINGLE LEG BROAD JUMP TO SAME LEG STICK`,
  [`Squat Jump Test`]: `Squat Jump Test`,
  [`Squat Monopoadlico Con Elastico`]: `DECELERATION SINGLE LEG SQUAT`,
  [`Squat Monopodalico Al Muro Con Fitbal`]: `SINGLE LEG WALL SQUAT WITH FITBALL`,
  [`Squat Monopodalico Al Muro Iso Angolo 90° Con Fitbal`]: `ISOMETRIC SINGLE LEG WALL SQUAT WITH FTIBALL`,
  [`Squat Monopodalico Al Muro Iso Angolo 90° Con Fitbal + Estensione Ginocchio`]: `ISOMETRIC SINGLE LEG WALL SQUAT WITH  KEEE EXTENSION WITH FTIBALL`,
  [`Squat Monopodalico Con Tirante`]: `SINGLE LEG SPANISH SQUAT`,
  [`Squat Orizzontale`]: `HORIZONTAL SQUAT`,
  [`Squat Parallelo`]: `PARALLEL AIR SQUAT`,
  [`Squat Parziale Con Tirante`]: `1/2 SPANISH SQUAT`,
  [`sta migliorando`]: `It is getting better`,
  [`sta peggiorando`]: `It is getting worse`,
  [`Stadio`]: `Stage`,
  [`STAFFA DESTRA`]: `RIGHT STAPES`,
  [`STAFFA SINISTRA`]: `LEFT STAPES`,
  [`Stai prendendo farmaci attualmente`]: `Are you taking any medication?`,
  [`Stai prendendo farmaci per questa condizione?`]: `Are you taking any medications for this condition?`,
  [`Stai prendendo supplementi nutrizionali attualmente`]: `Are you taking any supplement?`,
  [`Stai seguendo un particolare regime dietetico`]: `Are you following a diet?`,
  [`Stanchezza dell’atleta aspecifica`]: `Tired athlete undiagnosed`,
  [`Stanco`]: `Did you feel tired?`,
  [`Standing External Wall Raises`]: `Standing External Wall Raises`,
  [`Standing Forward Bending Test`]: `Standing Forward Bending Test`,
  [`Standing Forward Knee Flare`]: `Standing Forward Knee Flare`,
  [`Standing Lat Pull Downs`]: `Standing Lat Pull Downs`,
  [`Standing Reverse Knee Flare`]: `Standing Reverse Knee Flare`,
  [`Star Excursion Balance Test (SEBT) (anteriore) (DX)`]: `Star Excursion Balance Test (SEBT) (anterior) (R)`,
  [`Star Excursion Balance Test (SEBT) (anteriore) (SX)`]: `Star Excursion Balance Test (SEBT) (anterior) (L)`,
  [`Star Excursion Balance Test (SEBT) (anterolaterale) (DX)`]: `Star Excursion Balance Test (SEBT) (anterolateral) (R)`,
  [`Star Excursion Balance Test (SEBT) (anterolaterale) (SX)`]: `Star Excursion Balance Test (SEBT) (anterolateral) (L)`,
  [`Star Excursion Balance Test (SEBT) (anteromediale) (DX)`]: `Star Excursion Balance Test (SEBT) (anteromedial) (R)`,
  [`Star Excursion Balance Test (SEBT) (anteromediale) (SX)`]: `Star Excursion Balance Test (SEBT) (anteromedial) (L)`,
  [`Star Excursion Balance Test (SEBT) (laterale) (DX)`]: `Star Excursion Balance Test (SEBT) (lateral) (R)`,
  [`Star Excursion Balance Test (SEBT) (laterale) (SX)`]: `Star Excursion Balance Test (SEBT) (lateral) (L)`,
  [`Star Excursion Balance Test (SEBT) (mediale) (DX)`]: `Star Excursion Balance Test (SEBT) (medial) (R)`,
  [`Star Excursion Balance Test (SEBT) (mediale) (SX)`]: `Star Excursion Balance Test (SEBT) (medial) (L)`,
  [`Star Excursion Balance Test (SEBT) (posteriore) (DX)`]: `Star Excursion Balance Test (SEBT) (posterior) (R)`,
  [`Star Excursion Balance Test (SEBT) (posteriore) (SX)`]: `Star Excursion Balance Test (SEBT) (posterior) (L)`,
  [`Star Excursion Balance Test (SEBT) (posterolaterale) (DX)`]: `Star Excursion Balance Test (SEBT) (posterolateral) (R)`,
  [`Star Excursion Balance Test (SEBT) (posterolaterale) (SX)`]: `Star Excursion Balance Test (SEBT) (posterolateral) (L)`,
  [`Star Excursion Balance Test (SEBT) (posteromediale) (DX)`]: `Star Excursion Balance Test (SEBT) (posteromedial) (R)`,
  [`Star Excursion Balance Test (SEBT) (posteromediale) (SX)`]: `Star Excursion Balance Test (SEBT) (posteromedial) (L)`,
  [`Stare in piedi`]: `Standing  `,
  [`Stare in piedi in posizione eretta`]: `Standing upright`,
  [`Stare in piedi per 1 ora`]: `Standing for 1 hour`,
  [`Stare in piedi sulle punte`]: `Coming up on your toes`,
  [`Stare sdraiato a letto (girandosi, conservando la posizione del ginocchio)`]: `Lying in bed (turning over, maintaining knee position)`,
  [`Stare sdraiato a letto (girarsi tenendo ferma l'anca)`]: `Lying in bed (turning over, maintaining hip position)`,
  [`Stare seduto`]: `Sitting  `,
  [`Stare seduto o sdraiato`]: `Sitting or lying`,
  [`Stare seduto per 1 ora`]: `Sitting for 1 hour`,
  [`Stato attuale`]: `Actual status`,
  [`Steinmann Sign I (DX)`]: `Steinmann Sign I (R)`,
  [`Steinmann Sign I (SX)`]: `Steinmann Sign I (L)`,
  [`Stendersi a letto (girandosi, conservando la posizione del ginocchio)`]: `Lying in bed (turning over, maintaining knee position)`,
  [`Stenosi del canale cervicale`]: `Cervical spinal canal stenosis`,
  [`Stenosi del canale spinale a livello lombare`]: `Lumbar Spinal canal stenosis`,
  [`Step Down (DX)`]: `Step Down (R)`,
  [`Step Down (SX)`]: `Step Down (L)`,
  [`Step Down da piano inclinato (DX)`]: `Declined Step Down Test (R)`,
  [`Step Down da piano inclinato (SX)`]: `Declined Step Down Test (L)`,
  [`Step Down Incrociato Parziale Mani Ai Fianchi`]: `1/2 CURTSY STEP DOWN`,
  [`Step Down Laterale Parziale Mani Ai Fianchi`]: `1/2 SIDE STEP DOWMN`,
  [`Step Down Parziale Avanti Mani Ai Fianchi`]: `1/2 STEP DOWN`,
  [`Step Up (DX)`]: `Step Up (R)`,
  [`Step Up (SX)`]: `Step Up (L)`,
  [`Step Up Avanti Braccia Coordinate`]: `FRONT STEP UP`,
  [`Step Up Avanti Largo`]: `WIDE STEP UP`,
  [`Step Up Avanti Largo Braccia Coordinate`]: `WIDE STEP UP`,
  [`Step Up Dietro`]: `BACK STEP UP`,
  [`Step Up Incriociato Laterale Braccia Coordiante`]: `SIDE CROSSOVER STEP UP`,
  [`Step Up Incrociato Avanti Braccia Coordinate`]: `CROSSOVER STEP UP`,
  [`Step Up Laterale`]: `SIDE STEP UP`,
  [`Sterno`]: `Sternum`,
  [`STERNO`]: `STERNUM`,
  [`STERNO (APOFISI XIFOIDEA)`]: `STERNUM (XIPHOID PROCESS)`,
  [`STERNO (CORPO)`]: `STERNUM (BODY)`,
  [`STERNO (MANUBRIO)`]: `STERNUM (MANUBRIUM)`,
  [`Stiffness`]: `Stiffness`,
  [`Stile di vita`]: `Social/Health habits`,
  [`Stimolanti come il caffe, te, ecc.`]: `stimulants such as coffee`,
  [`Stiramento dei muscoli glutei`]: `Gluteal muscle strain`,
  [`Stiramento dei muscoli obliqui`]: `Abdominal oblique muscle strain`,
  [`Stiramento dei tendini degli ischiocrurali mediali`]: `Medial hamstring tendon strain at knee`,
  [`Stiramento del grande gluteo`]: `Gluteus maximus strain`,
  [`Stiramento del legamento calcaneonavicolare`]: `Spring ligament sprain in foot`,
  [`Stiramento del legamento semilunarepiramidale`]: `Lunate - triquetral sprain`,
  [`Stiramento del medio/piccolo gluteo`]: `Gluteus medius/minimus strain`,
  [`Stiramento del piriforme`]: `Piriformis muscle strain`,
  [`Stiramento del retto dell'addome`]: `Rectus abdominus strain`,
  [`Stiramento del tendine del grande adduttore`]: `Adductor magnus tendon strain`,
  [`Stiramento del tendine del retto femorale`]: `Rectus femoris tendon strain`,
  [`Stiramento del tendine dell'adduttore lungo`]: `Adductor longus tendon strain`,
  [`Stiramento del tendine dell'ileopsoas`]: `Iliopsoas tendon strain`,
  [`Stiramento del tendine distale del bicipite femorale`]: `Lateral hamstring tendon strain at knee`,
  [`Stiramento del tendine distale del tricipite brachiale`]: `Distal triceps tendon injury`,
  [`Stiramento del tendine inserzionale del retto dell'addome`]: `Abdominal tendon insertion strain`,
  [`Stiramento del trasverso dell'addome`]: `Transversus abdominis muscle strain`,
  [`Stiramento del/I legamento/I della articolazione interfalangea dell'alluce`]: `Sprain IP ligament(s) great toe`,
  [`Stiramento muscolare al collo`]: `Neck muscle strain`,
  [`Stiramento muscolare al tronco`]: `Truncal Muscle Strain`,
  [`Stiramento/lesione dei muscoli di anca/inguine`]: `Hip and Groin Muscle Strain/Tear`,
  [`Stiramento/lesione del legamento scafolunato`]: `Scapholunate ligament sprain/tear`,
  [`Stiramento/rottura del LCM con danno condrale/meniscale associato`]: `MCL strain/rupture with chondral/meniscal damage knee`,
  [`Stiramento/rottura del legamento collaterale laterale del ginocchio (LCL)`]: `LCL strain/rupture`,
  [`Stiramento/rottura dell'angolo posterolaterale del ginocchio`]: `Knee posterolateral complex str/tear`,
  [`Stiramento/rottura dell'ileopsoas`]: `Iliopsoas muscle strain/tear`,
  [`Stiramento/rottura dello psoas`]: `Psoas muscle strain/tear`,
  [`Stiramento/Spasmo muscolare/Trigger Points dei muscoli lombari`]: `Lumbar Spine muscle and Tendon Strain/Spasm/Trigger Points`,
  [`Stiramento/spasmo/trigger point dei muscoli del piede`]: `Foot Muscle Strain/Spasm/trigger Points`,
  [`Stiramento/spasmo/trigger point muscolo e/o tendine del collo`]: `Neck muscle and/or tendon strain/spasm/trigger points`,
  [`Stiramento/spasmo/trigger point muscolo e/o tendine facciale`]: `Facial muscle strain`,
  [`Stiramento/Spasmo/Trigger Points dei muscoli glutei`]: `Buttock Muscle Strain/Spasm/Trigger Points`,
  [`Sto per lo più a letto e mi trascino per arrivare in bagno`]: `I am in bed most of the time`,
  [`Stomaco`]: `Stomach`,
  [`STOMACO`]: `STOMACH`,
  [`STOMACO (CORPO)`]: `STOMACH (BODY)`,
  [`STOMACO (CURVATURA MAGGIORE)`]: `STOMACH (GREATER CURVATURE)`,
  [`STOMACO (CURVATURA MINORE)`]: `STOMACH (LESSER CURVATURE)`,
  [`STOMACO (FONDO)`]: `STOMACH (FUNDUS)`,
  [`STOMACO (FORNICE)`]: `STOMACH (FORNIX)`,
  [`STOMACO (PILORO)`]: `STOMACH (PYLORIC PART)`,
  [`Storia di cadute (non legate ad attivita' di contatto)`]: `Fall history (no contact fall)`,
  [`Storia di endometriosi o problemi ginecologici`]: `History of endometriosis`,
  [`Storia medica attuale personale (ultime 8 settimane)`]: `Recent Medical history (last 8 weeks)`,
  [`Storia medica remota personale`]: `Past Medical history`,
  [`Storia medica remota personale e famigliare`]: `Family history`,
  [`Storia remota muscolo-scheletrica - dolore cronico o ricorrente`]: `Muskuloskeletal history- pain`,
  [`Storia remota muscolo-scheletrica - infortuni`]: `Muskuloskeletal history- injuries`,
  [`Storia remota muscolo-scheletrica - uso di ortesi`]: `Muskuloskeletal history- use of orthosis`,
  [`Stork Test (DX)`]: `Stork Test (R)`,
  [`Stork Test (SX)`]: `Stork Test (L)`,
  [`Straight Bar Curl Eccentric`]: `Straight Bar Curl Eccentric`,
  [`Straight Leg Deadlift`]: `Straight Leg Deadlift`,
  [`Straight Leg Raise Test`]: `Straight Leg Raise Test`,
  [`Straight Leg Raise Test (DX)`]: `Straight Leg Raise Test (R)`,
  [`Straight Leg Raise Test (SX)`]: `Straight Leg Raise Test (L)`,
  [`Strain laterale destro`]: `Right lateral strain`,
  [`Strain laterale sinistro`]: `Left lateral strain`,
  [`Strain verticale inferiore`]: `Inferior vertical strain`,
  [`Strain verticale superiore`]: `Superior vertical strain`,
  [`Stratford P, Gill C, Westaway M, Binkley J. Assessing disability and change on individual patients: a report of a patient specific measure. Physiotherapy canada. 1995 Oct;47(4):258-63.`]: `Stratford P, Gill C, Westaway M, Binkley J. Assessing disability and change on individual patients: a report of a patient specific measure. Physiotherapy canada. 1995 Oct;47(4):258-63.`,
  [`Strattonante`]: `Pulling`,
  [`Straziante`]: `Wrenching`,
  [`stress`]: `stress`,
  [`Stress femororotuleo da rotula bipartita`]: `PFS related to bipartite patella`,
  [`Stress Fracture Test (Fulcrum Test) (DX)`]: `Stress Fracture Test (Fulcrum Test) (R)`,
  [`Stress Fracture Test (Fulcrum Test) (SX)`]: `Stress Fracture Test (Fulcrum Test) (L)`,
  [`Stress Psicologici`]: `Psychological stress`,
  [`Stress test del legamento trasverso cervicale`]: `Cervical Transverse Ligament Stress Test`,
  [`stress,`]: `Stress  `,
  [`stretching`]: `Stretching`,
  [`stretching,`]: `stretching`,
  [`Stretto`]: `Tight`,
  [`Stridente`]: `Rasping`,
  [`Stritolante`]: `Tugging`,
  [`Strizzante`]: `Squeezing`,
  [`Su una scala da 0 a 10 dove 0 è "non ho dolore affatto" e 10 è "il massimo dolore che conosco", che livello di dolore hai percepito mediamente negli ultimi 7 giorni (o da quando e' comparso)?`]: `On a scale from 0 to 10 with 0 being "no pain" and 10 being "the worst pain you have experienced with this condition", what level of pain have you had during the last 7 days?`,
  [`Su una scala da 0 a 10 dove 0 è "non ho dolore" e 10 è "il massimo dolore che conosco", che livello di dolore percepisci in questo momento?`]: `On a scale from 0 to 10 with 0 being "no pain" and 10 being "the worst pain you have experienced with this condition", what level of pain do you have right now?`,
  [`Su una scala da 0 a 10 dove 0 e' "per niente" e 10 è "moltissimo", quanto il tuo dolore influenza la tua vita quotidiana?`]: `On a scale from 0 to 10 with 0 being "nothing" and 10 being "a lot", how much your pain affect your Activity Daily Living?`,
  [`Su una scala da 0 a 100, dove 0 rappresenta la totale incapacità a svolgere qualsiasi abituale attività quotidiana e 100 rappresenta la condizione che aveva prima di avere il problema alla caviglia o al piede, come valuta la Sua attuale capacità a com...`]: `How would you rate your current level of function during your usual sports related activities from 0 to 100 with 100 being your level of function prior to your foot and ankle problem and 0 being the inability to perform any of your usual sports activities`,
  [`Su una scala da 0 a 100, dove 0 rappresenta la totale incapacità a svolgere qualsiasi abituale attività quotidiana e 100 rappresenta la condizione che aveva prima di avere il problema alla caviglia o al piede, come valuta la Sua attuale capacità a compiere le seguenti attivita' sportive...`]: `How would you rate your current level of function during your usual sports related activities from 0 to 100 with 100 being your level of function prior to your foot and ankle problem and 0 being the inability to perform any of your usual sports activities`,
  [`Su una scala da 0 a 100, dove 0 rappresenta la totale incapacità a svolgere qualsiasi abituale attività quotidiana e 100 rappresenta la condizione che aveva prima di avere il problema alla caviglia o al piede, come valuta la Sua attuale capacità a svo...`]: `How would you rate your current level of function during your usual activities of daily living from 0 to 100 with 100 being your level of function prior to your foot and ankle problem and 0 being the inability to perform any of your usual daily activities`,
  [`Su una scala da 0 a 100, dove 0 rappresenta la totale incapacità a svolgere qualsiasi abituale attività quotidiana e 100 rappresenta la condizione che aveva prima di avere il problema alla caviglia o al piede, come valuta la Sua attuale capacità a svolgere le seguenti attivita'...`]: `How would you rate your current level of function during your usual activities of daily living from 0 to 100 with 100 being your level of function prior to your foot and ankle problem and 0 being the inability to perform any of your usual daily activities`,
  [`Sub-acuto`]: `Sub-acute`,
  [`Sublussazione`]: `Subluxation`,
  [`sublussazione del tendine dei peronieri`]: `peroneal tendon subluxation`,
  [`Sublussazione della rotula`]: `Patellar subluxation`,
  [`Sublussazione dentale`]: `Subluxed Tooth`,
  [`Sublussazione/dislocazione del tendine peroneale`]: `Peroneal tendon subluxation/dislocation`,
  [`Sublussazione/instabilita' colonna cervicale`]: `Cervical Subluxation/instability`,
  [`sudore notturno`]: `Night sweating`,
  [`Suggerisce di posticipare il ritorno all'attivita' dopo un infortunio agli hamstring`]: `Suggest to postpone return to sport after hamstring injury`,
  [`Sulcus Sign (DX)`]: `Sulcus Sign (R)`,
  [`Sulcus Sign (SX)`]: `Sulcus Sign (L)`,
  [`Sunburn`]: `Sunburn`,
  [`Superiorita'`]: `Superior glide`,
  [`Superman`]: `SUPERMAN`,
  [`Supinato`]: `Supinated`,
  [`Supinazione`]: `Supination`,
  [`Supinazione avambraccio (DX)`]: `Forearm supination (R)`,
  [`Supinazione avambraccio (SX)`]: `Forearm supination (L)`,
  [`Supine Bridge`]: `Supine Bridge`,
  [`Supine Bridge Alternating Leg Lift`]: `Supine Bridge Alternating Leg Lift`,
  [`Supine Bridge Test`]: `Supine Bridge Test`,
  [`Supine Elbow Bridge`]: `Supine Elbow Bridge`,
  [`Supine Hamstring Isometric`]: `Supine Hamstring Isometric`,
  [`Supine Impingement Test (DX)`]: `Supine Impingement Test (R)`,
  [`Supine Impingement Test (SX)`]: `Supine Impingement Test (L)`,
  [`Supine Long-Sitting Test`]: `Supine Long-Sitting Test`,
  [`Supine Row`]: `Supine Row`,
  [`Supine Scissors`]: `Supine Scissors`,
  [`Supine Sign`]: `Supine Sign`,
  [`Supine Soleus Muscle Length Test (DX)`]: `Supine Soleus Muscle Length Test (R)`,
  [`Supine Soleus Muscle Length Test (SX)`]: `Supine Soleus Muscle Length Test (L)`,
  [`Supraspinatus`]: `Supraspinatus`,
  [`Supraspinatus 45`]: `Supraspinatus 45`,
  [`Supraspinatus 90`]: `Supraspinatus 90`,
  [`Supraspinatus Eccentric 45`]: `Supraspinatus Eccentric 45`,
  [`Supraspinatus Eccentric 90`]: `Supraspinatus Eccentric 90`,
  [`Supraspinatus Oscillatory 90`]: `Supraspinatus Oscillatory 90`,
  [`Surgical bowel problem`]: `Surgical bowel problem`,
  [`Surprise Test or Anterior Release Test (DX)`]: `Surprise Test or Anterior Release Test (R)`,
  [`Surprise Test or Anterior Release Test (SX)`]: `Surprise Test or Anterior Release Test (L)`,
  [`Sutura coronale`]: `Coronal suture`,
  [`SUTURA CORONALE`]: `CORONAL SUTURE`,
  [`SUTURA CORONALE (PARTE DESTRA)`]: `CORONAL SUTURE (RIGHT SIDE)`,
  [`SUTURA CORONALE (PARTE SINISTRA)`]: `CORONAL SUTURE (LEFT SIDE)`,
  [`SUTURA ETMOIDOLACRIMALE DESTRA`]: `RIGHT ETHMOIDOLACRIMAL SUTURE`,
  [`SUTURA ETMOIDOLACRIMALE SINISTRA`]: `LEFT ETHMOIDOLACRIMAL SUTURE`,
  [`SUTURA ETMOIDOMASCELLARE DESTRA`]: `RIGHT ETHMOIDOMAXILLARY SUTURE`,
  [`SUTURA ETMOIDOMASCELLARE SINISTRA`]: `LEFT ETHMOIDOMAXILLARY SUTURE`,
  [`SUTURA FRONTOETMOIDALE`]: `FRONTOETHMOIDAL SUTURE`,
  [`SUTURA FRONTOLACRIMALE DESTRA`]: `RIGHT FRONTOLACRIMAL SUTURE`,
  [`SUTURA FRONTOLACRIMALE SINISTRA`]: `LEFT FRONTOLACRIMAL SUTURE`,
  [`SUTURA FRONTOMASCELLARE DESTRA`]: `RIGHT FRONTOMAXILLARY SUTURE`,
  [`SUTURA FRONTOMASCELLARE SINISTRA`]: `LEFT FRONTOMAXILLARY SUTURE`,
  [`SUTURA FRONTONASALE`]: `FRONTONASAL SUTURE`,
  [`SUTURA FRONTOZIGOMATICA DESTRA`]: `RIGHT FRONTOZYGOMATIC SUTURE`,
  [`SUTURA FRONTOZIGOMATICA SINISTRA`]: `LEFT FRONTOZYGOMATIC SUTURE`,
  [`SUTURA INTERMASCELLARE`]: `INTERMAXILLARY SUTURE`,
  [`SUTURA INTERNASALE`]: `INTERNASAL SUTURE`,
  [`SUTURA LACRIMOCONCALE DESTRA`]: `RIGHT LACRIMOCONCHAL SUTURE`,
  [`SUTURA LACRIMOCONCALE SINISTRA`]: `LEFT LACRIMOCONCHAL SUTURE`,
  [`SUTURA LACRIMOMASCELLARE DESTRA`]: `RIGHT LACRIMOMAXILLARY SUTURE`,
  [`SUTURA LACRIMOMASCELLARE SINISTRA`]: `LEFT LACRIMOMAXILLARY SUTURE`,
  [`Sutura lambdoidea`]: `Lambdoid suture`,
  [`SUTURA LAMBDOIDEA DESTRA`]: `LAMBDOID SUTURE (RIGHT SIDE)`,
  [`SUTURA LAMBDOIDEA SINISTRA`]: `LAMBDOID SUTURE (LEFT SIDE)`,
  [`SUTURA METOPICA`]: `(METOPIC SUTURE)`,
  [`SUTURA NASOMASCELLARE DESTRA`]: `RIGHT NASOMAXILLARY SUTURE`,
  [`SUTURA NASOMASCELLARE SINISTRA`]: `LEFT NASOMAXILLARY SUTURE`,
  [`SUTURA OCCIPITOMASTOIDEA DESTRA`]: `RIGHT OCCIPITOMASTOID SUTURE`,
  [`SUTURA OCCIPITOMASTOIDEA SINISTRA`]: `LEFT OCCIPITOMASTOID SUTURE`,
  [`SUTURA PALATINA MEDIANA`]: `MEDIAN PALATINE SUTURE`,
  [`SUTURA PALATINA TRASVERSA`]: `TRANSVERSE PALATINE SUTURE`,
  [`SUTURA PALATINOETMOIDALE DESTRA`]: `RIGHT PALATOETHMOIDAL SUTURE`,
  [`SUTURA PALATINOETMOIDALE SINISTRA`]: `LEFT PALATOETHMOIDAL SUTURE`,
  [`SUTURA PALATINOMASCELLARE DESTRA`]: `RIGHT PALATOMAXILLARY SUTURE`,
  [`SUTURA PALATINOMASCELLARE SINISTRA`]: `LEFT PALATOMAXILLARY SUTURE`,
  [`SUTURA PARIETOMASTOIDEA DESTRA`]: `RIGHT PARIETOMASTOID SUTURE`,
  [`SUTURA PARIETOMASTOIDEA SINISTRA`]: `LEFT PARIETOMASTOID SUTURE`,
  [`SUTURA PARIETOTEMPORALE DESTRA`]: `RIGHT TEMPOROPARIETAL SUTURE`,
  [`SUTURA PARIETOTEMPORALE SINISTRA`]: `LEFT TEMPOROPARIETAL SUTURE`,
  [`Sutura sagittale`]: `Sagittal suture`,
  [`SUTURA SAGITTALE`]: `SAGITTAL SUTURE`,
  [`SUTURA SFENOFRONTALE DESTRA`]: `RIGHT SPHENOFRONTAL SUTURE`,
  [`SUTURA SFENOFRONTALE SINISTRA`]: `LEFT SPHENOFRONTAL SUTURE`,
  [`SUTURA SFENOMASCELLARE DESTRA`]: `RIGHT SPHENOMAXILLARY SUTURE`,
  [`SUTURA SFENOMASCELLARE SINISTRA`]: `LEFT SPHENOMAXILLARY SUTURE`,
  [`SUTURA SFENOPARIETALE DESTRA`]: `RIGHT SPHENOPARIETAL SUTURE`,
  [`SUTURA SFENOPARIETALE SINISTRA`]: `LEFT SPHENOPARIETAL SUTURE`,
  [`SUTURA SFENOSQUAMOSA DESTRA`]: `RIGHT SPHENOSQUAMOUS SUTURE`,
  [`SUTURA SFENOSQUAMOSA SINISTRA`]: `LEFT SPHENOSQUAMOUS SUTURE`,
  [`SUTURA SFENOVOMERALE`]: `SPHENOVOMERINE SUTURE`,
  [`SUTURA SFENOZIGOMATICA DESTRA`]: `RIGHT SPHENOZYGOMATIC SUTURE`,
  [`SUTURA SFENOZIGOMATICA SINISTRA`]: `LEFT SPHENOZYGOMATIC SUTURE`,
  [`SUTURA SQUAMOSA DESTRA`]: `RIGHT SQUAMOUS SUTURE`,
  [`SUTURA SQUAMOSA SINISTRA`]: `LEFT SQUAMOUS SUTURE`,
  [`SUTURA TEMPOROZIGOMATICA DESTRA`]: `RIGHT TEMPOROZYGOMATIC SUTURE`,
  [`SUTURA TEMPOROZIGOMATICA SINISTRA`]: `LEFT TEMPOROZYGOMATIC SUTURE`,
  [`SUTURA ZIGOMATICOMASCELLARE DESTRA`]: `RIGHT ZYGOMATICOMAXILLARY SUTURE`,
  [`SUTURA ZIGOMATICOMASCELLARE SINISTRA`]: `LEFT ZYGOMATICOMAXILLARY SUTURE`,
  [`Svitare il coperchio di un barattolo duro o nuovo`]: `Open a tight or new jar`,
  [`Svolge regolarmente sport o attività fisica?`]: `Are you currently undertaking sport or other physical activity?`,
  [`Svolgere attivita' leggere per la casa`]: `Performing light activities around home`,
  [`Svolgere attivita' pesanti per la casa`]: `Performing heavy activities around home`,
  [`Svolgere lavori domestici leggeri (cucinare, spolverare, ecc.)`]: `Light domestic duties (cooking, dusting, etc.)`,
  [`Svolgere lavori domestici pesanti (spostare oggetti pesanti, lavare i pavimenti, ecc.)`]: `Heavy domestic duties (moving heavy boxes, scrubbing floors, etc.)`,
  [`Svolgi normalmente attivita'?`]: `Do you practice any sport activity?`,
  [`Svolgimento di attività fisiche: Le seguenti domande riguardano le sue capacità di svolgere le sue attività fisiche preferite. Per attività fisiche si intendono sia le attività sportive, sia tutte le altre forme di attività che comportano un affati...`]: `Participation in physical activities: The following questions are about your ability to participate in your preferred physical activities. Physical activities include sporting activities as well as all other forms of activity where you become slightly out of breath. When you answer these questions consider to what degree your ability to participate in physical activities during the past week has been affected by your hip and/or groin problem.`,
  [`Svolgimento di attività fisiche: Le seguenti domande riguardano le sue capacità di svolgere le sue attività fisiche preferite. Per attività fisiche si intendono sia le attività sportive, sia tutte le altre forme di attività che comportano un affaticamento od un impegno fisico di un certo livello. Nel rispondere a queste domande dovrà tenere in considerazione il livello di esecuzione delle attività fisiche da lei effettuate durante la scorsa settimana sempre in rapporto al suo problema all'anca e/o all'inguine`]: `Participation in physical activities: The following questions are about your ability to participate in your preferred physical activities. Physical activities include sporting activities as well as all other forms of activity where you become slightly out of breath. When you answer these questions consider to what degree your ability to participate in physical activities during the past week has been affected by your hip and/or groin problem.`,
  [`svolgimento di un'eccessiva attivita'`]: `Performance of excessive activity`,
  [`svolgimento di una eccessiva attivita'`]: `Performance of excessive activity`,
  [`Svolgo ogni lavoro con molta difficolta'`]: `I can hardly do any work at all`,
  [`Sway-back`]: `Sway back`,
  [`Swiss Ball Dead Bug`]: `Swiss ball dead bug`,
  [`Swiss Ball I Raise`]: `Swiss ball I Raise`,
  [`Swiss Ball Plank Forward Backwards`]: `Swiss ball plank forward backwards`,
  [`Swiss Ball Plank Side To Side`]: `Swiss ball plank side to side`,
  [`Swiss Ball T Raise`]: `Swiss ball T Raise`,
  [`Swiss Ball Y Raise`]: `Swiss ball Y Raise`,
  [`Swissball Hip Opening Stretch`]: `Swissball Hip Opening Stretch`,
  [`Syndesmosis Squeeze Test (DX)`]: `Syndesmosis Squeeze Test (R)`,
  [`Syndesmosis Squeeze Test (SX)`]: `Syndesmosis Squeeze Test (L)`,
  [`T Raise With Scap Depression`]: `T Raise with Scap Depression`,
  [`T Spine Flexion Extension`]: `T Spine Flexion Extension`,
  [`T Spine Roller Lateral Turn`]: `T Spine Roller Lateral turn`,
  [`T Spine Roller Oh Mobility`]: `T Spine Roller OH Mobility`,
  [`T Spine Roller Press`]: `T Spine Roller Press`,
  [`T Spine Rotation`]: `T spine rotation`,
  [`T-Test`]: `T-Test`,
  [`T1`]: `T1`,
  [`T1 (PROCESSO SPINOSO)`]: `T1 (SPINOUS PROCESS)`,
  [`T1 (PROCESSO TRASVERSO DESTRO)`]: `T1 (RIGHT TRANSVERSE PROCESS)`,
  [`T1 (PROCESSO TRASVERSO SINISTRO)`]: `T1 (LEFT TRANSVERSE PROCESS)`,
  [`T10`]: `T10`,
  [`T10 (PROCESSO SPINOSO)`]: `T10 (SPINOUS PROCESS)`,
  [`T10 (PROCESSO TRASVERSO DESTRO)`]: `T10 (RIGHT TRANSVERSE PROCESS)`,
  [`T10 (PROCESSO TRASVERSO SINISTRO)`]: `T10 (LEFT TRANSVERSE PROCESS)`,
  [`T11`]: `T11`,
  [`T11 (PROCESSO SPINOSO)`]: `T11 (SPINOUS PROCESS)`,
  [`T11 (PROCESSO TRASVERSO DESTRO)`]: `T11 (RIGHT TRANSVERSE PROCESS)`,
  [`T11 (PROCESSO TRASVERSO SINISTRO)`]: `T11 (LEFT TRANSVERSE PROCESS)`,
  [`T12`]: `T12`,
  [`T12 (PROCESSO SPINOSO)`]: `T12 (SPINOUS PROCESS)`,
  [`T12 (PROCESSO TRASVERSO DESTRO)`]: `T12 (RIGHT TRANSVERSE PROCESS)`,
  [`T12 (PROCESSO TRASVERSO SINISTRO)`]: `T12 (LEFT TRANSVERSE PROCESS)`,
  [`T2`]: `T2`,
  [`T2 (PROCESSO SPINOSO)`]: `T2 (SPINOUS PROCESS)`,
  [`T2 (PROCESSO TRASVERSO DESTRO)`]: `T2 (RIGHT TRANSVERSE PROCESS)`,
  [`T2 (PROCESSO TRASVERSO SINISTRO)`]: `T2 (LEFT TRANSVERSE PROCESS)`,
  [`T3`]: `T3`,
  [`T3 (PROCESSO SPINOSO)`]: `T3 (SPINOUS PROCESS)`,
  [`T3 (PROCESSO TRASVERSO DESTRO)`]: `T3 (RIGHT TRANSVERSE PROCESS)`,
  [`T3 (PROCESSO TRASVERSO SINISTRO)`]: `T3 (LEFT TRANSVERSE PROCESS)`,
  [`T4`]: `T4`,
  [`T4 (PROCESSO SPINOSO)`]: `T4 (SPINOUS PROCESS)`,
  [`T4 (PROCESSO TRASVERSO DESTRO)`]: `T4 (RIGHT TRANSVERSE PROCESS)`,
  [`T4 (PROCESSO TRASVERSO SINISTRO)`]: `T4 (LEFT TRANSVERSE PROCESS)`,
  [`T5`]: `T5`,
  [`T5 (PROCESSO SPINOSO)`]: `T5 (SPINOUS PROCESS)`,
  [`T5 (PROCESSO TRASVERSO DESTRO)`]: `T5 (RIGHT TRANSVERSE PROCESS)`,
  [`T5 (PROCESSO TRASVERSO SINISTRO)`]: `T5 (LEFT TRANSVERSE PROCESS)`,
  [`T6`]: `T6`,
  [`T6 (PROCESSO SPINOSO)`]: `T6 (SPINOUS PROCESS)`,
  [`T6 (PROCESSO TRASVERSO DESTRO)`]: `T6 (RIGHT TRANSVERSE PROCESS)`,
  [`T6 (PROCESSO TRASVERSO SINISTRO)`]: `T6 (LEFT TRANSVERSE PROCESS)`,
  [`T7`]: `T7`,
  [`T7 (PROCESSO SPINOSO)`]: `T7 (SPINOUS PROCESS)`,
  [`T7 (PROCESSO TRASVERSO DESTRO)`]: `T7 (RIGHT TRANSVERSE PROCESS)`,
  [`T7 (PROCESSO TRASVERSO SINISTRO)`]: `T7 (LEFT TRANSVERSE PROCESS)`,
  [`T8`]: `T8`,
  [`T8 (PROCESSO SPINOSO)`]: `T8 (SPINOUS PROCESS)`,
  [`T8 (PROCESSO TRASVERSO DESTRO)`]: `T8 (RIGHT TRANSVERSE PROCESS)`,
  [`T8 (PROCESSO TRASVERSO SINISTRO)`]: `T8 (LEFT TRANSVERSE PROCESS)`,
  [`T9`]: `T9`,
  [`T9 (PROCESSO SPINOSO)`]: `T9 (SPINOUS PROCESS)`,
  [`T9 (PROCESSO TRASVERSO DESTRO)`]: `T9 (RIGHT TRANSVERSE PROCESS)`,
  [`T9 (PROCESSO TRASVERSO SINISTRO)`]: `T9 (LEFT TRANSVERSE PROCESS)`,
  [`Tachicardia`]: `Tachycardia`,
  [`Taekwondo`]: `Taekwondo`,
  [`Tagliare la carne tenendo il coltello con la mano malata`]: `Cut meat using a knife in my affected hand`,
  [`Tagliente`]: `Cutting`,
  [`Taking Off the Shoe Test (DX)`]: `Taking Off the Shoe Test (R)`,
  [`Taking Off the Shoe Test (SX)`]: `Taking Off the Shoe Test (L)`,
  [`Talar dome bone bruise`]: `Talar dome bone bruise`,
  [`Talar Tilt (Valgus) Stress Test (DX)`]: `Talar Tilt (Valgus) Stress Test (R)`,
  [`Talar Tilt (Valgus) Stress Test (SX)`]: `Talar Tilt (Valgus) Stress Test (L)`,
  [`Talar Tilt (Varus) Stress Test (DX)`]: `Talar Tilt (Varus) Stress Test (R)`,
  [`Talar Tilt (Varus) Stress Test (SX)`]: `Talar Tilt (Varus) Stress Test (L)`,
  [`Talassemia`]: `Sickle cell or thalassaemia`,
  [`Tandem walking test`]: `Tandem walking test`,
  [`Tediante`]: `Boring`,
  [`Tegumentari`]: `Tegumentary`,
  [`Tempi di recupero approssimativi (in settimane)`]: `Approximate recovery time (weeks)`,
  [`Temporale`]: `Temporal`,
  [`Tenderness`]: `Tenderness`,
  [`Tendine comune dei muscoli estensori del carpo (DX)`]: `Common tendon of extensor carpii muscles (R)`,
  [`Tendine comune dei muscoli estensori del carpo (SX)`]: `Common tendon of extensor carpii muscles (L)`,
  [`TENDINE COMUNE DEI MUSCOLI ESTENSORI DEL CARPO DI DESTRA`]: `COMMON TENDON OF RIGHT EXTENSOR CARPI MUSCLES`,
  [`TENDINE COMUNE DEI MUSCOLI ESTENSORI DEL CARPO DI SINISTRA`]: `COMMON TENDON OF LEFT EXTENSOR CARPI MUSCLES`,
  [`Tendine comune dei muscoli estensori del polso (SX)`]: `Common tendon of extensor carpii muscles (L)`,
  [`Tendine comune dei muscoli flessori del carpo (DX)`]: `Common tendon of flexor carpii muscles (R)`,
  [`Tendine comune dei muscoli flessori del carpo (SX)`]: `Common tendon of flexor carpii muscles (L)`,
  [`TENDINE COMUNE DEI MUSCOLI FLESSORI DEL CARPO DI DESTRA`]: `COMMON TENDON OF RIGHT FLEXOR CARPI MUSCLES`,
  [`TENDINE COMUNE DEI MUSCOLI FLESSORI DEL CARPO DI SINISTRA`]: `COMMON TENDON OF LEFT FLEXOR CARPI MUSCLES`,
  [`Tendine comune dei muscoli ischiocrurali (DX)`]: `Common tendon of hamstring muscles (R)`,
  [`Tendine comune dei muscoli ischiocrurali (SX)`]: `Common tendon of hamstring muscles (L)`,
  [`TENDINE COMUNE DEI MUSCOLI ISCHIOCRURALI DI DESTRA`]: `COMMON TENDON OF RIGHT HAMSTRING MUSCLES`,
  [`TENDINE COMUNE DEI MUSCOLI ISCHIOCRURALI SINISTRO`]: `COMMON TENDON OF LEFT HAMSTRING MUSCLES`,
  [`TENDINE COMUNE DEI MUSCOLI PARAVERTEBRALI DI DESTRA`]: `COMMON TENDON OF RIGHT PARAVERTEBRAL MUSCLES`,
  [`TENDINE COMUNE DEI MUSCOLI PARAVERTEBRALI DI SINISTRA`]: `COMMON TENDON OF LEFT PARAVERTEBRAL MUSCLES`,
  [`TENDINE COMUNE DEI MUSCOLI VASTI DEL MUSCOLO QUADRICIPITE DESTRO`]: `COMMON TENDON OF VASTI MUSCLES OF RIGHT QUADRICEPS MUSCLE`,
  [`TENDINE COMUNE DEI MUSCOLI VASTI DEL MUSCOLO QUADRICIPITE SINISTRO`]: `COMMON TENDON OF VASTUS MUSCLES OF LEFT QUADRICEPS`,
  [`TENDINE CONGIUNTO DEI MUSCOLI ISCHIOCRURALI DI DESTRA`]: `COMMON TENDON OF RIGHT HAMSTRING MUSCLES`,
  [`TENDINE CONGIUNTO DEI MUSCOLI ISCHIOCRURALI DI SINISTRA`]: `COMMON TENDON OF LEFT HAMSTRING MUSCLES`,
  [`TENDINE CONGIUNTO DEL MUSCOLO ILEOPSOAS DESTRO`]: `CONJOINT TENDON OF RIGHT ILIOPSOAS MUSCLE`,
  [`TENDINE CONGIUNTO DEL MUSCOLO ILEOPSOAS SINISTRO`]: `CONJOINT TENDON OF LEFT ILIOPSOAS MUSCLE`,
  [`TENDINE CONGIUNTO DEL MUSCOLO RETTO FEMORALE DESTRO`]: `CONJOINT TENDON OF RIGHT RECTUS FEMORIS MUSCLE`,
  [`TENDINE CONGIUNTO DEL MUSCOLO RETTO FEMORALE SINISTRO`]: `CONJOINT TENDON OF LEFT RECTUS FEMORIS MUSCLE`,
  [`TENDINE D'ACHILLE DESTRO`]: `RIGHT ACHILLES TENDON`,
  [`TENDINE D'ACHILLE SINISTRO`]: `LEFT ACHILLES TENDON`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL COLLO DI DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ROTATORES CERVICIS MUSCLES (DISTAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL COLLO DI DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT ROTATORES CERVICIS MUSCLES (PROXIMAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL COLLO DI SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT ROTATORES CERVICIS MUSCLES (DISTAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL COLLO DI SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT ROTATORES CERVICIS MUSCLES (PROXIMAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL TORACE DI DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ROTATORES THORACIS MUSCLES (DISTAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL TORACE DI DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT ROTATORES THORACIS MUSCLES (PROXIMAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL TORACE DI SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT ROTATORES THORACIS MUSCLES (DISTAL INSERTION)`,
  [`TENDINE DEI MUSCOLI ROTATORI DEL TORACE DI SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT ROTATORES THORACIS MUSCLES (PROXIMAL INSERTION)`,
  [`Tendine del capo breve del muscolo bicipite brachiale (DX)`]: `Proximal tendon of short head of biceps brachii muscle (R)`,
  [`Tendine del capo breve del muscolo bicipite brachiale (SX)`]: `Proximal tendon of short head of biceps brachii muscle (L)`,
  [`TENDINE DEL CAPO INFERIORE DEL MUSCOLO PTERIGOIDEO LATERALE DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF INFERIOR HEAD OF RIGHT LATERAL PTERYGOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL CAPO INFERIORE DEL MUSCOLO PTERIGOIDEO LATERALE DESTRO (INSERZIONE PTERIGOIDEA)`]: `TENDON OF INFERIOR HEAD OF RIGHT LATERAL PTERYGOID MUSCLE (PTERIGOYD INSERTION)`,
  [`TENDINE DEL CAPO INFERIORE DEL MUSCOLO PTERIGOIDEO LATERALE SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF INFERIOR HEAD OF LEFT LATERAL PTERYGOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL CAPO INFERIORE DEL MUSCOLO PTERIGOIDEO LATERALE SINISTRO (INSERZIONE PTERIGOIDEA)`]: `TENDON OF INFERIOR HEAD OF LEFT LATERAL PTERYGOID MUSCLE (PTERYGOID INSERTION)`,
  [`Tendine del capo lungo del muscolo bicipite brachiale (DX)`]: `Proximal tendon of long head of biceps brachii muscle (R)`,
  [`Tendine del capo lungo del muscolo bicipitebrachiale (DX)`]: `Proximal tendon of long head of biceps brachii muscle (R)`,
  [`Tendine del capo lungo del muscolo bicipite brachiale (SX)`]: `Proximal tendon of long head of biceps brachii muscle (L)`,
  [`Tendine del capo lungo del muscolo bicipitebrachiale (SX)`]: `Proximal tendon of long head of biceps brachii muscle (L)`,
  [`TENDINE DEL CAPO SUPERIORE DEL MUSCOLO PTERIGOIDEO MEDIALE DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF SUPERIOR HEAD OF RIGHT LATERAL PTERYGOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL CAPO SUPERIORE DEL MUSCOLO PTERIGOIDEO MEDIALE DESTRO (INSERZIONE PTERIGOIDEA)`]: `TENDON OF SUPERIOR HEAD OF RIGHT LATERAL PTERYGOID MUSCLE (PTERIGOYD INSERTION)`,
  [`TENDINE DEL CAPO SUPERIORE DEL MUSCOLO PTERIGOIDEO MEDIALE SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF SUPERIOR HEAD OF LEFT LATERAL PTERYGOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL CAPO SUPERIORE DEL MUSCOLO PTERIGOIDEO MEDIALE SINISTRO (INSERZIONE PTERIGOIDEA)`]: `TENDON OF SUPERIOR HEAD OF LEFT LATERAL PTERYGOID MUSCLE (PTERYGOID INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE BREVE DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ABDUCTOR POLLICIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE BREVE DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT ABDUCTOR POLLICIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE BREVE DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT ABDUCTOR POLLICIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE BREVE DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT ABDUCTOR POLLICIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL MIGNOLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT HAND ABDUCTOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL MIGNOLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT HAND ABDUCTOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL MIGNOLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT HAND ABDUCTOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL MIGNOLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT HAND ABDUCTOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL V DITO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FOOT ABDUCTOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL V DITO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FOOT ABDUCTOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL V DITO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FOOT ABDUCTOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DEL V DITO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FOOT ABDUCTOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DELL'ALLUCE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ABDUCTOR HALLUCIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT ABDUCTOR HALLUCIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE DELL'ALLUCE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT ABDUCTOR HALLUCIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE LUNGO DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ABDUCTOR POLLICIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE LUNGO DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT ABDUCTOR POLLICIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE LUNGO DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT ABDUCTOR POLLICIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ABDUTTORE LUNGO DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT ABDUCTOR POLLICIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ADDUCTOR POLLICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT ADDUCTOR POLLICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT ADDUCTOR POLLICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT ADDUCTOR POLLICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DELL'ALLUCE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT ADDUCTOR HALLUCIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE CAPO OBLIQUO)`]: `TENDON OF RIGHT ADDUCTOR HALLUCIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE CAPO TRASVERSO)`]: `TENDON OF RIGHT ADDUCTOR HALLUCIS MUSCLE (PROXIMAL INSERTION OF TRANSVERSE HEAD)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DELL'ALLUCE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT ADDUCTOR HALLUCIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DELL'ALLUCE SINISTRO (INSERZIONE PROSSIMALE CAPO OBLIQUO)`]: `TENDON OF LEFT ADDUCTOR HALLUCIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ADDUTTORE DELL'ALLUCE SINISTRO (INSERZIONE PROSSIMALE CAPO TRASVERSO)`]: `TENDON OF LEFT ADDUCTOR HALLUCIS MUSCLE (PROXIMAL INSERTION OF TRANSVERSE HEAD)`,
  [`TENDINE DEL MUSCOLO ARITENOIDEO OBLIQUO DESTRO (INSERZIONE EPIGLOTTICA)`]: `TENDON OF RIGHT OBLIQUE ARYTENOID MUSCLE (EPIGLOTTIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ARITENOIDEO OBLIQUO DESTRO (INSERZIONE LARINGEA)`]: `TENDON OF RIGHT OBLIQUE ARYTENOID MUSCLE (LARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ARITENOIDEO OBLIQUO SINISTRO (INSERZIONE EPIGLOTTICA)`]: `TENDON OF LEFT OBLIQUE ARYTENOID MUSCLE (EPIGLOTTIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ARITENOIDEO OBLIQUO SINISTRO (INSERZIONE LARINGEA)`]: `TENDON OF LEFT OBLIQUE ARYTENOID MUSCLE (LARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ARITENOIDEO TRASVERSO (INSERZIONE ARITENOIDEA DI DESTRA)`]: `TENDON OF TRANSVERSE ARYTENOID MUSCLE (RIGHT ARYTENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO ARITENOIDEO TRASVERSO (INSERZIONE ARITENOIDEA DI SINISTRA)`]: `TENDON OF TRANSVERSE ARYTENOID MUSCLE (LEFT ARYTENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE ANTERIORE DESTRO (INSERZIONE AURICOLARE)`]: `TENDON OF RIGHT AURICULARIS ANTERIOR MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE ANTERIORE DESTRO (INSERZIONE TEMPORALE)`]: `TENDON OF RIGHT AURICULARIS ANTERIOR MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE ANTERIORE SINISTRO (INSERZIONE AURICOLARE)`]: `TENDON OF LEFT AURICULARIS ANTERIOR MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE ANTERIORE SINISTRO (INSERZIONE TEMPORALE)`]: `TENDON OF LEFT AURICULARIS ANTERIOR MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE POSTERIORE DESTRO (INSERZIONE AURICOLARE)`]: `TENDON OF RIGHT AURICULARIS POSTERIOR MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE POSTERIORE DESTRO (INSERZIONE TEMPORALE)`]: `TENDON OF RIGHT AURICULARIS POSTERIOR MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE POSTERIORE SINISTRO (INSERZIONE AURICOLARE)`]: `TENDON OF LEFT AURICULARIS POSTERIOR MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE POSTERIORE SINISTRO (INSERZIONE TEMPORALE)`]: `TENDON OF LEFT AURICULARIS POSTERIOR MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE SUPERIORE DESTRO (INSERZIONE AURICOLARE)`]: `TENDON OF RIGHT AURICULARIS SUPERIOR MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE SUPERIORE DESTRO (INSERZIONE TEMPORALE)`]: `TENDON OF RIGHT AURICULARIS SUPERIOR MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE SUPERIORE SINISTRO (INSERZIONE AURICOLARE)`]: `TENDON OF LEFT AURICULARIS SUPERIOR MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO AURICOLARE SUPERIORE SINISTRO (INSERZIONE TEMPORALE)`]: `TENDON OF LEFT AURICULARIS SUPERIOR MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO BUCCINATORE DESTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF RIGHT BUCCINATOR MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO BUCCINATORE DESTRO (INSERZIONE PTERIGOMASCELLARE)`]: `TENDON OF RIGHT BUCCINATOR MUSCLE (PTERYGOIDMAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO BUCCINATORE SINISTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF LEFT BUCCINATOR MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO BUCCINATORE SINISTRO (INSERZIONE PTERIGOMASCELLARE)`]: `TENDON OF LEFT BUCCINATOR MUSCLE (PTERYGOIDMAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO CORRUGATORE DEL SOPRACCIGLIO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT CORRUGATOR SUPERCILII MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO CORRUGATORE DEL SOPRACCIGLIO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT CORRUGATOR SUPERCILII MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO CORRUGATORE DEL SOPRACCIGLIO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT CORRUGATOR SUPERCILII MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO CORRUGATORE DEL SOPRACCIGLIO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT CORRUGATOR SUPERCILII MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE DESTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF RIGHT INFERIOR CONSTRICTOR MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE DESTRO (INSERZIONE FARINGEA)`]: `TENDON OF RIGHT INFERIOR CONSTRICTOR MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE SINISTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF LEFT INFERIOR CONSTRICTOR MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE INFERIORE DELLA FARINGE SINISTRO (INSERZIONE FARINGEA)`]: `TENDON OF LEFT INFERIOR CONSTRICTOR MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE MEDIO DELLA FARINGE DESTRO (INSERZIONE FARINGEA)`]: `TENDON OF RIGHT MIDDLE CONSTRICTOR MUSCLE (PHARINGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE MEDIO DELLA FARINGE DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT MIDDLE CONSTRICTOR MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE MEDIO DELLA FARINGE SINISTRO (INSERZIONE FARINGEA)`]: `TENDON OF LEFT MIDDLE CONSTRICTOR MUSCLE (PHARINGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE MEDIO DELLA FARINGE SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT MIDDLE CONSTRICTOR MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE DESTRO (INSERZIONE FARINGEA)`]: `TENDON OF RIGHT SUPERIOR CONSTRICTOR MUSCLE (PHARINGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE DESTRO (INSERZIONE MILOIOIDEA)`]: `TENDON OF RIGHT SUPERIOR CONSTRICTOR MUSCLE (MYLOHYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE SINISTRO (INSERZIONE FARINGEA)`]: `TENDON OF LEFT SUPERIOR CONSTRICTOR MUSCLE (PHARINGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO COSTRITTORE SUPERIORE DELLA FARINGE SINISTRO (INSERZIONE MILOIOIDEA)`]: `TENDON OF LEFT SUPERIOR CONSTRICTOR MUSCLE (MYLOHYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO LATERALE DESTRO (INSERZIONE ARITENOIDEA)`]: `TENDON OF RIGHT LATERAL CRICOARYTENOID MUSCLE (ARYTENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO LATERALE DESTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF RIGHT LATERAL CRICOARYTENOID MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO LATERALE SINISTRO (INSERZIONE ARITENOIDEA)`]: `TENDON OF LEFT LATERAL CRICOARYTENOID MUSCLE (ARYTENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO LATERALE SINISTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF LEFT LATERAL CRICOARYTENOID MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO POSTERIORE DESTRO (INSERZIONE ARITENOIDEA)`]: `TENDON OF RIGHT POSTERIOR CRICOARYTENOID MUSCLE (ARYTENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO POSTERIORE DESTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF RIGHT POSTERIOR CRICOARYTENOID MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO POSTERIORE SINISTRO (INSERZIONE ARITENOIDEA)`]: `TENDON OF LEFT POSTERIOR CRICOARYTENOID MUSCLE (ARYTENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOARITENOIDEO POSTERIORE SINISTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF LEFT POSTERIOR CRICOARYTENOID MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOTIROIDEO DESTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF RIGHT CRICOTHYROID MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOTIROIDEO DESTRO (INSERZIONE TIROIDEA)`]: `TENDON OF RIGHT CRICOTHYROID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOTIROIDEO SINISTRO (INSERZIONE CRICOIDEA)`]: `TENDON OF LEFT CRICOTHYROID MUSCLE (CRICOID INSERTION)`,
  [`TENDINE DEL MUSCOLO CRICOTIROIDEO SINISTRO (INSERZIONE TIROIDEA)`]: `TENDON OF LEFT CRICOTHYROID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO DELL'UGOLA`]: `TENDON OF MUSCULUS UVULAE`,
  [`TENDINE DEL MUSCOLO DELTOIDE DESTRO (INSERZIONE ACROMIALE)`]: `TENDON OF RIGHT DELTOID MUSCLE (ACROMIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE DESTRO (INSERZIONE CLAVICOLARE)`]: `TENDON OF RIGHT DELTOID MUSCLE (CLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT DELTOID MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE DESTRO (INSERZIONE SPINALE)`]: `TENDON OF RIGHT DELTOID MUSCLE (SPINAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE SINISTRO (INSERZIONE ACROMIALE)`]: `TENDON OF LEFT DELTOID MUSCLE (ACROMIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE SINISTRO (INSERZIONE CLAVICOLARE)`]: `TENDON OF LEFT DELTOID MUSCLE (CLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT DELTOID MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DELTOIDE SINISTRO (INSERZIONE SPINALE)`]: `TENDON OF LEFT DELTOID MUSCLE (SPINAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE INFERIORE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SERRATUS POSTERIOR INFERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE INFERIORE DESTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT SERRATUS POSTERIOR INFERIOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE INFERIORE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SERRATUS POSTERIOR INFERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE INFERIORE SINISTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT SERRATUS POSTERIOR INFERIOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE SUPERIORE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SERRATUS POSTERIOR SUPERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE SUPERIORE DESTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT SERRATUS POSTERIOR SUPERIOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE SUPERIORE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SERRATUS POSTERIOR SUPERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DENTATO POSTERIORE SUPERIORE SINISTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT SERRATUS POSTERIOR SUPERIOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL LABBRO INFERIORE DESTRO (INSERZIONE LABIALE)`]: `TENDON OF RIGHT DEPRESSOR LABII INFERIORIS MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL LABBRO INFERIORE DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT DEPRESSOR LABII INFERIORIS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL LABBRO INFERIORE SINISTRO (INSERZIONE LABIALE)`]: `TENDON OF LEFT DEPRESSOR LABII INFERIORIS MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL LABBRO INFERIORE SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT DEPRESSOR LABII INFERIORIS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SETTO NASALE DESTRO (INSERZIONE MASCELLARE)`]: `TENDON OF RIGHT DEPRESSOR SEPTI NASI MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SETTO NASALE DESTRO (INSERZIONE NASALE)`]: `TENDON OF RIGHT DEPRESSOR SEPTI NASI MUSCLE (NASAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SETTO NASALE SINISTRO (INSERZIONE MASCELLARE)`]: `TENDON OF LEFT DEPRESSOR SEPTI NASI MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SETTO NASALE SINISTRO (INSERZIONE NASALE)`]: `TENDON OF LEFT DEPRESSOR SEPTI NASI MUSCLE (NASAL INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SOPRACCIGLIO DESTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF RIGHT DEPRESSOR SUPERCILII MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SOPRACCIGLIO DESTRO (INSERZIONE SOPRACILIARE)`]: `TENDON OF RIGHT DEPRESSOR SUPERCILII MUSCLE (SUPRACILIAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SOPRACCIGLIO SINISTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF LEFT DEPRESSOR SUPERCILII MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DEL SOPRACCIGLIO SINISTRO (INSERZIONE SOPRACILIARE)`]: `TENDON OF LEFT DEPRESSOR SUPERCILII MUSCLE (SUPRACILIAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DELL'ANGOLO DELLA BOCCA DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT DEPRESSOR ANGULI ORIS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DELL'ANGOLO DELLA BOCCA DESTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF RIGHT DEPRESSOR ANGULI ORIS MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DELL'ANGOLO DELLA BOCCA SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT DEPRESSOR ANGULI ORIS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DEPRESSORE DELL'ANGOLO DELLA BOCCA SINISTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF LEFT DEPRESSOR ANGULI ORIS MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DIGASTRICO DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT DIGASTRIC MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DIGASTRICO DESTRO (INSERZIONE MASTOIDEA)`]: `TENDON OF RIGHT DIGASTRIC MUSCLE (MASTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO DIGASTRICO SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT DIGASTRIC MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO DIGASTRICO SINISTRO (INSERZIONE MASTOIDEA)`]: `TENDON OF LEFT DIGASTRIC MUSCLE (MASTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA I COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT I LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA I COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT I LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA I COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT I LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA I COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT I LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA II COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT II LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA II COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT II LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA II COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT II LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA II COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT II LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA III COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT III LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA III COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT III LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA III COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT III LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA III COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT III LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IV COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT IV LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IV COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT IV LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IV COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT IV LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IV COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT IV LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IX COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT IX LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IX COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT IX LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IX COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT IX LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA IX COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT IX LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA V COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT V LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA V COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT V LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA V COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT V LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA V COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT V LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VI COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT VI LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VI COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT VI LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VI COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT VI LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VI COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT VI LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VII COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT VII LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VII COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT VII LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VII COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT VII LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VII COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT VII LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VIII COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT VIII LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VIII COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT VIII LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VIII COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT VIII LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA VIII COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT VIII LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA X COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT X LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA X COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT X LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA X COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT X LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA X COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT X LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XI COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT XI LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XI COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT XI LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XI COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT XI LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XI COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT XI LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XII COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT XII LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XII COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT XII LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XII COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT XII LEVATORES COSTARUM BREVES MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE BREVE DELLA XII COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT XII LEVATORES COSTARUM BREVES MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE DESTRO (INSERZIONE LABIALE)`]: `TENDON OF RIGHT LEVATOR LABII SUPERIORIS MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE DESTRO (INSERZIONE ZIGOMATICA)`]: `TENDON OF RIGHT LEVATOR LABII SUPERIORIS MUSCLE (ZYGOMATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE E DELL'ALA DEL NASO DESTRO (INSERZIONE LABIALE)`]: `TENDON OF RIGHT LEVATOR LABII SUPERIORIS ALAEQUE NASI MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE E DELL'ALA DEL NASO DESTRO (INSERZIONE MASCELLARE)`]: `TENDON OF RIGHT LEVATOR LABII SUPERIORIS ALAEQUE NASI MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE E DELL'ALA DEL NASO SINISTRO (INSERZIONE LABIALE)`]: `TENDON OF LEFT LEVATOR LABII SUPERIORIS ALAEQUE NASI MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE E DELL'ALA DEL NASO SINISTRO (INSERZIONE MASCELLARE)`]: `TENDON OF LEFT LEVATOR LABII SUPERIORIS ALAEQUE NASI MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE SINISTRO (INSERZIONE LABIALE)`]: `TENDON OF LEFT LEVATOR LABII SUPERIORIS MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL LABBRO SUPERIORE SINISTRO (INSERZIONE ZIGOMATICA)`]: `TENDON OF LEFT LEVATOR LABII SUPERIORIS MUSCLE (ZYGOMATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL VELO PALATINO DESTRO (INSERZIONE PALATINA)`]: `TENDON OF RIGHT LEVATOR VELI PALATINI MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL VELO PALATINO DESTRO (INSERZIONE PETROSA)`]: `TENDON OF RIGHT LEVATOR VELI PALATINI MUSCLE (PETROUS INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL VELO PALATINO SINISTRO (INSERZIONE PALATINA)`]: `TENDON OF LEFT LEVATOR VELI PALATINI MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DEL VELO PALATINO SINISTRO (INSERZIONE PETROSA)`]: `TENDON OF LEFT LEVATOR VELI PALATINI MUSCLE (PETROUS INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELL'ANGOLO DELLA BOCCA DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT LEVATOR ANGULI ORIS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELL'ANGOLO DELLA BOCCA DESTRO (INSERZIONE MASCELLARE)`]: `TENDON OF RIGHT LEVATOR ANGULI ORIS MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELL'ANGOLO DELLA BOCCA SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT LEVATOR ANGULI ORIS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELL'ANGOLO DELLA BOCCA SINISTRO (INSERZIONE MASCELLARE)`]: `TENDON OF LEFT LEVATOR ANGULI ORIS MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA PALPEBRA DELL'OCCHIO DESTRO (INSERZIONE ORBITARIA)`]: `TENDON OF RIGHT LEVATOR PALPEBRAE SUPERIORIS MUSCLE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA PALPEBRA DELL'OCCHIO DESTRO (INSERZIONE PALPEBRALE)`]: `TENDON OF RIGHT LEVATOR PALPEBRAE SUPERIORIS MUSCLE (PALPEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA PALPEBRA DELL'OCCHIO SINISTRO (INSERZIONE ORBITARIA)`]: `TENDON OF LEFT LEVATOR PALPEBRAE SUPERIORIS MUSCLE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA PALPEBRA DELL'OCCHIO SINISTRO (INSERZIONE PALPEBRALE)`]: `TENDON OF LEFT LEVATOR PALPEBRAE SUPERIORIS MUSCLE (PALPEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA SCAPOLA DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT LEVATOR SCAPULA MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA SCAPOLA DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT LEVATOR SCAPULA MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA SCAPOLA SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT LEVATOR SCAPULA MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE DELLA SCAPOLA SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT LEVATOR SCAPULA MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA I COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT I LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA I COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT I LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA I COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT I LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA I COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT I LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA II COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT II LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA II COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT II LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA II COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT II LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA II COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT II LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA III COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT III LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA III COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT III LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA III COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT III LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA III COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT III LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IV COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT IV LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IV COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT IV LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IV COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT IV LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IV COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT IV LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IX COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT IX LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IX COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT IX LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IX COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT IX LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA IX COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT IX LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA V COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT V LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA V COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT V LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA V COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT V LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA V COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT V LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VI COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT VI LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VI COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT VI LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VI COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT VI LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VI COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT VI LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VII COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT VII LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VII COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT VII LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VII COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT VII LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VII COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT VII LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VIII COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT VIII LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VIII COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT VIII LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VIII COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT VIII LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA VIII COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT VIII LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA X COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT X LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA X COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT X LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA X COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT X LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA X COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT X LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XI COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT XI LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XI COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT XI LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XI COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT XI LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XI COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT XI LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XII COSTA DESTRA (INSERZIONE COSTALE)`]: `TENDON OF RIGHT XII LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XII COSTA DESTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT XII LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XII COSTA SINISTRA (INSERZIONE COSTALE)`]: `TENDON OF LEFT XII LEVATORES COSTARUM LONGI MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ELEVATORE LUNGO DELLA XII COSTA SINISTRA (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT XII LEVATORES COSTARUM LONGI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR POLLICIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR POLLICIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR POLLICIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR POLLICIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DELL'ALLUCE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR HALLUCIS BREVIS MUSCLE (DISTAL  INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR HALLUCIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DELL'ALLUCE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR HALLUCIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DELL'ALLUCE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR HALLUCIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DELLE DITA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR DIGITORUM BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE BREVE DELLE DITA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR DIGITORUM BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE COMUNE DELLE DITA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR DIGITORUM MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE COMUNE DELLE DITA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR DIGITORUM MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE COMUNE DELLE DITA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR DIGITORUM MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE COMUNE DELLE DITA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR DIGITORUM MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DEL MIGNOLO DESTRO`]: `TENDON OF RIGHT EXTENSOR DIGITI MINIMI MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE DEL MIGNOLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT HAND EXTENSOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DEL MIGNOLO SINISTRO`]: `TENDON OF LEFT EXTENSOR DIGITI MINIMI MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE DEL MIGNOLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT HAND EXTENSOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELL'INDICE DESTRO`]: `TENDON OF RIGHT EXTENSOR INDICIS MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELL'INDICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR INDICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELL'INDICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR INDICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELL'INDICE SINISTRO`]: `TENDON OF LEFT EXTENSOR INDICIS MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELL'INDICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR INDICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELL'INDICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR INDICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELLE DITA DESTRO`]: `TENDON OF RIGHT EXTENSOR DIGITORUM MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE DELLE DITA SINISTRO`]: `TENDON OF LEFT EXTENSOR DIGITORUM MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR POLLICIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR POLLICIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR POLLICIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR POLLICIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`Tendine del muscolo estensore lungo dell'alluce (DX)`]: `Distal tendon of extensor hallucis longus muscle (R)`,
  [`Tendine del muscolo estensore lungo dell'alluce (SX)`]: `Distal tendon of extensor hallucis longus muscle (L)`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE DESTRO`]: `TENDON OF RIGHT EXTENSOR HALLUCIS LONGUS MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE SINISTRO`]: `TENDON OF LEFT EXTENSOR HALLUCIS LONGUS MUSCLE`,
  [`Tendine del muscolo estensore lungo delle dita (DX)`]: `Distal tendon of extensor digitorum longus muscle (R)`,
  [`Tendine del muscolo estensore lungo delle dita (SX)`]: `Distal tendon of extensor digitorum longus muscle (L)`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO`]: `TENDON OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO`]: `DORSAL DIGITAL NERVES OF LEFT FOOT`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE BREVE DEL CARPO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR CARPI RADIALIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE BREVE DEL CARPO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR CARPI RADIALIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE BREVE DEL CARPO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR CARPI RADIALIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE BREVE DEL CARPO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR CARPI RADIALIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE LUNGO DEL CARPO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT EXTENSOR CARPI RADIALIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE LUNGO DEL CARPO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT EXTENSOR CARPI RADIALIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE LUNGO DEL CARPO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT EXTENSOR CARPI RADIALIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE RADIALE LUNGO DEL CARPO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT EXTENSOR CARPI RADIALIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO`]: `TENDON OF RIGHT EXTENSOR CARPI ULNARIS MUSCLE`,
  [`TENDINE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO`]: `TENDON OF LEFT EXTENSOR CARPI ULNARIS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL MIGNOLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT HAND FLEXOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL MIGNOLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT HAND FLEXOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL MIGNOLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT HAND FLEXOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL MIGNOLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT HAND FLEXOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FLEXOR POLLICIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FLEXOR POLLICIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FLEXOR POLLICIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FLEXOR POLLICIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL V DITO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FOOT FLEXOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL V DITO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FOOT FLEXOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL V DITO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FOOT FLEXOR DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DEL V DITO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FOOT FLEXOR DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DELL'ALLUCE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FLEXOR HALLUCIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FLEXOR HALLUCIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DELL'ALLUCE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FLEXOR HALLUCIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE BREVE DELL'ALLUCE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FLEXOR HALLUCIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE LUNGO DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FLEXOR POLLICIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE LUNGO DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FLEXOR POLLICIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE LUNGO DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FLEXOR POLLICIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE LUNGO DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FLEXOR POLLICIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`Tendine del muscolo flessore lungo dell'alluce (DX)`]: `Distal tendon of flexor hallucis longus muscle (R)`,
  [`Tendine del muscolo flessore lungo dell'alluce (SX)`]: `Distal tendon of flexor hallucis longus muscle (L)`,
  [`Tendine del muscolo flessore lungo delle dita (DX)`]: `Distal tendon of flexor digitorum longus muscle (R)`,
  [`Tendine del muscolo flessore lungo delle dita (SX)`]: `Distal tendon of flexor digitorum longus muscle (L)`,
  [`TENDINE DEL MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO`]: `TENDON OF RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO`]: `TENDON OF LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE RADIALE DEL CARPO DESTRO`]: `TENDON OF RIGHT FLEXOR CARPI RADIALIS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE RADIALE DEL CARPO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE RADIALE DEL CARPO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE RADIALE DEL CARPO SINISTRO`]: `TENDON OF LEFT FLEXOR CARPI RADIALIS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE RADIALE DEL CARPO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE RADIALE DEL CARPO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FLEXOR CARPI RADIALIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO`]: `TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO`]: `TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE`,
  [`TENDINE DEL MUSCOLO FLESSORE ULNARE DEL CARPO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FLEXOR CARPI ULNARIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE ULNARE DEL CARPO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FLEXOR CARPI ULNARIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE ULNARE DEL CARPO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FLEXOR CARPI ULNARIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO FLESSORE ULNARE DEL CARPO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FLEXOR CARPI ULNARIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO INFERIORE DESTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT GEMELLUS INFERIOR MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO INFERIORE DESTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF RIGHT GEMELLUS INFERIOR MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO INFERIORE SINISTRO (INSERZIONE FEMORALE)`]: `TENDON OF LEFT GEMELLUS INFERIOR MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO INFERIORE SINISTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF LEFT GEMELLUS INFERIOR MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO SUPERIORE DESTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT GEMELLUS SUPERIOR MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO SUPERIORE DESTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF RIGHT GEMELLUS SUPERIOR MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO SUPERIORE SINISTRO (INSERZIONE FEMORALE)`]: `TENDON OF LEFT GEMELLUS SUPERIOR MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GEMELLO SUPERIORE SINISTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF LEFT GEMELLUS SUPERIOR MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOGLOSSO DESTRO (INSERZIONE LINGUALE)`]: `TENDON OF RIGHT GENIOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOGLOSSO DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT GENIOGLOSSUS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOGLOSSO SINISTRO (INSERZIONE LINGUALE)`]: `TENDON OF LEFT GENIOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOGLOSSO SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT GENIOGLOSSUS MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOIOIDEO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT GENIOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOIOIDEO DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT GENIOHYOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOIOIDEO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT GENIOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO GENIOIOIDEO SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT GENIOHYOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GRAN DORSALE DESTRO (INSERZIONE ILIACA)`]: `TENDON OF RIGHT LATISSIMUS DORSI MUSCLE (ILIAC INSERTION)`,
  [`TENDINE DEL MUSCOLO GRAN DORSALE DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT LATISSIMUS DORSI MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRAN DORSALE DESTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT LATISSIMUS DORSI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRAN DORSALE SINISTRO (INSERZIONE ILIACA)`]: `TENDON OF LEFT LATISSIMUS DORSI MUSCLE (ILIAC INSERTION)`,
  [`TENDINE DEL MUSCOLO GRAN DORSALE SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT LATISSIMUS DORSI MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRAN DORSALE SINISTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT LATISSIMUS DORSI MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE PETTORALE DESTRO (INSERZIONE CLAVICOLARE)`]: `TENDON OF RIGHT PECTORALIS MAJOR MUSCLE (CLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE PETTORALE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT PECTORALIS MAJOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE PETTORALE DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT PECTORALIS MAJOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE PETTORALE SINISTRO (INSERZIONE CLAVICOLARE)`]: `TENDON OF LEFT PECTORALIS MAJOR MUSCLE (CLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE PETTORALE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT PECTORALIS MAJOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE PETTORALE SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT PECTORALIS MAJOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE RETTO POSTERIORE DELLA TESTA DESTRO (INSERZIONE EPISTROFEA)`]: `TENDON OF RIGHT RECTUS CAPITIS POSTERIOR MAJOR MUSCLE (AXIS INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE RETTO POSTERIORE DELLA TESTA DESTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF RIGHT RECTUS CAPITIS POSTERIOR MAJOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE RETTO POSTERIORE DELLA TESTA SINISTRO (INSERZIONE EPISTROFEA)`]: `TENDON OF LEFT RECTUS CAPITIS POSTERIOR MAJOR MUSCLE (AXIS INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE RETTO POSTERIORE DELLA TESTA SINISTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF LEFT RECTUS CAPITIS POSTERIOR MAJOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE ROTONDO DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT TERES MAJOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE ROTONDO DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT TERES MAJOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE ROTONDO SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT TERES MAJOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO GRANDE ROTONDO SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT TERES MAJOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF I DORSAL INTEROSSEUS MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PALMARE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PALMARE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PALMARE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PALMARE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PLANTARE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PLANTARE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PLANTARE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I INTEROSSEO PLANTARE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF I PALMAR INTEROSSEUS MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO I LOMBRICALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF I LUMBRICAL MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF II DORSAL INTEROSSEUS MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PALMARE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PALMARE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PALMARE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PALMARE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PLANTARE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PLANTARE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PLANTARE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II INTEROSSEO PLANTARE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF II PALMAR INTEROSSEUS MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO II LOMBRICALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF II LUMBRICAL MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF III DORSAL INTEROSSEUS MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PALMARE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PALMARE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PALMARE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PALMARE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PLANTARE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PLANTARE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PLANTARE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III INTEROSSEO PLANTARE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF III PALMAR INTEROSSEUS MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF RIGHT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF RIGHT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF LEFT FOOT (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF LEFT FOOT (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF RIGHT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF RIGHT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF LEFT HAND (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO III LOMBRICALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF III LUMBRICAL MUSCLE OF LEFT HAND (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ILEOCOSTALE DEL COLLO DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT ILIOCOSTALIS CERVICIS MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ILEOCOSTALE DEL COLLO DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT ILIOCOSTALIS CERVICIS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ILEOCOSTALE DEL COLLO SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT ILIOCOSTALIS CERVICIS MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ILEOCOSTALE DEL COLLO SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT ILIOCOSTALIS CERVICIS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA I COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT I EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA I COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT I EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA I COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT I EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA I COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT I EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA II COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT II EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA II COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT II EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA II COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT II EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA II COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT II EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA III COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT III EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA III COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT III EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA III COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT III EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA III COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT III EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IV COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT IV EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IV COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT IV EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IV COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT IV EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IV COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT IV EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IX COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT IX EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IX COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT IX EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IX COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT IX EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA IX COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT IX EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA V COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT V EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA V COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT V EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA V COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT V EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA V COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT V EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VI COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VI EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VI COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VI EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VI COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VI EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VI COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VI EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VII EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VII EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VII EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VII EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VIII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VIII EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VIII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VIII EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VIII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VIII EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA VIII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VIII EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA X COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT X EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA X COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT X EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA X COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT X EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA X COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT X EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XI COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT XI EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XI COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT XI EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XI COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT XI EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XI COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT XI EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT XII EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT XII EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT XII EXTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE ESTERNO DELLA XII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT XII EXTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA I COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT I INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA I COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT I INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA I COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT I INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA I COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT I INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA II COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT II INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA II COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT II INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA II COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT II INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA II COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT II INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA III COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT III INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA III COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT III INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA III COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT III INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA III COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT III INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IV COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT IV INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IV COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT IV INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IV COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT IV INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IV COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT IV INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IX COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT IX INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IX COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT IX INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IX COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT IX INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA IX COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT IX INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA V COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT V INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA V COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT V INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA V COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT V INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA V COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT V INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VI COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VI INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VI COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VI INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VI COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VI INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VI COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VI INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VII INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VII INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VII INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VII INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VIII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VIII INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VIII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VIII INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VIII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VIII INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA VIII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VIII INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA X COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT X INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA X COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT X INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA X COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT X INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA X COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT X INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XI COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT XI INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XI COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT XI INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XI COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT XI INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XI COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT XI INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT XII INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT XII INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT XII INTERNAL INTERCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO INTERCOSTALE INTERNO DELLA XII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT XII INTERNAL INTERCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IOGLOSSO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT HYOGLOSSUS MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO IOGLOSSO DESTRO (INSERZIONE LINGUALE)`]: `TENDON OF RIGHT HYOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IOGLOSSO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT HYOGLOSSUS MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO IOGLOSSO SINISTRO (INSERZIONE LINGUALE)`]: `TENDON OF LEFT HYOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FOOT IV DORSAL INTEROSSEOUS MUSCLE (DISTAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FOOT IV DORSAL INTEROSSEOUS MUSCLE (PROXIMAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FOOT IV DORSAL INTEROSSEOUS MUSCLE (DISTAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FOOT IV DORSAL INTEROSSEOUS MUSCLE (PROXIMAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT HAND IV DORSAL INTEROSSEOUS MUSCLE (DISTAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT HAND IV DORSAL INTEROSSEOUS MUSCLE (PROXIMAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT HAND IV DORSAL INTEROSSEOUS MUSCLE (DISTAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV INTEROSSEO DORSALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT HAND IV DORSAL INTEROSSEOUS MUSCLE (PROXIMAL INSERTION) `,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DEL PIEDE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT FOOT IV LUMBRICAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DEL PIEDE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT FOOT IV LUMBRICAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT FOOT IV LUMBRICAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DEL PIEDE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT FOOT IV LUMBRICAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DELLA MANO DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT HAND IV LUMBRICAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DELLA MANO DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT HAND IV LUMBRICAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DELLA MANO SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT HAND IV LUMBRICAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO IV LOMBRICALE DELLA MANO SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT HAND IV LUMBRICAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL COLLO DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT LONGISSIMUS CERVICIS (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL COLLO DESTRO (INSERZIONE TORACICA)`]: `TENDON OF RIGHT LONGISSIMUS CERVICIS (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL COLLO SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT LONGISSIMUS CERVICIS (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL COLLO SINISTRO (INSERZIONE TORACICA)`]: `TENDON OF LEFT LONGISSIMUS CERVICIS (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL TORACE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT LONGISSIMUS THORACIS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL TORACE DESTRO (INSERZIONE LOMBARE)`]: `TENDON OF RIGHT LONGISSIMUS THORACIS MUSCLE (LUMBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL TORACE DESTRO (INSERZIONE TORACICA)`]: `TENDON OF RIGHT LONGISSIMUS THORACIS MUSCLE (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL TORACE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT LONGISSIMUS THORACIS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL TORACE SINISTRO (INSERZIONE LOMBARE)`]: `TENDON OF LEFT LONGISSIMUS THORACIS MUSCLE (LUMBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DEL TORACE SINISTRO (INSERZIONE TORACICA)`]: `TENDON OF LEFT LONGISSIMUS THORACIS MUSCLE (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DELLA TESTA DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT LONGISSIMUS CAPITIS MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DELLA TESTA DESTRO (INSERZIONE MASTOIDEA)`]: `TENDON OF RIGHT LONGISSIMUS CAPITIS MUSCLE (MASTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DELLA TESTA DESTRO (INSERZIONE TORACICA)`]: `TENDON OF RIGHT LONGISSIMUS CAPITIS MUSCLE (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DELLA TESTA SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT LONGISSIMUS CAPITIS MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DELLA TESTA SINISTRO (INSERZIONE MASTOIDEA)`]: `TENDON OF LEFT LONGISSIMUS CAPITIS MUSCLE (MASTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGHISSIMO DELLA TESTA SINISTRO (INSERZIONE TORACICA)`]: `TENDON OF LEFT LONGISSIMUS CAPITIS MUSCLE (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DEL COLLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT LONGUS COLLI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DEL COLLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT LONGUS COLLI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DEL COLLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT LONGUS COLLI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DEL COLLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT LONGUS COLLI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DELLA TESTA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT LONGUS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DELLA TESTA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT LONGUS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DELLA TESTA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT LONGUS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO LUNGO DELLA TESTA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT LONGUS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO MEDIO GLUTEO DESTRO`]: `TENDON OF RIGHT GLUTEUS MEDIUS MUSCLE`,
  [`TENDINE DEL MUSCOLO MEDIO GLUTEO SINISTRO`]: `TENDON OF LEFT GLUTEUS MEDIUS MUSCLE`,
  [`TENDINE DEL MUSCOLO MENTALE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT MENTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO MENTALE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT MENTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO MENTALE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT MENTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO MENTALE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT MENTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO MILOIOIDEO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT MYLOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO MILOIOIDEO DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT MYLOHYOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO MILOIOIDEO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT MYLOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO MILOIOIDEO SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT MYLOHYOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO CERVICALE DESTRO (INSERZIONE SPINOSA)`]: `TENDON OF RIGHT MULTIFIDUS CERVICIS MUSCLE (SPINOUS INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO CERVICALE DESTRO (INSERZIONE TRASVERSA)`]: `TENDON OF RIGHT MULTIFIDUS CERVICIS MUSCLE (TRANSVERSE INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO CERVICALE SINISTRO (INSERZIONE SPINOSA)`]: `TENDON OF LEFT MULTIFIDUS CERVICIS MUSCLE (SPINOUS INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO CERVICALE SINISTRO (INSERZIONE TRASVERSA)`]: `TENDON OF LEFT MULTIFIDUS CERVICIS MUSCLE (TRANSVERSE INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO LOMBARE DESTRO (INSERZIONE SPINOSA)`]: `TENDON OF RIGHT MULTIFIDUS LUMBORUM MUSCLE (SPINOUS INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO LOMBARE DESTRO (INSERZIONE TRASVERSA)`]: `TENDON OF RIGHT MULTIFIDUS LUMBORUM MUSCLE (TRANSVERSE INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO LOMBARE SINISTRO (INSERZIONE SPINOSA)`]: `TENDON OF LEFT MULTIFIDUS LUMBORUM MUSCLE (SPINOUS INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO LOMBARE SINISTRO (INSERZIONE TRASVERSA)`]: `TENDON OF LEFT MULTIFIDUS LUMBORUM MUSCLE (TRANSVERSE INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO TORACICO DESTRO (INSERZIONE SPINOSA)`]: `TENDON OF RIGHT MULTIFIDUS THORACIC MUSCLE (SPINOUS INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO TORACICO DESTRO (INSERZIONE TRASVERSA)`]: `TENDON OF RIGHT MULTIFIDUS THORACIC MUSCLE (TRANSVERSE INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO TORACICO SINISTRO (INSERZIONE SPINOSA)`]: `TENDON OF LEFT MULTIFIDUS THORACIC MUSCLE (SPINOUS INSERTION)`,
  [`TENDINE DEL MUSCOLO MULTIFIDO TORACICO SINISTRO (INSERZIONE TRASVERSA)`]: `TENDON OF LEFT MULTIFIDUS THORACIC MUSCLE (TRANSVERSE INSERTION)`,
  [`TENDINE DEL MUSCOLO NASALE DESTRO (INSERZIONE MASCELLARE)`]: `TENDON OF RIGHT NASAL MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO NASALE DESTRO (INSERZIONE NASALE)`]: `TENDON OF RIGHT NASAL MUSCLE (NASAL INSERTION)`,
  [`TENDINE DEL MUSCOLO NASALE SINISTRO (INSERZIONE MASCELLARE)`]: `TENDON OF LEFT NASAL MUSCLE (MAXILLARY INSERTION)`,
  [`TENDINE DEL MUSCOLO NASALE SINISTRO (INSERZIONE NASALE)`]: `TENDON OF LEFT NASAL MUSCLE (NASAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO (INSERZIONE ILEOPUBICA)`]: `TENDON OF RIGHT EXTERNAL OBLIQUE MUSCLE (ILIOPUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO ESTERNO DELL'ADDOME DESTRO (INSERZIONE STERNOCOSTALE)`]: `TENDON OF RIGHT EXTERNAL OBLIQUE MUSCLE (STERNOCOSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO ESTERNO DELL'ADDOME SINISTRO (INSERZIONE ILEOPUBICA)`]: `TENDON OF RIGHT EXTERNAL OBLIQUE MUSCLE (ILIOPUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO ESTERNO DELL'ADDOME SINISTRO (INSERZIONE STERNOCOSTALE)`]: `TENDON OF RIGHT EXTERNAL OBLIQUE MUSCLE (STERNOCOSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELL'OCCHIO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF INFERIOR OBLIQUE MUSCLE OF RIGHT EYE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELL'OCCHIO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF INFERIOR OBLIQUE MUSCLE OF RIGHT EYE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELL'OCCHIO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF INFERIOR OBLIQUE MUSCLE OF LEFT EYE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELL'OCCHIO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF INFERIOR OBLIQUE MUSCLE OF LEFT EYE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELLA TESTA DESTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF RIGHT OBLIQUUS CAPITIS INFERIOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELLA TESTA DESTRO (INSERZIONE EPISTROFEA)`]: `TENDON OF RIGHT OBLIQUUS CAPITIS INFERIOR MUSCLE (AXIS INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELLA TESTA SINISTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF LEFT OBLIQUUS CAPITIS INFERIOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INFERIORE DELLA TESTA SINISTRO (INSERZIONE EPISTROFEA)`]: `TENDON OF LEFT OBLIQUUS CAPITIS INFERIOR MUSCLE (AXIS INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT INTERNAL OBLIQUE MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME DESTRO (INSERZIONE ILEOPUBICA)`]: `TENDON OF RIGHT INTERNAL OBLIQUE MUSCLE (ILIOPUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT INTERNAL OBLIQUE MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO INTERNO DELL'ADDOME SINISTRO (INSERZIONE ILEOPUBICA)`]: `TENDON OF LEFT INTERNAL OBLIQUE MUSCLE (ILIOPUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELL'OCCHIO DESTRO (INSERZIONE BULBARE)`]: `TENDON OF SUPERIOR OBLIQUE MUSCLE OF RIGHT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELL'OCCHIO DESTRO (INSERZIONE ORBITARIA)`]: `TENDON OF SUPERIOR OBLIQUE MUSCLE OF RIGHT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELL'OCCHIO SINISTRO (INSERZIONE BULBARE)`]: `TENDON OF SUPERIOR OBLIQUE MUSCLE OF LEFT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELL'OCCHIO SINISTRO (INSERZIONE ORBITARIA)`]: `TENDON OF SUPERIOR OBLIQUE MUSCLE OF LEFT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELLA TESTA DESTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF RIGHT OBLIQUUS CAPITIS SUPERIOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELLA TESTA DESTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF RIGHT OBLIQUUS CAPITIS SUPERIOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELLA TESTA SINISTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF LEFT OBLIQUUS CAPITIS SUPERIOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO OBLIQUO SUPERIORE DELLA TESTA SINISTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF LEFT OBLIQUUS CAPITIS SUPERIOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OMOIOIDEO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT OMOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO OMOIOIDEO DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT OMOHYOID MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO OMOIOIDEO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT OMOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO OMOIOIDEO SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT OMOHYOID MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL MIGNOLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT HAND OPPONENS DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL MIGNOLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT HAND OPPONENS DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL MIGNOLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT HAND OPPONENS DIGITI MINIMI MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL MIGNOLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT HAND OPPONENS DIGITI MINIMI MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL POLLICE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT OPPONENS POLLICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL POLLICE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT OPPONENS POLLICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL POLLICE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT OPPONENS POLLICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OPPONENTE DEL POLLICE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT OPPONENS POLLICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ORBICOLARE DELL'OCCHIO DESTRO (INSERZIONE ORBITARIA)`]: `TENDON OF LEFT ORBICULARIS ORIS MUSCLE (INFERIOR LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ORBICOLARE DELL'OCCHIO SINISTRO (INSERZIONE ORBITARIA)`]: `TENDON OF LEFT ORBICULARIS ORIS MUSCLE (SUPERIOR LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ORBICOLARE DELLA BOCCA (INSERZIONE LABIALE INFERIORE)`]: `TENDON OF RIGHT ORBICULARIS ORIS MUSCLE (INFERIOR LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ORBICOLARE DELLA BOCCA (INSERZIONE LABIALE SUPERIORE)`]: `TENDON OF RIGHT ORBICULARIS ORIS MUSCLE (SUPERIOR LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE ESTERNO DESTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT OBTURATOR EXTERNUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE ESTERNO DESTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF RIGHT OBTURATOR EXTERNUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE ESTERNO SINISTRO (INSERZIONE FEMORALE)`]: `TENDON OF LEFT OBTURATOR EXTERNUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE ESTERNO SINISTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF LEFT OBTURATOR EXTERNUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE INTERNO DESTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT OBTURATOR INTERNUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE INTERNO DESTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF RIGHT OBTURATOR INTERNUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE INTERNO SINISTRO (INSERZIONE FEMORALE)`]: `TENDON OF LEFT OBTURATOR INTERNUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO OTTURATORE INTERNO SINISTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF LEFT OBTURATOR INTERNUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOFARINGEO DESTRO (INSERZIONE FARINGEA)`]: `TENDON OF RIGHT PALATOPHARYNGEUS MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOFARINGEO DESTRO (INSERZIONE PALATINA)`]: `TENDON OF RIGHT PALATOPHARYNGEUS MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOFARINGEO SINISTRO (INSERZIONE FARINGEA)`]: `TENDON OF LEFT PALATOPHARYNGEUS MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOFARINGEO SINISTRO (INSERZIONE PALATINA)`]: `TENDON OF LEFT PALATOPHARYNGEUS MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOGLOSSO DESTRO (INSERZIONE LINGUALE)`]: `TENDON OF RIGHT PALATOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOGLOSSO DESTRO (INSERZIONE PALATINA)`]: `TENDON OF RIGHT PALATOGLOSSUS MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOGLOSSO SINISTRO (INSERZIONE LINGUALE)`]: `TENDON OF LEFT PALATOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALATOGLOSSO SINISTRO (INSERZIONE PALATINA)`]: `TENDON OF LEFT PALATOGLOSSUS MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE BREVE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT PALMARIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE BREVE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT PALMARIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE BREVE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT PALMARIS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE BREVE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT PALMARIS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE LUNGO DESTRO`]: `TENDON OF RIGHT PALMARIS LONGUS MUSCLE`,
  [`TENDINE DEL MUSCOLO PALMARE LUNGO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT PALMARIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE LUNGO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT PALMARIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE LUNGO SINISTRO`]: `TENDON OF LEFT PALMARIS LONGUS MUSCLE`,
  [`TENDINE DEL MUSCOLO PALMARE LUNGO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT PALMARIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PALMARE LUNGO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT PALMARIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`Tendine del muscolo peroniero breve (DX)`]: `Distal tendon of peroneus brevis muscle (R)`,
  [`Tendine del muscolo peroniero breve (SX)`]: `Distal tendon of peroneus brevis muscle (L)`,
  [`Tendine del muscolo peroniero lungo (DX)`]: `Distal tendon of peroneus longus muscle (R)`,
  [`Tendine del muscolo peroniero lungo (SX)`]: `Distal tendon of peroneus longus muscle (L)`,
  [`TENDINE DEL MUSCOLO PICCOLO PETTORALE DESTRO (INSERZIONE CORACOIDEA)`]: `TENDON OF RIGHT PECTORALIS MINOR MUSCLE (CORACOID INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO PETTORALE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT PECTORALIS MINOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO PETTORALE SINISTRO (INSERZIONE CORACOIDEA)`]: `TENDON OF LEFT PECTORALIS MINOR MUSCLE (CORACOID INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO PETTORALE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT PECTORALIS MINOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO RETTO POSTERIORE DELLA TESTA DESTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF RIGHT RECTUS CAPITIS POSTERIOR MINOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO RETTO POSTERIORE DELLA TESTA DESTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF RIGHT RECTUS CAPITIS POSTERIOR MINOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO RETTO POSTERIORE DELLA TESTA SINISTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF LEFT RECTUS CAPITIS POSTERIOR MINOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO RETTO POSTERIORE DELLA TESTA SINISTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF LEFT RECTUS CAPITIS POSTERIOR MINOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO ROTONDO DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT TERES MINOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO ROTONDO DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT TERES MINOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO ROTONDO SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT TERES MINOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PICCOLO ROTONDO SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT TERES MINOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRAMIDALE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT PYRAMIDALIS ABDOMINIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRAMIDALE DESTRO (INSERZIONE PUBICA)`]: `TENDON OF RIGHT PYRAMIDALIS ABDOMINIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRAMIDALE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT PYRAMIDALIS ABDOMINIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRAMIDALE SINISTRO (INSERZIONE PUBICA)`]: `TENDON OF LEFT PYRAMIDALIS ABDOMINIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRIFORME DESTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT PIRIFORMIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRIFORME DESTRO (INSERZIONE SACRALE)`]: `TENDON OF RIGHT PIRIFORMIS MUSCLE (SACRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRIFORME SINISTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT PIRIFORMIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PIRIFORME SINISTRO (INSERZIONE SACRALE)`]: `TENDON OF RIGHT PIRIFORMIS MUSCLE (SACRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PLATISMA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT PLATYSMA MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PLATISMA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT PLATYSMA MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PLATISMA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT PLATYSMA MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PLATISMA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT PLATYSMA MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PROCERO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT PROCERUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PROCERO DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT PROCERUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PROCERO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT PROCERUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PROCERO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT PROCERUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PRONATORE QUADRATO DESTRO (INSERZIONE RADIALE)`]: `TENDON OF RIGHT PRONATOR QUADRATUS MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PRONATORE QUADRATO DESTRO (INSERZIONE ULNARE)`]: `TENDON OF RIGHT PRONATOR QUADRATUS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DEL MUSCOLO PRONATORE QUADRATO SINISTRO (INSERZIONE RADIALE)`]: `TENDON OF LEFT PRONATOR QUADRATUS MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO PRONATORE QUADRATO SINISTRO (INSERZIONE ULNARE)`]: `TENDON OF LEFT PRONATOR QUADRATUS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DEL MUSCOLO PRONATORE ROTONDO DESTRO`]: `TENDON OF RIGHT PRONATOR TERES MUSCLE`,
  [`TENDINE DEL MUSCOLO PRONATORE ROTONDO SINISTRO`]: `TENDON OF LEFT PRONATOR TERES MUSCLE`,
  [`TENDINE DEL MUSCOLO PTERIGOIDEO MEDIALE DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT MEDIAL PTERYGOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO PTERIGOIDEO MEDIALE DESTRO (INSERZIONE PTERIGOIDEA)`]: `TENDON OF RIGHT MEDIAL PTERYGOID MUSCLE (PTERYGOID INSERTION)`,
  [`TENDINE DEL MUSCOLO PTERIGOIDEO MEDIALE SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT MEDIAL PTERYGOID MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO PTERIGOIDEO MEDIALE SINISTRO (INSERZIONE PTERIGOIDEA)`]: `TENDON OF LEFT MEDIAL PTERYGOID MUSCLE (PTERYGOID INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DEL FEMORE DESTRO (INSERZIONE FEMORALE)`]: `TENDON OF RIGHT QUADRATUS FEMORIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DEL FEMORE DESTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF RIGHT QUADRATUS FEMORIS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DEL FEMORE SINISTRO (INSERZIONE FEMORALE)`]: `TENDON OF LEFT QUADRATUS FEMORIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DEL FEMORE SINISTRO (INSERZIONE ISCHIATICA)`]: `TENDON OF LEFT QUADRATUS FEMORIS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DELLA PIANTA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT QUADRATUS PLANTAE MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DELLA PIANTA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT QUADRATUS PLANTAE MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DELLA PIANTA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT QUADRATUS PLANTAE MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO QUADRATO DELLA PIANTA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT QUADRATUS PLANTAE MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO ANTERIORE DELLA TESTA DESTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF RIGHT RECTUS CAPITIS ANTERIOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO ANTERIORE DELLA TESTA DESTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF RIGHT RECTUS CAPITIS ANTERIOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF LEFT RECTUS CAPITIS ANTERIOR MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO ANTERIORE DELLA TESTA SINISTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF LEFT RECTUS CAPITIS ANTERIOR MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO DELL'ADDOME DESTRO (INSERZIONE PUBICA)`]: `TENDON OF RIGHT RECTUS ABDOMINIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO DELL'ADDOME DESTRO (INSERZIONE XIFOIDEA)`]: `TENDON OF RIGHT RECTUS ABDOMINIS MUSCLE (XIPHOID INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO DELL'ADDOME SINISTRO (INSERZIONE PUBICA)`]: `TENDON OF LEFT RECTUS ABDOMINIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO DELL'ADDOME SINISTRO (INSERZIONE XIFOIDEA)`]: `TENDON OF LEFT RECTUS ABDOMINIS MUSCLE (XIPHOID INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO INFERIORE DELL'OCCHIO DESTRO (INSERZIONE BULBARE)`]: `TENDON OF INFERIOR RECTUS MUSCLE OF RIGHT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO INFERIORE DELL'OCCHIO DESTRO (INSERZIONE ORBITARIA)`]: `TENDON OF INFERIOR RECTUS MUSCLE OF RIGHT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO INFERIORE DELL'OCCHIO SINISTRO (INSERZIONE BULBARE)`]: `TENDON OF INFERIOR RECTUS MUSCLE OF LEFT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO INFERIORE DELL'OCCHIO SINISTRO (INSERZIONE ORBITARIA)`]: `TENDON OF INFERIOR RECTUS MUSCLE OF LEFT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO LATERALE DELLA TESTA DESTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF RIGHT RECTUS CAPITIS LATERALIS MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO LATERALE DELLA TESTA DESTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF RIGHT RECTUS CAPITIS LATERALIS MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO LATERALE DELLA TESTA SINISTRO (INSERZIONE ATLANTOIDEA)`]: `TENDON OF LEFT RECTUS CAPITIS LATERALIS MUSCLE (ATLANTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO LATERALE DELLA TESTA SINISTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF LEFT RECTUS CAPITIS LATERALIS MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO MEDIALE DELL'OCCHIO DESTRO (INSERZIONE BULBARE)`]: `TENDON OF MEDIAL RECTUS MUSCLE OF RIGHT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO MEDIALE DELL'OCCHIO DESTRO (INSERZIONE ORBITARIA)`]: `TENDON OF MEDIAL RECTUS MUSCLE OF RIGHT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO MEDIALE DELL'OCCHIO SINISTRO (INSERZIONE BULBARE)`]: `TENDON OF MEDIAL RECTUS MUSCLE OF LEFT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO MEDIALE DELL'OCCHIO SINISTRO (INSERZIONE ORBITARIA)`]: `TENDON OF MEDIAL RECTUS MUSCLE OF LEFT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO SUPERIORE DELL'OCCHIO DESTRO (INSERZIONE BULBARE)`]: `TENDON OF SUPERIOR RECTUS MUSCLE OF RIGHT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO SUPERIORE DELL'OCCHIO DESTRO (INSERZIONE ORBITARIA)`]: `TENDON OF SUPERIOR RECTUS MUSCLE OF RIGHT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO SUPERIORE DELL'OCCHIO SINISTRO (INSERZIONE BULBARE)`]: `TENDON OF SUPERIOR RECTUS MUSCLE OF LEFT EYE (BULBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO RETTO SUPERIORE DELL'OCCHIO SINISTRO (INSERZIONE ORBITARIA)`]: `TENDON OF SUPERIOR RECTUS MUSCLE OF LEFT EYE (ORBITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RISORIO DESTRO (INSERZIONE BUCCALE)`]: `TENDON OF RIGHT RISORIUS MUSCLE (BUCCAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RISORIO DESTRO (INSERZIONE MASSETERINA)`]: `TENDON OF RIGHT RISORIUS MUSCLE (MASSETRIC INSERTION)`,
  [`TENDINE DEL MUSCOLO RISORIO SINISTRO (INSERZIONE BUCCALE)`]: `TENDON OF LEFT RISORIUS MUSCLE (BUCCAL INSERTION)`,
  [`TENDINE DEL MUSCOLO RISORIO SINISTRO (INSERZIONE MASSETERINA)`]: `TENDON OF LEFT RISORIUS MUSCLE (MASSETRIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MAGGIORE DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT RHOMBOID MAJOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MAGGIORE DESTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT RHOMBOID MAJOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MAGGIORE SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT RHOMBOID MAJOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MAGGIORE SINISTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT RHOMBOID MAJOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MINORE DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT RHOMBOID MINOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MINORE DESTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF RIGHT RHOMBOID MINOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MINORE SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT RHOMBOID MINOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ROMBOIDEO MINORE SINISTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LEFT RHOMBOID MINOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SALPINGOFARINGEO DESTRO (INSERZIONE FARINGEA)`]: `TENDON OF RIGHT SALPINGOPHARYNGEUS MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SALPINGOFARINGEO DESTRO (INSERZIONE TUBARICA)`]: `TENDON OF RIGHT SALPINGOPHARYNGEUS MUSCLE (TUBARIC INSERTION)`,
  [`TENDINE DEL MUSCOLO SALPINGOFARINGEO SINISTRO (INSERZIONE FARINGEA)`]: `TENDON OF LEFT SALPINGOPHARYNGEUS MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SALPINGOFARINGEO SINISTRO (INSERZIONE TUBARICA)`]: `TENDON OF LEFT SALPINGOPHARYNGEUS MUSCLE (TUBARIC INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO ANTERIORE DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT SCALENUS ANTERIOR MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO ANTERIORE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SCALENUS ANTERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO ANTERIORE SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT SCALENUS ANTERIOR MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO ANTERIORE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SCALENUS ANTERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO MEDIO DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT SCALENUS MEDIUS MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO MEDIO DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SCALENUS MEDIUS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO MEDIO SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT SCALENUS MEDIUS MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO MEDIO SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SCALENUS MEDIUS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO POSTERIORE DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT SCALENUS POSTERIOR MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO POSTERIORE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SCALENUS POSTERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO POSTERIORE SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT SCALENUS POSTERIOR MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SCALENO POSTERIORE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SCALENUS POSTERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL COLLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SEMISPINALIS CERVICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL COLLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SEMISPINALIS CERVICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL COLLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SEMISPINALIS CERVICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL COLLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SEMISPINALIS CERVICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL TORACE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SEMISPINALIS THORACIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL TORACE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SEMISPINALIS THORACIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL TORACE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SEMISPINALIS THORACIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DEL TORACE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SEMISPINALIS THORACIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DELLA TESTA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SEMISPINALIS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DELLA TESTA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SEMISPINALIS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DELLA TESTA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SEMISPINALIS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SEMISPINALE DELLA TESTA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SEMISPINALIS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SERRATO ANTERIORE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SERRATUS ANTERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SERRATO ANTERIORE DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT SERRATUS ANTERIOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SERRATO ANTERIORE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SERRATUS ANTERIOR MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SERRATO ANTERIORE SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT SERRATUS ANTERIOR MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SOPRASPINATO DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT SUPRASPINATUS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOPRASPINATO DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT SUPRASPINATUS MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SOPRASPINATO SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT SUPRASPINATUS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOPRASPINATO SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT SUPRASPINATUS MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA I COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT I SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA I COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT I SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA I COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT I SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA I COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT I SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA II COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT II SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA II COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT II SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA II COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT II SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA II COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT II SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA III COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT III SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA III COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT III SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA III COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT III SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA III COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT III SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IV COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT IV SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IV COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT IV SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IV COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT IV SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IV COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT IV SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IX COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT IX SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IX COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT IX SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IX COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT IX SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA IX COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT IX SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA V COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT V SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA V COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT V SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA V COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT V SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA V COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT V SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VI COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VI SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VI COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VI SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VI COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VI SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VI COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VI SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VII SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VII SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VII SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VII SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VIII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT VIII SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VIII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT VIII SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VIII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT VIII SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA VIII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT VIII SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA X COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT X SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA X COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT X SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA X COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT X SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA X COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT X SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XI COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT XI SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XI COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT XI SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XI COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT XI SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XI COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT XI SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XII COSTA DESTRA (INSERZIONE DISTALE)`]: `TENDON OF RIGHT XII SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XII COSTA DESTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT XII SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XII COSTA SINISTRA (INSERZIONE DISTALE)`]: `TENDON OF LEFT XII SUBCOSTAL MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOCOSTALE DELLA XII COSTA SINISTRA (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT XII SUBCOSTAL MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSCAPOLARE DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT SUBSCAPULARIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSCAPOLARE DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT SUBSCAPULARIS MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSCAPOLARE SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT SUBSCAPULARIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSCAPOLARE SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT SUBSCAPULARIS MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSPINATO DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT INFRASPINATUS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSPINATO DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT INFRASPINATUS MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSPINATO SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT INFRASPINATUS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SOTTOSPINATO SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT INFRASPINATUS MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL COLLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SPINALIS CERVICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL COLLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SPINALIS CERVICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL COLLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SPINALIS CERVICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL COLLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SPINALIS CERVICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL TORACE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SPINALIS THORACIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL TORACE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SPINALIS THORACIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL TORACE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SPINALIS THORACIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DEL TORACE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SPINALIS THORACIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DELLA TESTA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SPINALIS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DELLA TESTA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SPINALIS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DELLA TESTA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SPINALIS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPINALE DELLA TESTA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SPINALIS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DEL COLLO DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SPLENIUS CERVICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DEL COLLO DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SPLENIUS CERVICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DEL COLLO SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SPLENIUS CERVICIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DEL COLLO SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SPLENIUS CERVICIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DELLA TESTA DESTRO (INSERZIONE DISTALE)`]: `TENDON OF RIGHT SPLENIUS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DELLA TESTA DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF RIGHT SPLENIUS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DELLA TESTA SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF LEFT SPLENIUS CAPITIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SPLENIO DELLA TESTA SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF LEFT SPLENIUS CAPITIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STAPEDIO DESTRO (INSERZIONE STAFFALE)`]: `TENDON OF RIGHT STAPEDIUS MUSCLE (STAPES INSERTION)`,
  [`TENDINE DEL MUSCOLO STAPEDIO SINISTRO (INSERZIONE STAFFALE)`]: `TENDON OF LEFT STAPEDIUS MUSCLE (STAPES INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOCLEIDOMASTOIDEO DESTRO (INSERZIONE OCCIPITOMASTOIDEA)`]: `TENDON OF RIGHT STERNOCLEIDOMASTOIDEUS MUSCLE (OCCIPITOMASTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOCLEIDOMASTOIDEO DESTRO (INSERZIONE STERNOCLAVICOLARE)`]: `TENDON OF RIGHT STERNOCLEIDOMASTOIDEUS MUSCLE (STERNOCLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOCLEIDOMASTOIDEO SINISTRO (INSERZIONE OCCIPITOMASTOIDEA)`]: `TENDON OF LEFT STERNOCLEIDOMASTOIDEUS MUSCLE (OCCIPITOMASTOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOCLEIDOMASTOIDEO SINISTRO (INSERZIONE STERNOCLAVICOLARE)`]: `TENDON OF LEFT STERNOCLEIDOMASTOIDEUS MUSCLE (STERNOCLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOIOIDEO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT STERNOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOIOIDEO DESTRO (INSERZIONE STERNALE)`]: `TENDON OF RIGHT STERNOHYOID MUSCLE (STERNAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOIOIDEO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT STERNOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOIOIDEO SINISTRO (INSERZIONE STERNALE)`]: `TENDON OF LEFT STERNOHYOID MUSCLE (STERNAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOTIROIDEO DESTRO (INSERZIONE STERNALE)`]: `TENDON OF RIGHT STERNOTHYROID MUSCLE (STERNAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOTIROIDEO DESTRO (INSERZIONE TIROIDEA)`]: `TENDON OF RIGHT STERNOTHYROID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOTIROIDEO SINISTRO (INSERZIONE STERNALE)`]: `TENDON OF LEFT STERNOTHYROID MUSCLE (STERNAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STERNOTIROIDEO SINISTRO (INSERZIONE TIROIDEA)`]: `TENDON OF LEFT STERNOTHYROID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOFARINGEO DESTRO (INSERZIONE FARINGEA)`]: `TENDON OF RIGHT STYLOPHARYNGEUS MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOFARINGEO DESTRO (INSERZIONE STILOIDEA)`]: `TENDON OF RIGHT STYLOPHARYNGEUS MUSCLE (STYLOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOFARINGEO SINISTRO (INSERZIONE FARINGEA)`]: `TENDON OF LEFT STYLOPHARYNGEUS MUSCLE (PHARYNGEAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOFARINGEO SINISTRO (INSERZIONE STILOIDEA)`]: `TENDON OF LEFT STYLOPHARYNGEUS MUSCLE (STYLOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOGLOSSO DESTRO (INSERZIONE LINGUALE)`]: `TENDON OF RIGHT STYLOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOGLOSSO DESTRO (INSERZIONE STILOIDEA)`]: `TENDON OF RIGHT STYLOGLOSSUS MUSCLE (STYLOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOGLOSSO SINISTRO (INSERZIONE LINGUALE)`]: `TENDON OF LEFT STYLOGLOSSUS MUSCLE (LINGUAL INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOGLOSSO SINISTRO (INSERZIONE STILOIDEA)`]: `TENDON OF LEFT STYLOGLOSSUS MUSCLE (STYLOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOIOIDEO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT STYLOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOIOIDEO DESTRO (INSERZIONE STILOIDEA)`]: `TENDON OF RIGHT STYLOHYOID MUSCLE (STYLOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOIOIDEO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT STYLOHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO STILOIOIDEO SINISTRO (INSERZIONE STILOIDEA)`]: `TENDON OF LEFT STYLOHYOID MUSCLE (STYLOID INSERTION)`,
  [`TENDINE DEL MUSCOLO SUCCLAVIO DESTRO (INSERZIONE CLAVICOLARE)`]: `TENDON OF RIGHT SUBCLAVIUS MUSCLE (CLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SUCCLAVIO DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT SUBCLAVIUS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SUCCLAVIO SINISTRO (INSERZIONE CLAVICOLARE)`]: `TENDON OF LEFT SUBCLAVIUS MUSCLE (CLAVICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO SUCCLAVIO SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT SUBCLAVIUS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SUPINATORE DESTRO (INSERZIONE OMERALE)`]: `TENDON OF RIGHT SUPINATOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SUPINATORE DESTRO (INSERZIONE RADIALE)`]: `TENDON OF RIGHT SUPINATOR MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SUPINATORE SINISTRO (INSERZIONE OMERALE)`]: `TENDON OF LEFT SUPINATOR MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DEL MUSCOLO SUPINATORE SINISTRO (INSERZIONE RADIALE)`]: `TENDON OF LEFT SUPINATOR MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPORALE DESTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF RIGHT TEMPORAL MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPORALE DESTRO (INSERZIONE TEMPORALE)`]: `TENDON OF RIGHT TEMPORAL MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPORALE SINISTRO (INSERZIONE MANDIBOLARE)`]: `TENDON OF LEFT TEMPORAL MUSCLE (MANDIBULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPORALE SINISTRO (INSERZIONE TEMPORALE)`]: `TENDON OF LEFT TEMPORAL MUSCLE (TEMPORAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPOROPARIETALE DESTRO (INSERZIONE AURICOLARE)`]: `TENDON OF RIGHT TEMPOROPARIETAL MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPOROPARIETALE DESTRO (INSERZIONE PARIETALE)`]: `TENDON OF RIGHT TEMPOROPARIETAL MUSCLE (PARIETAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPOROPARIETALE SINISTRO (INSERZIONE AURICOLARE)`]: `TENDON OF LEFT TEMPOROPARIETAL MUSCLE (AURICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TEMPOROPARIETALE SINISTRO (INSERZIONE PARIETALE)`]: `TENDON OF LEFT TEMPOROPARIETAL MUSCLE (PARIETAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL TIMPANO DESTRO (INSERZIONE PIRAMIDALE)`]: `TENDON OF RIGHT TENSOR TYMPANI MUSCLE (PYRAMIDAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL TIMPANO DESTRO (INSERZIONE TIMPANICA)`]: `TENDON OF RIGHT TENSOR TYMPANI MUSCLE (TYMPANIC INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL TIMPANO DESTRO (INSERZIONE TUBARICA)`]: `TENDON OF RIGHT TENSOR TYMPANI MUSCLE (TUBARIC INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL TIMPANO SINISTRO (INSERZIONE PIRAMIDALE)`]: `TENDON OF LEFT TENSOR TYMPANI MUSCLE (PYRAMIDAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL TIMPANO SINISTRO (INSERZIONE TIMPANICA)`]: `TENDON OF LEFT TENSOR TYMPANI MUSCLE (TYMPANIC INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL TIMPANO SINISTRO (INSERZIONE TUBARICA)`]: `TENDON OF LEFT TENSOR TYMPANI MUSCLE (TUBARIC INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL VELO PALATINO DESTRO (INSERZIONE PALATINA)`]: `TENDON OF RIGHT TENSOR VELI PALATINI MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL VELO PALATINO DESTRO (INSERZIONE SFENOIDALE)`]: `TENDON OF RIGHT TENSOR VELI PALATINI MUSCLE (SPHENOIDAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL VELO PALATINO SINISTRO (INSERZIONE PALATINA)`]: `TENDON OF LEFT TENSOR VELI PALATINI MUSCLE (PALATINE INSERTION)`,
  [`TENDINE DEL MUSCOLO TENSORE DEL VELO PALATINO SINISTRO (INSERZIONE SFENOIDALE)`]: `TENDON OF LEFT TENSOR VELI PALATINI MUSCLE (SPHENOIDAL INSERTION)`,
  [`Tendine del muscolo tibiale anteriore (DX)`]: `Distal tendon of tibialis anterior muscle (R)`,
  [`Tendine del muscolo tibiale anteriore (SX)`]: `Distal tendon of tibialis anterior muscle (L)`,
  [`Tendine del muscolo tibiale posteriore (DX)`]: `Distal tendon of tibialis posterior muscle (R)`,
  [`Tendine del muscolo tibiale posteriore (SX)`]: `Distal tendon of tibialis posterior muscle (L)`,
  [`TENDINE DEL MUSCOLO TIROARITENOIDEO DESTRO (INSERZIONE ARITENOIDEA)`]: `TENDON OF RIGHT THYROARITENOID MUSCLE (ARITENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROARITENOIDEO DESTRO (INSERZIONE TIROIDEA)`]: `TENDON OF RIGHT THYROARITENOID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROARITENOIDEO SINISTRO (INSERZIONE ARITENOIDEA)`]: `TENDON OF LEFT THYROARITENOID MUSCLE (ARITENOID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROARITENOIDEO SINISTRO (INSERZIONE TIROIDEA)`]: `TENDON OF LEFT THYROARITENOID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROIOIDEO DESTRO (INSERZIONE IOIDEA)`]: `TENDON OF RIGHT THYROHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROIOIDEO DESTRO (INSERZIONE TIROIOIDEA)`]: `TENDON OF RIGHT THYROHYOID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROIOIDEO SINISTRO (INSERZIONE IOIDEA)`]: `TENDON OF LEFT THYROHYOID MUSCLE (HYOID INSERTION)`,
  [`TENDINE DEL MUSCOLO TIROIOIDEO SINISTRO (INSERZIONE TIROIDEA)`]: `TENDON OF LEFT THYROHYOID MUSCLE (THYROID INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO DESTRO (INSERZIONE CERVICALE)`]: `TENDON OF RIGHT TRAPEZIUM MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO DESTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF RIGHT TRAPEZIUM MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO DESTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF RIGHT TRAPEZIUM MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO DESTRO (INSERZIONE TORACICA)`]: `TENDON OF RIGHT TRAPEZIUM MUSCLE (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO SINISTRO (INSERZIONE CERVICALE)`]: `TENDON OF LEFT TRAPEZIUM MUSCLE (CERVICAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO SINISTRO (INSERZIONE OCCIPITALE)`]: `TENDON OF LEFT TRAPEZIUM MUSCLE (OCCIPITAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO SINISTRO (INSERZIONE SCAPOLARE)`]: `TENDON OF LEFT TRAPEZIUM MUSCLE (SCAPULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TRAPEZIO SINISTRO (INSERZIONE TORACICA)`]: `TENDON OF LEFT TRAPEZIUM MUSCLE (THORACIC INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DEL TORACE DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT TRANSVERSUS THORACIS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DEL TORACE DESTRO (INSERZIONE STERNALE)`]: `TENDON OF RIGHT TRANSVERSUS THORACIS MUSCLE (STERNAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DEL TORACE SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT TRANSVERSUS THORACIS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DEL TORACE SINISTRO (INSERZIONE STERNALE)`]: `TENDON OF LEFT TRANSVERSUS THORACIS MUSCLE (STERNAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME DESTRO (INSERZIONE COSTALE)`]: `TENDON OF RIGHT TRANSVERSUS ABDOMINS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME DESTRO (INSERZIONE INGUINALE)`]: `TENDON OF RIGHT TRANSVERSUS ABDOMINIS MUSCLE (INGUINAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME DESTRO (INSERZIONE LOMBARE)`]: `TENDON OF RIGHT TRANSVERSUS ABDOMINIS MUSCLE (LUMBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME DESTRO (INSERZIONE PUBICA)`]: `TENDON OF RIGHT TRANSVERSUS ABDOMINIS MUSCLE (PUBICA INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME SINISTRO (INSERZIONE COSTALE)`]: `TENDON OF LEFT TRANSVERSUS ABDOMINS MUSCLE (COSTAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME SINISTRO (INSERZIONE INGUINALE)`]: `TENDON OF LEFT TRANSVERSUS ABDOMINIS MUSCLE (INGUINAL INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME SINISTRO (INSERZIONE LOMBARE)`]: `TENDON OF LEFT TRANSVERSUS ABDOMINIS MUSCLE (LUMBAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TRASVERSO DELL'ADDOME SINISTRO (INSERZIONE PUBICA)`]: `TENDON OF LEFT TRANSVERSUS ABDOMINIS MUSCLE (PUBICA INSERTION)`,
  [`TENDINE DEL MUSCOLO TRICIPITE BRACHIALE DESTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF RIGHT TRICEPS BRACHII MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DEL MUSCOLO TRICIPITE BRACHIALE SINISTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF LEFT TRICEPS BRACHII MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MAGGIORE DESTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF RIGHT ZYGOMATIC MAJOR MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MAGGIORE DESTRO (INSERZIONE ZIGOMATICA)`]: `TENDON OF RIGHT ZYGOMATIC MAJOR MUSCLE (ZYGOMATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MAGGIORE SINISTRO (INSERZIONE ORBICOLARE)`]: `TENDON OF LEFT ZYGOMATIC MAJOR MUSCLE (ORBICULAR INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MAGGIORE SINISTRO (INSERZIONE ZIGOMATICA)`]: `TENDON OF LEFT ZYGOMATIC MAJOR MUSCLE (ZYGOMATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MINORE DESTRO (INSERZIONE LABIALE)`]: `TENDON OF RIGHT ZYGOMATIC MINOR MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MINORE DESTRO (INSERZIONE ZIGOMATICA)`]: `TENDON OF RIGHT ZYGOMATIC MINOR MUSCLE (ZYGOMATIC INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MINORE SINISTRO (INSERZIONE LABIALE)`]: `TENDON OF LEFT ZYGOMATIC MINOR MUSCLE (LABIAL INSERTION)`,
  [`TENDINE DEL MUSCOLO ZIGOMATICO MINORE SINISTRO (INSERZIONE ZIGOMATICA)`]: `TENDON OF LEFT ZYGOMATIC MINOR MUSCLE (ZYGOMATIC INSERTION)`,
  [`TENDINE DELLA CUFFIA DEI ROTATORI DI DESTRA`]: `RIGHT ROTATOR CUFF TENDON`,
  [`TENDINE DELLA CUFFIA DEI ROTATORI DI SINISTRA`]: `LEFT ROTATOR CUFF TENDON`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI DESTRO (INSERZIONE COSTALE INFERIORE)`]: `TENDON OF LUMBAR PART OF RIGHT ILIOCOSTALIS LUMBORUM MUSCLE (INFERIOR COSTAL INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI DESTRO (INSERZIONE COSTALE SUPERIORE)`]: `TENDON OF LUMBAR PART OF RIGHT ILIOCOSTALIS LUMBORUM MUSCLE (SUPERIOR COSTAL INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI DESTRO (INSERZIONE ILIACA)`]: `TENDON OF LUMBAR PART OF RIGHT ILIOCOSTALIS LUMBORUM MUSCLE (ILIAC INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI DESTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LUMBAR PART OF RIGHT ILIOCOSTALIS LUMBORUM MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI SINISTRO (INSERZIONE COSTALE INFERIORE)`]: `TENDON OF LUMBAR PART OF LEFT ILIOCOSTALIS LUMBORUM MUSCLE (INFERIOR COSTAL INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI SINISTRO (INSERZIONE COSTALE SUPERIORE)`]: `TENDON OF LUMBAR PART OF LEFT ILIOCOSTALIS LUMBORUM MUSCLE (SUPERIOR COSTAL INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI SINISTRO (INSERZIONE ILIACA)`]: `TENDON OF LUMBAR PART OF LEFT ILIOCOSTALIS LUMBORUM MUSCLE (ILIAC INSERTION)`,
  [`TENDINE DELLA PARTE LOMBARE DEL MUSCOLO ILEOCOSTALE DEI LOMBI SINISTRO (INSERZIONE VERTEBRALE)`]: `TENDON OF LUMBAR PART OF LEFT ILIOCOSTALIS LUMBORUM MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE DELLA PARTE PROFONDA DEL MUSCOLO MASSETERE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF DEEP PART OF RIGHT MASSETER MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DELLA PARTE PROFONDA DEL MUSCOLO MASSETERE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF DEEP PART OF RIGHT MASSETER MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DELLA PARTE PROFONDA DEL MUSCOLO MASSETERE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF DEEP PART OF LEFT MASSETER MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DELLA PARTE PROFONDA DEL MUSCOLO MASSETERE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF DEEP PART OF LEFT MASSETER MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DELLA PARTE SUPERFICIALE DEL MUSCOLO MASSETERE DESTRO (INSERZIONE DISTALE)`]: `TENDON OF SUPERFICIAL PART OF RIGHT MASSETER MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DELLA PARTE SUPERFICIALE DEL MUSCOLO MASSETERE DESTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF SUPERFICIAL PART OF RIGHT MASSETER MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE DELLA PARTE SUPERFICIALE DEL MUSCOLO MASSETERE SINISTRO (INSERZIONE DISTALE)`]: `TENDON OF SUPERFICIAL PART OF LEFT MASSETER MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DELLA PARTE SUPERFICIALE DEL MUSCOLO MASSETERE SINISTRO (INSERZIONE PROSSIMALE)`]: `TENDON OF SUPERFICIAL PART OF LEFT MASSETER MUSCLE (PROXIMAL INSERTION)`,
  [`Tendine della zampa d'oca (DX)`]: `Pes anserinus tendon (R)`,
  [`Tendine della zampa d'oca (SX)`]: `Pes anserinus tendon (L)`,
  [`TENDINE DELLA ZAMPA D'OCA DESTRO`]: `RIGHT PES ANSERINUS TENDON`,
  [`TENDINE DELLA ZAMPA D'OCA SINISTRO`]: `LEFT PES ANSERINUS TENDON`,
  [`Tendine diretto del muscolo retto femorale (DX)`]: `Direct tendon of rectus femoris muscle (R)`,
  [`Tendine diretto del muscolo retto femorale (SX)`]: `Direct tendon of rectus femoris muscle (L)`,
  [`TENDINE DISTALE DEL MUSCOLO ADDUTTORE BREVE DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT ADDUCTOR BREVIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ADDUTTORE BREVE SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT ADDUCTOR BREVIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ADDUTTORE LUNGO DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT ADDUCTOR LONGUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ADDUTTORE LUNGO SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT ADDUCTOR LONGUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ANCONEO DESTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF RIGHT ANCONEUS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ANCONEO SINISTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF LEFT ANCONEUS MUSCLE (ULNAR INSERTION)`,
  [`Tendine distale del muscolo bicipite brachiale (DX)`]: `Distal tendon of biceps brachii muscle (R)`,
  [`Tendine distale del muscolo bicipite brachiale (SX)`]: `Distal tendon of biceps brachii muscle (L)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE BRACHIALE DESTRO (INSERZIONE RADIALE)`]: `DISTAL TENDON OF RIGHT BICEPS BRACHII MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE BRACHIALE DESTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF RIGHT BICEPS BRACHII MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE BRACHIALE SINISTRO (INSERZIONE RADIALE)`]: `DISTAL TENDON OF LEFT BICEPS BRACHII MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE BRACHIALE SINISTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF LEFT BICEPS BRACHII MUSCLE (ULNAR INSERTION)`,
  [`Tendine distale del muscolo bicipite femorale (DX)`]: `Distal tendon of biceps femoris muscle (R)`,
  [`Tendine distale del muscolo bicipite femorale (SX)`]: `Distal tendon of biceps femoris muscle (L)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE FEMORALE DESTRO (INSERZIONE PERONEALE)`]: `DISTAL TENDON OF RIGHT BICEPS FEMORIS MUSCLE (PERONEAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE FEMORALE DESTRO (PORZIONE INTRAMUSCOLARE)`]: `DISTAL TENDON OF RIGHT BICEPS FEMORIS MUSCLE (INTRAMUSCULAR TENDON)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE FEMORALE SINISTRO (INSERZIONE PERONEALE)`]: `DISTAL TENDON OF LEFT BICEPS FEMORIS MUSCLE (PERONEAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BICIPITE FEMORALE SINISTRO (PORZIONE INTRAMUSCOLARE)`]: `DISTAL TENDON OF LEFT BICEPS FEMORIS MUSCLE (INTRAMUSCULAR TENDON)`,
  [`TENDINE DISTALE DEL MUSCOLO BRACHIALE DESTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF RIGHT BRACHIALIS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BRACHIALE SINISTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF LEFT BRACHIALIS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BRACHIORADIALE DESTRO (INSERZIONE RADIALE)`]: `DISTAL TENDON OF RIGHT BRACHIORADIALIS MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO BRACHIORADIALE SINISTRO (INSERZIONE RADIALE)`]: `DISTAL TENDON OF LEFT BRACHIORADIALIS MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO CORACOBRACHIALE DESTRO (INSERZIONE OMERALE)`]: `DISTAL TENDON OF RIGHT CORACOBRACHIALIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO CORACOBRACHIALE SINISTRO (INSERZIONE OMERALE)`]: `DISTAL TENDON OF LEFT CORACOBRACHIALIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE BREVE DELLE DITA DESTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM BREVIS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE BREVE DELLE DITA DESTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM BREVIS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE BREVE DELLE DITA DESTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM BREVIS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE BREVE DELLE DITA SINISTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM BREVIS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE BREVE DELLE DITA SINISTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM BREVIS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE BREVE DELLE DITA SINISTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM BREVIS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT EXTENSOR HALLUCIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT EXTENSOR HALLUCIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO (V DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE (V TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO (V DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE (V TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT EXTENSOR CARPI ULNARIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT EXTENSOR CARPI ULNARIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA DESTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM BREVIS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA DESTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM BREVIS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA DESTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM BREVIS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA DESTRO (V DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM BREVIS MUSCLE (V TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA SINISTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM BREVIS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA SINISTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM BREVIS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA SINISTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM BREVIS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE BREVE DELLE DITA SINISTRO (V DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM BREVIS MUSCLE (V TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELL'ALLUCE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT FLEXOR HALLUCIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELL'ALLUCE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT FLEXOR HALLUCIS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA DESTRO (V DITO DEL PIEDE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM LONGUS MUSCLE (V TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO (II DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE (II TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO (III DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE (III TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO (IV DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE (IV TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE LUNGO DELLE DITA SINISTRO (V DITO DEL PIEDE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM LONGUS MUSCLE (V TOE)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO (DITO ANULARE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE (RING FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO (DITO INDICE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE (INDEX FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO (DITO MEDIO)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE (MIDDLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO (DITO MIGNOLO)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE (LITTLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO (DITO ANULARE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE (RING FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO (DITO INDICE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE (INDEX FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO (DITO MEDIO)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE (MIDDLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO (DITO MIGNOLO)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE (LITTLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (DITO ANULARE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (RING FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (DITO INDICE)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (INDEX FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (DITO MEDIO)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (MIDDLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (DITO MIGNOLO)`]: `DISTAL TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (LITTLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (DITO ANULARE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (RING FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (DITO INDICE)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (INDEX FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (DITO MEDIO)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (MIDDLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (DITO MIGNOLO)`]: `DISTAL TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (LITTLE FINGER)`,
  [`TENDINE DISTALE DEL MUSCOLO GRACILE DESTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF RIGHT GRACILIS MUSCLE (TIBIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRACILE SINISTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF LEFT GRACILIS MUSCLE (TIBIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRANDE ADDUTTORE DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT ADDUCTOR MAGNUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRANDE ADDUTTORE SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT ADDUCTOR MAGNUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRANDE GLUTEO DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT GLUTEUS MAXIMUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRANDE GLUTEO SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT GLUTEUS MAXIMUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRANDE PSOAS DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT PSOAS MAJOR MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO GRANDE PSOAS SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT PSOAS MAJOR MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ILIACO DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT ILIACUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO ILIACO SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT ILIACUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO MEDIO GLUTEO DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT GLUTEUS MEDIUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO MEDIO GLUTEO SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT GLUTEUS MEDIUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PERONEO BREVE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT PERONEUS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PERONEO BREVE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT PERONEUS BREVIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PERONEO LUNGO DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT PERONEUS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PERONEO LUNGO SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT PERONEUS LONGUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PERONEO TERZO DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT PERONEUS TERTIUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PERONEO TERZO SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT PERONEUS TERTIUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PETTINEO DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT PECTINEUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PETTINEO SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT PECTINEUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PICCOLO GLUTEO DESTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF RIGHT GLUTEUS MINIMUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PICCOLO GLUTEO SINISTRO (INSERZIONE FEMORALE)`]: `DISTAL TENDON OF LEFT GLUTEUS MINIMUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PLANTARE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT PLANTARIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PLANTARE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT PLANTARIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO POPLITEO DESTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF RIGHT POPLITEUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO POPLITEO SINISTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF LEFT POPLITEUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PRONATORE ROTONDO DESTRO (INSERZIONE RADIALE)`]: `DISTAL TENDON OF RIGHT PRONATOR TERES MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO PRONATORE ROTONDO SINISTRO (INSERZIONE RADIALE)`]: `DISTAL TENDON OF DISTAL PRONATOR TERES MUSCLE (RADIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO RETTO FEMORALE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT RECTUS FEMORIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO RETTO FEMORALE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT RECTUS FEMORIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO SARTORIO DESTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF RIGHT SARTORIUS MUSCLE (TIBIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO SARTORIO SINISTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF LEFT SARTORIUS MUSCLE (TIBIAL INSERTION)`,
  [`Tendine distale del muscolo semimembranoso (DX)`]: `Distal tendon of semimembranosus muscle (R)`,
  [`Tendine distale del muscolo semimembranoso (SX)`]: `Distal tendon of semimembranosus muscle (L)`,
  [`TENDINE DISTALE DEL MUSCOLO SEMIMEMBRANOSO DESTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF RIGHT SEMIMEMBRANOSUS MUSCLE (TIBIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO SEMIMEMBRANOSO SINISTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF LEFT SEMIMEMBRANOSUS MUSCLE (TIBIAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO SEMITENDINOSO DESTRO (INSERZIONE TIBIALE)`]: `DISTAL TENDON OF RIGHT SEMITENDINOSUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO SEMITENDINOSO SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT SEMITENDINOSUS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TENSORE DELLA FASCIA LATA DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT TENSOR FASCIA LATA MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TENSORE DELLA FASCIA LATA SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT TENSOR FASCIA LATA MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TIBIALE ANTERIORE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT TIBIALIS ANTERIOR MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TIBIALE ANTERIORE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT TIBIALIS ANTERIOR MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TIBIALE POSTERIORE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT TIBIALIS POSTERIOR MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TIBIALE POSTERIORE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT TIBIALIS POSTERIOR MUSCLE (DISTAL INSERTION)`,
  [`Tendine distale del muscolo tricipite brachiale (DX)`]: `Distal tendon of triceps brachii muscle (R)`,
  [`Tendine distale del muscolo tricipite brachiale (SX)`]: `Distal tendon of triceps brachii muscle (L)`,
  [`TENDINE DISTALE DEL MUSCOLO TRICIPITE BRACHIALE DESTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF RIGHT TRICEPS BRACHIALIS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO TRICIPITE BRACHIALE SINISTRO (INSERZIONE ULNARE)`]: `DISTAL TENDON OF LEFT TRICEPS BRACHIALIS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO VASTO INTERMEDIO DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT VASTUS INTERMEDIUM MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO VASTO INTERMEDIO SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT VASTUS INTERMEDIUM MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO VASTO LATERALE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT VASTUS LATERALIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO VASTO LATERALE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT VASTUS LATERALIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO VASTO MEDIALE DESTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF RIGHT VASTUS MEDIALIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE DISTALE DEL MUSCOLO VASTO MEDIALE SINISTRO (INSERZIONE DISTALE)`]: `DISTAL TENDON OF LEFT VASTUS MEDIALIS MUSCLE (DISTAL INSERTION)`,
  [`TENDINE INTERMEDIO DEL MUSCOLO DIGASTRICO DESTRO`]: `INTERMEDIATE TENDON OF RIGHT DIGASTRIC MUSCLE`,
  [`TENDINE INTERMEDIO DEL MUSCOLO DIGASTRICO SINISTRO`]: `INTERMEDIATE TENDON OF LEFT DIGASTRIC MUSCLE`,
  [`TENDINE INTERMEDIO DEL MUSCOLO OMOIOIDEO DESTRO`]: `INTERMEDIATE TENDON OF RIGHT OMOHYOID MUSCLE`,
  [`TENDINE INTERMEDIO DEL MUSCOLO OMOIOIDEO SINISTRO`]: `INTERMEDIATE TENDON OF LEFT OMOHYOID MUSCLE`,
  [`Tendine intramuscolare del muscolo bicipite femorale (DX)`]: `Intramuscular tendon of biceps femoris muscle (R)`,
  [`Tendine intramuscolare del muscolo bicipite femorale (SX)`]: `Intramuscular tendon of biceps femoris muscle (L)`,
  [`Tendine intramuscolare del muscolo soleo (DX)`]: `Intramuscular tendon of soleus muscle (R)`,
  [`Tendine intramuscolare del muscolo soleo (SX)`]: `Intramuscular tendon of soleus muscle (L)`,
  [`TENDINE INTRAMUSCOLARE DEL MUSCOLO SOLEO DESTRO`]: `INTRAMUSCULAR TENDON OF RIGHT SOLEUS`,
  [`TENDINE INTRAMUSCOLARE DEL MUSCOLO SOLEO DESTRO (PORZIONE INTRAMUSCOLARE)`]: `INTRAMUSCULAR TENDON OF RIGHT SOLEUS MUSCLE (INTRAMUSCULAR PORTION)`,
  [`TENDINE INTRAMUSCOLARE DEL MUSCOLO SOLEO SINISTRO`]: `INTRAMUSCULAR TENDON OF LEFT SOLEUS`,
  [`TENDINE INTRAMUSCOLARE DEL MUSCOLO SOLEO SINISTRO (PORZIONE INTRAMUSCOLARE)`]: `INTRAMUSCULAR TENDON OF LEFT SOLEUS MUSCLE (INTRAMUSCULAR PORTION)`,
  [`TENDINE PROSSIMALE DEL CAPO BREVE DEL MUSCOLO BICIPITE BRACHIALE DESTRO (INSERZIONE CORACOIDEA)`]: `PROXIMAL TENDON OF SHORT HEAD OF RIGHT BICEPS BRACHII MUSCLE (CORACOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO BREVE DEL MUSCOLO BICIPITE BRACHIALE SINISTRO (INSERZIONE CORACOIDEA)`]: `PROXIMAL TENDON OF SHORT HEAD OF LEFT BICEPS BRACHII MUSCLE (CORACOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO BREVE DEL MUSCOLO BICIPITE FEMORALE DESTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF SHORT HEAD OF RIGHT BICEPS FEMORIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO BREVE DEL MUSCOLO BICIPITE FEMORALE SINISTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF SHORT HEAD OF LEFT BICEPS FEMORIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LATERALE DEL MUSCOLO TRICIPITE BRACHIALE DESTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF LATERAL HEAD OF RIGHT TRICEPS BRACHII MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LATERALE DEL MUSCOLO TRICIPITE BRACHIALE SINISTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF LATERAL HEAD OF LEFT TRICEPS BRACHII MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO BICIPITE BRACHIALE DESTRO (INSERZIONE GLENOIDEA)`]: `PROXIMAL TENDON OF LONG HEAD OF RIGHT BICEPS BRACHII MUSCLE (GLENOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO BICIPITE BRACHIALE SINISTRO (INSERZIONE GLENOIDEA)`]: `PROXIMAL TENDON OF LONG HEAD OF LEFT BICEPS BRACHII MUSCLE (GLENOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO BICIPITE FEMORALE DESTRO (INSERZIONE ISCHIATICA)`]: `PROXIMAL TENDON OF LONG HEAD OF RIGHT BICEPS FEMORIS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO BICIPITE FEMORALE DESTRO (PORZIONE INTRAMUSCOLARE)`]: `PROXIMAL TENDON OF LONG HEAD OF RIGHT BICEPS FEMORIS MUSCLE (INTRAMUSCULAR PORTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO BICIPITE FEMORALE SINISTRO (INSERZIONE ISCHIATICA)`]: `PROXIMAL TENDON OF LONG HEAD OF LEFT BICEPS FEMORIS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO BICIPITE FEMORALE SINISTRO (PORZIONE INTRAMUSCOLARE)`]: `PROXIMAL TENDON OF LONG HEAD OF LEFT BICEPS FEMORIS MUSCLE (INTRAMUSCULAR PORTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO TRICIPITE BRACHIALE DESTRO (INSERZIONE GLENOIDEA)`]: `PROXIMAL TENDON OF LONG HEAD OF RIGHT TRICEPS BRACHII MUSCLE (GLENOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO LUNGO DEL MUSCOLO TRICIPITE BRACHIALE SINISTRO (INSERZIONE GLENOIDEA)`]: `PROXIMAL TENDON OF LONG HEAD OF LEFT TRICEPS BRACHII MUSCLE (GLENOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO MEDIALE DEL MUSCOLO TRICIPITE BRACHIALE DESTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF MEDIAL HEAD OF RIGHT TRICEPS BRACHII MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL CAPO MEDIALE DEL MUSCOLO TRICIPITE BRACHIALE SINISTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF MEDIAL HEAD OF LEFT TRICEPS BRACHII MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ADDUTTORE BREVE DESTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF RIGHT ADDUCTOR BREVIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ADDUTTORE BREVE SINISTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF LEFT ADDUCTOR BREVIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ADDUTTORE LUNGO DESTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF RIGHT ADDUCTOR LONGUS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ADDUTTORE LUNGO SINISTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF LEFT ADDUCTOR LONGUS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ADDUTTORE MINIMO DESTRO (INSERZIONE ISCHIOPUBICA)`]: `PROXIMAL TENDON OF RIGHT ADDUCTOR MINIMUS MUSCLE (ISCHIOPUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ADDUTTORE MINIMO SINISTRO (INSERZIONE ISCHIOPUBICA)`]: `PROXIMAL TENDON OF LEFT ADDUCTOR MINIMUS MUSCLE (ISCHIOPUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ANCONEO DESTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF RIGHT ANCONEUS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ANCONEO SINISTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF LEFT ANCONEUS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO BRACHIALE DESTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF RIGHT BRACHIALIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO BRACHIALE SINISTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF LEFT BRACHIALIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO BRACHIORADIALE DESTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF RIGHT BRACHIORADIALIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO BRACHIORADIALE SINISTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF LEFT BRACHIORADIALIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO CORACOBRACHIALE DESTRO (INSERZIONE CORACOIDEA)`]: `PROXIMAL TENDON OF RIGHT CORACOBRACHIALIS MUSCLE (CORACOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO CORACOBRACHIALE SINISTRO (INSERZIONE CORACOIDEA)`]: `PROXIMAL TENDON OF LEFT CORACOBRACHIALIS MUSCLE (CORACOID INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT EXTENSOR HALLUCIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE LUNGO DELL'ALLUCE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT EXTENSOR HALLUCIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT EXTENSOR DIGITORUM LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE LUNGO DELLE DITA SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT EXTENSOR DIGITORUM LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF RIGHT EXTENSOR CARPI ULNARIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO DESTRO (INSERZIONE ULNARE)`]: `PROXIMAL TENDON OF RIGHT EXTENSOR CARPI ULNARIS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO (INSERZIONE OMERALE)`]: `PROXIMAL TENDON OF LEFT EXTENSOR CARPI ULNARIS MUSCLE (HUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ESTENSORE ULNARE DEL CARPO SINISTRO (INSERZIONE ULNARE)`]: `PROXIMAL TENDON OF LEFT EXTENSOR CARPI ULNARIS MUSCLE (ULNAR INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE BREVE DELLE DITA DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT FLEXOR DIGITORUM BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE BREVE DELLE DITA SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT FLEXOR DIGITORUM BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE LUNGO DELL'ALLUCE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT FLEXOR HALLUCIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE LUNGO DELL'ALLUCE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT FLEXOR HALLUCIS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT FLEXOR DIGITORUM PROFUNDUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE PROFONDO DELLE DITA SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT FLEXOR DIGITORUM PROFUNDUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (INSERZIONE OMEROULNARE)`]: `PROXIMAL TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (HUMEROULNAR INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA DESTRO (INSERZIONE RADIALE)`]: `PROXIMAL TENDON OF RIGHT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (RADIAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (INSERZIONE OMEROULNARE)`]: `PROXIMAL TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (HUMEROULNAR INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO FLESSORE SUPERFICIALE DELLE DITA SINISTRO (INSERZIONE RADIALE)`]: `PROXIMAL TENDON OF LEFT FLEXOR DIGITORUM SUPERFICIALIS MUSCLE (RADIAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GASTROCNEMIO LATERALE DESTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF RIGHT GASTROCNEMIUS LATERALIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GASTROCNEMIO LATERALE SINISTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF LEFT GASTROCNEMIUS LATERALIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GASTROCNEMIO MEDIALE DESTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF RIGHT GASTROCNEMIUS MEDIALIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GASTROCNEMIO MEDIALE SINISTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF LEFT GASTROCNEMIUS MEDIALIS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRACILE DESTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF RIGHT GRACILIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRACILE SINISTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF LEFT GRACILIS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRANDE ADDUTTORE DESTRO (INSERZIONE ISCHIOPUBICA)`]: `PROXIMAL TENDON OF RIGHT ADDUCTOR MAGNUS MUSCLE (ISCHIOPUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRANDE ADDUTTORE SINISTRO (INSERZIONE ISCHIOPUBICA)`]: `PROXIMAL TENDON OF LEFT ADDUCTOR MAGNUS MUSCLE (ISCHIOPUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRANDE GLUTEO DESTRO (INSERZIONE ILEOSACRALE)`]: `PROXIMAL TENDON OF RIGHT GLUTEUS MAXIMUS MUSCLE (ILIOSACRAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRANDE GLUTEO SINISTRO (INSERZIONE ILEOSACRALE)`]: `PROXIMAL TENDON OF LEFT GLUTEUS MAXIMUS MUSCLE (ILIOSACRAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRANDE PSOAS DESTRO (INSERZIONE VERTEBRALE)`]: `PROXIMAL TENDON OF RIGHT PSOAS MAJOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO GRANDE PSOAS SINISTRO (INSERZIONE VERTEBRALE)`]: `PROXIMAL TENDON OF LEFT PSOAS MAJOR MUSCLE (VERTEBRAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ILIACO DESTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF RIGHT ILIACUS MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO ILIACO SINISTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF LEFT ILIACUS MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO MEDIO GLUTEO DESTRO (INSERZIONE ILIACA)`]: `TENDON OF RIGHT GLUTEUS MEDIUS MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO MEDIO GLUTEO SINISTRO (INSERZIONE ILIACA)`]: `TENDON OF LEFT GLUTEUS MEDIUS MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PERONEO BREVE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT PERONEUS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PERONEO BREVE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT PERONEUS BREVIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PERONEO LUNGO DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT PERONEUS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PERONEO LUNGO SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT PERONEUS LONGUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PERONEO TERZO DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT PERONEUS TERTIUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PERONEO TERZO SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT PERONEUS TERTIUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PETTINEO DESTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF RIGHT PECTINEUS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PETTINEO SINISTRO (INSERZIONE PUBICA)`]: `PROXIMAL TENDON OF LEFT PECTINEUS MUSCLE (PUBIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PICCOLO GLUTEO DESTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF RIGHT GLUTEUS MINOR MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PICCOLO GLUTEO SINISTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF LEFT GLUTEUS MINOR MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PLANTARE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT PLANTARIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PLANTARE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT PLANTARIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO POPLITEO DESTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF RIGHT POPLITEUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO POPLITEO SINISTRO (INSERZIONE FEMORALE)`]: `PROXIMAL TENDON OF LEFT POPLITEUS MUSCLE (FEMORAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PRONATORE ROTONDO DESTRO (INSERZIONE ULNOOMERALE)`]: `PROXIMAL TENDON OF RIGHT PRONATOR TERES MUSCLE (ULNOHUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO PRONATORE ROTONDO SINISTRO (INSERZIONE ULNOOMERALE)`]: `PROXIMAL TENDON OF LEFT PRONATOR TERES MUSCLE (ULNOHUMERAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO RETTO FEMORALE DESTRO (CAPO DIRETTO)`]: `PROXIMAL TENDON OF RIGHT RECTUS FEMORIS MUSCLE (DIRECT HEAD)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO RETTO FEMORALE DESTRO (CAPO INDIRETTO)`]: `PROXIMAL TENDON OF RIGHT RECTUS FEMORIS MUSCLE (INDIRECT HEAD)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO RETTO FEMORALE DESTRO (PORZIONE INTRAMUSCOLARE)`]: `PROXIMAL TENDON OF RIGHT RECTUS FEMORIS MUSCLE (INTRAMUSCULAR TENDON)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO RETTO FEMORALE SINISTRO (CAPO DIRETTO)`]: `PROXIMAL TENDON OF LEFT RECTUS FEMORIS MUSCLE (DIRECT HEAD)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO RETTO FEMORALE SINISTRO (CAPO INDIRETTO)`]: `PROXIMAL TENDON OF LEFT RECTUS FEMORIS MUSCLE (INDIRECT HEAD)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO RETTO FEMORALE SINISTRO (PORZIONE INTRAMUSCOLARE)`]: `PROXIMAL TENDON OF LEFT RECTUS FEMORIS MUSCLE (INTRAMUSCULAR TENDON)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO SARTORIO DESTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF RIGHT SARTORIUS MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO SARTORIO SINISTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF LEFT SARTORIUS MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO SEMIMEMBRANOSO DESTRO (INSERZIONE ISCHIATICA)`]: `PROXIMAL TENDON OF RIGHT SEMIMEMBRANOSUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO SEMIMEMBRANOSO SINISTRO (INSERZIONE ISCHIATICA)`]: `PROXIMAL TENDON OF LEFT SEMIMEMBRANOSUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO SEMITENDINOSO DESTRO (INSERZIONE ISCHIATICA)`]: `PROXIMAL TENDON OF RIGHT SEMITENDINOSUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO SEMITENDINOSO SINISTRO (INSERZIONE ISCHIATICA)`]: `PROXIMAL TENDON OF LEFT SEMITENDINOSUS MUSCLE (ISCHIATIC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO TENSORE DELLA FASCIA LATA DESTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF RIGHT TENSOR FASCIA LATA MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO TENSORE DELLA FASCIA LATA SINISTRO (INSERZIONE ILIACA)`]: `PROXIMAL TENDON OF LEFT TENSOR FASCIA LATA MUSCLE (ILIAC INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO TIBIALE ANTERIORE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT TIBIALIS ANTERIOR MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO TIBIALE ANTERIORE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT TIBIALIS ANTERIOR MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO TIBIALE POSTERIORE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT TIBIALIS POSTERIOR MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO TIBIALE POSTERIORE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT TIBIALIS POSTERIOR MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO VASTO INTERMEDIO DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT VASTUS INTERMEDIUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO VASTO INTERMEDIO SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT VASTUS INTERMEDIUS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO VASTO LATERALE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT VASTUS LATERALIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO VASTO LATERALE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT VASTUS LATERALIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO VASTO MEDIALE DESTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF RIGHT VASTUS MEDIALIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PROSSIMALE DEL MUSCOLO VASTO MEDIALE SINISTRO (INSERZIONE PROSSIMALE)`]: `PROXIMAL TENDON OF LEFT VASTUS MEDIALIS MUSCLE (PROXIMAL INSERTION)`,
  [`TENDINE PUBOCOCCIGEO`]: `PUBOCOCCYGEAL TENDON`,
  [`Tendine quadricipitale (DX)`]: `Quadricipital tendon (R)`,
  [`Tendine quadricipitale (SX)`]: `Quadricipital tendon (L)`,
  [`TENDINE QUADRICIPITALE DESTRO`]: `RIGHT QUADRICIPITAL TENDON`,
  [`TENDINE QUADRICIPITALE SINISTRO`]: `LEFT QUADRICIPITAL TENDON`,
  [`Tendine rotuleo (DX)`]: `Patellar tendon (R)`,
  [`Tendine rotuleo (SX)`]: `Patellar tendon (L)`,
  [`TENDINE ROTULEO DESTRO`]: `RIGHT PATELLAR TENDON`,
  [`TENDINE ROTULEO SINISTRO`]: `LEFT PATELLAR TENDON`,
  [`Tendineo`]: `Tendineous`,
  [`Tendinopathy crossing anatomical boundaries`]: `Tendinopathy crossing anatomical boundaries`,
  [`Tendinopatia`]: `Tendinopathy`,
  [`Tendinopatia`]: `Tendinopathy`,
  [`Tendinopatia a localizzazione sconosciuta`]: `Tendinopathy location unspecified`,
  [`Tendinopatia achillea`]: `Achilles tendinopathy`,
  [`Tendinopatia calcifica`]: `Calcific tendinopathy`,
  [`Tendinopatia degli estensori dell'avambraccio`]: `Forearm extensor tendinopathy`,
  [`tendinopatia degli estensori delle dita`]: `extensor digitorum tendinopathy`,
  [`tendinopatia dei flessori-pronatori (gomito del golfista o epitrocleite)`]: `golfer elbow`,
  [`tendinopatia dei muscoli estensori (epicondilite o gomito del tennista)`]: `tennis elbow`,
  [`Tendinopatia dei muscoli peronieri`]: `peroneus muscles tendinopathy`,
  [`tendinopatia dei peronieri`]: `peroneal tendinopathy`,
  [`Tendinopatia del bicipite brachiale`]: `Biceps tendinopathy`,
  [`Tendinopatia del capo lungo del bicipite brachiale`]: `Biceps brachii long head tendinopathy`,
  [`tendinopatia del flessore lungo dell'alluce`]: `Flexor hallucis longus tendinopathy`,
  [`Tendinopatia del flessore lungo dell'alluce`]: `Flexor Hallucis Longus tendinopathy`,
  [`tendinopatia del flessore radiale del carpo`]: `Flexor carpi radialis tendinopathy`,
  [`tendinopatia del flessore ulnare del carpo`]: `Flexor carpi ulnaris tendinopathy`,
  [`Tendinopatia del gastrocnemio laterale a livello prossimale`]: `Lateral gastroc tendinopathy knee`,
  [`Tendinopatia del gastrocnemio mediale a livello prossimale`]: `Medial gastroc tendinopathy knee`,
  [`Tendinopatia del grande adduttore`]: `Adductor magnus tendinopathy`,
  [`tendinopatia del medio gluteo`]: `Gluteus medium tendinopathy`,
  [`Tendinopatia del medio/piccolo gluteo`]: `Gluteus med/min tendinopathy`,
  [`tendinopatia del muscolo plantare`]: `plantar muscle tendinopathy`,
  [`Tendinopatia del piriforme`]: `Piriformis tendinopathy`,
  [`Tendinopatia del retto dell'addome`]: `Rectus abdominus tendinopathy`,
  [`Tendinopatia del sopraspinato`]: `Supraspinatus tendinopathy`,
  [`Tendinopatia del tendine estensore a livello del piede`]: `Extensor tendinopathy in foot`,
  [`Tendinopatia del tendine quadricipitale +/- borsite soprarotulea`]: `Quadriceps tendinopathy +/- suprapatellar bursitis`,
  [`tendinopatia del tibiale anteriore`]: `tibialis anterior tendinopathy`,
  [`tendinopatia del tibiale posteriore`]: `tibialis posterior tendinopathy`,
  [`Tendinopatia dell'ileopsoas`]: `Iliopsoas tendinopathy`,
  [`Tendinopatia dell'ileopsoas con borsite`]: `Iliopsoas tendinopathy/bursitis`,
  [`Tendinopatia dell'origine del flessore comune (compresa il gomito del golfista)`]: `Golfer's elbow (medial epicondyle pain)`,
  [`Tendinopatia dell'origine dell'estensore comune (compresa il gomito del tennista)`]: `Tennis elbow (lateral epicondyle pain)`,
  [`tendinopatia della cuffia dei rotatori`]: `Rotator cuff tendinopathy`,
  [`tendinopatia della porzione media del tendine d'achille`]: `mid-belly Achilles tendinopathy`,
  [`tendinopatia della zampa d'oca`]: `pes anserinus tendinopathy`,
  [`tendinopatia distale del bicipite brachiale`]: `distal tendinopathy of biceps brachii`,
  [`Tendinopatia distale del bicipite brachiale`]: `Distal biceps tendinopathy`,
  [`tendinopatia distale del semimembranoso`]: `distal tendinopathy of semimembranosus`,
  [`tendinopatia distale del tricipite brachiale`]: `distal tendinopathy of triceps brachii`,
  [`Tendinopatia distale del tricipite brachiale`]: `Distal triceps tendinopathy`,
  [`Tendinopatia inserzionale degli ischiocrurali con borsite ischiatica`]: `Hamstring tendinopathy with ischial bursitis`,
  [`Tendinopatia inserzionale degli ischiocrurali mediali (compresa la borsite della zampa d'oca)`]: `Medial hamstring insertion tendonitis/pes anserinus bursitis`,
  [`Tendinopatia inserzionale del bicipite femorale`]: `Lateral hamstring insertion tendonitis`,
  [`Tendinopatia inserzionale del retto addominale`]: `Abdominal tendon insertion tendinopathy`,
  [`tendinopatia inserzionale del tendine d'achille`]: `Insertional tendinopathy of Achilles tendon`,
  [`Tendinopatia inserzionale del tibiale posteriore`]: `Tibialis posterior insertional tendinopathy`,
  [`Tendinopatia inserzionale prossimale degli ischiocrurali (hamstring)`]: `Hamstring origin tendinopathy`,
  [`Tendinopatia intercostale`]: `Intercostal tendinopathy`,
  [`Tendinopatia prossimale degli adduttori`]: `Adductor origin tendinopathy`,
  [`tendinopatia prossimale degli ischio-crurali (hamstring syndrome)`]: `Hamstring proximal tendinopathy`,
  [`tendinopatia prossimale del gastrocnemio laterale`]: `proximal tendinopathy of lateral gastrocnemius`,
  [`tendinopatia prossimale del gastrocnemio mediale`]: `proximal tendinopathy of medial gastrocnemius`,
  [`Tendinopatia prossimale del retto femorale`]: `Rectus femoris origin tendinopathy`,
  [`Tendinopatia prossimale del sartorio`]: `Sartorius tendinopathy`,
  [`tendinopatia quadricipitale`]: `quadricipital tendinopathy`,
  [`tendinopatia rotulea`]: `patellar tendinopathy`,
  [`Tendinopatia rotulea (esclusa la sindrome di Sinding Larsen Johannson)`]: `Patellar tendinopathy`,
  [`Tendinopatia/entesopatia dei glutei`]: `Gluteal tendinopathy/enthesopathy`,
  [`Tennis`]: `Tennis`,
  [`Tenoperiostite della gamba`]: `Tenoperiostitis of lower leg`,
  [`Tenoperiostite di coscia`]: `Tenoperiostitis of Thigh`,
  [`Tenosinovite degli estensori del polso/ tendinopatia del polso (esclusa la sindrome da intersezione)`]: `wrist extensor tenosynovitis/tendinopathy at wrist`,
  [`Tenosinovite degli estensori dell'avambraccio`]: `Forearm extensor tenosynovitis`,
  [`Tenosinovite del tibiale anteriore`]: `Tibialis anterior tenosynovitis`,
  [`tenosinovite dell'estensore radiale del carpo`]: `Extensor radialis carpis tenosynovitis `,
  [`tenosinovite dell'estensore ulnare del carpo`]: `Extensor ulnaris carpis tenosynovitis `,
  [`tenosinovite di De Quervain`]: `De Quervain's tenosynovitis`,
  [`Tenosinovite di De Quervain`]: `De Quervain's tenosynovitis`,
  [`Tenosinovite/tendinopatia dei flessori del polso`]: `Wrist flexor tenosynovitis/tendinopathy`,
  [`Tenosinovite/tendinopatia dei flessori delle dita`]: `Finger flexor tenosynovitis/tendinopathy`,
  [`Tenosinovite/tendinopatia dell' estensore del/le dito/a`]: `Finger(s) extensor tenosynovitis/tendinopathy`,
  [`Tensione`]: `Tension`,
  [`TENTORIO DEL CERVELLETTO`]: `CEREBELLAR TENTORIUM`,
  [`Termale`]: `Thermal`,
  [`Termoregolatorio`]: `Thermoregulatory`,
  [`Terribile`]: `Dreadful`,
  [`Terrificante`]: `Terryfing`,
  [`Terzo distale`]: `Distal third`,
  [`Terzo medio`]: `Middle third`,
  [`Terzo prossimale`]: `Proximal third`,
  [`Terzo superiore`]: `Proximal third`,
  [`Teso`]: `Taut`,
  [`Tessuti molli`]: `Soft tissues`,
  [`Tessuti superficiali/pelle`]: `Superficial tissues/skin`,
  [`Tessuto superficiale/pelle`]: `Superficial Tissue/Skin`,
  [`Test combinato di estensione e rotazione cervicale`]: `Combined Cervical Extension and Rotation Test`,
  [`Test dei movimenti di inseguimento oculare`]: `Smuth pursuits ocular motor test`,
  [`Test del ballottamento rotuleo (DX)`]: `Patellar Ballottement Test (R)`,
  [`Test del ballottamento rotuleo (SX)`]: `Patellar Ballottement Test (L)`,
  [`Test del legamento alare cervicale`]: `Cervical Alar Ligament Test`,
  [`Test del legamento trasverso cervicale`]: `Cervical Transverse Ligament Test`,
  [`Test del riflesso vestibolo-oculare orizzontale`]: `Horizontal vestibulo-ocular reflex test`,
  [`Test del riflesso vestibolo-oculare verticale`]: `Vertical vestibulo-ocular reflex test`,
  [`Test dell'estensione del gomito (DX)`]: `Elbow Extension Test (R)`,
  [`Test dell'estensione del gomito (SX)`]: `Elbow Extension Test (L)`,
  [`Test della convergenza oculare`]: `Convergence test`,
  [`Test della discesa del gradino (DX)`]: `Step Down Test (R)`,
  [`Test della discesa del gradino (SX)`]: `Step Down Test (L)`,
  [`Test della membrana occipitoatlantoidea posteriore`]: `Posterior Atlanto-Occipital Membrane Test`,
  [`Test della membrana tentoriale`]: `Tectorial Membrane Test`,
  [`Test della sensibilita' visuo-motoria`]: `Visual motion sensitivity test`,
  [`Test di flessione-rotazione C1-C2`]: `C1-C2 Flexion-Rotation Test`,
  [`Test movimenti saccadici orizzontali`]: `Horizontal saccades ocular motor test`,
  [`Test movimenti saccadici verticali`]: `Vertical saccades ocular motor test`,
  [`Test per la frattura di Jefferson`]: `Jefferson Fracture Test`,
  [`Testa`]: `Head`,
  [`TESTA`]: `HEAD`,
  [`Testicoli`]: `Testicles`,
  [`TESTICOLI (M)`]: `TESTICLES (M)`,
  [`TESTICOLO DESTRO (M)`]: `RIGHT TESTICLE (M)`,
  [`TESTICOLO SINISTRO (M)`]: `LEFT TESTICLE (M)`,
  [`Tests di esclusione`]: `Role-out tests`,
  [`Tests muscolari manuali`]: `Manual muscles tests`,
  [`Tests speciali`]: `Special tests`,
  [`Thessaly Test (DX)`]: `Thessaly Test (R)`,
  [`Thessaly Test (SX)`]: `Thessaly Test (L)`,
  [`Thigh region physisinjury`]: `Thigh region physisinjury`,
  [`Thigh Thrust Test (DX)`]: `Thigh Thrust Test (R)`,
  [`Thigh thrust Test (SX)`]: `Thigh Thrust Test (L)`,
  [`Thomas G. Mcpoil et al. Clinical Guidelines, Heel Pain – Plantar Fasciitis: Clinical practice guidelines linked to the international classification of functioning, disability, and health from the orthopaedic section of the American physical therapy ass...`]: `Thomas G. Mcpoil et al. Clinical Guidelines, Heel Pain – Plantar Fasciitis: Clinical practice guidelines linked to the international classification of functioning, disability, and health from the orthopaedic section of the American physical therapy ass...`,
  [`Thomas Test (DX)`]: `Thomas Test (R)`,
  [`Thomas Test (SX)`]: `Thomas Test (L)`,
  [`Thompson Test (DX)`]: `Thompson Test (R)`,
  [`Thompson Test (SX)`]: `Thompson Test (L)`,
  [`Three-Cone Drill Test`]: `Three-Cone Drill Test`,
  [`Ti sembra che l'articolazione mandibolare sia limitata nei movimenti?`]: `Do you feel a restriction of movement on your temporo-mandibular joint?`,
  [`Tibia (DX)`]: `Tibial bone (R)`,
  [`Tibia (SX)`]: `Tibial bone (L)`,
  [`TIBIA DESTRA`]: `RIGHT TIBIA`,
  [`TIBIA DESTRA (DIAFISI)`]: `RIGHT TIBIA (SHAFT)`,
  [`TIBIA DESTRA (EPIFISI PROSSIMALE)`]: `RIGHT TIBIA (SUPERIOR ARTICULAR SURFACE)`,
  [`TIBIA DESTRA (MALLEOLO MEDIALE)`]: `RIGHT TIBIA (MEDIAL MALLEOLUS)`,
  [`TIBIA DESTRA (MARGINE ANTERIORE)`]: `RIGHT TIBIA (ANTERIOR MARGIN)`,
  [`TIBIA DESTRA (MARGINE INTEROSSEO)`]: `RIGHT TIBIA (INTEROSSEOUS MARGIN)`,
  [`TIBIA DESTRA (MARGINE MEDIALE)`]: `RIGHT TIBIA (MEDIAL MARGIN)`,
  [`TIBIA DESTRA (PIATTO TIBIALE)`]: `RIGHT TIBIA (TIBIAL PLATE)`,
  [`TIBIA DESTRA (TUBEROSITA' TIBIALE)`]: `RIGHT TIBIA (TIBIAL TUBEROSITY)`,
  [`Tibia in inarcamento posteriore`]: `Posterior buckling`,
  [`Tibia in torsione esterna`]: `Tibial external twist`,
  [`TIBIA SINISTRA`]: `LEFT TIBIA`,
  [`TIBIA SINISTRA (DIAFISI)`]: `LEFT TIBIA (SHAFT)`,
  [`TIBIA SINISTRA (EPIFISI PROSSIMALE)`]: `LEFT TIBIA (SUPERIOR ARTICULAR SURFACE)`,
  [`TIBIA SINISTRA (MALLEOLO MEDIALE)`]: `LEFT TIBIA (MEDIAL MALLEOLUS)`,
  [`TIBIA SINISTRA (MARGINE ANTERIORE)`]: `LEFT TIBIA (ANTERIOR MARGIN)`,
  [`TIBIA SINISTRA (MARGINE INTEROSSEO)`]: `LEFT TIBIA (INTEROSSEOUS MARGIN)`,
  [`TIBIA SINISTRA (MARGINE MEDIALE)`]: `LEFT TIBIA (MEDIAL MARGIN)`,
  [`TIBIA SINISTRA (PIATTO TIBIALE)`]: `LEFT TIBIA (TIBIAL PLATE)`,
  [`TIBIA SINISTRA (TUBEROSITA' TIBIALE)`]: `LEFT TIBIA (TIBIAL TUBEROSITY)`,
  [`Tibia vara`]: `Tibia vara`,
  [`Tilt anteriore`]: `Anterior tilt`,
  [`Tilt laterale`]: `Lateral tilt`,
  [`Tilt laterale destro`]: `Right lateral tilt`,
  [`Tilt laterale rotula (DX)`]: `Patella lateral tilt (R)`,
  [`Tilt laterale rotula (SX)`]: `Patella lateral tilt (L)`,
  [`Tilt laterale sinistro`]: `Left lateral tilt`,
  [`Tilt mediale`]: `Medial tilt`,
  [`Tilt mediale rotula (DX)`]: `Patella medial tilt (R)`,
  [`Tilt mediale rotula (SX)`]: `Patella medial tilt (L)`,
  [`Tilt pelvico laterale`]: `Pelvic lateral tilt`,
  [`Tilt pelvico superiore`]: `Pelvic superior tilt`,
  [`Tilt posteriore`]: `Posterior tilt`,
  [`Timed Up and Go Test (TUG)`]: `Timed Up and Go Test (TUG)`,
  [`Timed Up and Go Test (TUG)`]: `Timed Up and Go Test (TUG)`,
  [`TIMO`]: `THYMUS`,
  [`Tinea pedis/ piede dell'atleta`]: `Tinea pedis/athlete's foot`,
  [`Tinel's Sign (DX)`]: `Tinel's Sign (R)`,
  [`Tinel's Sign (SX)`]: `Tinel's Sign (L)`,
  [`Tinel's Sign at the Elbow (DX)`]: `Tinel's Sign at the Elbow (R)`,
  [`Tinel's Sign at the Elbow (SX)`]: `Tinel's Sign at the Elbow (L)`,
  [`Tinetti Test POMA`]: `Tinetti Test POMA`,
  [`Tip Pinch Strength Test (DX)`]: `Tip Pinch Strength Test (R)`,
  [`Tip Pinch Strength Test (SX)`]: `Tip Pinch Strength Test (L)`,
  [`Tipo di disallineamento`]: `Misalignement type`,
  [`Tipo di disfunzione di movimento`]: `Movement dysfunction type`,
  [`Tipo di intervento`]: `Type of management`,
  [`Tipo militare`]: `Military posture`,
  [`Tipo miltare`]: `Military posture`,
  [`Tipo posturale`]: `Postural type`,
  [`Tirante`]: `Shooting`,
  [`Tirata Al Mento Con Elastici`]: `UPWARD ROW WITH RESISTANCE BAND`,
  [`Tiroide`]: `Thyroid  `,
  [`TIROIDE`]: `THYROID`,
  [`TIROIDE (LOBO DESTRO)`]: `THYROID (RIGHT LOBE)`,
  [`TIROIDE (LOBO SINISTRO)`]: `THYROID (LEFT LOBE)`,
  [`Tiroidite di Hashimoto`]: `Hashimoto thyroiditis`,
  [`Tissue Texture Change`]: `Tissue texture change`,
  [`Tke Band Pull With Roller`]: `TKE Band Pull with Roller`,
  [`Togliersi i calzini o le calze`]: `Taking off socks/stockings`,
  [`TONSILLE FARINGEE`]: `PHARYNGEAL TONSIL`,
  [`TONSILLE LINGUALI`]: `LINGUAL TONSIL`,
  [`TONSILLE PALATINE`]: `PALATINE TONSIL`,
  [`Tonsillite`]: `Tonsillitis`,
  [`Torace`]: `Thoracic spine`,
  [`Torcere/fare perno sul ginocchio`]: `Twisting/pivoting on your knee`,
  [`Torcere/fare perno sul ginocchio infortunato`]: `Twisting/pivoting on your injured knee`,
  [`Torpido`]: `Dull`,
  [`Torsione ant destra`]: `Right anterior twist`,
  [`Torsione anteriore sinistra`]: `Left anterior twist`,
  [`Torsione destra`]: `Right twist`,
  [`Torsione esterna`]: `External twist`,
  [`Torsione posteriore destra`]: `Right posterior twist`,
  [`Torsione posteriore sinistra`]: `Left posterior twist`,
  [`Torsione sinistra`]: `Left twist`,
  [`Torsione tibiale esterna`]: `External torsion`,
  [`Torturante`]: `Torturing`,
  [`Tosse, dispnea`]: `Cough, dyspnea`,
  [`Totalmente`]: `Totally`,
  [`Towel Pull Ups`]: `Towel Pull Ups`,
  [`TQR`]: `TQR`,
  [`TQR (RECOVERY)`]: `TQR (TOTAL QUALITY RECOVERY)`,
  [`TRACHEA`]: `TRACHEA`,
  [`TRACHEA (PARTE CERVICALE)`]: `TRACHEA (CERVICAL PART)`,
  [`TRACHEA (PARTE TORACICA)`]: `TRACHEA (THORACIC PART)`,
  [`Trapezio (DX)`]: `Trapezius (R)`,
  [`Trapezio (SX)`]: `Trapezius (L)`,
  [`TRAPEZIO DESTRO`]: `RIGHT TRAPEZIUM`,
  [`TRAPEZIO DESTRO (TUBERCOLO)`]: `RIGHT TRAPEZIUM (TUBERCLE)`,
  [`TRAPEZIO SINISTRO`]: `LEFT TRAPEZIUM`,
  [`TRAPEZIO SINISTRO (TUBERCOLO)`]: `LEFT TRAPEZIUM (TUBERCLE)`,
  [`Trapezoide (DX)`]: `Trapezoid (R)`,
  [`Trapezoide (SX)`]: `Trapezoid (L)`,
  [`TRAPEZOIDE DESTRO`]: `RIGHT TRAPEZOID`,
  [`TRAPEZOIDE SINISTRO`]: `LEFT TRAPEZOID`,
  [`Trapianto d'organo`]: `Organ transplants`,
  [`trasloco`]: `Moving house`,
  [`Trattamento`]: `Treatment`,
  [`Tratto ileotibiale (DX)`]: `Iliotibial band (R)`,
  [`Tratto ileotibiale (SX)`]: `Iliotibial band (L)`,
  [`TRATTO ILEOTIBIALE DESTRO`]: `RIGHT ILIOTIBIAL TRACT`,
  [`TRATTO ILEOTIBIALE DESTRO (INSERZIONE DISTALE)`]: `RIGHT ILIOTIBIAL TRACT (DISTAL INSERTION)`,
  [`TRATTO ILEOTIBIALE SINISTRO`]: `LEFT ILIOTIBIAL TRACT`,
  [`TRATTO ILEOTIBIALE SINISTRO (INSERZIONE)`]: `LEFT ILIOTIBIAL TRACT (DISTAL INSERTION)`,
  [`trauma`]: `Trauma  `,
  [`Trauma`]: `Trauma`,
  [`Trauma al fegato`]: `Liver trauma`,
  [`Trauma al moncone`]: `Stump injury`,
  [`Trauma al moncone di arto inferiore`]: `Stump trauma lower limb`,
  [`Trauma al moncone di arto superiore`]: `Stump trauma upper arm`,
  [`Trauma al moncone di avambraccio`]: `Stump trauma forearm`,
  [`Trauma al moncone di coscia`]: `Stump trauma thigh`,
  [`trauma al moncone di ginocchio`]: `Stump trauma knee`,
  [`Trauma al moncone di gomito`]: `Stump trauma elbow`,
  [`Trauma al pancreas`]: `Pancreatic trauma`,
  [`Trauma al rene`]: `Kidney trauma`,
  [`Trauma all'orecchio`]: `Ear trauma`,
  [`Trauma alla milza`]: `Spleen trauma`,
  [`Trauma alla testa/collo non diagnosticato come commozione cerebrale`]: `Head/neck impact not diagnosed as concussion`,
  [`Trauma alla vescica`]: `Bladder trauma`,
  [`Trauma d'organo`]: `Organ trauma`,
  [`trauma diretto`]: `Direct contusion`,
  [`trauma indiretto, sensazione durante un movimento specifico`]: `Indirect mechanism of injury, performing a specific movement`,
  [`Trauma intestinale`]: `Intestinal trauma`,
  [`Trauma laringeo (escluse le fratture)`]: `Laryngeal trauma`,
  [`trauma o caduta`]: `trauma/fall`,
  [`Trauma organico multiplo addominale`]: `Multiple organ trauma`,
  [`Trauma vascolare`]: `Vascular contusion`,
  [`trauma/ caduta`]: `trauma/fall`,
  [`trauma/caduta`]: `fall/trauma`,
  [`trauma/distorsione`]: `Sprain/trauma`,
  [`Traumi contusivi`]: `Contusions`,
  [`Tremolante`]: `Flickering`,
  [`Triade dell'atleta femminile`]: `Female athlete triad`,
  [`Tricep Push Down`]: `Tricep Push Down`,
  [`Tricep Push Down Band`]: `Tricep Push Down Band`,
  [`Trigger point`]: `Trigger point`,
  [`Trigger Point`]: `Trigger point`,
  [`Trigger point dei muscoli lombari`]: `Lumbar trigger pts or muscle spasm`,
  [`Trigger point di muscolo toracico`]: `Thoracic muscle trigger points`,
  [`Trigger point/contrattura/mialgia posteriore della spalla`]: `Shoulder muscle trigger points/posterior muscle soreness`,
  [`Trigger point/crampo/spasmo a muscolo del piede`]: `Foot muscle trigger points, cramping, spasm`,
  [`Trigger points dei muscoli della coscia`]: `Thigh muscle trigger points`,
  [`Trigger points dei muscoli glutei`]: `Buttock muscle trigger points`,
  [`Trigger points dei muscoli ischiocrurali (hamstring)`]: `Lateral hamstring trigger points`,
  [`Trigger points del quadricipite`]: `Rectus femoris trigger points`,
  [`Trigger points dell'ileopsoas`]: `Trigger points iliopsoas`,
  [`Trigger points muscoli faccia`]: `Facial muscle trigger points`,
  [`Trigger points prossimali degli adduttori`]: `Proximal adductor trigger points`,
  [`Trigger points/dolore muscolare del braccio`]: `Upper arm muscle trigger points/pain`,
  [`Trigger points/spasmo del retto dell'addome`]: `Rectus abdominis trigger points/spasm`,
  [`Trigger Points/Spasmo di un muscolo del tronco`]: `Truncal Muscle Trigger Points/Spasm`,
  [`Trigger points/spasmo muscolare a localizzazione multipla`]: `Trigger points/spasm multiple locations`,
  [`Trigger points/spasmo muscolare dei peronieri`]: `Peroneal trigger points/spasm`,
  [`Trigger points/spasmo muscolare del gastrocnemio`]: `Gastroc muscle trigger points/spasm`,
  [`Trigger points/spasmo muscolare del soleo`]: `Soleus Trigger points/Spasm`,
  [`Triple Compression Stress Test in Long Sitting (DX)`]: `Triple Compression Stress Test in Long Sitting (R)`,
  [`Triple Compression Stress Test in Long Sitting (SX)`]: `Triple Compression Stress Test in Long Sitting (L)`,
  [`Triple Hop For Distance Test (DX)`]: `Triple Hop For Distance Test (R)`,
  [`Triple Hop For Distance Test (SX)`]: `Triple Hop For Distance Test (L)`,
  [`Triple Jump for distance Test`]: `Triple Jump for distance Test`,
  [`Tripod Pinch Strength Test (DX)`]: `Tripod Pinch Strength Test (R)`,
  [`Tripod Pinch Strength Test (SX)`]: `Tripod Pinch Strength Test (L)`,
  [`Trombocitopenia`]: `Thrombocytopenia`,
  [`Trombocitosi`]: `Thrombocytosis`,
  [`Trombosi della vena succlavia/vena ascellare`]: `Subclavian vein/axillary vein thrombosis`,
  [`Trombosi venosa profonda`]: `Deep vein thrombosis`,
  [`Trombotica/emorragica`]: `Thrombotic-Haemorrhagic`,
  [`Trombotico/emorragica`]: `Thrombotic/haemorrhagic`,
  [`TRONCO`]: `TRUNK`,
  [`True Leg Length Test (DX)`]: `True Leg Length Test (R)`,
  [`True Leg Length Test (SX)`]: `True Leg Length Test (L)`,
  [`Trunk Curl-Up Test`]: `Trunk Curl-Up Test`,
  [`Trunk Flexor Endurance Test`]: `Trunk Flexor Endurance Test`,
  [`Trunk Twist`]: `Trunk Twist`,
  [`Tubercolosi`]: `Tuberculosis`,
  [`Tuck Jump`]: `Tuck Jump`,
  [`Tumefazione`]: `Swelling`,
  [`Tumore al braccio`]: `Tumour upper arm`,
  [`Tumore al collo`]: `Tumour neck`,
  [`Tumore al ginocchio`]: `Tumour knee`,
  [`Tumore al gomito`]: `Tumour elbow`,
  [`Tumore al piede`]: `Tumour foot`,
  [`Tumore al polso/mano`]: `Tumour wrist/hand`,
  [`Tumore all'anca/inguine`]: `Tumour groin and Hip`,
  [`Tumore all'avambraccio`]: `tumour forearm`,
  [`Tumore alla caviglia`]: `Tumour ankle`,
  [`Tumore alla colonna lombare`]: `Tumour lumbar spine`,
  [`Tumore alla colonna lombare/gluteo`]: `Tumour lumbar buttock`,
  [`Tumore alla colonna toracica/gabbia toracica`]: `Tumour thoracic spine/chest wall`,
  [`Tumore alla coscia`]: `Tumour thigh`,
  [`Tumore alla gamba`]: `Tumour lower leg`,
  [`Tumore alla spalla`]: `Tumour shoulder`,
  [`Tumore alla testa`]: `Tumour head`,
  [`Tumori`]: `Cancer`,
  [`Tuning Fork for ankle fractures (DX)`]: `Tuning Fork for ankle fractures (R)`,
  [`Tuning Fork for ankle fractures (SX)`]: `Tuning Fork for ankle fractures (L)`,
  [`Turning Quad Roller`]: `Turning Quad Roller`,
  [`Two Handed Med Ball Push Up`]: `Two Handed Med Ball Push Up`,
  [`UCLA Scala dolore`]: `UCLA Pain Scale`,
  [`UCLA Scala funzione`]: `UCLA Function Scale`,
  [`Ulcera duodenale`]: `Duodenal ulcer`,
  [`Ulcera gastrica`]: `Gastric ulcer`,
  [`Ulcera o altri problemi gastrici`]: `Ulcer/stomach problems`,
  [`Ulcerazione del piede`]: `Ulceration foot`,
  [`Ulceroso`]: `Sore`,
  [`Ulna (DX)`]: `Ulna (R)`,
  [`Ulna (SX)`]: `Ulna (L)`,
  [`ULNA DESTRA`]: `RIGHT ULNA `,
  [`ULNA DESTRA (DIAFISI)`]: `RIGHT ULNA (SHAFT)`,
  [`ULNA DESTRA (OLECRANO)`]: `RIGHT ULNA (OLECRANON)`,
  [`ULNA DESTRA (PROCESSO CORONOIDEO)`]: `RIGHT ULNA (CORONOID PROCESS)`,
  [`ULNA DESTRA (PROCESSO STILOIDEO)`]: `RIGHT ULNA (STYLOID PROCESS)`,
  [`ULNA DESTRA (TESTA)`]: `RIGHT ULNA (HEAD)`,
  [`ULNA SINISTRA`]: `LEFT ULNA `,
  [`ULNA SINISTRA (DIAFISI)`]: `LEFT ULNA (SHAFT)`,
  [`ULNA SINISTRA (OLECRANO)`]: `LEFT ULNA (OLECRANON)`,
  [`ULNA SINISTRA (PROCESSO CORONOIDEO)`]: `LEFT ULNA (CORONOID PROCESS)`,
  [`ULNA SINISTRA (PROCESSO STILOIDEO)`]: `LEFT ULNA (STYLOID PROCESS)`,
  [`ULNA SINISTRA (TESTA)`]: `LEFT ULNA (HEAD)`,
  [`Ulno-Carpal Stress Test (DX)`]: `Ulno-Carpal Stress Test (R)`,
  [`Ulno-Carpal Stress Test (SX)`]: `Ulno-Carpal Stress Test (L)`,
  [`Un po'`]: `Quite a bit`,
  [`Un po' migliore adesso rispetto a un anno fa`]: `Somewhat better than one year ago`,
  [`Un po' peggiore adesso rispetto a un anno fa`]: `Somewhat worse now than one year ago`,
  [`Un poco`]: `Mildly`,
  [`Una parte del tempo`]: `Some of the time`,
  [`Una volta al mese`]: `Monthly`,
  [`Una volta alla settimana`]: `Weekly`,
  [`Una volta riscaldato, ha dolore allungando al massimo il tendine di Achille con l'avampiede sul bordo di un gradino? (Tenendo il ginocchio esteso e rigido)`]: `Once you are warmed up for the day, do you have pain when stretching the Achilles tendon fully over the edge of a step? (keeping knee straight)`,
  [`Uncinato (DX)`]: `Hamate (R)`,
  [`Uncinato (SX)`]: `Hamate (L)`,
  [`UNCINATO DESTRO`]: `RIGHT HAMATE`,
  [`UNCINATO DESTRO (UNCINO)`]: `RIGHT HAMATE (HOOK)`,
  [`UNCINATO SINISTRO`]: `LEFT HAMATE`,
  [`UNCINATO SINISTRO (UNCINO)`]: `LEFT HAMATE (HOOK)`,
  [`Unghia del II dito del piede (DX)`]: `Nail of II toe (R)`,
  [`Unghia del II dito del piede (SX)`]: `Nail of II toe (L)`,
  [`UNGHIA DEL II DITO DEL PIEDE DESTRO`]: `NAIL OF II TOE OF RIGHT FOOT`,
  [`UNGHIA DEL II DITO DEL PIEDE SINISTRO`]: `NAIL OF II TOE OF LEFT FOOT`,
  [`Unghia del II dito della mano (DX)`]: `Nail of index finger (R)`,
  [`Unghia del II dito della mano (SX)`]: `Nail of index finger (L)`,
  [`UNGHIA DEL II DITO DELLA MANO DESTRA`]: `NAIL OF RIGHT INDEX FINGER`,
  [`UNGHIA DEL II DITO DELLA MANO SINISTRA`]: `NAIL OF LEFT INDEX FINGER`,
  [`Unghia del III dito del piede (DX)`]: `Nail of III toe (R)`,
  [`Unghia del III dito del piede (SX)`]: `Nail of III toe (L)`,
  [`UNGHIA DEL III DITO DEL PIEDE DESTRO`]: `NAIL OF III TOE OF RIGHT FOOT`,
  [`UNGHIA DEL III DITO DEL PIEDE SINISTRO`]: `NAIL OF III TOE OF LEFT FOOT`,
  [`Unghia del III dito della mano (DX)`]: `Nail of middle finger (R)`,
  [`Unghia del III dito della mano (SX)`]: `Nail of middle finger (L)`,
  [`UNGHIA DEL III DITO DELLA MANO DESTRA`]: `NAIL OF RIGHT MIDDLE FINGER`,
  [`UNGHIA DEL III DITO DELLA MANO SINISTRA`]: `NAIL OF LEFT MIDDLE FINGER`,
  [`Unghia del IV dito del piede (DX)`]: `Nail of IV toe (R)`,
  [`Unghia del IV dito del piede (SX)`]: `Nail of IV toe (L)`,
  [`UNGHIA DEL IV DITO DEL PIEDE DESTRO`]: `NAIL OF IV TOE OF RIGHT FOOT`,
  [`UNGHIA DEL IV DITO DEL PIEDE SINISTRO`]: `NAIL OF IV TOE OF LEFT FOOT`,
  [`Unghia del IV dito della mano (DX)`]: `Nail of ring finger (R)`,
  [`Unghia del IV dito della mano (SX)`]: `Nail of ring finger (L)`,
  [`UNGHIA DEL IV DITO DELLA MANO DESTRA`]: `NAIL OF RIGHT RING FINGER`,
  [`UNGHIA DEL IV DITO DELLA MANO SINISTRA`]: `NAIL OF LEFT RING FINGER`,
  [`Unghia del pollice (DX)`]: `Nail of thumb (R)`,
  [`Unghia del pollice (SX)`]: `Nail of thumb (L)`,
  [`UNGHIA DEL POLLICE DELLA MANO DESTRA`]: `NAIL OF RIGHT THUMB`,
  [`UNGHIA DEL POLLICE DELLA MANO SINISTRA`]: `NAIL OF LEFT THUMB`,
  [`Unghia del V dito del piede (DX)`]: `Nail of V toe (R)`,
  [`Unghia del V dito del piede (SX)`]: `Nail of V toe (L)`,
  [`UNGHIA DEL V DITO DEL PIEDE DESTRO`]: `NAIL OF V TOE OF RIGHT FOOT`,
  [`UNGHIA DEL V DITO DEL PIEDE SINISTRO`]: `NAIL OF V TOE OF LEFT FOOT`,
  [`Unghia del V dito della mano (DX)`]: `Nail of little finger (R)`,
  [`Unghia del V dito della mano (SX)`]: `Nail of little finger (L)`,
  [`UNGHIA DEL V DITO DELLA MANO DESTRA`]: `NAIL OF RIGHT LITTLE FINGER`,
  [`UNGHIA DEL V DITO DELLA MANO SINISTRA`]: `NAIL OF LEFT LITTLE FINGER`,
  [`Unghia dell'alluce (DX)`]: `Nail of hallux (R)`,
  [`Unghia dell'alluce (SX)`]: `Nail of hallux (L)`,
  [`UNGHIA DELL'ALLUCE DESTRO`]: `NAIL OF RIGHT HALLUX`,
  [`UNGHIA DELL'ALLUCE SINISTRO`]: `NAIL OF LEFT HALLUX`,
  [`Upper Cut Test (DX)`]: `Upper Cut Test (R)`,
  [`Upper Cut Test (SX)`]: `Upper Cut Test (L)`,
  [`Upper Foot Ball Press`]: `Upper Foot Ball Press`,
  [`Upper Quarter Y-Balance Test (inferolaterale) (DX)`]: `Upper Quarter Y-Balance Test (inferolateral) (R)`,
  [`Upper Quarter Y-Balance Test (inferolaterale) (SX)`]: `Upper Quarter Y-Balance Test (inferolateral) (L)`,
  [`Upper Quarter Y-Balance Test (infolaterale) (DX)`]: `Upper Quarter Y-Balance Test (inferolateral) (R)`,
  [`Upper Quarter Y-Balance Test (mediale) (DX)`]: `Upper Quarter Y-Balance Test (medial) (R)`,
  [`Upper Quarter Y-Balance Test (mediale) (SX)`]: `Upper Quarter Y-Balance Test (medial) (L)`,
  [`Upper Quarter Y-Balance Test (superolaterale) (DX)`]: `Upper Quarter Y-Balance Test (superolateral) (R)`,
  [`Upper Quarter Y-Balance Test (superolaterale) (SX)`]: `Upper Quarter Y-Balance Test (superolateral) (L)`,
  [`URETERE DESTRO`]: `RIGHT URETER`,
  [`URETERE DESTRO (PARTE ADDOMINALE)`]: `RIGHT URETER (ABDOMINAL PART)`,
  [`URETERE DESTRO (PARTE PELVICA)`]: `RIGHT URETER (PELVIC PART)`,
  [`URETERE SINISTRO`]: `LEFT URETER`,
  [`URETERE SINISTRO (PARTE ADDOMINALE)`]: `LEFT URETER (ABDOMINAL PART)`,
  [`URETERE SINISTRO (PARTE PELVICA)`]: `LEFT URETER (PELVIC PART)`,
  [`URETRA (F)`]: `FEMALE URETHRA (F)`,
  [`URETRA (M)`]: `MALE URETHRA (M)`,
  [`urinare`]: `Urinating`,
  [`Urinare o defecare`]: `urination or defecation`,
  [`Usare il coltello per tagliare del cibo`]: `Use a knife to cut food`,
  [`Usare la carta igienica con la mano malata`]: `Use bathroom tissue with my affected hand`,
  [`Usare un coltello per tagliare il cibo`]: `Use a knife to cut food`,
  [`Usato un ausilio (bastone, deambulatore, stampelle, ecc) all'aperto?`]: `Use an assistive device outdoors?`,
  [`Usato un ausilio (bastone, deambulatore, stampelle, ecc) in casa?`]: `Use an assistive device (cane, walker, crutches, etc.) indoors?`,
  [`Uso di droghe`]: `Drug use`,
  [`Uso di ortesi`]: `Orthosis`,
  [`Uso, vantaggi e svantaggi`]: `Pros and Conts`,
  [`Ustione`]: `Heat Illness`,
  [`UTERO (CERVICE) (F)`]: `UTERUS (CERVIX) (F)`,
  [`UTERO (CORPO) (F)`]: `UTERUS (BODY) (F)`,
  [`UTERO (F)`]: `UTERUS (F)`,
  [`UTERO (FONDO) (F)`]: `UTERUS (FUNDUS) (F)`,
  [`UTERO (TUBA UTERINA DESTRA) (F)`]: `RIGHT UTERINE TUBE (F)`,
  [`UTERO (TUBA UTERINA SINISTRA) (F)`]: `LEFT UTERINE TUBE (F)`,
  [`V ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT V STERNOCOSTAL JOINT`,
  [`V ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT V STERNOCOSTAL JOINT`,
  [`V ARTICOLAZIONE COSTOCONDRALE DESTRA`]: `RIGHT V COSTOCHONDRAL JOINT`,
  [`V ARTICOLAZIONE COSTOCONDRALE SINISTRA`]: `LEFT V COSTOCHONDRAL JOINT`,
  [`V ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT V COSTOTRANSVERSE JOINT`,
  [`V ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT V COSTOTRANSVERSE JOINT`,
  [`V ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT V COSTOVERTEBRAL JOINT`,
  [`V ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT V COSTOVERTEBRAL JOINT`,
  [`V costa (DX)`]: `V rib (R)`,
  [`V costa (SX)`]: `V rib (L)`,
  [`V COSTA DESTRA`]: `RIGHT V RIB`,
  [`V COSTA SINISTRA`]: `LEFT V RIB`,
  [`V metacarpo (DX)`]: `V metacarpal (R)`,
  [`V metacarpo (SX)`]: `V metacarpal (L)`,
  [`V METACARPO DESTRO`]: `RIGHT V METACARPAL`,
  [`V METACARPO DESTRO (BASE)`]: `RIGHT V METACARPAL (BASE)`,
  [`V METACARPO DESTRO (TESTA)`]: `RIGHT V METACARPAL (HEAD)`,
  [`V METACARPO SINISTRO`]: `LEFT V METACARPAL`,
  [`V METACARPO SINISTRO (BASE)`]: `LEFT V METACARPAL (BASE)`,
  [`V METACARPO SINISTRO (TESTA)`]: `LEFT V METACARPAL (HEAD)`,
  [`V metatarso (base) (DX)`]: `V metatarsal (base) (R)`,
  [`V metatarso (base) (SX)`]: `V metatarsal (base) (L)`,
  [`V metatarso (DX)`]: `V metatarsal (R)`,
  [`V metatarso (SX)`]: `V metatarsal (L)`,
  [`V METATARSO DESTRO`]: `RIGHT V METATARSAL`,
  [`V METATARSO DESTRO (BASE)`]: `RIGHT V METATARSAL (BASE)`,
  [`V METATARSO DESTRO (TESTA)`]: `RIGHT V METATARSAL (HEAD)`,
  [`V METATARSO SINISTRO`]: `LEFT V METATARSAL`,
  [`V METATARSO SINISTRO (BASE)`]: `LEFT V METATARSAL (BASE)`,
  [`V METATARSO SINISTRO (TESTA)`]: `LEFT V METATARSAL (HEAD)`,
  [`V Qualità della vita`]: `Quality of life`,
  [`Valgismo`]: `Valgus`,
  [`Valgismo con rotazione esterna di tibia`]: `Tibial external rotation/knee valgus`,
  [`Valgismo e rotazione esterna di tibia`]: `Knee valgus & tibial external rotation`,
  [`Valgo`]: `Valgus`,
  [`Valore`]: `Value`,
  [`Valutativo`]: `evaluative`,
  [`Valutazione`]: `Assessment`,
  [`Valutazione Addominale`]: `Abdominal assessment`,
  [`Valutazione Anca`]: `Hip assessment`,
  [`Valutazione Avambraccio`]: `Forearm assessment`,
  [`Valutazione Braccio`]: `Upper arm assessment`,
  [`Valutazione cardiologica`]: `Cardiac preparticipation screening`,
  [`Valutazione Caviglia e piede`]: `Ankle & foot assessment`,
  [`Valutazione Cervicale`]: `Neck assessment`,
  [`Valutazione Coscia anteriore`]: `Anterior thigh assessment`,
  [`Valutazione Coscia posteriore`]: `Posterior thigh assessment`,
  [`Valutazione Cranio`]: `Head assessment`,
  [`Valutazione dei movimenti`]: `Movement Assessment`,
  [`Valutazione flessibilità`]: `Flexibility assessment`,
  [`Valutazione flessibilita' muscolare`]: `Myofascial flexibility Assessment`,
  [`Valutazione forza articolare monodirezionale soggettiva`]: `rROM assessment`,
  [`Valutazione Gamba`]: `Leg assessment`,
  [`Valutazione Ginocchio`]: `Knee assessment`,
  [`Valutazione Glutea/Sacro-iliaca`]: `Gluteal/sacroiliac assessment`,
  [`Valutazione Gomito`]: `Elbow assessment`,
  [`Valutazione Lombare`]: `Lumbar assessment`,
  [`Valutazione palpatoria`]: `Palpation`,
  [`Valutazione Polso e mano`]: `Wrist & hand assessment`,
  [`Valutazione ROM attivo e movimenti funzionali`]: `aROM & functional movement assessment`,
  [`Valutazione ROM contro resistenza`]: `Resisted ROM Assessment`,
  [`Valutazione ROM passivo`]: `Passive ROM Assessment`,
  [`Valutazione ROM passivo soggettivo`]: `pROM assessment`,
  [`Valutazione Spalla`]: `Shoulder assessment`,
  [`Valutazione Torace`]: `Thorax assessment`,
  [`Valuti l'intensità dei seguenti sintomi durante l'ultima settimana`]: `Please rate the severity of the following symptoms in the last week`,
  [`Valuti la sua capacità di eseguire le seguenti azioni durante l'ultima settimana`]: `Please rate your ability to do the following activities in the last week`,
  [`Valvola aortica bicuspide`]: `Bicuspid aortic valve`,
  [`VALVOLA ILEOCIECALE`]: `ILEOCECAL VALVE`,
  [`Vara`]: `Vara`,
  [`Variabilità cardiaca`]: `Heart Rate Variability`,
  [`Varicella`]: `Chicken Pox`,
  [`Varicocele`]: `Varicocele`,
  [`Varismo`]: `Varus`,
  [`Varo`]: `Varus`,
  [`VAS-U (DOLORE MEDIO PERCEPITO)`]: `VAS-U (VISUAL ANALOGUE SCALE - USUAL)`,
  [`VAS-W (PEGGIOR DOLORE PERCEPITO)`]: `VAS-U (VISUAL ANALOGUE SCALE - WORST)`,
  [`Vasi`]: `Vessels`,
  [`Vaso`]: `Vessel`,
  [`Velocità massima`]: `Maximal speed`,
  [`VENA ASCELLARE DESTRA`]: `RIGHT AXILLARY VEIN`,
  [`VENA ASCELLARE SINISTRA`]: `LEFT AXILLARY VEIN`,
  [`VENA AZYGOS`]: `AZYGOS VEIN`,
  [`VENA BASILICA DELL'AVAMBRACCIO DESTRO`]: `BASILIC VEIN OF RIGHT FOREARM`,
  [`VENA BASILICA DELL'AVAMBRACCIO SINISTRO`]: `BASILIC VEIN OF LEFT FOREARM`,
  [`VENA BASILICA DESTRA`]: `RIGHT BASILIC VEIN`,
  [`VENA BASILICA SINISTRA`]: `LEFT BASILIC VEIN`,
  [`VENA BRACHIOCEFALICA DESTRA`]: `RIGHT BRACHIOCEPHALIC VEIN`,
  [`VENA BRACHIOCEFALICA SINISTRA`]: `LEFT BRACHIOCEPHALIC VEIN`,
  [`VENA CARDIACA MAGNA`]: `GREAT CARDIAC VEIN`,
  [`VENA CARDIACA MEDIA`]: `MIDDLE CARDIAC VEIN`,
  [`VENA CARDIACA PICCOLA`]: `SMALL CARDIAC VEIN`,
  [`VENA CAVA INFERIORE`]: `INFERIOR VENA CAVA`,
  [`VENA CAVA SUPERIORE`]: `SUPERIOR VENA CAVA`,
  [`VENA CEFALICA DELL'AVAMBRACCIO DESTRO`]: `CEPHALIC VEIN OF RIGHT FOREARM`,
  [`VENA CEFALICA DELL'AVAMBRACCIO SINISTRO`]: `CEPHALIC VEIN OF LEFT FOREARM`,
  [`VENA CEFALICA DESTRA`]: `RIGHT CEPHALIC VEIN`,
  [`VENA CEFALICA SINISTRA`]: `LEFT CEPHALIC VEIN`,
  [`VENA CIRCONFLESSA ILIACA PROFONDA DESTRA`]: `RIGHT DEEP CIRCUMFLEX ILIAC VEIN`,
  [`VENA CIRCONFLESSA ILIACA PROFONDA SINISTRA`]: `LEFT DEEP CIRCUMFLEX ILIAC VEIN`,
  [`VENA CIRCONFLESSA ILIACA SUPERFICIALE DESTRA`]: `RIGHT SUPERFICIAL CIRCUMFLEX ILIAC VEIN`,
  [`VENA CIRCONFLESSA ILIACA SUPERFICIALE SINISTRA`]: `LEFT SUPERFICIAL CIRCUMFLEX ILIAC VEIN`,
  [`VENA CUBITALE MEDIANA DESTRA`]: `RIGHT MEDIAN CUBITAL VEIN`,
  [`VENA CUBITALE MEDIANA SINISTRA`]: `LEFT MEDIAN CUBITAL VEIN`,
  [`VENA EMIAZYGOS`]: `HEMIAZYGOS VEIN`,
  [`VENA EPIGASTRICA INFERIORE DESTRA`]: `RIGHT INFERIOR EPIGASTRIC VEIN`,
  [`VENA EPIGASTRICA INFERIORE SINISTRA`]: `LEFT INFERIOR EPIGASTRIC VEIN`,
  [`VENA FACIALE DESTRA`]: `RIGHT FACIAL VEIN`,
  [`VENA FACIALE SINISTRA`]: `LEFT FACIAL VEIN`,
  [`VENA FEMORALE DESTRA`]: `RIGHT FEMORAL VEIN`,
  [`VENA FEMORALE PROFONDA DESTRA`]: `LEFT FEMORAL VEIN`,
  [`VENA FEMORALE PROFONDA SINISTRA`]: `RIGHT PROFUNDA FEMORIS VEIN`,
  [`VENA FEMORALE SINISTRA`]: `LEFT PROFUNDA FEMORIS VEIN`,
  [`VENA FRENICA DESTRA`]: `RIGHT PHRENIC VEIN`,
  [`VENA FRENICA SINISTRA`]: `LEFT PHRENIC VEIN`,
  [`VENA GIUGULARE ESTERNA DESTRA`]: `RIGHT EXTERNAL JUGULAR VEIN`,
  [`VENA GIUGULARE ESTERNA SINISTRA`]: `LEFT EXTERNAL JUGULAR VEIN`,
  [`VENA GIUGULARE INTERNA DESTRA`]: `RIGHT INTERNAL JUGULAR VEIN`,
  [`VENA GIUGULARE INTERNA SINISTRA`]: `LEFT INTERNAL JUGULAR VEIN`,
  [`VENA GLUTEA INFERIORE DESTRA`]: `RIGHT INFERIOR GLUTEAL VEINS`,
  [`VENA GLUTEA INFERIORE SINISTRA`]: `LEFT INFERIOR GLUTEAL VEINS`,
  [`VENA GLUTEA SUPERIORE DESTRA`]: `RIGHT INFERIOR GLUTEAL VEINS`,
  [`VENA GLUTEA SUPERIORE SINISTRA`]: `LEFT INFERIOR GLUTEAL VEINS`,
  [`VENA GRANDE SAFENA DESTRA`]: `RIGHT GREAT SAPHENOUS VEIN`,
  [`VENA GRANDE SAFENA SINISTRA`]: `LEFT GREAT SAPHENOUS VEIN`,
  [`VENA ILIACA COMUNE DESTRA`]: `RIGHT COMMON ILIAC VEIN`,
  [`VENA ILIACA COMUNE SINISTRA`]: `LEFT COMMON ILIAC VEIN`,
  [`VENA ILIACA ESTERNA DESTRA`]: `RIGHT EXTERNAL ILIAC VEIN`,
  [`VENA ILIACA ESTERNA SINISTRA`]: `LEFT EXTERNAL ILIAC VEIN`,
  [`VENA ILIACA INTERNA DESTRA`]: `RIGHT INTERNAL ILIAC VEIN`,
  [`VENA ILIACA INTERNA SINISTRA`]: `LEFT INTERNAL ILIAC VEIN`,
  [`VENA MEDIANA DELL'AVAMBRACCIO DESTRO`]: `RIGHT MEDIAN ANTEBRACHIAL VEIN`,
  [`VENA MEDIANA DELL'AVAMBRACCIO SINISTRO`]: `LEFT MEDIAN ANTEBRACHIAL VEIN`,
  [`VENA MESENTERICA INFERIORE`]: `RIGHT INFERIOR MESENTERIC VEIN`,
  [`VENA MESENTERICA SUPERIORE`]: `LEFT INFERIOR MESENTERIC VEIN`,
  [`VENA OBLIQUA DELL'ATRIO SINISTRO`]: `OBLIQUE VEIN OF LEFT ATRIUM`,
  [`VENA OFTALMICA INFERIORE DESTRA`]: `RIGHT INFERIOR OPHTHALMIC VEIN`,
  [`VENA OFTALMICA INFERIORE SINISTRA`]: `LEFT INFERIOR OPHTHALMIC VEIN`,
  [`VENA OFTALMICA SUPERIORE DESTRA`]: `RIGHT SUPERIOR OPHTHALMIC VEIN`,
  [`VENA OFTALMICA SUPERIORE SINISTRA`]: `LEFT SUPERIOR OPHTHALMIC VEIN`,
  [`VENA OVARICA DESTRA (F)`]: `RIGHT OVARIAN VEIN (F)`,
  [`VENA OVARICA SINISTRA (F)`]: `LEFT OVARIAN VEIN (F)`,
  [`VENA PICCOLA SAFENA DESTRA`]: `RIGHT SMALL SAPHENOUS VEIN`,
  [`VENA PICCOLA SAFENA SINISTRA`]: `LEFT SMALL SAPHENOUS VEIN`,
  [`VENA POLMONARE INFERIORE DESTRA`]: `RIGHT INFERIOR PULMUNARY VEIN`,
  [`VENA POLMONARE INFERIORE SINISTRA`]: `LEFT INFERIOR PULMUNARY VEIN`,
  [`VENA POLMONARE SUPERIORE DESTRA`]: `RIGHT SUPERIOR PULMUNARY VEIN`,
  [`VENA POLMONARE SUPERIORE SINISTRA`]: `LEFT SUPERIOR PULMUNARY VEIN`,
  [`VENA POPLITEA DESTRA`]: `RIGHT POPLITEAL VEIN`,
  [`VENA POPLITEA SINISTRA`]: `LEFT POPLITEAL VEIN`,
  [`VENA PORTA`]: `HEPATIC PORTAL VEIN`,
  [`VENA RENALE DESTRA`]: `RIGHT RENAL VEIN`,
  [`VENA RENALE SINISTRA`]: `LEFT RENAL VEIN`,
  [`VENA RETROMANDIBOLARE DESTRA`]: `RIGHT RETROMANDIBULAR VEIN`,
  [`VENA RETROMANDIBOLARE SINISTRA`]: `LEFT RETROMANDIBULAR VEIN`,
  [`VENA SACRALE MEDIANA`]: `MEDIAN SACRAL VEIN`,
  [`VENA SPLENICA`]: `SPLENIC VEIN`,
  [`VENA SUCCLAVIA DESTRA`]: `RIGHT SUBCLAVIAN VEIN`,
  [`VENA SUCCLAVIA SINISTRA`]: `LEFT SUBCLAVIAN VEIN`,
  [`VENA SURRENALE DESTRA`]: `RIGHT SUPRARENAL VEIN`,
  [`VENA SURRENALE SINISTRA`]: `LEFT SUPRARENAL VEIN`,
  [`VENA TESTICOLARE DESTRA (M)`]: `RIGHT TESTICULAR VEIN (M)`,
  [`VENA TESTICOLARE SINISTRA (M)`]: `LEFT TESTICULAR VEIN (M)`,
  [`VENE BRACHIALI DI DESTRA`]: `RIGHT BRACHIAL VEINS`,
  [`VENE BRACHIALI DI SINISTRA`]: `LEFT BRACHIAL VEINS`,
  [`VENE CEREBELLARI`]: `CEREBELLAR VEINS`,
  [`VENE CEREBRALI PROFONDE`]: `DEEP CEREBRAL VEINS`,
  [`VENE CEREBRALI SUPERFICIALI`]: `SUPERFICIAL CEREBRAL VEINS`,
  [`VENE DEL CUORE`]: `VEINS OF HEART`,
  [`VENE DEL TRONCO ENCEFALICO`]: `VEINS OF BRAINSTEM`,
  [`VENE DELL'ENCEFALO`]: `CEREBRAL VEINS`,
  [`VENE EPATICHE`]: `HEPATIC VEINS`,
  [`VENE INTEROSSEE ANTERIORI DI DESTRA`]: `RIGHT ANTERIOR INTEROSSEOUS VEINS`,
  [`VENE INTEROSSEE ANTERIORI DI SINISTRA`]: `LEFT ANTERIOR INTEROSSEOUS VEINS`,
  [`VENE INTEROSSEE POSTERIORI DI DESTRA`]: `RIGHT POSTERIOR INTEROSSEOUS VEINS`,
  [`VENE INTEROSSEE POSTERIORI DI SINISTRA`]: `LEFT POSTERIOR INTEROSSEOUS VEINS`,
  [`VENE INTRARENALI DI DESTRA`]: `RIGHT INTRARENAL VEINS`,
  [`VENE INTRARENALI DI SINISTRA`]: `LEFT INTRARENAL VEINS`,
  [`VENE LOMBARI ASCENDENTI`]: `ASCENDING LUMBAR VEIN`,
  [`VENE ORBITALI DI DESTRA`]: `RIGHT ORBITAL VEINS`,
  [`VENE ORBITALI DI SINISTRA`]: `LEFT ORBITAL VEINS`,
  [`VENE POLMONARI`]: `PULMONARY VEINS`,
  [`VENE RADIALI DI DESTRA`]: `RIGHT RADIAL VEINS`,
  [`VENE RADIALI DI SINISTRA`]: `LEFT RADIAL VEINS`,
  [`VENE ULNARI DI DESTRA`]: `RIGHT ULNAR VEINS`,
  [`VENE ULNARI DI SINISTRA`]: `LEFT ULNAR VEINS`,
  [`Vene varicose`]: `Varicose veins`,
  [`vento`]: `Wind`,
  [`Vernon H, Mior S. The neck disability index: A study of reliability and validity. Journal of Manipulative and Physiological Therapeutics, 1991, 14:409-15`]: `Vernon H, Mior S. The neck disability index: A study of reliability and validity. Journal of Manipulative and Physiological Therapeutics, 1991, 14:409-15`,
  [`Verruche ai piedi, comprese verruche plantari`]: `Feet warts - incl plantar warts`,
  [`Verruche al polso o alla mano`]: `Wrist and hand warts`,
  [`VERTEBRE CERVICALI`]: `CERVICAL VERTEBRAE`,
  [`VERTEBRE CERVICALI INFERIORI`]: `INFERIOR CERVICAL VERTEBRAE `,
  [`VERTEBRE CERVICALI SUPERIORI`]: `SUPERIOR CERVICAL VERTEBRAE`,
  [`VERTEBRE LOMBARI`]: `LUMBAR VERTEBRAE [L1-L5]`,
  [`VERTEBRE TORACICHE`]: `THORACIC VERTEBRAE`,
  [`Vertebre toraciche (processi spinosi)`]: `Thoracic vertebrae (spinous processes)`,
  [`Vertebre toraciche (processi trasversi)`]: `Thoracic vertebrae (transverse processes)`,
  [`VERTEBRE TORACICHE INFERIORI`]: `INFERIOR THORACIC VERTEBRAE`,
  [`VERTEBRE TORACICHE INTERMEDIE`]: `INTERMEDIATE THORACIC VERTEBRAE`,
  [`VERTEBRE TORACICHE SUPERIORI`]: `SUPERIOR THORACIC VERTEBRAE`,
  [`Vertical Jump Test`]: `Vertical Jump Test`,
  [`Verticalizzazione`]: `Verticalization`,
  [`vertigini`]: `Dizziness`,
  [`Vertigini, svenimenti,`]: `Dizziness, fainting, blackouts`,
  [`VESCICA`]: `URINARY BLADDER`,
  [`VESCICA (COLLO)`]: `URINARY BLADDER (NECK)`,
  [`VESCICA (CORPO)`]: `URINARY BLADDER (BODY)`,
  [`VESCICA (FONDO)`]: `URINARY BLADDER (`,
  [`Vescica al piede`]: `Blisters foot`,
  [`Vescica al polso/mano (comprese le dita e il pollice)`]: `Blisters of wrist/hand (incl fingers/thumb)`,
  [`Vescica al tallone`]: `Blisters heel`,
  [`VESCICOLA BILIARE`]: `GALLBLADDER`,
  [`VI ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT VI STERNOCOSTAL JOINT`,
  [`VI ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT VI STERNOCOSTAL JOINT`,
  [`VI ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT VI COSTOTRANSVERSE JOINT`,
  [`VI ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT VI COSTOTRANSVERSE JOINT`,
  [`VI ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT VI COSTOVERTEBRAL JOINT`,
  [`VI ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT VI COSTOVERTEBRAL JOINT`,
  [`VI costa (DX)`]: `VI rib (R)`,
  [`VI costa (SX)`]: `VI rib (L)`,
  [`VI COSTA DESTRA`]: `RIGHT VI RIB`,
  [`VI COSTA SINISTRA`]: `LEFT VI RIB`,
  [`VII ARTICOLAZIONE CONDROSTERNALE DESTRA`]: `RIGHT VII STERNOCOSTAL JOINT`,
  [`VII ARTICOLAZIONE CONDROSTERNALE SINISTRA`]: `LEFT VII STERNOCOSTAL JOINT`,
  [`VII ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT VII COSTOTRANSVERSE JOINT`,
  [`VII ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT VII COSTOTRANSVERSE JOINT`,
  [`VII ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT VII COSTOVERTEBRAL JOINT`,
  [`VII ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT VII COSTOVERTEBRAL JOINT`,
  [`VII costa (DX)`]: `VII rib (R)`,
  [`VII costa (SX)`]: `VII rib (L)`,
  [`VII COSTA DESTRA`]: `RIGHT VII RIB`,
  [`VII COSTA SINISTRA`]: `LEFT VII RIB`,
  [`VIII ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT VIII COSTOTRANSVERSE JOINT`,
  [`VIII ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT VIII COSTOTRANSVERSE JOINT`,
  [`VIII ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT VIII COSTOVERTEBRAL JOINT`,
  [`VIII ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT VIII COSTOVERTEBRAL JOINT`,
  [`VIII costa (DX)`]: `VIII rib (R)`,
  [`VIII costa (SX)`]: `VIII rib (L)`,
  [`VIII COSTA DESTRA`]: `RIGHT VIII RIB`,
  [`VIII COSTA SINISTRA`]: `LEFT VIII RIB`,
  [`VISA-A (TENDINE D'ACHILLE)`]: `VISA-P (VICTORIAN INSTITUTE OF SPORT ASSESSMENT - ACHILLES TENDON)`,
  [`VISA-P (TENDINE ROTULEO)`]: `VISA-P (VICTORIAN INSTITUTE OF SPORT ASSESSMENT - PATELLAR TENDON)`,
  [`Viscerale`]: `Visceral`,
  [`Visibile`]: `Visible`,
  [`Visible`]: `Visible`,
  [`Vivace e brillante`]: `Did you feel full of pep?`,
  [`VO2 MAX diretto`]: `VO2 max (direct)`,
  [`VO2 MAX teorico`]: `VO2 max (indirect)`,
  [`Volume di Kg sollevati`]: `Volume of lifted Kg`,
  [`VOMERE`]: `VOMER`,
  [`vomitare`]: `Vomiting`,
  [`vomito`]: `Vomit`,
  [`Vulnerability in the Neutral Zone`]: `Vulnerability in the Neutral Zone`,
  [`VULVA (F)`]: `VULVA`,
  [`VULVA (GRANDI LABBRA) (F)`]: `VULVA (LABIUM MAJUS) (F)`,
  [`VULVA (IMENE) (F)`]: `VAGINA (HYMEN) (F)`,
  [`VULVA (VAGINA) (F)`]: `VAGINA (F)`,
  [`W Raise With Scapula Depression`]: `W Raise With Scapula Depression`,
  [`Wainner's Clinical Prediction Rule (DX)`]: `Wainner's Clinical Prediction Rule (R)`,
  [`Wainner's Clinical Prediction Rule (SX)`]: `Wainner's Clinical Prediction Rule (L)`,
  [`Walking Abduction Hip Circles`]: `Walking Abduction Hip Circles`,
  [`Walking Ankle Pull Ins`]: `Walking Ankle Pull Ins`,
  [`Walking Arm Circles`]: `Walking Arm Circles`,
  [`Walking Band Half Lunge`]: `Walking Band Half Lunge`,
  [`Walking Bend Forward Ankle Grab`]: `Walking Bend Forward Ankle Grab`,
  [`Walking Dumbbell Rdl`]: `Walking Dumbbell RDL`,
  [`Walking Forward On Toes`]: `Walking Forward on Toes`,
  [`Walking Knee Pulls`]: `Walking Knee Pulls`,
  [`Walking Quad Stretch`]: `Walking Quad Stretch`,
  [`Wall Leg Up With Crossover Push And Pull`]: `Wall Leg Up with Crossover push and pull`,
  [`Wall to Occiput Distance`]: `Wall to Occiput Distance`,
  [`Watson Scaphoid Test (DX)`]: `Watson Scaphoid Test (R)`,
  [`Watson Scaphoid Test (SX)`]: `Watson Scaphoid Test (L)`,
  [`Watt massimi`]: `Maximal power output`,
  [`Watt medi`]: `Mean power output`,
  [`Web Space Tenderness (DX)`]: `Web Space Tenderness (R)`,
  [`Web Space Tenderness (SX)`]: `Web Space Tenderness (L)`,
  [`Wellness score`]: `Wellness score`,
  [`Whiplash Associated Disorder`]: `Whiplash Associated Disorder`,
  [`Wide Stance Back Squat`]: `Wide Stance Back Squat`,
  [`Windlass o Jack Test (DX)`]: `Windlass or Jack Test (R)`,
  [`Windlass o Jack Test (SX)`]: `Windlass or Jack Test (L)`,
  [`World’S Greatest Stretch`]: `World’s Greatest Stretch`,
  [`Wright Test`]: `Wright Test`,
  [`Wrist Ballottement Test (DX)`]: `Wrist Ballottement Test (R)`,
  [`Wrist Ballottement Test (SX)`]: `Wrist Ballottement Test (L)`,
  [`Wrist Flexion and Finger Extension Test (DX)`]: `Wrist Flexion and Finger Extension Test (R)`,
  [`Wrist Flexion and Finger Extension Test (SX)`]: `Wrist Flexion and Finger Extension Test (L)`,
  [`Wrist Flexion and Median Nerve Compression (DX)`]: `Wrist Flexion and Median Nerve Compression (R)`,
  [`Wrist Flexion and Median Nerve Compression (SX)`]: `Wrist Flexion and Median Nerve Compression (L)`,
  [`Wrist Ratio Index (DX)`]: `Wrist Ratio Index (R)`,
  [`Wrist Ratio Index (SX)`]: `Wrist Ratio Index (L)`,
  [`Wylie JD, Beckmann JT, Granger E, Tashjian RZ. Functional outcomes assessment in shoulder surgery. World J Orthop 2014; 5(5): 623-633`]: `Wylie JD, Beckmann JT, Granger E, Tashjian RZ. Functional outcomes assessment in shoulder surgery. World J Orthop 2014; 5(5): 623-633`,
  [`X ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT X COSTOTRANSVERSE JOINT`,
  [`X ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT X COSTOTRANSVERSE JOINT`,
  [`X ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT X COSTOVERTEBRAL JOINT`,
  [`X ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT X COSTOVERTEBRAL JOINT`,
  [`X costa (DX)`]: `X rib (R)`,
  [`X costa (SX)`]: `X rib (L)`,
  [`X COSTA DESTRA`]: `RIGHT X RIB`,
  [`X COSTA SINISTRA`]: `LEFT X RIB`,
  [`XI ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT XI COSTOTRANSVERSE JOINT`,
  [`XI ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT XI COSTOTRANSVERSE JOINT`,
  [`XI ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT XI COSTOVERTEBRAL JOINT`,
  [`XI ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT XI COSTOVERTEBRAL JOINT`,
  [`XI costa (DX)`]: `XI rib (R)`,
  [`XI costa (SX)`]: `XI rib (L)`,
  [`XI COSTA DESTRA`]: `RIGHT XI RIB`,
  [`XI COSTA SINISTRA`]: `LEFT XI RIB`,
  [`XII ARTICOLAZIONE COSTOTRASVERSARIA DESTRA`]: `RIGHT XII COSTOTRANSVERSE JOINT`,
  [`XII ARTICOLAZIONE COSTOTRASVERSARIA SINISTRA`]: `LEFT XII COSTOTRANSVERSE JOINT`,
  [`XII ARTICOLAZIONE COSTOVERTEBRALE DESTRA`]: `RIGHT XII COSTOVERTEBRAL JOINT`,
  [`XII ARTICOLAZIONE COSTOVERTEBRALE SINISTRA`]: `LEFT XII COSTOVERTEBRAL JOINT`,
  [`XII costa (DX)`]: `XII rib (R)`,
  [`XII costa (SX)`]: `XII rib (L)`,
  [`XII COSTA DESTRA`]: `RIGHT XII RIB`,
  [`XII COSTA SINISTRA`]: `LEFT XII RIB`,
  [`Y Raise With Scap Depression`]: `Y Raise with Scap Depression`,
  [`Yergason's Test (DX)`]: `Yergason's Test (R)`,
  [`Yergason's Test (SX)`]: `Yergason's Test (L)`,
  [`Young BA, Walker MJ, Strunce JB, Boyles RE, Whitman JM, Childs JD. Responsiveness of the Neck Disability Index in patients with mechanical neck disorders. Spine, 2009 Jul 24, online article ahead of print.`]: `Young BA, Walker MJ, Strunce JB, Boyles RE, Whitman JM, Childs JD. Responsiveness of the Neck Disability Index in patients with mechanical neck disorders. Spine, 2009 Jul 24, online article ahead of print.`,
  [`Young IA, Cleland JA, Michener LA, Brown C. Reliability, Construct Validity, and Responsiveness of the Neck Disability Index, Patient-Specific Functional Scale, and Numeric Pain Rating Scale in Patients with Cervical Radiculopathy, American Journal of Ph...`]: `Young IA, Cleland JA, Michener LA, Brown C. Reliability, Construct Validity, and Responsiveness of the Neck Disability Index, Patient-Specific Functional Scale, and Numeric Pain Rating Scale in Patients with Cervical Radiculopathy, American Journal of Ph...`,
  [`Zercher Good Morning`]: `Zercher Good Morning`,
  [`Zigzag Run Test`]: `Zigzag Run Test`,
};
