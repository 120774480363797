import { Divider, Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import { labels } from "../../../shared/translations";
import { StudyACWREWMA } from "./reportStudies/StudyACWREWMA";
import { StudyACWRRA } from "./reportStudies/StudyACWRRA";
import { StudyComparationAss } from "./reportStudies/StudyComparationAss";
import { StudyComparationPerc } from "./reportStudies/StudyComparationPerc";
import { StudyMonotony } from "./reportStudies/StudyMonotony";
import { StudyStrain } from "./reportStudies/StudyStrain";
import { StudyTemporalEvolution } from "./reportStudies/StudyTemporalEvolution";
import { StudyZScore } from "./reportStudies/StudyZScore";
import { StudySymmetry } from "./reportStudies/StudySymmetry";
import { StudyAsymmetry } from "./reportStudies/StudyAsymmetry";
import { StudySymmetryAss } from "./reportStudies/StudySymmetryAss";

const moment = extendMoment(Moment);

const STUDIES = {
  TEMPORAL: StudyTemporalEvolution,
  MONOTONY: StudyMonotony,
  STRAIN: StudyStrain,
  ACWRRA: StudyACWRRA,
  ACWREWMA: StudyACWREWMA,
  COMPAREPERC: StudyComparationPerc,
  COMPAREASS: StudyComparationAss,
  ZSCORE: StudyZScore,
  SYMMETRY: StudySymmetry,
  ASYMMETRY: StudyAsymmetry,
  SYMMETRYASS: StudySymmetryAss,
};

const ReportStudies = ({
  mainSubjectKey,
  studies,
  dateRange,
  fetchDateRange,
  graphDateView,
  graphType,
  currStudyParameters,
  data,
  onFetchDateRangeChange,
  setStateGraphData,
  defaultZero = false,

  studiesMeasureUnits,
  setStudiesMeasureUnits,

  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  //These state aggregates data from/to studies and present it to parent through callbacks
  const [studiesDaysFromEnd, setStudiesDaysFromEnd] = useState({});

  //Update studies days range/measure units
  const updateStudiesDaysFromEnd = (daysFromEnd, key) => {
    setStudiesDaysFromEnd((prevDays) => {
      prevDays[key] = daysFromEnd;
      return prevDays;
    });
  };

  //Update fetch data range in parent
  const updateFetchDataRange = () => {
    const newRange = dateRange.clone();
    Object.values(studiesDaysFromEnd).forEach((daysFromEnd) => {
      //Use ranges to check if we need to fetch new data
      const filterRange = moment.rangeFromInterval(
        "days",
        daysFromEnd,
        dateRange.end
      );
      newRange.add(filterRange, { adjacent: true });
    });

    if (!fetchDateRange.contains(newRange)) onFetchDateRangeChange(newRange);
  };

  //Get computed data from studies and updates graph data in parent
  const addNewGraphData = (newData, study) => {
    setStateGraphData((prevData) => {
      let hasData = false;

      //Empty data must present -> AXIS: { CURVE_NAME: {}}
      const newGraphData = { ...prevData };
      Object.entries(newData).forEach(([curveKey, curveData]) => {
        if (!newGraphData[curveKey]) newGraphData[curveKey] = {};

        Object.entries(curveData).forEach(([key, value]) => {
          if (value && Object.values(value).length > 0) {
            newGraphData[curveKey][key] = value;
            hasData = true;
          } else delete newGraphData[curveKey][key];
        });

        if (Object.keys(newGraphData[curveKey]).length === 0)
          delete newGraphData[curveKey];
      });

      //Update studies measure units
      setStudiesMeasureUnits((prevData) => {
        const newData = { ...prevData };
        if (study.um != null) {
          if (hasData) newData[study.key] = study.um;
          else delete newData[study.key];
        }
        return newData;
      });

      return newGraphData;
    });
  };

  //Effects
  useEffect(() => {
    updateFetchDataRange();
  }, [studiesDaysFromEnd, dateRange]);

  //Render
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <SpText variant="h4ComponentLabel">
          {labels.patient.graphReport.section.feedback.filters.studies}
        </SpText>
      </Grid>
      {studies.map((study) => {
        const Study = STUDIES[study.key];
        return (
          <Grid container item xs={12} key={study.key}>
            <Divider
              style={{
                padding: "1px",
                backgroundColor: "#31ccad",
                width: "100%",
                marginTop: "5px",
              }}
            />
            <Study
              mainSubjectKey={mainSubjectKey}
              study={study}
              graphDateView={graphDateView}
              graphType={graphType}
              currStudyParameters={currStudyParameters}
              dateRange={dateRange}
              studiesMeasureUnits={[
                ...new Set(Object.values(studiesMeasureUnits)),
              ]}
              data={data}
              onFetchDateDaysChange={(ranges) =>
                updateStudiesDaysFromEnd(ranges, study.key)
              }
              onStudyDataChange={(newData) => addNewGraphData(newData, study)}
              defaultZero={defaultZero}
              config={config?.[study.key]}
              setConfig={(data) => setConfig({ [study.key]: data })}
              requestSaveFlag={requestSaveFlag}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withSnackbar(ReportStudies);
