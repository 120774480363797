import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import "../App.css";
import SpText from "../components/atoms/SpText";
import SpIconButton from "../components/atoms/SpIconButton.js";

import { styled } from "../components/styled";
import { labels } from "./shared/translations";
import { getProfessionalById } from "../models/actions/Professionals";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import AccountButton from "../components/bundles/account/AccountButton";
import { isBusiness, isAuth0Enabled, log } from "../utils/common";

const StyledRowMain = styled("div")({
  display: "flex",
  flexDirection: "row",
  flex: "1 1 50%",
  marginTop: "1%",
});

const StyledColumnRight = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  paddingLeft: "5%",
  paddingRight: "5%",
});

const StyledColumnLeft = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  paddingLeft: "5%",
  paddingRight: "5%",
});

const Account = (props) => {
  const [explode, setExplode] = React.useState(false);

  // const bomb = () => {
  //   throw new Error("💥 CABOOM 💥");
  // };

  const [enableAuth0, setEnableAuth0] = useState(false);
  const [professionalData, setProfessionalData] = useState();
  const history = useHistory();
  const fetchData = async () => {
    try {
      const response = await getProfessionalById();
      setProfessionalData(response);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    const auth0Enabled = await isAuth0Enabled();
    setEnableAuth0(auth0Enabled);
    //Fetching data from the server
    await fetchData();
  }, []);

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>
        {!props.value || props.value.toString().trim() === ""
          ? "---"
          : props.value}
      </SpText>
    </Grid>
  );

  const PersonalInfo = () => (
    <Grid item xs={12}>
      <StyledRowMain>
        <StyledColumnLeft>
          <LabelValue
            label={labels.account.accountForm.givenName.toUpperCase()}
            value={professionalData?.name}
          />
          <LabelValue
            label={labels.account.accountForm.familyName.toUpperCase()}
            value={professionalData?.surname}
          />
          <LabelValue
            label={labels.account.accountForm.birthDate.toUpperCase()}
            value={professionalData?.birth_date}
          />
          <LabelValue
            label={labels.account.accountForm.email.toUpperCase()}
            value={professionalData?.email}
          />
          <LabelValue
            label={labels.account.accountForm.fiscalCode.toUpperCase()}
            value={professionalData?.fiscal_code}
          />
          <LabelValue
            label={labels.account.accountForm.piva.toUpperCase()}
            value={professionalData?.piva}
          />
        </StyledColumnLeft>

        <StyledColumnRight>
          <LabelValue
            label={labels.account.accountForm.telephone.toUpperCase()}
            value={professionalData?.telephone}
          />

          {/* <div>
            <button onClick={() => setExplode((e) => !e)}>
              toggle explode
            </button>
            {explode ? bomb() : null}
          </div> */}

          <LabelValue
            label={labels.account.accountForm.address.toUpperCase()}
            value={professionalData?.address}
          />
          <LabelValue
            label={labels.account.accountForm.city.toUpperCase()}
            value={professionalData?.city}
          />
          <LabelValue
            label={labels.account.accountForm.cap.toUpperCase()}
            value={professionalData?.cap}
          />
          <LabelValue
            label={labels.account.accountForm.province.toUpperCase()}
            value={professionalData?.province}
          />
          <LabelValue
            label={labels.account.accountForm.jobs.toUpperCase()}
            value={professionalData?.jobs}
          />
        </StyledColumnRight>
      </StyledRowMain>
    </Grid>
  );

  const SectionTitle = ({ title }) => (
    <Grid item container xs={12}>
      <SpText style={{ flex: 1 }} variant="h1">
        {title}
      </SpText>
    </Grid>
  );

  const LicenseInfo = () => (
    <Grid item xs={12}>
      <StyledRowMain>
        <StyledColumnLeft>
          <LabelValue
            label={labels.account.licenseForm.parentUser.toUpperCase()}
            value={professionalData?.parent_user}
          />
          <LabelValue
            label={labels.account.licenseForm.licenses.toUpperCase()}
            value={professionalData?.licences}
          />
          <LabelValue
            label={labels.account.licenseForm.years.toUpperCase()}
            value={professionalData?.years}
          />
        </StyledColumnLeft>

        <StyledColumnRight>
          <LabelValue
            label={labels.account.licenseForm.activationDate.toUpperCase()}
            value={
              professionalData?.activation_date
                ? moment(professionalData?.activation_date).format("DD/MM/YYYY")
                : null
            }
          />
          <LabelValue
            label={labels.account.licenseForm.expiryDate.toUpperCase()}
            value={
              professionalData?.expiry_date
                ? moment(professionalData?.expiry_date).format("DD/MM/YYYY")
                : null
            }
          />
          <LabelValue
            label={labels.account.licenseForm.validLicense.toUpperCase()}
            value={
              professionalData?.valid_licence || isBusiness() || enableAuth0
                ? labels.general.yes.toUpperCase()
                : labels.general.no.toUpperCase()
            }
          />
          <LabelValue
            label={labels.account.licenseForm.trial.toUpperCase()}
            value={
              professionalData?.trial
                ? labels.general.yes.toUpperCase()
                : labels.general.no.toUpperCase()
            }
          />
        </StyledColumnRight>
      </StyledRowMain>
    </Grid>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <SectionTitle title={labels.account.title} />
        <PersonalInfo />
        <SectionTitle title={labels.account.license_title} />
        <LicenseInfo />
        <AccountButton professionalData={professionalData} />
      </Grid>
    </>
  );
};

export default withSnackbar(Account);