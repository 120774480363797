import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import SpTextInput from "../../atoms/SpTextInput";
import { labels } from "../../../pages/shared/translations";
import { logoutProfessional } from "../../../models/actions/Auth";
import SpButton from "../../atoms/SpButton";
import { useHistory } from "react-router-dom";
import { isBusiness, isAuth0Enabled, rollbar } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";

const AccountButton = (props) => {
  const { logout } = useAuth0();
  const { professionalData } = props;
  const [enableAuth0, setEnableAuth0] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    try {
      const auth0Enabled = await isAuth0Enabled();
      setEnableAuth0(auth0Enabled);
    } catch (e) {
      rollbar.error("AccountButton - fetch flag", e);
    }
  }, []);

  const logoutUser = async () => {
    if (isBusiness() || enableAuth0) {
      logout();
    } else {
      const response = await logoutProfessional();
      if (response) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        return history.replace("/login");
      } else {
        props.snackbarShowErrorMessage(response.error);
      }
    }
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        alignSelf: "center",
      }}
    >
      <form
        action={process.env.WP_SILENT_LOGIN_URL}
        target="_self"
        method="POST"
      >
        {/*Start Hidden input*/}
        <SpTextInput
          name={"login_token"}
          style={{ display: "none" }}
          displayLabel={false}
          value={professionalData?.login_token}
        />
        <SpTextInput
          name={"email"}
          style={{ display: "none" }}
          displayLabel={false}
          value={professionalData?.email}
        />
        {/*End Hidden input*/}
        {isBusiness() && (
          <SpButton
            variant="none"
            buttonType={"accept"}
            text={labels.account.toolbar.go_to_account}
            type="submit"
          />
        )}
        <SpButton
          variant="none"
          buttonType={"accept"}
          style={{ marginLeft: "10px" }}
          onClick={logoutUser}
          text={labels.account.toolbar.logout}
        />
      </form>
    </Grid>
  );
};

export default AccountButton;
