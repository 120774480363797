import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StudySharedControls from "./SharedControls";
import StudyZScoreReferencePeriod from "./studyZScore/StudyZScoreReferencePeriod";
import StudyZScoreFixedPeriods from "./studyZScore/StudyZScoreFixedPeriods";
import StudyZScoreSubjectsReferencePeriod from "./studyZScore/StudyZScoreSubjectsReferencePeriod";
import StudyZScoreSubjectsFixedPeriods from "./studyZScore/StudyZScoreSubjectsFixedPeriods";
import { genericIsEnabled } from "../../ReportHelperFns";
import { labels } from "../../../../shared/translations";

const ZSCORE_STUDIES = [
  StudyZScoreReferencePeriod,
  StudyZScoreFixedPeriods,
  StudyZScoreSubjectsReferencePeriod,
  StudyZScoreSubjectsFixedPeriods,
];

export const StudyZScore = ({
  mainSubjectKey,
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      setActive(config.active);
      setConfigDone(true);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <Grid container item xs={12}>
      <StudySharedControls
        title={labels.patient.graphReport.section.feedback.studies[study.key]}
        active={active}
        setActive={setActive}
        enabled={isEnabled()}
      ></StudySharedControls>
      <Grid container item xs={12}>
        {active &&
          isEnabled() &&
          ZSCORE_STUDIES.map((ZScoreStudy, idx) => (
            <ZScoreStudy
              key={`${study.key}_${idx}`}
              mainSubjectKey={mainSubjectKey}
              study={study}
              graphDateView={graphDateView}
              graphType={graphType}
              currStudyParameters={currStudyParameters}
              dateRange={dateRange}
              data={data}
              defaultZero={defaultZero}
              onStudyDataChange={onStudyDataChange}
              onFetchDateDaysChange={onFetchDateDaysChange}
              zScoreActiveAndEnabled={active && isEnabled()}
              config={config?.[`${idx}`]}
              setConfig={(data) => setConfig({ [`${idx}`]: data })}
              requestSaveFlag={requestSaveFlag}
            />
          ))}
      </Grid>
    </Grid>
  );
};
