import { call } from "./utils";

export const getAssessmentsDetail = async () =>
  await call({ method: "GET", url: "/assessments/list" });

export const getAssessmentsTemplateCategories = async () =>
  await call({
    method: "GET",
    url: "/assessments/categories/list",
  });

export const getAssessmentQuestionnaires = async (data) =>
  await call({
    url: "/assessments/get/questionnaires/by/id",
    data: { data },
  });

export const getAssessmentTemplateElementsByCategoryIds = async (data) =>
  await call({
    url: "/assessments/template/elements/by/categories",
    data: { data },
  });

export const getAssessmentTemplatesByProfessional = async () =>
  await call({ url: "/assessments/get/templates/by/professional" });

export const sendAssessmentAnswer = async (data) =>
  await call({ url: "/assessments/send/answer", data: { data } });

export const sendAssessmentAnswerPresentation = async (data) =>
  await call({
    url: "/assessments/send/presentation/answer",
    data: { data },
  });

export const sendAssessmentAnswerMonitoring = async (data) =>
  await call({
    url: "/assessments/send/monitoring/answer",
    data: { data },
  });

export const saveAssessmentTemplate = async (data) =>
  await call({ url: "/assessments/save/template", data: { data } });

export const updateAssessmentTemplate = async (data) =>
  await call({
    url: "/assessments/update/template",
    data: { data },
  });

export const getAssessmentMonitList = async (data) =>
  await call({
    url: "/assessments/monitoring/list",
    data: { data },
  });

export const getAssessmentPresentationList = async (data) =>
  await call({
    url: "/assessments/presentation/list",
    data: { data },
  });

export const createAssessmentTemplate = async (data) =>
  await call({ url: "/assessment/create", data: { data } });

export const getAssessmentTemplates = async (data) =>
  await call({
    url: "/assessments/templates/list",
    data: { data },
  });

export const getAssessmentTemplateDetail = async (data) =>
  await call({
    url: "/assessments/template/by/id",
    data: { data },
  });

export const getAssessmentPresentationAnswers = async (data) =>
  await call({
    url: "/assessments/get/presentation/answers",
    data: { data },
  });

export const getAssessmentMonitoringAnswers = async (data) =>
  await call({
    url: "/assessments/get/monitoring/answers",
    data: { data },
  });

export const deleteAssessmentPresentationAnswer = async (data) =>
  await call({
    url: "/assessments/remove/presentation/answers",
    data: { data },
  });

export const getMonitoringAssessmentToRepeat = async (data) =>
  await call({
    url: "/assessments/get/monitoring/to/repeat",
    data: { data },
  });

export const getPresentationAssessmentToRepeat = async (data) =>
  await call({
    url: "/assessments/get/presentation/to/repeat",
    data: { data },
  });

export const getAnswerStructureDysfunctions = async (data) =>
  await call({
    url: "/assessments/get/answer/structure/dysfunction",
    data: { data },
  });
