import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import PROMSIndexes from "./PROMSIndexes";
import PROMSGlobalFilters from "./PROMSGlobalFilters";
import PROMSLineGraph from "./PROMSLineGraph";
import PROMSData from "./PROMSData";

const moment = extendMoment(Moment);

const PROMS = ({
  data,
  onDataRequest,
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [selectedProm, setSelectedProm] = useState();

  const [availableRange, setAvailableRange] = useState(
    moment.rangeFromInterval("month", -1, moment.utc().startOf("day"))
  );
  const [dateRange, setDateRange] = useState(
    moment.rangeFromInterval("month", -1, moment.utc().startOf("day"))
  );

  const [graphDateView, setGraphDateView] = useState("");

  const [graphData, setGraphData] = useState();

  const doDataRequest = (filterRange) => {
    const newRange = availableRange.add(filterRange, { adjacent: true });
    setAvailableRange(newRange);
    onDataRequest(newRange);
  };

  useEffect(() => {
    onDataRequest(dateRange);
  }, []);

  useEffect(() => {
    if (!availableRange.contains(dateRange)) doDataRequest(dateRange);
  }, [dateRange, data, graphDateView, selectedProm]);

  return (
    <Grid item xs={12} container direction="column" wrap="nowrap">
      <PROMSGlobalFilters
        selectedProm={selectedProm}
        dateRange={dateRange}
        graphDateView={graphDateView}
        onSelectedPromsChanged={(prom) => setSelectedProm(prom)}
        onDateRangeChanged={(range) => setDateRange(range)}
        onCurrentDateViewChanged={(dateView) => setGraphDateView(dateView)}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
      />
      <PROMSIndexes selectedProm={selectedProm} />
      <PROMSLineGraph
        data={graphData}
        dateRange={dateRange}
        graphDateView={graphDateView}
        defaultZero={false}
      />
      <PROMSData
        data={data}
        dateRange={dateRange}
        graphDateView={graphDateView}
        selectedProm={selectedProm}
        onGraphDataChanged={setGraphData}
      />
    </Grid>
  );
};

export default PROMS;
