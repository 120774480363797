import React from "react";
import { Box, Grid } from "@material-ui/core";
import SpText from "../../atoms/SpText";
import SpTextInput from "../../atoms/SpTextInput";
import SpButton from "../../atoms/SpButton";
import { labels, psTranslate } from "../../../pages/shared/translations";

const Feedback = ({ event, setValue, click, register }) =>
  event.feedbacks &&
  event.feedbacks?.length > 0 && (
    <Grid
      direction="column"
      container
      style={{ marginTop: "2%", marginBottom: "2%" }}
    >
      <Grid
        item
        container
        direction="row"
        xs={12}
        style={{ marginBottom: "2%" }}
      >
        <Grid item xs={12}>
          <SpText variant="h1PageSubtitle">Feedback</SpText>
          {!(
            event.archiving_status === "completed_and_feedback_not_sent" ||
            event.archiving_status === "completed_and_feedback_sent"
          ) && (
            <SpText variant="text">
              {labels.bundles.calendarPlan.feedback.hint}
            </SpText>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <form>
          {event.feedbacks.map((feed, idx) => {
            setValue(`feed_resp_ans[${idx}].id`, feed.id);
            return (
              <Grid
                direction="row"
                container
                alignItems="center"
                style={{ marginTop: "5px" }}
              >
                <Grid item xs={12}>
                  {event.archiving_status ===
                    "completed_and_feedback_not_sent" ||
                  event.archiving_status === "completed_and_feedback_sent" ? (
                    <SpText variant="h4ComponentLabel">
                      {`${psTranslate(feed?.feedback_parameter?.name)} (in ${
                        feed?.feedback_parameter?.um
                      })`}
                      :{" "}
                      <Box
                        color={"#31ccad"}
                        style={{ display: "inline-block" }}
                      >
                        {feed.answer}
                      </Box>
                    </SpText>
                  ) : (
                    <SpTextInput
                      name={`feed_resp_ans[${idx}].answer`}
                      type="number"
                      minValue={0}
                      inputRef={register}
                      defaultValue={feed.answer}
                      formControlStyle={{ width: "100%" }}
                      label={`${psTranslate(
                        feed?.feedback_parameter?.name
                      )} (in ${feed?.feedback_parameter?.um})`}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </form>
      </Grid>
      {!(
        event.archiving_status === "completed_and_feedback_not_sent" ||
        event.archiving_status === "completed_and_feedback_sent"
      ) && (
        <Grid xs={12} item>
          <SpButton
            buttonType="accept"
            style={{ width: "100%", marginTop: "2%" }}
            text={labels.bundles.calendarPlan.feedback.save}
            variant="h1PageTitle"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              click();
            }}
          />
        </Grid>
      )}
    </Grid>
  );

export default Feedback;
