import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../../components/atoms/SpSelect";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { getTimeViews } from "../../../../models/actions/Patients";
import { getAllPromsSurveys } from "../../../../models/actions/Proms";
import { labels, psTranslate } from "../../../shared/translations";
import { getMomentIdFromKey } from "../ReportHelperFns.js";
const moment = extendMoment(Moment);

const dateFormat = "YYYY-MM-DD";

const PROMSGlobalFilters = ({
  selectedProm,
  dateRange,
  graphDateView,

  onSelectedPromsChanged,
  onDateRangeChanged,
  onCurrentDateViewChanged,

  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [proms, setProms] = useState([]);
  const [dateViews, setDateViews] = useState([]);

  const changeDate = (newDateRange) => {
    //Snap to start/end of week/month
    const start = newDateRange.start.startOf(
      getMomentIdFromKey(graphDateView.key)
    );
    let end = newDateRange.end;
    //Let's use week as example to explain:
    end.add(-1, "hour"); //Move yesterday in case user selected a monday, else no harm
    end.endOf(getMomentIdFromKey(graphDateView.key)); //Reach end of week, which is now always sunday of the prev week at 23:59
    end.add(1, "hour"); //Move to monday, start of next element, now it's monday 00:59
    end = end.startOf("day"); //Fix hours to start of day, move back to 00:00

    onDateRangeChanged(moment.range(start, end));
  };

  //Effects
  useEffect(async () => {
    let [views, allProms] = await Promise.all([
      getTimeViews(),
      getAllPromsSurveys(),
    ]);
    setDateViews(views);
    if (!graphDateView) onCurrentDateViewChanged(views[0]);

    setProms(allProms);
    if (!selectedProm) onSelectedPromsChanged(allProms[0]);
  }, []);

  useEffect(() => {
    if (graphDateView && dateViews.length > 0) changeDate(dateRange);
  }, [graphDateView]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (
      !configDone &&
      config &&
      proms.length > 0 &&
      dateViews.length > 0 &&
      graphDateView &&
      selectedProm
    ) {
      const nowStart = moment().startOf("day").add(config.dateRange[0], "days");
      const nowEnd = moment().startOf("day").add(config.dateRange[1], "days");
      onDateRangeChanged(moment.range(nowStart, nowEnd));
      onSelectedPromsChanged(
        proms.find((prom) => prom.id == config.selectedProms)
      );
      const dateView = dateViews.find(
        (type) => type.id == config.graphDateView
      );
      onCurrentDateViewChanged(dateView);
      setConfigDone(true);
    }
  }, [config, configDone, proms, dateViews, graphDateView, selectedProm]);
  useEffect(() => {
    if (requestSaveFlag) {
      const now = moment().startOf("day");
      //Save config
      setConfig({
        dateRange: [
          dateRange.start.diff(now, "days"),
          dateRange.end.diff(now, "days"),
        ],
        selectedProms: selectedProm.id,
        graphDateView: graphDateView.id,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  const constPadding = { padding: "5px" };
  return (
    <Grid item xs={12} container direction="row" wrap="nowrap">
      <Grid item xs={3} container direction="column" wrap="nowrap">
        <Grid item xs={12} style={constPadding}>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.start_date
            }
            type="date"
            formControlStyle={{ width: "100%" }}
            value={dateRange.start.format(dateFormat)}
            onChange={(evnt) =>
              moment(evnt.target.value).isBefore(dateRange.end) &&
              changeDate(moment.range(evnt.target.value, dateRange.end))
            }
          />
        </Grid>
        <Grid item xs={12} style={constPadding}>
          <SpTextInput
            label={labels.patient.graphReport.section.feedback.filters.end_date}
            type="date"
            formControlStyle={{ width: "100%" }}
            value={dateRange.end.format(dateFormat)}
            onChange={(evnt) =>
              dateRange.start.isBefore(moment(evnt.target.value)) &&
              changeDate(moment.range(dateRange.start, evnt.target.value))
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={3} container direction="column" wrap="nowrap">
        <Grid item xs={12} style={constPadding}>
          <SpSelect
            label={
              labels.patient.graphReport.section.feedback.filters
                .study_parameters
            }
            labelPaddingTop={"0px"}
            formControlStyle={{ width: "100%" }}
            value={selectedProm ?? ""}
            onChange={(evnt) => onSelectedPromsChanged(evnt.target.value)}
          >
            {proms.map((prom) => (
              <SpSelectMenuItem key={prom.name} value={prom}>
                {psTranslate(prom.name)}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
        <Grid item xs={12} direction="row" style={constPadding} wrap="nowrap">
          <SpSelect
            label={
              labels.patient.graphReport.section.feedback.filters.date_view
            }
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={graphDateView}
            onChange={(evnt) => onCurrentDateViewChanged(evnt.target.value)}
          >
            {dateViews.map((dateView) => (
              <SpSelectMenuItem key={dateView.key} value={dateView}>
                {
                  labels.patient.graphReport.section.feedback.filters[
                    dateView.name.trim().toLowerCase()
                  ]
                }
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PROMSGlobalFilters;
