import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { labels } from "../../shared/translations";

const RaceInputDetails = ({
  selectedTemplate,
  templateData,
  control,
  setValue,
  register,
  disabled = false,
}) => {
  useEffect(() => {
    if (templateData) {
      setValue("race_motivation", templateData.race_motivation);
      setValue("race_minutes", templateData.race_minutes);
    }
  }, [templateData]);

  return (
    <Grid
      item
      xs={12}
      container
      style={{ justifyContent: "flex-start" }}
      direction="row"
    >
      <SpTextInput
        name="race_motivation"
        inputRef={register}
        label={
          labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
        }
        disabled={disabled}
      />
      <SpTextInput
        type="number"
        minValue={1}
        name="race_minutes"
        inputRef={register}
        style={{ marginLeft: "3px" }}
        label={labels.patient.viewPlan.addActivity.inputForm.raceMinutes}
        disabled={disabled}
      />
    </Grid>
  );
};

export default RaceInputDetails;
